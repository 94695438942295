
import CopyToClipboard from "react-copy-to-clipboard";
import {
    Container, Row, Col, Button, Card, CardBody, CardTitle, UncontrolledTooltip, Progress, Label
} from "reactstrap";
import { TooltipButton } from '../../../../inputs';
import React, { useEffect, useState, useCallback, useMemo, useRef, Component } from 'react';

export default class MessageTextCellRenderer extends Component {

    constructor(props) {
        super(props);
        //this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    render() {

        var { props } = this;
        var { colDef, data, setPnMessage } = props;

        if (data.rosterCandidate) {
            return (<a className="text-info" href="javascript:;" onClick={() => { setPnMessage(data.messageText); }} title={data.messageText}><u>{data.messageText}</u></a>);
        }
        return null;
    }
}