import React, { useEffect, useState } from 'react';
import {
    Label, FormGroup, Row, Col, Collapse, Input
 } from "reactstrap"; 
import { ComboBox, DropdownBox } from "../../../dropdowns";
import { DatePicker } from "../../../date-pickers";
import { formatDate } from "../../../../utils";
import moment from "moment";
import { useTable, useFlexLayout, useFilters, useSortBy } from 'react-table';
import { DefaultColumnFilter } from "../../../react-table/filters";
import api from "../../../../services/api";
import _ from "lodash";
import { TooltipButton } from '../../../inputs';
import Grid from "../../../Grid";
import { Mode } from '../../../../utils';

export default function ProjectAttributes(props) {
    // console.log('ProjectAttributes', props)
    const {
        itemInEdit, 
        setItemInEdit
    } = props;

    const [ drawerMode, setDrawerMode ] = React.useState(Mode.NONE);
    const [ candidateAttribute, setCandidateAttribute ] = React.useState(null);
    const [ pageNumber, setPageNumber ] = React.useState(1);
    const [ pageSize, setPageSize ] = React.useState(10);
    const [ totalRecords, setTotalRecords ] = React.useState(0);
    const [ selectedProject, setSelectedProject ] = useState(null);
    const [ attributes, setAttributes ] = useState([]);
    const [ data, setData ] = useState([]);
    
    const skipPageResetRef = React.useRef();

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 150, 
            maxWidth: 200,
    }), []);

    useEffect(() => {
        if (props.project) {
            setDrawerMode(Mode.ADD);
            setSelectedProject({
                lookupId: props.project.projectId,
                lookupValue: props.project.projectName,
            })
        }
    }, [])

    useEffect(() => {
        // console.log({itemInEdit});
        skipPageResetRef.current = true;
        const newData = itemInEdit?.projectCandidateAttributes ? [...itemInEdit.projectCandidateAttributes] : [];
        setData([...newData]);
    }, [itemInEdit?.projectCandidateAttributes]);

    useEffect(() => {
        if (!candidateAttribute)
            return;

        // console.log({candidateAttribute});
        if (!selectedProject?.lookupId)
            setSelectedProject(candidateAttribute?.projectAttribute?.project);
    }, [candidateAttribute]);

    useEffect(() => {
        if (!selectedProject?.lookupId)
        {
            // console.log({candidateAttribute, exists: (candidateAttribute ? true : false), selectedProject});
            if (candidateAttribute)
                setCandidateAttribute({...candidateAttribute, projectAttribute: null, projectAttributeId: null});
            
            return;
        }
        
        api.get(`/projectattribute/project/${selectedProject.lookupId}`)
            .then((response) => {
                // console.log(response.data);
                setAttributes(response.data);
            })
            .catch((error) => {
                console.error(error);
            })
    }, [selectedProject]);

    useEffect(() => {
        if (drawerMode === Mode.NONE)
            setCandidateAttribute(null);
    }, [drawerMode]);

    const columns = React.useMemo(() => {
        return [
            {
                Header: "Project",
                id: "projectName",
                accessor: (row) => row.projectAttribute ? row.projectAttribute.project?.lookupValue : "",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "Attribute",
                id: "attributeName",
                accessor: (row) => row.projectAttribute ? row.projectAttribute.projectAttributeName : "",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "Value",
                id: "value",
                accessor: (row) => row.stringValue ?? row.numberValue ?? (row.dateValue ? moment(row.dateValue).format("DD-MM-YYYY") : null),
                Filter: DefaultColumnFilter,
                filter: "text",
                disableFilters: true,
                disableSortBy: true,
            },
            {
                Header: "Created Date",
                accessor: (row) => row.createdDate ? moment(row.createdDate).format("DD-MM-YYYY HH:mm:ss") : null,
                Filter: DefaultColumnFilter,
                filter: "text",
                disableFilters: true,
                disableSortBy: true,
            },
            {
                Header: "Last Modified",
                accessor: (row) => row.modifiedDate ? moment(row.modifiedDate).format("DD-MM-YYYY HH:mm:ss") : null,
                Filter: DefaultColumnFilter,
                filter: "text",
                disableFilters: true,
                disableSortBy: true,
            },
            {
                id: 'buttons',
                Header: "Action",
                width: 180,
                canResize: false,
                Cell: ({row:{original, isGrouped}}) => (
                    (original && !isGrouped) ?
                    (
                        <div className="td-action text-center">  
                            <TooltipButton
                                id={`edit_${original.projectCandidateAttributeId}`}
                                title="Edit"
                                className="btn-icon"
                                color="default"
                                size="xsm"
                                type="button"
                                onClick={() => {
                                    // console.log({original});
                                    setCandidateAttribute({...original})
                                    setDrawerMode(Mode.EDIT);
                                }}
                            >
                                <i className="fas fa-pencil-alt pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`delete_${original.projectCandidateAttributeId}`}
                                title="Delete"
                                className="btn-icon"
                                color="warning"
                                size="xsm"
                                type="button"
                                onClick={() => {
                                    // console.log("Delete: ", { skill: original, itemInEdit });
                                    const index = data.findIndex(val => {
                                        if (original.projectCandidateAttributeId)
                                            return val.projectCandidateAttributeId === original.projectCandidateAttributeId;
    
                                        return val.index === original.index;
                                    });
                                    // console.log("Deleted index: ", { index });
                                    const currAttributes = [...data];
                                    currAttributes.splice(index, 1);
                                    setItemInEdit({ ...itemInEdit, projectCandidateAttributes: currAttributes });
                                }}
                            >
                                <i className="fas fa-trash-alt pt-1"></i>
                            </TooltipButton>
                        </div>
                    ) :
                    (
                        <div className="td td-action"></div>
                    )
                )
            }
        ]
    }, [itemInEdit, data]);

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
                })
            },
        }),
        []
    );

    const defaultSort = React.useMemo(() => [{ id: "projectName", desc: false }],
        []
    );
    const defaultFilter = React.useMemo(() => props.project ? [{ id: "projectName", value: props.project.projectName }] : [],
        []
    );
        // console.log('defaultFilter', defaultFilter)
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                "hiddenColumns": [],
                sortBy: defaultSort,
                canSort: true,
                filters: defaultFilter
            },
            defaultColumn,
            // manualSortBy: true,
            filterTypes,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        // useRowSelect,
        useFlexLayout,
        useFilters,
        useSortBy,
    );

    return (<>
        <Collapse 
            isOpen={drawerMode !== Mode.NONE}
            style={{ zIndex: "9999", border: "0.0625rem solid #dee2e6" }}
            className="bg-white w-100 rounded p-3 mb-2"
        >
            <Row>
                <Col xs={12}>
                    <Row>
                        <Col xs={3}>
                            <FormGroup>
                                <Label>
                                    Project
                                </Label>
                                <ComboBox 
                                    endpoint="/project"
                                    selectedItem={selectedProject}
                                    onChange={(selectedItem) =>  setSelectedProject(selectedItem)}
                                    minLength={1}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <Label>
                                    Attribute
                                </Label>
                                <DropdownBox
                                    data={attributes}
                                    disabled={!selectedProject?.lookupId}
                                    isLookup={false}
                                    idField="projectAttributeId"
                                    valueField="projectAttributeName"
                                    selectedItem={candidateAttribute?.projectAttribute}
                                    onChange={(selectedItem) => setCandidateAttribute({
                                        ...candidateAttribute,
                                        projectAttribute: selectedItem,
                                        projectAttributeId: selectedItem?.projectAttributeId
                                    })}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={2}>
                            <FormGroup>
                                <Label>
                                    Value
                                </Label>
                            {
                                candidateAttribute?.projectAttribute && candidateAttribute?.projectAttribute.projectAttributeType.lookupValue === "string" &&
                                <Input
                                    id="stringValue"
                                    type="text"
                                    required
                                    value={candidateAttribute?.stringValue ?? ""}
                                    onChange={(e) => setCandidateAttribute({ ...candidateAttribute, stringValue: e.target.value, numberValue: null, dateValue: null })}
                                />
                            }
                            {
                                candidateAttribute?.projectAttribute && candidateAttribute?.projectAttribute.projectAttributeType.lookupValue === "number" &&
                                <Input
                                    id="numberValue"
                                    type="text"
                                    required
                                    value={candidateAttribute?.numberValue ?? ""}
                                    onChange={(e) => {
                                        if (isNaN(e.target.value))
                                            return;

                                        setCandidateAttribute({ ...candidateAttribute, numberValue: e.target.value, stringValue: null, dateValue: null });
                                    }}
                                />
                            }
                            {
                                candidateAttribute?.projectAttribute && candidateAttribute?.projectAttribute.projectAttributeType.lookupValue === "date" &&
                                <DatePicker
                                    name="achievedDate"
                                    id="achievedDate"
                                    type="text"
                                    required
                                    value={formatDate(candidateAttribute?.dateValue)}
                                    onChange={value => {
                                        setCandidateAttribute({ 
                                            ...candidateAttribute, 
                                            dateValue: !value ? null : moment(value).format("YYYY-MM-DDT00:00:00"),
                                            stringValue: null,
                                            numberValue: null
                                        });
                                    }}
                                    closeOnSelect
                                />
                            }
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-end">
                    <TooltipButton
                        id="cancel"
                        title="Cancel"
                        className="btn-icon"
                        color="warning"
                        size="sm"
                        type="button"
                        onClick={ () => {                 
                            setDrawerMode(Mode.NONE);
                        }}                    
                    >
                        Cancel
                    </TooltipButton>
                    <TooltipButton
                        id="add"
                        title="Add"
                        className="btn-icon ml-2"
                        color="default"
                        size="sm"
                        type="button"
                        onClick={ () => { 
                            if (drawerMode === Mode.ADD) {
                                setItemInEdit( { 
                                    ...itemInEdit, 
                                    projectCandidateAttributes: [ 
                                        ...itemInEdit?.projectCandidateAttributes || [], 
                                        {
                                            candidateId: itemInEdit?.candidateId,
                                            ...candidateAttribute,
                                            index: data ? data.length - 1 : 0
                                        } 
                                    ] 
                                } );
                            } 
                            else if (drawerMode === Mode.EDIT)
                            {
                                const index = _.findIndex(data, o => {
                                    if (candidateAttribute.projectCandidateAttributeId)
                                        return o.projectCandidateAttributeId === candidateAttribute.projectCandidateAttributeId;

                                    return o.index === candidateAttribute.index;
                                });

                                const currAttributes = [...data]
                                currAttributes.splice(index, 1, { 
                                    ...candidateAttribute
                                } );
                                // console.log({index, candidateAttribute, currAttributes})
                                setItemInEdit( { 
                                    ...itemInEdit, 
                                    projectCandidateAttributes: currAttributes 
                                } );
                            }
                            setDrawerMode(Mode.NONE);
                        }}
                    >
                        {drawerMode === Mode.ADD ? "Add" : drawerMode === Mode.EDIT ? "Update" : ""}
                    </TooltipButton>
                </Col>
            </Row>
        </Collapse>
        <Collapse
            isOpen={drawerMode === Mode.NONE}
        >
            <TooltipButton
                id="addnew"
                title="Add New"
                className="btn-icon ml-3 mb-2"
                color="default"
                size="sm"
                type="button"
                onClick={ () => {
                    setCandidateAttribute({ candidateId: itemInEdit?.candidateId })
                    setDrawerMode(Mode.ADD);
                }}
            >
                <i className="fas fa-plus pt-1"></i>
            </TooltipButton>
        </Collapse>
        
        <Grid
            rows={rows}
            tableProps={getTableProps()}
            headerGroups={headerGroups}
            tableBodyProps={getTableBodyProps()}
            prepareRow={prepareRow}
            height={drawerMode !== Mode.NONE ? "300px" : "500px"}
        />
    </>)
}