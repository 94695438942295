import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import { useParams, useHistory } from "react-router-dom";
import api from "../../../../services/api";
import qs from "qs";
import { SortsConverter, FiltersConverter } from "../../../../helpers"
import {
    Container, Row, Col, Card, CardBody
} from "reactstrap";
import moment from "moment";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../../alerts";
import { TooltipButton } from '../../../inputs';
import fileDownload from "js-file-download";
import Loader from "../../../loaders";
import { IndeterminateCheckbox } from '../../../react-table/controls';
import ActionBtnCellRenderer from './cellRenderer/actionBtnCellRenderer'
import PositionCellRenderer from './cellRenderer/positionCellRenderer'
import { useMsal } from "@azure/msal-react";
import _ from 'lodash';
import { roles as constRoles } from "../../../../auth/roles";

const timeoutLength = 1500;
const apiurl = "recruitmentrequest";

export default function LabourRequestGrid(props) {
    const { instance, token } = useMsal();
    const user = instance.getActiveAccount();
    const [data, setData] = React.useState([]);

    const params = useParams();
    const [copiedIndex, setCopiedIndex] = React.useState(null);
    
    const history = useHistory();

    React.useEffect(() => {
        if (copiedIndex !== null) {
            const timeoutId = setTimeout(() => {
                setCopiedIndex(null);
            }, timeoutLength);

            return () => clearTimeout(timeoutId);
        }
    }, [copiedIndex]);
    
    const [loading, setLoading] = React.useState(false);
    const [itemInEdit, setItemInEdit] = React.useState(null);
    const [canAutoOpen, setCanAutoOpen] = React.useState(true);
    const [showActiveRequestCheckbox, setShowActiveRequestCheckbox] = useState(false);
    const [showActiveRequest, setShowActiveRequest] = useState(false);
    const [showAll, setShowAll] = useState(false);

    const [pageNumber, setPageNumber] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(50);
    const [deleteAlert, setDeleteAlert] = React.useState([]);
    const [filters, setFilters] = React.useState([]);

    const defaultSort = React.useMemo(() => [{ id: "lrfCreationDate", desc: true }, { id: "projectName", desc: false }],
        []
    );

    const [sortBy, setSortBy] = React.useState(defaultSort);
    const skipPageResetRef = React.useRef();
    const [voidAuthorized, setVoidAuthorized] = useState(false);
    const [voidConfirmation, setVoidConfirmation] = useState(null);
    const [versionCounter, setVersionCounter] = useState(0);
    const gridRef = useRef();
    const [gridOptions, setGridOptions] = useState(null);
    
    const getRowClass = ({ data }) => {
        return data?.status == "Approved" ? "green-pastel" : data?.status == "Draft" ? "yellow-pastel" : ""
    }

    const getLRFRelatedData = useCallback((data) => {
        setLoading(true);
        setVoidConfirmation(null);

        const apiCalls = [
            api.get(`/joborder/lrfvoidcheck?recruitmentrequestid=${data.recruitmentRequestId}`),
            api.get(`/rostercandidate/lrfvoidcheck?recruitmentrequestid=${data.recruitmentRequestId}`),
        ];

        Promise.all(apiCalls)
            .then(responses => {
                setVoidConfirmation({
                    lrfId: data.recruitmentRequestId,
                    lrfNo: data.recruitmentRequestNumber,
                    rosters: [...responses[1].data],
                    jobOrders: [...responses[0].data]
                });
            })
            .catch((error) => console.error({ error }))
            .finally(() => setLoading(false))
            ;
    }, []);

    const handleExportToExcel = useCallback(() => {
        setLoading(true);
        const url = `recruitmentrequest/export`;

        api.get(url, { responseType: 'blob' })
            .then(blob => {
                fileDownload(blob.data, "LRF.xlsx");
            })
            .catch(error => {
                console.error({ error });
            })
            .finally(() => setLoading(false))
            ;
    }, [data]);

    const resizeColumns = useCallback(() => {
        if (!gridRef?.current?.columnApi || data?.length === 0)
            return;

        const allColumnIds = [];
        gridRef.current.columnApi.getColumns().forEach((column) => {
            // console.log(column, column.getId());
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }, [data, gridRef]);

    useEffect(() => {
        if (!gridRef.current?.api)
            return;
        
        onGridReady(gridRef.current);
    }, [showAll, showActiveRequest]);

    const defaultColumns = [
        {
            name: "isUrgent",
            title: "Urgent Request",
            width: 100,
            valueFormatter: (params) => {
                var { value } = params;
                return value ? "Y" : "N";
            },
        },
        {
            name: "recruitmentRequestNumber",
            title: "LRF Number"
        },
        {
            name: "createdDate",
            title: "Created Date",
            field: 'date',
            filter: 'agDateColumnFilter',
            valueFormatter: (params) => {
                var { value } = params;
                return moment(value).format('DD.MM.YYYY');
            },
        },
        {
            name: "department",
            title: "Department",
        },
        {
            name: "location",
            title: "Location"
        },
        {
            name: "project",
            title: "Project"
        },
        {
            name: "dispatchUnit",
            title: "Dispatch Unit"
        },
        {
            name: "roster",
            title: "Roster"
        },
        {
            name: "recruitmentType",
            title: "Type"
        },
        {
            name: "status",
            title: "Status"
        },
        {
            name: "approverName",
            title: "Approver Name",
        },
        {
            name: "createdBy",
            title: "Created By",
        },
        {
            name: "positionName",
            title: "Positions",
            cellRenderer: PositionCellRenderer,
            autoHeight: true,
            sortable: false,
        },
        {
            name: "button",
            title: "Action",
            pinned: 'right',
            width: 100,
            filter: '',

            filterParams: {
                name: 'action'
            },
            sortable: false,
            floatingFilter: false,
            cellClass: "action-button-cell",
            cellRenderer: ActionBtnCellRenderer,
            cellRendererParams: {
                clicked: function (field) {
                    alert(`${field} was clicked`);
                },
                getLRFRelatedData,
                history,
                voidAuthorized,
            },
            additionalParams: () => {

                var props = {
                    copiedIndex: copiedIndex,
                    setCopiedIndex: setCopiedIndex,

                    itemInEdit: itemInEdit,
                    setItemInEdit: setItemInEdit,
                    handleDelete: handleDelete,
                };

                return props;
            },
        },
    ];

    const [agGridSort, setAgGridSort] = useState(['asc', 'desc']);

    const [columnDefs, setColumnDefs] = useState(defaultColumns.map((c) => {
        var obj = {
            ...c,
            field: c.name,
            headerName: c.title,
        };

        if ('name' in obj) delete obj.name;
        if ('title' in obj) delete obj.title;

        return obj;
    }));

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['reset'],
            debounceMs: 200,
            excelMode: 'mac',
        },
        floatingFilter: true,
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        suppressMenu: true,
    }), []);

    const loadingOverlayComponent = useMemo(() => Loader, []);

    const onCellEditRequest = useCallback((event) => {
        console.log("on cell edit request: ", { event });
    }, []);

    const refreshCache = useCallback(
        (route) => {
            //console.log('gridRef', gridRef);
            if (!gridRef || !gridRef.current || !gridRef.current.api)
                return;

            setVersionCounter(versionCounter + 1);
            gridRef.current.api.purgeServerSideCache();
        },
        [versionCounter]
    );
    
    const onGridReady = useCallback((params) => {
        var datasource = getServerSideDatasource();
        params.api.setServerSideDatasource(datasource);
    }, [pageSize, params, showAll, showActiveRequest]);

    const getServerSideDatasource = useCallback(() => {
        return {

            getRows: (params2) => {
                var { request } = params2;
                var { filterModel, sortModel } = request;

                var thisFilters = FiltersConverter(filterModel);
                var thisSorts = SortsConverter(sortModel);
                var selectedSorts = (thisSorts.length ? thisSorts : defaultSort);

                const queryString = qs.stringify({ filters: thisFilters, sortBy: selectedSorts, showAll, showActiveRequest }, { allowDots: true });
                const url = `/${apiurl}/${params2.request.startRow ?? 0}/${pageSize}${params.requestId ? ("/" + params.requestId + "/" + params.action) : ""}${queryString ? `?${queryString}` : ""}`;

                api.get(url)
                    .then((response) => {
                        var successData = {
                            rowData: response.data.data,
                            rowCount: response.data.total,
                        };

                        params2.success(successData);
                    })
                    .catch(error => {
                        params2.fail();
                    });
            },
        };
    }, [pageSize, params, showAll, showActiveRequest]);

    useEffect(() => {
        const { idTokenClaims: { roles } } = instance.getActiveAccount();
        setVoidAuthorized(_.some(roles, o => o === constRoles.Admin));
        setShowActiveRequestCheckbox(_.some(roles, o => o === constRoles.Admin));
    }, [instance]);

    const handleDelete = (item) => {
        const success = () => {
            setLoading(false);
            setDeleteAlert([<DeleteSuccessAlert key={0} onConfirm={() => setDeleteAlert([])} />]);
            if (data.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1);
            } else {
                refreshCache();
            }
        };
        const failure = () => {
            setLoading(false);
            setDeleteAlert([<DeleteFailedAlert key={0} onConfirm={() => setDeleteAlert([])} />]);
        };
        const events = {
            onConfirm: () => {
                setLoading(true);
                api.delete(`/${apiurl}/${item.jobOrderId}`).then(success).catch(failure);
            },
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert key={0} {...events} />])
    }

    React.useEffect(() => {
        if (canAutoOpen && data && data.length === 1 && params.requestId && params.action && params.action === "joborder") {
            setItemInEdit(data[0]);
        }
    }, [canAutoOpen, data, params]);

    return (
        <section className="main">
            {loading && <Loader />}
            {deleteAlert.length > 0 && deleteAlert}
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card className="no-transition">
                            <CardBody>
                                <Row>
                                    <Col sm={6} className="d-flex flex-wrap justify-content-start align-items-center">
                                        <TooltipButton
                                            id="addnew"
                                            title="Add New"
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={() => { history.push("/lrf") }}
                                        >
                                            <i className="fas fa-plus pt-1"></i>
                                        </TooltipButton>
                                        <TooltipButton
                                            id="export-excel"
                                            title="Export to Excel"
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={() => handleExportToExcel()}
                                        >
                                            <i className="fas fa-file-excel pt-1"></i>
                                        </TooltipButton>
                                    </Col>
                                    <Col sm={6} className="text-xs-left text-sm-right d-flex flex-wrap justify-content-end align-items-center">
                                    {
                                        showActiveRequestCheckbox &&
                                        <IndeterminateCheckbox
                                            id="showActiveRequest"
                                            type="checkbox"
                                            labelClassName="job-order-control mr-3"
                                            onChange={() => {
                                                const newValue = !showActiveRequest;
                                                setShowActiveRequest(newValue);

                                                if (!newValue && showAll)
                                                    setShowAll(!showAll);
                                            }}
                                            checked={showActiveRequest}
                                            customTitle="Show All Active Request"
                                        />
                                    }
                                        <IndeterminateCheckbox
                                            id="showCompletedJobOrderCheckBox"
                                            type="checkbox"
                                            labelClassName="job-order-control mr-3"
                                            onChange={() => {
                                                const newValue = !showAll;
                                                setShowAll(newValue);

                                                if (newValue && !showActiveRequest)
                                                    setShowActiveRequest(newValue);
                                            }}
                                            checked={showAll}
                                            customTitle="Show All Request"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <div className="ag-theme-alpine ag-grid" style={{ width: '100%', height: 'calc(100vh - 200px)' }}>
                                            <AgGridReact
                                                ref={gridRef}
                                                gridOptions={gridOptions}
                                                //domLayout={'autoHeight'}
                                                columnDefs={columnDefs}
                                                defaultColDef={defaultColDef}
                                                loadingOverlayComponent={loadingOverlayComponent}
                                                //sideBar={sideBar}
                                                suppressColumnVirtualisation={false}
                                                suppressRowVirtualisation={false}
                                                debounceVerticalScrollbar={false}
                                                rowBuffer={20}
                                                headerHeight={30}
                                                groupHeaderHeight={30}
                                                rowHeight={30}
                                                floatingFiltersHeight={30}
                                                // readOnlyEdit={true}
                                                onRowDataUpdated={() => resizeColumns()}
                                                suppressDragLeaveHidesColumns={true}
                                                suppressColumnMoveAnimation={true}
                                                animateRows={false}
                                                onCellEditRequest={onCellEditRequest}
                                                excelStyles={excelStyles()}
                                                pagination={true}
                                                paginationPageSize={pageSize}
                                                cacheBlockSize={pageSize}
                                                rowModelType={'serverSide'}
                                                serverSideInfiniteScroll={true}
                                                onGridReady={onGridReady}
                                                sortingOrder={agGridSort}
                                                rowClass={'ag-row-normal'}
                                                getRowClass={getRowClass}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export const excelStyles = () => {
    const headerDefaultStyle = {
        alignment: {
            vertical: 'Center',
            horizontal: "Center",
        },
        interior: {
            color: '#f8f8f8',
            pattern: 'Solid',
            patternColor: undefined,
        },
        borders: {
            borderTop: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderRight: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderBottom: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderLeft: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
        },
        font: {
            fontName: 'Roboto',
            color: '#181d1f',
            bold: true,
            size: 12,
        },
    }

    return [
        {
            id: 'header',
            ...headerDefaultStyle
        },
        {
            id: 'date-header',
            ...headerDefaultStyle,
            alignment: {
                vertical: 'Center',
                horizontal: "Center",
                rotate: 90,
            },
        },
        {
            id: 'cell',
            alignment: {
                vertical: 'Top',
                wrapText: true,
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid',
                patternColor: undefined,
            },
            borders: {
                borderTop: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderRight: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderBottom: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderLeft: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
            },
            font: {
                fontName: 'Roboto',
                color: '#181d1f',
                weight: 700,
                size: 12,
            },
        },
    ];
};
