import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Button, Col, Input, Modal, Row } from 'reactstrap';
import api from "../../../../services/api";
import Loader from "../../../loaders";
import qs from "qs";
import { Container, Collapse } from 'reactstrap';
import Grid from '../../../Grid';
import { useTable, useRowSelect, useFlexLayout, useSortBy } from 'react-table';
import { IndeterminateCheckbox } from '../../../react-table/controls';
import moment from "moment";
import SweetAlert from 'react-bootstrap-sweetalert';
import EditorSMS from '../../Candidates/EditorSMS';
import { ErrorNotification } from '../../../alerts';
import { MakeWords } from '../../../../helpers/StringHelper';

export default function CandidateConfirmationEditor(props) {
    const {
        project,
        dispatchUnits,
        rosters,
        rosterCandidates,
        selected,
        onClose,
        onSaved,
        confirmationMessage,
        singleSelection,
        customConfirm,
        lookups,
        customTitle,
        preSelectDataId,
    } = props;

    const [loading, setLoading] = React.useState(false);
    const [title, setTitle] = useState(null);
    const [candidateTitle, setCandidateTitle] = useState(null);
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [failedMessages, setFailedMessages] = useState(null);
    const [estimationMessage, setEstimationMessage] = useState(null);
    const [currentMessage, setCurrentMessage] = useState(confirmationMessage);
    const [currentCancelMessage, setCurrentCancelMessage] = useState(null);
    const [dataIsEmpty, setDataIsEmpty] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [candidates, setCandidates] = useState([]);
    const [selectedCandidates, setSelectedCandidates] = useState([]);
    const [isEstimation, setIsEstimation] = useState(false);

    // Validation
    const [validationMessage, setValidationMessage] = useState(null);
    const [initialSelectedRows, setInitialSelectedRows] = useState([]);
    const defaultSort = React.useMemo(() => [{ id: "index", desc: false }],
        []
    );

    // Error message variables
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorTitle, setErrorTitle] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);
    const [errorMessageOpening, setErrorMessageOpening] = React.useState(`Error occured when processing the request: `);
    const [errorTitleOnGenerate, setErrorTitleOnGenerate] = React.useState(`Send Confirmation Error`);
    const [invalidDateMsg, setInvalidDateMsg] = React.useState(`One or more employees have invalid start/end date`);
    const invalidRosterPos = "Please enter Roster Position for slot no.";

    const loadData = useCallback(() => {
        // console.log("props", props);

        // console.log({dispatchUnits, rosters, rosterCandidates});
        const refType = rosterCandidates && rosterCandidates.length > 0 ? "RC" : (rosters && rosters.length > 0 ? "R" : (dispatchUnits && dispatchUnits.length > 0 ? "D" : ""));

        if (refType === "")
            return;

        const refIds = [];

        if (refType === "RC")
            rosterCandidates.forEach(rc => {
                refIds.push(rc.rosterCandidateId);
            });

        if (refType === "R")
            rosters.forEach(rc => {
                refIds.push(rc.rosterId);
            });

        if (refType === "D")
            dispatchUnits.forEach(rc => {
                refIds.push(rc.dispatchUnitId);
            });

        if (refIds.length === 0)
            return;

        setLoading(true);

        const refIdStr = qs.stringify({ refIds }, { allowDots: true });

        const apiCalls = [
            api.get(`rostercandidateschedule/waiting-schedules?reftype=${refType}&${refIdStr}`)
        ];

        if (refType === "RC")
            apiCalls.push(api.get(`rostercandidate/candidates?${refIdStr}`));

        if (refType === "R")
            apiCalls.push(api.get(`roster/candidates?${refIdStr}`));

        if (refType === "D")
            apiCalls.push(api.get(`dispatchunit/candidates?${refIdStr}`));

        apiCalls.push(api.get(`/messagesmaster?${qs.stringify({ filters: [{ id: "title", value: "Estimate Availability" }] }, { allowDots: true })}`));

        Promise.all(apiCalls)
            .then((response) => {
                // console.log("loadData", {data: response.data});
                const newData = response[0].data && response[0].data !== "" ? [...response[0].data] : [];
                setData([...newData.map((d, index) => ({...d, index: index + 1, isEstimation: false}))]);
                setDataIsEmpty(!response[0].data || response[0].data === "");

                if (!customTitle)
                {
                    let currentTitle = " - Multiple";
    
                    if (dispatchUnits && dispatchUnits.length) {
                        currentTitle = ` - ${dispatchUnits.length === 1 ? dispatchUnits[0].dispatchUnitName : "Multiple"}`;
    
                        if (rosters && rosters.length)
                            currentTitle = `${currentTitle} - ${rosters.length === 1 ? rosters[0].rosterName : "Multiple"}`;
                    }
    
                    // console.log(currentTitle);
                    setTitle(currentTitle);
                }

                if (rosterCandidates && rosterCandidates.length) {
                    let currentCandidateTitle = "";

                    rosterCandidates.forEach(x => {
                        currentCandidateTitle = `${currentCandidateTitle}${currentCandidateTitle !== "" ? "," : ""} ${x.candidateName}${x.positionName ? ` - ${x.positionName}` : ""}`;
                    });

                    setCandidateTitle(currentCandidateTitle);
                }

                if (refType === "RC" || refType === "R" || refType === "D") {
                    const newCandidates = [];

                    response[1].data.filter(rc => rc.candidateId).forEach(rc => {
                        if (!newData.length)
                            return;

                        newData.filter(d => d.rosterCandidateId === rc.rosterCandidateId).forEach(d => {
                            newCandidates.push({
                                ...rc.candidate,
                                rosterCandidateId: rc.rosterCandidateId,
                                rosterCandidateScheduleIds: d.rosterCandidateScheduleIds,
                                currentProjectName: project.projectName,
                                dispatchUnitId: rc.roster?.dispatchUnitId,
                                dispatchUnitName: rc.roster?.dispatchUnit?.dispatchUnitName,
                                rosterId: rc.rosterId,
                                rosterName: rc.roster?.rosterName,
                                locationId: rc.roster?.dispatchUnit?.locationId,
                                locationName: rc.roster?.dispatchUnit?.location?.lookupValue,
                                startDate: moment(d.startDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
                                endDate: moment(d.endDate, "YYYY-MM-DD").format("DD-MM-YYYY")
                            })
                        });
                    });

                    setCandidates([...newCandidates]);
                    setEstimationMessage(response[2].data.data[0]);
                    return;
                }
                
                setEstimationMessage(response[1].data.data[0]);
            })
            .catch((error) => {
                console.log(error.response);
            })
            .finally(() => {
                setLoading(false);
                setFirstLoad(false);
            });
    }, [project, dispatchUnits, rosters, rosterCandidates, customTitle]);

    // BEGIN ERROR MESSAGE HANDLER -----------------------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            onConfirm: () => {
                setErrorMessage(null);
                setErrorTitle(null);
            },
            message: errorMessage,
            title: errorTitle ?? errorTitleOnGenerate
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage, errorTitle]);
    // END ERROR MESSAGE HANDLER -------------------------------------------------------------------------------------------------------------------------------------------


    useEffect(() => {
        if (!project)
            return;

        if ((!dispatchUnits || dispatchUnits.length === 0) && (!rosters || rosters.length === 0) && (!rosterCandidates || rosterCandidates.length === 0))
            return;

        loadData();
    }, [project, dispatchUnits, rosters, rosterCandidates]);

    const columns = useMemo(() => {
        return [
            {
                id: "index",
                Header: "No.",
                disableSortBy: false,
                width: "50",
                Cell: ({ row }) => {
                    const {original} = row;
                    const index = `${!original.startEndHasWorkSchedule ? "*" : ""}${row.index + 1}`;

                    return (
                        <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                            <span className="text-left ml-2">{index}</span>
                        </div>
                    );
                },
                width: 40,
            },
            {
                id: 'startDate',
                Header: "Start Date",
                accessor: (row) => !row.nullableStartDate ? 'N/A' : moment(row.startDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
                disableSortBy: false,
                width: 50,
            },
            {
                id: 'endDate',
                Header: "End Date",
                accessor: (row) => !row.nullableEndDate ? 'N/A' : moment(row.endDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
                disableSortBy: false,
                width: 50,
            },
            {
                id: 'workDays',
                Header: "Work Days",
                disableSortBy: false,
                Cell: ({ row: { original } }) => {
                    return (
                        <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                        {
                            <span className={`text-left ml-2${original.inactiveDateExists ? " text-danger" : ""}`}>
                            {
                                original.workDays
                            }
                            {
                                original.inactiveDateExists ? "**" : ""
                            }
                            </span>
                        }
                        </div>
                    );
                },
                width: 60,
            },
            {
                id: 'availabilityStatus',
                Header: "Availability Status",
                accessor: (row) => {
                    return row?.availabilityStatus ?? "N/A";
                },
                disableSortBy: false,
                width: 80,
            },
            {
                id: 'estimateStatus',
                Header: "Estimate Status",
                accessor: (row) => {
                    return row?.estimateAvailabilityStatus ?? "N/A";
                },
                disableSortBy: false,
                width: 80,
            },
        ]
    }, [firstLoad, selected, data]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        flatRows,
        state,
        selectedFlatRows,
        toggleAllRowsSelected
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: defaultSort,
                // "selectedRowIds": {},
                "selectedRowIds": initialSelectedRows,
                "hiddenColumns": []
            },
            // defaultColumn
        },
        useFlexLayout,
        hooks => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    width: 20,
                    disableSortBy: true,
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => {
                        return (
                            <div>
                                {
                                    !singleSelection &&
                                    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} id="all" />
                                }
                            </div>
                        )
                    },
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => {
                        const { original } = row;

                        if (firstLoad)
                            row.isSelected = (selected?.some(s => s.rosterCandidateId === original.rosterCandidateId && s.startEndGroupId === original.startEndGroupId) || row.isSelected);

                        return (
                            <div>
                                {
                                    row.original != null &&
                                    <IndeterminateCheckbox
                                        {...row.getToggleRowSelectedProps()} id={row.original.startEndGroupId}
                                        onChange={(e) => {
                                            const selected = row.isSelected;
                                            // console.log(selected);

                                            if (singleSelection)
                                                toggleAllRowsSelected(false);

                                            row.toggleRowSelected(!selected);
                                        }}
                                    />
                                }
                            </div>
                        )
                    },
                },
                ...columns,
            ])
        },
        useSortBy,
        useRowSelect,
    );

    useEffect(() => {
        const filteredCandidates = candidates.filter(c => selectedFlatRows.filter(s => {
            return s.original.rosterCandidateId === c.rosterCandidateId && s.original.rosterCandidateScheduleIds.filter(d => c.rosterCandidateScheduleIds.filter(e => e === d).length).length;
        }).length);
        // console.log(filteredCandidates, selectedFlatRows);
        setSelectedCandidates([...filteredCandidates]);
    }, [selectedFlatRows]);


    const handleSendMessage = useCallback((apiurl, message, callback, loadingCallback, failedMessagesCallback, templateId) => {
        if (!message || !message.message || message.message === "")
            return;

        if (loadingCallback)
            loadingCallback(true);
        else
            setLoading(true);
        // console.log(selectedFlatRows);

        const body = {
            schedules: selectedFlatRows.map(({ original }) => {
                const {
                    rosterCandidateId,
                    startDate,
                    endDate,
                    rosterCandidateScheduleIds,
                } = original;

                return {
                    rosterCandidateId,
                    startDate,
                    endDate,
                    rosterCandidateScheduleIds,
                    isEstimation,
                }
            }),
            message: encodeURI(message.message),
            messagesMasterId: templateId
        };

        // console.log(body);

        api.post(apiurl, body)
            .then((response) => {
                if (response.data?.failed?.length > 0) {
                    setFailedMessages(response.data.failed);

                    if (failedMessagesCallback)
                        failedMessagesCallback([...response.data.failed]);

                    if (loadingCallback)
                        loadingCallback(false);
                    else
                        setLoading(false);

                    return;
                }

                setFailedMessages(null);

                if (callback)
                    callback();

                setShowModal(false);

                if (loadingCallback)
                    loadingCallback(false);
                else
                    setLoading(false);

                onSaved();
            })
            .catch((error => {
                console.log(error.response);

                if (loadingCallback)
                    loadingCallback(false);
                else
                    setLoading(false);
            }));
    }, [selectedFlatRows, isEstimation]);


    // BEGIN SEND CONFIRMATION BUTTON HANDLER -------------------------------------------------------------------------------------------------------------------------------------------
    const sendConfirmationBtn = useCallback(() => {

        // Validate data
        const invalidHrisPostitionList = flatRows.filter(s => !s.original.hrispositionMasterName);
        const invalidDateList = selectedFlatRows.filter(s => {

            const { original, values } = s;

            var invalidStartDate = !values.startDate || values.startDate == 'N/A';
            var invalidEndDate = !values.endDate || values.endDate == 'N/A';

            if (invalidStartDate || invalidEndDate)
                return true;
            return false;
        });

        // Show validation
        if (invalidDateList && invalidDateList.length > 0) {

            var slotNoList = invalidDateList.map(item => item.index);
            var slotNoWords = MakeWords(slotNoList);
            setErrorTitle(errorTitleOnGenerate);
            setErrorMessage(`${invalidDateMsg}. Please check schedule no.: ${slotNoWords}.`);
            return;
        }


        // Show invalid HRIS roster position
        if (invalidHrisPostitionList.some(s => s)) {
            var slotNoList = invalidHrisPostitionList.map(m => m.original.slotNo);
            var slotNos = MakeWords(slotNoList);
            var invalidMsg = `${invalidRosterPos} ${slotNos}`;

            setErrorTitle(errorTitleOnGenerate);
            setErrorMessage(invalidMsg);
            return;
        }



        //console.log('sendConfirmationBtn');
        //console.log('selectedFlatRows', selectedFlatRows);
        //return;

        // Default function to next step
        customConfirm && customConfirm.callback ?
            customConfirm.callback([...selectedFlatRows.map(({ original }) => ({ ...original, startDate: moment(original.startDate, "YYYY-MM-DD").format("DD-MM-YYYY"), endDate: moment(original.endDate, "YYYY-MM-DD").format("DD-MM-YYYY") }))]) :
            setShowModal(true);

    }, [data, selectedFlatRows, customConfirm, showModal]);
    // END SEND CONFIRMATION BUTTON HANDLER -------------------------------------------------------------------------------------------------------------------------------------------

    // BEGIN AUTO SELECT VALID DATA -------------------------------------------------------------------------------------
    const setSelection = useCallback(() => {
        if (!data || data.length == 0) {
            setInitialSelectedRows([]);
            return;
        }

        // Foreach data
        var selectedItemList = [];
        var selected = {};

        data.forEach((row, index) => {


            // If not data preselected
            //if (!preSelectDataId.includes(row.startEndGroupId)) {
            if (!preSelectDataId?.some(s => s.startEndGroupId == row.startEndGroupId && s.rosterCandidateId == row.rosterCandidateId)) {
                return;
            }


            // New item to append
            var newItem = { ...row, id: index };
            selectedItemList.push(newItem);



        });


        // Set id of selected item
        selectedItemList.forEach((item) => {
            selected[`${item.id}`] = true;
        })
        setInitialSelectedRows(selected);


    }, [data]);


    useEffect(() => {
        setSelection();
    }, [data]);
    // END AUTO SELECT VALID DATA -------------------------------------------------------------------------------------

    // BEGIN WARNING MESSAGE GENERATOR -------------------------------------------------------------------------------------
    useEffect(() => {
        if (!flatRows || flatRows.length == 0) {
            setValidationMessage(null);
            return;
        }

        const newErrorMessages = [];

        if (firstLoad == false && (!data || data.length == 0)) {
            newErrorMessages.push("Data not found or data end date may past today.");
        }

        // Invalid start/end date
        var invalidStartDate = flatRows.some(s => !s.values.startDate || s.values.startDate == 'N/A');
        var invalidEndDate = flatRows.some(s => !s.values.endDate || s.values.endDate == 'N/A');
        if (invalidStartDate || invalidEndDate) {
            newErrorMessages.push(`${invalidDateMsg}`);
        }

        if (flatRows.filter(d => !d.original.startEndHasWorkSchedule).length)
            newErrorMessages.push("*The following start date / end date is not marked as work day.");

        if (data.filter(d => d.inactiveDateExists).length)
            newErrorMessages.push("**The schedule is overlapping with inactive date(s).");


        // Show invalid HRIS roster position
        const invalidHrisPostitionList = flatRows.filter(s => !s.original.hrispositionMasterName);
        if (invalidHrisPostitionList.some(s => s)) {
            var slotNoList = invalidHrisPostitionList.map(m => m.original.slotNo);
            var slotNos = MakeWords(slotNoList);
            var invalidMsg = `${invalidRosterPos} ${slotNos}`;
            newErrorMessages.push(invalidMsg);
        }


        setValidationMessage(newErrorMessages.length > 0 ? newErrorMessages : null);
    }, [flatRows, data]);
    // END WARNING MESSAGE GENERATOR -------------------------------------------------------------------------------------

    return (
        <>
            {(loading) && <Loader />}
            {errorNotification.length > 0 && errorNotification}
            <div
                className="modal-header my-0"
            >
                <Container fluid>
                    <Row>
                        <Col lg={9}>
                            <h5
                                className="modal-title"
                            >
                                {customTitle ? customTitle : `Confirmation${title ?? ""}`}
                            </h5>
                        </Col>
                        <Col lg={3}>
                            <button
                                aria-label="Close"
                                className="close"
                                onClick={onClose}
                                type="button"
                            >
                                <span aria-hidden={true}>
                                    <i className="fas fa-times-circle text-black" />
                                </span>
                            </button>
                        </Col>
                    </Row>
                    {
                        candidateTitle && candidateTitle !== "" ?
                            <Row>
                                <Col lg={12}>
                                    <h6
                                        className="modal-title"
                                    >
                                        {candidateTitle}
                                    </h6>
                                </Col>
                            </Row>
                            :
                            null
                    }
                </Container>
            </div>
            <div className="modal-body">
                <div className="w-100 mb-1 d-flex flex-wrap align-items-center">
                    <IndeterminateCheckbox
                        id="estimate-only"
                        onChange={({target}) => {
                            const {checked} = target;
                            setIsEstimation(checked);
                            setCurrentMessage(checked ? estimationMessage : confirmationMessage);
                        }}
                        checked={isEstimation}
                    />
                    <span className="text-wrap">
                        Estimate Only
                    </span>
                </div>
                <div className="w-100 mb-1">
                    <span className="text-wrap">
                        <b>*Estimate Only</b> flag will send confirmation request for estimated schedule availability request to the user.
                    </span>
                </div>
                <Collapse isOpen={!!validationMessage} className="mb-4">
                    <div className="alert alert-warning" role="alert">
                    {
                        validationMessage?.map((d, index) => (
                            <>
                            {d}
                            {
                                index < validationMessage.length - 1 ? <><br /></> : null
                            }
                            </>
                        ))
                    }
                    </div>
                </Collapse>
                <Grid
                    height='calc(100vh - 436px)'
                    rows={rows}
                    tableProps={getTableProps()}
                    headerGroups={headerGroups}
                    tableBodyProps={getTableBodyProps()}
                    prepareRow={prepareRow}
                    rowStyle={(row) => {
                        return !row.original.startEndHasWorkSchedule || row.original.inactiveDateExists ? "yellow" : "";
                    }}
                />
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={() => {
                        onClose();
                    }}
                    type="button"
                >
                    Close
                </Button>
                {/* <Button
                    color="default"
                    onClick={() => setShowCancelModal(true)}
                    type="button"
                    disabled={!selectedFlatRows || !selectedFlatRows.length}
                >
                    Cancel
                </Button> */}
                <Button
                    color="info"
                    onClick={() => {
                        sendConfirmationBtn();
                    }}
                    type="button"
                    disabled={!selectedFlatRows || !selectedFlatRows.length}
                >
                    {customConfirm ? (customConfirm.confirmButtonTitle ?? "Confirm") : "Send Confirmation"}
                </Button>
            </div>
            {
                showCancelModal &&
                <SweetAlert
                    title="Cancel"
                    info
                    showCancel
                    cancelBtnText="Cancel"
                    confirmBtnText="Send Message"
                    confirmBtnBsStyle="success"
                    onConfirm={() => handleSendMessage("/rostercandidateaccommodation/cancel", currentCancelMessage, () => setShowCancelModal(false))}
                    onCancel={() => {
                        setShowCancelModal(false);
                        if (failedMessages?.length > 0)
                            setFailedMessages(null);
                        onClose();
                    }}
                >
                    {
                        failedMessages?.length > 0 &&
                        <div className="font-size-message-alert-custom text-danger mb-2">
                            <span>
                                <i>
                                    Message failed to send to:{" "}
                                    {
                                        failedMessages.map((item, idx) => {
                                            return `${item.candidateName}${idx < failedMessages.length - 1 ? ", " : "."}`;
                                        })
                                    }
                                </i>
                            </span>
                        </div>
                    }
                    <Input
                        type="textarea"
                        rows={6}
                        value={currentCancelMessage?.message ?? ""}
                        onChange={(e) => setCurrentCancelMessage({ ...currentCancelMessage, message: e.target.value })}
                    />
                </SweetAlert>
            }
            {
                showModal &&
                <EditorSMS
                    items={selectedCandidates}
                    onClose={() => {
                        setShowModal(false);
                        // if (failedMessages?.length > 0)
                        //     setFailedMessages(null);
                        // onClose();
                    }}
                    onCustomSave={(message, loadingCallback, failedMessagesCallback, templateId) => handleSendMessage("/rostercandidateaccommodation/confirm", { message }, () => setShowModal(false), loadingCallback, failedMessagesCallback, templateId)}
                    lookups={{
                        ...lookups
                    }}
                    defaultMessage={currentMessage}
                    sourcePage="roster"
                />
            }
            {
                dataIsEmpty &&
                <SweetAlert
                    title="Error!"
                    error
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    onConfirm={() => onClose()}
                >
                    Data not found.
                </SweetAlert>
            }
        </>
    )
};