/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
////import React, { useState } from 'react';
import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import { useParams } from "react-router-dom";
import api from "../../../../../services/api";
import qs from "qs";
import { SortsConverter, FiltersConverter } from "../../../../../helpers"
import { Container, Row, Col, Button, Label, FormGroup, Modal } from "reactstrap";
import { DropdownBox } from "../../../../dropdowns";
import moment from "moment";
import { ErrorNotification, ConfirmCancelAlert, ConfirmRollbackAlert, ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../../../alerts";
import { TooltipButton } from '../../../../inputs';
import fileDownload from "js-file-download";
import Loader from "../../../../loaders";
import ContractDialog from "../ContractDialog";
import ContractTemplate from "../ContractTemplate";
import DefinitivOnboardingEditor from '../DefinitivOnboardingEditor';
import SweetAlert from 'react-bootstrap-sweetalert';
import debounce from 'lodash.debounce';
import VacancyEditor from '../VacancyEditor';
import JobOrderApplicantList from '../JobOrderApplicantList';
import { DateComparator, DateValueFormatter, MakeWordsValueGetter, ImportDialogMessageCellRenderer } from '../../../../../helpers/AgGridHelper';
import DropdownButton from '../../../../dropdowns/dropdownbutton';
import { IndeterminateCheckbox } from '../../../../react-table/controls';
import { useAppContext } from '../../../../../contexts/AppProvider';
import { ComboBox } from '../../../../dropdowns/index';
import EditDialog from '../EditDialog';
import TalentSearchDialog from '../TalentSearchDialog';
import JobOrderCancellationDialog from '../JobOrderCancellationDialog';
import JobOrderCandidateWithdrawalDialog from '../JobOrderCandidateWithdrawalDialog';
import StatusTooltip from './tooltips/StatusTooltip';

const timeoutLength = 1500;

export default function EditJobOrderGridV2(props) {
    const {
        item,
        onClose,
        candidateIdForCvPreview,
        setCandidateIdForCvPreview,
        setRefreshHandle,
        itemInEditRef = null,
        openRef,
        setOpen,
        setLoading,
        loadingRef,
        idRef,
        callFrom,
        setPdrsRequest
    } = props;

    const params = useParams();
    const [copiedIndex, setCopiedIndex] = React.useState(null);
    useEffect(() => {
        if (copiedIndex !== null) {
            const timeoutId = setTimeout(() => {
                setCopiedIndex(null);
            }, timeoutLength);

            return () => clearTimeout(timeoutId);
        }
    }, [copiedIndex]);
    const [data, setData] = React.useState([]);
    const [createNew, setCreateNew] = React.useState(false);
    const [pageSize, setPageSize] = React.useState(50);
    const [deleteAlert, setDeleteAlert] = React.useState([]);
    const [cancelAlert, setCancelAlert] = React.useState([]);
    const [itemInEdit, setItemInEdit] = React.useState({ jobOrderCandidates: [] });
    const [newAssignment, setNewAssignment] = React.useState(null);
    //const [open, setOpen] = React.useState(true);
    const [missingMilestoneAlert, setMissingMilestoneAlert] = React.useState([]);
    //const [recruitmentPeople, setRecruitmentPeople] = React.useState([]);
    const [recruitmentPeoples, setRecruitmentPeoples] = React.useState([]);
    const [recruitmentPeopleEndpoint, setRecruitmentPeopleEndpoint] = React.useState("/recruitmentperson/all");
    const [jobOrderCandidateDialogOpen, setJobOrderCandidateDialogOpen] = React.useState(null);
    const [contractDialogOpen, setContractDialogOpen] = React.useState(null);
    const [contractTemplateOpen, setContractTemplateOpen] = React.useState(null);
    const [validationItem, setValidationItem] = useState(null);
    const [confirmationItem, setConfirmationItem] = useState(null);
    const [definitivOnboardingCandidates, setDefinitivOnboardingCandidates] = useState([]);
    const [definitivOnboardingCompany, setDefinitivOnboardingCompany] = useState(null);
    const [definitivOnboardingStartDate, setDefinitivOnboardingStartDate] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [openVacancySetting, setOpenVacancySetting] = useState(false);
    const [openApplicantList, setOpenApplicantList] = useState(false);
    const [isSelectAll, setIsSelectAll] = React.useState(false);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [showTalentSearchDialog, setShowTalentSearchDialog] = useState(false);
    const [validationMessage, setValidationMessage] = React.useState(null);
    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const [showCandidateWithdrawDialog, setShowCandidateWithdrawDialog] = useState(null);


    // BEGIN REF -----------------------------------------------------------------------------------------------------------------------------
    const open = useMemo(() => openRef.current, [openRef]);
    const loading = useMemo(() => loadingRef.current, [loadingRef]);
    const jobOrderId = useMemo(() => item ? item.jobOrderId : idRef ? idRef.current : null, [idRef, item]);

    useEffect(() => {
        itemInEditRef.current = itemInEdit;
    }, [itemInEdit]);
    // END REF -----------------------------------------------------------------------------------------------------------------------------


    const [showWithdrawn, setShowWithdrawn] = useState(false);
    const [showCancelled, setShowCancelled] = useState(false);

    // Error message variables
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorTitle, setErrorTitle] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);

    const errorMessageOpening = `Error occured when processing the request: `;
    const errorTitleOnGenerate = `Job Order Grid Edit`;

    const defaultSort = React.useMemo(() => [{ id: "lrfCreationDate", desc: true }, { id: "projectName", desc: false }],
        []
    );
    const [agGridSort, setAgGridSort] = useState(['asc', 'desc']);

    // Page vars
    const apiurl = "joborder";

    const [versionCounter, setVersionCounter] = useState(0);
    const gridRef = useRef();
    const [gridOptions, setGridOptions] = useState(null);


    useEffect(() => {
        //setLoading(true);
        var url = "";
        if (itemInEdit?.recruitmentRequest?.isConfidential) {
            //console.log('isConfidential', itemInEdit?.recruitmentRequest?.isConfidential);
            //url = `/lookup/recruitmentpeoplecf`;
            setRecruitmentPeopleEndpoint("/recruitmentperson/all-cf");
        } else {
            //console.log('isConfidential', itemInEdit?.recruitmentRequest?.isConfidential);
            //url = `/lookup/recruitmentpeople`;
            setRecruitmentPeopleEndpoint("/recruitmentperson/all");
        }
        //var apiCalls = [
        //    api.get(url),
        //];

        //Promise.all(apiCalls)
        //    .then((response) => {
        //        if (response[0]) setRecruitmentPeople(response[0].data);
        //    })
        //    .catch((error) => console.error("error: ", error))
        //    .finally(() => {
        //        //setLoading(false);
        //    });

    }, [itemInEdit?.recruitmentRequest?.isConfidential]);




    const resizeColumns = useCallback(() => {
        if (!gridRef?.current?.columnApi || data?.length === 0)
            return;

        const allColumnIds = [];
        gridRef.current.columnApi.getColumns().forEach((column) => {
            // console.log(column, column.getId());
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }, [data, gridRef]);

    useEffect(() => {
        // Refresh datasource
        if (gridRef && gridRef.current && gridRef.current.api) {
            const datasource = getServerSideDatasource();
            gridRef.current.api.setServerSideDatasource(datasource);
        }

        // Refresh data
        refreshCache([]);
    }, [gridRef, params.action, params.requestId]);

    const onSelectionChanged = useCallback(debounce((event) => {
        var selectedNodes = event.api.getSelectedNodes();
        setIsSelectAll(selectedNodes.length === data.length ? true : selectedNodes.length > 0 ? null : false);
        setSelectedRows([...selectedNodes]);
    }, 200), [gridRef, data]);

    const selectAll = useCallback(() => {
        var statusTo = isSelectAll ? false : true;
        gridRef.current.api.forEachNode(function (node) {
            node.setSelected(statusTo);
        });
    }, [gridRef, data, isSelectAll]);

    const defaultColDef = useMemo(() => ({
        //flex: 2,
        sortable: true,
        resizable: true,
        //filter: true,
        editable: false,
        //filter: 'agSetColumnFilter',
        filter: 'agTextColumnFilter',
        cellClass: 'ag-cell-vcenter',
        filterParams: {
            buttons: ['reset'],
            debounceMs: 200,
            excelMode: 'mac',
        },
        floatingFilter: true,
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        suppressMenu: true,
    }), []);

    const columnDefs = useMemo(() => [
        {
            field: "jobOrderCandidateId",
            headerName: "ID",
            flex: 1,
            headerCheckboxSelection: false,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
        },
        {
            headerName: "Roster Slot No.",
            field: "rosterCandidateNumber",
            flex: 1,
            cellRenderer: (params) => {
                const { data: rowData } = params;
                return rowData.rosterCandidate ? (rowData.rosterCandidate.relatedRosterCandidateId ? `${rowData.rosterCandidate.relatedRosterCandidateNumber}.${rowData.rosterCandidate.rosterCandidateNumber}` : `${rowData.rosterCandidate.rosterCandidateNumber}`) : null;
            },
        },
        {
            headerName: "Employed Position",
            field: "position.positionName",
            flex: 2,
        },
        {
            headerName: "Talent Name",
            field: "candidateName",
            flex: 2,
        },
        {
            headerName: "CV / Resume",
            field: "candidate.candidateDocumentIsHavingCv",
            cellRenderer: (params) => {
                const { data: rowData } = params;

                const isHavingCv = rowData?.candidate?.candidateDocumentIsHavingCv;

                return (<div style={{ width: "100%" }} className={`d-flex flex-wrap justify-content-center align-items-center`}>
                    {
                        isHavingCv &&
                        <a className="text-info" href='javascript:;' onClick={() => { setCandidateIdForCvPreview(rowData.candidateId); }}><span className='fas fa-check-circle text-success'></span> View CV / Resume</a>
                    }
                </div>);
            }
        },
        {
            headerName: "Mobile",
            field: "candidate.mobilePhoneNumber",
            flex: 1,
        },
        {
            headerName: "Email",
            field: "candidate.email",
            flex: 2,
        },
        {
            headerName: "Start Date",
            field: "startDate",
            filter: 'agDateColumnFilter',
            valueFormatter: DateValueFormatter,
            flex: 1,
        },
        {
            headerName: "End Date",
            field: "endDate",
            filter: 'agDateColumnFilter',
            valueFormatter: DateValueFormatter,
            flex: 1,
        },
        {
            headerName: "Latest Milestone Date",
            field: "latestJobOrderMilestoneDate",
            filter: 'agDateColumnFilter',
            valueFormatter: DateValueFormatter,
            flex: 1,
        },
        {
            headerName: "Latest Milestone",
            field: "latestJobOrderMilestoneName",
            flex: 2,
        },
        {
            headerName: "Status",
            field: "status.lookupValue",
            cellClass: "px-0 py-0 text-center ag-cell-vcenter",
            cellRenderer: (params) => {
                const { data: rowData } = params;
                const withdrawn = rowData.status?.lookupValue === "Withdrawn";
                const unsuitable = rowData.status?.lookupValue === "Candidate Unsuitable";
                const style = withdrawn ? "red text-white" : unsuitable ? "maroon text-white" : "text-dark";

                return (
                    <div className={`d-block w-100-custom ${style}`}>
                        {rowData.status?.lookupValue}
                    </div>
                );
            },
            suppressSizeToFit: true,
            flex: 1.7,
            tooltipComponent: (props) => props.node.data.withdrawStatus ? StatusTooltip(props) : (<></>),
            tooltipField: `status.lookupValue`,
        },
        {
            headerName: "Point of Hire",
            field: "pointOfHire",
            flex: 1,
            cellRenderer: (params) => {
                var { data } = params;
                if (!data?.pointOfHire) return null;
                return <div className="d-block w-100-custom text-truncate">{data?.pointOfHire}</div>;
            }
        },
        {
            field: "button",
            headerName: "Action",
            pinned: 'right',
            filter: '',
            width: 185,
            filterParams: {
                name: 'action'
            },

            sortable: false,
            floatingFilter: false,
            cellClass: "actions-button-cell ag-cell-vcenter",
            cellRenderer: (params) => {
                const { data: rowData } = params;
                // console.log('rowData', rowData);
                var index = 0;
                //var visible = !itemInEdit?.isCancelled;
                var children = [
                    {
                        title: "Edit",
                        icon: "fas fa-edit",
                        visible: !itemInEdit?.isCancelled,
                        onClick: () => {
                            setJobOrderCandidateDialogOpen({ ...rowData });
                        }
                    },
                    {
                        title: "Milestone Progress",
                        icon: "fas fa-folder-open",
                        visible: true,
                        onClick: () => {
                            viewJobOrderCandidate(rowData.jobOrderCandidateId, index);
                        }
                    },
                    {
                        title: "Contract Options",
                        icon: "fas fa-file-signature",
                        visible: !itemInEdit?.isCancelled,
                        onClick: () => {
                            setContractDialogOpen({ ...rowData, projectName: props.item.projectName, projectId: props.item.projectId });
                        }
                    },
                    {
                        title: "Download Contract (Word)",
                        icon: "fas fa-file-word",
                        visible: !itemInEdit?.isCancelled,
                        disabled: !rowData.generatedContractId,
                        onClick: () => {
                            downloadAttachment(rowData.generatedContractId, "docx")
                        }
                    },
                    {
                        title: "Download Contract (PDF)",
                        icon: "fas fa-file-pdf",
                        visible: !itemInEdit?.isCancelled,
                        disabled: !rowData.generatedContractId,
                        onClick: () => {
                            downloadAttachment(rowData.generatedContractId, "pdf")
                        }
                    },
                    {
                        title: "Send SMS",
                        icon: "fas fa-comment",
                        visible: !itemInEdit?.isCancelled,
                        onClick: () => {
                            viewCandidateSMS(rowData.candidateId, rowData.jobOrderCandidateId)
                        }
                    },
                    {
                        title: "Send Email",
                        icon: "fas fa-envelope-open",
                        visible: !itemInEdit?.isCancelled,
                        onClick: () => {
                            viewCandidateEmail(rowData.candidateId)
                        }
                    },
                    {
                        title: "View Talent",
                        icon: "fas fa-cog",
                        visible: !itemInEdit?.isCancelled,
                        onClick: () => {
                            viewCandidate(rowData.candidateId)
                        }
                    },
                    {
                        title: "Assign to Roster",
                        icon: "fas fa-calendar-alt",
                        visible: !itemInEdit?.isCancelled,
                        disabled: !rowData.candidateId || !rowData.rosterCandidate || rowData.candidateAssigned,
                        onClick: () => {
                            assignToRoster(rowData)
                        }
                    },
                    {
                        title: "Withdraw",
                        icon: "fas fa-ban",
                        visible: !itemInEdit?.isCancelled,
                        disabled: rowData.candidateAssigned,
                        onClick: () => {
                            setShowCandidateWithdrawDialog(rowData)
                        }
                    },
                    {
                        title: "Unsuccessful",
                        icon: "fas fa-eraser",
                        visible: !itemInEdit?.isCancelled,
                        disabled: rowData.candidateAssigned,
                        onClick: () => {
                            unsuccessful(rowData)
                        }
                    },
                    {
                        title: "On Hold",
                        icon: "fas fa-hand-paper",
                        visible: !itemInEdit?.isCancelled,
                        disabled: rowData.candidateAssigned,
                        onClick: () => {
                            hold(rowData)
                        }
                    },
                    {
                        title: "Clear Talent",
                        icon: "fas fa-trash-alt",
                        visible: !itemInEdit?.isCancelled,
                        color: "warning",
                        onClick: () => {
                            handleCandidateDelete(index, rowData)
                        }
                    }
                ];

                var childrenOfSearchTalent = [
                    {
                        title: "Search Talent",
                        icon: "fas fa-search",
                        visible: !itemInEdit?.isCancelled,
                        onClick: () => {
                            viewSearchCandidate(rowData.jobOrderCandidateId, index)
                        }
                    },
                    {
                        title: "Edit",
                        icon: "fas fa-edit",
                        visible: !itemInEdit?.isCancelled,
                        onClick: () => {
                            setJobOrderCandidateDialogOpen({ ...rowData });
                        }
                    },
                ];


                return rowData.candidate != null && rowData.status?.lookupValue !== "Withdrawn" && rowData.status?.lookupValue !== "Candidate Unsuitable" ?
                    <>
                        <DropdownButton title="Progress" icon="fas fa-folder-open" children={children} size="xsm" buttonGroupClassName="btn-block w-100-custom" onClick={() => viewJobOrderCandidate(rowData.jobOrderCandidateId, index)}
                            title2="" icon2="fas fa-edit"
                            onClick2={() => {
                                setJobOrderCandidateDialogOpen({ ...rowData });
                            }}
                        ></DropdownButton>
                    </>
                    :
                    rowData.status?.lookupValue !== "Withdrawn" && rowData.status?.lookupValue !== "Candidate Unsuitable" && !itemInEdit?.isCancelled ?
                        <>
                            <DropdownButton title="Search Talent" icon="fas fa-search" children={childrenOfSearchTalent} size="xsm" buttonGroupClassName="btn-block w-100-custom" color="secondary"
                                onClick={() => viewSearchCandidate(rowData.jobOrderCandidateId, index)}
                            >
                            </DropdownButton>
                        </>
                        :
                        null;
            },
            cellRendererParams: {
                clicked: function (field) {
                    alert(`${field} was clicked`);
                }
            },
            additionalParams: () => ({
                copiedIndex: copiedIndex,
                setCopiedIndex: setCopiedIndex,

                itemInEdit: itemInEdit,
                setItemInEdit: setItemInEdit,
            }),
        },
    ], [selectedRows, itemInEdit]);

    const loadingOverlayComponent = useMemo(() => Loader, []);

    const onCellEditRequest = useCallback((event) => {
        // console.log("on cell edit request: ", { event });
    }, []);

    const refreshCache = useCallback((route) => {
        //console.log('gridRef', gridRef);
        if (!gridRef || !gridRef.current || !gridRef.current.api)
            return;

        setVersionCounter(versionCounter + 1);
        gridRef.current.api.purgeServerSideCache();

    }, [versionCounter, params]);

    const getServerSideDatasource = useCallback(() => {
        return {
            getRows: (params2) => {
                setLoading(true);
                var { request } = params2;
                var { filterModel, sortModel } = request;

                var thisFilters = FiltersConverter(filterModel);
                var thisSorts = SortsConverter(sortModel);
                var selectedSorts = (thisSorts.length ? thisSorts : defaultSort);

                const queryString = qs.stringify({
                    filters: thisFilters,
                    sortBy: selectedSorts,
                }, {
                    allowDots: true
                });
                const url = `/${apiurl}/${jobOrderId}/${params2.request.startRow ?? 0}/${pageSize}${params.requestId ? ("/" + params.requestId + "/" + params.action) : ""}${queryString ? `?${queryString}` : ""}`;
                api.get(url)
                    .then((response) => {
                        // console.log('response', response);
                        var successData = {
                            rowData: response.data.data,
                            //rowCount: 100,
                            rowCount: response.data.total,
                        };

                        params2.success(successData);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('error on getServerSideDatasource.getRows', error);
                        params2.fail();
                        setLoading(false);
                    });
            },
        };
    }, [params]);

    const updateSingleRow = useCallback((jobOrderCandidateId, callback = null) => {
        var gridApi = gridRef.current.api;
        gridApi.forEachNode(rowNode => {
            if (jobOrderCandidateId == rowNode.data.jobOrderCandidateId) {
                const url = `/jobordercandidate/${jobOrderCandidateId}`;
                setLoading(true);
                api.get(url)
                    .then(({ data }) => {
                        // console.log('data to set', 'data', data);
                        rowNode.setData(data);
                        if (callback) callback();
                    }).catch((error) => {
                        console.error("error: ", { error });
                    }).finally(() => setLoading(false))
                    ;
            }
        });
    }, [gridRef]);

    // END AG GRID FUNCTIONS ---------------------------------------------------------------------------------------------------------------------------

    // useeffect companies 
    // useEffect(() => {
    //     console.log('companies', companies);
    // }, [companies]);

    // useEffect(() => {
    //     console.log('recruitmentPeople', recruitmentPeople);
    // }, [recruitmentPeople]);

    const loadData = (needLoading = true, fastReload = false) => {
        if (!jobOrderId)
            return;

        setLoading(needLoading);

        // Api calls fast reload
        var apiCalls = [
            api.get(`/joborder/${jobOrderId}?showWithdrawn=${showWithdrawn}&showCancelled=${showCancelled}`), // 0
        ];

        // If complete reload
        if (!fastReload) {
            apiCalls = [
                ...apiCalls,
                //api.get(`/lookup/recruitmentpeople`), // 1
                api.get(`/lookup/company`), // 1
                //api.get(`/recruitmentrequest/${jobOrderId}`), // 3
            ];
        }

        Promise.all(apiCalls)
            .then((response) => {
                if (needLoading) setLoading(false);
                const jobOrder = response[0].data;
                //var lrf_ = response[3].data;
                var newData = jobOrder.jobOrderCandidates.map((v) => {
                    return {
                        ...v,
                        startDate: !v.startDate ? null : moment(v.startDate).startOf('date').toDate(),
                        endDate: !v.endDate ? null : moment(v.endDate).startOf('date').toDate(),
                        latestJobOrderMilestoneDate: !v.latestJobOrderMilestoneDate ? null : moment(v.latestJobOrderMilestoneDate).startOf('date').toDate(),
                        latestJobOrderMilestoneName: v.candidate != null ? v.latestJobOrderMilestone != null ? v.latestJobOrderMilestone.milestoneName : "New" : ""
                    };
                });
                setItemInEdit(jobOrder);
                setData(newData);
                //if (response[1]) setRecruitmentPeople(response[1].data);
                if (response[1]) setCompanies(response[1].data);
                //setLrf(lrf_);
            })
            .catch((error) => {
                if (needLoading) setLoading(false);
                console.error("error: ", error);
            })
            .finally(() => {
            });
    }

    //useEffect(() => {
    //    console.log('setRefreshHandle', setRefreshHandle);
    //    console.log('loadData', loadData);
    //    if (setRefreshHandle) setRefreshHandle(loadData);
    //}, [setRefreshHandle, loadData]);

    // useEffect(() => {
    //     console.log('itemInEdit', itemInEdit);
    // }, [itemInEdit])

    useEffect(() => {
        if (!jobOrderId)
            return;

        loadData();
    }, [jobOrderId, newAssignment, item, showWithdrawn, showCancelled]);

    const cancelJobOrder = useCallback((cancelObj) => {
        setShowCancelDialog(false);

        const success = (response) => {
            setLoading(false);
            onClose();
        };
        const failure = (error) => {
            setErrorMessage(`${errorMessageOpening}${error.response?.data ?? ""}`);
            console.error(error);
            setLoading(false);
        };
        const events = {
            onConfirm: () => {
                setCancelAlert([]);
                setLoading(true);
                api.post(`joborder/cancel/${jobOrderId}`, JSON.stringify(cancelObj))
                    .then(success)
                    .catch(failure)
                ;
            },
            onCancel: () => setCancelAlert([])
        }
        setCancelAlert([<ConfirmCancelAlert {...events} />])
    }, [item?.jobOrderId, onClose]);

    const cancelJobOrderSlots = useCallback(() => {
        const success = () => {
            setLoading(false);
            loadData(true);
        };
        const failure = (error) => {
            setErrorMessage(`${errorMessageOpening}${error.response?.data ?? ""}`);
            console.error({error});
            setLoading(false);
        };
        const events = {
            onConfirm: () => {
                setCancelAlert([]);
                setLoading(true);
                const dataToSend = selectedRows.map(r => r.data.jobOrderCandidateId);

                const url = `joborder/cancelslots/${jobOrderId}`;
                api.post(url, JSON.stringify(dataToSend))
                    .then(success)
                    .catch(failure)
                ;
            },
            onCancel: () => setCancelAlert([])
        }

        if (!selectedRows?.length)
            return;

        setCancelAlert([<ConfirmCancelAlert {...events} />]);
    }, [selectedRows, loadData]);

    const rollbackJobOrder = useCallback(() => {
        const success = (response) => {
            setLoading(false);
            onClose();
        };
        const failure = (error) => {
            setErrorMessage(`${errorMessageOpening}${error.response?.data ?? ""}`);
            console.error(error);
        };
        const events = {
            onConfirm: () => {
                setCancelAlert([]);
                setLoading(true);

                let url = `joborder/rollback/${jobOrderId}`;
                api.post(url)
                    .then(success)
                    .catch(failure)
                    .finally(() => setLoading(false))

            },
            onCancel: () => setCancelAlert([])
        }
        setCancelAlert([<ConfirmRollbackAlert {...events} />])
    }, [item?.jobOrderId, onClose]);

    const downloadAttachment = React.useCallback((id, type) => {
        setLoading(true);
        let url = `generatedcontract/download/${id}/${type}`;
        api.get(url, { responseType: 'blob' })
            .then(blob => {
                // console.log(blob);
                var filename = /(?<=attachment;.*filename=").*(?=";)/.exec(blob.headers["content-disposition"])[0]
                fileDownload(blob.data, filename);
            }).catch(error => {
                console.error(error)
            }).finally(() => setLoading(false))
    }, [])

    const handleCandidateDelete = (index, item) => {
        const success = () => {
            setDeleteAlert([<DeleteSuccessAlert onConfirm={() => setDeleteAlert([])} />]);

            setNewAssignment(item);
            setLoading(false);
        };
        const failure = () => {
            setDeleteAlert([<DeleteFailedAlert onConfirm={() => setDeleteAlert([])} />]);
            setLoading(false);
        };
        const events = {
            onConfirm: () => {
                setDeleteAlert([]);
                setLoading(true);
                // api.delete(`/joborder/${jobOrderId}`).then(success).catch(failure)
                var updatedItem = {
                    ...item,
                    candidateName: null,
                    candidateId: null,
                    latestJobOrderMilestoneId: null,
                    latestJobOrderMilestoneDate: null,
                    jobOrderCandidateMilestones: item.jobOrderCandidateMilestones?.map(o => ({ ...o, milestoneDate: null }))
                };

                api.put(`/jobordercandidate/${updatedItem.jobOrderCandidateId}`, JSON.stringify(updatedItem))
                    .then(success)
                    .catch(failure);
            },
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert {...events} />])
    }

    const viewSearchCandidate = (jobOrderCandidateId, index) => {
        setOpen(false);
        setNewAssignment(null);
        props.onViewSearchCandidate(jobOrderCandidateId, index, setOpen, setNewAssignment);
    }

    const viewJobOrderCandidate = (jobOrderCandidateId, index) => {
        // setOpen(false);
        setNewAssignment(null);
        props.onViewJobOrderCandidate(jobOrderCandidateId, index, setOpen, setNewAssignment);
    }

    const viewCandidate = (id) => {
        //setLoading(true);
        // console.log("CandidateId: ", id);
        setOpen(false);
        props.onViewCandidate(id, setOpen)
    }

    const viewCandidateEmail = (id) => {
        //setLoading(true);
        setOpen(false)
        props.onViewCandidateEmail(id, setOpen)
    }

    const viewCandidateEmails = (items) => {
        //setLoading(true);
        setOpen(false)
        props.onViewCandidateEmails(items, setOpen)
    }

    const viewCandidateSMS = (id, jobOrderCandidateId) => {
        //setLoading(true);
        setOpen(false)
        props.onViewCandidateSMS(id, jobOrderCandidateId, setOpen)
    }

    const viewBulkCandidateSMS = (items) => {
        // console.log('viewBulkCandidateSMS');
        // console.log('items', items);
        //setLoading(true);
        setOpen(false)
        props.onViewBulkCandidateSMS(items, setOpen)
    }

    const viewBulkMilestone = (items, joc) => {
        //setLoading(true);
        //setOpen(false)
        props.onViewBulkMilestone(items, joc, setOpen)
    }

    const assignToRoster = (item) => {
        const { jobOrderCandidateMilestones } = item;
        jobOrderCandidateMilestones.sort((a, b) => a.orderingIndex > b.orderingIndex ? -1 : a.orderingIndex < b.orderingIndex ? 1 : 0);

        setConfirmationItem({
            title: "Roster Assigning Confirmation",
            jobOrderCandidateId: item.jobOrderCandidateId,
            candidateId: item.candidateId,
            message: `Do you want to set the milestone to the last step (${jobOrderCandidateMilestones[0]?.milestoneName})?`,
            urlParameter: `assigntoroster?id=${item.jobOrderCandidateId}&candidateid=${item.candidateId}`
        });
    };

    const handleConfirmed = useCallback((updateMilestone) => {
        const currentConfirmationItem = JSON.parse(JSON.stringify(confirmationItem));
        setConfirmationItem(null);
        setLoading(true);
        // console.log({ confirmationItem, currentConfirmationItem, updateMilestone });

        const { jobOrderCandidateId, candidateId } = currentConfirmationItem;
        // console.log({ confirmationItem, currentConfirmationItem, updateMilestone, jobOrderCandidateId, candidateId });

        api.put(`/jobordercandidate/${confirmationItem.urlParameter}&updateMilestone=${updateMilestone}`)
            .then(() => updateSingleRow(jobOrderCandidateId))
            .catch((error) => {
                setValidationItem({
                    type: "error",
                    title: "System Error",
                    jobOrderCandidateId: jobOrderCandidateId,
                    message: "An Error Occured, please contact Admin.",
                });
                setLoading(false);
            })
            .finally(() => setLoading(false))
            ;
    }, [confirmationItem]);

    const withdraw = (item) => {
        // console.log(item);
        setValidationItem({
            type: "warning",
            title: "Withdrawal Confirmation",
            jobOrderCandidateId: item.jobOrderCandidateId,
            message: `Are you sure you want to withdraw ${item.candidateName}?`,
            urlParameter: "withdraw",
            body: {
                jobOrderCandidateId: item.jobOrderCandidateId,
                withdrawStatusId: item.withdrawStatusId,
                withdrawComment: item.withdrawComment,
            },
            callback: () => setShowCandidateWithdrawDialog(null),
        });
    }

    const unsuccessful = (item) => {
        setValidationItem({
            type: "warning",
            title: "Unsuccessful Confirmation",
            jobOrderCandidateId: item.jobOrderCandidateId,
            message: `Are you sure you want to cancel ${item.candidateName}?`,
            urlParameter: "unsuccessful"
        });
    }

    const hold = (item) => {
        setValidationItem({
            type: "warning",
            title: "On Hold Confirmation",
            jobOrderCandidateId: item.jobOrderCandidateId,
            message: `Are you sure you want to put ${item.candidateName} on hold?`,
            urlParameter: "hold"
        });
    }

    const handleCancellation = useCallback(() => {
        const currentValidationItem = { ...validationItem };
        setValidationItem(null);
        setLoading(true);

        api.put(`/jobordercandidate/${validationItem.urlParameter}?id=${validationItem.jobOrderCandidateId}`, validationItem?.body ? JSON.stringify(validationItem.body) : undefined)
            .then(() => {
                //updateSingleRow(jobOrderCandidateId, () => loadData(false));
                if (validationItem?.callback)
                    validationItem.callback();

                loadData(true, true);
            })
            .catch((error) => {
                console.error(error);
                setValidationItem({
                    type: "error",
                    title: "System Error",
                    jobOrderCandidateId: currentValidationItem.jobOrderCandidateId,
                    message: "An Error Occured, please contact Admin.",
                });
                setLoading(false);
            })
            .finally(() => {
                setValidationItem(null);
            })
            ;
    }, [validationItem]);

    const handleSendToDefinitivOnboarding = useCallback(() => {
        if (!definitivOnboardingCandidates?.length || !definitivOnboardingCompany?.lookupId || !definitivOnboardingStartDate)
            return;

        setLoading(true);

        const data = {
            candidateIds: definitivOnboardingCandidates.map(c => c.candidateId),
            designatedCompanyId: definitivOnboardingCompany.lookupId,
            hireDate: definitivOnboardingStartDate
        };

        api.post(`/${apiurl}/definitivonboarding`, JSON.stringify(data))
            .then(() => loadData())
            .catch((error) => {
                console.error({ error });
                setLoading(false);
            })
            .finally(() => {
                setValidationItem(null);
            })
            ;
    }, [definitivOnboardingCandidates, definitivOnboardingCompany, definitivOnboardingStartDate]);


    const updateStatus = (callback = null) => {
        return api.put(`/${apiurl}/complete/${itemInEdit.jobOrderId}/${itemInEdit.completionDate === null}`)
            .then((response) => {
                setItemInEdit({ ...itemInEdit, completionDate: response.data === "" ? null : response.data })
            })
            .catch((error) => console.error(error))
            .finally(() => {
                setLoading(false);
                if (callback) {
                    callback();
                }
            });
    };

    useEffect(() => {
        if (!open)
            return;

        loadData();
    }, [open]);


    // BEGIN HANDLING RECRUITMENT PERSON COMPATIBILITY -------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {

        api.get(`/recruitmentperson/get-all`)
            .then(({ data }) => {
                setRecruitmentPeoples(data);
            })
            .catch((error) => {
                console.error({ error });
                //setLoading(false);
            })
            .finally(() => {

            })
            ;

    },[])

    const selectedRecruitmentPerson = useMemo(() => {
        // console.log('selectedRecruitmentPerson');
        if (!itemInEdit?.recruitmentPerson || !itemInEdit?.recruitmentPerson?.lookupId)
            return null;

        var recruitmentPersonId = itemInEdit?.recruitmentPerson?.lookupId;
        var recruitmentPerson = recruitmentPeoples.map(m => ({
            ...m,
            lookupId: m.recruitmentPersonId,
            lookupValue: m.displayName,
        })).find(r => r.recruitmentPersonId === recruitmentPersonId);
        //recruitmentPerson = recruitmentPerson ? { ...recruitmentPerson } : null;
        // console.log('itemInEdit?.recruitmentPerson', itemInEdit?.recruitmentPerson);
        // console.log('recruitmentPersonId', recruitmentPersonId);
        // console.log('recruitmentPerson', recruitmentPerson);
        return recruitmentPerson;

    }, [itemInEdit?.recruitmentPerson?.lookupId, recruitmentPeoples]);

    // useEffect(() => {
    //     console.log('recruitmentPeoples', recruitmentPeoples);
    // }, [recruitmentPeoples]);

    // useEffect(() => {
    //     console.log('selectedRecruitmentPerson', selectedRecruitmentPerson);
    // }, [selectedRecruitmentPerson]);

    // END HANDLING RECRUITMENT PERSON COMPATIBILITY -------------------------------------------------------------------------------------------------------------------------------------------------------------------------


    //useEffect(() => {
    //    //if (itemInEdit?.recruitmentPerson && itemInEdit?.recruitmentPerson?.lookupId && !itemInEdit.recruitmentPerson.userProfileId) {
    //    //    var newRecruitmentPerson = {
    //    //        ...itemInEdit.recruitmentPerson,
    //    //        lookupId: itemInEdit?.recruitmentPerson?.userProfileId,
    //    //        lookupValue: itemInEdit?.recruitmentPerson?.nameAndEmail,
    //    //    };
    //    //    setItemInEdit({
    //    //        ...itemInEdit,
    //    //        recruitmentPerson: newRecruitmentPerson
    //    //    });
    //    //}
    //    console.log('itemInEdit?.recruitmentPerson', itemInEdit?.recruitmentPerson);
    //}, [itemInEdit?.recruitmentPerson])


    // BEGIN ERROR MESSAGE HANDLER -----------------------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            onConfirm: () => {
                setErrorMessage(null);
                setErrorTitle(null);
            },
            message: errorMessage,
            title: errorTitle ?? errorTitleOnGenerate
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage, errorTitle]);
    // END ERROR MESSAGE HANDLER -------------------------------------------------------------------------------------------------------------------------------------------

    const onFirstDataRendered = useCallback((params) => {
        const { api: gridApi } = params;
        gridApi.forEachNode((node) => {
            // console.log({node});
            if (node.data && selectedRows.filter(r => r.data.jobOrderCandidateId === node.data.jobOrderCandidateId).length > 0)
                node.setSelected(true);
            else
                node.setSelected(false);
        });
    }, [selectedRows]);

    return (
        <>
            {deleteAlert.length > 0 && deleteAlert}
            {cancelAlert.length > 0 && cancelAlert}
            {/* (loading) && <Loader /> */}
            {errorNotification.length > 0 && errorNotification}
            {missingMilestoneAlert.length > 0 && missingMilestoneAlert}
            {!!contractDialogOpen &&
                <ContractDialog
                    jobOrderCandidate={contractDialogOpen}
                    onClose={() => {
                        var { jobOrderCandidateId } = contractDialogOpen;
                        updateSingleRow(jobOrderCandidateId);
                        setContractDialogOpen(null);
                        //loadData();
                    }}
                />
            }
            {!!jobOrderCandidateDialogOpen &&
                <EditDialog
                    jobOrderCandidate={jobOrderCandidateDialogOpen}
                    jobOrder={item}
                    onClose={() => {
                        var { jobOrderCandidateId } = jobOrderCandidateDialogOpen;
                        updateSingleRow(jobOrderCandidateId);
                        setJobOrderCandidateDialogOpen(null);
                        //loadData();
                    }}
                    onSave={(close) => {
                        //var { jobOrderCandidateId } = jobOrderCandidateDialogOpen;
                        //updateSingleRow(jobOrderCandidateId);
                        loadData();
                        if (close) setJobOrderCandidateDialogOpen(null);
                    }}
                />
            }
            {!!contractTemplateOpen &&
                <ContractTemplate
                    jobOrderCandidates={contractTemplateOpen}
                    onClose={() => {
                        contractTemplateOpen.map((id) => {
                            updateSingleRow(id);
                            return {
                                jobOrderCandidates: id,
                                status: "ok"
                            };
                        });
                        //var { jobOrderCandidateId } = contractTemplateOpen;
                        //updateSingleRow(jobOrderCandidateId);
                        setContractTemplateOpen(null);
                        //loadData();
                    }}
                />
            }
            {
                validationItem &&
                <SweetAlert
                    title={validationItem.title}
                    error={validationItem.type === "error"}
                    warning={validationItem.type === "warning"}
                    confirmBtnText={validationItem.type === "warning" ? "Yes" : "OK"}
                    confirmBtnBsStyle="danger"
                    cancelBtnText={validationItem.type === "warning" ? "Cancel" : null}
                    onConfirm={() => handleCancellation()}
                    onCancel={validationItem.type === "warning" ? () => setValidationItem(null) : null}
                    showCancel={validationItem.type === "warning"}
                >
                    {
                        validationItem.message
                    }
                </SweetAlert>
            }
            {
                confirmationItem &&
                <SweetAlert
                    title={confirmationItem.title}
                    onConfirm={() => handleConfirmed(true)}
                    warning
                    customButtons={
                        <React.Fragment>
                            <Button color="secondary" onClick={() => setConfirmationItem(null)}>Cancel</Button>
                            <Button color="warning" onClick={() => handleConfirmed(false)}>No</Button>
                            <Button color="default" onClick={() => handleConfirmed(true)}>Yes</Button>
                        </React.Fragment>
                    }
                >
                    {
                        confirmationItem.message
                    }
                </SweetAlert>
            }

            <Container fluid >
                {
                    itemInEdit ?
                        <>
                            <Row className={`h-100 job-order-header`}>
                                <Col lg={12} className="pl-2">
                                    <div className="container-group pt-3 px-3 mb-3">
                                        <Row>
                                            <Col sm={2} className="mb-3">
                                                <div>Dispatch Unit</div>
                                                <div className="font-weight-bold">{itemInEdit.dispatchUnitName ?? 'N/A'}</div>
                                            </Col>
                                            <Col sm={2} className="mb-3">
                                                <div>Expected Start Date</div>
                                                <div className="font-weight-bold">{itemInEdit.startDateExpected ? moment(itemInEdit.startDateExpected).format("DD.MM.YYYY") : "N/A"}</div>
                                            </Col>
                                            <Col sm={2} className="mb-3">
                                                <div>Planned End Date</div>
                                                <div className="font-weight-bold">{itemInEdit.finishDatePlanned ? moment(itemInEdit.finishDatePlanned).format("DD.MM.YYYY") : "N/A"}</div>
                                            </Col>
                                            <Col sm={2} className="mb-3">
                                                <div>Desired Entity</div>
                                                <div className="font-weight-bold">{itemInEdit.desiredEntity ?? 'N/A'}</div>
                                            </Col>
                                            <Col sm={2} className="mb-3">
                                                <div>Reporting To</div>
                                                <div className="font-weight-bold">{itemInEdit.reportingToName ?? 'N/A'}</div>
                                            </Col>
                                            <Col sm={2} className="mb-3">
                                                <div>Work Location</div>
                                                <div className="font-weight-bold">{itemInEdit.workLocation ?? 'N/A'}</div>
                                            </Col>
                                            <Col sm={2} className="mb-3">
                                                <div>Expected Start Time</div>
                                                <div className="font-weight-bold">{itemInEdit.expectedStartTime ? moment(itemInEdit.expectedStartTime, 'HH:mm:ss').format("HH:mm") : "N/A"}</div>
                                            </Col>
                                            <Col sm={2} className="mb-3">
                                                <div>Expected Finish Time</div>
                                                <div className="font-weight-bold">{itemInEdit.expectedFinishTime ? moment(itemInEdit.expectedFinishTime, 'HH:mm:ss').format("HH:mm") : "N/A"}</div>
                                            </Col>
                                            <Col sm={2} className="mb-3">
                                                <div>Scope of Work</div>
                                                <div className="font-weight-bold">{itemInEdit.scopeOfWork ?? 'N/A'}</div>
                                            </Col>
                                            <Col sm={2} className="mb-3">
                                                <div>Vacancy Start Date</div>
                                                <div className="font-weight-bold">{itemInEdit.publishStartDate ? moment(itemInEdit.publishStartDate).format("DD.MM.YYYY") : "N/A"}</div>
                                            </Col>
                                            <Col sm={2} className="mb-3">
                                                <div>Vacancy End Date</div>
                                                <div className="font-weight-bold">{itemInEdit.publishEndDate ? moment(itemInEdit.publishEndDate).format("DD.MM.YYYY") : "N/A"}</div>
                                            </Col>
                                            <Col sm={2} className="mb-3">
                                                <div></div>
                                                <div className="font-weight-bold"></div>
                                            </Col>
                                        </Row>
                                        <Row>
                                        <Col lg={2} md={4} sm={6} xs={12} className="pr-0">
                                                        <FormGroup>
                                                            <Label>
                                                                Recruitment Coordinator
                                                            </Label>
                                                            <ComboBox
                                                                className="mr-2"
                                                                endpoint="/lookup/users"
                                                                isLookup={false}
                                                                minLength={1}
                                                                idField="email"
                                                                valueField="nameAndEmail"
                                                                selectedItem={itemInEdit?.recruitmentCoordinator}
                                                                onChange={(selectedItem) => {
                                                                    setLoading(true);
                                                                    setItemInEdit({
                                                                        ...itemInEdit,
                                                                        recruitmentCoordinator: !selectedItem ? null : selectedItem,
                                                                        recruitmentCoordinatorId: selectedItem?.userProfileId ? selectedItem?.userProfileId : null
                                                                    });
                                                                    api.put(`/joborder/coordinator/${props.item.jobOrderId}`, JSON.stringify({
                                                                        jobOrderId: props.item.jobOrderId,
                                                                        recruitmentCoordinatorId: selectedItem ? selectedItem.userProfileId : null,
                                                                        recruitmentCoordinator: selectedItem
                                                                    }))
                                                                    .then((response) => { })
                                                                    .catch((error) => console.error("error: ", error))
                                                                    .finally(() => setLoading(false));


                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col lg={2} md={4} sm={6} xs={12} className="pr-0">
                                                        <FormGroup>
                                                            <Label>
                                                                Recruitment Person
                                                            </Label>

                                                            {/*
                                                            <DropdownBox
                                                                data={recruitmentPeople}
                                                                selectedItem={itemInEdit?.recruitmentPerson}
                                                                onChange={(item) => {
                                                                    setLoading(true);
                                                                    setItemInEdit({ ...itemInEdit, recruitmentPerson: item, recruitmentPersonId: item ? item.lookupId : null });
                                                                    api.put(`/joborder/person/${props.item.jobOrderId}`, JSON.stringify({
                                                                        jobOrderId: props.item.jobOrderId,
                                                                        recruitmentPersonId: item ? item.lookupId : null
                                                                    }))
                                                                        .then((response) => { })
                                                                        .catch((error) => console.error("error: ", error))
                                                                        .finally(() => setLoading(false));
                                                                }}
                                                            />
                                                            */}
                                                            <ComboBox
                                                                minLength={0}
                                                                endpoint={recruitmentPeopleEndpoint}
                                                                selectedItem={selectedRecruitmentPerson}
                                                                isLookup={false}
                                                                idField="userProfileId"
                                                                valueField="nameAndEmail"
                                                                onChange={(item) => {
                                                                    setLoading(true);
                                                                    setItemInEdit({
                                                                        ...itemInEdit,
                                                                        recruitmentPersonId: item ? item.recruitmentPersonId : null,
                                                                        recruitmentPerson: !item ? null : {
                                                                            ...item,
                                                                            lookupId: item.recruitmentPersonId,
                                                                            lookupValue: item.displayName,
                                                                        }
                                                                    });
                                                                    api.put(`/joborder/person/${props.item.jobOrderId}`, JSON.stringify({
                                                                        jobOrderId: props.item.jobOrderId,
                                                                        recruitmentPersonId: item ? item.recruitmentPersonId : null
                                                                    }))
                                                                        .then((response) => { })
                                                                        .catch((error) => console.error("error: ", error))
                                                                        .finally(() => setLoading(false));

                                                                }}
                                                            />

                                                        </FormGroup>
                                                    </Col>

                                            <Col lg={8} md={4} xs={12} className="job-order-header-action text-right">

                                                {
                                                    !itemInEdit?.isCancelled &&
                                                    <>
                                                        <Button
                                                            className="btn-icon mr-2 mb-3"
                                                            color="default"
                                                            size="sm"
                                                            type="button"
                                                            disabled={!itemInEdit?.jobOrderId}
                                                            onClick={() => window.open(`/joborderlrfdetails/${itemInEdit?.recruitmentRequestId}`, '_blank')}
                                                        >
                                                            <i className="fas fa-external-link-alt"></i> Show LRF Details
                                                        </Button>
                                                        <Button
                                                            className="btn-icon mr-2 mb-3"
                                                            color="default"
                                                            size="sm"
                                                            type="button"
                                                            disabled={!itemInEdit?.jobOrderId}
                                                            onClick={() => {
                                                                const candidateIds = [];

                                                                if (selectedRows?.length > 0) {
                                                                    //console.log('selectedRows', selectedRows);
                                                                    //return;
                                                                    selectedRows.forEach(d => {
                                                                        if (!d.data.candidateId)
                                                                            return;

                                                                        candidateIds.push(d.data.candidateId);
                                                                    })
                                                                }

                                                                window.open(`/tnadiscrepancy-joborder/${jobOrderId}${candidateIds.length > 0 ? `?candidateIds=${candidateIds.join("-")}` : ""}`, '_blank');
                                                            }}

                                                        >
                                                            <i className="fas fa-external-link-alt"></i> TNA Discrepancy Report
                                                        </Button>
                                                        <Button
                                                            className="btn-icon mr-2 mb-3"
                                                            color="default"
                                                            size="sm"
                                                            type="button"
                                                            onClick={() => {
                                                                setOpenVacancySetting(true);
                                                            }}
                                                            disabled={data?.filter(d => !d.candidateId).length === 0}
                                                        >
                                                            <i className="fas fa-door-open mr-1"></i> Vacancies Setting
                                                        </Button>
                                                        <Button
                                                            className="btn-icon mr-2 mb-3"
                                                            color="default"
                                                            size="sm"
                                                            type="button"
                                                            onClick={() => {
                                                                setOpenApplicantList(true);
                                                            }}
                                                            disabled={!itemInEdit?.publishStartDate || !itemInEdit?.publishEndDate}
                                                        >
                                                            <i className="fas fa-user mr-1"></i> Show Applicants
                                                            {
                                                                itemInEdit?.totalUnproccessedApplicants > 0 && data?.filter(d => !d.candidateId).length > 0 &&
                                                                <span className="position-absolute top-badge end-0 translate-middle px-2 py-1 rounded bg-yellow text-dark">{itemInEdit?.totalUnproccessedApplicants}</span>
                                                            }
                                                        </Button>
                                                        <Button
                                                            className="btn-icon mr-2 mb-3"
                                                            color="default"
                                                            size="sm"
                                                            type="button"
                                                            onClick={() => {
                                                                setShowCancelDialog(true);
                                                            }}
                                                            disabled={!itemInEdit?.jobOrderId || itemInEdit?.completionPercent == 1}
                                                        >
                                                            <i className="fas fa-ban mr-1"></i> Cancel This Job
                                                        </Button>
                                                    </>

                                                }
                                                {
                                                    itemInEdit?.isCancelled &&

                                                    <Button
                                                        className="btn-icon mr-2 mb-3"
                                                        color="default"
                                                        size="sm"
                                                        type="button"
                                                        onClick={() => {
                                                            rollbackJobOrder();
                                                        }}
                                                        disabled={!itemInEdit?.jobOrderId || itemInEdit?.completionPercent == 1}
                                                    >
                                                        <i className="fas fa-ban mr-1"></i> Rollback This Job
                                                    </Button>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                    {/*
                                        <div className="alert alert-info">
                                            Dispatch Unit: {itemInEdit.dispatchUnitName ?? 'N/A'};
                                            Expected Start Date: {itemInEdit.startDateExpected ? moment(itemInEdit.startDateExpected).format("DD.MM.YYYY") : "N/A"};
                                            Planned End Date: {itemInEdit.finishDatePlanned ? moment(itemInEdit.finishDatePlanned).format("DD.MM.YYYY") : "N/A"};
                                            Desired Entity: {itemInEdit.desiredEntity ?? 'N/A'};
                                            Reporting To: {itemInEdit.reportingToName ?? 'N/A'};
                                            Work Location: {itemInEdit.workLocation ?? 'N/A'};
                                            Expected Start Time: {itemInEdit.expectedStartTime ? moment(itemInEdit.expectedStartTime, 'HH:mm:ss').format("HH:mm") : "N/A"};
                                            Expected Finish Time: {itemInEdit.expectedFinishTime ? moment(itemInEdit.expectedFinishTime, 'HH:mm:ss').format("HH:mm") : "N/A"};
                                            Scope of Work: {itemInEdit.scopeOfWork ?? 'N/A'};
                                        </div>
                                        */}

                                </Col>
                            </Row>

                            <Row>
                            </Row>
                        </>
                        :
                        null
                }
                <Row>
                    <Col sm={9}>
                        {
                            !itemInEdit?.isCancelled &&
                            <>
                                <Button
                                    id="selectAll"
                                    title={!isSelectAll ? 'Select All' : 'Unselect All'}
                                    className="btn-icon mr-2 mb-3"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    onClick={() => selectAll()}
                                >
                                    <i className={`fas ${isSelectAll == null ? 'fa-minus-square' : !isSelectAll ? 'fa-square' : 'fa-check-square'}`}></i> {!isSelectAll ? 'Select All' : 'Unselect All'}
                                </Button>
                                <Button
                                    className="btn-icon mr-2 mb-3"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    disabled={!selectedRows.length}
                                    onClick={() => {
                                        const jobOrderCandidateIds = selectedRows.map(d => d.data.jobOrderCandidateId);
                                        const joc = selectedRows.map(d => d.data);

                                        var containsCandidate = selectedRows.some((s) => !!s.data.candidateId);
                                        if (!containsCandidate) {
                                            setValidationMessage("Please select position(s) with associated talent.");
                                            return;
                                        }

                                        viewBulkMilestone(jobOrderCandidateIds, joc);
                                    }}
                                >
                                    <i className="fas fa-folder-open"></i> Milestone Progress
                                </Button>
                                <Button
                                    className="btn-icon mr-2 mb-3"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    disabled={!selectedRows.length}
                                    onClick={() => viewBulkCandidateSMS(selectedRows.map(d => ({ ...d.data.candidate, jobOrderCandidateId: d.data.jobOrderCandidateId })))}
                                >
                                    <i className="fas fa-comment mr-1"></i> Send SMS
                                </Button>
                                <Button
                                    className="btn-icon mr-2 mb-3"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    disabled={!selectedRows.length}
                                    onClick={() => {
                                        viewCandidateEmails(selectedRows.map(d => ({ ...d.data.candidate })));
                                    }}
                                >
                                    <i className="fas fa-envelope-open"></i> Send Email
                                </Button>
                                <Button
                                    className="btn-icon mr-2 mb-3"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    disabled={!selectedRows.length}
                                    onClick={() => {
                                        setContractTemplateOpen([...selectedRows.map(d => d.data.jobOrderCandidateId)])
                                    }}
                                >
                                    <i className="fas fa-file-signature mr-1"></i> Generate Contracts
                                </Button>
                                <Button
                                    className="btn-icon mr-2 mb-3"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    disabled={!selectedRows.length}
                                    onClick={() => {
                                        setDefinitivOnboardingCandidates([...selectedRows.filter(c => c.candidateId && c.status.lookupValue !== "Withdrawn" && c.status.lookupValue !== "Candidate Unsuitable").map(c => c)]);
                                    }}
                                >
                                    <i className="fas fa-upload"></i> Send to Definitiv Onboarding
                                </Button>
                                <Button
                                    className="btn-icon mr-2 mb-3"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        cancelJobOrderSlots();
                                    }}
                                    disabled={!selectedRows?.length}
                                >
                                    <i className="fas fa-ban mr-1"></i> Cancel Selected Slot
                                </Button>
                                <Button
                                    className="btn-icon mr-2 mb-3"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        setShowTalentSearchDialog(true);
                                    }}
                                >
                                    <i className="fas fa-search mr-1"></i> Search Roster
                                </Button>
                            </>
                        }
                    </Col>

                    <Col sm={3} className="text-xs-left text-sm-right d-flex flex-wrap justify-content-end align-items-center pb-3">

                        <IndeterminateCheckbox
                            id="showWithdrawnCheckBox"
                            type="checkbox"
                            labelClassName="job-order-control mr-3"
                            onChange={() => {
                                setShowWithdrawn(!showWithdrawn);
                            }}
                            checked={showWithdrawn}
                            customTitle="Show Withdrawn"
                        />
                        <IndeterminateCheckbox
                            id="showCancelledCheckBox"
                            type="checkbox"
                            labelClassName="job-order-control"
                            onChange={() => {
                                setShowCancelled(!showCancelled);
                            }}
                            checked={showCancelled}
                            customTitle="Show Cancelled"
                        />

                    </Col>
                </Row>
                <Row className="">
                    <div className="ag-theme-alpine ag-grid w-100 ">
                        <AgGridReact
                            domLayout={'autoHeight'}
                            ref={gridRef}
                            gridOptions={gridOptions}
                            columnDefs={columnDefs}
                            rowData={data}
                            defaultColDef={defaultColDef}
                            loadingOverlayComponent={loadingOverlayComponent}
                            suppressColumnVirtualisation={false}
                            suppressRowVirtualisation={false}
                            debounceVerticalScrollbar={false}
                            rowBuffer={20}
                            headerHeight={30}
                            groupHeaderHeight={30}
                            rowHeight={40}
                            floatingFiltersHeight={30}
                            suppressDragLeaveHidesColumns={true}
                            suppressColumnMoveAnimation={true}
                            animateRows={false}
                            onCellEditRequest={onCellEditRequest}
                            excelStyles={excelStyles()}
                            pagination={true}
                            paginationPageSize={pageSize}
                            cacheBlockSize={pageSize}
                            sortingOrder={agGridSort}
                            rowSelection={'multiple'}
                            suppressRowClickSelection={true}
                            onSelectionChanged={onSelectionChanged}
                            onFirstDataRendered={onFirstDataRendered}
                            tooltipShowDelay={0}
                            tooltipHideDelay={20000}
                        />
                    </div>
                </Row>
            </Container>

            {
                !!definitivOnboardingCandidates?.length &&
                <DefinitivOnboardingEditor
                    selectedCandidates={[...definitivOnboardingCandidates]}
                    onClose={() => {
                        setDefinitivOnboardingCandidates([]);
                        loadData();
                    }}
                    onSaved={() => {
                        setDefinitivOnboardingCandidates();
                        loadData();
                    }}
                    companies={[...companies]}
                />
            }
            {
                openVacancySetting &&
                <VacancyEditor
                    onClose={() => setOpenVacancySetting(false)}
                    onSave={() => {
                        setOpenVacancySetting(false);
                        loadData();
                    }}
                    refresh={() => loadData()}
                    jobOrder={itemInEdit}
                />
            }
            {
                openApplicantList &&
                <JobOrderApplicantList
                    applicantGrid={gridRef}
                    jobOrder={itemInEdit}
                    onClose={() => {
                        setOpenApplicantList(false);
                        loadData();
                    }}
                />
            }
            {
                validationMessage &&
                <SweetAlert
                    danger
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    title={<h4 className="mb-0">Job Order</h4>}
                    onConfirm={() => setValidationMessage(null)} // need to add loading indicator on confirm
                    onCancel={() => setValidationMessage(null)}
                    focusCancelBtn
                >
                    <p>{validationMessage}</p>
                </SweetAlert>
            }
            {
                showTalentSearchDialog &&
                <TalentSearchDialog
                    jobOrderId={itemInEdit?.jobOrderId}
                    onClose={() => {
                        setShowTalentSearchDialog(false);
                        loadData();
                    }}
                />
            }
            {
                showCancelDialog &&
                <JobOrderCancellationDialog
                    jobOrderId={itemInEdit?.jobOrderId}
                    onClose={() => setShowCancelDialog(false)}
                    onSubmit={(cancelObj) => cancelJobOrder(cancelObj)}
                />
            }
            {
                showCandidateWithdrawDialog ?
                <JobOrderCandidateWithdrawalDialog
                    jobOrderCandidate={showCandidateWithdrawDialog}
                    onClose={() => setShowCandidateWithdrawDialog(null)}
                    onSubmit={(withdrawObj) => withdraw(withdrawObj)}
                />
                :
                null
            }
        </>
    )
}

export const excelStyles = () => {
    const headerDefaultStyle = {
        alignment: {
            vertical: 'Center',
            horizontal: "Center",
        },
        interior: {
            color: '#f8f8f8',
            pattern: 'Solid',
            patternColor: undefined,
        },
        borders: {
            borderTop: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderRight: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderBottom: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderLeft: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
        },
        font: {
            fontName: 'Roboto',
            color: '#181d1f',
            bold: true,
            size: 12,
        },
    }

    return [
        {
            id: 'header',
            ...headerDefaultStyle
        },
        {
            id: 'date-header',
            ...headerDefaultStyle,
            alignment: {
                vertical: 'Center',
                horizontal: "Center",
                rotate: 90,
            },
        },
        {
            id: 'cell',
            alignment: {
                vertical: 'Top',
                wrapText: true,
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid',
                patternColor: undefined,
            },
            borders: {
                borderTop: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderRight: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderBottom: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderLeft: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
            },
            font: {
                fontName: 'Roboto',
                color: '#181d1f',
                weight: 700,
                size: 12,
            },
        },
    ];
};


