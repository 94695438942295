import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from "moment";
import { useTable, useFlexLayout } from 'react-table';
import _ from "lodash";
import Loader from "../../loaders";
import { UncontrolledTooltip } from "reactstrap";
import { HotTable, HotColumn } from '@handsontable/react';
import Handsontable from "handsontable";
import api from "../../../services/api";

function getTextWidth(text, font) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
  
    context.font = font || getComputedStyle(document.body).font;
  
    return context.measureText(text).width;
}
function getMinDimension(text, font, size) {
    const container = document.createElement('div');
    container.style = "display:none !important;"

    return {
        height: null,
        width: null
    }
}
const ColumnRenderer = (props) => {
    const { value, TD } = props;
    
    Handsontable.dom.addClass(TD, 'data-cell');
    // console.log(value);

    return (
        <React.Fragment>
            {/* {value?.values?.trainingRequirementName} */}
            {value}
        </React.Fragment>
    );
};
const tnaColWidth = 60;
function HotMatrix(props) {

    const {
        selectedProject,
        cols,
        data,
        setItemInEdit,
        setPositionInEdit,
        onAutoSaved,
        setLoading,
        onChanged,
        matrixSave,
        lookups
    } = props;

    const hotTableComponent = useRef(null);
    const [ selectedCell, setSelectedCell ] = useState(null);
    const [ processedData, setProcessedData ] = useState([]);
    const [ isChanged, setIsChanged ] = useState(false);

    useEffect(() => {
        if (data?.length === 0 || cols?.length === 0)
        {
            setProcessedData([]);
            return;
        }

        // console.log(data, cols);
        const keys = [];

        data.forEach(item => {
            Object.keys(item).forEach(key => {
                if (keys.filter(k => k.real === key).length > 0)
                    return;
                
                keys.push({real: key, actual: key + "-R"});
            });
        });

        // console.log(keys);
        setProcessedData(data.map(item => {
            const arrayResult = keys.map(key => {
                return {
                    [key.actual]: item[key.real].values?.trainingRequirementName
                }
            });

            const result = arrayResult.reduce((acc, curr) => {
                // console.log(acc, curr);

                if (!acc || acc.length === 0)
                    acc = {};

                Object.keys(curr).forEach(key => {
                    acc[key] = curr[key];
                });

                return acc;
            })

            return {
                ...item,
                ...result
            };
        }))
    }, [data, cols]);

    useEffect(() => onChanged(isChanged), [isChanged]);
    useEffect(() => setIsChanged(false), [selectedProject]);
    useEffect(() => {
        if (!matrixSave)
            return;
        
        handleAutoSave(() => {
            setIsChanged(false);
        });
    }, [matrixSave]);

    const columnInfo = useMemo(() => {
        //columns
        const subHeaders = [{ label: 'Position' }];
        const headers = cols.filter(f => f.columns && f.columns.length > 0).map((grp, grpIndex) => {
            grp.columns.forEach((col) => {
                subHeaders.push({
                    label: col.name,
                });
            })

            return {
                label: grp.name,
                colspan: grp.columns.length
            }
        });

        //columnSize
        let longestTextLength = 0, longestText;
        const check = (col) => {
            if (col.name.length > longestTextLength) {
                longestTextLength = col.name.length;
                longestText = col.name;
            }
            // if (col.columns && col.columns.length > 0) {
            //     _.forEach(col.columns, check)
            // }            
        }
        // _.forEach(cols, check);
        _.forEach(cols, (col) => {
            if (col.columns && col.columns.length > 0) {
                _.forEach(col.columns, check)
            } 
        });

        const height = Math.ceil(getTextWidth(longestText));
        //console.log("height", height);


        var objectColumn = {
            columns: [[{ label: '', colspan: 1 }, ...headers], [...subHeaders]],
            columnSize: {
                rowHeight: height + 20,
                bottomPosition: height / 2,
                leftPosition: 32
            }
        };
        //console.log('columnInfo', objectColumn);

        return objectColumn;
    }, [ cols ])

    const hiddenContextMenu = useCallback(() => {
        return !selectedCell || selectedCell?.column < 1;
    }, [selectedCell]);

    const handleSetItemInEdit = useCallback((rowData, col, tnaId) => {
        if (!rowData || col < 0)
            return;

        const cellData = Object.keys(rowData).map((key) => ({[key]: rowData[key]})).reduce((acc, curr) => {
                const arrKey = Object.keys(curr)[0];

                if (!acc || !acc.length)
                    acc = [];

                acc.push(curr[arrKey]);
                return acc;
            })
            .filter(val => val?.colIndex + 1 === col)[0]
        ;

        const {values} = cellData;
        // console.log({tnaId, values});

        if (!values?.trainingNeedAnalysisId && tnaId)
        {
            setLoading(true);
            api.get(`/trainingneedanalysis/single/${tnaId}`)
                .then((response) => {
                    const newValues = response.data;
                    setLoading(false);
                    setItemInEdit(newValues);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });

            return;
        }

        if (values && values.trainingNeedAnalysisId) {
            setItemInEdit({
                ...values,
                position: {
                    lookupValue: rowData.Position?.title
                },
                trainingGroup: {
                    lookupValue: rowData[cellData.title]?.trainingGroupName
                },
                trainingMaster: {
                    lookupValue: rowData[cellData.title]?.title
                },
                trainingRequirement: values.trainingRequirementId ? 
                _.find(props.lookups.trainingRequirements, o => o.lookupId === values.trainingRequirementId) 
                : null,
                trainingProvider: values.trainingProviderId ? 
                _.find(props.lookups.trainingProviders, o => o.lookupId === values.trainingProviderId) 
                : null,
                fundingSource: values.fundingSourceId ? 
                _.find(props.lookups.fundingSources, o => o.lookupId === values.fundingSourceId) 
                : null,
            });
        }
        else {
            setItemInEdit({
                projectId: selectedProject.projectId,
                trainingNeedAnalysisId: 0,
                tnaTrainingMasterId: rowData[cellData.title].tnaTrainingMasterId,
                tnaPositionId: rowData.Position.tnaPositionId,
                fundingSourceId: null,
                trainingProviderId: null,
                trainingRequirementId: null,
                percentRequired: null,
                startDate: moment(),
                endDate: null,
                position: {
                    lookupValue: rowData.Position.title
                },
                trainingGroup: {
                    lookupValue: rowData[cellData.title]?.trainingGroupName
                },
                trainingMaster: {
                    lookupValue: rowData[cellData.title]?.title
                },
            })
        }
    }, [selectedProject]);

    const handleAutoSave = useCallback((callback, getTnaId = false) => {
        const hotTable = hotTableComponent.current?.hotInstance;

        if (!hotTable)
            return;

        setLoading(true);
        const rawDataToSave = [];
        const dataToSave = [];
        // console.log("auto save: ", {processedData});
        processedData
            .filter(item => {
                const arrKeys = Object.keys(item);

                return arrKeys.filter(key => {
                    return item[key]?.values ? true : false;
                }).length > 0;
            })
            .forEach((item, row) => {
                const rowData = hotTable.getSourceDataAtRow(row);

                if (!rowData)
                    return;

                Object.keys(item).forEach(key => {
                    if (item[key]?.values)
                    {
                        const dataToSaveValues = {...item[key].values};
                        rawDataToSave.push(item[key].values);
                        // console.log("update data: ", {key, dataToSaveValues});

                        if (!dataToSaveValues.trainingNeedAnalysisId)
                        {
                            dataToSaveValues.projectId = selectedProject.projectId;
                            dataToSaveValues.trainingNeedAnalysisId = 0;
                            dataToSaveValues.tnaTrainingMasterId = rowData[key].tnaTrainingMasterId;
                            dataToSaveValues.tnaPositionId = rowData.Position.tnaPositionId;
                            dataToSaveValues.startDate = moment();
                        }

                        dataToSave.push(dataToSaveValues);
                    }
                });
            });
        // console.log({dataToSave: dataToSave.filter(x => x.trainingNeedAnalysisId === 0), rawDataToSave});

        const {row, column} = selectedCell;

        const checkRowData = hotTable.getSourceDataAtRow(row);
        const cellData = Object.keys(checkRowData).map((key) => ({[key]: checkRowData[key]})).reduce((acc, curr) => {
            const arrKey = Object.keys(curr)[0];

            if (!acc || !acc.length)
                acc = [];

            acc.push(curr[arrKey]);
            return acc;
        })
        .filter(val => val?.colIndex + 1 === column)[0];

        // console.log(getTnaId, selectedProject.projectId, checkRowData[cellData.title].tnaTrainingMasterId, checkRowData.Position.tnaPositionId);

        api.post(`/trainingneedanalysis/bulk${getTnaId ? `?projectid=${selectedProject.projectId}&tnatrainingmasterid=${checkRowData[cellData.title].tnaTrainingMasterId}&tnapositionid=${checkRowData.Position.tnaPositionId}` : ""}`, dataToSave)
            .then((response) => {
                setLoading(false);
                onAutoSaved();

                if (callback)
                    callback(getTnaId ? (response.data && response.data !== "" ? response.data : null) : null);
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    }, [selectedProject, hotTableComponent, processedData, selectedCell]);

    const hotTable = useMemo(() => {
        // console.log('hotTable: ', { cols, columnInfo, data })

        if (!columnInfo) {
            return null;
        }

        return (
            <HotTable
                className='tna'
                ref={hotTableComponent}
                data={processedData}
                colHeaders={true}
                nestedHeaders={columnInfo.columns}                
                columnHeaderHeight ={[80, columnInfo.columnSize.rowHeight]}
                selectionMode={"range"}
                viewportColumnRenderingOffset={150}
                fixedColumnsLeft={1}
                manualRowMove={false}
                manualColumnResize={true}
                manualColumnFreeze={false}
                manualColumnMove={false}
                renderAllRows={true}
                contextMenu={
                    hiddenContextMenu() ?
                    false
                    :
                    {
                        items: {
                            edit_matrix: {
                                name: "Edit",
                                callback(key, selection, event) {
                                    const hotTable = hotTableComponent.current?.hotInstance;
                
                                    if (!hotTable || !selection || selection.length === 0)
                                        return;

                                    const {
                                        col,
                                        row
                                    } = selection[0]?.start;
                                    
                                    const rowData = hotTable.getSourceDataAtRow(row);

                                    if (isChanged)
                                    {
                                        handleAutoSave((tnaId) => {
                                            setIsChanged(false);
                                            handleSetItemInEdit(rowData, col, tnaId);
                                        }, true);
                                        return;
                                    }

                                    handleSetItemInEdit(rowData, col);
                                },
                                hidden: hiddenContextMenu
                            }
                        }
                    }
                }
                modifyColWidth={(width, column) => {
                    if (!hotTableComponent.current)
                        return tnaColWidth;

                    const hot = hotTableComponent.current.hotInstance;

                    //console.log("modifyColWidth", "width", width, "column", column);
                    //console.log("columnInfo", columnInfo);
                    //console.log("clientWidth", hot.table.parentElement.parentElement.parentElement.clientWidth);
                    //console.log("length", columnInfo.columns[1].length);
                    //console.log("tnaColWidth", tnaColWidth);

                    if (column === 0 && hotTableComponent.current) {
                        const width = hot.table.parentElement.parentElement.parentElement.clientWidth - ((columnInfo.columns[1].length * tnaColWidth) - tnaColWidth)
                        return width < 300 ? 300 : width;
                    }
                    return tnaColWidth;
                }}
                height='auto'
                licenseKey="non-commercial-and-evaluation"
                afterGetColHeader={(col, TH) => {
                    TH.col = col;
                    TH.addEventListener('dblclick', ({ currentTarget: { col } }) => { 
                        // console.log(`col: `, col); 
                    });
                    if (col > 0 && columnInfo.columns[1].some(s => s.label === TH.textContent)) {
                        Handsontable.dom.addClass(
                            TH,
                            'rotate-header'
                        );
                    }
                }}
                afterGetRowHeader={(row, TH) => {
                    TH.row = row;
                    TH.addEventListener('dblclick', ({ currentTarget: { row } }) => { 
                        // console.log(`row: `, row); 
                    });
                }}
                afterSelectionEnd={(row, column, row2, column2) => {
                    // console.log('afterSelectionEnd', {row, column, row2, column2})
                    const hotTable = hotTableComponent.current?.hotInstance;

                    setSelectedCell({
                        row,
                        column
                    });

                    if (!hotTable || column > 0)
                        return;
                    
                    const rowData = hotTable.getSourceDataAtRow(row);
                    // console.log(rowData);

                    const item = {
                        position: {
                            positionId: rowData?.Position?.id,
                            positionName: rowData?.Position?.title,
                            tnaPositionId: rowData?.Position?.tnaPositionId
                        },
                        //position: {
                        //    positionId: rowData.Position.id,
                        //    positionName: rowData.Position.title,
                        //    tnaPositionId: rowData.Position.tnaPositionId
                        //},
                        groups: _.keyBy(cols.filter(f => f.name !== "Position").map(o => {

                            return {
                                trainingGroupName: o.name,
                                trainingGroupId: o.id,
                                skills: o.columns.map(c => {

                                    // If data null
                                    if (!rowData) 
                                        return undefined;

                                    // Assign value
                                    const value = rowData[c.name];
                                    if (value) {
                                        const { values, title, ...rest } = value;
                                        return { trainingTitle: title, ...values, ...rest }
                                    }
                                    return undefined;
                                }).filter(f => f !== undefined)
                            }
                        }), o => o.trainingGroupId)
                    }

                    if (isChanged)
                    {
                        handleAutoSave(() => {
                            setIsChanged(false);
                            setPositionInEdit(item);
                        });
                        return;
                    }

                    // console.log(item);
                    setPositionInEdit(item);
                }}
                beforeChange={(changes, source) => {
                    if (source === "loadData" || changes.length === 0)
                        return;
                    
                    let checkResult = true;
                    
                    changes.forEach(change => {
                        // console.log(change, lookups.trainingRequirements.filter(x => x.lookupValue === change[3]));
                        if (change[3] && change[3] !== "" && lookups.trainingRequirements.filter(x => x.lookupValue === change[3]).length === 0)
                            checkResult = false;
                    });

                    if (!checkResult)
                        return checkResult;
                }}
                afterSetDataAtCell={(changes, source) => {
                    if (source === "loadData" || changes.length === 0)
                        return;
                    
                    const hotTable = hotTableComponent.current?.hotInstance;

                    if (!hotTable)
                        return;

                    changes.forEach(change => {
                        const rowData = processedData[change[0]];
                        const prop = hotTable.colToProp(change[1]);
                        const colName = prop.substring(0, prop.length - 2);
                        // console.log({changes, processedData, rowData, colName});
                        // console.log(rowData[colName]);
                        const lookup = lookups.trainingRequirements.filter(x => x.lookupValue === change[3])[0];
                        const values = rowData[colName]?.values;
    
                        if (!lookup && change[2] !== change[3])
                        {
                            if (values)
                                rowData[colName].values = {
                                    ...values,
                                    trainingRequirementId: null
                                };
                            setIsChanged(true);
                            return;
                        }
    
                        if (!values)
                            rowData[colName].values = {};
    
                        rowData[colName].values.trainingRequirementId = lookup?.lookupId;
                        rowData[colName].values.trainingRequirementName = lookup?.lookupValue;
    
                        if (change[2] !== change[3] && !isChanged)
                            setIsChanged(true);
                    });
                    
                    // console.log("afterSetDataAtCell: ", {processedData});
                }}
            >
                <HotColumn data='Position.title' key={-1} readOnly={true} className="cursor-pointer" />
                {
                    columnInfo.columns[1].slice(1).map((col, index) => {
                        // console.log(col.label);
                        return (
                            <HotColumn data={`${col.label}-R`} key={index} className='data-cell'>
                                <ColumnRenderer hot-renderer />
                            </HotColumn>
                        )
                    })
                }
                
            </HotTable>
        )
    }, [ columnInfo, hiddenContextMenu, processedData, lookups, isChanged, handleAutoSave, handleSetItemInEdit ]);

    // console.log('Columns: ', { cols, columns: columnInfo.columns, data })
    return (
        <>
            {hotTable}
            {/* <div>
                <h5>columns</h5>
                <div>
                    { JSON.stringify(columns, null, 2) }
                </div>
            </div>
            <div>
                <h5>columnSize</h5>
                <div>
                    { JSON.stringify(columnSize, null, 2) }
                </div>
            </div> */}
        </>
    );

}
export default HotMatrix;