/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Loader from '../../../loaders';
import api from "../../../../services/api";
import { Button, Col, Container, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import { useFlexLayout, useRowSelect, useTable } from 'react-table';
import { IndeterminateCheckbox } from '../../../react-table/controls';
import { DefaultColumnFilter, DateColumnFilter } from '../../../react-table/filters';
import { TooltipButton } from '../../../inputs';
import moment from "moment";
import Grid from "../../../Grid";
import { ComboBox, DropdownBox } from "../../../dropdowns";
import { ShortenWords, ToFilenameString } from "../../../../helpers/StringHelper";
import { ErrorNotification } from "../../../alerts";
import fileDownload from "js-file-download";
import qs from "qs";


export default function InactiveDatesAudit(props) {
    const {
        candidate,
        candidateId,
        setCandidateId,
        onClose
    } = props;

    const skipPageResetRef = React.useRef();

    const [loading, setLoading] = useState(false);
    const [userGroup, setUserGroup] = useState(null);
    const [data, setData] = useState([]);
    const [userGroupMemberName, setUserGroupMemberName] = useState(null);
    const [userGroupMembers, setUserGroupMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);

    // Error message variables
    const [errorTitle, setErrorTitle] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);
    const [errorMessageOpening, setErrorMessageOpening] = React.useState(`Error occured when processing the request: `);
    const [errorTitleOnGenerate, setErrorTitleOnGenerate] = React.useState(`Candidate Inactive Date Audit Error`);


    const loadData = useCallback(() => {
        setUserGroup(null);
        setData([]);

        if (!candidateId)
            return;

        setLoading(true);

        const apiCalls = [
            //api.get(`/usergroup/${userGroupId}`),
            //api.get(`/usergroup/search-members?id=${userGroupId}`)
            api.get(`/candidateinactivedateaudit/list-by-candidateid/${candidateId}`)
        ]

        Promise.all(apiCalls)
            .then((responses) => {
                console.log(responses);
                //setUserGroup(responses[0]?.data);
                setData(responses[0]?.data ?? []);
            })
            .catch(error => console.error(error))
            .finally(() => setLoading(false))
            ;
    }, [candidateId]);

    useEffect(() => {
        loadData();
    }, [candidateId]);

    useEffect(() => {
        console.log('data', data);
    }, [data]);


    //useEffect(() => {
    //    console.log('selectedMember', selectedMember);

    //    // Auto return if selectedMember is null
    //    if (selectedMember == null) return;

    //    // Insert selectedMember to data
    //    var newIndex = data.length + 1;
    //    setData([...data, { ...selectedMember, index: newIndex }]);
    //    setSelectedMember(null);


    //}, [selectedMember]);

    // Add
    const handleAdd = useCallback(() => {
        console.log('selectedMember', selectedMember);

        // Auto return if selectedMember is null
        if (selectedMember == null) return;

        // Insert selectedMember to data
        var newIndex = data.length + 1;
        setData([...data, { ...selectedMember, index: newIndex }]);
        setSelectedMember(null);
    }, [selectedMember, data]);


    const handleSave = useCallback(() => {
        if (!data)
            return;

        setLoading(true);
        var postData = [...data];

        api.post(`/usergroupmember/save-list`, postData)
            .then(() => {
                setLoading(false);
                setCandidateId(null);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            })
            ;
    }, [candidateId, data]);

    const handleExport = useCallback(() => {
        exportAction();
    }, [candidateId, data]);


    // Function void to download the data
    const exportAction = useCallback(() => {
        try
        {

            const params = {
                candidateIds: candidateId
            };
            var candidateFileName = ShortenWords(ToFilenameString(candidate.candidateName), 6);
            let filename = `candidate-inactive-audit-trail-${candidateFileName}-${moment().format("YYYYMMDDhhmmss")}.xlsx`;
            let url = `/reports/download-candidate-inactive-audit-trail?${qs.stringify(params)}`;
            downloadExport(url, filename);
        }
        catch (error)
        {
            console.error(error);
            setErrorMessage(`${errorMessageOpening}${error.message}`);
        }
    }, [candidateId, candidate]);



    const downloadExport = React.useCallback((url, filename) => {
        setLoading(true);
        api.get(url, { responseType: 'blob' })
            .then(blob => {
                // console.log(blob);
                //var filename = /(?<=attachment;.*filename=").*(?=";)/.exec(blob.headers["content-disposition"])[0];
                fileDownload(blob.data, filename);
            }).catch(error => {
                console.error(error);
                setErrorMessage(`${errorMessageOpening} ${error.message} `);
                //setLoading(false);
            }).finally(() => setLoading(false))
    }, []);



    // BEGIN DATA LIST --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 150,
            maxWidth: 200,
        }), []);



    const columns = React.useMemo(() => {
        return [
            {
                Header: "Action Date",
                id: "createdDate",
                accessor: (row) => row.createdDate ? `${moment(row.createdDate).format("DD/MM/YYYY HH:mm:ss")}` : "",
                Filter: (e) => DateColumnFilter({ ...e, dateFormat: "DD/MM/YYYY", dateMask: "99/99/9999", className: "datepicker-on-top" }),
                filter: "text",
                //flex: 1,
                width: 100,
                //disableSortBy: true,
                //disableFilters: true,
            },
            {
                Header: "Inactive#",
                accessor: "candidateInactiveDateId",
                //flex: 1,
                width: 60,
                //disableSortBy: true,
                //disableFilters: true,
            },
            {
                Header: "Actioned By#",
                accessor: "createdByNameAndEmail",
                flex: 1,
                //disableSortBy: true,
                //disableFilters: true,
            },
            {
                Header: "Action Type",
                id: "action",
                width: 75,
                accessor: (row) => !row.action ? "N/A" : row.action.toLowerCase() == "i" ? "Insert" : row.action.toLowerCase() == "u" ? "Update" : row.action.toLowerCase() == "d" ? "Delete" : row.action,
                flex: 1,
            },
            {
                Header: "Start Date",
                //accessor: "startDate",
                id: "startDate",
                accessor: (row) => row.startDate ? `${moment(row.startDate).format("DD/MM/YYYY")}` : "",
                Filter: (e) => DateColumnFilter({ ...e, dateFormat: "DD/MM/YYYY", dateMask: "99/99/9999", className: "datepicker-on-top" }),
                filter: "text",
                //flex: 1,
                width: 65,
                //disableSortBy: true,
                //disableFilters: true,
            },
            {
                Header: "End Date",
                id: "endDate",
                accessor: (row) => row.endDate ? `${moment(row.endDate).format("DD/MM/YYYY")}` : "",
                Filter: (e) => DateColumnFilter({ ...e, dateFormat: "DD/MM/YYYY", dateMask: "99/99/9999", className: "datepicker-on-top" }),
                //flex: 1,
                width: 65,
                //disableSortBy: true,
                //disableFilters: true,
            },
            {
                Header: "Notes",
                accessor: "reason",
                flex: 2,
            },
        ]
    }, [data]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data: data,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout
    );


    // END DATA LIST --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    // Error message handling
    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            message: errorMessage,
            title: errorTitle,
            onConfirm: () => {
                setErrorTitle(null);
                setErrorMessage(null);
            }
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage, errorTitle]);
    return (
        <Modal
            isOpen={true}
            //className="modal-xl"
            className="modal-fullscreen modal-fullscreen-xl"
            //modalClassName="db-example-modal-lg"
            //modalClassName="db-example-modal-lg"
        >
            {
                loading && <Loader />
            }
            {errorNotification.length > 0 && errorNotification}
            <div className="modal-header">
                <h5 className="modal-title">
                    Inactive Date Audits of {candidate?.candidateName}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={() => onClose()}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div className="modal-body">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <TooltipButton
                                id="showauditlog"
                                title="Export to Excel"
                                className="btn-icon mr-3 mb-2 mt-n3"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={() => {
                                    handleExport(true);
                                }}
                            >
                                <i className="fas fa-file-export pt-1 mr-1"></i> Export to Excel
                            </TooltipButton>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>

                            <Grid
                                rows={rows}
                                tableProps={getTableProps()}
                                headerGroups={headerGroups}
                                tableBodyProps={getTableBodyProps()}
                                prepareRow={prepareRow}
                                height={'calc(42vh)'}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="modal-footer">
                <Button
                    color="secondary"
                    onClick={() => onClose(null)}
                    type="button"
                >
                    Close
                </Button>
            </div>
        </Modal>
    );
};