import React, { useEffect, useState } from 'react';
import { useTable, useFlexLayout } from 'react-table';
import _ from "lodash";
import Grid from "../../../Grid";
import moment from "moment";

export default function Positions(props) {
    const skipPageResetRef = React.useRef();

    const {
        itemInEdit
    } = props;

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 150, 
            maxWidth: 200,
    }), []);

    // BEGIN INDEPENDENT DATA STATE ------------------------------------------------------------------------------------------------------------------
    const [data, setData] = useState([]);


    const refreshData = React.useCallback(() => {

        // Load data
        if (itemInEdit && itemInEdit.candidatePositions) {
            setData([...itemInEdit.candidatePositions]);
        }
        else {
            setData([]);
        }
    }, [itemInEdit]);

    React.useEffect(() => {

        // If already exists, cancel refresh
        if (data && data.length > 0) {
            return;
        }
        refreshData();
    }, [itemInEdit?.candidatePositions]);

    // END INDEPENDENT DATA STATE ------------------------------------------------------------------------------------------------------------------


    const columns = React.useMemo(() => {
        return [
            {
                Header: "Employed Position Name",
                accessor: "position.positionName",
            },
            {
                Header: "Entity",
                accessor: "company.companyName",
            },
            {
                Header: "Is Primary",
                accessor: (row) => row.isPrimary ? "Y" : "N",
            },
            {
                Header: "StartDate",
                accessor: (row) => row.startDate ? moment(row.startDate).format("DD/MM/YYYY") : "",
            },
            {
                Header: "EndDate",
                accessor: (row) => row.endDate ? moment(row.endDate).format("DD/MM/YYYY") : "",
            },
        ]
    }, [data]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data: data,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout
    );

    return (   
        <Grid
            rows={rows}
            tableProps={getTableProps()}
            headerGroups={headerGroups}
            tableBodyProps={getTableBodyProps()}
            prepareRow={prepareRow}
            height={'calc(42vh)'}
        />
    );
}