import React from 'react';
import api from "../../../services/api";
import {
    Input, Label, FormGroup, Button, Modal, Container, Row, Col, Alert, ButtonGroup
 } from "reactstrap"; 
import Loader from "../../loaders";
import { ComboBox } from "../../dropdowns";
import { ContractTermValidation } from "./Validation";
import { TooltipButton } from '../../inputs';
import fileDownload from "js-file-download";
import { TermValueSources } from '../../../utils';
import { FileDropzone, DropzoneList } from '../../FileDropzone';

export default function Editor(props) {
    const {
        item
    } = props;

    const [ itemInEdit, setItemInEdit ] = React.useState(item);
    const [ loading, setLoading ] = React.useState(false);
    const [ sectionType, setSectionType ] = React.useState(item ? (item.sectionType ?? {}) : {});
    const [ selectedValueSource, setSelectedValueSource ] = React.useState(item?.templateContent ? TermValueSources[1] : TermValueSources[0]);
    const [ validationAlert, setValidationAlert ] = React.useState([]);

    const apiurl = "contractterm";

    React.useEffect(() => {
        setItemInEdit( { ...itemInEdit, contractTermValue: selectedValueSource.key == 2 ? null : itemInEdit?.contractTermValue } );
    }, [selectedValueSource]);

    const handleTextChanged = ({ target: { name, value } }) => {
        setItemInEdit({ ...itemInEdit, [name]: value });
    }

    const validate = React.useCallback((itemToCheck) => {
        let errors = validationAlert.map(x => x);

        ContractTermValidation.forEach((value) => {
            value.tests.forEach((test) => {
                const exists = errors.some((item) => item.key === value.field && item.type === test.type);
                const result = test.validate(itemToCheck);

                if (!result && !exists)
                    errors.push( { key: value.field, type: test.type, message: test.message } )
                
                if (result && exists)
                    errors = errors.filter((item) => item.key !== value.field && item.type !== test.type);
            })
        });

        setValidationAlert(errors);
        return errors.length === 0;
    }, [itemInEdit, validationAlert]);

    const downloadAttachment = (fileInfo) => {
        if (fileInfo instanceof File) {
            fileDownload(fileInfo, fileInfo.name);
        } else {
            setLoading(true);
            let url = `contractterm/download/${fileInfo.contractTermId}`;
            api.get(url, { responseType: 'blob' })
            .then(blob => {
                fileDownload(blob.data, fileInfo.templateFilename);
            }).catch(error => {
                console.error(error)
            }).finally(() => setLoading(false))
        }
    }

    const files = React.useMemo(() => {
        if (selectedValueSource.key === 1)
            return null;

        if (itemInEdit?.file)
            return <DropzoneList
                name={itemInEdit.file.name}
                size={itemInEdit.file.size}
                download={downloadAttachment}
                itemInEdit={itemInEdit}
            />;
        
        if (itemInEdit?.templateFilename) {
            setSelectedValueSource(TermValueSources[1]);
            return <DropzoneList
                name={itemInEdit.templateFilename}
                size={itemInEdit.templateLength}
                download={downloadAttachment}
                itemInEdit={itemInEdit}
            />;
        }

        return null;
    }, [itemInEdit]);

    const handleSave = (item, type) => {
        item = {
            ...item,
            sectionTypeId: type.lookupId ?? 0
        };

        if (!validate(item))
            return;
        
        setLoading(true);
        const url = `${apiurl}${item.contractTermId ? `/${item.contractTermId}` : ""}`;
        const method = `${item.contractTermId ? "PUT" : "POST"}`;
        const formData = new FormData();

        formData.append("sectionTypeId", item.sectionTypeId);

        if(item.contractTermId)
            formData.append("contractTermId", item.contractTermId);

        formData.append("seqNo", item.seqNo ?? "0");
        formData.append("contractTermCode", item.contractTermCode);
        formData.append("contractTermTitle", item.contractTermTitle);
        formData.append("contractTermTitleDesc", item.contractTermTitleDesc ? item.contractTermTitleDesc : "");
        
        if(item.file) {
            formData.append("contractTermValue", "");
            formData.append("file", item.file);
            formData.append("templateContent", null);
            formData.append("templateContentType", "");
            formData.append("templateLength", "");
            formData.append("templateFilename", "");
        }
        
        if(!item.file)
        {
            formData.append("contractTermValue", item.contractTermValue ? item.contractTermValue : "");

            if(selectedValueSource.key == 1 && item.templateFilename)
            {
                formData.append("templateContent", null);
                formData.append("templateContentType", "");
                formData.append("templateLength", "");
                formData.append("templateFilename", "");
            }

            if(selectedValueSource.key == 2 && item.templateFilename)
            {
                formData.append("templateContent", item.templateContent);
                formData.append("templateContentType", item.templateContentType);
                formData.append("templateLength", item.templateLength);
                formData.append("templateFilename", item.templateFilename);
            }
        }
        
        // for (var pair of formData.entries())
        //     console.log(pair[0]+ ', ' + pair[1]);

        api({ url, method, data: formData })
        .then((response) => {
            setLoading(false);
            props.onSaved();
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
        });
    }

    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    {props.title ?? "Title"}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}>x</span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                {
                    validationAlert && validationAlert.length > 0 &&
                    <Alert color="warning" className="pa-0 d-flex justify-content-between">
                        <span className="alert-text">
                            <ul className="mb-0">
                                {
                                    validationAlert.map((item, index) => <li key={index}>{item.message}</li>)
                                }
                            </ul>
                        </span>
                    </Alert>
                }
                <Container>
                    {(loading) && <Loader />}
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Section Type
                                    <span className="text-danger">*</span>
                                </Label>
                                <ComboBox 
                                    endpoint="/contractSectionType"
                                    selectedItem={sectionType}
                                    onChange={setSectionType}
                                    minLength={2}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Code
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    name="contractTermCode"
                                    id="contractTermCode"
                                    type="text"
                                    required
                                    value={itemInEdit?.contractTermCode ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Title
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    name="contractTermTitle"
                                    id="contractTermTitle"
                                    type="text"
                                    required
                                    value={itemInEdit?.contractTermTitle ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Title Description
                                </Label>
                                <Input
                                    name="contractTermTitleDesc"
                                    id="contractTermTitleDesc"
                                    type="textarea"
                                    rows={6}
                                    value={itemInEdit?.contractTermTitleDesc ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <ButtonGroup size="sm">
                                <TooltipButton
                                    id="manualinput"
                                    active={selectedValueSource.key === 1}
                                    title="Manual Input"
                                    className="btn-icon mb-2"
                                    name="viewMode"
                                    value={TermValueSources[0]}
                                    onClick={() => {
                                        setSelectedValueSource(TermValueSources[0]);
                                    }}
                                >
                                    <i className="fas fa-pencil-alt pt-1"></i>
                                </TooltipButton>
                                <TooltipButton
                                    id="document"
                                    active={selectedValueSource.key === 2}
                                    title="Upload Document"
                                    className="btn-icon mb-2"
                                    name="viewMode"
                                    value={TermValueSources[1]}
                                    onClick={() => {
                                        setSelectedValueSource(TermValueSources[1]);
                                    }}
                                >
                                    <i className="fas fa-upload pt-1"></i>
                                </TooltipButton>
                            </ButtonGroup>
                        </Col>
                        {
                            (selectedValueSource.key == 1) &&
                            <Col xs={12}>
                                <FormGroup>
                                    <Label>
                                        Term Values
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        name="contractTermValue"
                                        id="contractTermValue"
                                        type="textarea"
                                        rows={6}
                                        value={itemInEdit?.contractTermValue ?? ""}
                                        onChange={handleTextChanged}
                                    />
                                </FormGroup>
                            </Col>
                        }
                        {
                            (selectedValueSource.key == 2) &&
                            <FileDropzone
                                files={files}
                                onDrop={([file]) => {
                                    setItemInEdit( { ...itemInEdit, file } );
                                }}
                            />
                        }
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={props.onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave(itemInEdit, sectionType)}
                    type="button"
                >
                    Save Changes
                </Button>
            </div>
        </Modal>
    )
}