const labourRequestValidation = [
    {
        field: "clientId",
        tests: [
            {
                type: "required",
                message: "Client is required.",
                validate: (obj) => !obj.clientId,
            }
        ]
    },
    {
        field: "projectId",
        tests: [
            {
                type: "required",
                message: "Site / Project is required.",
                validate: (obj) => !obj.projectId,
            }
        ]
    },
    // {
    //     field: "workorderId",
    //     tests: [
    //         {
    //             type: "required",
    //             message: "Payroll Default Cost Code is required.",
    //             validate: (obj) => !obj.workorderId,
    //         }
    //     ]
    // },
    // {
    //     field: "costCodeId",
    //     tests: [
    //         {
    //             type: "required",
    //             message: "Onboarding Cost Code is required.",
    //             validate: (obj) => !obj.costCodeId,
    //         }
    //     ]
    // },
    {
        field: "onBoardWorkorderId",
        tests: [
            {
                type: "required",
                message: "Onboarding Cost Code is required.",
                validate: (obj) => !obj.onBoardWorkorderId && !obj.newOnBoardWorkorder,
            }
        ]
    },
    {
        field: "locationId",
        tests: [
            {
                type: "required",
                message: "Location is required.",
                validate: (obj) => !obj.locationId,
            }
        ]
    },
    // {
    //     field: "departmentId",
    //     tests: [
    //         {
    //             type: "required",
    //             message: "Department is required.",
    //             validate: (obj) => !obj.departmentId,
    //         }
    //     ]
    // },
    {
        field: "recruitmentTypeId",
        tests: [
            {
                type: "required",
                message: "Recruitment Type is required.",
                validate: (obj) => !obj.recruitmentTypeId,
            }
        ]
    },
    {
        field: "recruitmentCategoryId",
        tests: [
            {
                type: "required",
                message: "Recruitment Category is required.",
                validate: (obj) => obj.recruitmentType?.lookupValue.toLowerCase().includes("white") && !obj.recruitmentCategoryId,
            }
        ]
    },
    {
        field: "isBudgeted",
        tests: [
            {
                type: "required",
                message: "Is Budgeted is required.",
                validate: (obj) => obj.recruitmentCategory?.lookupValue === "New Role" && obj.isBudgeted !== true && obj.isBudgeted !== false,
            }
        ]
    },
    {
        field: "isLikeForLike",
        tests: [
            {
                type: "required",
                message: "Like for Like is required.",
                validate: (obj) => obj.recruitmentCategory?.lookupValue === "Replacement" && obj.isLikeForLike !== true && obj.isLikeForLike !== false,
            }
        ]
    },
    {
        field: "attachment",
        tests: [
            {
                type: "required",
                message: "Attachment is required.",
                validate: (obj) => (obj.recruitmentCategory?.lookupValue === "New Role" || (obj.recruitmentCategory?.lookupValue === "Replacement" && obj.isLikeForLike === false)) && !obj.recruitmentRequestAttachments?.length,
            }
        ]
    },
    {
        field: "requestDetails",
        tests: [
            {
                type: "required",
                message: "At least one detail is required.",
                validate: (obj) => (!obj.requestDetails || obj.requestDetails.length === 0),
            },
            {
                type: "details",
                // message: "Detail - Employment Category, Roster Position, Employed Position, Quantity Required, Payroll Default Cost Code, Employment Type, Expected Start Date, Desired Entity and Reporting To must be filled.",
                // validate: (obj) => (obj.requestDetails?.filter(r => !r.employmentCategoryId || !r.hrispositionMasterId || !r.positionId || !r.quantityRequired || !r.workorderId || !r.employmentTypeId || !r.startDateExpected || !r.companyId || !r.reportingToName).length > 0),
                message: "Detail - Employment Category, Roster Position, Employed Position, Quantity Required, Employment Type, Expected Start Date, Desired Entity and Reporting To must be filled.",
                validate: (obj) => (obj.requestDetails?.filter(r => !r.employmentCategoryId || (obj.rosterId && !r.hrispositionMasterId) || !r.positionId || !r.quantityRequired || !r.employmentTypeId || !r.startDateExpected || !r.companyId || !r.reportingToName).length > 0),
                validateDetail: (obj) => {
                    const result = [];
                    const {requestDetails} = obj;

                    if (requestDetails?.filter(r => !r.employmentCategoryId).length > 0)
                        result.push("Employment Category");

                    if (obj.rosterId && requestDetails?.filter(r => !r.hrispositionMasterId).length > 0)
                        result.push("Roster Position");

                    if (requestDetails?.filter(r => !r.positionId).length > 0)
                        result.push("Employed Position");

                    if (requestDetails?.filter(r => !r.quantityRequired).length > 0)
                        result.push("Quantity Required");

                    // if (requestDetails?.filter(r => !r.workorderId).length > 0)
                    //     result.push("Payroll Default Cost Code");

                    if (requestDetails?.filter(r => !r.employmentTypeId).length > 0)
                        result.push("Employment Type");

                    if (requestDetails?.filter(r => !r.startDateExpected).length > 0)
                        result.push("Expected Start Date");

                    if (requestDetails?.filter(r => !r.companyId).length > 0)
                        result.push("Desired Entity");

                    if (requestDetails?.filter(r => !r.reportingToName).length > 0)
                        result.push("Reporting To");

                    return result;
                }
            }
        ]
    }
];

const labourRequestDetailValidation = [
    {
        field: "positionId",
        tests: [
            {
                type: "required",
                message: "Position is required.",
                validate: (obj) => !obj.positionId,
            }
        ]
    },
    {
        field: "rehireExceeds",
        tests: [
            {
                type: "invalid",
                message: "Rehire List must not exceed Quantity Required.",
                validate: (obj) => obj.quantityRequired && obj.requestDetailRehires && obj.requestDetailRehires.length > obj.quantityRequired,
            }
        ]
    },
    {
        field: "quantityRequired",
        tests: [
            {
                type: "required",
                message: "Quantity Required is required.",
                validate: (obj) => !obj.quantityRequired,
            }
        ]
    },
    {
        field: "employmentTypeId",
        tests: [
            {
                type: "required",
                message: "Employment Type is required.",
                validate: (obj) => !obj.employmentTypeId,
            }
        ]
    },
    {
        field: "employmentCategoryId",
        tests: [
            {
                type: "required",
                message: "Employment Category is required.",
                validate: (obj) => !obj.employmentCategoryId,
            }
        ]
    },
    {
        field: "reportingToName",
        tests: [
            {
                type: "required",
                message: "Reporting To is required.",
                validate: (obj) => !obj.reportingToName,
            }
        ]
    },
    {
        field: "startDateExpected",
        tests: [
            {
                type: "required",
                message: "Expected Start Date is required.",
                validate: (obj) => !obj.startDateExpected,
            }
        ]
    },
    {
        field: "finishDatePlanned",
        tests: [
            {
                type: "required",
                message: "Planned End Date is required.",
                validate: (obj) => {
                    if (obj.employmentType && (obj.employmentType.lookupValue === "Fixed Term")) {
                        return !obj.finishDatePlanned
                    }
                    return false;
                },
            }
        ]
    },
    {
        field: "companyId",
        tests: [
            {
                type: "required",
                message: "Desired Entity is required.",
                validate: (obj) => !obj.companyId,
            }
        ]
    },
    {
        field: "maxSalaryFrom",
        tests: [
            {
                type: "required",
                message: "Max Salary/Rate (From) is required.",
                validate: (obj) => {
                    if (obj.employmentCategory && obj.employmentCategory.lookupValue === "White Collar")
                        return !obj.maxSalaryFrom;
                    
                    return false;
                },
            }
        ]
    },
    {
        field: "maxSalaryTo",
        tests: [
            {
                type: "required",
                message: "Max Salary/Rate (To) is required.",
                validate: (obj) => {
                    if (obj.employmentCategory && obj.employmentCategory.lookupValue === "White Collar")
                        return !obj.maxSalaryTo;
                    
                    return false;
                },
            }
        ]
    },
    {
        field: "recruitmentCurrentEmployees.company",
        tests: [
            {
                type: "required",
                message: "Replaced Employee Details - Company is required.",
                validate: (obj, request) => {
                    if (obj.employmentCategory && obj.employmentCategory.lookupValue === "White Collar" && request.recruitmentCategory?.lookupValue === "Replacement")
                        return !obj.recruitmentCurrentEmployees.length || !obj.recruitmentCurrentEmployees[0].companyId;
                    
                    return false;
                },
            }
        ]
    },
    {
        field: "recruitmentCurrentEmployees.employee",
        tests: [
            {
                type: "required",
                message: "Replaced Employee Details - Employee is required.",
                validate: (obj, request) => {
                    if (obj.employmentCategory && obj.employmentCategory.lookupValue === "White Collar" && request.recruitmentCategory?.lookupValue === "Replacement")
                        return !obj.recruitmentCurrentEmployees.length || !obj.recruitmentCurrentEmployees[0].employeePayrollId;
                    
                    return false;
                },
            }
        ]
    },
];

export {
    labourRequestValidation,
    labourRequestDetailValidation
};