import React, { createContext, useReducer, useContext } from "react";
import jobOrderReducer, { initialState, actions } from "../reducers/jobOrderReducer";

export const JobOrderContext = createContext(initialState);

export const JobOrderProvider = ({ children }) => {
    const [state, dispatch] = useReducer(jobOrderReducer, initialState);

    const setNotification = (notification) => {
        dispatch({
            type: actions.SET_NOTIFICATION,
            payload: {
                notification: notification
            }
        });
    }

    const value = {
        setNotification,
        notification: state.notification,
    };
    return <JobOrderContext.Provider value={value}>{children}</JobOrderContext.Provider>;
}

const useJobOrder = () => {
    const context = useContext(JobOrderContext);
  
    if (context === undefined)
        throw new Error("useJobOrder must be used within JobOrderProvider");
  
    return context;
};
  
export default useJobOrder;
