import React, { useCallback, useState } from 'react';
import { useTable, useRowSelect, useFlexLayout, useFilters, useSortBy, useAsyncDebounce } from 'react-table';
import { useParams } from "react-router-dom";
import api from "../../../services/api";
import {
   Container, Row, Col, Button, Card, CardBody, CardTitle, Collapse, FormGroup, Label, Input
} from "reactstrap";
import Pager from "../../Pager";
//import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../alerts";
import Loader from "../../loaders";
import CandidateEditor from "./Editor";
import EditorSMS from "./EditorSMS";
import EditorEmail from './EditorEmail';
import EditorSkill from './EditorSkill';
import { DefaultColumnFilter } from "../../react-table/filters";
import qs from "qs";
import { TooltipButton } from "../../inputs";
import fileDownload from "js-file-download";
import _ from "lodash";
import Grid from "../../Grid";
import { ComboBox, DropdownBox } from '../../dropdowns';
import { formatDate } from '../../../utils';
import { DatePicker } from '../../date-pickers';
import moment from "moment";
import EditorComment from './EditorComment';

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, id, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef
  
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])
  
      return (
        <>
            <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" ref={resolvedRef} id={`candidate_${id}`} {...rest} />
                <label className="custom-control-label" htmlFor={`candidate_${id}`}></label>
            </div>
        </>
      )
    }
  )

function List(props) {
    const [ data, setData ] = React.useState([]);
    const [ loading, setLoading ] = React.useState(false);
    const [ itemInEdit, setItemInEdit ] = React.useState(null);
    const [ itemSMS, setItemSMS ] = React.useState([]);
    const [ itemEmail, setItemEmail ] = React.useState([]);
    const [ itemComment, setItemComment] = React.useState([]);
    const [ pageNumber, setPageNumber ] = React.useState(1);
    const [ pageSize, setPageSize ] = React.useState(100);
    const [ totalRecords, setTotalRecords ] = React.useState(0);
    const [ lookups, setLookups ] = React.useState({});
    const [ selectedCandidates, setSelectedCandidates ] = React.useState([]);
    const [ filters, setFilters ] = React.useState([]);
    const [ showSkillEditor, setShowSkillEditor ] = React.useState(false);
    const [ openFilter, setOpenFilter ] = useState(false);
    const [ advancedFilters, setAdvancedFilters ] = useState([]);

    const defaultSort = React.useMemo(() => [{ id: "surname", desc: false }],
        []
    );
    
    const [ sortBy, setSortBy ] = React.useState(defaultSort);

    const skipPageResetRef = React.useRef();
    const {id} = useParams();

    const loadData = React.useCallback(() => {
        skipPageResetRef.current = true;
        const queryString = qs.stringify({filters, sortBy, advancedFilters}, { allowDots: true });
        setItemInEdit(null);
        setItemSMS([]);
        setItemEmail([]);
        setItemComment([]);
        setShowSkillEditor(false);
        setData([]);
        setLoading(true);

        api.get(`/candidate/${(pageNumber - 1) * pageSize}/${pageSize}${queryString ? `?${queryString}` : ""}`)
        .then(({data}) => {
            setData(data.data);
            setTotalRecords(data.total);
        }).catch((error) => {
            console.error("error: ", error);
        }).finally(() => setLoading(false));
    }, [filters, sortBy, pageNumber, pageSize, advancedFilters]);

    React.useEffect(() => {
        skipPageResetRef.current = false;

        setLoading(true);
        let apiCalls = [
            api.get(`/lookup/skillposition`),
            api.get(`/lookup/candidatetype`),
            api.get(`/lookup/salutation`),
            api.get(`/lookup/preference`),
            api.get(`/lookup/industry`),
            api.get(`/lookup/gender`),
            api.get(`/lookup/maritalStatus`),
            api.get(`/lookup/candidateStatus`),
            api.get(`/lookup/candidateFlag`),
            api.get(`/lookup/residencyStatus`),
            api.get(`/lookup/visatype`),
            api.get(`/lookup/communicationtype`),
            api.get(`/lookup/country`),
            api.get(`/lookup/state`),
            api.get(`/lookup/relationshiptype`),
            api.get(`/lookup/candidateplatformorigin`),
            api.get(`/lookup/messagesmasters`),
            api.get(`/emailtemplate`), // TODO: returns paged result - move to lookup
            api.get(`/lookup/recruitmentpeople`),
            api.get(`/lookup/candidatediscipline`),
            api.get(`/lookup/company`),
            api.get(`/lookup/identifier`),
            api.get(`/lookup/candidateevent`),
            api.get(`/lookup/candidateeventstatus`),
            api.get(`/lookup/candidatecommenttype`),
            api.get(`/lookup/agency`),
            api.get(`contractingcompany/all`)
        ];

        Promise.all(apiCalls)
        .then((data) => {
            setLookups({
                skillPositions: data[0].data,
                candidateTypes: data[1].data,
                salutations: data[2].data,
                preferences: data[3].data,
                industries: data[4].data,
                genders: data[5].data,
                maritalStatuses: data[6].data,
                candidateStatuses: data[7].data,
                candidateFlags: data[8].data,
                residencyStatuses: data[9].data,
                visaTypes: data[10].data,
                communicationTypes: data[11].data,
                countries: data[12].data,
                states: data[13].data,
                relationshipTypes: data[14].data,
                candidatePlatformOrigin: data[15].data,
                messagesMaster: data[16].data,
                emailTemplate: data[17].data.data,
                recruitmentPeople: data[18].data,
                candidateDisciplines: data[19].data,
                companies: data[20].data,
                identifiers: data[21].data,
                events: data[22].data,
                eventStatus: data[23].data,
                candidateCommentTypes: data[24].data,
                agencies: data[25].data,
                contractingCompanies: data[26].data
            });
            if (id) {
                setItemInEdit({candidateId: id});
            }
        }).finally(() => setLoading(false));
        
    }, []);

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
                })
            },
        }),
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            width: 190,
            disableSortBy: true,
    }), []);

    const mergeCandidates = React.useCallback(() => {
        if (selectedCandidates && selectedCandidates.length === 2) {
            const [ principalCandidateId, mergeCandidateId ] = selectedCandidates;
            console.log("Valid Merge: ", { principalCandidateId, mergeCandidateId, selectedCandidates });

            api.post("/candidate/merge", { principalCandidateId, mergeCandidateId })
            .then(response => {
                console.log("Merge: ", response);
            }).catch((error) => {
                console.error("error: ", error);
            }).finally(() => setLoading(false));
        } else {
            console.log("Invalid Merge: ", { selectedCandidates });
        }
    }, [selectedCandidates])

    const selectCandidates = React.useCallback((target, row) => {
        console.log("change: ", target, target.checked);
        toggleRowSelected(row.id, target.checked);
        if (target.checked) {
            const newList = [ ...selectedCandidates, row.original.candidateId ];
            console.log("newList: ", newList, selectedCandidates);
            setSelectedCandidates(newList);
        } else {
            setSelectedCandidates(selectedCandidates.filter(o => o !== row.original.candidateId));

        }
    }, [selectedCandidates]);

    const columns = React.useMemo(() => {
        return [
            {
                id: 'selection',
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <div>
                    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} id="all" />
                  </div>
                ),
                // The cell can use the individual row's getToggleRowSelectedProps method
                // to the render a checkbox
                Cell: ({ row }) => (
                  <div>
                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} id={row.original.candidateId} onChange={({target}) => selectCandidates(target, row)} />
                  </div>
                ),
                width: 30,
            },
            {
                Header: "Surname",
                accessor: "surname",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "First Name",
                accessor: "firstName",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "Employee Number",
                accessor: "employeeNumber",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "State",
                accessor: (row) => row.state?.lookupValue,
                id: "state",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            // {
            //     Header: "Middle Name",
            //     accessor: "middleName",
            //     Filter: DefaultColumnFilter,
            //     filter: "text",
            //     disableSortBy: false,
            // },
            // {
            //     Header: "Preferred Name",
            //     accessor: "preferredName",
            //     Filter: DefaultColumnFilter,
            //     filter: "text",
            //     disableSortBy: false,
            // },
            // {
            //     Header: "DOB",
            //     accessor: (row) => row.doB ? `${moment(row.doB).format("DD/MM/YYYY")}` : "", //"doB",
            //     // Filter: DefaultColumnFilter,
            //     // filter: 'text',
            //     disableFilters: true
            // },
            {
                Header: "Mobile",
                accessor: (row) => {
                    if (row.mobilePhoneNumber && row.mobilePhoneNumber !== "") 
                    {
                        const match = row.mobilePhoneNumber.match(/^([\dX]{4})([\dX]{3})([\dX]{3})$/)
                        if (match)
                        {
                            return `${match[1]} ${match[2]} ${match[3]}`;
                        }
                    }
                    return row.mobilePhoneNumber;
                },
                id: "mobilephonenumber",
                Filter: DefaultColumnFilter,
                filter: "text",
            },
            {
                Header: "Email",
                accessor: "email",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            // {
            //     Header: "Recruitment Person",
            //     accessor: "recruitmentPerson",
            //     Filter: DefaultColumnFilter,
            //     filter: "text",
            //     disableSortBy: false,
            // },
            // {
            //     Header: "Project",
            //     accessor: "allocationStatus.projectName",
            //     Filter: DefaultColumnFilter,
            //     filter: "text",
            //     disableSortBy: false,
            // },
            {
                Header: "Position",
                accessor: "position.positionName",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "Advertising Reference",
                accessor: "adsReference",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "Status",
                accessor: "jobOrderStatus",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "Action",
                id: 'button',
                Cell: ({row:{original}}) => (<TooltipButton
                    id={`edit_${original.candidateId}`}
                    title="Edit"
                    className="btn-icon"
                    color="default"
                    size="xsm"
                    type="button"
                    onClick={() => setItemInEdit(original)}
                >
                    <i className="fas fa-pencil-alt pt-1"></i>
                </TooltipButton>),
                width: 120,
            }
        ]
    }, [selectCandidates]);


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,     
        selectedFlatRows,
        toggleRowSelected
    } = useTable(
        {
            columns,
            data,
            initialState: {
                "hiddenColumns": [],
                sortBy: defaultSort,
                canSort: true,
            },
            defaultColumn,
            manualSortBy: true,
            filterTypes,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        useFilters,
        useSortBy,
        useRowSelect,
        useFlexLayout,
        // hooks => {
        //   hooks.visibleColumns.push(columns => [
        //     // Let's make a column for selection
        //     {
        //       id: 'selection',
        //       // The header can use the table's getToggleAllRowsSelectedProps method
        //       // to render a checkbox
        //       Header: ({ getToggleAllRowsSelectedProps }) => (
        //         <div>
        //           <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} id="all" />
        //         </div>
        //       ),
        //       // The cell can use the individual row's getToggleRowSelectedProps method
        //       // to the render a checkbox
        //       Cell: ({ row }) => (
        //         <div>
        //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} id={row.original.candidateId} onChange={({target}) => selectCandidates(target, row)} />
        //         </div>
        //       ),
        //       width: 30,
        //     },
        //     ...columns,
        //   ])
        // }
    )

    React.useEffect(() => {
        console.log('selectedFlatRows', selectedFlatRows);
    }, [selectedFlatRows]);

    React.useEffect(() => {
        loadData();
    }, [pageNumber, pageSize, filters, sortBy]);

    React.useEffect(() => {
        setFilters(state.filters);
    }, [state.filters]);

    React.useEffect(() => {
        setSortBy(state.sortBy);
    }, [state.sortBy]);

    React.useEffect(() => {
        console.log(selectedCandidates);
    }, [selectedCandidates]);

    const handleSetAdvancedFilters = useCallback((name, data) => {
        console.log("currentFilter: ", {advancedFilters, data});
        const newFilters = [...advancedFilters];
        const index = newFilters.indexOf(newFilters.filter(f => f.id === name)[0]);
        console.log("currentIndex: ", {index});

        if (data && index > -1)
            newFilters.splice(index, 1, {id: name, value: data.lookupValue ?? data, lookupId: data.lookupId});
        
        if (!data && index > -1)
            newFilters.splice(index, 1);

        if (data && index === -1)
            newFilters.push({id: name, value: data.lookupValue ?? data, lookupId: data.lookupId});

        console.log({newFilters});
        setAdvancedFilters([...newFilters]);
    }, [advancedFilters]);

    return (
        <section className="main">
            {loading && <Loader />}
            {/* {deleteAlert.length > 0 && deleteAlert} */}
            <Container fluid>
                <Row>
                    <Col>
                        <Card className="no-transition">
                            <CardBody>
                                <Row>
                                    <Col lg={12}>
                                        <CardTitle>
                                            <h4 className="text-center">Talents</h4>
                                        </CardTitle>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <TooltipButton
                                            id="addnew"
                                            title="Add New"
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={() => { 
                                                setItemInEdit({
                                                    candidateId: 0,
                                                    receivingCommunication: false,
                                                    receivingEmail: false,
                                                    receivingSms: false,
                                                    mailingAddressSameAsMain: true
                                                })  
                                            }}
                                        >
                                            <i className="fas fa-plus pt-1"></i>
                                        </TooltipButton>
                                        <TooltipButton
                                            id="sendsms"
                                            title="Send Bulk SMS"
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={() => { 
                                                setItemSMS(selectedFlatRows.map(d => d.original))
                                            }}
                                        >
                                            <i className="fas fa-comment mr-1"></i> Send SMS
                                        </TooltipButton>
                                        <TooltipButton
                                            id="sendemail"
                                            title="Send Bulk Email"
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={() => { 
                                                setItemEmail(selectedFlatRows.map(d => d.original))
                                            }}
                                        >
                                            <i className="fas fa-envelope-open"></i> Send Email
                                        </TooltipButton>
                                        <TooltipButton
                                    id="addcomment"
                                    title="Add Bulk Comment"
                                    className="btn-icon ml-2 mb-2"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        setItemComment(selectedFlatRows.map(d => d.original))
                                    }}
                                >
                                    <i className="fas fa-comment mr-1"></i> Add Comment
                                </TooltipButton>
                                <TooltipButton
                                            id="export"
                                            title="Export to Excel"
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={() => { 
                                                
                                                console.log("Filters: ", state.filters);
                                                const queryString = qs.stringify({filters: [...state.filters]}, { allowDots: true });

                                                setLoading(true);
                                                let url = `candidate/export${queryString ? `?${queryString}` : ""}`;
                                                api.get(url, { responseType: 'blob' })
                                                .then(blob => {                
                                                    console.log("Download: ", blob);
                                                    fileDownload(blob.data, "candidates.xlsx");
                                                }).catch(error => {
                                                    console.error(error)
                                                }).finally(() => setLoading(false))

                                            }}
                                        >
                                            <i className="fas fa-file-excel"></i> Export
                                        </TooltipButton>
                                        <TooltipButton
                                            id="merge"
                                            title="Merge Candidates"
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={mergeCandidates}
                                        >
                                            <i className="fas fa-object-group"></i> Merge
                                        </TooltipButton>
                                        <TooltipButton
                                            id="skill"
                                            title="Bulk Add Skills"
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            disabled={!selectedCandidates || selectedCandidates.length === 0}
                                            onClick={() => setShowSkillEditor(true)}
                                        >
                                            <i className="fas fa-object-group"></i> Bulk Add Skills
                                        </TooltipButton>
                                    </Col>
                                </Row>
                                <Row className="my-2">
                                    <Col lg={12}>
                                        <Button
                                            aria-expanded={openFilter}
                                            onClick={() =>
                                                setOpenFilter(!openFilter)
                                            }
                                            className="w-100 text-primary text-left no-shadow"
                                            color="link"
                                            type="button"
                                        >
                                            Advanced Filters
                                            <i className="ni ni-bold-down float-right pt-1"></i>
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <Collapse isOpen={openFilter} className="mb-2">
                                            <Row>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Country
                                                        </Label>
                                                        <DropdownBox
                                                            data={lookups?.countries ?? []}
                                                            isLookup={true}
                                                            selectedItem={advancedFilters?.filter(f => f.id === "Country").map(f => ({lookupId: f.lookupId, lookupValue: f.value}))[0]}
                                                            onChange={(item) => handleSetAdvancedFilters("Country", item)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            State
                                                        </Label>
                                                        <DropdownBox
                                                            data={lookups?.states ?? []}
                                                            isLookup={true}
                                                            selectedItem={advancedFilters?.filter(f => f.id === "State").map(f => ({lookupId: f.lookupId, lookupValue: f.value}))[0]}
                                                            onChange={(item) => handleSetAdvancedFilters("State", item)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            City
                                                        </Label>
                                                        <Input
                                                            value={advancedFilters.filter(a => a.id === "City")[0]?.value ?? ""}
                                                            onChange={(e) => handleSetAdvancedFilters("City", e.target.value)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Postcode
                                                        </Label>
                                                        <Input
                                                            value={advancedFilters.filter(a => a.id === "Postcode")[0]?.value ?? ""}
                                                            onChange={(e) => handleSetAdvancedFilters("Postcode", e.target.value)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Residency Status
                                                        </Label>
                                                        <DropdownBox
                                                            data={lookups?.residencyStatuses ?? []}
                                                            isLookup={true}
                                                            selectedItem={advancedFilters?.filter(f => f.id === "ResidencyStatus").map(f => ({lookupId: f.lookupId, lookupValue: f.value}))[0]}
                                                            onChange={(item) => handleSetAdvancedFilters("ResidencyStatus", item)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Nationality
                                                        </Label>
                                                        <Input
                                                            value={advancedFilters.filter(a => a.id === "Nationality")[0]?.value ?? ""}
                                                            onChange={(e) => handleSetAdvancedFilters("Nationality", e.target.value)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Skill
                                                        </Label>
                                                        <ComboBox
                                                            endpoint={`/trainingmasterskill`}
                                                            selectedItem={advancedFilters?.filter(f => f.id === "Skill").map(f => ({lookupId: f.lookupId, lookupValue: f.value}))[0]}
                                                            onChange={(item) => handleSetAdvancedFilters("Skill", item)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Min. Expiry Date
                                                        </Label>
                                                        <DatePicker
                                                            name="ExpiryDate"
                                                            id="ExpiryDate"
                                                            type="text"
                                                            value={formatDate(advancedFilters.filter(a => a.id === "ExpiryDate")[0]?.value ?? "")}
                                                            onChange={value => handleSetAdvancedFilters("ExpiryDate", !value ? null : moment(value).format("YYYY-MM-DDT00:00:00"))}
                                                            closeOnSelect
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Project
                                                        </Label>
                                                        <ComboBox
                                                            endpoint={`/project`}
                                                            selectedItem={advancedFilters?.filter(f => f.id === "Project").map(f => ({lookupId: f.lookupId, lookupValue: f.value}))[0]}
                                                            onChange={(item) => handleSetAdvancedFilters("Project", item)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Location
                                                        </Label>
                                                        <ComboBox
                                                            endpoint={`/location`}
                                                            selectedItem={advancedFilters?.filter(f => f.id === "Location").map(f => ({lookupId: f.lookupId, lookupValue: f.value}))[0]}
                                                            onChange={(item) => handleSetAdvancedFilters("Location", item)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Available Date Range
                                                        </Label>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <DatePicker
                                                                    name="AvailableDateRangeStart"
                                                                    id="AvailableDateRangeStart"
                                                                    type="text"
                                                                    value={formatDate(advancedFilters.filter(a => a.id === "AvailableDateRangeStart")[0]?.value ?? "")}
                                                                    onChange={value => handleSetAdvancedFilters("AvailableDateRangeStart", !value ? null : moment(value).format("YYYY-MM-DDT00:00:00"))}
                                                                    closeOnSelect
                                                                />
                                                            </Col>
                                                            <Col lg={6}>
                                                                <DatePicker
                                                                    name="AvailableDateRangeEnd"
                                                                    id="AvailableDateRangeEnd"
                                                                    type="text"
                                                                    value={formatDate(advancedFilters.filter(a => a.id === "AvailableDateRangeEnd")[0]?.value ?? "")}
                                                                    onChange={value => handleSetAdvancedFilters("AvailableDateRangeEnd", !value ? null : moment(value).format("YYYY-MM-DDT00:00:00"))}
                                                                    closeOnSelect
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Has Selected Document Types
                                                        </Label>
                                                        <DropdownBox
                                                            data={[{lookupId: 1, lookupValue: "Yes"}, {lookupId: 2, lookupValue: "No"}]}
                                                            isLookup={true}
                                                            selectedItem={advancedFilters?.filter(f => f.id === "HasSelectedDocTypes").map(f => ({lookupId: f.lookupId, lookupValue: f.value}))[0]}
                                                            onChange={(item) => handleSetAdvancedFilters("HasSelectedDocTypes", item)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} className="d-flex flex-wrap align-items-center justify-content-end">
                                                    <Button
                                                        color="secondary"
                                                        onClick={() => loadData()}
                                                        type="button"
                                                    >
                                                        Filter
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Collapse>
                                    </Col>
                                </Row>

                                <Grid
                                    height={'calc(100vh - 520px)'}
                                    totalRecords={totalRecords}
                                    pageSize={pageSize}
                                    rows={rows}
                                    tableProps={getTableProps()}
                                    headerGroups={headerGroups}
                                    tableBodyProps={getTableBodyProps()}
                                    prepareRow={prepareRow}
                                    onPagerChangePage={(pager) => {
                                        setPageNumber(pager.currentPage);
                                    }}
                                    onPagerChangePageSize={(size) => {
                                        setPageSize(size);
                                    }}
                                    needPaging={true}
                                />

                                {/* <Table>
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>First Name</th>
                                            <th>Surname</th>
                                            <th>Preferred Name</th>
                                            
                                            <th className="text-right">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data && data.length > 0 && data.map(renderTableRow)
                                        }
                                    </tbody>
                                </Table> */}
                                {
                                    !!itemInEdit && 
                                    <CandidateEditor
                                        item={itemInEdit} 
                                        onClose={() => loadData(state.filters, state.sortBy)} 
                                        onSaved={() => loadData(state.filters, state.sortBy)}
                                        lookups={{
                                            ...lookups
                                        }}
                                    />
                                }
                                {
                                    !!itemSMS && itemSMS.length > 0 &&
                                    <EditorSMS
                                        items={itemSMS} 
                                        onClose={() => setItemSMS([])} 
                                        onSaved={() => loadData(state.filters, state.sortBy)}
                                        lookups={{
                                            ...lookups
                                        }}
                                    />
                                }
                                {
                                    !!itemEmail && itemEmail.length > 0 &&
                                    <EditorEmail
                                        items={itemEmail} 
                                        onClose={() => setItemEmail([])} 
                                        onSaved={() => loadData(state.filters, state.sortBy)}
                                        lookups={{
                                            ...lookups
                                        }}
                                    />
                                }
                                {
                                    !!itemComment && itemComment.length > 0 &&
                                    <EditorComment
                                        items={itemComment}
                                        onClose={() => setItemComment([])}
                                        onSaved={() => loadData(state.filters, state.sortBy)}
                                        lookups={{
                                            ...lookups
                                        }}
                                    />
                                }
                                {
                                    showSkillEditor && selectedCandidates && selectedCandidates.length > 0 &&
                                    <EditorSkill
                                        items={selectedCandidates}
                                        onClose={() => setShowSkillEditor(false)}
                                        onSaved={() => loadData(state.filters, state.sortBy)}
                                        lookups={{
                                            ...lookups
                                        }}
                                    />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default List;
