import React, { useCallback } from 'react';
import { useHistory, useParams } from "react-router-dom";
import api from "../../../services/api";
import { ComboBox } from "../../dropdowns";
import {
   Container, Row, Col, Label, FormGroup, Button, Card, CardBody, CardTitle, ButtonGroup, UncontrolledTooltip
} from "reactstrap";
import MatrixEditor from "./MatrixEditor";
import TnaSetup from "./Setup"
import Loader from "../../loaders";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert, MissingApproverAlert } from "../../alerts";
import Matrix from "./Matrix";
import HotMatrix from "./HotMatrix";
import List from "./List";
import { TooltipButton } from "../../inputs";
import _ from 'lodash';
import PositionSetup from "./PositionSetup";
import fileDownload from "js-file-download";
import qs from "qs";

function View(props) {
    const { id } = useParams();
    let history = useHistory();

    const [ matrixCols, setMatrixCols ] = React.useState([]);
    const [ matrixData, setMatrixData ] = React.useState([]);  
    const [ listData, setListData ] = React.useState([]); 
    const [ listFilters, setListFilters ] = React.useState([]); 
    const initialSetup = { positions: [], groups: []};
    const [ setupData, setSetupData ] = React.useState(initialSetup); 
    const [ setupOpen, setSetupOpen ] = React.useState(false); 

    const [ selectedProject, setSelectedProject ] = React.useState(null);
    const [ tnaStatus, setTnaStatus ] = React.useState({});
    const [ loading, setLoading ] = React.useState(false);
    const [ itemInEdit, setItemInEdit ] = React.useState(null);
    const [ isChanged, setIsChanged ] = React.useState(false);
    const [ matrixSave, setMatrixSave ] = React.useState(false);
    const [ viewMode, setViewMode ] = React.useState("matrix");
    
    const [ trainingGroups, setTrainingGroups ] = React.useState([]);
    const [ fundingSources, setFundingSources ] = React.useState([]);
    const [ trainingProviders, setTrainingProviders ] = React.useState([]);
    const [ trainingRequirements, setTrainingRequirements ] = React.useState([]);
    const [ positionInEdit, setPositionInEdit ] = React.useState(null);

    const [ deleteAlert, setDeleteAlert ] = React.useState([]);
    const [ approverAlert, setApproverAlert ] = React.useState([]);
    const skipPageResetRef = React.useRef();


    const loadMatrix = (id) => {

        setLoading(true);

        api.get(`/trainingneedanalysis/tnamatrix/${id}`)
        .then(({ data }) => {
            //console.log('matrix data', 'data', data);
            setMatrixData(data.rows);
            setMatrixCols(data.cols);
        })
        .catch((error) => {
            console.error("error: ", error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const loadList = (id) => {
        setLoading(true);
        skipPageResetRef.current = true;

        api.get(`/trainingneedanalysis/${id}`)
        .then(({ data }) => {
            setListData(data);
        })
        .catch((error) => console.error("error: ", error))
        .finally(() => setLoading(false));
    }

    React.useEffect(() => {
        skipPageResetRef.current = false;
        setLoading(true);

        let apiCalls = [
            api.get("/lookup/traininggroup"),
            api.get("/lookup/fundingsource"),
            api.get("/lookup/trainingprovider"),
            api.get("/lookup/tnavalue"),
        ];

        if (id) {
            apiCalls.push(api.get(`project/${id}`));
        }

        Promise.all(apiCalls)
        .then((response) => {
            setTrainingGroups(response[0].data);
            setFundingSources(response[1].data);
            setTrainingProviders(response[2].data);
            setTrainingRequirements(response[3].data);

            if (id) {
                setSelectedProject(response[4].data);
                history.push("/tna")
            }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => setLoading(false));

    }, [id]);

    const loadSetup = (projectId) => {
        api
        .get(`/trainingneedanalysis/setup/${projectId}`)
        .then(({data}) => {
            const groups = _.keyBy(data.groups, o => o.trainingGroupId);
            const setup = {
                positions: data.positions,
                groups,
            }
            setSetupData(setup);
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const emptyData = React.useCallback(() => {

        setListData([]);
        setMatrixCols([]);
        setMatrixData([]);
        setSetupData(initialSetup);
        setViewMode("");

    }, [listData, matrixCols, matrixData, setupData, viewMode]);

    const reloadAll = React.useCallback(() => {
        //console.log("reloadAll");

        // Empty data and reset view mode
        var tempViewMode = viewMode;
        emptyData();
        setViewMode(tempViewMode);

        if (selectedProject) {

            loadSetup(selectedProject.projectId);

            if (viewMode === "list") {
                loadList(selectedProject.projectId);
            } else if (viewMode === "matrix") {
                loadMatrix(selectedProject.projectId);
            }

        } 


    }, [viewMode, selectedProject]);
    //const forceReloadView = React.useCallback((viewTo, ) => {
    //    setViewMode(viewTo);
    //    reloadAll();
    //}, [viewMode, selectedProject]);

    React.useEffect(() => {
        reloadAll();
    }, [viewMode, selectedProject]);

    React.useEffect(() => {
        //console.log("matrixCols", matrixCols);
    }, [matrixCols]);

    React.useEffect(() => {
        //console.log("matrixData", matrixData);
    }, [matrixData]);

    React.useEffect(() => {
        if (selectedProject) {
            api.get(`/trainingneedanalysis/status/${selectedProject.projectId}`)
            .then((response) => {
                setTnaStatus(response.data);
            })
            .catch((error) => {
              console.error("error: ", error);
            });
        } else {
            setTnaStatus({});
        }

    }, [selectedProject])

    
    const handleDelete = (item) => {

        const success = () => {
            setDeleteAlert([<DeleteSuccessAlert onConfirm={() => setDeleteAlert([])} />]);
            loadList();
        };
        const failure = () => setDeleteAlert([<DeleteFailedAlert onConfirm={() => setDeleteAlert([])} />]);
        const events = {
            onConfirm: () => api.delete(`/trainingneedanalysis/${item.trainingNeedAnalysisId}`).then(success).catch(failure),
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert {...events} />])
    }

    const handleSubmit = (approved = false) => {

        setLoading(true);

        api.put(`trainingneedanalysis/status/${selectedProject.projectId}/${approved}`)
        .then((response) => {
            setTnaStatus(response.data);
            //history.push("/")
        })
        .catch((error) => {
            const project = error?.response?.data;
            if (project && project.projectId) {
                setApproverAlert([<MissingApproverAlert 
                    onConfirm={() => setApproverAlert([])} 
                    message={`Training Needs Analysis Approver not set for ${project.projectName}`}
                />]);
            } else {
                console.error(error);
            }
            
        })
        .finally(() => setLoading(false));
    } 

    const handleClear = () => {
        
        let apiDelete;

        if (!!positionInEdit)
        {
            const trainings = _.flatten(_.values(positionInEdit.groups).map(g => g.skills.map(s => s.tnaTrainingMasterId)));
            const positions = [positionInEdit.position.tnaPositionId];
            
            apiDelete = api.delete(`trainingneedanalysis/0`, {
                data: JSON.stringify({
                    tnaTrainingMasterIds: trainings,
                    tnaPositionIds: positions,
                    projectId: selectedProject.projectId
                })
            });
        }
        else 
        {
            apiDelete = itemInEdit.trainingNeedAnalysisId 
            ? api.delete(`trainingneedanalysis/${itemInEdit.trainingNeedAnalysisId}`)
            : api.delete(`trainingneedanalysis/0`, {
                data: JSON.stringify({
                    tnaTrainingMasterIds: itemInEdit.tnaTrainingMasterIds,
                    tnaPositionIds: itemInEdit.tnaPositionIds,
                    projectId: selectedProject.projectId
                })
            });
        }

        apiDelete
        .then((response) => console.info(response.status))
        .catch((error) => console.error(error))
        .finally(() => {
            setItemInEdit(null);
            setPositionInEdit(null);
            loadMatrix(selectedProject.projectId);
        });
    };

    const openRosterTNAExport = useCallback(() => {
        const params = {
            projectId: selectedProject?.projectId
        }

        window.open(`/reports/rostertnaexport?${qs.stringify(params)}`, "_blank")
    }, [selectedProject]);

    return (
        <section className="main">
            {deleteAlert.length > 0 && deleteAlert}
            {approverAlert.length > 0 && approverAlert}
            {loading && <Loader />}
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card className="no-transition">
                            <CardBody>
                                <CardTitle>
                                    <h4 className="text-center">Training Needs Analysis</h4>
                                </CardTitle>
                                <Container fluid>
                                    <Row>
                                        <Col sm={12} md={{ size: 4, offset: 4 }}>
                                            <FormGroup>
                                                <Label>
                                                    Project
                                                </Label>
                                                <ComboBox 
                                                    endpoint="/project/search"
                                                    isLookup={false}
                                                    //accessToken={token?.accessToken}
                                                    idField="projectId"
                                                    valueField="projectName"
                                                    selectedItem={selectedProject}
                                                    onChange={setSelectedProject}
                                                    minLength={0}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={{ size: 4, offset: 4 }}>
                                            {!!selectedProject && <Label>                                                
                                                Status: {tnaStatus.status ?? "Draft"}
                                            </Label>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                        {
                                            viewMode === "matrix" &&
                                            <>
                                                <TooltipButton
                                                    id="backBtn"
                                                    title="Back"
                                                    className="btn-icon mb-2"
                                                    color="default"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => {  
                                                        history.push("/activetna");
                                                    }}
                                                >
                                                    <i className="fas fa-arrow-left pt-1"></i> 
                                                </TooltipButton>
                                                <TooltipButton
                                                    id="setupBtn"
                                                    title="Setup"
                                                    className="btn-icon mb-2"
                                                    color="default"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => {  
                                                        setSetupOpen(true)
                                                    }}
                                                    disabled={!selectedProject}
                                                >
                                                    <i className="fas fa-cog pt-1"></i> 
                                                </TooltipButton>
                                                <TooltipButton
                                                    id="addnew"
                                                    title="Export"
                                                    className="btn-icon mb-2"
                                                    color="default"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => {  
                                                        openRosterTNAExport();
                                                    }}
                                                    disabled={!selectedProject}
                                                >
                                                    <i className="fas fa-file-export pt-1"></i> Export
                                                </TooltipButton>
                                            </>
                                        }
                                            {viewMode === "list" && <TooltipButton
                                                    id="export"
                                                    title="Export to Excel"
                                                    className="btn-icon ml-2 mb-2"
                                                    color="default"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => { 

                                                        const queryString = qs.stringify({filters: [...listFilters]}, { allowDots: true });
                                                        console.log("Filters: ", {listFilters,queryString});

                                                        setLoading(true);
                                                        let url = `trainingneedanalysis/export/${selectedProject.projectId}${queryString ? `?${queryString}` : ""}`;
                                                        api.get(url, { responseType: 'blob' })
                                                        .then(blob => {                
                                                            console.log("Download: ", blob);
                                                            fileDownload(blob.data, `TNA ${selectedProject.projectName}.xlsx`);
                                                        }).catch(error => {
                                                            console.error(error)
                                                        }).finally(() => setLoading(false))
                                                    }}
                                                >
                                                    <i className="fas fa-file-excel"></i> Export
                                                </TooltipButton>
                                            }
                                            {/* {viewMode === "list" && <TooltipButton
                                                id="addnew"
                                                title="Add New"
                                                className="btn-icon ml-2 mb-2"
                                                color="default"
                                                size="sm"
                                                type="button"
                                                onClick={() => {  
                                                    setItemInEdit({
                                                        projectId: selectedProject.projectId,
                                                        startDate: moment(),
                                                        trainingNeedAnalysisId: 0,
                                                    });
                                                }}
                                                disabled={!selectedProject}
                                            >
                                                <i className="fas fa-plus pt-1"></i>
                                            </TooltipButton>} */}

                                            <ButtonGroup size="sm" style={{float:"right"}}>
                                                <TooltipButton 
                                                    disabled={!selectedProject}
                                                    id="listview"
                                                    title="Tabular View"
                                                    className="btn-icon mb-2"
                                                    name="viewMode"
                                                    active={viewMode === "list"}
                                                    value="list"
                                                    onClick={(e) => { 
                                                        console.log(viewMode)
                                                        setViewMode(e.currentTarget.value)
                                                    }}
                                                >
                                                    <i className="fas fa-th-list pt-1"></i>
                                                </TooltipButton>
                                                <TooltipButton 
                                                    disabled={!selectedProject}
                                                    id="matrixview"
                                                    title="Matrix View"
                                                    className="btn-icon mb-2"
                                                    name="viewMode"
                                                    active={viewMode === "matrix"}
                                                    value="matrix"
                                                    onClick={(e) => {
                                                        console.log(viewMode)
                                                        setViewMode(e.currentTarget.value)
                                                    }}
                                                >
                                                    <i className="fas fa-table pt-1"></i>
                                                </TooltipButton>
                                            </ButtonGroup>
                                        </Col>
                                        <Col xs={12}>
                                            {selectedProject && viewMode === "list" ? (
                                                <List 
                                                    selectedProject={selectedProject} 
                                                    data={listData}
                                                    handleDelete={handleDelete} 
                                                    setItemInEdit={setItemInEdit} 
                                                    handleFilterChange={setListFilters}
                                                    lookups={{
                                                        trainingGroups,
                                                        fundingSources,
                                                        trainingProviders,
                                                        trainingRequirements
                                                    }}
                                                />
                                            )
                                            : selectedProject && viewMode === "matrix" ? (
                                                <HotMatrix 
                                                    selectedProject={selectedProject} 
                                                    data={matrixData} 
                                                    cols={matrixCols} 
                                                    setItemInEdit={setItemInEdit} 
                                                    setPositionInEdit={setPositionInEdit}
                                                    setLoading={setLoading}
                                                    onChanged={setIsChanged}
                                                    matrixSave={matrixSave}
                                                    onAutoSaved={() => {
                                                        setMatrixSave(false);
                                                        reloadAll();
                                                        //loadMatrix(selectedProject.projectId);
                                                    }}
                                                    lookups={{
                                                        trainingGroups,
                                                        fundingSources,
                                                        trainingProviders,
                                                        trainingRequirements
                                                    }}
                                                />
                                            ) : <></>} 

                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col sm={12} className="d-flex justify-content-center">
                                            <Button
                                                disabled={!selectedProject}
                                                className="btn py-2 px-3"
                                                color="default"
                                                size="sm"
                                                type="button"
                                                onClick={() => {
                                                    handleSubmit(!!tnaStatus.status && (tnaStatus.status === "Submitted" || tnaStatus.status !== "Approved"))
                                                }}
                                                //disabled={listData.length === 0}
                                            >
                                                {tnaStatus.status && tnaStatus.status === "Submitted" 
                                                ? "Mark Complete" 
                                                : tnaStatus.status && tnaStatus.status === "Approved" ? "Re-Submit for Review" : "Submit for Review"
                                                }
                                            </Button>
                                        {
                                            viewMode === "matrix" &&
                                            <Button
                                                disabled={!selectedProject || !isChanged}
                                                className="btn py-2 px-3"
                                                color="default"
                                                size="sm"
                                                type="button"
                                                onClick={() => {
                                                    setMatrixSave(true);
                                                }}
                                            >
                                                Save Changes
                                            </Button>
                                        }
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {
                setupOpen && 
                <TnaSetup 
                    item={setupData} 
                    projectId={selectedProject.projectId}
                    onClose={() => setSetupOpen(false)} 
                    onSaved={() => {
                        setSetupOpen(false);
                        reloadAll();
                        //loadSetup(selectedProject.projectId);
                        //loadMatrix(selectedProject.projectId);
                    }} 
                />
            }
            {/* {
                !!itemInEdit && viewMode === "list" && 
                <TnaEditor 
                    item={itemInEdit} 
                    onClose={() => setItemInEdit(null)} 
                    onSaved={() => {
                        setItemInEdit(null);

                        //if (viewMode === "list") {
                            loadList(selectedProject.projectId);
                        //} else {
                        //    loadMatrix(selectedProject.projectId);
                        //}
                    }} 
                    lookups={{
                        trainingGroups,
                        fundingSources,
                        trainingProviders,
                        trainingRequirements
                    }}
                />
            } */}
            {
                !!itemInEdit && viewMode === "matrix" && 
                <MatrixEditor 
                    item={itemInEdit} 
                    onClear={handleClear}
                    onClose={() => setItemInEdit(null)} 
                    onSaved={() => {
                        setItemInEdit(null);
                        reloadAll();
                        //loadMatrix(selectedProject.projectId);
                    }} 
                    lookups={{
                        trainingGroups,
                        fundingSources,
                        trainingProviders,
                        trainingRequirements
                    }}
                />
            }
            {
                !!positionInEdit && viewMode === "matrix" && 
                <PositionSetup 
                    item={positionInEdit} 
                    projectId={selectedProject?.projectId}
                    onClear={handleClear}
                    onClose={() => setPositionInEdit(null)} 
                    onSaved={() => {
                        setPositionInEdit(null);
                        reloadAll();
                        //loadSetup(selectedProject?.projectId);
                        //loadMatrix(selectedProject?.projectId);
                    }} 
                    lookups={{
                        fundingSources,
                        trainingProviders,
                        trainingRequirements
                    }}
                />
            }
        </section>
    )
}

export default View;