/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
    Collapse,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
} from "reactstrap";

import { useMsal, useIsAuthenticated } from "@azure/msal-react";
//import { useMsal } from "../../auth/msalContext";

// import transparent_logo from "../../assets/img/transparent/Services_transparent.png";
// import red_logo from "../../assets/img/transparent/Services_transparent.png";
import transparent_logo from "../../assets/img/Services_CMYK.jpg";
// import red_logo from "../../assets/img/Services_CMYK.jpg";
import red_logo from "../../assets/img/logo_services_sm2.png";
import transparent_logo_2 from "../../assets/img/logotr.png";


import { getWindowDimensions } from "../../utils/index";
import { requiresRoles } from "../Can";
import { roles } from "../../auth/roles";
import { useHistory, Link } from "react-router-dom";
import { useAppContext } from "../../contexts/AppProvider";

// import { AppContext } from "../../App";

function SimpleNavbarBcMain(props) {

    const {
        directions
    } = props;

    //const { isAuthenticated, user } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const user = instance.getActiveAccount();


    const history = useHistory();
    const [positionOnTop, setPositionOnTop] = React.useState(window.scrollY == 0);
    const [isScrollingUp, setIsScrollingUp] = React.useState(false);
    const [lastScrollPos, setLastScrollPos] = React.useState(window.scrollY)
    const [currentPage, setCurrentPage] = React.useState(history.location.pathname);
    const [breadcrumbContent, setBreadcrumbContent] = useState(null);
    //const [breadcrumbs, setBreadcrumbs] = React.useState([]);
    const {
        breadcrumbs, setBreadcrumbs, breadcrumbsRef,
        breadcrumbItems, setBreadcrumbItems, breadcrumbItemsRef,
        breadcrumbItemOverrides, setBreadcrumbItemOverrides, breadcrumbItemOverridesRef,
    } = useAppContext();

    // console.log('instance', instance);
    // console.log('user', user);

    // const { appState, setAppState } = React.useContext(AppContext);
    // const [ companies, setCompanies ] = React.useState([]);
    // const [ projects, setProjects ] = React.useState([]);

    // React.useEffect(() => {
    //     fetch(`${process.env.REACT_APP_WEB_API_BASE_URI}/lookup/company`)
    //     .then(response => response.json())
    //     .then(data => setCompanies(data))
    // }, []);

    const [collapseOpen, toggleCollapse] = React.useState(false);

    const [logo, setLogo] = useState(transparent_logo_2);



    React.useEffect(() => {
        let headroom = new Headroom(document.getElementById("navbar-main"), {
            onPin: () => setLogo(transparent_logo_2),
            onUnpin: () => setLogo(transparent_logo)
        });
        headroom.init();
    });

    let navbarType = "";
    switch (props.type) {
        case "dark":
            navbarType = "bg-default navbar-dark";
            break;
        case "transparent":
            navbarType = "navbar-transparent";
            break;
        case "primary":
            navbarType = "bg-primary navbar-dark";
            break;
        case "white":
            navbarType = "bg-white";
            break;
        default:
            navbarType = "bg-default navbar-dark";
            break;
    }

    const dimension = getWindowDimensions();
    const authFontSize = dimension.width <= 580 ? "0.6rem" : "1rem";
    const handleLogout = () => {
        //var currentUser = instance.getAccountByHomeId(user.homeAccountId);
        //const account = instance.getActiveAccount();
        ////account.
        //instance.logoutRedirect({
        //    account: currentUser,
        //    postLogoutRedirectUri: '/'
        //});
        instance.logout();
    };

    // BEGIN - Breadcrumb ------------------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {

        const url = currentPage.substring(1);
        const substr = url.split("/");

        if (substr[0] === "")
            substr.shift();

        var breadcrumbs = [];
        breadcrumbs.push("home");

        substr.forEach((val) => breadcrumbs.push(val));
        setBreadcrumbs([...breadcrumbs]);

    }, [currentPage]);

    useEffect(() => {
        console.log('breadcrumbs', breadcrumbs);
    }, [breadcrumbs])



    const onBreadcrumbScroll = () => {
        setPositionOnTop(window.scrollY == 0);
        setIsScrollingUp(window.scrollY < lastScrollPos);
        setLastScrollPos(window.scrollY);
    };

    React.useEffect(() => {
        document.addEventListener("scroll", onBreadcrumbScroll);
        const unlisten = history.listen((e) => setCurrentPage(e.pathname));

        return function cleanup() {
            document.removeEventListener("scroll", onBreadcrumbScroll);
            unlisten();
        };
    });

    React.useEffect(() => {
        setPositionOnTop(true);
    }, [currentPage]);

    let className = "breadcrumb-custom-default";
    let textClassName = "default";

    if (positionOnTop) {
        className += " breadcrumb-custom-top";
        textClassName = "";
    }

    if (isScrollingUp && !positionOnTop)
        className += " breadcrumb-custom-scrolled";

    var menuContainerClass = useMemo(() => {
        if (!breadcrumbs?.length || (breadcrumbs?.length == 1 && breadcrumbs[0] == "home")) {
            return "px-lg-10";
        }
        else {
            return "px-lg-4";
        }

    }, [breadcrumbs]);


    const generateBreadcrumbContent = () => {
        let combined = "";
        var breadcrumbItems = [];

        var content = breadcrumbs.map((val, key) => {
            //console.log('breadcrumbs val key', val, key);
            var overrideItem = breadcrumbItemOverrides?.find(x => x.index == key);
            console.log('breadcrumbItemOverrides', breadcrumbItemOverrides);
            console.log('overrideItem', overrideItem);
            console.log('val key', val, key);
            const direction = directions?.filter(x => x.key === val)[0];
            const breadcrumbItem = overrideItem ? overrideItem.breadcrumbItem : (val === "home" ? <i className="fas fa-home"></i> : (directions?.filter(x => x.key === val)?.length > 0 ? directions.filter(x => x.key === val)[0].value : val));
            breadcrumbItems.push(breadcrumbItem);

            if (key === breadcrumbs.length - 1)
                return (
                    <span className={`breadcrumb-item breadcrumb-item-custom active ${textClassName} text-uppercase`} key={key} id={`bc-${val}`}>
                        {breadcrumbItem}
                    </span>
                );

            combined += (val === "home" ? "" : `/${val}`);
            return (
                <>
                    {/* val == "home" &&
                                <UncontrolledTooltip delay={0} placement="bottom" target={`bc-${val}`}>
                                    Home
                                </UncontrolledTooltip>
                                */ }
                    <React.Fragment key={key}>
                        <Link
                            className={`breadcrumb-item breadcrumb-item-custom ${textClassName} text-uppercase`}
                            to={direction && direction.customRedirect ? direction.customRedirect : combined}
                            id={`bc-${val}`}
                        >
                            {breadcrumbItem}
                        </Link>
                    </React.Fragment>
                </>
            );
        });
        setBreadcrumbItems(breadcrumbItems);
        setBreadcrumbContent(content);
    }

    useEffect(() => {
        console.log('breadcrumbs', breadcrumbs);
        generateBreadcrumbContent();
    }, [breadcrumbs, breadcrumbItemOverrides]);

    // END - Breadcrumb ------------------------------------------------------------------------------------------------------------------------------------

    return (
        <>
            <Navbar
                className={`navbar-main headroom ${navbarType} navbar-height navbar-shadow navbar-m-md-0`}
                expand="lg"
                id="navbar-main"
            >
                <Container fluid style={{ justifyContent: 'flex-start' }} className={menuContainerClass} >
                    <Link className="mr-3 navbar-brand-custom" to="/">
                        <img alt="Altrad Logo" src={logo}></img>
                    </Link>

                    <h2 className="text-uppercase title mb-0 d-none d-md-block navbar-title-merged-custom text-white">HRIS Extension</h2>


                    <ol className="breadcrumb-ol-custom navbar-left-menu">
                        {breadcrumbContent}
                    </ol>


                    <div className="ml-auto text-white navbar-right-menu">

                        {
                            requiresRoles([roles.Admin, roles.Spc], user,
                                <>
                                    {
                                        requiresRoles([roles.Admin], user,
                                            <Link className="cursor-pointer mr-3 navbar-item" to="/maintenance"><i className="fas fa-cog pt-1 pr-1 text-white" /> Maintenance</Link>
                                        )
                                    }
                                    {
                                        requiresRoles([roles.Admin, roles.Spc], user,
                                            <Link className="cursor-pointer mr-3 navbar-item" to="/reportcentre"><i className="far fa-list-alt pt-1 pr-1 text-white" /> Report Centre</Link>
                                        )
                                    }
                                </>
                            )
                        }
                        {
                            isAuthenticated && user && <>
                                <span className="mb-0 navbar-item" id="username">
                                    {`${user.name}`}
                                </span>
                                <a href="#" className="ml-3 navbar-item" aria-label="Logout" id="logout" onClick={() => handleLogout()}><span className="fas fa-sign-out-alt"></span></a>

                                <UncontrolledTooltip delay={0} placement="bottom" target="username">
                                    {user.idTokenClaims.roles && user.idTokenClaims.roles.length > 0 && user.idTokenClaims.roles.sort().join(", ")}
                                </UncontrolledTooltip>
                                <UncontrolledTooltip delay={0} placement="bottom" target="logout">
                                    Logout
                                </UncontrolledTooltip>
                            </>
                        }
                    </div>

                    {!props.noMenu && <>
                        <button
                            className="navbar-toggler"
                            type="button"
                            onClick={() => toggleCollapse(!collapseOpen)}
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <Collapse
                            id="navbar_global"
                            navbar
                            toggler="#navbar_global"
                            isOpen={collapseOpen}
                        >
                            <div className="navbar-collapse-header">
                                <Row>
                                    <Col className="collapse-brand" xs="6">
                                        <Link to="/index">
                                            <img
                                                alt="..."
                                                src={red_logo}
                                            ></img>
                                        </Link>
                                    </Col>
                                    <Col className="collapse-close" xs="6">
                                        <button
                                            className="navbar-toggler"
                                            onClick={() => toggleCollapse(!collapseOpen)}
                                        >
                                            <span></span>
                                            <span></span>
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                            <Nav
                                className="navbar-nav-hover align-items-lg-center ml-lg-auto"
                                navbar
                            >
                                <UncontrolledDropdown nav>
                                    <DropdownToggle
                                        tag={NavLink}
                                        data-toggle="dropdown"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                        role="button"
                                    >
                                        <i className="ni ni-tablet-button d-lg-none"></i>
                                        <span className="nav-link-inner--text ">App Pages</span>
                                    </DropdownToggle>
                                    <DropdownMenu aria-labelledby="navbarDropdownMenuLink">
                                        <DropdownItem to="/account-settings" tag={Link}>
                                            <i className="ni ni-lock-circle-open text-muted"></i>
                                            Account Settings
                                        </DropdownItem>
                                        <DropdownItem to="/login-page" tag={Link}>
                                            <i className="ni ni-tv-2 text-danger"></i>
                                            Login Page
                                        </DropdownItem>
                                        <DropdownItem to="/register-page" tag={Link}>
                                            <i className="ni ni-air-baloon text-pink"></i>
                                            Register Page
                                        </DropdownItem>
                                        <DropdownItem to="/reset-page" tag={Link}>
                                            <i className="ni ni-atom text-info"></i>
                                            Reset Page
                                        </DropdownItem>
                                        <DropdownItem to="/invoice-page" tag={Link}>
                                            <i className="ni ni-bullet-list-67 text-success"></i>
                                            Invoice Page
                                        </DropdownItem>
                                        <DropdownItem to="/checkout-page" tag={Link}>
                                            <i className="ni ni-basket text-orange"></i>
                                            Checkout Page
                                        </DropdownItem>
                                        <DropdownItem to="/chat-page" tag={Link}>
                                            <i className="ni ni-chat-round text-primary"></i>
                                            Chat Page
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Nav>
                        </Collapse>
                    </>}

                </Container>


            </Navbar>
        </>
    );
}

SimpleNavbarBcMain.defaultProps = {
    type: "dark",
};

SimpleNavbarBcMain.propTypes = {
    type: PropTypes.oneOf(["dark", "transparent", "primary", "white"]),
};

export default SimpleNavbarBcMain;