import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Loader from "../../../../loaders";
import { AgGridReact } from "ag-grid-react";
import DateColumnTooltip from "../../tooltips/DateColumnTooltip";
import SetupPanel from "./panels/SetupPanel";
import useGrid from "./contexts/GridContext";
import api from "../../../../../services/api";
import qs from "qs";
import { StatusPanelLeft, StatusPanelRight } from "../../panels";
import moment from "moment";
import { ViewAccess } from "../../../../../utils";
import { requiresRoles } from "../../../../Can";
import { roles } from "../../../../../auth/roles";
import { useMsal } from "@azure/msal-react";

const editableCellClass = "roster-cell";

const getContentCellClass = (content) => {
    if (!content) {
        return "grid-empty-roster";
    }
    return "";
}

export default function RosterEmptySlotGrid(props) {
    const {
        dispatchUnit,
        startDate,
        endDate,
        trimStartDate,
        trimEndDate,
        project,
        setDataLoaded,
        dataLoaded,
        setStartDate,
        setEndDate,
    } = useGrid();

    const gridRef = useRef();
    const [loading, setLoading] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [disableTrimStartDateDefault, setDisableTrimStartDateDefault] = useState(null);
    const [disableTrimEndDateDefault, setDisableTrimEndDateDefault] = useState(null);
    const [viewAccess, setViewAccess] = useState(ViewAccess.FULLACCESS);
    const { instance } = useMsal();
    const user = instance.getActiveAccount();

    useEffect(() => {
        requiresRoles([roles.Spc], user, () => {
            // console.log('Allowed SPC Readonly');
            setViewAccess(ViewAccess.READONLY);
        });
    }, []);

    const loadingOverlayComponent = useMemo(() => Loader, []);
    const getRowId = useMemo(() => (params) => params?.data?.rosterCandidateId, []);

    const statusBar = useMemo(() => ({
        statusPanels: [
            {
                statusPanel: StatusPanelLeft,
                align: 'left',
            },
            {
                statusPanel: StatusPanelRight,
                align: 'right',
            },
        ],
    }), []);

    useEffect(() => {
        if (process.env.REACT_APP_HRIS_ROSTER_EMPTY_SLOTS_GRID_COLUMN_STATE in localStorage && !!gridRef.current.columnApi) {
            gridRef.current.columnApi.applyColumnState({
                state: JSON.parse(localStorage.getItem(process.env.REACT_APP_HRIS_ROSTER_EMPTY_SLOTS_GRID_COLUMN_STATE)),
                applyOrder: true,
                defaultState: {
                    aggFunc: null,
                    flex: null,
                    hide: false,
                    pinned: null,
                    pivot: false,
                    pivotIndex: null,
                    rowGroup: false,
                    rowGroupIndex: null,
                    sort: null,
                    sortIndex: null
                }
            });
        }
        setDataLoaded(rowData && rowData.length > 0)
    }, [rowData]);

    const defaultColDef = useMemo(() => ({
        sortable: false,
        cellClass: 'roster-cell',
        tooltipComponent: DateColumnTooltip,
        autoHeight: false,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200
        },
        menuTabs: ['filterMenuTab'],
        suppressPaste: true,
        suppressFillHandle: true,
    }), []);

    const staticColumnDefs = useMemo(() => {
        return [
            {
                headerName: "",
                children: [
                    {
                        field: "slot",
                        headerName: "Slot",
                        width: 55,
                        pinned: true,
                        sort: "asc",
                    },
                    {
                        field: "candidateName",
                        headerName: "Employee Name",
                        width: 150,
                        pinned: true,
                        cellClass: (params) => {
                            const contentCellClass = getContentCellClass(params.data?.candidateName);
                            return `${editableCellClass} ${contentCellClass}`;
                        },
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: "hrispositionMasterName",
                        headerName: "Roster Position",
                        width: 200,
                        columnGroupShow: 'closed',
                        cellClass: (params) => {
                            var contentCellClass = getContentCellClass(params.data?.hrispositionMasterName);
                            return `${editableCellClass} ${contentCellClass}`;
                        },
                        pinned: true,
                    },
                    {
                        field: "positionName",
                        headerName: "Employed Position",
                        width: 200,
                        columnGroupShow: 'closed',
                        cellClass: (params) => {
                            var contentCellClass = getContentCellClass(params.data?.positionName);
                            return `roster-cell ${contentCellClass}`;
                        },
                        pinned: true,
                    },
                    {
                        field: "shiftName",
                        headerName: "Shift",
                        width: 70,
                        cellClass: (params) => {
                            var contentCellClass = getContentCellClass(params.data?.shiftName);
                            return `${editableCellClass} ${contentCellClass}`;
                        },
                        pinned: true,
                    },
                    {
                        field: "siteTravelTypeName",
                        headerName: "Site Travel Type",
                        width: 100,
                        cellClass: (params) => {
                            var contentCellClass = getContentCellClass(params.data?.siteTravelTypeName);
                            return `${editableCellClass} ${contentCellClass}`;
                        },
                        pinned: true,
                    },
                    {
                        field: "pointOfHireName",
                        headerName: "Point of Hire",
                        width: 90,
                        cellClass: (params) => {
                            var contentCellClass = getContentCellClass(params.data?.pointOfHireName);
                            return `${editableCellClass} ${contentCellClass}`;
                        },
                        pinned: true,
                    },
                    {
                        field: "rosterCandidateCompanyName",
                        headerName: "Entity",
                        width: 150,
                        cellClass: (params) => {
                            if (params.data.rosterCompanyId !== params.data.rosterCandidateCompanyId) {
                                return `roster-cell text-danger`;
                            }
                            return 'roster-cell'
                        },
                    },
                ]
            },
        ]
    }, []);

    const loadSchedule = useCallback(query => {
        gridRef.current.api.showLoadingOverlay();
        gridRef.current.api.clearRangeSelection();

        const queryString = query ? qs.stringify({...query}, { allowDots: true }) : null;

        api.get(`/rostergrid/emptyslots${queryString ? `?${queryString}` : ""}`)
            .then((response) => {
                try {
                    const {
                        schedules,
                        filterStartDate,
                        filterEndDate,
                        dispatchUnit
                    } = response.data;

                    // console.log({
                    //     schedules,
                    //     filterStartDate,
                    //     filterEndDate,
                    //     dispatchUnit,
                    // });

                    if (schedules && schedules.length == 0) {
                        setColumnDefs([...staticColumnDefs]);
                        setRowData([]);
                        setLoading(false);
                        return;
                    }

                    const dateColumns = [];

                    const start = moment(filterStartDate, 'YYYY-MM-DD');
                    const end = moment(filterEndDate, 'YYYY-MM-DD');
                    setStartDate(new Date(start.year(), start.month(), start.date()));
                    setEndDate(new Date(end.year(), end.month(), end.date()));

                    const current = moment(start);

                    while (current <= end) {
                        dateColumns.push(moment(current));
                        current.add(1, 'd');
                    }

                    setColumnDefs([
                        ...staticColumnDefs,
                        ...dateColumns.map((m) => ({
                            headerName: m.format('ddd'),
                            marryChildren: true,
                            children: [
                                {
                                    field: `dates.${m.format('YYYYMMDD')}`,
                                    // tooltipField: `dates.${m.format('YYYYMMDD')}`,
                                    headerName: m.format('DD/MM/YYYY'),
                                    autoHeight: false,
                                    resizable: false,
                                    width: 30,
                                    headerClass: 'date-header',
                                    suppressMovable: true,
                                    lockPosition: 'right',
                                    filter: false,
                                    suppressMenu: true,
                                    valueFormatter: (params) => {
                                        // console.log("value formatter", {params});
                                        return params?.value?.value === "W" ? "1" : "";
                                    },
                                    cellClass: (params) => {
                                        // console.log("cell class", {params});
                                        return params.value && params.value.value ? `date-cell roster-report-icon ${params.value?.className} amount amount${params.value?.value.length}` : 'date-cell';
                                    },
                                }
                            ]
                        }))
                    ]);

                    setRowData(schedules);

                    gridRef.current.api.closeToolPanel();

                    var elInfo = document.querySelector("#du-info");
                    elInfo.innerHTML = `${dispatchUnit.projectName} | ${dispatchUnit.dispatchUnitName} | ${dispatchUnit.quoteNumber} | ${dispatchUnit.locationName}`;
                    setLoading(false);

                    const dateExists = schedules.filter(s => {
                        if (!s.dates)
                            return false;

                        if (!Object.keys(s.dates).length)
                            return false;

                        return true;
                    }).length > 0;

                    setDisableTrimStartDateDefault(!dateExists);
                    setDisableTrimEndDateDefault(!dateExists);
                }
                catch (error) {
                    console.error({error});
                    setLoading(false);
                }
            }).catch((error) => {
                console.error({error});
                setLoading(false);
            }).finally(() => {

            });

    }, [staticColumnDefs]);

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: 'setupPanel',
                    labelDefault: 'Setup',
                    labelKey: 'setupPanel',
                    iconKey: 'fa-gear',
                    toolPanel: SetupPanel,
                    width: 280,
                    toolPanelParams: {
                        today: new Date(),
                        loadSchedule,
                        disableTrimStartDateDefault: disableTrimStartDateDefault,
                        disableTrimEndDateDefault: disableTrimEndDateDefault,
                        viewAccess,
                    },
                },
            ],
            defaultToolPanel: 'setupPanel',
            position: "left"
        };
    }, []);

    return (
        <section className="main">
            {loading && <Loader />}
            <div className="ag-theme-alpine px-2" style={{ width: '100%', height: 'calc(100vh - 130px)' }}>
                <AgGridReact
                    ref={gridRef}
                    context={{ dispatchUnit, startDate, endDate, trimStartDate, trimEndDate, project }}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    suppressColumnVirtualisation={false}
                    suppressRowVirtualisation={false}
                    rowBuffer={20}
                    debounceVerticalScrollbar={false}
                    suppressClipboardPaste={true}
                    headerHeight={70}
                    groupHeaderHeight={22}
                    rowHeight={22}
                    onGridReady={({ api }) => {
                        api.hideOverlay();
                    }}
                    readOnlyEdit={true}
                    getRowId={getRowId}
                    enableRangeSelection={true}
                    loadingOverlayComponent={loadingOverlayComponent}
                    sideBar={sideBar}
                    statusBar={statusBar}
                    tooltipShowDelay={0}
                    tooltipHideDelay={20000}
                    suppressDragLeaveHidesColumns={true}
                    suppressColumnMoveAnimation={true}
                    multiSortKey='ctrl'
                    copyHeadersToClipboard={false}
                    enableCellChangeFlash={false}
                    animateRows={true}
                />
            </div>
        </section>
    );
};
