/* eslint-disable eqeqeq */

import moment from "moment";

export function FiltersConverter(filterModel, dateFormat = "DD.MM.YYYY") {

    if (filterModel == null)
        return null;
    // console.log('filterModel', filterModel);

    var datatableFilter = Object.entries(filterModel).map(([k, v]) => {
        var value;

        if (v.filterType == "date") {
            value = v.dateFrom;
        } else if (v.filterType == "set" && v.values && v.values.length > 0) {
            value = v.values[0];
        } else {
            value = v.filter;
        }

        var obj = {
            id: k,
            value: value,
        };

        return obj;
    });
    // console.log('datatableFilter', datatableFilter);
    return datatableFilter;
}

export function SortsConverter(sortModel) {

    if (sortModel == null)
        return null;

    var datatableSort = sortModel.map((v, i, a) => ({
        id: v.colId,
        desc: !v.sort || v.sort == 'asc' ? false : true,
    }));

    // console.log('datatableSort', datatableSort);
    return datatableSort;
}

export function DateComparator(filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue;

    if (dateAsString == null) {
        return 0;
    }

    // In the example application, dates are stored as dd/mm/yyyy
    // We create a Date object for comparison against the filter date
    var dateParts = dateAsString.split('/');
    var day = Number(dateParts[2]);
    var month = Number(dateParts[1]) - 1;
    var year = Number(dateParts[0]);
    var cellDate = new Date(year, month, day);

    // Now that both parameters are Date objects, we can compare
    if (cellDate < filterLocalDateAtMidnight) {
        return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
    }
    return 0;
}


export default function ParamsConverter() {
    //export { FiltersConverter as FiltersConverter } from "ParamsConverter";
    //export { SortsConverter as SortsConverter } from "ParamsConverter";


    ////export function FiltersConverter(filterModel) {
    //function FiltersConverter(filterModel) {

    //    if (filterModel == null)
    //        return null;

    //    var datatableFilter = Object.entries(filterModel).map(([k, v]) => new {
    //        id: k,
    //        value: v.filter
    //    });
    //    console.log('datatableFilter', datatableFilter);
    //    return datatableFilter;

    //}
    //function SortsConverter(sortModel) {

    //    if (sortModel == null)
    //        return null;

    //    var datatableSort = sortModel.map((v, i, a) => new {
    //        id: v.colId,
    //        desc: v.sort
    //    });

    //    console.log('datatableSort', datatableSort);
    //    return datatableSort;


    //}
}