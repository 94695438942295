import React from 'react';

function NumberRangeColumnFilter({ 
    column: { 
        filterValue = [], 
        preFilteredRows, 
        setFilter, 
        id 
    } 
}) {

    // const [min, max] = React.useMemo(() => {    
    //     const min = _.minBy(preFilteredRows, (row) => row.values[id])
    //     const max = _.maxBy(preFilteredRows, (row) => row.values[id])
    //     return [min ? min.values[id] : 0, max ? max.values[id] : 0]
    // }, [id, preFilteredRows])
  
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <input
                className="input-filter form-control"
                value={filterValue[0] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
                }}
                placeholder={`Min`}
                style={
                    {marginRight: "0.5rem"}
                }
            />
            to
            <input
                className="input-filter form-control"
                value={filterValue[1] || ''}
                type="number"
                onChange={e => {
                    const val = e.target.value
                    setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
                }}
                placeholder={`Max`}
                style={
                    {marginLeft: "0.5rem"}
                }
            />
        </div>
    )
}

export default NumberRangeColumnFilter;