import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Container, Modal, Row } from "reactstrap";
import Loader from "../../loaders";
import api from "../../../services/api";
import { moneyFormatter, percentFormatter } from "../../ag-grid/ValueFormatters";
import { AgGridReact } from "ag-grid-react";

export default function LabourRequestDetailEmployeeBenefitsModal(props) {
    const {
        requestDetailId,
        onClose,
    } = props;

    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState([]);
    const gridRef = useRef();
    const loadingOverlayComponent = useMemo(() => Loader, []);
    
    const loadData = useCallback(() => {
        if (!requestDetailId)
            return;

        setLoading(true);
        api.get(`recruitmentrequest/detail/currentbenefits/${requestDetailId}`)
            .then((response) => {
                const {data: newData} = response;
                setData([...newData]);
            })
            .catch((error) => {
                console.log({error});
            })
            .finally(() => setLoading(false))
        ;
    }, [requestDetailId]);

    useEffect(() => {
        if (!requestDetailId)
            return;

        loadData();
    }, [requestDetailId]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        filter: false,
        editable: false,
        suppressMenu: true,
    }), []);
    
    const columnDefs = useMemo(() => [
        {
            field: "benefitTypeName",
            headerName: "Type",
        },
        {
            field: "uom",
            headerName: "UoM",
            width: 140,
            maxWidth: 140,
        },
        {
            field: "benefitValue",
            headerName: "Value",
            valueFormatter: (params) => {
                const {data: rowData} = params;

                if (rowData.uom === "Payment")
                    return moneyFormatter(params);

                if (rowData.uom === "Percent")
                    return percentFormatter(params);

                return params.value;
            },
            cellClass: "text-right",
        },
    ], []);

    const onGridReady = useCallback((params) => {
        params.api.sizeColumnsToFit();
        window.addEventListener('resize', function () {
            setTimeout(function () {
                params.api.sizeColumnsToFit();
            });
        });
    }, [data]);

    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
        {
            loading &&
            <Loader />
        }
            <div
                className="modal-header my-0"
            >
                <Container fluid>
                    <Row>
                        <Col lg={9}>
                            <h5
                                className="modal-title"
                            >
                                Current Pay Profile
                            </h5>
                        </Col>
                        <Col lg={3}>
                            <button
                                aria-label="Close"
                                className="close"
                                onClick={onClose}
                                type="button"
                            >
                                <span aria-hidden={true}>
                                    <i className="fas fa-times-circle text-black" />
                                </span>
                            </button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-body"
            >
                <Container fluid className="m-0 p-0 mt-1 py-2">
                    <div className="ag-theme-alpine ag-grid" style={{ width: '100%', height: '200px' }}>
                        <AgGridReact
                            ref={gridRef}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            loadingOverlayComponent={loadingOverlayComponent}
                            suppressColumnVirtualisation={false}
                            suppressRowVirtualisation={false}
                            debounceVerticalScrollbar={false}
                            rowBuffer={20}
                            headerHeight={30}
                            groupHeaderHeight={30}
                            rowHeight={30}
                            floatingFiltersHeight={30}
                            suppressDragLeaveHidesColumns={true}
                            suppressColumnMoveAnimation={true}
                            animateRows={false}
                            pagination={false}
                            rowClass={'ag-row-normal'}
                            suppressContextMenu={true}
                            rowData={data}
                            onGridReady={onGridReady}
                        />
                    </div>
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={onClose}
                    type="button"
                >
                    Close
                </Button>
            </div>
        </Modal>
    );
};