import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Button, Col, Input, Modal, Row } from 'reactstrap';
import api from "../../../../services/api";
import Loader from "../../../loaders";
import qs from "qs";
import { Container } from 'reactstrap';
import Grid from '../../../Grid';
import { useTable, useRowSelect, useFlexLayout, useSortBy } from 'react-table';
import { IndeterminateCheckbox } from '../../../react-table/controls';
import moment from "moment";

export default function LogisticAccommodationConfirmEditor(props) {
    const {
        title,
        candidateTitle,
        rosterCandidates,
        selected,
        customCallback,
        onClose,
        preSelectDataId,
    } = props;

    // Vars
    const [ loading, setLoading ] = React.useState(false);
    const [ data, setData ] = useState([]);
    const [ firstLoad, setFirstLoad ] = useState(true);

    // Validations
    const [initialSelectedRows, setInitialSelectedRows] = useState([]);


    // Init data load
    const loadData = useCallback(() => {
        setData([]);

        if (!rosterCandidates?.length)
            return;

        const refIds = [];
        rosterCandidates.forEach(rc => {
            refIds.push(rc.rosterCandidateId);
        });

        if (refIds.length === 0)
            return;

        setLoading(true);

        const refIdStr = qs.stringify({refIds}, { allowDots: true });
        api.get(`rostercandidateschedule/waiting-accommodations?reftype=RC&${refIdStr}`)
            .then((response) => {
                // console.log("loadData", {data: response.data});
                setData(response.data && response.data !== "" ? response.data : []);
            })
            .catch((error) => {
                console.log(error.response);
            })
            .finally(() => {
                setLoading(false);
                setFirstLoad(false);
            });
    }, [rosterCandidates]);

    useEffect(() => {
        if (!rosterCandidates || !rosterCandidates.length)
            return;

        loadData();
    }, [rosterCandidates]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Start Date",
                accessor: (row) => moment(row.startDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
                disableSortBy: false,
            },
            {
                Header: "End Date",
                accessor: (row) => moment(row.endDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
                disableSortBy: false,
            },
            {
                Header: "Name",
                accessor: "accommodation.accommodationName",
                disableSortBy: false,
            },
            {
                Header: "Address",
                accessor: "accommodation.accommodationAddress",
                disableSortBy: false,
            },
            {
                Header: "Phone Number",
                accessor: "phoneNumber",
                disableSortBy: false,
            },
        ]
    }, [firstLoad]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        selectedFlatRows,  
    } = useTable(
        {
            columns,
            data,
            initialState: {
                // "selectedRowIds": {},
                "selectedRowIds": initialSelectedRows,
                "hiddenColumns": []
            },
            // defaultColumn
        },
        useFlexLayout,
        hooks => {
          hooks.visibleColumns.push(columns => [
            // Let's make a column for selection
            {
                id: 'selection',
                width: 30,
                disableSortBy: true,
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div>
                    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} id="all" />
                    </div>
                ),
                // The cell can use the individual row's getToggleRowSelectedProps method
                // to the render a checkbox
                Cell: ({ row }) => {
                    const {original} = row;
                    
                    if (firstLoad)
                        row.isSelected = selected?.some(s => s.rosterCandidateId === original.rosterCandidateId && s.startEndGroupId === original.startEndGroupId) || row.isSelected;

                    return (
                        <div>
                        {
                            row.original != null &&
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} id={row.original.startEndGroupId} />
                        }
                        </div>
                    )
                },
            },
            ...columns,
          ])
        },
        useSortBy,
        useRowSelect,
    );

    // BEGIN AUTO SELECT VALID DATA -------------------------------------------------------------------------------------
    const setSelection = useCallback(() => {
        if (!data || data.length == 0) {
            setInitialSelectedRows([]);
            return;
        }

        // Foreach data
        var selectedItemList = [];
        var selected = {};

        data.forEach((row, index) => {


            // If not data preselected
            //if (!preSelectDataId.includes(row.startEndGroupId)) {
            if (!preSelectDataId?.some(s => s.startEndGroupId == row.startEndGroupId && s.rosterCandidateId == row.rosterCandidateId)) {
                return;
            }


            // New item to append
            var newItem = { ...row, id: index };
            selectedItemList.push(newItem);



        });


        // Set id of selected item
        selectedItemList.forEach((item) => {
            selected[`${item.id}`] = true;
        })
        setInitialSelectedRows(selected);


    }, [data]);


    useEffect(() => {
        setSelection();
    }, [data]);
    // END AUTO SELECT VALID DATA -------------------------------------------------------------------------------------

    return (
        <>
            {(loading) && <Loader />}
            <div
                className="modal-header my-0"
            >
                <Container fluid>
                    <Row>
                        <Col lg={9}>
                            <h5
                                className="modal-title"
                            >
                                Accommodation Confirmation{title}
                            </h5>
                        </Col>
                        <Col lg={3}>
                            <button
                                aria-label="Close"
                                className="close"
                                onClick={onClose}
                                type="button"
                            >
                                <span aria-hidden={true}>
                                    <i className="fas fa-times-circle text-black" />
                                </span>
                            </button>
                        </Col>
                    </Row>
                    {
                        candidateTitle && candidateTitle != "" && candidateTitle.trim() != 'null' ?
                    <Row>
                        <Col lg={12}>
                            <h6
                                className="modal-title"
                            >
                                {candidateTitle}
                            </h6>
                        </Col>
                    </Row>
                    :
                    <></>
                }
                </Container>
            </div>
            <div
                className="modal-body"
            >
                <Grid
                    height='calc(100vh - 436px)'
                    rows={rows}
                    tableProps={getTableProps()}
                    headerGroups={headerGroups}
                    tableBodyProps={getTableBodyProps()}
                    prepareRow={prepareRow}
                />
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="default"
                    onClick={() => {
                        customCallback?.onCancel &&
                        customCallback.onCancel();
                    }}
                    type="button"
                >
                    {customCallback?.cancelButtonTitle ?? "Cancel"}
                </Button>
                <Button
                    color="secondary"
                    onClick={() => {
                        customCallback?.onReturn &&
                        customCallback.onReturn();
                    }}
                    type="button"
                >
                    {customCallback?.returnButtonTitle ?? "Back"}
                </Button>
                <Button
                    color="info"
                    onClick={() => {
                        customCallback?.onContinue &&
                        customCallback.onContinue(selectedFlatRows?.map(d => d.original));
                    }}
                    type="button"
                >
                    {customCallback?.continueButtonTitle ?? "Next"}
                </Button>
            </div>
        </>
    )
};