
import moment from "moment";

export function HexToBytes(hex) {
    const bytes = new Uint8Array(hex.length / 2);
    for (let i = 0; i !== bytes.length; i++) {
        bytes[i] = parseInt(hex.substring(2 * i, 2 * i + 2), 16);
    }
    return bytes;
}
export function BytesToDataUri(bytes, mimeType) {
    const base64 = btoa(String.fromCharCode.apply(null, bytes));
    return `data:${mimeType};base64,${base64}`;
}
export function Base64ToBytes(base64) {
    const binaryString = atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
}
