import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import api from "../../../../services/api";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useFlexLayout, useRowSelect, useTable } from 'react-table';
import { Button, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import Grid from '../../../Grid';
import Loader from '../../../loaders';
import { IndeterminateCheckbox } from '../../../react-table/controls';
import moment from "moment";
import { ComboBox } from '../../../dropdowns';
import { DatePicker } from '../../../date-pickers';
import { formatDate, getDayNameOfDate } from '../../../../utils';
import { ascSort, descSort } from '../../../../helpers/ListHelper'
import qs from "qs";

export default function FlightSearchEditor(props) {
    const {
        data,
        onClose,
        onSaved,
    } = props;

    const [ loading, setLoading ] = React.useState(false);
    const [ flightNumber, setFlightNumber ] = useState(data.flightNumber);
    const [ departureAirport, setDepartureAirport ] = useState(data.flightFrom);
    const [ arrivalAirport, setArrivalAirport ] = useState(data.flightTo);
    const [ departureDateStart, setDepartureDateStart ] = useState(moment(data.flightDate, "DD/MM/YYYY"));
    const [ departureDateEnd, setDepartureDateEnd ] = useState(moment(data.flightDate, "DD/MM/YYYY"));
    const [ dateRange, setDateRange ] = useState([]);
    const [ flightData, setFlightData ] = useState([]);
    const [ validationAlert, setValidationAlert ] = useState(null);

    useEffect(() => {
        console.log('FlightSearchEditor', 'flightData', flightData);
    }, [flightData])

    useEffect(() => {
        if (!departureDateStart || !departureDateEnd)
        {
            setDateRange([]);
            return;
        }

        const startDate = new Date(departureDateStart?.format("YYYY-MM-DD"));
        const endDate = new Date(departureDateEnd?.format("YYYY-MM-DD"));

        if (endDate < startDate)
        {
            setDateRange([]);
            return;
        }

        const newRange = [];

        while (startDate <= endDate)
        {
            newRange.push(new Date(startDate));
            startDate.setDate(startDate.getDate() + 1);
        }

        setDateRange([...newRange]);
    }, [departureDateStart, departureDateEnd]);

    const loadData = useCallback(() => {
        if (!departureAirport || !arrivalAirport || !dateRange.length)
        {
            setValidationAlert({
                message: "From, To, Departure Date Start, and Departure Date End cannot be empty. Departure Date Start cannot be larger than Departure Date End."
            });
            return;
        }

        console.log(dateRange);
        
        setLoading(true);
        api.get(`airport/search-flights?departureairport=${departureAirport.airportCode}&arrivalairport=${arrivalAirport.airportCode}`)
            .then((response) => {
                // console.log(response.data);

                const filteredData = response.data
                    .filter(d => !flightNumber || flightNumber === "" || d.flightNumber?.toLowerCase().includes(flightNumber.toLowerCase()))
                    .filter(d => dateRange.filter(r => d.departureDay === getDayNameOfDate(r, true).toLowerCase()).length > 0)
                    .map(d => ({
                        ...d,
                        departureDate: dateRange.filter(r => d.departureDay === getDayNameOfDate(r, true).toLowerCase())[0],
                        departureDateView: moment(dateRange.filter(r => d.departureDay === getDayNameOfDate(r, true).toLowerCase())[0]).format("DD/MM/YYYY"),
                    }))
                ;
                filteredData.sort((a, b) => {
                    if (a.departureDate < b.departureDate)
                        return -3;

                    if (a.departureDate >= b.departureDate && a.departureTime < b.departureTime)
                        return -2;

                    if (a.departureDate >= b.departureDate && a.departureTime >= b.departureTime && a.flightNumber < b.flightNumber)
                        return -1;
                    
                    return 1;
                });
                console.log(filteredData);
                setFlightData(filteredData);
            })
            .catch((error) => {
                console.log({error});
            })
            .finally(() => setLoading(false))
        ;
    }, [flightNumber, departureAirport, arrivalAirport, dateRange]);

    const columns = React.useMemo(() => {
        return [
            {
                id: 'selection',
                width: 30,
                disableSortBy: true,
                Header: () => {
                    return (
                        <div>
                        </div>
                    )
                },
                Cell: ({row}) => {
                    const {original} = row;
                    
                    return (
                        <div>
                        {
                            original != null &&
                            <IndeterminateCheckbox
                                {...row.getToggleRowSelectedProps()}
                                id={row.id}
                                onChange={(e) => {
                                    // console.log({row, selected: row.isSelected});
                                    const selected = row.isSelected;
                                    toggleAllRowsSelected(false);
                                    row.toggleRowSelected(!selected);
                                }}
                            />
                        }
                        </div>
                    )
                },
            },
            {
                Header: "Flight Number",
                accessor: "flightNumber",
                disableSortBy: false,
            },
            {
                Header: "Departure Date",
                accessor: "departureDateView",
                disableSortBy: false,
            },
            {
                Header: "Departure Terminals",
                accessor: (row) => row.departureTerminals ? row.departureTerminals.join(", ") : null,
                id: "departureTerminals",
                disableSortBy: false,
            },
            {
                Header: "Departure Time",
                accessor: "departureTime",
                disableSortBy: false,
            },
            {
                Header: "Arrival Terminals",
                accessor: (row) => row.arrivalTerminals ? row.arrivalTerminals.join(", ") : null,
                id: "arrivalTerminals",
                disableSortBy: false,
            },
            {
                Header: "Arrival Time",
                accessor: "arrivalTime",
                disableSortBy: false,
            },
        ]
    }, [data]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        toggleAllRowsSelected
    } = useTable(
        {
            columns,
            data: flightData,
        },
        useFlexLayout,
        // hooks => {
        //     hooks.visibleColumns.push(columns => [
                
        //         ...columns,
        //     ])
        // },
        useRowSelect,
    );

    const handleSelectFlight = useCallback(() => {
        console.log(selectedFlatRows[0]);
        const selectedRow = {...selectedFlatRows[0].original};
        const arrivalDate = new Date(selectedRow.departureDate);
        arrivalDate.setDate(arrivalDate.getDate() + (selectedRow.arrivalTime < selectedRow.departureTime ? 1 : 0));
        var selectedDepartureTerminal = !selectedRow.departureTerminals || !selectedRow.departureTerminals.length ? null : selectedRow.departureTerminals.sort(ascSort).join('/');
        var selectedArrivalTerminal = !selectedRow.arrivalTerminals || !selectedRow.arrivalTerminals.length ? null : selectedRow.arrivalTerminals.sort(ascSort).join('/');
        console.log('selectedDepartureTerminal', selectedDepartureTerminal);
        console.log('selectedArrivalTerminal', selectedArrivalTerminal);

        if (selectedRow.departureTerminals?.length)
        {
            setLoading(true);
            const departureQueryString = qs.stringify({terminalNames: selectedRow.departureTerminals}, { allowDots: true });

            api.post(`airportterminal/updateterminals2/${departureAirport.airportId}?${departureQueryString}`)
                .then((response) => {
                    //console.log(response.data);
                    const departureTerminals = [...response.data];
                    console.log('selectedRow', selectedRow);
                    //const departureTerminal = departureTerminals.length ? departureTerminals.filter(d => selectedRow.departureTerminals.map(t => t.toLowerCase()).includes(d.terminalName.toLowerCase()))[0] : null;
                    const departureTerminal = departureTerminals.length ? departureTerminals.find(d => d.terminalName.toLowerCase() == selectedDepartureTerminal?.toLowerCase()) : null;

                    console.log('departureTerminals', departureTerminals);
                    console.log('departureTerminal', departureTerminal);



                    if (selectedRow.arrivalTerminals?.length)
                    {
                        const arrivalQueryString = qs.stringify({terminalNames: selectedRow.arrivalTerminals}, { allowDots: true });

                        api.post(`airportterminal/updateterminals2/${arrivalAirport.airportId}?${arrivalQueryString}`)
                            .then((response) => {
                                //console.log(response.data);
                                const arrivalTerminals = [...response.data];
                                const arrivalTerminal = arrivalTerminals.length ? arrivalTerminals.find(d => d.terminalName.toLowerCase() == selectedArrivalTerminal?.toLowerCase()) : null;

                                console.log('arrivalTerminals', arrivalTerminals);
                                console.log('arrivalTerminal', arrivalTerminal);

                                setLoading(false);
                                
                                onSaved({
                                    ...selectedRow,
                                    flightFromId: departureAirport.airportId,
                                    flightFrom: {...departureAirport},
                                    flightToId: arrivalAirport.airportId,
                                    flightTo: {...arrivalAirport},
                                    airportTerminalId: departureTerminal?.airportTerminalId,
                                    airportTerminal: departureTerminal,
                                    //airportTerminalName: departureTerminalName,
                                    arrivalTerminalId: arrivalTerminal?.airportTerminalId,
                                    arrivalTerminal: arrivalTerminal,
                                    //arrivalTerminalName: arrivalTerminalName,
                                    flightDateTime: `${selectedRow.departureDate.getFullYear()}-${selectedRow.departureDate.getMonth() + 1}-${selectedRow.departureDate.getDate()}T${selectedRow.departureTime}`,
                                    arrivalTime: `${arrivalDate.getFullYear()}-${arrivalDate.getMonth() + 1}-${arrivalDate.getDate()}T${selectedRow.arrivalTime}`,
                                });
                            })
                            .catch((error) => {
                                console.log({error});
                                setLoading(false);
                            })
                        ;

                        return;
                    }
                    
                    setLoading(false);
                    onSaved({
                        ...selectedRow,
                        flightFromId: departureAirport.airportId,
                        flightFrom: {...departureAirport},
                        flightToId: arrivalAirport.airportId,
                        flightTo: {...arrivalAirport},
                        airportTerminalId: departureTerminal?.airportTerminalId,
                        airportTerminal: departureTerminal,
                        arrivalTerminalId: null,
                        arrivalTerminal: null,
                        flightDateTime: `${selectedRow.departureDate.getFullYear()}-${selectedRow.departureDate.getMonth() + 1}-${selectedRow.departureDate.getDate()}T${selectedRow.departureTime}`,
                        arrivalTime: `${arrivalDate.getFullYear()}-${arrivalDate.getMonth() + 1}-${arrivalDate.getDate()}T${selectedRow.arrivalTime}`,
                    });
                })
                .catch((error) => {
                    console.log({error});
                    setLoading(false);
                })
            ;
            return;
        }

        if (!selectedRow.departureTerminals?.length && selectedRow.arrivalTerminals?.length)
        {
            setLoading(true);
            const arrivalQueryString = qs.stringify({terminalNames: selectedRow.arrivalTerminals}, { allowDots: true });

            api.post(`airportterminal/updateterminals2/${arrivalAirport.airportId}?${arrivalQueryString}`)
                .then((response) => {
                    console.log(response.data);
                    const arrivalTerminals = [...response.data];
                    const arrivalTerminal = arrivalTerminals.length ? arrivalTerminals.find(d => d.terminalName.toLowerCase() == selectedArrivalTerminal?.toLowerCase()) : null;
                    setLoading(false);
                    
                    onSaved({
                        ...selectedRow,
                        flightFromId: departureAirport.airportId,
                        flightFrom: {...departureAirport},
                        flightToId: arrivalAirport.airportId,
                        flightTo: {...arrivalAirport},
                        airportTerminalId: null,
                        airportTerminal: null,
                        arrivalTerminalId: arrivalTerminal?.airportTerminalId,
                        arrivalTerminal: arrivalTerminal,
                        flightDateTime: `${selectedRow.departureDate.getFullYear()}-${selectedRow.departureDate.getMonth() + 1}-${selectedRow.departureDate.getDate()}T${selectedRow.departureTime}`,
                        arrivalTime: `${arrivalDate.getFullYear()}-${arrivalDate.getMonth() + 1}-${arrivalDate.getDate()}T${selectedRow.arrivalTime}`,
                    });
                })
                .catch((error) => {
                    console.log({error});
                    setLoading(false);
                })
            ;
        }

        onSaved({
            ...selectedRow,
            flightFrom: {...departureAirport},
            flightTo: {...arrivalAirport},
            airportTerminal: null,
            arrivalTerminal: null,
            flightDateTime: `${selectedRow.departureDate.getFullYear()}-${selectedRow.departureDate.getMonth() + 1}-${selectedRow.departureDate.getDate()}T${selectedRow.departureTime}`,
            arrivalTime: `${arrivalDate.getFullYear()}-${arrivalDate.getMonth() + 1}-${arrivalDate.getDate()}T${selectedRow.arrivalTime}`,
        });
    }, [departureAirport, arrivalAirport, selectedFlatRows, onSaved]);

    return (
        <>
        {
            (loading) && <Loader />
        }
        <div
            className="modal-header my-0"
        >
            <Container fluid>
                <Row>
                    <Col lg={9}>
                        <h5
                            className="modal-title"
                        >
                            Flight List
                        </h5>
                    </Col>
                    <Col lg={3}>
                        <button
                            aria-label="Close"
                            className="close"
                            onClick={onClose}
                            type="button"
                        >
                            <span aria-hidden={true}>
                                <i className="fas fa-times-circle text-black" />
                            </span>
                        </button>
                    </Col>
                </Row>
            </Container>
        </div>
        <div
            className="modal-body"
        >
            <Container fluid>
                <Row>
                    <Col
                        xs={12}
                        md={2}
                    >
                        <FormGroup>
                            <Label>Flight Number</Label>
                            <Input
                                name="flightNumber"
                                type="text"
                                required
                                value={flightNumber ?? ""}
                                onChange={(e) => setFlightNumber(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        xs={12}
                        md={3}
                    >
                        <FormGroup>
                            <Label>From</Label>
                            <ComboBox
                                endpoint="/airport/search-maintenance"
                                isLookup={false}
                                idField="airportId"
                                valueField="airportName"
                                minLength={0}
                                selectedItem={departureAirport}
                                onChange={(selected) => setDepartureAirport(selected)}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        xs={12}
                        md={3}
                    >
                        <FormGroup>
                            <Label>To</Label>
                            <ComboBox
                                endpoint="/airport/search-maintenance"
                                isLookup={false}
                                idField="airportId"
                                valueField="airportName"
                                minLength={0}
                                selectedItem={arrivalAirport}
                                onChange={(selected) => setArrivalAirport(selected)}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        xs={12}
                        md={2}
                    >
                        <FormGroup>
                            <Label>Departure Date</Label>
                            <DatePicker
                                name="departureDateStart"
                                id="departureDateStart"
                                type="text"
                                required
                                value={formatDate(departureDateStart)}
                                onChange={(value) => {
                                    setDepartureDateStart(value);
                                }}
                                closeOnSelect
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        xs={12}
                        md={2}
                    >
                        <FormGroup>
                            <Label>Arrival Date</Label>
                            <DatePicker
                                name="departureDateEnd"
                                id="departureDateEnd"
                                type="text"
                                required
                                value={formatDate(departureDateEnd)}
                                onChange={(value) => {
                                    setDepartureDateEnd(value);
                                }}
                                closeOnSelect
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col
                        xs={12}
                        md={2}
                    >
                        <Button
                            color="default"
                            onClick={() => loadData()}
                            type="button"
                        >
                            Search
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Container>
                        <Grid
                            height='calc(35vh)'
                            width="100%"
                            rows={rows}
                            tableProps={getTableProps()}
                            headerGroups={headerGroups}
                            tableBodyProps={getTableBodyProps()}
                            prepareRow={prepareRow}
                        />
                    </Container>
                </Row>
            </Container>
        </div>
        <div
            className="modal-footer"
        >
            <Button
                color="secondary"
                onClick={() => {
                    onClose();
                }}
                type="button"
            >
                Close
            </Button>
            <Button
                color="info"
                onClick={() => handleSelectFlight()}
                type="button"
                disabled={!selectedFlatRows || !selectedFlatRows.length}
            >
                Select
            </Button>
        </div>
        {
            validationAlert &&
            <SweetAlert
                title="Error!"
                error
                confirmBtnText="OK"
                confirmBtnBsStyle="danger"
                onConfirm={() => setValidationAlert(null)}
            >
            {
                validationAlert.message
            }
            </SweetAlert>
        }
        </>
    );
}