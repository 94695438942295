const today = new Date();

export const initialState = {
    notification: null,
};

export const actions = {
    SET_NOTIFICATION: "SET_NOTIFICATION",
}

const jobOrderReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case actions.SET_NOTIFICATION:
            return {
                ...state,
                notification: payload.notification,
            };
    };
};

export default jobOrderReducer;
