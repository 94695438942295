////import React, { useState } from 'react';
import React, { useEffect, useState, useCallback, useMemo, useRef, Component } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import { useParams, useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import api from "../../../../services/api";
import qs from "qs";
import { useLocation } from 'react-router-dom';
import { SortsConverter, FiltersConverter } from "../../../../helpers"
import {
    Container, Row, Col, Button, Card, CardBody, CardTitle, UncontrolledTooltip, Progress, Label
} from "reactstrap";

//import { DefaultColumnFilter } from "../../../react-table/filters";
import { useTable, useFilters, useSortBy, useFlexLayout, useBlockLayout } from 'react-table';

import moment from "moment";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../../alerts";
import CopyToClipboard from "react-copy-to-clipboard";
import { TooltipButton } from '../../../inputs';

import fileDownload from "js-file-download";
import Loader from "../../../loaders";
import Grid from "../../../Grid";
//import DateFilter from "../../../date-filters"
//import { DatePicker } from '../../../date-pickers';
import { DefaultColumnFilter, DateColumnFilter } from "../../../react-table/filters";
import { formatDate, formatDateTime, BulkConfirmationMode, ViewAccess } from '../../../../utils';
import { IndeterminateCheckbox } from '../../../react-table/controls';
import { useSticky } from 'react-table-sticky';
import { SetupPanel } from './panels'
import ActionBtnCellRenderer from './cellRenderer/actionBtnCellRenderer'
import DispatchNameCellRenderer from './cellRenderer/dispatchNameCellRenderer'
import ActionTakenCellRenderer from './cellRenderer/actionTakenCellRenderer'
import AttachmentFromEmailCellRenderer from './cellRenderer/attachmentFromEmailCellRenderer'
import MessageTextCellRenderer from './cellRenderer/messageTextCellRenderer';
import { ParagraphNotification } from '../../../alerts';
import { DateComparator } from '../../../../helpers/AgGridHelper';

import { toGetter, userGetter } from './valueGetter'


import EditorEmail from '../../Candidates/EditorEmail';
import SMSEditor from '../../Candidates/EditorSMS';
import ConfirmBigDownloadAlert from "../../../alerts/ConfirmBigDownloadAlert"

const timeoutLength = 1500;


export default function AllRosterGrid(props) {
    //const { token } = useMsal();
    const history = useHistory();
    const [data, setData] = React.useState([]);
    const [attrCols, setAttrCols] = React.useState([]);

    const params = useParams();
    const [copiedIndex, setCopiedIndex] = React.useState(null);
    React.useEffect(() => {
        if (copiedIndex !== null) {
            const timeoutId = setTimeout(() => {
                setCopiedIndex(null);
            }, timeoutLength);

            return () => clearTimeout(timeoutId);
        }
    }, [copiedIndex]);


    const [smsEditorData, setSmsEditorData] = useState(null);
    const [emailEditorData, setEmailEditorData] = useState(null);


    useState(() => {
        console.log('smsEditorData', smsEditorData);
    }, [smsEditorData]);

    useState(() => {
        console.log('emailEditorData', emailEditorData);
    }, [emailEditorData]);


    const [loading, setLoading] = React.useState(false);
    const [itemInEdit, setItemInEdit] = React.useState(null);
    const [createNew, setCreateNew] = React.useState(false);
    const [canAutoOpen, setCanAutoOpen] = React.useState(true);


    const [downloadAlert, setDownloadAlert] = useState([]);



    const [pageNumber, setPageNumber] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(50);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [deleteAlert, setDeleteAlert] = React.useState([]);
    const [lookups, setLookups] = React.useState({});
    const [filters, setFilters] = React.useState([]);

    const defaultSort = React.useMemo(() => [
        { id: "orderIndex", desc: false },
        { id: "rosterCandidateNumber", desc: false },
        { id: "projectName", desc: false },

    ], []);

    const [sortBy, setSortBy] = React.useState(defaultSort);
    const skipPageResetRef = React.useRef();
    const apiurl = "roster";


    const location = useLocation();
    const {
        projectid,
        dispatchunitid,
        rosterid,
    } = qs.parse(location.search, { ignoreQueryPrefix: true });


    // BEGIN PARAGRAPH NOTIFICATION VARIABLES
    const [pnMessage, setPnMessage] = React.useState(null);
    const [pnTitle, setPnTitle] = React.useState(null);
    const [paragraphNotification, setParagraphNotification] = React.useState([]);
    const [pnMessageOpening, setPnMessageOpening] = React.useState(``);
    const [pnTitleOnGenerate, setPnTitleOnGenerate] = React.useState(``);
    // END PARAGRAPH NOTIFICATION VARIABLES


    // Access Right 
    const [viewAccess, setViewAccess] = React.useState(ViewAccess.FULLACCESS);
    const { instance } = useMsal();
    const user = instance.getActiveAccount();
    const isEditable = useMemo(() => { return viewAccess == ViewAccess.READONLY ? false : true }, [viewAccess]);
    const isReadonly = useMemo(() => { return viewAccess == ViewAccess.READONLY ? true : false }, [viewAccess]);
    const editableCellClass = useMemo(() => { return viewAccess == ViewAccess.READONLY ? `roster-cell` : `editable-cell roster-cell` }, [viewAccess]);


    const loadSchedule = (params) => {

        console.log('loadSchedule');
        console.log('params', params);

        var {
            dispatchUnitId,
            projectId,
            startDate,
            endDate,
            showAssignedTalents
        } = params;

        var newParam = {
            projectId: projectId,
            dispatchUnit: dispatchUnitId,
            start: !startDate ? '' : moment(startDate).format('YYYY-MM-DD'),
            end: !endDate ? '' : moment(endDate).format('YYYY-MM-DD'),
            showAssignedTalents: showAssignedTalents
        };

        return loadData(newParam);

    }


    // BEGIN AG GRID VARS -------------------------------------------------------------------------------------------------------------------------

    const [versionCounter, setVersionCounter] = useState(0);
    const gridRef = useRef();
    const [gridOptions, setGridOptions] = useState(null);
    //const [serverSideInitialRowCount, setServerSideInitialRowCount] = useState(0);

    // END AG GRID VARS ---------------------------------------------------------------------------------------------------------------------------

    // BEGIN AG GRID FUNCTIONS -------------------------------------------------------------------------------------------------------------------------

    const handleActionTaken = useCallback((original) => {
        // console.log(original);
        setLoading(true);
        const newAction = !original.actionTaken;

        api.put(`/${apiurl}/actiontaken/${original.messageLogId}?action=${newAction}`)
            .then((response) => {
                const responseData = response.data;
                const newData = [...data];
                const checkedRow = newData.filter(d => d.messageLogId === original.messageLogId)[0];
                const checkedIdx = newData.indexOf(checkedRow);
                const newRow = { ...checkedRow };
                newRow.actionTaken = newAction;
                newRow.actionTakenDate = responseData.actionTakenDate;
                newRow.modifiedBy = responseData.modifiedBy;
                newData.splice(checkedIdx, 1, newRow);
                // console.log({original, newRow, newData, data, responseData});
                setData(newData);
                updateSingleRow(original.messageLogId);
            })
            .catch((error) => {
                console.error("error: ", { error });
            })
            .finally(() => setLoading(false));
    }, [data]);

    const resizeColumns = useCallback(() => {
        if (!gridRef?.current?.columnApi || data?.length === 0)
            return;

        const allColumnIds = [];
        gridRef.current.columnApi.getColumns().forEach((column) => {
            // console.log(column, column.getId());
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }, [data, gridRef]);

    /*
    useEffect(() => {

        // Refresh datasource
        if (gridRef && gridRef.current && gridRef.current.api) {
            var datasource = getServerSideDatasource();
            gridRef.current.api.setServerSideDatasource(datasource);
        }

        // Refresh data
        refreshCache([]);

    }, [gridRef, params.action, params.requestId]);
    */

    const defaultColumns1 = [
        /*
        {
            name: "createdDate_",
            title: "Date / Time",
            field: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                debounceMs: 500
            },
            valueFormatter: (params) => {
                var { value } = params;
                //return value;
                return value ? moment(value).local().format("DD-MM-YYYY HH:mm:ss") : null;
                //return value ? moment(formatDateTime(value)).format("DD-MM-YYYY") : null;
            },
        },
        */
        {
            name: "dispatchUnitName",
            title: "Dispatch Unit"
        },
        {
            name: "locationName",
            title: "Location"
        },
        {
            name: "rosterCandidateNumber",
            title: "Slot"
        },
        {
            name: "position",
            title: "Natural Position"
        },
        {
            name: "rosterPosition",
            title: "Roster Position"
        },
        {
            name: "drivers_Licence",
            title: "Drivers Licence"
        },
        {
            name: "msic",
            title: "MSIC"
        },
        {
            name: "erms",
            title: "ERMS"
        },
        {
            name: "surname",
            title: "Surname"
        },
        {
            name: "firstName",
            title: "First Name"
        },
        {
            name: "pointOfHire",
            title: "Point of Hire"
        },
        {
            name: "mobilePhoneNumber",
            title: "Mobile Phone Number"
        },
        {
            name: "shift",
            title: "Shift"
        },
        {
            name: "accomodation",
            title: "Accomodation"
        },

    ];

    const defaultColumns2 = [

        {
            name: "mobe",
            title: "Mobe Date",
            field: 'date',
            filter: 'agDateColumnFilter',
            valueFormatter: (params) => {
                var { value } = params;
                return value ? moment(value).format("DD.MM.YYYY") : "N/A";
            },
            filterParams: {
                comparator: DateComparator
            }
        },
        {
            name: "mobeShift",
            title: "Mobe Shift"
        },
        {
            name: "workStart",
            title: "Work Start",
            field: 'date',
            filter: 'agDateColumnFilter',
            valueFormatter: (params) => {
                var { value } = params;
                return value ? moment(value).format("DD.MM.YYYY") : "N/A";
            },
            filterParams: {
                comparator: DateComparator
            }
        },
        {
            name: "workStartTime",
            title: "Work Start Time"
        },
        {
            name: "terminal",
            title: "Terminal"
        },
        {
            name: "mobeFlightInfo",
            title: "Mobe Flight Details"
        },
        {
            name: "mobeFlightComment",
            title: "Mobe Flight Notes"
        },
        {
            name: "endWorkDate",
            title: "End Work Date",
            field: 'date',
            filter: 'agDateColumnFilter',
            valueFormatter: (params) => {
                var { value } = params;
                return value ? moment(value).format("DD.MM.YYYY") : "N/A";
            },
            filterParams: {
                comparator: DateComparator
            }
        },
        {
            name: "workEndTime",
            title: "Work End Time"
        },
        {
            name: "demobe",
            title: "Demobe Date",
            field: 'date',
            filter: 'agDateColumnFilter',
            valueFormatter: (params) => {
                var { value } = params;
                return value ? moment(value).format("DD.MM.YYYY") : "N/A";
            },
            filterParams: {
                comparator: DateComparator
            }
        },
        {
            name: "demobeShift",
            title: "Demobe Am Pm"
        },
        {
            name: "demobeFlightInfo",
            title: "Demobe Flight Details"
        },
        {
            name: "demobeFlightComment",
            title: "Demobe Flight Notes"
        },
        /*{
            name: "rosterCandidateStatus",
            title: "Confirmation Status"
        },*/
        {
            name: "availabilityStatus",
            title: "Availability Status"
        },
        {
            name: "dasStatus",
            title: "DAS Status"
        },
        {
            name: "logisticStatus",
            title: "Logistic Status"
        },
        /*
        {
            name: "destination",
            title: "To",
            valueGetter: (params) => {
                var { data } = params;
                return data.emailAddress ?? data.mobilePhone;
            }
        },
        {
            name: "candidate.candidateName",
            title: "Talent Name"
        },
        {
            name: "rosterCandidate.roster.dispatchUnit.project.projectName",
            title: "Project Name"
        },
        {
            name: "rosterCandidate.roster.dispatchUnit.dispatchUnitName",
            title: "Dispatch Name",
            cellRenderer: DispatchNameCellRenderer,
        },
        {
            name: "rosterCandidate.roster.dispatchUnit.location.lookupValue",
            title: "Location"
        },
        {
            name: "rosterCandidate.hrispositionMaster.hrispositionMasterName",
            title: "Roster Position"
        },
        {
            name: "notificationType",
            title: "Message Type"
        },
        {
            name: "messagesMasterTitle",
            title: "Message Template Title",
            valueGetter: (params) => {
                var { data } = params;
                return data.messageType?.lookupValue === "Notification" ? data.messagesMaster?.title : "Confirmation"
            }
        },
        {
            name: "messageText",
            title: "Message Text",
            cellRenderer: MessageTextCellRenderer,
            cellRendererParams: {
                setPnMessage
            },
            //sortable: false,
        },

        {
            name: "anyAttachment",
            title: "Attachment from Email",
            cellRenderer: AttachmentFromEmailCellRenderer
        },
        {
            name: "action",
            title: "Action Taken",
            cellRenderer: ActionTakenCellRenderer,
            cellRendererParams: {
                handleActionTaken
            },
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['Yes', 'No']
            }
        },
        {
            name: "actionTakenDate",
            title: "Action Date",
            field: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                debounceMs: 500
            },
            valueFormatter: (params) => {
                var { value } = params;
                return value ? moment(value).local().format("DD-MM-YYYY HH:mm:ss") : null;
                //return value ? moment(formatDateTime(value)).format("DD-MM-YYYY") : null;
            },
        },
        {
            name: "user",
            title: "User",
            valueGetter: (params) => {
                var { data } = params;
                return data.actionTaken !== null ? data.modifiedBy : null;
            },
        },
        {
            name: "button",
            title: "Follow Up Action",
            width: 100,
            pinned: 'right',
            filter: '',

            filterParams: {
                name: 'action'
            },
            sortable: false,
            floatingFilter: false,
            cellClass: "action-button-cell",
            cellRenderer: ActionBtnCellRenderer,
            //valueFormatter: (value) => {
            //    console.log('value', value);
            //    return <>Abc</>
            //},
            cellRendererParams: {
                clicked: function (field) {
                    alert(`${field} was clicked`);
                },
            },
            additionalParams: () => {

                var props = {
                    copiedIndex: copiedIndex,
                    setCopiedIndex: setCopiedIndex,

                    itemInEdit: itemInEdit,
                    setItemInEdit: setItemInEdit,
                    handleDelete: handleDelete,
                    setSmsEditorData,
                    setEmailEditorData
                };

                return props;
            },
        },
        */
    ];

    const [agGridSort, setAgGridSort] = useState(['asc', 'desc']);

    const columnDefs = useMemo(() => {
        var defaultCols1 = defaultColumns1.map((c) => {
            var obj = {
                ...c,
                field: c.name,
                headerName: c.title,
            };

            if ('name' in obj) delete obj.name;
            if ('title' in obj) delete obj.title;

            return obj;
        });
        var defaultCols2 = defaultColumns2.map((c) => {
            var obj = {
                ...c,
                field: c.name,
                headerName: c.title,
            };

            if ('name' in obj) delete obj.name;
            if ('title' in obj) delete obj.title;

            return obj;
        });

        var attrNo = 0;
        var attrHeader = attrCols.map(s => {
            attrNo++;
            var { key, name, orderIndex } = s;
            return {
                field: key,
                headerName: name,
                //headerName: `${attrNo}. ${name}`,
            };
        });

        return defaultCols1.concat(attrHeader, defaultCols2);
    }, [attrCols]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        //filter: 'agSetColumnFilter',
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['reset'],
            debounceMs: 200,
            excelMode: 'mac',
        },
        floatingFilter: true,
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        suppressMenu: true,
    }), []);

    const loadingOverlayComponent = useMemo(() => Loader, []);

    const onCellEditRequest = useCallback((event) => {
        console.log("on cell edit request: ", { event });
    }, []);

    const autoSelectResources = useCallback((gridApi) => {
    });

    const sideBar = useMemo(() => ({
        toolPanels: [
            /*
            {
                id: 'setupPanel',
                labelDefault: 'Setup',
                labelKey: 'setupPanel',
                iconKey: 'fa-gear',
                toolPanel: SetupPanel,
                width: 280,
                toolPanelParams: () => {
                    return {
                        setCreateNew: setCreateNew,
                        setLoading: setLoading,
                        fileDownload: fileDownload
                    };
                }
            },
            */
            {
                id: 'setupPanel',
                labelDefault: 'Setup',
                labelKey: 'setupPanel',
                iconKey: 'fa-gear',
                toolPanel: SetupPanel,
                width: 280,
                toolPanelParams: {
                    today: new Date(),
                    loadSchedule,
                    autoSelectResources,
                    setLoading,
                    projectid,
                    dispatchunitid,
                    viewAccess,
                    data,
                },
            },

        ],
        defaultToolPanel: 'setupPanel',
        position: "left"
    }), [fileDownload]);

    const refreshCache = useCallback((route) => {
        //console.log('gridRef', gridRef);
        if (!gridRef || !gridRef.current || !gridRef.current.api)
            return;

        setVersionCounter(versionCounter + 1);
        gridRef.current.api.purgeServerSideCache();

    }, [versionCounter, params]);

    const onGridReady = useCallback((params) => {

        var datasource = getServerSideDatasource();
        params.api.setServerSideDatasource(datasource);
    }, []);

    const getServerSideDatasource = useCallback(() => {

        return {

            getRows: (params2) => {


                //console.log('getRows');
                //console.log('params2', params2);

                var { request } = params2;
                var { filterModel, sortModel } = request;

                var thisFilters = FiltersConverter(filterModel);
                var thisSorts = SortsConverter(sortModel);
                var selectedSorts = (thisSorts.length ? thisSorts : defaultSort);
                var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


                setFilters(thisFilters);
                setSortBy(selectedSorts);

                const queryString = qs.stringify({
                    filters: thisFilters,
                    sortBy: selectedSorts
                }, {
                    allowDots: true
                });


                const url = `/${apiurl}/${params2.request.startRow ?? 0}/${pageSize}${params.requestId ? ("/" + params.requestId + "/" + params.action) : ""}${queryString ? `?${queryString}` : ""}`;

                console.log('queryString', queryString);
                console.log('url', url);

                //api.get(`/path/to/endpoint/${params.request.startRow}/${params.request.endRow}`)
                api.get(url)
                    .then((response) => {
                        console.log('response', response);
                        var successData = {
                            rowData: response.data.data,
                            //rowCount: 100,
                            rowCount: response.data.total,
                        };


                        setData(response.data.data);
                        setTotalRecords(response.data.total);

                        //setServerSideInitialRowCount(response.data.total);

                        //console.log('success data', successData);
                        params2.success(successData);
                    })
                    .catch(error => {
                        console.error('error on getServerSideDatasource.getRows', error);
                        params2.fail();
                    });
            },
        };
    }, [params]);


    const updateSingleRow = useCallback((id) => {
        var gridApi = gridRef.current.api;
        gridApi.forEachNode(rowNode => {

            if (id == rowNode.data.messageLogId) {
                //console.log('rowNode', rowNode);

                const url = `/${apiurl}/${id}`;
                setLoading(true);
                api.get(url)
                    .then(({ data }) => {
                        //console.log('data to set', 'data', data);
                        rowNode.setData(data)
                    }).catch((error) => {
                        console.error("error: ", { error });
                    }).finally(() => setLoading(false));
            }
        });
    }, [gridRef]);


    // END AG GRID FUNCTIONS ---------------------------------------------------------------------------------------------------------------------------

    React.useEffect(() => {
        if (params.action === "new")
            setCreateNew(true);
    }, [params.action])

    const loadData = React.useCallback((paramObject = null) => {

        /*
        if (params2 && 'api' in params2) {
            var { api: api2 } = params2;
            api2.hideOverlay();
        }
        */

        //console.log('params2', params2);

        /*
        var paramObject = {
            projectId: 493,
            start: '2022-10-01',
            end: '2022-12-31'
        };
        */

        const queryString = qs.stringify(paramObject, { allowDots: false });
        skipPageResetRef.current = true;
        setItemInEdit(null);
        setCreateNew(false);
        setData([]);

        const url = `/${apiurl}/all-roster-grid?${queryString}`;
        setLoading(true);
        api.get(url)
            .then(({ data }) => {

                // Init vars
                var { rowGroup, attributeColumns } = data;


                // Set attribute columns
                setAttrCols(attributeColumns);


                var newData = rowGroup.map((s) => {

                    var newSelect = { ...s };
                    attributeColumns.forEach((v) => {
                        var { key, name, orderIndex } = v;
                        var value = newSelect.attributes.find(w => w.key == key)?.value;
                        newSelect[key] = value;
                    });

                    return newSelect;


                });


                // Set data
                setData(newData);
                setTotalRecords(newData.length);

            }).catch((error) => {
                setItemInEdit(null);
                setData([]);
                setAttrCols([]);
                console.error("error: ", { error });
            }).finally(() => setLoading(false));
    }, [filters, sortBy, pageNumber, pageSize, params]);

    const handleDelete = (item) => {
        const success = () => {
            setLoading(false);
            setDeleteAlert([<DeleteSuccessAlert key={0} onConfirm={() => setDeleteAlert([])} />]);
            if (data.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1);
            } else {
                refreshCache();
            }
        };
        const failure = () => {
            setLoading(false);
            setDeleteAlert([<DeleteFailedAlert key={0} onConfirm={() => setDeleteAlert([])} />]);
        };
        const events = {
            onConfirm: () => {
                setLoading(true);
                api.delete(`/${apiurl}/${item.jobOrderId}`).then(success).catch(failure);
            },
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert key={0} {...events} />])
    }

    React.useEffect(() => {
        console.log("data", data);
        if (canAutoOpen && data && data.length === 1 && params.requestId && params.action && params.action === "joborder") {
            setItemInEdit(data[0]);
        }
    }, [canAutoOpen, data, params]);


    // BEGIN PARAGRAPH NOTIFICATION HANDLER --------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {

        if (!pnMessage) {
            setParagraphNotification([]);
            return;
        }

        var events = {
            onConfirm: () => {
                setPnMessage(null);
                setPnTitle(null);
            },
            message: pnMessage,
            title: pnTitle ?? pnTitleOnGenerate
        }
        setParagraphNotification([<ParagraphNotification {...events} />]);

    }, [pnMessage, pnTitle]);
    // END PARAGRAPH NOTIFICATION HANDLER -----------------------------------------------------------------------------------------------------------------------


    // On load
    useEffect(() => {


        const apiCalls = [
            api.get(`/lookup/messagesmasters`),
        ];

        loadData();

        Promise.all(apiCalls)
            .then((responses) => {
                setLookups({
                    messagesMaster: responses[0].data
                });
            })
            .catch((error) => {
                console.error("error: ", { error });
            })
            .finally(() => {
                //setLoading(false);
            });
    }, [])



    return (
        <section className="main">
            {loading && <Loader />}
            {deleteAlert.length > 0 && deleteAlert}
            {downloadAlert.length > 0 && downloadAlert}
            {paragraphNotification.length > 0 && paragraphNotification}
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card className="no-transition">
                            <CardBody>
                                {/*
                                <CardTitle>
                                    <h4 className="text-center">Job Orders {params.requestId && `LRF #${params.requestId}`}</h4>
                                </CardTitle>
                                */}

                                {/*
                                <Row>
                                    <Col sm={6}>

                                        <TooltipButton
                                            id="export"
                                            title="Export to Excel"
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            disabled={(data == null || data.length == 0)}
                                            onClick={() => {

                                                // Function void to download the data
                                                var exportAction = () => {
                                                    setLoading(true);
                                                    setDownloadAlert([]);
                                                    const queryString = qs.stringify({ filters, sortBy }, { allowDots: true });
                                                    var url = `${apiurl}/export?${queryString}`;
                                                    api.get(url, { responseType: 'blob' })
                                                        .then(blob => {

                                                            console.log(`${apiurl}/export`);

                                                            var today = new Date();
                                                            var dd = String(today.getDate()).padStart(2, '0');
                                                            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                                                            var yyyy = today.getFullYear();

                                                            // console.log("Download: ", blob);
                                                            var fileNameDownload = `Communications_${yyyy}${mm}${dd}.xlsx`;
                                                            fileDownload(blob.data, fileNameDownload);
                                                        }).catch(error => {
                                                            console.error(error)
                                                        }).finally(() => setLoading(false));
                                                }

                                                // If big data download,
                                                // Confirm the user first
                                                if (totalRecords != null && totalRecords > 1000) {

                                                    var events = {
                                                        onConfirm: () => exportAction(),
                                                        onCancel: () => setDownloadAlert([])
                                                    }
                                                    setDownloadAlert([<ConfirmBigDownloadAlert {...events} />]);
                                                }
                                                // If small data download,
                                                // Just directly download the data
                                                else {
                                                    exportAction();
                                                }

                                            }}
                                        >
                                            <i className="fas fa-file-excel"></i> Export
                                        </TooltipButton>


                                    </Col>
                                    <Col sm={6} className="text-xs-left text-sm-right d-flex flex-wrap justify-content-end align-items-center">


                                    </Col>
                                </Row>
                                */}

                                <Row>
                                    <Col sm={12}>
                                        <div className="ag-theme-alpine ag-grid ag-ignore-disabled-input" style={{ width: '100%', height: 'calc(100vh - 200px)' }}>
                                            <AgGridReact
                                                ref={gridRef}
                                                gridOptions={gridOptions}
                                                //domLayout={'autoHeight'}
                                                columnDefs={columnDefs}
                                                rowData={data}
                                                defaultColDef={defaultColDef}
                                                loadingOverlayComponent={loadingOverlayComponent}
                                                sideBar={sideBar}
                                                suppressColumnVirtualisation={false}
                                                suppressRowVirtualisation={false}
                                                debounceVerticalScrollbar={false}
                                                rowBuffer={20}
                                                headerHeight={30}
                                                groupHeaderHeight={30}
                                                rowHeight={30}
                                                floatingFiltersHeight={30}
                                                // readOnlyEdit={true}
                                                onRowDataUpdated={() => resizeColumns()}
                                                suppressDragLeaveHidesColumns={true}
                                                suppressColumnMoveAnimation={true}
                                                animateRows={false}
                                                onCellEditRequest={onCellEditRequest}
                                                excelStyles={excelStyles()}
                                                pagination={true}
                                                paginationPageSize={pageSize}
                                                cacheBlockSize={pageSize}
                                                //rowModelType={'serverSide'}
                                                //serverSideInfiniteScroll={true}
                                                //onGridReady={onGridReady}
                                                sortingOrder={agGridSort}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {
                smsEditorData &&
                <SMSEditor
                    candidateId={smsEditorData.rosterCandidateId || smsEditorData.jobOrderCandidateId ? null : smsEditorData.candidateId}
                    refId={smsEditorData.rosterCandidateId ?? smsEditorData.jobOrderCandidateId}
                    projectId={smsEditorData.rosterCandidateId ? smsEditorData.rosterCandidate.roster.dispatchUnit.projectId : smsEditorData.jobOrderCandidateId ? smsEditorData.jobOrderCandidate.jobOrder.projectId : null}
                    project={smsEditorData.rosterCandidateId ? { projectId: smsEditorData.rosterCandidate.roster.dispatchUnit.projectId, projectName: smsEditorData.rosterCandidate.roster.dispatchUnit.project.projectName } : smsEditorData.jobOrderCandidateId ? { projectId: smsEditorData.jobOrderCandidate.jobOrder.projectId, projectName: smsEditorData.jobOrderCandidate.jobOrder.projectName } : null}
                    items={[{ ...smsEditorData }]}
                    onClose={() => {
                        updateSingleRow(smsEditorData.messageLogId);
                        setSmsEditorData(null);
                    }}
                    onSaved={() => {
                        updateSingleRow(smsEditorData.messageLogId);
                        setSmsEditorData(null);
                    }}
                    lookups={{
                        ...lookups
                    }}
                    sourcePage={smsEditorData.rosterCandidateId ? "roster" : smsEditorData.jobOrderCandidateId ? "jobOrder" : null}
                />
            }
            {
                emailEditorData &&
                <EditorEmail
                    candidateId={emailEditorData.rosterCandidateId || emailEditorData.jobOrderCandidateId ? null : emailEditorData.candidateId}
                    items={[{ ...emailEditorData }]}
                    refId={emailEditorData.rosterCandidateId ?? emailEditorData.jobOrderCandidateId}
                    projectId={emailEditorData.rosterCandidateId ? emailEditorData.rosterCandidate.roster.dispatchUnit.projectId : emailEditorData.jobOrderCandidateId ? emailEditorData.jobOrderCandidate.jobOrder.projectId : null}
                    project={emailEditorData.rosterCandidateId ? { projectId: emailEditorData.rosterCandidate.roster.dispatchUnit.projectId, projectName: emailEditorData.rosterCandidate.roster.dispatchUnit.project.projectName } : emailEditorData.jobOrderCandidateId ? { projectId: emailEditorData.jobOrderCandidate.jobOrder.projectId, projectName: emailEditorData.jobOrderCandidate.jobOrder.projectName } : null}
                    onClose={() => {
                        updateSingleRow(emailEditorData.messageLogId);
                        setEmailEditorData(null);
                    }}
                    onSaved={() => {
                        updateSingleRow(emailEditorData.messageLogId);
                        setEmailEditorData(null);
                    }}
                    lookups={{
                        ...lookups
                    }}
                    sourcePage={emailEditorData.rosterCandidateId ? "roster" : emailEditorData.jobOrderCandidateId ? "jobOrder" : null}
                />
            }
        </section>
    )

}


export const excelStyles = () => {
    const headerDefaultStyle = {
        alignment: {
            vertical: 'Center',
            horizontal: "Center",
        },
        interior: {
            color: '#f8f8f8',
            pattern: 'Solid',
            patternColor: undefined,
        },
        borders: {
            borderTop: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderRight: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderBottom: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderLeft: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
        },
        font: {
            fontName: 'Roboto',
            color: '#181d1f',
            bold: true,
            size: 12,
        },
    }

    return [
        {
            id: 'header',
            ...headerDefaultStyle
        },
        {
            id: 'date-header',
            ...headerDefaultStyle,
            alignment: {
                vertical: 'Center',
                horizontal: "Center",
                rotate: 90,
            },
        },
        {
            id: 'cell',
            alignment: {
                vertical: 'Top',
                wrapText: true,
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid',
                patternColor: undefined,
            },
            borders: {
                borderTop: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderRight: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderBottom: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderLeft: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
            },
            font: {
                fontName: 'Roboto',
                color: '#181d1f',
                weight: 700,
                size: 12,
            },
        },
    ];
};


