import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";

export default function ConfirmBigDownloadAlert(props) {
    const {
        onConfirm,
        onCancel,
    } = props;

    return (
        <SweetAlert

            warning
            showCancel
            confirmBtnText="Confirm"
            confirmBtnBsStyle="danger"
            title={<h4 className="mb-0">Please confirm</h4>}
            onConfirm={onConfirm} // need to add loading indicator on confirm
            onCancel={onCancel}
            focusCancelBtn
        >
            <p>This will export extensive amount of data.</p>
            <p>Are you sure you want to continue?</p>
        </SweetAlert>
    );
}