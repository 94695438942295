/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import api from "./services/api";
import { Route } from 'react-router';
import ScrollToTop from "./components/ScrollToTop";
import { Layout } from './components/Layout';
import NavbarMain from "./components/navbars/NavbarMain";
import SimpleNavbarBcMain from "./components/navbars/SimpleNavbarBcMain";
import Breadcrumb from "./components/navbars/Breadcrumb";
import FooterMain from "./components/footers/FooterMain";
import { Home } from './components/Home';
import { LabourRequestForm, LabourRequestList } from './components/sections/LabourRequest';
import LabourRequestGrid from './components/sections/LabourRequest/LrGridV2/LabourRequestGrid';
import { TnaMatrix, TnaView, ActiveTna } from "./components/sections/TrainingNeedsAnalysis";
import Maintenance from "./components/sections/Maintenance";
import { JobOrderV2, JobOrderV1, Milestone, EditJobOrderInPage, EditJobOrderInPageAlt } from "./components/sections/LabourRequest/JobOrder";
import { loginRequest } from "./auth/authConfig";
import { Rates } from './components/sections/Rates';
import RateSheetTcView from './components/sections/RateSheetTc';
import { TermsAndConditionsList } from "./components/sections/TermsAndConditions";
import Loader from "./components/loaders";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';
import ProtectedRoute from "./components/ProtectedRoute";
import Unauthorized from "./components/Unauthorized";
import { roles } from "./auth/roles";
import { CandidateList, CandidateDocuments } from "./components/sections/Candidates";
import CandidateGrid from "./components/sections/Candidates/GridV2/CandidateGrid";
import CandidatePage from "./components/sections/Candidates/EditorPage";
import { ContractTermView } from "./components/sections/ContractTerm";
import { ProjectContractTermList } from "./components/sections/ProjectContractTerm";
import { ProjectContractTemplateList } from "./components/sections/ProjectContractTemplate";
import { EntityContractTemplateList } from "./components/sections/EntityContractTemplate";
import { CrewList } from "./components/sections/Crews";
import { AccommodationList } from "./components/sections/Accommodation";
import { RosterList, CandidateLocationReport, CreateNewWithoutForecast } from "./components/sections/Roster";
import { Forecast as Forecast2, Grid as ForecastGrid, ForecastWizard, RosterWizard } from "./components/sections/Forecasting";
import TnaDRGridJobOrder from './components/sections/LabourRequest/JobOrder/TNADiscrepancyReportV2/TnaDRGridJobOrder';
import JobOrderTNADiscrepancyReport from './components/sections/LabourRequest/JobOrder/TNADiscrepancyReport';
import RosterCandidateAllocation from './components/reports/RosterCandidateAllocation';
import RosterExport from './components/reports/RosterExport';
import CommunicationGrid from "./components/sections/Communication/GridV2/CommunicationGrid"
import { CommunicationList } from "./components/sections/Communication";
import RosterTNADiscrepancyReport from './components/sections/Roster/TNADiscrepancyReport';
import TnaDRGrid from './components/sections/Roster/TNADiscrepancyReportV2/TnaDRGrid';
import RosterSiteExport from './components/reports/RosterSiteExport';
import RosterTNAExport from './components/reports/RosterTNAExport';
import Grid from './components/sections/RosterV2/Grid';
import ReportCentreList from './components/sections/ReportCentre/List';
import GenericReport from './components/reports/GenericReport';
import { RosterProvider } from "./components/sections/RosterV2/contexts/RosterContext";
import AllRosterGrid from "./components/sections/Roster/AllRosterGrid/AllRosterGrid";
import { RosterProvider as RosterProvider_AllRosterGrid } from "./components/sections/Roster/AllRosterGrid/contexts/RosterContext";
import { ForecastProvider } from "./components/sections/Forecasting/contexts/ForecastContext";
import "./assets/css/custom.css";
import PortalUserList from './components/sections/Candidates/PortalUserList';
import CandidateMergeDialog from './components/sections/Candidates/DataMerge';
import ReviewRequestList from './components/sections/LabourRequest/ReviewRequestList';
import ReviewRequestDetail from './components/sections/LabourRequest/ReviewRequestDetail';
import LabourRequestApprovalList from './components/sections/LabourRequest/Approval/LabourRequestApprovalList';
import { AppProvider } from './contexts/AppProvider';
import { JobOrderProvider } from "./components/sections/LabourRequest/JobOrder/GridV2/contexts/JobOrderContext";
import { ReportProvider as RosterAccommodationReportProvider } from "./components/sections/RosterV2/reports/contexts/ReportContext";
import RosterAccommodationReport from './components/sections/RosterV2/reports/RosterAccommodationReport';
import { GridProvider as RosterEmptySlotsProvider } from "./components/sections/RosterV2/pages/EmptySlots/contexts/GridContext";
import RosterEmptySlot from './components/sections/RosterV2/pages/EmptySlots/RosterEmptySlotGrid';
import LRFDetailList from './components/sections/LabourRequest/JobOrder/LRFDetailList';
import JobOrderMilestoneEditor from './components/sections/LabourRequest/JobOrder/JobOrderMilestoneEditor';

const AllRosterGridProvider = (props) => <RosterProvider_AllRosterGrid><AllRosterGrid {...props} /></RosterProvider_AllRosterGrid>;
const RosterGrid = (props) => <RosterProvider><Grid {...props} /></RosterProvider>;
const JobOrderGrid = (props) => <JobOrderProvider><JobOrderV2 {...props} /></JobOrderProvider>;
const RosterAccommodationReportGrid = (props) => <RosterAccommodationReportProvider><RosterAccommodationReport {...props} /></RosterAccommodationReportProvider>;
const RosterEmptySlotGrid = (props) => <RosterEmptySlotsProvider><RosterEmptySlot {...props} /></RosterEmptySlotsProvider>;
//const ForecastGrid = (props) => <ForecastProvider><Forecast {...props} /></ForecastProvider>;

export default function App(props) {

    const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, loginRequest);
    const { instance, accounts, inProgress } = useMsal();

    /*********************************************************************************************/
    /* Don't reapeat this code. Needed for workaround only. Refactoring of Roster needs to occur */
    /*********************************************************************************************/
    const [rosterLookups, setRosterLookups] = useState({ shifts: [], siteTravelTypes: [], pointOfHires: [] });
    useEffect(() => {
        const apiCalls = [
            api.get(`/lookup/shift`),
            api.get(`/lookup/sitetraveltype`),
            api.get(`/lookup/pointofhire`),
        ];
        Promise.all(apiCalls)
            .then((response) => setRosterLookups({
                shifts: response[0].data,
                siteTravelTypes: response[1].data,
                pointOfHires: response[2].data,
            }))
            .catch((error) => console.log(error.response));
    }, []);
    /*******/
    /* End */
    /*******/

    return (
        <AppProvider>
            <Layout>
                <AuthenticatedTemplate>
                    <ScrollToTop />
                    <>
                        <SimpleNavbarBcMain
                            type="red"
                            noMenu
                            directions={[
                                {
                                    key: "rosterwizard",
                                    value: "Roster Wizard - Create Roster Template Mapping"
                                },
                                {
                                    key: "rosterwizardfinal",
                                    value: "Roster Wizard - Assign Resources"
                                },
                                {
                                    key: "roster",
                                    value: "Manage Roster"
                                },
                                {
                                    key: "rosteremptyslots",
                                    value: "Roster Empty Slots"
                                },
                                {
                                    key: "all-roster-grid",
                                    value: "ALl Roster Grid"
                                },
                                {
                                    key: "roster-create-new-without-forecast",
                                    value: "Create New Roster without Forecast"
                                },
                                {
                                    key: "lrf",
                                    value: "Labour Request Form",
                                    customRedirect: "/lrflist"
                                },
                                {
                                    key: "lrflist",
                                    value: "Labour Request Forms - List"
                                },
                                {
                                    key: "tnadiscrepancy-joborder",
                                    value: "TNA Discrepancy Report"
                                },
                                {
                                    key: "tnadiscrepancy-roster",
                                    value: "TNA Discrepancy Report"
                                },
                                {
                                    key: "joborder",
                                    value: "Job Order"
                                },
                                {
                                    key: "joborderdetail",
                                    value: "Job Order",
                                    customRedirect: "/joborder"
                                },
                                {
                                    key: "joborderlrfdetails",
                                    value: "Job Order",
                                    customRedirect: "/joborder"
                                },
                                {
                                    key: "jobordermilestone",
                                    value: "Job Order Milestone",
                                    customRedirect: "/joborder"
                                },
                                {
                                    key: "reportcentre",
                                    value: "Report Centre"
                                },
                                {
                                    key: "reportviewer",
                                    value: "Report Centre",
                                    customRedirect: "/reportcentre"
                                },
                                {
                                    key: "talent",
                                    value: "Talents",
                                    customRedirect: "/talents"
                                },
                                {
                                    key: "portalusers",
                                    value: "Portal Users",
                                    customRedirect: "/talents"
                                },
                                {
                                    key: "lrfreview",
                                    value: "Application Review Requests",
                                },
                                {
                                    key: "lrfreviewdetail",
                                    value: "Application Review Requests",
                                    customRedirect: "/lrfreview",
                                },
                                {
                                    key: "lrfapproval",
                                    value: "Labour Request Approval",
                                },
                                {
                                    key: "lrfapprovaldetail",
                                    value: "Labour Request Approval",
                                    customRedirect: "/lrfapproval",
                                },
                                {
                                    key: "rosteraccommodationreport",
                                    value: "Roster Accommodation Report",
                                },
                            ]}
                        />
                        {/* 
                    <NavbarMain type="white" noMenu />
                    <Breadcrumb
                        directions={[
                            {
                                key: "rosterwizard",
                                value: "Roster Wizard - Create Roster Template Mapping"
                            },
                            {
                                key: "rosterwizardfinal",
                                value: "Roster Wizard - Assign Resources"
                            },
                            {
                                key: "roster",
                                value: "Manage Roster"
                            },
                            {
                                key: "all-roster-grid",
                                value: "ALl Roster Grid"
                            },
                            {
                                key: "roster-create-new-without-forecast",
                                value: "Create New Roster without Forecast"
                            },
                            {
                                key: "lrf",
                                value: "Labour Request Form",
                                customRedirect: "/lrflist"
                            },
                            {
                                key: "lrflist",
                                value: "Labour Request Forms - List"
                            },
                            {
                                key: "tnadiscrepancy-joborder",
                                value: "TNA Discrepancy Report"
                            },
                            {
                                key: "tnadiscrepancy-roster",
                                value: "TNA Discrepancy Report"
                            },
                            {
                                key: "joborder",
                                value: "Job Order"
                            },
                            {
                                key: "joborderdetail",
                                value: "Job Order",
                                customRedirect: "/joborder"
                            },
                            {
                                key: "reportcentre",
                                value: "Report Centre"
                            },
                            {
                                key: "reportviewer",
                                value: "Report Centre",
                                customRedirect: "/reportcentre"
                            },
                            {
                                key: "talent",
                                value: "Talents",
                                customRedirect: "/talents"
                            },
                            {
                                key: "portalusers",
                                value: "Portal Users",
                                customRedirect: "/talents"
                            },
                            {
                                key: "lrfreview",
                                value: "Application Review Requests",
                            },
                            {
                                key: "lrfreviewdetail",
                                value: "Application Review Requests",
                                customRedirect: "/lrfreview",
                            },
                            {
                                key: "lrfapproval",
                                value: "Labour Request Approval",
                            },
                            {
                                key: "lrfapprovaldetail",
                                value: "Labour Request Approval",
                                customRedirect: "/lrfapproval",
                            },
                        ]}
                    />
                    */}
                        <Route exact path='/' component={Home} />
                        <Route exact path='/unauthorized' component={Unauthorized} />
                        <ProtectedRoute path='/lrf/:id?' component={LabourRequestForm} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/lrflist/:skip?/:take?' component={LabourRequestGrid} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/lrflistV1/:skip?/:take?' component={LabourRequestList} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/lrfreview' component={ReviewRequestList} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/lrfreviewdetail/:id' component={ReviewRequestDetail} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/lrfapproval' component={LabourRequestApprovalList} allowedRoles={[roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/lrfapprovaldetail/:id?' component={LabourRequestForm} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/tna/:id?' component={TnaView} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/activetna' component={ActiveTna} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        {/* <ProtectedRoute path='/matrix' component={TnaMatrix} allowedRoles={[roles.Recruiter]} /> */}

                    {/* <ProtectedRoute path='/maintenance/trainingmaster' component={TrainingMaster} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} /> */}
                        <ProtectedRoute path='/maintenance/:selected?/:mode?/:id?' component={Maintenance} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/rates/:projectId?' component={RateSheetTcView} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                    {/* <ProtectedRoute path='/terms' component={TermsAndConditionsList} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />  */}
                        <ProtectedRoute path='/milestone/:requestId(\d+)?/:action(\D+)?' component={Milestone} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        
                        
                        <ProtectedRoute path='/joborderdetail/:fpJobOrderId' component={JobOrderV2} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/joborder/:requestId(\d+)?/:action(\D+)?' component={JobOrderGrid} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/joborderlrfdetails/:requestId(\d+)' component={LRFDetailList} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/joborderV1/:requestId(\d+)?/:action(\D+)?' component={JobOrderV1} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/jobordermilestone/:jobOrderId' component={JobOrderMilestoneEditor} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />

                       


                    {/* <ProtectedRoute path='/joborder' component={JobOrder} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} /> 

                        <ProtectedRoute path='/joborder/:requestId?' component={JobOrder} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]}  />  */}
                        <ProtectedRoute path='/talents/:id?' exact component={CandidateGrid} allowedRoles={[roles.Recruiter, roles.Admin]} />
                        <ProtectedRoute path='/talentsV1/:id?' exact component={CandidateList} allowedRoles={[roles.Recruiter, roles.Admin]} />
                        <ProtectedRoute path='/talents/:id/docs' component={CandidateDocuments} allowedRoles={[roles.Recruiter, roles.Admin]} />
                        <ProtectedRoute path='/talent/:id' exact component={CandidatePage} allowedRoles={[roles.Recruiter, roles.Admin]} />
                        <ProtectedRoute path='/talents/merge/wizard' component={CandidateMergeDialog} allowedRoles={[roles.Recruiter, roles.Admin]} />

                        <ProtectedRoute path='/contractterm' component={ContractTermView} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/projectterm' component={ProjectContractTermList} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/pct/:projectId?' component={ProjectContractTemplateList} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/ect/:entityId?' component={EntityContractTemplateList} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/accommodation' component={AccommodationList} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        {/* <ProtectedRoute path='/crew' component={CrewList} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]}  /> */}
                        {/* <ProtectedRoute path='/roster_old' component={RosterList} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} /> */}
                        <ProtectedRoute path='/employeelocreport' component={CandidateLocationReport} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/forecasting' component={ForecastGrid} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin, roles.Spc]} />
                        <ProtectedRoute path='/forecasting_old' component={Forecast2} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/rosterwizard' component={ForecastWizard} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/rosterwizardfinal' component={RosterWizard} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/tnadiscrepancy-joborder/:id' component={TnaDRGridJobOrder} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/tnadiscrepancy-joborderV1/:id' component={JobOrderTNADiscrepancyReport} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/reports/rostercandidateallocation' component={RosterCandidateAllocation} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/reports/rosterexport' component={RosterExport} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/communication' component={CommunicationGrid} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/communicationV1' component={CommunicationList} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/portalusers' component={PortalUserList} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />

                        <ProtectedRoute path='/tnadiscrepancy-roster' component={TnaDRGrid} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/tnadiscrepancy-rosterV1' component={RosterTNADiscrepancyReport} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/reports/rostersiteexport' component={RosterSiteExport} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        <ProtectedRoute path='/reports/rostertnaexport' component={RosterTNAExport} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />

                        {/* <ProtectedRoute path='/aggrid' component={Grid} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]}  /> */}
                        <ProtectedRoute path='/roster' component={RosterGrid} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin, roles.Spc]} {...rosterLookups} />
                        <ProtectedRoute path='/rosteremptyslots' component={RosterEmptySlotGrid} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin, roles.Spc]} {...rosterLookups} />
                        <ProtectedRoute path='/rosteraccommodationreport' component={RosterAccommodationReportGrid} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin, roles.Spc]} />
                        <ProtectedRoute path='/all-roster-grid' component={AllRosterGridProvider} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin, roles.Spc]} />
                        <ProtectedRoute path='/roster-create-new-without-forecast' component={CreateNewWithoutForecast} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin, roles.Spc]} />
                        <ProtectedRoute path='/reportcentre' component={ReportCentreList} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin, roles.Spc]} />
                        <ProtectedRoute path='/reportviewer/:reportName' component={GenericReport} allowedRoles={[roles.Recruiter, roles.Manager, roles.Admin]} />
                        {/* <FooterMain /> */}
                    </>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Loader />
                </UnauthenticatedTemplate>
            </Layout>
        </AppProvider>
    )
}
