import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Loader from '../../../loaders';
import api from "../../../../services/api";
import { Button, Col, Container, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import { useFlexLayout, useRowSelect, useTable } from 'react-table';
import { IndeterminateCheckbox } from '../../../react-table/controls';
import { TooltipButton } from '../../../inputs';

function LocationListComponent(props) {
    const { id, title, data, onSelect, onMove, hideLabel, columnHeader, listPosition } = props;

    const columns = useMemo(() => [
        {
            Header: columnHeader,
            accessor: 'lookupValue',
        },
    ], []);

    const defaultColumn = useMemo(
        () => ({
            minWidth: 30,
            width: 150,
            maxWidth: 200,
        }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // selectedFlatRows,
        toggleRowSelected,
    } = useTable (
        {
            columns,
            data,
            defaultColumn,
        },
        useFlexLayout,
        useRowSelect,
        // hooks => {
        //     hooks.visibleColumns.push(columns => [
        //         {
        //             id: 'selection',
        //             minWidth: 35,
        //             width: 35,
        //             maxWidth: 35,
        //             Header: ({ getToggleAllRowsSelectedProps }) => (
        //                 <div>
        //                     <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} id={`milestone-header-${id}`} />
        //                 </div>
        //             ),
        //             Cell: ({ row }) => (
        //                 <div>
        //                     <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} id={`milestone-${id}-${row.id}`} />
        //                 </div>
        //             ),
        //         },
        //         ...columns,
        //     ]);
        // }
    );

    // useEffect(() => {
    //     onSelect(selectedFlatRows.map(d => ({ ...d.original, selected: true })));
    // }, [selectedFlatRows]);

    return (
        <>
            {!hideLabel && <label className="">{title}</label>}
            <div {...getTableProps()} className="table flex table-hover scrollable">
                <div className="thead">
                    {headerGroups.map(headerGroup => {
                        return (<div {...headerGroup.getHeaderGroupProps()} className="tr">
                            {headerGroup.headers.map((column, index) => {
                                return (<div {...column.getHeaderProps()} className="th">
                                    {column.render('Header')}
                                </div>);
                            })}
                            <div className="th th-action text-right" style={{width: "77px"}}></div>
                        </div>);
                    }
                    )}
                </div>
                <div {...getTableBodyProps()} className="tbody">
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <div {...row.getRowProps()} className="tr">
                            {
                                listPosition === "right" &&
                                <div className="td td-action text-left" style={{width: "60px"}}>
                                    <TooltipButton
                                        id={`move_${i}`}
                                        title="Move"
                                        className="btn-icon"
                                        color="default"
                                        size="sm"
                                        type="button"
                                        outline
                                        onClick={() => {
                                            onMove(row.original)
                                        }}
                                    >
                                        <i className="fas fa-chevron-left"></i>
                                    </TooltipButton>
                                </div>
                            }
                            {
                                row.cells.map(cell => {
                                    return <div {...cell.getCellProps({
                                        onClick: cell.column.id !== "selection" ? () => toggleRowSelected(row.id) : undefined
                                    })}
                                        className={`td ${cell.column.id !== "selection" ? "cursor-pointer" : ""}`}
                                    >
                                        {cell.render('Cell')}
                                    </div>;
                                })
                            }
                            {
                                listPosition === "left" &&
                                <div className="td td-action text-right" style={{width: "60px"}}>
                                    <TooltipButton
                                        id={`move_${i}`}
                                        title="Move"
                                        className="btn-icon"
                                        color="default"
                                        size="sm"
                                        type="button"
                                        outline
                                        onClick={() => {
                                            onMove(row.original)
                                        }}
                                    >
                                        <i className="fas fa-chevron-right"></i>
                                    </TooltipButton>
                                </div>
                            }
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default function LocationList(props) {
    const {
        projectId,
        setSelectedProjectId
    } = props;

    const [ loading, setLoading ] = useState(false);
    const [ project, setProject ] = useState(null);
    const [ selectedLocations, setSelectedLocations ] = useState([]);
    const [ locationName, setLocationName ] = useState(null);
    const [ locations, setLocations ] = useState([]);

    const loadData = useCallback(() => {
        setProject(null);
        setSelectedLocations([]);

        if (!projectId)
            return;

        setLoading(true);

        const apiCalls = [
            api.get(`/project/${projectId}`),
            api.get(`/project/search-locations?id=${projectId}`)    
        ]

        Promise.all(apiCalls)
            .then((responses) => {
                console.log(responses);
                setProject(responses[0]?.data);
                setSelectedLocations(responses[1]?.data ?? []);
            })
            .catch(error => console.error(error))
            .finally(() => setLoading(false))
        ;
    }, [projectId]);

    useEffect(() => {
        loadData();
    }, [projectId]);

    const loadLocationData = useCallback(() => {
        setLocations([]);

        if (!locationName || locationName?.trim() === "")
            return;
        
        setLoading(true);
        api.get(`/lookup/location?filter=${locationName}`)
            .then((response) => {
                const loadedLocations = response.data?.filter(p => selectedLocations.filter(sp => sp.lookupId === p.lookupId).length === 0);
                console.log(loadedLocations);
                setLocations(loadedLocations);
            })
            .catch(error => console.error(error))
            .finally(() => setLoading(false))
        ;
    }, [selectedLocations, locationName]);

    useEffect(() => {
        loadLocationData();
    }, [locationName]);

    const handleSave = useCallback(() => {
        if (!selectedLocations)
            return;
        
        setLoading(true);

        api.post(`/project/locations/${projectId}`, selectedLocations.map(sp => sp.lookupId))
            .then(() => {
                setLoading(false);
                setSelectedProjectId(null);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            })
        ;
    }, [projectId, selectedLocations]);

    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
        {
            loading && <Loader />
        }
            <div className="modal-header">
                <h5 className="modal-title">
                    Manage Project's Location - {project?.projectName}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={() => setSelectedProjectId(null)}
                    type="button"
                >
                    <span aria-hidden={true}>x</span>
                </button>
            </div>
            <div className="modal-body">
                <Container fluid>
                    <Row>
                        <Col lg={6}>
                            <Row>
                                <Col lg={12}>
                                    <FormGroup>
                                        <Label>
                                            Available Location Name
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            value={locationName ?? ""}
                                            onChange={(e) => setLocationName(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <LocationListComponent
                                id="available-locations"
                                title="Available Locations"
                                hideLabel={true}
                                data={locations}
                                onSelect={() => {}}
                                onMove={(data) => {
                                    const currentLocations = [...locations];
                                    const removedLocation = currentLocations.filter(sp => sp.lookupId === data.lookupId)[0];
                                    currentLocations.splice(currentLocations.indexOf(removedLocation), 1);
                                    setLocations(currentLocations);
                                    const currentSelectedLocations = [...selectedLocations];
                                    currentSelectedLocations.push(data);
                                    setSelectedLocations(currentSelectedLocations);
                                }}
                                columnHeader="Available Locations"
                                listPosition="left"
                            />
                        </Col>
                        <Col lg={6}>
                            <LocationListComponent
                                id="selected-locations"
                                title="Selected Locations"
                                hideLabel={true}
                                data={selectedLocations}
                                onSelect={() => {}}
                                onMove={(data) => {
                                    const currentSelectedLocations = [...selectedLocations];
                                    const removedLocation = currentSelectedLocations.filter(sp => sp.lookupId === data.lookupId)[0];
                                    currentSelectedLocations.splice(currentSelectedLocations.indexOf(removedLocation), 1);
                                    setSelectedLocations(currentSelectedLocations);
                                    console.log({locationName, locations, data, added: data?.lookupValue?.toLowerCase().includes(locationName?.toLowerCase())});

                                    if (!locationName || locationName.trim() === "" || !data?.lookupValue?.toLowerCase().includes(locationName?.toLowerCase()))
                                        return;

                                    const currentLocations = [...locations];
                                    currentLocations.push(data);
                                    setLocations(currentLocations);
                                }}
                                columnHeader="Selected Locations"
                                listPosition="right"
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="modal-footer">
                <Button
                    color="secondary"
                    onClick={() => setSelectedProjectId(null)}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave()}
                    type="button"
                >
                    Save Changes
                </Button>
            </div>
        </Modal>
    );
};