import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    Row, Col, DropdownToggle, DropdownMenu, DropdownItem, ButtonGroup, Button, ButtonDropdown
} from "reactstrap";
import { formatDate, PNM_StatusApproved, PNM_StatusPending, PNM_StatusRejected } from "../../../utils";
import { TooltipButton } from '../../inputs';
import fileDownload from "js-file-download";
import api from "../../../services/api";
import { MissingJobDescriptionAlert } from "../../alerts";
import { roles } from '../../../auth/roles';
import { useMsal } from '@azure/msal-react';
import { AgGridReact } from 'ag-grid-react';
import Loader from '../../loaders';

function LabourRequestDetailListV2(props) {
    const {
        handleAddClick,
        handleEditClick,
        handleCopyClick,
        handleDeleteClick,
        status,
        request,
        details,
        isApproval,
    } = props;

    const [alert, setAlert] = useState([]);
    const [rowData, setRowData] = useState([]);
    const gridRef = useRef();
    const { instance } = useMsal();
    const user = instance.getActiveAccount();
    const isUserAdmin = user?.idTokenClaims.roles?.filter(r => r === roles.Admin).length > 0;
    const [selectedDetailActionId, setSelectedDetailActionId] = useState(null);
    const loadingOverlayComponent = useMemo(() => Loader, []);

    useEffect(() => {
        setRowData(details ? details : []);
    }, [details]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        editable: false,
        filter: false,
        suppressMenu: true,
    }), []);

    const columnDefs = useMemo(() => [
        {
            field: "hrispositionMasterName",
            headerName: "Roster Position",
            width: 200,
            cellClass: (params) => {
                const {data: cellData} = params;
                return cellData.hrispositionMaster || !request?.rosterId ? null : "bg-lrf-blank";
            },
            cellRenderer: (props) => {
                const {data: {hrispositionMaster}} = props;
                return hrispositionMaster?.hrispositionMasterName ?? "N/A";
            },
        },
        {
            field: "positionName",
            headerName: "Employed Position",
            width: 200,
            cellClass: (params) => {
                const {data: cellData} = params;
                return cellData.position && cellData.positionHasRates ? null : "bg-lrf-blank";
            },
            cellRenderer: (props) => {
                const {data: {position}} = props;
                return position?.lookupValue ?? "N/A";
            },
        },
        {
            field: "employmentCategory",
            headerName: "Employment Category",
            width: 200,
            cellClass: (params) => {
                const {data: cellData} = params;
                return cellData.employmentCategory ? null : "bg-lrf-blank";
            },
            cellRenderer: (props) => {
                const {data: {employmentCategory}} = props;
                return employmentCategory?.lookupValue ?? "N/A";
            },
        },
        {
            field: "quantityRequired",
            headerName: "Quantity",
            width: 100,
            cellClass: "text-right",
        },
        {
            field: "startDateExpected",
            headerName: "Start",
            width: 100,
            cellClass: (params) => {
                const {data: cellData} = params;
                return cellData.startDateExpected ? null : "bg-lrf-blank";
            },
            cellRenderer: (props) => {
                const {data: {startDateExpected}} = props;
                return startDateExpected ? formatDate(startDateExpected) : "N/A";
            },
        },
        {
            field: "finishDatePlanned",
            headerName: "End",
            width: 100,
            cellClass: "text-center",
            valueFormatter: ({value}) => {
                return value ? formatDate(value) : "N/A";
            },
        },
        {
            field: "company",
            headerName: "Desired Entity",
            width: 250,
            cellClass: (params) => {
                const {data: cellData} = params;
                return cellData.company ? null : "bg-lrf-blank";
            },
            cellRenderer: (props) => {
                const {data: {company}} = props;
                return company?.lookupValue ?? "N/A";
            },
        },
        {
            field: "workorder",
            headerName: "Payroll Default Cost Code",
            width: 200,
            cellClass: (params) => {
                const {data: cellData} = params;
                return cellData.workorder ? null : "bg-lrf-blank";
            },
            cellRenderer: (props) => {
                const {data: {workorder}} = props;

                return workorder?.lookupValue ?? "N/A";
            },
        },
        {
            field: "employmentType",
            headerName: "Employment Type",
            width: 170,
            cellClass: (params) => {
                const {data: cellData} = params;
                return cellData.employmentType ? null : "bg-lrf-blank";
            },
            cellRenderer: (props) => {
                const {data: {employmentType}} = props;
                return employmentType?.lookupValue ?? "N/A";
            },
        },
        {
            field: "reportingToName",
            headerName: "Reporting To",
            width: 200,
            cellClass: (params) => {
                const {data: cellData} = params;
                return cellData.reportingToName ? null : "bg-lrf-blank";
            },
            cellRenderer: (props) => {
                const {data: {reportingToName}} = props;
                return reportingToName ?? "N/A";
            },
        },
        {
            field: "button",
            headerName: "Action",
            pinned: "right",
            cellClass: "actions-button-cell",
            suppressSorting: true,
            cellRenderer: (props) => {
                const {data} = props;
                // console.log({isOpen: selectedDetailActionId === data.requestDetailId, selectedDetailActionId})

                return (
                    <div key={data.requestDetailId} className="text-center">
                        <ButtonGroup className="w-100">
                            <Button
                                color="default"
                                onClick={(event) => handleEditClick(event, data)}
                                size="xss"
                            >
                                <span className="font-size-xs-custom">
                                    <i className={`fas ${status && ((!isUserAdmin && status.lookupValue === PNM_StatusPending) || status.lookupValue === PNM_StatusApproved || status.lookupValue === PNM_StatusRejected || status.lookupValue === "Approved" || status.lookupValue === "In Progress" || status.lookupValue === "Void" || status.lookupValue === "Completed" || status.lookupValue.includes("Pending")) ? "fa-folder-open" : "fa-pencil-alt"} mr-2`} />
                                {
                                    status && ((!isUserAdmin && status.lookupValue === PNM_StatusPending) || status.lookupValue === PNM_StatusApproved || status.lookupValue === PNM_StatusRejected || status.lookupValue === "Approved" || status.lookupValue === "In Progress" || status.lookupValue === "Void" || status.lookupValue === "Completed" || status.lookupValue.includes("Pending")) ? "Open" : "Edit"
                                }
                                </span>
                            </Button>
                            <ButtonDropdown
                                isOpen={selectedDetailActionId === data.requestDetailId}
                                toggle={() => setSelectedDetailActionId(selectedDetailActionId === data.requestDetailId ? null : data.requestDetailId)}
                                color="default"
                                size="xss"
                            >
                                <DropdownToggle caret color="default" />
                                <DropdownMenu className="p-0 grid-dropdown-menu">
                                    <DropdownItem
                                        onClick={() => { downloadAttachment({ ...data }) }}
                                        className="p-0 px-3 m-0"
                                        disabled={!data.positionHasDescriptions}
                                    >
                                        <span className="font-size-small-custom">
                                            <i className="fas fa-file-download mr-2" />Download Job Description
                                        </span>
                                    </DropdownItem>
                                {
                                    !isApproval &&
                                    <>
                                        <DropdownItem
                                            onClick={(event) => handleCopyClick(event, data)}
                                            className="p-0 px-3 m-0"
                                            disabled={status && ((!isUserAdmin && status.lookupValue === PNM_StatusPending) || status.lookupValue === PNM_StatusApproved || status.lookupValue === PNM_StatusRejected || status.lookupValue === "Approved" || status.lookupValue === "In Progress" || status.lookupValue === "Void" || status.lookupValue === "Completed")}
                                        >
                                            <span className="font-size-small-custom">
                                                <i className="fas fa-copy mr-2" />Duplicate Row
                                            </span>
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={(event) => handleDeleteClick(event, data)}
                                            className="p-0 px-3 m-0"
                                            disabled={status && ((!isUserAdmin && status.lookupValue === PNM_StatusPending) || status.lookupValue === PNM_StatusApproved || status.lookupValue === PNM_StatusRejected || status.lookupValue === "Approved" || status.lookupValue === "In Progress" || status.lookupValue === "Void" || status.lookupValue === "Completed")}
                                        >
                                            <span className="font-size-small-custom">
                                                <i className="fas fa-trash-alt mr-2" />Delete
                                            </span>
                                        </DropdownItem>
                                    </>
                                }
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    </div>
                );
            },
            width: 130,
            sortable: false,
            floatingFilter: false,
            filter: "",
        },
    ], [handleEditClick, selectedDetailActionId, request]);

    const downloadAttachment = (fileInfo) => {
        const url = `recruitmentrequest/jobdescription/${fileInfo.positionId}`;
        api.get(url, { responseType: 'blob' })
            .then((blob, oth) => {
                const filename = RegExp(/(?<=filename=)\*?['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?(?=;)/).exec(blob.headers["content-disposition"])[0];
                fileDownload(blob.data, filename);
            }).catch((error) => {
                const info = error?.response?.data;

                if (info) {
                    info.text().then(text => {
                        const position = JSON.parse(text);
                        setAlert([<MissingJobDescriptionAlert
                            onConfirm={() => setAlert([])}
                            message={`Job Description not set for Position: ${position.positionName}`}
                        />]);
                    })

                } else {
                    console.error(error);
                }
            })
        ;
    }

    return (
        <>
            {alert.length > 0 && alert}

            <div className="px-0">
                <Row>
                    <Col xs={12} className="pt-0 pb-3">
                    {
                        !isApproval &&
                        <TooltipButton
                            id="addnew"
                            title="Add New"
                            className="btn-icon btn-xsm"
                            color="default"
                            size="md"
                            type="button"
                            onClick={handleAddClick}
                            disabled={status && ((!isUserAdmin && status.lookupValue === PNM_StatusPending) || status.lookupValue === PNM_StatusApproved || status.lookupValue === PNM_StatusRejected || status.lookupValue === "Approved" || status.lookupValue === "In Progress" || status.lookupValue === "Void" || status.lookupValue === "Completed")}
                        >
                            <i className="fas fa-plus pt-1"></i>
                        </TooltipButton>
                    }
                    </Col>
                    <Col xs={12}>
                        <div className="ag-theme-alpine mt-2 w-100">
                            <AgGridReact
                                ref={gridRef}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                suppressColumnVirtualisation={false}
                                suppressRowVirtualisation={false}
                                rowBuffer={20}
                                debounceVerticalScrollbar={false}
                                suppressContextMenu={true}
                                suppressClipboardPaste={true}
                                headerHeight={30}
                                floatingFiltersHeight={30}
                                rowHeight={40}
                                readOnlyEdit={true}
                                loadingOverlayComponent={loadingOverlayComponent}
                                multiSortKey='ctrl'
                                enableCellChangeFlash={true}
                                animateRows={true}
                                rowSelection={"single"}
                                domLayout="autoHeight"
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default LabourRequestDetailListV2;
