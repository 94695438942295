import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Button, Col, Input, Modal, Row } from 'reactstrap';
import api from "../../../../services/api";
import Loader from "../../../loaders";
import qs from "qs";
import { Container } from 'reactstrap';
import Grid from '../../../Grid';
import { useTable, useRowSelect, useFlexLayout, useExpanded } from 'react-table';
import { IndeterminateCheckbox } from '../../../react-table/controls';
import moment from "moment";

export default function LogisticSummaryConfirmEditor(props) {
    const {
        title,
        candidateTitle,
        rosterCandidates,
        mobeFlights,
        demobeFlights,
        accommodations,
        customCallback,
        onClose
    } = props;
    
    const [ loading, setLoading ] = React.useState(false);
    const [ flightData, setFlightData ] = useState([]);
    const [ firstLoad, setFirstLoad ] = useState(true);

    useEffect(() => {
        // console.log({mobeFlights, demobeFlights});
        const newFlightData = [];
        setFlightData(newFlightData.concat(mobeFlights, demobeFlights));
    }, [mobeFlights, demobeFlights]);

    const flightColumns = useMemo(() => {
        return [
            {
                Header: "Flight Number",
                accessor: "flightNumber",
                disableSortBy: false,
            },
            {
                Header: "From",
                accessor: (row) => {
                    var { flightFrom, airportTerminal } = row;
                    var output = "";
                    if (flightFrom.airportName) output = `${flightFrom.airportName}`
                    if (airportTerminal && airportTerminal.terminalName) output += ` (Terminal ${airportTerminal.terminalName})`;
                    return output;
                },
                disableSortBy: false,
            },
            {
                Header: "To",
                accessor: (row) => {
                    var { flightTo, arrivalTerminal } = row;
                    var output = "";
                    if (flightTo.airportName) output = `${flightTo.airportName}`
                    if (arrivalTerminal && arrivalTerminal.terminalName) output += ` (Terminal ${arrivalTerminal.terminalName})`;
                    return output;
                },
                disableSortBy: false,
            },
            {
                Header: "Flight Date",
                accessor: (row) => moment(row.flightDateTime, "YYYY-MM-DD").format("DD-MM-YYYY"),
                disableSortBy: false,
            },
            {
                Header: "Flight Time",
                accessor: (row) => moment(row.flightDateTime, "YYYY-MM-DDTHH:mm:ss").format("HH:mm"),
                disableSortBy: false,
            },
            {
                Header: "Arrival Date",
                accessor: (row) => moment(row.arrivalTime, "YYYY-MM-DD").format("DD-MM-YYYY"),
                disableSortBy: false,
            },
            {
                Header: "Arrival Time",
                accessor: (row) => moment(row.arrivalTime, "YYYY-MM-DDTHH:mm:ss").format("HH:mm"),
                disableSortBy: false,
            },
        ]
    }, [firstLoad]);

    // const {
    //     getTableProps,
    //     getTableBodyProps,
    //     headerGroups,
    //     rows,
    //     prepareRow,
    //     state,
    //     selectedFlatRows,  
    // } =
    const flightTable =
    useTable(
        {
            columns: flightColumns,
            data: flightData,
        },
        useFlexLayout,
        useExpanded
    );

    const accommodationColumns = useMemo(() => {
        return [
            {
                Header: "Start Date",
                accessor: (row) => moment(row.startDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
                disableSortBy: false,
            },
            {
                Header: "End Date",
                accessor: (row) => moment(row.endDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
                disableSortBy: false,
            },
            {
                Header: "Name",
                accessor: "accommodation.accommodationName",
                disableSortBy: false,
            },
            {
                Header: "Address",
                accessor: "accommodation.accommodationAddress",
                disableSortBy: false,
            },
            {
                Header: "Phone Number",
                accessor: "phoneNumber",
                disableSortBy: false,
            },
        ]
    }, [firstLoad]);
    
    const accommodationTable =
    useTable(
        {
            columns: accommodationColumns,
            data: accommodations,
        },
        useFlexLayout,
        useExpanded
    );

    return (
        <>
            {(loading) && <Loader />}
            <div
                className="modal-header my-0"
            >
                <Container fluid>
                    <Row>
                        <Col lg={9}>
                            <h5
                                className="modal-title"
                            >
                                Accommodation Confirmation{title}
                            </h5>
                        </Col>
                        <Col lg={3}>
                            <button
                                aria-label="Close"
                                className="close"
                                onClick={onClose}
                                type="button"
                            >
                                <span aria-hidden={true}>
                                    <i className="fas fa-times-circle text-black" />
                                </span>
                            </button>
                        </Col>
                    </Row>
                    {
                        candidateTitle && candidateTitle != "" && candidateTitle.trim() != 'null' ?
                    <Row>
                        <Col lg={12}>
                            <h6
                                className="modal-title"
                            >
                                {candidateTitle}
                            </h6>
                        </Col>
                    </Row>
                    :
                    <></>
                }
                </Container>
            </div>
            <div
                className="modal-body"
            >
                <Grid
                    height='calc(20vh)'
                    rows={flightTable.rows}
                    tableProps={flightTable.getTableProps()}
                    headerGroups={flightTable.headerGroups}
                    tableBodyProps={flightTable.getTableBodyProps()}
                    prepareRow={flightTable.prepareRow}
                />
                <Grid
                    height='calc(20vh)'
                    rows={accommodationTable.rows}
                    tableProps={accommodationTable.getTableProps()}
                    headerGroups={accommodationTable.headerGroups}
                    tableBodyProps={accommodationTable.getTableBodyProps()}
                    prepareRow={accommodationTable.prepareRow}
                />
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="default"
                    onClick={() => {
                        customCallback?.onCancel &&
                        customCallback.onCancel();
                    }}
                    type="button"
                >
                    {customCallback?.cancelButtonTitle ?? "Cancel"}
                </Button>
                <Button
                    color="secondary"
                    onClick={() => {
                        customCallback?.onReturn &&
                        customCallback.onReturn();
                    }}
                    type="button"
                >
                    {customCallback?.returnButtonTitle ?? "Back"}
                </Button>
                <Button
                    color="info"
                    onClick={() => {
                        customCallback?.onContinue &&
                        customCallback.onContinue();
                    }}
                    type="button"
                    disabled={!mobeFlights?.length && !demobeFlights?.length && !accommodations?.length}
                >
                    {customCallback?.continueButtonTitle ?? "Next"}
                </Button>
            </div>
        </>
    )
};