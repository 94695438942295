import api from "../../../../services/api";
import _, {difference} from "lodash";
import moment from "moment";
import util from "./util"
import { formatDate } from "../../../../utils";
import qs from "query-string";
import { ScheduleTypeCodes } from "./enums";

class MissingFieldDefinitionError extends Error  {
    constructor(message) {
        super(message);
        this.name = "MissingFieldDefinitionError";
    }
}
class CellEditManager {
    constructor () {
        this._fields = [];
    }
    addField(field) {
        this._fields = [ ...this._fields, field ];
    }
    setField(name) {
        const field = this._fields.find(field => field._name === name);
        if (!field) {
            throw new MissingFieldDefinitionError(`Missing strategy for field '${name}'.`)
        }
        return field;
    }
}
class CellEdit {
    constructor (name, handler) {
        this._name = name;
        this._handler = handler;
    }
    update(options, date, filters) {
        this._handler({ ...options.data }, {...options, ...filters}, date).then((newData) => {
            if (!newData)
                return;

            const tx = {
                update: Array.isArray(newData) ? newData : [newData],
            };

            console.log({newData, tx, options});
            options.api.applyTransactionAsync(tx, (result) => {
                // options.api.redrawRows({rowNodes: result.update})
                // console.log({update: result.update});
                options.api.refreshCells({force: true, rowNodes: result.update});
            });
        });
    }
    get(options, date, filters, callback) {
        this._handler({ ...options.data }, {...options, ...filters, callback}, date);
    }
}
const CellEditHelper = (function() {

    const cellEditManager = new CellEditManager();

    const forCandidate = new CellEdit("candidateName", (data, { newValue, api: gridApi, startDate, endDate, trimStartDate, trimEndDate }) => {
        gridApi.showLoadingOverlay();

        const queryString = qs.stringify({
            startDate: trimStartDate ? undefined : formatDate(startDate, 'YYYY-MM-DD'),
            endDate: trimEndDate ? undefined : formatDate(endDate, 'YYYY-MM-DD'),
        }, { allowDots: true });
        console.log('queryString: ', queryString)
        return api.post(`/rostergrid/rostercandidate/${data.rosterCandidateId}/candidate/${newValue ? newValue.candidateId : 0}?${queryString}`)
        .then((response) => {
            console.log('updated roster candidate: ', response.data)
            return response.data;
            // if (newValue) {
            //     data.candidateId = newValue.candidateId;
            //     data.candidateName = newValue.candidateName;
            //     data.info = "Info"; 
            // } else {
            //     data.candidateId = null;
            //     data.candidateName = null;
            //     data.info = null;
            // }
            // return data;
        })
        .catch((error) => {
            // TODO: Display Error message in UI
            console.log(error.response)
        })
        .finally(() => gridApi.hideOverlay());
    });
    cellEditManager.addField(forCandidate);

    const forDates = new CellEdit("dates", (data, { newValue, api: gridApi, setErrorMessage, setErrorTitle, errorMessageOpening, errorTitleOnGenerate, setTrainingData, setShowDeleteConfirmation }, date) => {
        // console.log('forDates', {newValue, data, date});
        const payload = util.getPayloadForRosterScheduleUpdate(newValue, data, date);

        const createTraining = payload.create.filter(c => c.rosterScheduleTypeCode === ScheduleTypeCodes.Training)[0];

        if (createTraining)
            payload.create.splice(payload.create.indexOf(createTraining), 1);
        
        const removeTraining = payload.remove.filter(c => c.rosterScheduleTypeCode === ScheduleTypeCodes.Training)[0];
        // console.log('forDates payload', payload, {createTraining}, {removeTraining});
        const trainingExists = removeTraining ? true : false;

        const action = (callback) => {
            gridApi.showLoadingOverlay();
            const apiCalls = [
                api.post(`/rostergrid/rostercandidateschedule`, [payload]),
                api.post(`/rostergrid/trainings/remove`, [{rosterCandidateId: payload.rosterCandidateId, dates: [payload.date]}])
            ];
            
            Promise.all(apiCalls)
                .then(() => {
                    setShowDeleteConfirmation(null);
                    gridApi.hideOverlay();
                    if (callback)
                        callback();
                })
                .catch((error) => {
                    // TODO: Display Error message in UI
                    console.log(error.response);
                    gridApi.hideOverlay();
                });
        }

        if (trainingExists)
        {
            gridApi.showLoadingOverlay();
            const checkCandidates = [payload.candidateId];
            // console.log({checkCandidates, payload});
            api.post(`/candidatetraining/bytalents`, JSON.stringify(checkCandidates))
                .then((response) => {
                    const {data: trainingData} = response;
                    gridApi.hideOverlay();
                    setShowDeleteConfirmation({
                        message: "Talent trainings data will also be removed. Continue?",
                        details: trainingData.map(t => `${t.candidate.candidateName} - ${t.trainingDetails}`),
                        action,
                    });
                })
                .catch((error) => {
                    console.log({error});
                    gridApi.hideOverlay();
                });
            return Promise.resolve(false);
        }

        gridApi.showLoadingOverlay();
        return api.post(`/rostergrid/rostercandidateschedule`, [payload])
        .then((response) => {
            // console.log('forDates response1', {response, data});

            if (removeTraining && response.data.removed && response.data.removed.length > 0)
            {
                const data = [];

                gridApi.forEachNode((rowNode) => {
                    const nodeData = rowNode.data;

                    if (!nodeData)
                        return;

                    let nodeDataChanged = false;
                    
                    response.data.removed.forEach(r => {
                        if (nodeData.dates[r.column] && nodeData.dates[r.column].schedules && nodeData.dates[r.column].schedules[r.rosterScheduleTypeCode] === r.rosterCandidateScheduleId)
                        {
                            // console.log("before change: ", JSON.parse(JSON.stringify(nodeData)));
                            delete nodeData.dates[r.column].schedules[r.rosterScheduleTypeCode];

                            if (!Object.keys(nodeData.dates[r.column].schedules).length)
                                delete nodeData.dates[r.column];
                            else {
                                nodeData.dates[r.column].className = nodeData.dates[r.column].className.replace(r.rosterScheduleTypeCode, "").trim();
                                nodeData.dates[r.column].value = nodeData.dates[r.column].value.replace(r.rosterScheduleTypeCode, "");
                            }

                            if (!nodeDataChanged)
                                nodeDataChanged = true;
                        }
                    })

                    if (nodeDataChanged)
                    data.push({...nodeData});
                });

                gridApi.hideOverlay();
                return data;
            }

            if (!data.dates[date]) {
                data.dates[date] = { ...util.defaultDatesValue }
            }
            const newSchedule = { ...data.dates[date].schedules };

            if (response.data.created && response.data.created.length > 0) {
                response.data.created.forEach(info => {
                    newSchedule[info.rosterScheduleTypeCode] = info.rosterCandidateScheduleId;
                    data.dates[info.column].value += info.rosterScheduleTypeCode;
                    data.dates[info.column].className += ` ${info.rosterScheduleTypeCode}`;
                })
            }
            if (response.data.removed && response.data.removed.length > 0) {
                response.data.removed.forEach(info => {
                    Object.keys(newSchedule).filter(key => key === info.rosterScheduleTypeCode && newSchedule[key] === info.rosterCandidateScheduleId).forEach(key => {
                        delete newSchedule[key];
                    });

                    data.dates[info.column].className = data.dates[info.column].className.replace(info.rosterScheduleTypeCode, "").trim();
                    data.dates[info.column].value = data.dates[info.column].value.replace(info.rosterScheduleTypeCode, "");
                })
            }
            // console.log('forDates response2', {response, newSchedule});

            data["dates"][date] = {
                value: data.dates[date]?.value ?? "",
                className: data.dates[date]?.className ?? "",
                schedules: {...newSchedule}
            }

            if (createTraining && data.candidateId)
            {
                const createDate = moment(createTraining.column, "YYYYMMDD").format("YYYY-MM-DD");
                setTrainingData({
                    startDate: createDate,
                    endDate: createDate,
                    candidateId: data.candidateId,
                    onSaved: (params) => {
                        console.log({params});
        
                        api.post("candidatetraining", JSON.stringify(params.data))
                            .then(() => {
                                gridApi.hideOverlay();
                                params.successCallback();
                            })
                            .catch((error) => {
                                console.error({error});
                                gridApi.hideOverlay();
                                params.errorCallback(error.response?.data);
                            })
                        ;
                    }
                });
            }
            else {
                gridApi.hideOverlay();
            }

            // console.log('forDates response3', {data});
            return data;
        })
        .catch((error) => {
            // TODO: Display Error message in UI
            console.log(error.response)
            setErrorMessage(`${errorMessageOpening}${error.message}`);
            setErrorTitle(errorTitleOnGenerate);
            gridApi.hideOverlay();
        })
        ;
    });
    cellEditManager.addField(forDates);

    const forShift = new CellEdit("shiftName", (data, { newValue, api: gridApi, startDate, endDate, trimStartDate, trimEndDate }) => {
        gridApi.showLoadingOverlay();

        const queryString = qs.stringify({
            startDate: trimStartDate ? null : formatDate(startDate, 'YYYY-MM-DD'),
            endDate: trimEndDate ? null : formatDate(endDate, 'YYYY-MM-DD'),
        }, { allowDots: true });
        return api.patch(`/rostergrid/rostercandidate/${data.rosterCandidateId}?${queryString}`, [
            {
                "op": "add",
                "path": "/shiftId",
                "value": newValue ? `${newValue.shiftId}` : null
            }
        ])
        .then((response) => {
            if (newValue) {
                data.shiftId = newValue.shiftId;
                data.shiftName = newValue.shiftName;
            } else {
                data.shiftId = null;
                data.shiftName = null;
            }
            return data;
        })
        .catch((error) => {
            // TODO: Display Error message in UI
            console.log(error.response)
        })
        .finally(() => gridApi.hideOverlay());
    })
    cellEditManager.addField(forShift);

    const forPosition = new CellEdit("hrispositionMasterName", (data, { newValue, api: gridApi, startDate, endDate, trimStartDate, trimEndDate }) => {
        gridApi.showLoadingOverlay();
        
        const queryString = qs.stringify({
            startDate: trimStartDate ? null : formatDate(startDate, 'YYYY-MM-DD'),
            endDate: trimEndDate ? null : formatDate(endDate, 'YYYY-MM-DD'),
        }, { allowDots: true });
        return api.patch(`/rostergrid/rostercandidate/${data.rosterCandidateId}?${queryString}`, [
            {
                "op": "add",
                "path": "/hrispositionMasterId",
                "value": newValue ? `${newValue.hrispositionMasterId}` : null
            }
        ])
        .then((response) => {
            if (newValue) {
                data.hrispositionMasterId = newValue.hrispositionMasterId;
                data.hrispositionMasterName = newValue.hrispositionMasterName;
            } else {
                data.hrispositionMasterId = null;
                data.hrispositionMasterName = null;
            }
            return data
        })
        .catch((error) => {
            // TODO: Display Error message in UI
            console.log(error.response)
        })
        .finally(() => gridApi.hideOverlay());
        
    })
    cellEditManager.addField(forPosition);

    const forSlot = new CellEdit("slot", (data, { newValue, api: gridApi, dispatchUnit, startDate, endDate, trimStartDate, trimEndDate }) => {

        const [ left, right ] = data.slot.toString().split('.');
        const [ newLeft, newRight ] = newValue.toString().split('.');

        console.log('forSlot', { data, newValue, gridApi, left, right, newLeft, newRight })
        //rostercandidateeditslot
        gridApi.showLoadingOverlay();
        return api.post(`/rostergrid/rostercandidateeditslot`, 
            {
                rosterCandidateId: data.rosterCandidateId,
                slot: newValue,
                dispatchUnitId: dispatchUnit.lookupId,
                startDate: trimStartDate ? null : formatDate(startDate, 'YYYY-MM-DD'),
                endDate: trimEndDate ? null : formatDate(endDate, 'YYYY-MM-DD'),
            }
        )
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            // TODO: Display Error message in UI
            console.log(error.response)
        })
        .finally(() => gridApi.hideOverlay());
    })
    cellEditManager.addField(forSlot);

    const forDeleteCandidate = new CellEdit("candidateName - check candidate change", (data, { api: gridApi, callback }) => {
        gridApi.showLoadingOverlay();

        const apiCalls = [
            api.get(`communication/candidatelog?rostercandidateid=${data.rosterCandidateId}&candidateid=${data.candidateId}`),
            api.get(`rosterattribute/roster/${data.rosterCandidateId}`),
        ];

        return Promise.all(apiCalls)
            .then((responses) => {
                const responseData = {};

                if (responses[0]?.data?.length)
                    responseData.messageLogs = responses[0].data;

                if (responses[1]?.data?.length)
                    responseData.rosterAttributes = responses[1].data;

                gridApi.hideOverlay();
                // console.log({responses, responseData});
                if (callback)
                    callback(responseData);
            })
            .catch((error) => {
                console.log({ error });
                gridApi.hideOverlay();
            })
        ;
    });
    cellEditManager.addField(forDeleteCandidate);

    const forSiteTravelType = new CellEdit("siteTravelTypeName", (data, { newValue, api: gridApi, startDate, endDate, trimStartDate, trimEndDate }) => {
        gridApi.showLoadingOverlay();
        // console.log("site travel type", {data, newValue});
        
        return api.patch(`/rostergrid/rostercandidate/${data.rosterCandidateId}`, [
            {
                "op": "add",
                "path": "/siteTravelTypeId",
                "value": newValue ? `${newValue.siteTravelTypeId}` : null
            }
        ])
        .then((response) => {
            if (newValue) {
                data.siteTravelTypeId = newValue.siteTravelTypeId;
                data.siteTravelTypeName = newValue.siteTravelTypeName;
            } else {
                data.siteTravelTypeId = null;
                data.siteTravelTypeName = null;
            }
            return data;
        })
        .catch((error) => {
            // TODO: Display Error message in UI
            console.error({error});
        })
        .finally(() => gridApi.hideOverlay());
    })
    cellEditManager.addField(forSiteTravelType);

    const forPointOfHire = new CellEdit("pointOfHireName", (data, { newValue, api: gridApi, startDate, endDate, trimStartDate, trimEndDate }) => {
        gridApi.showLoadingOverlay();
        // console.log("point of hire", {data, newValue});
        
        return api.patch(`/rostergrid/rostercandidate/${data.rosterCandidateId}`, [
            {
                "op": "add",
                "path": "/pointOfHireId",
                "value": newValue ? `${newValue.pointOfHireId}` : null
            }
        ])
        .then((response) => {
            if (newValue) {
                data.pointOfHireId = newValue.pointOfHireId;
                data.pointOfHireName = newValue.pointOfHireName;
            } else {
                data.pointOfHireId = null;
                data.pointOfHireName = null;
            }
            return data;
        })
        .catch((error) => {
            // TODO: Display Error message in UI
            console.error({error});
        })
        .finally(() => gridApi.hideOverlay());
    })
    cellEditManager.addField(forPointOfHire);

    return cellEditManager;
})();

export default CellEditHelper;