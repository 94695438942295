import React from 'react';
import api from "../../../../services/api";
import qs from "qs";
import {
   Container, Row, Col, CardTitle
} from "reactstrap";
import ContractTemplateEditor from "./Editor";
import TemplateHelp from "./Help";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert, DeleteDependenciesAlert } from "../../../alerts";
import { TooltipButton } from '../../../inputs';
import { DefaultColumnFilter } from "../../../react-table/filters";
import { useTable, useExpanded, useFilters, useSortBy, useFlexLayout } from 'react-table';
import Loader from "../../../loaders";
import Grid from "../../../Grid";

export default function List(props) {
    const [ data, setData ] = React.useState([]);
    const [ loading, setLoading ] = React.useState(false);
    const [ itemInEdit, setItemInEdit ] = React.useState(null);
    const [ templateModel, setTemplateModel ] = React.useState(null);
    const [ templateModalOpen, setTemplateModalOpen ] = React.useState(false);
    const [ filters, setFilters ] = React.useState([]);

    const defaultSort = React.useMemo(() => [{ id: "templateName", desc: false }],
        []
    );
    
    const [ sortBy, setSortBy ] = React.useState(defaultSort);

    const [ pageNumber, setPageNumber ] = React.useState(1);
    const [ pageSize, setPageSize ] = React.useState(10);
    const [ totalRecords, setTotalRecords ] = React.useState(0);
    const [ deleteAlert, setDeleteAlert ] = React.useState([]);
    const skipPageResetRef = React.useRef();
    
    const loadData = React.useCallback(() => {
        skipPageResetRef.current = true;
        const queryString = qs.stringify({filters, sortBy}, { allowDots: true });
        setItemInEdit(null);
        setData([]);
        
        setLoading(true);
        var withContent = 0;
        api.get(`/contracttemplate/${(pageNumber - 1) * pageSize}/${pageSize}/${withContent}${queryString ? `?${queryString}` : ""}`)
        .then(({data}) => {
            setData(data.data);
            setTotalRecords(data.total);
        }).catch((error) => {
            console.error("error: ", error);
        }).finally(() => setLoading(false));
    }, [filters, sortBy, pageNumber, pageSize]);
    
    const handleDelete = (item) => {
        const success = () => {
            setDeleteAlert([<DeleteSuccessAlert onConfirm={() => setDeleteAlert([])} />]);
            if (data.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1);
            } else {
                loadData();
            }
        };
        const failure = (error) => {
            if (typeof error?.response?.data?.validationCode === 'string' && error.response.data.validationCode.includes('errordepend')) {
                var { tables } = error?.response?.data;
                setDeleteAlert([<DeleteDependenciesAlert onConfirm={() => setDeleteAlert([])} data={tables} />]);
            } else {
                setDeleteAlert([<DeleteFailedAlert onConfirm={() => setDeleteAlert([])} />]);
            }
        };
        const events = {
            onConfirm: () => api.delete(`/contracttemplate/${item.contractTemplateId}`).then(success).catch(failure),
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert {...events} />])
    }

    React.useEffect(() => {        
         loadData();
    }, [pageNumber, pageSize, filters, sortBy]);

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
                })
            },
        }),
        []
    )

    const columns = React.useMemo(() => {
        return [
            {
                Header: "Name",
                id: "templateName",
                accessor: "templateName",
                Filter: DefaultColumnFilter,
                filter: "text",
                width: 500,
                disableSortBy: false,
            },
            {
                Header: "Actions",
                id: 'button',
                Cell: ( { row: { original } } ) =>
                    (
                        <div>
                            <TooltipButton
                                id={`edit_${original.contractTemplateId}`}
                                title="Edit"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={() => setItemInEdit(original)}
                            >
                                <i className="fas fa-pencil-alt pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`delete_${original.contractTemplateId}`}
                                title="Delete"
                                className="btn-icon"
                                color="warning"
                                size="sm"
                                type="button"
                                onClick={() => handleDelete(original)}
                            >
                                <i className="fas fa-trash-alt pt-1"></i>
                            </TooltipButton>
                        </div>
                    ),
                width: 140,
            },
        ]
    }, []);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,       
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: defaultSort,
                canSort: true,
            },
            filterTypes,
            defaultColumn,
            manualSortBy: true,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
        useExpanded
    );
    
    React.useEffect(() => {
        setFilters(state.filters);
    }, [state.filters]);
    
    React.useEffect(() => {
        setSortBy(state.sortBy);
    }, [state.sortBy]);

    return (
        <>
            {loading && <Loader />}
            {deleteAlert.length > 0 && deleteAlert}
            <Container fluid>
                <Row>
                    <Col>
                        <CardTitle>
                            <h4 className="text-center">Contract Templates</h4>
                        </CardTitle>
                        <TooltipButton
                            id="addnew"
                            title="Add New"
                            className="btn-icon ml-2 mb-2"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => {  
                                setItemInEdit({});
                            }}
                        >
                            <i className="fas fa-plus pt-1"></i>
                        </TooltipButton>
                        <TooltipButton
                            id="templatehelp"
                            title="Template Help"
                            className="btn-icon ml-2 mb-2"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => {
                                setTemplateModalOpen(true);
                            }}
                        >
                            <i className="fas fa-question pt-1"></i>
                        </TooltipButton>
                        <Grid
                            height='calc(100vh - 460px)'
                            totalRecords={totalRecords}
                            pageSize={pageSize}
                            rows={rows}
                            tableProps={getTableProps()}
                            headerGroups={headerGroups}
                            tableBodyProps={getTableBodyProps()}
                            prepareRow={prepareRow}
                            onPagerChangePage={(pager) => {
                                setPageNumber(pager.currentPage);
                            }}
                            onPagerChangePageSize={(size) => {
                                setPageSize(size);
                            }}
                            needPaging={true}
                        />
                        {
                            !!itemInEdit && 
                            <ContractTemplateEditor 
                                item={itemInEdit} 
                                onClose={() => setItemInEdit(null)} 
                                onSaved={() => loadData()}
                            />
                        }
                        {
                            <TemplateHelp 
                                open={templateModalOpen} 
                                onClose={() => setTemplateModalOpen(false)} 
                            />
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )
}