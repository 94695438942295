import moment from "moment";
import React from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import InputMask from "react-input-mask";
// reactstrap components
import {
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Input
} from "reactstrap";

// Core Components

function DateTimePicker(props) {
    const [focus, setFocus] = React.useState("");
    const [error, setError] = React.useState("");
    const { onChange, ...rest } = props;

    return (
      <>
        <FormGroup className={`${focus} ${error}`}>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-calendar-grid-58"></i>
              </InputGroupText>
            </InputGroupAddon>
            <ReactDatetime
              {...rest}
              input={true}
              renderInput={(props, openCalendar) => <Input {...props} onFocus={openCalendar} />}
              inputProps={{
                placeholder: "Date & Time",
                onFocus: () => setFocus("focused"),
                onBlur: () => setFocus(""),
              }}
              onChange={(val) => {
                if(!onChange)
                  return;
                
                onChange(val, rest.name)
                setError(val === null || (moment.isMoment(val) && val.isValid()) ? "" : "has-error")
              }}
            />
          </InputGroup>
        </FormGroup>
      </>
    );
  }

function DatePicker(props) {
  const [focus, setFocus] = React.useState("");
  const [error, setError] = React.useState("");
  const { onChange, mask, ...rest } = props;

  return (
    <>
      <FormGroup className={`${focus} ${error} ${props.className}`}>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-calendar-grid-58"></i>
            </InputGroupText>
          </InputGroupAddon>
          <ReactDatetime
            {...rest}
            input={true}
            renderInput={(props, openCalendar) => <InputMask  mask={mask ? mask : "99/99/9999"} {...props} onFocus={openCalendar} />}
            onChange={(val) => {
                if (val === "__/__/____") {
                    val = null;
                }
                
                onChange(val, rest.name)

                setError(val === null || (moment.isMoment(val) && val.isValid()) ? "" : "has-error")
                
            }}
            inputProps={{
              placeholder: "",
              onFocus: () => setFocus("focused"),
              onBlur: () => setFocus(""),
              disabled: props.disabled ?? false,
              value: rest.value
            }}
            timeFormat={false}
            dateFormat="DD/MM/YYYY"
          />
        </InputGroup>
      </FormGroup>
    </>
  );
}

export { 
    DatePicker,
    DateTimePicker
};
