import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import api from "../../../../services/api";
import TemplateDialog from "./TemplateDialog";
import { useBlockLayout, useFlexLayout, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import Grid from "../../../Grid";
import { Checkbox, RadioButton } from "@progress/kendo-react-inputs";
import { TooltipButton } from "../../../inputs";
import fileDownload from "js-file-download";

const templateRows = [
    {
        id: "documentType",
        title: "Document Type",
    },
    {
        id: "filename",
        title: "File Name",
        isCheckboxRow: true,
        clickable: true,
    },
    {
        id: "uploadDate",
        title: "Uploaded At",
        isEndRow: true,
    },
];

function DocumentDialog(props) {
    const {
        title,
        selectedCandidates,
        selectedCandidateId,
        initialSelectedDocuments,
        onClose,
        onBackClick,
        onNextClick,
        direction,
    } = props;

    const [ loading, setLoading ] = useState(false);
    const [ sourceData, setSourceData ] = useState([]);
    const [ data, setData ] = useState([]);
    const [ selectedDocuments, setSelectedDocuments ] = useState(initialSelectedDocuments ?? []);
    const skipPageResetRef = useRef();

    const loadData = useCallback(() => {
        skipPageResetRef.current = true;

        if (!selectedCandidates?.length)
            return;

        setLoading(true);

        api.post(`candidatemerge/compare/documents`, JSON.stringify(selectedCandidates.map(c => c.candidateId)))
            .then((response) => {
                const {data: {data: responseData, maxRowCount}} = response;
                // console.log({responseData, maxRowCount});

                if (!responseData.length)
                {
                    setLoading(false);

                    if (direction === 1)
                        onNextClick([]);
                    else
                        onBackClick();

                    return;
                }

                const transposedData = [];

                for (let i = 0; i < maxRowCount; i++) {
                    templateRows.forEach(r => {
                        const row = {
                            rowCustomId: r.id,
                            title: r.title,
                            isCheckboxRow: r.isCheckboxRow,
                            isEndRow: r.isEndRow,
                            clickable: r.clickable,
                        };

                        responseData.forEach(d => {
                            if (responseData.filter(r => r.candidateId === d.candidateId).length - 1 < i)
                                return;
                            
                            const currentResponseData = responseData.filter(r => r.candidateId === d.candidateId)[i];
                            row[d.candidateId] = {
                                candidateDocumentId: currentResponseData.candidateDocumentId,
                                candidateId: currentResponseData.candidateId,
                                label: currentResponseData[r.id] ?? "N/A",
                            };
                        });

                        transposedData.push(row);
                    });
                }
                // console.log({transposedData});
                setData([...transposedData]);
                setSourceData([...transposedData]);
                setLoading(false);
            })
            .catch((error) => {
                console.log({error});
                setLoading(false);
            })
        ;
    }, [skipPageResetRef, api, selectedCandidates, selectedCandidateId, onNextClick, onBackClick, direction]);

    useEffect(() => loadData(), [selectedCandidates]);

    const defaultColumn = useMemo(() => ({
        disableFilters: true,
        disableSortBy: true,
    }), []);

    const columns = useMemo(() => {
        selectedCandidates.sort((a, b) => a.candidateId - selectedCandidateId !== 0 ? Math.abs(a.candidateId - b.candidateId) : -1);

        return [
            {
                Header: "",
                accessor: "title",
                sticky: "left",
                width: 100,
                maxWidth: 100,
            },
            ...selectedCandidates.map(d => ({
                Header: () => {
                    return (
                        <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                        {
                            selectedCandidateId === d.candidateId &&
                            <RadioButton
                                name="selectCandidate"
                                value={d.candidateId}
                                disabled={true}
                                checked={d.candidateId === selectedCandidateId}
                            />
                        }
                            <span className={`text-left${selectedCandidateId === d.candidateId ? " ml-2" : ""}`}>{d.candidateName} ({d.candidateId})</span>
                        </div>
                    );
                },
                id: `${d.candidateId}`,
                columns: [
                    {
                        Header: "",
                        id: `data_${d.candidateId}`,
                        Cell: ({ row: { original } }) => {
                            if (!original[d.candidateId])
                                return (
                                    <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                                        <span className="text-left">-</span>
                                    </div>
                                );
        
                            return (
                                <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-center">
                                    <span className="text-left text-break">{original[d.candidateId].label ?? "N/A"}</span>
                                {
                                    original.rowCustomId === "filename" &&
                                    <TooltipButton
                                        id={`download_${original.candidateDocumentId}`}
                                        title="Download"
                                        className="btn-icon ml-2"
                                        color="default"
                                        size="xsm"
                                        type="button"
                                        onClick={() => {
                                            setLoading(true);
                                            const requestUrl = `candidate/download-document/${original.candidateDocumentId}`;
                                            api.get(requestUrl, { responseType: 'blob' })
                                                .then(({data}) => {
                                                    fileDownload(data, original[d.candidateId].label);
                                                    setLoading(false);
                                                }).catch(error => {
                                                    console.error(error);
                                                    setLoading(false);
                                                })
                                            ;
                                        }}
                                    >
                                        <i className="fas fa-file-download pt-1"></i>
                                    </TooltipButton>
                                }
                                </div>
                            );
                        },
                        minWidth: 150,
                    },
                    {
                        Header: () => {
                            if (!sourceData.filter(sd => sd[d.candidateId]).length)
                                return null;
                            
                            const currentCandidateDocuments = sourceData.filter(sd => sd[d.candidateId]?.candidateId === d.candidateId).map(sd => sd[d.candidateId].candidateDocumentId);
                            const selected = currentCandidateDocuments.length && currentCandidateDocuments.every(sd => selectedDocuments.includes(sd)) ? true : false;

                            return (
                                <div className="w-100 px-1 d-flex flex-row justify-content-center align-items-center">
                                    <Checkbox
                                        onChange={(e) => {
                                            const {value} = e;

                                            if (!value)
                                            {
                                                setSelectedDocuments([...selectedDocuments.filter(sd => !currentCandidateDocuments.includes(sd))]);
                                                return;
                                            }

                                            setSelectedDocuments([...selectedDocuments.filter(sd => !currentCandidateDocuments.includes(sd)), ...currentCandidateDocuments]);
                                        }}
                                        checked={selected}
                                    />
                                    <span className="text-left ml-2">Select All</span>
                                </div>
                            );
                        },
                        id: `check_${d.candidateId}`,
                        Cell: ({ row: { original } }) => {
                            if (!original.isCheckboxRow || !original[d.candidateId])
                                return null;
        
                            return (
                                <div className="w-100 px-1 d-flex flex-row justify-content-center align-items-center">
                                    <Checkbox
                                        onChange={(e) => {
                                            const {value} = e;

                                            if (!value)
                                            {
                                                setSelectedDocuments([...selectedDocuments.filter(sd => sd !== original[d.candidateId].candidateDocumentId)]);
                                                return;
                                            }

                                            setSelectedDocuments([...selectedDocuments, original[d.candidateId].candidateDocumentId]);
                                        }}
                                        checked={selectedDocuments.includes(original[d.candidateId].candidateDocumentId)}
                                    />
                                </div>
                            );
                        },
                        maxWidth: 50,
                        width: 50,
                    },
                ],
                minWidth: 200,
                sticky: d.candidateId === selectedCandidateId ? "left" : null,
            }))
        ]
    }, [selectedCandidates, selectedCandidateId, sourceData, selectedDocuments]);

    // useEffect(() => console.log({selectedDocuments}), [selectedDocuments]);
    // useEffect(() => console.log({data}), [data]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout,
        useBlockLayout,
        useSticky,
    );

    // useEffect(() => console.log({rows}), [rows]);

    const content = useMemo(() => {
        return (
            <>
                <Row className="m-0 p-0 w-100">
                    <Col lg={12} className="m-0 p-0 d-flex flex-wrap justify-content-start align-items-center">
                        <span className="font-size-small-custom">
                            <i className="fas fa-info-circle mr-1" />Please select the Documents to be merged.
                        </span>
                    </Col>
                </Row>
                <Row className="m-0 p-0 mt-3 w-100">
                    <Grid
                        height={'calc(100vh - 464px)'}
                        totalRecords={rows.length}
                        pageSize={rows.length}
                        rows={rows}
                        tableProps={getTableProps()}
                        headerGroups={headerGroups.map(h => {
                            const headerGroupProps = h.getHeaderGroupProps();
                            headerGroupProps.style = {...headerGroupProps.style, width: "100%"}

                            return {
                                ...h,
                                getHeaderGroupProps: () => headerGroupProps
                            };
                        })}
                        tableBodyProps={getTableBodyProps()}
                        prepareRow={(row) => {
                            prepareRow(row);
                            const rowProps = row.getRowProps();
                            row.getRowProps = () => {
                                return {
                                    ...rowProps,
                                    style: {
                                        ...rowProps.style,
                                        width: "100%",
                                    },
                                };
                            };

                            row.cells = row.cells.map(c => {
                                const cellProps = c.getCellProps();
                                
                                return {
                                    ...c,
                                    getCellProps: () => ({
                                        ...cellProps,
                                        style: {
                                            ...cellProps.style,
                                            backgroundColor: c.column.id.includes(`${selectedCandidateId}`) ? "#c2deff" : c.column.id === "title" ? "#fff" : "inherit",
                                            borderBottomWidth: row.original.isEndRow ? "0.15rem" : "inherit",
                                            borderBottomColor: row.original.isEndRow ? "#bbb" : "inherit",
                                            borderBottomStyle: row.original.isEndRow ? "solid" : "inherit",
                                        },
                                    })
                                };
                            });
                        }}
                        fullscreen={true}
                    />
                </Row>
            </>
        );
    }, [rows, getTableProps, headerGroups, getTableBodyProps, prepareRow, selectedCandidateId]);

    const footer = useMemo(() => (
        <>
            <Button
                color="secondary"
                onClick={onClose}
                type="button"
            >
                Close
            </Button>
            <Button
                color="info"
                onClick={() => onBackClick()}
                type="button"
            >
                <i className="fas fa-chevron-left mr-2" />Back: Data Review - Job Order Assignments
            </Button>
            <Button
                color="info"
                onClick={() => onNextClick(selectedDocuments)}
                type="button"
            >
                Next: Data Review - Trainings<i className="fas fa-chevron-right ml-2" />
            </Button>
        </>
    ), [onClose, onBackClick, onNextClick, selectedDocuments]);

    return (
        <TemplateDialog
            title={title}
            loading={loading}
            content={content}
            footer={footer}
            onClose={onClose}
        />
    );
};

export default DocumentDialog;
