import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Input, Label, Modal, Row } from 'reactstrap';
import { formatDate } from '../../../../utils';
import { DatePicker } from '../../../date-pickers';
import Loader from '../../../loaders';
import api from "../../../../services/api";
import moment from "moment";

export default function RosterAttributeEditor(props) {
    const {
        rosterCandidateId,
        project,
        candidate,
        onClose
    } = props;

    const [ loading, setLoading ] = React.useState(false);
    const [ data, setData ] = useState([]);

    const loadData = useCallback(() => {
        setLoading(true);

        api.get(`/rostercandidate/attributes/${rosterCandidateId}`)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => console.log({error}))
            .finally(() => setLoading(false))
        ;
    }, [rosterCandidateId]);

    useEffect(() => {
        if (!rosterCandidateId)
            return;
        
        loadData();
    }, [rosterCandidateId]);

    const handleDataChanged = useCallback((inputId, name, value, type, rosterAttribute) => {
        const newData = [...data];
        const id = parseInt(inputId);
        const selected = newData.filter(d => d.rosterAttributeId === id);
        const keyName = type === "string" ? "stringValue" : type === "number" ? "numberValue" : type === "date" ? "dateValue" : null;

        if (!selected.length)
        {
            if (keyName)
                newData.push({ rosterCandidateId, rosterAttribute, rosterAttributeId: id, [keyName]: value });
            
            setData(newData);
            return;
        }

        const newItem = { ...selected[0], [keyName]: value };
        newData.splice(newData.indexOf(selected[0]), 1, newItem);
        setData(newData);
    }, [rosterCandidateId, data]);

    const handleSave = useCallback(() => {
        setLoading(true);

        api.post(`/rostercandidate/attributes/${rosterCandidateId}`, JSON.stringify(data))
            .then(() => setLoading(false))
            .catch((error) => {
                console.log({error});
                setLoading(false);
            })
            .finally(() => onClose())
        ;
    }, [rosterCandidateId, data]);

    return (
        <Modal
            isOpen={true}
            className="modal-xl"
            modalClassName="db-example-modal-xl"
        >
        {
            (loading) && <Loader />
        }
            <div
                className="modal-header my-0"
            >
                <Container fluid>
                    <Row>
                        <Col lg={9}>
                            <h5
                                className="modal-title"
                            >
                                Roster Attributes - {project?.projectName} - {candidate?.candidateName}
                            </h5>
                        </Col>
                        <Col lg={3}>
                            <button
                                aria-label="Close"
                                className="close"
                                onClick={onClose}
                                type="button"
                            >
                                <span aria-hidden={true}>
                                    <i className="fas fa-times-circle text-black" />
                                </span>
                            </button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-body"
            >
                <Container>
                {
                    data.map((item, idx) => {
                        return (
                            <Row key={`attr_item_${idx}`}>
                                <Col xs={4}>
                                    <Label>
                                    {
                                        item.rosterAttribute.rosterAttributeName
                                    }
                                    </Label>
                                </Col>
                                <Col xs={8}>
                                {
                                    item.rosterAttribute.rosterAttributeType.lookupValue === "string" &&
                                    <Input
                                        className="form-group"
                                        name={item.rosterAttribute.rosterAttributeName}
                                        id={item.rosterAttribute.rosterAttributeId}
                                        type="text"
                                        value={item.stringValue ?? ""}
                                        onChange={(e) => handleDataChanged(e.target.id, e.target.name, e.target.value, "string", item.rosterAttribute)}
                                    />
                                }
                                {
                                    item.rosterAttribute.rosterAttributeType.lookupValue === "number" &&
                                    <Input
                                        className="form-group"
                                        name={item.rosterAttribute.rosterAttributeName}
                                        id={item.rosterAttribute.rosterAttributeId}
                                        type="number"
                                        value={item.numberValue ?? ""}
                                        onChange={(e) => handleDataChanged(e.target.id, e.target.name, e.target.value, "number", item.rosterAttribute)}
                                        placeholder="(number)"
                                    />
                                }
                                {
                                    item.rosterAttribute.rosterAttributeType.lookupValue === "date" &&
                                    <DatePicker
                                        name={item.rosterAttribute.rosterAttributeName}
                                        id={item.rosterAttribute.rosterAttributeId}
                                        type="text"
                                        required
                                        value={formatDate(item.dateValue)}
                                        onChange={value => {
                                            handleDataChanged(item.rosterAttribute.rosterAttributeId, item.rosterAttribute.rosterAttributeName, !value ? null : moment(value).format("YYYY-MM-DDT00:00:00"), "date", item.rosterAttribute);
                                        }}
                                        closeOnSelect
                                    />
                                }
                                </Col>
                            </Row>
                        );
                    })
                }
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={() => {
                        onClose();
                    }}
                    type="button"
                >
                    Close
                </Button>
                {/* <Button
                    color="default"
                    onClick={() => setShowCancelModal(true)}
                    type="button"
                    disabled={!selectedFlatRows || !selectedFlatRows.length}
                >
                    Cancel
                </Button> */}
                <Button
                    color="info"
                    onClick={() => {
                        handleSave();
                    }}
                    type="button"
                >
                    Save
                </Button>
            </div>
        </Modal>
    );
}