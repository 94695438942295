import React from 'react';
import api from "../../../services/api";
import qs from "qs";
import {
   Container, Row, Col, Card, CardBody, CardTitle
} from "reactstrap";
import AccommodationEditor from "./Editors/AccommodationEditor";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../alerts";
import { TooltipButton } from '../../inputs';
import Loader from "../../loaders";
import { DefaultColumnFilter } from "../../react-table/filters";
import Grid from "../../Grid";
import { useTable, useExpanded, useFilters, useSortBy, useFlexLayout } from 'react-table';

export default function List(props) {
    const [ loading, setLoading ] = React.useState(false);
    const [ data, setData ] = React.useState([]);
    const [ itemInEdit, setItemInEdit ] = React.useState(null);
    const [ filters, setFilters ] = React.useState([]);

    const defaultSort = React.useMemo(() => [{ id: "accommodationName", desc: false }],
        []
    );
    
    const [ sortBy, setSortBy ] = React.useState(defaultSort);
    const [ pageNum, setPageNum ] = React.useState(1);
    const [ pageSize, setPageSize ] = React.useState(10);
    const [ totalRecords, setTotalRecords ] = React.useState(0);
    const [ deleteAlert, setDeleteAlert ] = React.useState([]);
    const skipPageResetRef = React.useRef();
    const apiurl = "accommodation";

    const loadData = React.useCallback(() => {
        setLoading(true);
        skipPageResetRef.current = true;
        const queryString = qs.stringify({filters: filters}, { allowDots: true });
        setItemInEdit(null);
        setData([]);

        api.get(`/${apiurl}/${(pageNum - 1) * pageSize}/${pageSize}${queryString ? `?${queryString}` : ""}`)
        .then(({data}) => {
            setData(data.data ?? []);
            setTotalRecords(data.total);
        }).catch((error) => {
            console.error(error.response);
        }).finally(() => setLoading(false));
    }, [filters, pageNum, pageSize]);

    React.useEffect(() => {
        loadData();
    }, [filters, pageNum, pageSize]);
    
    const handleDelete = (item) => {
        const success = () => {
            setLoading(false);
            setDeleteAlert([<DeleteSuccessAlert onConfirm={() => setDeleteAlert([])} key={1} />]);
            loadData();
        };
        const failure = (error) => {
            setLoading(false);
            setDeleteAlert([<DeleteFailedAlert onConfirm={() => setDeleteAlert([])} key={1} message={error.response.status >= 500 ? undefined : error.response.data} />]);
        };
        const events = {
            onConfirm: () => {
                setLoading(true);
                api.delete(`/${apiurl}/${item.accommodationId}`).then(success).catch(failure);
            },
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert {...events} key={1} />])
    }

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
                })
            },
        }),
        []
    );

    const columns = React.useMemo(() => [
        {
            Header: "Accommodation Name",
            id: "accommodationName",
            accessor: "accommodationName",
            filter: "text",
            width: 200,
            disableSortBy: false,
        },
        {
            Header: "Accommodation Address",
            id: "accommodationAddress",
            accessor: "accommodationAddress",
            filter: "text",
            width: 400,
            disableSortBy: false,
        },
        {
            Header: "Actions",
            id: 'button',
            Cell: ( { row: { original } } ) =>
                (
                    <div>
                        <TooltipButton
                            id={`edit_${original.accommodationId}`}
                            title="Edit"
                            className="btn-icon"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => setItemInEdit(original)}
                        >
                            <i className="fas fa-pencil-alt pt-1"></i>
                        </TooltipButton>
                        <TooltipButton
                            id={`delete_${original.accommodationId}`}
                            title="Delete"
                            className="btn-icon"
                            color="warning"
                            size="sm"
                            type="button"
                            onClick={() => handleDelete(original)}
                        >
                            <i className="fas fa-trash-alt pt-1"></i>
                        </TooltipButton>
                    </div>
                ),
            width: 140,
        }]
    , [data]);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,       
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: defaultSort,
                canSort: true,
            },
            filterTypes,
            defaultColumn,
            manualSortBy: true,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
        useExpanded
    );
    
    React.useEffect(() => {
        setFilters(state.filters);
    }, [state.filters]);
    
    React.useEffect(() => {
        setSortBy(state.sortBy);
    }, [state.sortBy]);

    return (
        <section className="main">
            {loading && <Loader />}
            {deleteAlert.length > 0 && deleteAlert}
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card className="no-transition">
                            <CardBody>
                                <CardTitle>
                                    <h4 className="text-center">Accommodations</h4>                                    
                                </CardTitle>
                                <Container fluid className="px-0">
                                    <Row noGutters>
                                        <Col xs={12}>
                                            <TooltipButton
                                                id="addnewdu"
                                                title="Add Accommodation"
                                                className="btn-icon ml-2 mb-2"
                                                color="default"
                                                size="sm"
                                                type="button"
                                                disabled={false}
                                                onClick={() => setItemInEdit({})}
                                            >
                                                <i className="fas fa-plus pt-1"></i> Add Accommodation
                                            </TooltipButton>
                                        </Col>
                                        <Col xs={12}>
                                            <Grid
                                            height='calc(100vh - 476px)'
                                                totalRecords={totalRecords}
                                                pageSize={pageSize}
                                                rows={rows}
                                                tableProps={getTableProps()}
                                                headerGroups={headerGroups}
                                                tableBodyProps={getTableBodyProps()}
                                                prepareRow={prepareRow}
                                                onPagerChangePage={(pager) => {
                                                    setPageNum(pager.currentPage);
                                                }}
                                                onPagerChangePageSize={(size) => {
                                                    setPageSize(size);
                                                }}
                                                needPaging={true}
                                            />
                                        </Col>
                                        {
                                            itemInEdit ?
                                            <Col xs={12}>
                                                <AccommodationEditor
                                                    item={itemInEdit} 
                                                    onClose={() => setItemInEdit(null)}
                                                    onSaved={() => loadData()}
                                                />
                                            </Col>
                                            :
                                            null
                                        }
                                    </Row>
                                </Container>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}