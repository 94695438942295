/* eslint-disable eqeqeq */
/* eslint-disable no-loop-func */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import {
    Modal, Container, Row, Col, Button, Label
} from "reactstrap";
import api from "../../../../services/api";
import { useDropzone } from 'react-dropzone';
import cn from "classnames";
import Loader from "../../../loaders";
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community';
import readXlsxFile from 'read-excel-file';

// import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
// import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import _ from 'lodash';

import "../../../../assets/css/ag-grid.css";
import "../../../../assets/css/ag-theme-alpine.css";
import { TooltipButton } from '../../../inputs';
import moment from 'moment';

const UPLOAD_MODE = "upload";
const VALIDATION_MODE = "validation";

export default function MiniTable(props) {

    const {
        defaultColumns,
        dataRef,
        setData,
        defaultColDef,
        validationAlertRef,
        setValidationAlert,
        validatedRef,
        setValidated
    } = props;

    const data = useMemo(() => dataRef?.current, [dataRef?.current]);
    const validated = useMemo(() => validatedRef?.current, [validatedRef?.current]);

    const [loading, setLoading] = useState(false);
    const [importFiles, setImportFiles] = useState([]);
    const [alert, setAlert] = useState('');
    const [validationData, setValidationData] = useState([]);
    const [mode, setMode] = useState(UPLOAD_MODE);
    //const [validationAlert, setValidationAlert] = useState([]);
    const [openValidationAlert, setOpenValidationAlert] = useState(false);
    const [canSave, setCanSave] = useState(false);
    //const [validated, setValidated] = useState(false);
    const [duplicateDataValidationAlert, setDuplicateDataValidationAlert] = useState([]);

    const [columnDefsV, setColumnDefsV] = useState([
        {
            field: 'rowNo',
            headerName: 'Row No',
            width: 60,
        },
        {
            field: 'projectName',
            headerName: 'Project Name',
            width: 200,
        },
        {
            field: 'dispatchUnitName',
            headerName: 'Dispatch Unit Name',
            width: 200,
        },
        {
            field: 'candidateEmail',
            headerName: 'Candidate Email',
            width: 200,
        },
        {
            field: 'date',
            headerName: 'Date',
            width: 200,
        },
        {
            field: 'message',
            headerName: 'Message',
            flex: 2,
            //width: 200,
        },
    ]);


    const [columnDefs, setColumnDefs] = useState([...defaultColumns.map(c => ({
        ...c,
        field: c.name,
        headerName: c.title,
    }))]);

    const validationAlert = useMemo(() => validationAlertRef?.current, [validationAlertRef?.current]);
    const [rowBuffer, setRowBuffer] = useState(20);
    const gridRef = useRef();
    const gridRefV = useRef();

    const onDrop = useCallback(acceptedFiles => setImportFiles([...acceptedFiles]), [importFiles]);

    const handleUpload = useCallback(() => {
        setLoading(true);

        const formData = new FormData();

        const file = importFiles[0];
        console.log('acceptedFiles', importFiles);
        formData.append('files', file, file.name);

        api({ url: `roster/import/${props.rosterId}`, method: 'POST', data: formData })
            .then(response => {
                console.log('roster import', response);
                setAlert('success');
            })
            .catch((error) => {
                console.error(error);
                setAlert('error');
            })
            .finally(() => setLoading(false));

    }, [importFiles]);



    useEffect(() => {
        const rosterCandidateDates = [];
        const newValidationData = [];

        if (!data?.length) {
            setColumnDefs([...defaultColumns.map(c => ({
                ...c,
                field: c.name,
                headerName: c.title,
            }))]);
            setCanSave(false);
            setValidationAlert([...newValidationData]);
            setDuplicateDataValidationAlert([...newValidationData]);
            return;
        }

        let newCanSave = true;
        let i = 0;

        while (i < data.length) {
            if (!rosterCandidateDates.filter(rc => rc.rosterCandidateId === data[i].rosterCandidateId)?.length) {
                rosterCandidateDates.push({
                    rosterCandidateId: data[i].rosterCandidateId,
                    startDate: data[i].workStart,
                    endDate: data[i].workEnd,
                });
                i++;
                continue;
            }

            if (!rosterCandidateDates.filter(rc => rc.rosterCandidateId === data[i].rosterCandidateId && rc.startDate === data[i].workStart && rc.endDate === data[i].workEnd)?.length) {
                i++;
                continue;
            }

            if (newCanSave)
                newCanSave = false;

            if (!newValidationData.filter(d => d.id === data[i].rosterCandidateId)?.length)
                newValidationData.push({
                    id: data[i].rosterCandidateId,
                    messages: []
                });

            const validation = newValidationData.filter(d => d.id === data[i].rosterCandidateId)[0];

            if (!validation.messages?.length)
                validation.messages.push("Start Date/End Date for each row with the same ID should be different.");

            i++;
        }

        // console.log({newCanSave, rosterCandidateDates});

        setValidationAlert([...newValidationData]);
        setDuplicateDataValidationAlert([...newValidationData]);
        setCanSave(newCanSave);
    }, [data]);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const dropzoneClasses = useMemo(() => cn({
        'dropzone': true,
        'mt-2': true,
        'active': isDragActive,
        'accept': isDragAccept,
        'reject': isDragReject
    }), [
        isDragActive,
        isDragAccept,
        isDragReject
    ]);

    const loadingOverlayComponent = useMemo(() => Loader, []);
    const loadingOverlayComponentV = useMemo(() => Loader, []);


    const files = useMemo(() =>
        importFiles.map(
            file => (
                <li key={file.path}>
                    {file.path} - {file.size} bytes
                </li>
            )

        ), [importFiles]);

    const resizeColumns = useCallback(() => {
        if (!gridRef?.current?.columnApi || data?.length === 0)
            return;

        const allColumnIds = [];
        gridRef.current.columnApi.getColumns().forEach((column) => {
            // console.log(column, column.getId());
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }, [data, gridRef]);


    const resizeColumnsV = useCallback(() => {
        if (!gridRefV?.current?.columnApi || validationAlert?.length === 0)
            return;

        const allColumnIds = [];
        gridRefV.current.columnApi.getColumns().forEach((column) => {
            // console.log(column, column.getId());
            allColumnIds.push(column.getId());
        });
        gridRefV.current.columnApi.autoSizeColumns(allColumnIds, false);
    }, [validationAlert, gridRefV]);

    const getRowId = useMemo(() => (params) => params.data.id, []);

    const onCellEditRequest = useCallback((event) => {
        console.log("on cell edit request: ", { event });
    }, []);


    return (

        <Row className="w-100 mx-0 mt-3">
            {
                !!validated &&
                <Col xs={12} className={`d-flex flex-wrap justify-content-end align-items-center px-0 mb-${validated ? "2" : "1"}`}>
                    <TooltipButton
                        id="show-validation-result"
                        title={!!validationAlert?.length ? "Show Invalid Data" : "Validation Success"}
                        className="btn-icon"
                        color={!!validationAlert?.length ? "default" : "success"}
                        size="sm"
                        type="button"
                        onClick={() => setOpenValidationAlert(true)}
                    >
                        {!!validationAlert?.length && <><i className="fas fa-exclamation mr-1" /> Show Invalid Data</>}
                        {!validationAlert?.length && <><i className="fas fa-check mr-1" /> Validation Success</>}
                    </TooltipButton>
                </Col>
            }
            {
                data?.length > 0 && !validated &&
                <Col xs={12} className="d-flex flex-wrap justify-content-end align-items-center px-0 mb-2">
                    <span className="text-warning font-italic">Please do the validation process before upload the data.</span>
                </Col>
            }
            <Col xs={12} className="d-flex flex-wrap justify-content-center align-items-center px-0">
                <div className="ag-theme-alpine" style={{ width: '100%', height: data?.length ? (mode === UPLOAD_MODE ? "44vh" : "40vh") : 75 }}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={data}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        loadingOverlayComponent={loadingOverlayComponent}
                        suppressColumnVirtualisation={false}
                        suppressRowVirtualisation={false}
                        debounceVerticalScrollbar={false}
                        rowBuffer={20}
                        headerHeight={40}
                        groupHeaderHeight={22}
                        rowHeight={22}
                        // readOnlyEdit={true}
                        onRowDataUpdated={() => resizeColumns()}
                        suppressDragLeaveHidesColumns={true}
                        suppressColumnMoveAnimation={true}
                        animateRows={false}
                        onCellEditRequest={onCellEditRequest}
                    />
                </div>
            </Col>

            {
                alert &&
                <SweetAlert
                    title={alert.type.toLowerCase() === 'error' ? 'Error' : 'Success'}
                    error={alert.type.toLowerCase() === 'error'}
                    confirmBtnText="OK"
                    confirmBtnBsStyle={alert.type.toLowerCase() === 'error' ? "danger" : "success"}
                    onConfirm={() => {
                        if (alert.type.toLowerCase() === "error") {
                            setAlert(null);
                            return;
                        }

                        props.onClose();
                    }}
                >
                    {
                        alert.type.toLowerCase() == 'error' ? alert.message : 'File successfully uploaded'
                    }
                </SweetAlert>
            }
            {
                openValidationAlert && !validationAlert?.length &&
                <SweetAlert
                    title={validationAlert.length ? "Warning" : "Validation Success!"}
                    warning={validationAlert.length > 0}
                    success={validationAlert.length === 0}
                    confirmBtnText="OK"
                    confirmBtnBsStyle={validationAlert.length > 0 ? "danger" : "success"}
                    onConfirm={() => {
                        setOpenValidationAlert(false);
                    }}
                    className="swal-wide"
                >
                </SweetAlert>
            }
            {
                openValidationAlert && !!validationAlert?.length &&
                <Modal
                    isOpen={true}
                    className="modal-xl"
                    modalClassName="db-example-modal-xl"
                >
                    <div className="modal-header">
                        <h5 className="modal-title">
                            Validation Result
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            onClick={() => setOpenValidationAlert(false)}
                            type="button"
                        >
                            <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="ag-theme-alpine" style={{ width: '100%', height: data?.length ? (mode === UPLOAD_MODE ? "44vh" : "40vh") : 75 }}>
                            <AgGridReact
                                ref={gridRefV}
                                rowData={validationAlert}
                                columnDefs={columnDefsV}
                                defaultColDef={defaultColDef}
                                loadingOverlayComponent={loadingOverlayComponentV}
                                suppressColumnVirtualisation={false}
                                suppressRowVirtualisation={false}
                                debounceVerticalScrollbar={false}
                                rowBuffer={20}
                                headerHeight={40}
                                groupHeaderHeight={22}
                                rowHeight={22}
                                // readOnlyEdit={true}
                                onRowDataUpdated={() => resizeColumnsV()}
                                suppressDragLeaveHidesColumns={true}
                                suppressColumnMoveAnimation={true}
                                animateRows={false}
                                onCellEditRequest={onCellEditRequest}
                            />
                        </div>

                    </div>
                    <div className="modal-footer d-flex flex-wrap justify-content-between align-items-center w-100">
                        <div className="d-flex flex-wrap justify-content-start align-items-center">
                            <Button
                                color="secondary"
                                onClick={()=>setOpenValidationAlert(false)}
                                type="button"
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>
            }
        </Row>
    )
}