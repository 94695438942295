/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import api from '../../../services/api';
import Loader from '../../loaders';
import { ReportDialog, ErrorNotification } from '../../alerts';
import { DatePicker } from "../../date-pickers";
import { formatDate } from "../../../utils";
import { ComboBox, DropdownBox } from "../../dropdowns";
import qs from "qs";
import moment from "moment";
import fileDownload from "js-file-download";
import { debounce } from "lodash";
import Multiselect from 'multiselect-react-dropdown';
import { IndeterminateCheckbox } from '../../react-table/controls';

import {
    Container, Row, Col, Card, CardHeader, CardBody, Input, FormGroup, Label, Button, Collapse, Modal, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledPopover, PopoverBody,
    CardTitle
} from "reactstrap";
import { AcceptableExportWords } from '../../../helpers/StringHelper';

function List(props) {

    // Natural data
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    // Report Navigation
    const [reportDialog, setReportDialog] = useState([]);
    const [step, setStep] = useState(1);
    const [selectedData, setSelectedData] = useState(null);

    // Report Fields
    const [isHavingParams, setIsHavingParams] = useState(true);
    const [isHavingStart, setIsHavingStart] = useState(false);
    const [isHavingEnd, setIsHavingEnd] = useState(false);
    const [isHavingProjectIds, setIsHavingProjectIds] = useState(false);
    const [isHavingProjectId, setIsHavingProjectId] = useState(false);
    const [isHavingProjectIdWithAll, setIsHavingProjectIdWithAll] = useState(false);
    const [isHavingDispatchUnitId, setIsHavingDispatchUnitId] = useState(false);
    const [isHavingLocationId, setIsHavingLocationId] = useState(false);
    const [isHavingPositionId, setIsHavingPositionId] = useState(false);
    const [isHavingCandidateId, setIsHavingCandidateId] = useState(false);
    const [isHavingIncludeInactive, setIsHavingIncludeInactive] = useState(false);
    const [isHavingExternalLink, setIsHavingExternalLink] = useState(false);
    const [paramsCount, setParamsCount] = useState(0);
    const [paramsCol, setParamsCol] = useState(12);

    // Report Params
    const today = new Date();
    const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date(today.getFullYear(), today.getMonth() + 6, 1));
    const [selectedProject, setSelectedProject] = React.useState(null);

    // Error message variables
    const [errorTitle, setErrorTitle] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);
    const [errorMessageOpening, setErrorMessageOpening] = React.useState(`Error occured when processing the request: `);
    const [errorTitleOnGenerate, setErrorTitleOnGenerate] = React.useState(`Generate Report Error`);

    // MultiProjects
    const [loadingProjects, setLoadingProjects] = React.useState(false);
    const [projectList, setProjectList] = React.useState([]);
    const [selectedProjects, setSelectedProjects] = React.useState([]);
    const [selectedProjectIds, setSelectedProjectIds] = React.useState([]);

    // Dispatch Unit
    const [loadingDUs, setLoadingDUs] = React.useState(false);
    const [dispatchUnitList, setDispatchUnitList] = React.useState([]);
    const [selectedDu, setSelectedDu] = React.useState([]);
    const [selectedDuIds, setSelectedDuIds] = React.useState([]);

    // Locations
    const [loadingLocs, setLoadingLocs] = React.useState(false);
    const [locationsList, setLocationsList] = React.useState([]);
    const [selectedLoc, setSelectedLoc] = React.useState([]);
    const [selectedLocIds, setSelectedLocIds] = React.useState([]);

    // Position
    const [loadingPos, setLoadingPos] = React.useState(false);
    const [posList, setPosList] = React.useState([]);
    const [selectedPos, setSelectedPos] = React.useState([]);
    const [selectedPosIds, setSelectedPosIds] = React.useState([]);

    // Candidate
    const [loadingCad, setLoadingCad] = React.useState(false);
    const [cadList, setCadList] = React.useState([]);
    const [selectedCad, setSelectedCad] = React.useState([]);
    const [selectedCadIds, setSelectedCadIds] = React.useState([]);

    // Candidate
    const [includeInactiveValue, setIncludeInactiveValue] = useState(false);

    // External Link
    const [externalLinkUrl, setExternalLinkUrl] = React.useState(null);



    // BEGIN Location functions --------------------------------------------------------------------------------------------------------------------------------------
    const onLocationRemove = (newList) => {
        setSelectedLoc([...newList]);
        getLocation('');
    }
    const onLocationsFilterChangeMultiselect = (value) => {
        getLocation(value);
    }

    const getLocation = React.useCallback(debounce(filter => {
        // if (!filter || filter.length === 0) return;

        setLoadingDUs(true);
        api.get(`/location/search?filter=${filter}`)
            .then(({ data }) => {
                // setPositions([...data.map(d => ({...d, combinedName: `${d.hrispositionName} (${d.position.lookupValue})`}))]);
                setLocationsList(data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoadingDUs(false));
    }, 200), [locationsList]);

    const onLocationsChangeMultiselect = useCallback((values) => {
        //console.log(values);
        setSelectedLoc(values);
    }, [selectedLoc]);

    useEffect(() => {
        console.log('selectedLoc', selectedLoc);
        var locIdList = selectedLoc.map((v) => v.locationId);
        setSelectedLocIds(locIdList);
    }, [selectedLoc]);
    // END Location functions --------------------------------------------------------------------------------------------------------------------------------------


    // BEGIN Dispatch Unit functions --------------------------------------------------------------------------------------------------------------------------------------
    const onDispatchUnitsFilterChangeMultiselect = (value) => {
        console.log('onPositionsFilterChangeMultiselect', onDispatchUnitsFilterChangeMultiselect);
        getDispatchUnit(value);
    }

    const getDispatchUnit = React.useCallback(debounce(filter => {
        console.log('getDispatchUnit');
        // if (!filter || filter.length === 0) return;

        if (!selectedProject && !selectedLocIds) {
            setDispatchUnitList([]);
            console.log('project empty pos');

            return;
        }

        var projectId = !selectedProject ? null : selectedProject.projectId;

        setLoadingDUs(true);
        api.get(`/dispatchunit/search?filter=${filter}&projectId=${projectId ?? ''}&locationIds=${!selectedLocIds ? '' : selectedLocIds.join(',')}`)
            .then(({ data }) => {
                // setPositions([...data.map(d => ({...d, combinedName: `${d.hrispositionName} (${d.position.lookupValue})`}))]);
                setDispatchUnitList(data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoadingDUs(false));
    }, 200), [selectedProject, dispatchUnitList, selectedLocIds]);

    const onDispatchUnitsChangeMultiselect = useCallback((values) => {
        //console.log(values);
        setSelectedDu(values);
    }, [selectedDu]);

    useEffect(() => {
        var duIdList = selectedDu.map((v) => v.dispatchUnitId);
        setSelectedDuIds(duIdList);
    }, [selectedDu]);
    // END Dispatch Unit functions --------------------------------------------------------------------------------------------------------------------------------------

    // BEGIN Position functions --------------------------------------------------------------------------------------------------------------------------------------
    const onPositionsFilterChangeMultiselect = (value) => {
        console.log('onPositionsFilterChangeMultiselect', onPositionsFilterChangeMultiselect);
        getPosition(value);
    }

    const getPosition = React.useCallback(debounce(filter => {
        console.log('getPositions');
        // if (!filter || filter.length === 0) return;

        if (!selectedProject) {
            setPosList([]);
            console.log('project empty pos');

            return;
        }

        var projectId = !selectedProject ? null : selectedProject.projectId;


        const params = {
            start: moment(startDate).format('YYYY-MM-DD'),
            end: moment(endDate).format('YYYY-MM-DD'),
            projectIds: projectId,
            filter: filter
        };

        setLoadingPos(true);
        api.get(`/Position/search-for-rca-report?${qs.stringify(params)}`)
            .then(({ data }) => {
                // setPositions([...data.map(d => ({...d, combinedName: `${d.hrispositionName} (${d.position.lookupValue})`}))]);
                setPosList(data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoadingPos(false));
    }, 200), [selectedProject, startDate, endDate, selectedProject]);

    const onPositionsChangeMultiselect = useCallback((values) => {
        //console.log(values);
        setSelectedPos(values);
        setSelectedCad([]);
    }, [selectedPos]);

    useEffect(() => {
        if (!selectedPos) {
            setSelectedPos([]);
            return;
        }
        var posIdList = selectedPos.map((v) => v.positionId);
        setSelectedPosIds(posIdList);
    }, [selectedPos]);
    // END Position functions --------------------------------------------------------------------------------------------------------------------------------------

    // BEGIN Candidate functions --------------------------------------------------------------------------------------------------------------------------------------
    const onCandidatesFilterChangeMultiselect = (value) => {
        console.log('onCandidatesFilterChangeMultiselect', onCandidatesFilterChangeMultiselect);
        getCandidate(value);
    }

    const getCandidate = React.useCallback(debounce(filter => {
        console.log('getCandidates');
        console.log('selectedPosIds', selectedPosIds);
        // if (!filter || filter.length === 0) return;

        if (!selectedProject) {
            setCadList([]);
            console.log('project empty Cad');

            return;
        }

        var projectId = !selectedProject ? null : selectedProject.projectId;


        const params = {
            start: moment(startDate).format('YYYY-MM-DD'),
            end: moment(endDate).format('YYYY-MM-DD'),
            projectIds: projectId,
            positionIds: !selectedPosIds ? '' : selectedPosIds.join(','),
            filter: filter
        };

        setLoadingCad(true);
        api.get(`/Candidate/search-for-rca-report?${qs.stringify(params)}`)
            .then(({ data }) => {
                // setCandidates([...data.map(d => ({...d, combinedName: `${d.hrisCandidateName} (${d.Candidate.lookupValue})`}))]);
                setCadList(data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoadingCad(false));
    }, 200), [selectedProject, startDate, endDate, selectedPosIds]);

    const onCandidatesChangeMultiselect = useCallback((values) => {
        //console.log(values);
        setSelectedCad(values);
    }, [selectedCad]);

    useEffect(() => {
        if (!selectedCad) {
            setSelectedCad([]);
            return;
        }
        var CadIdList = selectedCad.map((v) => v.candidateId);
        setSelectedCadIds(CadIdList);
    }, [selectedCad]);
    // END Candidate functions --------------------------------------------------------------------------------------------------------------------------------------

    
    //#region Multi Projects functions
    const onProjectsRemove = (newList) => {
        setSelectedProjects([...newList]);
        getProjects('');
    }
    const onProjectsFilterChangeMultiselect = (value) => {
        getProjects(value);
    }

    const getProjects = React.useCallback(debounce(filter => {
        setLoadingProjects(true);
        api.get(`/project/search/false?filter=${filter}`)
            .then(({ data }) => {
                setProjectList(data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoadingProjects(false));
    }, 200), [projectList]);


    const onProjectsChangeMultiselect = useCallback((values) => {
        setSelectedProjects(values);
    }, [selectedProjects]);
    
    useEffect(() => {
        var projIdList = selectedProjects.map((v) => v.projectId);
        setSelectedProjectIds(projIdList);
    }, [selectedProjects]);
    //#endregion Multi Projects functions

    // Load data function
    const loadData = useCallback(() => {
        setLoading(true);
        api.get("reports/reportlist")
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                setErrorMessage(`${errorMessageOpening}${error.message}`);
                console.error("error", error);
            })
            .finally(() => setLoading(false))
            ;
    }, []);

    // Load data on start
    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        console.log(data);
    }, [data]);

    useEffect(() => {
        console.log('selectedProject', selectedProject);
        getDispatchUnit('');
        setSelectedDu([]);
        getPosition('');
        setSelectedPos('');

    }, [selectedProject, selectedLocIds]);

    useEffect(() => {
        console.log('selectedPosIds', selectedPosIds);
        getCandidate('');
    }, [selectedPosIds]);


    useEffect(() => {

        getLocation('');
        setSelectedLoc([]);

    }, [isHavingLocationId]);


    useEffect(() => {

        getProjects('');
        setSelectedProjects([]);

    }, [isHavingProjectIds]);



    useEffect(() => {
        if (step == 1) {
            setSelectedData(null);
        }
        if (step == 2 && isHavingExternalLink) {
            setStep(1);
        }
    }, [step, isHavingExternalLink]);

    const openReport = useCallback(() => {

    }, []);

    // Function to check is parameters have specific field name
    const isHaving = useCallback((fieldName) => {

        try {
            if (!selectedData || !selectedData.parameters)
                return null;

            var params = JSON.parse(selectedData.parameters);

            // If params is array
            if (Array.isArray(params)) {
                return params.find((v) => v.id == fieldName);
            }
            // If params is object
            else {
                if (fieldName in params) {
                    return params[fieldName];
                } else {
                    //console.warn(`fieldName ${fieldName} on params is not found, returns null instead`);
                    return null;
                }

            }
        }
        catch (error) {
            setErrorMessage(`${errorMessageOpening}${error.message}`);
            console.error(error);

            return null;
        }


    }, [selectedData]);

    // Function to check is parameters have required specific field name
    const isRequired = useCallback((fieldName) => {

        try {

            var obj = isHaving(fieldName);
            var isRequired = obj ? obj.required : false;
            return isRequired;
        }
        catch (error) {
            setErrorMessage(`${errorMessageOpening}${error.message}`);
            console.error(error);

            return false;
        }


    }, [selectedData]);


    // Initiate required fields
    useEffect(() => {
        var start = isHaving('start') ? true : false;
        var end = isHaving('end') ? true : false;
        var projectId = isHaving('projectId') ? true : false;
        var projectIdWithAll = isHaving('projectIdWithAll') ? true : false;
        var projectIds = isHaving('projectIds') ? true : false;
        var dispatchUnitId = isHaving('dispatchUnitId') ? true : false;
        var locationId = isHaving('locationId') ? true : false;
        var positionId = isHaving('positionId') ? true : false;
        var candidateId = isHaving('candidateId') ? true : false;
        var includeInactive = isHaving('includeInactive') ? true : false;
        var externalLink = isHaving('externalLink') ? true : false;

        setIsHavingStart(start);
        setIsHavingEnd(end);
        setIsHavingProjectId(projectId);
        setIsHavingProjectIds(projectIds);
        setIsHavingDispatchUnitId(dispatchUnitId);
        setIsHavingLocationId(locationId);
        setIsHavingPositionId(positionId);
        setIsHavingCandidateId(candidateId);
        setIsHavingIncludeInactive(includeInactive)
        setIsHavingExternalLink(externalLink);
        setIsHavingProjectIdWithAll(projectIdWithAll);

        setIsHavingParams(start || end || projectId || projectIdWithAll || projectIds || dispatchUnitId || locationId || positionId || candidateId || includeInactive || projectIdWithAll);

        // Count params
        var count = [start, end, projectId, projectIds, dispatchUnitId, locationId, positionId, candidateId, includeInactive].reduce((acc, current) => acc + !!current, 0);
        setParamsCount(count);
        setParamsCol(12 / count);

        // Add url
        setExternalLinkUrl(isHaving('externalLink'));


    }, [selectedData]);
    useEffect(() => {

        if (!externalLinkUrl)
            return;

        window.open(externalLinkUrl, "_blank");
        setExternalLinkUrl(null);

    }, [externalLinkUrl]);

    const exportManual = useCallback(() => {
        try {
            var d = selectedData;
            window.open(`/reportviewer/${d.reportName}?reportName=${d.reportFilename}&containerName=${d.reportName.replace(/\s/g, '')}`, "_blank");
            setStep(1);
        }
        catch (error) {
            console.error(error);
            setErrorMessage(`${errorMessageOpening}${error.message}`);
        }
    }, [selectedData, selectedProject, selectedProjectIds, selectedDuIds, errorMessageOpening, errorTitleOnGenerate, startDate, endDate]);


    // Function void to download the data
    const exportAction = useCallback(() => {
        try {
            var d = selectedData;
            //window.open(`/reportviewer/${d.reportName}?reportName=${d.reportFilename}&containerName=${d.reportName.replace(/\s/g, '')}`, "_blank");

            // Validate params
            if (isRequired('start') && !startDate) {
                setErrorTitle(errorTitleOnGenerate)
                setErrorMessage('Please input Start Date');
                return;
            }
            if (isRequired('end') && !endDate) {
                setErrorTitle(errorTitleOnGenerate)
                setErrorMessage('Please input End Date');
                return;
            }
            if (isRequired('projectId') && !selectedProject) {
                setErrorTitle(errorTitleOnGenerate)
                setErrorMessage('Please input Project');
                return;
            }
            if (isRequired('projectIds') && !selectedProjectIds) {
                setErrorTitle(errorTitleOnGenerate)
                setErrorMessage('Please input Project');
                return;
            }
            if (isRequired('dispatchUnitId') && !selectedDuIds) {
                setErrorTitle(errorTitleOnGenerate)
                setErrorMessage('Please input Dispatch Unit');
                return;
            }
            if (isRequired('locationId') && !selectedLocIds) {
                setErrorTitle(errorTitleOnGenerate)
                setErrorMessage('Please input Location');
                return;
            }

            var defaultProjectList = !selectedProject ? [] : [selectedProject.projectId];

            const params = {
                reportName: d.reportFilename,
                start: moment(startDate).format('YYYY-MM-DD'),
                end: moment(endDate).format('YYYY-MM-DD'),
                projectId: !selectedProject ? '' : selectedProject.projectId,
                projectIds: !selectedProjectIds ? '' : selectedProjectIds.join(','),
                dispatchUnitId: !selectedDuIds ? '' : selectedDuIds.join(','),
                locationId: !selectedLocIds ? '' : selectedLocIds.join(','),
                dispatchUnit: !selectedDuIds ? '' : selectedDuIds,
                location: !selectedLocIds ? '' : selectedLocIds,
                positionIds: !selectedPosIds ? '' : selectedPosIds,
                candidateIds: !selectedCadIds ? '' : selectedCadIds,
                defaultProject: defaultProjectList,
                includeInactive: includeInactiveValue
            };
            console.log('params', params);

            let url = `/reports/download-from-report-centre?${qs.stringify(params)}`;
            let startdateFileName = moment(startDate).format('YYYYMMDD');
            let enddateFileName = moment(endDate).format('YYYYMMDD');
            let purename = d.reportFilename.replace('.trdp', '');



            let projectTitle = getProjectFilename();
            let duTitle = firstOrMulti(selectedDu, `dispatchUnitName`, `Dispatch Unit`);
            let locationTitle = firstOrMulti(selectedLoc, `locationName`, `Location`);
            let positionTitle = firstOrMulti(selectedPos, `positionName`, `Position`);
            let includeInactiveTitle = getIncludeInactiveFilename();

            // If projectTitle contains all project then remove strip and trim
            if (projectTitle && projectTitle.toLowerCase().includes('all project')) {
                projectTitle = 'All Project';
            }


            //let projectTitle = AcceptableExportWords(getProjectFilename());
            //let duTitle = AcceptableExportWords(firstOrMulti(selectedDu, `dispatchUnitName`, `Dispatch Unit`));
            //let locationTitle = AcceptableExportWords(firstOrMulti(selectedLoc, `locationName`, `Location`));
            //let positionTitle = AcceptableExportWords(firstOrMulti(selectedPos, `positionName`, `Position`));
            //let includeInactiveTitle = AcceptableExportWords(getIncludeInactiveFilename());

            //let locationTitle = firstOrMulti(selectedLoc, `locationName`, `Location`);
                //let duTitle = selectedDu ? `-Dispatch Unit ${selectedDu.find(w => w).dispatchUnitName.replace(/^(dispatch unit)/ig, "")}` : ``;
                
            //let projectTitle = selectedProject ? `-Project ${selectedProject.projectName.replace(/^(project)/ig, "")}` : ``;
            //let projectTitle = selectedProject ? `-Project ${selectedProject.projectName.replace(/^(project)/ig, "")}` : ``;


            let filename = `${purename}-${startdateFileName}-to-${enddateFileName}${projectTitle}.xlsx`;
            var endpointName = ``;

            if (d.reportName == `All Roster Export`)
            {
                endpointName = `download-all-roster-export`;
                filename = `${purename}-${startdateFileName}-to-${enddateFileName}${projectTitle}.xlsx`;
            }
            else if (d.reportName == `Fatigue Report`)
            {
                endpointName = `download-fatigue`;
                filename = `${endpointName}${locationTitle}${duTitle}.xlsx`;
            }
            else if (d.reportName == `Roster Candidate Allocation`)
            {
                endpointName = `download-roster-candidate-allocation`;
                var rawFilename = `${endpointName}-${startdateFileName}-to-${enddateFileName}-${projectTitle}-${positionTitle}-${includeInactiveTitle}`;
                //var downloadFilename = AcceptableExportWords(rawFilename);
                filename = `${rawFilename}.xlsx`;
            }
            else if (d.reportName == `Vacancy Report`) {
                endpointName = `download-vacancy-report`;
                filename = `${endpointName}-${startdateFileName}-to-${enddateFileName}${projectTitle}${duTitle}.xlsx`;
            }
            else if (
                d.reportName == `Roster Export` ||
                d.reportName == `Roster Export Detail` 
            )
            {
                filename = `${purename}-${startdateFileName}-to-${enddateFileName}${projectTitle}${duTitle}.xlsx`;
            }
            else if (
                d.reportName == `Candidate Availability Report`
            )
            {
                endpointName = `download-candidate-availability-report`;
                filename = `HRIS Candidate Availability Report ${startdateFileName}-${enddateFileName} ${moment().format("YYYYMMDDhhmmss")}.xlsx`;
            }

            // If end point overriden then get from end point name
            if (endpointName) {
                url = `/reports/${endpointName}?${qs.stringify(params)}`;

                console.log(url);
            }

            downloadExport(url, filename);
            //setStep(1);
        }
        catch (error) {
            console.error(error);
            setErrorMessage(`${errorMessageOpening}${error.message}`);
        }
    }, [selectedData, selectedProject, selectedProjectIds, selectedDuIds, errorMessageOpening, errorTitleOnGenerate, startDate, endDate, selectedLocIds, selectedPosIds, selectedCadIds, includeInactiveValue]);

    const getProjectFilename = useCallback(() => {
        return selectedProject ? `-Project ${selectedProject.projectName.replace(/^(project)/ig, "")}` : ``;
    }, [selectedProject]);

    const getIncludeInactiveFilename = useCallback(() => {
        return includeInactiveValue == null ? `` : `-Include Inactive ${includeInactiveValue == true ? 'Yes' : 'No'}`;
    }, [includeInactiveValue]);


    const beginWith = (text, beginWith) => {
        var index = text.indexOf(beginWith);

        return index == 0 ? true : false;
    };

    const firstOrMulti = useCallback((list, keyName, prefix = '') => {

        console.log('firstOrMulti', { list, keyName, prefix })
        //console.log('list', list);

        if (!list || list.length == 0) {
            return ``;
        }

        console.log('continue');

        var prefixLower = prefix ? prefix.toLowerCase() : '';
        var unitName = list.find(w => w)[keyName];
        if (unitName && prefixLower) {
            var regEx = new RegExp(`/^(${prefixLower})/ig`);
            unitName = unitName.replace(regEx, '');
        }

        // Remove duplicate prefix
        //if (prefix && beginWith(unitName, prefix))


        if (list.length == 1) {
            return `-${prefix} ${unitName}`;
        }
        else {
            return `-${prefix} Multiple`;
        }
    }, []);

    const downloadExport = React.useCallback((url, filename) => {
        setLoading(true);
        api.get(url, { responseType: 'blob' })
            .then(blob => {
                // console.log(blob);
                //var filename = /(?<=attachment;.*filename=").*(?=";)/.exec(blob.headers["content-disposition"])[0];
                fileDownload(blob.data, filename);
            }).catch(error => {
                console.error(error);
                setErrorMessage(`${errorMessageOpening} ${error.message} `);
                //setLoading(false);
            }).finally(() => setLoading(false))
    }, []);

    // Error message handling
    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            message: errorMessage,
            title: errorTitle,
            onConfirm: () => {
                setErrorTitle(null);
                setErrorMessage(null);
            }
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage, errorTitle]);


    return (
        <section className="main">
            {
                (loading) && <Loader />
            }
            {reportDialog.length > 0 && reportDialog}
            {errorNotification.length > 0 && errorNotification}
            <Container fluid>
                <Card className="no-transition">
                    <CardBody className="full-height-card">
                        <Row>
                            <Col lg={12}>
                                <CardTitle>
                                    <h4 className="text-center">Report Centre</h4>
                                    {selectedData && <h5 className="text-center mb-4">{selectedData.reportName}</h5>}
                                </CardTitle>
                            </Col>
                        </Row>
                        <Collapse id="step-1" isOpen={step == 1}>
                            <Row className="mt-4 d-flex flex-wrap justify-content-center align-items-center">
                                {
                                    data.map((d) => {
                                        return (
                                            <Col
                                                md={12}
                                                lg={8}
                                                className="p-0 m-3 border border-dark rounded"
                                                key={`report_${d.reportId} `}
                                                style={{ height: 60 }}
                                            >
                                                <Button
                                                    onClick={() => {
                                                        //var params = {

                                                        //};
                                                        //var events = {
                                                        //    onConfirm: () => exportAction(d),
                                                        //    onCancel: () => setReportDialog([]),
                                                        //    params: params
                                                        //}
                                                        //setReportDialog([<ReportDialog {...events} />]);
                                                        setSelectedData(d);
                                                        setStep(2);
                                                        setSelectedDu([]);
                                                        setSelectedDuIds([]);
                                                        setSelectedLoc([]);
                                                        setSelectedLocIds([]);
                                                        setSelectedProject(null);
                                                        setSelectedProjects([]);
                                                        console.log('d', d);

                                                        //if (d.reportFilename == 'AllRosterExport.trdp' ||
                                                        //    d.reportFilename == 'RosterCandidateAllocation.trdp'
                                                        //) {
                                                        //    setIsHavingParams(true);
                                                        //}
                                                        //else {
                                                        //    setIsHavingParams(false);
                                                        //}
                                                    }}
                                                    className="w-100 h-100 p-0 m-0 border-0"
                                                    color="outline-light"
                                                >
                                                    <Container fluid className="h-100">
                                                        <Row className="h-100">
                                                            <Col lg={2} className="p-0">
                                                                <h2 className="m-0 h-100 d-flex flex-wrap justify-content-center align-items-center"><i className="far fa-list-alt" /></h2>
                                                            </Col>
                                                            <Col lg={8} className="p-0">
                                                                <h5 className="m-0 p-0 h-100 d-flex flex-wrap justify-content-start align-items-center">{d.reportName}</h5>
                                                            </Col>
                                                            <Col lg={2} className="p-0">
                                                                <h5 className="m-0 p-0 h-100 d-flex flex-wrap justify-content-center align-items-center"><i className="fas fa-chevron-right" /></h5>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </Button>
                                            </Col>
                                        );
                                    })
                                }
                            </Row>
                        </Collapse>
                        <Collapse id="step-2" isOpen={step == 2}>

                            <Collapse id="params" isOpen={isHavingParams == true}>
                                <Row className="text-justify">
                                    <Col lg={3} md={2} className="hidden-sm">
                                    </Col>

                                    <Col lg={6} md={8} sm={12}>
                                        <Row>
                                            {isHavingStart &&
                                                <Col sm={6}>
                                                    <FormGroup>
                                                        <Label>
                                                            Start Date
                                                            <span className="text-danger">*</span>
                                                        </Label>
                                                        <DatePicker
                                                            name="startDate"
                                                            id="startDate"
                                                            type="text"
                                                            required
                                                            value={formatDate(startDate)}
                                                            onChange={(value) => {
                                                                setStartDate(value);
                                                            }}
                                                            closeOnSelect
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            }
                                            {isHavingEnd &&
                                                <Col sm={6}>
                                                    <FormGroup>
                                                        <Label>
                                                            End Date
                                                            <span className="text-danger">*</span>
                                                        </Label>
                                                        <DatePicker
                                                            name="endDate"
                                                            id="endDate"
                                                            type="text"
                                                            required
                                                            value={formatDate(endDate)}
                                                            onChange={(value) => {
                                                                setEndDate(value);
                                                            }}
                                                            closeOnSelect
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            }
                                            {(isHavingProjectId || isHavingProjectIdWithAll) &&
                                                <Col sm={6}>
                                                    <FormGroup>
                                                        <Label>
                                                            Project
                                                            <span className="text-danger">*</span>
                                                        </Label>
                                                        <ComboBox
                                                            endpoint={isHavingProjectIdWithAll ? "/project/search-with-all" : "/project/search"}
                                                            isLookup={false}
                                                            idField="projectId"
                                                            valueField="projectName"
                                                            selectedItem={selectedProject}
                                                            onChange={setSelectedProject}
                                                            minLength={0}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            }
                                            {isHavingProjectIds &&
                                                <Col sm={6}>
                                                    <FormGroup>
                                                        <Label>
                                                            Projects
                                                        </Label>
                                                        <Multiselect
                                                            className="slim-search"
                                                            options={projectList}
                                                            loading={loadingProjects}
                                                            displayValue="projectName"
                                                            placeholder="Type to search"
                                                            selectedValues={selectedProjects}
                                                            onSearch={onProjectsFilterChangeMultiselect}
                                                            onSelect={(selected) => {
                                                                onProjectsChangeMultiselect(selected);
                                                            }}
                                                            onRemove={onProjectsRemove}
                                                            showCheckbox={true}
                                                            showArrow={true}
                                                            closeOnSelect={true}

                                                            // endpoint="/project/search"
                                                            // isLookup={false}
                                                            // idField="projectId"
                                                            // valueField="projectName"
                                                            // selectedItem={selectedProject}
                                                            // onChange={setSelectedProject}
                                                            // minLength={0}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            }
                                            {isHavingLocationId &&
                                                <Col sm={6}>
                                                    <FormGroup>
                                                        <Label>
                                                            Location
                                                            {/*<span className="text-danger">*</span>*/}
                                                        </Label>
                                                        <Multiselect
                                                            className="slim-search"
                                                            options={locationsList}
                                                            loading={loadingLocs}
                                                            displayValue="locationName"
                                                            placeholder="Type to search"
                                                            selectedValues={selectedLoc}
                                                            onSearch={onLocationsFilterChangeMultiselect}
                                                            onSelect={(selected) => {
                                                                onLocationsChangeMultiselect(selected);
                                                            }}
                                                            onRemove={onLocationRemove}
                                                            showCheckbox={true}
                                                            showArrow={true}
                                                            closeOnSelect={true}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            }

                                            {isHavingDispatchUnitId &&
                                                <Col sm={6}>
                                                    <FormGroup>
                                                        <Label>
                                                            Dispatch Unit
                                                            {/*<span className="text-danger">*</span>*/}
                                                        </Label>
                                                        <Multiselect
                                                            className="slim-search"
                                                            options={dispatchUnitList}
                                                            loading={loadingDUs}
                                                            displayValue="dispatchUnitName"
                                                            placeholder="Type to search"
                                                            selectedValues={selectedDu}
                                                            onSearch={onDispatchUnitsFilterChangeMultiselect}
                                                            onSelect={(selected) => {
                                                                onDispatchUnitsChangeMultiselect(selected);
                                                            }}
                                                            showCheckbox={true}
                                                            showArrow={true}
                                                            closeOnSelect={true}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            }

                                            {isHavingPositionId &&
                                                <Col sm={6}>
                                                    <FormGroup>
                                                        <Label>
                                                            Position
                                                            {/*<span className="text-danger">*</span>*/}
                                                        </Label>
                                                        <Multiselect
                                                            className="slim-search"
                                                            options={posList}
                                                            loading={loadingPos}
                                                            displayValue="positionName"
                                                            placeholder="Type to search"
                                                            selectedValues={selectedPos}
                                                            onSearch={onPositionsFilterChangeMultiselect}
                                                            onRemove={onPositionsChangeMultiselect}
                                                            onSelect={(selected) => {
                                                                onPositionsChangeMultiselect(selected);
                                                            }}
                                                            showCheckbox={true}
                                                            showArrow={true}
                                                            closeOnSelect={true}
                                                            disabled={true}
                                                        />
                                                        {/*disabled={!selectedProject && !selectedLoc}*/}
                                                    </FormGroup>
                                                </Col>
                                            }

                                            {isHavingCandidateId &&
                                                <Col sm={6}>
                                                    <FormGroup>
                                                        <Label>
                                                            Candidate
                                                            {/*<span className="text-danger">*</span>*/}
                                                        </Label>
                                                        <Multiselect
                                                            className="slim-search"
                                                            options={cadList}
                                                            loading={loadingCad}
                                                            displayValue="candidateName"
                                                            placeholder="Type to search"
                                                            selectedValues={selectedCad}
                                                            onSearch={onCandidatesFilterChangeMultiselect}
                                                            onRemove={onCandidatesChangeMultiselect}
                                                            onSelect={(selected) => {
                                                                onCandidatesChangeMultiselect(selected);
                                                            }}
                                                            showCheckbox={true}
                                                            showArrow={true}
                                                            closeOnSelect={true}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            }

                                            {isHavingIncludeInactive &&

                                                <Col sm={6}>
                                                <FormGroup>
                                                    <label>&nbsp;</label>


                                                    <IndeterminateCheckbox
                                                        id="includeInactiveValueCheckBox"
                                                        type="checkbox"
                                                        labelClassName=""
                                                        onChange={() => {
                                                            setIncludeInactiveValue(!includeInactiveValue);
                                                        }}
                                                        checked={includeInactiveValue}
                                                        customTitle="Include Inactive"
                                                    />
                                                    </FormGroup>
                                                </Col>
                                            }
                                        </Row>
                                    </Col>

                                    <Col lg={3} md={2} className="hidden-sm">
                                    </Col>

                                </Row>
                            </Collapse>
                            <Collapse id="params" isOpen={isHavingParams == false}>
                                <Row className="text-justify">
                                    <Col sm="12">
                                        Press download to continue.
                                    </Col>
                                </Row>
                            </Collapse>
                            <Row className="text-center mt-4">
                                <Col sm="12">
                                    <Button color="secondary" type="button"
                                        onClick={() => {
                                            setStep(1);
                                        }}>
                                        <span class="fa fa-arrow-left mr-2"></span>
                                        Back
                                    </Button>
                                    <Button color="info" type="button"
                                        onClick={() => {
                                            //generateMessage();
                                            exportAction();
                                        }}>
                                        <span class="fa fa-download mr-2"></span>
                                        Download
                                    </Button>
                                </Col>
                            </Row>
                            {/*<Row className="text-center mt-4">
                                <Col sm="12">
                                    <small>Too confused?
                                        <a className="ml-1" href="javascript:;" onClick={() => {
                                        exportManual();
                                    }}><u>Click here</u></a> to use the old school.</small>
                                </Col>
                            </Row>*/}
                        </Collapse>
                    </CardBody>
                </Card>
            </Container>
        </section>
    );
};

export default List;