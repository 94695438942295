/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';
import {
    Button, Modal, Row, Col, FormGroup, Label, Input, Container
} from "reactstrap";
import api from "../../../../services/api";
import { Mode } from '../../../../utils';
import { formatDate, momentToDateOnly } from '../../../../utils/index';
import { DatePicker } from '../../../date-pickers';

export default function ExportRosterScheduleDialog(props) {
    const {
        onClose,
        itemInEditRef: sourceRef,
        setItemInEdit: setSource,
        //inputStartDateRef,
        //inputEndDateRef,
        //setInputStartDate,
        //setInputEndDate,
        action
    } = props;


    const [itemInEdit, setItemInEdit] = useState(sourceRef?.current);

    useEffect(() => {
        setSource(itemInEdit);
    }, [itemInEdit]);



    // Refs
    const handleDateChanged = useCallback((name, value) => {
        // console.log({name, value});
        setItemInEdit({
            ...itemInEdit,
            [name]: value ? momentToDateOnly(value) : null
        });
    }, [itemInEdit]);


    return (
        <Modal
            isOpen={true}
        >
            <div
                className="modal-header"
            >
                <Container fluid>
                    <Row>
                        <Col lg={9}>
                            <h5
                                className="modal-title"
                            >
                                Export Roster Schedule
                            </h5>
                        </Col>
                        <Col lg={3}>
                            <button
                                aria-label="Close"
                                className="close"
                                onClick={onClose}
                                type="button"
                            >
                                <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                            </button>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <h6 className="modal-title">

                            </h6>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="modal-body">
                <Row>
                    <Col lg={6} xs={12}>
                        <FormGroup>
                            <Label>
                                Start Date
                                <span className="text-danger">*</span>
                            </Label>
                            <DatePicker
                                name="startDate"
                                id="startDate"
                                type="text"
                                required
                                value={itemInEdit?.startDate ? formatDate(itemInEdit?.startDate) : null}
                                onChange={(value) => {
                                    handleDateChanged("startDate", value);
                                }}
                                closeOnSelect
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={6} xs={12}>
                        <FormGroup>
                            <Label>
                                End Date
                                <span className="text-danger">*</span>
                            </Label>
                            <DatePicker
                                name="endDate"
                                id="endDate"
                                type="text"
                                required
                                value={itemInEdit?.endDate ? formatDate(itemInEdit?.endDate) : null}
                                onChange={(value) => {
                                    handleDateChanged("endDate", value);
                                }}
                                closeOnSelect
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            <div
                className="modal-footer"
            >
                <div className="w-100 h-100 d-flex flex-wrap justify-content-end align-items-center">
                    <Button
                        color="default"
                        onClick={() => action()}
                        type="button"
                        disabled={!itemInEdit?.startDate || !itemInEdit?.endDate}
                    >
                        Submit
                    </Button>
                    <Button
                        color="secondary"
                        onClick={onClose}
                        type="button"
                    >
                        Close
                    </Button>
                </div>
            </div>
        </Modal>
    )
}