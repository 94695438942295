import React, { useCallback, useEffect, useState } from 'react';
import {
    Label, FormGroup, Row, Col, Collapse, Container
 } from "reactstrap"; 
import { ComboBox, DropdownBox } from "../../../dropdowns";
import { useTable, useFlexLayout } from 'react-table';
import _ from "lodash";
import { TooltipButton } from '../../../inputs';
import Grid from "../../../Grid";
import { formatDate, formatDateZeroTime, Mode } from '../../../../utils';
import moment from "moment";
import { DatePicker } from '../../../date-pickers';
import react from '@handsontable/react';

const dateFormats = [
    "YYYY-MM-DD",
    "DD-MM-YYYY",
    "DD/MM/YYYY",
    "YYYY-MM-DDT00:00:00"
];

export default function AllocationHistory(props) {

    const [ drawerMode, setDrawerMode ] = React.useState(Mode.NONE);
    const [ candidateAllocation, setCandidateAllocation ] = React.useState(null);
    const [ newId, setNewId ] = useState(0);
    const [candidateId, setCandidateId] = useState(0);
    const [data, setData] = useState([]);

    const skipPageResetRef = React.useRef();

    const {
        itemInEdit, 
        setItemInEdit
    } = props;

    const refreshData = React.useCallback(() => {
        if (!itemInEdit || !itemInEdit.candidateAllocations)
            return;
        
        setData([...itemInEdit.candidateAllocations]);
    }, [itemInEdit]);

    React.useEffect(() => {
        if ((itemInEdit?.candidateId ?? 0) === candidateId)
            return;
        
        setCandidateId(itemInEdit?.candidateId ?? 0);
    }, [itemInEdit?.candidateId]);

    useEffect(() => {
        refreshData();
    }, [candidateId]);

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 150, 
            maxWidth: 200,
    }), [])

    const columns = React.useMemo(() => {
        const colArray = [
            {
                Header: "Entity",
                accessor: "company.companyName",
            },
            {
                Header: "Project",
                accessor: "project.projectName",
            },
            {
                Header: "Position",
                accessor: "position.positionName",
            },
            {
                Header: "Start Date",
                accessor: (row) => row.startDate ? moment(row.startDate).format("DD/MM/YYYY") : "",
            },
            {
                Header: "End Date",
                accessor: (row) => row.endDate ? moment(row.endDate).format("DD/MM/YYYY") : "",
            },
            {
                Header: "Is Active",
                accessor: (row) => row.active ? "Yes" : "No",
            },
            {
                id: 'buttons',
                Header: "Action",
                width: 180,
                canResize: false,
                Cell: ({row:{original, isGrouped}}) => (
                    (original && !isGrouped) ?
                    (
                        <div className="td-action text-center">
                            <TooltipButton
                                id={`edit_${original.candidateAllocationId}`}
                                title="Edit"
                                className="btn-icon"
                                color="default"
                                size="xsm"
                                type="button"
                                onClick={() => {
                                    setCandidateAllocation({...original})
                                    setDrawerMode(Mode.EDIT);
                                }}
                            >
                                <i className="fas fa-pencil-alt pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`delete_${original.candidateAllocationId}`}
                                title="Delete"
                                className="btn-icon"
                                color="warning"
                                size="xsm"
                                type="button"
                                onClick={() => {
                                    console.log("Delete: ", { allocation: original, itemInEdit });
                                    const index = itemInEdit.candidateAllocations.findIndex(val => val.candidateAllocationId === original.candidateAllocationId);

                                    if (index < 0)
                                        return;
    
                                    const allocations = [...itemInEdit.candidateAllocations];
                                    allocations.splice(index, 1);
                                    setData([...allocations]);
                                    setItemInEdit({ ...itemInEdit, candidateAllocations: allocations });
                                }}
                            >
                                <i className="fas fa-trash-alt pt-1"></i>
                            </TooltipButton>
                        </div>
                    ) :
                    (
                        <div className="td td-action"></div>
                    )
                )
            },
        ];

        if (!itemInEdit.canUpdateAllocationHistory)
        {
            const buttonIndex = colArray.indexOf(c => c.id === "buttons");
            colArray.splice(buttonIndex, 1);
        }

        return colArray;
    }, [data]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data: data || [],
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout
    );

    return (<>
        <Collapse
            isOpen={drawerMode !== Mode.NONE}
            style={{ zIndex: "9999", border: "0.0625rem solid #dee2e6" }}
            className="bg-white w-100 rounded p-3 mb-2"
        >
            <Container fluid>
                    <Row>
                        <Col xs={12} md={4}>
                            <FormGroup>
                                <Label>
                                    Company (Entity)
                                </Label>
                                <DropdownBox
                                    data={props.lookups.companies?.map(o => ({companyId: o.lookupId, companyName: o.lookupValue}))}
                                    selectedItem={candidateAllocation?.company}
                                    idField="companyId"
                                    valueField="companyName"
                                    onChange={(selectedItem) => setCandidateAllocation({ 
                                        ...candidateAllocation, 
                                        company: selectedItem, 
                                        companyId: selectedItem?.companyId 
                                    })}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormGroup>
                                <Label>
                                    Project
                                </Label>
                                <ComboBox 
                                    endpoint="/project/search"
                                    isLookup={false}
                                    minLength={0}
                                    idField="projectId"
                                    valueField="projectName"
                                    selectedItem={candidateAllocation?.project}
                                    onChange={(selectedItem) => setCandidateAllocation({ 
                                        ...candidateAllocation, 
                                        project: selectedItem, 
                                        projectId: selectedItem?.projectId 
                                    })}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormGroup>
                                <Label>
                                    Position
                                </Label>
                                <ComboBox 
                                    endpoint="/position/search"
                                    isLookup={false}
                                    minLength={0}
                                    idField="positionId"
                                    valueField="positionName"
                                    selectedItem={candidateAllocation?.position}
                                    onChange={(selectedItem) => setCandidateAllocation({ 
                                        ...candidateAllocation, 
                                        position: selectedItem, 
                                        positionId: selectedItem?.positionId 
                                    })}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormGroup>
                                <Label>
                                    Start Date
                                </Label>
                                <DatePicker
                                    name="startDate"
                                    id="startDate"
                                    type="text"
                                    required
                                    value={candidateAllocation?.startDate ? moment(candidateAllocation.startDate, dateFormats, true).format("DD/MM/YYYY") : ""}
                                    onChange={(value) => setCandidateAllocation({ 
                                        ...candidateAllocation, 
                                        startDate: !value ? null : formatDate(value, "YYYY-MM-DDT00:00:00")
                                    })}
                                    closeOnSelect
                                    isValidDate={() => true}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormGroup>
                                <Label>
                                    End Date
                                </Label>
                                <DatePicker
                                    name="endDate"
                                    id="endDate"
                                    type="text"
                                    required
                                    value={candidateAllocation?.endDate ? moment(candidateAllocation.endDate, dateFormats, true).format("DD/MM/YYYY") : ""}
                                    onChange={(value) => setCandidateAllocation({ 
                                        ...candidateAllocation, 
                                        endDate: !value ? null : formatDate(value, "YYYY-MM-DDT00:00:00")
                                    })}
                                    closeOnSelect
                                    isValidDate={() => true}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <FormGroup className="">
                                <Label for="verified">
                                    Active
                                </Label>
                                <label className="custom-toggle custom-toggle-primary d-block">
                                    <input type="checkbox" 
                                            name="active"
                                            id="active"
                                            checked={candidateAllocation?.active ? true : false} 
                                            onChange={({ target: { checked } }) => {
                                                console.log('checked', checked);
                                                setCandidateAllocation({ ...candidateAllocation, active: checked })
                                            }} 
                                    />
                                    <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                                </label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="d-flex justify-content-end">
                            <TooltipButton
                                id="cancel"
                                title="Cancel"
                                className="btn-icon"
                                color="warning"
                                size="sm"
                                type="button"
                                onClick={ () => {
                                    setDrawerMode(Mode.NONE);
                                }}                    
                            >
                                Cancel
                            </TooltipButton>
                            <TooltipButton
                                id="add"
                                title={drawerMode === Mode.ADD ? "Add" : drawerMode === Mode.EDIT ? "Update" : ""}
                                className="btn-icon ml-2"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={ () => { 
                                    if (drawerMode === Mode.ADD) {
                                        const allocations = data ? [...data] : [];
                                        
                                        setData([
                                            ...allocations,
                                            { 
                                                ...candidateAllocation
                                            }
                                        ]);
                                        setItemInEdit({
                                            ...itemInEdit,
                                            candidateAllocations: [
                                                ...allocations,
                                                { 
                                                    ...candidateAllocation
                                                }
                                            ]
                                        });
                                        setNewId(newId - 1);
                                    } 
                                    else if (drawerMode === Mode.EDIT)
                                    {
                                        const index = _.findIndex(data, o => o.candidateAllocationId === candidateAllocation.candidateAllocationId);

                                        const allocations = [...data];
                                        if (candidateAllocation.active && allocations.length > 0) 
                                        {
                                            allocations.forEach(f => f.active = false);
                                        }
                                        allocations.splice(index, 1, { 
                                            ...candidateAllocation
                                        } );

                                        setData([...allocations]);
                                        setItemInEdit( { 
                                            ...itemInEdit, 
                                            candidateAllocations: allocations 
                                        });
                                    }
                                    setDrawerMode(Mode.NONE);
                                }}
                            >
                                {drawerMode === Mode.ADD ? "Add" : drawerMode === Mode.EDIT ? "Update" : ""}
                            </TooltipButton>
                        </Col>
                    </Row>  
            </Container>
        </Collapse>
    {
        itemInEdit.canUpdateAllocationHistory ?
        <Collapse
            isOpen={drawerMode === Mode.NONE}
        >
            <TooltipButton
                id="addnew"
                title="Add New"
                className="btn-icon ml-3 mb-2"
                color="default"
                size="sm"
                type="button"
                onClick={ () => {
                    setCandidateAllocation({ candidateAllocationId: newId, active: true, company: null, project: null, position: null })
                    setDrawerMode(Mode.ADD);
                }}
            >
                <i className="fas fa-plus pt-1"></i>
            </TooltipButton>
        </Collapse>
        :
        null
    }
        <Grid
            rows={rows}
            tableProps={getTableProps()}
            headerGroups={headerGroups}
            tableBodyProps={getTableBodyProps()}
            prepareRow={prepareRow}
            // height='calc(100vh - 200px)'
            height='33vh'
        />
    </>)

}