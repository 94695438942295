/* eslint-disable no-unused-vars */
////import React, { useState } from 'react';
import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import { useParams } from "react-router-dom";
import api from "../../../../../services/api";
import qs from "qs";
import { SortsConverter, FiltersConverter } from "../../../../../helpers"
import { Container, Row, Col, Button, Card, CardBody, CardTitle, UncontrolledTooltip, Progress, Label } from "reactstrap";
import { DropdownBox } from "../../../../dropdowns";
import moment from "moment";
import { ErrorNotification, ConfirmCancelAlert, ConfirmRollbackAlert, ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../../../alerts";
import { TooltipButton } from '../../../../inputs';
import fileDownload from "js-file-download";
import Loader from "../../../../loaders";
import ContractDialog from "../ContractDialog";
import ContractTemplate from "../ContractTemplate";
import DefinitivOnboardingEditor from '../DefinitivOnboardingEditor';
import SweetAlert from 'react-bootstrap-sweetalert';
import debounce from 'lodash.debounce';
import VacancyEditor from '../VacancyEditor';
import JobOrderApplicantList from '../JobOrderApplicantList';
import { DateComparator, DateValueFormatter, MakeWordsValueGetter, ImportDialogMessageCellRenderer } from '../../../../../helpers/AgGridHelper';
import DropdownButton from '../../../../dropdowns/dropdownbutton';
import { IndeterminateCheckbox } from '../../../../react-table/controls';
import EditJobOrderGridV2 from './EditJobOrderGridV2';

const timeoutLength = 1500;

export default function PageAlt(props) {
    const {
        item,
        candidateIdForCvPreview,
        setCandidateIdForCvPreview,
        onClose,
        setRefreshHandle,
        onSaved,
        onViewCandidate,
        onViewSearchCandidate,
        onViewJobOrderCandidate,
        onViewCandidateEmail,
        onViewCandidateSMS,
        onViewCandidateEmails,
        onViewBulkCandidateSMS,
        onViewBulkMilestone,
        setPdrsRequest
    } = props;

    const itemInEditRef = useRef(null);
    const [open, setOpen] = React.useState(true);
    const [loading, setLoading] = useState(false);

    // BEGIN REF -----------------------------------------------------------------------------------------------------------------------------
    const loadingRef = useRef(loading);

    const openRef = useRef(open);
    useEffect(() => {
        openRef.current = open;
    }, [open]);

    useEffect(() => {
        loadingRef.current = loading;
    }, [loading]);
    // END REF -----------------------------------------------------------------------------------------------------------------------------


    return (
        <>

            {(loading) && <Loader />}

            {/*
                <CardTitle>
                    <h4 className="text-center">Job Orders {params.requestId && `LRF #${params.requestId}`}</h4>
                </CardTitle>
                */}

            <Row noGutters>
                <Col xs={12}>
                    <EditJobOrderGridV2
                        item={item}
                        candidateIdForCvPreview={candidateIdForCvPreview}
                        setCandidateIdForCvPreview={setCandidateIdForCvPreview}
                        onClose={onClose}
                        setRefreshHandle={setRefreshHandle}
                        onSaved={onSaved}
                        onViewCandidate={onViewCandidate}
                        onViewSearchCandidate={onViewSearchCandidate}
                        onViewJobOrderCandidate={onViewJobOrderCandidate}
                        onViewCandidateEmail={onViewCandidateEmail}
                        onViewCandidateSMS={onViewCandidateSMS}
                        onViewCandidateEmails={onViewCandidateEmails}
                        onViewBulkCandidateSMS={onViewBulkCandidateSMS}
                        onViewBulkMilestone={onViewBulkMilestone}
                        itemInEditRef={itemInEditRef}
                        setOpen={setOpen}
                        openRef={openRef}
                        setLoading={setLoading}
                        loadingRef={loadingRef}
                        callFrom="page"
                        setPdrsRequest={setPdrsRequest}
                    />
                </Col>
            </Row>


        </>
    )
}

