import React, { useEffect, useRef, useState, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import api from "../../../../services/api";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import qs from "qs";
import debounce from 'lodash.debounce';

const textField = "hrispositionMasterName";
const keyField = "hrispositionMasterId";
const endpoint = "/rostergrid/searchpositions";

const PositionEditor = forwardRef((props, ref) => {
    const { charPress } = props;
    const ovalue = {
        [keyField]: props.data[keyField],
        [textField]: props.data[textField]
    };
    const [value, setValue] = useState(ovalue);
    const [filter, setFilter] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [opened, setOpened] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const refInput = useRef(null);

    useEffect(() => {
        if (!charPress || charPress === "")
        {
            refInput.current.focus();
            return;
        }

        refInput.current.focus();
        setFilter(charPress);
        setValue({[keyField]: 0, [textField]: charPress});
    }, []);

    useImperativeHandle(ref, () => {
        return {
            // the final value to send to the grid, on completion of editing
            getValue() {
                return value;
            }, 
            // Gets called once before editing starts, to give editor a chance to
            // cancel the editing before it even starts.
            isCancelBeforeStart() {
                return false;
            }, 
            // Gets called once when editing is finished (eg if Enter is pressed).
            // If you return true, then the result of the edit will be ignored.
            isCancelAfterEnd() {
                return (!value ? null : value[keyField]) === ovalue[keyField];
            }
        };
    });

    const requestData = React.useCallback(
        debounce((filter) => {
            if (!filter) {
                setLoading(false);
                return;
            }

            const queryString = qs.stringify({ filter }, { allowDots: true });

            if (!queryString) {
                setLoading(false);
                return;
            }

            api.get(`${endpoint}${queryString ? `?${queryString}` : ""}`)
            .then((response) => {
                if (!response.data)
                {
                    setData([]);
                } 
                else 
                {
                    setData(response.data);
                }

                setLoading(false);

                if (!opened)
                    setOpened(true);
            })
            .catch((error) => {
                console.log(error.response);
                setLoading(false);
            })
            ;
        }, 300)
    , [opened]);

    useEffect(() => {
        setLoading(true);
        setData([]);
        requestData(filter);
    }, [filter]);

    const onFilterChange = React.useCallback((event) => {
        setFilter(event.filter.value);
    }, []);

    const onChange = React.useCallback((event) => {
        setValue(event.value);
    }, [opened, textField]);

    const comboboxProps = useMemo(() => {
        const result = {
            data,
            value,
            onChange,
            keyField,
            textField,
            filterable: true,
            onFilterChange,
            ref: refInput,
            rounded: "null",
            size: "small",
            loading,
            opened,
            onOpen: () => setOpened(true),
            onClose: () => setOpened(false)
        }

        return result;
    }, [data, value, onChange, keyField, textField, onFilterChange, refInput, loading, opened, disabled]);

    return (
        <ComboBox {...comboboxProps}/>
    );
});

export default PositionEditor;
