////import React, { useState } from 'react';
import React, { useEffect, useState, useCallback, useMemo, useRef, Component } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import { useLocation, useParams, useHistory } from "react-router-dom";
import api from "../../../../services/api";
import qs from "qs";
import { SortsConverter, FiltersConverter } from "../../../../helpers"
import {
    Container, Row, Col, Button, Card, CardBody, CardTitle, UncontrolledTooltip, Progress, Label
} from "reactstrap";

//import { DefaultColumnFilter } from "../../../react-table/filters";
import { useTable, useFilters, useSortBy, useFlexLayout, useBlockLayout } from 'react-table';

import moment from "moment";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../../alerts";
import CopyToClipboard from "react-copy-to-clipboard";
import { TooltipButton } from '../../../inputs';

import fileDownload from "js-file-download";
import Loader from "../../../loaders";
import Grid from "../../../Grid";
//import DateFilter from "../../../date-filters"
//import { DatePicker } from '../../../date-pickers';
import { DefaultColumnFilter, DateColumnFilter } from "../../../react-table/filters";
import { formatDate, formatDateTime } from '../../../../utils';
import { IndeterminateCheckbox } from '../../../react-table/controls';
import { useSticky } from 'react-table-sticky';


import { ParagraphNotification } from '../../../alerts';




import EditorEmail from '../../Candidates/EditorEmail';
import SMSEditor from '../../Candidates/EditorSMS';
import ConfirmBigDownloadAlert from "../../../alerts/ConfirmBigDownloadAlert"
import { forEach } from 'lodash';
import { StripName } from '../../../../helpers/StringHelper';

const timeoutLength = 1500;

export default function TnaDRGrid(props) {
    //const { token } = useMsal();
    const history = useHistory();
    const [rawData, setRawData] = React.useState(null);
    const [data, setData] = React.useState([]);

    const params = useParams();
    const location = useLocation();
    const [copiedIndex, setCopiedIndex] = React.useState(null);
    React.useEffect(() => {
        if (copiedIndex !== null) {
            const timeoutId = setTimeout(() => {
                setCopiedIndex(null);
            }, timeoutLength);

            return () => clearTimeout(timeoutId);
        }
    }, [copiedIndex]);


    const [smsEditorData, setSmsEditorData] = useState(null);
    const [emailEditorData, setEmailEditorData] = useState(null);


    useState(() => {
        console.log('smsEditorData', smsEditorData);
    }, [smsEditorData]);

    useState(() => {
        console.log('emailEditorData', emailEditorData);
    }, [emailEditorData]);


    const [loading, setLoading] = React.useState(false);
    const [itemInEdit, setItemInEdit] = React.useState(null);
    const [createNew, setCreateNew] = React.useState(false);
    const [canAutoOpen, setCanAutoOpen] = React.useState(true);


    const [downloadAlert, setDownloadAlert] = useState([]);



    const [pageNumber, setPageNumber] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(50);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [deleteAlert, setDeleteAlert] = React.useState([]);
    const [lookups, setLookups] = React.useState({});
    const [filters, setFilters] = React.useState([]);

    const defaultSort = React.useMemo(() => [{ id: "createdDate", desc: true }],
        []
    );

    const [sortBy, setSortBy] = React.useState(defaultSort);
    const skipPageResetRef = React.useRef();
    const apiurl = "rostercandidate/tnadiscrepancyreport";


    function getTextWidth(text, font, size) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        context.font = font || getComputedStyle(document.body).font;

        return context.measureText(text).width;
    }


    // BEGIN PARAGRAPH NOTIFICATION VARIABLES
    const [pnMessage, setPnMessage] = React.useState(null);
    const [pnTitle, setPnTitle] = React.useState(null);
    const [paragraphNotification, setParagraphNotification] = React.useState([]);
    const [pnMessageOpening, setPnMessageOpening] = React.useState(``);
    const [pnTitleOnGenerate, setPnTitleOnGenerate] = React.useState(``);
    // END PARAGRAPH NOTIFICATION VARIABLES

    // BEGIN AG GRID VARS -------------------------------------------------------------------------------------------------------------------------

    const [versionCounter, setVersionCounter] = useState(0);
    const gridRef = useRef();
    const [gridOptions, setGridOptions] = useState(null);
    //const [serverSideInitialRowCount, setServerSideInitialRowCount] = useState(0);

    var groupHeaderHeight = 75;
    const [headerHeight, setHeaderHeight] = useState(150);
    var floatingFiltersHeight = 50;
    var pivotGroupHeaderHeight = 50;
    var pivotHeaderHeight = 100;

    // END AG GRID VARS ---------------------------------------------------------------------------------------------------------------------------

    // BEGIN AG GRID FUNCTIONS -------------------------------------------------------------------------------------------------------------------------

    const resizeColumns = useCallback(() => {
        if (!gridRef?.current?.columnApi || data?.length === 0)
            return;

        const allColumnIds = [];
        gridRef.current.columnApi.getColumns().forEach((column) => {
            // console.log(column, column.getId());
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }, [data, gridRef]);

    /*
    useEffect(() => {

        // Refresh datasource
        if (gridRef && gridRef.current && gridRef.current.api) {
            var datasource = getServerSideDatasource();
            gridRef.current.api.setServerSideDatasource(datasource);
        }



        // Refresh data
        refreshCache([]);

    }, [gridRef, params.action, params.requestId]);
    */

    const defaultColumns = [
        {
            name: "candidateName",
            title: "Candidate Name"
        },
        {
            name: "positionName",
            title: "Position Name"
        },
    ];

    const [agGridSort, setAgGridSort] = useState(['asc', 'desc']);

    const columnDefs = useMemo(() => {
        var newMap = defaultColumns.map((c) => {
            //var obj = {
            //    ...obj,
            //    field: c.name,
            //    headerName: c.title,
            //    editable: false,
            //    filter: !('filter' in c) ? 'agTextColumnFilter' : c.filter,
            //    floatingFilter: !('floatingFilter' in c) ? true : c.floatingFilter,
            //};
            var obj = {
                ...c,
                field: c.name,
                headerName: c.title,
                //editable: false,
                //filter: !('filter' in c) ? 'agTextColumnFilter' : c.filter,
                //floatingFilter: !('floatingFilter' in c) ? true : c.floatingFilter,
                //floatingFilterComponentParams: {
                //    suppressFilterButton: true,
                //},
            };

            if ('name' in obj) delete obj.name;
            if ('title' in obj) delete obj.title;

            //if ('pinned' in c) obj = { ...obj, pinned: c.pinned };
            //if ('valueFormatter' in c) obj = { ...obj, valueFormatter: c.valueFormatter };
            //if ('cellRenderer' in c) obj = { ...obj, cellRenderer: c.cellRenderer };
            //if ('cellRendererParams' in c) obj = { ...obj, cellRendererParams: c.cellRendererParams };
            //if ('additionalParams' in c) obj = { ...obj, additionalParams: c.additionalParams };
            //if ('cellClass' in c) obj = { ...obj, cellClass: c.cellClass };

            return obj;
        });



        if (rawData) {
            var excludeCol = ['candidate name', 'position'];
            var { trainingTitleColumns } = rawData;
            var colToAdd = trainingTitleColumns.filter(w => w && !excludeCol.some(e => e == w.toLowerCase())).map(v => ({
                field: StripName(v),
                headerName: v,
                headerClass: 'ag-vheader',
                width: '50px',
                valueFormatter: (params) => {
                    var { value } = params;
                    return value == "0" || value == "1" ? "-" : !value ? "" : moment(formatDateTime(value)).format("DD-MM-YYYY");
                },
                cellClass: (params) => {
                    var addonClass = 'text-center';
                    var { data, value } = params;

                    if (value == "0") {
                        return `pink ${addonClass}`;
                    } else if (value) {
                        return `cyan ${addonClass}`;
                    } else {
                        return ` ${addonClass}`;
                    }

                },
            }));

            newMap = newMap.concat(colToAdd);


            var longestText = newMap.map(v => v.headerName).reduce(
                function (a, b) {
                    return a.length > b.length ? a : b;
                }
            );

            var font = '10px Roboto'
            var height = getTextWidth(longestText, font);

            console.log('setHeaderHeight', 'height', font);
            setHeaderHeight(height);

        }

        return newMap;
    }, [rawData]);

    const defaultColDef = useMemo(() => ({
        //sortable: true,
        resizable: true,
        //filter: true,
        editable: false,

        filter: '',
        sortable: false,
        floatingFilter: false,

        //filter: 'agSetColumnFilter',
        //filter: 'agTextColumnFilter',
        //filterParams: {
        //    buttons: ['reset'],
        //    debounceMs: 200,
        //    excelMode: 'mac',
        //},
        //floatingFilter: true,
        //floatingFilterComponentParams: {
        //    suppressFilterButton: true,
        //},
        suppressMenu: true,
    }), []);

    const loadingOverlayComponent = useMemo(() => Loader, []);


    // END AG GRID FUNCTIONS ---------------------------------------------------------------------------------------------------------------------------


    React.useEffect(() => {
        if (params.action === "new")
            setCreateNew(true);
    }, [params.action])

    const loadData = React.useCallback((params2 = null) => {

        if (params2 && 'api' in params2) {
            var { api: api2 } = params2;
            api2.hideOverlay();
        }

        //console.log('params2', params2);


        const queryString = qs.stringify({ filters, sortBy }, { allowDots: true });
        skipPageResetRef.current = true;
        setItemInEdit(null);
        setCreateNew(false);
        setData([]);


        const queryObj = qs.parse(location.search, { ignoreQueryPrefix: true });
        // console.log(queryObj);
        if (!queryObj || !queryObj.dispatchUnitId)
            return;

        const candidateIds = queryObj?.candidateIds ? decodeURI(queryObj.candidateIds).split("-") : [];


        const url = `/${apiurl}?dispatchUnitId=${queryObj.dispatchUnitId}${queryObj.rosterId ? `&rosterId=${queryObj.rosterId}` : ""}`;
        setLoading(true);
        api.post(url, JSON.stringify(candidateIds))
            .then(({ data }) => {
                console.log('data from api', 'data', data);
                setRawData(data);
                //setData(data.data);
                //setTotalRecords(data.total);
            }).catch((error) => {
                setItemInEdit(null);
                setRawData([]);
                //setData([]);
                console.error("error: ", { error });
            }).finally(() => setLoading(false));
    }, [filters, sortBy, pageNumber, pageSize, params]);

    React.useEffect(() => {
        console.log("data", data);
        if (canAutoOpen && data && data.length === 1 && params.requestId && params.action && params.action === "joborder") {
            setItemInEdit(data[0]);
        }
    }, [canAutoOpen, data, params]);

    useEffect(() => {

        // Return if empty
        //console.log('useEffect', 'rawData', rawData);
        if (!rawData || !rawData.rows || !rawData.rows.length) {
            //console.log('fail case 1', 'rawData', rawData);
            setData([]);
            setTotalRecords(0);
            return;
        }


        // Assign variables
        var {
            projectName,
            rows,
            trainingTitleColumns
        } = rawData;


        // Return if empty
        if (!rows || !rows.length) {
            console.log('fail case 2');
            setData([]);
            setTotalRecords(0);
            return;
        }

        var newData = rows.map((v, i, a) => {
            var oneRow = {
                candidateName: v.candidateName,
                positionName: v.positionName,
                candidateId: v.columns && v.columns.length > 0 ? v.columns[0].candidateId : null
            }

            // If columns exists
            if (v.columns && v.columns.length) {

                var drawableColumns = v.columns.filter(w => w && w.skillTitle);

                forEach(drawableColumns, (oneCol, iCol, arrCol) => {
                    var colKey = StripName(oneCol.skillTitle);
                    //console.log('oneCol.skillTitle', oneCol.skillTitle);
                    //console.log('colKey', colKey);
                    oneRow[colKey] = oneCol.expiredDate ? oneCol.expiredDate : oneCol.result ? "1" : "0";
                });
            }

            return oneRow;
        });

        setTotalRecords(newData ? newData.length : 0);

        //console.log('newData result', newData);

        setData(newData);


    }, [rawData]);


    // BEGIN PARAGRAPH NOTIFICATION HANDLER --------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {

        if (!pnMessage) {
            setParagraphNotification([]);
            return;
        }

        var events = {
            onConfirm: () => {
                setPnMessage(null);
                setPnTitle(null);
            },
            message: pnMessage,
            title: pnTitle ?? pnTitleOnGenerate
        }
        setParagraphNotification([<ParagraphNotification {...events} />]);

    }, [pnMessage, pnTitle]);
    // END PARAGRAPH NOTIFICATION HANDLER -----------------------------------------------------------------------------------------------------------------------


    // On load
    useEffect(() => {


        const apiCalls = [
            api.get(`/lookup/messagesmasters`),
        ];

        Promise.all(apiCalls)
            .then((responses) => {
                setLookups({
                    messagesMaster: responses[0].data
                });
                loadData();
            })
            .catch((error) => {
                console.error("error: ", { error });
            })
            .finally(() => {
                setLoading(false);
            });
    }, [])



    return (
        <section className="main">
            {loading && <Loader />}
            {deleteAlert.length > 0 && deleteAlert}
            {downloadAlert.length > 0 && downloadAlert}
            {paragraphNotification.length > 0 && paragraphNotification}
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card className="no-transition">
                            <CardBody>
                                <CardTitle>
                                    {/*<h5 className="mb-0 text-center">
                                        TNA Discrepancy Report
                                    </h5>*/}
                                    <h6 className="mb-0">
                                        Project: {rawData?.projectName}
                                    </h6>
                                </CardTitle>

                                <Row>
                                    <Col sm={12}>
                                        <div className="ag-theme-alpine ag-grid ag-ignore-disabled-input ag-vertical-columns" style={{ width: '100%', height: 'calc(100vh - 200px)' }}>
                                            <AgGridReact
                                                ref={gridRef}
                                                gridOptions={gridOptions}
                                                //domLayout={'autoHeight'}
                                                rowData={data}
                                                columnDefs={columnDefs}
                                                defaultColDef={defaultColDef}
                                                loadingOverlayComponent={loadingOverlayComponent}
                                                //sideBar={sideBar}
                                                suppressColumnVirtualisation={false}
                                                suppressRowVirtualisation={false}
                                                debounceVerticalScrollbar={false}
                                                rowBuffer={20}
                                                headerHeight={30}
                                                groupHeaderHeight={30}
                                                rowHeight={30}
                                                floatingFiltersHeight={30}
                                                // readOnlyEdit={true}
                                                onRowDataUpdated={() => resizeColumns()}
                                                suppressDragLeaveHidesColumns={true}
                                                suppressColumnMoveAnimation={true}
                                                animateRows={false}
                                                //onCellEditRequest={onCellEditRequest}
                                                excelStyles={excelStyles()}
                                                //pagination={true}
                                                //paginationPageSize={pageSize}
                                                //cacheBlockSize={pageSize}
                                                //rowModelType={'serverSide'}
                                                //serverSideInfiniteScroll={true}
                                                //onGridReady={onGridReady}
                                                //sortingOrder={agGridSort}
                                                groupHeaderHeight={groupHeaderHeight}
                                                headerHeight={headerHeight}
                                                floatingFiltersHeight={floatingFiltersHeight}
                                                pivotGroupHeaderHeight={pivotGroupHeaderHeight}
                                                pivotHeaderHeight={pivotHeaderHeight}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </section>
    )

}


export const excelStyles = () => {
    const headerDefaultStyle = {
        alignment: {
            vertical: 'Center',
            horizontal: "Center",
        },
        interior: {
            color: '#f8f8f8',
            pattern: 'Solid',
            patternColor: undefined,
        },
        borders: {
            borderTop: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderRight: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderBottom: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderLeft: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
        },
        font: {
            fontName: 'Roboto',
            color: '#181d1f',
            bold: true,
            size: 12,
        },
    }

    return [
        {
            id: 'header',
            ...headerDefaultStyle
        },
        {
            id: 'date-header',
            ...headerDefaultStyle,
            alignment: {
                vertical: 'Center',
                horizontal: "Center",
                rotate: 90,
            },
        },
        {
            id: 'cell',
            alignment: {
                vertical: 'Top',
                wrapText: true,
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid',
                patternColor: undefined,
            },
            borders: {
                borderTop: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderRight: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderBottom: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderLeft: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
            },
            font: {
                fontName: 'Roboto',
                color: '#181d1f',
                weight: 700,
                size: 12,
            },
        },
    ];
};


