import React, { useEffect } from 'react';
import api from "../../../services/api";
import qs from "qs";
import {
   Container, Row, Col, Card, CardBody, CardTitle, FormGroup, Label
} from "reactstrap";
import { ComboBox } from "../../dropdowns";
import ProjectContractTemplateEditor from "./Editor";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../alerts";
import { TooltipButton } from '../../inputs';
import { DefaultColumnFilter } from "../../react-table/filters";
import { useTable, useExpanded, useFilters, useSortBy, useFlexLayout } from 'react-table';
import Loader from "../../loaders";
import Grid from "../../Grid";
import TemplateHelp from "../Maintenance/ContractTemplate/Help";
import { useParams } from 'react-router-dom';

export default function List(props) {
    const { projectId = 0 } = useParams();
    const [ selectedProject, setSelectedProject ] = React.useState(null);
    const [ data, setData ] = React.useState([]);
    const [ loading, setLoading ] = React.useState(false);
    const [ itemInEdit, setItemInEdit ] = React.useState(null);
    const [ filters, setFilters ] = React.useState([]);

    const defaultSort = React.useMemo(() => [{ id: "templateName", desc: false }],
        []
    );
    
    const [ sortBy, setSortBy ] = React.useState(defaultSort);

    const [ pageNumber, setPageNumber ] = React.useState(1);
    const [ pageSize, setPageSize ] = React.useState(10);
    const [ totalRecords, setTotalRecords ] = React.useState(0);
    const [ deleteAlert, setDeleteAlert ] = React.useState([]);
    const skipPageResetRef = React.useRef();
    const [ templateModalOpen, setTemplateModalOpen ] = React.useState(false);
    
    const apiurl = "projectcontracttemplate";
    
    const loadData = React.useCallback(() => {
        if(!selectedProject)
            return;

        const queryString = qs.stringify({filters, sortBy}, { allowDots: true });
        setItemInEdit(null);
        setData([]);
        skipPageResetRef.current = true;

        setLoading(true);
        api.get(`/${apiurl}/${selectedProject.projectId}/${(pageNumber - 1) * pageSize}/${pageSize}${queryString ? `?${queryString}` : ""}`)
        .then(({data}) => {
            setData(data.data ?? []);
            setTotalRecords(data.total);
        }).catch((error) => {
            console.error("error: ", error);
        }).finally(() => setLoading(false));
    }, [filters, sortBy, pageNumber, pageSize, selectedProject]);
    
    const handleDelete = (item) => {
        const success = () => {
            setLoading(false);
            setDeleteAlert([<DeleteSuccessAlert onConfirm={() => setDeleteAlert([])} />]);
            if (data.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1);
            } else {
                loadData();
            }
        };
        const failure = () => {
            setLoading(false);
            setDeleteAlert([<DeleteFailedAlert onConfirm={() => setDeleteAlert([])} />]);
        };
        const events = {
            onConfirm: () => {
                setLoading(true);
                api.delete(`/${apiurl}/${item.projectContractTemplateId}`).then(success).catch(failure);
            },
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert {...events} />])
    }

    React.useEffect(() => {
        if(selectedProject)
            loadData();
    }, [selectedProject, pageNumber, pageSize, filters, sortBy]);

    useEffect(() => {
        if (!projectId)
            return;

        console.log(projectId);
        setLoading(true);
        api.get(`/project/${projectId}`)
        .then(({data}) => {
            setLoading(false);
            setSelectedProject(data);
        }).catch((error) => {
            setLoading(false);
            console.error("error: ", error.response);
        });
    }, [projectId]);

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
                })
            },
        }),
        []
    )

    const columns = React.useMemo(() => [
        {
            Header: "Contract Template",
            id: "templateName",
            accessor: (row) => row.templateName,
            filter: "text",
            width: 300,
            disableSortBy: false,
        },
        {
            Header: "File Name",
            id: "fileName",
            accessor: (row) => row.templateFilename ?? row.contractTemplate.templateFilename,
            filter: "text",
            width: 300,
            disableSortBy: false,
        },
        {
            Header: "Actions",
            id: 'button',
            Cell: ( { row: { original } } ) =>
                (
                    <div>
                        <TooltipButton
                            id={`edit_${original.projectContractTemplateId}`}
                            title="Edit"
                            className="btn-icon"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => setItemInEdit(original)}
                        >
                            <i className="fas fa-pencil-alt pt-1"></i>
                        </TooltipButton>
                        <TooltipButton
                            id={`delete_${original.projectContractTemplateId}`}
                            title="Delete"
                            className="btn-icon"
                            color="warning"
                            size="sm"
                            type="button"
                            onClick={() => handleDelete(original)}
                        >
                            <i className="fas fa-trash-alt pt-1"></i>
                        </TooltipButton>
                    </div>
                ),
            width: 140,
        }]
    , [selectedProject]);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,       
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: defaultSort,
                canSort: true,
            },
            filterTypes,
            defaultColumn,
            manualSortBy: true,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
        useExpanded
    );
    
    React.useEffect(() => {
        setFilters(state.filters);
    }, [state.filters]);
    
    React.useEffect(() => {
        setSortBy(state.sortBy);
    }, [state.sortBy]);

    return (
        <section className="main">
            {loading && <Loader />}
            {deleteAlert.length > 0 && deleteAlert}
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card className="no-transition">
                            <CardBody>
                                <CardTitle>
                                    <h4 className="text-center">Project Contract Templates</h4>                                    
                                </CardTitle>
                                <Container fluid className="px-0">
                                    <Row noGutters>
                                        <Col sm={12} md={{ size: 4, offset: 4 }}>
                                            <FormGroup>
                                                <Label>
                                                    Project
                                                </Label>
                                                <ComboBox 
                                                    endpoint="/project/search"
                                                    isLookup={false}
                                                    idField="projectId"
                                                    valueField="projectName"
                                                    selectedItem={selectedProject}
                                                    onChange={setSelectedProject}
                                                    minLength={0}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12}>
                                            {
                                                // (totalRecords === 0) &&
                                                <TooltipButton
                                                    id="addnew"
                                                    title="Add New"
                                                    className="btn-icon ml-2 mb-2"
                                                    color="default"
                                                    size="sm"
                                                    type="button"
                                                    disabled={!selectedProject}
                                                    onClick={() => {  
                                                        setItemInEdit({
                                                            projectId: selectedProject.projectId
                                                        });
                                                    }}
                                                >
                                                    <i className="fas fa-plus pt-1"></i>
                                                </TooltipButton>
                                            }
                                            <TooltipButton
                                                id="templatehelp"
                                                title="Template Help"
                                                className="btn-icon ml-2 mb-2"
                                                color="default"
                                                size="sm"
                                                type="button"
                                                disabled={!selectedProject}
                                                onClick={() => {
                                                    setTemplateModalOpen(true);
                                                }}
                                            >
                                                <i className="fas fa-question pt-1"></i>
                                            </TooltipButton>
                                        </Col>
                                        <Col xs={12}>
                                            <Grid
                                                height='calc(100vh - 552px)'
                                                totalRecords={totalRecords}
                                                pageSize={pageSize}
                                                rows={rows}
                                                tableProps={getTableProps()}
                                                headerGroups={headerGroups}
                                                tableBodyProps={getTableBodyProps()}
                                                prepareRow={prepareRow}
                                                onPagerChangePage={(pager) => {
                                                    setPageNumber(pager.currentPage);
                                                }}
                                                onPagerChangePageSize={(size) => {
                                                    setPageSize(size);
                                                }}
                                                needPaging={true}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                                {
                                    !!itemInEdit && 
                                    <ProjectContractTemplateEditor
                                        title={`${selectedProject.projectName} Contract Template`}
                                        item={itemInEdit} 
                                        onClose={() => setItemInEdit(null)} 
                                        onSaved={() => loadData()}
                                    />
                                }
                                {
                                    !!selectedProject &&
                                    <TemplateHelp 
                                        open={templateModalOpen} 
                                        onClose={() => setTemplateModalOpen(false)}
                                        projectId={selectedProject.projectId}
                                    />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    )

}