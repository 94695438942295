/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-throw-literal */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Button, Col, Row } from 'reactstrap';
import api from "../../../../services/api";
import Loader from "../../../loaders";
import qs from "qs";
import { Container } from 'reactstrap';
import Grid from '../../../Grid';
import { useTable, useRowSelect, useFlexLayout, useSortBy } from 'react-table';
import { IndeterminateCheckbox } from '../../../react-table/controls';
import moment from "moment";
import SweetAlert from 'react-bootstrap-sweetalert';
import EditorSMS from '../../Candidates/EditorSMS';
import { DropdownBox } from "../../../dropdowns";
import { Collapse } from "reactstrap"
import _ from 'lodash';
import { ErrorNotification } from '../../../alerts';
import { BulkConfirmationMode } from '../../../../utils'
import { TooltipButton } from "../../../inputs";
import { MakeWords } from '../../../../helpers/StringHelper';

export default function BulkAvailabilityConfirmation(props) {
    const {
        project,
        dispatchUnits,
        dispatchUnitData = [],
        rosters,
        rosterCandidates,
        selected,
        onClose,
        onSaved,
        confirmationMessage,
        singleSelection,
        customConfirm,
        lookups,
        bulkConfirmationMode = BulkConfirmationMode.AVAILABILITY
    } = props;

    const [loading, setLoading] = React.useState(false);
    const [title, setTitle] = useState(null);
    const [failedMessages, setFailedMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState(confirmationMessage);
    const [currentMessage2, setCurrentMessage2] = useState(confirmationMessage);
    const [dataIsEmpty, setDataIsEmpty] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [candidates, setCandidates] = useState([]);
    const [selectedCandidates2, setSelectedCandidates2] = useState([]);
    const [selectedSourceObject2, setSelectedSourceObject2] = useState([]);
    const [attributes, setAttributes] = React.useState([]);
    const [isEstimation, setIsEstimation] = useState(false);
    const [estimationMessage, setEstimationMessage] = useState(null);
    const invalidLogisticMsg = `One or more employees have no mobe, demobe, and accomodation`;
    const invalidDateMsg = `One or more employees have invalid start/end date`;
    const invalidNameMsg = `One or more employees have invalid name or mobile phone number`;
    const invalidRosterPos = "Please enter Roster Position for slot no.";
    //const [bulkConfirmationMode, setBulkConfirmationMode] = React.useState(BulkConfirmationMode.Accomodation);

    // Datatable step 1
    const [data, setData] = useState([]);
    const [selectedStartEndDateList, setSelectedStartEndDateList] = useState([]);
    const [step, setStep] = useState(1);
    const [initialSelectedRows, setInitialSelectedRows] = useState([]);
    const [validationMessage, setValidationMessage] = useState(null);
    const defaultSort = React.useMemo(() => [{ id: "slotNo", desc: false }],
        []
    );

    // Datatable step 2
    const [dataSms, setDataSms] = useState([]);
    const [showModalSms, setShowModalSms] = useState(false);

    // Error message variables
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorTitle, setErrorTitle] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);
    const errorMessageOpening = `Error occured when processing the request: `;
    const errorTitleOnGenerate = `Bulk Confirmation Error`;

    const loadData = useCallback(() => {
        try {
            // console.log("{dispatchUnits, rosters, rosterCandidates}", { dispatchUnits, rosters, rosterCandidates });
            const refType = rosterCandidates && rosterCandidates.length > 0 ? "RC" : (rosters && rosters.length > 0 ? "R" : (dispatchUnits && dispatchUnits.length > 0 ? "D" : ""));

            // console.log('refType', refType);

            if (refType === "")
                return;

            const refIds = [];

            if (refType === "RC")
                rosterCandidates.forEach(rc => {
                    refIds.push(rc.rosterCandidateId);
                });

            if (refType === "R")
                rosters.forEach(rc => {
                    refIds.push(rc.rosterId);
                });

            if (refType === "D")
                dispatchUnits.forEach(rc => {
                    refIds.push(rc.dispatchUnitId);
                });

            if (refIds.length === 0)
                return;

            setLoading(true);

            const refIdStr = qs.stringify({ refIds }, { allowDots: true });

            const apiCalls = [
                api.get(`rostercandidateschedule/waiting-schedules-per-candidate?reftype=${refType}&${refIdStr}`)
            ];

            if (refType === "RC")
                apiCalls.push(api.get(`rostercandidate/candidates?${refIdStr}`));

            if (refType === "R")
                apiCalls.push(api.get(`roster/candidates?${refIdStr}`));

            if (refType === "D")
                apiCalls.push(api.get(`dispatchunit/candidates?${refIdStr}`));
            
            apiCalls.push(api.get(`/messagesmaster?${qs.stringify({ filters: [{ id: "title", value: "Estimate Availability" }] }, { allowDots: true })}`));

            Promise.all(apiCalls)
                .then((response) => {
                    // console.log("response", response);
                    const newData = response[0].data && response[0].data !== "" ? [...response[0].data] : [];
                    setData(newData);
                    setDataIsEmpty(!response[0].data || response[0].data === "");
                    let currentTitle = " - Multiple";

                    // Set selected date by default
                    if (newData) {
                        const selectedList = [];
                        newData.forEach((oneRc, i, a) => {

                            // console.log("oneRc", oneRc);

                            if (oneRc.startEndDateList.length)
                                selectedList.push(oneRc.startEndDateList[0]);

                        });

                        // console.log("selectedList", selectedList);
                        setSelectedStartEndDateList(selectedList);
                    }

                    if (dispatchUnits && dispatchUnits.length) {
                        currentTitle = ` - ${dispatchUnits.length === 1 ? dispatchUnits[0].dispatchUnitName : "Multiple"}`;

                        if (rosters && rosters.length)
                            currentTitle = `${currentTitle} - ${rosters.length === 1 ? rosters[0].rosterName : "Multiple"}`;
                    }

                    // console.log(currentTitle);
                    setTitle(currentTitle);

                    if (rosterCandidates && rosterCandidates.length) {
                        let currentCandidateTitle = "";

                        rosterCandidates.forEach(x => {
                            currentCandidateTitle = `${currentCandidateTitle}${currentCandidateTitle !== "" ? "," : ""} ${x.candidateName} - ${x.positionName}`;
                        });
                    }

                    if (refType === "RC" || refType === "R" || refType === "D") {
                        const newCandidates = [];

                        response[1].data.filter(rc => rc.candidateId).forEach(rc => {
                            if (!newData.length)
                                return;

                            newData.filter(d => d.rosterCandidateId === rc.rosterCandidateId).forEach(d => {
                                newCandidates.push({
                                    ...rc.candidate,
                                    rosterCandidateId: rc.rosterCandidateId,
                                    rosterCandidateScheduleIds: d.rosterCandidateScheduleIds,
                                    currentProjectName: project.projectName,
                                    dispatchUnitId: rc.roster?.dispatchUnitId,
                                    dispatchUnitName: rc.roster?.dispatchUnit?.dispatchUnitName,
                                    rosterId: rc.rosterId,
                                    rosterName: rc.roster?.rosterName,
                                    locationId: rc.roster?.dispatchUnit?.locationId,
                                    locationName: rc.roster?.dispatchUnit?.location?.lookupValue,
                                    startDate: moment(d.startDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
                                    endDate: moment(d.endDate, "YYYY-MM-DD").format("DD-MM-YYYY")
                                })
                            });
                        });

                        setCandidates([...newCandidates]);
                        setEstimationMessage(response[2].data.data[0]);
                        return;
                    }
                
                    setEstimationMessage(response[1].data.data[0]);
                })
                .catch((error) => {
                    setErrorMessage(`${errorMessageOpening}${error.response.data}`);
                    console.error({error});
                })
                .finally(() => {
                    setLoading(false);
                    setFirstLoad(false);
                });


            const apiCallsMessage = [
                api.get(`messagesmaster/messageattributes`),
            ];
            apiCallsMessage.push(api.get(`projectAttribute/search`));
            apiCallsMessage.push(api.get(`rosterAttribute/search`));

            Promise.all(apiCallsMessage)
                .then((responses) => {
                    // console.log('responses of set attributes', 'responses', responses);
                    setAttributes([...responses[0]?.data.map(d => ({ ...d, hidden: (d.name === "{RosterCandidateSchedule.StartDate}" || d.name === "{RosterCandidateSchedule.EndDate}") })), ...responses[1]?.data?.map(m => ({ name: `{${m.projectAttributeName}}`, category: "Project Attribute", hidden: false })), ...responses[2]?.data.map(m => ({ name: `{${m.rosterAttributeName}}`, category: "Roster Attribute", hidden: false }))]);
                })
                .catch((error) => {
                    setErrorMessage(`${errorMessageOpening}${error.response.data}`);
                    console.error(error);
                })
                .finally(() => {
                    //setLoading(false)
                });
        }
        catch (error) {
            setLoading(false);
            setErrorMessage(`${errorMessageOpening}${error.message}`);
        }

    }, [project, dispatchUnits, rosters, rosterCandidates, attributes]);


    // Function to send all sms
    const handleSend = useCallback(() => {
        try {
            setLoading(true);

            // Send data api
            const sendData = [...dataSms];

            // Prepare list hit api
            const apiSendSms = [];


            sendData.forEach((oneData) => {

                try {

                    // Prepare flatten
                    let flightIds = [];
                    let rosterCandidateAccommodationIds = [];

                    // Copy array
                    const selectedSchedules = [...sendData.filter(w => w.rosterCandidateId == oneData.rosterCandidateId).map(w => ({...w, isEstimation}))];
                    var selectedDate = {};

                    // Clear junk data before send
                    // And Add more data
                    selectedSchedules.forEach((oneSch) => {

                        // Remove junk data
                        if ('startEndDateList' in oneSch && oneSch.startEndDateList) {
                            oneSch.startEndDateList = [];
                        }

                        // Add flightids and rosterCandidateAccommodationIds
                        selectedDate = selectedStartEndDateList.find(w => w.rosterCandidateId == oneData.rosterCandidateId);
                        if (selectedDate) {
                            oneSch.flightIds = selectedDate.flightIds;
                            oneSch.rosterCandidateAccommodationIds = selectedDate.rosterCandidateAccommodationIds;

                            flightIds = flightIds.concat(selectedDate.flightIds);
                            rosterCandidateAccommodationIds = rosterCandidateAccommodationIds.concat(selectedDate.rosterCandidateAccommodationIds);
                        }
                        else {
                            oneSch.flightIds = [];
                            oneSch.rosterCandidateAccommodationIds = [];
                        }

                    });

                    // console.log('selectedSchedules', {selectedSchedules, flightIds});

                    // Add additional params
                    var refIds_ = selectedSchedules.map((w) => w.rosterCandidateId);
                    var showYesNo = false;
                    var showComment = false;

                    if ('showYesNo' in oneData.message)
                        showYesNo = oneData.message.showYesNo;

                    if ('showComment' in oneData.message)
                        showComment = oneData.message.showComment;


                    // Body to send to server
                    const body = {
                        refType: "RosterCandidate",
                        refIds: refIds_,
                        flightIds: flightIds,
                        schedules: selectedSchedules,
                        message: encodeURI(oneData.message.message),
                        messagesMasterId: oneData.message?.lookupId,
                        showYesNo: showYesNo,
                        showComment: showComment,
                        startDate: selectedDate?.startDate,
                        endDate: selectedDate?.endDate,
                        rosterCandidateAccommodationIds,
                    };

                    // console.log({body});

                    var submit = null;

                    if (bulkConfirmationMode == BulkConfirmationMode.AVAILABILITY) {
                        apiSendSms.push(api.post(`rostercandidateaccommodation/confirm`, body));
                    } else if (bulkConfirmationMode == BulkConfirmationMode.LOGISTIC) {
                        apiSendSms.push(api.post(`rostercandidateaccommodation/accommodation-confirm`, body));
                    } else {
                        throw 'Error sending message: Unknown bulkConfirmationMode';
                    }
                }
                catch (error) {
                    setLoading(false);
                    console.error(error);
                    setErrorMessage(`${errorMessageOpening}${error.message}`);

                }
            });

            // console.log('apiSendSms', apiSendSms);

            Promise.all(apiSendSms)
                .then((responses) => {
                    // console.log('apiSendSms', 'responses', responses);
                    const fails = responses.filter(r => r.data.failed.length).map(r => r.data.failed);

                    if (!fails.length)
                    {
                        setLoading(false);
                        onSaved();
                        return;
                    }

                    const resultFailedMessages = [];

                    fails.forEach(fail => {
                        fail.forEach(item => resultFailedMessages.push(item));
                    });

                    setFailedMessages([...resultFailedMessages]);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setErrorMessage(`${errorMessageOpening}${error.message}`);
                    setLoading(false);
                });
        }
        catch (error) {
            setErrorMessage(`${errorMessageOpening}${error.message}`);
            console.log(error);
            setLoading(false);
        }
    }, [data, loading, dataSms, selectedStartEndDateList, errorMessageOpening, onSaved, isEstimation]);


    useEffect(() => {
        if (!project)
            return;

        if ((!dispatchUnits || dispatchUnits.length === 0) && (!rosters || rosters.length === 0) && (!rosterCandidates || rosterCandidates.length === 0))
            return;

        loadData();
    }, [project, dispatchUnits, rosters, rosterCandidates]);
    useEffect(() => {
        //console.log('dispatchUnitData', dispatchUnitData);
    }, [dispatchUnitData]);
    useEffect(() => {
        //console.log('selectedStartEndDateList', selectedStartEndDateList);
        if (data && selectedStartEndDateList && selectedStartEndDateList.length > 0) {
            selectedStartEndDateList.forEach((oneDate) => {


                var oneData = data.find(w => w && oneDate && w.rosterCandidateId == oneDate.rosterCandidateId);
                if (!oneData)
                    return;
                oneData.rosterCandidateScheduleStartDate = oneDate.startDateFormatted;
                oneData.rosterCandidateScheduleEndDate = oneDate.endDateFormatted;
                oneData.startDate = oneDate.startDate;
                oneData.endDate = oneDate.endDate;
            });
        }
    }, [selectedStartEndDateList]);


    const updateSelectedStartEndDate = (rosterCandidateId, item) => {

        const selectedList = [...selectedStartEndDateList];
        const i = selectedList.findIndex(w => w && w.rosterCandidateId == rosterCandidateId);
        
        if (i != -1)
            selectedList.splice(i, 1);

        // If item not null
        if (item)
            selectedList.push(item);

        //setSelectedStartEndDateList('a');
        // console.log({item, selectedList});
        setSelectedStartEndDateList(selectedList);

    }

    // BEGIN TABLE CHOOSE DATE ------------------------------------------------------------------------------------------------------------------------

    const columns = useMemo(() => {
        try {
            if (bulkConfirmationMode == BulkConfirmationMode.AVAILABILITY) {

                return [
                    {
                        id: 'slotNo',
                        Header: "Slot",
                        width: 50,
                        disableSortBy: false,
                        Cell: ({ row: { original } }) => {
                            const selectedStartEndDate = selectedStartEndDateList?.filter(w => w)?.find(w => w?.rosterCandidateId == original?.rosterCandidateId);
                            const hasWorkSchedule = original.startEndDateList?.filter(d => d.rosterCandidateId === selectedStartEndDate?.rosterCandidateId && d.startEndHasWorkSchedule).length > 0;
                            const slotNo = `${!hasWorkSchedule ? "*" : ""}${original.slotNo}`;

                            return (
                                <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                                    <span className="text-left ml-2">{slotNo}</span>
                                </div>
                            );
                        },
                    },
                    {
                        id: 'rosterCandidateId',
                        Header: "Roster Candidate ID",
                        accessor: (row) => row.rosterCandidateId,
                        disableSortBy: false,
                    },
                    {
                        id: 'candidateName',
                        Header: "Employee Name",
                        accessor: (row) => !row || !row.candidate ? 'N/A' : row.candidate.candidateName,
                        disableSortBy: false,
                    },
                    {
                        id: 'mobilePhoneNumber',
                        Header: "Mobile Number",
                        accessor: (row) => !row || !row.candidate ? 'N/A' : row.candidate.mobilePhoneNumber,
                        disableSortBy: false,
                    },
                    {
                        id: 'startDate',
                        Header: "Start Date",
                        disableSortBy: false,
                        accessor: (row) => {
                            // Return N/A if not available
                            if (!row.startEndDateList || !row.startEndDateList.some(w => w) || !selectedStartEndDateList)
                                return 'N/A';

                            // Return value if available
                            var selectedDate = selectedStartEndDateList.filter(w => w).find(w => w?.rosterCandidateId == row?.rosterCandidateId);
                            return !selectedDate ? 'N/A' : selectedDate.startDateFormatted;
                        },
                        Cell: ({ row }) => {
                            const { original, values } = row;
                            // console.log("render dropdown", {data: original.startEndDateList, selectedStartEndDateList});

                            // Return N/A if not available
                            if (!original.startEndDateList || !original.startEndDateList.some(w => w) || !selectedStartEndDateList)
                                return (<>N/A</>);

                            const selectedStartEndDate = selectedStartEndDateList.filter(w => true).find(w => w?.rosterCandidateId == original?.rosterCandidateId);
                            // console.log({selectedStartEndDate, originalData: original.startEndDateList});
                            // Return list if available
                            return (<div>

                                <DropdownBox
                                    data={original.startEndDateList ?? []}
                                    /*selectedItem={selectedStartEndDateList[original.rosterCandidateId]}*/
                                    selectedItem={selectedStartEndDate}
                                    // selectedItem={original.startEndDateList.filter()}
                                    valueField={'startDateFormatted'}
                                    idField={'startEndGroupId'}
                                    onChange={(item) => {
                                        updateSelectedStartEndDate(original?.rosterCandidateId, item);
                                        // console.log('DropdownBox on change selectedStartEndDateList', 'item', item);
                                    }}
                                />
                            </div>);

                        },
                    },
                    {
                        id: 'endDate',
                        Header: "End Date",
                        accessor: (row) => {

                            // Return N/A if not available
                            if (!row.startEndDateList || !row.startEndDateList.some(w => w) || !selectedStartEndDateList)
                                return 'N/A';

                            // Return value if available
                            var selectedDate = selectedStartEndDateList.filter(w => w).find(w => w?.rosterCandidateId == row?.rosterCandidateId);
                            return !selectedDate ? 'N/A' : selectedDate.endDateFormatted;
                        },
                        disableSortBy: false,
                    },
                    {
                        id: 'workDays',
                        Header: "Workdays",
                        disableSortBy: false,
                        Cell: ({ row: { original } }) => {
                            const selectedStartEndDate = selectedStartEndDateList?.filter(w => w)?.find(w => w?.rosterCandidateId == original?.rosterCandidateId);

                            const workDayText = (!original.startEndDateList || !original.startEndDateList.some(w => w) || !selectedStartEndDate) ? "N/A"
                                :
                                selectedStartEndDate.workDays;

                            const inactiveDateExists = original.startEndDateList.filter(d => d.rosterCandidateId === selectedStartEndDate?.rosterCandidateId && d.inactiveDateExists).length > 0;

                            return (
                                <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                                {
                                    <span className={`text-left ml-2${workDayText !== "N/A" && inactiveDateExists ? " text-danger" : ""}`}>
                                    {
                                        workDayText
                                    }
                                    {
                                        workDayText !== "N/A" && inactiveDateExists ? "**" : ""
                                    }
                                    </span>
                                }
                                </div>
                            );
                        },
                    },
                    {
                        id: 'status',
                        Header: "Status",
                        accessor: (row) => {
                            const selectedStartEndDate = selectedStartEndDateList?.filter(w => w)?.find(w => w?.rosterCandidateId == row?.rosterCandidateId);
                            // console.log({row, selectedStartEndDate});
                            return selectedStartEndDate?.availabilityStatus ?? "N/A";
                        },
                        disableSortBy: false,
                    },
                    {
                        id: 'estimateStatus',
                        Header: "Estimate Status",
                        accessor: (row) => {
                            const selectedStartEndDate = selectedStartEndDateList?.filter(w => w)?.find(w => w?.rosterCandidateId == row?.rosterCandidateId);
                            // console.log({row, selectedStartEndDate});
                            return selectedStartEndDate?.estimateAvailabilityStatus ?? "N/A";
                        },
                        disableSortBy: false,
                    },
                ];
            }
            else if (bulkConfirmationMode == BulkConfirmationMode.LOGISTIC) {

                return [
                    {
                        id: 'slotNo',
                        Header: "Slot",
                        width: 50,
                        disableSortBy: false,
                        Cell: ({ row: { original } }) => {
                            const selectedStartEndDate = selectedStartEndDateList?.filter(w => w)?.find(w => w?.rosterCandidateId == original?.rosterCandidateId);
                            const hasWorkSchedule = original.startEndDateList?.filter(d => d.rosterCandidateId === selectedStartEndDate?.rosterCandidateId && d.startEndHasWorkSchedule).length > 0;
                            const slotNo = `${!hasWorkSchedule ? "*" : ""}${original.slotNo}`;

                            return (
                                <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                                    <span className="text-left ml-2">{slotNo}</span>
                                </div>
                            );
                        },
                    },
                    {
                        id: 'rosterCandidateId',
                        Header: "Roster Candidate ID",
                        accessor: (row) => row.rosterCandidateId,
                        disableSortBy: false,
                    },
                    {
                        id: 'candidateName',
                        Header: "Employee Name",
                        accessor: (row) => !row || !row.candidate ? 'N/A' : row.candidate.candidateName,
                        disableSortBy: false,
                    },
                    {
                        id: 'mobilePhoneNumber',
                        Header: "Mobile Number",
                        accessor: (row) => !row || !row.candidate ? 'N/A' : row.candidate.mobilePhoneNumber,
                        disableSortBy: false,
                    },
                    {
                        id: 'startDate',
                        Header: "Start Date",
                        disableSortBy: false,
                        accessor: (row) => {

                            // Return N/A if not available
                            if (!row.startEndDateList && !row.startEndDateList.some(w => w) || !selectedStartEndDateList)
                                return 'N/A';

                            // Return value if available
                            var selectedDate = selectedStartEndDateList.filter(w => w).find(w => w?.rosterCandidateId == row?.rosterCandidateId);
                            return !selectedDate ? 'N/A' : selectedDate.startDateFormatted;
                        },
                        Cell: ({ row }) => {
                            const { original, values } = row;


                            // Return N/A if not available
                            if (!original.startEndDateList || !original.startEndDateList.some(w => w) || !selectedStartEndDateList)
                                return (<>N/A</>);

                            // Return list if available
                            return (<div>

                                <DropdownBox
                                    data={original.startEndDateList ?? []}
                                    /*selectedItem={selectedStartEndDateList[original.rosterCandidateId]}*/
                                    selectedItem={selectedStartEndDateList.filter(w => true).find(w => w?.rosterCandidateId == original?.rosterCandidateId)}
                                    valueField={'startDateFormatted'}
                                    idField={'startEndGroupId'}
                                    onChange={(item) => {
                                        updateSelectedStartEndDate(original.rosterCandidateId, item);
                                        // console.log('DropdownBox on change selectedStartEndDateList', 'item', item);
                                    }}
                                />
                            </div>);

                        },
                    },
                    {
                        id: 'endDate',
                        Header: "End Date",
                        accessor: (row) => {

                            // Return N/A if not available
                            if (!row.startEndDateList || !row.startEndDateList.some(w => w) || !selectedStartEndDateList)
                                return 'N/A';

                            // Return value if available
                            var selectedDate = selectedStartEndDateList.filter(w => w).find(w => w?.rosterCandidateId == row?.rosterCandidateId);
                            return !selectedDate ? 'N/A' : selectedDate.endDateFormatted;
                        },
                        disableSortBy: false,
                    },
                    {
                        id: 'workDays',
                        Header: "Workdays",
                        disableSortBy: false,
                        Cell: ({ row: { original } }) => {
                            const selectedStartEndDate = selectedStartEndDateList?.filter(w => w)?.find(w => w?.rosterCandidateId == original?.rosterCandidateId);

                            const workDayText = (!original.startEndDateList || !original.startEndDateList.some(w => w) || !selectedStartEndDate) ? "N/A"
                                :
                                selectedStartEndDate.workDays;

                            const inactiveDateExists = original.startEndDateList.filter(d => d.rosterCandidateId === selectedStartEndDate?.rosterCandidateId && d.inactiveDateExists).length > 0;

                            return (
                                <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                                {
                                    <span className={`text-left ml-2${workDayText !== "N/A" && inactiveDateExists ? " text-danger" : ""}`}>
                                    {
                                        workDayText
                                    }
                                    {
                                        workDayText !== "N/A" && inactiveDateExists ? "**" : ""
                                    }
                                    </span>
                                }
                                </div>
                            );
                        },
                    },
                    {
                        id: 'mobe',
                        Header: "Mobe",
                        accessor: (row) => {

                            // Return N/A if not available
                            if (!row.startEndDateList && !row.startEndDateList.some(w => w))
                                return 'N/A';

                            // Return value if available
                            var mobe = !selectedStartEndDateList ? null : selectedStartEndDateList.filter(w => w).find(w => w?.rosterCandidateId == row?.rosterCandidateId)?.mobe;
                            if (mobe == null)
                                return 'N/A';

                            var flightNumberPrefix = !mobe.flightNumber ? `` : `${mobe.flightNumber} : `;

                            var fromDate = !mobe.flightDateTime ? "" : moment(mobe.flightDateTime, "YYYY-MM-DDTHH:mm:ss").format('DD/MM/YYYY');
                            var fromTime = !mobe.flightDateTime || !mobe.arrivalTime ? "" : moment(mobe.flightDateTime, "YYYY-MM-DDTHH:mm:ss").format('HH:mm');
                            var toTime = !mobe.arrivalTime ? "" : moment(mobe.arrivalTime, "YYYY-MM-DDTHH:mm:ss").format('HH:mm');

                            var fromAirport = !mobe.flightFrom || !mobe.flightFrom.airportName ? "" : mobe.flightFrom.airportName;
                            var toAirport = !mobe.flightTo || !mobe.flightTo.airportName ? "" : mobe.flightTo.airportName;

                            var fromTerminal = !mobe.airportTerminal || !mobe.airportTerminal.terminalName ? "" : mobe?.airportTerminal.terminalName;
                            var toTerminal = !mobe.arrivalTerminal || !mobe.arrivalTerminal.terminalName ? "" : mobe?.arrivalTerminal.terminalName;

                            const flighInfo = (fromAirport && fromTime && toAirport && toTime) ? `${fromAirport} ${fromTerminal} ${fromTime} - ${toAirport} ${toTerminal} ${toTime}` : ``

                            var text = `${flightNumberPrefix}${fromDate} ${flighInfo}`;


                            while (text.includes("  "))
                                text = text.replace("  ", " ");

                            return text;
                        },
                        disableSortBy: false,
                    },
                    {
                        id: 'demobe',
                        Header: "Demobe",
                        accessor: (row) => {

                            // Return N/A if not available
                            if (!row.startEndDateList && !row.startEndDateList.some(w => w))
                                return 'N/A';

                            // Return value if available
                            var mobe = !selectedStartEndDateList ? null : selectedStartEndDateList.filter(w => w).find(w => w?.rosterCandidateId == row.rosterCandidateId)?.demobe;
                            if (mobe == null)
                                return 'N/A';

                            var flightNumberPrefix = !mobe.flightNumber ? `` : `${mobe.flightNumber} : `;

                            var fromDate = !mobe.flightDateTime ? "" : moment(mobe.flightDateTime, "YYYY-MM-DDTHH:mm:ss").format('DD/MM/YYYY');
                            var fromTime = !mobe.flightDateTime || !mobe.arrivalTime ? "" : moment(mobe.flightDateTime, "YYYY-MM-DDTHH:mm:ss").format('HH:mm');
                            var toTime = !mobe.arrivalTime ? "" : moment(mobe.arrivalTime, "YYYY-MM-DDTHH:mm:ss").format('HH:mm');

                            var fromAirport = !mobe.flightFrom || !mobe.flightFrom.airportName ? "" : mobe.flightFrom.airportName;
                            var toAirport = !mobe.flightTo || !mobe.flightTo.airportName ? "" : mobe.flightTo.airportName;

                            var fromTerminal = !mobe.airportTerminal || !mobe.airportTerminal.terminalName ? "" : mobe?.airportTerminal.terminalName;
                            var toTerminal = !mobe.arrivalTerminal || !mobe.arrivalTerminal.terminalName ? "" : mobe?.arrivalTerminal.terminalName;
                            
                            const flighInfo = (fromAirport && fromTime && toAirport && toTime) ? `${fromAirport} ${fromTerminal} ${fromTime} - ${toAirport} ${toTerminal} ${toTime}` : ``

                            var text = `${flightNumberPrefix}${fromDate} ${flighInfo}`;


                            while (text.includes("  "))
                                text = text.replace("  ", " ");

                            return text;
                        },
                        disableSortBy: false,
                    },
                    {
                        id: 'accomodation',
                        Header: "Accomodation",
                        accessor: (row) => {

                            // Return N/A if not available
                            if (!row.startEndDateList && !row.startEndDateList.some(w => w))
                                return 'N/A';

                            // Return value if available
                            var rcAccommodation = !selectedStartEndDateList ? null : selectedStartEndDateList.filter(w => w).find(w => w?.rosterCandidateId == row.rosterCandidateId)?.rcAccommodation;
                            if (rcAccommodation == null)
                                return 'N/A';

                            var accomodationName = !rcAccommodation.accommodation ? "N/A" : rcAccommodation.accommodation.accommodationName;
                            var startDate = !rcAccommodation.startDate ? "N/A" : moment(rcAccommodation.startDate, "YYYY-MM-DDT00:00:00").format('DD/MM/YYYY');
                            var endDate = !rcAccommodation.endDate ? "N/A" : moment(rcAccommodation.endDate, "YYYY-MM-DDT00:00:00").format('DD/MM/YYYY');
                            var text = `${accomodationName}`;
                            if (rcAccommodation.startDate || rcAccommodation.endDate) text += ` : ${startDate} - ${endDate}`;

                            // console.log('rcAccommodation', rcAccommodation);

                            return text;
                        },
                        disableSortBy: false,
                    },
                    {
                        id: 'status',
                        Header: "Status",
                        accessor: (row) => {
                            const selectedStartEndDate = selectedStartEndDateList?.filter(w => w)?.find(w => w?.rosterCandidateId == row?.rosterCandidateId);
                            // console.log({row, selectedStartEndDate});
                            return selectedStartEndDate?.availabilityStatus ?? "N/A";
                        },
                        disableSortBy: false,
                    },
                ];
            }
            else {
                throw 'Unknown bulkConfirmationMode, showing default columns instead'
            }
        }
        catch (error) {
            setErrorMessage(`${errorMessageOpening}${error.message}`);
            console.log(error);
            setLoading(false);


            return [
                {
                    id: 'slotNo',
                    Header: "Slot",
                    accessor: (row) => row.slotNo,
                    width: 50,
                    disableSortBy: false,
                },
                {
                    id: 'rosterCandidateId',
                    Header: "Roster Candidate ID",
                    accessor: (row) => row.rosterCandidateId,
                    disableSortBy: false,
                },
                {
                    id: 'candidateName',
                    Header: "Employee Name",
                    accessor: (row) => !row || !row.candidate ? 'N/A' : row.candidate.candidateName,
                    disableSortBy: false,
                },
                {
                    id: 'mobilePhoneNumber',
                    Header: "Mobile Number",
                    accessor: (row) => !row || !row.candidate ? 'N/A' : row.candidate.mobilePhoneNumber,
                    disableSortBy: false,
                },
            ];
        }

    }, [firstLoad, selected, selectedStartEndDateList, data, initialSelectedRows, bulkConfirmationMode]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        flatRows,
        selectedFlatRows,
        toggleAllRowsSelected
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: defaultSort,
                // "selectedRowIds": {},
                "selectedRowIds": initialSelectedRows,
                "hiddenColumns": ['rosterCandidateId']
            },
            // defaultColumn
        },
        useFlexLayout,
        hooks => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    width: 30,
                    disableSortBy: true,
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => {
                        return (
                            <div>
                                {
                                    !singleSelection &&
                                    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} id="all" />
                                }
                            </div>
                        )
                    },
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => {
                        const { original, values } = row;

                        if (firstLoad)
                            row.isSelected = (selected?.some(s => s.rosterCandidateId === original.rosterCandidateId && s.startEndGroupId === original.startEndGroupId) || row.isSelected);

                        // Validation
                        var isValid = true;
                        var invalidMobe = !values.mobe || values.mobe == 'N/A';
                        var invalidDemobe = !values.demobe || values.demobe == 'N/A';
                        var invalidAccommodation = !values.accommodation || values.accommodation == 'N/A';

                        if (invalidMobe && invalidDemobe && invalidAccommodation) {
                            isValid = false;
                        }


                        return (
                            <div>
                                {
                                    row.original != null &&
                                    <IndeterminateCheckbox 
                                        {...row.getToggleRowSelectedProps()} id={`check_${row.original.rosterCandidateId}`}
                                        onChange={(e) => {
                                            // console.log('e', e);
                                            const selected = row.isSelected;

                                            if (singleSelection)
                                                toggleAllRowsSelected(false);


                                            row.toggleRowSelected(!selected);
                                        }}
                                    />
                                }
                            </div>
                        )
                    },
                },
                ...columns,
            ])
        },
        useSortBy,
        useRowSelect,
    );

    // BEGIN WARNING MESSAGE GENERATOR -------------------------------------------------------------------------------------
    useEffect(() => {

        if (!flatRows || flatRows.length == 0) {
            setValidationMessage(null);
            return;
        }

        const newValidationMessages = [];

        // Invalid employee name or mobile phone number
        var invalidName = flatRows.some(s => !s.values.candidateName || s.values.candidateName == 'N/A');
        var invalidMobilePhoneNumber = flatRows.some(s => !s.values.mobilePhoneNumber || s.values.mobilePhoneNumber == 'N/A');
        if (invalidName || invalidMobilePhoneNumber) {
            newValidationMessages.push(`${invalidNameMsg}`);
        }

        // Invalid start/end date
        var invalidStartDate = flatRows.some(s => !s.values.startDate || s.values.startDate == 'N/A');
        var invalidEndDate = flatRows.some(s => !s.values.endDate || s.values.endDate == 'N/A');
        if (invalidStartDate || invalidEndDate) {
            newValidationMessages.push(`${invalidDateMsg}`);
        }

        if (bulkConfirmationMode == BulkConfirmationMode.LOGISTIC) {

            var invalidMobe = flatRows.some(s => !s.values.mobe || s.values.mobe == 'N/A');
            var invalidDemobe = flatRows.some(s => !s.values.demobe || s.values.demobe == 'N/A');
            var invalidAccommodation = flatRows.some(s => !s.values.accommodation || s.values.accommodation == 'N/A');

            if (invalidMobe && invalidDemobe && invalidAccommodation) {
                newValidationMessages.push(`${invalidLogisticMsg}`);
            }
        }

        // Invalid Roster Position
        console.log('Invalid Roster Position', 'flatRows', flatRows);
        var invalidRosterPosition = flatRows.filter(s => !s.original.hrispositionMasterName);
        if (invalidRosterPosition.some(f => f)) {

            var slotNoList = invalidRosterPosition.map(m => m.original.slotNo);
            var slotNos = MakeWords(slotNoList);
            var invalidMsg = `${invalidRosterPos} ${slotNos}`;
            newValidationMessages.push(invalidMsg);
        }

        if (selectedStartEndDateList.filter(d => !d.startEndHasWorkSchedule).length > 0)
            newValidationMessages.push("*The following start date / end date is not marked as work day.");

        if (selectedStartEndDateList.filter(d => d.inactiveDateExists).length > 0)
            newValidationMessages.push("**The schedule is overlapping with inactive date(s).");

        // If validation exists but no data invalid
        setValidationMessage(newValidationMessages.length > 0 ? newValidationMessages : null);
    }, [flatRows, selectedStartEndDateList]);
    // END WARNING MESSAGE GENERATOR -------------------------------------------------------------------------------------

    // BEGIN AUTO SELECT VALID DATA -------------------------------------------------------------------------------------
    const setSelection = useCallback(() => {
        if (!data || data.length == 0) {
            setInitialSelectedRows([]);
            return;
        }

        // Foreach data
        var selectedItemList = [];
        var selected = {};

        data.forEach((row, index) => {

            // Invalid name or mobile phone number
            if (!row.candidate || !row.candidate.candidateName || row.candidate.candidateName == 'N/A' ||
                !row.candidate || !row.candidate.mobilePhoneNumber || row.candidate.mobilePhoneNumber == 'N/A') {
                return;
            }

            // Invalid Date and Workdays
            if (!row.startEndDateList || !row.startEndDateList.some(w => w)) 
                return;

            // Invalid Roster Position
            if (!row.hrispositionMasterName)
                return;

            
            // Invalid mobe
            var invalidMobe = false;
            var mobe = !row.startEndDateList || !row.startEndDateList.some(w => w) ? null : row.startEndDateList.find(w=> w).mobe;
            if (mobe == null) invalidMobe = true;

            // Invalid demobe
            var invalidDemobe = false;
            var demobe = !row.startEndDateList || !row.startEndDateList.some(w => w) ? null : row.startEndDateList.find(w => w).demobe;
            if (demobe == null) invalidDemobe = true;


            // Invalid accomodation
            var invalidRcAccommodation = false;
            var rcAccommodation = !row.startEndDateList || !row.startEndDateList.some(w => w) ? null : row.startEndDateList.find(w => w).rcAccommodation;
            if (rcAccommodation == null) invalidRcAccommodation = true;

            // Invalid logistic, mobe, demobe, and accomodation
            if (bulkConfirmationMode == BulkConfirmationMode.LOGISTIC &&
                invalidMobe && invalidDemobe && invalidRcAccommodation) {
                return;
            }


            // New item to append
            var newItem = { ...row, id: index };
            selectedItemList.push(newItem);



        });


        // Set id of selected item
        selectedItemList.forEach((item) => {
            selected[`${item.id}`] = true;
        })
        setInitialSelectedRows(selected);

    }, [data, selectedStartEndDateList, bulkConfirmationMode]);


    useEffect(() => {
        setSelection();
    }, [data, selectedStartEndDateList, bulkConfirmationMode]);
    // END AUTO SELECT VALID DATA -------------------------------------------------------------------------------------

    const generateMessage = useCallback(() => {
        // Validate data
        // Get list of invalid date
        const invalidDateList = selectedFlatRows.filter(s => {
            const { values } = s;
            const invalidStartDate = !values.startDate || values.startDate == 'N/A';
            const invalidEndDate = !values.endDate || values.endDate == 'N/A';

            if (invalidStartDate || invalidEndDate)
                return true;
            return false;
        });

        // Get list of invalid name or phone number
        const invalidLogisticList = selectedFlatRows.filter(s => {
            const { values } = s;
            const invalidMobe = !values.mobe || values.mobe == 'N/A';
            const invalidDemobe = !values.demobe || values.demobe == 'N/A';
            const invalidAccommodation = !values.accommodation || values.accommodation == 'N/A';

            if (invalidMobe && invalidDemobe && invalidAccommodation)
                return true;
            return false;
        });

        // Get list of invalid name or phone number
        const invalidNameOrPhoneList = selectedFlatRows.filter(s => {
            const { values } = s;
            const invalidCandidateName = !values.candidateName || values.candidateName == 'N/A';
            const invalidMobilePhoneNumber = !values.mobilePhoneNumber || values.mobilePhoneNumber == 'N/A';

            if (invalidCandidateName || invalidMobilePhoneNumber)
                return true;
            return false;
        });

        // Get list of invalid roster position
        const invalidRosterPosition = selectedFlatRows.filter(s => !s.original.hrispositionMasterName);

        // Validate name or phone number
        if (invalidNameOrPhoneList && invalidNameOrPhoneList.length > 0) {
            const slotNoList = invalidNameOrPhoneList.map((item) => item.original.slotNo);
            const slotNoWords = MakeWords(slotNoList);
            setErrorTitle(errorTitleOnGenerate);
            setErrorMessage(`${invalidNameMsg}. Please check slot number: ${slotNoWords}.`);
            return;
        }

        // Validate date
        if (invalidDateList && invalidDateList.length > 0) {
            const slotNoList = invalidDateList.map((item) => item.original.slotNo);
            const slotNoWords = MakeWords(slotNoList);
            setErrorTitle(errorTitleOnGenerate);
            setErrorMessage(`${invalidDateMsg}. Please check slot number: ${slotNoWords}.`);
            return;
        }

        // Validate logistic
        if (bulkConfirmationMode == BulkConfirmationMode.LOGISTIC && invalidLogisticList && invalidLogisticList.length > 0) {
            const slotNoList = invalidLogisticList.map((item) => item.original.slotNo);
            const slotNoWords = MakeWords(slotNoList);
            setErrorTitle(errorTitleOnGenerate);
            setErrorMessage(`${invalidLogisticMsg}. Please check slot number: ${slotNoWords}.`);
            return;
        }

        // Validate roster position
        if (invalidRosterPosition && invalidRosterPosition.length > 0) {
            const slotNoList = invalidRosterPosition.map((item) => item.original.slotNo);
            const slotNoWords = MakeWords(slotNoList);
            setErrorTitle(errorTitleOnGenerate);
            setErrorMessage(`${invalidRosterPos} ${slotNoWords}.`);
            return;
        }

        // Generate message
        //if (step == 2) {
        var tempData = [...data];
        tempData.forEach((oneData) => {
            var oneDataDs = { ...oneData };
            var candidate = { ...oneData.candidate };

            // Skip if message template is empty
            if (!currentMessage) {
                tempData.message = null;
                return;
            }

            // Copy object and message from source
            var messageObj = { ...currentMessage };
            var message = `${messageObj?.message}`;


            // Find and replace variables
            if (attributes && attributes.length > 0) {
                for (let i = 0; i < attributes.length; i++) {
                    // console.log('attributes', { attributes, message });
                    const attribute = attributes[i];

                    if (attributes[i].category === "Message Attribute") {
                        const valueSource = _.camelCase(attribute.valueSource);

                        if (candidate[valueSource]) {
                            // console.log("message attr 2", {message, attribute, name: attribute.name, valueSource, value: candidate[valueSource], result: message.replace(new RegExp(attribute.name, 'g'), candidate[valueSource])});
                            message = message.replace(new RegExp(attribute.name, 'g'), candidate[valueSource]);
                        }

                        if (oneDataDs[valueSource]) {
                            // console.log("message attr 2", {message, attribute, name: attribute.name, valueSource, value: candidate[valueSource], result: message.replace(new RegExp(attribute.name, 'g'), candidate[valueSource])});
                            message = message.replace(new RegExp(attribute.name, 'g'), oneDataDs[valueSource]);
                        }



                        continue;
                    }

                    if ('value' in attribute && attribute.value)
                        message = message.replace(new RegExp(`${attribute.name}`, 'g'), attribute.value);
                }
            }

            messageObj.message = message;
            oneData.message = messageObj;

        });
        setData(tempData);

        // Filter data
        var selectedRcIdList = selectedFlatRows.map((item) => { return item.values.rosterCandidateId; });
        var filteredData = tempData.filter((w) => selectedRcIdList.includes(w.rosterCandidateId));

        // Set data to step 2
        setDataSms(filteredData);
        // setLoadAccommodations(true);
        setStep(2);
        // console.log('tempData', tempData);
        // console.log('filteredData', filteredData);

        //selectedFlatRows.se

        //var newMessageList = [];
        //newMessageList.push
    }, [step, data, dataSms, selectedFlatRows, currentMessage]);


    // END TABLE CHOOSE DATE --------------------------------------------------------------------------------------------------------------------------

    // BEGIN TABLE MESSAGE PREPARATION ----------------------------------------------------------------------------------------------------------------


    const columnsSms = useMemo(() => {
        return [
            {
                id: 'slotNo',
                Header: "Slot",
                width: 25,
                disableSortBy: false,
                Cell: ({ row: { original } }) => {
                    const selectedStartEndDate = selectedStartEndDateList?.filter(w => w)?.find(w => w?.rosterCandidateId == original?.rosterCandidateId);
                    const hasWorkSchedule = original.startEndDateList?.filter(d => d.rosterCandidateId === selectedStartEndDate?.rosterCandidateId && d.startEndHasWorkSchedule).length > 0;
                    const slotNo = `${!hasWorkSchedule ? "*" : ""}${original.slotNo}`;

                    return (
                        <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                            <span className="text-left ml-2">{slotNo}</span>
                        </div>
                    );
                },
            },
            {
                Header: "Employee Name",
                accessor: (row) => !row || !row.candidate ? null : row.candidate.candidateName,
                width: 50,
                disableSortBy: false,
            },
            {
                id: 'mobilePhoneNumber',
                Header: "Mobile Number",
                accessor: (row) => !row || !row.candidate ? 'N/A' : row.candidate.mobilePhoneNumber,
                width: 50,
                disableSortBy: false,
            },
            {
                id: 'workDays',
                Header: "Workdays",
                disableSortBy: false,
                Cell: ({ row: { original } }) => {
                    const selectedStartEndDate = selectedStartEndDateList?.filter(w => w)?.find(w => w?.rosterCandidateId == original?.rosterCandidateId);

                    const workDayText = (!original.startEndDateList || !original.startEndDateList.some(w => w) || !selectedStartEndDate) ? "N/A"
                        :
                        selectedStartEndDate.workDays;

                    const inactiveDateExists = original.startEndDateList.filter(d => d.rosterCandidateId === selectedStartEndDate?.rosterCandidateId && d.inactiveDateExists).length > 0;

                    return (
                        <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                        {
                            <span className={`text-left ml-2${workDayText !== "N/A" && inactiveDateExists ? " text-danger" : ""}`}>
                            {
                                workDayText
                            }
                            {
                                workDayText !== "N/A" && inactiveDateExists ? "**" : ""
                            }
                            </span>
                        }
                        </div>
                    );
                },
                width: 30,
            },
            {
                id: 'status',
                Header: "Status",
                accessor: (row) => {
                    const selectedStartEndDate = selectedStartEndDateList?.filter(w => w)?.find(w => w?.rosterCandidateId == row?.rosterCandidateId);
                    // console.log({row, selectedStartEndDate});

                    if (bulkConfirmationMode === BulkConfirmationMode.AVAILABILITY)
                        return selectedStartEndDate?.availabilityStatus ?? "N/A";

                    return selectedStartEndDate?.logisticStatus ?? "N/A";
                },
                disableSortBy: false,
                width: 35,
            },
            {
                Header: "Message",
                //accessor: (row) => !row || !('message' in row) || !('message' in row.message) ? null : row.message.message,
                Cell: ({ row }) => {
                    const { original } = row;
                    //console.log('original', original);
                    return (<div>{(!original || !('message' in original) || !('message' in original.message) ? "" : original.message.message)}</div>);
                    /*
                    return (<div>

                        <Input
                            type="textarea"
                            value={(!original || !('message' in original) || !('message' in original.message) ? "" : original.message.message)}
                            className="overflow-hidden"
                            style={{ resize: 'none' }}
                            onChange={(e) => {
                                setCursorPosition(e.target.selectionStart);


                                e.target.style.height = 'inherit';
                                e.target.style.height = `${e.target.scrollHeight}px`;

                                var newData = [...data];
                                var currentData = newData.find(w => w.rosterCandidateId == original.rosterCandidateId);
                                currentData.message.message = e.target.value;
                                //setData(newData);
                            }}
                            onClick={(e) => {
                                setCursorPosition(e.target.selectionStart)

                                e.target.style.height = 'inherit';
                                e.target.style.height = `${e.target.scrollHeight}px`;


                            }}
                            onKeyUp={(e) => {
                                if (e.nativeEvent.keyCode >= 37 && e.nativeEvent.keyCode <= 40) {
                                    setCursorPosition(e.target.selectionStart)
                                }
                            }}
                        />
                    </div>);
                    */
                },
                disableSortBy: false,
            },
            {
                Header: "Action",
                //accessor: (row) => !row || !('message' in row) || !('message' in row.message) ? null : row.message.message,
                width: "15",
                Cell: ({ row }) => {
                    const { original } = row;
                    return (<TooltipButton
                        id={`edit_${original?.candidate?.candidateId}`}
                        title="Edit"
                        className="btn-icon"
                        color="default"
                        size="xsm"
                        type="button"
                        onClick={() => {

                            // Push data to modal
                            var candidateList = [];
                            candidateList.push({ ...original.candidate });
                            setSelectedCandidates2(candidateList);
                            setSelectedSourceObject2({ ...original });

                            setShowModalSms(true);
                            setCurrentMessage2(original.message);
                        }}>
                        <i className="fas fa-pencil-alt pt-1"></i>
                    </TooltipButton>);
                },
                disableSortBy: false,
                width: 25,
            },
        ];
    }, [firstLoad, selected, selectedStartEndDateList, step, data, bulkConfirmationMode]);

    const {
        getTableProps: getTablePropsSms,
        getTableBodyProps: getTableBodyPropsSms,
        headerGroups: headerGroupsSms,
        rows: rowsSms,
        prepareRow: prepareRowSms,
    } = useTable(
        {
            columns: columnsSms,
            data: dataSms,
            initialState: {
                sortBy: defaultSort,
                // "selectedRowIds": {},
                "hiddenColumns": []
            },
            // defaultColumn
        },
        useFlexLayout,
        useSortBy,
        useRowSelect,
    );


    // END TABLE MESSAGE PREPARATION ------------------------------------------------------------------------------------------------------------------


    const onSaveEditSms = useCallback((message, callback, loadingCallback, sourceObject) => {
        if (!message || !message.message || message.message === "")
            return;

        if (loadingCallback)
            loadingCallback(true);
        else
            setLoading(true);
        // console.log(selectedFlatRows);

        //sourceObject.rosterCandidateId;

        var newData = [...dataSms];
        var editedData = newData.find(w => w.rosterCandidateId == sourceObject.rosterCandidateId);

        if (editedData) {
            editedData.message.message = message.message;
        }

        setDataSms(newData);
        // setLoadAccommodations(true);

        callback();
        loadingCallback(false);
        setLoading(false);


    }, [selectedFlatRows]);

    // React.useEffect(() => {
    //     console.log('selectedFlatRows', selectedFlatRows);
    // }, [selectedFlatRows]);

    // BEGIN ERROR MESSAGE HANDLER -----------------------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            onConfirm: () => {
                setErrorMessage(null);
                setErrorTitle(null);
            },
            message: errorMessage,
            title: errorTitle ?? errorTitleOnGenerate
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage, errorTitle]);
    // END ERROR MESSAGE HANDLER -------------------------------------------------------------------------------------------------------------------------------------------



    return (
        <>
            {(loading) && <Loader />}
            {errorNotification.length > 0 && errorNotification}
            <Collapse className="modal-bulk-send-choose-date" isOpen={step == 1}>
                <div className="modal-header my-0">
                    <Container fluid>
                        <Row>
                            <Col lg={9}>
                                <h5 className="modal-title">
                                    {bulkConfirmationMode == BulkConfirmationMode.AVAILABILITY ? `Bulk Availability Confirmation` : `Bulk Logistic Confirmation`}
                                    {title}
                                </h5>
                                <div><small>Roster Candidate Selection</small></div>
                            </Col>
                            <Col lg={3}>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    onClick={onClose}
                                    type="button"
                                >
                                    <span aria-hidden={true}>
                                        <i className="fas fa-times-circle text-black" />
                                    </span>
                                </button>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={12}>
                            {
                                bulkConfirmationMode === BulkConfirmationMode.AVAILABILITY &&
                                <>
                                    <div className="w-100 mb-1 d-flex flex-wrap align-items-center">
                                        <IndeterminateCheckbox
                                            id="estimate-only"
                                            onChange={({target}) => {
                                                const {checked} = target;
                                                setIsEstimation(checked);
                                                setCurrentMessage(checked ? estimationMessage : confirmationMessage);
                                            }}
                                            checked={isEstimation}
                                        />
                                        <span className="text-wrap">
                                            Estimate Only
                                        </span>
                                    </div>
                                    <div className="w-100 mb-1">
                                        <span className="text-wrap">
                                            <b>*Estimation Only</b> flag will send confirmation request for estimated schedule availability request to the user.
                                        </span>
                                    </div>
                                </>
                            }
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="modal-body">
                    <Collapse isOpen={!!validationMessage} className="mb-4">
                        <div className="alert alert-warning" role="alert">
                        {
                            validationMessage?.map((d, index) => (
                                <>
                                {d}
                                {
                                    index < validationMessage.length - 1 ? <><br /></> : null
                                }
                                </>
                            ))
                        }
                        </div>
                    </Collapse>
                    <Grid
                        height='calc(100vh - 436px)'
                        rows={rows}
                        tableProps={getTableProps()}
                        headerGroups={headerGroups}
                        tableBodyProps={getTableBodyProps()}
                        prepareRow={prepareRow}
                        rowStyle={(row) => {
                            if (!selectedStartEndDateList?.length)
                                return "yellow";

                            if (!row.original.hrispositionMasterName) 
                                return "yellow";

                            return selectedStartEndDateList.filter(d => d.rosterCandidateId === row.original.rosterCandidateId && (!d.startEndHasWorkSchedule || d.inactiveDateExists)).length ? "yellow" : "";
                        }}
                    />
                </div>
                <div className="modal-footer">
                    <Button color="secondary" type="button"
                        onClick={() => {
                            loadData();
                            //setStep(2);
                        }}>
                        <span className="fa fa-refresh"></span>
                        Reset
                    </Button>
                    <Button color="secondary" type="button"
                        onClick={() => {
                            onClose();
                        }}>
                        Cancel
                    </Button>
                    <Button color="info" type="button" disabled={!selectedFlatRows || !selectedFlatRows.length}
                        onClick={() => {
                            generateMessage();
                            //setStep(2);
                        }}>
                        Continue
                    </Button>
                </div>

            </Collapse>

            <Collapse className="modal-bulk-send-choose-date" isOpen={step == 2}>
                <div className="modal-header my-0">
                    <Container fluid>
                        <Row>
                            <Col lg={9}>
                                <h5
                                    className="modal-title"
                                >
                                    {bulkConfirmationMode == BulkConfirmationMode.AVAILABILITY ? `Bulk Availability Confirmation` : `Bulk Logistic Confirmation`}
                                    {title}
                                </h5>
                                <div><small>Message Preparation</small></div>
                            </Col>
                            <Col lg={3}>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    onClick={onClose}
                                    type="button"
                                >
                                    <span aria-hidden={true}>
                                        <i className="fas fa-times-circle text-black" />
                                    </span>
                                </button>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="modal-body">
                    <Collapse isOpen={!!validationMessage} className="mb-4">
                        <div className="alert alert-warning" role="alert">
                        {
                            validationMessage?.map((d, index) => (
                                <>
                                {d}
                                {
                                    index < validationMessage.length - 1 ? <><br /></> : null
                                }
                                </>
                            ))
                        }
                        </div>
                    </Collapse>
                    <Collapse isOpen={failedMessages?.length > 0} className="mb-2">
                    {
                        failedMessages?.length ?
                        <div className="p-2 font-size-message-alert-custom text-danger">
                            <ul>
                            {
                                failedMessages.map((item, idx) => {
                                    if (item.failCategory === "Empty Message")
                                        return (
                                            <li key={`fail_${idx}`}>
                                                <span>Message cannot be empty.</span>
                                            </li>
                                        );

                                    if (item.failCategory === "Invalid Attribute Usage")
                                        return (
                                            <li key={`fail_${idx}`}>
                                                <span>{item.message}</span>
                                            </li>
                                        );

                                    if (item.failCategory === "URL Response")
                                        return (
                                            <li key={`fail_${idx}`}>
                                                <span><b>Add URL to Response</b> must be checked.</span>
                                            </li>
                                        );
                                    
                                    if (item.failCategory === "Talent Response Parameter")
                                        return (
                                            <li key={`fail_${idx}`}>
                                                <span><b>{"{FlowUrls.TalentResponseUrl}"}</b> must be provided.</span>
                                            </li>
                                        );
                                    
                                    if (item.failCategory === "Message Unsent")
                                        return (
                                            <li key={`fail_${idx}`}>
                                                <span>
                                                {
                                                    `Message is failed to send to ${item.candidateName}.`
                                                }
                                                </span>
                                            </li>
                                        );
                                    
                                    if (item.failCategory === "Invalid Attribute")
                                        return (
                                            <li key={`fail_${idx}`}>
                                                <span>
                                                {
                                                    item.failAttribute
                                                }
                                                </span>
                                            </li>
                                        );
                                            
                                    if (item.failCategory === "Index Error")
                                        return (
                                            <li key={`fail_${idx}`}>
                                                <span>
                                                    {item.candidateName} ({item.mobileNumber}) SMS request is not able to be processed. Please contact your admin to check the log files.<br />
                                                    Original error message: "Index was outside the bounds of the array."<br />
                                                    Thank you.
                                                </span>
                                            </li>
                                        );

                                    return (
                                        <li key={`fail_${idx}`}>
                                            <span>
                                            {
                                                `${item.candidateName}: missing ${item.failCategory} - ${item.failAttribute}.`
                                            }
                                            </span>
                                        </li>
                                    );
                                })
                            }
                            </ul>
                        </div>
                        :
                        null
                    }
                    </Collapse>
                    <Grid
                        height='calc(100vh - 436px)'
                        rows={rowsSms}
                        tableProps={getTablePropsSms()}
                        headerGroups={headerGroupsSms}
                        tableBodyProps={getTableBodyPropsSms()}
                        prepareRow={prepareRowSms}
                        rowStyle={(row) => {
                            if (!selectedStartEndDateList?.length)
                                return "";

                            return selectedStartEndDateList.filter(d => d.rosterCandidateId === row.original.rosterCandidateId && (!d.startEndHasWorkSchedule || d.inactiveDateExists)).length ? "yellow" : "";
                        }}
                    />
                </div>
                <div className="modal-footer">
                    <Button
                        color="secondary"
                        onClick={() => {
                            setStep(1);
                        }}
                        type="button"
                    >
                        Back
                    </Button>
                    <Button
                        color="info"
                        onClick={() => {
                            handleSend();
                        }}
                        type="button"
                    >
                        Send Confirmation
                        {/*{customConfirm ? (customConfirm.confirmButtonTitle ?? "Confirm") : "Send Confirmation"}*/}
                    </Button>
                </div>
            </Collapse>
        {
            showModalSms &&
            <EditorSMS
                items={selectedCandidates2}
                sourceObject={selectedSourceObject2}
                onClose={() => {
                    setShowModalSms(false);
                    if (failedMessages?.length > 0)
                        setFailedMessages([]);
                    //onClose();
                }}
                onCustomSave={(message, loadingCallback, failedMessagesCallback, templateId, messageLog, sourceObject) =>
                    onSaveEditSms({ message }, () => {
                        setShowModalSms(false);
                        setLoading(false);
                    }, loadingCallback, sourceObject)}
                lookups={{
                    ...props.lookups
                }}
                defaultMessage={currentMessage2}
                buttonSendMessage={false}
            />
        }
        {
            dataIsEmpty &&
            <SweetAlert
                title="Error!"
                error
                confirmBtnText="OK"
                confirmBtnBsStyle="danger"
                onConfirm={() => onClose()}
            >
                Data not found.
            </SweetAlert>
        }
        </>
    )
};