import { Collapse } from "reactstrap";
import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import { useMemo } from "react";

export default function DeleteDependenciesAlert(props) {

    const {
        onConfirm,
        fields,
        title = 'Delete Error',
        desc = 'To delete this data, please check this dependencies below.',
        showList = true,
        data = [],
    } = props;

    const dataValidation = useMemo(() => {

        if (!data?.length) {
            return [];
        }

        var filtered = data.filter(f => f.list?.length);
        return filtered;

    }, [data]);

    console.log('DuplicateNotification', 'props', props);
    console.log('showList', showList);

    return (
        <SweetAlert
            title={<h4 className="mb-0">{title ?? "Data Duplicate"}</h4>}
            onConfirm={onConfirm}
            type="danger"
            className="alert-notif df df-sweetalert"
        >
            <div className="mb-4">
                {desc ?? "To delete this data, please check this dependencies below."}
            </div>
            <Collapse isOpen={showList}>
                {dataValidation?.length && dataValidation.map((item, index) => {
                    var { tableName, mentionName, list } = item;


                    return (<table className="table table-hover df df-table mb-3">
                        <thead>
                            <tr>
                                <th>{mentionName}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list?.length && list.map((item2, index) => {
                                return (<tr key={`${tableName}_${index}`}>
                                    <td className="show-complete">{item2}</td>
                                </tr>)
                            })}
                        </tbody>
                    </table>);
                })}
            </Collapse>
        </SweetAlert>
    );
}
