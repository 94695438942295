import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";

export default function ConfirmDeleteAlert(props) {

    const {
        onConfirm,
        onCancel
    } = props;

    return (
        <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            title={<span className="mb-0 h4">Are you sure?</span>}
            onConfirm={onConfirm} // need to add loading indicator on confirm
            onCancel={onCancel}
            focusCancelBtn
        >
        You will not be able to recover this record!
        </SweetAlert>
    );
}



