const trainingNeedsAnalysisValidation = [
    {
        field: "positionId",
        tests: [
            {
                type: "required",
                message: "Position is required.",
                validate: (obj) => !obj.positionId,
            }
        ]
    },
    {
        field: "trainingMasterId",
        tests: [
            {
                type: "required",
                message: "Training is required.",
                validate: (obj) => !obj.trainingMasterId,
            }
        ]
    },
    {
        field: "trainingGroupId",
        tests: [
            {
                type: "required",
                message: "Training Group is required.",
                validate: (obj) => !obj.trainingGroupId,
            }
        ]
    },
    // {
    //     field: "trainingProviderId",
    //     tests: [
    //         {
    //             type: "required",
    //             message: "Training Provider is required.",
    //             validate: (obj) => !obj.trainingProviderId,
    //         }
    //     ]
    // },
    {
        field: "trainingRequirementId",
        tests: [
            {
                type: "required",
                message: "Training Requirement is required.",
                validate: (obj) => !obj.trainingRequirementId,
            }
        ]
    },
    {
        field: "startDate",
        tests: [
            {
                type: "required",
                message: "Start Date is required.",
                validate: (obj) => !obj.startDate,
            }
        ]
    }
];

export {
    trainingNeedsAnalysisValidation
};