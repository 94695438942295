import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import api from "../../../../services/api";

export default (props) => {
    const {node, column, node: {data}, statusType} = props;
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState([]);

    const loadData = useCallback(() => {
        setLoading(true);

        api.get(`/rostergrid/status/detail/${statusType}/${data.rosterCandidateId}`)
            .then((response) => {
                const {data} = response;
                console.log({data});
                setDetails(data);
            })
            .catch((error) => console.log({error}))
            .finally(() => setLoading(false))
        ;
    }, [data, statusType]);

    useEffect(() => loadData(), [data]);

    return (
        <div key={`${node.rowIndex}-${column.instanceId}`}
            className="border border-info px-3 py-1 d-flex flex-wrap justify-content-center"
            style={{ backgroundColor: 'white', minWidth: '150px' }}
        >
        {
            loading ? <Spinner color="default" size="sm" /> : null
        }
            <div className="w-100">
            {
                details.map((d, idx) => {
                    switch (statusType) {
                        case "availability":
                        case "das":
                            return (
                                <>
                                    <span><b>{d.status}</b>: {`${d.startDate ? moment(d.startDate).format("DD-MM-YYYY") : "N/A"} to ${d.endDate ? moment(d.endDate).format("DD-MM-YYYY") : "N/A"}`}</span>
                                {
                                    idx < details.length - 1 ?
                                    <br />
                                    :
                                    null
                                }
                                </>
                            );
                        case "accommodation":
                            return (
                                <>
                                    <span><b>{d.status}</b>: {`${d.flightNumber ?? "N/A"} - ${d.departureAirport ?? "N/A"}, ${d.departureCity ?? "N/A"} [${d.departureDate ? moment(d.departureDate).format("DD-MM-YYYY HH:mm") : "N/A"}] - ${d.arrivalAirport ?? "N/A"}, ${d.arrivalCity ?? "N/A"} [${d.arrivalDate ? moment(d.arrivalDate).format("DD-MM-YYYY HH:mm") : "N/A"}]`}</span>
                                {
                                    idx < details.length - 1 ?
                                    <br />
                                    :
                                    null
                                }
                                </>
                            );
                        // case "das":
                        //     return (
                        //         <>
                        //             <span><b>{d.status}</b>: {`${d.lastStatusDate ? moment(d.lastStatusDate).format("DD-MM-YYYY") : "N/A"}`}</span>
                        //         {
                        //             idx < details.length - 1 ?
                        //             <br />
                        //             :
                        //             null
                        //         }
                        //         </>
                        //     );
                        case "recruitment":
                            return (
                                <>
                                    <span><b>{d.status}</b>: {`${d.recruitmentRequestNumber ?? "N/A"} - ${d.recruiterName ?? "N/A"}`}</span>
                                {
                                    idx < details.length - 1 ?
                                    <br />
                                    :
                                    null
                                }
                                </>
                            );
                        default:
                            return null;
                    }
                })
            }
            </div>
        </div>
    );
};
