
import CopyToClipboard from "react-copy-to-clipboard";
import {
    Container, Row, Col, Button, Card, CardBody, CardTitle, UncontrolledTooltip, Progress, Label
} from "reactstrap";
import { TooltipButton } from '../../../../inputs';
import React, { useEffect, useState, useCallback, useMemo, useRef, Component } from 'react';

export default class PositionCellRenderer extends Component {

    constructor(props) {
        super(props);
        //this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    render() {

        var { props } = this;
        var { colDef, data } = props;

        //return (data.details?.map(m => (m.joExists ? <><a href={`/joborder/${m.requestDetailId}/joborder`} target='_blank'>{m.hrispositionMaster} ({m.amount})</a><br /></> : <><span>{m.hrispositionMaster} ({m.amount})</span><br /></>)));
        return (data.details?.map(m => (m.joExists ? <><a href={`/joborder/${m.requestDetailId}/joborder`} target='_blank' className="mb--2 d-block">{m.positionName} ({m.quantityRequired})</a></> : <><span className="mb--2 d-block">{m.positionName} ({m.quantityRequired})</span></>)));


    }
}