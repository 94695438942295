import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FiltersConverter, SortsConverter } from "../../../helpers";
import api from "../../../services/api";
import Loader from "../../loaders";
import qs from "qs";
import { AgGridReact } from "ag-grid-react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { TooltipButton } from "../../inputs";
import EditorPortalUser from "./EditorPortalUser";
import CandidateEditor from "./Editor";
import { ErrorNotification } from "../../alerts";
import SweetAlert from "react-bootstrap-sweetalert";

const pageSize = 100;
const errorMessageOpening = "Error occured when processing the request: ";
const errorTitleOnGenerate = "Portal User Grid";
const confirmationType = {
    activation: 'ACTIVATION',
    removal: 'REMOVAL'
};

function ConfirmStatusRenderer(props) {
    const {value} = props;

    return (
        <div className="grid-w-100 m-0 p-0 text-center">
            <span>
                <i className={`fas fa-${value ? "check-circle" : "times-circle"} text-${value ? "success" : "danger"}`} />
            </span>
        </div>
    );
}

export default function PortalUserList(props) {
    const defaultSort = useMemo(() => [{ id: "displayName", desc: true }],
        []
    );

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState(defaultSort);
    const [sortBy, setSortBy] = useState(defaultSort);
    const [pageNumber, setPageNumber] = React.useState(1);
    // const [pageSize, setPageSize] = React.useState(100);
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorTitle, setErrorTitle] = useState(null);
    const [confirmation, setConfirmation] = useState(null);
    const [errorNotification, setErrorNotification] = useState([]);
    const [itemInEdit, setItemInEdit] = useState(null);
    const [candidateInEdit, setCandidateInEdit] = useState(null);
    const [lookups, setLookups] = useState(null);
    const gridRef = useRef();

    useEffect(() => {
        setLoading(true);
        let apiCalls = [
            api.get(`/lookup/skillposition`),
            api.get(`/lookup/candidatetype`),
            api.get(`/lookup/salutation`),
            api.get(`/lookup/preference`),
            api.get(`/lookup/industry`),
            api.get(`/lookup/gender`),
            api.get(`/lookup/maritalStatus`),
            api.get(`/lookup/candidateStatus`),
            api.get(`/lookup/candidateFlag`),
            api.get(`/lookup/residencyStatus`),
            api.get(`/lookup/visatype`),
            api.get(`/lookup/communicationtype`),
            api.get(`/lookup/country`),
            api.get(`/lookup/state`),
            api.get(`/lookup/relationshiptype`),
            api.get(`/lookup/candidateplatformorigin`),
            api.get(`/lookup/messagesmasters`),
            api.get(`/emailtemplate`), // TODO: returns paged result - move to lookup
            api.get(`/lookup/recruitmentpeople`),
            api.get(`/lookup/candidatediscipline`),
            api.get(`/lookup/company`),
            api.get(`/lookup/identifier`),
            api.get(`/lookup/candidateevent`),
            api.get(`/lookup/candidateeventstatus`),
            api.get(`/lookup/candidatecommenttype`),
            api.get(`/lookup/agency`),
            api.get(`/contractingcompany/all`)
        ];

        Promise.all(apiCalls)
            .then((data) => {
                setLookups({
                    skillPositions: data[0].data,
                    candidateTypes: data[1].data,
                    salutations: data[2].data,
                    preferences: data[3].data,
                    industries: data[4].data,
                    genders: data[5].data,
                    maritalStatuses: data[6].data,
                    candidateStatuses: data[7].data,
                    candidateFlags: data[8].data,
                    residencyStatuses: data[9].data,
                    visaTypes: data[10].data,
                    communicationTypes: data[11].data,
                    countries: data[12].data,
                    states: data[13].data,
                    relationshipTypes: data[14].data,
                    candidatePlatformOrigin: data[15].data,
                    messagesMaster: data[16].data,
                    emailTemplate: data[17].data.data,
                    recruitmentPeople: data[18].data,
                    candidateDisciplines: data[19].data,
                    companies: data[20].data,
                    identifiers: data[21].data,
                    events: data[22].data,
                    eventStatus: data[23].data,
                    candidateCommentTypes: data[24].data,
                    agencies: data[25].data,
                    contractingCompanies: data[26].data
                });
            }).catch((error) => {
                console.error("error on getting options", {error});
                setErrorMessage(`${errorMessageOpening}${error.response.data}`);
                setErrorTitle(errorTitleOnGenerate);
            }).finally(() => setLoading(false));
    }, []);

    const loadData = React.useCallback(() => {
        refreshCache();
    }, [filters, sortBy, pageNumber, pageSize]);

    const handleConfirmed = useCallback((obj) => {
        setLoading(true);

        if (obj.type === confirmationType.activation)
        {
            api.put(`candidateportal/activation/${obj.id}?active=${obj.active}`)
                .then(() => {
                    loadData();
                })
                .catch((error) => {
                    setErrorTitle("Activation process failed.");
                    setErrorMessage(error.response.data);
                })
                .finally(() => {
                    setConfirmation(null);
                    setLoading(false);
                });

            return;
        }
        
        api.delete(`candidateportal/${obj.id}`)
            .then(() => {
                loadData();
            })
            .catch((error) => {
                setErrorTitle("Removal process failed.");
                setErrorMessage(error.response.data);
            })
            .finally(() => {
                setConfirmation(null);
                setLoading(false);
            });

        return;
    }, [loadData]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        //filter: 'agSetColumnFilter',
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['reset'],
            debounceMs: 200,
            excelMode: 'mac',
        },
        floatingFilter: true,
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        suppressMenu: true,
    }), []);

    const columnDefs = useMemo(() => [
        {
            field: "displayName",
            headerName: "Display Name",
            width: 300,
        },
        {
            field: "talent",
            headerName: "Info",
            cellRenderer: (props) => {
                const {data} = props;

                if (!data.candidateId)
                    return null;

                return (
                    <div
                        className="grid-w-100 h-100 m-0 p-0 d-flex flex-wrap justify-content-center align-items-center"
                        onClick={() => setCandidateInEdit({
                            candidateId: data.candidateId,
                        })}
                    >
                        <i className="fas fa-exclamation-circle" />    
                    </div>
                );
            },
            width: 50,
            sortable: false,
            floatingFilter: false,
            filter: "",
        },
        {
            field: "phoneNumber",
            headerName: "Phone Number",
            valueFormatter: (params) => {
                var { value } = params;

                const match = value?.match(/^([\dX]{4})([\dX]{3})([\dX]{3})$/)
                if (match) {
                    return `${match[1]} ${match[2]} ${match[3]}`;
                } else {
                    return value;
                }
            },
            width: 200,
        },
        {
            field: "phoneNumberConfirmed",
            headerName: "Phone Number Confirmed",
            cellRenderer: ConfirmStatusRenderer,
            filter: "",
        },
        {
            field: "email",
            headerName: "Email",
            width: 450,
        },
        {
            field: "emailConfirmed",
            headerName: "Email Confirmed",
            cellRenderer: ConfirmStatusRenderer,
            filter: "",
        },
        {
            field: "accessFailedCount",
            headerName: "Access Failed Count",
            filter: "",
        },
        {
            field: "newPasswordRequired",
            headerName: "New Password Required",
            cellRenderer: ConfirmStatusRenderer,
            filter: "",
        },
        {
            field: "button",
            headerName: "Action",
            pinned: "right",
            cellRenderer: (props) => {
                const {data} = props;
                return (
                    <div className="grid-w-100 h-100 m-0 p-0 d-flex flex-wrap justify-content-center align-items-center">
                        <TooltipButton
                            id={`edit-${data.id}`}
                            title="Edit"
                            color="default"
                            className="btn-icon"
                            size="xss"
                            type="button"
                            onClick={() => setItemInEdit(data)}
                            delay={30}
                        >
                            <i className="fas fa-xs fa-pencil-alt" />
                        </TooltipButton>
                        <TooltipButton
                            id={`active-${data.id}`}
                            title={data.isActive ? "Deactivate" : "Activate"}
                            color={data.isActive ? "default" : "success"}
                            className="btn-icon"
                            size="xss"
                            type="button"
                            onClick={() => setConfirmation({
                                type: confirmationType.activation,
                                title: `User ${data.isActive ? "Deactivation" : "Activation"} Confirmation`,
                                message: `Are you sure you want to ${data.isActive ? "deactivate" : "activate"} user ${data.displayName}?`,
                                id: data.id,
                                active: !data.isActive
                            })}
                            delay={30}
                        >
                            <i className={`fas fa-xs fa-${data.isActive ? "times" : "check"}`} />
                        </TooltipButton>
                        <TooltipButton
                            id={`delete-${data.id}`}
                            title="Delete"
                            color="warning"
                            className="btn-icon"
                            size="xss"
                            type="button"
                            onClick={() => setConfirmation({
                                type: confirmationType.removal,
                                title: `User Removal Confirmation`,
                                message: `Are you sure you want to remove user ${data.displayName}?`,
                                id: data.id
                            })}
                            delay={30}
                        >
                            <i className="fas fa-xs fa-trash-alt" />
                        </TooltipButton>
                    </div>
                );
            },
            width: 200,
            sortable: false,
            floatingFilter: false,
            filter: "",
        },
    ], []);

    const refreshCache = useCallback(() => {
        if (!gridRef || !gridRef.current || !gridRef.current.api)
            return;

        const datasource = getServerSideDatasource();
        gridRef.current.api.setServerSideDatasource(datasource);
        gridRef.current.api.purgeServerSideCache();
    }, [gridRef]);

    const onGridReady = useCallback((params) => {
        const datasource = getServerSideDatasource();
        // console.log("on grid ready", {datasource});
        params.api.setServerSideDatasource(datasource);
        params.api.sizeColumnsToFit();
        window.addEventListener('resize', function () {
            setTimeout(function () {
                params.api.sizeColumnsToFit();
            });
        });
    }, []);

    const getServerSideDatasource = () => {
        return {
            getRows: (getRowParams) => {
                var { request } = getRowParams;
                const { filterModel, sortModel } = request;

                const currentFilters = FiltersConverter(filterModel);
                const currentSortBy = SortsConverter(sortModel);
                const selectedSorts = (currentSortBy.length ? currentSortBy : defaultSort);

                setFilters(currentFilters);
                setSortBy(selectedSorts);

                const queryString = qs.stringify({ filters: currentFilters, sortBy: selectedSorts }, { allowDots: true });
                const url = `/candidateportal/${getRowParams.request.startRow ?? 0}/${pageSize}${queryString ? `?${queryString}` : ""}`;

                api.get(url)
                    .then((response) => {
                        console.log({response});
                        try {
                            const rows = response.data.data;
                            const result = {
                                rowData: rows,
                                rowCount: response.data.total,
                            };
                            setData(rows);
                            getRowParams.success(result);
                        }
                        catch (error) {
                            console.error({error});
                            setErrorMessage(`${errorMessageOpening}${error.message}`);
                            setErrorTitle(errorTitleOnGenerate);
                        }
                    })
                    .catch(error => {
                        getRowParams.fail();
                        console.error({error});
                        setErrorMessage(`${errorMessageOpening}${error.message}`);
                        setErrorTitle(errorTitleOnGenerate);
                    });
            },
        };
    };

    const loadingOverlayComponent = useMemo(() => Loader, []);

    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            onConfirm: () => {
                setErrorMessage(null);
                setErrorTitle(null);
            },
            message: errorMessage,
            title: errorTitle ?? errorTitleOnGenerate
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage, errorTitle]);

    return (
        <section className="main">
        {
            loading && <Loader />
        }
        {
            errorNotification?.length > 0 && errorNotification
        }
        {
            confirmation ?
            <SweetAlert
                title={confirmation.title}
                error
                confirmBtnText="OK"
                confirmBtnBsStyle="danger"
                onConfirm={() => {
                    handleConfirmed(confirmation);
                }}
            >
            {
                confirmation.message
            }
            </SweetAlert>
            :
            null
        }
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card className="no-transition">
                            <CardBody>
                                <Row>
                                    <Col sm={12}>
                                        <div className="ag-theme-alpine ag-grid clickable-row" style={{ width: '100%', height: 'calc(100vh - 200px)' }}>
                                            <AgGridReact
                                                ref={gridRef}
                                                //domLayout={'autoHeight'}
                                                columnDefs={columnDefs}
                                                defaultColDef={defaultColDef}
                                                loadingOverlayComponent={loadingOverlayComponent}
                                                //sideBar={sideBar}
                                                suppressColumnVirtualisation={false}
                                                suppressRowVirtualisation={false}
                                                debounceVerticalScrollbar={false}
                                                rowBuffer={20}
                                                headerHeight={30}
                                                groupHeaderHeight={30}
                                                rowHeight={30}
                                                floatingFiltersHeight={30}
                                                // readOnlyEdit={true}
                                                suppressDragLeaveHidesColumns={true}
                                                suppressColumnMoveAnimation={true}
                                                animateRows={false}
                                                pagination={true}
                                                paginationPageSize={pageSize}
                                                cacheBlockSize={pageSize}
                                                rowModelType={'serverSide'}
                                                serverSideInfiniteScroll={true}
                                                onGridReady={onGridReady}
                                                sortingOrder={["asc", "desc"]}
                                                rowSelection={'multiple'}
                                                suppressRowClickSelection={true}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        {
            itemInEdit ?
            <EditorPortalUser
                data={itemInEdit}
                onClose={() => setItemInEdit(null)}
                onSave={() => {
                    setItemInEdit(null);
                    loadData();
                }}
            />
            :
            null
        }
        {
            candidateInEdit ?
            <CandidateEditor
                item={candidateInEdit}
                onClose={() => {
                    setCandidateInEdit(null);
                    loadData();
                }}
                onSaved={() => {
                    setCandidateInEdit(null);
                    loadData();
                }}
                lookups={{
                    ...lookups
                }}
            />
            :
            null
        }
        </section>
    );
};
