/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import api from "../../../../services/api";
import { DefaultColumnFilter } from '../../../react-table/filters';
import { useExpanded, useFilters, useFlexLayout, useSortBy, useTable } from 'react-table';
import Loader from '../../../loaders';
import { CardTitle, Col, Container, Row } from 'reactstrap';
import { TooltipButton } from '../../../inputs';
import Grid from '../../../Grid';
import qs from "qs";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from '../../../alerts';
import Editor from './Editor';

const defaultSort = [{ id: "project", desc: false }];

function RecruitmentApprovalFlow(props) {
    const [ data, setData ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ itemInEdit, setItemInEdit ] = useState(null);
    const [ pageNumber, setPageNumber ] = useState(1);
    const [ pageSize, setPageSize ] = useState(10);
    const [ totalRecords, setTotalRecords ] = useState(0);
    const [ filters, setFilters ] = useState([]);
    const [ sortBy, setSortBy ] = useState(defaultSort);
    const [ deleteAlert, setDeleteAlert ] = useState([]);
    const skipPageResetRef = useRef();

    const loadData = useCallback(() => {
        skipPageResetRef.current = true;
        const queryString = qs.stringify({filters, sortBy}, { allowDots: true });
        setItemInEdit(null);
        setData([]);

        setLoading(true);

        api.get(`/recruitmentapproval/flow/${(pageNumber - 1) * pageSize}/${pageSize}${queryString ? `?${queryString}` : ""}`)
            .then(({data}) => {
                setData(data.data);
                setTotalRecords(data.total);
            })
            .catch((error) => {
                console.error("error: ", error);
            })
            .finally(() => setLoading(false))
        ;
    }, [filters, sortBy, pageNumber, pageSize]);

    const handleDelete = useCallback((item) => {
        const success = () => {
            setDeleteAlert([<DeleteSuccessAlert onConfirm={() => setDeleteAlert([])} />]);
            if (data.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1);
            } else {
                loadData();
            }
        };
        const failure = () => setDeleteAlert([<DeleteFailedAlert onConfirm={() => setDeleteAlert([])} />]);
        const events = {
            onConfirm: () => api.delete(`/recruitmentapproval/messagesmaster/${item.recruitmentTypeId}`).then(success).catch(failure),
            onCancel: () => setDeleteAlert([]),
        };
        setDeleteAlert([<ConfirmDeleteAlert {...events} />]);
    }, [data, pageNumber]);

    useEffect(() => {
        loadData();
    }, [pageNumber, pageSize, filters, sortBy]);

    const filterTypes = useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
                })
            },
        }),
        []
    );

    const columns = useMemo(() => {
        const result = [
            {
                Header: "Recruitment Type",
                id: "recruitmentTypeName",
                accessor: (row) => row.recruitmentTypeName,
                filter: "text",
                width: 150,
                disableSortBy: false,
            },
            {
                Header: "Actions",
                id: 'button',
                Cell: ( { row: { original } } ) =>
                    (
                        <div>
                            <TooltipButton
                                id={`edit_${original.recruitmentTypeId}`}
                                title="Edit"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={() => setItemInEdit(original)}
                            >
                                <i className="fas fa-pencil-alt pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`delete_${original.recruitmentTypeId}`}
                                title="Delete"
                                className="btn-icon"
                                color="warning"
                                size="sm"
                                type="button"
                                onClick={() => handleDelete(original)}
                            >
                                <i className="fas fa-trash-alt pt-1"></i>
                            </TooltipButton>
                        </div>
                    ),
                width: 70,
            },
        ];

        return result;
    }, []);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: defaultSort,
                canSort: true,
            },
            filterTypes,
            defaultColumn,
            manualSortBy: true,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
        useExpanded
    );
    
    useEffect(() => {
        setFilters(state.filters);
    }, [state.filters]);
    
    useEffect(() => {
        setSortBy(state.sortBy);
    }, [state.sortBy]);
    
    return (
        <>
            {loading && <Loader />}
            {deleteAlert.length > 0 && deleteAlert}
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <CardTitle>
                            <h4 className="text-center">LRF Approval Setting</h4>
                        </CardTitle>
                        <TooltipButton
                            id="addnew"
                            title="Add New"
                            className="btn-icon ml-2 mb-2"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => {  
                                setItemInEdit({});
                            }}
                        >
                            <i className="fas fa-plus pt-1"></i>
                        </TooltipButton>
                        <Grid
                            height='calc(100vh - 460px)'
                            totalRecords={totalRecords}
                            pageSize={pageSize}
                            rows={rows}
                            tableProps={getTableProps()}
                            headerGroups={headerGroups}
                            tableBodyProps={getTableBodyProps()}
                            prepareRow={prepareRow}
                            onPagerChangePage={(pager) => {
                                setPageNumber(pager.currentPage);
                            }}
                            onPagerChangePageSize={(size) => {
                                setPageSize(size);
                            }}
                            needPaging={true}
                        />
                    </Col>
                </Row>
            </Container>
        {
            itemInEdit ?
            <Editor
                data={itemInEdit}
                onClose={() => {
                    setItemInEdit(null);
                    loadData();
                }}
                onSave={() => {
                    setItemInEdit(null);
                    loadData();
                }}
            />
            :
            null
        }
        </>
    );
}

export default RecruitmentApprovalFlow;
