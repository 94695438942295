import React from 'react';
import PropTypes from 'prop-types';
import {
    Pagination, PaginationItem, PaginationLink
 } from "reactstrap";
 import { DropdownBox } from "./dropdowns";
 import _ from "lodash";
 import { getWindowDimensions } from "../utils/index";

const propTypes = {
    //items: PropTypes.array.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onChangePageSize: PropTypes.func.isRequired,
    initialPage: PropTypes.number,
    pageSize: PropTypes.number,
    rowInPage: PropTypes.number,
    totalItems: PropTypes.number.isRequired,
}

const defaultProps = {
    initialPage: 1,
    pageSize: 10,
}

const pageSizes = [ { size: 10 }, { size: 20 }, { size: 50 }, { size: 100 } ]
function Pager(props) {
    const { rowInPage } = props;
    const [ state, setState ] = React.useState({ pager: {  } });

    React.useEffect(() => {        
        setPage(props.initialPage);
    }, [props.totalItems, props.pageSize]);

    const setPageSize = ({ size }) => {
        props.onChangePageSize(size);
    }

    const setPage = (page) => {
        var { totalItems, pageSize } = props;
        var pager = state.pager;

        // get new pager object for specified page
        pager = getPager(totalItems, page, pageSize);

        // get new page of items from items array
        //var pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

        // update state
        setState({ pager: pager });

        // call change page function in parent component
        props.onChangePage(pager);
    }

    const getPager = (totalItems, currentPage, pageSize) => {
        // default to first page
        currentPage = currentPage || 1;

        // default page size is 10
        pageSize = pageSize || 10;

        // calculate total pages
        var totalPages = Math.ceil(totalItems / pageSize);

        var startPage, endPage;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        // calculate start and end item indexes
        var startIndex = (currentPage - 1) * pageSize;
        var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    var pager = state.pager;

    if (!pager.pages) {
        return null;
    }

    const dimension = getWindowDimensions();
    const showPageButton = dimension.width >= 580;

    return (
        <div
            className="pagination-container d-flex justify-content-between"
        >
            <div style={{width: '100px'}}>
                {props.onChangePageSize && 
                    <DropdownBox 
                        data={pageSizes}
                        selectedItem={{ size: pager.pageSize }}
                        onChange={setPageSize}
                        idField="size"
                        valueField="size"
                        disableClear={true}
                    />
                }
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
                <Pagination
                    className="pagination pagination-default"
                    listClassName="d-flex justify-content-center flex-row"
                >
                    
                    <PaginationItem
                        disabled={pager.currentPage === 1 || pager.totalPages === 1}
                    >
                        <PaginationLink
                            aria-label="Previous"
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setPage(1);
                            }}
                        >
                            <span aria-hidden={true}>
                            <i
                                aria-hidden={true}
                                className="fa fa-angle-double-left"
                            ></i>
                            </span>
                        </PaginationLink>
                    </PaginationItem>
                    <PaginationItem
                        disabled={pager.currentPage === 1 || pager.totalPages === 1}
                    >
                        <PaginationLink
                            aria-label="Previous"
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setPage(pager.currentPage - 1);
                            }}
                        >
                            <span aria-hidden={true}>
                            <i aria-hidden={true} className="ni ni-bold-left"></i>
                            </span>
                        </PaginationLink>
                    </PaginationItem>

                    {
                        showPageButton ?
                        pager.pages.map((page, index) => <PaginationItem 
                                    disabled={pager.currentPage === page || pager.totalPages === 1}
                                    key={index}
                                    className={pager.currentPage === page ? 'active' : ''}
                                >
                                    <PaginationLink
                                    href="#"
                                    onClick={(e) => {
                                            e.preventDefault();
                                            setPage(page)
                                        }}
                                    >
                                    {page}
                                    </PaginationLink>
                                </PaginationItem>)
                        :
                        null
                    }

                    <PaginationItem
                        disabled={pager.currentPage === pager.totalPages || pager.totalPages === 1}
                    >
                        <PaginationLink
                            aria-label="Next"
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setPage(pager.currentPage + 1);
                            }}
                        >
                            <span aria-hidden={true}>
                            <i aria-hidden={true} className="ni ni-bold-right"></i>
                            </span>
                        </PaginationLink>
                    </PaginationItem>
                    <PaginationItem
                        disabled={pager.currentPage === pager.totalPages || pager.totalPages === 1}
                    >
                        <PaginationLink
                            aria-label="Next"
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setPage(pager.totalPages);
                            }}
                        >
                            <span aria-hidden={true}>
                            <i
                                aria-hidden={true}
                                className="fa fa-angle-double-right"
                            ></i>
                            </span>
                        </PaginationLink>
                    </PaginationItem>
                </Pagination>
                
                {
                    showPageButton ?
                    <small>
                        {`${rowInPage} of ${pager.totalItems} record(s)`}
                    </small>
                    :
                    <small>
                        {`page ${pager.currentPage} of ${pager.totalPages} page(s)`}
                    </small>
                }
            </div>
        </div>
    );
}

Pager.propTypes = propTypes;
Pager.defaultProps = defaultProps;
export default Pager;