import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useTable, useRowSelect, useFlexLayout, useFilters, useSortBy, useAsyncDebounce } from 'react-table';
import api from "../../../../services/api";

import Grid from '../../../Grid';
import {
    Container, Row, Col, Button, Card, CardBody, CardTitle, Collapse, FormGroup, Label, Input, Modal
} from "reactstrap";
import Loader from "../../../loaders";
import _ from "lodash";
import { TooltipButton } from '../../../inputs';
import { DefaultColumnFilter } from "../../../react-table/filters";
import qs from "qs";
import { ErrorNotification } from '../../../alerts';

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, id, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" ref={resolvedRef} id={`candidate_${id}`} {...rest} />
                    <label className="custom-control-label" htmlFor={`candidate_${id}`}></label>
                </div>
            </>
        )
    }
);

export default function SearchRehire(props) {

    const { dataExisting } = props;

    const [candidateIdExclusionList, setCandidateIdExclusionList] = React.useState([]);
    const [itemInSearch, setItemInSearch] = React.useState(null);
    const [tnaSkills, setTNASkills] = React.useState([]);
    const [potentialCandidates, setPotentialCandidates] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [candidateFilter, setCandidateFilter] = React.useState("");
    const [open, setOpen] = React.useState(true);

    // Grid data
    const [data, setData] = useState([]);
    const [validationMessage, setValidationMessage] = useState(null);
    const defaultSort = React.useMemo(() => [{ id: "slotNo", desc: false }],
        []
    );
    const [sortBy, setSortBy] = React.useState(defaultSort);
    const skipPageResetRef = React.useRef();
    const [filters, setFilters] = React.useState([]);
    const [advancedFilters, setAdvancedFilters] = useState([]);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [totalRecords, setTotalRecords] = React.useState(0);

    // Error message variables
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorTitle, setErrorTitle] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);
    const [errorMessageOpening, setErrorMessageOpening] = React.useState(`Error occured when processing the request: `);
    const [errorTitleOnGenerate, setErrorTitleOnGenerate] = React.useState(`Bulk Confirmation Error`);

    // BEGIN TABLE 1 VARIABLES --------------------------------------------------------------------------------------------------------------------------------------------
    const defaultColumn = React.useMemo(
        () => ({
            width: 190,
            disableSortBy: true,
        }), []);

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue).replace(/[/-]/g, "")
                            .toLowerCase()
                            .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                        : true
                })
            },
        }),
        []
    );

    const columns = React.useMemo(() => {
        return [
            //{
            //    id: 'selection',
            //    // The header can use the table's getToggleAllRowsSelectedProps method
            //    // to render a checkbox
            //    Header: ({ getToggleAllRowsSelectedProps }) => (
            //        <div>
            //            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} id="all" />
            //        </div>
            //    ),
            //    // The cell can use the individual row's getToggleRowSelectedProps method
            //    // to the render a checkbox
            //    Cell: ({ row }) => (
            //        <div>
            //            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} id={row.original.candidateId}
            //                //onChange={({ target }) => selectCandidates(target, row)}
            //            />
            //        </div>
            //    ),
            //    width: 30,
            //},
            {
                Header: "Surname",
                accessor: "surname",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "First Name",
                accessor: "firstName",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "Employee Number",
                accessor: "employeeNumber",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "State",
                accessor: (row) => row.state?.lookupValue,
                id: "state",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "Mobile",
                accessor: (row) => {
                    if (row.mobilePhoneNumber && row.mobilePhoneNumber !== "") {
                        const match = row.mobilePhoneNumber.match(/^([\dX]{4})([\dX]{3})([\dX]{3})$/)
                        if (match) {
                            return `${match[1]} ${match[2]} ${match[3]}`;
                        }
                    }
                    return row.mobilePhoneNumber;
                },
                id: "mobilephonenumber",
                Filter: DefaultColumnFilter,
                filter: "text",
            },
            {
                Header: "Email",
                accessor: "email",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "Position",
                accessor: "position.positionName",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "Advertising Reference",
                accessor: "adsReference",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "Status",
                accessor: "jobOrderStatus",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            }
        ]
    }, [data]);


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        selectedFlatRows,
        toggleRowSelected
    } = useTable(
        {
            columns,
            data,
            initialState: {
                "hiddenColumns": [],
                sortBy: defaultSort,
                canSort: true,
            },
            defaultColumn,
            manualSortBy: true,
            filterTypes,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        useFilters,
        useSortBy,
        useRowSelect,
        useFlexLayout,
        hooks => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    width: 30,
                    disableSortBy: true,
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => {
                        return (
                            <div>
                                {
                                    //!singleSelection &&
                                    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} id="all_rehire" />
                                }
                            </div>
                        )
                    },
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => {
                        const { original, values } = row;


                        return (
                            <div>
                                {
                                    row.original != null &&
                                    <IndeterminateCheckbox
                                        {...row.getToggleRowSelectedProps()} id={`check_${row.original.candidateId}`}
                                        onChange={(e) => {
                                            //console.log('e', e);
                                            const selected = row.isSelected;

                                            //if (singleSelection)
                                            //    toggleAllRowsSelected(false);


                                            row.toggleRowSelected(!selected);
                                        }}
                                    />
                                }
                            </div>
                        )
                    },
                },
                ...columns,
            ])
        },
        );

    // Update candidate id exclusion from search
    React.useEffect(() => {
        if (!dataExisting) {
            setCandidateIdExclusionList([]);
            return;
        }
        
        var candidateIdList = dataExisting.map((v) => v.candidateId);
        setCandidateIdExclusionList(candidateIdList);
    }, [dataExisting]);

    



    // END TABLE 1 VARIABLES --------------------------------------------------------------------------------------------------------------------------------------------

    // BEGIN LOAD DATA ---------------------------------------------------------------------------------------------------
    const loadData = useCallback(() => {

        // Preparation
        skipPageResetRef.current = true;
        const queryString = qs.stringify({ filters, sortBy, advancedFilters, excludedIds: candidateIdExclusionList }, { allowDots: true });
        setData([]);
        setLoading(true);


        try {
            api.get(`/candidate/${(pageNumber - 1) * pageSize}/${pageSize}${queryString ? `?${queryString}` : ""}`)
                .then(({ data }) => {
                    setData(data.data);
                    setTotalRecords(data.total);
                }).catch((error) => {
                    console.error("error: ", error);
                }).finally(() => setLoading(false));
        }
        catch (error) {
            setLoading(false);
            setErrorMessage(`${errorMessageOpening}${error.message}`);
        }

    }, [filters, sortBy, pageNumber, pageSize, advancedFilters, candidateIdExclusionList]);

    React.useEffect(() => {
        loadData();
    }, [filters, sortBy, pageNumber, pageSize, advancedFilters, candidateIdExclusionList]);


    React.useEffect(() => {
        setFilters(state.filters);
    }, [state.filters]);

    React.useEffect(() => {
        setSortBy(state.sortBy);
    }, [state.sortBy]);

    // END LOAD DATA -----------------------------------------------------------------------------------------------------

    React.useEffect(() => {
        if (props.jobOrderCandidateId > 0) {
            setLoading(true);

            let apiCalls = [
                api.get(`/jobordercandidate/${props.jobOrderCandidateId}`),
                api.get(`/jobordercandidate/${props.jobOrderCandidateId}/tnaskills`),
                api.get(`/jobordercandidate/${props.jobOrderCandidateId}/searchcd/all`)
            ];

            Promise.all(apiCalls)
                .then((response) => {
                    console.log("response: ", response);
                    setItemInSearch(response[0].data);
                    setTNASkills(response[1].data);
                    setPotentialCandidates(response[2].data);
                })
                .catch((error) => console.error(error))
                .finally(() => setLoading(false));
        }
    }, [props.jobOrderCandidateId]);



    const handleAdd = useCallback(() => {

        var selectedItem = selectedFlatRows.map((v) => v.original);
        props.onSaved(selectedItem);

        // Uncheck all
        selectedFlatRows.forEach((row) => {
            //const { row } = v;
            var selected = row.isSelected;
            row.toggleRowSelected(!selected);
        });


    }, [selectedFlatRows]);


    const handleAddAndClose = useCallback(() => {
        handleAdd();
        props.onClose();

        // Uncheck all
        selectedFlatRows.forEach((row) => {
            //const { row } = v;
            var selected = row.isSelected;
            row.toggleRowSelected(!selected);
        });

    }, [selectedFlatRows]);


    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            onConfirm: () => {
                setErrorMessage(null);
                setErrorTitle(null);
            },
            message: errorMessage,
            title: errorTitle ?? errorTitleOnGenerate
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage, errorTitle]);




    return (
        <>
            <Modal
                isOpen={open}
                className="modal-xl m-4"
                modalClassName="modal-fullwidth"
            >
                {(loading) && <Loader />}
                {errorNotification.length > 0 && errorNotification}
                <div
                    className="modal-header"
                >
                    <h5
                        className="modal-title"
                    >
                        Candidate Search
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        onClick={props.onClose}
                        type="button"
                    >
                        <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                    </button>
                </div>
                <div
                    className="modal-body"
                >
                    <Collapse isOpen={validationMessage} className="mb-4">
                        <div className="alert alert-warning" role="alert">{validationMessage}</div>
                    </Collapse>
                    {/*<Grid
                        height='calc(100vh - 436px)'
                        rows={rows}
                        tableProps={getTableProps()}
                        headerGroups={headerGroups}
                        tableBodyProps={getTableBodyProps()}
                        prepareRow={prepareRow}
                    />*/}

                    <Grid
                        height='calc(100vh - 436px)'
                        totalRecords={totalRecords}
                        pageSize={pageSize}
                        rows={rows}
                        tableProps={getTableProps()}
                        headerGroups={headerGroups}
                        tableBodyProps={getTableBodyProps()}
                        prepareRow={prepareRow}
                        onPagerChangePage={(pager) => {
                            setPageNumber(pager.currentPage);
                        }}
                        onPagerChangePageSize={(size) => {
                            setPageSize(size);
                        }}
                        needPaging={true}
                    />

                </div>
                <div
                    className="modal-footer"
                >
                    <Button
                        color="secondary"
                        onClick={props.onClose}
                        type="button"
                    >
                        Close
                    </Button>
                    <Button
                        color="secondary"
                        type="button"
                        onClick={() => {
                            handleAdd();
                        }}
                    >
                        Add
                    </Button>
                    <Button
                        color="info"
                        type="button"
                        onClick={() => {
                            handleAddAndClose();
                        }}
                    >
                        Add & Close
                    </Button>
                </div>

            </Modal>
        </>
    )

}
