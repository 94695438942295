import React, { useEffect } from 'react';

export default (props) => {
    const {node, column, node: {data}} = props;

    return (
        <div key={`${node.rowIndex}-${column.instanceId}`}
            className="border border-info px-3 py-1 d-flex flex-wrap justify-content-center"
            style={{ backgroundColor: 'white', minWidth: '200px' }}
        >
            <div className="w-100">
                Withdraw Reason:<br/><b>{data?.withdrawStatus?.lookupValue}</b><br/><br/>
                Comments:<br/><b>{data?.withdrawComment ?? "N/A"}</b>
            </div>
        </div>
    );
};
