/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import api from "../../../../services/api";
import {
    Label, FormGroup, Button, Modal, Container, Row, Col, Input
 } from "reactstrap"; 
import { DropdownBox } from "../../../dropdowns";
import Loader from "../../../loaders";
import fileDownload from "js-file-download";
import { useDropzone } from 'react-dropzone';
import SweetAlert from "react-bootstrap-sweetalert";
import cn from "classnames";
import { ErrorNotification } from "../../../alerts"
import ContractPositionDialog from './ContractPositionDialog';

// request a weekday along with a long date
var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };

export default function ContractDialog(props) {
    // console.log('ContractDialog', props)
    const {jobOrderCandidate} = props;
    const [ loading, setLoading ] = React.useState(false);
    const [ selectedTemplate, setSeletectedTemplate ] = React.useState({});
    const [ generatedContract, setGeneratedContract ] = React.useState({});
    const [ contractError, setContractError ] = React.useState(false);
    const [ generatedContractName, setGeneratedContractName ] = useState(null);

    const [ contractTemplates, setContractTemplates ] = React.useState([]);
    const [ itemInEdit, setItemInEdit ] = React.useState({});

    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorTitle, setErrorTitle] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);
    const [errorMessageOpening, setErrorMessageOpening] = React.useState(`Error occured when processing the request: `);
    const [errorTitleOnGenerate, setErrorTitleOnGenerate] = React.useState(`Contract Template File Error`);
    const [showPositionDialog, setShowPositionDialog] = useState(false);

    useEffect(() => {
        try {
            if (!generateContract)
                return;

            const pdfName = generatedContract.pdfFilename?.split(".").slice(0, generatedContract.pdfFilename?.split(".").length - 1).join("");
            const docxName = generatedContract.docxFilename?.split(".").slice(0, generatedContract.pdfFilename?.split(".").length - 1).join("");

            setGeneratedContractName(pdfName ?? docxName);
        }
        catch (error) {
            setErrorMessage(`${errorMessageOpening}${error.message}`);
            setLoading(false);
        }
    }, [generatedContract]);
    
    React.useEffect(() => {
        setLoading(true);

        const projectId = jobOrderCandidate.projectId;
        const companyId = jobOrderCandidate.companyId;
        
        let apiCalls = [
            api.get(`/lookup/contracttemplate?projectId=${projectId}${companyId ? `&companyId=${companyId}` : ""}`),
            api.get(`/generatedcontract/${jobOrderCandidate.jobOrderCandidateId}/joc`),
        ];

        Promise.all(apiCalls)
        .then((response) => {
            // console.log('data', response[0].data)
            setContractTemplates(response[0].data.map(m => ({ ...m, html: `<span class='ddl-wrap'><span class='ddl-header'>${m.lookupLabel}</span><span class='ddl-value'>${m.lookupValue}</span></span>` })));
            setGeneratedContract(response[1].data);
        })
        .catch((error) => {
            console.error("error: ", error);
            setErrorMessage(`${errorMessageOpening}${error.message}`);
        })
        .finally(() => setLoading(false));

    }, []);

    const downloadAttachment = React.useCallback((type) => {

            setLoading(true);
            let url = `generatedcontract/download/${generatedContract.generatedContractId}/${type}`;
            api.get(url, { responseType: 'blob' })
            .then(blob => {
                fileDownload(blob.data, type == "pdf" ? generatedContract.pdfFilename : generatedContract.docxFilename);
            }).catch(error => {
                console.error(error);
                setErrorMessage(`${errorMessageOpening}${error.message}`);
            }).finally(() => setLoading(false))
        
    }, [generatedContract])

    const {
        getRootProps, 
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone(
        {
            maxFiles: 1,
            multiple: false,
            accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            onDrop: ([file], fileRejections) => {
                // console.log(file, fileRejections)
                setItemInEdit( { ...itemInEdit, file } );
            }
        });


    const files = React.useMemo(() => {
        let name, size, file;

        if (itemInEdit.file) {
            name = itemInEdit.file.name;
            size = itemInEdit.file.size;
            file = itemInEdit.file;
        } else if (itemInEdit.filename) {
            name = itemInEdit.filename;
            size = itemInEdit.fileSizeInBytes;
            file = itemInEdit;
        } else {
            return null;
        }
        return <aside className="dropzone-list">
            <a onClick={() => downloadAttachment(file)}>{name} - {size} bytes</a>
        </aside>
    }, [ itemInEdit.file ]);

    const downloadErrorDocument = useCallback(() => {
        if (!jobOrderCandidate.candidateId)
            return;

        setLoading(true);
        const url = `generatedcontract/error/${jobOrderCandidate.candidateId}`;
        api.get(url, { responseType: 'blob' })
            .then(blob => {
                const {headers} = blob;
                const filename = headers["content-disposition"].split(";")[1].split("\"")[1];
                fileDownload(blob.data, filename);
            })
            .catch(error => {
                console.error(error);
                setErrorMessage(`${errorMessageOpening}${error.message}`);
            })
            .finally(() => setLoading(false))
        ;
    }, [jobOrderCandidate]);

    const dropzoneClasses = React.useMemo(() => cn({
        'dropzone': true,
        'mt-2': true,
        'active': isDragActive,
        'accept': isDragAccept,
        'reject': isDragReject
    }), [
        isDragActive,
        isDragAccept,
        isDragReject
    ]);

    const generateContract = useCallback((position, callback) => {
        // console.log('selectedTemplate', {...selectedTemplate, joc: jobOrderCandidate})
        // if (selectedTemplate && selectedTemplate.lookupId) {
            setLoading(true);
            api.post(`generatedcontract/generate`, { jobOrderCandidateId: jobOrderCandidate.jobOrderCandidateId, contractTemplateId: selectedTemplate.lookupId, contractTemplateType: selectedTemplate.lookupType, positionId: position?.positionId ?? null })
            .then(response => {
                setGeneratedContract(response.data);

                if (callback)
                    callback();
            }).catch(error => {
                if (error.response.data?.message === "Contract Terms") {
                    setContractError({ message: `Project '${jobOrderCandidate.projectName}' (${error.response.data.locationName} - ${error.response.data.positionName}) missing Terms and Conditions.` });
                } else if (error.response?.data?.message === "Rate") {
                    setContractError({ message: `Project '${jobOrderCandidate.projectName}' (${error.response.data.employmentCategoryName} - ${error.response.data.locationName} - ${error.response.data.positionName}) missing Rate.` });
                } else if (error.response?.data === "Contract Template") {
                    setContractError({ message: `Project '${jobOrderCandidate.projectName}' missing Contract Template.` });
                } else if (error.response?.data === "Project") {
                    setContractError({ message: `Cannot find project info for Job Order.` })
                } else if (error.response?.data?.message === "Recruitment Person") {
                    setContractError({ message: `Recruitment Person is empty.` })
                } else if (error.response?.data?.message === "Job Info") {
                    setContractError({ message: `Cannot find Job Info for Job Order, please check Job Order Candidate.` })
                } else if (error.response?.data?.message === "Lookup Value Recruitment Phone") {
                    setContractError({ message: `Cannot find Lookup Value for Recruitment Phone.` })
                } else if (error.response?.data?.message === "Base Contract") {
                    setContractError({ message: `Cannot find Base Contract.` })
                } else if (error.response?.data?.message === "Template Content") {
                    setContractError({ message: `Cannot find Template Content.` })
                } else if (error.response?.data?.message === "Job Order Candidate") {
                    setContractError({ message: `Cannot find Job Order Candidate.` })
                } else if (error.response?.data?.message === "Document Type") {
                    setContractError({ message: `Cannot find Document Type.` })
                } else if (error.response?.data?.message && error.response?.data?.message.includes("Technical error")) {
                    setErrorTitle(errorTitleOnGenerate);
                    setErrorMessage(`${errorMessageOpening}${error.response?.data?.message}`);
                } else if (error.response?.data === "Invalid document parameter") {
                    setErrorTitle(errorTitleOnGenerate);
                    setErrorMessage((
                        <>
                        {
                            errorMessageOpening
                        }
                        Error when generating contract. Click <u className="cursor-pointer" onClick={() => downloadErrorDocument()}>here</u> to download the detail error message in the document.
                        </>
                    ));
                } else if (error.response?.data?.message) {
                    setErrorTitle(errorTitleOnGenerate);
                    setErrorMessage(`${errorMessageOpening}Unexpected error ${error.response?.data?.message}`);
                } else {
                    setErrorTitle(errorTitleOnGenerate);
                    setErrorMessage(`${errorMessageOpening}${error.message}`);
                }
                console.error(error.response);
            }).finally(() => setLoading(false));
        // }        
    }, [jobOrderCandidate, selectedTemplate, downloadErrorDocument]);

    const manualUpload = React.useCallback(() => {
        setLoading(true);

        var formData = new FormData();

        formData.append("jobOrderCandidateId", jobOrderCandidate.jobOrderCandidateId);
        if (!!generatedContract.generatedContractId) {
            formData.append("generatedContractId", generatedContract.generatedContractId);
        }
        
        if (itemInEdit.file) {
            formData.append("file", itemInEdit.file);
        }

        api.post(`generatedcontract/manual`, formData)
        .then(response => {
            setGeneratedContract(response.data)
        }).catch(error => {
            console.error(error);
            setErrorMessage(`${errorMessageOpening}${error.message}`);
        }).finally(() => setLoading(false))
    }, [jobOrderCandidate, generatedContract, itemInEdit]);

    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            onConfirm: () => {
                setErrorMessage(null);
                setErrorTitle(null);
            },
            message: errorMessage,
            title: errorTitle
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage, errorTitle]);

    return (
        <Modal
            isOpen={true}
            className="modal-lg contract-dialog"
            modalClassName="db-example-modal-lg"
        >
            {(loading) && <Loader />}
            {errorNotification.length > 0 && errorNotification}
            <div className="modal-header">
                <h5 className="modal-title">{`Contract - ${jobOrderCandidate.candidateName} - Job Order #${jobOrderCandidate.jobOrderId}`}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div className="modal-body">
                <div className="dialog-wrapper">
                    <div className="dialog-section">
                        <h5>Generate New</h5>
                        <div className="dialog-form">
                            <Row>
                                <Col xs={9}>
                                    <FormGroup>
                                        <Label>
                                            Template
                                        </Label>
                                        <DropdownBox 
                                            data={contractTemplates}
                                            selectedItem={selectedTemplate}
                                            html={true}
                                            onChange={(item) => { 
                                                setSeletectedTemplate(item);
                                            }}
                                        />
                                    </FormGroup>
                                </Col> 
                                <Col xs={3} className="d-flex align-items-end justify-content-start">
                                    <Button className="mb-3 w-100" onClick={() => setShowPositionDialog(true)}>GO</Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="dialog-section">
                        <h5>Download Current Contract</h5>
                        <div className="dialog-form">
                            {
                                generatedContract.generatedContractId
                                ? (<div className="d-flex flex-wrap justify-content-start align-items-center">
                                    <button type="button" className="btn btn-xl btn-outline-secondary h-100" onClick={() => downloadAttachment("docx")}>
                                        <span className="btn-inner--icon">
                                            <i className="far fa-file-word"></i>
                                        </span>
                                    </button>
                                    <button type="button" className="btn btn-xl btn-outline-secondary h-100" onClick={() => downloadAttachment("pdf")}>
                                        <span className="btn-inner--icon">
                                            <i className="far fa-file-pdf"></i>
                                        </span>
                                    </button>
                                    <p className="m-0">
                                        {generatedContractName}<br/>
                                        {
                                        generatedContract.overwrittenDate 
                                        ? `Uploaded on ${new Intl.DateTimeFormat('en-AU', options).format(new Date(generatedContract.overwrittenDate))}` 
                                        : `Generated on ${new Intl.DateTimeFormat('en-AU', options).format(new Date(generatedContract.generatedDate))}`
                                        }
                                    </p>
                                </div>)
                                : (<p>A Contract has not been generated for this item yet.</p>)
                            }
                        </div>
                    </div>
                    <div className="dialog-section">
                        <h5>Manual Upload</h5>
                        <div className="dialog-form">
                            <Row>
                                <Col xs={9}>
                                    <div {...getRootProps({className: dropzoneClasses})}>
                                        <input {...getInputProps()} />
                                        {files || <p>Drag 'n' drop a file here, or click to select a file</p>}
                                    </div>
                                </Col>
                                <Col xs={3} className="d-flex align-items-end justify-content-start">
                                    <Button className="mb-3 w-100" onClick={manualUpload} disabled={!itemInEdit?.file}>UPLOAD</Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal-footer d-flex flex-wrap justify-content-center"
            >
                <Button
                    color="primary"
                    onClick={props.onClose}
                    type="button"
                >
                    Done
                </Button>
            </div>
        {
            contractError &&
            <SweetAlert
                warning
                title={<h4 className="mb-0 text-center">{errorTitleOnGenerate}</h4>}
                confirmBtnText="OK"
                confirmBtnBsStyle="danger"
                onConfirm={() => {
                    setContractError(false);
                }}
            >
                <span>{contractError.message}</span>
            </SweetAlert>
        }
        {
            showPositionDialog &&
            <ContractPositionDialog
                jobOrderCandidateId={jobOrderCandidate?.jobOrderCandidateId}
                onClose={() => setShowPositionDialog(false)}
                onSubmit={(position) => {
                    setShowPositionDialog(false);
                    generateContract(position);
                }}
            />
        }
        </Modal>
    );
}