import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";

export default function MissingApproverAlert(props) {
    const {
        onConfirm,
        message
    } = props;

    return (
        <SweetAlert
            
            error
            showCancel={false}
            confirmBtnText="Ok"
            confirmBtnBsStyle="danger"
            title={<h4 className="mb-0">Missing Approver</h4>}
            onConfirm={onConfirm}
            focusCancelBtn
        >
            <p>{message}</p>
        </SweetAlert>
    );
}