import React, { useEffect, useState } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { formatDate } from '../../../utils';
import { DatePicker } from '../../date-pickers';
import moment from "moment";
import { ComboBox } from '../../dropdowns';

function ComboboxColumnFilter({
    column: {
        filterValue,
        preFilteredRows,
        setFilter
    },
    isLookup,
    endpoint,
    idField,
    valueField,
    defaultValue
})
{
    const [ selectedItem, setSelectedItem ] = useState(defaultValue ?? null);

    useEffect(() => {
        setFilter(selectedItem ? selectedItem[valueField] : "");
    }, [selectedItem]);

    return (
        <InputGroup>
            <ComboBox
                endpoint={endpoint}
                isLookup={isLookup}
                idField={idField}
                valueField={valueField}
                selectedItem={selectedItem}
                minLength={0}
                onChange={(item) => {
                    setSelectedItem(item);
                }}
            />
        </InputGroup>
    )
}

export default ComboboxColumnFilter;