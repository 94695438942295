const ratesValidation = [
    {
        field: "projectId",
        tests: [
            {
                type: "required",
                message: "Project is required.",
                validate: (obj) => !obj.projectId,
            }
        ]
    },
    {
        field: "employmentCategoryId",
        tests: [
            {
                type: "required",
                message: "Employment Category is required.",
                validate: (obj) => {
                    return !obj.employmentCategoryId
                },
            }
        ]
    },
    {
        field: "positionId",
        tests: [
            {
                type: "required",
                message: "Position is required.",
                validate: (obj) => {
                    return (!obj.positionId && obj.positionIds.length === 0) || (!!obj.positionId && obj.positionIds.length !== 0)
                },
            }
        ]
    },
    {
        field: "startDate",
        tests: [
            {
                type: "required",
                message: "Start Date is required.",
                validate: (obj) => !obj.startDate,
            }
        ]
    },
    {
        field: "fulltimeRate",
        tests: [
            {
                type: "required",
                message: "Filltime Rate is required.",
                validate: (obj) => !obj.fulltimeRate,
            }
        ]
    },
    // {
    //     field: "holidayRatePercent",
    //     tests: [
    //         {
    //             type: "required",
    //             message: "Weekend/Public Holiday is required.",
    //             validate: (obj) => obj.holidayRatePercent === 0 ? false : !obj.holidayRatePercent,
    //         }
    //     ]
    // },
    // {
    //     field: "casualLoadingPercent",
    //     tests: [
    //         {
    //             type: "required",
    //             message: "Casual Loading is required.",
    //             validate: (obj) => obj.casualLoadingPercent === 0 ? false : !obj.casualLoadingPercent,
    //         }
    //     ]
    // }
];
//fulltimeRate
//holidayRatePercent
//casualLoadingPercent
export {
    ratesValidation
};