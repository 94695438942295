import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from "moment";
import api from '../../../../services/api';
import { DefaultColumnFilter, DateColumnFilter } from '../../../react-table/filters';
import { useTable, useFlexLayout, useFilters, useSortBy } from 'react-table';
import Grid from '../../../Grid';
import Loader from '../../../loaders';

export default function DispatchHistory(props) {
    const {
        itemInEdit,
    } = props;
    const skipPageResetRef = useRef();

    const [data, setData] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [lookups, setLookups] = React.useState({});
    const [filters, setFilters] = React.useState([]);
    const defaultSort = React.useMemo(() => [],[]);
    const [sortBy, setSortBy] = React.useState(defaultSort);
    const [candidateId, setCandidateId] = useState(0);

    const refreshData = useCallback(() => {
        setData([]);

        if (!candidateId)
            return;

        setLoading(true);
        skipPageResetRef.current = true;
        api.get(`candidate/dispatchhistory/${candidateId}`)
            .then(response => {
                setData(response.data);
            })
            .catch(error => console.log({error}))
            .finally(() => setLoading(false))
            ;
    }, [candidateId]);

    useEffect(() => {
        if (candidateId === (itemInEdit?.candidateId ?? 0))
            return;

        setCandidateId(itemInEdit?.candidateId ?? 0);
    }, [itemInEdit?.candidateId]);
    
    useEffect(() => {
        refreshData();
    }, [candidateId]);

    const columns = useMemo(() => [
        {
            id: "projectName",
            Header: "Project",
            accessor: (row) => row.projectName,
            Filter: DefaultColumnFilter,
            filter: "text",
            width: 100,
            disableSortBy: false,
            disableFilters: false,
        },
        {
            id: "dispatchUnitName",
            Header: "Dispatch Unit",
            accessor: (row) => row.dispatchUnitName,
            Filter: DefaultColumnFilter,
            filter: "text",
            width: 100,
            disableSortBy: false,
            disableFilters: false,
        },
        {
            id: "hrispositionMasterName",
            Header: "Roster Position",
            accessor: (row) => row.hrispositionMasterName,
            Filter: DefaultColumnFilter,
            filter: "text",
            width: 135,
            //width: 130,
            disableSortBy: false,
            disableFilters: false,
        },
        {
            id: "positionName",
            Header: "Employed Position",
            accessor: (row) => row.positionName,
            Filter: DefaultColumnFilter,
            filter: "text",
            //width: 100,
            width: 125,
            //width: 150,
            disableSortBy: false,
            disableFilters: false,
        },
        {
            id: "shiftName",
            Header: "Shift",
            accessor: (row) => row.shiftName,
            Filter: DefaultColumnFilter,
            filter: "text",
            width: 60,
            //width: 70,
            disableSortBy: false,
            disableFilters: false,
        },
        {
            id: "confirmationStatus",
            Header: "Confirmation",
            accessor: (row) => row.status,
            Filter: DefaultColumnFilter,
            filter: "text",
            width: 110,
            //width: 170,
            disableSortBy: false,
            disableFilters: false,
        },
        {
            id: "entity",
            Header: "Entity",
            accessor: (row) => row.companyName,
            Filter: DefaultColumnFilter,
            filter: "text",
            width: 100,
            disableSortBy: false,
            disableFilters: false,
        },
        {
            id: "mobeDate",
            Header: "Mobe Date",
            Cell: ({ row }) => {
                const { original } = row;
                return original.nullableStartDate ? moment(original.nullableStartDate, "YYYY-MM-DD").format(`dd, DD.MM.YYYY`) : "-";
            },
            accessor: (row) => row.nullableStartDate ? moment(row.nullableStartDate, "YYYY-MM-DD").format(`DD.MM.YYYY`) : "-",
            Filter: (e) => DateColumnFilter({ ...e, dateFormat: "DD.MM.YYYY", dateMask: "99.99.9999" }),
            filter: "text",
            width: 100,
            //width: 150,
            disableSortBy: false,
            disableFilters: false,
        },
        {
            id: "demobeDate",
            Header: "Demobe Date",
            Cell: ({ row }) => {
                const { original } = row;
                return original.nullableEndDate ? moment(original.nullableEndDate, "YYYY-MM-DD").format(`dd, DD.MM.YYYY`) : "-";
            },
            accessor: (row) => row.nullableEndDate ? moment(row.nullableEndDate, "YYYY-MM-DD").format(`DD.MM.YYYY`) : "-",
            Filter: (e) => DateColumnFilter({ ...e, dateFormat: "DD.MM.YYYY", dateMask: "99.99.9999" }),
            filter: "text",
            width: 100,
            //width: 150,
            disableSortBy: false,
            disableFilters: false,
        },
    ], [data]);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: false,
        disableFilters: false,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
    } = useTable(
        {
            columns,
            data,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
    );

    React.useEffect(() => {
        setFilters(state.filters);
    }, [state.filters]);

    React.useEffect(() => {
        setSortBy(state.sortBy);
    }, [state.sortBy]);

    return (
        <>
            {loading && <Loader />}
            <Grid
                rows={rows}
                tableProps={getTableProps()}
                headerGroups={headerGroups}
                tableBodyProps={getTableBodyProps()}
                prepareRow={prepareRow}
                height={'calc(42vh)'}
            />
        </>
    );
}