import React, { useCallback, useEffect, useState } from 'react';
import {
    Label, FormGroup, Row, Col, Collapse, Container, Input, Button
} from "reactstrap";
import Loader from '../../../loaders';
import { useTable, useExpanded, useFilters, useSortBy, useFlexLayout } from 'react-table';
import _ from "lodash";
import Grid from '../../../Grid';
import { DefaultColumnFilter, DateColumnFilter } from '../../../react-table/filters';
import { TooltipButton } from '../../../inputs';
import { Mode } from '../../../../utils';
import { DatePicker } from '../../../date-pickers';
import { formatDate } from "../../../../utils";
import moment from "moment";
import api from "../../../../services/api";
import qs from "qs";
import DuplicateNotification from "../../../alerts/DuplicateNotification"
import { IndeterminateCheckbox } from '../../../react-table/controls';
import InactiveDatesAudit from './InactiveDatesAudit';

const dateFormats = [
    "YYYY-MM-DD",
    "DD-MM-YYYY",
    "DD/MM/YYYY",
    "YYYY-MM-DDT00:00:00"
];

export default function InactiveDates(props) {
    const {
        itemInEdit,
        setItemInEdit,
    } = props;

    const [loading, setLoading] = React.useState(false);
    const [drawerMode, setDrawerMode] = React.useState(Mode.NONE);
    const [inactiveDate, setInactiveDate] = useState(null);
    const [data, setData] = useState([]);
    const [dataInPage, setDataInPage] = useState([]);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [newId, setNewId] = useState(0);
    const [duplicateNotification, setDuplicateNotification] = useState([]);
    const [showAudit, setShowAudit] = useState(false);
    const skipPageResetRef = React.useRef();

    const [startDate_, setStartDate_] = useState(null);
    const [endDate_, setEndDate_] = useState(null);
    const [reason, setReason] = useState("");

    const [trimStartDate_, setTrimStartDate_] = useState(true);
    const [trimEndDate_, setTrimEndDate_] = useState(true);

    const [invalidStartDate, setInvalidStartDate] = useState(null);
    const [invalidEndDate, setInvalidEndDate] = useState(null);
    const [invalidReason, setInvalidReason] = useState(null);
    const [openedCollapse, setOpenedCollapse] = React.useState("collapse-1");
    const [candidateId, setCandidateId] = useState(0);

    const loadData = useCallback(() => {
        skipPageResetRef.current = true;
        setLoading(true);

        api.get(`/candidate/inactivedates/${candidateId}/${(pageNumber - 1) * pageSize}/${pageSize}`)
            .then(({ data }) => {
                const newData = [...data.data];
                setData(newData);
                setTotalRecords(data.total);
            })
            .catch((error) => console.error(error.response))
            .finally(() => setLoading(false))
            ;
    }, [candidateId]);

    useEffect(() => {

        if (!data || !pageSize || !pageNumber) {
            console.log("Can't show inactive date due incomplete dependencies vars");
            console.log("data", data, "pageSize", pageSize, "pageNumber", pageNumber);

            setDataInPage([]);
            return;
        }

        // Determine Data position
        var start = ((pageNumber - 1) * pageSize);
        var end = start + pageSize;

        // Get search result
        var searchResult = [...data];
        if (!trimStartDate_ && startDate_)
            searchResult = searchResult.filter(w => moment(w.startDate).isSameOrBefore(startDate_, 'day'));

        if (!trimEndDate_ && endDate_)
            searchResult = searchResult.filter(w => moment(w.endDate).isSameOrAfter(endDate_, 'day'));

        if (reason)
            searchResult = searchResult.filter(w => w.reason.toLowerCase().includes(reason.toLowerCase()));

        // console.log("reason", reason);
        // console.log("data", data);

        // Get current page data
        var currentPage = searchResult.slice(start, end);
        setTotalRecords(searchResult.length);
        setDataInPage(currentPage);

    }, [data, pageNumber, pageSize, trimStartDate_, trimEndDate_, startDate_, endDate_, reason]);

    useEffect(() => {
        if ((itemInEdit?.candidateId ?? 0) === candidateId)
            return;

        setCandidateId(itemInEdit?.candidateId ?? 0);
    }, [itemInEdit?.candidateId]);

    useEffect(() => {
        if (!candidateId)
            return;

        loadData();
    }, [candidateId]);

    useEffect(() => {
        setItemInEdit({ ...itemInEdit, candidateInactiveDates: [...data] });
    }, [data]);

    // BEGIN Auto scroll on add or edit mode -----------------------------------------------------------------------------------------
    useEffect(() => {
        scrollToAddEditDrawer();
    }, [drawerMode]);

    const scrollToAddEditDrawer = useCallback(() => {
        if (drawerMode && drawerMode != Mode.NONE) {

            // Scroll to insert or update
            setTimeout(function () { //Start the timer
                var scrollObject = document.getElementById("editInactiveDateContainer");
                scrollObject.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }.bind(this), 500);
        }
    }, [drawerMode]);
    // END Auto scroll on add or edit mode -----------------------------------------------------------------------------------------


    // BEGIN SYNC VARIABLES startDate, endDate -----------------------------------------------------------------------------------------
    useEffect(() => {
        if (inactiveDate && !('startDate' in inactiveDate)) {
            setStartDate_("");
        } else if (inactiveDate && startDate_ != inactiveDate.startDate) {
            setStartDate_(inactiveDate.startDate);
        }

        if (inactiveDate && !('endDate' in inactiveDate)) {
            setEndDate_("");
        } else if (inactiveDate && endDate_ != inactiveDate.endDate) {
            setEndDate_(inactiveDate.endDate);
        }

        if (!inactiveDate) {
            setStartDate_("");
            setEndDate_("");
        }
    }, [inactiveDate, inactiveDate?.startDate, inactiveDate?.endDate]);

    useEffect(() => {
        if (inactiveDate && startDate_ != inactiveDate.startDate) {

            setInactiveDate({
                ...inactiveDate,
                startDate: startDate_
            });

        }
        if (inactiveDate && endDate_ != inactiveDate.endDate) {

            setInactiveDate({
                ...inactiveDate,
                endDate: endDate_
            });
        }

    }, [startDate_, endDate_]);
    // END SYNC VARIABLES startDate, endDate -----------------------------------------------------------------------------------------

    const clearValidation = () => {
        setInvalidStartDate(null);
        setInvalidEndDate(null);
        setInvalidReason(null);
    }


    const columns = React.useMemo(() => [
        {
            Header: "Start Date",
            id: "startDate",
            accessor: (row) => row.startDate ? `${moment(row.startDate).format("DD/MM/YYYY")}` : "",
            Filter: (e) => DateColumnFilter({ ...e, dateFormat: "DD/MM/YYYY", dateMask: "99/99/9999", className: "datepicker-on-top" }),
            filter: "text",
            width: 100,
            disableSortBy: false,
            disableFilters: true,
        },
        {
            Header: "End Date",
            id: "endDate",
            accessor: (row) => row.endDate ? `${moment(row.endDate).format("DD/MM/YYYY")}` : "",
            Filter: (e) => DateColumnFilter({ ...e, dateFormat: "DD/MM/YYYY", dateMask: "99/99/9999", className: "datepicker-on-top" }),
            filter: "text",
            width: 100,
            disableSortBy: false,
            disableFilters: true,
        },
        {
            Header: "Reason",
            id: "reason",
            accessor: "reason",
            Filter: DefaultColumnFilter,
            filter: "text",
            width: 100,
            disableSortBy: false,
            disableFilters: true,
        },
        {
            id: 'buttons',
            Header: "Action",
            width: 180,
            canResize: false,
            Cell: ({ row: { original, isGrouped } }) => (
                (original && !isGrouped) ?
                    (
                        <div className="td-action text-center">
                            <TooltipButton
                                id={`edit_${original.candidateInactiveDateId}`}
                                title="Edit"
                                className="btn-icon"
                                color="default"
                                size="xsm"
                                type="button"
                                onClick={() => {
                                    setInactiveDate({ ...original });
                                    setDrawerMode(Mode.EDIT);
                                }}
                            >
                                <i className="fas fa-pencil-alt pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`delete_${original.candidateInactiveDateId}`}
                                title="Delete"
                                className="btn-icon"
                                color="warning"
                                size="xsm"
                                type="button"
                                onClick={() => {
                                    setLoading(true);
                                    const index = data.findIndex(val => val.candidateInactiveDateId === original.candidateInactiveDateId);
                                    //console.log("Delete: ", { inactiveDate: original, itemInEdit, index, candidateInactiveDates: itemInEdit?.candidateInactiveDates, data });

                                    if (index < 0) {
                                        setLoading(false);
                                        return;
                                    }

                                    const deleted = data.filter(val => val.candidateInactiveDateId === original.candidateInactiveDateId && val.candidateInactiveDateId > 0);
                                    const removedInactiveDates = itemInEdit?.removedInactiveDates ? [...itemInEdit?.removedInactiveDates] : [];

                                    if (deleted.length > 0)
                                        removedInactiveDates.push({ ...deleted[0] });

                                    const allocations = [...data];
                                    allocations.splice(index, 1);
                                    //console.log({ deleted, removedInactiveDates, allocations });
                                    setItemInEdit({ ...itemInEdit, removedInactiveDates: removedInactiveDates });
                                    setData(allocations);
                                    setTotalRecords(allocations.length);
                                    setLoading(false);
                                }}
                            >
                                <i className="fas fa-trash-alt pt-1"></i>
                            </TooltipButton>
                        </div>
                    ) :
                    (
                        <div className="td td-action"></div>
                    )
            )
        },
    ], [dataInPage]);

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue).replace(/[/-]/g, "")
                            .toLowerCase()
                            .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                        : true
                })
            },
        }),
        []
    );

    const defaultSort = React.useMemo(() => [{ id: "projectName", desc: false }],
        []
    );
    const defaultFilter = React.useMemo(() => props.project ? [{ id: "projectName", value: props.project.projectName }] : [],
        []
    );

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
        disableFilters: true,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data: dataInPage,
            initialState: {
                "hiddenColumns": [],
                sortBy: defaultSort,
                canSort: true,
                filters: defaultFilter
            },
            defaultColumn,
            // manualSortBy: true,
            filterTypes,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        // useRowSelect,
        useFlexLayout,
        useFilters,
        useSortBy,
    );

    return (
        <>
            <Row>
                <Col xs={12}>
                    {duplicateNotification.length > 0 && duplicateNotification}
                    {loading && <Loader />}
                    <Collapse id="editInactiveDateContainer"
                        isOpen={drawerMode !== Mode.NONE}
                        style={{ zIndex: "9999", border: "0.0625rem solid #dee2e6" }}
                        className="bg-white w-100 rounded p-3 mb-2"
                    >
                        <Container fluid>
                            <Row>
                                <Col xs={12} md={6}>
                                    <FormGroup>
                                        <Label>
                                            Start Date <span className="text-danger">*</span>
                                        </Label>
                                        <DatePicker
                                            name="startDate"
                                            id="startDate"
                                            type="text"
                                            required
                                            value={startDate_ ? formatDate(startDate_) : ""}
                                            onChange={(value) => {
                                                var dateStr = !value ? null : formatDate(value, "YYYY-MM-DDT00:00:00");
                                                setStartDate_(dateStr);
                                                //setInactiveDate({
                                                //    ...inactiveDate,
                                                //    startDate: dateStr
                                                //});
                                            }}
                                            closeOnSelect
                                        />
                                        <Collapse id="invalidStartDate" isOpen={(invalidStartDate == null ? false : true)}><small className="text-danger">{invalidStartDate}</small></Collapse>
                                    </FormGroup>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormGroup>
                                        <Label>
                                            End Date <span className="text-danger">*</span>
                                        </Label>
                                        <DatePicker
                                            name="endDate"
                                            id="endDate"
                                            type="text"
                                            required
                                            value={endDate_ ? formatDate(endDate_) : ""}
                                            onChange={(value) => {
                                                var dateStr = !value ? null : formatDate(value, "YYYY-MM-DDT00:00:00");
                                                setEndDate_(dateStr);
                                                //setInactiveDate({
                                                //    ...inactiveDate,
                                                //    endDate: dateStr
                                                //});
                                            }}
                                            closeOnSelect
                                        />
                                        <Collapse id="invalidEndDate" isOpen={(invalidEndDate == null ? false : true)}><small className="text-danger">{invalidEndDate}</small></Collapse>
                                    </FormGroup>
                                </Col>
                                <Col lg={12}>
                                    <FormGroup>
                                        <Label>
                                            Reason <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            name="reason"
                                            id="reason"
                                            type="textarea"
                                            rows={4}
                                            required
                                            value={inactiveDate?.reason ?? ""}
                                            onChange={(e) =>
                                                setInactiveDate({
                                                    ...inactiveDate,
                                                    reason: e.target.value
                                                })
                                            }
                                        />
                                        <Collapse id="invalidReason" isOpen={(invalidReason == null ? false : true)}><small className="text-danger">{invalidReason}</small></Collapse>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="d-flex justify-content-end">
                                    <TooltipButton
                                        id="cancel"
                                        title="Cancel"
                                        className="btn-icon"
                                        color="warning"
                                        size="sm"
                                        type="button"
                                        onClick={() => {
                                            // Close Drawer
                                            setDrawerMode(Mode.NONE);

                                            // Clear validation 
                                            setInvalidStartDate(null);
                                            setInvalidEndDate(null);
                                            setInvalidReason(null);
                                        }}
                                    >
                                        Cancel
                                    </TooltipButton>
                                    <TooltipButton
                                        id="add"
                                        title="Add"
                                        className="btn-icon ml-2"
                                        color="default"
                                        size="sm"
                                        type="button"
                                        onClick={() => {

                                            // Clear validation first
                                            clearValidation();
                                            var validated = false;


                                            // Validations
                                            // Validate start date
                                            if (!inactiveDate?.startDate) {
                                                setInvalidStartDate("Start Date is mandatory.");
                                                validated = true;
                                            }
                                            // Validate end date
                                            if (!inactiveDate?.endDate) {
                                                setInvalidEndDate("End Date is mandatory.");
                                                validated = true;
                                            }

                                            // Validate reason
                                            if (!inactiveDate?.reason) {
                                                setInvalidReason("Reason is mandatory.");
                                                validated = true;
                                            }


                                            // Validate start date
                                            if (startDate_ && moment(startDate_, 'YYYY-MM-DD').isValid() != true) {
                                                setInvalidStartDate("Start Date is invalid format.");
                                                validated = true;
                                            }

                                            // Validate end date
                                            if (endDate_ && moment(endDate_, 'YYYY-MM-DD').isValid() != true) {
                                                setInvalidEndDate("End Date is invalid format.");
                                                validated = true;
                                            }

                                            // Abort if validation detected
                                            if (validated)
                                                return;

                                            clearValidation();

                                            setLoading(true);

                                            // IF ADD MODE
                                            if (drawerMode === Mode.ADD) {

                                                // ADD DATA VALIDATION
                                                const validateIndex = _.findIndex(data, o =>
                                                    o.startDate == inactiveDate.startDate && o.endDate == inactiveDate.endDate
                                                );

                                                // If data found duplicated
                                                if (validateIndex != -1) {
                                                    var events = {
                                                        onConfirm: () => setDuplicateNotification([])
                                                    }
                                                    setDuplicateNotification([<DuplicateNotification {...events} />]);
                                                    setLoading(false);
                                                    validated = true;
                                                    return;
                                                }

                                                // Save new data to object
                                                const newData = [...data, { ...inactiveDate }];
                                                setNewId(newId - 1);
                                                setData(newData);
                                                setTotalRecords(data.length + 1);
                                            }

                                            // IF EDIT MODE
                                            else if (drawerMode === Mode.EDIT) {


                                                // Initiate old data
                                                const inactiveDates = [...data];
                                                const index = _.findIndex(inactiveDates, o => o.candidateInactiveDateId === inactiveDate.candidateInactiveDateId);


                                                // Validate data
                                                // Find duplicate data
                                                const validateIndex = _.findIndex(inactiveDates, o =>
                                                    o.startDate == inactiveDate.startDate && o.endDate == inactiveDate.endDate &&
                                                    o.candidateInactiveDateId != inactiveDate.candidateInactiveDateId
                                                );

                                                // If duplicate found
                                                if (validateIndex != -1) {
                                                    var events = {
                                                        onConfirm: () => setDuplicateNotification([])
                                                    }
                                                    setDuplicateNotification([<DuplicateNotification {...events} />]);
                                                    setLoading(false);
                                                    validated = true;
                                                    return;
                                                }

                                                // Remove old data and replace it with new one
                                                inactiveDates.splice(index, 1, {
                                                    ...inactiveDate
                                                });
                                                setData(inactiveDates);
                                            }

                                            // Close drawer and clear temp data
                                            setInactiveDate(null);
                                            setDrawerMode(Mode.NONE);
                                            setLoading(false);
                                        }}
                                    >
                                        {drawerMode === Mode.ADD ? "Add" : drawerMode === Mode.EDIT ? "Update" : ""}
                                    </TooltipButton>
                                </Col>
                            </Row>
                        </Container>
                    </Collapse>
                    <Collapse
                        isOpen={drawerMode === Mode.NONE}
                    >
                        <Row>
                            <Col xs={12} md={6}>
                                <TooltipButton
                                    id="addnew"
                                    title="Add New"
                                    className="btn-icon ml-3 mb-2"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        setStartDate_("");
                                        setEndDate_("");
                                        setInactiveDate({ candidateId: itemInEdit?.candidateId, candidateInactiveDateId: newId });
                                        setDrawerMode(Mode.ADD);
                                    }}
                                >
                                    <i className="fas fa-plus pt-1"></i>
                                </TooltipButton>
                            </Col>
                            <Col xs={12} md={6} className="text-right">
                                <TooltipButton
                                    id="showauditlog"
                                    title="Show Audit Log"
                                    className="btn-icon ml-3 mb-2"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        setShowAudit(true);
                                    }}
                                >
                                    <i className="fas fa-list pt-1 mr-1"></i> Show Audit Log
                                </TooltipButton>
                            </Col>
                        </Row>
                    </Collapse>

                    <Row>
                        <Col sm={12}>
                            <hr className="mt-3 mb-3" style={{ width: "100%" }} />
                            <h5 className="mt-0 mb-3">
                                <Button
                                    aria-expanded={openedCollapse === "collapse-1"}
                                    onClick={() =>
                                        setOpenedCollapse(openedCollapse === "collapse-1" ? "" : "collapse-1")
                                    }
                                    className="w-100 text-primary text-left no-shadow pl-0 pr-0"
                                    color="link"
                                    type="button"
                                >
                                    Inactive Dates Filter
                                    <i className="ni ni-bold-down float-right pt-1"></i>
                                </Button>
                            </h5>
                        </Col>
                    </Row>
                    <Collapse isOpen={openedCollapse === "collapse-1"}>
                        <Row className="mb-3">
                            <Col sm={4}>
                                <FormGroup>
                                    <div className="w-100 d-flex justify-content-between">
                                        <Label>
                                            Start Date
                                        </Label>
                                        <IndeterminateCheckbox
                                            id="trimStartDate_"
                                            type="checkbox"
                                            onChange={() => {
                                                setTrimStartDate_(!trimStartDate_);
                                            }}
                                            checked={trimStartDate_}
                                            customTitle="Trim"
                                        />
                                    </div>
                                    <DatePicker
                                        name="startDate_"
                                        id="startDate_"
                                        type="search"
                                        required
                                        value={formatDate(startDate_)}
                                        onChange={(value) => {
                                            setStartDate_(value);
                                        }}
                                        closeOnSelect
                                        disabled={trimStartDate_}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup>
                                    <div className="w-100 d-flex justify-content-between">
                                        <Label>
                                            End Date
                                        </Label>
                                        <IndeterminateCheckbox
                                            id="trimEndDate_"
                                            type="checkbox"
                                            onChange={() => {
                                                setTrimEndDate_(!trimEndDate_);
                                            }}
                                            checked={trimEndDate_}
                                            customTitle="Trim"
                                        />
                                    </div>
                                    <DatePicker
                                        name="endDate_"
                                        id="endDate_"
                                        type="search"
                                        required
                                        value={formatDate(endDate_)}
                                        onChange={(value) => {
                                            setEndDate_(value);
                                        }}
                                        closeOnSelect
                                        disabled={trimEndDate_}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>
                                        Reason
                                    </Label>
                                    <Input
                                        name="reason"
                                        id="reason"
                                        type="search"
                                        value={reason}
                                        onChange={(event) => {
                                            setReason(event.target.value);
                                            //console.log("value", event.target.value);
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Collapse>
                    <Row>
                        <Col sm="12">
                            <Grid
                                rows={rows}
                                tableProps={getTableProps()}
                                headerGroups={headerGroups}
                                tableBodyProps={getTableBodyProps()}
                                prepareRow={prepareRow}
                                totalRecords={totalRecords}
                                pageSize={pageSize}
                                onPagerChangePage={(pager) => {
                                    setPageNumber(pager.currentPage);
                                }}
                                onPagerChangePageSize={(size) => {
                                    setPageSize(size);
                                }}
                                needPaging={true}
                                height="auto"
                                style="min-height:300px"
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            {
                showAudit &&
                <InactiveDatesAudit
                    candidate={itemInEdit}
                    candidateId={candidateId}
                    setCandidateId={setCandidateId}
                    onClose={() => {
                        setShowAudit(false);
                    }}
                />
            }

        </>
    )
}