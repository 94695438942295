import React from 'react';
import api from "../../../../services/api";
import {
    Input, Label, FormGroup, Button, Modal, Row, Col
 } from "reactstrap"; 
import Loader from "../../../loaders";
import { MilestonesTable, JobOrderMilestonesTable, MoveDir } from "../../LabourRequest/JobOrder/Tables";
import _ from "lodash";
import { TooltipButton } from '../../../inputs';
import { TrimObject } from '../../../../helpers/StringHelper';

const middleColWidth = 74;
const middleColStyle = {
    flex: `0 0 ${middleColWidth}px`,
    maxWidth: `${middleColWidth}px`,
    position: "relative",
    width: "100%",
    paddingRight: "15px",
    paddingLeft: "15px",
    display: "flex",    
    flexDirection: "column",
}
const outerColStyle = {
    flex: `0 0 calc(50% - ${middleColWidth / 2}px)`,
    maxWidth: `calc(50% - ${middleColWidth / 2}px)`,
    position: "relative",
    width: "100%",
    paddingRight: "15px",
    paddingLeft: "15px",
    display: "flex"
}

export default function Editor(props) {
    const [ itemInEdit, setItemInEdit ] = React.useState({});
    const [ loading, setLoading ] = React.useState(false);

    const [ milestones, setMilestones ] = React.useState([]);
    const [ selectedMilestones, setSelectedMilestones ] = React.useState([]);
    const [ jobOrderMilestones, setJobOrderMilestones ] = React.useState([]);

    React.useEffect(() => {
        setLoading(true);

        let apiCalls = [
            api.get("/lookup/jobordermilestonemaster"),
        ];
        if (props.item.milestoneTemplateId > 0) {
            apiCalls.push(api.get(`milestonetemplate/${props.item.milestoneTemplateId}`))
        }

        Promise.all(apiCalls)
        .then((response) => {

            const allMilestones = response[0].data;
            if (props.item.milestoneTemplateId > 0) { 
                const { milestoneTemplateDetails, ...milestoneTemplate } = response[1].data;

                setItemInEdit(milestoneTemplate);
    
                if (milestoneTemplateDetails && milestoneTemplateDetails.length > 0) {
                    
                    const selectedMilestoneIds = milestoneTemplateDetails.map(o => o.lookupId);                                    
                    const updatedMilestones = _.values(_.chain(allMilestones).keyBy("lookupId").omit(selectedMilestoneIds).value());
                    setMilestones(_.orderBy(updatedMilestones, o => o.lookupValue));
                    setJobOrderMilestones(milestoneTemplateDetails);
                }
                else 
                {
                    setMilestones(allMilestones);
                    setJobOrderMilestones([])
                }
            } else {
                setMilestones(allMilestones);
            }
            
        })
        .catch((error) => console.error("error: ", error))
        .finally(() => setLoading(false));

    }, []);

    const handleAddSelected = () => {
        const length = jobOrderMilestones.length;
        setJobOrderMilestones(_.concat(jobOrderMilestones, selectedMilestones.map((o, i) => ({...o, order: (length + i + 1)}))));

        const selectedMilestoneIds = selectedMilestones.map(o => o.lookupId);                                    
        const updatedMilestones = _.values(_.chain(milestones).keyBy("lookupId").omit(selectedMilestoneIds).value());

        setMilestones(_.orderBy(updatedMilestones, o => o.lookupValue));
        setSelectedMilestones([]);
    }

    const handleAdd = (item) => {
        const length = jobOrderMilestones.length;
        setJobOrderMilestones([ ...jobOrderMilestones, { ...item, order: length + 1 } ]);
        setMilestones(milestones.filter(f => f.lookupId !== item.lookupId));
        setSelectedMilestones([]);
    }
    
    const handleRemoveSelected = () => {
        const selectedMilestoneIds = selectedMilestones.map(o => o.lookupId); 
        const updatedJobOrderMilestones = _.values(_.chain(jobOrderMilestones).keyBy("lookupId").omit(selectedMilestoneIds).value());
        setJobOrderMilestones(_.orderBy(updatedJobOrderMilestones, o => o.order).map((o, i) => ({...o, order: i+1})));
        
        setMilestones(_.orderBy(_.concat(milestones, selectedMilestones), o => o.lookupValue));
        setSelectedMilestones([]);
    }

    const handleRemove = (item) => {
        setMilestones(_.orderBy([ ...milestones, { ...item } ], o => o.lookupValue));
        setJobOrderMilestones(jobOrderMilestones.filter(f => f.lookupId !== item.lookupId).map((o, i) => ({ ...o, order: i + 1 })));
        setSelectedMilestones([]);
    }

    const splitJoms = (item, dir) => {
        const upperList = _.slice(jobOrderMilestones, 0, item.order - dir);
        const lowerList = _.slice(jobOrderMilestones, item.order - dir);
        return {
            upperList,
            lowerList,
            swapItems: lowerList.splice(0, 2)
        }
    }

    const handleMoveUp = (item) => {
        const { upperList, lowerList, swapItems } = splitJoms(item, MoveDir.UP);

        const downItem = swapItems[0];
        const upItem = swapItems[1];

        setJobOrderMilestones([...upperList, { ...upItem, order: upItem.order-1 }, { ...downItem, order: downItem.order+1 }, ...lowerList]);
    }

    const handleMoveDown = (item) => {
        const { upperList, lowerList, swapItems } = splitJoms(item, MoveDir.DOWN);

        const upItem = swapItems[0];
        const downItem = swapItems[1];

        console.log({
            upperList, lowerList, swapItems, upItem, downItem
        })

        setJobOrderMilestones([...upperList, { ...downItem, order: downItem.order-1 }, { ...upItem, order: upItem.order+1 }, ...lowerList]);
    }

    const handleTextChanged = ({ target: { name, value } }) => {
        setItemInEdit({ ...itemInEdit, [name]: value });
    }

    const handleSave = (item) => {
        setLoading(true)

        // Trim every string property
        item = TrimObject(item);
        setItemInEdit(item);

        // Save
        const data = JSON.stringify({...item, milestoneTemplateDetails: jobOrderMilestones.map(o => ({
            ...o,
            jobOrderMilestoneMasterId: o.lookupId,
            orderingIndex: o.order
        }))});
        const save = item.milestoneTemplateId > 0 
            ? api.put(`milestonetemplate/${item.milestoneTemplateId}`, data)
            : api.post(`milestonetemplate`, data)

        save
        .then((response) => {
            props.onSaved();
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }
    
    return (
        <Modal
            isOpen={true}
            className="modal-xl"
            modalClassName="db-example-modal-xl"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Milestone Template
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}>x</span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                {/* <Container> */}
                    {(loading) && <Loader />}
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Name
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    name="templateName"
                                    id="templateName"
                                    type="text"
                                    required
                                    value={itemInEdit.templateName || ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>  
                        <Col xs={12}>
                            <div className="row">
                                <div style={outerColStyle}>
                                    <MilestonesTable 
                                        data={milestones}
                                        onSelect={setSelectedMilestones}
                                        onAdd={handleAdd}
                                        hideLabel={true}                         
                                    />
                                </div>
                                <div style={middleColStyle}>
                                    <div >
                                        <TooltipButton
                                            id="add"
                                            title="Add Selected"
                                            className="btn-icon mt-5 mb-3 py-2 px-3"
                                            color="default"
                                            type="button"
                                            outline
                                            onClick={handleAddSelected}
                                        >
                                            <i className="fas fa-chevron-right"></i>
                                        </TooltipButton>
                                    </div>
                                    <div >
                                        <TooltipButton
                                            id="remove"
                                            title="Remove Selected"
                                            className="btn-icon py-2 px-3"
                                            color="default"
                                            type="button"
                                            outline
                                            onClick={handleRemoveSelected}
                                        >
                                            <i className="fas fa-chevron-left"></i>
                                        </TooltipButton>
                                    </div>
                                </div>
                                <div style={outerColStyle}>
                                    <JobOrderMilestonesTable 
                                        data={jobOrderMilestones}
                                        onSelect={setSelectedMilestones}
                                        onRemove={handleRemove}
                                        onMoveUp={handleMoveUp}
                                        onMoveDown={handleMoveDown} 
                                        hideLabel={true}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                {/* </Container> */}
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={props.onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave(itemInEdit)}
                    type="button"
                >
                    Save Changes
                </Button>
            </div>
        </Modal>
    )
}