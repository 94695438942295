import React, { useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import api from "../../../../services/api";
import qs from "qs";
import {
    Container, Row, Col, Button, Card, CardBody, CardTitle, UncontrolledTooltip, Progress, Label
} from "reactstrap";

//import { DefaultColumnFilter } from "../../../react-table/filters";
import { useTable, useFilters, useSortBy, useFlexLayout, useBlockLayout } from 'react-table';
import CreateJobOrder from "./CreateJobOrder";
import CandidateEditor from "../../Candidates/Editor";
import CandidateEditorEmail from "../../Candidates/EditorEmail";
import CandidateEditorSMS from "../../Candidates/EditorSMS";
//import SearchCandidates from "./SearchCandidates";
import CandidateSearch from "./CandidateSearch";
import JobOrderCandidateEditor from "./JobOrderCandidateEditor";
import EditJobOrder from "./EditJobOrder";
import moment from "moment";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../../alerts";
import CopyToClipboard from "react-copy-to-clipboard";
import { TooltipButton } from '../../../inputs';
import BulkMilestoneEditor from "./BulkMilestoneEditor";
import fileDownload from "js-file-download";
import Loader from "../../../loaders";
import Grid from "../../../Grid";
//import DateFilter from "../../../date-filters"
//import { DatePicker } from '../../../date-pickers';
import { DefaultColumnFilter, DateColumnFilter } from "../../../react-table/filters";
import { formatDate, formatDateTime } from '../../../../utils';
import { IndeterminateCheckbox } from '../../../react-table/controls';
import { useSticky } from 'react-table-sticky';

const timeoutLength = 1500;
const defaultText = "Copy link to clipboard";
const copiedText = "Copied!"
const TooltipContent = ({ scheduleUpdate, index, copiedIndex }) => {
    const [text, setText] = React.useState(defaultText);

    React.useEffect(() => {
        if (copiedIndex === index) {
            setText(copiedText);
            scheduleUpdate();
            const timeoutId = setTimeout(() => {
                setText(defaultText);
                scheduleUpdate();
            }, timeoutLength);
            return () => clearTimeout(timeoutId);
        }
    }, [copiedIndex, index, scheduleUpdate]);

    return (
        <>{text}</>
    );
}

export default function List(props) {
    //const { token } = useMsal();
    const history = useHistory();
    const [data, setData] = React.useState([]);

    const params = useParams();
    const [copiedIndex, setCopiedIndex] = React.useState(null);
    React.useEffect(() => {
        if (copiedIndex !== null) {
            const timeoutId = setTimeout(() => {
                setCopiedIndex(null);
            }, timeoutLength);

            return () => clearTimeout(timeoutId);
        }
    }, [copiedIndex]);

    // const [ jobOrder, setjobOrder ] = React.useState([]);
    // const [ recruitmentRequests, setRecruitmentRequests ] = React.useState([]);
    // const [ jobOrderMilestoneMaster, setJobOrderMilestoneMaster ] = React.useState([]);
    // const [ candidates, setCandidates ] = React.useState([]);
    const [candidateId, setCandidateId] = React.useState(null);
    const candidateCallback = React.useRef(null);
    const assingmentCallback = React.useRef(null);

    const [loading, setLoading] = React.useState(false);
    const [itemInEdit, setItemInEdit] = React.useState(null);
    const [createNew, setCreateNew] = React.useState(false);
    const [canAutoOpen, setCanAutoOpen] = React.useState(true);
    const [bulkMilestone, setBulkMilestone] = React.useState([]);
    const [showCancelledJobOrder, setShowCancelledJobOrder] = React.useState(false);
    const [showCompletedJobOrder, setShowCompletedJobOrder] = useState(false);

    const [emailCandidateId, setEmailCandidateId] = React.useState(null);
    const [smsCandidateId, setSMSCandidateId] = React.useState(null);
    const [itemSMS, setItemSMS] = React.useState([]);
    const [itemEmail, setItemEmail] = React.useState([]);
    const [jobOrderCandidate, setJobOrderCandidate] = React.useState(null);

    const [searchCandidate, setSearchCandidate] = React.useState(null);

    const [pageNumber, setPageNumber] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [deleteAlert, setDeleteAlert] = React.useState([]);
    const [lookups, setLookups] = React.useState({});
    const [filters, setFilters] = React.useState([]);

    const defaultSort = React.useMemo(() => [{ id: "projectName", desc: false }],
        []
    );

    const [sortBy, setSortBy] = React.useState(defaultSort);
    const skipPageResetRef = React.useRef();
    const apiurl = "joborder";

    React.useEffect(() => {
        if (params.action === "new")
            setCreateNew(true);
    }, [params.action])

    const loadData = React.useCallback(() => {
        const queryString = qs.stringify({ filters, sortBy, showCancelledJobOrder, showCompletedJobOrder }, { allowDots: true });
        skipPageResetRef.current = true;
        setItemInEdit(null);
        setCreateNew(false);
        setData([]);

        const url = `/${apiurl}/${(pageNumber - 1) * pageSize}/${pageSize}${params.requestId ? ("/" + params.requestId + "/" + params.action) : ""}${queryString ? `?${queryString}` : ""}`;
        setLoading(true);
        api.get(url)
            .then(({ data }) => {
                setData(data.data);
                setTotalRecords(data.total);
            }).catch((error) => {
                setItemInEdit(null);
                setData([]);
                console.error("error: ", {error});
            }).finally(() => setLoading(false));
    }, [filters, sortBy, pageNumber, pageSize, params, showCancelledJobOrder, showCompletedJobOrder]);

    const handleDelete = (item) => {
        const success = () => {
            setLoading(false);
            setDeleteAlert([<DeleteSuccessAlert key={0} onConfirm={() => setDeleteAlert([])} />]);
            if (data.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1);
            } else {
                loadData();
            }
        };
        const failure = () => {
            setLoading(false);
            setDeleteAlert([<DeleteFailedAlert key={0} onConfirm={() => setDeleteAlert([])} />]);
        };
        const events = {
            onConfirm: () => {
                setLoading(true);
                api.delete(`/${apiurl}/${item.jobOrderId}`).then(success).catch(failure);
            },
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert key={0} {...events} />])
    }

    React.useEffect(() => {
        loadData();
    }, [pageNumber, pageSize, filters, sortBy, params, showCancelledJobOrder, showCompletedJobOrder]);

    React.useEffect(() => {
        console.log("data", data);
        if (canAutoOpen && data && data.length === 1 && params.requestId && params.action && params.action === "joborder") {
            setItemInEdit(data[0]);
        }
    }, [canAutoOpen, data, params]);


    // React.useEffect(() => {
    //     api.get(`/lookup/jobordermilestonemaster`)
    //     .then(({data}) => {
    //         setJobOrderMilestoneMaster(data);
    //     })
    //     .catch((error) => console.error(error));
    // }, []);

    // React.useEffect(() => {
    //     api.get(`/lookup/candidate`) 
    //     .then(({data}) => {
    //         setCandidates(data);
    //     })
    //     .catch((error) => console.error(error));
    // }, []);

    const filterTypes = React.useMemo(() => ({
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
            })
        },

    }), []);

    const columns = React.useMemo(() => {
        return [
            {
                Header: "Id",
                id: "id",
                accessor: (row, id) => {
                    return (id + 1) + ((pageNumber - 1) * pageSize);
                },
                width: 100,
                Filter: DefaultColumnFilter,
                filter: "text",
            },
            {
                Header: "Project Name",
                id: "projectName",
                accessor: (row) => row.projectName,
                width: 300,
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
                disableFilters: false,
            },
            {
                Header: "Contract Owner",
                id: "contractOwner",
                accessor: (row) => row.contractOwner,
                width: 300,
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
                disableFilters: false,
            },
            {
                Header: "Position Name",
                id: "hrispositionMasterName",
                accessor: (row) => row.hrispositionMasterName,
                width: 200,
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
                disableFilters: false,
            },
            {
                Header: "Employed Position",
                id: "positionName",
                accessor: (row) => row.positionName,
                width: 200,
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
                disableFilters: false,
            },
            {
                Header: "LRF",
                id: "recruitmentRequestNumber",
                Cell: ({ row }) => {
                    return (<a href={`/lrf/${row.original.recruitmentRequestId}`} target='_blank'>{row.original.recruitmentRequestNumber}</a>);
                },
                accessor: (row) => row.recruitmentRequestNumber,
                width: 150,
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
                disableFilters: false,
            },
            {
                Header: "LRF Creation Date",
                id: "lrfCreationDate",
                accessor: (row) => !(row.lrfCreationDate) ? "N/A" : moment(row.lrfCreationDate).format("DD.MM.YYYY"),
                width: 200,
                Filter: (e) => DateColumnFilter({ ...e, dateFormat: "DD.MM.YYYY", dateMask: "99.99.9999" }),
                filter: "text",
                disableSortBy: false,
                disableFilters: false,
            },
            {
                Header: "Job Order Creation Date",
                id: "jobOrderCreationDate",
                accessor: (row) => moment(row.jobOrderCreationDate).format("DD.MM.YYYY"),
                width: 200,
                Filter: (e) => DateColumnFilter({ ...e, dateFormat: "DD.MM.YYYY", dateMask: "99.99.9999" }),
                filter: "text",
                disableSortBy: false,
                disableFilters: false,
                //sticky: "right"
            },
            {
                Header: "Job Location",
                id: "jobLocation",
                accessor: (row) => row.recruitLocation,
                width: 200,
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
                disableFilters: false,
            },
            {
                Header: "Quantity Required",
                id: "qty",
                accessor: (row) => row.requestDetailQuantity,
                width: 150,
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
                disableFilters: false,
            },
            {
                Header: "Expected Start",
                id: "startdt",
                accessor: (row) => row.startDateExpected ? moment(row.startDateExpected).format("DD.MM.YYYY") : "N/A",
                width: 125,
                Filter: (e) => DateColumnFilter({...e, dateFormat: "DD.MM.YYYY", dateMask: "99.99.9999"}),
                filter: "text",
                disableSortBy: false,
                disableFilters: false,
            },
            {
                Header: "Expected End",
                id: "enddt",
                accessor: (row) => row.finishDatePlanned ? moment(row.finishDatePlanned).format("DD.MM.YYYY") : "N/A",
                width: 125,
                Filter: (e) => DateColumnFilter({...e, dateFormat: "DD.MM.YYYY", dateMask: "99.99.9999"}),
                filter: "text",
                disableSortBy: false,
                disableFilters: false,
            },
            {
                Header: "Recruitment Person",
                id: "recruiter",
                accessor: (row) => row.recruitmentPerson?.lookupValue,
                width: 200,
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
                disableFilters: false,
            },
            {
                Header: "Dispatch Name",
                id: "dispatchUnitName",
                accessor: "dispatchUnitName",
                width: 200,
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
                disableFilters: false,
            },
            {
                Header: "Completion Percent",
                id: "completionPercent",
                width: 200,
                disableFilters: true,
                Cell: ({ row: { original } }) => {
                    if (original.isCancelled) {
                        return <span>Cancelled</span>
                    }
                    const completionPercent = original.completionDate ? 100 : Math.round(original.completionPercent * 100);
                    return (
                        <div className="w-100 progress-wrapper pt-0">
                            <div className="progress-info">
                                <div className="progress-percentage">
                                    <span>{completionPercent}%</span>
                                </div>
                            </div>
                            <Progress max="100" value={completionPercent} color="default" className="w-100"></Progress>
                        </div>
                    );
                },
                sticky: "right"
            },
            {
                Header: "Action",
                id: 'button',
                Cell: ({ row: { original } }) => {
                    return (
                        <div key={original.jobOrderId}>
                            <UncontrolledTooltip delay={0} placement="top" target={`link-${original.jobOrderId}`}>
                                {({ scheduleUpdate }) => {
                                    const index = original.jobOrderId;

                                    return (
                                        <TooltipContent {...{ scheduleUpdate, index, copiedIndex }} />
                                    );
                                }
                                }
                            </UncontrolledTooltip>
                            <CopyToClipboard
                                text={`${window.location.protocol}//${window.location.host}/home?job=${original.identifier}`}
                                onCopy={(value) => {
                                    setCopiedIndex(original.jobOrderId);
                                }}
                            >
                                <Button
                                    id={`link-${original.jobOrderId}`}
                                    className="btn-icon"
                                    color="default"
                                    size="xsm"
                                    type="button"
                                    onClick={() => { }}
                                >
                                    <i className="fas fa-clone pt-1"></i>
                                </Button>
                            </CopyToClipboard>
                            <TooltipButton
                                id={`edit_${original.jobOrderId}`}
                                title="Open"
                                className="btn-icon"
                                color="default"
                                size="xsm"
                                type="button"
                                onClick={() => {
                                    setItemInEdit(original);
                                }}
                            >
                                <i className="fas fa-folder-open pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`delete_${original.jobOrderId}`}
                                title="Delete"
                                className="btn-icon"
                                color="warning"
                                size="xsm"
                                type="button"
                                onClick={() => handleDelete(original)}
                            >
                                <i className="fas fa-trash-alt pt-1"></i>
                            </TooltipButton>
                        </div>
                    )
                },
                width: 150,
                sticky: "right"
            }
        ]
    }, [pageNumber, pageSize]);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: defaultSort,
                canSort: true,
            },
            filterTypes,
            manualSortBy: true,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
        useBlockLayout,
        useSticky,
    );

    React.useEffect(() => {
        setFilters(state.filters);
    }, [state.filters]);

    React.useEffect(() => {
        setSortBy(state.sortBy);
    }, [state.sortBy]);

    return (
        <section className="main">
            {loading && <Loader />}
            {deleteAlert.length > 0 && deleteAlert}
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card className="no-transition">
                            <CardBody>
                                <CardTitle>
                                    <h4 className="text-center">Job Orders {params.requestId && `LRF #${params.requestId}`}</h4>
                                </CardTitle>
                                <Row>
                                    <Col sm={6}>
                                        <TooltipButton
                                            id="addnew"
                                            title="Add New"
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={() => {
                                                //setItemInEdit({});
                                                setCreateNew(true);
                                            }}
                                        >
                                            <i className="fas fa-plus pt-1"></i>
                                        </TooltipButton>
                                        <TooltipButton
                                            id="export"
                                            title="Export to Excel"
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={() => {
                                                setLoading(true);

                                                let url = `joborder/export`;
                                                api.get(url, { responseType: 'blob' })
                                                    .then(blob => {
                                                        console.log("Download: ", blob);
                                                        fileDownload(blob.data, "joborder.xlsx");
                                                    }).catch(error => {
                                                        console.error(error)
                                                    }).finally(() => setLoading(false))

                                            }}
                                        >
                                            <i className="fas fa-file-excel"></i> Export
                                        </TooltipButton>
                                        <TooltipButton
                                            id="exportcandidate"
                                            title="Export Candidate to Excel"
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={() => {
                                                setLoading(true);

                                                let url = `joborder/exportcandidate`;
                                                api.get(url, { responseType: 'blob' })
                                                    .then(blob => {
                                                        console.log("Download: ", blob);
                                                        fileDownload(blob.data, "jobordercandidate.xlsx");
                                                    }).catch(error => {
                                                        console.error(error)
                                                    }).finally(() => setLoading(false))

                                            }}
                                        >
                                            <i className="fas fa-file-excel"></i> Export Candidate
                                        </TooltipButton>

                                    </Col>
                                    <Col sm={6} className="text-xs-left text-sm-right d-flex flex-wrap justify-content-end align-items-center">
                                        <IndeterminateCheckbox
                                            id="showCompletedJobOrderCheckBox"
                                            type="checkbox"
                                            labelClassName="job-order-control mr-3"
                                            onChange={() => {
                                                setShowCompletedJobOrder(!showCompletedJobOrder);
                                            }}
                                            checked={showCompletedJobOrder}
                                            customTitle="Show Completed Job Orders"
                                        />
                                        <IndeterminateCheckbox
                                            id="showCancelledJobOrderCheckBox"
                                            type="checkbox"
                                            labelClassName="job-order-control"
                                            onChange={() => {
                                                setShowCancelledJobOrder(!showCancelledJobOrder);
                                            }}
                                            checked={showCancelledJobOrder}
                                            customTitle="Show Cancelled Job Orders"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>

                                        <Grid
                                            height={'calc(100vh - 464px)'}
                                            totalRecords={totalRecords}
                                            pageSize={pageSize}
                                            rows={rows}
                                            tableProps={getTableProps()}
                                            headerGroups={headerGroups}
                                            tableBodyProps={getTableBodyProps()}
                                            prepareRow={prepareRow}
                                            onPagerChangePage={(pager) => {
                                                setPageNumber(pager.currentPage);
                                            }}
                                            onPagerChangePageSize={(size) => {
                                                setPageSize(size);
                                            }}
                                            needPaging={true}
                                        />
                                    </Col>
                                </Row>
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {
                itemInEdit && candidateId &&
                <CandidateEditor
                    item={{ candidateId }}
                    onClose={() => {
                        setCandidateId(null);

                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }

                    }}
                    onSaved={(candidateItem) => {
                        setCandidateId(null);

                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }
                    }}
                    lookups={{
                        ...lookups
                    }}
                />
            }
            {
                itemInEdit && searchCandidate &&
                <CandidateSearch
                    jobOrderCandidate={searchCandidate}
                    jobOrderCandidateId={searchCandidate.jobOrderCandidateId}
                    jobOrderCandidateIndex={searchCandidate.index}
                    onClose={() => {
                        setSearchCandidate(null);

                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }
                        if (assingmentCallback.current) {
                            assingmentCallback.current(null);
                        }
                    }}
                    onSaved={(item, index) => {

                        setSearchCandidate(null);

                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }

                        if (assingmentCallback.current) {
                            assingmentCallback.current({ item, index });
                        }


                    }}
                    lookups={{
                        ...lookups
                    }}
                />
            }
            {
                itemInEdit && (emailCandidateId > 0 || itemEmail.length > 0) &&
                <CandidateEditorEmail
                    candidateId={emailCandidateId}
                    items={itemEmail}
                    onClose={() => {
                        setEmailCandidateId(null);
                        setItemEmail([]);

                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }
                    }}
                    onSaved={() => {
                        setEmailCandidateId(null);

                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }
                    }}
                    lookups={{
                        ...lookups
                    }}
                />
            }
            {
                itemInEdit && ((smsCandidateId && smsCandidateId.candidateId > 0) || itemSMS.length > 0) &&
                <CandidateEditorSMS
                    candidateId={smsCandidateId?.candidateId}
                    refId={smsCandidateId?.jobOrderCandidateId}
                    items={itemSMS}
                    onClose={() => {
                        setSMSCandidateId(null);
                        setItemSMS([]);

                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }
                    }}
                    onSaved={() => {
                        setSMSCandidateId(null);

                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }
                    }}
                    lookups={{
                        ...lookups
                    }}
                    sourcePage="jobOrder"
                />
            }
            {
                itemInEdit && jobOrderCandidate &&
                <JobOrderCandidateEditor
                    jobOrder={itemInEdit}
                    jobOrderCandidateId={jobOrderCandidate.jobOrderCandidateId}
                    itemIndex={jobOrderCandidate.index}
                    onClose={(item) => {
                        setJobOrderCandidate(null);


                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }

                        if (assingmentCallback.current) {
                            assingmentCallback.current(item);
                        }
                    }}
                    onSaved={(item) => {
                        setJobOrderCandidate(null);

                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }
                    }}
                    lookups={{
                        ...lookups
                    }}
                />
            }
            {
                itemInEdit && bulkMilestone.length > 0 &&
                <BulkMilestoneEditor
                    candidates={bulkMilestone}
                    jobOrderId={itemInEdit.jobOrderId}
                    onClose={() => {
                        setBulkMilestone([]);
                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }
                    }}
                    onSaved={() => {
                        setBulkMilestone([]);
                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }
                    }}
                />
            }
            {
                itemInEdit &&
                <EditJobOrder
                    item={itemInEdit}
                    onClose={() => {
                        setItemInEdit(null);
                        setCanAutoOpen(false);
                        loadData();
                    }}
                    onSaved={() => {
                        loadData();
                    }}
                    onViewCandidate={(id, callback) => {

                        candidateCallback.current = callback;

                        setLoading(true);
                        let apiCalls = [
                            api.get(`/lookup/skillposition`),
                            api.get(`/lookup/candidatetype`),
                            api.get(`/lookup/salutation`),
                            api.get(`/lookup/preference`),
                            api.get(`/lookup/industry`),
                            api.get(`/lookup/gender`),
                            api.get(`/lookup/maritalStatus`),
                            api.get(`/lookup/candidateStatus`),
                            api.get(`/lookup/candidateFlag`),
                            api.get(`/lookup/residencyStatus`),
                            api.get(`/lookup/visatype`),
                            api.get(`/lookup/communicationtype`),
                            api.get(`/lookup/country`),
                            api.get(`/lookup/state`),
                            api.get(`/lookup/relationshiptype`),
                            api.get(`/lookup/candidateplatformorigin`),
                            api.get(`/lookup/recruitmentpeople`),
                            api.get(`/lookup/messagesmasters`),
                            api.get(`/emailtemplate`), // TODO: returns paged result - move to lookup
                            api.get(`/lookup/candidatediscipline`),
                            api.get(`/lookup/company`),
                            api.get(`/lookup/identifier`),
                            api.get(`/lookup/candidateevent`),
                        ];

                        Promise.all(apiCalls)
                            .then((data) => {
                                setLookups({
                                    skillPositions: data[0].data,
                                    candidateTypes: data[1].data,
                                    salutations: data[2].data,
                                    preferences: data[3].data,
                                    industries: data[4].data,
                                    genders: data[5].data,
                                    maritalStatuses: data[6].data,
                                    candidateStatuses: data[7].data,
                                    candidateFlags: data[8].data,
                                    residencyStatuses: data[9].data,
                                    visaTypes: data[10].data,
                                    communicationTypes: data[11].data,
                                    countries: data[12].data,
                                    states: data[13].data,
                                    relationshipTypes: data[14].data,
                                    candidatePlatformOrigin: data[15].data,
                                    recruitmentPeople: data[16].data,
                                    messagesMaster: data[17].data,
                                    emailTemplate: data[18].data.data,
                                    candidateDisciplines: data[19].data,
                                    companies: data[20].data,
                                    identifiers: data[21].data,
                                    events: data[22].data,
                                });
                            }).finally(() => {
                                setLoading(false);
                                callback(false);

                                if (id != null)
                                    setCandidateId(id);

                            });
                    }}
                    onViewSearchCandidate={(jobOrderCandidateId, index, callback, callbackNewAssignment) => {

                        candidateCallback.current = callback;
                        assingmentCallback.current = callbackNewAssignment;

                        setSearchCandidate({ jobOrderCandidateId, index });
                    }}
                    onViewJobOrderCandidate={(jobOrderCandidateId, index, callback, callbackNewAssignment) => {

                        candidateCallback.current = callback;
                        assingmentCallback.current = callbackNewAssignment;

                        setJobOrderCandidate({ jobOrderCandidateId, index });
                    }}
                    onViewCandidateEmail={(id, callback) => {

                        candidateCallback.current = callback;

                        setLoading(true);
                        let apiCalls = [
                            api.get(`/lookup/messagesmasters`),
                        ];

                        Promise.all(apiCalls)
                            .then((data) => {
                                setLookups({
                                    ...lookups,
                                    messagesMaster: data[0].data,
                                });
                            }).finally(() => {
                                setLoading(false);
                                callback(false);
                                setEmailCandidateId(id);
                            });
                    }}
                    onViewCandidateSMS={(id, jobOrderCandidateId, callback) => {

                        candidateCallback.current = callback;

                        setLoading(true);
                        let apiCalls = [
                            api.get(`/lookup/messagesmasters`),
                        ];

                        Promise.all(apiCalls)
                            .then((data) => {
                                setLookups({
                                    ...lookups,
                                    messagesMaster: data[0].data,
                                });
                            }).finally(() => {
                                setLoading(false);
                                callback(false);

                                setSMSCandidateId({ candidateId: id, jobOrderCandidateId });
                            });
                    }}
                    onViewCandidateEmails={(items, callback) => {
                        candidateCallback.current = callback;

                        setLoading(true);
                        let apiCalls = [
                            api.get(`/lookup/messagesmasters`),
                        ];

                        Promise.all(apiCalls)
                            .then((data) => {
                                setLookups({
                                    ...lookups,
                                    messagesMaster: data[0].data,
                                });
                            }).finally(() => {
                                setLoading(false);
                                callback(false);

                                setItemEmail(items);
                                setEmailCandidateId(0);
                            });
                    }}
                    onViewBulkCandidateSMS={(items, callback) => {


                        candidateCallback.current = callback;

                        setLoading(true);
                        let apiCalls = [
                            api.get(`/lookup/messagesmasters`),
                        ];

                        Promise.all(apiCalls)
                            .then((data) => {
                                setLookups({
                                    ...lookups,
                                    messagesMaster: data[0].data,
                                });
                            }).finally(() => {
                                setLoading(false);
                                callback(false);

                                setItemSMS(items);
                                setSMSCandidateId(null);
                            });
                    }}
                    onViewBulkMilestone={(items, callback) => {
                        candidateCallback.current = callback;
                        setBulkMilestone(items);
                        callback(false);
                    }}
                />
                // <JobOrderCreatorEditor 
                //     item={itemInEdit} 
                //     onClose={() => setItemInEdit(null)} 
                //     onSaved={() => loadData()}
                //     lookups={{
                //         // jobOrder, 
                //         //recruitmentRequests, 
                //         jobOrderMilestoneMaster, candidates
                //     }}
                // />
            }
            {
                createNew &&
                <CreateJobOrder
                    onClose={() => {
                        setCreateNew(false); history.push(`/${apiurl}${params.requestId ? ("/" + params.requestId) : ""}`);
                    }}
                    onSaved={() => {
                        loadData();
                        // history.push(`/${apiurl}${params.requestId ? ("/" + params.requestId) : ""}`);
                    }}
                />
            }
        </section>
    )

}