import React, { useCallback, useEffect } from 'react';
import {
    Input, Label, FormGroup, Button, Modal, Container, Row, Col, Alert
} from "reactstrap"; 
import { formatDateZeroTime } from "../../../utils";
import { RadioGroup } from "../../inputs";
import _ from "lodash";
import Loader from "../../loaders";
import api from "../../../services/api";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { debounce } from "lodash";
import '@progress/kendo-theme-bootstrap/dist/all.css';
import Multiselect from 'multiselect-react-dropdown';
import { ErrorNotification, DuplicateNotification } from '../../alerts';

export default function Setup(props) {
    
    const {
        trainingRequirements
    } = props.lookups;

    const [ tnaSetup, setTnaSetup ] = React.useState({ ...props.item, trainingRequirementId: trainingRequirements[0] ? trainingRequirements[0].lookupId : null });
    const [ loading, setLoading ] = React.useState(false);
    //const [ loadingPositions, setLoadingPositions ] = React.useState(false);
    const [ loadingTraining, setLoadingTraining ] = React.useState(false);
    //const [ positions, setPositions ] = React.useState([]);
    const [ training, setTraining ] = React.useState([]);
    //const [ validationAlert, setValidationAlert ] = React.useState([]);
    const [actionButtonDisabled, setActionButtonDisabled] = React.useState([]);

    // Error message variables
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorTitle, setErrorTitle] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);
    const [errorMessageOpening, setErrorMessageOpening] = React.useState(`Error occured when processing the request: `);
    const [errorTitleOnGenerate, setErrorTitleOnGenerate] = React.useState(`Contract Template File Error`);

    // Duplicate notification
    const [duplicateNotification, setDuplicateNotification] = React.useState([]);

    useEffect(() => {
        getTraining("", 1);
    }, []);
    useEffect(() => {
        setActionButtonDisabled(props?.projectId ? false : true);
    }, [props?.projectId]);


    const validateSave = () => {
        try {
            // Validate data
            if (!tnaSetup) throw 'tnaSetup not found';

            if (!tnaSetup.groups) throw 'Groups not found';

            //var groupList = Object.values(tnaSetup.groups);
            //groupList.filter((w,i) => w.skills.filter())


            // Flatten object with simple detail
            const existingItems = _.flatten(Object.entries(tnaSetup.groups).map(([k, v]) => [...v.skills.map(o => {
                return {
                    trainingMasterId: o.trainingMasterId,
                    trainingMasterSkillId: o.trainingMasterSkillId
                }
            })]));

            // Flatten object with complete detail
            const existingItemsCompleteDetail = _.flatten(Object.entries(tnaSetup.groups).map(([k, v]) => [...v.skills.map(o => {
                return {
                    trainingMasterId: o.trainingMasterId,
                    trainingMasterSkillId: o.trainingMasterSkillId,
                    trainingTitle: o.trainingTitle,
                    trainingGroupName: v.trainingGroupName,
                    trainingGroupId: v.trainingGroupId
                }
            })]));

            // Sorting item to filter duplicate faster
            existingItems.sort((a, b) => {
                if (a.trainingMasterId < b.trainingMasterId) {
                    return -1;
                }
                if (a.trainingMasterId > b.trainingMasterId) {
                    return 1;
                }
                if (a.trainingMasterSkillId < b.trainingMasterSkillId) {
                    return -1;
                }
                if (a.trainingMasterSkillId > b.trainingMasterSkillId) {
                    return 1;
                }
                return 0;
            });

            // Checking value
            //console.log('existingItems', existingItems);

            // Check for duplicates
            var groupedSkill = []
            groupedSkill = existingItems.reduce((accumulatedValue, currentValue) => {

                // Copy new duplicate value
                var newVal = { ...currentValue };

                // Add count if any
                if ('count' in newVal) {
                    newVal.count++;
                }
                else {
                    newVal.count = 1;
                }

                // Find existing duplicate
                var existingSkill = accumulatedValue.find(w =>
                    w.trainingMasterId == currentValue.trainingMasterId &&
                    w.trainingMasterSkillId == currentValue.trainingMasterSkillId);

                if (existingSkill) {
                    existingSkill.count++;
                }
                else {
                    accumulatedValue.push(newVal);
                }

                return accumulatedValue;

            }, []);

            // Filter duplicate data 
            // where count > 1
            var duplicateSkill = [];
            duplicateSkill = groupedSkill.filter(w => w.count > 1);
            if (duplicateSkill && duplicateSkill.length > 0) {

                // Remove loading screen
                setLoading(false);

                // Foreach duplicate item
                //var duplicateToShow = [];
                //for (var i = 0; i < duplicateSkill.length; i++) {

                //    // Get detailed item, where is duplicate, and where to duplicate
                //    var duplicateItem = duplicateSkill[i];
                //    var duplicateLocations = existingItemsCompleteDetail.filter(w =>
                //        w.trainingMasterId == duplicateItem.trainingMasterId &&
                //        w.trainingMasterSkillId == duplicateItem.trainingMasterSkillId);

                //    // If data after recheck is not duplicate then skip it
                //    if (duplicateLocations.length < 2) {
                //        continue;
                //    }

                //    // Accumulate data to show it after this
                //    var oneDuplicateToShow = {
                //        id: i,
                //        title: duplicateLocations[0].trainingTitle,
                //        firstDuplicate: duplicateLocations[0].trainingGroupName,
                //        secondDuplicate: duplicateLocations[1].trainingGroupName
                //    }

                //    duplicateToShow.push(oneDuplicateToShow);
                //}

                // Foreach duplicate item
                var duplicateToShow2 = [];
                for (var i = 0; i < duplicateSkill.length; i++) {

                    // Get detailed item, where is duplicate, and where to duplicate
                    var duplicateItem = duplicateSkill[i];
                    var duplicateLocations = existingItemsCompleteDetail.filter(w =>
                        w.trainingMasterId == duplicateItem.trainingMasterId &&
                        w.trainingMasterSkillId == duplicateItem.trainingMasterSkillId);

                    // If data after recheck is not duplicate then skip it
                    if (duplicateLocations.length < 2) {
                        continue;
                    }

                    var duplicateSourceList = duplicateLocations.map((item) => { return item.trainingGroupName });

                    // Accumulate data to show it after this
                    var oneDuplicateToShow = {
                        id: i,
                        duplicateItem: duplicateLocations[0].trainingTitle,
                        duplicateSourceList: duplicateSourceList,
                    }

                    duplicateToShow2.push(oneDuplicateToShow);
                }

                // Prepare vars and show error message
                /*var events = {
                    onConfirm: () => setDuplicateNotification([]),
                    showList: true,
                    data: duplicateToShow,
                    column1duplicateDataTitle: `Skill Name${duplicateToShow.length > 1 ? 's' : ''}`
                }*/
                var events = {
                    onConfirm: () => setDuplicateNotification([]),
                    showList2: true,
                    data2: duplicateToShow2,
                    desc: `The following skill is assigned multiple times. Please review and remove the duplication.`
                }
                setDuplicateNotification([<DuplicateNotification {...events} />]);


                // Return false if duplcate found
                return false;
            }

            // If duplicate not found then return true
            return true;
        }
        catch (error) {
            setLoading(false);
            setErrorMessage(`${errorMessageOpening}${error.message}`);
            return false;
        }
    }

    const handleSave = () => {
        try {
            setLoading(true);

            //console.log({ tnaSetup, groups: _.values(tnaSetup.groups) });


            // Do validation
            var isCorrect = validateSave();
            if (!isCorrect) {
                setLoading(false);
                return;
            }


            // Prepare json data to send
            const jsonData = JSON.stringify({
                ...tnaSetup,
                groups: _.values(tnaSetup.groups)
            })

            // Send data
            api.put(`/trainingneedanalysis/setup/position/${props.projectId}`, jsonData)
                .then((response) => {
                    setLoading(false);
                    console.log("Save complete: ", response)
                    props.onSaved();
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error.response);
                    setErrorMessage(`${errorMessageOpening}${error.response}`);
                });
        }
        catch (error) {
            setLoading(false);
            setErrorMessage(`${errorMessageOpening}${error.message}`);
        }
    }

    const getTraining = React.useCallback(debounce((filter, key) => {
        // if (!filter || filter.length < 3) return;

        const existingItems = _.flatten(Object.entries(tnaSetup.groups).map(([k, v]) => [...v.skills.map(o => {
            return {
                trainingMasterId: o.trainingMasterId,
                trainingMasterSkillId: o.trainingMasterSkillId
            }
        })]));

        setLoadingTraining(key);
        api.get(`/trainingneedanalysis/training?filter=${filter}`)
        .then(({data}) => {
            setTraining(data.filter(o => !existingItems.some(p => p.trainingMasterId === o.trainingMasterId && p.trainingMasterSkillId == o.trainingMasterSkillId)));
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => setLoadingTraining(false));
    }, 200), [tnaSetup.groups]);

    const onTrainingFilterChange = (event) => {
        console.log("onTrainingFilterChange: ", {event})
        const filter  = event.filter.value;
        getTraining(filter, 1);
    }

    const onTrainingFilterChangeMultiselect = (value) => {
        getTraining(value, 1);
    }

    const onTrainingChange = (event, key) => {

        const groups = {
            ...tnaSetup.groups,
            [key]: { ...tnaSetup.groups[key], skills: event.target.value }
        }
        setTnaSetup({ ...tnaSetup, groups: groups })
    }

    const onTrainingRemove = (value, id) => {
        //console.log('remove', 'value', value);
        tnaSetup.groups[id].skills = value;
    }

    const onTrainingChangeMultiselect = useCallback((selected, key) => {
        console.log(selected);
        const groups = {
            ...tnaSetup.groups,
            [key]: { ...tnaSetup.groups[key], skills: selected }
        }
        setTnaSetup({ ...tnaSetup, groups: groups })
    }, [tnaSetup.groups]);


    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            onConfirm: () => {
                setErrorMessage(null);
                setErrorTitle(null);
            },
            message: errorMessage,
            title: errorTitle
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage, errorTitle]);


    // const handleDateChanged = (value, name) => {
    //     setTnaSetup({ ...tnaSetup, 
    //         [name]: formatDateZeroTime(value) //moment(value).format("YYYY-MM-DDT00:00:00") 
    //     });
    // }
    // const handleNumberChanged = ({ target: { value, name, min, max } }) => {
    //     const percent = value * 1;
    //     setTnaSetup({ ...tnaSetup, [name]: percent >= max ? max : percent <= min ? "" : percent });
    // }

    // const itemRender = (li, itemProps) => {
    //     console.log({li, itemProps});
    //     const itemChildren = (
    //     <span style={{ color: "#00F" }}>
    //         {itemProps.dataItem.trainingTitle}
    //     </span>
    //     );
    
    //     return React.cloneElement(li, li.props, itemChildren);
    // };

    return <Modal
        isOpen={true}
        fullscreen={true}
        className="modal-fullscreen"
        modalClassName=""
    >
        <div
            className="modal-header my-0"
        >
            <h5
                className="modal-title"
            >
                Title
            </h5>
            <button
                aria-label="Close"
                className="close"
                onClick={props.onClose}
                type="button"
            >
                <span aria-hidden={true}>x</span>
            </button>
        </div>
        <div
            className="modal-body"
        >
            {/* {validationAlert && validationAlert.length > 0 && <Alert color="warning" className="pa-0 d-flex justify-content-between">
                    <span className="alert-text">
                        <ul className="mb-0">
                            {
                                validationAlert.map((item, index) => <li key={index}>{item.message}</li>)
                            }
                        </ul>
                    </span>
                </Alert>} */}
            <Container>
                {(loading) && <Loader />}
                {errorNotification.length > 0 && errorNotification}
                {duplicateNotification.length > 0 && duplicateNotification}
                <Row>
                    <Col xs={12}>
                        <Row className="mb-3">
                            <Col xs={2}>
                                <Label>Position:</Label>
                            </Col>
                            <Col xs={10}>
                                <Label className="font-weight-bold">{'\u00A0'}{tnaSetup.position?.positionName}</Label>
                            </Col>
                        </Row>
                    </Col>
                    {/* <Col xs={12}>                        
                        <FormGroup>
                            <Label>
                                Positions
                            </Label>
                            <MultiSelect
                                data={positions}
                                loading={loadingPositions}
                                textField="positionName"
                                dataItemKey="positionId"
                                placeholder="Type to search"
                                value={tnaSetup.positions}
                                filterable={true}
                                onFilterChange={onPositionsFilterChange}
                                onChange={onPositionsChange}
                                style={{ width: '100%' }}
                                onClose={() => setPositions([])}
                            />
                        </FormGroup>
                    </Col> */}
                    {
                        Object.entries(tnaSetup.groups).map(([id, group]) => {
                            return <Col xs={12} key={id}>
                                <FormGroup>
                                    <Label>
                                        {group.trainingGroupName}
                                    </Label>
                                    {/* <MultiSelect
                                        data={training}
                                        loading={loadingTraining === id}
                                        itemRender={itemRender}
                                        textField="trainingTitle"
                                        dataItemKey="trainingMasterSkillId"
                                        placeholder="Type to search"
                                        value={tnaSetup.groups[id].skills}
                                        //value={tnaSetup.allTraining}
                                        //tags={tnaSetup.groups[id].skills ? tnaSetup.groups[id].skills.map(o => ({ text: o.trainingTitle, data: [{ ...o }] })) : []}
                                        filterable={true}
                                        onFilterChange={onTrainingFilterChange}
                                        onChange={(event) => onTrainingChange(event, id)}
                                        style={{ width: '100%' }}
                                        onClose={() => setTraining([])}
                                    /> */}
                                    <Multiselect
                                        options={training}
                                        loading={loadingTraining === id}
                                        displayValue="trainingTitle"
                                        placeholder="Type to search"
                                        selectedValues={tnaSetup.groups[id].skills}
                                        onSearch={onTrainingFilterChangeMultiselect}
                                        onRemove={(value) => {
                                            onTrainingRemove(value, id);
                                        }}
                                        onSelect={(selected) => {
                                            onTrainingChangeMultiselect(selected, id);
                                        }}
                                        showCheckbox={true}
                                        showArrow={true}
                                        groupBy="trainingMasterName"
                                        closeOnSelect={true}
                                    />
                                </FormGroup>
                            </Col>
                        })
                    }
                        <Col xs={12}>
                            <h6 className="mt-2">
                                Defaults
                            </h6>
                            <hr className="w-100 mt-2 mb-3" />
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Training Requirement
                                    <span className="text-danger">*</span>
                                </Label>
                                <RadioGroup
                                    items={trainingRequirements}
                                    name="trainingRequirementId"
                                    valueField="lookupLabel"
                                    handleChange={({ target: { value } }) => {
                                        const trainingRequirementId = value * 1
                                        const type = _.find(trainingRequirements, (item) => item.lookupId === trainingRequirementId);
                                        setTnaSetup( { ...tnaSetup, trainingRequirement: type, trainingRequirementId } );
                                    }}
                                    selected={tnaSetup.trainingRequirementId}
                                    colWidth={4}
                                />
                            </FormGroup>
                        </Col>
                        {/* <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Training Provider
                                </Label>
                                <RadioGroup
                                    items={trainingProviders}
                                    name="trainingProviderId"
                                    valueField="lookupValue"
                                    handleChange={({ target: { value } }) => {
                                        const trainingProviderId = value * 1
                                        const type = _.find(trainingProviders, (item) => item.lookupId === trainingProviderId);
                                        setTnaSetup( { ...tnaSetup, trainingProvider: type, trainingProviderId } );
                                    }}
                                    selected={tnaSetup.trainingProviderId}
                                    colWidth={3}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Funding Source
                                </Label>
                                <RadioGroup
                                    items={fundingSources}
                                    name="fundingSourceId"
                                    handleChange={({ target: { value } }) => {
                                        const fundingSourceId = value ? value * 1 : null;
                                        const type = _.find(fundingSources, (item) => item.lookupId === fundingSourceId);
                                        setTnaSetup( { ...tnaSetup, fundingSource: type, fundingSourceId } );
                                    }}
                                    selected={tnaSetup.fundingSourceId}
                                    colWidth={3}
                                    hasNA
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label>
                                    Start Date
                                </Label>
                                <DatePicker
                                    name="startDate"
                                    id="startDate"
                                    type="text"
                                    required
                                    value={formatDate(tnaSetup.startDate)}
                                    onChange={handleDateChanged}
                                    closeOnSelect                                
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label>
                                    End Date
                                </Label>
                                <DatePicker
                                    name="endDate"
                                    id="endDate"
                                    type="text"
                                    required
                                    value={formatDate(tnaSetup.endDate)}
                                    onChange={handleDateChanged}
                                    closeOnSelect                                
                                />
                            </FormGroup> 
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Percentage
                                </Label>
                                <InputGroup className="mb-4">
                                    <Input 
                                        name="percentRequired"
                                        id="percentRequired"
                                        type="number"
                                        max={100}
                                        min={0}
                                        value={tnaSetup.percentRequired || ""}
                                        onChange={handleNumberChanged}
                                    ></Input>
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <i className="fas fa-percent"></i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col> */}
                </Row>
            </Container>
        </div>
        <div
                className="modal-footer"
            >
                
                <Button
                    className="mr-auto"
                    color="default"
                    disabled={actionButtonDisabled}
                    onClick={props.onClear}
                    type="button"
                >
                    Clear
                </Button>
                
                <Button
                    color="secondary"
                    onClick={props.onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    disabled={actionButtonDisabled}
                    onClick={handleSave}
                    type="button"
                >
                    Save Changes
                </Button>
            </div>
    </Modal>
}