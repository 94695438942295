/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import {
    Label, Button, Modal, Container, Row, Col, FormGroup, Input
} from "reactstrap";
import _ from "lodash";
import moment from "moment";
import api from "../../../../services/api";
import Loader from '../../../loaders';

export default function LRFDetailMessagingDialog(props) {
    const {
        requestDetail,
        onClose,
        onSave,
    } = props;

    console.log({requestDetail});

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);

    const handleSave = useCallback(() => {
        if (!message || message === "")
            return;

        setLoading(true);

        api.post(`joborder/messaging/hiring/${requestDetail.requestDetailId}`, JSON.stringify({
            jobOrderId: requestDetail.requestDetailId,
            message,
        }))
            .then(() => {
                setLoading(false);
                onSave();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [requestDetail, message, onSave]);

    return (
        <Modal
            isOpen={true}
        >
            {loading && <Loader />}
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    {requestDetail?.recruitmentRequestNumber} {requestDetail?.position ? ` - ${requestDetail.position.lookupValue}` : ""} - Send Notification
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                <Container fluid className='px-0'>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Message
                                </Label>
                                <Input
                                    name="message"
                                    id="message"
                                    type="textarea"
                                    required
                                    value={message ?? ""}
                                    onChange={({ target: { value } }) => setMessage(value)}
                                    rows={6}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="secondary"
                    onClick={handleSave}
                    type="button"
                    disabled={!message || message === ""}
                >
                    Send Notification
                </Button>
            </div>
        </Modal>
    );
}