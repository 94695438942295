import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Container, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import Loader from "../../../../../loaders";
import { DropdownBox } from "../../../../../dropdowns";
import api from "../../../../../../services/api";
import { AgGridReact } from "ag-grid-react";
import TalentEditor from '../../../../Candidates/Editor';
import qs from "qs";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { DropDownList } from "@progress/kendo-react-dropdowns";

const editableCellClass = "roster-cell";

const getContentCellClass = (content) => {
    if (!content) {
        return "grid-empty-roster";
    }
    return "";
}

export default function CandidateResponseDialog(props) {
    const {
        dispatchUnitId,
        onClose,
    } = props;

    const [loading, setLoading] = useState(false);
    const [lookups, setLookups] = useState(null);
    const [messageMasterList, setMessageMasterList] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [emptySlots, setEmptySlots] = useState([]);
    const [candidates, setCandidates] = useState([]);
    const [candidateInEdit, setCandidateInEdit] = useState(null);
    const [viewData, setViewData] = useState(null);
    const [selectedCandidate, setSelectedCandidate] = useState(null);
    const candidateGridRef = useRef();
    const emptySlotGridRef = useRef();
    const [emptySlotColumnDefs, setEmptySlotColumnDefs] = useState([]);
    const loadingOverlayComponent = useMemo(() => Loader, []);

    useEffect(() => {
        const apiCalls = [
            api.get(`/messagesmaster?${qs.stringify({ filters: [{ id: "title", value: "Availability" }] }, { allowDots: true })}`),
            api.get(`/messagesmaster?${qs.stringify({ filters: [{ id: "title", value: "Accommodation" }] }, { allowDots: true })}`),
            api.get(`/messagesmaster?${qs.stringify({ filters: [{ id: "title", value: "DAS" }] }, { allowDots: true })}`),
            api.get(`/contractingcompany/all`),
        ];

        Promise.all(apiCalls)
            .then((data) => {
                
            }).catch((error) => {
                console.error(error.response);
            }).finally(() => {
                //setLoading(false);
            });
    }, []);
    
    const staticEmptySlotColumnDefs = useMemo(() => {
        return [
            {
                headerName: "",
                children: [
                    {
                        field: "slot",
                        headerName: "Slot",
                        width: 55,
                        pinned: true,
                        sort: "asc",
                    },
                    {
                        field: "candidateName",
                        headerName: "Employee Name",
                        width: 150,
                        pinned: true,
                        cellClass: (params) => {
                            const contentCellClass = getContentCellClass(params.data?.candidateName);
                            return `${editableCellClass} ${contentCellClass}`;
                        },
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: "hrispositionMasterName",
                        headerName: "Roster Position",
                        width: 200,
                        columnGroupShow: 'closed',
                        cellClass: (params) => {
                            var contentCellClass = getContentCellClass(params.data?.hrispositionMasterName);
                            return `${editableCellClass} ${contentCellClass}`;
                        },
                    },
                    {
                        field: "positionName",
                        headerName: "Employed Position",
                        width: 200,
                        columnGroupShow: 'closed',
                        cellClass: (params) => {
                            var contentCellClass = getContentCellClass(params.data?.positionName);
                            return `roster-cell ${contentCellClass}`;
                        },
                        pinned: true,
                    },
                    {
                        field: "shiftName",
                        headerName: "Shift",
                        width: 70,
                        cellClass: (params) => {
                            var contentCellClass = getContentCellClass(params.data?.shiftName);
                            return `${editableCellClass} ${contentCellClass}`;
                        },
                    },
                    {
                        field: "siteTravelTypeName",
                        headerName: "Site Travel Type",
                        width: 100,
                        cellClass: (params) => {
                            var contentCellClass = getContentCellClass(params.data?.siteTravelTypeName);
                            return `${editableCellClass} ${contentCellClass}`;
                        },
                    },
                    {
                        field: "pointOfHireName",
                        headerName: "Point of Hire",
                        width: 90,
                        cellClass: (params) => {
                            var contentCellClass = getContentCellClass(params.data?.pointOfHireName);
                            return `${editableCellClass} ${contentCellClass}`;
                        },
                    },
                    {
                        field: "rosterCandidateCompanyName",
                        headerName: "Entity",
                        width: 150,
                        cellClass: (params) => {
                            if (params.data.rosterCompanyId !== params.data.rosterCandidateCompanyId) {
                                return `roster-cell text-danger`;
                            }
                            return 'roster-cell'
                        },
                    },
                ]
            },
        ]
    }, []);

    const loadData = useCallback(() => {
        if (!dispatchUnitId)
            return;

        setLoading(true);

        const apiCalls = [
            api.get(`candidate/roster/search/response?dispatchUnitId=${dispatchUnitId}`),
            api.get(`rostergrid/emptyslots?dispatchUnitId=${dispatchUnitId}`),
        ];

        Promise.all(apiCalls)
            .then((responses) => {
                setCandidates(responses[0].data);
                const {schedules, filterStartDate: startDate, filterEndDate: endDate, dispatchUnit} = responses[1].data;

                if (schedules && schedules.length == 0) {
                    setEmptySlotColumnDefs([...staticEmptySlotColumnDefs]);
                    setEmptySlots([]);
                    setLoading(false);
                    return;
                }

                const dateColumns = [];

                const start = moment(startDate, 'YYYY-MM-DD');
                const end = moment(endDate, 'YYYY-MM-DD');
                const current = moment(start);

                while (current <= end) {
                    dateColumns.push(moment(current));
                    current.add(1, 'd');
                }

                setEmptySlotColumnDefs([
                    ...staticEmptySlotColumnDefs,
                    ...dateColumns.map((m) => ({
                        headerName: m.format('ddd'),
                        marryChildren: true,
                        children: [
                            {
                                field: `dates.${m.format('YYYYMMDD')}`,
                                // tooltipField: `dates.${m.format('YYYYMMDD')}`,
                                headerName: m.format('DD/MM/YYYY'),
                                autoHeight: false,
                                resizable: false,
                                width: 30,
                                headerClass: 'date-header',
                                suppressMovable: true,
                                lockPosition: 'right',
                                filter: false,
                                suppressMenu: true,
                                valueFormatter: (params) => {
                                    // console.log("value formatter", {params});
                                    return params?.value?.value === "W" ? "1" : "";
                                },
                                cellClass: (params) => {
                                    // console.log("cell class", {params});
                                    return params.value && params.value.value ? `date-cell roster-report-icon ${params.value?.className} amount amount${params.value?.value.length}` : 'date-cell';
                                },
                            }
                        ]
                    }))
                ]);

                setEmptySlots([...schedules]);
                setViewData({
                    startDate,
                    endDate,
                    dispatchUnit,
                });
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [dispatchUnitId, staticEmptySlotColumnDefs]);

    const handleAssignCandidate = useCallback(() => {
        if (!selectedCandidate?.rosterCandidateId)
            return;

        setLoading(true);

        api.post("candidate/roster/emptyslot/assign", JSON.stringify({
            rosterId: selectedCandidate.rosterId,
            candidateId: selectedCandidate.candidateId,
            rosterCandidateId: selectedCandidate.rosterCandidateId,
            rosterSlotNotificationId: selectedCandidate.rosterSlotNotificationId,
        }))
            .then(() => {
                setSelectedCandidate(null);
                loadData();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [selectedCandidate, loadData]);

    useEffect(() => {
        if (!dispatchUnitId)
            return;

        setLoading(true);

        const apiCalls = [
            api.get(`/lookup/skillposition`),
            api.get(`/lookup/candidatetype`),
            api.get(`/lookup/salutation`),
            api.get(`/lookup/preference`),
            api.get(`/lookup/industry`),
            api.get(`/lookup/gender`),
            api.get(`/lookup/maritalStatus`),
            api.get(`/lookup/candidateStatus`),
            api.get(`/lookup/candidateFlag`),
            api.get(`/lookup/residencyStatus`),
            api.get(`/lookup/visatype`),
            api.get(`/lookup/communicationtype`),
            api.get(`/lookup/country`),
            api.get(`/lookup/state`),
            api.get(`/lookup/relationshiptype`),
            api.get(`/lookup/candidateplatformorigin`),
            api.get(`/lookup/messagesmasters`),
            api.get(`/emailtemplate`),
            api.get(`/lookup/recruitmentpeople`),
            api.get(`/lookup/candidatediscipline`),
            api.get(`/lookup/company`),
            api.get(`/lookup/identifier`),
            api.get(`/lookup/candidateevent`),
            api.get(`/lookup/rosterflighttype`),
            api.get(`/lookup/flightstatus`),
            api.get(`/lookup/accommodationstatus`),
            api.get(`/lookup/RosterApprovalStatus`),
            api.get(`/lookup/WorkSequence`),
            api.get(`/lookup/agency`),
            api.get(`/contractingcompany/all`),
            api.get(`/lookup/candidatecommenttype`),
            api.get(`/lookup/candidateeventstatus`),
        ];

        Promise.all(apiCalls)
            .then((responses) => {
                setLookups({
                    skillPositions: responses[0].data,
                    candidateTypes: responses[1].data,
                    salutations: responses[2].data,
                    preferences: responses[3].data,
                    industries: responses[4].data,
                    genders: responses[5].data,
                    maritalStatuses: responses[6].data,
                    candidateStatuses: responses[7].data,
                    candidateFlags: responses[8].data,
                    residencyStatuses: responses[9].data,
                    visaTypes: responses[10].data,
                    communicationTypes: responses[11].data,
                    countries: responses[12].data,
                    states: responses[13].data,
                    relationshipTypes: responses[14].data,
                    candidatePlatformOrigin: responses[15].data,
                    messagesMaster: responses[16].data,
                    emailTemplate: responses[17].data.data,
                    recruitmentPeople: responses[18].data,
                    candidateDisciplines: responses[19].data,
                    companies: responses[20].data,
                    identifiers: responses[21].data,
                    events: responses[22].data,
                    rosterFlightTypes: responses[23].data,
                    rosterFlightStatuses: responses[24].data,
                    rosterAccommodationStatuses: responses[25].data,
                    rosterApprovalStatus: responses[26].data,
                    workSequence: responses[27].data,
                    agencies: responses[28].data,
                    contractingCompanies: responses[29].data,
                    candidateCommentTypes: responses[30].data,
                    eventStatus: responses[31].data,
                });
                setLoading(false);
                loadData();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [dispatchUnitId]);

    const defaultColDef = useMemo(() => ({
        sortable: false,
        cellClass: 'roster-cell',
        autoHeight: false,
        resizable: true,
        filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200
        },
        suppressPaste: true,
        suppressFillHandle: true,
        editable: false,
        suppressMenu: true,
    }), []);

    const candidateColumnDefs = useMemo(() => {
        return [
            {
                headerName: "No.",
                width: 30,
                filter: false,
                editable: false,
                valueGetter: (args) => args.node.rowIndex + 1,
            },
            {
                field: "candidateName",
                headerName: "Employee Name",
                width: 150,
                sortable: true,
                filter: 'agTextColumnFilter',
                valueGetter: (args) => args.data.candidate.candidateName,
            },
            {
                field: "positionName",
                headerName: "Position Name",
                width: 150,
                sortable: true,
                filter: 'agTextColumnFilter',
                valueGetter: (args) => args.data.hrispositionMaster.hrispositionMasterName,
            },
            {
                field: "info",
                headerName: "Info",
                width: 40,
                onCellClicked: (args) => {
                    setCandidateInEdit({ candidateId: args.data.candidateId });
                },
                valueFormatter: () => '',
                cellClass: () => "roster-cell cursor-pointer link fas fa-info-circle text-center",
                filter: false,
            },
            {
                field: "startDate",
                headerName: "Start Date",
                width: 150,
                sortable: true,
                filter: 'agTextColumnFilter',
                valueGetter: (args) => moment(args.data.startDate).format("DD.MM.YYYY"),
            },
            {
                field: "endDate",
                headerName: "End Date",
                width: 150,
                sortable: true,
                filter: 'agTextColumnFilter',
                valueGetter: (args) => moment(args.data.endDate).format("DD.MM.YYYY"),
            },
            {
                field: "action",
                headerName: "Action",
                width: 40,
                onCellClicked: (args) => {
                    if (!emptySlots?.length)
                        return;
                    
                    setSelectedCandidate({
                        rosterId: args.data.rosterId,
                        candidateId: args.data.candidateId,
                        rosterSlotNotificationId: args.data.rosterSlotNotificationId,
                    });
                },
                valueFormatter: () => '',
                cellClass: () => `roster-cell link fas fa-arrow-right text-center ${emptySlots?.length ? "cursor-pointer" : "text-muted"}`,
                filter: false,
            },
        ]
    }, [handleAssignCandidate, emptySlots]);

    return (
        <>
            <Modal
                isOpen={true}
                className="modal-fullscreen modal-fullscreen-xl"
            >
                <div
                    className="modal-header"
                >
                    <h5
                        className="modal-title"
                    >
                        Talent Responses{viewData ? ` - ${viewData.dispatchUnit.dispatchUnitName} (${viewData.startDate ? moment(viewData.startDate).format("DD-MM-YYYY") : "N/A"} to ${viewData.endDate ? moment(viewData.endDate).format("DD-MM-YYYY") : "N/A"})` : ""}
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        onClick={onClose}
                        type="button"
                    >
                        <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                    </button>
                </div>
                <div
                    className="modal-body"
                    style={{height: "70vh", maxHeight: "70vh", overflowY: "auto"}}
                >
                    <Container fluid className="m-0 p-0 p-2 h-100">
                        {loading && <Loader />}
                        <Row className="m-0 p-0 mt-2 h-100">
                            <Col xs={6} className="m-0 p-0">
                                <div className="ag-theme-alpine px-2" style={{ width: '100%', height: '63vh' }}>
                                    <AgGridReact
                                        ref={candidateGridRef}
                                        rowData={candidates}
                                        columnDefs={candidateColumnDefs}
                                        defaultColDef={defaultColDef}
                                        suppressColumnVirtualisation={false}
                                        suppressRowVirtualisation={false}
                                        rowBuffer={20}
                                        debounceVerticalScrollbar={false}
                                        allowContextMenuWithControlKey={true}
                                        suppressClipboardPaste={true}
                                        headerHeight={25}
                                        groupHeaderHeight={22}
                                        rowHeight={22}
                                        onGridReady={({ api }) => {
                                            api.hideOverlay();
                                        }}
                                        readOnlyEdit={true}
                                        enableRangeSelection={true}
                                        loadingOverlayComponent={loadingOverlayComponent}
                                        tooltipShowDelay={0}
                                        tooltipHideDelay={20000}
                                        suppressDragLeaveHidesColumns={true}
                                        suppressColumnMoveAnimation={true}
                                        copyHeadersToClipboard={false}
                                        enableCellChangeFlash={true}
                                        animateRows={true}
                                        rowSelection="multiple"
                                        suppressRowClickSelection={true}
                                    />
                                </div>
                            </Col>
                            <Col xs={6} className="m-0 p-0">
                                <div className="ag-theme-alpine px-2" style={{ width: '100%', height: '63vh' }}>
                                    <AgGridReact
                                        ref={emptySlotGridRef}
                                        rowData={emptySlots}
                                        columnDefs={emptySlotColumnDefs}
                                        defaultColDef={defaultColDef}
                                        suppressColumnVirtualisation={false}
                                        suppressRowVirtualisation={false}
                                        rowBuffer={20}
                                        debounceVerticalScrollbar={false}
                                        allowContextMenuWithControlKey={true}
                                        suppressClipboardPaste={true}
                                        headerHeight={70}
                                        groupHeaderHeight={22}
                                        rowHeight={22}
                                        onGridReady={({ api }) => {
                                            api.hideOverlay();
                                        }}
                                        readOnlyEdit={true}
                                        enableRangeSelection={true}
                                        loadingOverlayComponent={loadingOverlayComponent}
                                        tooltipShowDelay={0}
                                        tooltipHideDelay={20000}
                                        suppressDragLeaveHidesColumns={true}
                                        suppressColumnMoveAnimation={true}
                                        copyHeadersToClipboard={false}
                                        enableCellChangeFlash={true}
                                        animateRows={true}
                                        rowSelection="multiple"
                                        suppressRowClickSelection={true}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="modal-footer d-flex flex-wrap"
                >
                    <Button
                        color="secondary"
                        onClick={onClose}
                        type="button"
                        className="mt-2"
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        {
            candidateInEdit ?
            <TalentEditor
                item={candidateInEdit}
                onClose={() => {
                    setCandidateInEdit(null);
                }}
                onSaved={() => {
                    setCandidateInEdit(null);
                }}
                lookups={{
                    ...lookups
                }}
            />
            :
            null
        }
        {
            selectedCandidate ?
            <SweetAlert
                title="Roster Slot Assignment"
                warning
                showCancel
                cancelBtnText="Cancel"
                confirmBtnText="Ok"
                confirmBtnBsStyle="danger"
                onConfirm={() => handleAssignCandidate()}
                onCancel={() => setSelectedCandidate(null)}
            >
                <Container fluid>
                    <Row className="text-justify m-0 p-0">
                        <Col sm="12" className="m-0 p-0">
                            <FormGroup>
                                <Label>
                                    Select Slot Number
                                    <span className="text-danger">*</span>
                                </Label>
                                <DropDownList
                                    data={emptySlots}
                                    textField="slot"
                                    dataItemKey="rosterCandidateId"
                                    value={selectedCandidate?.rosterCandidate}
                                    style={{width: "55px"}}
                                    className="font-size-small-custom w-100"
                                    onChange={({value}) => setSelectedCandidate({
                                            ...selectedCandidate,
                                            rosterCandidateId: value.rosterCandidateId,
                                            rosterCandidate: value,
                                        })}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
            </SweetAlert>
            :
            null
        }
        </>
    );
};
