import React from 'react';
import { useHistory } from "react-router-dom";
import { useTable, useFilters, useSortBy, useFlexLayout } from 'react-table';
import _ from "lodash";
import moment from "moment";
import qs from "qs";
import SweetAlert from "react-bootstrap-sweetalert";
import {
    Container, Row, Col, Card, CardBody, CardTitle, FormGroup, Label
 } from "reactstrap";
import { DefaultColumnFilter } from "../../react-table/filters";
import { TooltipButton } from "../../inputs";
import { ComboBox } from "../../dropdowns";
import api from "../../../services/api";
import Pager from "../../Pager";
import Loader from "../../loaders";
import "./ActiveTna.css";
import Grid from "../../Grid";

function SelectProject(props) {
    const {
        cloneProject,
        onConfirm,
        onCancel
    } = props;

    const [ selectedProject, setSelectedProject ] = React.useState(null);

    return <SweetAlert
        info
        showCancel
        confirmBtnText="Clone"
        confirmBtnBsStyle="danger"
        title="Clone"
        onConfirm={() => onConfirm(cloneProject, selectedProject)} // need to add loading indicator on confirm
        onCancel={onCancel}
        focusCancelBtn
    >
        <div className="mt-1">
            <FormGroup className="tnadownshift">
                <Label>
                    Project
                </Label>
                <ComboBox 
                    endpoint="/project/search"
                    isLookup={false}
                    idField="projectId"
                    valueField="projectName"
                    selectedItem={selectedProject}
                    onChange={setSelectedProject}
                />
            </FormGroup>
        </div>

        {!selectedProject && <p>Please select a project to clone <i>{cloneProject.projectName}</i> to.</p>}
        {selectedProject && <p>Clone <i>{cloneProject.projectName}</i> TNA to <i>{selectedProject.projectName}</i>?</p>}
        

    </SweetAlert>
}

function ActiveTna(props) {

    const history = useHistory();
    const skipPageResetRef = React.useRef();

    const [ data, setData ] = React.useState([]);
    const [ loading, setLoading ] = React.useState(false);
    const [ pageNumber, setPageNumber ] = React.useState(1);
    const [ pageSize, setPageSize ] = React.useState(10);
    const [ totalRecords, setTotalRecords ] = React.useState(0);
    const [ clone, setClone ] = React.useState(false);
    const [ filters, setFilters ] = React.useState([]);

    const defaultSort = React.useMemo(() => [{ id: "projectName", desc: false }],
        []
    );
    
    const [ sortBy, setSortBy ] = React.useState(defaultSort);

    const loadData = React.useCallback(() => {
        setLoading(true);
        setData([]);
        skipPageResetRef.current = true;
        const queryString = qs.stringify({filters, sortBy}, { allowDots: true });
        
        api.get(`/trainingneedanalysis/activetna/${(pageNumber - 1) * pageSize}/${pageSize}${queryString ? `?${queryString}` : ""}`)
        .then(({data}) => {
            setData(data.data);
            setTotalRecords(data.total);
        }).catch((error) => {
            console.error("error: ", error);
        }).finally(() => setLoading(false));
    }, [filters, sortBy, pageNumber, pageSize]);

    React.useEffect(() => loadData(), [pageNumber, pageSize, filters, sortBy]);

    const filterTypes = React.useMemo(() => ({
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
            const rowValue = row.values[id]
            return rowValue !== undefined
                ? String(rowValue).replace(/[/-]/g, "")
                    .toLowerCase()
                    .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                : true
            })
        },
    }), []);
  
    const handleCloneConfirm = (cloneProject, toProject) => {
        console.log("Clone: ", { cloneProject, toProject });

        api.post(`trainingneedanalysis/clone/${cloneProject.projectId}/${toProject.projectId}`)
        .then((response) => {
            console.log("Cloned: ", { response });
            history.push(`/tna/${response.data}`);
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            setLoading(false);
        })
        
        setClone(null);
    };

    const handleCloneCancel = () => {
        setClone(null);
    };

    const columns = React.useMemo(() => {
        return [
            {
                Header: "Project",
                id: "projectName",
                accessor: "projectName",
                width: 300,
                disableSortBy: false,
            },
            {
                Header: "Status",
                id: "tnaStatus",
                accessor: "tnaStatus",
                width: 100,
            },
            {
                Header: "Status Date",
                id: "statusDate",
                accessor: (row) => row.tnaStatusDate ? `${moment(row.tnaStatusDate).format("DD/MM/YYYY")}` : "-",
                width: 100,
                // disableFilters: true
            },
            {
                Header: "Action",
                id: 'button',
                Cell: ( { row: { original } } ) => (<div>
                    <TooltipButton
                        id={`edit_${original.projectId}`}
                        title="Edit"
                        className="btn-icon"
                        color="default"
                        size="xsm"
                        type="button"
                        onClick={() => history.push(`/tna/${original.projectId}`)}
                    >
                        <i className="fas fa-pencil-alt pt-1"></i>
                    </TooltipButton>
                    <TooltipButton
                        id={`copy_${original.projectId}`}
                        title="Duplicate Row"
                        className="btn-icon"
                        color="info"
                        size="xsm"
                        type="button"
                        onClick={() => {                                
                            setClone({ ...original })
                        }}
                        
                    >
                        <i className="fas fa-copy pt-1"></i>
                    </TooltipButton>
                </div>),
                width: 80,
            }
        ]
    }, []);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: defaultSort,
                canSort: true,
            },
            filterTypes,
            manualSortBy: true,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout,
        useFilters,
        useSortBy
    );
    
    React.useEffect(() => {
        setFilters(state.filters);
    }, [state.filters]);
    
    React.useEffect(() => {
        setSortBy(state.sortBy);
    }, [state.sortBy]);

    return <section className="main">
        {loading && <Loader />}
        {clone && <SelectProject onConfirm={handleCloneConfirm} onCancel={handleCloneCancel} cloneProject={clone} />}
        
        <Container>
            <Row>
                <Col>
                    <Card className="no-transition">
                        <CardBody>
                            <CardTitle>
                                <h4 className="text-center">Active TNA</h4>
                            </CardTitle>
                            <TooltipButton
                                id="addnew"
                                title="Add New"
                                className="btn-icon ml-2 mb-2"
                                color="default"
                                size="xsm"
                                type="button"
                                onClick={() => { 
                                    history.push("/tna");
                                }}
                            >
                                <i className="fas fa-plus pt-1"></i>
                            </TooltipButton>
                            <Grid
                                totalRecords={totalRecords}
                                pageSize={pageSize}
                                rows={rows}
                                tableProps={getTableProps()}
                                headerGroups={headerGroups}
                                tableBodyProps={getTableBodyProps()}
                                prepareRow={prepareRow}
                                onRowDoubleClick={(row) => history.push(`/tna/${row.original.projectId}`)}
                                onPagerChangePage={(pager) => {
                                    setPageNumber(pager.currentPage);
                                }}
                                onPagerChangePageSize={(size) => {
                                    setPageSize(size);
                                }}
                                needPaging={true}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    </section>;
}

export default ActiveTna;