import React from 'react';

export default (props) => {
    return (
        <div key={`${props.node.rowIndex}-${props.column.instanceId}`}
            className="border border-info px-3 py-1"
            style={{ backgroundColor: 'white', minWidth: '150px' }}
        >
            {props.value}
        </div>
    );
};