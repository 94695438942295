import React, { useCallback } from 'react';
import api from "../../../services/api";
import {
    Input, Label, FormGroup, Button, Modal, Container, Row, Col, Alert, InputGroup, InputGroupAddon, InputGroupText
 } from "reactstrap"; 
import _ from "lodash";
import { ComboBox, DropdownBox } from "../../dropdowns";
import Loader from "../../loaders";
import { DatePicker } from '../../date-pickers';
import { formatDate, formatDateZeroTime, Mode } from "../../../utils";
import { TooltipButton } from '../../inputs';
import { tcValidation } from "./tcValidation";
import TextareaAutosize from "react-autosize-textarea";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import '@progress/kendo-theme-bootstrap/dist/all.css';
import Multiselect from 'multiselect-react-dropdown';

export default function Editor(props) {

    const {
        components,
        employmentcategories,
        item,
        onSaved,
        onClose,
        drawerMode,
        setDrawerMode
    } = props;

    //const [drawerMode, setDrawerMode] = React.useState(drawerModeProp);

    const [ itemInEdit, setItemInEdit ] = React.useState(item);
    const [ validationAlert, setValidationAlert ] = React.useState([]);
    const [ loading, setLoading ] = React.useState(false);

    const [ loadingLocations, setLoadingLocations ] = React.useState(false);
    const [ locationsFromApi, setLocationsFromApi ] = React.useState([]);
    const [ locations, setLocations ] = React.useState([]);
    
    const [loadingPositions, setLoadingPositions] = React.useState(false);
    const [positionsFromApi, setPositionsFromApi] = React.useState([]);
    const [ positions, setPositions ] = React.useState([]);

    const handleTextChanged = ({ target: { name, value } }) => {
        setItemInEdit({ ...itemInEdit, [name]: value });
    }

    const handleDateChanged = (value, name) => {
        setItemInEdit({ 
            ...itemInEdit, 
            [name]: !value ? null : formatDateZeroTime(value)
        });     
    }

    const validate = React.useCallback(() => {
        let errors = [ ...validationAlert ];
        _.forEach(tcValidation, (value) => {
            _.forEach(value.tests, (test) => {
                const exists = _.some(errors, (item) => item.key === value.field && item.type === test.type);
                const result = test.validate(itemInEdit);
                if (result && !exists) {
                    errors.push( { key: value.field, type: test.type, message: test.message } )
                } else if (!result && exists) {
                    _.remove(errors, (item) =>  item.key === value.field && item.type === test.type);
                }
            })
        });
        setValidationAlert([ ...errors ]);
        if (errors.length > 0) {
            return false;
        }
        return true;
    }, [itemInEdit, validationAlert]);

    React.useEffect(() => {
        if (itemInEdit && validationAlert.length > 0) validate();
    }, [itemInEdit]);


    React.useEffect(() => {
        console.log('itemInEdit?.locations', itemInEdit?.locations, 'locationsFromApi', locationsFromApi);

        var newArr = [];
        if (locationsFromApi && itemInEdit?.locations) {
            newArr = locationsFromApi.concat(itemInEdit?.locations)
        } else if (locationsFromApi) {
            newArr = locationsFromApi;
        } else if (itemInEdit?.locations) {
            newArr = itemInEdit?.locations;
        }

        // Filter duplicates
        newArr = newArr.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.locationId === value.locationId
            ))
        )

        setLocations(newArr);

    }, [itemInEdit?.locations, locationsFromApi]);

    React.useEffect(() => {
        console.log('itemInEdit', itemInEdit,'itemInEdit?.positions', itemInEdit?.positions, 'positionsFromApi', positionsFromApi);

        var newArr = [];
        if (positionsFromApi && itemInEdit?.positions) {
            newArr = positionsFromApi.concat(itemInEdit?.positions)
        } else if (locationsFromApi) {
            newArr = positionsFromApi;
        } else if (itemInEdit?.positions) {
            newArr = itemInEdit?.positions;
        }

        // Filter duplicates
        newArr = newArr.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.positionId === value.positionId
            ))
        )

        setPositions(newArr);

    }, [itemInEdit?.positions, positionsFromApi]);

    const handleSave = (tc) => {

        if (!validate()) {
            return;
        }

        setLoading(true)

        const data = JSON.stringify(tc);
        const save = item.termAndConditionId > 0 
            ? api.put(`termandcondition/${tc.termAndConditionId}`, data)
            : api.post(`termandcondition`, data)

        save
        .then((response) => {
            console.log("TC Save: ", response);
            onSaved();
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const getPositions = React.useCallback(_.debounce(filter => {
        //if (!filter && filter.length === 0) return;

        setLoadingPositions(true);
        api.get(`/position/searchname?filter=${filter}`)
        .then(({data}) => {
            setPositionsFromApi(data);
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => setLoadingPositions(false));
    }, 200), []);

    const onPositionsFilterChange = (event) => {
        const filter  = event.filter.value;
        getPositions(filter);
    }

    const onPositionsFilterChangeMultiselect = (filter) => {
        console.log('onPositionsFilterChangeMultiselect', 'filter', filter)
        getPositions(filter);
    }

    const onPositionsChange = (event) => {
        console.log("onPositionsChange", event.target.value);
        setItemInEdit({ ...itemInEdit, positions: event.target.value, positionIds: event.target.value.map(o => o.positionId) });
    }

    const onPositionsChangeMultiselect = useCallback((values) => {
        setItemInEdit({ ...itemInEdit, positions: values, positionIds: values.map(v => v.positionId) });
    }, [itemInEdit]);


    const getLocations = React.useCallback(_.debounce(filter => {
        //if (!filter && filter.length === 0) return;

        setLoadingLocations(true);
        api.get(`/location/search?filter=${filter}`)
        .then(({data}) => {
            setLocationsFromApi(data);
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => setLoadingLocations(false));
    }, 200), []);

    const onLocationsFilterChange = (event) => {
        const filter  = event.filter.value;
        getLocations(filter);
    }

    const onLocationsFilterChangeMultiselect = (filter) => {
        getLocations(filter);
    }

    const onLocationsChange = (event) => {
        console.log("onLocationsChange", event.target.value);
        setItemInEdit({ ...itemInEdit, locations: event.target.value, locationIds: event.target.value.map(o => o.locationId) });
    }

    const onLocationsChangeMultiselect = useCallback((values) => {
        setItemInEdit({ ...itemInEdit, locations: values, locationIds: values.map(v => v.locationId) });
    }, [itemInEdit]);

    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    {drawerMode == Mode.EDIT ? `Edit` : `Add`} T&amp;C
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onClose}
                    type="button"
                >
                    <span aria-hidden={true}>x</span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                {validationAlert && validationAlert.length > 0 && <Alert color="warning" className="pa-0 d-flex justify-content-between">
                    <span className="alert-text">
                        <ul className="mb-0">
                            {
                                validationAlert.map((item, index) => <li key={index}>{item.message}</li>)
                            }
                        </ul>
                    </span>
                </Alert>}
                <Container>
                    {(loading) && <Loader />}
                    <Row>  
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Employment Category
                                    <span className="text-danger">*</span>
                                </Label>
                                <DropdownBox
                                    data={employmentcategories}
                                    selectedItem={itemInEdit.employmentCategory}
                                    onChange={(item) => {
                                        console.log("on component change: ", item);
                                        setItemInEdit( { ...itemInEdit, employmentCategory: item, employmentCategoryId: item ? item.lookupId : null } );
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Component
                                    <span className="text-danger">*</span>
                                </Label>
                                <DropdownBox
                                    data={components}
                                    selectedItem={itemInEdit.component}
                                    onChange={(item) => {
                                        console.log("on component change: ", item);
                                        setItemInEdit( { ...itemInEdit, component: item, componentId: item ? item.lookupId : null } );
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Value
                                    <span className="text-danger">*</span>
                                </Label>
                                <InputGroup>
                                    <Input
                                        name="componentValue"
                                        id="componentValue"
                                        type="textarea"
                                        onChange={handleTextChanged}
                                        value={itemInEdit.componentValue ?? ""}
                                        tag={TextareaAutosize}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Start Date
                                    <span className="text-danger">*</span>
                                </Label>
                                <DatePicker
                                    name="startDate"
                                    id="startDate"
                                    type="text"
                                    required
                                    value={formatDate(itemInEdit.startDate)}
                                    onChange={handleDateChanged}
                                    closeOnSelect
                                    />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    End Date (leave empty if ongoing)
                                </Label>
                                <DatePicker
                                        name="endDate"
                                        id="endDate"
                                        type="text"
                                        value={formatDate(itemInEdit.endDate)}
                                        onChange={handleDateChanged}
                                        closeOnSelect
                                    />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Location
                                </Label>
                                {itemInEdit?.locationId > 0 || drawerMode == Mode.EDIT ? (
                                    <ComboBox 
                                        minLength={0}
                                        endpoint="/location/search"
                                        isLookup={false}
                                        idField="locationId"
                                        valueField="locationName"
                                        selectedItem={itemInEdit.location}
                                        onChange={(item) => {
                                            setItemInEdit( { ...itemInEdit, location: item, locationId: item ? item.locationId : null } );
                                        }}
                                    />
                                ) : (
                                    <Multiselect
                                        options={locations}
                                        loading={loadingLocations}
                                        displayValue="locationName"
                                        placeholder="Type to search"
                                        selectedValues={itemInEdit?.locations}
                                        onSearch={onLocationsFilterChangeMultiselect}
                                        onSelect={(selected) => {
                                            onLocationsChangeMultiselect(selected);
                                        }}
                                        showCheckbox={true}
                                        showArrow={true}
                                        closeOnSelect={true}
                                    />
                                )}
                                
                            </FormGroup>
                        </Col> 
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Position
                                    <span className="text-danger">*</span>
                                </Label>
                                {itemInEdit?.positionId > 0 || drawerMode == Mode.EDIT ? (
                                    <ComboBox 
                                        minLength={0}
                                        endpoint="/position/search"
                                        isLookup={false}
                                        idField="positionId"
                                        valueField="positionName"
                                        selectedItem={itemInEdit.position}
                                        onChange={(item) => {
                                            setItemInEdit( { ...itemInEdit, position: item, positionId: item ? item.positionId : null } );
                                        }}
                                    />
                                ) : (
                                    <Multiselect
                                        options={positions}
                                        loading={loadingPositions}
                                        displayValue="positionName"
                                        placeholder="Type to search"
                                        selectedValues={itemInEdit?.positions}
                                        onSearch={onPositionsFilterChangeMultiselect}
                                        onSelect={(selected) => {
                                            onPositionsChangeMultiselect(selected);
                                        }}
                                        showCheckbox={true}
                                        showArrow={true}
                                        closeOnSelect={true}
                                            />
                                )}
                            </FormGroup>
                        </Col>
                        
                        {/* <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Location
                                </Label>
                                <ComboBox 
                                    minLength={2}
                                    endpoint="/location/search"
                                    isLookup={false}
                                    idField="locationId"
                                    valueField="locationName"
                                    selectedItem={itemInEdit.location}
                                    onChange={(item) => {
                                        setItemInEdit( { ...itemInEdit, location: item, locationId: item ? item.locationId : null } );
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Position
                                </Label>
                                <ComboBox 
                                    minLength={2}
                                    endpoint="/position/search"
                                    isLookup={false}
                                    idField="positionId"
                                    valueField="positionName"
                                    selectedItem={itemInEdit.position}
                                    onChange={(item) => {
                                        setItemInEdit( { ...itemInEdit, position: item, positionId: item ? item.positionId : null } );
                                    }}
                                />
                            </FormGroup>
                        </Col> */}
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave(itemInEdit)}
                    type="button"
                >
                    Save Changes
                </Button>
            </div>
        </Modal>
    )
}