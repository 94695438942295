import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
  } from 'react';
import { KEY_BACKSPACE, KEY_DELETE } from '../../../../utils';
  
  const ARROW_LEFT = 'ArrowLeft';
  const ARROW_RIGHT = 'ArrowRight';
  
  export default forwardRef((props, ref) => {

    console.log('forwardRef', props)
    const createInitialState = () => {
      let startValue;
      const currentValue = props.value || { value: '', className: '', schedules: {} }
  
      if (props.eventKey === KEY_BACKSPACE || props.eventKey === KEY_DELETE) {
        // if backspace or delete pressed, we clear the cell
        startValue = '';
      } else if (props.charPress && process.env.REACT_APP_ROSTER_VALID_SCHEDULE_CHARS.includes(props.charPress.toUpperCase())) {
        // if a valid letter was pressed, we start with the letter
        startValue = currentValue.value + props.charPress.toUpperCase();
      } else {
        // otherwise we start with the current value
        startValue = currentValue.value;
      }

      return {
        value: startValue,
      };
    };

    const initialState = createInitialState();
    const [value, setValue] = useState(initialState.value);
    const refInput = useRef(null);

    // focus on the input
    useEffect(() => {
      // get ref from React component
      window.setTimeout(() => {
        const eInput = refInput.current;
        if (eInput) {
            eInput.focus();
        }
      });
    }, []);

    const isLeftOrRight = (event) => [ARROW_LEFT, ARROW_RIGHT].indexOf(event.key) > -1;

    const deleteOrBackspace = (event) => [KEY_DELETE, KEY_BACKSPACE].indexOf(event.key) > -1;

    const onKeyDown = (event) => {
        console.log('KEY: ', event.key);
        if (isLeftOrRight(event) || deleteOrBackspace(event)) {
            event.stopPropagation();
            return;
        }
        const key = event.key.toUpperCase();
        if (!process.env.REACT_APP_ROSTER_VALID_SCHEDULE_CHARS.includes(key)) {
            event.preventDefault();
        }
    };

    useImperativeHandle(ref, () => {
        return {
            // the final value to send to the grid, on completion of editing
            getValue() {
                return value;
            },
        };
    });
  
    return (
      <input
        ref={refInput}
        className={'form-control'}
        value={value}
        onChange={(event) => {
            console.log('CHANGE: ', event.target.value);
            const set = new Set(event.target.value.toUpperCase());
            if (set.has("W") && set.has("N")) {
                const last = event.target.value[event.target.value.length - 1];
                const remove = last == "N" ? "W" : "N";
                set.delete(remove)
            }
            setValue(String.prototype.concat(...set))
        }}
        onKeyDown={(event) => onKeyDown(event)}
      />
    );
  });