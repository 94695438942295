
import CopyToClipboard from "react-copy-to-clipboard";
import {
    Container, Row, Col, Button, Card, CardBody, CardTitle, UncontrolledTooltip, Progress, Label
} from "reactstrap";
import { TooltipButton } from '../../../../inputs';
import React, { useEffect, useState, useCallback, useMemo, useRef, Component } from 'react';

export default class AttachmentFromEmailCellRenderer extends Component {

    constructor(props) {
        super(props);
        //this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    render() {

        var { props } = this;
        var { colDef, data } = props;


        return (<center>{data.anyAttachment ? "Y" : "N"}</center>);
    }
}