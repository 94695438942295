import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    Progress
 } from "reactstrap"; 
import moment from "moment";
import { TooltipButton } from '../../../inputs';
import api from '../../../../services/api';
import { DefaultColumnFilter } from '../../../react-table/filters';
import { useFlexLayout, useTable } from 'react-table';
import Grid from '../../../Grid';
import Loader from '../../../loaders';
import fileDownload from 'js-file-download';
import JobOrderCandidateEditor from '../../LabourRequest/JobOrder/JobOrderCandidateEditor';

export default function Recruitment(props) {
    const {
        itemInEdit,
        lookups,
    } = props;
    const skipPageResetRef = useRef();

    const [ candidateId, setCandidateId ] = useState(0);
    const [ data, setData ] = useState([]);
    const [ loading, setLoading ] = React.useState(false);
    const [ jobOrderCandidate, setJobOrderCandidate ] = React.useState(null);

    useEffect(() => {
        if (candidateId === (itemInEdit?.candidateId ?? 0))
            return;
        
        setCandidateId(itemInEdit?.candidateId ?? 0);
    }, [itemInEdit?.candidateId]);

    useEffect(() => {
        setData([]);

        if (!candidateId)
            return;

        setLoading(true);
        api.get(`candidate/joborders/${candidateId}`)
            .then(response => {
                setData(response.data);
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
        ;
    }, [candidateId]);

    const downloadAttachment = useCallback((id, type) => {
        setLoading(true);
        let url = `generatedcontract/download/${id}/${type}`;
        api.get(url, { responseType: 'blob' })
        .then(blob => {
            var filename = /(?<=attachment;.*filename=").*(?=";)/.exec(blob.headers["content-disposition"])[0]
            fileDownload(blob.data, filename);
        }).catch(error => {
            console.error(error)
        }).finally(() => setLoading(false))
    }, []);
    
    const columns = useMemo(() => [
        {
            Header: "Project Name",
            accessor: (row) => row.jobOrder.projectName,
            filter: "text",
            width: 100,
            disableSortBy: false,
        },
        {
            Header: "Dispatch Unit Name",
            accessor: (row) => row.rosterCandidates[0]?.roster.dispatchUnit.dispatchUnitName,
            filter: "text",
            width: 100,
            disableSortBy: false,
        },
        {
            Header: "Position Name",
            accessor: (row) => row.jobOrder.positionName,
            filter: "text",
            width: 100,
            disableSortBy: false,
        },
        {
            Header: "Job Location",
            accessor: (row) => row.jobOrder.recruitLocation,
            filter: "text",
            width: 100,
            disableSortBy: false,
        },
        {
            Header: "Expected Start",
            accessor: (row) => row.jobOrder.startDateExpected ? moment(row.jobOrder.startDateExpected).format("DD-MM-YYYY") : null,
            filter: "text",
            width: 60,
            disableSortBy: false,
        },
        {
            Header: "Recruitment Person",
            accessor: (row) => row.jobOrder.recruitmentPerson?.lookupValue,
            filter: "text",
            width: 100,
            disableSortBy: false,
        },
        {
            Header: "Latest Milestone Date",
            accessor: (row) => row.latestJobOrderMilestoneDate ? moment(row.latestJobOrderMilestoneDate).format("DD-MM-YYYY") : null,
            filter: "text",
            width: 60,
            disableSortBy: false,
        },
        {
            Header: "Latest Milestone",
            accessor: (row) => row.latestJobOrderMilestone?.milestoneName,
            filter: "text",
            width: 100,
            disableSortBy: false,
        },
        {
            Header: "Milestone Notes",
            accessor: (row) => "latestJobOrderMilestoneNote",
            Cell: ({ row }) => <span dangerouslySetInnerHTML={{ __html: row.original.latestJobOrderMilestoneNote }} />,
            filter: "text",
            width: 200,
            disableSortBy: false,
        },
        {
            Header: "Status",
            filter: "text",
            width: 60,
            disableSortBy: false,
            Cell: ({row:{original}}) => {
                const withdrawn = original.status?.lookupValue === "Withdrawn";

                return (
                    <div className={withdrawn ? "red text-white" : ""}>
                        {original.status?.lookupValue}
                    </div>
                )
            }
        },
        {
            Header: "Point of Hire",
            accessor: (row) => row.pointOfHire,
            filter: "text",
            width: 100,
            disableSortBy: false,
        },
        {
            Header: "Completion %",
            filter: "text",
            width: 100,
            disableSortBy: false,
            Cell: ({row: {original}}) => {
                const completionPercent = Math.round(original.completionPercent * 100);
                return (
                    <div className="w-100 progress-wrapper pt-0">
                        <div className="progress-info">
                            <div className="progress-percentage">
                                <span>{completionPercent}%</span>
                            </div>
                        </div>
                        <Progress max="100" value={completionPercent} color="default" className="w-100"></Progress>
                    </div>
                );
            }
        },
        {
            id: 'buttons',
            Header: "Action",
            width: 120,
            canResize: false,
            Cell: ({row:{original, index}}) => (
                (original && original.status?.lookupValue !== "Withdrawn") ?
                (
                    <div className="td-action text-center">
                        <TooltipButton
                            id={`progress_${original.jobOrderCandidateId}`}
                            title="Milestone Progress"
                            className="btn-icon"
                            color="default"
                            size="xsm"
                            type="button"
                            onClick={() => setJobOrderCandidate({jobOrderCandidateId: original.jobOrderCandidateId, index})}
                        >
                            <i className="fas fa-pencil-alt pt-1"></i>
                        </TooltipButton>
                        <TooltipButton
                            id={`download_word_${original.jobOrderCandidateId}`}
                            title="Download Contract Word"
                            className="btn-icon"
                            color="default"
                            size="xsm"
                            type="button"
                            onClick={() => downloadAttachment(original.generatedContractId, "docx")}
                        >
                            <i className="fas fa-file-word pt-1"></i>
                        </TooltipButton>
                        <TooltipButton
                            id={`download_pdf_${original.jobOrderCandidateId}`}
                            title="Download Contract PDF"
                            className="btn-icon"
                            color="default"
                            size="xsm"
                            type="button"
                            onClick={() => downloadAttachment(original.generatedContractId, "pdf")}
                        >
                            <i className="fas fa-file-pdf pt-1"></i>
                        </TooltipButton>
                    </div>
                ) :
                (
                    <div className="td td-action"></div>
                )
            )
        },
    ]
    , [data]);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
        disableFilters: true,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout
    );

    return (
        <>
            {loading && <Loader />}
            <Grid
                rows={rows}
                tableProps={getTableProps()}
                headerGroups={headerGroups}
                tableBodyProps={getTableBodyProps()}
                prepareRow={prepareRow}
                height="45vh"
            />
            {
                itemInEdit && jobOrderCandidate &&
                <JobOrderCandidateEditor
                    jobOrderCandidateId={jobOrderCandidate.jobOrderCandidateId}
                    itemIndex={jobOrderCandidate.index}
                    onClose={(item) => {
                        setJobOrderCandidate(null);
                    }}
                    onSaved={(item) => {
                        setJobOrderCandidate(null);
                    }}
                    lookups={{
                        ...lookups
                    }}
                />
            }
        </>
    );
}