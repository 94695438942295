import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import api from '../../../services/api';
import Loader from '../../loaders';
import { ReportDialog, ErrorNotification } from '../../alerts';
import { DatePicker } from "../../date-pickers";
import { formatDate } from "../../../utils";
import { ComboBox, DropdownBox } from "../../dropdowns";
import qs from "qs";
import moment from "moment";
import fileDownload from "js-file-download";
import { debounce } from "lodash";
import Multiselect from 'multiselect-react-dropdown';
import { IndeterminateCheckbox } from '../../react-table/controls';
//import { DropdownBox } from "../../dropdowns";

import {
    Container, Row, Col, Card, CardHeader, CardBody, Input, FormGroup, Label, Button, Collapse, Modal, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledPopover, PopoverBody,
    CardTitle
} from "reactstrap";

function CreateNewWithoutForecast(props) {

    // Natural data
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    // Report Navigation
    const [reportDialog, setReportDialog] = useState([]);
    const [step, setStep] = useState(1);
    const [selectedData, setSelectedData] = useState(null);

    // Report Fields
    const [isHavingParams, setIsHavingParams] = useState(true);
    const [isHavingStart, setIsHavingStart] = useState(false);
    const [isHavingEnd, setIsHavingEnd] = useState(false);
    const [isHavingProjectId, setIsHavingProjectId] = useState(false);
    const [isHavingDispatchUnitId, setIsHavingDispatchUnitId] = useState(false);
    const [isHavingLocationId, setIsHavingLocationId] = useState(false);
    const [isHavingPositionId, setIsHavingPositionId] = useState(false);
    const [isHavingCandidateId, setIsHavingCandidateId] = useState(false);
    const [isHavingIncludeInactive, setIsHavingIncludeInactive] = useState(false);
    const [isHavingExternalLink, setIsHavingExternalLink] = useState(false);
    const [paramsCount, setParamsCount] = useState(0);
    const [paramsCol, setParamsCol] = useState(12);

    // Report Params
    const today = new Date();
    //const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
    //const [endDate, setEndDate] = useState(new Date(today.getFullYear(), today.getMonth() + 6, 1));
    const [selectedProject, setSelectedProject] = React.useState(null);
    const [newRosterName, setNewRosterName] = useState(null);

    // Error message variables
    const [errorTitle, setErrorTitle] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);
    const [errorMessageOpening, setErrorMessageOpening] = React.useState(`Error occured when processing the request: `);
    const [errorTitleOnGenerate, setErrorTitleOnGenerate] = React.useState(`Create New Roster without Forecast`);

    // Dispatch Unit
    const [loadingDUs, setLoadingDUs] = React.useState(false);
    const [dispatchUnitList, setDispatchUnitList] = React.useState([]);
    const [selectedDus, setSelectedDus] = React.useState([]);
    const [selectedDuIds, setSelectedDuIds] = React.useState([]);
    const [selectedDispatchUnit, setSelectedDispatchUnit] = React.useState(null);

    // Locations
    const [loadingLocs, setLoadingLocs] = React.useState(false);
    const [locationsList, setLocationsList] = React.useState([]);
    const [selectedLocs, setSelectedLocs] = React.useState([]);
    const [selectedLoc, setSelectedLoc] = React.useState(null);
    const [selectedLocIds, setSelectedLocIds] = React.useState([]);

    // Position
    const [loadingPos, setLoadingPos] = React.useState(false);
    const [posList, setPosList] = React.useState([]);
    const [selectedPos, setSelectedPos] = React.useState([]);
    const [selectedPosIds, setSelectedPosIds] = React.useState([]);

    // Candidate
    const [loadingCad, setLoadingCad] = React.useState(false);
    const [cadList, setCadList] = React.useState([]);
    const [selectedCad, setSelectedCad] = React.useState([]);
    const [selectedCadIds, setSelectedCadIds] = React.useState([]);

    // Candidate
    const [includeInactiveValue, setIncludeInactiveValue] = useState(false);

    // Item In Edit
    const [itemInEdit, setItemInEdit] = useState({
        location: null,
        company: null,
        startDate: null,
        endDate: null,
        project: null,
        quoteNumber: null,
        dispatchUnitName: null,
        totalSlot: null,
    });

    // External Link
    const [externalLinkUrl, setExternalLinkUrl] = React.useState(null);

    // Etc
    let history = useHistory();
    var apiurl = 'roster';

    // BEGIN Project functions --------------------------------------------------------------------------------------------------------------------------------------

    useEffect(() => {
        setItemInEdit({ ...itemInEdit, project: selectedProject });
    }, [selectedProject]);

    // END Project functions --------------------------------------------------------------------------------------------------------------------------------------


    // BEGIN Location functions --------------------------------------------------------------------------------------------------------------------------------------
    const onLocationRemove = (newList) => {
        setSelectedLocs([...newList]);
        getLocation('');
    }
    const onLocationsFilterChangeMultiselect = (value) => {
        getLocation(value);
    }

    const getLocation = React.useCallback(debounce(filter => {
        // if (!filter || filter.length === 0) return;

        setLoadingDUs(true);
        api.get(`/location/search?filter=${filter}`)
            .then(({ data }) => {
                // setPositions([...data.map(d => ({...d, combinedName: `${d.hrispositionName} (${d.position.lookupValue})`}))]);
                setLocationsList(data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoadingDUs(false));
    }, 200), [locationsList]);

    const onLocationsChangeMultiselect = useCallback((values) => {
        //console.log(values);
        setSelectedLocs(values);
    }, [selectedLocs]);

    useEffect(() => {
        // console.log('selectedLoc', selectedLocs);
        var locIdList = selectedLocs.map((v) => v.locationId);
        setSelectedLocIds(locIdList);
    }, [selectedLocs]);
    // END Location functions --------------------------------------------------------------------------------------------------------------------------------------


    // BEGIN Dispatch Unit functions --------------------------------------------------------------------------------------------------------------------------------------
    const onDispatchUnitsFilterChangeMultiselect = (value) => {
        // console.log('onPositionsFilterChangeMultiselect', onDispatchUnitsFilterChangeMultiselect);
        getDispatchUnit(value);
    }

    const getDispatchUnit = React.useCallback(debounce(filter => {
        // console.log('getDispatchUnit');
        // if (!filter || filter.length === 0) return;

        if (!selectedProject && !selectedLocIds) {
            setDispatchUnitList([]);
            // console.log('project empty pos');

            return;
        }

        var projectId = !selectedProject ? null : selectedProject.projectId;

        setLoadingDUs(true);
        api.get(`/dispatchunit/search?filter=${filter}&projectId=${projectId ?? ''}&locationIds=${!selectedLocIds ? '' : selectedLocIds.join(',')}`)
            .then(({ data }) => {
                // setPositions([...data.map(d => ({...d, combinedName: `${d.hrispositionName} (${d.position.lookupValue})`}))]);
                setDispatchUnitList(data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoadingDUs(false));
    }, 200), [selectedProject, dispatchUnitList, selectedLocIds]);

    const onDispatchUnitsChangeMultiselect = useCallback((values) => {
        //console.log(values);
        setSelectedDus(values);
    }, [selectedDus]);

    useEffect(() => {
        var duIdList = selectedDus.map((v) => v.dispatchUnitId);
        setSelectedDuIds(duIdList);
    }, [selectedDus]);
    // END Dispatch Unit functions --------------------------------------------------------------------------------------------------------------------------------------

    // BEGIN Position functions --------------------------------------------------------------------------------------------------------------------------------------
    const onPositionsFilterChangeMultiselect = (value) => {
        // console.log('onPositionsFilterChangeMultiselect', onPositionsFilterChangeMultiselect);
        getPosition(value);
    }

    const getPosition = React.useCallback(debounce(filter => {
        // console.log('getPositions');
        // if (!filter || filter.length === 0) return;

        if (!selectedProject) {
            setPosList([]);
            // console.log('project empty pos');

            return;
        }

        var projectId = !selectedProject ? null : selectedProject.projectId;


        const params = {
            start: moment(itemInEdit?.startDate).format('YYYY-MM-DD'),
            end: moment(itemInEdit?.endDate).format('YYYY-MM-DD'),
            projectIds: projectId,
            filter: filter
        };

        setLoadingPos(true);
        api.get(`/Position/search-for-rca-report?${qs.stringify(params)}`)
            .then(({ data }) => {
                // setPositions([...data.map(d => ({...d, combinedName: `${d.hrispositionName} (${d.position.lookupValue})`}))]);
                setPosList(data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoadingPos(false));
    }, 200), [selectedProject, itemInEdit.startDate, itemInEdit.endDate, selectedProject]);

    const onPositionsChangeMultiselect = useCallback((values) => {
        //console.log(values);
        setSelectedPos(values);
        setSelectedCad([]);
    }, [selectedPos]);

    useEffect(() => {
        if (!selectedPos) {
            setSelectedPos([]);
            return;
        }
        var posIdList = selectedPos.map((v) => v.positionId);
        setSelectedPosIds(posIdList);
    }, [selectedPos]);
    // END Position functions --------------------------------------------------------------------------------------------------------------------------------------

    // BEGIN Candidate functions --------------------------------------------------------------------------------------------------------------------------------------
    const onCandidatesFilterChangeMultiselect = (value) => {
        // console.log('onCandidatesFilterChangeMultiselect', onCandidatesFilterChangeMultiselect);
        getCandidate(value);
    }

    const getCandidate = React.useCallback(debounce(filter => {
        // console.log('getCandidates');
        // console.log('selectedPosIds', selectedPosIds);
        // if (!filter || filter.length === 0) return;

        if (!selectedProject) {
            setCadList([]);
            // console.log('project empty Cad');

            return;
        }

        var projectId = !selectedProject ? null : selectedProject.projectId;


        const params = {
            start: moment(itemInEdit?.startDate).format('YYYY-MM-DD'),
            end: moment(itemInEdit?.endDate).format('YYYY-MM-DD'),
            projectIds: projectId,
            positionIds: !selectedPosIds ? '' : selectedPosIds.join(','),
            filter: filter
        };

        setLoadingCad(true);
        api.get(`/Candidate/search-for-rca-report?${qs.stringify(params)}`)
            .then(({ data }) => {
                // setCandidates([...data.map(d => ({...d, combinedName: `${d.hrisCandidateName} (${d.Candidate.lookupValue})`}))]);
                setCadList(data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoadingCad(false));
    }, 200), [selectedProject, itemInEdit.startDate, itemInEdit.endDate, selectedPosIds]);

    const onCandidatesChangeMultiselect = useCallback((values) => {
        //console.log(values);
        setSelectedCad(values);
    }, [selectedCad]);

    useEffect(() => {
        if (!selectedCad) {
            setSelectedCad([]);
            return;
        }
        var CadIdList = selectedCad.map((v) => v.candidateId);
        setSelectedCadIds(CadIdList);
    }, [selectedCad]);
    // END Candidate functions --------------------------------------------------------------------------------------------------------------------------------------

    // Load data function
    const loadData = useCallback(() => {
        //setLoading(true);
        //api.get("reports/reportlist")
        //    .then((response) => {
        //        setData(response.data);
        //    })
        //    .catch((error) => {
        //        setErrorMessage(`${errorMessageOpening}${error.message}`);
        //        console.error("error", error);
        //    })
        //    .finally(() => setLoading(false))
        //    ;
    }, []);

    // Load data on start
    useEffect(() => {
        loadData();
    }, []);

    // useEffect(() => {
    //     console.log(data);
    // }, [data]);

    useEffect(() => {
        // console.log('selectedProject', selectedProject);
        getDispatchUnit('');
        setSelectedDus([]);
        getPosition('');
        setSelectedPos('');

    }, [selectedProject, selectedLocIds]);

    useEffect(() => {
        // console.log('selectedPosIds', selectedPosIds);
        getCandidate('');
    }, [selectedPosIds]);


    useEffect(() => {

        getLocation('');
        setSelectedLocs([]);

    }, [isHavingLocationId]);



    useEffect(() => {
        if (step == 1) {
            setSelectedData(null);
        }
        if (step == 2 && isHavingExternalLink) {
            setStep(1);
        }
    }, [step, isHavingExternalLink]);

    const openReport = useCallback(() => {

    }, []);

    // Function to check is parameters have specific field name
    const isHaving = useCallback((fieldName) => {

        try {
            if (!selectedData || !selectedData.parameters)
                return null;

            var params = JSON.parse(selectedData.parameters);

            // If params is array
            if (Array.isArray(params)) {
                return params.find((v) => v.id == fieldName);
            }
            // If params is object
            else {
                if (fieldName in params) {
                    return params[fieldName];
                } else {
                    //console.warn(`fieldName ${fieldName} on params is not found, returns null instead`);
                    return null;
                }

            }
        }
        catch (error) {
            setErrorMessage(`${errorMessageOpening}${error.message}`);
            console.error(error);

            return null;
        }


    }, [selectedData]);

    // Function to check is parameters have required specific field name
    const isRequired = useCallback((fieldName) => {

        try {

            var obj = isHaving(fieldName);
            var isRequired = obj ? obj.required : false;
            return isRequired;
        }
        catch (error) {
            setErrorMessage(`${errorMessageOpening}${error.message}`);
            console.error(error);

            return false;
        }


    }, [selectedData]);


    // Initiate required fields
    useEffect(() => {
        var start = isHaving('start') ? true : false;
        var end = isHaving('end') ? true : false;
        var projectId = isHaving('projectId') ? true : false;
        var dispatchUnitId = isHaving('dispatchUnitId') ? true : false;
        var locationId = isHaving('locationId') ? true : false;
        var positionId = isHaving('positionId') ? true : false;
        var candidateId = isHaving('candidateId') ? true : false;
        var includeInactive = isHaving('includeInactive') ? true : false;
        var externalLink = isHaving('externalLink') ? true : false;

        setIsHavingStart(start);
        setIsHavingEnd(end);
        setIsHavingProjectId(projectId);
        setIsHavingDispatchUnitId(dispatchUnitId);
        setIsHavingLocationId(locationId);
        setIsHavingPositionId(positionId);
        setIsHavingCandidateId(candidateId);
        setIsHavingIncludeInactive(includeInactive)
        setIsHavingExternalLink(externalLink);

        setIsHavingParams(start || end || projectId || dispatchUnitId || locationId || positionId || candidateId || includeInactive);

        // Count params
        var count = [start, end, projectId, dispatchUnitId, locationId, positionId, candidateId, includeInactive].reduce((acc, current) => acc + !!current, 0);
        setParamsCount(count);
        setParamsCol(12 / count);

        // Add url
        setExternalLinkUrl(isHaving('externalLink'));


    }, [selectedData]);
    useEffect(() => {

        if (!externalLinkUrl)
            return;

        window.open(externalLinkUrl, "_blank");
        setExternalLinkUrl(null);

    }, [externalLinkUrl]);

    // Function void to download the data
    const createAction = useCallback(() => {

        try {

            // Validation
            if (!itemInEdit?.startDate) {
                setErrorTitle(errorTitleOnGenerate);
                setErrorMessage('Please input Start Date');
                return;
            }
            if (!itemInEdit?.endDate) {
                setErrorTitle(errorTitleOnGenerate);
                setErrorMessage('Please input End Date');
                return;
            }
            if (!itemInEdit?.project) {
                setErrorTitle(errorTitleOnGenerate);
                setErrorMessage('Please input Project');
                return;
            }
            if (!itemInEdit?.totalSlot) {
                setErrorTitle(errorTitleOnGenerate);
                setErrorMessage('Please input Total Slot');
                return;
            }
            if (!itemInEdit?.company) {
                setErrorTitle(errorTitleOnGenerate);
                setErrorMessage('Please input Entity');
                return;
            }
            if (!itemInEdit?.location) {
                setErrorTitle(errorTitleOnGenerate);
                setErrorMessage('Please input Location');
                return;
            }


            var postObj = { ...itemInEdit, startDate: formatDate(itemInEdit.startDate, 'YYYY-MM-DD'), endDate: formatDate(itemInEdit.endDate, 'YYYY-MM-DD') };
            var url = `${apiurl}/create-new-without-forecast`;
            setLoading(true);
            api.post(url, postObj)
                .then(response => {
                    // console.log("Merge: ", response);
                    var { data } = response;
                    //var { dispatchUnit } = data;
                    //var { dispatchUnitName } = dispatchUnit;

                    // If validation
                    if (data && typeof data == 'string' && data.includes("already exists")) {
                        setErrorTitle(errorTitleOnGenerate);
                        setErrorMessage(`${errorMessageOpening}${data}`);
                        return;
                    }

                    // If success
                    setNewRosterName(data.rosterName);

                    var filter = {
                        projectid: postObj.project.projectId,
                        dispatchunitid: data.dispatchUnitId,
                        rosterid: data.rosterId,
                        start: formatDate(itemInEdit.startDate, 'YYYY-MM-DD'),
                        end: formatDate(itemInEdit.endDate, 'YYYY-MM-DD'),
                    };

                    const queryString = qs.stringify(filter, { allowDots: false });


                    //history.push(`/roster?projectid=${postObj.project.projectId}&dispatchunitid=${data.dispatchUnitId}&rosterid=${data.rosterId}`);
                    history.push(`/roster?${queryString}`);
                    //setStep(2);
                }).catch((error) => {
                    //console.log("error result: ", error);
                    console.error("error: ", error);
                    setErrorTitle(errorTitleOnGenerate);
                    setErrorMessage(`${errorMessageOpening}${error.message}`);
                }).finally(() => setLoading(false));

        }
        catch (error) {
            console.error(error);
            setErrorTitle(errorTitleOnGenerate);
            setErrorMessage(`${errorMessageOpening}${error.message}`);
        }
    }, [itemInEdit, errorMessageOpening, errorTitleOnGenerate]);


    const getProjectFilename = useCallback(() => {
        return selectedProject ? `-Project ${selectedProject.projectName.replace(/^(project)/ig, "")}` : ``;
    }, [selectedProject]);

    const getIncludeInactiveFilename = useCallback(() => {
        return includeInactiveValue == null ? `` : `-Include Inactive ${includeInactiveValue == true ? 'Yes' : 'No'}`;
    }, [includeInactiveValue]);


    const beginWith = (text, beginWith) => {
        var index = text.indexOf(beginWith);

        return index == 0 ? true : false;
    };

    const firstOrMulti = useCallback((list, keyName, prefix = '') => {

        // console.log('firstOrMulti', { list, keyName, prefix })
        //console.log('list', list);

        if (!list || list.length == 0) {
            return ``;
        }

        // console.log('continue');

        var prefixLower = prefix ? prefix.toLowerCase() : '';
        var unitName = list.find(w => w)[keyName];
        if (unitName && prefixLower) {
            var regEx = new RegExp(`/^(${prefixLower})/ig`);
            unitName = unitName.replace(regEx, '');
        }

        // Remove duplicate prefix
        //if (prefix && beginWith(unitName, prefix))


        if (list.length == 1) {
            return `-${prefix} ${unitName}`;
        }
        else {
            return `-${prefix} Multiple`;
        }
    }, []);

    const downloadExport = React.useCallback((url, filename) => {
        setLoading(true);
        api.get(url, { responseType: 'blob' })
            .then(blob => {
                // console.log(blob);
                //var filename = /(?<=attachment;.*filename=").*(?=";)/.exec(blob.headers["content-disposition"])[0];
                fileDownload(blob.data, filename);
            }).catch(error => {
                console.error(error);
                setErrorMessage(`${errorMessageOpening} ${error.message} `);
                //setLoading(false);
            }).finally(() => setLoading(false))
    }, []);

    // Error message handling
    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            message: errorMessage,
            title: errorTitle,
            onConfirm: () => {
                setErrorTitle(null);
                setErrorMessage(null);
            }
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage, errorTitle]);


    return (
        <section className="main">
            {
                (loading) && <Loader />
            }
            {reportDialog.length > 0 && reportDialog}
            {errorNotification.length > 0 && errorNotification}
            <Container fluid>
                <Card className="no-transition">
                    <CardBody className="full-height-card">
                        <Row>
                            <Col lg={12}>
                                <CardTitle>
                                    <h4 className="text-center">Roster - Create New without Forecast</h4>
                                </CardTitle>
                            </Col>
                        </Row>

                        <Collapse id="step-1" isOpen={step == 1}>

                            <Row className="text-justify">
                                <Col lg={3} md={2} className="hidden-sm">
                                </Col>

                                <Col lg={6} md={8} sm={12}>

                                    <Row>


                                        <Col sm={12} md={6}>
                                            <FormGroup>
                                                <Label for="dispatchUnitName">
                                                    Dispatch Name
                                                    <span className="text-danger">*</span>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    name="dispatchUnitName"
                                                    id="dispatchUnitName"
                                                    value={itemInEdit?.dispatchUnitName ?? ""}
                                                    onChange={(e) => setItemInEdit({ ...itemInEdit, dispatchUnitName: e.target.value })}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <FormGroup>
                                                <Label for="locationName">
                                                    Location
                                                    <span className="text-danger">*</span>
                                                </Label>
                                                <ComboBox
                                                    endpoint="/location/search"
                                                    isLookup={false}
                                                    idField="locationId"
                                                    valueField="locationName"
                                                    selectedItem={itemInEdit?.location}
                                                    onChange={(item) => {
                                                        setItemInEdit({ ...itemInEdit, location: { ...item } })
                                                    }}
                                                    minLength={0}
                                                />
                                                {/*
                                                <DropdownBox
                                                    id="ddLocation"
                                                    data={locationsList}
                                                    selectedItem={selectedLoc}
                                                    onChange={(item) => {
                                                        setItemInEdit({ ...itemInEdit, location: { ...item } });
                                                    }}
                                                />
                                                */}
                                                {/*<Input
                                                        type="text"
                                                        name="locationName"
                                                        id="locationName"
                                                        disabled={true}
                                                        placeholder={itemInEdit?.location?.lookupValue ?? ""}
                                                    />*/}
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <FormGroup>
                                                <Label for="quoteNumber">
                                                    Quote Number
                                                    <span className="text-danger">*</span>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    name="quoteNumber"
                                                    id="quoteNumber"
                                                    value={itemInEdit?.quoteNumber ?? ""}
                                                    onChange={(e) => setItemInEdit({ ...itemInEdit, quoteNumber: e.target.value })}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <FormGroup>
                                                <Label for="projectName">
                                                    Project
                                                    <span className="text-danger">*</span>
                                                </Label>
                                                <ComboBox
                                                    endpoint="/project/search"
                                                    isLookup={false}
                                                    idField="projectId"
                                                    valueField="projectName"
                                                    selectedItem={selectedProject}
                                                    onChange={setSelectedProject}
                                                    minLength={0}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <FormGroup>
                                                <Label for="totalslot">
                                                    Number of Slots to Create
                                                    <span className="text-danger">*</span>
                                                </Label>
                                                <Input
                                                    type="number"
                                                    name="totalSlot"
                                                    id="totalSlot"
                                                    min={1}
                                                    placeholder="Please input 1 to xxx."
                                                    value={itemInEdit?.totalSlot ?? ""}
                                                    onChange={(e) => setItemInEdit({ ...itemInEdit, totalSlot: e.target.value })}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <FormGroup>
                                                <Label for="entity">
                                                    Entity
                                                    <span className="text-danger">*</span>
                                                </Label>
                                                <ComboBox
                                                    endpoint="/company/search"
                                                    isLookup={false}
                                                    idField="companyId"
                                                    valueField="companyName"
                                                    selectedItem={itemInEdit?.company}
                                                    onChange={(item) => {
                                                        setItemInEdit({ ...itemInEdit, company: item });
                                                    }}
                                                    minLength={0}
                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col sm={12} md={6}>
                                            <FormGroup>
                                                <Label for="startDate">
                                                    Start Date
                                                    <span className="text-danger">*</span>
                                                </Label>
                                                <DatePicker
                                                    name="startDate"
                                                    id="startDate"
                                                    type="text"
                                                    required
                                                    value={!itemInEdit?.startDate ? '' : formatDate(itemInEdit.startDate)}
                                                    onChange={(value) => {
                                                        setItemInEdit({ ...itemInEdit, startDate: value });
                                                        //setStartDate(value);
                                                    }}
                                                    closeOnSelect
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <FormGroup>
                                                <Label for="endDate">
                                                    End Date
                                                    <span className="text-danger">*</span>
                                                </Label>
                                                <DatePicker
                                                    name="endDate"
                                                    id="endDate"
                                                    type="text"
                                                    required
                                                    value={!itemInEdit?.endDate ? '' : formatDate(itemInEdit.endDate)}
                                                    onChange={(value) => {
                                                        setItemInEdit({ ...itemInEdit, endDate: value });
                                                        //setEndDate(value);
                                                    }}
                                                    closeOnSelect
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col lg={3} md={2} className="hidden-sm">
                                </Col>

                            </Row>

                            <Row className="text-center mt-4">
                                <Col sm="12">
                                    <Button color="secondary" type="button"
                                        onClick={() => {
                                            history.push("/");
                                        }}>
                                        <span class="fa fa-arrow-left mr-2"></span>
                                        Back
                                    </Button>
                                    <Button color="info" type="button"
                                        onClick={() => {
                                            //generateMessage();
                                            createAction();
                                        }}>
                                        <span class="fa fa-save mr-2"></span>
                                        Create
                                    </Button>
                                </Col>
                            </Row>
                            {/*<Row className="text-center mt-4">
                                <Col sm="12">
                                    <small>Too confused?
                                        <a className="ml-1" href="javascript:;" onClick={() => {
                                        exportManual();
                                    }}><u>Click here</u></a> to use the old school.</small>
                                </Col>
                            </Row>*/}
                        </Collapse>

                        <Collapse id="step-2" isOpen={step == 2}>

                            <Row className="text-justify">
                                <Col lg={3} md={2} className="hidden-sm">
                                </Col>

                                <Col lg={6} md={8} sm={12}>

                                    <Row>
                                        <Col sm={12} className="mt-4">
                                            <h3>Roster successfully created with name "{newRosterName}".</h3>
                                        </Col>
                                        <Col sm={12} className="mt-3">
                                            <Button color="info" type="button"
                                                onClick={() => {
                                                    history.push("/");
                                                }}>
                                                <span class="fa fa-check mr-2"></span>
                                                Ok
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Collapse>
                    </CardBody>
                </Card>
            </Container>
        </section>
    );
};

export default CreateNewWithoutForecast;