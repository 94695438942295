
import CopyToClipboard from "react-copy-to-clipboard";
import {
    Container, Row, Col, Button, Card, CardBody, CardTitle, UncontrolledTooltip, Progress, Label
} from "reactstrap";
import { TooltipButton } from '../../../../../inputs';
import React, { useEffect, useState, useCallback, useMemo, useRef, Component } from 'react';


export default class CompletionPercentCellRenderer extends Component {

    constructor(props) {
        super(props);
        //this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    render() {

        var { props } = this;
        var { colDef, data } = props;

        if (data.isCancelled) {
            return <span>Cancelled</span>
        }
        const completionPercent = data.completionDate ? 100 : Math.round(data.completionPercent * 100);
        return (
            <div className="text-center">
                <div className="w-100 progress-wrapper pt-0">
                    <div className="progress-info">
                        <div className="progress-percentage">
                            <span>{completionPercent}%</span>
                        </div>
                    </div>
                    <Progress max="100" value={completionPercent} color="default" className="w-100"></Progress>
                </div>
            </div>
        );


    }
}
