import React from 'react';
import { useTable, useFilters, useFlexLayout, useSortBy } from 'react-table';
import api from "../../../../services/api";
import {
    Label, FormGroup, Button, Modal, Container, Row, Col, UncontrolledTooltip
} from "reactstrap";
import Loader from "../../../loaders";
import { DefaultColumnFilter, NumberRangeColumnFilter } from "../../../react-table/filters";
import _ from "lodash";
import { FixedSizeList } from 'react-window';
import { TooltipButton } from '../../../inputs';
import { IndeterminateCheckbox } from '../../../react-table/controls';
import { useMemo } from 'react';

const scrollbarWidth = () => {
    // thanks too https://davidwalsh.name/detect-scrollbar-width
    const scrollDiv = document.createElement('div')
    scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;')
    document.body.appendChild(scrollDiv)
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
    document.body.removeChild(scrollDiv)
    return scrollbarWidth
}

function CandidateSearch(props) {

    const [itemInSearch, setItemInSearch] = React.useState(null);
    const [tnaSkills, setTNASkills] = React.useState([]);
    const [potentialCandidates, setPotentialCandidates] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [showRosteredTalents, setShowRosteredTalents] = React.useState(false);
    const [showAssignedTalents, setShowAssignedTalents] = React.useState(false);

    const handleSave = (item) => {
        setLoading(true);
        var newItem = { ...itemInSearch, candidateId: item.id, candidateName: item.name };

        api.put(`/jobordercandidate/${itemInSearch.jobOrderCandidateId}`, JSON.stringify(newItem))
            .then((response) => {
                setLoading(false);
                props.onSaved(item, props.jobOrderCandidateIndex);
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    }

    // const loadData = (filters = [], sortBy = null) => {
    //     setLoading(true);

    //     const queryString = qs.stringify({filters, sortBy}, { allowDots: true });

    //     //setPotentialCandidates([]);

    //     api.get(`/jobordercandidate/${props.jobOrderCandidateId}/search${queryString ? `?${queryString}` : ""}`)
    //     .then(({data}) => {
    //         console.log("Load Data: ", data);
    //         setPotentialCandidates(data);
    //      })
    //     .catch((error) => {
    //         console.error(error);
    //     })
    //     .finally(() => setLoading(false));
    // }



    React.useEffect(() => {

        var showRosteredTalentsInt = showRosteredTalents ? 1 : 0;
        var showAssignedTalentsInt = showAssignedTalents ? 1 : 0;

        if (props.jobOrderCandidateId > 0) {
            setLoading(true);

            api.get(`/jobordercandidate/${props.jobOrderCandidateId}`)
                .then(async ({ data }) => {
                    let apiCalls = [
                        api.get(`/jobordercandidate/${props.jobOrderCandidateId}/tnaskills`),
                        api.get(`/jobordercandidate/${props.jobOrderCandidateId}/search?showRosteredTalents=${showRosteredTalentsInt}&showAssignedTalents=${showAssignedTalentsInt}`)
                    ];
                    return await Promise.all(apiCalls)
                        .then(([skills, candidates]) => {
                            setItemInSearch(data);
                            setTNASkills(skills.data);
                            setPotentialCandidates(candidates.data);
                        })
                        .catch((error) => console.error(error))

                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => setLoading(false));
        }
    }, [props.jobOrderCandidateId, showRosteredTalents, showAssignedTalents]);

    const columns = React.useMemo(() => {
        const cols = [
            {
                id: "select",
                Header: "",
                minWidth: 30,
                width: 30,
                maxWidth: 30,
                Cell: ({ index, row }) => {
                    return <TooltipButton
                        id={`select_${index}`}
                        title="Select Candidate"
                        className="btn-icon"
                        color="default"
                        size="xsm"
                        type="button"
                        onClick={() => handleSave(row.original)}
                    >
                        <i className="fas fa-share"></i>
                    </TooltipButton>
                }
            },
            {
                Header: "Candidate Name",
                accessor: "name",
            },
            {
                Header: "Employed Position",
                accessor: "position",
            },
            {
                Header: "Assigned Job Order",
                accessor: "assignedJobOrder",
            },
            //{
            //    Header: "LRF",
            //    accessor: "lrfNumber",
            //},
            //{
            //    Header: "Start Date",
            //    accessor: "startDate",
            //},
            //{
            //    Header: "HRIS Position",
            //    accessor: "employedPosition",
            //},
            {
                Header: "State",
                accessor: "state",
                width: 50,
            },
            {
                Header: "Status",
                accessor: "status",
                width: 50,
            },
            {
                Header: "Score",
                accessor: "score",
                width: 100,
                Filter: NumberRangeColumnFilter,
                filter: (rows, [column], [min, max]) => {
                    if (!min && !max) {
                        return rows;
                    }
                    return _.filter(rows, (row) => (!min || row.values[column] >= min) && (!max || row.values[column] <= max));
                },
            },

        ];

        if (tnaSkills && tnaSkills.length > 0) {
            tnaSkills.forEach((skill) => {
                cols.push(
                    {
                        id: skill.trainingMasterId,
                        Header: skill.trainingTitle,
                        disableSortBy: true,
                        disableFilters: true,
                        accessor: () => skill.trainingMasterId,
                        Cell: ({ row, value }) => {
                            const skills = row.original.training[value]?.skills || [];

                            return skills.length === 0 ? null : (<>
                                <div className="text-green text-center w-100 h-100 d-flex justify-content-center align-items-center" id={`tip${row.id}_${value}`}>
                                    <i className="fas fa-check-circle"></i>
                                </div>
                                <UncontrolledTooltip placement="top" target={`tip${row.id}_${value}`}>
                                    <ul className="list-unstyled mb-1 text-left">
                                        {skills.map((skill, index) => <li key={index}>{skill.name}</li>)}
                                    </ul>
                                </UncontrolledTooltip>
                            </>)
                        }
                    }
                )
            })
        }

        return cols;
    }, [tnaSkills]);

    const defaultColumn = React.useMemo(() => ({
        minWidth: 30,
        width: 150,
        maxWidth: 200,
        Filter: DefaultColumnFilter,
        filter: "text",
    }), []);

    const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])
    const viewPortHeight = React.useMemo(() => window.innerHeight, []);

    const tableHeight = useMemo(() => {
        var height = viewPortHeight - 331;
        var notifBar = itemInSearch && tnaSkills.length === 0 ? 69 : 0;
        var checkBar = 40;

        height = height + notifBar + checkBar;
        return height;
    }, [viewPortHeight, itemInSearch, tnaSkills]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        totalColumnsWidth,
        //state 
    } = useTable(
        {
            columns,
            data: potentialCandidates,
            defaultColumn,
            //manualFilters: true,
            //manualSortBy: true,
        },
        useFlexLayout,
        useFilters,
        useSortBy
    );

    // const loadDataDebounced = useAsyncDebounce(loadData, 200)
    // React.useEffect(() => {
    //     if (itemInSearch) {
    //         console.log("debounce")
    //         loadDataDebounced(state.filters, state.sortBy); 
    //     }
    // }, [itemInSearch, state.filters, state.sortBy]);

    const RenderRow = React.useCallback(
        ({ style, index }) => {
            const row = rows[index];
            prepareRow(row)
            return (
                <div {...row.getRowProps({ style })}
                    className={`tr`}

                >
                    {row.cells.map((cell, i) => {
                        return <div {...cell.getCellProps()} className={`td ${i > 3 ? "text-center" : ""}`}>{cell.render('Cell')}</div>
                    })}
                </div>
            )
        }, [prepareRow, rows])

    return (
        <Modal
            isOpen={true}
            className="modal-xl m-4"
            modalClassName="modal-fullwidth"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Candidate Search - {itemInSearch?.jobOrder?.hrispositionMasterName}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}>x</span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                <Container fluid>
                    {(loading) && <Loader />}
                    {itemInSearch && tnaSkills.length === 0 &&
                        <Row className="mt-n2">
                            <Col xs={12} className="mb-3">
                                <div className="alert alert-danger" role="alert">No required / recommended skills (TNA) for this position under Project {itemInSearch?.jobOrder?.projectName} </div>
                            </Col>
                        </Row>
                    }
                    <Row className="mt-n3">

                        <Col sm={6} className="text-xs-left text-sm-right d-flex flex-wrap justify-content-start align-items-center mb-2">
                            <IndeterminateCheckbox
                                id="showAssignedTalents"
                                type="checkbox"
                                labelClassName="job-order-control mr-3"
                                onChange={() => {
                                    setShowAssignedTalents(!showAssignedTalents);
                                }}
                                checked={showAssignedTalents}
                                customTitle="Show Assigned Talents"
                            />
                            <IndeterminateCheckbox
                                id="showRosteredTalentsCheckBox"
                                type="checkbox"
                                labelClassName="job-order-control"
                                onChange={() => {
                                    setShowRosteredTalents(!showRosteredTalents);
                                }}
                                checked={showRosteredTalents}
                                customTitle="Show Rostered Talents"
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <div {...getTableProps()} className="table table-hover">
                                <div className="thead">
                                    {headerGroups.map(headerGroup => (
                                        <div {...headerGroup.getHeaderGroupProps()} className="tr">
                                            {headerGroup.headers.map((column, i) => {

                                                return (
                                                    <div {...column.getHeaderProps()} className={`th ${i > 4 ? "title-pre-wrap text-center" : ""}`}>

                                                        <span {...column.getSortByToggleProps()}>
                                                            {column.render('Header')}
                                                        </span>

                                                        <span>
                                                            {column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? <i className="fas fa-long-arrow-alt-up ml-1"></i>
                                                                    : <i className="fas fa-long-arrow-alt-down ml-1"></i>
                                                                : ''}
                                                        </span>

                                                        <div className="mt-1">
                                                            {column.canFilter ? column.render('Filter') : null}
                                                        </div>

                                                    </div>
                                                )
                                            }
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div {...getTableBodyProps()} className="tbody">
                                    <FixedSizeList
                                        height={tableHeight}
                                        style={{ width: "100%" }}
                                        itemCount={rows.length}
                                        itemSize={35}
                                        width={totalColumnsWidth + scrollBarSize}
                                    >
                                        {RenderRow}
                                    </FixedSizeList>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={props.onClose}
                    type="button"
                >
                    Close
                </Button>
            </div>

        </Modal>
    )

}
export default CandidateSearch;