import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from "moment";
import api from '../../../../services/api';
import { DefaultColumnFilter, DateColumnFilter } from '../../../react-table/filters';
import { useTable, useFlexLayout, useFilters, useSortBy } from 'react-table';
import Grid from '../../../Grid';
import Loader from '../../../loaders';

export default function TrainingHistory(props) {
    const {
        itemInEdit,
    } = props;
    const skipPageResetRef = useRef();

    const [data, setData] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [filters, setFilters] = React.useState([]);
    const defaultSort = React.useMemo(() => [],[]);
    const [sortBy, setSortBy] = React.useState(defaultSort);
    const [candidateId, setCandidateId] = useState(0);

    const refreshData = useCallback(() => {
        setData([]);

        if (!candidateId)
            return;

        setLoading(true);
        skipPageResetRef.current = true;
        api.get(`candidatetraining/bytalent/${candidateId}`)
            .then(response => {
                setData(response.data);
            })
            .catch(error => console.log({error}))
            .finally(() => setLoading(false))
            ;
    }, [candidateId]);

    useEffect(() => {
        if (candidateId === (itemInEdit?.candidateId ?? 0))
            return;

        setCandidateId(itemInEdit?.candidateId ?? 0);
    }, [itemInEdit?.candidateId]);
    
    useEffect(() => {
        refreshData();
    }, [candidateId]);

    const columns = useMemo(() => [
        {
            id: "startDate",
            Header: "Start Date",
            accessor: (row) => moment(row.startDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
            Filter: DateColumnFilter,
            filter: "text",
            width: 100,
            disableSortBy: true,
            disableFilters: true,
        },
        {
            id: "endDate",
            Header: "End Date",
            accessor: (row) => moment(row.endDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
            Filter: DateColumnFilter,
            filter: "text",
            width: 100,
            disableSortBy: true,
            disableFilters: true,
        },
        {
            id: "trainingDetails",
            Header: "Training Details",
            accessor: (row) => row.trainingDetails,
            Filter: DefaultColumnFilter,
            filter: "text",
            width: 135,
            disableSortBy: true,
            disableFilters: false,
        },
    ], [data]);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: false,
        disableFilters: false,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
    } = useTable(
        {
            columns,
            data,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
    );

    React.useEffect(() => {
        setFilters(state.filters);
    }, [state.filters]);

    React.useEffect(() => {
        setSortBy(state.sortBy);
    }, [state.sortBy]);

    return (
        <>
            {loading && <Loader />}
            <Grid
                rows={rows}
                tableProps={getTableProps()}
                headerGroups={headerGroups}
                tableBodyProps={getTableBodyProps()}
                prepareRow={prepareRow}
                height={'calc(42vh)'}
            />
        </>
    );
}