import { Collapse } from "reactstrap";
import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";

export default function DuplicateNotification(props) {

    const {
        onConfirm,
        fields,
        title = 'Data Duplicate',
        desc = 'Data duplicate was found, please check again your data.',
        showList = false,
        column1duplicateDataTitle = 'Duplicate Item',
        column2duplicateFirstSource = 'Was found in',
        column3duplicateSecondSource = 'And duplicate to',
        data = [],
        showList2 = false,
        data2 = []
    } = props;

    console.log('DuplicateNotification', 'props', props);
    console.log('showList', showList);

    return (
        <SweetAlert
            title={<h4 className="mb-0">{title ?? "Data Duplicate"}</h4>}
            onConfirm={onConfirm}
            type="danger"
            className="alert-notif df df-sweetalert"
        >
            <div className={showList2 && data2 && data2.length > 1 ? "mb-5" : "mb-4"}>
                {desc ?? "Data duplicate was found, please check again your data."}
            </div>
            <Collapse
                isOpen={showList}
            >
                {
                    showList &&
                    <table className="table table-hover scrollable df df-table">
                        <thead>
                            <tr>
                                <th>{column1duplicateDataTitle}</th>
                                <th>{column2duplicateFirstSource}</th>
                                <th>{column3duplicateSecondSource}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.length > 0 && data.map((item, index) => {
                                return (<tr key={item.id}>
                                    <td className="show-complete">{item.title}</td>
                                    <td className="show-complete">{item.firstDuplicate}</td>
                                    <td className="show-complete">{item.secondDuplicate}</td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                }
            </Collapse>
            <Collapse className="text-justify ml-3 mr-3"
                isOpen={showList2}
            >
                {
                    data2 && data2.length > 0 && data2.map((item, index) => {
                        return (
                        <div className="one-duplicate-set mb-4">
                            <div className="mb-2">{item.duplicateItem}:</div>
                            <div>
                                <ul className="ul-normal-start">
                                    {item.duplicateSourceList && item.duplicateSourceList.length > 0 && item.duplicateSourceList.map((oneDuplicate) => {
                                        return (<li>{oneDuplicate}</li>);
                                    })}
                                </ul>
                            </div>
                        </div>);

                    })
                }
            </Collapse>
        </SweetAlert>
    );
}
