const today = new Date();

export const initialState = {
    startDate: new Date(today.getFullYear(), today.getMonth(), 1),
    endDate: new Date(today.getFullYear(), today.getMonth() + 3, 0),
    state: null,
    scope: null,
    client: null,
    department: null,
    manager: null,
    project: null,
    notification: null,
};

export const actions = {
    SET_START_DATE: "SET_START_DATE",
    SET_END_DATE: "SET_END_DATE",
    SET_CLIENT: "SET_CLIENT",
    SET_DEPARTMENT: "SET_DEPARTMENT",
    SET_MANAGER: "SET_MANAGER",
    SET_PROJECT: "SET_PROJECT",
    SET_SCOPE: "SET_SCOPE",
    SET_STATE: "SET_STATE",
    SET_NOTIFICATION: "SET_NOTIFICATION"
}

const forecastReducer = (state, action) => {
    const { type, payload } = action;
    //console.log('forecastReducer', { type, payload })
    switch (type) {
        case actions.SET_START_DATE:
            return {
                ...state,
                startDate: payload.startDate,
            }
        case actions.SET_END_DATE:
            return {
                ...state,
                endDate: payload.endDate,
            }
        case actions.SET_CLIENT:
            return {
                ...state,
                client: {...payload.client},
            }
        case actions.SET_DEPARTMENT:
            return {
                ...state,
                department: {...payload.department},
            }
        case actions.SET_MANAGER:
            return {
                ...state,
                manager: {...payload.manager},
            }
        case actions.SET_PROJECT:
            return {
                ...state,
                project: {...payload.project},
            }
        case actions.SET_SCOPE:
            return {
                ...state,
                scope: {...payload.scope},
            }
        case actions.SET_STATE:
            return {
                ...state,
                state: {...payload.state},
            }
        case actions.SET_NOTIFICATION:
            return {
                ...state,
                notification: payload.notification,
            }

    }
}

export default forecastReducer;