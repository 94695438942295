import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import api from "../../../../services/api";
import TemplateDialog from "./TemplateDialog";
import { useBlockLayout, useFlexLayout, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import Grid from "../../../Grid";
import { Checkbox, RadioButton } from "@progress/kendo-react-inputs";
import { profileRows } from "./MergeHelper";

function MergeSummaryDialog(props) {
    const {
        title,
        selectedCandidates,
        selectedCandidateId,
        selectedCandidateProfile,
        selectedCandidateAttribute,
        selectedInactiveDates,
        selectedDocuments,
        selectedClientInclusions,
        selectedClientExclusions,
        onClose,
        onBackClick,
        onNextClick,
    } = props;

    const [ loading, setLoading ] = useState(false);
    const [ profileData, setProfileData ] = useState([]);
    const [ data, setData ] = useState([]);
    const skipPageResetRef = useRef();

    const loadData = useCallback(() => {
        skipPageResetRef.current = true;

        if (!selectedCandidates?.length)
            return;

        setLoading(true);

        const summaryParam = {
            candidateIds: selectedCandidates.map(c => c.candidateId),
            projectCandidateAttributeIds: selectedCandidateAttribute,
            inactiveDateIds: selectedInactiveDates,
            documentIds: selectedDocuments,
            clientInclusionClientIds: selectedClientInclusions,
            clientExclusionClientIds: selectedClientExclusions,
        };

        api.post(`candidatemerge/summarize`, JSON.stringify(summaryParam))
            .then((response) => {
                const transposedProfileData = [];

                profileRows.forEach(r => {
                    // console.log({rowId: r.id, currentRow: selectedCandidateProfile[r.id]});
                    const row = {
                        title: r.title,
                        rowCustomId: r.id,
                        rowType: r.type,
                        additionalPropertyName: r.additionalPropertyName,
                        additionalPropertyType: r.additionalPropertyType,
                        label: selectedCandidateProfile[r.id].value,
                    };
                    transposedProfileData.push(row);
                });

                setProfileData(transposedProfileData);

                const {data: responseData} = response;
                console.log({responseData});
                setData([...responseData]);
            })
            .catch((error) => console.log({error}))
            .finally(() => setLoading(false))
        ;
    }, [skipPageResetRef, api, selectedCandidateAttribute, selectedInactiveDates, selectedDocuments, selectedClientInclusions, selectedClientExclusions, selectedCandidateProfile]);

    useEffect(() => loadData(), [selectedCandidates]);

    const defaultColumn = useMemo(() => ({
        disableFilters: true,
        disableSortBy: true,
    }), []);

    const columns = useMemo(() => {
        return [
            {
                Header: "",
                accessor: "title",
                sticky: "left",
                width: 100,
                maxWidth: 100,
            },
            ...selectedCandidates.filter(c => c.candidateId === selectedCandidateId).map(d => ({
                Header: () => (
                    <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                        <span className="text-left ml-2">{d.candidateName} ({d.candidateId})</span>
                    </div>
                ),
                id: `${d.candidateId}`,
                Cell: ({ row: { original } }) => {
                    return (
                        <div className={`w-100 px-1 d-flex flex-row justify-content-start align-items-start`}>
                            <span className="text-left ml-2">
                            {
                                original.rowType !== "bool" ?
                                original.label ?? "-"
                                :
                                original.label ? "Yes" : "No"
                            }
                            </span>
                        </div>
                    );
                },
                width: 150,
                minWidth: 150,
            }))
        ]
    }, [selectedCandidates, selectedCandidateId]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data: profileData,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout,
        useBlockLayout,
        useSticky,
    );

    const content = useMemo(() => {
        selectedCandidates.sort((a, b) => a.candidateId - selectedCandidateId !== 0 ? Math.abs(a.candidateId - b.candidateId) : -1);

        return (
            <div style={{maxHeight: "65vh", overflowY: "auto"}}>
            {/* <div> */}
                <Row className="m-0 p-0 w-100">
                    <Col lg={12} className="m-0 p-0 d-flex flex-wrap justify-content-start align-items-center">
                        <span className="font-size-small-custom">
                            <i className="fas fa-info-circle mr-1" />Click Merge button to start the process.
                        </span>
                    </Col>
                </Row>
                {/* <Row className="m-0 p-0 mt-3 w-100">
                    <Grid
                        height={'calc(100vh - 464px)'}
                        totalRecords={profileRows.length}
                        pageSize={profileRows.length}
                        rows={rows}
                        tableProps={getTableProps()}
                        headerGroups={headerGroups.map(h => {
                            const headerGroupProps = h.getHeaderGroupProps();
                            headerGroupProps.style = {...headerGroupProps.style, width: "100%"}

                            return {
                                ...h,
                                getHeaderGroupProps: () => headerGroupProps
                            };
                        })}
                        tableBodyProps={getTableBodyProps()}
                        prepareRow={(row) => {
                            prepareRow(row);
                            const rowProps = row.getRowProps();
                            const {original} = row;
                            row.getRowProps = () => {
                                return {
                                    ...rowProps,
                                    style: {
                                        ...rowProps.style,
                                        width: "100%",
                                    },
                                };
                            };

                            row.cells = row.cells.map(c => {
                                const columnId = parseInt(c.column.id);
                                const cellProps = c.getCellProps();
                                return {
                                    ...c,
                                    getCellProps: () => ({
                                        ...cellProps,
                                        style: {
                                            ...cellProps.style,
                                            backgroundColor: columnId === selectedCandidateId && (!original.rowType || original.rowType !== "title") ? "#c2deff" : c.column.id === "title" && (!original.rowType || original.rowType !== "title") ? "#fff" : original.rowType === "title" ? "#aaa" : "inherit",
                                            color: original.rowType === "title" ? "#fff" : "inherit",
                                        }
                                    })
                                };
                            });
                        }}
                        fullscreen={true}
                    />
                </Row> */}
                <Row className="m-0 p-0 mt-3 w-100">
                {
                    selectedCandidates.filter(d => d.candidateId === selectedCandidateId).map((c, idx) => {
                        const currentData = data.filter(d => d.candidateId === c.candidateId)[0];

                        return (
                            <Col lg={12} key={`merge_${idx}`}>
                                <span className="font-size-small-custom">
                                    Main Profile: <b>{c.candidateName} ({c.candidateId})</b><br />
                                    {/* Merged Identifiers: {currentData?.projectCandidateAttributeCount ?? 0}<br />
                                    Merged Documents: {currentData?.documentCount ?? 0}<br />
                                    Merged Inactive Dates: {currentData?.inactiveDateCount ?? 0}<br />
                                    Merged Client Inclusions: {currentData?.clientInclusionCount ?? 0}<br />
                                    Merged Client Exclusions: {currentData?.clientExclusionCount ?? 0}<br /> */}
                                </span>
                            </Col>
                        );
                    })
                }
                </Row>
                <Row className="m-0 p-0 mt-3 w-100">
                    <Col lg={12}>
                        <span className="font-size-small-custom">Merged Profiles:<br/></span>
                    </Col>
                {
                    selectedCandidates.filter(d => d.candidateId !== selectedCandidateId).map((c, idx) => {
                        const currentData = data.filter(d => d.candidateId === c.candidateId)[0];

                        return (
                            <Col lg={12} key={`merge_${idx}`} className={idx === 0 ? "mt-2" : ""}>
                                <span className="font-size-small-custom">
                                    <b>{c.candidateName} ({c.candidateId})</b><br />
                                    {/* Merged Identifiers: {currentData?.projectCandidateAttributeCount ?? 0}<br />
                                    Merged Documents: {currentData?.documentCount ?? 0}<br />
                                    Merged Inactive Dates: {currentData?.inactiveDateCount ?? 0}<br />
                                    Merged Client Inclusions: {currentData?.clientInclusionCount ?? 0}<br />
                                    Merged Client Exclusions: {currentData?.clientExclusionCount ?? 0}<br /> */}
                                </span>
                            </Col>
                        );
                    })
                }
                </Row>
            </div>
        );
    }, [profileRows, rows, selectedCandidates, data, getTableProps, headerGroups, getTableBodyProps, prepareRow, selectedCandidateId]);

    const footer = useMemo(() => (
        <>
            <Button
                color="secondary"
                onClick={onClose}
                type="button"
            >
                Close
            </Button>
            <Button
                color="info"
                onClick={() => onBackClick()}
                type="button"
            >
                <i className="fas fa-chevron-left mr-2" />Back: Data Compare - Client Exclusion
            </Button>
            <Button
                color="info"
                onClick={() => onNextClick()}
                type="button"
            >
                Merge<i className="fas fa-chevron-right ml-2" />
            </Button>
        </>
    ), [onClose, onBackClick, onNextClick]);

    // useEffect(() => console.log({selectedCandidateProfile}), [selectedCandidateProfile]);

    // useEffect(() => console.log({profileData}), [profileData]);

    return (
        <TemplateDialog
            title={title}
            loading={loading}
            content={content}
            footer={footer}
            onClose={onClose}
        />
    );
};

export default MergeSummaryDialog;
