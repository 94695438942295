import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { Button, Col, Container, Label, Modal, Row } from 'reactstrap';
import Loader from '../../../loaders';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';
import readXlsxFile from 'read-excel-file';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import api from "../../../../services/api";
import RosterSheetImportStatusTooltip from '../tooltips/RosterSheetImportStatusTooltip';

const UPLOAD_MODE = "upload";

export default function ImportRosterSheetDialog(props) {
    const {
        rosterId,
        onClose,
    } = props;

    const [loading, setLoading] = useState(false);
    const [importFiles, setImportFiles] = useState([]);
    const [alert, setAlert] = useState(null);
    const [data, setData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [dateColumns, setDateColumns] = useState([]);
    const [mode, setMode] = useState(UPLOAD_MODE);
    const gridRef = useRef();

    const onDrop = useCallback(acceptedFiles => setImportFiles([...acceptedFiles]), [importFiles]);
    const loadingOverlayComponent = useMemo(() => Loader, []);

    const {
        getRootProps, 
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ 
        onDrop,
        maxFiles: 1,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const dropzoneClasses = useMemo(() => cn({
        'dropzone': true,
        'mt-2': true,
        'active': isDragActive,
        'accept': isDragAccept,
        'reject': isDragReject
    }), [
        isDragActive,
        isDragAccept,
        isDragReject
    ]);

    const files = useMemo(() => importFiles
        .map(
            file => (
                <li key={file.path}>
                    {file.path} - {file.size} bytes
                </li>
            )
        )
    , [importFiles]);

    const defaultColDef = useMemo(() => ({
        sortable: false,
        resizable: true,
        editable: false,
        filter: 'agSetColumnFilter',
        filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200
        },
    }), []);

    const defaultColumns = [
        { 
            field: "slot",
            headerName: "Slot",
        },
        { 
            field: "employeeName",
            headerName: "Employee Name",
        },
        { 
            field: "rosterPosition",
            headerName: "Roster Position",
        },
        { 
            field: "employedPosition",
            headerName: "Employed Position",
        },
        { 
            field: "shift",
            headerName: "Shift",
        },
        { 
            field: "siteTravelType",
            headerName: "Site Travel Type",
        },
        { 
            field: "pointOfHire",
            headerName: "Point of Hire",
        },
    ];

    const handleFileSelected = useCallback(() => {
        if (!importFiles?.length)
            return;

        setLoading(true);
        const file = importFiles[0];

        readXlsxFile(file, { getSheets: true })
            .then((sheets) => {
                const sheet = sheets[0];

                readXlsxFile(file, { sheet: sheet.name })
                    .then((rows) => {
                        if (rows?.length < 1 || rows[0]?.length < defaultColumns.length) {
                            setAlert({ type: "error", message: "Invalid data format." });
                            setImportFiles([]);
                            return;
                        }

                        const columns = [...rows[1]];
                        const newDateColumns = [];
                        const newRows = [...rows];
                        newRows.splice(0, 2);
                        const gridColumns = [];
                        const newData = [];

                        columns.forEach(c => {
                            if (!defaultColumns.filter(d => d.headerName.toLowerCase() === c.toLowerCase())?.length && !moment(c, "DD/MM/YYYY", true).isValid())
                                return;

                            const isDefaultColumn = defaultColumns.filter(d => d.headerName.toLowerCase() === c.toLowerCase())?.length > 0;
                            
                            const column = {
                                field: defaultColumns.filter(d => d.headerName.toLowerCase() === c.toLowerCase())?.length ? defaultColumns.filter(d => d.headerName.toLowerCase() === c.toLowerCase())[0].field : moment(c, "DD/MM/YYYY").format("YYYYMMDD"),
                                headerName: c
                            };

                            if (!isDefaultColumn)
                            {
                                column.width = 80;
                                column.maxWidth = 80;
                                column.minWidth = 80;
                                newDateColumns.push(column);
                            }

                            gridColumns.push(column);
                        });

                        newRows.forEach((r, r_idx) => {
                            const rowNo = r_idx + 1;
                            const row = { rowNo: rowNo };

                            r.forEach((c, idx) => {
                                if (!gridColumns.filter(d => d.headerName.toLowerCase() === columns[idx].toLowerCase())?.length && !moment(columns[idx], "DD/MM/YYYY", true).isValid())
                                    return;

                                const columnName = gridColumns.filter(d => d.headerName.toLowerCase() === columns[idx].toLowerCase())?.length ? gridColumns.filter(d => d.headerName.toLowerCase() === columns[idx].toLowerCase())[0].field : moment(columns[idx], "DD/MM/YYYY").format("YYYYMMDD");
                                row[columnName] = c;
                            });

                            // console.log(`row[${r_idx}] = `, {...row});
                            newData.push({ ...row });
                        });

                        // console.log({sheets, columns, newRows, gridColumns, newData});

                        // console.log({gridColumns, newRows, newData, newValidationData});
                        setColumnDefs([...gridColumns]);
                        setDateColumns([...newDateColumns]);
                        setData([...newData]);
                    })
                    .catch((error) => setAlert({type: "error", message: error}))
                    .finally(() => setLoading(false));
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;

    }, [importFiles, defaultColumns]);

    useEffect(() => {
        if (!importFiles?.length)
            return;

        handleFileSelected();
    }, [importFiles]);

    const resizeColumns = useCallback(() => {
        if (!gridRef?.current?.columnApi || data?.length === 0)
            return;

        const allColumnIds = [];
        gridRef.current.columnApi.getColumns().forEach((column) => {
            // console.log(column, column.getId());
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }, [data, gridRef]);

    const handleValidateData = useCallback(() => {
        if (!rosterId)
            return;

        setLoading(true);

        const dataToSend = data.map(d => {
            const item = {
                slot: d.slot,
                employeeName: d.employeeName,
                rosterPosition: d.rosterPosition,
                employedPosition: d.employedPosition,
                shift: d.shift,
                siteTravelType: d.siteTravelType,
                pointOfHire: d.pointOfHire,
                dates: {},
            };

            dateColumns.forEach(dc => {
                if (!d.hasOwnProperty(dc.field))
                    return;

                item.dates[dc.field] = d[dc.field];
            });

            return item;
        });

        // console.log({dataToSend, dateColumns});

        api.post(`roster/sheet/validateimport?rosterId=${rosterId}`, JSON.stringify(dataToSend))
            .then((response) => {
                // console.log({response});
                const {data: validationData} = response;
                const newData = JSON.parse(JSON.stringify(data));

                newData.forEach(d => {
                    const validationItem = validationData.filter(v => v.slot === (typeof d.slot === "number" ? d.slot.toString() : d.slot))[0];

                    if (!validationItem)
                        return;

                    d.validationResult = {...validationItem};
                });

                // console.log({newData});

                setData([...newData]);
                setColumnDefs([
                    {
                        field: "validationResult",
                        headerName: "Validation Result",
                        valueFormatter: () => '',
                        cellClass: (params) => `roster-cell link text-center ${params.data.validationResult ? `${params.data.validationResult.warningItems.filter(w => w.isError).length > 0 ? "fas fa-times-circle grid-status-declined" : (params.data.validationResult.warningItems.length > 0 ? "fas fa-exclamation-circle grid-status-pending" : "fas fa-check-circle grid-status-confirmed")}` : ""}`,
                        tooltipComponent: (props) => {
                            // console.log({props});
                            return props.node.data.validationResult.warningItems.length ? RosterSheetImportStatusTooltip({ ...props }) : null;
                        },
                        width: 100,
                        maxWidth: 100,
                        minWidth: 100,
                        tooltipField: "validationResult",
                    },
                    ...defaultColumns,
                    ...dateColumns,
                ]);
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [rosterId, data, dateColumns, defaultColumns]);

    const handleImport = useCallback(() => {
        if (!rosterId)
            return;

        setLoading(true);

        const dataToSend = data.map(d => {
            const item = {
                slot: d.slot,
                rosterPosition: d.rosterPosition,
                employedPosition: d.employedPosition,
                shift: d.shift,
                siteTravelType: d.siteTravelType,
                pointOfHire: d.pointOfHire,
                dates: {},
            };

            dateColumns.forEach(dc => {
                if (!d.hasOwnProperty(dc.field))
                    return;

                item.dates[dc.field] = d[dc.field];
            });

            return item;
        });

        api.post(`roster/sheet/import?rosterId=${rosterId}`, JSON.stringify(dataToSend))
            .then(() => {
                setLoading(false);
                onClose();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [rosterId, data, dateColumns, onClose]);

    return (
        <Modal
            isOpen={true}
            className="modal-xl"
            modalClassName="db-example-modal-xl"
        >
        {
            loading && <Loader />
        }
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Import Roster Sheet
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div {...getRootProps({className: dropzoneClasses})}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop a file here, or click to select a file</p>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <aside className="dropzone-list pt-3">
                                <Label>File</Label>
                                <ul>{files}</ul>
                            </aside>
                        </Col>
                    </Row>
                    <Row>
                    {
                        data?.length > 0 && data.filter(d => d.validationResult && d.validationResult.warningItems.filter(v => v.isError).length > 0).length ?
                        <Col xs={12} className="d-flex flex-wrap justify-content-end align-items-center px-0 mb-2">
                            <span className="text-warning font-italic">Can't continue the import process. Please hover to the Validation Result column that marked with <i className="fas fa-times-circle grid-status-declined" />.</span>
                        </Col>
                        :
                        null
                    }
                    {
                        data?.length > 0 && data.filter(d => !d.validationResult).length ?
                        <Col xs={12} className="d-flex flex-wrap justify-content-end align-items-center px-0 mb-2">
                            <span className="text-warning font-italic">Please validate before continue to import the data.</span>
                        </Col>
                        :
                        null
                    }
                        <Col xs={12} className="d-flex flex-wrap justify-content-center align-items-center px-0">
                            <div className="ag-theme-alpine" style={{ width: '100%', height: data?.length ? "44vh" : 75 }}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={data}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    loadingOverlayComponent={loadingOverlayComponent}
                                    suppressColumnVirtualisation={false}
                                    suppressRowVirtualisation={false}
                                    debounceVerticalScrollbar={false}
                                    rowBuffer={20}
                                    headerHeight={25}
                                    groupHeaderHeight={22}
                                    rowHeight={22}
                                    onRowDataUpdated={() => resizeColumns()}
                                    suppressDragLeaveHidesColumns={true}
                                    suppressColumnMoveAnimation={true}
                                    animateRows={true}
                                    tooltipShowDelay={500}
                                    tooltipHideDelay={3600000}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer d-flex flex-wrap justify-content-between align-items-center w-100"
            >
                <div className="d-flex flex-wrap justify-content-start align-items-center">
                    <Button
                        color="secondary"
                        onClick={onClose}
                        type="button"
                    >
                        Close
                    </Button>
                </div>
                <div className="d-flex flex-wrap justify-content-end align-items-center">
                    <Button
                        color="default"
                        onClick={() => {
                            handleValidateData();
                        }}
                            type="button"
                            disabled={!rosterId || !importFiles?.length}
                        className="mr-2"
                    >
                        <i className="fas fa-check-square mr-2" />Validate
                    </Button>
                    <Button
                        color="default"
                        onClick={() => {
                            handleImport();
                        }}
                        type="button"
                        disabled={!data.length || data.filter(d => !d.validationResult || d.validationResult.warningItems.filter(v => v.isError).length > 0).length}
                    >
                        <i className="fas fa-paper-plane mr-2" />Upload to Roster
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
