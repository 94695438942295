
import CopyToClipboard from "react-copy-to-clipboard";
import {
    Container, Row, Col, Button, Card, CardBody, CardTitle, UncontrolledTooltip, Progress, Label
} from "reactstrap";
import { TooltipButton } from '../../../../inputs';
import { IndeterminateCheckbox } from '../../../../react-table/controls';
import React, { useEffect, useState, useCallback, useMemo, useRef, Component } from 'react';

export default class DispatchNameCellRenderer extends Component {

    constructor(props) {
        super(props);
        //this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    render() {

        var { props } = this;
        var { colDef, data } = props;
        var { handleActionTaken } = props;


        return (
            <center>
                <IndeterminateCheckbox
                    id={`actiontaken-${data.messageLogId}`}
                    type="checkbox"
                    onChange={() => handleActionTaken(data)}
                    checked={data.actionTaken ?? false}
                />
            </center>
        );
    }
}