import moment from 'moment';
import React, { useState, useCallback } from 'react';
import api from "../../../../services/api";
import Loader from "../../../loaders";
import EditorSMS from '../../Candidates/EditorSMS';
import CandidateConfirmationEditor from './CandidateConfirmationEditor';

export default function CandidateDASEditor(props) {
    const {
        project,
        dispatchUnits,
        rosterCandidates,
        onClose,
        onSaved,
        confirmationMessage,
        lookups
    } = props;

    const [loading, setLoading] = React.useState(false);
    const [ messageEditorParam, setMessageEditorParam ] = useState(null);

    const handleSendMessage = useCallback((apiurl, message, loadingCallback, failedMessagesCallback, templateId) => {
        if (!message || !message.message || message.message === "" || !messageEditorParam?.rosterCandidates?.length)
            return;

        if (loadingCallback)
            loadingCallback(true);
        else
            setLoading(true);

        const body = {
            schedules: messageEditorParam.rosterCandidates.map((original) => {
                const {
                    rosterCandidateId,
                    startDate,
                    endDate,
                    rosterCandidateScheduleIds
                } = original;

                return {
                    rosterCandidateId,
                    startDate: moment(startDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                    endDate: moment(endDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                    rosterCandidateScheduleIds
                }
            }),
            message: encodeURI(message.message),
            messagesMasterId: templateId
        };

        // console.log(body);

        api.post(apiurl, body)
            .then((response) => {
                const fails = [...response.data.failed];

                if (!fails.length)
                {
                    setMessageEditorParam(null);
                    setLoading(false);
                    onSaved();
                    return;
                }

                failedMessagesCallback([...fails]);

                if (loadingCallback)
                    loadingCallback(false);
                else
                    setLoading(false);
            })
            .catch((error => {
                console.log(error.response);

                if (loadingCallback)
                    loadingCallback(false);
                else
                    setLoading(false);
            }));
    }, [messageEditorParam?.rosterCandidates]);

    return (
        <>
            {(loading) && <Loader />}
            <CandidateConfirmationEditor
                project={project}
                dispatchUnits={dispatchUnits}
                rosters={null}
                rosterCandidates={rosterCandidates}
                confirmationMessage={confirmationMessage}
                onClose={onClose}
                onSaved={onSaved}
                lookups={{...lookups}}
                customConfirm={{
                    callback: (data) => {
                        setMessageEditorParam({
                            rosterCandidates: [...data]
                        });
                    },
                    confirmButtonTitle: "Send Confirmation"
                }}
                singleSelection={true}
                customTitle="DAS Confirmation"
            />
        {
            messageEditorParam ?
            <EditorSMS
                items={messageEditorParam.rosterCandidates}
                onClose={() => {
                    setMessageEditorParam(null);
                    onClose();
                }}
                onCustomSave={(message, loadingCallback, failedMessagesCallback, templateId) => handleSendMessage("/rostercandidateaccommodation/das-confirm", { message }, loadingCallback, failedMessagesCallback, templateId)}
                lookups={{
                    ...lookups
                }}
                defaultMessage={confirmationMessage}
                sourcePage="roster"
            />
            :
            null
        }
        </>
    )
};