
import CopyToClipboard from "react-copy-to-clipboard";
import {
    Container, Row, Col, Button, Card, CardBody, CardTitle, UncontrolledTooltip, Progress, Label
} from "reactstrap";
import { TooltipButton } from '../../../../inputs';
import React, { useEffect, useState, useCallback, useMemo, useRef, Component } from 'react';



export default class ActionBtnCellRenderer extends Component {

    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }
    btnClickedHandler() {
        this.props.clicked(this.props.value);
    }
    render() {
        //console.log('BtnCellRenderer', 'this', this);
        var { props } = this;
        var { colDef, data } = props;
        var { additionalParams } = colDef;
        //console.log('additionalParams', additionalParams());
        const {
            copiedIndex,
            setCopiedIndex,
            itemInEdit,
            setItemInEdit,
            handleDelete,
        } = additionalParams();

        const {
            getLRFRelatedData,
            history,
            voidAuthorized
        } = this.props;

        return (
            <div key={`action_container_${data.recruitmentRequestId}`} className="text-center">
                <TooltipButton
                    id={`edit_${data.recruitmentRequestId}`}
                    title="Edit"
                    className="btn-icon"
                    color="default"
                    size="xss"
                    type="button"
                    onClick={(event) => { history.push(`/lrf/${data.recruitmentRequestId}`) }}
                >
                    <i className="fa fa-xs fa-pencil-alt pt-1"></i>
                </TooltipButton>
                <TooltipButton
                    id={`joborders_${data.recruitmentRequestId}`}
                    title="View Job Orders"
                    className="btn-icon"
                    color="default"
                    size="xss"
                    type="button"
                    onClick={(event) => { history.push(`/joborder/${data.recruitmentRequestId}/request`) }}
                >
                    <i className="fa fa-xs fa-link pt-1"></i>
                </TooltipButton>
                {
                    voidAuthorized && data.status?.lookupValue === "Approved" &&
                    <TooltipButton
                        id={`void_${data.recruitmentRequestId}`}
                        title="Void"
                        className="btn-icon"
                        color="default"
                        size="xss"
                        type="button"
                        onClick={() => this.props.getLRFRelatedData(data)}
                    >
                        <i className="fa fa-xs fa-trash-alt pt-1"></i>
                    </TooltipButton>
                }
            </div>
        );


    }
}
