import React from 'react';
import { useTable, useFlexLayout, useFilters, useSortBy, useGroupBy, useExpanded, useAsyncDebounce } from 'react-table';
import api from "../../../services/api";
import {
   Container, Row, Col
} from "reactstrap";
import Loader from "../../loaders";
import { DefaultColumnFilter } from "../../react-table/filters";
import qs from "qs";
import { TooltipButton } from "../../inputs";
import _ from "lodash";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../alerts";
import TcEditor from "./TcEditor";
import Grid from "../../Grid";
import fileDownload from "js-file-download";
import { Mode } from "../../../utils";
import { useState } from 'react';
import TcImportDialog from './TcImportDialog';

const date = new Intl.DateTimeFormat('en-AU');

function useControlledState(state, { instance }) {
    return React.useMemo(() => {
        if (state.groupBy.length) {
            return {
                ...state,
                hiddenColumns: [...state.hiddenColumns, ...state.groupBy].filter((col, index, all) => all.indexOf(col) === index),
            }
        }
        return state;
    }, [state])
}

export default function List(props) {

    const [ data, setData ] = React.useState([]);
    const [ loading, setLoading ] = React.useState(false);
    const [ itemInEdit, setItemInEdit ] = React.useState(null);
    // const [ pageNumber, setPageNumber ] = React.useState(1);
    // const [ pageSize, setPageSize ] = React.useState(10);
    // const [ totalRecords, setTotalRecords ] = React.useState(0);
    const [ deleteAlert, setDeleteAlert ] = React.useState([]);
    const [ filters, setFilters ] = React.useState([]);
    const [ sortBy, setSortBy ] = React.useState([]);
    const [lookups, setLookups] = React.useState({ components: [], employmentcategories: [] });
    const [drawerMode, setDrawerMode] = React.useState(Mode.NONE);
    const [importDialogIsOpen, setImportDialogIsOpen] = useState(false);

    const {
        selectedProject
    } = props;

    const skipPageResetRef = React.useRef();

    React.useEffect(() => {
        setLoading(true);
        const apiCalls = [api.get('lookup/component'), api.get('lookup/employmentcategory')];
        Promise.all(apiCalls)
        .then((response) => {
            setLookups({
                components: [...response[0].data],
                employmentcategories: [...response[1].data]
            })
        })
        .catch(error => console.error(error))
        .finally(() => setLoading(false));

    }, []);

    
    const loadData = React.useCallback(() => {
        setItemInEdit(null);
        setData([]);
        setDrawerMode(Mode.NONE);
        if (!selectedProject) {
            return
        }
  
        skipPageResetRef.current = true;

        const queryString = qs.stringify({filters, sortBy}, { allowDots: true });

        setLoading(true);
        api.get(`/termandcondition/${selectedProject.projectId}${queryString ? `?${queryString}` : ""}`)
        .then(({data}) => {
            console.log('data', data)
            setData(data);
            //setTotalRecords(data.total);
        }).catch((error) => {
            console.error("error: ", error);
        }).finally(() => setLoading(false));
    }, [selectedProject, filters, sortBy]);



    const handleDelete = React.useCallback((item) => {
        const success = (response) => {
            if (!response.status === 200) {
                throw new Error("Delete failed.");
            }
            setDeleteAlert([<DeleteSuccessAlert onConfirm={() => setDeleteAlert([])} />]);
            loadData();
        };
        const failure = () => setDeleteAlert([<DeleteFailedAlert onConfirm={() => setDeleteAlert([])} />]);
        const events = {
            onConfirm: () => api.delete(`/termandcondition/${item.termAndConditionId}`).then(success).catch(failure),
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert {...events} />])
    }, [loadData])

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
                })
            },
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            width: 60,
            disableGroupBy: true
    }), []);

    const defaultSort = React.useMemo(() => [{ id: "employmentCategory.lookupValue" },{ id: "component.lookupValue" }, { id: "location.locationName" }, { id: "position.positionName" }], []);
    const defaultGroup = React.useMemo(() => ["employmentCategory.lookupValue"], []);
    const defaultExpand = React.useMemo(() => ({ "employmentCategory.lookupValue:Blue Collar": true, "employmentCategory.lookupValue:White Collar": true }), []);

    const columns = React.useMemo(() => {
        return [
            {
                Header: "Category",
                accessor: "employmentCategory.lookupValue",
                Filter: DefaultColumnFilter,
                filter: "text",
                width: 120,
                disableGroupBy: false
            },
            {
                Header: "Component",
                accessor: "component.lookupValue",
                Filter: DefaultColumnFilter,
                filter: "text",
                width: 150,
                disableGroupBy: false
            },
            {
                Header: "Value",
                accessor: "componentValue",
                Filter: DefaultColumnFilter,
                filter: "text",
                width: 150
            },
            {
                Header: "Start Date",
                accessor: "startDate",
                Cell: ({value}) => value ? date.format(Date.parse(value)) : "",
                disableFilters: true,
                width: 85,
            },
            {
                Header: "End Date",
                accessor: "endDate",
                Cell: ({value}) => value ? date.format(Date.parse(value)) : "",
                disableFilters: true,
                width: 85,
            },
            {
                Header: "Location",
                accessor: "location.locationName",
                Filter: DefaultColumnFilter,
                filter: "text",
                width: 100,
                disableGroupBy: false
            },
            {
                Header: "Position",
                accessor: "position.positionName",
                Filter: DefaultColumnFilter,
                filter: "text",
                width: 100,
                disableGroupBy: false
            },
            {
                Header: "Actions",
                id: 'button',
                Cell: ( {row: { original }} ) => 
                {
                    if (!original) return null;

                    return (
                        <div key={original.rateId}>
                            <TooltipButton
                                id={`edit_${original.rateId}`}
                                title="Edit"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={(event) =>{
                                    setItemInEdit(original);
                                    setDrawerMode(Mode.EDIT);
                                }}
                            >
                                <i className="fas fa-pencil-alt pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`delete_${original.rateId}`}
                                title="Delete"
                                className="btn-icon"
                                color="warning"
                                size="sm"
                                type="button"
                                onClick={(event) => handleDelete(original)}
                            >
                                <i className="fas fa-trash-alt pt-1"></i>
                            </TooltipButton>
                        </div>
                    )
                },
                width: 85,
            }
        ]
    }, [handleDelete]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: defaultSort,
                groupBy: defaultGroup,
                expanded: defaultExpand
            },
            defaultColumn,
            manualSortBy: true,
            filterTypes,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        useFilters,
        useGroupBy,
        useSortBy,
        useExpanded,
        useFlexLayout,
        (hooks) => {
            hooks.useControlledState.push(useControlledState);
            hooks.visibleColumns.push((columns, { instance }) => {
                if (!instance.state.groupBy.length) {
                    return columns
                }
                return [
                    {
                        id: 'expander', 
                        width: 150,
                        Header: ({ allColumns, state: { groupBy } }) => {
                            return groupBy.map(columnId => {
                                const column = allColumns.find(d => d.id === columnId);
                                return (
                                    <span {...column.getHeaderProps()}>
                                        {column.canGroupBy ? (
                                            <span {...column.getGroupByToggleProps()}>
                                                {column.isGrouped ? <i className="fas fa-minus mr-1"></i> : <i className="fas fa-plus mr-1"></i>}
                                            </span>
                                        ) : null}
                                        {column.render('Header')}{' '}
                                    </span>
                                )
                            })
                        },
                        Cell: ({ row }) => {
                            if (row.canExpand) {
                                const groupedCell = row.allCells.find(d => d.isGrouped);
                                return (
                                    <span
                                        {...row.getToggleRowExpandedProps({
                                        style: {
                                            paddingLeft: `${row.depth * 2}rem`,
                                        },
                                        })}
                                    >
                                        {row.isExpanded ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-right"></i>} {groupedCell.render('Cell')}{' '}
                                        ({row.subRows.length})
                                    </span>
                                )
                            }
                            return null
                        },
                    },
                    ...columns,
                ]
              })
        }
    )

    const setFiltersDebounced = useAsyncDebounce(setFilters, 200);
    
    React.useEffect(() => {
        setFiltersDebounced(state.filters); 
    }, [state.filters]);

    React.useEffect(() => {
        setSortBy(state.sortBy); 
    }, [state.sortBy]);

    React.useEffect(() => {
        loadData()        
    }, [selectedProject, filters, sortBy]);

    return (
        <>
            {deleteAlert.length > 0 && deleteAlert}
            {loading && <Loader />}
            <Container fluid className="px-0">
                <Row noGutters>
                    <Col xs={12}>
                        <TooltipButton
                            id="addnew"
                            title="Add New"
                            className="btn-icon ml-2 mb-2"
                            color="default"
                            size="sm"
                            type="button"
                            disabled={!selectedProject}
                            onClick={() => {  
                                setItemInEdit({
                                    termAndConditionId: 0,
                                    projectId: selectedProject.projectId,
                                    startDate: new Date()
                                });
                                setDrawerMode(Mode.ADD);
                            }}
                        >
                            <i className="fas fa-plus pt-1"></i>
                        </TooltipButton>
                        <TooltipButton
                            id="export"
                            title="Export to Excel"
                            className="btn-icon ml-2 mb-2"
                            color="default"
                            size="sm"
                            type="button"
                            disabled={!selectedProject}
                            onClick={() => { 
                                setLoading(true);

                                let url = `termandcondition/export/${selectedProject.projectId}`;
                                api.get(url, { responseType: 'blob' })
                                .then(blob => {                
                                    console.log("Download: ", blob);
                                    fileDownload(blob.data, `Term and Condition of ${selectedProject.projectName}.xlsx`);
                                }).catch(error => {
                                    console.error(error)
                                }).finally(() => setLoading(false))
                            }}
                        >
                            <i className="fas fa-file-excel"></i> Export
                        </TooltipButton>
                        <TooltipButton
                            id="import"
                            title="Import"
                            className="btn-icon ml-2 mb-2"
                            color="default"
                            size="sm"
                            type="button"
                            disabled={!selectedProject}
                            onClick={() => {
                                //setLoading(true);
                                setImportDialogIsOpen(true);

                            }}
                        >
                            <i className="fas fa-file-import"></i> Import
                        </TooltipButton>
                        <h5 className="text-left ml-3 d-inline">Terms &amp; Conditions</h5>
                    </Col>
                    <Col xs={12}>
                        <Grid
                            height={"auto"}
                            maxHeight={'47vh'}
                            rows={rows}
                            tableProps={getTableProps()}
                            headerGroups={headerGroups}
                            tableBodyProps={getTableBodyProps()}
                            prepareRow={prepareRow}
                        />
                    </Col>
                </Row>
            </Container>
            {
                !!itemInEdit &&
                <TcEditor
                    item={itemInEdit}
                    components={[...lookups.components]}
                    employmentcategories={[...lookups.employmentcategories]}
                    onClose={() => {
                        setItemInEdit(null);
                        setDrawerMode(Mode.NONE);
                    }}
                    onSaved={() => {
                        loadData();
                        setDrawerMode(Mode.NONE);
                    }}
                    drawerMode={drawerMode}
                    setDrawerMode={setDrawerMode}
                />
            }
            {
                importDialogIsOpen && !!selectedProject &&
                <TcImportDialog
                    projectId={selectedProject.projectId}
                    onClose={() => {
                        setImportDialogIsOpen(false);
                        setLoading(false);
                    }}
                />
            }
        </>
    )

}