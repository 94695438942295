
import CopyToClipboard from "react-copy-to-clipboard";
import {
    Container, Row, Col, Button, Card, CardBody, CardTitle, UncontrolledTooltip, Progress, Label
} from "reactstrap";
import { TooltipButton } from '../../../../inputs';
import React, { useEffect, useState, useCallback, useMemo, useRef, Component } from 'react';

export default class DispatchNameCellRenderer extends Component {

    constructor(props) {
        super(props);
        //this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    render() {

        var { props } = this;
        var { colDef, data } = props;

        if (data.rosterCandidate) {
            return (<a className="text-info" href={`/roster?projectid=${data?.rosterCandidate?.roster?.dispatchUnit?.projectId}&dispatchunitid=${data?.rosterCandidate?.roster?.dispatchUnitId}`} target="_blank"><u>{data.rosterCandidate?.roster?.dispatchUnit?.dispatchUnitName}</u></a>);
        }
        return null;
    }
}