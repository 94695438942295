import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Loader from "../../../loaders";
import { Button, Col, Container, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import api from "../../../../services/api";
import { DefaultColumnFilter } from "../../../react-table/filters";
import { useExpanded, useFilters, useFlexLayout, useSortBy, useTable } from "react-table";
import Grid from "../../../Grid";
import moment from "moment";
import { TooltipButton } from "../../../inputs";
import { ConfirmDeleteAlert } from "../../../alerts";

export default function RosterCandidateNoteEditor(props) {
    const {
        data,
        onClose
    } = props;
    const [loading, setLoading] = useState(false);
    const initItem = {rosterCandidateId: data?.rosterCandidateId, candidateId: data?.candidateId ?? null};
    const [itemInEdit, setItemInEdit] = useState(initItem);
    const [history, setHistory] = useState([]);
    const [deleteParam, setDeleteParam] = useState(null);
    const skipPageResetRef = useRef();

    const loadData = useCallback(() => {
        if (!data.rosterCandidateId)
            return;

        setLoading(true);
        api.get(`rostercandidatenote/search/rostercandidate?id=${data.rosterCandidateId}`)
            .then((response) => {
                // console.log({response});
                setHistory([...response.data]);
                setItemInEdit(initItem);
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [data]);

    useEffect(() => {
        if (!data.rosterCandidateId)
            return;

        loadData();
    }, [data]);

    const handleSave = useCallback(() => {
        setLoading(true);

        const apiCaller = itemInEdit?.rosterCandidateNoteId ? api.put(`rostercandidatenote/${itemInEdit?.rosterCandidateNoteId}`, JSON.stringify({...itemInEdit})) : api.post(`rostercandidatenote`, JSON.stringify({...itemInEdit}));
        apiCaller
            .then((response) => {
                // console.log({response});
                loadData();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [itemInEdit]);

    const handleDelete = useCallback((id) => {
        setLoading(true);
        api.delete(`rostercandidatenote/${id}`)
            .then((response) => {
                // console.log({response});
                setDeleteParam(null);
                loadData();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, []);

    const columns = useMemo(() => {
        return [
            {
                Header: `Talent Name`,
                id: "candidateName",
                accessor: (row) => row.candidate?.candidateName ?? "-",
                filter: "text",
            },
            {
                Header: `Created Date`,
                id: "createdDate",
                accessor: (row) => moment(row.createdDate).format("DD MMM YYYY HH:mm"),
                disableFilters: true,
            },
            {
                Header: `Created By`,
                accessor: "createdBy",
                disableFilters: true,
            },
            {
                Header: "Notes",
                accessor: "note",
                disableFilters: true,
            },
            {
                Header: "Actions",
                id: 'button',
                Cell: ( { row: { original } } ) =>
                    (
                        <div>
                            <TooltipButton
                                id={`edit_${original.rosterCandidateNoteId}`}
                                title="Edit"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={() => setItemInEdit(original)}
                            >
                                <i className="fas fa-pencil-alt pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`delete_${original.rosterCandidateNoteId}`}
                                title="Delete"
                                className="btn-icon"
                                color="warning"
                                size="sm"
                                type="button"
                                onClick={() => setDeleteParam(original.rosterCandidateNoteId)}
                            >
                                <i className="fas fa-trash-alt pt-1"></i>
                            </TooltipButton>
                        </div>
                    ),
                width: 60,
            },
        ];
    }, []);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
    }), []);

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
                })
            },
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data: history,
            initialState: {
                canSort: false,
                hiddenColumns: ['candidateName']
            },
            filterTypes,
            defaultColumn,
            manualSortBy: false,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
        useExpanded
    );

    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
            centered
        >
            {(loading) && <Loader />}
            <div
                className="modal-header my-0"
            >
                <Container fluid>
                    <Row>
                        <Col lg={9}>
                            <h5
                                className="modal-title"
                            >
                                Roster Notes Slot #{data?.slot} ({itemInEdit?.rosterCandidateNoteId ? "Edit" : "New"})
                            </h5>
                        </Col>
                        <Col lg={3}>
                            <button
                                aria-label="Close"
                                className="close"
                                onClick={() => onClose()}
                                type="button"
                            >
                                <span aria-hidden={true}>
                                    <i className="fas fa-times-circle text-black" />
                                </span>
                            </button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="modal-body">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Notes <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    name={`note`}
                                    id={`note_${itemInEdit?.rosterCandidateId}`}
                                    type="textarea"
                                    bsSize="sm"
                                    required
                                    value={itemInEdit?.note ?? ""}
                                    onChange={({ target: { value } }) => {
                                        setItemInEdit({
                                            ...itemInEdit,
                                            note: value,
                                        });
                                    }}
                                    rows={5}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} className="d-flex flex-wrap justify-content-end align-items-center">
                            <Button
                                color="default"
                                onClick={() => handleSave()}
                                type="button"
                                disabled={!itemInEdit?.note || itemInEdit?.note.trim() === ""}
                            >
                            {
                                itemInEdit?.rosterCandidateNoteId ? "Update" : "Save"
                            }
                            </Button>
                        {
                            itemInEdit?.rosterCandidateNoteId ?
                            <Button
                                color="warning"
                                onClick={() => setItemInEdit(initItem)}
                                type="button"
                            >
                                Cancel
                            </Button>
                            :
                            null
                        }
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs={12}>
                            <Grid
                                totalRecords={history?.length}
                                pageSize={history?.length}
                                rows={rows}
                                tableProps={getTableProps()}
                                headerGroups={headerGroups}
                                tableBodyProps={getTableBodyProps()}
                                prepareRow={prepareRow}
                                needPaging={false}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={() => onClose()}
                    type="button"
                >
                    Close
                </Button>
            </div>
        {
            deleteParam ?
            <ConfirmDeleteAlert
                onConfirm={() => handleDelete(deleteParam)}
                onCancel={() => setDeleteParam(null)}
            />
            :
            null
        }
        </Modal>
    );
};
