/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Loader from '../../../loaders';
import api from "../../../../services/api";
import { Button, Col, Container, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import { useFlexLayout, useRowSelect, useTable, useFilters, useSortBy } from 'react-table';
import { IndeterminateCheckbox } from '../../../react-table/controls';
import { DefaultColumnFilter, DateColumnFilter } from '../../../react-table/filters';
import { TooltipButton } from '../../../inputs';
import moment from "moment";
import Grid from "../../../Grid";
import { ComboBox, DropdownBox } from "../../../dropdowns";
import SweetAlert from 'react-bootstrap-sweetalert';


export default function UserGroupMemberList(props) {
    const {
        userGroupId,
        setSelectedUserGroupId
    } = props;

    const skipPageResetRef = React.useRef();

    const [loading, setLoading] = useState(false);
    const [userGroup, setUserGroup] = useState(null);
    const [data, setData] = useState([]);
    const [userGroupMemberName, setUserGroupMemberName] = useState(null);
    const [userGroupMembers, setUserGroupMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const loadData = useCallback(() => {
        setUserGroup(null);
        setData([]);

        if (!userGroupId)
            return;

        setLoading(true);

        const apiCalls = [
            api.get(`/usergroup/${userGroupId}`),
            api.get(`/usergroup/search-members?id=${userGroupId}`)
        ]

        Promise.all(apiCalls)
            .then((responses) => {
                console.log(responses);
                setUserGroup(responses[0]?.data);
                setData(responses[1]?.data ?? []);
            })
            .catch(error => console.error(error))
            .finally(() => setLoading(false))
            ;
    }, [userGroupId]);

    useEffect(() => {
        loadData();
    }, [userGroupId]);



    const defaultSort = useMemo(() => [
        { id: "email", desc: false },
        //{ id: "displayName", desc: false },
        //{ id: "jobTitle", desc: false }
    ]);

    const [sortBy, setSortBy] = useState(defaultSort);

    const loadUserGroupMemberData = useCallback(() => {
        setUserGroupMembers([]);

        if (!userGroupMemberName || userGroupMemberName?.trim() === "")
            return;

        setLoading(true);
        api.get(`/lookup/userGroupMember?filter=${userGroupMemberName}`)
            .then((response) => {
                const loadedUserGroupMembers = response.data?.filter(p => data.filter(sp => sp.lookupId === p.lookupId).length === 0);
                console.log(loadedUserGroupMembers);
                setUserGroupMembers(loadedUserGroupMembers);
            })
            .catch(error => console.error(error))
            .finally(() => setLoading(false))
            ;
    }, [data, userGroupMemberName]);

    useEffect(() => {
        loadUserGroupMemberData();
    }, [userGroupMemberName]);

    //useEffect(() => {
    //    console.log('selectedMember', selectedMember);

    //    // Auto return if selectedMember is null
    //    if (selectedMember == null) return;

    //    // Insert selectedMember to data
    //    var newIndex = data.length + 1;
    //    setData([...data, { ...selectedMember, index: newIndex }]);
    //    setSelectedMember(null);


    //}, [selectedMember]);

    // Validate 
    const validate = (newItem) => {

        // Validate if member is already in the list by userProfile.email
        //data.find((item, index) => data.findIndex(f => f.userProfile?.email == item.userProfile?.email) !== index);
        var existingItem = data.find(f => f.userProfile?.email && newItem.userProfile?.email && f.userProfile?.email.toLowerCase() == newItem.userProfile?.email.toLowerCase());
        if (existingItem) {
            setErrorMessage(`Selected member ${newItem.userProfile?.displayName} (${newItem.userProfile?.email}) is already in the list.`);
            return false;
        }
        return true;

    }

    // Add
    const handleAdd = useCallback(() => {
        console.log('selectedMember', selectedMember);

        // Auto return if selectedMember is null
        if (selectedMember == null) return;

        // Validate
        if (!validate({ ...selectedMember })) return;

        // Insert selectedMember to data
        var newIndex = data.length + 1;
        setData([...data, { ...selectedMember, index: newIndex }]);
        setSelectedMember(null);
    }, [selectedMember, data]);


    const handleSave = useCallback(() => {
        if (!data)
            return;

        setLoading(true);
        var postData = [...data];

        api.post(`/usergroupmember/save-list`, postData)
            .then(() => {
                setLoading(false);
                setSelectedUserGroupId(null);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            })
            ;
    }, [userGroupId, data]);

    // BEGIN DATA LIST --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 150,
            maxWidth: 200,
            Filter: DefaultColumnFilter,
            filter: "text",
            disableSortBy: true,
            disableFilters: true,
        }), []);


    const filterTypes = React.useMemo(() => ({
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
            })
        },

    }), []);


    const columns = React.useMemo(() => {
        return [
            {
                Header: "User",
                accessor: "userProfile.email",
                id: "email",
                flex: 1,
                Filter: DefaultColumnFilter,
                filter: "text",
                width: 100,
                disableSortBy: false,
                disableFilters: false,
            },
            {
                Header: "Name",
                accessor: "userProfile.displayName",
                id: "displayName",
                flex: 1,
                Filter: DefaultColumnFilter,
                filter: "text",
                width: 100,
                disableSortBy: false,
                disableFilters: false,
            },
            {
                Header: "Title",
                accessor: "userProfile.jobTitle",
                id: "jobTitle",
                flex: 2,
                Filter: DefaultColumnFilter,
                filter: "text",
                width: 100,
                disableSortBy: false,
                disableFilters: false,
            }, 
            {
                id: 'buttons',
                Header: "Action",
                width: 85,
                canResize: false,
                Cell: ({ row: { original, isGrouped } }) => (
                    (original && !isGrouped) ?
                        (
                            <div className="td-action text-left">
                                <TooltipButton
                                    id={`delete_${original.userGroupMemberId}`}
                                    title="Delete"
                                    className="btn-icon"
                                    color="warning"
                                    size="xsm"
                                    type="button"
                                    onClick={() => {

                                        // Find index of the row to be deleted
                                        var index;
                                        if (original.userGroupMemberId) {
                                            index = data.findIndex(f => f.userGroupMemberId === original.userGroupMemberId);
                                        } else if (original.userProfile?.email) {
                                            index = data.findIndex(f => f.userProfile?.email == original.userProfile?.email);
                                        }
                                        else {
                                            return;
                                        }

                                        // Return if index is not found
                                        if (index < 0)
                                            return;

                                        // Remove row from data
                                        const newData = [...data];
                                        newData.splice(index, 1);
                                        setData([...newData]);
                                    }}
                                >
                                    <i className="fas fa-trash-alt pt-1"></i>
                                </TooltipButton>
                            </div>
                        ) :
                        (
                            <div className="td td-action"></div>
                        )
                )
            },
        ]
    }, [data]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data: data,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
            initialState: {
                sortBy: defaultSort,
                canSort: true,
            },
            filterTypes,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
    );


    // END DATA LIST --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    return (
        <Modal
            isOpen={true}
            className="modal-xl"
            modalClassName="db-example-modal-lg"
        >
            {
                loading && <Loader />
            }
            {
                errorMessage &&
                <SweetAlert
                    title={`Manage User Group Member's of ${userGroup?.userGroupName}`}
                    error
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    onConfirm={() => setErrorMessage(null)}
                >
                    {
                        errorMessage
                    }
                </SweetAlert>
            }
            <div className="modal-header">
                <h5 className="modal-title">
                    Manage User Group Member's of {userGroup?.userGroupName}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={() => setSelectedUserGroupId(null)}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div className="modal-body">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div className="form-inline mb-4">
                                <FormGroup className="mr-2">
                                    <Label>
                                        Email
                                    </Label>
                                </FormGroup>
                                <ComboBox
                                    className="mr-2"
                                    endpoint="/lookup/users"
                                    isLookup={false}
                                    minLength={1}
                                    idField="email"
                                    valueField="nameAndEmail"
                                    selectedItem={selectedMember?.userProfile}
                                    onChange={(selectedItem) => {
                                        if (!selectedItem) {
                                            setSelectedMember(null);
                                            return;
                                        }

                                        setSelectedMember({
                                            ...selectedMember,
                                            userGroupId: userGroupId,
                                            userProfileId: selectedItem?.userProfileId ? selectedItem?.userProfileId : null,
                                            userProfile: { ...selectedItem }
                                        });
                                    }}
                                />
                                <Button
                                    color="danger"
                                    onClick={() => handleAdd()}
                                    type="button"
                                >
                                    Add
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>

                            <Grid
                                rows={rows}
                                tableProps={getTableProps()}
                                headerGroups={headerGroups}
                                tableBodyProps={getTableBodyProps()}
                                prepareRow={prepareRow}
                                height={'calc(42vh)'}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="modal-footer">
                <Button
                    color="secondary"
                    onClick={() => setSelectedUserGroupId(null)}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave()}
                    type="button"
                >
                    Save Changes
                </Button>
            </div>
        </Modal>
    );
};