import React from 'react';
import {
    Container, Row, Col, CardTitle, Button, Modal, FormGroup, Label, Input
 } from "reactstrap";
 import api from "../../../../services/api";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../../alerts";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { TooltipButton } from '../../../inputs';
import { DefaultColumnFilter } from "../../../react-table/filters";
import { useTable, useExpanded, useFilters, useSortBy, useFlexLayout } from 'react-table';
import Loader from "../../../loaders";
import Grid from "../../../Grid";
import { TrimObject } from '../../../../helpers/StringHelper';

function EmailTemplate(props) {

    const [ emailTemplateData, setEmailTemplateData ] = React.useState([]);    
    const [ emailTemplateLoading, setEmailTemplateLoading ] = React.useState(false);
    const [ emailTemplateInEdit, setEmailTemplateInEdit ] = React.useState(null);
    const [ pageNumber, setPageNumber ] = React.useState(1);
    const [ pageSize, setPageSize ] = React.useState(10);
    const [ totalRecords, setTotalRecords ] = React.useState(0);
    const [ editorState, setEditorState] = React.useState(EditorState.createEmpty());
    
    const [ deleteAlert, setDeleteAlert ] = React.useState([]);
    const skipPageResetRef = React.useRef();

    const loadData = () => {
        setEmailTemplateInEdit(null);
        setEmailTemplateData([]);
        skipPageResetRef.current = true;
        
        setEmailTemplateLoading(true);
        api.get(`/emailtemplate/${(pageNumber - 1) * pageSize}/${pageSize}`)
        .then(({data}) => {
            setEmailTemplateData(data.data);
            setTotalRecords(data.total);
        }).catch((error) => {
            console.error("error: ", error);
        }).finally(() => setEmailTemplateLoading(false));
    }

    const handleClick = (item) => {
        setEmailTemplateInEdit(item);
        
        const contentBlock = htmlToDraft(item.message);

        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));

        }
    }

    const onEditorStateChange = (item) => {
        setEditorState(item);
        setEmailTemplateInEdit({...emailTemplateInEdit, message: draftToHtml(convertToRaw(editorState.getCurrentContent()))});

        console.log("Editor State: ", editorState.getCurrentContent());
    }

    const handleDelete = (item) => {
        const success = () => {
            setDeleteAlert([<DeleteSuccessAlert onConfirm={() => setDeleteAlert([])} />]);
            if (emailTemplateData.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1);
            } else {
                loadData();
            }
        };
        const failure = () => setDeleteAlert([<DeleteFailedAlert onConfirm={() => setDeleteAlert([])} />]);
        const events = {
            onConfirm: () => api.delete(`/emailtemplate/${item.emailTemplateId}`).then(success).catch(failure),
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert {...events} />])
    }

    const handleSave = (item) => {
        item = TrimObject(item);

        setEmailTemplateLoading(true)
        setEmailTemplateInEdit(item);

        const data = JSON.stringify(item);
        const save = item.emailTemplateId > 0 
            ? api.put(`emailtemplate/${item.emailTemplateId}`, data)
            : api.post("emailtemplate", data)

        save
        .then((response) => {
            loadData();
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            setEmailTemplateLoading(false);
        });
        
        
    }

    const handleTextChanged = ({ target: { name, value } }) => {
        setEmailTemplateInEdit({ ...emailTemplateInEdit, [name]: value });
    }

    React.useEffect(() => {        
        loadData();
    }, [pageNumber, pageSize]);

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
                })
            },
        }),
        []
    )

    const columns = React.useMemo(() => {
        return [
            {
                Header: "Email Template",
                id: 'template',
                Cell: ( { row: { original } } ) =>
                    (
                        <div>
                            <strong>{original.title}</strong>
                            <br />
                            <strong>Subject: {original.subject}</strong>
                            <br />
                            <strong>Email Content: </strong> <br />
                            <div dangerouslySetInnerHTML={{ __html: original.message }}></div>
                        </div>
                    ),
                width: 430,
            },
            {
                Header: "Actions",
                id: 'button',
                Cell: ( { row: { original } } ) =>
                    (
                        <div>
                            <TooltipButton
                                id={`edit_${original.emailTemplateId}`}
                                title="Edit"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={() => handleClick(original)}
                            >
                                <i className="fas fa-pencil-alt pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`delete_${original.emailTemplateId}`}
                                title="Delete"
                                className="btn-icon"
                                color="warning"
                                size="sm"
                                type="button"
                                onClick={() => handleDelete(original)}
                            >
                                <i className="fas fa-trash-alt pt-1"></i>
                            </TooltipButton>
                        </div>
                    ),
                width: 140,
            },
        ]
    }, []);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,       
    } = useTable(
        {
            columns,
            data: emailTemplateData,
            filterTypes,
            defaultColumn,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
        useExpanded
    );

    return (
        <>
            {emailTemplateLoading && <Loader />}
            {deleteAlert.length > 0 && deleteAlert}
            <Container fluid>
                <Row>
                    <Col>
                        <CardTitle>
                            <h4 className="text-center">Email Template</h4>
                        </CardTitle>
                        <TooltipButton                        
                            id="addnew"
                            title="Add New"
                            className="btn-icon ml-2 mb-2"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => {  
                                setEmailTemplateInEdit({});
                            }}
                        >
                            <i className="fas fa-plus pt-1"></i>
                        </TooltipButton>
                        <Grid
                            height='calc(100vh - 460px)'
                            totalRecords={totalRecords}
                            pageSize={pageSize}
                            rows={rows}
                            tableProps={getTableProps()}
                            headerGroups={headerGroups}
                            tableBodyProps={getTableBodyProps()}
                            prepareRow={prepareRow}
                            onPagerChangePage={(pager) => {
                                setPageNumber(pager.currentPage);
                            }}
                            onPagerChangePageSize={(size) => {
                                setPageSize(size);
                            }}
                            needPaging={true}
                        />
                    </Col>
                </Row>
            </Container>
            {
            !!emailTemplateInEdit && 
            <Modal
                isOpen={true}
                className="modal-lg"
                modalClassName="db-example-modal-lg"
            >
                <div
                    className="modal-header"
                >
                    <h5
                        className="modal-title"
                    >
                        Email Template Editor
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        onClick={() => setEmailTemplateInEdit(null)}
                        type="button"
                    >
                        <span aria-hidden={true}>x</span>
                    </button>
                </div>
                <div
                    className="modal-body"
                >
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label>
                                        Title
                                    </Label>
                                    <Input 
                                        name="title"
                                        type="text"
                                        endpoint="/title"
                                        value={emailTemplateInEdit?.title}
                                        onChange={handleTextChanged}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label>
                                        Subject
                                    </Label>
                                    <Input
                                        name="subject"
                                        type="text"
                                        endpoint="/subject"
                                        value={emailTemplateInEdit?.subject}
                                        onChange={handleTextChanged}
                                    />
                                </FormGroup>
                            </Col>    
                            <Col xs={12}>
                                <FormGroup>
                                    <Label>
                                        Message
                                    </Label>
                                    <Editor
                                        // editorStyle={{ border: "1px solid #c0c0c0", padding: "0px 10px 0px 10px" }}
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorStateChange}
                                        />        
                                </FormGroup>
                            </Col> 
                        </Row>
                    </Container>
                </div>
                <div
                    className="modal-footer"
                >
                    <Button
                        color="secondary"
                        onClick={() => { 
                            setEmailTemplateInEdit(null);
                        }}
                        type="button"
                    >
                        Close
                    </Button>
                    <Button
                        color="info"
                        onClick={() => handleSave(emailTemplateInEdit)}
                        type="button"
                    >
                        Save Changes
                    </Button>
                </div>
            </Modal>
            }
        </>
    );
}

export default EmailTemplate;