import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from "react-router-dom";
import api from "../../../services/api";
import {
   Container, Row, Col, Card, CardBody, CardTitle
} from "reactstrap";
import Loader from "../../loaders";
import { HotTable, HotColumn } from "@handsontable/react";
import moment from "moment";
import Handsontable from 'handsontable';
import qs from "qs";

function CellRenderer(props) {
    // console.log("cell renderer", props.value);
    if (!props.value)
        return null;

    const {
        value, TD
    } = props;
    
    Handsontable.dom.addClass(TD, 'data-cell');
    
    return (
        <div className={`h-100 p-0 m-0 d-flex flex-wrap justify-content-center ${value.result !== null ? (value.result === 0 ? "pink" : "cyan") : "pink"}`}>
            {value.expiredDate ? moment(value.expiredDate, "YYYY-MM-DD").format("DD-MM-YYYY") : "-"}
            {/* <i className={`fas ${value === "N" ? "fa-check-circle text-success" : "fa-times-circle text-danger"}`}></i> */}
        </div>
    )
}

const tnaColWidth = 40;

export default function TNADiscrepancyReport(props) {
    const params = useParams();
    const location = useLocation();

    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState(null);
    const [ columnHeaders, setColumnHeaders ] = useState([]);
    const [ gridData, setGridData ] = useState([]);
    const [ gridColumns, setGridColumns ] = useState([]);
    const hotTableComponent = useRef(null);
    
    const loadData = React.useCallback(() => {
        const queryObj = qs.parse(location.search, { ignoreQueryPrefix: true });
        // console.log(queryObj);
        if (!queryObj || !queryObj.dispatchUnitId)
            return;

        const candidateIds = queryObj?.candidateIds ? decodeURI(queryObj.candidateIds).split("-") : [];

        setLoading(true);
        api.post(`rostercandidate/tnadiscrepancyreport?dispatchUnitId=${queryObj.dispatchUnitId}${queryObj.rosterId ? `&rosterId=${queryObj.rosterId}` : ""}`, JSON.stringify(candidateIds))
            .then((response) => {
                // console.log(response);
                setData(response.data);
            })
            .catch((error) => {
                console.error(error.response);
            })
            .finally(() => setLoading(false));
    }, [params, location]);

    useEffect(() => {
        // console.log({params, location});
        loadData();
    }, [params, location]);

    useEffect(() => {
        if (!data || data.rows?.length === 0)
            return;

        // console.log(data);
        const newColumnHeaders = ["Candidate Name", "Position Name"];
        const newGridColumns = [];

        setGridData(
            data.rows.map(x => {
                const columns = x.columns?.filter(y => y.skillTitle !== null).reduce((obj, item) => {
                    if (newGridColumns.filter(y => y === item.skillTitle)?.length === 0)
                    {
                        newGridColumns.push(item.skillTitle);
                        newColumnHeaders.push(item.skillTitle);
                    }
                    
                    return (obj[item.skillTitle] = {expiredDate: item.expiredDate, result: item.result}, obj);
                }, {});

                return {
                    candidateName: x.candidateName,
                    positionName: x.positionName,
                    ...columns
                }
            })
        );

        setColumnHeaders(newColumnHeaders);
        setGridColumns(newGridColumns);
    }, [data]);

    const grid = useMemo(() => {
        const longestChar = gridColumns.reduce((a, b) => a.length > b.length ? a : b, " ")
        // gridColumns.sort(
        //     function (a, b) {
        //         return b.length - a.length;
        //     }
        // )[0] ?? 14;

        return (<>
        {
            gridData?.length > 0 && columnHeaders?.length > 0 && longestChar &&
            <HotTable
                ref={hotTableComponent}
                className={'tna'}
                colHeaders={columnHeaders}
                data={gridData}
                rowHeaders={true}
                stretchH={"all"}
                height="auto"
                licenseKey="5c26d-2087e-1df71-d6238-dfc22"
                columnHeaderHeight ={[longestChar.length * 7]}
                modifyColWidth={(width, column) => {
                    if (parseInt(column) < 2) {
                        if (!hotTableComponent.current)
                            return 300;

                        const hot = hotTableComponent.current.hotInstance;
                        const customWidth = (hot.table.parentElement.parentElement.parentElement.clientWidth - ((gridColumns.length * (tnaColWidth)) - tnaColWidth)) / 2.5;
                        return customWidth < 300 ? 300 : customWidth;
                    }

                    return tnaColWidth;
                }}
                afterGetColHeader={(col, TH) => {
                    TH.col = col;
                    TH.addEventListener('dblclick', ({ currentTarget: { col } }) => { 
                        // console.log(`col: `, col); 
                    });
                    if (col > 1 && gridColumns.some(s => s === TH.textContent)) {
                        Handsontable.dom.addClass(
                            TH,
                            'rotate-header'
                        );
                    }
                }}
                afterGetRowHeader={(row, TH) => {
                    TH.row = row;
                    TH.addEventListener('dblclick', ({ currentTarget: { row } }) => { 
                        // console.log(`row: `, row); 
                    });
                }}
            >
                <HotColumn data={'candidateName'} readOnly={true} />
                <HotColumn data={'positionName'} readOnly={true} />
            {
                gridColumns.length > 0 && gridColumns.map(x => {
                    return (
                        <HotColumn key={x} data={x} readOnly={true} className="h-100 w-100 data-cell">
                            <CellRenderer hot-renderer />
                        </HotColumn>
                    );
                })
            }
            </HotTable>
        }
        </>);
    }, [gridData, columnHeaders, gridColumns, hotTableComponent]);

    return (
        <section className="main">
            {loading && <Loader />}
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card className="no-transition">
                            <CardBody>
                                <CardTitle>
                                    <h5 className="mb-0 text-center">
                                            TNA Discrepancy Report
                                    </h5>
                                    <h6 className="mb-0">
                                            Project: {data?.projectName}
                                    </h6>
                                </CardTitle>
                            </CardBody>
                            <CardBody className='pt-1'>
                                <Container fluid>                                    
                                    <Row>
                                        <Col xs={12}>                                            
                                        {
                                            grid
                                        }
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}