import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, FormGroup, Label, Modal, Row } from 'reactstrap';
import api from "../../../../services/api";
import { ComboBox } from '../../../dropdowns';
import Loader from '../../../loaders';
import PropTypes from "prop-types";

export default function ContractPositionDialog(props) {
    const {jobOrderCandidateId, onClose, onSubmit} = props;

    const [ loading, setLoading ] = useState(false);
    const [ jobOrderCandidate, setJobOrderCandidate ] = useState(null);
    const [ selectedPosition, setSelectedPosition ] = useState(null);

    const loadData = useCallback(() => {
        if (!jobOrderCandidateId)
            return;

        setLoading(true);
        api.get(`jobordercandidate/${jobOrderCandidateId}`)
            .then((response) => {
                setJobOrderCandidate({...response.data});
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [jobOrderCandidateId]);

    useEffect(() => {
        loadData();
    }, [jobOrderCandidateId]);

    useEffect(() => {
        if (!jobOrderCandidate)
            return;

        setSelectedPosition({
            positionId: jobOrderCandidate.position?.positionId,
            positionName: jobOrderCandidate.position?.positionName,
        });
    }, [jobOrderCandidate]);

    return (
        <Modal
            isOpen={true}
        >
            {(loading) && <Loader />}
            <div className="modal-header">
                <h5 className="modal-title">Generate Contract - Position Selection</h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div className="modal-body">
                <Container fluid className="px-0">
                    <Row>
                        <Col md={12} className="border-right border-black">
                            <FormGroup className="mb-3 pb-3 border-bottom-3">
                                <Label>
                                    Employed Position
                                </Label>
                                <ComboBox
                                    minLength={0}
                                    endpoint="/position/searchname"
                                    isLookup={false}
                                    idField="positionId"
                                    valueField="positionName"
                                    selectedItem={selectedPosition}
                                    onChange={(item) => {                                   
                                        setSelectedPosition(item);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="default"
                    onClick={() => onSubmit(selectedPosition)}
                    type="button"
                    disabled={!selectedPosition}
                >
                    Submit
                </Button>
            </div>
        </Modal>
    );
}

ContractPositionDialog.propTypes = {
    jobOrderCandidateId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}