import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";

function RadioGroup(props) {

    const {
        items,
        name,
        idField,
        valueField,
        handleChange,
        colWidth,
        inline,
        selected,
        hasNA,
    } = props;

    const wrapperClasses = classNames(
        { 'custom-control-inline': inline },
        { [`col-${colWidth}`]: inline && !!colWidth },
        'custom-control', 
        'custom-radio',
        { 'mr-0': inline && !!colWidth }
    )

    return (
        <div>
            {items.map((lookupItem, index) => {
                return (
                    <div 
                        className={wrapperClasses} 
                        key={index}
                    >
                        <input 
                            type="radio" 
                            className="custom-control-input" 
                            id={`${name}_${lookupItem[valueField]}`} 
                            name={name} 
                            value={lookupItem[idField]} 
                            checked={selected === lookupItem[idField]}
                            onChange={handleChange} 
                        />
                        <label 
                            className="custom-control-label" 
                            htmlFor={`${name}_${lookupItem[valueField]}`}
                        >
                            { lookupItem[valueField] }
                        </label>
                    </div>
                )
            })} 
            {hasNA && <div 
                        className={wrapperClasses} 
                        key={-1}
                    >
                        <input 
                            type="radio" 
                            className="custom-control-input" 
                            id={`${name}_NA`} 
                            name={name} 
                            value={undefined} 
                            checked={selected === null || selected === undefined}
                            onChange={handleChange} 
                        />
                        <label 
                            className="custom-control-label" 
                            htmlFor={`${name}_NA`}
                        >
                            NA
                        </label>
                    </div>}
        </div>
    )
}

RadioGroup.propTypes = {
    items: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    idField: PropTypes.string,
    valueField: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    colWidth: PropTypes.number,
    inline: PropTypes.bool,
    selected: PropTypes.any,
    hasNA: PropTypes.bool
}

RadioGroup.defaultProps = {
    idField: "lookupId",
    valueField: "lookupValue",
    colWidth: 2,
    inline: true,
    hasNA: false,
}

export default RadioGroup;