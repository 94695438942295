import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Container, Row, Col, FormGroup, Label, Button } from 'reactstrap';
//import { formatDate } from "../../../../utils";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { filterBy } from "@progress/kendo-data-query";
import useForecast from "../contexts/ForecastContext";
import moment from "moment";

import api from "../../../../services/api";
import { formatDate, ViewAccess } from '../../../../utils'

export default (props) => { 
    
    const {
        api: gridApi,
        columnApi,
        loadData,
        createRoster,
        viewAccess,
    } = props;

    const {
        setStartDate,
        setEndDate,
        setClient,
        setDepartment,
        setManager,
        setProject,
        setScope,
        setState,
        startDate,
        endDate,
        client,
        department,
        manager,
        project,
        scope,
        state,
    } = useForecast();

    const [ clients, setClients ] = useState([]);
    const [ allClients, setAllClients ] = useState([]);
    const [ clientsLoading, setClientsLoading ] = useState(false);

    const [ departments, setDepartments ] = useState([]);
    const [ allDepartments, setAllDepartments ] = useState([]);
    const [ departmentsLoading, setDepartmentsLoading ] = useState(false);

    const [ managers, setManagers ] = useState([]);
    const [ allManagers, setAllManagers ] = useState([]);
    const [ managersLoading, setManagerssLoading ] = useState(false);
    
    const [ projects, setProjects ] = useState([]);
    const [ allProjects, setAllProjects ] = useState([]);
    const [ projectsLoading, setProjectsLoading ] = useState(false);

    const [ scopes, setScopes ] = useState([]);
    const [ allScopes, setAllScopes ] = useState([]);
    const [ scopesLoading, setScopesLoading ] = useState(false);
    
    const [ states, setStates ] = useState([]);
    const [ allStates, setAllStates ] = useState([]);
    const [ statesLoading, setStatesLoading ] = useState(false);

    useEffect(() => {
        if (process.env.REACT_APP_HRIS_FORECAST_GRID_SETUP_STATE in localStorage) {
            const savedState = JSON.parse(localStorage.getItem(process.env.REACT_APP_HRIS_FORECAST_GRID_SETUP_STATE));
            setStartDate(new Date(savedState.startDate));
            setEndDate(new Date(savedState.endDate));
            setClient(savedState.client);
            setDepartment(savedState.department);
            setManager(savedState.manager);
            setProject(savedState.project);
            setScope(savedState.scope);
            setState(savedState.state);

            loadData({
                startDate: savedState.startDate,
                endDate: savedState.endDate,
                project: savedState.project?.lookupValue,
                department: savedState.department?.lookupValue,
                state: savedState.state?.lookupValue,
                client: savedState.client?.lookupValue,
                scope: savedState.scope?.lookupValue,
                manager: savedState.manager?.lookupValue,
            });
        }
    }, []);

    useEffect(() => {
        let apiCalls = [
            api.get(`/forecast/projects?client=${client?.lookupValue || ''}`),
            api.get(`/forecast/departments?client=${client?.lookupValue || ''}`),
            api.get(`/forecast/managers?client=${client?.lookupValue || ''}`),
        ];
        setProjectsLoading(true);
        setDepartmentsLoading(true);
        setManagerssLoading(true);
        if (!client || !client.lookupValue) {
            apiCalls.push(api.get(`/forecast/clients`));
            setClientsLoading(true);
        }

        Promise.all(apiCalls).then((response) => {
            
            setProjects([...response[0].data]);
            setAllProjects([...response[0].data]);

            setDepartments([...response[1].data]);
            setAllDepartments([...response[1].data]);

            setManagers([...response[2].data]);
            setAllManagers([...response[2].data]);

            if (!client || !client.lookupValue) {
                setClients([...response[3].data]);
                setAllClients([...response[3].data])
            }
        }).catch((err) => {
            //setErrorMessage(`${errorMessageOpening}${err.message}`)
        }).finally(() => {
            setProjectsLoading(false);
            setDepartmentsLoading(false);
            setManagerssLoading(false);
            setClientsLoading(false);
        });


    }, [client]);

    React.useEffect(() => {
        if (!project || !project.lookupValue) {
            setScopes([]);
            setAllScopes([]);
            setScope(null);
            return;
        }
        setScopesLoading(true);
        api.get(`/forecast/scopes?client=${client?.lookupValue || ''}&siteProjectName=${project?.lookupValue}`)
        .then((response) => {
            setScopes([...response.data]);
            setAllScopes([...response.data]);
        }).catch((err) => {
            //setErrorMessage(`${errorMessageOpening}${err.message}`)
        }).finally(() => {
            setScopesLoading(false);
        });
    }, [project, client])

    React.useEffect(() => {
        if (!project || !project.lookupValue) {
            setStates([]);
            setAllStates([]);
            setState(null);
            return;
        }
        setStatesLoading(true);
        api.get(`/forecast/states?client=${client?.lookupValue || ''}&siteProjectName=${project?.lookupValue || ''}&scopeRef=${scope?.lookupValue || ''}`)
        .then((response) => {
            setStates([...response.data]);
            setAllStates([...response.data]);
        }).catch((err) => {
            //setErrorMessage(`${errorMessageOpening}${err.message}`)
        }).finally(() => {
            setStatesLoading(false);
        });
    }, [project, client, scope])
    
    const handleFilterClick = useCallback(() => {
        localStorage.setItem(process.env.REACT_APP_HRIS_FORECAST_GRID_SETUP_STATE, JSON.stringify({
            client,
            department,
            manager,
            project,
            scope,
            state,
            startDate: formatDate(startDate, 'YYYY-MM-DD'),
            endDate: formatDate(endDate, 'YYYY-MM-DD'),
        }));
        loadData({
            startDate: formatDate(startDate, 'YYYY-MM-DD'),
            endDate: formatDate(endDate, 'YYYY-MM-DD'),
            project: project?.lookupValue,
            department: department?.lookupValue,
            state: state?.lookupValue,
            client: client?.lookupValue,
            scope: scope?.lookupValue,
            manager: manager?.lookupValue,
        });
    }, [client, department, manager, project, scope, state, startDate, endDate]);

    const filterData = (filter, data) => {
        const copy = [...data];
        return filterBy(copy, filter);
    };

    const onClientFilterChange = useCallback((event) => {
        const data = filterData(event.filter, allClients);
        setClients(data);
    }, [allClients]);
    
    const onDepartmentFilterChange = useCallback((event) => {
        const data = filterData(event.filter, allDepartments);
        setDepartments(data);
    }, [allDepartments]);

    const onManagerFilterChange = useCallback((event) => {
        const data = filterData(event.filter, allManagers);
        setManagers(data);
    }, [allManagers]);

    const onProjectFilterChange = useCallback((event) => {
        const data = filterData(event.filter, allProjects);
        setProjects(data);
    }, [allProjects]);

    const onScopeFilterChange = useCallback((event) => {
        const data = filterData(event.filter, allScopes);
        setScopes(data);
    }, [allScopes]);

    const onStateFilterChange = useCallback((event) => {
        const data = filterData(event.filter, allStates);
        setStates(data);
    }, [allStates]);


    return (
        <Container fluid className='d-flex flex-column justify-content-between h-100'>
            <Row>
                <Col xs={12} className="pt-3">
                    <h5>Forecast Filter</h5>
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <Label>
                            Forecast Start Date
                        </Label>
                        <DatePicker
                            name="startDate"
                            id="startDate"
                            required
                            value={startDate}
                            onChange={({value}) => {
                                setStartDate(value);
                            }}
                            format={"dd/MM/yyyy"}
                            className='w-100'
                        />
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <Label>
                            Forecast End Date
                        </Label>
                        <DatePicker
                            name="endDate"
                            id="endDate"
                            required
                            value={endDate}
                            onChange={({value}) => {
                                setEndDate(value);
                            }}
                            format={"dd/MM/yyyy"}
                            className='w-100'
                        />
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <Label>
                            Client
                        </Label>
                        <ComboBox
                            data={clients}
                            value={client}
                            onChange={({ value }) => setClient(value)}
                            dataItemKey="lookupValue"
                            textField="lookupValue"
                            filterable={true}
                            onFilterChange={onClientFilterChange}
                            placeholder="Select Client..."
                            loading={clientsLoading}
                            rounded="small"
                            size="small"
                            className='w-100'
                        />
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <Label>
                            Department
                        </Label>
                        <ComboBox
                            data={departments}
                            value={department}
                            onChange={({ value }) => setDepartment(value)}
                            dataItemKey="lookupValue"
                            textField="lookupValue"
                            filterable={true}
                            onFilterChange={onDepartmentFilterChange}
                            placeholder="Select Department..."
                            loading={departmentsLoading}
                            rounded="small"
                            size="small"
                            className='w-100'
                        />
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <Label>
                            Manager
                        </Label>
                        <ComboBox
                            data={managers}
                            value={manager}
                            onChange={({ value }) => setManager(value)}
                            dataItemKey="lookupValue"
                            textField="lookupValue"
                            filterable={true}
                            onFilterChange={onManagerFilterChange}
                            placeholder="Select Manager..."
                            loading={managersLoading}
                            rounded="small"
                            size="small"
                            className='w-100'
                        />
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <Label>
                            Site/Project
                        </Label>
                        <ComboBox
                            data={projects}
                            value={project}
                            onChange={({ value }) => setProject(value)}
                            dataItemKey="lookupValue"
                            textField="lookupValue"
                            filterable={true}
                            onFilterChange={onProjectFilterChange}
                            placeholder="Select Project..."
                            loading={projectsLoading}
                            rounded="small"
                            size="small"
                            className='w-100'
                        />
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <Label>
                            Scopes Ref
                        </Label>
                        <ComboBox
                            data={scopes}
                            value={scope}
                            onChange={({ value }) => setScope(value)}
                            dataItemKey="lookupValue"
                            textField="lookupValue"
                            filterable={true}
                            onFilterChange={onScopeFilterChange}
                            placeholder={!project || !project.lookupValue ? "Select Project to enable" : "Select Scope..."}
                            loading={scopesLoading}
                            rounded="small"
                            size="small"
                            className='w-100'
                            disabled={!project || !project.lookupValue}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <Label>
                            State
                        </Label>
                        <ComboBox
                            data={states}
                            value={state}
                            onChange={({ value }) => setState(value)}
                            dataItemKey="lookupValue"
                            textField="lookupValue"
                            filterable={true}
                            onFilterChange={onStateFilterChange}
                            placeholder={!project || !project.lookupValue ? "Select Project to enable" : "Select State..."}
                            loading={statesLoading}
                            rounded="small"
                            size="small"
                            className='w-100'
                            disabled={!project || !project.lookupValue}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <Button
                        color="secondary"
                        onClick={handleFilterClick}
                        type="button"
                        className="w-100"
                    >
                        Search Forecasts
                    </Button>
                </Col>
                { viewAccess == ViewAccess.FULLACCESS && 
                    <Col xs={12} className="pt-3">
                        <Button
                            color="secondary"
                            onClick={() => {
                                createRoster(gridApi);
                            }}
                            type="button"
                            className="w-100"
                        >
                            Create Roster
                        </Button>
                    </Col>
                }
            </Row>
        </Container>
    )
}