import React from 'react';

export default function Checkbox(props) {

    const {
        wrapperClasses,
        index,
        text,
        className,
        ...rest
    } = props;

    return (
        <div
            className={wrapperClasses}
            key={index}
        >
            <input
                type="checkbox"
                className={`custom-control-input${className ? ` ${className}` : ""}`}
                {...rest} />
            <label
                className={`custom-control-label${className ? ` ${className}` : ""}`}
                htmlFor={rest.id}
            >
                {text}
            </label>
        </div>
    );
}
