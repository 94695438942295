import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";

export default function PleaseCompleteAlert(props) {

    const {
        onConfirm,
        fields
    } = props;

    return (
        <SweetAlert
            title={<h4 className="mb-0">Please complete the following fields to continue.</h4>}
            onConfirm={onConfirm}
            type="danger"
        >
            <div className="mb-2">
                {fields.map((text, i) => <p key={i} className="mb-0">{text}</p>)}
            </div>
        </SweetAlert>
    );
}
