import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useFlexLayout, useRowSelect, useTable } from 'react-table';
import { Button, Col, Container, Modal, Row } from 'reactstrap';
import api from "../../../services/api";
import Grid from '../../Grid';
import Loader from '../../loaders';
import { IndeterminateCheckbox } from '../../react-table/controls';
import { DefaultColumnFilter } from '../../react-table/filters';

export default function LabourRequestDetailPositionEditor(props) {
    const {
        rosterId,
        hrispositionMasterId,
        request,
        requestDetail,
        onClose,
        onSaved
    } = props;

    const [ loading, setLoading ] = useState(false);
    const [ matrixCols, setMatrixCols ] = useState([]);
    const [ matrixRows, setMatrixRows ] = useState([]);
    const [ initialSelectedRows, setInitialSelectedRows ] = useState([]);

    const skipPageResetRef = React.useRef();

    const loadData = useCallback(() => {
        if (!rosterId || !hrispositionMasterId)
            return;

        setLoading(true);
        api.get(`recruitmentrequest/rosterpositions/${rosterId}/${hrispositionMasterId}/true`)
            .then((response) => {
                setLoading(false);
                setMatrixCols(response.data.columns.filter(c => c.title !== "First Name" && c.title !== "Surname").map(c => ({...c, isSelected: false, title: c.title !== "Roster Position" ? moment(c.title, "DD-MM-YYYY").format("DD-MMM") : c.title})));
                const rows = response.data.rows.map(r => ({...r, hasCandidate: r.candidateId ? true : false, columns: r.columns.map(rc => ({...rc, date: moment(rc.date, "DD-MM-YYYY").format("DD-MMM")}))}));

                if (requestDetail?.selectedRosterPositions?.length)
                {
                    console.log({requestDetail});
                    requestDetail.selectedRosterPositions.forEach(s => {
                        const existingItems = rows.filter(r => r.rosterCandidateId === s.rosterCandidateId);
                        console.log({existingItems, s});
                        
                        if (existingItems.length && (!existingItems[0].requestDetailId || existingItems[0].requestDetailId !== requestDetail.requestDetailId))
                        {
                            const itemIndex = rows.indexOf(existingItems[0]);
                            existingItems[0].requestDetailId = requestDetail.requestDetailId;
                            console.log(itemIndex, {...existingItems[0]}, rows[itemIndex]);
                            rows[itemIndex] = {...existingItems[0]};
                        }
                    });
                    console.log(rows);
                }

                const otherDetails = [...request.requestDetails.filter(r => r.requestDetailId !== requestDetail.requestDetailId)];

                if (otherDetails.length)
                {
                    otherDetails.forEach(d => {
                        console.log(d);
                        d.selectedRosterPositions.forEach(s => {
                            const existingItems = rows.filter(r => r.rosterCandidateId === s.rosterCandidateId);

                            if (existingItems.length && existingItems[0].requestDetailId !== d.requestDetailId)
                            {
                                const itemIndex = rows.indexOf(existingItems[0]);
                                existingItems[0].requestDetailId = d.requestDetailId;
                                rows.splice(itemIndex, 1, existingItems[0]);
                            }
                        });
                    });
                }

                const allDetails = [...request.requestDetails];

                console.log(allDetails);

                rows.forEach(r => {
                    console.log(r);
                    if (r.requestDetailId !== null)
                    {
                        if (!allDetails.filter(d => d.requestDetailId === r.requestDetailId).length)
                            return;
                        
                        let found = true;

                        allDetails.filter(d => d.requestDetailId === r.requestDetailId).forEach(d => {
                            console.log(d, d.selectedRosterPositions);
                            if (!found)
                                return;
                            
                            found = d.selectedRosterPositions && d.selectedRosterPositions.filter(s => s.rosterCandidateId === r.rosterCandidateId && s.requestDetailId === r.requestDetailId).length > 0;
                        });

                        console.log(found);
                        if (!found)
                            r.requestDetailId = null;
                        
                        return;
                    }

                    // allDetails.forEach(d => {
                    //     if (!d.selectedRosterPositions.filter(s => s.rosterCandidateId === r.rosterCandidateId).length)
                    //         return;
                        
                    //     r.requestDetailId = d.requestDetailId;
                    // })
                });

                console.log(rows);
                setMatrixRows(rows);
            })
            .catch((error) => {
                setLoading(false);
                console.log({error});
            })
        ;
    }, [rosterId, hrispositionMasterId, requestDetail]);

    useEffect(() => {
        if (!rosterId || !hrispositionMasterId)
            return;

        loadData();
    }, [rosterId, hrispositionMasterId]);

    useEffect(() => {
        if (!matrixRows.length)
            return;
        
        const arraySelected = [];
        matrixRows.forEach((item, index) => {
            if (!item.requestDetailId)
                return;
            
            arraySelected.push({id: index, ...item});
        });

        const selected = {};

        arraySelected.forEach((item) => {
            selected[`${item.id}`] = true;
        })

        setInitialSelectedRows(selected);
    }, [matrixRows, requestDetail]);

    const filterTypes = React.useMemo(() => ({
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
            const rowValue = row.values[id]
            return rowValue !== undefined
                ? String(rowValue).replace(/[/-]/g, "")
                    .toLowerCase()
                    .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                : true
            })
        },
    }), []);

    const columns = useMemo(() => {
        const result = [];

        result.push({
            id: 'selection',
            width: 30,
            disableSortBy: true,
            Header: () => (
                <div>
                </div>
            ),
            Cell: ({row}) => {
                const {original} = row;
                
                return (
                    <div>
                    {
                        original != null &&
                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} id={original.rosterCandidateId} disabled={original.requestDetailId && original.requestDetailId !== requestDetail.requestDetailId} />
                    }
                    </div>
                )
            },
        });

        matrixCols.forEach(c => {
            result.push({
                Header: c.title,
                accessor: (row) => {
                    if (c.source !== "date")
                        return row[_.camelCase(c.source)];
                    
                    const selectedDate = row.columns.filter(col => col.date === c.title)[0];
                    return selectedDate?.rosterScheduleTypeCodes;
                },
                id: c.title,
                width: c.title === "Roster Position" ? 200 : 50,
                disableFilters: true,
                disableSortBy: true,
            });
        })
        
        return [...result];
    }, [matrixCols, requestDetail]);

    const defaultColumn = useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        selectedFlatRows,
        toggleAllRowsSelected
    } = useTable(
        {
            columns,
            data: matrixRows,
            initialState: {
                selectedRowIds: initialSelectedRows
            },
            filterTypes,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout,
        useRowSelect
    );

    useEffect(() => {
        console.log(selectedFlatRows);
    }, [selectedFlatRows]);

    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
        {
            loading &&
            <Loader />
        }
            <div
                className="modal-header my-0"
            >
                <Container fluid>
                    <Row>
                        <Col lg={9}>
                            <h5
                                className="modal-title"
                            >
                                Roster Empty Positions
                            </h5>
                        </Col>
                        <Col lg={3}>
                            <button
                                aria-label="Close"
                                className="close"
                                onClick={onClose}
                                type="button"
                            >
                                <span aria-hidden={true}>
                                    <i className="fas fa-times-circle text-black" />
                                </span>
                            </button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-body"
            >
                <Grid
                    rows={rows}
                    tableProps={getTableProps()}
                    headerGroups={headerGroups}
                    tableBodyProps={getTableBodyProps()}
                    prepareRow={prepareRow}
                    height='calc(100vh - 436px)'
                />
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={() => onClose()}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => onSaved(selectedFlatRows.map(s => ({...s.original})))}
                    type="button"
                    disabled={!selectedFlatRows || !selectedFlatRows.filter(d => !d.original.requestDetailId || d.original.requestDetailId === requestDetail.requestDetailId).length}
                >
                    Select
                </Button>
            </div>
        </Modal>
    )
}
