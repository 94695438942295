import React, { useEffect, useState } from 'react';
import {
    Label, FormGroup, Button, Modal, Container, Row, Col, Alert
 } from "reactstrap"; 
import { ComboBox, } from "../../../dropdowns";
import _ from "lodash";
import Loader from "../../../loaders";
import api from "../../../../services/api";
import { useDropzone } from 'react-dropzone';
import cn from "classnames";
import fileDownload from "js-file-download";
import { RadioGroup, TooltipButton } from '../../../inputs';
import { useMemo } from 'react';
import { EditorState, ContentState, convertToRaw, SelectionState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor as Editor2 } from 'react-draft-wysiwyg';
import SweetAlert from 'react-bootstrap-sweetalert';
import { ShortenTextColumn } from '../../../../helpers';
import { TrimObject } from '../../../../helpers/StringHelper';

export default function Editor(props) {
    //const { token } = useMsal();
    const { defaultPositionFilter, defaultEmploymentCategory } = props;

    const [ itemInEdit, setItemInEdit ] = React.useState(props.item);
    const [ loading, setLoading ] = React.useState(false);
    const [ validationAlert, setValidationAlert ] = React.useState([]);
    const [ employmentCategories, setEmploymentCategories ] = React.useState([]);
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const [validationMessage, setValidationMessage] = useState(null);

    const [firstLoadEditor, setFirstLoadEditor] = React.useState(true);

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setItemInEdit({ ...itemInEdit, description: draftToHtml(convertToRaw(editorState.getCurrentContent())) });
        //setSelectedEmail({ ...selectedEmail, lookupValue: draftToHtml(convertToRaw(editorState.getCurrentContent())) });
    }

    // Auto load vacancy description editor content
    useEffect(() => {
        if (!firstLoadEditor || itemInEdit?.description == null) {
            return;
        }

        // Use a regular expression to match and remove the font-size and font-family styles
        var newDescription = itemInEdit?.description;
        newDescription = newDescription.replace(/font-size: [^;]+;/g, '');
        newDescription = newDescription.replace(/font-family: [^;]+;/g, '');


        const contentBlock = htmlToDraft(newDescription);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const contentEditorState = EditorState.createWithContent(contentState);

            setEditorState(contentEditorState);
            setFirstLoadEditor(false);
        }
    }, [firstLoadEditor, itemInEdit])

    useEffect(() => {
        setLoading(true);
        api.get(`/lookup/employmentcategory`)
            .then((response) => {
                setLoading(false);
                setEmploymentCategories(response.data);
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    }, []);

    useEffect(() => {
        if (!defaultPositionFilter)
            return;

        api.get(`/position/search?filter=${defaultPositionFilter}`)
            .then(response => {
                const currentPosition = {...response.data[0]};
                setItemInEdit({
                    position: {...currentPosition},
                    positionId: currentPosition.positionId,
                });
            })
            .catch(error => console.log({error}))
        ;
    }, [defaultPositionFilter])
    
    const validate = React.useCallback(() => {
        let errors = [...validationAlert];


        // If employment category is not selected, validate the employment category
        const employmentCategoryIdExists = _.some(errors, (item) => item.key === "employmentCategoryId" && item.type === "required");
        if (!itemInEdit.employmentCategoryId && !employmentCategoryIdExists) {
            errors.push({ key: "employmentCategoryId", type: "required", message: "Employment Category is required" });
        }
        else if (!!itemInEdit.employmentCategoryId && !!employmentCategoryIdExists) {
            _.remove(errors, (item) => item.key === "employmentCategoryId" && item.type === "required");
        }

        const positionIdExists = _.some(errors, (item) => item.key === "positionId" && item.type === "required");

        console.log("validating: ", { positionId: itemInEdit.positionId, errors }, !itemInEdit.positionId, !positionIdExists)
        if (!itemInEdit.positionId && !positionIdExists)
        {
            errors.push( { key: "positionId", type: "required", message:  "Position is required" } )
        } else if (!!itemInEdit.positionId && !!positionIdExists)
        {
            _.remove(errors, (item) => item.key === "positionId" && item.type === "required");
        }

        const fileExists = _.some(errors, (item) => item.key === "file" && item.type === "required");

        console.log("validating: ", { itemInEdit, errors }, !!itemInEdit.file, !!itemInEdit.filename)
        const hasFile = !!itemInEdit.file || (!itemInEdit.file && !!itemInEdit.filename);
        if (!hasFile && !fileExists)
        {
            errors.push( { key: "file", type: "required", message:  "A File is required" } )
        } else if (hasFile && fileExists)
        {
            _.remove(errors, (item) => item.key === "file" && item.type === "required");
        }
        // _.forEach(termsValidation, (value) => {
        //     _.forEach(value.tests, (test) => {
        //         const exists = _.some(errors, (item) => item.key === value.field && item.type === test.type);
        //         const result = test.validate(itemInEdit);
        //         if (result && !exists) {
        //             errors.push( { key: value.field, type: test.type, message: test.message } )
        //         } else if (!result && exists) {
        //             _.remove(errors, (item) =>  item.key === value.field && item.type === test.type);
        //         }
        //     })
        // });
        setValidationAlert([ ...errors ]);
        if (errors.length > 0) {
            return false;
        }
        return true;
    }, [itemInEdit, validationAlert]);

    const downloadAttachment = (fileInfo) => {

        if (fileInfo instanceof File) {
            fileDownload(fileInfo, fileInfo.name);
        } else {
            setLoading(true);
            let url = `jobdescription/download/${fileInfo.jobDescriptionId}`;
            api.get(url, { responseType: 'blob' })
            .then(blob => {
                fileDownload(blob.data, fileInfo.filename);
            }).catch(error => {
                console.error(error)
            }).finally(() => setLoading(false))
        }
    }

    const {
        getRootProps, 
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone(
        {
            maxFiles: 1,
            multiple: false,
            onDrop: ([file]) => {
                // console.log(file)
                setItemInEdit( { ...itemInEdit, file } );
            }
        });


    const files = React.useMemo(() => {
        let name, size, file;

        if (itemInEdit?.file) {
            name = itemInEdit.file.name;
            size = itemInEdit.file.size;
            file = itemInEdit.file;
        } else if (itemInEdit?.filename) {
            name = itemInEdit.filename;
            size = itemInEdit.fileSizeInBytes;
            file = itemInEdit;
        } else {
            return null;
        }
        return <aside className="dropzone-list pt-3">
            <Label>Files</Label>
            <ul>
                <li key={name} className="pt-1">
                    <a onClick={() => downloadAttachment(file)}>{name} - {size} bytes</a>
                </li> 
            </ul>
        </aside>
    }, [ itemInEdit, itemInEdit?.file ]);

    const dropzoneClasses = React.useMemo(() => cn({
        'dropzone': true,
        'mt-2': true,
        'active': isDragActive,
        'accept': isDragAccept,
        'reject': isDragReject
    }), [
        isDragActive,
        isDragAccept,
        isDragReject
    ]);

    React.useEffect(() => {
        if (itemInEdit && validationAlert.length > 0) validate();
    }, [itemInEdit]);

    //const selectedEmploymentCategory = useMemo(() => {
    //    console.log('selectedEmploymentCategory');
    //    console.log('itemInEdit?.employmentCategoryId', itemInEdit?.employmentCategoryId);
    //    console.log('defaultEmploymentCategory', defaultEmploymentCategory);
    //    var selectedId = itemInEdit?.employmentCategoryId ? itemInEdit.employmentCategoryId : (defaultEmploymentCategory ? defaultEmploymentCategory : null);
    //    selectedId = selectedId == null ? null : parseInt(selectedId);
    //    return selectedId;
    //}, [itemInEdit?.employmentCategoryId, defaultEmploymentCategory, employmentCategories]);

    useEffect(() => {
        console.log('employmentCategories', employmentCategories);
    }, [employmentCategories]);

    const handleSave = (item) => {

        // Trim every string property
        item = TrimObject(item);
        setItemInEdit(item);


        // Validate
        if (!validate()) {
            return;
        }
        setLoading(true);

        let url = `${process.env.REACT_APP_WEB_API_BASE_URI}/jobdescription`;
        let method = "POST";

        var formData = new FormData();

        formData.append("positionId", item.positionId);
        formData.append("employmentCategoryId", item.employmentCategoryId);
        formData.append("jobDescriptionId", item.jobDescriptionId ?? 0);
        formData.append("description", item.description == null ? null : item.description.trim());

        if (item.file) {
            formData.append("file", item.file);
        }

        api({ url, method, data: formData }).then((response) => {
            console.log("upload: ", response);
            setLoading(false);
            props.onSaved();
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
        });
    }

    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    {itemInEdit?.jobDescriptionId ? "Edit" : "Add"} JD
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}>x</span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                {validationAlert && validationAlert.length > 0 && <Alert color="warning" className="pa-0 d-flex justify-content-between">
                    <span className="alert-text">
                        <ul className="mb-0">
                            {
                                validationAlert.map((item, index) => <li key={index}>{item.message}</li>)
                            }
                        </ul>
                    </span>
                </Alert>}
                <Container>
                    {(loading) && <Loader />}
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Employment Category
                                    <span className="text-danger">*</span>
                                </Label>
                                <RadioGroup
                                    items={employmentCategories}
                                    name="employmentCategoryId"
                                    idField="lookupId"
                                    valueField="lookupLabel"
                                    handleChange={({ target: { value } }) => {
                                        console.log(value);
                                        const employmentCategoryId = parseInt(value);
                                        const type = _.find(employmentCategories, (item) => item.lookupId === employmentCategoryId);
                                        setItemInEdit( { ...itemInEdit, employmentCategory: type, employmentCategoryId } );
                                    }}
                                    selected={itemInEdit?.employmentCategoryId ? itemInEdit.employmentCategoryId : (defaultEmploymentCategory ? defaultEmploymentCategory : null)}
                                    colWidth={4}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Position
                                    <span className="text-danger">*</span>
                                </Label>
                                <ComboBox 
                                    endpoint="/position/search"
                                    isLookup={false}
                                    idField="positionId"
                                    valueField="positionName"
                                    selectedItem={itemInEdit?.position}
                                    onChange={(item) => {
                                        setItemInEdit( { ...itemInEdit, position: item, positionId: item ? item.positionId : null } );
                                    }}
                                    disabled={defaultPositionFilter ? true : false}
                                />
                            </FormGroup>
                        </Col>
                        
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Job Descriptions
                                </Label>
                                <Editor2
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName editor-min-height-3-lines"
                                    onEditorStateChange={onEditorStateChange}
                                    toolbar={{
                                        options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'remove', 'history'], // Removed 'fontSize' and 'fontFamily'
                                    }}
                                />
                            </FormGroup>
                        </Col> 
                        <Col xs={12}>
                            <div {...getRootProps({className: dropzoneClasses})}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop a file here, or click to select a file</p>
                            </div>
                            {files}
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={() => {
                        props.onClose();
                    }}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave(itemInEdit)}
                    type="button"
                >
                    Save Changes
                </Button>
            </div>
            {
                validationMessage &&
                <SweetAlert
                    warning
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    title={<h4 className="mb-0">Job Description</h4>}
                    onConfirm={() => setValidationMessage(null)} // need to add loading indicator on confirm
                    onCancel={() => setValidationMessage(null)}
                    focusCancelBtn
                >
                    <p>{validationMessage}</p>
                </SweetAlert>
            }
        </Modal>
    )
}