import React, { useCallback, useEffect, useState } from 'react';
import api from "../../../../services/api";
import {
    Label, FormGroup, Button, Row, Col, Alert, Modal
 } from "reactstrap"; 
 import { DropdownBox } from "../../../dropdowns";
import Loader from "../../../loaders";
import SweetAlert from "react-bootstrap-sweetalert";
import fileDownload from 'js-file-download';
import ContractPositionDialog from './ContractPositionDialog';


export default function ContractDialog(props) {
    const {
        jobOrderCandidates,
        onClose
    } = props;

    const [ loading, setLoading ] = React.useState(false);
    const [ generatedContracts, setGeneratedContracts ] = React.useState([]);
    const [ contractTemplates, setContractTemplates ] = React.useState([]);
    const [ selectedTemplate, setSeletectedTemplate ] = React.useState({});
    const [ validationAlert, setValidationAlert ] = React.useState([]);
    const [showPositionDialog, setShowPositionDialog] = useState(false);

    React.useEffect(() => {
        setLoading(true);
        // console.log('ContractDialog bulk', jobOrderCandidates)
        const jobOrderCandidate = jobOrderCandidates[0]
        
        let apiCalls = [
            api.get(`/lookup/contracttemplatejoc?jobOrderCandidateId=${jobOrderCandidate}`),
        ];

        Promise.all(apiCalls)
        .then((response) => {
            // console.log('data', response[0].data)
            setContractTemplates(response[0].data.map(m => ({ ...m, html: `<span class='ddl-wrap'><span class='ddl-header'>${m.lookupLabel}</span><span class='ddl-value'>${m.lookupValue}</span></span>` })));
        })
        .catch((error) => console.error("error: ", error))
        .finally(() => setLoading(false));

    }, []);

    const downloadErrorDocument = useCallback((candidateId) => {
        if (!candidateId)
            return;

        setLoading(true);
        const url = `generatedcontract/error/${candidateId}`;
        api.get(url, { responseType: 'blob' })
            .then(blob => {
                // console.log({blob});
                const {headers} = blob;
                const filename = headers["content-disposition"].split(";")[1].split("\"")[1];
                // console.log({filename});
                fileDownload(blob.data, filename);
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => setLoading(false))
        ;
    }, []);

    const generateContract = React.useCallback((position, callback) => {
        // console.log('selectedTemplate', {...selectedTemplate})
        if (jobOrderCandidates && jobOrderCandidates.length > 0) {
            setLoading(true);
            api.post(`generatedcontract/generatebulk`, { jobOrderCandidateId: jobOrderCandidates, contractTemplateId: selectedTemplate.lookupId, contractTemplateType: selectedTemplate.lookupType, positionId: position?.positionId ?? null })
            .then(response => {
                setGeneratedContracts(response.data);

                if (callback)
                    callback();
            }).catch(error => {
                console.log({error});
                if (error.response.data?.errors)
                {
                    const errors = error.response.data?.errors.map(item => {
                        if (item.message === "Project")
                            return {message: `Invalid project.`};

                        if (item.message === "Contract Terms")
                            return {message: `Project ${item.projectName} (${item.locationName} - ${item.positionName}) missing terms and conditions.`};
                        
                        if (item.message === "Rate")
                            return {message: `Project ${item.projectName} (${item.employmentCategoryName} - ${item.locationName} - ${item.positionName}) missing rate.`};
                        
                        if (item.message === "Recruitment Person")
                            return {message: `Recruitment person is empty.`};
                        
                        if (item.message === "System")
                            return {message: (
                                <>
                                    Error when generating contract for {item.candidateName} (Project {item.projectName} ({item.locationName} - {item.positionName})).
                                    Click <u className="cursor-pointer" onClick={() => downloadErrorDocument(item.candidateId)}>here</u> to download the detail error message in the document.
                                </>
                            )};
                    });
                    setValidationAlert(errors);
                }
            }).finally(() => setLoading(false));
        }        
    }, [jobOrderCandidates, selectedTemplate]);

    useEffect(() => console.log({jobOrderCandidates}), [jobOrderCandidates]);

    return (
        <Modal
            isOpen={true}
        >
        {
            (loading) && <Loader />
        }
        {
            validationAlert && validationAlert.length > 0 &&
            <Alert color="warning" className="pa-0 d-flex justify-content-between">
                {/* <span className="alert-text"> */}
                    <ul className="mb-0">
                        {
                            validationAlert.map((item, index) => <li key={index} className="text-left">{item.message}</li>)
                        }
                    </ul>
                {/* </span> */}
            </Alert>
        }
            <div className="modal-header">
                <h5 className="modal-title">Bulk Contract Generation</h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div className="modal-body">
                <div className="dialog-wrapper">
                    <div className="dialog-section">
                        <h5>Generate New</h5>
                        <div className="dialog-form">
                            <Row>
                                <Col xs={9}>
                                    <FormGroup>
                                        <Label>
                                            Template
                                        </Label>
                                        <DropdownBox 
                                            data={contractTemplates}
                                            selectedItem={selectedTemplate}
                                            html={true}
                                            onChange={(item) => { 
                                                setSeletectedTemplate(item);
                                            }}
                                        />
                                    </FormGroup>
                                </Col> 
                                <Col xs={3} className="d-flex align-items-end justify-content-start">
                                    <Button className="mb-3 w-100" onClick={() => setShowPositionDialog(true)}>GO</Button>
                                </Col>
                            </Row>
                            {
                                (generatedContracts && generatedContracts.length > 0) &&
                                <Row>
                                    <Col>
                                        <p>Generated {generatedContracts.length} contract{generatedContracts.length > 1 ? "s" : ""}</p>
                                    </Col>
                                </Row>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal-footer d-flex flex-wrap justify-content-center"
            >
                <Button
                    color="primary"
                    onClick={onClose}
                    type="button"
                >
                    Done
                </Button>
            </div>
        {
            showPositionDialog &&
            <ContractPositionDialog
                jobOrderCandidateId={jobOrderCandidates[0]}
                onClose={() => setShowPositionDialog(false)}
                onSubmit={(position) => {
                    setShowPositionDialog(false);
                    generateContract(position);
                }}
            />
        }
        </Modal>
    );
}