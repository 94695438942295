import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { IndeterminateCheckbox } from '../../../../../react-table/controls';
import { ViewAccess, formatDate } from "../../../../../../utils";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import qs from "qs";
import debounce from 'lodash.debounce';
import { filterBy } from "@progress/kendo-data-query";
import api from "../../../../../../services/api";
import useGrid from '../contexts/GridContext';
import SearchCandidateDialog from '../dialogs/SearchCandidateDialog';
import CandidateResponseDialog from '../dialogs/CandidateResponseDialog';

export default (props) => { 
    const {
        loadSchedule,
        startDateDefault,
        endDateDefault,
        disableTrimStartDateDefault,
        disableTrimEndDateDefault,
        viewAccess,
    } = props;

    const { 
        setDispatchUnit, 
        dispatchUnit,
        project,
        setProject,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        trimStartDate,
        setTrimStartDate,
        trimEndDate,
        setTrimEndDate,
        showArchivedDispatchUnits,
        setShowArchivedDispatchUnits,
        dataLoaded,
    } = useGrid();

    const [ projects, setProjects ] = useState([]);
    const [ projectsLoading, setProjectsLoading ] = useState(false);

    const [ dispatchUnits, setDispatchUnits ] = useState([]);
    const [ allDispatchUnits, setAllDispatchUnits ] = useState([]);
    const [ dispatchUnitsLoading, setDispatchUnitsLoading ] = useState(false);
    const [ notificationItems, setNotificationItems ] = useState(null);
    const [ candidateResponseItems, setCandidateResponseItems ] = useState(null);

    useEffect(() => {
        if (process.env.REACT_APP_HRIS_ROSTER_ACCOMMODATION_REPORT_GRID_COLUMN_STATE in localStorage) {
            const savedState = JSON.parse(localStorage.getItem(process.env.REACT_APP_HRIS_ROSTER_ACCOMMODATION_REPORT_GRID_COLUMN_STATE));

            // Date states
            const startDateState = startDateDefault ? startDateDefault : new Date(savedState.startDate);
            const endDateState = endDateDefault ? endDateDefault : new Date(savedState.endDate);
            const trimStartDateState = savedState.trimStartDate;
            const trimEndDateState = savedState.trimEndDate;

            // Load schedule var parameters
            const startDateStateLoad = trimStartDateState ? null : formatDate(startDateState, 'YYYY-MM-DD');
            const endDateStateLoad = trimEndDateState ? null : formatDate(endDateState, 'YYYY-MM-DD');

            setProject(savedState.project);            
            setDispatchUnit(savedState.dispatchUnit);
            setShowArchivedDispatchUnits(savedState.showArchivedDispatchUnits);

            setStartDate(startDateState);
            setEndDate(endDateState);
            setTrimStartDate(trimStartDateState);
            setTrimEndDate(trimEndDateState);

            // console.log('loadSchedule', 'case 2');
            loadSchedule({ 
                projectId: savedState.project.projectId,
                dispatchUnitId: savedState.dispatchUnit.lookupId,
                startDate: startDateStateLoad,
                endDate: endDateStateLoad
            })
        }
    }, []);

    const requestProjectData = useCallback(
        debounce((filter) => {
            const queryString = qs.stringify({ filter }, { allowDots: true });

            api.get(`/project/search${queryString ? `?${queryString}` : ""}`)
                .then((response) => {
                    if (!response.data)
                        setProjects([]);
                    else
                        setProjects(response.data);
                })
                .catch((error) => {
                    console.error(error.response)
                })
                .finally(() => {
                    setProjectsLoading(false);
                })
            ;
        }, 300)
    , []);

    const onProjectFilterChange = useCallback((event) => {
        setProjectsLoading(true);
        requestProjectData(event.filter?.value);
        setProjects([]);
    }, []);

    const onProjectChange = useCallback((event) => {
        setProject(event.value);
    }, []);

    useEffect(() => {
        if (project) {
            setDispatchUnitsLoading(true);

            api.get(`/lookup/dispatchunit/${project?.projectId}/${showArchivedDispatchUnits}`)
                .then((response) => {
                    if (!response.data)
                    {
                        setDispatchUnits([]);
                        setAllDispatchUnits([]);
                    } 
                    else 
                    {
                        setDispatchUnits(response.data);
                        setAllDispatchUnits(response.data);
                    }
    
                })
                .catch((error) => console.error(error.response))
                .finally(() => {
                    setDispatchUnitsLoading(false);
                });
        }
        else {
            setDispatchUnits([]);
            setAllDispatchUnits([]);
        }
        
    }, [project, showArchivedDispatchUnits]);

    const onDispatchUnitChange = useCallback((event) => {
        setDispatchUnit(event.value);
    }, []);
    
    const handleFilterClick = useCallback(() => {
        localStorage.setItem(process.env.REACT_APP_HRIS_ROSTER_ACCOMMODATION_REPORT_GRID_COLUMN_STATE, JSON.stringify({
            project,
            dispatchUnit,
            trimStartDate,
            trimEndDate,
            startDate: formatDate(startDate, 'YYYY-MM-DD'),
            endDate: formatDate(endDate, 'YYYY-MM-DD'),
            showArchivedDispatchUnits
        }));
        loadSchedule({ 
            projectId: project.projectId,
            dispatchUnitId: dispatchUnit.lookupId,
            startDate: trimStartDate ? null: formatDate(startDate, 'YYYY-MM-DD'),
            endDate: trimEndDate ? null : formatDate(endDate, 'YYYY-MM-DD')
        })
    }, [project, dispatchUnit, trimStartDate, trimEndDate, startDate, endDate, showArchivedDispatchUnits]);

    const onDispatchUnitFilterChange = useCallback((event) => {
        const copy = [...allDispatchUnits];
        const data = filterBy(copy, event.filter);
        setDispatchUnits(data);
    }, [allDispatchUnits]);



    return (
        <Container fluid className='d-flex flex-column justify-content-start h-100'>
            <Row>
                <Col xs={12} className="pt-3">
                    <h5>Report Filter</h5>
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <Label>
                            Project
                            <span className="text-danger"> *</span>
                        </Label>
                        <ComboBox
                            data={projects}
                            value={project}
                            onChange={onProjectChange}
                            dataItemKey="projectId"
                            textField="projectName"
                            filterable={true}
                            onFilterChange={onProjectFilterChange}
                            onFocus={onProjectFilterChange}
                            placeholder=""
                            loading={projectsLoading}
                            rounded="small"
                            size="small"
                            className='w-100'
                        />
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <div className="w-100 d-flex justify-content-between">
                            <Label>
                                Dispatch Unit
                                <span className="text-danger"> *</span>
                            </Label>
                            <IndeterminateCheckbox
                                id="showArchived"
                                type="checkbox"
                                onChange={() => {
                                    setShowArchivedDispatchUnits(!showArchivedDispatchUnits);
                                }}
                                checked={showArchivedDispatchUnits}
                                customTitle="Show Archived"
                            />
                        </div>
                        <ComboBox
                            data={dispatchUnits}
                            value={dispatchUnit}
                            onChange={onDispatchUnitChange}
                            dataItemKey="lookupId"
                            textField="lookupValue"
                            filterable={true}
                            placeholder=""
                            loading={dispatchUnitsLoading}
                            rounded="small"
                            size="small"
                            className='w-100'
                            onFilterChange={onDispatchUnitFilterChange}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup className={trimStartDate ? 'disabled' : ''}>
                        <div className="w-100 d-flex justify-content-between">
                            <Label>
                                Start Date
                                <span className="text-danger"> *</span>
                            </Label>
                            <IndeterminateCheckbox
                                id="trimStartDate"
                                type="checkbox"
                                onChange={() => {
                                    setTrimStartDate(!trimStartDate);
                                }}
                                disabled={disableTrimStartDateDefault}
                                checked={trimStartDate}
                                customTitle="Trim"
                            />
                        </div>
                        <DatePicker
                            name="startDate"
                            id="startDate"
                            required
                            value={startDate}
                            onChange={({value}) => {
                                setStartDate(value);
                            }}
                            disabled={trimStartDate}
                            format={"dd/MM/yyyy"}
                            className='w-100'
                        />
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup className={trimEndDate ? 'disabled' : ''}>
                        <div className="w-100 d-flex justify-content-between">
                            <Label>
                                End Date
                                <span className="text-danger"> *</span>
                            </Label>
                            <IndeterminateCheckbox
                                id="trimEndDate"
                                type="checkbox"
                                onChange={() => {
                                    setTrimEndDate(!trimEndDate);
                                }}
                                disabled={disableTrimEndDateDefault}
                                checked={trimEndDate}
                                customTitle="Trim"
                            />
                        </div>
                        <DatePicker
                            name="endDate"
                            id="endDate"
                            required
                            value={endDate}
                            onChange={({value}) => {
                                setEndDate(value);
                            }}
                            disabled={trimEndDate}
                            format={"dd/MM/yyyy"}
                            className='w-100'
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <Button
                        color="secondary"
                        onClick={handleFilterClick}
                        type="button"
                        className="w-100"
                    >
                        Filter
                    </Button>
                </Col>
            </Row>
        {
            viewAccess == ViewAccess.FULLACCESS &&
            <Row className="mb-3 pb-3">
                <Col xs={12} className="pt-3">
                    <h5>Actions</h5>
                </Col>
                <Col xs={12}>
                    <Button
                        color="secondary btn-grid-panel"
                        onClick={() => setNotificationItems({
                            dispatchUnitId: dispatchUnit?.lookupId,
                            onClose: () => {
                                setNotificationItems(null);
                                loadSchedule({ 
                                    projectId: project.projectId,
                                    dispatchUnitId: dispatchUnit.lookupId,
                                    startDate: trimStartDate ? null: formatDate(startDate, 'YYYY-MM-DD'),
                                    endDate: trimEndDate ? null : formatDate(endDate, 'YYYY-MM-DD')
                                });
                            },
                        })}
                        type="button"
                        className="w-100 text-left"
                        disabled={!dataLoaded || !dispatchUnit}
                    >
                        Send Notification
                    </Button>
                </Col>
                <Col xs={12}>
                    <Button
                        color="secondary btn-grid-panel"
                        onClick={() => setCandidateResponseItems({
                            dispatchUnitId: dispatchUnit?.lookupId,
                            onClose: () => {
                                setCandidateResponseItems(null);
                                loadSchedule({ 
                                    projectId: project.projectId,
                                    dispatchUnitId: dispatchUnit.lookupId,
                                    startDate: trimStartDate ? null: formatDate(startDate, 'YYYY-MM-DD'),
                                    endDate: trimEndDate ? null : formatDate(endDate, 'YYYY-MM-DD')
                                });
                            },
                        })}
                        type="button"
                        className="w-100 text-left"
                        disabled={!dataLoaded || !dispatchUnit}
                    >
                        View Candidate Responses
                    </Button>
                </Col>
            </Row>
        }
        {
            notificationItems ?
            <SearchCandidateDialog
                {...notificationItems}
            />
            :
            null
        }
        {
            candidateResponseItems ?
            <CandidateResponseDialog
                {...candidateResponseItems}
            />
            :
            null
        }
        </Container>
    )
}
