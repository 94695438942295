/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { ScheduleTypeCodes, ScheduleTypeMobilisation, ScheduleTypeValues } from "../helpers/enums";
import api from "../../../../services/api";
import { formatDate } from '../../../../utils';
import { Spinner } from 'reactstrap';
import moment from 'moment';
import { DateStringToUtcDate } from '../../../../helpers/StringHelper';

const initial = <p className='m-0'></p>;
const empty = <p className='m-0'>No Data</p>;

const TooltipContainer = (props) => {
    const {
        key,
        children
    } = props;

    return (
        <p key={key} className='m-0' style={{ fontSize: "0.8rem" }}>
            {
                children
            }
        </p>
    );
}

export default (props) => {
    const {
        data,
        column
    } = props;

    const [cellValues, setCellValues] = useState([initial]);
    const [loading, setLoading] = useState(false);
    const [flights, setFlights] = useState([]);
    const [flightsLoading, setFlightsLoading] = useState(false);
    const [flightsJsx, setFlightsJsx] = useState(<></>);

    const rosterCandidateId = useMemo(() => {
        return data?.rosterCandidateId;
    }, [data?.rosterCandidateId]);


    useEffect(() => {
        const [, date] = column.colId.split('.');
        const values = data['dates'][date];
        const mobeValues = [...new Set(values?.value)].filter(v => ScheduleTypeMobilisation.includes(v));


        if (!mobeValues?.length) {
            // console.log({values});
            setCellValues(
                [
                    (<TooltipContainer>
                        {values?.scheduleNotes}
                    </TooltipContainer>),
                    ...[...new Set(values?.value)].filter(v => v !== ScheduleTypeCodes.InactiveDate).map((key, index) => (
                        <TooltipContainer key={index}>
                            {ScheduleTypeValues[key]}
                        </TooltipContainer>
                    ))
                ]
            );
            //return;
        }

        setLoading(true);
        const scheduleKeys = Object.keys(values.schedules);
        const scheduleIds = [];
        const rcaApiParams = [];
        const dateUtc = DateStringToUtcDate(date, 'YYYYMMDD');
        if (date)
            rcaApiParams.push({
                rosterCandidateId: rosterCandidateId,
                //date: moment(date, 'YYYYMMDD').toDate()
                date: dateUtc
            });

        //console.log('rcaApiParams', rcaApiParams);

        scheduleKeys.forEach(s => {
            scheduleIds.push(values.schedules[s]);
        });

        let apiCalls = [
            api.post(`rostergrid/accommodations`, JSON.stringify(rcaApiParams)),
        ];


        if (mobeValues?.length)
            apiCalls.push(api.post(`rostergrid/flights`, JSON.stringify(scheduleIds)));

        Promise.all(apiCalls)
            .then((responses) => {

                const _flights = !responses[1] ? [] : responses[1].data;
                const _accommodations = responses[0].data;
                let lastIndex = 0;
                setFlights([..._flights]);
                // console.log({flights});

                var newCellValues = [];
                newCellValues.push([
                    (<TooltipContainer style={!_flights?.length && !_accommodations.length ? null : { fontSize: "0.8rem", fontWeight: "bold" }}>
                        {values?.scheduleNotes ? <>{values?.scheduleNotes}<br /></> : null}
                    </TooltipContainer>),
                ]);

                if (!_flights.length) {
                    newCellValues.push(
                        ...[...new Set(values?.value)].filter(v => v !== ScheduleTypeCodes.InactiveDate).map((key, index) => {
                            lastIndex++;
                            return (<TooltipContainer key={lastIndex}>
                                {
                                    values?.scheduleNotes ?
                                        <>{values?.scheduleNotes}<br /></>
                                        :
                                        null
                                }
                                {ScheduleTypeValues[key]}
                            </TooltipContainer>);
                        })
                    );
                }
                else {
                    newCellValues.push(
                        ...[...new Set(values?.value)].filter(v => v !== ScheduleTypeCodes.InactiveDate).map((key, index) => {

                            lastIndex++;
                            const currentFlights = _flights.filter(f => f.rosterCandidateScheduleId === values.schedules[key]);

                            if (!currentFlights?.length)
                                return (
                                    <TooltipContainer key={lastIndex} style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                                        {ScheduleTypeValues[key]}
                                    </TooltipContainer>
                                );

                            return (
                                <TooltipContainer key={lastIndex}>
                                    {ScheduleTypeValues[key]}<br />
                                    {
                                        currentFlights.map(currentFlight => {
                                            return (
                                                <>
                                                    <span className="m-1">
                                                        {
                                                            currentFlight.flightDateTime ? `${formatDate(currentFlight.flightDateTime, "DD-MM-YYYY HH:mm:ss")} - ` : ""}{`${currentFlight.departureAirportName ?? "-"} to ${currentFlight.arrivalAirportName ?? "-"}`}
                                                    </span>
                                                    <br />
                                                </>
                                            );
                                        })
                                    }
                                </TooltipContainer>
                            )
                        })
                    );
                }
                if (_accommodations?.length) {

                    let accommodationHitCount = 0;


                    let lastIndex2 = 0;

                    // Current roster candidate schedule
                    const currentRcs = _accommodations.find(f => f.date && moment.utc(f.date).toDate().getTime() === dateUtc.getTime());


                    let cumulativeResult = [];
                    if (currentRcs?.rosterCandidateAccommodation?.length && accommodationHitCount == 0) {
                        accommodationHitCount++;
                        lastIndex++;
                        cumulativeResult.push(
                            <TooltipContainer key={lastIndex} style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                                {/*{ScheduleTypeValues[key]}*/}
                                Accommodation
                            </TooltipContainer>
                        );
                    }

                    if (!currentRcs?.rosterCandidateAccommodation?.length) {
                        return null;
                    }

                    for (let index2 = 0; index2 < currentRcs.rosterCandidateAccommodation.length; index2++) {
                        lastIndex2++;
                        //console.log('currentRcs.rosterCandidateAccommodation', currentRcs.rosterCandidateAccommodation);
                        const rca = currentRcs.rosterCandidateAccommodation[index2];
                        const startDateStr = rca.startDate ? formatDate(rca.startDate, "DD-MM-YYYY") : "";
                        const endDateStr = rca.endDate ? formatDate(rca.endDate, "DD-MM-YYYY") : "";

                        cumulativeResult.push(
                            <span className="m-1" key={lastIndex2}>
                                {rca.startDate ? `${startDateStr}` : ""}
                                {rca.endDate ? ` - ${endDateStr}` : ""}
                                {` - ${rca.accommodation.accommodationName ?? "N/A"}`}
                            </span>
                        );
                    }

                    lastIndex++;
                    newCellValues.push(
                        <TooltipContainer key={lastIndex}>
                            {/*{ScheduleTypeValues[key]}<br />*/}
                            {cumulativeResult}
                        </TooltipContainer>
                    );


                }

                setCellValues(newCellValues);

            })
            .catch((error) => {
                console.error(error);
                setCellValues("Unexpected error");
            })
            .finally(() => setLoading(false));
    }, [data]);

    const tooltip = useMemo(() => {
        // console.log({cellValues});
        return cellValues?.length ? cellValues : empty;
    }, [cellValues]);

    return (
        <div key={`${props.node.rowIndex}-${props.column.instanceId}`}
            className="border border-info px-3 py-1"
            style={{ backgroundColor: 'white', minWidth: '150px', maxHeight: "300px" }}
        >
            <>{tooltip}</>
            <>{ loading ? <div className="w-100 d-flex flex-wrap justify-content-center"><Spinner color="default" size="sm" /></div> : null }</>
        </div>
    );
};