import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import {
    Modal, Container, Row, Col, Button, Label
} from "reactstrap";
import api from "../../../services/api";
import { useDropzone } from 'react-dropzone';
import cn from "classnames";
import Loader from "../../loaders";
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community';
import readXlsxFile from 'read-excel-file';
import { DateValueFormatter, MakeWordsValueGetter, ImportDialogMessageCellRenderer } from '../../../helpers/AgGridHelper';
import _ from 'lodash';
import "../../../assets/css/ag-grid.css";
import "../../../assets/css/ag-theme-alpine.css";

const UPLOAD_MODE = "upload";
const VALIDATION_MODE = "validation";

export default function RateImportDialog(props) {
    const {
        projectId,
        loadData,
        onClose,
    } = props;

    const [loading, setLoading] = useState(false);
    const [importFiles, setImportFiles] = useState([]);
    const [alert, setAlert] = useState('');
    const [data, setData] = useState([]);
    const [mode, setMode] = useState(UPLOAD_MODE);
    const [isValid, setIsValid] = useState(false);
    const [isDuplicated, setIsDuplicated] = useState(false);
    const apiurl = 'rates';

    const defaultColumns = [
        {
            field: "id",
            headerName: "ID"
        },
        {
            field: "project",
            headerName: "Project"
        },
        {
            field: "employmentCategory",
            headerName: "Employment Category"
        },
        {
            field: "location",
            headerName: "Location"
        },
        {
            field: "position",
            headerName: "Position"
        },
        {
            field: "startDate",
            headerName: "Start Date"
        },
        {
            field: "endDate",
            headerName: "End Date"
        },
        {
            field: "fulltimeRate",
            headerName: "Fulltime Rate",
            minWidth: 100,
            width: 100,
        },
        {
            field: "fullTimeRateDs",
            headerName: "Full Time Rate DS",
            minWidth: 100,
            width: 100,
        },
        {
            field: "fullTimeRateNs",
            headerName: "Full Time Rate NS",
            minWidth: 100,
            width: 100,
        },
        {
            field: "overtimeRate",
            headerName: "Overtime Rate",
            minWidth: 100,
            width: 100,
        },
        {
            field: "casualNormalTime",
            headerName: "Casual Normal Time",
            minWidth: 100,
            width: 100,
        },
        {
            field: "casualNightshift",
            headerName: "Casual Nightshift",
            minWidth: 100,
            width: 100,
        },
        {
            field: "casualNightshiftWeekendRate",
            headerName: "Casual Nightshift Weekend Rate",
            minWidth: 100,
            width: 100,
        },
        {
            field: "casualNsnotStandard",
            headerName: "Casual NS (if not std%)",
            minWidth: 100,
            width: 100,
        },
        {
            field: "fullTimeOffsiteRate",
            headerName: "Full Time Offsite Rate",
            minWidth: 100,
            width: 100,
        },
        {
            field: "casualOffsiteRate",
            headerName: "Casual Offsite Rate",
            minWidth: 100,
            width: 100,
        },
        {
            field: "fullTimeTraining",
            headerName: "Full Time Training",
            minWidth: 100,
            width: 100,
        },
        {
            field: "casualTraining",
            headerName: "Casual Training",
            minWidth: 100,
            width: 100,
        },
        {
            field: "casualLoadingRate",
            headerName: "Casual Loading Rate",
            minWidth: 100,
            width: 100,
        },
        {
            field: "notes",
            headerName: "Notes"
        },
    ];

    const [columnDefs, setColumnDefs] = useState([...defaultColumns]);
    const gridRef = useRef();

    const onDrop = useCallback(acceptedFiles => setImportFiles([...acceptedFiles]), [importFiles]);

    useEffect(() => {
        setIsValid(data?.length && !data.some((v, i, a) => !v.status));
        setIsDuplicated(data?.length && data.some((v, i, a) => v.isDuplicated));
    }, [data]);

    const handleFileSelected = useCallback(() => {
        setLoading(true);
        const file = importFiles[0];

        // console.log('readXlsxFile');
        readXlsxFile(file)
            .then((rows) => {
                if (rows.length == 0 || rows[0].length < 20 || rows[0][0]?.toLowerCase() !== defaultColumns[0].headerName.toLowerCase()) {
                    setAlert({ type: "error", message: "Invalid data format." });
                    setImportFiles([]);
                    setData([]);
                    return;
                }

                const columns = [...rows[0]];
                const newRows = [...rows];
                newRows.splice(0, 1);
                const gridColumns = [];
                const newData = [];
                const newValidationData = [];

                // console.log('rows', rows);
                // console.log('columns', columns);
                columns.forEach(c => {
                    const fieldName = defaultColumns.filter(d => d.headerName === c)?.length ? defaultColumns.filter(d => d.headerName === c)[0].field : c.replace(/\s+/g, "").replace(/[^A-Za-z]+/g, "");

                    const columnStaticState = {
                        field: `before.${fieldName}`,
                        headerName: `${c}`,
                    };
                    let columnBefore = {
                        field: `before.${fieldName}`,
                        headerName: `${c} - Before`,

                    };
                    let columnAfter = {
                        field: `after.${fieldName}`,
                        headerName: `${c} - After`,
                    };

                    if (fieldName && fieldName.toLowerCase().includes("date")) {
                        const addonForDate = {
                            filter: 'agDateColumnFilter',
                            valueFormatter: DateValueFormatter
                        };

                        columnBefore = { ...columnBefore, ...addonForDate };
                        columnAfter = { ...columnAfter, ...addonForDate };
                    }
                    else if (fieldName && fieldName.toLowerCase() == "id") {
                        gridColumns.push(columnStaticState);
                        return;
                    }

                    gridColumns.push(columnBefore);
                    gridColumns.push(columnAfter);
                });

                // console.log('fieldNames', fieldNames);
                // console.log('gridColumns', gridColumns);
                gridColumns.push({
                    field: 'isDuplicated',
                    headerName: `Is Duplicated`,
                    editable: false,
                    cellClass: "px-0 py-0 text-center",
                    cellRenderer: (params) => {
                        const { data } = params;
                        const { isDuplicated } = data;
                        const statusText = !isDuplicated ? "Ok" : "Duplicated";
                        const statusIcon = !isDuplicated ? "fas fa-check-circle text-success" : "fas fa-exclamation-circle text-warning";
                    
                        return (
                            <div>
                                <span className={`${statusIcon} mr-1`}></span>
                                {statusText}
                            </div>
                        );
                    },
                });
                gridColumns.push({
                    field: 'status',
                    headerName: `Validation Status`,
                    editable: false,
                    cellClass: "px-0 py-0 text-center",
                    cellRenderer: ImportDialogMessageCellRenderer,
                });
                gridColumns.push({
                    field: 'messages',
                    headerName: `Validation Message`,
                    editable: false,
                    valueGetter: MakeWordsValueGetter,
                });

                const validationColumns = [
                    "id",
                    "project",
                    "employment category",
                    "location",
                    "position",
                    "start date",
                    "end date",
                    "fulltime rate",
                    "overtime rate",
                    "casual normal time",
                    "casual nightshift",
                    "casual nightshift weekend rate",
                    "notes",
                    "full time rate ds",
                    "full time rate ns",
                    "casual ns (if not std%)",
                    "full time offsite rate",
                    "casual offsite rate",
                    "full time training",
                    "casual training",
                    "casual loading rate",
                ];

                newRows.forEach((r, r_idx) => {
                    const row = {
                        before: {},
                        after: {},
                    };
                    const validationRow = {
                        rowId: r_idx,
                        rowNo: r_idx + 1,
                    };
                    row['after']['rowId'] = r_idx;
                    row['after']['rowNo'] = r_idx + 1;

                    r.forEach((c, idx) => {
                        const columnName = defaultColumns.filter(d => d.headerName.toLowerCase() === columns[idx].toLowerCase())?.length ? defaultColumns.filter(d => d.headerName.toLowerCase() === columns[idx].toLowerCase())[0].field : columns[idx].replace(/\s+/g, "").replace(/[^A-Za-z]+/g, "");
                        row['after'][columnName] = c;

                        if (validationColumns.includes(columns[idx].toLowerCase()))
                            validationRow[columnName] = c;
                    });

                    newData.push({ ...row });
                    newValidationData.push({ ...validationRow });
                });

                api.post(`/${apiurl}/validateimport?projectId=${projectId}`, JSON.stringify(newValidationData))
                    .then((response) => {
                        const { data: serverValidation } = response;

                        if (mode !== VALIDATION_MODE)
                            setMode(VALIDATION_MODE);

                        if (serverValidation?.length)
                            serverValidation.forEach(d => {
                                const selectedRow = newData.find(w => w.after && w.after.rowId == d.rowId);

                                if (selectedRow) {
                                    selectedRow['messages'] = d.messages;
                                    selectedRow['status'] = d.status;
                                    selectedRow['before'] = { ...d.data.before };
                                    selectedRow['after'] = { ...d.data.after };
                                    selectedRow.isDuplicated = d.isDuplicated;
                                }
                            });

                        setColumnDefs([...gridColumns]);
                        setData([...newData]);
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.error({ error });
                        setLoading(false);
                    })
                ;
            })
        ;
    }, [projectId, importFiles]);


    useEffect(() => {
        if (!importFiles?.length)
            return;

        handleFileSelected();
    }, [importFiles]);

    useEffect(() => {
        const rosterCandidateDates = [];
        const newValidationData = [];

        if (!data?.length) {
            setColumnDefs([...defaultColumns]);
            return;
        }

        let newCanSave = true;
        let i = 0;

        while (i < data.length) {
            if (!rosterCandidateDates.filter(rc => rc.rosterCandidateId === data[i].rosterCandidateId)?.length) {
                rosterCandidateDates.push({
                    rosterCandidateId: data[i].rosterCandidateId,
                    startDate: data[i].workStart,
                    endDate: data[i].workEnd,
                });
                i++;
                continue;
            }

            if (!rosterCandidateDates.filter(rc => rc.rosterCandidateId === data[i].rosterCandidateId && rc.startDate === data[i].workStart && rc.endDate === data[i].workEnd)?.length) {
                i++;
                continue;
            }

            if (newCanSave)
                newCanSave = false;

            if (!newValidationData.filter(d => d.id === data[i].rosterCandidateId)?.length)
                newValidationData.push({
                    id: data[i].rosterCandidateId,
                    messages: []
                });

            const validation = newValidationData.filter(d => d.id === data[i].rosterCandidateId)[0];

            if (!validation.messages?.length)
                validation.messages.push("Start Date/End Date for each row with the same ID should be different.");

            i++;
        }
    }, [data]);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const dropzoneClasses = useMemo(() => cn({
        'dropzone': true,
        'mt-2': true,
        'active': isDragActive,
        'accept': isDragAccept,
        'reject': isDragReject
    }), [
        isDragActive,
        isDragAccept,
        isDragReject
    ]);

    const loadingOverlayComponent = useMemo(() => Loader, []);

    const defaultColDef = useMemo(() => ({
        sortable: false,
        resizable: true,
        filter: true,
        editable: false,
        filter: 'agSetColumnFilter',
        filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200
        },
    }), []);

    const files = useMemo(() =>
        importFiles.map(
            file => (
                <li key={file.path}>
                    {file.path} - {file.size} bytes
                </li>
            )
        )
        , [importFiles]);

    const resizeColumns = useCallback(() => {
        if (!gridRef?.current?.columnApi || data?.length === 0)
            return;

        const allColumnIds = [];
        gridRef.current.columnApi.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }, [data, gridRef]);

    const handleImport = useCallback(() => {
        setLoading(true);

        const postData = data.map((v, i, a) => {
            return { ...v.after };
        });

        api.post(`${apiurl}/importraw`, postData)
            .then(() => {
                // console.log('roster import', response);
                setAlert({ type: "success" });
            })
            .catch((error) => {
                console.error({ error });
                setAlert({ type: "error", message: JSON.stringify(error.response?.data) });
            })
            .finally(() => setLoading(false));
    }, [data, columnDefs, importFiles]);

    return (
        <Modal
            isOpen={true}
            className="modal-xl"
            modalClassName="db-example-modal-xl"
        >
            {loading && <Loader />}
            <div className="modal-header">
                <h5 className="modal-title">
                    Import Rates
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div className="modal-body">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div {...getRootProps({ className: dropzoneClasses })}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop a file here, or click to select a file</p>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <aside className="dropzone-list pt-3">
                                <Label>File</Label>
                                <ul>{files}</ul>
                            </aside>
                        </Col>
                    </Row>
                    <Row className="w-100 mx-0 mt-3">
                        {
                            data?.length > 0 && !isValid &&
                            <Col xs={12} className={`d-flex flex-wrap justify-content-end align-items-center px-0${isDuplicated ? "" : " mb-2"}`}>
                                <span className="text-danger font-italic">Please check the invalid data below.</span>
                            </Col>
                        }
                        {
                            data?.length > 0 && isValid &&
                            <Col xs={12} className={`d-flex flex-wrap justify-content-end align-items-center px-0${isDuplicated ? "" : " mb-2"}`}>
                                <span className="text-success font-italic">Your data are ok, click Upload to continue.</span>
                            </Col>
                        }
                        {
                            data?.length > 0 && isDuplicated &&
                            <Col xs={12} className="d-flex flex-wrap justify-content-end align-items-center px-0 mb-2">
                                <span className="text-warning font-size-small-custom font-italic">Duplicated data exists. Please check before uploading.</span>
                            </Col>
                        }
                        <Col xs={12} className="d-flex flex-wrap justify-content-center align-items-center px-0">
                            <div className="ag-theme-alpine" style={{ width: '100%', height: data.length ? (mode === UPLOAD_MODE ? "44vh" : "40vh") : 75 }}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={data}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    loadingOverlayComponent={loadingOverlayComponent}
                                    suppressColumnVirtualisation={false}
                                    suppressRowVirtualisation={false}
                                    debounceVerticalScrollbar={false}
                                    rowBuffer={20}
                                    headerHeight={40}
                                    groupHeaderHeight={22}
                                    rowHeight={22}
                                    // readOnlyEdit={true}
                                    onRowDataUpdated={() => resizeColumns()}
                                    suppressDragLeaveHidesColumns={true}
                                    suppressColumnMoveAnimation={true}
                                    animateRows={false}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="modal-footer d-flex flex-wrap justify-content-between align-items-center w-100">
                <div className="d-flex flex-wrap justify-content-start align-items-center">
                    <Button
                        color="secondary"
                        onClick={onClose}
                        type="button"
                    >
                        Close
                    </Button>
                </div>
                <div className="d-flex flex-wrap justify-content-end align-items-center">
                    <Button
                        color="default"
                        onClick={() => {
                            handleImport();
                        }}
                        type="button"
                        disabled={!isValid}
                    >
                        <i className="fas fa-paper-plane mr-2" />Upload
                    </Button>
                </div>
            </div>
        {
            alert &&
            <SweetAlert
                title={alert.type.toLowerCase() === 'error' ? 'Error' : 'Success'}
                error={alert.type.toLowerCase() === 'error'}
                confirmBtnText="OK"
                confirmBtnBsStyle={alert.type.toLowerCase() === 'error' ? "danger" : "success"}
                onConfirm={() => {
                    if (alert.type.toLowerCase() === "error") {
                        setAlert(null);
                        return;
                    }
                    loadData();
                    onClose();
                }}
            >
            {
                alert.type.toLowerCase() == 'error' ? alert.message : 'File successfully uploaded'
            }
            </SweetAlert>
        }
        </Modal>
    )
}