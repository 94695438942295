import React, { useEffect, useState } from 'react';
import {
    Label, FormGroup, Row, Col, Collapse, Container, Input
 } from "reactstrap"; 
import { DropdownBox } from "../../../dropdowns";
import { useTable, useFlexLayout } from 'react-table';
import _ from "lodash";
import { TooltipButton } from '../../../inputs';
import Grid from "../../../Grid";
import { Mode } from '../../../../utils';

export default function Identifiers(props) {
    const {
        itemInEdit, 
        setItemInEdit
    } = props;

    const [ loading, setLoading ] = React.useState(false);
    const [ drawerMode, setDrawerMode ] = React.useState(Mode.NONE);
    const [ candidateIdentifier, setCandidateIdentifier ] = React.useState(null);
    const [candidateId, setCandidateId] = useState(0);
    const [ data, setData ] = useState([]);

    useEffect(() => {
        if ((itemInEdit?.candidateId ?? 0) === candidateId)
            return;
        
        setCandidateId(itemInEdit?.candidateId ?? 0);
    }, [itemInEdit?.candidateId]);

    useEffect(() => {
        if (!candidateId)
            return;

        setData(itemInEdit?.candidateIdentities ?? []);
    }, [candidateId]);

    const skipPageResetRef = React.useRef();

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 150, 
            maxWidth: 200,
    }), [])

    const columns = React.useMemo(() => {
        return [
            {
                Header: "Id",
                accessor: "identifier.lookupValue",
            },
            {
                Header: "Value",
                accessor: "identifierValue",
            },
            {
                id: 'buttons',
                Header: "Action",
                width: 180,
                canResize: false,
                Cell: ({row:{original, isGrouped}}) => (
                    (original && !isGrouped) ?
                    (
                        <div className="td-action text-right">  
                            <TooltipButton
                                id={`edit_${original.candidateIdentityId}`}
                                title="Edit"
                                className="btn-icon"
                                color="default"
                                size="xsm"
                                type="button"
                                onClick={() => {
                                    setCandidateIdentifier({...original})
                                    setDrawerMode(Mode.EDIT);
                                }}
                            >
                                <i className="fas fa-pencil-alt pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`delete_${original.candidateIdentityId}`}
                                title="Delete"
                                className="btn-icon"
                                color="warning"
                                size="xsm"
                                type="button"
                                onClick={() => {
                                    console.log("Delete: ", { identifier: original, itemInEdit });
                                    const index = _.findIndex(data, val => {
                                        if (original.candidateIdentityId)
                                            return val.candidateIdentityId === original.candidateIdentityId;

                                        return val.index === original.index;
                                    });
                                    console.log("Deleted index: ", { data, index });
                                    const identities = [...data];
                                    identities.splice(index, 1);
                                    setData([...identities]);
                                    setItemInEdit({ ...itemInEdit, candidateIdentities: identities });
                                }}
                            >
                                <i className="fas fa-trash-alt pt-1"></i>
                            </TooltipButton>
                        </div>
                    ) : (
                        <div className="td td-action"></div>
                    )
                )
            },
        ]
    }, [data]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data: itemInEdit?.candidateIdentities || [],
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout
    )

    return (
        <>
            <Collapse
                isOpen={drawerMode !== Mode.NONE}
                style={{ zIndex: "9999", border: "0.0625rem solid #dee2e6" }}
                className="bg-white w-100 rounded p-3 mb-2"
            >
                <Container fluid>
                        <Row>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <Label>
                                        Id
                                    </Label>
                                    <DropdownBox
                                        data={props.lookups.identifiers}
                                        selectedItem={candidateIdentifier?.identifier}
                                        onChange={(selectedItem) => setCandidateIdentifier({ 
                                            ...candidateIdentifier, 
                                            identifier: selectedItem, 
                                            identifierId: selectedItem?.lookupId 
                                        })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <Label>
                                        Value
                                    </Label>
                                    <Input
                                        type="text"
                                        required
                                        value={candidateIdentifier?.identifierValue ?? ""}
                                        onChange={( { target: { value } } ) => {
                                            setCandidateIdentifier({
                                                ...candidateIdentifier,
                                                identifierValue: value
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} className="d-flex justify-content-end">
                                <TooltipButton
                                    id="cancel"
                                    title="Cancel"
                                    className="btn-icon"
                                    color="warning"
                                    size="sm"
                                    type="button"
                                    onClick={ () => {                        
                                        setDrawerMode(Mode.NONE);
                                    }}                    
                                >
                                    Cancel
                                </TooltipButton>
                                <TooltipButton
                                    id="add"
                                    title="Add"
                                    className="btn-icon ml-2"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    onClick={ () => { 
                                        if (drawerMode === Mode.ADD) {
                                            const newData = {
                                                ...candidateIdentifier,
                                                index: data?.length ? data.length - 1 : 0,
                                            }
                                            setData([...data, {...newData}]);
                                            setItemInEdit( { 
                                                ...itemInEdit,
                                                candidateIdentities: [
                                                    ...data || [],
                                                    {...newData}
                                                ]
                                            } );
                                        } 
                                        else if (drawerMode === Mode.EDIT)
                                        {
                                            const index = _.findIndex(data, o => {
                                                if (candidateIdentifier.candidateIdentityId)
                                                    return o.candidateIdentityId === candidateIdentifier.candidateIdentityId;
            
                                                return o.index === candidateIdentifier.index;
                                            });
            
                                            const identities = [...data]
                                            identities.splice(index, 1, { 
                                                ...candidateIdentifier
                                            } );
                                            console.log({index, candidateIdentifier, identities})
                                            setData([...identities]);
                                            setItemInEdit( { 
                                                ...itemInEdit, 
                                                candidateIdentities: identities 
                                            } );
                                        }
                                        setDrawerMode(Mode.NONE);
                                    }}
                                >
                                    {drawerMode === Mode.ADD ? "Add" : drawerMode === Mode.EDIT ? "Update" : ""}
                                </TooltipButton>
                            </Col>
                        </Row>  
                </Container>
            </Collapse>
            <Collapse
                isOpen={drawerMode === Mode.NONE}
            >
                <TooltipButton
                    id="addnew"
                    title="Add New"
                    className="btn-icon ml-3 mb-2"
                    color="default"
                    size="sm"
                    type="button"
                    onClick={ () => {
                        setCandidateIdentifier({ identifier: null, identifierValue: null })
                        setDrawerMode(Mode.ADD);
                    }}
                >
                    <i className="fas fa-plus pt-1"></i>
                </TooltipButton>
            </Collapse>
            
            <Grid
                rows={rows}
                tableProps={getTableProps()}
                headerGroups={headerGroups}
                tableBodyProps={getTableBodyProps()}
                prepareRow={prepareRow}
            />
        </>
    );
}