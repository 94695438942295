import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { render } from 'react-dom'
import {
    Input, Label, FormGroup, Row, Col, Button, InputGroup, InputGroupAddon
} from "reactstrap";
import { ComboBox, DropdownBox } from "../../../dropdowns";
import { DatePicker } from "../../../date-pickers";
import { formatDate, formatDateZeroTime } from "../../../../utils";
import moment from "moment";
import InputMask from "react-input-mask";
import { PhotoDropzone } from '../../../FileDropzone';
import { ListBox, processListBoxData } from '@progress/kendo-react-listbox';
import ReactStars from 'react-stars'
import { debounce } from "lodash";
import api from '../../../../services/api';
import Multiselect from 'multiselect-react-dropdown';
import { TooltipButton } from '../../../inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { v4 } from 'uuid';
import { PhoneNumberRenderer } from './Renderers/PhoneNumberRenderer';

export default function Profile(props) {

    const {
        itemInEdit,
        setItemInEdit,
        setSmsFromContact,
        setEmailFromContact,
        isSaving,
        lookups: {phoneTypes},
    } = props;

    const [communicationTypes, setCommunicationTypes] = React.useState([]);
    const [profileData, setProfileData] = useState({});
    const [clientList, setClientList] = useState([]);
    const [alternativeLocationList, setAlternativeLocationList] = useState([]);
    const [loadingClient, setLoadingClient] = useState(false);
    const [candidatePhoneInEdit, setCandidatePhoneInEdit] = useState(null);

    useEffect(() => {
        console.log('profileData', profileData);
    }, [profileData]);

    useEffect(() => {
        if (!itemInEdit || profileData.candidateId !== undefined)
            return;

        const {
            candidateId,
            photoFileName,
            photoFileType,
            photoFileContent,
            firstName,
            middleName,
            surname,
            preferredName,
            salutation,
            salutationId,
            maritalStatus,
            maritalStatusId,
            universalStudentID,
            naturalPosition,
            naturalPositionId,
            gender,
            genderId,
            doB,
            atsi,
            disability,
            cald,
            mobilePhoneNumber,
            receivingCommunication,
            receivingEmail,
            receivingSms,
            preferredCommunication,
            preferredCommunicationId,
            email,
            receivingPhoneCall,
            residencyStatus,
            residencyStatusId,
            nationality,
            visaNumber,
            visaExpiryDate,
            candidateType,
            candidateTypeId,
            candidateStatus,
            candidateStatusId,
            agency,
            agencyId,
            defaultProject,
            defaultProjectId,
            fasttrackCandidateNo,
            mainAddressLine1,
            mainAddressLine2,
            mainAddressCountry,
            mainAddressCountryId,
            mainAddressState,
            mainAddressStateId,
            mainAddressCity,
            mainAddressPostcode,
            mailingAddressSameAsMain,
            mailAddressLine1,
            mailAddressLine2,
            mailAddressCountry,
            mailAddressCountryId,
            mailAddressState,
            mailAddressStateId,
            mailAddressCity,
            mailAddressPostcode,
            emergencyContactName,
            emergencyContactPhone,
            relationshipToCandidate,
            relationshipToCandidateId,
            modifiedBy,
            modifiedDate,
            candidateClientExclusions,
            candidateClientInclusions,
            performanceRating,
            alternativeLocation,
            alternativeLocationId,
            contractingCompany,
            contractingCompanyId
        } = itemInEdit;

        setProfileData({
            candidateId,
            photoFileName,
            photoFileType,
            photoFileContent,
            firstName,
            middleName,
            surname,
            preferredName,
            salutation,
            salutationId,
            maritalStatus,
            maritalStatusId,
            universalStudentID,
            naturalPosition,
            naturalPositionId,
            gender,
            genderId,
            doB,
            atsi,
            disability,
            cald,
            mobilePhoneNumber,
            receivingCommunication,
            receivingEmail,
            receivingSms,
            preferredCommunication,
            preferredCommunicationId,
            email,
            receivingPhoneCall,
            residencyStatus,
            residencyStatusId,
            nationality,
            visaNumber,
            visaExpiryDate,
            candidateType,
            candidateTypeId,
            candidateStatus,
            candidateStatusId,
            agency,
            agencyId,
            defaultProject,
            defaultProjectId,
            fasttrackCandidateNo,
            mainAddressLine1,
            mainAddressLine2,
            mainAddressCountry,
            mainAddressCountryId,
            mainAddressState,
            mainAddressStateId,
            mainAddressCity,
            mainAddressPostcode,
            mailingAddressSameAsMain,
            mailAddressLine1,
            mailAddressLine2,
            mailAddressCountry,
            mailAddressCountryId,
            mailAddressState,
            mailAddressStateId,
            mailAddressCity,
            mailAddressPostcode,
            emergencyContactName,
            emergencyContactPhone,
            relationshipToCandidate,
            relationshipToCandidateId,
            modifiedBy,
            modifiedDate,
            candidateClientExclusions,
            candidateClientInclusions,
            performanceRating,
            alternativeLocation,
            alternativeLocationId,
            contractingCompany,
            contractingCompanyId
        });
    }, [itemInEdit]);

    React.useEffect(() => {
        setCommunicationTypes(props.lookups.communicationTypes?.filter(o => {
            if (o.lookupValue === "Phone" && profileData.receivingPhoneCall) return true;
            if (o.lookupValue === "Email" && profileData.receivingEmail) return true;
            if (o.lookupValue === "SMS" && profileData.receivingSms) return true;
            return false;
        }));

    }, [profileData.receivingEmail, profileData.receivingPhoneCall, profileData.receivingSms, props.lookups.communicationTypes]);

    useEffect(() => {
        if (!isSaving)
            return;

        setItemInEdit({ ...itemInEdit, ...profileData });
    }, [isSaving]);

    const handleTextChanged = ({ target: { name, value } }) => {
        setProfileData({ ...profileData, [name]: value });
    }

    const ratingChanged = (ratingChanged) => {
        setProfileData({ ...profileData, performanceRating: ratingChanged });
    }

    const handleDateChanged = (value, name) => {
        setProfileData({
            ...profileData,
            [name]: !value ? null : formatDateZeroTime(value) //moment(value).format("YYYY-MM-DDT00:00:00") 
        });
    }


    // BEGIN Clients functions --------------------------------------------------------------------------------------------------------------------------------------
    const onClientsFilterChangeMultiselect = (value) => {
        getClients(value);
    }
    
    const getClients = React.useCallback(debounce((filter='') => {
        // if (!filter || filter.length === 0) return;
        setLoadingClient(true);
        api.get(`/client/search?filter=${filter}`)
            .then(({ data }) => {
                // setPositions([...data.map(d => ({...d, combinedName: `${d.hrispositionName} (${d.position.lookupValue})`}))]);
                setClientList(data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoadingClient(false));
    }, 200), [clientList]);

    const onClientInclusionChangeMultiselect = useCallback((values) => {
        var newList = values.map((v, i, a) => ({ ...v, candidateId: itemInEdit.candidateId }));
        setProfileData({ ...profileData, candidateClientInclusions: newList });
    }, [profileData]);
    const onClientInclusionRemoveMultiselect = (newList) => {
        onClientInclusionChangeMultiselect([...newList]);
        getClients('');
    }

    const onClientExclusionChangeMultiselect = useCallback((values) => {
        var newList = values.map((v, i, a) => ({ ...v, candidateId: itemInEdit.candidateId }));
        setProfileData({ ...profileData, candidateClientExclusions: newList });
    }, [profileData]);
    const onClientExclusionRemoveMultiselect = (newList) => {
        onClientExclusionChangeMultiselect([...newList]);
        getClients('');
    }

    useEffect(() => {
        getClients('');
        getAlternativeLocation();
    }, []);

    //useEffect(() => {
    //    console.log('selectedLoc', selectedLoc);
    //    var locIdList = selectedLoc.map((v) => v.locationId);
    //    setSelectedLocIds(locIdList);
    //}, [selectedLoc]);
    // END Clients functions --------------------------------------------------------------------------------------------------------------------------------------




    const getAlternativeLocation = React.useCallback((filter = '') => {

        api.get(`/alternativelocation/search?filter=${filter}`)
            .then(({ data }) => {
                // setPositions([...data.map(d => ({...d, combinedName: `${d.hrispositionName} (${d.position.lookupValue})`}))]);
                setAlternativeLocationList(data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => { });
    }, [alternativeLocationList]);

    // useEffect(() => {
    //     console.log('profileData.alternativeLocation', profileData.alternativeLocation);
    // }, [profileData.alternativeLocation]);

    // useEffect(() => {
    //     console.log('profileData.candidateClientInclusions', profileData.candidateClientInclusions);
    // }, [profileData.candidateClientInclusions]);

    // useEffect(() => {
    //     console.log('profileData.candidateClientExclusions', profileData.candidateClientExclusions);
    // }, [profileData.candidateClientExclusions]);

    useEffect(() => {
        if (!candidatePhoneInEdit?.isRemoval)
            return;

        const {candidatePhones} = itemInEdit;
        const newPhoneNumbers = JSON.parse(JSON.stringify(candidatePhones));
        const deleted = newPhoneNumbers.filter(p => p.uid === candidatePhoneInEdit.uid)[0];

        if (!deleted)
            return;

        newPhoneNumbers.splice(newPhoneNumbers.indexOf(deleted), 1);
        const newRemovedCandidatePhones = [...itemInEdit?.removedCandidatePhones];

        if (deleted.candidatePhoneId)
            newRemovedCandidatePhones.push(deleted);

        setItemInEdit({...itemInEdit, candidatePhones: newPhoneNumbers, removedCandidatePhones: newRemovedCandidatePhones});
    }, [candidatePhoneInEdit]);

    const phoneNumberRenderer = useMemo(() => {
        if (!itemInEdit?.candidatePhones)
            return null;

        const {candidatePhones} = itemInEdit;

        if (!candidatePhones?.length)
            return null;

        return (
            <ListBox
                data={candidatePhones ?? []}
                item={(props) => PhoneNumberRenderer({
                    ...props,
                    phoneTypes,
                    onDataChanged: (val, isRemoval = false) => {
                        if (isRemoval)
                        {
                            setCandidatePhoneInEdit({...val, isRemoval});
                            return;
                        }

                        const updated = candidatePhones.filter(p => p.uid === val.uid)[0];
                
                        if (!updated)
                        {
                            setItemInEdit({...itemInEdit, candidatePhones: candidatePhones});
                            return;
                        }
                        
                        candidatePhones.splice(candidatePhones.indexOf(updated), 1, {...val});
                        setItemInEdit({...itemInEdit, candidatePhones: candidatePhones});
                    },
                    onSendSms: (p) => {
                        setSmsFromContact([{...profileData, mobilePhoneNumber: p.phoneNumber}]);
                    },
                })}
                style={{
                    width: "100%",
                    height: "22vh"
                }}
                className="border-0"
            />
        );
    }, [itemInEdit?.candidatePhones, profileData, phoneTypes]);

    return (
        <>
            <Row>
                <Col lg={7} xs={12} className="border-right border-primary">
                    <Row>
                        <Col lg={4} xs={12}>
                            <PhotoDropzone
                                photo={profileData?.photoFileType ? { fileName: profileData?.photoFileName, fileType: profileData?.photoFileType, fileContent: profileData?.photoFileContent } : null}
                                setBinaryStr={(filename, str) => {
                                    if (!str) {
                                        setProfileData({ ...profileData, photoFileName: null, photoFileType: null, photoFileContent: null });
                                        return;
                                    }

                                    const splitted = str.split(",");
                                    const mimeType = splitted[0].split(";")[0].split(":")[1];
                                    const base64Str = splitted[1];
                                    setProfileData({ ...profileData, photoFileName: filename, photoFileType: mimeType, photoFileContent: base64Str });
                                }}
                                accept="image/jpg,image/jpeg,image/png"
                            />
                        </Col>
                        <Col lg={8} xs={12}>
                            <Row>
                                <Col lg={12} xs={12} className="font-weight-bold w-100 border-bottom border-primary my-2">
                                    Profile
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Label>
                                            First Name
                                        </Label>
                                        <Input
                                            name="firstName"
                                            id="firstName"
                                            type="text"
                                            required
                                            value={profileData.firstName ?? ""}
                                            onChange={handleTextChanged}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Label>
                                            Middle Name
                                        </Label>
                                        <Input
                                            name="middleName"
                                            id="middleName"
                                            type="text"
                                            required
                                            value={profileData.middleName ?? ""}
                                            onChange={handleTextChanged}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Label>
                                            Surname
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            name="surname"
                                            id="surname"
                                            type="text"
                                            required
                                            value={profileData.surname ?? ""}
                                            onChange={handleTextChanged}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Label>
                                            Preferred Name
                                        </Label>
                                        <Input
                                            name="preferredName"
                                            id="preferredName"
                                            type="text"
                                            required
                                            value={profileData.preferredName ?? ""}
                                            onChange={handleTextChanged}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Label>
                                            Salutation
                                        </Label>
                                        <DropdownBox
                                            data={props.lookups.salutations}
                                            selectedItem={profileData.salutation || {}}
                                            onChange={(item) => {
                                                setProfileData({ ...profileData, salutation: item, salutationId: item ? item.lookupId : null });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} xs={12}>
                            <Row>
                                <Col lg={12}>
                                    <FormGroup>
                                        <Label>
                                            Universal Student ID
                                        </Label>
                                        <Input
                                            name="universalStudentID"
                                            id="universalStudentID"
                                            type="text"
                                            value={profileData.universalStudentID ?? ""}
                                            onChange={handleTextChanged}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={12}>
                                    <FormGroup>
                                        <Label>
                                            Natural Position
                                        </Label>

                                        <ComboBox
                                            minLength={0}
                                            endpoint="/position/search"
                                            isLookup={false}
                                            idField="positionId"
                                            valueField="positionName"
                                            selectedItem={profileData.naturalPosition}
                                            onChange={(selectedItem) => setProfileData({
                                                ...profileData,
                                                naturalPosition: selectedItem,
                                                naturalPositionId: selectedItem?.positionId
                                            })}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={8} xs={12}>
                            <Row>
                                <Col lg={6} xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Gender
                                        </Label>
                                        <DropdownBox
                                            data={props.lookups.genders}
                                            selectedItem={profileData.gender || {}}
                                            onChange={(item) => {
                                                setProfileData({ ...profileData, gender: item, genderId: item ? item.lookupId : null });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={6} xs={12}>
                                    <FormGroup>
                                        <Label>
                                            DOB
                                        </Label>
                                        <DatePicker
                                            name="doB"
                                            id="doB"
                                            type="text"
                                            required
                                            value={formatDate(profileData.doB)}
                                            onChange={handleDateChanged}
                                            closeOnSelect
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={4} className="h-100">
                                    <FormGroup className="">
                                        <Label for="atsi">
                                            ATSI
                                        </Label>
                                        <label className="custom-toggle custom-toggle-primary d-block">
                                            <input type="checkbox"
                                                name="atsi"
                                                id="atsi"
                                                checked={profileData.atsi || false}
                                                onChange={({ target: { checked } }) => setProfileData({ ...profileData, atsi: checked })}
                                            />
                                            <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                                        </label>
                                    </FormGroup>
                                </Col>
                                <Col xs={4} className="h-100">
                                    <FormGroup className="">
                                        <Label for="disability">
                                            Disability
                                        </Label>
                                        <label className="custom-toggle custom-toggle-primary d-block">
                                            <input type="checkbox"
                                                name="disability"
                                                id="disability"
                                                checked={profileData.disability || false}
                                                onChange={({ target: { checked } }) => setProfileData({ ...profileData, disability: checked })}
                                            />
                                            <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                                        </label>
                                    </FormGroup>
                                </Col>
                                <Col xs={4} className="h-100">
                                    <FormGroup className="">
                                        <Label for="cald">
                                            CALD
                                        </Label>
                                        <label className="custom-toggle custom-toggle-primary d-block">
                                            <input type="checkbox"
                                                name="cald"
                                                id="cald"
                                                checked={profileData.cald || false}
                                                onChange={({ target: { checked } }) => setProfileData({ ...profileData, cald: checked })}
                                            />
                                            <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                                        </label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} xs={12} className="font-weight-bold w-100 border-bottom border-primary my-2">
                            Contact
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} xs={12}>
                            <FormGroup>
                                <Label>
                                    Mobile Number
                                </Label>
                                <InputGroup>
                                    <InputMask
                                        name="mobilePhoneNumber"
                                        id="mobilePhoneNumber"
                                        type="tel"
                                        required
                                        value={profileData.mobilePhoneNumber ?? ""}
                                        onChange={handleTextChanged}
                                        className="form-control"
                                        mask={process.env.REACT_APP_MOBILE_FORMAT}
                                        alwaysShowMask={false}
                                        formatChars={{ '0': '[0-9X]' }}
                                        onFocus={(event) => event.currentTarget.select()}
                                    />
                                    <div className="input-group-append">
                                        <TooltipButton
                                            color="default"
                                            disabled={!profileData?.mobilePhoneNumber || profileData.mobilePhoneNumber === ""}
                                            onClick={() => setSmsFromContact([profileData])}
                                            id="send-sms"
                                            title="Send SMS"
                                        >
                                            <i className="fas fa-paper-plane" />
                                        </TooltipButton>
                                    </div>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg={6} xs={12}>
                            <FormGroup>
                                <Label>
                                    Email
                                </Label>
                                <InputGroup>
                                    <Input
                                        name="email"
                                        id="email"
                                        type="email"
                                        required
                                        //readOnly
                                        value={profileData.email ?? ""}
                                        onChange={handleTextChanged}
                                    />
                                    <div className="input-group-append">
                                        <TooltipButton
                                            color="default"
                                            disabled={!profileData?.email || profileData.email === ""}
                                            onClick={() => setEmailFromContact([profileData])}
                                            id="send-email"
                                            title="Send Email"
                                        >
                                            <i className="fas fa-paper-plane" />
                                        </TooltipButton>
                                    </div>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg={6} xs={12}>
                            <Row>
                                <Col lg={12} className="font-weight-bold">
                                    <Row className="w-100 border-bottom border-primary my-2 mx-0">
                                        Other Phone Numbers
                                    </Row>
                                </Col>
                                <Col lg={12}>
                                    <TooltipButton
                                        id="addnew"
                                        title="Add New"
                                        className="btn-icon mb-2"
                                        color="default"
                                        size="sm"
                                        type="button"
                                        onClick={() => {
                                            const newNumbers = [...(itemInEdit?.candidatePhones ?? []), {
                                                candidatePhoneId: 0,
                                                phoneType: null,
                                                phoneTypeId: null,
                                                phoneNumber: null,
                                                uid: v4(),
                                            }];
                                            setItemInEdit({...itemInEdit, candidatePhones: [...newNumbers]});
                                        }}
                                    >
                                        <i className="fas fa-plus pt-1"></i>
                                    </TooltipButton>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} className="font-size-small-custom">
                                {
                                    phoneNumberRenderer
                                }
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} xs={12}>
                            <Row>
                                <Col lg={6} xs={12}>
                                    <FormGroup className="">
                                        <Label for="receivingCommunication">
                                            Receive Communication
                                        </Label>
                                        <label className="custom-toggle custom-toggle-primary d-block">
                                            <input type="checkbox"
                                                name="receivingCommunication"
                                                id="receivingCommunication"
                                                checked={profileData.receivingCommunication || false}
                                                onChange={({ target: { checked } }) => {
                                                    setProfileData({
                                                        ...profileData,
                                                        receivingCommunication: checked,
                                                        receivingEmail: !checked ? false : profileData.receivingEmail,
                                                        receivingSms: !checked ? false : profileData.receivingSms,
                                                        preferredCommunication: !checked ? null : profileData.preferredCommunication,
                                                        preferredCommunicationId: !checked ? null : profileData.preferredCommunicationId
                                                    });

                                                }}
                                            />
                                            <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                                        </label>
                                    </FormGroup>
                                </Col>
                                <Col lg={6} xs={12}>
                                    <FormGroup className="">
                                        <Label for="receivingEmail">
                                            Receive Email
                                        </Label>
                                        <label className="custom-toggle custom-toggle-primary d-block">
                                            <input type="checkbox"
                                                name="receivingEmail"
                                                id="receivingEmail"
                                                checked={profileData.receivingEmail || false}
                                                disabled={!profileData.receivingCommunication}
                                                onChange={({ target: { checked } }) => setProfileData({ ...profileData, receivingEmail: checked })}
                                            />
                                            <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                                        </label>
                                    </FormGroup>
                                </Col>
                                <Col lg={6} xs={12}>
                                    <FormGroup className="">
                                        <Label for="receivingPhoneCall">
                                            Receive Phone Call
                                        </Label>
                                        <label className="custom-toggle custom-toggle-primary d-block">
                                            <input type="checkbox"
                                                name="receivingPhoneCall"
                                                id="receivingPhoneCall"
                                                checked={profileData.receivingPhoneCall || false}
                                                disabled={!profileData.receivingCommunication}
                                                onChange={({ target: { checked } }) => setProfileData({ ...profileData, receivingPhoneCall: checked })}
                                            />
                                            <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                                        </label>
                                    </FormGroup>
                                </Col>
                                <Col lg={6} xs={12}>
                                    <FormGroup className="">
                                        <Label for="receivingSms">
                                            Receive SMS
                                        </Label>
                                        <label className="custom-toggle custom-toggle-primary d-block">
                                            <input type="checkbox"
                                                name="receivingSms"
                                                id="receivingSms"
                                                checked={profileData.receivingSms || false}
                                                disabled={!profileData.receivingCommunication}
                                                onChange={({ target: { checked } }) => setProfileData({ ...profileData, receivingSms: checked })}
                                            />
                                            <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                                        </label>
                                    </FormGroup>
                                </Col>
                                <Col lg={12}>
                                    <FormGroup>
                                        <Label>
                                            Preffered Communication Type
                                        </Label>
                                        <DropdownBox
                                            data={communicationTypes}
                                            selectedItem={profileData.preferredCommunication || {}}
                                            disabled={!profileData.receivingCommunication}
                                            onChange={(item) => {
                                                setProfileData({ ...profileData, preferredCommunication: item, preferredCommunicationId: item ? item.lookupId : null });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} className="font-weight-bold w-100 border-bottom border-primary my-2">
                            Residency
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} xs={12}>
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Residency Status
                                        </Label>
                                        <DropdownBox
                                            data={props.lookups.residencyStatuses}
                                            selectedItem={profileData.residencyStatus || {}}
                                            onChange={(item) => {
                                                setProfileData({ ...profileData, residencyStatus: item, residencyStatusId: item ? item.lookupId : null });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Nationality
                                        </Label>
                                        <Input
                                            name="nationality"
                                            id="nationality"
                                            type="text"
                                            required
                                            value={profileData.nationality ?? ""}
                                            onChange={handleTextChanged}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={8} xs={12}>
                            <Row>
                                <Col lg={6} xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Visa Number
                                        </Label>
                                        <Input
                                            name="visaNumber"
                                            id="visaNumber"
                                            type="text"
                                            required
                                            value={profileData.visaNumber ?? ""}
                                            onChange={handleTextChanged}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={6} xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Visa Expiry
                                        </Label>
                                        <DatePicker
                                            name="visaExpiryDate"
                                            id="visaExpiryDate"
                                            type="text"
                                            required
                                            value={formatDate(profileData.visaExpiryDate)}
                                            onChange={handleDateChanged}
                                            closeOnSelect
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} xs={12} className="font-weight-bold w-100 border-bottom border-primary my-2">
                            Preference
                        </Col>
                    </Row>
                    <Row>

                        <Col xs={6}>
                            <FormGroup>
                                <Label className={"stars-label"}>
                                    Performance Rating
                                </Label>
                                <ReactStars
                                    className={"stars-on-profile"}
                                    count={10}
                                    half={false}
                                    size={29}
                                    onChange={ratingChanged}
                                    value={profileData.performanceRating}
                                    color2={'#ffd700'} />
                                <span class={`fa fa-times-circle ${(profileData.performanceRating == null ? "d-none" : "stars-delete")}`} aria-hidden="true" onClick={() => { ratingChanged(null); }}></span>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Alternative Location
                                </Label>
                                <DropdownBox
                                    data={alternativeLocationList}
                                    selectedItem={profileData.alternativeLocation}
                                    idField="alternativeLocationId"
                                    valueField="alternativeLocationName"
                                    onChange={(item) => {
                                        setProfileData({ ...profileData, alternativeLocation: item, alternativeLocationId: item ? item.alternativeLocationId : null });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Client Exclusion Preference
                                </Label>

                                <Multiselect
                                    className="slim-search"
                                    options={clientList}
                                    loading={loadingClient}
                                    displayValue="clientName"
                                    placeholder="Type to search"
                                    selectedValues={profileData.candidateClientExclusions}
                                    onSearch={onClientsFilterChangeMultiselect}
                                    onSelect={(selected) => {
                                        onClientExclusionChangeMultiselect(selected);
                                    }}
                                    onRemove={onClientExclusionRemoveMultiselect}
                                    showCheckbox={true}
                                    showArrow={true}
                                    closeOnSelect={true}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Client Inclusion Preference
                                </Label>

                                <Multiselect
                                    className="slim-search"
                                    options={clientList}
                                    loading={loadingClient}
                                    displayValue="clientName"
                                    placeholder="Type to search"
                                    selectedValues={profileData.candidateClientInclusions}
                                    onSearch={onClientsFilterChangeMultiselect}
                                    onSelect={(selected) => {
                                        onClientInclusionChangeMultiselect(selected);
                                    }}
                                    onRemove={onClientInclusionRemoveMultiselect}
                                    showCheckbox={true}
                                    showArrow={true}
                                    closeOnSelect={true}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col lg={5} xs={12}>
                    <Row>
                        <Col lg={6} xs={12}>
                            <FormGroup>
                                <Label>
                                    Candidate Type
                                </Label>
                                <DropdownBox
                                    data={props.lookups.candidateTypes}
                                    selectedItem={profileData.candidateType}
                                    onChange={(item) => {
                                        setProfileData({ ...profileData, candidateType: item, candidateTypeId: item ? item.lookupId : null });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={6} xs={12}>
                            <FormGroup>
                                <Label>
                                    Candidate Status
                                </Label>
                                <DropdownBox
                                    data={props.lookups.candidateStatuses}
                                    selectedItem={profileData.candidateStatus}
                                    onChange={(item) => {
                                        setProfileData({ ...profileData, candidateStatus: item, candidateStatusId: item ? item.lookupId : null });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={6} xs={12}>
                            <FormGroup>
                                <Label>
                                    Agency
                                </Label>
                                <DropdownBox
                                    data={props.lookups.agencies}
                                    selectedItem={profileData.agency}
                                    onChange={(item) => {
                                        setProfileData({ ...profileData, agency: item, agencyId: item ? item.lookupId : null });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={6} xs={12}>
                            <FormGroup>
                                <Label>
                                    Contracting Company
                                </Label>
                                <DropdownBox
                                    data={props.lookups.contractingCompanies}
                                    selectedItem={profileData.contractingCompany}
                                    idField="contractingCompanyId"
                                    valueField="contractingCompanyName"
                                    onChange={(item) => {
                                        setProfileData({ ...profileData, contractingCompany: item, contractingCompanyId: item ? item.contractingCompanyId : null });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={12}>
                            <FormGroup>
                                <Label>
                                    Default Project
                                </Label>
                                <ComboBox
                                    endpoint="/project/search"
                                    isLookup={false}
                                    idField="projectId"
                                    valueField="projectName"
                                    selectedItem={profileData.defaultProject}
                                    onChange={(item) => {
                                        setProfileData({ ...profileData, defaultProject: item, defaultProjectId: item ? item.projectId : null });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={12} xs={12}>
                            <FormGroup>
                                <Label>
                                    External Reference No
                                </Label>
                                <Input
                                    name="fasttrackCandidateNo"
                                    id="fasttrackCandidateNo"
                                    type="number"
                                    className="hide-arrow"
                                    onInput={(e) => e.target.value = e.target.value.slice(0, 9)}
                                    value={profileData.fasttrackCandidateNo ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} className="font-weight-bold w-100 border-bottom border-primary my-2">
                            Main Address
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Main Address Line 1
                                </Label>
                                <Input
                                    name="mainAddressLine1"
                                    id="mainAddressLine1"
                                    type="text"
                                    value={profileData.mainAddressLine1 ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Main Address Line 2
                                </Label>
                                <Input
                                    name="mainAddressLine2"
                                    id="mainAddressLine2"
                                    type="text"
                                    value={profileData.mainAddressLine2 ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={6} xs={12}>
                            <FormGroup>
                                <Label>
                                    Country
                                </Label>
                                <DropdownBox
                                    data={props.lookups.countries}
                                    selectedItem={profileData.mainAddressCountry || {}}
                                    onChange={(item) => {
                                        setProfileData({ ...profileData, mainAddressCountry: item, mainAddressCountryId: item ? item.lookupId : null });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={6} xs={12}>
                            <FormGroup>
                                <Label>
                                    State
                                </Label>
                                <DropdownBox
                                    data={props.lookups.states}
                                    selectedItem={profileData.mainAddressState || {}}
                                    onChange={(item) => {
                                        setProfileData({ ...profileData, mainAddressState: item, mainAddressStateId: item ? item.lookupId : null });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={6} xs={12}>
                            <FormGroup>
                                <Label>
                                    City
                                </Label>
                                <Input
                                    name="mainAddressCity"
                                    id="mainAddressCity"
                                    type="text"
                                    value={profileData.mainAddressCity ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={6} xs={12}>
                            <FormGroup>
                                <Label>
                                    Postcode
                                </Label>
                                <Input
                                    name="mainAddressPostcode"
                                    id="mainAddressPostcode"
                                    type="text"
                                    value={profileData.mainAddressPostcode ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup className="d-flex flex-wrap align-items-center">
                                <Label for="mailingAddressSameAsMain" className="mr-3 my-0">
                                    Mailing address same as Main
                                </Label>
                                <label className="custom-toggle custom-toggle-primary">
                                    <input type="checkbox"
                                        name="mailingAddressSameAsMain"
                                        id="mailingAddressSameAsMain"
                                        checked={profileData.mailingAddressSameAsMain || false}
                                        onChange={({ target: { checked } }) => setProfileData({ ...profileData, mailingAddressSameAsMain: checked })}
                                    />
                                    <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                                </label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} className="font-weight-bold w-100 border-bottom border-primary my-2">
                            Mailing Address
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Mailing Address Line 1
                                </Label>
                                <Input
                                    name="mailAddressLine1"
                                    id="mailAddressLine1"
                                    type="text"
                                    value={profileData.mailAddressLine1 ?? ""}
                                    disabled={profileData.mailingAddressSameAsMain}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Mailing Address Line 2
                                </Label>
                                <Input
                                    name="mailAddressLine2"
                                    id="mailAddressLine2"
                                    type="text"
                                    value={profileData.mailAddressLine2 ?? ""}
                                    disabled={profileData.mailingAddressSameAsMain}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={6} xs={12}>
                            <FormGroup>
                                <Label>
                                    Country
                                </Label>
                                <DropdownBox
                                    data={props.lookups.countries}
                                    selectedItem={profileData.mailAddressCountry || {}}
                                    disabled={profileData.mailingAddressSameAsMain}
                                    onChange={(item) => {
                                        setProfileData({ ...profileData, mailAddressCountry: item, mailAddressCountryId: item ? item.lookupId : null });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={6} xs={12}>
                            <FormGroup>
                                <Label>
                                    State
                                </Label>
                                <DropdownBox
                                    data={props.lookups.states}
                                    selectedItem={profileData.mailAddressState}
                                    disabled={profileData.mailingAddressSameAsMain}
                                    onChange={(item) => {
                                        setProfileData({ ...profileData, mailAddressState: item, mailAddressStateId: item ? item.lookupId : null });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={6} xs={12}>
                            <FormGroup>
                                <Label>
                                    City
                                </Label>
                                <Input
                                    name="mailAddressCity"
                                    id="mailAddressCity"
                                    type="text"
                                    value={profileData.mailAddressCity ?? ""}
                                    disabled={profileData.mailingAddressSameAsMain}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={6} xs={12}>
                            <FormGroup>
                                <Label>
                                    Postcode
                                </Label>
                                <Input
                                    name="mailAddressPostcode"
                                    id="mailAddressPostcode"
                                    type="text"
                                    value={profileData.mailAddressPostcode ?? ""}
                                    disabled={profileData.mailingAddressSameAsMain}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} className="font-weight-bold w-100 border-bottom border-primary my-2">
                            Emergency Contact
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Emergency Contact Name
                                </Label>
                                <Input
                                    name="emergencyContactName"
                                    id="emergencyContactName"
                                    type="text"
                                    required
                                    value={profileData.emergencyContactName ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={6} xs={12}>
                            <FormGroup>
                                <Label>
                                    Emergency Contact Phone
                                </Label>
                                <Input
                                    name="emergencyContactPhone"
                                    id="emergencyContactPhone"
                                    type="text"
                                    required
                                    value={profileData.emergencyContactPhone ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={6} xs={12}>
                            <FormGroup>
                                <Label>
                                    Relationship
                                </Label>
                                <DropdownBox
                                    data={props.lookups.relationshipTypes}
                                    selectedItem={profileData.relationshipToCandidate || {}}
                                    onChange={(item) => {
                                        setProfileData({ ...profileData, relationshipToCandidate: item, relationshipToCandidateId: item ? item.lookupId : null });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="d-flex ml-3 mt-3 font-size-sm">
                <div>
                    Last Modified By {profileData.modifiedBy} on {new Date(profileData.modifiedDate).toLocaleDateString("en-au", {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                    })}
                </div>
            </div>
        </>
    )
}