/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-loop-func */
import { ScheduleTypeCodes, MoveDirection } from "./enums";
import api from "../../../../services/api";
import _ from "lodash";
import moment from "moment";
import util from "./util"
import { formatDate, Mode, ViewAccess } from "../../../../utils";
import qs from 'query-string'

const ContextMenuHelper = ( function () {

    let _gridApi, 
        _setLoading, 
        _column, 
        _columnApi, 
        _context, 
        _defaultItems,
        _node, 
        _value,
        _dispatchUnit,
        _startDate,
        _endDate,
        _trimStartDate,
        _trimEndDate,
        _project,
        _setStepUpInEdit,
        _setCandidateContractInEdit,
        _setRosterAttributeInEdit,
        _setCandidateInEdit,
        _setCandidateMessageList,
        _setCandidateConfirmationList,
        _setCandidateBulkAvailabilityList,
        _setCandidateBulkLogisticList,
        _setCandidateDASList,
        _setCandidateBulkDASList,
        _setDeletedData,
        _setLogisticInEdit,
        _staticColumnDefs,
        _loadSchedule,
        _setContingencyOpen,
        _setErrorMessage,
        _setErrorTitle,
        _setTrainingData,
        _setCandidateManualConfirmationList,
        _setRosterPositionAlignmentConfirmation,
        _setShowPrepopulateWorkSequence,
        _lookups,
        _setShowMessageHistory,
        _setShowAuditHistory,
        _setShowTalentsSearch,
        _setShowDeleteConfirmation,
        _setDisableTrimStartDateDefault,
        _setDisableTrimEndDateDefault,
        _viewAccess,
        _setSlotCountObj,
        _refreshDataExistence
    ;

    const _getOrderedRowIndexes = (ranges) => {
        return [...new Set(ranges
            .map(range => {
                const { start, end } = util.getStartEndIndexFromRange(range, 1);
                return _.range(start, end);
            })
            .flat()
            .sort((a, b) => a - b))]
    }

    const _getdRowIndexes = (ranges) => {
        return [...new Set(ranges
            .map(range => {
                const { start, end } = util.getStartEndIndexFromRange(range, 1);
                return _.range(start, end);
            })
            .flat())]
    }

    const _clearCellRanges = (ranges, gridApi = null, staticColumnDefs = null, setShowDeleteConfirmation = null) => {
        // console.log('_clearCellRanges',gridApi, _gridApi)
        _gridApi = _gridApi || gridApi;
        _staticColumnDefs = _staticColumnDefs || staticColumnDefs;

        const data = [];
        const payload = [];
        const trainings = [];
        let trainingsIncluded = false;

        ranges.forEach(range => {
            const { start, end } = util.getStartEndIndexFromRange(range);
            for (let i = start; i <= end; i++) {
                const rowNode = _gridApi.getDisplayedRowAtIndex(i); //
                const newData = { ...rowNode.data }; // data
                
                range.columns.forEach(column => {
                    if (_staticColumnDefs[0].children.find(f => f.field === column.colId)) {
                        return;
                    }
                    const [ , date ] = column.colDef.field.split('.');

                    const payloadItem = util.getPayloadForRosterScheduleUpdate('', newData, date);
                    payload.push(payloadItem);

                    if (payloadItem.create.filter(p => p.rosterScheduleTypeCode === "T").length || payloadItem.remove.filter(p => p.rosterScheduleTypeCode === "T").length)
                    {
                        trainingsIncluded = true;
                        const trainingItem = trainings.filter(t => t.rosterCandidateId === payloadItem.rosterCandidateId)[0];

                        if (trainingItem)
                            trainingItem.dates.push(payloadItem.date);
                        else
                            trainings.push({candidateId: payloadItem.candidateId, rosterCandidateId: payloadItem.rosterCandidateId, dates: [payloadItem.date]});
                    }
                    delete newData.dates[date];
                });

                data.push(newData);
            }
        });

        const action = (callback) => {
            // console.log({ data, payload, trainings });
            _gridApi.showLoadingOverlay();
            const apiCalls = [
                api.post(`/rostergrid/rostercandidateschedule`, payload),
                api.post(`/rostergrid/trainings/remove`, trainings)
            ];
            Promise.all(apiCalls)
                .then((responses) => {
                    // console.log('rostercandidateschedule: ', { data, responses });

                    if (trainingsIncluded && callback)
                    {
                        setShowDeleteConfirmation(null);
                        _gridApi.hideOverlay();
    
                        if (callback)
                            callback();
                        
                        return;
                    }
            
                    if (responses[0].data.created && responses[0].data.created.length > 0) {
                        data.forEach(row => {
                            responses[0].data.created.filter(f => f.rosterCandidateId === row.rosterCandidateId).forEach(item => {
                                row.dates[item.column].schedules[item.rosterScheduleTypeCode] = item.rosterCandidateScheduleId
                            })
                        })
                    }
                    if (responses[0].data.removed && responses[0].data.removed.length > 0) {
                        data.forEach(row => {
                            responses[0].data.removed.filter(f => f.rosterCandidateId === row.rosterCandidateId).forEach(item => {
                                delete row.dates[item.column].schedules[item.rosterScheduleTypeCode]
                            })
                        })
                    }
    
                    const tx = {
                        update: data,
                    };
    
                    _gridApi.applyTransaction(tx);
                    _gridApi.hideOverlay();
                })
                .catch((error) => {
                    // TODO: Display Error message in UI
                    console.log({error});
                    _gridApi.hideOverlay();
                })
            ;
        };

        if (trainingsIncluded)
        {
            _gridApi.showLoadingOverlay();
            const checkCandidates = trainings.filter(t => t.candidateId).map(t => t.candidateId);
            // console.log({checkCandidates, trainings});
            api.post(`/candidatetraining/bytalents`, JSON.stringify(checkCandidates))
                .then((response) => {
                    const {data: trainingData} = response;
                    // console.log({trainingData});
                    _gridApi.hideOverlay();
                    setShowDeleteConfirmation({
                        message: "Talent trainings data will also be removed. Continue?",
                        details: trainingData.map(t => `${t.candidate.candidateName} - ${t.trainingDetails}`),
                        action,
                    });
                })
                .catch((error) => {
                    console.log({error});
                    _gridApi.hideOverlay();
                });
            return;
        }

        action();
    }

    const _copyAction = (slotCount = "1") => {
        _gridApi.showLoadingOverlay();
        const ranges = _gridApi.getCellRanges();
        const orderedItemsToCopy = _getOrderedRowIndexes(ranges)

        //console.log('_copyAction', { ranges, orderedItemsToCopy, _dispatchUnit, _endDate, _startDate });

        const create = [];

        orderedItemsToCopy.forEach((value, index, array) => {
            const rowNode = _gridApi.getDisplayedRowAtIndex(value);
            // console.log('rowNode', rowNode)
            create.push(rowNode.data.rosterCandidateId);
        });

        const dataToSend = {
            rosterCandidateId: create,
            dispatchUnitId: _dispatchUnit.lookupId,
            startDate: _trimStartDate ? null : formatDate(_startDate, 'YYYY-MM-DD'),
            endDate: _trimEndDate ? null : formatDate(_endDate, 'YYYY-MM-DD'),
        };

        const action = (gridApi, slotNumber) => {
            api.post(`/rostergrid/rostercandidatecopyslot`, {...dataToSend, slotCount: parseInt(slotNumber)})
                .then((response) => {            
                    // console.log('response', response)
                    var update = [];
                    if (response.data.updated && response.data.updated.length > 0) {
                        response.data.updated.forEach(d => {
                            var updateNode = gridApi.getRowNode(d.rosterCandidateId);
                            
                            // console.log('updateNode', updateNode)
                            update.push({
                                ...updateNode.data,
                                slot: d.slot,
                            })
                        })
                    }
                    const tx = {
                        add: response.data.created,
                        update
                    };
                    gridApi.applyTransactionAsync(tx, (result) => {
                        gridApi.clearRangeSelection();
                        gridApi.refreshCells({force: true, rowNodes: result.update});
                        gridApi.hideOverlay();
                    });
                })
                .catch((error) => {
                    // TODO: Display Error message in UI
                    console.log(error.response);
                    gridApi.hideOverlay();
                });
        }

        if (slotCount === "N")
        {
            _setSlotCountObj({
                ...dataToSend,
                mode: Mode.COPY,
                action
            });
            _gridApi.hideOverlay();
            return;
        }

        action(_gridApi, slotCount);
    }

    const _addAction = (slotCount = "1", insertByRanges = true) => {
        _gridApi.showLoadingOverlay();

        const totalRows = _gridApi.getModel().getRowCount();
        const ranges = _gridApi.getCellRanges();
        //const orderedItemsToCopy = _getOrderedRowIndexes(ranges);
        let orderedItemsToCopy = null;

        if (insertByRanges == false) 
            orderedItemsToCopy = totalRows == 0 ? [0] : [totalRows - 1];
        else
            orderedItemsToCopy = _getOrderedRowIndexes(ranges);
        
        //console.log('_gridApi', _gridApi);
        //console.log('totalRows', totalRows);
        //console.log('ranges', ranges);
        //console.log('orderedItemsToCopy', orderedItemsToCopy);

        //_gridApi.hideOverlay();
        //return;

        //console.log('_addAction', { ranges, orderedItemsToCopy, _dispatchUnit, _endDate, _startDate });

        const create = [];

        if (totalRows != 0) {
            orderedItemsToCopy.forEach((value, index, array) => {
                const rowNode = _gridApi.getDisplayedRowAtIndex(value);
                // console.log('rowNode', rowNode)
                create.push(rowNode.data.rosterCandidateId);
                console.log('rowNode', rowNode);
                console.log('rowNode.data', rowNode.data);
            });
        }

        console.log('create', create);
        //_gridApi.hideOverlay();

        const dataToSend = {
            rosterCandidateId: create,
            dispatchUnitId: _dispatchUnit.lookupId,
            startDate: formatDate(_startDate, 'YYYY-MM-DD'),
            endDate: formatDate(_endDate, 'YYYY-MM-DD'),
        };

        //return;

        var postUrl = ''; 
        if (totalRows == 0) 
            postUrl = `/rostergrid/rostercandidateaddslotplain`;
        else
            postUrl = `/rostergrid/rostercandidateaddslot`;


        const action = (gridApi, slotNumber) => {
            api.post(postUrl, {...dataToSend, slotCount: parseInt(slotNumber)})
                .then((response) => {            
                    console.log({response});
                    var update = [];
                    if (response.data.updated && response.data.updated.length > 0) {
                        response.data.updated.forEach(d => {
                            var updateNode = gridApi.getRowNode(d.rosterCandidateId);
                            
                            // console.log('updateNode', updateNode)
                            update.push({
                                ...updateNode.data,
                                slot: d.slot,
                            })
                        })
                    }
                    const tx = {
                        add: response.data.created,
                        update
                    };
                    gridApi.applyTransactionAsync(tx, (result) => {
                        gridApi.clearRangeSelection();
                        _refreshDataExistence();
                        gridApi.hideOverlay();
                    });
                })
                .catch((error) => {
                    // TODO: Display Error message in UI
                    console.log(error.response);
                    gridApi.hideOverlay();
                });
        }

        if (slotCount === "N")
        {
            _setSlotCountObj({
                ...dataToSend,
                mode: Mode.ADD,
                action
            });
            _gridApi.hideOverlay();
            return;
        }

        action(_gridApi, slotCount);
    }

    const _splitAction = () => {
        _gridApi.showLoadingOverlay();

        const ranges = _gridApi.getCellRanges();
        const orderedItemsToCopy = _getOrderedRowIndexes(ranges)

        //console.log('_splitAction', { ranges, orderedItemsToCopy, _dispatchUnit, _endDate, _startDate });

        let create = [];
        const updated = [];

        orderedItemsToCopy.forEach((value, index, array) => {
            const rowNode = _gridApi.getDisplayedRowAtIndex(value);

            create.push(rowNode.data.rosterCandidateId);
            updated.push(rowNode.data);
        });

        api.post(`/rostergrid/rostercandidatesplitslot`, {
                rosterCandidateId: create,
                dispatchUnitId: _dispatchUnit.lookupId,
                startDate: _trimStartDate ? null : formatDate(_startDate, 'YYYY-MM-DD'),
                endDate: _trimEndDate ? null : formatDate(_endDate, 'YYYY-MM-DD'),
            })
            .then((response) => {            
                // console.log({response});
                const tx = {
                    add: response.data.created,
                    update: updated.map(u => ({
                        ...u,
                        slot: response.data.updated.filter(d => d.rosterCandidateId === u.rosterCandidateId)[0].slot,
                        hasChildren: response.data.updated.filter(d => d.rosterCandidateId === u.rosterCandidateId)[0].hasChildren,
                    })),
                };
                _gridApi.applyTransactionAsync(tx, (result) => {
                    // console.log({result});
                    _gridApi.clearRangeSelection();
                    _gridApi.refreshCells({force: true, rowNodes: result.update});
                    _gridApi.hideOverlay();
                });
            })
            .catch((error) => {
                // TODO: Display Error message in UI
                console.log(error.response);
                _gridApi.hideOverlay();
            });
    }

    const _removeAction = () => {
        const ranges = _gridApi.getCellRanges();
        const itemIndexes = _getOrderedRowIndexes(ranges);

        const items = [];

        itemIndexes.forEach((value, index, array) => {
            const rowNode = _gridApi.getDisplayedRowAtIndex(value);
            // console.log('rowNode', rowNode)
            items.push(rowNode.data);
        });
        // console.log({data: _node.data, ranges, itemIndexes, items});
        _setDeletedData({ data: items, deleteAction: async (data) => {
            _gridApi.showLoadingOverlay();
            const ranges = _gridApi.getCellRanges();
            const orderedItemsToRemove = _getOrderedRowIndexes(ranges)
            const remove = [];
            const update = [];

            //console.log('deleteAction', {data, orderedItemsToRemove})

            orderedItemsToRemove.forEach((value, index, array) => {

                const rowNode = _gridApi.getDisplayedRowAtIndex(value);

                const [ left, right ] = rowNode.data.slot.toString().split('.');

                remove.push({ rosterCandidateId: rowNode.data.rosterCandidateId });

                // console.log(' left, right ', { left, right })
                if (right) {
                    let nextIndex = value + 1;
                    let splitSlot = _gridApi.getDisplayedRowAtIndex(nextIndex);

                    if (splitSlot)
                    {
                        let [ , isSlot ] = splitSlot?.data.slot.toString().split('.');

                        // console.log('1111:', { nextIndex, splitSlot, isSlot })
                        if (isSlot) {
                            while(isSlot) {
                                // console.log('2222:', { nextIndex, splitSlot, isSlot })

                                update.push({ ...splitSlot.data, slot: (splitSlot.data.slot - 0.1).toFixed(1) })
                                nextIndex++;
                                splitSlot = _gridApi.getDisplayedRowAtIndex(nextIndex);

                                if (!splitSlot)
                                {
                                    isSlot = false;
                                    continue;
                                }

                                if (splitSlot)
                                {
                                    isSlot = splitSlot.data.slot.toString().split('.')[1];
                                    // console.log('3333:', { nextIndex, splitSlot, isSlot })
                                }
                            }
                        }
                    }
                } else {
                    const next = array[index + 1] ?? _gridApi.getDisplayedRowCount();

                    // console.log('rowNode', {next, rowNode, value, index, array})
                    if (next > (value + 1)) {
                        for (let i = (value + 1); i < next; i++) {
                            const updateNode = _gridApi.getDisplayedRowAtIndex(i);
                            const [ slotLeft, slotRight ] = updateNode.data.slot.toString().split('.');
    
                            // console.log('updateNode', {data: updateNode.data, slotLeft, slotRight, i})
                            update.push({ ...updateNode.data, slot: parseFloat(`${parseInt(slotLeft) - (index + 1)}${slotRight ? `.${slotRight}` : ""}`) })
                        }
                    }
                }

            });

            const updateSlots = update.map(m => { 
                const [ slotLeft, slotRight ] = m.slot.toString().split('.');
                // console.log("map update slots", {slot: m.slot, slotLeft, slotRight});
                return {
                    rosterCandidateId: m.rosterCandidateId, 
                    slot: slotRight ? slotRight : slotLeft 
                }
            });

            // console.log({update, updateSlots});

            return api.post(`/rostergrid/rostercandidateremoveslot`, {
                    rosterCandidateId: remove.map(m => m.rosterCandidateId),
                    updateSlots,
                })
                .then((response) => {            
                    // console.log('response', response);

                    const updated = [...update];

                    if (response.data.updated.length > 0)
                        response.data.updated.forEach(d => {
                            if (updated.filter(u => u.rosterCandidateId === d.rosterCandidateId).length > 0)
                                return;
                            
                            updated.push(d);
                        });
                    
                    const tx = {
                        remove,
                        update: updated,
                    };
                    _gridApi.applyTransactionAsync(tx, (result) => {
                        // console.log({result});
                        _gridApi.clearRangeSelection();
                        _refreshDataExistence();
                        _gridApi.refreshCells({ force: true, rowNodes: result.update });
                        _gridApi.hideOverlay();
                    });
                })
                .catch((error) => {
                    // TODO: Display Error message in UI
                    console.log(error.response);
                    _gridApi.hideOverlay();
                });
        }});

        // const ranges = _gridApi.getCellRanges();

        // const orderedItemsToRemove = _getOrderedRowIndexes(ranges)

        // if (window.confirm("Conflict window placeholder. Continue?")) {
        //     _setLoading(true);
        //     let remove = [];
        //     let update = [];

        //     orderedItemsToRemove.forEach((value, index, array) => {

        //         const rowNode = _gridApi.getDisplayedRowAtIndex(value);
        //         remove.push({ rosterCandidateId: rowNode.data.rosterCandidateId });

        //         const next = array[index + 1] ?? _gridApi.getDisplayedRowCount();

        //         if (next > (value + 1)) {
        //             for (let i = (value + 1); i < next; i++) {
        //                 const updateNode = _gridApi.getDisplayedRowAtIndex(i);
        //                 update.push({ ...updateNode.data, slot: updateNode.data.slot - (index + 1) })
        //             }
        //         }
        //     })
            

        //     api.post(`/rostergrid/rostercandidateremoveslot`, {
        //         rosterCandidateId: remove.map(m => m.rosterCandidateId),
        //         updateSlots: update.map(m => ({ rosterCandidateId: m.rosterCandidateId, slot: m.slot }))
        //     })
        //     .then((response) => {            
        //         console.log('response', response)
                
        //         const tx = {
        //             remove,
        //             update
        //         };
        //         _gridApi.applyTransactionAsync(tx, (result) => {
        //             _setLoading(false);
        //             _gridApi.clearRangeSelection();
        //         });
        //     })
        //     .catch((error) => {
        //         // TODO: Display Error message in UI
        //         console.log(error.response)
        //     })
        //     .finally(() => _gridApi.hideOverlay());

        // }
    }

    const _refreshSlotNumberAction = () => {
        const rowNode = _gridApi.getDisplayedRowAtIndex(0);

        if (!rowNode)
            return;
        
        _gridApi.showLoadingOverlay();
        const {data: {rosterId}} = rowNode;

        api.post(`rostergrid/slotnumber/refresh?rosterId=${rosterId}`)
            .then((response) => {
                const update = [];
                if (response.data?.length > 0) {
                    response.data.forEach(d => {
                        const updateNode = _gridApi.getRowNode(d.rosterCandidateId);
                        update.push({
                            ...updateNode.data,
                            slot: d.slot,
                        })
                    })
                }
                const tx = {
                    update
                };
                _gridApi.applyTransactionAsync(tx, (result) => {
                    _gridApi.clearRangeSelection();
                    _gridApi.refreshCells({ force: true, rowNodes: result.update });
                    _refreshDataExistence();
                    _gridApi.hideOverlay();
                });
            })
            .catch((error) => {
                console.log({error});
                _gridApi.hideOverlay();
            });
    }

    const _getScheduleValue = (nodeData, scheduleTypeCode, oldValue, switchShift) => {
        // console.log('_getScheduleValue', { nodeData, scheduleTypeCode, oldValue, switchShift })
        if (switchShift && oldValue.value.includes(ScheduleTypeCodes.Working)) {
            return oldValue.value.replace(ScheduleTypeCodes.Working, ScheduleTypeCodes.Night)
        } else if (switchShift && oldValue.value.includes(ScheduleTypeCodes.Night)) {
            return oldValue.value.replace(ScheduleTypeCodes.Night, ScheduleTypeCodes.Working);
        } else if (oldValue.value.includes(scheduleTypeCode)) {
            return oldValue.value.replace(scheduleTypeCode, "");
        }
        
        if (nodeData?.shiftName === "Night" && scheduleTypeCode === ScheduleTypeCodes.Working && oldValue.value.includes(scheduleTypeCode))
            return oldValue.value.replace(scheduleTypeCode, "");
        
        if (nodeData?.shiftName === "Night" && scheduleTypeCode === ScheduleTypeCodes.Working)
            return String.prototype.concat(...new Set((oldValue.value ?? '') + ScheduleTypeCodes.Night));
        
        return String.prototype.concat(...new Set((oldValue.value ?? '') + scheduleTypeCode));
    }

    const defaultDatesValue = { value: '', className: '', schedules: {} };

    const _scheduleAction = (scheduleTypeCode, switchShift = false) => {
        const ranges = _gridApi.getCellRanges();
        let data = [];
        let payload = [];
        ranges.forEach(range => {
            const { start, end } = util.getStartEndIndexFromRange(range);
            for (let i = start; i <= end; i++) {
                const rowNode = _gridApi.getDisplayedRowAtIndex(i); //
                const newData = { ...rowNode.data }; // data
                
                range.columns.forEach(column => {
                    //if (column.instanceId < process.env.REACT_APP_ROSTER_DATE_COLUMN_START_INDEX) {
                    if (_staticColumnDefs[0].children.find(f => f.field === column.colId)) {
                        return;
                    }
                    const [ , date ] = column.colDef.field.split('.');
                    //const oldValue =  newData;
                    const oldValue = newData.dates[date] ?? defaultDatesValue;
                    // console.log('_getScheduleValue', { newData, scheduleTypeCode, date, oldValue })
                    const value = _getScheduleValue(newData, scheduleTypeCode, oldValue, switchShift)

                    payload.push(util.getPayloadForRosterScheduleUpdate(value, newData, date));

                    newData.dates[date] = {
                        value: value,
                        className: value.split('').join(' '),
                        schedules: oldValue.schedules
                    };
                });

                data.push(newData);

            }
        })

        _gridApi.showLoadingOverlay();
        api.post(`/rostergrid/rostercandidateschedule`, payload)
        .then((response) => {
            
            //console.log('rostercandidateschedule: ', { data, response  })
     
            if (response.data.created && response.data.created.length > 0) {
                data.forEach(row => {
                    response.data.created.filter(f => f.rosterCandidateId === row.rosterCandidateId).forEach(item => {
                        row.dates[item.column].schedules[item.rosterScheduleTypeCode] = item.rosterCandidateScheduleId
                    })
                })
            }
            if (response.data.removed && response.data.removed.length > 0) {
                data.forEach(row => {
                    response.data.removed.filter(f => f.rosterCandidateId === row.rosterCandidateId).forEach(item => {
                        delete row.dates[item.column].schedules[item.rosterScheduleTypeCode]
                    })
                })
            }

            const tx = {
                update: data,
            };

            _gridApi.applyTransaction(tx);
            _setDisableTrimStartDateDefault(false);
            _setDisableTrimEndDateDefault(false);
        })
        .catch((error) => {
            // TODO: Display Error message in UI
            console.log(error.response)
        })
        .finally(() => _gridApi.hideOverlay());

    }

    const _trainingAction = () => {
        const ranges = _gridApi.getCellRanges();
        const payload = [];
        ranges.forEach(range => {
            const { start, end } = util.getStartEndIndexFromRange(range);
            for (let i = start; i <= end; i++) {
                const rowNode = _gridApi.getDisplayedRowAtIndex(i); //
                const newData = { ...rowNode.data }; // data
                
                range.columns.forEach(column => {
                    //if (column.instanceId < process.env.REACT_APP_ROSTER_DATE_COLUMN_START_INDEX) {
                    if (_staticColumnDefs[0].children.find(f => f.field === column.colId)) {
                        return;
                    }
                    const [ , date ] = column.colDef.field.split('.');
                    const oldValue = newData.dates[date] ?? defaultDatesValue;
                    const value = _getScheduleValue(newData, ScheduleTypeCodes.Training, oldValue, false);
                    payload.push({...util.getPayloadForRosterScheduleUpdate(value, newData, date), candidateId: newData.candidateId});
                });
            }
        })

        if (!payload.length)
            return;

        payload.sort((a, b) => moment(a.date, "YYYY-MM-DD") - moment(b.date, "YYYY-MM-DD"));
        _setTrainingData({
            startDate: payload[0].date,
            endDate: payload[payload.length - 1].date,
            candidateId: payload[0].candidateId,
            onSaved: (params) => {
                // console.log({params});

                api.post("candidatetraining", JSON.stringify(params.data))
                    .then(() => {
                        params.successCallback();
                    })
                    .catch((error) => {
                        console.error({error});
                        params.errorCallback(error.response?.data);
                    })
                ;
            },
            onDelete: (params) => {
                // console.log({params});

                api.delete(`candidatetraining/id/${params.data.candidateTrainingId}`)
                    .then(() => {
                        params.successCallback();
                    })
                    .catch((error) => {
                        console.error({error});
                        params.errorCallback(error.response?.data);
                    })
                ;
            }
        });
    }

    const _clearAction = () => {
        const ranges = _gridApi.getCellRanges();
        _clearCellRanges(ranges, _gridApi, _staticColumnDefs, _setShowDeleteConfirmation);
    }

    const _setStatusAction = (status) => {
        _setLoading(true);
        const ranges = _gridApi.getCellRanges();
        const rowIndexes = _getdRowIndexes(ranges);
        let update = [];
        let rowNodes = [];

        rowIndexes.forEach((value) => {
            const rowNode = _gridApi.getDisplayedRowAtIndex(value);
            // console.log('rowNode', rowNode)
            update.push(rowNode.data.rosterCandidateId);
            rowNodes.push(rowNode);
        });

        api.post(`/rostergrid/rostercandidatestatus/${status}`, update)
        .then((response) => {            
            // console.log('response', response)

            rowNodes.forEach(rowNode => rowNode.setData({ ...rowNode.data, confirmationStatusId: response.data.confirmationStatusId, confirmationStatusName: response.data.confirmationStatusName }))

            _setLoading(false);
        })
        .catch((error) => {
            // TODO: Display Error message in UI
            console.log(error.response)
        })
        .finally(() => _gridApi.hideOverlay());

        //console.log('_setStatusAction', { ranges, _gridApi, rowIndexes })
    }

    const _setAvailabilityStatusAction = (status, isEstimate = false) => {
        _setLoading(true);
        const ranges = _gridApi.getCellRanges();
        const rowIndexes = _getdRowIndexes(ranges);
        const update = [];
        const rowNodes = [];

        rowIndexes.forEach((value) => {
            const rowNode = _gridApi.getDisplayedRowAtIndex(value);
            // console.log('rowNode', rowNode);
            update.push({rosterCandidateId: rowNode.data.rosterCandidateId, candidateId: rowNode.data.candidateId});
            rowNodes.push(rowNode);
        });
        _setLoading(false);
        _setCandidateManualConfirmationList({data: update, type: isEstimate ? "Estimate Availability" : "Availability", status});

        // api.post(`/rostergrid/availabilitystatus/${status}`, update)
        // .then((response) => {
        //     rowNodes.forEach(rowNode => rowNode.setData({ ...rowNode.data, availabilityStatusId: response.data.availabilityStatusId, availabilityStatusName: response.data.availabilityStatusName, availabilityStatusClassName: response.data.availabilityStatusClassName }));
        //     _setLoading(false);
        // })
        // .catch((error) => {
        //     // TODO: Display Error message in UI
        //     console.log(error.response);
        //     _setLoading(false);
        // })
        // .finally(() => _gridApi.hideOverlay());

        // console.log('_setAvailabilityStatusAction', { ranges, _gridApi, rowIndexes })
    }

    const _setLogisticStatusAction = (status) => {
        _setLoading(true);
        const ranges = _gridApi.getCellRanges();
        const rowIndexes = _getdRowIndexes(ranges);
        const update = [];
        const rowNodes = [];

        rowIndexes.forEach((value) => {
            const rowNode = _gridApi.getDisplayedRowAtIndex(value);
            // console.log('rowNode', rowNode)
            update.push({rosterCandidateId: rowNode.data.rosterCandidateId, candidateId: rowNode.data.candidateId});
            rowNodes.push(rowNode);
        });
        _setLoading(false);
        _setCandidateManualConfirmationList({data: update, type: "Logistic", status});

        // api.post(`/rostergrid/logisticstatus/${status}`, update)
        // .then((response) => {
        //     rowNodes.forEach(rowNode => rowNode.setData({ ...rowNode.data, logisticStatusId: response.data.logisticStatusId, logisticStatusName: response.data.logisticStatusName, logisticStatusClassName: response.data.logisticStatusClassName }));
        //     _setLoading(false);
        // })
        // .catch((error) => {
        //     // TODO: Display Error message in UI
        //     console.log(error.response);
        //     _setLoading(false);
        // })
        // .finally(() => _gridApi.hideOverlay());

        // console.log('_setLogisticStatusAction', { ranges, _gridApi, rowIndexes })
    }

    const _setDASStatusAction = (status) => {
        _setLoading(true);
        const ranges = _gridApi.getCellRanges();
        const rowIndexes = _getdRowIndexes(ranges);
        const update = [];
        const rowNodes = [];

        rowIndexes.forEach((value) => {
            const rowNode = _gridApi.getDisplayedRowAtIndex(value);
            // console.log('rowNode', rowNode)
            update.push({rosterCandidateId: rowNode.data.rosterCandidateId, candidateId: rowNode.data.candidateId});
            rowNodes.push(rowNode);
        });
        _setLoading(false);
        _setCandidateManualConfirmationList({data: update, type: "DAS", status});

        // api.post(`/rostergrid/dasstatus/${status}`, update)
        // .then((response) => {
        //     rowNodes.forEach(rowNode => rowNode.setData({ ...rowNode.data, dasstatusId: response.data.dasstatusId, dasstatusName: response.data.dasstatusName, dasstatusClassName: response.data.dasstatusClassName }));
        //     _setLoading(false);
        // })
        // .catch((error) => {
        //     // TODO: Display Error message in UI
        //     console.log(error.response);
        //     _setLoading(false);
        // })
        // .finally(() => _gridApi.hideOverlay());

        // console.log('_setDASStatusAction', { ranges, _gridApi, rowIndexes })
    }

    const _sendMessageAction = () => {
        _setLoading(true);
        const ranges = _gridApi.getCellRanges();
        const rowIndexes = _getdRowIndexes(ranges);
        const rosterCandidateIds = [];
        let rosterCandidateId = null;
        rowIndexes.forEach((value, index, array) => {
            const rowNode = _gridApi.getDisplayedRowAtIndex(value);
            //console.log('rowNode', rowNode);
            rosterCandidateIds.push(rowNode.data.rosterCandidateId);

            if (!rosterCandidateId)
                rosterCandidateId = rowNode.data.rosterCandidateId;
        });

        api.post(`/rostercandidate/id-bulk`, JSON.stringify(rosterCandidateIds))
        .then((response) => {            
            // console.log('response', {response}, response.data, response.data.length);
            if (response.data && response.data.length > 0) {
                _setCandidateMessageList({rosterCandidateId, candidates: [...response.data]});
            }
            _setLoading(false);
        })
        .catch((error) => {
            // TODO: Display Error message in UI
            console.log(error.response);
            _setLoading(false);
        })
        ;
    }

    const _sendAvailabilityConfirmationAction = () => {
        const ranges = _gridApi.getCellRanges();
        const rowIndexes = _getdRowIndexes(ranges);
        let rosterCandidates = [];
        rowIndexes.forEach((value, index, array) => {
            const rowNode = _gridApi.getDisplayedRowAtIndex(value);
            //console.log('rowNode', rowNode)
            rosterCandidates.push({ 
                rosterCandidateId: rowNode.data.rosterCandidateId,
                candidateName: rowNode.data.candidateName,
                positionName: rowNode.data.positionName,
            });
        });
        _setCandidateConfirmationList(rosterCandidates);
    }

    const _sendDASConfirmationAction = () => {
        const ranges = _gridApi.getCellRanges();
        const rowIndexes = _getdRowIndexes(ranges);
        let rosterCandidates = [];
        rowIndexes.forEach((value, index, array) => {
            const rowNode = _gridApi.getDisplayedRowAtIndex(value);
            // console.log('rowNode', rowNode)
            rosterCandidates.push({ 
                rosterCandidateId: rowNode.data.rosterCandidateId,
                candidateName: rowNode.data.candidateName,
                positionName: rowNode.data.positionName,
            });
        });
        _setCandidateDASList(rosterCandidates);
    }

    const _sendBulkDASConfirmationAction = () => {
        const ranges = _gridApi.getCellRanges();
        const rowIndexes = _getdRowIndexes(ranges);
        const rosterCandidates = [];
        rowIndexes.forEach((value, index, array) => {
            const rowNode = _gridApi.getDisplayedRowAtIndex(value);
            rosterCandidates.push({ 
                rosterCandidateId: rowNode.data.rosterCandidateId,
            });
        });
        _setCandidateBulkDASList(rosterCandidates);
    }
    const _generateLrfAction = () => {
        _setLoading(true);
        const ranges = _gridApi.getCellRanges();
        const rowIndexes = _getdRowIndexes(ranges);
        const ids = [];
        const slots = [];
        const invalidSlots = [];

        rowIndexes.forEach((value, index, array) => {
            const rowNode = _gridApi.getDisplayedRowAtIndex(value);
            //console.log('rowNode', rowNode);
            const dateKeys = Object.keys(rowNode.data.dates);

            if (!dateKeys.length) {
                invalidSlots.push({
                    slot: rowNode.data.slot,
                })
                return;
            }

            const dateLimits = {};

            dateKeys.forEach(dt => {
                if (rowNode.data.dates[dt].value.includes(ScheduleTypeCodes.Start) && (!dateLimits.startDateKey || dateLimits.startDateKey > dt))
                    dateLimits.startDateKey = dt;

                if (rowNode.data.dates[dt].value.includes(ScheduleTypeCodes.End) && (!dateLimits.endDateKey || dateLimits.endDateKey < dt))
                    dateLimits.endDateKey = dt;
            });

            slots.push({
                projectId: rowNode.data.projectId,
                rosterId: rowNode.data.rosterId,
                positionId: rowNode.data.positionId,
                hrispositionMasterId: rowNode.data.hrispositionMasterId,
                hrispositionId: rowNode.data.hrispositionId,
                locationId: rowNode.data.locationId,
                companyId: rowNode.data.rosterCandidateCompanyId,
                startDate: moment(dateKeys[0], "YYYYMMDD").format("YYYY-MM-DD"),
                endDate: moment(dateKeys[dateKeys.length - 1], "YYYYMMDD").format("YYYY-MM-DD"),
                startDateKey: dateLimits.startDateKey,
                endDateKey: dateLimits.endDateKey,
                dates: rowNode.data.dates,
                slot: rowNode.data.slot,
            });
            ids.push(rowNode.data.rosterCandidateId);
        });

        if (invalidSlots.length > 0) {
            const slotNo = invalidSlots[0].slot;
            _setErrorMessage(`Slot No. ${slotNo} has no schedule or Start / End Date to generate LRF. Please fix before generating the LRF.`);
            _setLoading(false);
            return;
        }

        if (slots.filter(s => !s.startDate || !s.endDate || !s.dates[s.startDateKey]?.value.includes(ScheduleTypeCodes.Start) || !s.dates[s.endDateKey]?.value.includes(ScheduleTypeCodes.End)).length > 0) {
            const slotNo = slots.filter(s => !s.startDate || !s.endDate || !s.dates[s.startDateKey]?.value.includes(ScheduleTypeCodes.Start) || !s.dates[s.endDateKey]?.value.includes(ScheduleTypeCodes.End))[0].slot;
            _setErrorMessage(`Slot No. ${slotNo} has no schedule or Start / End Date to generate LRF. Please fix before generating the LRF.`);
            _setLoading(false);
            return;
        }

        // console.log({slots, ids});

        //console.log('rostergrid/lrf', ids, slots);
        api.post("/rostergrid/lrf", {
            rosterCandidateIds: ids,
            items: slots,
            dispatchUnitId: _dispatchUnit.lookupId,
            startDate: _trimStartDate ? null : formatDate(_startDate, 'YYYY-MM-DD'),
            endDate: _trimEndDate ? null : formatDate(_endDate, 'YYYY-MM-DD'),
        })
            .then((response) => {
                console.log({response});
                response.data.recruitmentRequestIds.forEach(d => {
                    console.log({d});
                    window.open(`/lrf/${d}`, '_blank');
                });

                const tx = {
                    update: response.data.rosterCandidates,
                };

                _gridApi.applyTransactionAsync(tx, (result) => {
                    _gridApi.refreshCells({ force: true, rowNodes: result.update });
                });
            })
            .catch((error) => {
                //setValidationAlert({ message: error.response.status === 501 ? error.response.data : "System error." });
                _setErrorMessage(error.response?.data?.error ?? "System error.");
                console.log({ error });
            })
            .finally(() => _setLoading(false));
    }

    const _logisticsAction = () => {
        _setLoading(true);
        const ranges = _gridApi.getCellRanges();
        const rowIndexes = _getdRowIndexes(ranges);
        let rowNode;
        const rosterCandidates = [];
        rowIndexes.forEach((value, index, array) => {
            rowNode = _gridApi.getDisplayedRowAtIndex(value);
            rosterCandidates.push({ ...rowNode.data });
        });
        //console.log('_logisticsAction', {rowNode, rosterCandidates})
        const rosters = [ { rosterId: rowNode.data.rosterId, rosterName: rowNode.data.rosterName } ];
        const dispatchUnits = [ { dispatchUnitId: _dispatchUnit.lookupId, dispatchUnitName: _dispatchUnit.lookupValue } ];

        api.post(`rostercandidate/id-bulk`, JSON.stringify(rosterCandidates.map(rc => rc.rosterCandidateId)))
            .then((response) => {
                //console.log("request detail logistic: ", [...response.data]);
                _setLoading(false);
                _setLogisticInEdit({
                    rosterCandidates: [...response.data],
                    rosters,
                    dispatchUnits
                });
            })
            .catch((error) => {
                console.log({error});
                _setLoading(false);
            })
        ;
    }

    const _generateRosterSheetAction = () => {
        _setLoading(true);

        const params = {
            fileName: `${_dispatchUnit.lookupValue} - ${moment(new Date()).format("DD-MM-YYYY HH:mm:ss")}`,
            sheetName: "data",
            processCellCallback: (param) => {
                return param.column.colId.includes("dates") && param.value ? param.value.value : param.value;
            },
        };

        _gridApi.exportDataAsExcel(params);
        _setLoading(false);
        _loadSchedule();
    };

    const _contingencyAction = () => {
        _setContingencyOpen(_node)
    }

    const _hideColumnAction = () => {
        const column = _gridApi.getCellRanges()[0].columns[0];
        // console.log({column, _staticColumnDefs});

        _columnApi.applyColumnState({
            state: [
                {colId: column.colId, hide: true}
            ]
        });
    }

    const _showAllHiddenColumnsAction = () => {
        if (!_staticColumnDefs[0]?.children.length)
            return;

        const newStates = _staticColumnDefs[0].children.map(child => {
            return {
                colId: child.field,
                hide: false
            };
        });

        // console.log({newStates});

        _columnApi.applyColumnState({
            state: newStates
        });
    }

    const _rosterPositionAligmentAction = () => {
        const ranges = _gridApi.getCellRanges();
        const rowIndexes = _getdRowIndexes(ranges);
        const ids = [];

        rowIndexes.forEach((value) => {
            const rowNode = _gridApi.getDisplayedRowAtIndex(value);
            ids.push(rowNode.data.rosterCandidateId);
        });

        _setRosterPositionAlignmentConfirmation({
            rosterCandidateIds: ids,
        });
    }

    const _prePopulateWorkSequenceAction_original = (sequence) => {
        _gridApi.showLoadingOverlay();
        const ranges = _gridApi.getCellRanges();
        const dates = [];
        ranges.forEach(range => {
            const { start, end } = util.getStartEndIndexFromRange(range);
            for (let i = start; i <= end; i++) {
                const rowNode = _gridApi.getDisplayedRowAtIndex(i); //
                const newData = { ...rowNode.data }; // data
                const newDate = {
                    rosterCandidateId: newData.rosterCandidateId,
                    dates: [],
                }

                range.columns.forEach(column => {
                    //if (column.instanceId < process.env.REACT_APP_ROSTER_DATE_COLUMN_START_INDEX) {
                    if (_staticColumnDefs[0].children.find(f => f.field === column.colId)) {
                        return;
                    }
                    const { headerName } = column.colDef;
                    newDate.dates.push(moment(headerName, "DD/MM/YYYY").format("YYYY-MM-DD"));
                });

                dates.push(newDate);
            }
        });

        // console.log({dates, sequence});
        api.post(`/rostergrid/worksequence/populate`, { workSequence: sequence, dates })
            .then((response) => {
                const { data: responseData } = response;
                // const tx = {
                //     update: responseData.rosterCandidates
                // };
                // _gridApi.applyTransactionAsync(tx, (result) => {
                //     _gridApi.clearRangeSelection();
                //     _gridApi.refreshCells({force: true, rowNodes: result.update});
                //     _setDisableTrimStartDateDefault(false);
                //     _setDisableTrimEndDateDefault(false);
                //     _setStartDate()
                //     _gridApi.hideOverlay();
                // });
                _loadSchedule({
                    startDate: responseData.startDate,
                    endDate: responseData.endDate,
                });
            })
            .catch((error) => {
                // TODO: Display Error message in UI
                console.log({ error });
                _setErrorMessage(`${error.response?.data ?? "System Error!"}`);
                _setErrorTitle("Pre-populate Work Sequence Error");
            })
            .finally(() => _gridApi.hideOverlay())
            ;
    };

    const _prePopulateWorkSequenceAction = () => {
        //_gridApi.showLoadingOverlay();
        const ranges = _gridApi.getCellRanges();
        const dates = [];
        ranges.forEach(range => {
            const { start, end } = util.getStartEndIndexFromRange(range);
            for (let i = start; i <= end; i++) {
                const rowNode = _gridApi.getDisplayedRowAtIndex(i); //
                const newData = { ...rowNode.data }; // data
                const newDate = {
                    rosterCandidateId: newData.rosterCandidateId,
                    dates: [],
                }
                
                range.columns.forEach(column => {
                    //if (column.instanceId < process.env.REACT_APP_ROSTER_DATE_COLUMN_START_INDEX) {
                    if (_staticColumnDefs[0].children.find(f => f.field === column.colId)) {
                        return;
                    }
                    const { headerName } = column.colDef;
                    newDate.dates.push(moment(headerName, "DD/MM/YYYY").format("YYYY-MM-DD"));
                });

                dates.push(newDate);
            }
        });

        var passVar = {
            workSequence: null,
            dates: dates
        };
        _setShowPrepopulateWorkSequence(passVar);


        // console.log({dates, sequence});;
    };

    const _setCandidateSearchAction = () => {
        const ranges = _gridApi.getCellRanges();
        const rowIndexes = _getdRowIndexes(ranges);
        const ids = [];

        rowIndexes.forEach((value) => {
            const rowNode = _gridApi.getDisplayedRowAtIndex(value);
            ids.push(rowNode.data.rosterCandidateId);
        });

        _setShowTalentsSearch({
            rosterCandidateIds: ids,
        });
    };

    const _getMessageLogAction = () => {
        // _gridApi.showLoadingOverlay();
        const ranges = _gridApi.getCellRanges();
        const lastRange = ranges[ranges.length - 1];
        const { end } = util.getStartEndIndexFromRange(lastRange);
        const { data } = _gridApi.getDisplayedRowAtIndex(end);
        // console.log({lastRange, data});
        _setShowMessageHistory({...data});
    };

    const _getAuditLogAction = () => {
        // _gridApi.showLoadingOverlay();
        const ranges = _gridApi.getCellRanges();
        const lastRange = ranges[ranges.length - 1];
        const { end } = util.getStartEndIndexFromRange(lastRange);
        const { data } = _gridApi.getDisplayedRowAtIndex(end);
        // console.log({lastRange, data});
        _setShowAuditHistory({...data});
    };

    const findPreviousParentNode = (index) => {
        let node = _gridApi.getDisplayedRowAtIndex(index);
        if (node.data.slot % 1 > 0) {
            return findPreviousParentNode((index*1) - 1);
        }
        return node;
    }
    const findNextParentNode = (index) => {
        let node = _gridApi.getDisplayedRowAtIndex(index);
        if (node.data.slot % 1 > 0) {
            return findNextParentNode((index*1) + 1);
        }
        return node;
    }

    const getDecimalPart = (num) => {
        if (Number.isInteger(num)) {
            return 0;
        }
      
        const decimalStr = num.toString().split('.')[1];
        return Number(decimalStr);
      }

    const _moveAction = (direction) => {

        const ranges = _gridApi.getCellRanges();
        const orderedRowIndexes = _getOrderedRowIndexes(ranges);
        const selectedNodes = orderedRowIndexes.map(m => _gridApi.getDisplayedRowAtIndex(m))
        const parentNodes = selectedNodes.filter(f => f.data.slot % 1 === 0).sort((a, b) => a.rowIndex - b.rowIndex);
        const childNodes = selectedNodes.filter(f => f.data.slot % 1 > 0).sort((a, b) => a.rowIndex - b.rowIndex);

        let currentNode;

        const payload = [];
        if (parentNodes && parentNodes.length > 0) {
            if (MoveDirection.Up === direction) {
                var groupedNodes = parentNodes.reduce((output, node, index, array) => {
                    const prevNode = findPreviousParentNode(node.rowIndex - 1);
                    if (!index || array[index - 1].rowIndex !== prevNode.rowIndex) {
                        output[prevNode.rowIndex] = []; 
                        currentNode = prevNode;
                    }
                    output[currentNode.rowIndex].push(node);
                    return output;
                }, {});

                Object.entries(groupedNodes).forEach(([downIndex, upNodes]) => {
                    const downNode = _gridApi.getDisplayedRowAtIndex(downIndex);
                    payload.push({ rosterCandidateId: downNode.data.rosterCandidateId, slot: downNode.data.slot + upNodes.length })
                    upNodes.forEach(upNode => {
                        if (upNode.data.slot % 1 === 0) {
                            payload.push({ rosterCandidateId: upNode.data.rosterCandidateId, slot: upNode.data.slot - 1 })
                        }
                    })
                });
            } 
            else if (MoveDirection.Down === direction) {
                var groupedNodes = parentNodes.reverse().reduce((output, node, index, array) => {
                    const nextNode = findNextParentNode(node.rowIndex + 1);
                    if (!index || array[index - 1].rowIndex !== nextNode.rowIndex) {
                        output[nextNode.rowIndex] = []; 
                        currentNode = nextNode;
                    }
                    output[currentNode.rowIndex].push(node);
                    return output;
                }, {});

                Object.entries(groupedNodes).forEach(([upIndex, downNodes]) => {
                    const upNode = _gridApi.getDisplayedRowAtIndex(upIndex);
                    payload.push({ rosterCandidateId: upNode.data.rosterCandidateId, slot: upNode.data.slot - downNodes.length })
                    downNodes.forEach(downNode => {
                        if (downNode.data.slot % 1 === 0) {
                            payload.push({ rosterCandidateId: downNode.data.rosterCandidateId, slot: downNode.data.slot + 1 })
                        }
                    })
                });
            }
        }
        else if (childNodes && childNodes.length > 0) {
            if (MoveDirection.Up === direction) {
                var groupedNodes = childNodes.reduce((output, node, index, array) => {
                    const prevNode = _gridApi.getDisplayedRowAtIndex(node.rowIndex - 1);
                    if (!index || array[index - 1].rowIndex !== prevNode.rowIndex) {
                        output[prevNode.rowIndex] = []; 
                        currentNode = prevNode;
                    }
                    output[currentNode.rowIndex].push(node);
                    return output;
                }, {});

                Object.entries(groupedNodes).forEach(([downIndex, upNodes]) => {
                    const downNode = _gridApi.getDisplayedRowAtIndex(downIndex);
                    payload.push({ rosterCandidateId: downNode.data.rosterCandidateId, slot: getDecimalPart(downNode.data.slot) + upNodes.length })
                    upNodes.forEach(upNode => {
                        if (upNode.data.slot % 1 > 0) {
                            payload.push({ rosterCandidateId: upNode.data.rosterCandidateId, slot: getDecimalPart(upNode.data.slot) - 1 })
                        }
                    })
                });
            }
            else if (MoveDirection.Down === direction) {
                var groupedNodes = childNodes.reverse().reduce((output, node, index, array) => {
                    const nextNode = _gridApi.getDisplayedRowAtIndex(node.rowIndex + 1);
                    if (!index || array[index - 1].rowIndex !== nextNode.rowIndex) {
                        output[nextNode.rowIndex] = []; 
                        currentNode = nextNode;
                    }
                    output[currentNode.rowIndex].push(node);
                    return output;
                }, {});

                Object.entries(groupedNodes).forEach(([upIndex, downNodes]) => {
                    const upNode = _gridApi.getDisplayedRowAtIndex(upIndex);
                    payload.push({ rosterCandidateId: upNode.data.rosterCandidateId, slot: getDecimalPart(upNode.data.slot) - downNodes.length })
                    downNodes.forEach(downNode => {
                        if (downNode.data.slot % 1 > 0) {
                            payload.push({ rosterCandidateId: downNode.data.rosterCandidateId, slot: getDecimalPart(downNode.data.slot) + 1 })
                        }
                    })
                });
            }
        }

        if (payload.length) {
            _gridApi.showLoadingOverlay();

            const query = {
                startDate: _trimStartDate ? null : formatDate(_startDate, 'YYYY-MM-DD'),
                endDate: _trimEndDate ? null : formatDate(_endDate, 'YYYY-MM-DD'),
            }
            const queryString = qs.stringify(query, { allowDots: true });

            api.post(`/rostergrid/rostercandidatereorderslot?${queryString}`, payload)
            .then((response) => {
                const tx = {
                    update: response.data,
                };

                _gridApi.applyTransaction(tx);
            })
            .catch((error) => {
                // TODO: Display Error message in UI
                console.log(error.response)
            })
            .finally(() => _gridApi.hideOverlay());
        }

        _gridApi.clearRangeSelection();
    }

    const _mobilizeAction = () => _scheduleAction(ScheduleTypeCodes.Mobilise);
    const _demobilizeAction = () => _scheduleAction(ScheduleTypeCodes.Demobilise);
    const _startAction = () => _scheduleAction(ScheduleTypeCodes.Start);
    const _endAction = () => _scheduleAction(ScheduleTypeCodes.End);
    const _workingAction = () => _scheduleAction(ScheduleTypeCodes.Working);
    const _wfhAction = () => _scheduleAction(ScheduleTypeCodes.WorkFromHome);
    const _halfDayAction = () => _scheduleAction(ScheduleTypeCodes.HalfDay);
    // const _trainingAction = () => _scheduleAction(ScheduleTypeCodes.Training);
    const _leaveAction = () => _scheduleAction(ScheduleTypeCodes.Leave);
    const _rnrAction = () => _scheduleAction(ScheduleTypeCodes.RNR);
    const _sickLeaveAction = () => _scheduleAction(ScheduleTypeCodes.SickLeave);
    const _fatigueDayAction = () => _scheduleAction(ScheduleTypeCodes.FatigueDay);
    const _driveOutAction = () => _scheduleAction(ScheduleTypeCodes.DriveOut);
    const _driveInAction = () => _scheduleAction(ScheduleTypeCodes.DriveIn);
    const _lwopAction = () => _scheduleAction(ScheduleTypeCodes.LWOP);
    const _switchShiftAction = () => _scheduleAction(ScheduleTypeCodes.Working, true);
    const _moveUpAction = () => _moveAction(MoveDirection.Up);
    const _moveDownAction = () => _moveAction(MoveDirection.Down);
    let slot = 0;
    const slotNumberSubMenu = [
        "2",
        "3",
        "4",
        "5",
        "N",
    ]
    const _slotBlankMenuItems = [
        {
            name: 'Add Slot',
            action: () => _addAction(1, false),
        },
        {
            name: 'Add N Slots',
            subMenu: slotNumberSubMenu.map(s => ({
                name: s,
                action: () => {
                    _addAction(s, false);
                },
            })),
        }
    ];
    const _slotMenuItems = [
        {
            name: 'Copy Slot',
            action: _copyAction,
        },
        {
            name: 'Copy N Times',
            subMenu: slotNumberSubMenu.map(s => ({
                name: s,
                action: () => {
                    _copyAction(s);
                },
            })),
        },
        {
            name: 'Add Slot',
            action: _addAction,
        },
        {
            name: 'Add N Slots',
            subMenu: slotNumberSubMenu.map(s => ({
                name: s,
                action: () => {
                    _addAction(s);
                },
            })),
        },
        {
            name: 'Split Slot',
            action: _splitAction,
        },
    ];
    const _isFirstSplitSlotSelected = (rowNodes) => {
        return rowNodes.every(n => n.data.slot % 1 > 0) && rowNodes.some(s => {
            const node = _gridApi.getDisplayedRowAtIndex(s.rowIndex - 1);
            return node && node.data.slot % 1 === 0;
        })
    }
    const _isLastSplitSlotSelected = (rowNodes) => {
        return rowNodes.every(n => n.data.slot % 1 > 0) && rowNodes.some(s => {
            const node = _gridApi.getDisplayedRowAtIndex(s.rowIndex + 1);
            return node && node.data.slot % 1 === 0;
        })
    }
    const _reorderMenuItems = (rowNodes) => {
        return [
            {
                name: 'Move Up',
                action: _moveUpAction,
                disabled: rowNodes.some(s => s.rowIndex === _gridApi.getFirstDisplayedRow()) || _isFirstSplitSlotSelected(rowNodes),
            },
            {
                name: 'Move Down',
                action: _moveDownAction,
                disabled: rowNodes.some(s => s.rowIndex === _gridApi.getLastDisplayedRow()) || _isLastSplitSlotSelected(rowNodes),
            },
        ];
    }
    const _removeMenuItems = [
        {
            name: 'Remove Slot',
            action: _removeAction,
        },
    ];
    const _refreshSlotNumberItems = [
        {
            name: 'Refresh All Slot Numbers',
            action: _refreshSlotNumberAction,
        },
    ];
    const _logisticsMenuItems = [
        {
            name: 'Arrange Logistics',
            action: _logisticsAction,
        },
    ];
    const _availabilityMenuItems = [
        {
            name: 'Send Availability Confirmation',
            action: _sendAvailabilityConfirmationAction,
        },
    ];
    const _messagesMenuItems = [
        {
            name: 'Send Message',
            action: _sendMessageAction,
        },
    ];
    const _dasMenuItems = [
        {
            name: 'DAS Confirmation',
            action: _sendDASConfirmationAction,
        },
    ];
    const _bulkDasMenuItems = [
        {
            name: 'Bulk DAS Confirmation',
            action: _sendBulkDASConfirmationAction,
        },
    ];
    const _attributesMenuItems = [
        {
            name: 'Project Specific Attributes',
            action: () => {
                _setCandidateInEdit({
                    candidateId: _node.data.candidateId,
                    project: {
                        projectId: _project.projectId,
                        projectName: _project.projectName
                    },
                })
            },
        },
        {
            name: 'Roster Specific Attributes',
            action: () => {
                _setRosterAttributeInEdit({
                    rosterCandidateId: _node.data.rosterCandidateId,
                    candidate: {
                        candidateId: _node.data.candidateId,
                        candidateName: _node.data.candidateName
                    },
                    project: {
                        projectId: _project.projectId,
                        projectName: _project.projectName
                    },
                })
            },
        },
    ];
    const _contractMenuItems = [
        {
            name: 'Generate Contract',
            action: () => {
                _setCandidateContractInEdit({
                    rosterCandidateId: _node.data.rosterCandidateId,
                    candidate: {
                        candidateId: _node.data.candidateId,
                        candidateName: _node.data.candidateName
                    },
                    project: {
                        projectId: _project.projectId,
                        projectName: _project.projectName
                    },
                    company: {
                        lookupId: _node.data.rosterCandidateCompanyId
                    },
                    position: {
                        positionId: _node.data.positionId,
                        positionName: _node.data.positionName
                    },
                    location: {
                        locationId: _node.data.locationId
                    }

                });
                // console.log('_node', _node);
            },
        },
    ];
    const _stepupMenuItems = [
        {
            name: 'Step Up',
            action: () => {
                var [ , date ] = _gridApi.getColumnDefs()[1].children[0].colId.split('.');

                _setStepUpInEdit({
                    rosterCandidateId: _node.data.rosterCandidateId,
                    candidate: {
                        candidateId: _node.data.candidateId,
                        candidateName: _node.data.candidateName
                    },
                    position: {
                        positionId: _node.data.positionId,
                        positionName: _node.data.positionName
                    },
                    company: {
                        lookupId: _node.data.rosterCandidateCompanyId,
                        lookupValue: _node.data.rosterCandidateCompanyName
                    },
                    startDate: date,
                    rowNode: _node
                })
            },
        },
    ];
    const _statusMenuItems = [
        {
            name: 'Set Availability Status',
            subMenu: [
                {
                    name: 'Confirmed',
                    action: () => {
                        _setAvailabilityStatusAction('Confirmed');
                    },
                },
                {
                    name: 'Declined',
                    action: () => {
                        _setAvailabilityStatusAction('Declined');
                    },
                },
                {
                    name: 'Estimate Confirmed',
                    action: () => {
                        _setAvailabilityStatusAction('Estimate Confirmed', true);
                    },
                },
                {
                    name: 'Estimate Declined',
                    action: () => {
                        _setAvailabilityStatusAction('Estimate Declined', true);
                    },
                },
            ],
        },
        {
            name: 'Set Logistic Status',
            subMenu: [
                {
                    name: 'Confirmed',
                    action: () => {
                        _setLogisticStatusAction('Logistic Confirmed');
                    },
                },
                {
                    name: 'Declined',
                    action: () => {
                        _setLogisticStatusAction('Logistic Declined');
                    },
                }
            ],
        },
        {
            name: 'Set DAS Status',
            subMenu: [
                {
                    name: 'Confirmed',
                    action: () => {
                        _setDASStatusAction('Confirmed');
                    },
                },
                {
                    name: 'Declined',
                    action: () => {
                        _setDASStatusAction('Declined');
                    },
                },
                {
                    name: 'Booked',
                    action: () => {
                        _setDASStatusAction('Booked');
                    },
                },
                {
                    name: 'Completed',
                    action: () => {
                        _setDASStatusAction('Completed');
                    },
                },
                {
                    name: '(Clear)',
                    action: () => {
                        _setDASStatusAction('Clear');
                    },
                }
            ],
        },
    ];
    const _lrfMenuItems = [
        {
            name: 'Generate LRF',
            action: _generateLrfAction,
        },
    ];
    const _contingencyMenuItems = [
        {
            name: 'Open Contingency Window',
            action: _contingencyAction,
        },
    ];
    const _confirmationItems = [
        {
            name: 'Bulk Availability Confirmation',
            action: () => {
                const ranges = _gridApi.getCellRanges();
                const rowIndexes = _getdRowIndexes(ranges);
                let rosterCandidates = [];
                rowIndexes.forEach((value, index, array) => {
                    const rowNode = _gridApi.getDisplayedRowAtIndex(value);
                    // console.log('rowNode', rowNode)
                    rosterCandidates.push({ 
                        rosterCandidateId: rowNode.data.rosterCandidateId,
                    });
                });
                _setCandidateBulkAvailabilityList(rosterCandidates);
            }
        },
        {
            name: 'Bulk Logistic Confirmation',
            action: () => {
                const ranges = _gridApi.getCellRanges();
                const rowIndexes = _getdRowIndexes(ranges);
                let rosterCandidates = [];
                rowIndexes.forEach((value, index, array) => {
                    const rowNode = _gridApi.getDisplayedRowAtIndex(value);
                    // console.log('rowNode', rowNode)
                    rosterCandidates.push({ 
                        rosterCandidateId: rowNode.data.rosterCandidateId,
                    });
                });
                _setCandidateBulkLogisticList(rosterCandidates);
            }
        }
    ];
    const _scheduleMenuItems = [
        {
            name: 'Mobilise',
            action: _mobilizeAction,
        },
        {
            name: 'Demobilise',
            action: _demobilizeAction,
        },
        {
            name: 'Start',
            action: _startAction,
        },
        {
            name: 'End',
            action: _endAction,
        },
        {
            name: 'Working',
            action: _workingAction,
        },
        {
            name: 'Work from Home',
            action: _wfhAction,
        },
        {
            name: 'Half Day',
            action: _halfDayAction,
        },
        // {
        //     name: 'Training',
        //     action: _trainingAction,
        // },
        {
            name: 'Leave',
            action: _leaveAction,
        },
        {
            name: 'R&R',
            action: _rnrAction,
        },
        {
            name: 'Sick Leave',
            action: _sickLeaveAction,
        },
        {
            name: 'Fatigue Day',
            action: _fatigueDayAction,
        },
        {
            name: 'LWOP',
            action: _lwopAction,
        },
        {
            name: 'Drive-In',
            action: _driveInAction,
        },
        {
            name: 'Drive-Out',
            action: _driveOutAction,
        },
        {
            name: 'Switch Shift',
            action: _switchShiftAction,
        },
        {
            name: 'Clear',
            action: _clearAction,
        }
    ];
    const _trainingMenuItems = [
        {
            name: 'Training',
            action: _trainingAction,
        },
    ];
    const _exportMenuItems = [
        {
            name: 'Roster Sheet Generator',
            action: _generateRosterSheetAction,
        },
    ];
    const _columnVisibilityItems = [
        {
            name: 'Hide Column',
            action: _hideColumnAction,
        },
        {
            name: 'Show All Hidden Columns',
            action: _showAllHiddenColumnsAction,
        },
    ];
    const _positionAlignmentItems = [
        {
            name: 'Align Employed Position to Roster Position',
            action: _rosterPositionAligmentAction,
        }
    ];
    const _workSequenceItems = (lookups) => [
        {
            name: 'Pre-populate Work Sequence',
            action: () => _prePopulateWorkSequenceAction(),
            //subMenu: lookups?.workSequence?.map(l => {
            //    console.log('lookups?.workSequence', lookups?.workSequence);
            //    // console.log({l});
            //    return {
            //        name: l.lookupValue,
            //        action: () => _prePopulateWorkSequenceAction(l.lookupValue),
            //    };
            //})
        }
    ];
    const _candidateSearchItems = [
        {
            name: 'Talents Search',
            action: () => _setCandidateSearchAction(),
        },
    ];
    const _auditItems = [
        {
            name: 'Show All Related Messages',
            action: () => _getMessageLogAction(),
        },
        {
            name: 'Show Audit Log',
            action: () => _getAuditLogAction(),
        },
    ];

    return {
        getdRowIndexes: _getdRowIndexes,
        clearCellRanges: _clearCellRanges,
        getContextMenu: function (options) {

            (
                {
                    api: _gridApi,
                    column: _column,
                    columnApi: _columnApi,
                    context: _context,
                    defaultItems: _defaultItems,
                    node: _node,
                    value: _value,
                    setLoading: _setLoading,
                    dispatchUnit: _dispatchUnit,
                    startDate: _startDate,
                    endDate: _endDate,
                    trimStartDate: _trimStartDate,
                    trimEndDate: _trimEndDate,
                    project: _project,
                    setStepUpInEdit: _setStepUpInEdit,
                    setCandidateContractInEdit: _setCandidateContractInEdit,
                    setRosterAttributeInEdit: _setRosterAttributeInEdit,
                    setCandidateInEdit: _setCandidateInEdit,
                    setCandidateMessageList: _setCandidateMessageList,
                    setCandidateConfirmationList: _setCandidateConfirmationList,
                    setCandidateBulkAvailabilityList: _setCandidateBulkAvailabilityList,
                    setCandidateBulkLogisticList: _setCandidateBulkLogisticList,
                    setCandidateDASList: _setCandidateDASList,
                    setCandidateBulkDASList: _setCandidateBulkDASList,
                    setDeletedData: _setDeletedData,
                    setLogisticInEdit: _setLogisticInEdit,
                    staticColumnDefs: _staticColumnDefs,
                    loadSchedule: _loadSchedule,
                    setContingencyOpen: _setContingencyOpen,
                    setErrorMessage: _setErrorMessage,
                    setErrorTitle: _setErrorTitle,
                    setTrainingData: _setTrainingData,
                    setCandidateManualConfirmationList: _setCandidateManualConfirmationList,
                    setRosterPositionAlignmentConfirmation: _setRosterPositionAlignmentConfirmation,
                    setShowPrepopulateWorkSequence: _setShowPrepopulateWorkSequence,
                    lookups: _lookups,
                    setShowMessageHistory: _setShowMessageHistory,
                    setShowAuditHistory: _setShowAuditHistory,
                    setShowTalentsSearch: _setShowTalentsSearch,
                    setShowDeleteConfirmation: _setShowDeleteConfirmation,
                    setDisableTrimStartDateDefault: _setDisableTrimStartDateDefault,
                    setDisableTrimEndDateDefault: _setDisableTrimEndDateDefault,
                    viewAccess: _viewAccess,
                    setSlotCountObj: _setSlotCountObj,
                    refreshDataExistence: _refreshDataExistence
                } = options
            );

            const ranges = _gridApi.getCellRanges();
            const rowIndexes = _getdRowIndexes(ranges);
            const rowNodes = [];
            rowIndexes.forEach((value, index, array) => {
                const rowNode = _gridApi.getDisplayedRowAtIndex(value);
                rowNodes.push(rowNode);
            });

            const isSingleNode = rowNodes.length === 1;

            var menu = [];

            // console.log('_column?.colId', _column?.colId);
            // console.log('rowNodes', {rowNodes});
            

            let prevMenuExists = false;

            if (_viewAccess === ViewAccess.READONLY)
            {
                menu.push(..._exportMenuItems);
                return menu;
            }

            if (_column?.colId === "candidateName") {
                if (_node.data.candidateId) {
                    menu.push(..._logisticsMenuItems);

                    if (isSingleNode)
                        menu.push(..._availabilityMenuItems);

                    menu.push(..._messagesMenuItems);
                    menu.push(..._attributesMenuItems);
                    menu.push(..._contingencyMenuItems);
                    menu.push('separator');

                    if (isSingleNode)
                        menu.push(..._dasMenuItems);
                    
                    menu.push(..._bulkDasMenuItems);
                    menu.push('separator');
                    menu.push(..._confirmationItems);
                    menu.push('separator');

                    // if (isSingleNode && _node.data.rosterCandidateCompanyId)
                    //     menu.push(..._contractMenuItems);
                    
                    if (isSingleNode)
                        menu.push(..._stepupMenuItems);
                    
                    if (isSingleNode)
                        menu.push('separator');
                    
                    menu.push(..._statusMenuItems);
                } else {
                    menu.push(..._logisticsMenuItems);
                    menu.push(..._contingencyMenuItems);
                    // menu.push(..._confirmationItems);
                    // menu.push('separator');
                    if (rowNodes.every(({data}) => !data.recruitmentRequestId))
                    {
                        menu.push('separator');
                        menu.push(..._lrfMenuItems);
                    }
                }
                prevMenuExists = true;
            }

            if (_staticColumnDefs[0].children.filter(f => f.field === _column?.colId).length) {
                if (prevMenuExists)
                    menu.push('separator');
                
                if (rowNodes.every(n => (n.data.slot % 1) === 0))
                    menu.push(..._slotMenuItems);
                
                if (!rowNodes.filter(n => n.data.hasChildren).length)
                    menu.push(..._removeMenuItems);

                menu.push(..._refreshSlotNumberItems);
                prevMenuExists = true;
            } else {
                if (prevMenuExists)
                    menu.push('separator');


                menu.push(..._slotBlankMenuItems);


                menu.push('separator');

                menu.push(..._scheduleMenuItems);

                if (isSingleNode && rowNodes[0].data.candidateId)
                    menu.push(..._trainingMenuItems);

                if (_lookups?.workSequence)
                {
                    menu.push('separator');
                    menu.push(..._workSequenceItems(_lookups));
                }
                
                prevMenuExists = true;
            }

            if (rowNodes.filter(r => r.data.candidateId || !r.data.positionId).length === 0)
            {
                if (prevMenuExists)
                    menu.push('separator');
                
                menu.push(..._positionAlignmentItems);
                prevMenuExists = true;
            }

            if (rowNodes.filter(r => r.data.candidateId).length === 0)
            {
                if (prevMenuExists)
                    menu.push('separator');
                
                menu.push(..._candidateSearchItems);
                prevMenuExists = true;
            }
            
            if (_column?.colId === 'slot') {
                // console.log('rowNodes', rowNodes)

                if (prevMenuExists)
                    menu.push('separator');

                prevMenuExists = true;
                menu.push(..._reorderMenuItems(rowNodes))
            }

            if (_staticColumnDefs[0].children.filter(f => f.field === _column?.colId).length)
            {
                if (prevMenuExists)
                    menu.push('separator');
                
                menu.push(..._columnVisibilityItems);
                prevMenuExists = true;
            }
            
            if (prevMenuExists)
                menu.push('separator');
            
            menu.push(..._exportMenuItems);
            prevMenuExists = true;
            
            if (isSingleNode)
            {
                if (prevMenuExists)
                    menu.push('separator');
                
                menu.push(..._auditItems);
                prevMenuExists = true;
            }
            
            return menu;
        }
    }
 } ) ();

export default ContextMenuHelper;