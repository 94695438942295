/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useCallback } from 'react';
import api from "../../../services/api";
import {
    Input, Label, FormGroup, Button, Modal, Container, Row, Col, Alert, InputGroup, InputGroupAddon, InputGroupText
 } from "reactstrap"; 
import _ from "lodash";
import { ComboBox, DropdownBox } from "../../dropdowns";
import Loader from "../../loaders";
import { DatePicker } from '../../date-pickers';
import { formatDate, formatDateZeroTime, Mode } from "../../../utils";
import { TooltipButton } from '../../inputs';
import { ratesValidation } from "./rateValidation";
import TextareaAutosize from "react-autosize-textarea";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import '@progress/kendo-theme-bootstrap/dist/all.css';
import Multiselect from 'multiselect-react-dropdown';

export default function Editor(props) {

    const {
        employmentcategories,
        item,
        onSaved,
        onClose,
        drawerMode,
        setDrawerMode
    } = props;

    const [ itemInEdit, setItemInEdit ] = React.useState({
        ...item, 
        positionIds: [],
        locationIds: [],
        casualLoadingPercentDisplay: ((item.casualLoadingPercent ?? 0) * 100).toFixed(2),
        holidayRatePercentDisplay: ((item.holidayRatePercent ?? 0) * 100).toFixed(2),
        overtimeRatePercentDisplay: ((item.overtimeRatePercent ?? 0) * 100).toFixed(2),
    });
    const [ validationAlert, setValidationAlert ] = React.useState([]);
    const [ loading, setLoading ] = React.useState(false);
    const [ loadingLocations, setLoadingLocations ] = React.useState(false);
    const [ locations, setLocations ] = React.useState([]);
    
    const [ loadingPositions, setLoadingPositions ] = React.useState(false);
    const [positions, setPositions] = React.useState([]);

    const [loadingRosterSequences, setLoadingRosterSequences] = React.useState(false);
    const [rosterSequences, setRosterSequences] = React.useState([]);




    const handleTextChanged = ({ target: { name, value } }) => {
        setItemInEdit({ ...itemInEdit, [name]: value });
    }

    const handlePercentChanged = ({ target: { name, value } }) => {
        //casualLoadingPercent
        //holidayRatePercent
        setItemInEdit({ ...itemInEdit, [name]: (value ?? 0) / 100, [`${name}Display`]: (value ?? 0) });
    }

    const handleNumberChanged = ({ target: { name, value } }) => {
        setItemInEdit({ ...itemInEdit, [name]: value });
    }

    // React.useEffect(() => {
    //     const {
    //         fulltimeRate = 0,
    //         holidayRatePercent = 0,
    //         casualLoadingPercent = 0,
    //         overtimeRatePercent = 0
    //     } = itemInEdit;

    //     const holidayRate = fulltimeRate * holidayRatePercent;
    //     const casualLoadingRate = fulltimeRate * casualLoadingPercent;
    //     const casualNormalRate = (fulltimeRate*1) + casualLoadingRate;
    //     const casualHolidayRate = holidayRate + casualLoadingRate;
    //     //const casualNormalNightRate = 0;
    //     //const casualHolidayNightRate = 0;
    //     const overtimeRate = fulltimeRate * overtimeRatePercent;

    //     setItemInEdit({
    //         ...itemInEdit,
    //         holidayRate: holidayRate.toFixed(2),
    //         casualLoadingRate: casualLoadingRate.toFixed(2),
    //         casualNormalRate: casualNormalRate.toFixed(2),
    //         casualHolidayRate: casualHolidayRate.toFixed(2),
    //         overtimeRate: overtimeRate.toFixed(2)
    //         //casualNormalNightRate: casualNormalNightRate.toFixed(2),
    //         //casualHolidayNightRate: casualHolidayNightRate.toFixed(2)
    //     })

    // }, [itemInEdit.fulltimeRate, itemInEdit.holidayRatePercent, itemInEdit.casualLoadingPercent, itemInEdit.overtimeRatePercent]);

    const handleDateChanged = (value, name) => {
        setItemInEdit({ 
            ...itemInEdit, 
            [name]: !value ? null : formatDateZeroTime(value)
        });     
    }



    const validate = React.useCallback(() => {
        let errors = [ ...validationAlert ];
        _.forEach(ratesValidation, (value) => {
            _.forEach(value.tests, (test) => {
                const exists = _.some(errors, (item) => item.key === value.field && item.type === test.type);
                const result = test.validate(itemInEdit);
                if (result && !exists) {
                    errors.push( { key: value.field, type: test.type, message: test.message } )
                } else if (!result && exists) {
                    _.remove(errors, (item) =>  item.key === value.field && item.type === test.type);
                }
            })
        });
        setValidationAlert([ ...errors ]);
        if (errors.length > 0) {
            return false;
        }
        return true;
    }, [itemInEdit, validationAlert]);

    React.useEffect(() => {
        if (itemInEdit && validationAlert.length > 0) validate();
    }, [itemInEdit]);

    const handleSave = (rate) => {

        if (!validate()) {
            return;
        }

        setLoading(true)

        const data = JSON.stringify(rate);
        const save = rate.rateId > 0 
            ? api.put(`rates/${rate.rateId}`, data)
            : api.post(`rates`, data)

        save
        .then((response) => {
            console.log("Rates Save: ", response);
            onSaved();
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const getPositions = React.useCallback(_.debounce(filter => {
        //if (!filter && filter.length === 0) return;

        setLoadingPositions(true);
        api.get(`/position/searchname?filter=${filter}`)
        .then(({data}) => {
            setPositions(data);
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => setLoadingPositions(false));
    }, 200), []);

    const onPositionsFilterChange = (event) => {
        const filter  = event.filter.value;
        getPositions(filter);
    }

    const onPositionsFilterChangeMultiselect = (filter) => {
        getPositions(filter);
    }

    const onPositionsChange = (event) => {
        console.log("onPositionsChange", event.target.value);
        setItemInEdit({ ...itemInEdit, positions: event.target.value, positionIds: event.target.value.map(o => o.positionId) });
    }

    const onPositionsChangeMultiselect = useCallback((values) => {
        setItemInEdit({ ...itemInEdit, positions: values, positionIds: values.map(v => v.positionId) });
    }, [itemInEdit]);

    // BEGIN MULTI SELECT LOCATION -----------------------------------------------------------------------------------------------------------------------
    const getLocations = React.useCallback(_.debounce(filter => {
        if (!filter && filter.length === 0) return;

        setLoadingLocations(true);
        api.get(`/location/search?filter=${filter}`)
        .then(({data}) => {
            setLocations(data);
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => setLoadingLocations(false));
    }, 200), []);

    const onLocationsFilterChange = (event) => {
        const filter  = event.filter.value;
        getLocations(filter);
    }

    const onLocationsFilterChangeMultiselect = (filter) => {
        getLocations(filter);
    }

    const onLocationsChange = (event) => {
        console.log("onLocationsChange", event.target.value);
        setItemInEdit({ ...itemInEdit, locations: event.target.value, locationIds: event.target.value.map(o => o.locationId) });
    }

    const onLocationsChangeMultiselect = useCallback((values) => {
        setItemInEdit({ ...itemInEdit, locations: values, locationIds: values.map(v => v.locationId) });
    }, [itemInEdit]);

    // END MULTI SELECT LOCATION -------------------------------------------------------------------------------------------------------------------------

    // BEGIN MULTI SELECT ROSTER SEQUENCE -----------------------------------------------------------------------------------------------------------------------
    const getRosterSequences = React.useCallback(_.debounce(filter => {
        if (!filter && filter.length === 0) return;

        setLoadingRosterSequences(true);
        api.get(`/rostersequence/search?filter=${filter}`)
            .then(({ data }) => {
                setRosterSequences(data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoadingRosterSequences(false));
    }, 200), []);

    const onRosterSequencesFilterChangeMultiselect = (filter) => {
        getRosterSequences(filter);
    }


    const onRosterSequencesChangeMultiselect = useCallback((values) => {
        setItemInEdit({ ...itemInEdit, rosterSequences: values, rosterSequenceIds: values.map(v => v.rosterSequenceId) });
    }, [itemInEdit]);

    // END MULTI SELECT ROSTER SEQUENCE -------------------------------------------------------------------------------------------------------------------------

    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    {drawerMode == Mode.EDIT ? `Edit` : `Add`} Rate
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                {validationAlert && validationAlert.length > 0 && <Alert color="warning" className="pa-0 d-flex justify-content-between">
                    <span className="alert-text">
                        <ul className="mb-0">
                            {
                                validationAlert.map((item, index) => <li key={index}>{item.message}</li>)
                            }
                        </ul>
                    </span>
                </Alert>}
                <Container>
                    {(loading) && <Loader />}
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Employment Category
                                    <span className="text-danger">*</span>
                                </Label>
                                <DropdownBox
                                    data={employmentcategories}
                                    selectedItem={itemInEdit.employmentCategory}
                                    onChange={(item) => {
                                        console.log("on component change: ", item);
                                        setItemInEdit( { ...itemInEdit, employmentCategory: item, employmentCategoryId: item ? item.lookupId : null } );
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Position
                                    <span className="text-danger">*</span>
                                </Label>
                                {itemInEdit?.positionId > 0 || drawerMode == Mode.EDIT ? (
                                    <ComboBox 
                                        minLength={0}
                                        endpoint="/position/search"
                                        isLookup={false}
                                        idField="positionId"
                                        valueField="positionName"
                                        selectedItem={itemInEdit.position}
                                        onChange={(item) => {
                                            setItemInEdit( { ...itemInEdit, position: item, positionId: item ? item.positionId : null } );
                                        }}
                                    />
                                ) : (
                                    <Multiselect
                                        options={positions}
                                        loading={loadingPositions}
                                        displayValue="positionName"
                                        placeholder="Type to search"
                                        selectedValues={itemInEdit?.positions}
                                        onSearch={onPositionsFilterChangeMultiselect}
                                        onSelect={(selected) => {
                                            onPositionsChangeMultiselect(selected);
                                        }}
                                        showCheckbox={true}
                                        showArrow={true}
                                        closeOnSelect={true}
                                            />
                                )}
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Location
                                </Label>
                                {itemInEdit?.locationId > 0 || drawerMode == Mode.EDIT ? (
                                    <ComboBox 
                                        minLength={0}
                                        endpoint="/location/search"
                                        isLookup={false}
                                        idField="locationId"
                                        valueField="locationName"
                                        selectedItem={itemInEdit?.location}
                                        onChange={(item) => {
                                            setItemInEdit( { ...itemInEdit, location: item, locationId: item ? item.locationId : null } );
                                        }}
                                    />
                                ) : (
                                    <Multiselect
                                        options={locations}
                                        loading={loadingLocations}
                                        displayValue="locationName"
                                        placeholder="Type to search"
                                        selectedValues={itemInEdit?.locations}
                                        onSearch={onLocationsFilterChangeMultiselect}
                                        onSelect={(selected) => {
                                            onLocationsChangeMultiselect(selected);
                                        }}
                                        showCheckbox={true}
                                        showArrow={true}
                                        closeOnSelect={true}
                                    />
                                )}
                                
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Roster Sequence
                                </Label>
                                {itemInEdit?.rosterSequenceId > 0 || drawerMode == Mode.EDIT ? (
                                    <ComboBox
                                        minLength={0}
                                        endpoint="/rostersequence/search"
                                        isLookup={false}
                                        idField="rosterSequenceId"
                                        valueField="description"
                                        selectedItem={itemInEdit?.rosterSequence}
                                        onChange={(item) => {
                                            setItemInEdit({ ...itemInEdit, rosterSequence: item, rosterSequenceId: item ? item.rosterSequenceId : null });
                                        }}
                                    />
                                ) : (
                                    <Multiselect
                                        options={rosterSequences}
                                        loading={loadingRosterSequences}
                                        displayValue="description"
                                        placeholder="Type to search"
                                        selectedValues={itemInEdit?.rosterSequences}
                                        onSearch={onRosterSequencesFilterChangeMultiselect}
                                        onSelect={(selected) => {
                                            onRosterSequencesChangeMultiselect(selected);
                                        }}
                                        showCheckbox={true}
                                        showArrow={true}
                                        closeOnSelect={true}
                                    />
                                )}

                            </FormGroup>
                        </Col> 
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Start Date
                                    <span className="text-danger">*</span>
                                </Label>
                                <DatePicker
                                    name="startDate"
                                    id="startDate"
                                    type="text"
                                    required
                                    value={formatDate(itemInEdit.startDate)}
                                    onChange={handleDateChanged}
                                    closeOnSelect
                                    />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    End Date (leave empty if ongoing)
                                </Label>
                                <DatePicker
                                        name="endDate"
                                        id="endDate"
                                        type="text"
                                        value={formatDate(itemInEdit.endDate)}
                                        onChange={handleDateChanged}
                                        closeOnSelect
                                    />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Fulltime Rate
                                    <span className="text-danger">*</span>
                                </Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        name="fulltimeRate"
                                        id="fulltimeRate"
                                        type="number"
                                        value={itemInEdit.fulltimeRate ?? ""}
                                        onChange={handleTextChanged}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Full Time Rate DS
                                    <span className="text-danger">*</span>
                                </Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        name="fullTimeRateDs"
                                        id="fullTimeRateDs"
                                        type="number"
                                        value={itemInEdit.fullTimeRateDs ?? ""}
                                        onChange={handleTextChanged}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Full Time Rate NS
                                    <span className="text-danger">*</span>
                                </Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        name="fullTimeRateNs"
                                        id="fullTimeRateNs"
                                        type="number"
                                        value={itemInEdit.fullTimeRateNs ?? ""}
                                        onChange={handleTextChanged}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        {/* <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Casual Loading 
                                    <span className="text-danger">*</span>
                                </Label>
                                <InputGroup>
                                    <Input
                                        name="casualLoadingPercent"
                                        id="casualLoadingPercent"
                                        type="number"
                                        //value={(itemInEdit.casualLoadingPercent ?? 0) * 100}
                                        value={itemInEdit.casualLoadingPercentDisplay}
                                        onChange={handlePercentChanged}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>%</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Weekend/Public Holiday
                                    <span className="text-danger">*</span>
                                </Label>
                                <InputGroup>
                                    <Input
                                        name="holidayRatePercent"
                                        id="holidayRatePercent"
                                        type="number"
                                        //value={(itemInEdit.holidayRatePercent ?? 0) * 100}
                                        value={itemInEdit.holidayRatePercentDisplay}
                                        onChange={handlePercentChanged}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>%</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>                        
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Overtime
                                    <span className="text-danger">*</span>
                                </Label>
                                <InputGroup>
                                    <Input
                                        name="overtimeRatePercent"
                                        id="overtimeRatePercent"
                                        type="number"
                                        value={itemInEdit.overtimeRatePercentDisplay}
                                        onChange={handlePercentChanged}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>%</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col> */}
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Overtime Rate
                                </Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{backgroundColor: "#ffffff"}}>$</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        name="overtimeRate"
                                        id="overtimeRate"
                                        type="number"
                                        //readOnly
                                        value={itemInEdit.overtimeRate ?? ""}
                                        onChange={handleTextChanged}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Casual Normal Time
                                </Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{backgroundColor: "#ffffff"}}>$</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        name="casualNormalRate"
                                        id="casualNormalRate"
                                        type="number"
                                        value={itemInEdit.casualNormalRate ?? ""}
                                        onChange={handleTextChanged}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Casual Nightshift
                                </Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{backgroundColor: "#ffffff"}}>$</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        name="casualNormalNightRate"
                                        id="casualNormalNightRate"
                                        type="number"
                                        onChange={handleTextChanged}
                                        value={itemInEdit.casualNormalNightRate ?? ""}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Casual Nightshift Weekend Rate
                                </Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{backgroundColor: "#ffffff"}}>$</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        name="casualHolidayNightRate"
                                        id="casualHolidayNightRate"
                                        type="number"
                                        onChange={handleTextChanged}
                                        value={itemInEdit.casualHolidayNightRate ?? ""}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Casual NS (if not std%)
                                </Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        name="casualNsnotStandard"
                                        id="casualNsnotStandard"
                                        type="number"
                                        onChange={handleTextChanged}
                                        value={itemInEdit.casualNsnotStandard ?? ""}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Full Time Offsite Rate
                                </Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        name="fullTimeOffsiteRate"
                                        id="fullTimeOffsiteRate"
                                        type="number"
                                        onChange={handleTextChanged}
                                        value={itemInEdit.fullTimeOffsiteRate ?? ""}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Casual Offsite Rate
                                </Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        name="casualOffsiteRate"
                                        id="casualOffsiteRate"
                                        type="number"
                                        onChange={handleTextChanged}
                                        value={itemInEdit.casualOffsiteRate ?? ""}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Full Time Training
                                </Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        name="fullTimeTraining"
                                        id="fullTimeTraining"
                                        type="number"
                                        onChange={handleTextChanged}
                                        value={itemInEdit.fullTimeTraining ?? ""}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Casual Training
                                </Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        name="casualTraining"
                                        id="casualTraining"
                                        type="number"
                                        onChange={handleTextChanged}
                                        value={itemInEdit.casualTraining ?? ""}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Casual Loading Rate
                                </Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        name="casualLoadingRate"
                                        id="casualLoadingRate"
                                        type="number"
                                        // readOnly
                                        onChange={handleTextChanged}
                                        value={itemInEdit.casualLoadingRate ?? ""}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        {/* <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Weekend/Public Holiday Rate
                                </Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{backgroundColor: "#e9ecef"}}>$</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        name="holidayRate"
                                        id="holidayRate"
                                        type="number"
                                        readOnly
                                        value={itemInEdit.holidayRate ?? ""}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Casual Weekend Rate
                                </Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText style={{backgroundColor: "#e9ecef"}}>$</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        name="casualHolidayRate"
                                        id="casualHolidayRate"
                                        type="number"
                                        readOnly
                                        value={itemInEdit.casualHolidayRate ?? ""}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col> */}
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Notes
                                </Label>
                                <InputGroup>
                                    <Input
                                        name="notes"
                                        id="notes"
                                        type="textarea"
                                        onChange={handleTextChanged}
                                        value={itemInEdit.notes ?? ""}
                                        tag={TextareaAutosize}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        
                    </Row>
        
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave(itemInEdit)}
                    type="button"
                >
                    Save Changes
                </Button>
            </div>
        </Modal>
    )
}