export const jobOrderValidation = [
    {
        field: "recruitmentRequestId",
        tests: [
            {
                type: "required",
                message: "Recruitment Request is required.",
                validate: (obj) => !obj.recruitmentRequestId,
            }
        ]
    },   
    {
        field: "positions",
        tests: [
            {
                type: "required",
                message: "At least one Job Order Position must be selected.",
                validate: (obj) => (!obj.positions || obj.positions.length === 0),
            }
        ]
    },   
    {
        field: "milestones",
        tests: [
            {
                type: "required",
                message: "At least one Job Order Milestone is required.",
                validate: (obj) => (!obj.milestones || obj.milestones.length === 0),
            }
        ]
    }
];