/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useMemo, useCallback, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button, Col, Container, FormGroup, Input, Label, Modal, Row, Spinner } from 'reactstrap';
import { EditorState, ContentState, convertToRaw, SelectionState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { useTable, useFlexLayout, useFilters, useSortBy } from 'react-table';
import jsonFormData from "json-form-data";
import { DatePicker } from '../../../date-pickers';
import Loader from '../../../loaders';
import api from "../../../../services/api";
import fileDownload from "js-file-download";
import moment from 'moment';
import { FileDropzone, DropzoneList } from '../../../FileDropzone';
import { DefaultColumnFilter, DateColumnFilter } from '../../../react-table/filters';
import { TooltipButton } from '../../../inputs';
import Grid from '../../../Grid';
import { currencyFormatterAUD, formatDate, momentToDateOnly } from '../../../../utils';
import VacancyPreview from './VacancyPreview';
import { FormatFileSize } from '../../../../helpers/StringHelper'
import { formatDateZeroTime } from '../../../../utils/index';

function ExternalLinkModal(props) {
    const { identifier, id, onClose } = props;
    const [url, setUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [copied, setCopied] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        setLoading(true);
        const apiCalls = [
            api.get(`/lookup/ExternalHostUrl`),
            api.get(`/joborder/vacancy?id=${id}`),
        ];

        Promise.all(apiCalls)
            .then((responses) => {
                const host = responses[0].data[0]?.lookupValue;
                setUrl(`${host}application?id=${identifier}`);
                setData(responses[1]?.data);
            })
            .catch((error) => {
                console.log({ error });
            })
            .finally(() => setLoading(false))
            ;
    }, [id]);

    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Vacancy Link
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                <Container fluid className="d-flex flex-wrap justify-content-center align-items-center">
                    {
                        loading ?
                            <Spinner color="default" type="border" />
                            :
                            <>
                                <Row className="m-0 p-0 w-100">
                                    <Col className="m-0 p-0" lg={12}>
                                        <span className="text-dark" style={{ fontSize: "0.9rem" }}>{url}</span>
                                        {
                                            copied &&
                                            <span className="text-warning ml-2" style={{ fontSize: "0.9rem" }}><i>- Copied!</i></span>
                                        }
                                    </Col>
                                </Row>
                                <Row className="m-0 mt-4 p-0 w-100">
                                    <Col className="m-0 p-0" lg={12}>
                                        <span><b>Details</b></span>
                                    </Col>
                                </Row>
                                <Row className="m-0 mt-2 p-0 w-100" style={{ fontSize: "0.9rem" }}>
                                    <Col className="m-0 p-0" lg={6}>
                                        <span>Project:<b className="ml-2">{data?.jobOrderNavigation.recruitmentRequest.project?.lookupValue ?? "-"}</b></span>
                                    </Col>
                                    <Col className="m-0 p-0" lg={6}>
                                        <span>Location:<b className="ml-2">{data?.jobOrderNavigation.recruitmentRequest.location?.lookupValue ?? "-"}</b></span>
                                    </Col>
                                </Row>
                                <Row className="m-0 mt-2 p-0 w-100" style={{ fontSize: "0.9rem" }}>
                                    <Col className="m-0 p-0" lg={6}>
                                        <span>Position:<b className="ml-2">{data?.jobOrderNavigation.position?.lookupValue ?? "-"}</b></span>
                                    </Col>
                                    <Col className="m-0 p-0" lg={6}>
                                        <span>Scope of Work:<b className="ml-2">{data?.jobOrderNavigation.scopeOfWork ?? "-"}</b></span>
                                    </Col>
                                </Row>
                                <Row className="m-0 mt-2 p-0 w-100" style={{ fontSize: "0.9rem" }}>
                                    <Col className="m-0 p-0" lg={6}>
                                        <span>Vacancy Start:<b className="ml-2">{data?.publishStartDate ? moment(data.publishStartDate).format("DD-MM-YYYY") : "-"}</b></span>
                                    </Col>
                                    <Col className="m-0 p-0" lg={6}>
                                        <span>Vacancy End:<b className="ml-2">{data?.publishEndDate ? moment(data.publishEndDate).format("DD-MM-YYYY") : "-"}</b></span>
                                    </Col>
                                </Row>
                                <Row className="m-0 mt-2 p-0 w-100" style={{ fontSize: "0.9rem" }}>
                                    <Col className="m-0 p-0" lg={12}>
                                        <span>Expected Start:<b className="ml-2">{data?.jobOrderNavigation.startDateExpected ? moment(data.jobOrderNavigation.startDateExpected).format("DD-MM-YYYY") : "-"}</b></span>
                                    </Col>
                                </Row>
                                <Row className="m-0 mt-2 p-0 w-100" style={{ fontSize: "0.9rem" }}>
                                    <Col className="m-0 p-0" lg={12}>
                                        <span>Rates:<b className="ml-2">{currencyFormatterAUD(data?.fulltimeRate) ?? "-"}</b></span>
                                    </Col>
                                </Row>
                                <Row className="m-0 mt-2 p-0 w-100" style={{ fontSize: "0.9rem" }}>
                                    <Col className="m-0 p-0" lg={12}>
                                        <span>
                                            Recruiter:
                                            <b className="ml-2">{data?.recruitmentPerson?.displayName ?? "-"}</b>
                                            <b className="ml-2"><i className="fas fa-phone-alt mr-1" />{data?.phoneNumber ?? "-"}</b>
                                            <b className="ml-2"><i className="fas fa-envelope mr-1" />{data?.recruitmentPerson?.email ?? "-"}</b>
                                        </span>
                                    </Col>
                                </Row>
                            </>
                    }
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="default"
                    onClick={() => {
                        navigator.clipboard.writeText(url);
                        setCopied(true);
                    }}
                    type="button"
                    disabled={!url || url === ""}
                >
                    Copy Link to Clipboard
                </Button>
            </div>
        </Modal>
    );
}

function VacancyEditor(props) {

    // Props
    const { onClose, onSave, jobOrder, refresh } = props;


    // Regular variables
    const [loading, setLoading] = useState(false);
    const [itemInEdit, setItemInEdit] = useState({ jobOrderId: jobOrder?.jobOrderId, publishStartDate: jobOrder?.publishStartDate, publishEndDate: jobOrder?.publishEndDate, vacancyDescription: jobOrder?.vacancyDescription });
    const [showRemovalConfirmation, setShowRemovalConfirmation] = useState(false);
    const [saved, setSaved] = useState(jobOrder?.publishStartDate ? true : false);
    const [showExternalLink, setShowExternalLink] = useState(false);
    const [showVacancyPreview, setShowVacancyPreview] = useState(false);
    const [saveCount, setSaveCount] = useState(0);
    const [documentData, setDocumentData] = useState(null);
    const [documentList, setDocumentList] = useState([]);
    const [downloadingDocument, setDownloadingDocument] = useState(null);
    const [validationMessage, setValidationMessage] = useState(null);

    const [selectedEmail, setSelectedEmail] = React.useState(null);

    const skipPageResetRef = React.useRef();
    const [lookups, setLookups] = React.useState({});
    const [filters, setFilters] = React.useState([]);
    const [showPleaseSaveVacancyPreview, setShowPleaseSaveVacancyPreview] = useState(false);
    const [fileConfig, setFileConfig] = useState(null);

    // Editor variables
    const [firstLoadEditor, setFirstLoadEditor] = React.useState(true);
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setItemInEdit({ ...itemInEdit, vacancyDescription: draftToHtml(convertToRaw(editorState.getCurrentContent())) });
        //setSelectedEmail({ ...selectedEmail, lookupValue: draftToHtml(convertToRaw(editorState.getCurrentContent())) });
    }

    // Auto load vacancy description editor content
    useEffect(() => {
        if (!firstLoadEditor || itemInEdit?.vacancyDescription == null) {
            return;
        }

        // Use a regular expression to match and remove the font-size and font-family styles
        var newVacancyDescription = itemInEdit?.vacancyDescription;
        newVacancyDescription = newVacancyDescription.replace(/font-size: [^;]+;/g, '');
        newVacancyDescription = newVacancyDescription.replace(/font-family: [^;]+;/g, '');


        const contentBlock = htmlToDraft(newVacancyDescription);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const contentEditorState = EditorState.createWithContent(contentState);

            setEditorState(contentEditorState);
            setFirstLoadEditor(false);
        }
    }, [firstLoadEditor, jobOrder, itemInEdit])

    // Refs
    const handleDateChanged = useCallback((name, value) => {
        // console.log({name, value});
        setItemInEdit({
            ...itemInEdit,
            [name]: value ? momentToDateOnly(value) : null
            //[name]: value ? formatDateZeroTime(value) : null
        });
    }, [itemInEdit]);

    useEffect(() => {
        console.log('itemInEdit', itemInEdit);
    }, [itemInEdit]);


    useEffect(() => {
        setLoading(true);
        const apiCalls = [
            api.get(`/lookup/fileconfig`),
        ];

        Promise.all(apiCalls)
            .then((responses) => {
                const fileconfig = { ...responses[0].data };
                setFileConfig(fileconfig);
            })
            .catch((error) => {
                console.log({ error });
            })
            .finally(() => setLoading(false))
            ;
    }, []);



    //useEffect(() => {
    //    console.log('itemInEdit', itemInEdit);
    //}, [itemInEdit])

    // BEGIN FILE HANDLER ------------------------------------------------------------------------------------------------------------------------------------------------------------
    const downloadAttachment = (fileInfo) => {
        if (fileInfo instanceof File) {
            fileDownload(fileInfo, fileInfo.name);
        } else {
            setLoading(true);
            let url = `candidate/download-document/${fileInfo.candidateDocumentId}`;
            api.get(url, { responseType: 'blob' })
                .then(blob => {
                    fileDownload(blob.data, fileInfo.filename);
                }).catch(error => {
                    console.error(error);
                }).finally(() => setLoading(false))
        }
    }


    const files = React.useMemo(() => {
        if (documentData?.file)
            return <DropzoneList
                name={documentData.file.name}
                size={documentData.file.size}
                download={downloadAttachment}
                itemInEdit={documentData.file}
            />;

        if (documentData?.filename) {
            return <DropzoneList
                name={documentData.filename}
                size={documentData.fileSizeInKbytes}
                download={downloadAttachment}
                itemInEdit={documentData}
            />;
        }

        return null;
    }, [documentData]);


    // Auto empty document data and save to document list
    useEffect(() => {


        if (documentData == null) {
            return;
        }

        // Validation here
        if (documentData && fileConfig) {
            var invalidFileType = !fileConfig.allowedFileType.some(s => s && s.toLowerCase() == documentData.fileType);
            var exceedFileSize = documentData.file.size > fileConfig.maxFileSizeByte;

            // If invalid file type or exceed file size, show error message
            if (invalidFileType && fileConfig.validateVacancyAttachmentFileType) {
                setValidationMessage("Invalid file type. Please upload a valid file type.");
                setDocumentData(null);
                return;
            }

            // If invalid file type or exceed file size, show error message
            else if (exceedFileSize && fileConfig.validateVacancyAttachmentFileSize) {
                setValidationMessage(`File size exceeded. Please upload a file with a maximum size of ${FormatFileSize(fileConfig.maxFileSizeByte)}.`);
                setDocumentData(null);
                return;
            }

        }

        console.log('useEffect', 'documentData', documentData);
        if ((documentData.filename || documentData.file)) {
            const documents = [...documentList];
            //newDocumentData.fileContent = 
            //var newDocumentData = { ...documentData };
            //const index = documents.findIndex(val => val.index === documentData.index);
            //if (index >= 0) {
            //    documents[index] = documentData;
            //} else {
            documents.push(documentData);
            //}
            setDocumentList([...documents]);
            setDocumentData(null);
        }
    }, [documentData, fileConfig]);

    useEffect(() => {
        console.log('documentList', documentList);
    }, [documentList]);


    // END FILE HANDLER ------------------------------------------------------------------------------------------------------------------------------------------------------------

    // BEGIN FILE LIST HANDLER ------------------------------------------------------------------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (documentList?.length > 0) {
            return;
        }

        if (jobOrder?.jobOrderId) {
            api.get(`joborder/${jobOrder.jobOrderId}/vacancyattachments`)
                .then(response => {
                    setDocumentList(response.data);
                }).catch(error => {
                    console.error(error);
                });
        }
    }, []);



    // END FILE LIST HANDLER ------------------------------------------------------------------------------------------------------------------------------------------------------------


    useEffect(() => {
        if (!downloadingDocument) {
            return;
        }

        setLoading(true);
        var url = `joborder/vacancy-attachment-by-id/${downloadingDocument.jobOrderVacancyAttachmentId}-${downloadingDocument.jobOrderId}/download/`;
        var filename = `${downloadingDocument.filename}`;


        api.get(url, { responseType: 'blob' })
            .then(blob => {
                // console.log(blob);
                //var filename = /(?<=attachment;.*filename=").*(?=";)/.exec(blob.headers["content-disposition"])[0];
                fileDownload(blob.data, filename);
            }).catch(error => {
                console.error(error);
                //setErrorMessage(`${errorMessageOpening} ${error.message} `);
                //setLoading(false);
            }).finally(() => setLoading(false))

        setDownloadingDocument(null);

    }, [downloadingDocument]);


    const handleSave = useCallback((close, preview = false) => {
        setLoading(true);

        try {

            // Initiate form data
            var item = {
                ...itemInEdit,
                jobOrderVacancyAttachments: [...documentList]
            };
            console.log('handleSave', 'item', item);
            var form = jsonFormData(item, {
                initialFormData: new FormData(),
                showLeafArrayIndexes: true,
                includeNullValues: false,
                mapping: function (value) {
                    // if (typeof value === 'boolean') {
                    //     return +value ? '1': '0';
                    // }
                    return value;
                }
            });

            // Handle file upload
            const formData = new FormData();
            form.forEach((value, key, parent) => {

                if (value instanceof File || value instanceof Blob) {
                    formData.append(key.replace(/(\[)([a-zA-Z]+)(\])/, ".$2").replace(/\[\d+\]$/, ""), value, value.name)
                }
                else {
                    formData.append(key.replace(/(\[)([a-zA-Z]+)(\])/, ".$2"), value)
                }
            });

            let url = `${process.env.REACT_APP_WEB_API_BASE_URI}/joborder/setvacancy`;
            let method = "POST";

            const save = api({ url, method, data: formData })

            save
                .then((response) => {
                    setLoading(false);
                    setSaveCount(saveCount + 1);
                    setSaved(true);

                    if (close)
                        onSave();
                    else if (preview)
                        setShowVacancyPreview(true);
                    //else
                    //    refresh();
                })
                .catch((error) => {
                    console.log({ error });
                    setLoading(false);
                });

        }
        catch (error) {
            setLoading(false);
            console.error(error, error.message);


            return;
        }

        //api.post(`/joborder/setvacancy`, JSON.stringify({ ...itemInEdit }))
        //    .then(() => {
        //        setLoading(false);
        //        setSaveCount(saveCount + 1);
        //        setSaved(true);

        //        if (close)
        //            onSave();
        //        //else
        //        //    refresh();
        //    })
        //    .catch((error) => {
        //        console.log({ error });
        //        setLoading(false);
        //    })
        //    ;
    }, [itemInEdit, onSave, documentList]);

    const onSaveClick = useCallback((close = true, preview = false) => {
        if (!itemInEdit.publishStartDate) {
            setShowRemovalConfirmation(true);
            return;
        }

        handleSave(close, preview);
    }, [itemInEdit, handleSave]);

    const onCloseClick = useCallback(() => {
        if (saveCount) {
            onSave();
            return;
        }
        onClose();
    }, [onClose, saveCount, onSave]);

    const onDeleteFile = useCallback((original) => {
        setLoading(true);
        const index = documentList.findIndex(val => {
            if (original.jobOrderVacancyAttachmentId)
                return val.jobOrderVacancyAttachmentId === original.jobOrderVacancyAttachmentId;

            return val.index === original.index;
        });
        //console.log("Delete: ", { documentData: original, itemInEdit, index, candidateDocuments: documentList });

        if (index < 0) {
            setLoading(false);
            return;
        }

        const documents = [...documentList];
        documents.splice(index, 1);
        setDocumentList([...documents]);
        setLoading(false);
    }, [documentList, setDocumentList, setLoading])


    return (
        <Modal
            isOpen={true}
            className={'modal-xl'}
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Vacancies Setting
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onCloseClick}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                {/* <Container> */}
                {(loading) && <Loader />}
                <Row>
                    <Col lg={6} xs={12}>
                        <FormGroup>
                            <Label>
                                Start Date
                                <span className="text-danger">*</span>
                            </Label>
                            <DatePicker
                                name="publishStartDate"
                                id="publishStartDate"
                                type="text"
                                required
                                value={itemInEdit?.publishStartDate ? formatDate(itemInEdit.publishStartDate) : null}
                                onChange={(value) => {
                                    handleDateChanged("publishStartDate", value);
                                }}
                                closeOnSelect
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={6} xs={12}>
                        <FormGroup>
                            <Label>
                                End Date
                                <span className="text-danger">*</span>
                            </Label>
                            <DatePicker
                                name="publishEndDate"
                                id="publishEndDate"
                                type="text"
                                required
                                value={itemInEdit?.publishEndDate ? formatDate(itemInEdit.publishEndDate) : null}
                                onChange={(value) => {
                                    handleDateChanged("publishEndDate", value);
                                }}
                                closeOnSelect
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <Label>
                                Vacancies Description
                            </Label>
                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName editor-min-height-3-lines"
                                onEditorStateChange={onEditorStateChange}
                                toolbar={{
                                    options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'remove', 'history'], // Removed 'fontSize' and 'fontFamily'
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <Label>
                                File
                                <span className="text-danger">*</span>
                            </Label>

                            {/*<Grid
                                rows={rows}
                                tableProps={getTableProps()}
                                headerGroups={headerGroups}
                                tableBodyProps={getTableBodyProps()}
                                prepareRow={prepareRow}
                                height='100%'
                            />*/}

                            <Row>

                                {// Foreach documentList
                                    documentList && documentList.map((original, i, a) => {
                                        return (<Col xs={12} className={`mb-2 mt-1`}>
                                            <TooltipButton
                                                id={`delete_${original.jobOrderVacancyAttachmentId}`}
                                                title="Delete"
                                                className="btn-icon mr-2"
                                                color="warning"
                                                size="xsm"
                                                type="button"
                                                onClick={() => { onDeleteFile(original); }}
                                            >
                                                <i className="fas fa-trash-alt pt-1"></i>
                                            </TooltipButton>

                                            <u className="text-underline"
                                                onClick={() => {
                                                    if (!original?.jobOrderVacancyAttachmentId) {
                                                        return;
                                                    }
                                                    setDownloadingDocument({ ...original });
                                                }}
                                            >{original.filename}</u>
                                        </Col>);
                                    })}
                            </Row>



                            <Row>

                                {!!fileConfig?.maxFileSizeByte &&
                                    <Col xs={12} className="mt-3">
                                        <div className="alert alert-warning">
                                            Max file size is {FormatFileSize(fileConfig.maxFileSizeByte)}
                                        </div>
                                    </Col>
                                }
                                <FileDropzone
                                    files={files}
                                    onDrop={([file]) => {
                                        setDocumentData({ ...documentData, filename: file.name, file, fileType: file.type, index: documentList?.length ? documentList?.length - 1 : 0 });
                                        //const reader = new FileReader();
                                        //reader.onload = (event) => {
                                        //    setDocumentData({
                                        //        ...documentData,
                                        //        filename: file.name,
                                        //        file,
                                        //        fileContent: event.target.result
                                        //    });
                                        //};
                                        //reader.readAsDataURL(file);
                                    }}
                                />
                            </Row>
                        </FormGroup>
                    </Col>
                    <Col lg={12}>
                        <Button
                            disabled={!saved}
                            type="button"
                            color="default"
                            onClick={() => setShowExternalLink(true)}
                        >
                            Show Link
                        </Button>
                        <Button
                            disabled={!saved}
                            type="button"
                            color="default"
                            className="ml-3"
                            onClick={() => onSaveClick(false, true)}
                        >
                            Save & Show Preview
                        </Button>
                    </Col>
                </Row>
                {/* </Container> */}
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={onCloseClick}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => onSaveClick(false)}
                    type="button"
                    disabled={(!itemInEdit?.publishStartDate && itemInEdit?.publishEndDate) || (!itemInEdit?.publishStartDate && itemInEdit?.publishEndDate)}
                >
                    Save
                </Button>
                <Button
                    color="info"
                    onClick={() => onSaveClick()}
                    type="button"
                    disabled={(!itemInEdit?.publishStartDate && itemInEdit?.publishEndDate) || (!itemInEdit?.publishStartDate && itemInEdit?.publishEndDate)}
                >
                    Save & Close
                </Button>
            </div>
            {
                showRemovalConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Confirm"
                    confirmBtnBsStyle="danger"
                    title={<h4 className="mb-0">Vacancy Setting Clearance Confirmation</h4>}
                    onConfirm={() => handleSave()} // need to add loading indicator on confirm
                    onCancel={() => setShowRemovalConfirmation(false)}
                    focusCancelBtn
                >
                    <p>This will clear the vacancy setting. Talents will not be able to see this vacancy anymore.</p>
                    <p>Are you sure you want to continue?</p>
                </SweetAlert>
            }
            {
                showExternalLink &&
                <ExternalLinkModal
                    identifier={jobOrder?.identifier}
                    id={jobOrder?.jobOrderId}
                    onClose={() => setShowExternalLink(false)}
                    data={jobOrder}
                />
            }
            {
                showVacancyPreview &&
                <VacancyPreview
                    onClose={() => setShowVacancyPreview(false)}
                    itemInEdit={{ ...itemInEdit }}
                    jobOrder={{ ...jobOrder }}
                    documentList={{ ...documentList }}
                />
            }
            {
                showPleaseSaveVacancyPreview &&
                <SweetAlert
                    warning
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    title={<h4 className="mb-0">Vacancy Preview</h4>}
                    onConfirm={() => setShowPleaseSaveVacancyPreview(false)} // need to add loading indicator on confirm
                    onCancel={() => setShowPleaseSaveVacancyPreview(false)}
                    focusCancelBtn
                >
                    <p>Please click Save button before using the preview.</p>
                </SweetAlert>
            }
            {
                validationMessage &&
                <SweetAlert
                    warning
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    title={<h4 className="mb-0">Vacancy Preview</h4>}
                    onConfirm={() => setValidationMessage(null)} // need to add loading indicator on confirm
                    onCancel={() => setValidationMessage(null)}
                    focusCancelBtn
                >
                    <p>{validationMessage}</p>
                </SweetAlert>
            }
        </Modal>
    );
};

export default VacancyEditor;
