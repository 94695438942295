import React from 'react';
import api from "../../../services/api";
import qs from "qs";
import {
   Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Label
} from "reactstrap";
import ContractTermEditor from "./Editor";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../alerts";
import { TooltipButton } from '../../inputs';
import { DefaultColumnFilter } from "../../react-table/filters";
import { useTable, useExpanded, useFilters, useSortBy, useFlexLayout } from 'react-table';
import Loader from "../../loaders";
import Grid from "../../Grid";
import { ComboBox } from "../../dropdowns";
import { useHistory } from "react-router-dom";
import { EditSeqNo } from '../ContractTerm';

export default function List(props) {
    const {
        location
    } = props;

    const project = location?.state?.project;

    const [ data, setData ] = React.useState([]);
    const [ loading, setLoading ] = React.useState(false);
    const [ itemInEdit, setItemInEdit ] = React.useState(null);
    const [ editSeqNoMode, setEditSeqNoMode ] = React.useState(false);
    const [ editSeqNoModeList, setEditSeqNoModeList ] = React.useState([]);
    const [ selectedSeqNoMode, setSelectedSeqNoMode ] = React.useState({});
    const [ filters, setFilters ] = React.useState([]);

    const defaultSort = React.useMemo(() => [{ id: "sectionType", desc: false }],
        []
    );
    
    const [ sortBy, setSortBy ] = React.useState(defaultSort);

    const [ selectedProject, setSelectedProject ] = React.useState(project ?? null);
    const [ pageNumber, setPageNumber ] = React.useState(1);
    const [ pageSize, setPageSize ] = React.useState(10);
    const [ totalRecords, setTotalRecords ] = React.useState(0);
    const [ deleteAlert, setDeleteAlert ] = React.useState([]);
    const skipPageResetRef = React.useRef();
    const history = useHistory();
    const apiurl = "projectcontractterm";
    
    const loadData = React.useCallback(() => {
        if(!selectedProject)
            return;

        const queryString = qs.stringify({filters, sortBy}, { allowDots: true });
        setItemInEdit(null);
        setData([]);
        skipPageResetRef.current = true;

        setLoading(true);
        api.get(`/${apiurl}/${selectedProject.projectId}/${(pageNumber - 1) * pageSize}/${pageSize}${queryString ? `?${queryString}` : ""}`)
        .then(({data}) => {
            setData(data.data ?? []);

            if(!data.data)
                setEditSeqNoModeList([]);

            if(data.data)
            {
                const tmpData = [];
                data.data.map((item, idx) => {
                    tmpData.push({key: idx, isSelected: false});
                });
                setEditSeqNoModeList(tmpData);
                setSelectedSeqNoMode(tmpData[0]);
            }

            setTotalRecords(data.total);
        }).catch((error) => {
            console.error("error: ", error);
        }).finally(() => setLoading(false));
    }, [filters, sortBy, selectedProject, pageNumber, pageSize]);

    const saveSeqNo = (projectTermId, seqNo, selectedItem) => {
        if (seqNo <= 0)
            return;
        
        setLoading(true);

        api.put(`/${apiurl}/seqno/${projectTermId}/${seqNo}`)
        .catch((error) => {
            console.error("error: ", error);
        }).finally(() => {
            setEditSeqNoMode(false);
            selectedItem.isSelected = false;
            setLoading(false);
        });
    }
    
    const handleDelete = (item) => {
        const success = () => {
            setLoading(false);
            setDeleteAlert([<DeleteSuccessAlert onConfirm={() => setDeleteAlert([])} />]);
            if (data.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1);
            } else {
                loadData();
            }
        };
        const failure = () => {
            setLoading(false);
            setDeleteAlert([<DeleteFailedAlert onConfirm={() => setDeleteAlert([])} />]);
        };
        const events = {
            onConfirm: () => {
                setLoading(true);
                api.delete(`/${apiurl}/${item.projectContractTermId}`).then(success).catch(failure);
            },
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert {...events} />])
    }

    React.useEffect(() => {
        if(selectedProject)
            loadData();
    }, [selectedProject, pageNumber, pageSize]);

    React.useEffect(() => {
        if(!editSeqNoMode)
            loadData();
    }, [editSeqNoMode, filters, sortBy]);

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
                })
            },
        }),
        []
    )

    const columns = React.useMemo(() => [
        {
            Header: "Section Type",
            id: "sectionType",
            accessor: (row) => row.contractTerm.sectionType.lookupValue,
            filter: "text",
            width: 300,
            disableSortBy: false,
        },
        {
            Header: "Seq. No (Per Type)",
            id: "seqNo",
            Cell: ( { row: { original, index } } ) => {
                const selectedItem = editSeqNoModeList.filter(x => x.key === index)[0];

                return (
                    <EditSeqNo
                        id={original.projectContractTermId}
                        seqNo={original.seqNo}
                        status={selectedItem ?? {}}
                        onEdit={() => {
                            if(selectedItem)
                            {
                                selectedItem.isSelected = true;
                                const otherItem = editSeqNoModeList.filter(x => x.key !== index);

                                if(otherItem.length > 0)
                                    otherItem.map(x => x.isSelected = false);

                                setSelectedSeqNoMode(selectedItem);
                            }
                            
                            setEditSeqNoMode(true);
                        }}
                        onSave={(projectTermId, seqNo) => saveSeqNo(projectTermId, seqNo, selectedItem)}
                        onClose={() => {
                            selectedItem.isSelected = false;
                            setSelectedSeqNoMode({});
                        }}
                    />
                );
            },
            width: 150,
        },
        {
            Header: "Contract Term Code",
            id: "contractTermCode",
            accessor: (row) => row.contractTerm.contractTermCode,
            filter: "text",
            width: 300,
            disableSortBy: false,
        },
        {
            Header: "Contract Term Title",
            id: "contractTermTitle",
            accessor: (row) => row.contractTerm.contractTermTitle,
            filter: "text",
            width: 300,
            disableSortBy: false,
        },
        {
            Header: "Actions",
            id: 'button',
            Cell: ( { row: { original } } ) =>
                (
                    <div>
                        <TooltipButton
                            id={`edit_${original.projectContractTermId}`}
                            title="Edit"
                            className="btn-icon"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => setItemInEdit(original)}
                        >
                            <i className="fas fa-pencil-alt pt-1"></i>
                        </TooltipButton>
                        <TooltipButton
                            id={`delete_${original.projectContractTermId}`}
                            title="Delete"
                            className="btn-icon"
                            color="warning"
                            size="sm"
                            type="button"
                            onClick={() => handleDelete(original)}
                        >
                            <i className="fas fa-trash-alt pt-1"></i>
                        </TooltipButton>
                    </div>
                ),
            width: 140,
        }]
    , [selectedProject, data, selectedSeqNoMode]);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,       
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: defaultSort,
                canSort: true,
            },
            filterTypes,
            defaultColumn,
            manualSortBy: true,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
        useExpanded
    );
    
    React.useEffect(() => {
        setFilters(state.filters);
    }, [state.filters]);
    
    React.useEffect(() => {
        setSortBy(state.sortBy);
    }, [state.sortBy]);

    return (
        <section className="main">
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card className="no-transition">
                            <CardBody>
                                <CardTitle>
                                    <h4 className="text-center">Project Contract Terms</h4>                                    
                                </CardTitle>
                                {loading && <Loader />}
                                {deleteAlert.length > 0 && deleteAlert}
                                <Container fluid className="px-0">
                                    <Row noGutters>
                                        <Col sm={12} md={{ size: 4, offset: 4 }}>
                                            <FormGroup>
                                                <Label>
                                                    Project
                                                </Label>
                                                <ComboBox 
                                                    endpoint="/project/search"
                                                    isLookup={false}
                                                    idField="projectId"
                                                    valueField="projectName"
                                                    selectedItem={selectedProject}
                                                    onChange={setSelectedProject}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12}>
                                            <TooltipButton
                                                id="addnew"
                                                title="Add New"
                                                className="btn-icon ml-2 mb-2"
                                                color="default"
                                                size="sm"
                                                type="button"
                                                disabled={!selectedProject}
                                                onClick={() => setItemInEdit({projectId: selectedProject.projectId})}
                                            >
                                                <i className="fas fa-plus pt-1"></i>
                                            </TooltipButton>
                                            {
                                                project &&
                                                <TooltipButton
                                                    id="return"
                                                    title="Return to Contract Term"
                                                    className="btn-icon ml-2 mb-2"
                                                    color="default"
                                                    size="sm"
                                                    type="button"
                                                    disabled={!selectedProject}
                                                    onClick={() => {
                                                        history.push("/contractterm", {viewMode: "matrix"});
                                                    }}
                                                >
                                                    <i className="fas fa-arrow-left pt-1"></i> Back
                                                </TooltipButton>
                                            }
                                        </Col>
                                        <Col xs={12}>
                                            <Grid
                                                totalRecords={totalRecords}
                                                pageSize={pageSize}
                                                rows={rows}
                                                tableProps={getTableProps()}
                                                headerGroups={headerGroups}
                                                tableBodyProps={getTableBodyProps()}
                                                prepareRow={prepareRow}
                                                onPagerChangePage={(pager) => {
                                                    setPageNumber(pager.currentPage);
                                                }}
                                                onPagerChangePageSize={(size) => {
                                                    setPageSize(size);
                                                }}
                                                needPaging={true}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                                {
                                    !!itemInEdit && 
                                    <ContractTermEditor
                                        title="Contract Term"
                                        item={itemInEdit} 
                                        onClose={() => setItemInEdit(null)} 
                                        onSaved={() => loadData()}
                                    />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}