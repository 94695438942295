/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import api from "../../../../services/api";
import {
    Button, Modal, Container, Row, Col, Input, Spinner, FormGroup, Label
 } from "reactstrap"; 
import { ComboBox, DropdownBox } from "../../../dropdowns";
import { DatePicker } from "../../../date-pickers";
import Loader from "../../../loaders";
import moment from "moment";
import { formatDate, formatDateZeroTime } from "../../../../utils";
import { Stepper, Step, StepLabel, Typography } from '@material-ui/core';
import _ from 'lodash';
import { TooltipButton } from '../../../inputs';
import SweetAlert from 'react-bootstrap-sweetalert';
import qs from "qs";

function JobOrderCandidateEditor(props) {
    const {
        jobOrder,
        setPdrsRequest
    } = props;

    const [ refresh, setRefresh ] = React.useState(null);
    const [ itemInEdit, setItemInEdit ] = React.useState(null);
    const [ itemHistory, setItemHistory] = React.useState([]);
    const [ currentCandidateMilestone, setCurrentCandidateMilestone ] = React.useState(null);
    const [ currentStep, setCurrentStep ] = React.useState(0);
    const [ milestones, setMilestones ] = React.useState([]);
    const [ milestoneAll, setMilestoneAll ] = React.useState([]);
    const [ loading, setLoading ] = React.useState(false);
    const [ confirmation, setConfirmation ] = useState(null);
    const [ validation, setValidation ] = useState(null);
    //const [ applicantPackSentMessage, setApplicantPackSentMessage ] = useState(null);
    //const [ isAlreadySentMessage, setIsAlreadySentMessage ] = useState(false);

    React.useEffect(() => { 
        if (props.jobOrderCandidateId > 0) {
            setLoading(true);
            setRefresh(null);

            let apiCalls = [
                api.get(`jobordercandidate/${props.jobOrderCandidateId}`), // 0
                api.get(`jobordercandidate/${props.jobOrderCandidateId}/mts`), // 1
                api.get(`jobordercandidate/${props.jobOrderCandidateId}/nmt`), // 2
                api.get(`jobordercandidate/${props.jobOrderCandidateId}/mth`), // 3
                api.get(`jobordercandidate/${props.jobOrderCandidateId}/mts-all`), // 4
                //api.get(`/messagesmaster?${qs.stringify({ filters: [{ id: "title", value: "Applicant Pack Sent" }] }, { allowDots: true })}`), // 5
            ];

            Promise.all(apiCalls)            
            .then((response) => {
                console.log('milestone load', response)
                setItemInEdit(response[0].data);
                setMilestones(response[1].data);
                //setCurrentCandidateMilestone({...response[2].data, pointOfHire: response[0].data?.pointOfHire, position: response[0].data?.position});
                setCurrentCandidateMilestone({ jobOrderCandidateId: props.jobOrderCandidateId, pointOfHire: response[0].data?.pointOfHire, position: response[0].data?.position});
                //setCurrentCandidateMilestone(null);
                setItemHistory(response[3].data);
                setMilestoneAll(response[4].data);
                //setApplicantPackSentMessage(response[5].data.data[0]);

                var i = _.findIndex(
                    response[1].data,
                    (item) => item.jobOrderMilestoneId == response[2].data.jobOrderMilestoneId
                );
                setCurrentStep(i);
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
        }
    }, [refresh]);



    const handleSelectedMilestoneChanged = (item) => {       
        console.log('handleSelectedMilestoneChanged','item', item);
        setCurrentCandidateMilestone( { ...currentCandidateMilestone,
            jobOrderMilestoneId: item ? item.jobOrderMilestoneId : null,
            milestoneName: item ? item.milestoneName : "",
            orderingIndex: item ? item.orderingIndex : null,
            requireSms: item ? item.requireSms : null
        });
    };

    const handleTextChanged = ({ target: { name, value } }) => {
        setCurrentCandidateMilestone({ ...currentCandidateMilestone, [name]: value });
    }
    
    
    const handleDateChanged = (value, name) => {
        setCurrentCandidateMilestone({ 
            ...currentCandidateMilestone, 
            [name]: !value ? null : formatDateZeroTime(value) //moment(value).format("YYYY-MM-DDT00:00:00") 
        });
    }

    const handleSavePosition = useCallback(() => {
        setLoading(true);
        api.put(
            `jobordercandidate/${currentCandidateMilestone.jobOrderCandidateId}/position`,
             JSON.stringify({positionId: currentCandidateMilestone?.position?.positionId ?? null}))
        .then(() => {
            setItemInEdit({...itemInEdit, positionId: currentCandidateMilestone?.position?.positionId ?? null, position: currentCandidateMilestone?.position});
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }, [currentCandidateMilestone, itemInEdit]);

    const handleSavePointOfHire = useCallback(() => {
        setLoading(true);
        api.put(
            `jobordercandidate/${currentCandidateMilestone.jobOrderCandidateId}/poh`,
             JSON.stringify({pointOfHire: currentCandidateMilestone?.pointOfHire ?? null}))
        .then(() => {
            setItemInEdit({...itemInEdit, pointOfHire: currentCandidateMilestone.pointOfHire});
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }, [currentCandidateMilestone, itemInEdit]);

    const handleSave = (completed, updateRoster, callback) => {
        setLoading(true)
        api.post(
            `jobordercandidate/${currentCandidateMilestone.jobOrderCandidateId}/newmile?completed=${completed ?? "false"}&updateroster=${updateRoster ?? "false"}`,
             JSON.stringify(currentCandidateMilestone))
        .then((response) => {
            setRefresh(true);
            // props.onSaved(itemInEdit);
            if (callback)
                callback();
        })
        .catch((error) => {
            console.error({error});

            if (confirmation)
                setConfirmation(null);
            
            setValidation({
                title: "Submission Failed",
                message: error.response.status < 500 ? `${error.response.data}` : "System error. Please contact admin."
            });
        })
        .finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        console.log('currentCandidateMilestone', currentCandidateMilestone);
    }, [currentCandidateMilestone])

    const checkMilestone = useCallback((isAlreadySentMessage = false) => {

        // BEGIN VALIDATION ------------------------------------------------------------------------------------------------------------------------

        if (!currentCandidateMilestone?.milestoneName) {
            setValidation({
                title: `Job Order Candidate - ${itemInEdit?.candidate?.candidateName}`,
                message: "Please select the Milestone Progress Update from the list."
            });
            return;
        }


        if (!currentCandidateMilestone?.milestoneDate) {
            setValidation({
                title: `Job Order Candidate - ${itemInEdit?.candidate?.candidateName}`,
                message: "Please enter the Milestone Date."
            });
            return;
        }


        // END VALIDATION ------------------------------------------------------------------------------------------------------------------------

        //if (!isAlreadySentMessage && currentCandidateMilestone?.milestoneName && currentCandidateMilestone?.milestoneName.trim().toLowerCase() == 'applicant pack sent') {
        //if (!isAlreadySentMessage && currentCandidateMilestone?.milestoneName && currentCandidateMilestone?.milestoneName.trim().toLowerCase() == 'applicant paperwork received') {

        if (!isAlreadySentMessage && currentCandidateMilestone?.requireSms == true) {


            var name = currentCandidateMilestone?.milestoneName == null ? '' : currentCandidateMilestone?.milestoneName.trim();
            var applicantPackSentMessages = [];
            //const queryString = qs.stringify({ name: name }, { allowDots: true });
            const queryString = qs.stringify({ filters: [{ id: "title", value: name }] }, { allowDots: true });
            let apiCalls = [
                //api.get(`/messagesmaster/check-for-milestone?${queryString}`),
                api.get(`/messagesmaster?${queryString}`), // 1
            ];

            setLoading(true);
            Promise.all(apiCalls)
                .then((response) => {
                    setLoading(false);
                    //console.log('applicantPackSentMessages', applicantPackSentMessages);
                    //return;
                    applicantPackSentMessages = response[0].data.data;

                    // If message master template not found
                    if (applicantPackSentMessages.length == 0) {
                        setValidation({
                            title: `Bulk Milestone Editor`,
                            message: `Milestone ${name} requires a message master template, which is not found. To continue, please open Maintenance -> Messages Master -> Add -> ${name} -> Save.`
                        });
                        return;
                    }

                    // If message master template found
                    var applicantPackSentMessage = applicantPackSentMessages[0];


                    var candidate = {
                        ...itemInEdit?.candidate,
                        jobOrderCandidateId: itemInEdit?.jobOrderCandidateId,
                        jobOrderId: itemInEdit?.jobOrderId,
                    };
                    var newPdrsRequest = {
                        milestoneName: name,
                        jobOrderCandidateId: itemInEdit?.jobOrderCandidateId,
                        jobOrderId: itemInEdit?.jobOrderId,
                        projectId: itemInEdit?.jobOrder?.projectId,
                        candidateId: itemInEdit?.candidateId,
                        itemSMS: [candidate],
                        defaultMessage: applicantPackSentMessage,
                        onSaved: (isSuccess = true) => {
                            checkMilestone(isSuccess);
                        }
                    };


                    console.log('newPdrsRequest', newPdrsRequest);
                    console.log('itemInEdit', itemInEdit);

                    //setIsAlreadySentMessage(true);
                    setPdrsRequest(newPdrsRequest);

                    return;


                })
                .catch((error) => {
                    setLoading(false);
                    setValidation({
                        title: `Job Order Candidate - ${itemInEdit?.candidate?.candidateName}`,
                        message: `Unexpected error.`
                    });
                    console.error(error);
                });
            //.finally(() => setLoading(false));



            return;

        }

        if (!milestones.length || !itemInEdit || !currentCandidateMilestone)
        {
            handleSave();
            return;
        }

        const latestMilestoneId = milestones[milestones.length - 1].jobOrderMilestoneId;

        if (latestMilestoneId !== currentCandidateMilestone.jobOrderMilestoneId)
        {
            handleSave();
            return;
        }

        console.log({itemInEdit});

        if (!itemInEdit.jobOrder.rosterId)
        {
            handleSave(true);
            return;
        }

        setConfirmation({
            type: "Talent Placement",
            title: "Talent Placement",
            message: `Do you want to automatically assign the talent to Dispatch ${itemInEdit.jobOrder.dispatchUnitName}?`
        });
    }, [itemInEdit, milestones, currentCandidateMilestone]);

    const handleOnConfirmed = useCallback(() => {
        if (!confirmation)
            return;

        if (confirmation.type === "Set to Filled")
        {
            handleSetToFilled(() => setConfirmation(null));
            return;
        }

        if (confirmation.type === "Talent Placement")
        {
            handleSave(true, true, () => setConfirmation(null));
            return;
        }
    }, [confirmation]);

    const handleSetToFilled = useCallback((callback) => {
        setLoading(true);
        api.put(`jobordercandidate/${currentCandidateMilestone.jobOrderCandidateId}/filled`)
        .then(({data}) => {
            console.log({data});
            setItemInEdit({...itemInEdit, status: {...data.status}, statusId: data.statusId, filledBy: data.filledBy, filledDate: data.filledDate});
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            setLoading(false);

            if (callback)
                callback();
        });
    }, [currentCandidateMilestone, itemInEdit]);

    const renderHistoryList = (item, index) => {      
        return (
            <li key={index} className="list-group-item">
                {item.milestoneDate && moment(item.milestoneDate).format("ddd, DD.MM.YYYY") }{item.submittedBy ? ` - ${item.submittedBy}` : ""} <i className="fas fa-angle-right ml-1 mr-1"></i> {item.milestoneName}
                <br />
                <blockquote className="blockquote">
                    <p className="mb-0">{item.milestoneNote && item.milestoneNote.length > 0 ? item.milestoneNote : "No Comment."}</p>
                </blockquote>
            </li>
        );        
    }
    
    return (
        <Modal
            isOpen={true}
            className="modal-xl"
            modalClassName="db-example-modal-xl"
        >
        {
            confirmation ?
            <SweetAlert
                title={confirmation.title}
                warning
                showCancel
                cancelBtnText="No"
                confirmBtnText="Yes"
                confirmBtnBsStyle="danger"
                onConfirm={() => handleOnConfirmed()}
                onCancel={() => {
                    if (confirmation.type === "Set to Filled")
                    {
                        setConfirmation(null);
                        return;
                    }

                    if (confirmation.type === "Talent Placement")
                    {
                        handleSave(true, false, () => setConfirmation(null));
                        return;
                    }
                }}
            >
                {confirmation.message}
            </SweetAlert>
            :
            null
        }
        {
            validation ?
            <SweetAlert
                title={validation.title}
                error
                confirmBtnText="OK"
                confirmBtnBsStyle="danger"
                onConfirm={() => setValidation(null)}
            >
                {validation.message}
            </SweetAlert>
            :
            null
        }
            <div className="modal-header">
                <h5 className="modal-title">Job Order Candidate - {itemInEdit?.candidate?.candidateName}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div className="modal-body milestone">
                <Container fluid className="px-0">
                    {(loading) && <Loader />}
                    <Row>
                        <Col md={12}>
                            {/* <Stepper activeStep={currentStep} alternativeLabel>
                                {milestones.map((item, index) => 
                                    <Step key={index}>
                                        <StepLabel>{item.milestoneName}</StepLabel>
                                    </Step>
                                )}
                            </Stepper> */}
                            <Stepper alternativeLabel>
                                {milestoneAll.map((item, index) => {
                                    const completed = !!itemHistory.find(f => f.jobOrderMilestoneId == item.jobOrderMilestoneId);
                                    const isComplete = item.orderingIndex <= currentStep;
                                    return <Step key={index} completed={completed} active={completed} className={isComplete ? 'milestone-complete' : ''}>
                                        <StepLabel>{item.milestoneName}</StepLabel>
                                    </Step>}
                                )}
                            </Stepper>
                        </Col>
                    {
                        !jobOrder?.isCancelled &&
                        <Col md={4} className="border-right border-black">
                            <FormGroup className="mb-3 pb-3 border-bottom-3">
                                <Label>
                                    Employed Position
                                </Label>
                                <Container fluid>
                                    <Row>
                                        <Col lg={10} className="m-0 p-0">
                                            <ComboBox
                                                minLength={0}
                                                endpoint="/position/searchname"
                                                isLookup={false}
                                                idField="positionId"
                                                valueField="positionName"
                                                selectedItem={currentCandidateMilestone?.position}
                                                onChange={(item) => {                                   
                                                    setCurrentCandidateMilestone( { ...currentCandidateMilestone, position: item } );
                                                }}
                                            />
                                        </Col>
                                        <Col lg={2} className="m-0 p-0 d-flex flex-wrap justify-content-center align-items-center">
                                            <TooltipButton
                                                id="save-poh"
                                                title="Save Employed Position"
                                                className="btn-icon"
                                                color="info"
                                                size="sm"
                                                type="button"
                                                onClick={() => handleSavePosition()}
                                            >
                                                Save
                                            </TooltipButton>
                                        </Col>
                                    </Row>
                                </Container>
                            </FormGroup>
                            <FormGroup className="mb-3 pb-3 border-bottom-3">
                                <Label>
                                    Point of Hire
                                </Label>
                                <Container fluid>
                                    <Row>
                                        <Col lg={10} className="m-0 p-0">
                                            <Input
                                                name="pointOfHire"
                                                id="pointOfHire"
                                                type="text"
                                                placeholder="Point of Hire"
                                                rows={8}
                                                value={currentCandidateMilestone?.pointOfHire ?? ""}
                                                onChange={handleTextChanged}
                                            />
                                        </Col>
                                        <Col lg={2} className="m-0 p-0 d-flex flex-wrap justify-content-center align-items-center">
                                            <TooltipButton
                                                id="save-poh"
                                                title="Save Point of Hire"
                                                className="btn-icon"
                                                color="info"
                                                size="sm"
                                                type="button"
                                                onClick={() => handleSavePointOfHire()}
                                            >
                                                Save
                                            </TooltipButton>
                                        </Col>
                                    </Row>
                                </Container>
                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    Milestone Progress Update
                                </Label>
                                <DropdownBox 
                                    data={milestones}
                                    idField="jobOrderMilestoneId"
                                    valueField="milestoneName"
                                    selectedItem={currentCandidateMilestone}
                                    onChange={(item) => { 
                                        handleSelectedMilestoneChanged(item);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <DatePicker 
                                    name="milestoneDate"
                                    id="milestoneDate"
                                    type="text"
                                    required
                                    value={formatDate(currentCandidateMilestone == null ? "" : currentCandidateMilestone.milestoneDate)}
                                    onChange={handleDateChanged}
                                    closeOnSelect              
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    name="milestoneNote"
                                    id="milestoneNote"
                                    type="textarea"
                                    placeholder="Milestone Note"
                                    rows={8}
                                    value={currentCandidateMilestone == null ? "" : currentCandidateMilestone.milestoneNote}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                            <FormGroup className="mb-3 pb-3 border-bottom-3">
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        checkMilestone();
                                    }}
                                    type="button">
                                    Submit Progress <i className="fas fa-caret-right ml-2"></i>
                                </Button>
                            </FormGroup>
                            {/* <FormGroup>
                                <Label>
                                    Recruiter may at their discretion change the status of this job order slot to "Filled".
                                </Label>
                                <Container fluid>
                                    <Row className>
                                        <Col lg={12} className="m-0 p-0 d-flex flex-wrap justify-content-start align-items-start">
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    setConfirmation({
                                                        type: "Set to Filled",
                                                        title: "Set to Filled Confirmation",
                                                        message: "Are you sure you want to update the status to Filled?",
                                                    });
                                                }}
                                                type="button"
                                                disabled={itemInEdit?.status?.lookupValue === "Filled"}
                                            >
                                                Set to Filled
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </FormGroup> */}
                        </Col>
                    }
                        <Col md={jobOrder?.isCancelled ? 12 : 8} className="border-left border-black">
                        {
                            (!loading) ?
                            itemHistory && itemHistory.length === 0 ? 
                            (
                                <p className="text-center text-muted">No Progress / New Candidate</p>
                            ) : itemHistory && itemHistory.length > 0 ?
                            (
                                <>
                                    <ul className="list-group list-group-flush" style={{overflowY: 'auto', height: 'calc(100vh - 100px)'}}>
                                        {itemHistory.map(renderHistoryList)}
                                    </ul>
                                </>
                            ) : null
                            : null  
                        }
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={() => {
                        props.onClose(itemInEdit)
                    }}
                    type="button"
                >
                    Close
                </Button>
            </div>
        </Modal>
    )
}

export default JobOrderCandidateEditor;