import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import {
    Container, Row, Col, Card, CardBody, CardTitle, Button, Input, ButtonGroup, CardFooter
} from "reactstrap";
import Loader from "../../loaders";
import { HotTable, HotColumn } from "@handsontable/react";
import Handsontable from "handsontable";
import 'handsontable/dist/handsontable.full.css';
import { TooltipButton } from '../../inputs';
import { formatDate } from '../../../utils';
import { DatePicker } from '../../date-pickers';
import moment from "moment";
import { array } from 'prop-types';
import api from "../../../services/api";
import qs from "qs";
import CandidateEditor from "../Candidates/Editor";
import debounce from 'lodash.debounce';
import _ from 'lodash';
import ConflictScheduleEditor from '../Roster/Editors/ConflictScheduleEditor';

import ConflictDialog from '../Roster/ConflictDialog';
import { ErrorNotification } from '../../alerts'

const dateFormat = "DD-MM-YYYY";
const dateFormats = [
    "DD-MM-YYYY"
];

// const ColumnRenderer = (props) => {
//     console.log('ColumnRenderer: ', props)
//     const { value } = props;
//     return (
//       <React.Fragment>
//         {value?.val}
//       </React.Fragment>
//     );
//   };

function InfoButton(props) {
    const {
        value
    } = props;

    if (!value)
        return null;

    return (
        <div className="cursor-pointer w-100 h-100 py-1 pr-1 m-0 d-flex flex-wrap justify-content-center">
            <i className="fas fa-info-circle"></i>
        </div>
    )
}

function OverlappedButton(props) {
    const {
        value
    } = props;

    if (!value)
        return null;

    return (
        <div className={`${value === "Y" ? "cursor-pointer " : ""}w-100 h-100 py-1 pr-1 m-0 d-flex flex-wrap justify-content-center`}>
            <i className={`fas ${value === "N" ? "fa-check-circle text-success" : "fa-times-circle text-danger"}`}></i>
        </div>
    )
}

function RosterWizard(props) {
    const location = useLocation();
    //console.log('RosterWizard location:', location);
    const {
        projects,
        positions,
        dates,
        projectData,
        positionData,
        selectedData,
        selectedCols,
        selectedRows,
        forecastFilters,
        selectedManpowerForecasts
    } = location.state;
    const [ loading, setLoading ] = React.useState(false);
    const hotTableComponent = useRef(null);
    
    const [ tableData, setTableData ] = useState([]);
    const [ tableColumns, setTableColumns ] = useState([]);
    const [ hotColumns, setHotColumns ] = useState([]);
    const [ topTalents, setTopTalents ] = useState(null);
    const [ selectedCell, setSelectedCell ] = useState(null);
    const [ selectedTalents, setSelectedTalents ] = useState(null);
    const [ lookups, setLookups ] = useState({});
    const [ itemInEdit, setItemInEdit ] = useState(null);
    const [talentConflictSchedule, setTalentConflictSchedule] = useState(null);
    const history = useHistory();

    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);
    const [errorMessageOpening, setErrorMessageOpening] = React.useState(`Error occured when processing the request: `);

    useEffect(() => {
        setLoading(true);
        const dateColumns = new Array();

        const start = moment(dates[0], 'DD/MM/YYYY');
        const end = moment(dates[1], 'DD/MM/YYYY');
        let current = moment(start);
        
        while(current <= end) {       
            if (selectedData.filter(s => s.filter(d => moment(d.date).format("YYYYMMDD") === current.format("YYYYMMDD")).length > 0).length === 0)
            {
                current.add(1, 'd');
                continue;
            }
            
            dateColumns.push(moment(current));
            current.add(1, 'd');
        }
        
        setHotColumns(dateColumns);
        setTableColumns([
            "Project",
            "Ref No",
            "Position",
            "Slot Number",
            "Employee Name",
            "Info",
            "Overlap Status",
            ...dateColumns.map((m) => m.format('DD/MM/YYYY'))
        ])

        const apiCalls = [
            api.get(`/lookup/skillposition`),
            api.get(`/lookup/candidatetype`),
            api.get(`/lookup/salutation`),
            api.get(`/lookup/preference`),
            api.get(`/lookup/industry`),
            api.get(`/lookup/gender`),
            api.get(`/lookup/maritalStatus`),
            api.get(`/lookup/candidateStatus`),
            api.get(`/lookup/candidateFlag`),
            api.get(`/lookup/residencyStatus`),
            api.get(`/lookup/visatype`),
            api.get(`/lookup/communicationtype`),
            api.get(`/lookup/country`),
            api.get(`/lookup/state`),
            api.get(`/lookup/relationshiptype`),
            api.get(`/lookup/candidateplatformorigin`),
            api.get(`/messagesmaster`), // TODO: returns paged result - move to lookup
            api.get(`/emailtemplate`), // TODO: returns paged result - move to lookup
            api.get(`/lookup/recruitmentpeople`),
            api.get(`/lookup/candidatediscipline`),
            api.get(`/lookup/company`),
            api.get(`/lookup/identifier`),
            api.get(`/lookup/candidateevent`),
        ];

        Promise.all(apiCalls)
        .then((data) => {
            setLookups({
                skillPositions: data[0].data,
                candidateTypes: data[1].data,
                salutations: data[2].data,
                preferences: data[3].data,
                industries: data[4].data,
                genders: data[5].data,
                maritalStatuses: data[6].data,
                candidateStatuses: data[7].data,
                candidateFlags: data[8].data,
                residencyStatuses: data[9].data,
                visaTypes: data[10].data,
                communicationTypes: data[11].data,
                countries: data[12].data,
                states: data[13].data,
                relationshipTypes: data[14].data,
                candidatePlatformOrigin: data[15].data,
                messagesMaster: data[16].data.data,
                emailTemplate: data[17].data.data,
                recruitmentPeople: data[18].data,
                candidateDisciplines: data[19].data,
                companies: data[20].data,
                identifiers: data[21].data,
                events: data[22].data,
            });
        }).finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        console.log('data', { projectData, positionData, selectedRows, selectedCols, selectedData, tableColumns, hotColumns, topTalents });
        const data = [
            ...projectData.map((m, k) =>
                {
                    return {
                        projectId: m.project.projectId,
                        locationId: m.location.lookupId,
                        //workorderId: m.workorder.lookupId,
                        ...m,
                        __children: [
                            ...positionData.filter(f => f.project === m.forecastProject).map((p, j) => {
                                let position = {
                                    projectId: m.project.projectId,
                                    hrispositionMasterId: p.hrispositionMaster.hrispositionMasterId,
                                    //workorderId: m.workorder.lookupId,
                                    hrispositionMaster: p.hrispositionMaster,
                                    slot: p.slot,
                                    refNo: p.refNo,
                                };
                                let slotData = [...new Array(p.slot)].map((s, i) => {
                                    const talentData = topTalents?.filter(d => d.projectId === m.project.projectId && d.positionId === p.hrispositionMaster.hrispositionMasterId && d.refNo === p.refNo)[i];
                                    return {
                                        ...position,
                                        projectId: m.project.projectId,
                                        hrispositionMasterId: p.hrispositionMaster.hrispositionMasterId,
                                        slotNo: i + 1,
                                        talentData,
                                        talent: talentData ? `${talentData.candidateName} - ${talentData.candidateId}` : "",
                                        info: talentData ? "Y" : null,
                                        isOverlapped: talentData ? "N" : null,
                                        index: [k, j, i],
                                        dates: hotColumns.reduce((pv, cv, idx) => {
                                            const rowIndex = selectedRows.findIndex(f => f === p.refNo);
                                            const row = selectedData[rowIndex];
                                            const value = row.filter(f => moment(f.date).format("DD/MM/YYYY") === cv.format('DD/MM/YYYY'))[0] ?? 0;
                                            // console.log("value, ", {rowIndex, value, i, row});
                                            
                                            if (value && value.fte && value.fte >= (i + 1))
                                                pv[cv.format('YYYYMMDD')] = "Y";
                                            
                                            return pv;
                                        }, {})
                                    }
                                })
                                return slotData;
                            }).flat()
                        ],
                        // __children: [
                        //     ...positionData.filter(f => f.project === m.forecastProject).map((p, j) =>
                        //         {
                        //             return {
                        //                 projectId: m.project.projectId,
                        //                 positionId: p.position.positionId,
                        //                 workorderId: m.workorder.lookupId,
                        //                 position: p.position,
                        //                 slot: p.slot,
                        //                 refNo: p.refNo,
                        //                 __children: [...new Array(p.slot)].map((s, i) =>
                        //                     {
                        //                         const talentData = topTalents?.filter(d => d.projectId === m.project.projectId && d.positionId === p.position.positionId && d.refNo === p.refNo)[i];
                        //                         console.log(talentData);
                        //                         return {
                        //                             projectId: m.project.projectId,
                        //                             positionId: p.position.positionId,
                        //                             slotNo: i + 1,
                        //                             talentData,
                        //                             talent: talentData ? `${talentData.candidateName} - ${talentData.candidateId}` : "",
                        //                             info: "",
                        //                             index: [k, j, i],
                        //                             dates: hotColumns.reduce((pv, cv, idx) => {
                        //                                 const rowIndex = selectedRows.findIndex(f => f === p.refNo);
                        //                                 const colIndex = selectedCols.findIndex(f => f === cv.format('DD/MM/YYYY'));
                        //                                 const value = selectedData[rowIndex][colIndex] ?? 0;
                                                        
                        //                                 //pv[cv.format('YYYYMMDD')] = (!!value && !!value.fte && value.fte >= (i + 1)) ? 'Y' : '';
                        //                                 pv[cv.format('YYYYMMDD')] = (!!value && value >= (i + 1)) ? 'Y' : '';
                        //                                 // pv[cv.format('YYYYMMDD')] = {
                        //                                 //     val: (!!value && value >= (i + 1)) ? 'Y' : ''
                        //                                 // };
                        //                                 return pv;
                        //                             }, {})
                        //                         }
                        //                     }
                        //                 )
                        //             }
                        //         }
                        //     )
                        // ]
                    }
                }
            )
        ];

        console.log('setTableData', data);

        setTableData(data);
    }, [projectData, positionData, selectedRows, selectedCols, selectedData, tableColumns, hotColumns, topTalents]);

    useEffect(() => {
        const hotTable = hotTableComponent.current?.hotInstance;

        if (!hotTable)
            return;

        hotTable.updateSettings({
            cells(row, col) {
                const cellProperties = {};
                const cellData = hotTable.getDataAtCell(row, 3);

                if (col >= 4 && (!cellData || cellData === ""))
                {
                    cellProperties.readOnly = true;
                    cellProperties.editor = false;
                    cellProperties.type = "text";
                }
                
                return cellProperties;
            }
        });
    }, [tableData]);

    useEffect(() => {
        if (!selectedCell || !selectedTalents)
            return;
        
        const hotTable = hotTableComponent.current?.hotInstance;

        if (!hotTable)
            return;
        
        if (selectedCell.column === "info")
        {
            const name = hotTable.getDataAtRowProp(selectedCell.row, "talent");

            if (!name || name === "")
                return;
            
            const nameArr = name.split(" - ");
            const id = parseInt(nameArr[nameArr.length - 1]);
            setItemInEdit({candidateId: id});
            return;
        }

        if (selectedCell.column === "isOverlapped")
        {
            const data = hotTable.getSourceDataAtRow(selectedCell.row);
            console.log(data);

            if (!data || !data.index)
                return;
            
            const isOverlapped = hotTable.getDataAtRowProp(selectedCell.row, "isOverlapped");
            //const [index1, index2, index3] = data.index;

            const slotDates = Object.keys(data.dates)?.filter(d => data.dates[d] && data.dates[d] !== "");
            slotDates.sort();
            const startDate = moment(slotDates[0], "YYYYMMDD").format("YYYY-MM-DD");
            const endDate = moment(slotDates[slotDates.length - 1], "YYYYMMDD").format("YYYY-MM-DD");
            
            if (!isOverlapped || isOverlapped === "N")
                return;

            const name = hotTable.getDataAtRowProp(selectedCell.row, "talent");

            if (!name || name === "")
                return;
            
            const nameArr = name.split(" - ");
            const id = parseInt(nameArr[nameArr.length - 1]);
            if (!id) {
                return;
            }
            setTalentConflictSchedule({
                candidateId: id,
                //candidateName: nameArr[0],
                //detailProject: data,
                startDate,
                endDate
            });
            return;
        }
    }, [selectedCell]);

    const searchName = useCallback(
        debounce((query, process) => {
            setLoading(true);
            let queryString = null;

            if (query && query !== "")
            {
                queryString = qs.stringify({ filter: query }, { allowDots: true })
            }

            api.get(`/candidate/searchname${queryString ? `?${queryString}` : ""}`)
                .then((response) => {
                    if (!response.data || response.data.length === 0)
                    {
                        process([]);
                        return;
                    }

                    process(response.data?.map(x => `${x.candidateName} - ${x.candidateId}`));
                })
                .catch((error) => console.log(error.response))
                .finally(() => setLoading(false));
        }, 200)
    , []);

    const updateTalent = useCallback((indexes, value) => {
        setLoading(true);
        const [index1, index2, index3] = indexes;
        const newData = [...tableData];
        const selectedData = newData[index1];
        const child = selectedData.__children.filter(x => x.index[1] === index2 && x.index[2] === index3)[0];
        const url = "/candidate/single-sum";

        if (!value || value === "")
        {
            child.talentData = null;
            child.talent = null;
            newData.splice(
                newData.indexOf(selectedData),
                1,
                selectedData
            );
            setTableData(newData);
            setLoading(false)
            return;
        }

        const splittedStr = value.split(" - ");
        const id = parseInt(splittedStr[splittedStr.length - 1]);

        if (!id)
        {
            child.talentData = null;
            child.talent = null;
            newData.splice(
                newData.indexOf(selectedData),
                1,
                selectedData
            );
            setTableData(newData);
            setLoading(false);
            return;
        }

        api.get(`${url}/${id}`)
            .then((response) => {
                child.talentData = {
                    candidateId: response.data.candidateId,
                    candidateName: response.data.candidateName,
                    projectId: selectedData.projectId,
                    positionId: child.positionId
                };
                const name = `${response.data.candidateName} - ${response.data.candidateId}`;
                child.talent = name;
                newData.splice(
                    newData.indexOf(selectedData),
                    1,
                    selectedData
                );
                setTableData(newData);
            })
            .catch((error) => console.log(error.response))
            .finally(() => setLoading(false));
    }, [tableData, hotTableComponent.current]);

    const rosterGrid = useMemo(() => {
        return (<>
        {
            tableData && tableData.length > 0 && tableColumns && tableColumns.length > 0 && hotColumns && hotColumns.length > 0 &&
            <HotTable
                ref={hotTableComponent}
                id={'hot'}
                colHeaders={tableColumns}
                data={tableData}
                rowHeaders={true}
                nestedRows={true}
                stretchH={'all'}
                autoColumnSize={true}
                autoRowSize={false}
                height={'calc(100vh - 310px)'}
                licenseKey="5c26d-2087e-1df71-d6238-dfc22"
                columnHeaderHeight ={[80]}
                outsideClickDeselects={false}
                manualRowMove={true}
                manualColumnResize={true}
                manualColumnFreeze={true}
                bindRowsWithHeaders={true}
                contextMenu={false}
                // contextMenu={
                //     {
                //         items:{
                //             row_below: {
                //                 name: 'Add row',
                //                 callback(key, selection, clickEvent) { 
                //                     const newData = [ ...tableData ];
                //                     const [ index1, index2, index3 ] = hotTableComponent.current.hotInstance.getSourceDataAtRow(selection[0].end.row).index;
                //                     const item = newData[index1].__children[index2];
                //                     item.__children.push({
                //                         slotNo: item.__children.length + 1,
                //                         talent: '',
                //                         index: [ index1, index2, item.__children.length ],
                //                         dates: { ...item.__children[index3].dates }
                //                     })
                //                     setTableData(newData);
                //                 }
                //             },
                //             // add_col_left: {
                //             //     name: 'Add Col',
                //             //     callback: (key, selection, clickEvent) => {
                //             //         const firstDate = moment(hotColumns[0]);
                //             //         firstDate.add(-1, 'day');
                //             //         console.log('add_col_left', { key, selection, clickEvent, tableColumns, hotColumns, firstDate })

                //             //         setHotColumns([firstDate, ...hotColumns]);
                //             //         var newCols = [ ...tableColumns ];
                //             //         newCols.splice(6, 0, firstDate.format('DD/MM/YYYY'))
                //             //         setTableColumns(newCols)
                //             //     }
                //             // },
                //             sp1: '---------',
                //             cut: {
                //                 name: 'Cut',
                //             },
                //             copy: {
                //                 name: 'Copy',
                //             },
                //             undo: {
                //                 name: 'Undo',
                //             },
                //             redo: {
                //                 name: 'Redo',
                //             },
                //             sp3: '---------',
                //             remove_row: {
                //                 name: 'Remove row',
                //                 callback(key, selection, clickEvent) {
                //                     try {

                //                         // Init basic vars
                //                         const newData = [...tableData];
                //                         const selObj = hotTableComponent.current.hotInstance.getSourceDataAtRow(selection[0].end.row);
                //                         const [index1, index2, index3] = selObj.index;
                //                         const project = newData[index1];
                //                         const projectId = selObj.projectId;
                //                         const refNo = selObj.refNo;

                //                         // If item not found, then throw error
                //                         if (!project) throw `It's shouldn't be like this: Project data not found`;

                //                         // Find item in the project
                //                         const itemIndex = project.__children.findIndex(w =>
                //                             w.projectId == projectId &&
                //                             w.refNo == refNo &&
                //                             w.slotNo == selObj.slotNo);
                //                         const item = project.__children[itemIndex];

                //                         // If item not found, then throw error
                //                         if (!item) throw `It's shouldn't be like this: Deletion data not found`;


                //                         // Remove item
                //                         project.__children.splice(itemIndex, 1);
                                        
                //                         // Recount slot no
                //                         var projectGroupList = project.filter(w => w.projectId == projectId && w.refNo == refNo);

                //                         for (let r = 0; r < projectGroupList.length; r++) {
                //                             projectGroupList[r].slotNo = r + 1;
                //                             projectGroupList[r].index = [index1, index2, r];
                //                         }

                //                         // Set table data
                //                         setTableData(newData);
                //                     }
                //                     catch (err) {
                //                         if (err.message.includes("like this")) {
                //                             setErrorMessage(err.message);
                //                         }
                //                         else {
                //                             setErrorMessage(`${errorMessageOpening}${err.message}`);
                //                         }

                //                         console.error("error on remove row", err.message);

                //                     }
                //                 }
                //             },
                //             clear_column: {
                //                 name: 'Clear column',
                //             },
                //         }
                //     }
                // }
                modifyColWidth={(width, column) => {
                    if (column > 6) {
                        return 30;
                    } else {
                        return width + 15;
                    }
                }}
                afterGetColHeader={(column, TH) => {
                    if (column < 0) {
                        return;
                    }
                    if (TH.textContent.includes("/")) {
                        Handsontable.dom.addClass(
                            TH,
                            'rotate-header'
                        );
                    }
                    if (TH.firstChild) {
                        Handsontable.dom.addClass(
                            TH.firstChild,
                            'htCenter'
                        );
                    }
                }}
                afterLoadData={() => {
                    const hotTable = hotTableComponent.current?.hotInstance;

                    if (!hotTable)
                        return;

                    const rowNum = hotTable.countRows();
                    const newSelectedTalents = [];

                    for (let i = 0; i < rowNum; i++)
                    {
                        const value = hotTable.getDataAtRowProp(i, "talent");
                        if (value && value !== "")
                        {
                            const splittedValue = value.split(" - ");
                            const candidateId = parseInt(splittedValue[1]) ?? 0;

                            newSelectedTalents.push({
                                row: i,
                                column: "talent",
                                infoColumn: "info",
                                candidateId,
                                isOverlapped: "N",
                                name: value
                            });
                        }
                    }
                    console.log(newSelectedTalents);
                    setSelectedTalents(newSelectedTalents);                  
                    // hopefully temp solution, fixes issue with cell/header misalignment in some data scenario's
                    // test scenario used: 12 dates, 2 projects, 3 positions each
                    // Slots:
                    // proj 1: pos 1 x 3, pos 2 x 1, pos 3 x 14 (or greater)
                    // proj 2: pos 1 x 1, pos 2 x 2, pos 3 x 1
                    // 
                    setTimeout(() => {
                        if (hotTableComponent.current) {
                            hotTableComponent.current.hotInstance.render(); 
                        }
                    }, 100);
                }}
                afterSelectionEnd={(row, column, row2, column2) => {
                    const hotTable = hotTableComponent.current?.hotInstance;

                    if (!hotTable)
                        return;

                    var elCounter = document.querySelector("#agg-data")
                    elCounter.innerHTML = "";
                        
                    const startRow = Math.min(row, row2);
                    const endRow = Math.max(row, row2);
                    
                    const startCol = Math.min(column, column2);
                    const endCol = Math.max(column, column2);

                    //console.log('selection: ', {hotTable, startRow, endRow, startCol, endCol});

                    const map = new Map();
                    if (startCol < 7) {
                        for (let i = startRow; i <= endRow; i++) {
                            addToMap(i, startCol);
                        }
                    } else {
                        for (let i = startRow; i <= endRow; i++) {
                            for (let k = startCol; k <= endCol; k++) {
                                addToMap(i, k);
                            }
                        }
                    }
                    let result = "";
                    map.forEach((value, key) => {
                        result += `${key}: ${value} | `
                    })
                    elCounter.innerHTML = result.slice(0, -3);

                    //console.log('map: ', map);

                    setSelectedCell({
                        row: row,
                        column: hotTable.colToProp(column)
                    });

                    function addToMap(rowIndex, colIndex) {
                        const value = hotTable.getDataAtCell(rowIndex, colIndex) || "Empty";
                        map.set(value, (map.get(value) ?? 0) + 1);
                    }
                }}
                afterSetDataAtCell={(changes, source) => {
                    if (source === "loadData" || changes[0][1] === "isOverlapped")
                        return;

                    const hotTable = hotTableComponent.current?.hotInstance;

                    if (!hotTable)
                        return;
                    
                    if (changes[0][1] === "talent")
                    {
                        setLoading(true);
                        const rowNum = hotTable.countRows();
                        const apiCalls = [];
                        console.log(hotTable.getSourceDataAtRow(changes[0][0]));
                        const rowData = hotTable.getSourceDataAtRow(changes[0][0]);
                        const slotDates = Object.keys(rowData.dates)?.filter(d => rowData.dates[d] && rowData.dates[d] !== "");
                        slotDates.sort();
                        const startDate = moment(slotDates[0], "YYYYMMDD").format("YYYY-MM-DD");
                        const endDate = moment(slotDates[slotDates.length - 1], "YYYYMMDD").format("YYYY-MM-DD");
                        const sourceData = [];

                        for (let i = 0; i < rowNum; i++)
                        {
                            // const value = (changes[0][0] === i && changes[0][1] === "talent") ? changes[0][3] : hotTable.getDataAtRowProp(i, "talent");

                            // if (value && value !== "")
                            // {                        
                            //     const splittedValue = value.split(" - ");
                            //     const candidateId = parseInt(splittedValue[1]) ?? 0;
                            //     apiCalls.push(api.get(`rosterwizard/check-conflicts?candidateid=${candidateId}&candidatename=${value}&rownumber=${i}&startdate=${startDate}&enddate=${endDate}`));
                            // }

                            const value = (changes[0][0] === i) ? changes[0][3] : null;
                            const talentValues = hotTable.getDataAtRowProp(i, "talent");
                            const currRowData = hotTable.getSourceDataAtRow(i);

                            if (talentValues && talentValues !== null)
                                sourceData.push({
                                    source: currRowData,
                                    rowIdx: i
                                });

                            if (value && value !== "")
                            {
                                const splittedValue = value.split(" - ");
                                const candidateId = parseInt(splittedValue[1]) ?? 0;
                                apiCalls.push(api.get(`rosterwizard/check-conflicts?candidateid=${candidateId}&candidatename=${value}&rownumber=${i}&startdate=${startDate}&enddate=${endDate}`));
                            }
                        }

                        console.log(sourceData, apiCalls);

                        if (!apiCalls || apiCalls.length === 0)
                        {
                            setLoading(false);
                            return;
                        }

                        Promise.all(apiCalls)
                            .then((responses) => {
                                if (!responses || responses.length === 0)
                                    return;

                                const newSelectedTalents = [];

                                hotTable.batch(() => {
                                    responses.forEach(x => {
                                        console.log(x);
                                        newSelectedTalents.push({
                                            row: x.data.rowNumber,
                                            column: "talent",
                                            infoColumn: "info",
                                            candidateId: x.data.candidateId,
                                            name: x.data.candidatename,
                                            isOverlapped: x.data.isOverlapped
                                        });
                                        
                                        const infoCol = hotTable.propToCol("info");
                                        hotTable.setDataAtCell(x.data.rowNumber, infoCol, "Y");
                                        const overlappedCol = hotTable.propToCol("isOverlapped");
                                        hotTable.setDataAtCell(x.data.rowNumber, overlappedCol, x.data.isOverlapped ? "Y" : "N");
                                        const duplicateTalent = sourceData?.filter(y => y.source?.talentData?.candidateId === x.data.candidateId)[0];
                                        console.log(duplicateTalent);
                                        const isOverlapped = x.data.isOverlapped || duplicateTalent;
                                        hotTable.setDataAtCell(x.data.rowNumber, overlappedCol, isOverlapped ? "Y" : "N");

                                        if (duplicateTalent)
                                            hotTable.setDataAtCell(duplicateTalent.rowIdx, overlappedCol, "Y");
                                    });

                                    setSelectedTalents(newSelectedTalents);
                                });

                                const indexes = hotTable.getSourceDataAtRow(changes[0][0]).index;

                                updateTalent(indexes, changes[0][3]);
                            })
                            .catch((errors) => {
                                console.log(errors);
                            })
                            .finally(() => setLoading(false));
                    }
                }}
            >
                <HotColumn data={'project.projectName'} readOnly={true} />
                <HotColumn data={'refNo'} readOnly={true} />
                <HotColumn data={'hrispositionMaster.hrispositionMasterName'} readOnly={true} />
                <HotColumn data={'slotNo'} readOnly={true} />
                <HotColumn
                    data={'talent'}
                    type="dropdown"
                    strict={true}
                    source={(query, process) => {
                        searchName(query, process);
                    }}

                />
                <HotColumn
                    data={'info'}
                    readOnly={true}
                >
                    <InfoButton
                        hot-renderer
                    />
                </HotColumn>
                <HotColumn
                    data={'isOverlapped'}
                    readOnly={true}
                >
                    <OverlappedButton
                        hotTable={hotTableComponent.current?.hotInstance}
                        hot-renderer
                    />
                </HotColumn>
                {hotColumns.map(m => {
                    return (<HotColumn  readOnly={true}
                        key={`${m.format('YYYYMMDD')}`} 
                        data={`dates.${m.format('YYYYMMDD')}`} 
                        className={'data-cell'}
                    ></HotColumn>)
                })}
            </HotTable>
        }
        </>);
    }, [tableData, tableColumns, hotTableComponent.current]);

    const handleGenerateTalent = useCallback(() => {
        setLoading(true);
        const apiCalls = [];
        const filters = [];
        
        tableData.forEach((m) => {
            m.__children.forEach(p => {
                // console.log(p);
                const slotDates = Object.keys(p.dates)?.filter(d => p.dates[d] && p.dates[d] !== "");
                slotDates.sort();

                if (!slotDates || slotDates.length === 0)
                    return;

                filters.push({
                    projectId: m.project.projectId,
                    positionId: p.hrispositionMaster.hrispositionMasterId,
                    companyId: m.entity?.lookupId,
                    refNo: p.refNo,
                    slot: positionData.filter(d => d.hrispositionMaster.hrispositionMasterId === p.hrispositionMaster.hrispositionMasterId && d.refNo === p.refNo)[0].slot,
                    startDate: moment(slotDates[0], "YYYYMMDD").format("YYYY-MM-DD"),
                    endDate: moment(slotDates[slotDates.length - 1], "YYYYMMDD").format("YYYY-MM-DD")
                });
            })
        });
        const unique = [...new Map(filters.map((item) => [item["refNo"], item])).values(),];
        // console.log(unique);
        const queryString = qs.stringify({filters: unique}, { allowDots: true });

        // api.get(`/rosterwizard/top-talents?${queryString}`)
        api.post(`/rosterwizard/top-talents`, filters)
            .then((data) => {
            const talents = [];

            if (!data.data || data.data.length === 0)
            {
                setTopTalents(talents);
                return;
            }

            data.data.forEach(item => {
                if (!item.candidates || item.candidates.length === 0)
                    return;

                const candidate = item.candidates;

                candidate.forEach(inner => {
                    if (talents.filter(x => x.candidateId === inner.candidateId).length > 0)
                        return;

                    talents.push({
                        projectId: item.projectId,
                        positionId: item.positionId,
                        refNo: item.refNo,
                        candidateId: inner.candidateId,
                        candidateName: inner.candidateName
                    })
                })
            })

            setTopTalents(talents);
        })
        .catch((error) => console.log(error.response))
        .finally(() => {
            setLoading(false);
        })
    }, [tableData]);

    const handleNextPressed = useCallback(() => {
        //console.log('selectedTalents',selectedTalents);
        // if (!selectedTalents || selectedTalents.length === 0)
        //     return;
        
        //console.log('handleNextPressed tableData',tableData);
        setLoading(true);
        const data = {
            rosters: [
                ...tableData.map((m, k) => {
                    const unique = [...new Map(m.__children.map((item) => [item["refNo"], item])).values(),];
                    //console.log('unique',unique);
                    return {
                        ...m,
                        positions: [
                            //...m.__children.map((p, j) => {
                            ...unique.map((p, j) => {
                                return {
                                    ...p,
                                    candidates: [
                                        //...p.__children?.map((c, i) => {
                                        ...m.__children.filter(f => f.refNo === p.refNo)?.map((c, i) => {

                                            let candidateId = 0;
                                            if (c.talent) {
                                                const splittedValue = c.talent.split(" - ");
                                                candidateId = parseInt(splittedValue[1]) ?? 0;
                                            }

                                            return {
                                                projectId: m.project.projectId,
                                                hrispositionMasterId: p.hrispositionMaster.hrispositionMasterId,
                                                //candidateId: c.talentData.candidateId,
                                                candidateId,
                                                dates: hotColumns.reduce((pv, cv, idx) => {
                                                    const rowIndex = selectedRows.findIndex(f => f === p.refNo);
                                                    const row = selectedData[rowIndex];
                                                    const value = row.filter(f => moment(f.date).format("DD/MM/YYYY") === cv.format('DD/MM/YYYY'))[0] ?? 0;
                                                    // console.log("value, ", {rowIndex, value, i, row});
                                                    
                                                    pv[cv.format('YYYYMMDD')] = (value && value.fte && value.fte >= (i + 1) && c.dates[cv.format('YYYYMMDD')] === "Y") ? value.fte : 0;
                                                    
                                                    return pv;
                                                }, {})
                                            };
                                        })
                                    ]
                                }
                            })
                        ]
                    }
            })],
            manpowerForecasts: selectedManpowerForecasts
        };

        console.log(data);
        
        api.post(`/rosterwizard/generate-roster`, data)
            .then((response) => {
                setLoading(false);
                history.push(`/roster?projectid=${response.data.projectId}&dispatchunitid=${response.data.dispatchUnitId}&rosterid=${response.data.rosterId}`);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error.response);
            });
    }, [tableData, selectedTalents, hotColumns, selectedRows, selectedCols, selectedData, selectedManpowerForecasts]);

    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            onConfirm: () => setErrorMessage(null),
            message: errorMessage
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage]);


    return (
        <section className="main">
            {(loading) && <Loader />}
            {errorNotification.length > 0 && errorNotification}
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card className="no-transition">
                            <CardBody className='pt-1'>
                                <CardTitle>
                                    <h4 className="text-center">Roster Wizard - Assign Resources</h4>
                                </CardTitle>
                                <Container fluid>
                                    <Row className="mb-3">
                                        <Col lg={4} className="d-flex flex-wrap justify-content-start">
                                            <TooltipButton
                                                id="generatetalent"
                                                title="Auto-Select Resources"
                                                className="btn-icon"
                                                color="default"
                                                type="button"
                                                disabled={!tableData || tableData.length === 0}
                                                onClick={() => handleGenerateTalent()}
                                            >
                                                <i className="fas fa-download pt-1"></i> Auto-Select Resources
                                            </TooltipButton>
                                        </Col>
                                        <Col lg={8} className="d-flex flex-wrap justify-content-end align-items-center">
                                            <Row className="mr-4 h-100">
                                                <Col className="p-0">
                                                    <div className='d-flex align-items-center justify-content-center text-muted mx-1 h-100 p-0'>
                                                        [Y] : Work Days
                                                    </div>
                                                </Col>
                                            </Row>
                                            <ButtonGroup>
                                                <Button
                                                    color="secondary"
                                                    onClick={() => {
                                                        history.push({
                                                            pathname: "/rosterwizard",
                                                            state: {
                                                                projects: projects,
                                                                positions: positions,
                                                                dates: dates,
                                                                projectDataState: projectData,
                                                                positionDataState: positionData,
                                                                selectedData,
                                                                selectedCols,
                                                                selectedRows,
                                                                forecastFilters,
                                                                selectedManpowerForecasts
                                                            }
                                                        });
                                                    }}
                                                >
                                                    Back
                                                </Button>
                                                <Button
                                                    color="secondary"
                                                    onClick={() => {
                                                        handleNextPressed();
                                                    }}
                                                >
                                                    Submit
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                        {
                                            rosterGrid
                                        }
                                        </Col>
                                    </Row>
                                    
                                </Container>
                            </CardBody>

                            <div className='d-flex justify-content-end border-top'>
                                <p className='mb-0 mr-4' id='agg-data'>{'\u00A0'}</p>
                            </div>

                        </Card>
                    </Col>
                </Row>
            </Container>
        {
            itemInEdit &&
            <CandidateEditor
                item={itemInEdit}
                onClose={() => setItemInEdit(null)}
                onSaved={() => setItemInEdit(null)}
                lookups={{
                    ...lookups
                }}
            />
        }
        {
            talentConflictSchedule &&
            <ConflictDialog 
                data={talentConflictSchedule}
                close={() => setTalentConflictSchedule(null)}
            />
            // <ConflictScheduleEditor
            //     candidate={talentConflictSchedule}
            //     onClose={() => setTalentConflictSchedule(null)}
            //     onSaved={() => setTalentConflictSchedule(null)}
            // />
        }
        </section>
    );
}

export default RosterWizard;