import React from 'react';
import {
    ButtonGroup, Button, Container, Row, Col, Input
 } from "reactstrap";
 import Pager from "./Pager";
 import PropTypes from "prop-types";
 import { TooltipButton } from './inputs';

function DataList(props)
{
    const {
        hideLabel,
        title,
        totalRecords,
        pageSize,
        rows,
        tableProps,
        headerGroups,
        tableBodyProps,
        prepareRow,
        onSearchClick,
        toggleRowSelected,
        selectedFlatRows,
        onDataDoubleClick,
        onSingleDataMove,
        onMultipleDataMove,
        onLoadMore,
        isPicker,
        isLeftSide,
        onChangePage,
        onChangePageSize,
    } = props;

    const [ searchText, setSearchText ] = React.useState("");

    return (
        <>
            <Container fluid className="w-100">
                <Row className="w-100 d-flex justify-content-center align-items-center">
                {
                    !hideLabel &&
                    <Col className="align-middle">
                        <span className="align-middle">{title}</span>
                    </Col>
                }
                    <Col className="justify-content-center">
                        <ButtonGroup>
                            <Input
                                className="border-dark rounded-0"
                                placeholder="Search..."
                                bsSize="sm"
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            <Button
                                id={`${isLeftSide ? "left" : "right"}-search`}
                                title="Search"
                                className="btn-icon"
                                color="dark"
                                size="sm"
                                type="button"
                                outline
                                onClick={() => {
                                    if(onSearchClick)
                                        onSearchClick(searchText);
                                }}
                            >
                                <i className="fas fa-search"></i>
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Container>
            <br/>
            <div className="p-0 m-0 w-100" style={{overflowX: "scroll"}}>
                <div {...tableProps} className="table table-hover" style={{border: "solid 1px #CCC"}}>
                    <div className="w-100" style={{overflowY: "scroll"}}>
                    {
                        headerGroups.map(headerGroup => {
                            return (
                                <div {...headerGroup.getHeaderGroupProps()} className="tr w-100">
                                {
                                    !isLeftSide ?
                                    <div role="columnHeader" className="th td-action text-center">
                                        <Button
                                            id="right-move-all"
                                            title="Move All"
                                            className="btn-icon"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={() => {
                                                onMultipleDataMove(selectedFlatRows);
                                            }}
                                            disabled={!selectedFlatRows || selectedFlatRows.length === 0}
                                        >
                                            <i className="fas fa-angle-double-left"></i>
                                        </Button>
                                    </div>
                                    :
                                    null
                                }
                                {
                                    headerGroup.headers.map((column, index) => {
                                        return (
                                            <div {...column.getHeaderProps()} className="th">
                                                {column.render('Header')}
                                            </div>
                                        );
                                    })
                                }
                                {
                                    isLeftSide ?
                                    <div role="columnHeader" className="th td-action text-center">
                                        <Button
                                            id="left-move-all"
                                            title="Move All"
                                            className="btn-icon"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={() => {
                                                onMultipleDataMove(selectedFlatRows);
                                            }}
                                            disabled={!selectedFlatRows || selectedFlatRows.length === 0}
                                        >
                                            <i className="fas fa-angle-double-right"></i>
                                        </Button>
                                    </div>
                                    :
                                    null
                                }
                                </div>
                            );
                        })
                    }
                    </div>
                    <div {...tableBodyProps} style={{height: "200px", overflowY: "scroll", overflowX: "hidden"}}>
                        {
                            rows.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <div {...row.getRowProps()} className="tr w-100">
                                    {
                                        isPicker && !isLeftSide ?
                                        <div className="td td-action text-center">
                                            <TooltipButton
                                                id={`add_${i}`}
                                                title="Add"
                                                className="btn-icon"
                                                color="default"
                                                size="sm"
                                                type="button"
                                                outline
                                                onClick={() => {
                                                    onSingleDataMove(row.original)
                                                }}
                                            >
                                                <i className="fas fa-chevron-left"></i>
                                            </TooltipButton>
                                        </div>
                                        :
                                        null
                                    }
                                    {
                                        row.cells.map(cell => {
                                            return <div {...cell.getCellProps({
                                                onClick: cell.column.id !== "selection" ? () => toggleRowSelected(row.id) : undefined
                                            })}
                                                className={`td ${cell.column.id !== "selection" ? "cursor-pointer" : ""}`}
                                                
                                            >
                                                {cell.render('Cell')}
                                            </div>;
                                        })
                                    }
                                    {
                                        isPicker && isLeftSide ?
                                        <div className="td td-action text-center">
                                            <TooltipButton
                                                id={`add_${i}`}
                                                title="Add"
                                                className="btn-icon"
                                                color="default"
                                                size="sm"
                                                type="button"
                                                outline
                                                onClick={() => {
                                                    onSingleDataMove(row.original)
                                                }}
                                            >
                                                <i className="fas fa-chevron-right"></i>
                                            </TooltipButton>
                                        </div>
                                        :
                                        null
                                    }
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className="p-2">
                        <Pager
                            totalItems={totalRecords ?? 0}
                            rowInPage={rows.length ?? 0}
                            pageSize={pageSize} 
                            onChangePage={(pager) => {
                                if(onChangePage)
                                    onChangePage(pager.currentPage);
                            }}
                            onChangePageSize={(size) => {
                                if(onChangePageSize)
                                    onChangePageSize(size);
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

DataList.propTypes = {
    hideLabel: PropTypes.bool,
    title: PropTypes.string.isRequired,
    totalRecords: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    rows: PropTypes.array.isRequired,
    tableProps: PropTypes.object.isRequired,
    headerGroups: PropTypes.array.isRequired,
    tableBodyProps: PropTypes.object.isRequired,
    prepareRow: PropTypes.func.isRequired,
    toggleRowSelected: PropTypes.func.isRequired,
    selectedFlatRows: PropTypes.array,
    onDataDoubleClick: PropTypes.func,
    onSingleDataMove: PropTypes.func,
    onLoadMore: PropTypes.func.isRequired,
    isPicker: PropTypes.bool,
    isLeftSide: PropTypes.bool,
}

DataList.defaultProps = {
    hideLabel: false,
    selectedFlatRows: [],
    isPicker: false,
    isLeftSide: false,
}

export default DataList;
