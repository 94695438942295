import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { Input } from 'reactstrap';
import { KEY_ARROW_LEFT, KEY_ARROW_RIGHT, KEY_BACKSPACE, KEY_DELETE, KEY_ENTER, KEY_TAB } from '../../utils';

const NumericEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);
    const refInput = useRef(null);

    // focus on the input
    useEffect(() => {
        refInput.current.focus();
        refInput.current.select();
    }, []);

    /* Utility Methods */
    const cancelBeforeStart =
        props.eventKey &&
        props.eventKey.length === 1 &&
        '1234567890.,'.indexOf(props.eventKey) < 0;

    const isLeftOrRight = (event) => {
        return [KEY_ARROW_LEFT, KEY_ARROW_RIGHT].indexOf(event.key) > -1;
    };

    const isCharNumeric = (charStr) => {
        return !!/\d/.test(charStr);
    };

    const isNumericKey = (event) => {
        const charStr = event.key;
        return isCharNumeric(charStr) || '.,'.indexOf(charStr) >= 0;
    };

    const isBackspace = (event) => {
        return event.key === KEY_BACKSPACE || event.key === KEY_DELETE;
    };

    const finishedEditingPressed = (event) => {
        const key = event.key;
        return key === KEY_ENTER || key === KEY_TAB;
    };

    const onKeyDown = (event) => {
        if (isLeftOrRight(event) || isBackspace(event)) {
            event.stopPropagation();
            return;
        }

        if (!finishedEditingPressed(event) && !isNumericKey(event)) {
            if (event.preventDefault) event.preventDefault();
        }

        if (finishedEditingPressed(event)) {
            props.stopEditing();
        }
    };

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            // the final value to send to the grid, on completion of editing
            getValue() {
                return value === '' || value === null ? null : parseFloat(value);
            },
            isCancelBeforeStart() {
                return false;
            },
            isCancelAfterEnd() {
                const finalValue = this.getValue();
                // console.log({value, finalValue});
                return finalValue === null ? null : finalValue === null;
            },
        };
    });

    return (
        <input
            ref={refInput}
            value={value ?? ""}
            onChange={(event) => setValue(event.target.value)}
            onKeyDown={(event) => onKeyDown(event)}
            className="text-right h-100 w-100 custom-grid-editor k-input"
            autoComplete="off"
        />
    );
});

export default NumericEditor;