const ContractTermValidation = [
    {
        field: "sectionTypeId",
        tests: [
            {
                type: "required",
                message: "Section Type is required.",
                validate: (obj) => obj.sectionTypeId ? true : false,
            }
        ]
    },
    {
        field: "contractTermCode",
        tests: [
            {
                type: "required",
                message: "Contract Term Code is required.",
                validate: (obj) => {
                    return obj.contractTermCode ? true : false
                },
            }
        ]
    },
    {
        field: "contractTermTitle",
        tests: [
            {
                type: "required",
                message: "Contract Term Title is required.",
                validate: (obj) => {
                    return obj.contractTermTitle ? true : false
                },
            }
        ]
    },
    {
        field: "contractTermValue",
        tests: [
            {
                type: "required",
                message: "Contract Term Value is required.",
                validate: (obj) => {
                    return obj.contractTermValue || obj.file || obj.templateFilename ? true : false
                },
            }
        ]
    },
];
export {
    ContractTermValidation
};