import React from 'react';
import api from "../../../../services/api";
import {
    Input, Label, FormGroup, Button, Modal, Row, Col
 } from "reactstrap";
 import Loader from "../../../loaders";
import { AccommodationValidation } from "../Validations/AccommodationValidation";

export default function AccommodationEditor(props) {
    const {
        item,
        onSaved,
        onClose,
    } = props;

    const [ itemInEdit, setItemInEdit ] = React.useState(item);
    const [ loading, setLoading ] = React.useState(false);
    const [ validationAlert, setValidationAlert ] = React.useState([]);
    const apiurl = "accommodation";

    const handleTextChanged = ({ target: { name, value } }) => {
        setItemInEdit({ ...itemInEdit, [name]: value });
    };

    const validate = React.useCallback((itemToCheck) => {
        let errors = validationAlert.map(x => x);

        AccommodationValidation.forEach((value) => {
            value.tests.forEach((test) => {
                const exists = errors.some((item) => item.key === value.field && item.type === test.type);
                const result = test.validate(itemToCheck);

                if (!result && !exists)
                    errors.push( { key: value.field, type: test.type, message: test.message } )
                
                if (result && exists)
                    errors = errors.filter((item) => item.key !== value.field && item.type !== test.type);
            })
        });

        setValidationAlert(errors);
        return errors.length === 0;
    }, [itemInEdit, validationAlert]);

    const handleSave = (item) => {
        if (!validate(item))
            return;
        
        setLoading(true);
        const data = JSON.stringify(item);
        const save = item.accommodationId > 0
            ? api.put(`${apiurl}/${item.accommodationId}`, data)
            : api.post(`${apiurl}`, data)

        save
        .then((response) => {
            setLoading(false);
            onSaved();
        })
        .catch((error) => {
            setLoading(false);
            console.error(error.message);
        });
    };
    
    return (
        <Modal
            isOpen={true}
            className="modal-xl"
            modalClassName="db-example-modal-xl"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Accommodation
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onClose}
                    type="button"
                >
                    <span aria-hidden={true}>x</span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                {/* <Container> */}
                    {(loading) && <Loader />}
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Name
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    name="accommodationName"
                                    id="accommodationName"
                                    type="text"
                                    required
                                    value={itemInEdit.accommodationName || ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Address
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    name="accommodationAddress"
                                    id="accommodationAddress"
                                    type="text"
                                    required
                                    value={itemInEdit.accommodationAddress || ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                {/* </Container> */}
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave(itemInEdit)}
                    type="button"
                >
                    Save Changes
                </Button>
            </div>
        </Modal>
    )
}