import React, { Component } from 'react';
import qs from 'query-string';

export default class RosterSiteExport extends Component {

    componentDidMount() {
        const params = qs.parse(this.props.location.search);
        window.jQuery('#reportViewer1')
            .telerik_ReportViewer({
                serviceUrl: 'api/reports/',
                reportSource: {
                    report: './RosterSiteExport.trdp',
                    parameters: {
                        "start": params.start,
                        "end": params.end,
                        "projectId": params.projectId,
                        "dispatchUnitId": params.dispatchUnitId,
                    }
                },
                scale: 1.0,
                viewMode: 'INTERACTIVE',
                printMode: 'SPECIFIC',
            });
    }

  render() {
      return <div id="reportViewer1"></div>
  }
}