/* eslint-disable no-unused-vars */
import React, { createContext, useReducer, useContext } from "react";
import rosterReducer, { initialState, actions } from "../reducers/rosterReducer";

export const RosterContext = createContext(initialState);

export const RosterProvider = ({ children }) => {
    const [state, dispatch] = useReducer(rosterReducer, initialState);

    //console.log('RosterProvider', 'rosterReducer', rosterReducer);
    //console.log('RosterProvider', 'initialState', initialState);
    //console.log('RosterProvider', 'state', state);
    //console.log('RosterProvider', 'dispatch', dispatch);
    //console.log('RosterProvider', 'children', children);
    //console.log('RosterProvider', 'actions', actions);

    const setProject = (project) => {
        dispatch({
            type: actions.SET_PROJECT,
            payload: {
                project: project
            }
        });
    }

    const setDispatchUnit = (dispatchUnit) => {
        dispatch({
            type: actions.SET_DISPATCH,
            payload: {
                dispatchUnit: dispatchUnit
            }
        });
    }

    const setStartDate = (startDate) => {
        dispatch({
            type: actions.SET_START,
            payload: {
                startDate: startDate
            }
        });
    }

    const setEndDate = (endDate) => {
        dispatch({
            type: actions.SET_END,
            payload: {
                endDate: endDate
            }
        });
    }

    const setTrimStartDate = (trimStartDate) => {
        dispatch({
            type: actions.SET_TRIM_START,
            payload: {
                trimStartDate: trimStartDate
            }
        });
    }

    const setTrimEndDate = (trimEndDate) => {
        dispatch({
            type: actions.SET_TRIM_END,
            payload: {
                trimEndDate: trimEndDate
            }
        });
    }

    const setShowArchivedDispatchUnits = (showArchivedDispatchUnits) => {
        dispatch({
            type: actions.SET_SHOW_ARCHIVED,
            payload: {
                showArchivedDispatchUnits: showArchivedDispatchUnits
            }
        });
    }

    const setDataLoaded = (dataLoaded) => {
        dispatch({
            type: actions.SET_DATA_LOADED,
            payload: {
                dataLoaded: dataLoaded
            }
        });
    }

    const setDataExists = (dataExists) => {
        dispatch({
            type: actions.SET_DATA_EXISTS,
            payload: {
                dataExists: dataExists
            }
        });
    }
    
    const setDispatchEditorOpen = (dispatchEditorOpen) => {
        dispatch({
            type: actions.SET_DISPATCH_EDITOR_OPEN,
            payload: {
                dispatchEditorOpen: dispatchEditorOpen
            }
        });
    }
    
    const setNotification = (notification) => {
        dispatch({
            type: actions.SET_NOTIFICATION,
            payload: {
                notification: notification
            }
        });
    }

    const setContingencyOpen = (contingencyOpen) => {
        dispatch({
            type: actions.SET_CONTINGENCY_OPEN,
            payload: {
                contingencyOpen: contingencyOpen
            }
        });
    }

    const value = {
        setProject,
        setDispatchUnit,
        setStartDate,
        setEndDate,
        setTrimStartDate,
        setTrimEndDate,
        setShowArchivedDispatchUnits,
        setDataLoaded,
        setDataExists,
        setDispatchEditorOpen,
        setNotification,
        setContingencyOpen,
        project: state.project,
        dispatchUnit: state.dispatchUnit,
        startDate: state.startDate,
        endDate: state.endDate,
        trimStartDate: state.trimStartDate,
        trimEndDate: state.trimEndDate,
        showArchivedDispatchUnits: state.showArchivedDispatchUnits,
        dataLoaded: state.dataLoaded,
        dataExists: state.dataExists,
        dispatchEditorOpen: state.dispatchEditorOpen,
        notification: state.notification,
        contingencyOpen: state.contingencyOpen,
    };
    return <RosterContext.Provider value={value}>{children}</RosterContext.Provider>;
}

const useRoster = () => {
    const context = useContext(RosterContext);
  
    if (context === undefined) {
      throw new Error("useRoster must be used within RosterProvider");
    }
  
    return context;
  };
  
  export default useRoster;