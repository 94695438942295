
export const ScheduleTypeCodes = {
    Mobilise: "M",
    Demobilise: "D",
    Start: "S",
    End: "E",
    Working: "W",
    Training: "T",
    Leave: "L",
    RNR: "R",
    SickLeave: "U",
    FatigueDay: "F",
    DriveOut: "O",
    DriveIn: "C",
    LWOP: "P",
    Night: "N",
    InactiveDate: "I",
    WorkFromHome: "H",
    HalfDay: "Y",
};

export const ScheduleTypeValues = {
    M: "Mobilise",
    D: "Demobilise",
    S: "Start",
    E: "End",
    W: "Working Day",
    T: "Training",
    L: "Leave",
    R: "R&R",
    U: "Sick Leave",
    F: "Fatigue Day",
    O: "Drive-Out",
    C: "Drive-In",
    P: "LWOP",
    N: "Working Night",
    I: "Inactive Date",
    H: "Work from Home",
    Y: "Half Day",
};

export const ScheduleTypeInvalid = {
    M: [],
    D: [],
    S: [],
    E: [],
    W: ["N","T","L","R","U","F", "H", "Y"],
    T: ["W", "N", "H", "Y"],
    L: ["W", "N", "H", "Y"],
    R: ["W", "N", "H", "Y"],
    U: ["W", "N", "H", "Y"],
    F: ["W", "N", "H", "Y"],
    O: [],
    C: [],
    P: [],
    N: ["W","T","L","R","U","F", "H", "Y"],
    H: ["W", "N","T","L","R","U","F", "Y"],
    Y: ["W", "N","T","L","R","U","F", "H"],
};

export const MoveDirection = {
    Up: "Up",
    Down: "Down",
};

export const ScheduleTypeMobilisation = [
    "M",
    "D",
    "O",
    "C",
];
