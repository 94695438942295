import React from 'react';
import api from "../../../services/api";
import {
    Label, FormGroup, Button, Modal, Container, Row, Col, Alert, Input, ButtonGroup
 } from "reactstrap"; 
import Loader from "../../loaders";
import { ComboBox } from "../../dropdowns";
import { ProjectContractTermValidation } from "./Validation";
import { TooltipButton } from '../../inputs';
import fileDownload from "js-file-download";
import { TermValueSources } from '../../../utils';
import { FileDropzone, DropzoneList } from '../../FileDropzone';

export default function Editor(props) {
    const {
        item
    } = props;

    const [ loading, setLoading ] = React.useState(false);
    const [ projectContractTerm, setProjectContractTerm ] = React.useState(item ?? {});
    const [ contractTerm, setContractTerm ] = React.useState(item ? (item.contractTerm ?? {}) : {});
    const [ selectedValueSource, setSelectedValueSource ] = React.useState(item?.templateContent ? TermValueSources[1] : TermValueSources[0]);
    const [ validationAlert, setValidationAlert ] = React.useState([]);

    const apiurl = "projectcontractterm";

    React.useEffect(() => {
        setProjectContractTerm( { ...projectContractTerm, contractTermValue: selectedValueSource.key == 2 ? null : projectContractTerm?.contractTermValue } );
    }, [selectedValueSource]);

    const handleTextChanged = ({ target: { name, value } }) => {
        setProjectContractTerm({ ...projectContractTerm, [name]: value });
    }

    const downloadAttachment = (fileInfo) => {
        if (fileInfo instanceof File) {
            fileDownload(fileInfo, fileInfo.name);
            return;
        }
        
        if (!fileInfo.contractTermId) {
            setLoading(true);
            let url = `projectcontractterm/download/${fileInfo.projectContractTermId}`;
            api.get(url, { responseType: 'blob' })
            .then(blob => {
                fileDownload(blob.data, fileInfo.templateFilename);
            }).catch(error => {
                console.error(error)
            }).finally(() => setLoading(false));
            return;
        }
        
        setLoading(true);
        let url = `contractterm/download/${fileInfo.contractTermId}`;
        api.get(url, { responseType: 'blob' })
        .then(blob => {
            fileDownload(blob.data, fileInfo.templateFilename);
        }).catch(error => {
            console.error(error)
        }).finally(() => setLoading(false));
    }

    const files = React.useMemo(() => {
        if (projectContractTerm?.file)
            return <DropzoneList
                name={projectContractTerm.file.name}
                size={projectContractTerm.file.size}
                download={downloadAttachment}
                itemInEdit={projectContractTerm}
            />;
        
        if (projectContractTerm?.templateFilename)
        {
            setSelectedValueSource(TermValueSources[1]);
            return <DropzoneList
                name={projectContractTerm.templateFilename}
                size={projectContractTerm.templateLength}
                download={downloadAttachment}
                itemInEdit={projectContractTerm}
            />;
        }
        
        if (contractTerm?.templateFilename) {
            setSelectedValueSource(TermValueSources[1]);
            return <DropzoneList
                name={contractTerm.templateFilename}
                size={contractTerm.templateLength}
                download={downloadAttachment}
                itemInEdit={contractTerm}
            />;
        }
        
        return null;
    }, [projectContractTerm]);

    React.useEffect(() => {    
        setProjectContractTerm({...projectContractTerm, contractTermId: contractTerm?.contractTermId ?? 0});
    }, [contractTerm]);

    const validate = React.useCallback((itemToCheck) => {
        let errors = validationAlert.map(x => x);

        ProjectContractTermValidation.forEach((value) => {
            value.tests.forEach((test) => {
                const exists = errors.some((item) => item.key === value.field && item.type === test.type);
                const result = test.validate(itemToCheck);

                if (!result && !exists)
                    errors.push( { key: value.field, type: test.type, message: test.message } )
                
                if (result && exists)
                    errors = errors.filter((item) => item.key !== value.field && item.type !== test.type);
            })
        });

        setValidationAlert(errors);
        return errors.length === 0;
    }, [item, validationAlert]);

    const handleSave = (contractTermData) => {
        if (!validate(contractTermData))
            return;

        setLoading(true);
        const url = `${apiurl}${contractTermData.projectContractTermId ? `/${contractTermData.projectContractTermId}` : ""}`;
        const method = `${contractTermData.projectContractTermId ? "PUT" : "POST"}`;
        const formData = new FormData();

        formData.append("projectId", contractTermData.projectId);
        formData.append("seqNo", contractTermData.seqNo ?? "0");

        if(item.projectContractTermId)
            formData.append("projectContractTermId", item.projectContractTermId);

        formData.append("contractTermId", contractTermData.contractTermId);
        
        if(contractTermData.file)
            formData.append("file", contractTermData.file);

        if(!contractTermData.file && !contractTermData.contractTermId)
            formData.append("contractTermValue", contractTermData.contractTermValue ? contractTermData.contractTermValue : "");


        contractTermData.contractTerm = null;
        
        api({ url, method, data: formData })
        .then((response) => {
            setLoading(false);
            props.onSaved();
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
        });
    }

    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    {props.title ?? "Title"}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}>x</span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                {
                    validationAlert && validationAlert.length > 0 &&
                    <Alert color="warning" className="pa-0 d-flex justify-content-between">
                        <span className="alert-text">
                            <ul className="mb-0">
                                {
                                    validationAlert.map((item, index) => <li key={index}>{item.message}</li>)
                                }
                            </ul>
                        </span>
                    </Alert>
                }
                <Container>
                    {(loading) && <Loader />}
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Contract Term Code
                                </Label>
                                <ComboBox 
                                    endpoint={`/contractterm/project/search/${item.projectId}/${item.projectContractTermId ?? 0}`}
                                    isLookup={false}
                                    idField="contractTermId"
                                    valueField="contractTermCode"
                                    selectedItem={contractTerm}
                                    onChange={(term) => {
                                        setContractTerm(term);
                                        handleTextChanged({target: {name: "contractTermValue", value: term?.contractTermValue ?? ""}});
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Contract Term Title : {`${contractTerm ? (contractTerm.contractTermTitle ?? "") : ""}`}
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <ButtonGroup size="sm">
                                <TooltipButton
                                    id="manualinput"
                                    active={selectedValueSource.key === 1}
                                    title="Manual Input"
                                    className="btn-icon mb-2"
                                    name="viewMode"
                                    value={TermValueSources[0]}
                                    onClick={() => {
                                        setSelectedValueSource(TermValueSources[0]);
                                    }}
                                >
                                    <i className="fas fa-pencil-alt pt-1"></i>
                                </TooltipButton>
                                <TooltipButton
                                    id="document"
                                    active={selectedValueSource.key === 2}
                                    title="Upload Document"
                                    className="btn-icon mb-2"
                                    name="viewMode"
                                    value={TermValueSources[1]}
                                    onClick={() => {
                                        setSelectedValueSource(TermValueSources[1]);
                                    }}
                                >
                                    <i className="fas fa-upload pt-1"></i>
                                </TooltipButton>
                            </ButtonGroup>
                        </Col>
                        {
                            (selectedValueSource.key == 1) &&
                            <Col xs={12}>
                                <FormGroup>
                                    <Label>
                                        Term Values
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        name="contractTermValue"
                                        id="contractTermValue"
                                        type="textarea"
                                        rows={6}
                                        value={projectContractTerm?.contractTermValue ?? ""}
                                        onChange={handleTextChanged}
                                    />
                                </FormGroup>
                            </Col>
                        }
                        {
                            (selectedValueSource.key == 2) &&
                            <FileDropzone
                                files={files}
                                onDrop={([file]) => {
                                    setProjectContractTerm( { ...projectContractTerm, file } );
                                }}
                            />
                        }
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={props.onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave(projectContractTerm)}
                    type="button"
                >
                    Save Changes
                </Button>
            </div>
        </Modal>
    )
}