import React from 'react';
import moment from "moment";
import { MakeWords } from "./StringHelper";

export function DateValueFormatter(params) {
    //console.log('DateValueFormatter');
    //console.log('params', params);
    var { value } = params;

    if (typeof value == "undefined") {
        return null;
    }

    // console.log('moment', moment);
    // console.log('params', params);

    return value ? moment(value).format("DD.MM.YYYY") : "N/A";
}

export function DateTimeValueFormatter(params) {
    //console.log('DateValueFormatter');
    //console.log('params', params);
    var { value } = params;

    if (typeof value == "undefined") {
        return null;
    }

    return value ? moment(value).format("DD.MM.YYYY HH:mm:ss") : "N/A";
}

export function CurrencyValueFormatter(params) {

    const currency = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD'
    });

    var { value } = params;
    return value ? currency.format(value) : "";
}


export function MakeWordsValueGetter(params) {
    var { data } = params;
    var { messages } = data;

    var words = MakeWords(messages);
    if (!words) return "N/A";
    return words;
}

export function ImportDialogMessageCellRenderer(params) {
    var { data } = params;
    var { status } = data;
    var statusText = status ? "Ok" : "Invalid";
    var statusIcon = status ? "fas fa-check text-success" : "fas fa-times text-danger";
    // console.log({original, withdrawn});

    return (
        <div className={status ? "green-pastel text-dark" : "red-pastel text-dark"}>
            <span className={`${statusIcon} mr-1`}></span>
            {statusText}
        </div>
    );
}


export function DateComparator(filterLocalDateAtMidnight, cellValue) {

    var dateAsString = cellValue;

    if (dateAsString == null) {
        return -0;
    }



    // In the example application, dates are stored as dd/mm/yyyy
    // We create a Date object for comparison against the filter date
    //var dateTimeParts = dateAsString.split('T');
    //if (!dateTimeParts || dateTimeParts.length == 0) {
    //    return -1;
    //}

    //var dateOnlyParts = dateTimeParts[0].split('-');
    //var day = Number(dateOnlyParts[2]);
    //var month = Number(dateOnlyParts[1]) - 1;
    //var year = Number(dateOnlyParts[0]);
    //var cellDate = new Date(year, month, day);

    var cellDate = moment(dateAsString).startOf('date').toDate();

    if (!cellDate || isNaN(cellDate)) {
        //console.error('cellDate is invalid', 'cellDate', cellDate);
        return -1;
    }

    //console.log('filterLocalDateAtMidnight', filterLocalDateAtMidnight);
    //console.log('cellDate', cellDate);
    //console.log('dateAsString', dateAsString);
    //console.log('dateParts', dateOnlyParts);

    // Now that both parameters are Date objects, we can compare
    if (cellDate < filterLocalDateAtMidnight) {
        //console.log('return -1');
        return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
        //console.log('return 1');
        return 1;
    }
    //console.log('return 0');
    return 0;

}

export function SmartGroup(columns, rendererType, customRenderer) {
    return function (params) {
        const currentRowIndex = params.node.rowIndex;
        const currentPage = params.api.paginationGetCurrentPage();
        const pageSize = params.api.paginationGetPageSize();
        const rowIndexOnCurrentPage = currentRowIndex - currentPage * pageSize;

        if (rowIndexOnCurrentPage > 0) {
            const previousRowIndex = rowIndexOnCurrentPage > 0
                ? currentRowIndex - 1 // Use the previous row on the current page
                : (currentPage - 1) * pageSize + pageSize - 1; // Use the last row on the previous page
            const previousRowNode = params.api.getDisplayedRowAtIndex(previousRowIndex);
            const previousData = previousRowNode.data;
            // console.log('previousRowNode', previousRowNode);

            // If any of the specified columns have a different value, display all subsequent columns
            for (let column of columns) {
                if (previousData[column] !== params.data[column]) {
                    return customRenderer ? customRenderer(params) : params.value;
                }
            }

            // If the column value is the same as the previous row, return an empty string
            if (previousData[params.colDef.field] === params.data[params.colDef.field]) {
                return "";
            }
        }

        return customRenderer ? customRenderer(params) : params.value;
    };
}



