import React from 'react';
import api from "../../../../services/api";
import {
    Input, Label, FormGroup, Button, Modal, Container, Row, Col
 } from "reactstrap"; 
import Loader from "../../../loaders";
import { RadioGroup } from "../../../inputs";
import { TrimObject } from '../../../../helpers/StringHelper';

export default function Editor(props) {

    const [ itemInEdit, setItemInEdit ] = React.useState(props.item);
    const [ loading, setLoading ] = React.useState(false);

    const handleTextChanged = ({ target: { name, value } }) => {
        setItemInEdit({ ...itemInEdit, [name]: value });
    }

    const handleSave = (item) => {
        setLoading(true);

        // Trim every string property
        item = TrimObject(item);
        setItemInEdit({ ...item });

        // Sync label with value if label is empty
        if (!item.lookupLabel) {
            item.lookupLabel = item.lookupValue;
        }

        // Save
        const data = JSON.stringify(item);
        const save = item.lookupId > 0 
            ? api.put(`lookup/${item.lookupId}`, data)
            : api.post(`lookup`, data)

        save
        .then((response) => {
            props.onSaved();
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }
    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    {props.title ?? "Title"}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}>x</span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                <Container>
                    {(loading) && <Loader />}
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Name
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    name="lookupValue"
                                    id="lookupValue"
                                    type="text"
                                    required
                                    value={itemInEdit.lookupValue ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>  
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Description
                                </Label>
                                <Input
                                    name="lookupLabel"
                                    id="lookupLabel"
                                    type="textarea"
                                    value={itemInEdit.lookupLabel ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>Enabled</Label>
                                <RadioGroup 
                                    items={[
                                        {lookupId: false, lookupValue: "Yes"}, 
                                        {lookupId: true, lookupValue: "No"}
                                    ]} 
                                    name="hidden" 
                                    handleChange={({ target: { name, value } }) => setItemInEdit({ ...itemInEdit, [name]: value === "true" })} 
                                    selected={itemInEdit.hidden}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={props.onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave(itemInEdit)}
                    type="button"
                >
                    Save Changes
                </Button>
            </div>
        </Modal>
    )
}