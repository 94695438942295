import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import api from "../../../../services/api";
import PropTypes from "prop-types";
import Loader from '../../../loaders';
import { Button, Col, Container, Label, Modal, Row } from 'reactstrap';
import { AgGridReact } from 'ag-grid-react';
import { TooltipButton } from '../../../inputs';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import LRFDetailView from './LRFDetailView';
import moment from 'moment';
import LRFDetailMessagingDialog from './LRFDetailMessagingDialog';

export default function LRFDetailList(props) {
    const {
        recruitmentRequestId,
        isModalView,
        onClose,
    } = props;

    const params = useParams();

    const requestId = useMemo(() => recruitmentRequestId ?? params?.requestId ?? 0, [recruitmentRequestId, params?.requestId]);

    const [loading, setLoading] = useState(false);
    const [recruitmentRequest, setRecruitmentRequest] = useState(null);
    const [requestDetails, setRequestDetails] = useState([]);
    const [selectedDetail, setSelectedDetail] = useState(null);
    const [messageObj, setMessageObj] = useState(null);
    const gridRef = useRef();

    const loadData = useCallback(() => {
        if (!requestId)
            return;

        setLoading(true);

        api.get(`recruitmentrequest/${requestId}`)
            .then((response) => {
                const {data: currentRequest} = response;
                setRequestDetails(currentRequest?.requestDetails ? [...currentRequest.requestDetails] : []);

                if (currentRequest?.requestDetails)
                    delete currentRequest.requestDetails;

                setRecruitmentRequest({...currentRequest});
            })
            .catch(error => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [requestId]);

    useEffect(() => {
        if (!requestId)
            return;

        loadData();
    }, [requestId]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        cellClass: 'roster-cell',
        autoHeight: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200
        },
        menuTabs: ['filterMenuTab'],
        suppressPaste: true,
        suppressFillHandle: true,
        suppressMovable: true,
        suppressFillHandle: true,
        editable: false,
        suppressMenu: true,
    }), []);

    const columnDefs = useMemo(() => [
        {
            field: "action",
            headerName: "Action",
            maxWidth: 80,
            editable: false,
            pinned: true,
            sortable: false,
            filter: false,
            cellRenderer: (cellParams) => {
                const {data: detail} = cellParams;

                return (
                    <div className="text-center">
                        <TooltipButton
                            id={`show_detail_${detail.requestDetailId}`}
                            title="Show"
                            disabled={!detail.requestDetailId}
                            className="btn-icon p-0"
                            color="default"
                            size="xss"
                            type="button"
                            onClick={() => {
                                setSelectedDetail({...recruitmentRequest, detail});
                            }}
                        >
                            <i className="fas fa-xs fa-search-plus" />
                        </TooltipButton>
                        <TooltipButton
                            id={`send_detail_${detail.requestDetailId}`}
                            title="Send Message to Hiring Manager"
                            disabled={!detail.requestDetailId}
                            className="btn-icon p-0"
                            color="default"
                            size="xss"
                            type="button"
                            onClick={() => {
                                setMessageObj({...recruitmentRequest, ...detail});
                            }}
                        >
                            <i className="fas fa-xs fa-paper-plane" />
                        </TooltipButton>
                    </div>
                );
            },
        },
        {
            field: "hrispositionMaster.hrispositionMasterName",
            headerName: "Roster Position",
            sort: "asc",
        },
        {
            field: "position.lookupValue",
            headerName: "Recruitment Position",
        },
        {
            field: "startDateExpected",
            headerName: "Expected Start Date",
            valueFormatter: (params) => {
                const { value } = params;
                return value ? moment(value).format('DD.MM.YYYY') : "N/A";
            },
        },
        {
            field: "finishDatePlanned",
            headerName: "Planned End Date",
            valueFormatter: (params) => {
                const { value } = params;
                return value ? moment(value).format('DD.MM.YYYY') : "N/A";
            },
        },
        {
            field: "reportingTo",
            headerName: "Reporting To",
            valueFormatter: (params) => {
                const { data: detail } = params;
                return detail?.reportingTo?.displayName ?? "N/A";
            },
        },
    ], [recruitmentRequest]);

    const getRowId = useMemo(() => (params) => params?.data?.requestDetailId, []);

    const onGridReady = useCallback((param) => {
        console.log({param});

        if (!param?.api)
            return;

        param.api.sizeColumnsToFit({
            defaultMinWidth: 100,
        });
    }, []);

    const contents = useMemo(() => {
        return (
            <Container fluid>
                <Row>
                    <Col md={1} sm={12}>
                        <Label>Project</Label>
                    </Col>
                    <Col md={11} sm={12}>
                        <Label>: <b>{recruitmentRequest?.project?.lookupValue ?? "N/A"}</b></Label>
                    </Col>
                    <Col md={1} sm={12}>
                        <Label>Client</Label>
                    </Col>
                    <Col md={11} sm={12}>
                        <Label>: <b>{recruitmentRequest?.client?.lookupValue ?? "N/A"}</b></Label>
                    </Col>
                    <Col md={1} sm={12}>
                        <Label>Requestor</Label>
                    </Col>
                    <Col md={11} sm={12}>
                        <Label>: <b>{recruitmentRequest?.project ? `${recruitmentRequest.requestor.displayName} (${recruitmentRequest.requestor.email})` : "N/A"}</b></Label>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="ag-theme-alpine ag-grid" style={{ width: '100%', height: '65vh' }}>
                        <AgGridReact
                            ref={gridRef}
                            rowData={requestDetails}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            suppressColumnVirtualisation={false}
                            suppressRowVirtualisation={false}
                            rowBuffer={20}
                            debounceVerticalScrollbar={false}
                            allowContextMenuWithControlKey={true}
                            suppressContextMenu={true}
                            suppressClipboardPaste={true}
                            headerHeight={25}
                            rowHeight={28}
                            readOnlyEdit={true}
                            getRowId={getRowId}
                            suppressDragLeaveHidesColumns={true}
                            suppressColumnMoveAnimation={true}
                            multiSortKey='ctrl'
                            enableCellChangeFlash={false}
                            animateRows={true}
                            rowSelection={"single"}
                            onGridReady={onGridReady}
                        />
                        </div>
                    </Col>
                </Row>
            {
                selectedDetail ?
                <LRFDetailView
                    item={selectedDetail}
                    onClose={() => setSelectedDetail(null)}
                />
                :
                null
            }
            {
                messageObj ?
                <LRFDetailMessagingDialog
                    requestDetail={messageObj}
                    onClose={() => setMessageObj(null)}
                    onSave={() => setMessageObj(null)}
                />
                :
                null
            }
            </Container>
        );
    }, [recruitmentRequest, requestDetails, defaultColDef, columnDefs, getRowId, onGridReady, selectedDetail, messageObj]);

    if (isModalView)
        return (
            <Modal
                isOpen={true}
                fullscreen="true"
                className="modal-fullscreen modal-fullscreen-xl"
            >
            {
                loading && <Loader />
            }
                <div
                    className="modal-header"
                >
                    <h5
                        className="modal-title"
                    >
                        LRF Details View{recruitmentRequest ? ` - ${recruitmentRequest.recruitmentRequestNumber}` : ""}
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        onClick={onClose}
                        type="button"
                    >
                        <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                    </button>
                </div>
                <div
                    className="modal-body pb-1"
                >
                {
                    contents
                }
                </div>
                <div
                    className="modal-footer"
                >
                    <Button
                        color="secondary"
                        onClick={onClose}
                        type="button"
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        );
    
    return (
        <section className="main">
        {
            loading && <Loader />
        }
            <Container fluid className="d-flex flex-wrap justify-content-between align-items-center pb-4">
                <span><h5><strong>LRF Details View{recruitmentRequest ? ` - ${recruitmentRequest.recruitmentRequestNumber}` : ""}</strong></h5></span>
            </Container>
        {
            contents
        }
        </section>
    );
};

LRFDetailList.propTypes = {
    recruitmentRequestId: PropTypes.number,
    isModalView: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
}

LRFDetailList.defaultProps = {
    isModalView: false,
}