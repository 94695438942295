import React, { useEffect, useRef, useState, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import api from "../../../../services/api";
import { DropDownList } from "@progress/kendo-react-dropdowns";

const textField = "lookupValue";
const keyField = "lookupId";

const ShiftEditor = forwardRef((props, ref) => {
    console.log('ShiftEditor', props)
    const [value, setValue] = useState(props.value);
    const [data, setData] = useState(props.shifts);
    const refInput = useRef(null);

    useEffect(() => {
        refInput.current.focus();
    }, []);

    useImperativeHandle(ref, () => {
        return {
            // the final value to send to the grid, on completion of editing
            getValue() {
                return value;
            }, 
            // Gets called once before editing starts, to give editor a chance to
            // cancel the editing before it even starts.
            isCancelBeforeStart() {
                return false;
            }, 
            // Gets called once when editing is finished (eg if Enter is pressed).
            // If you return true, then the result of the edit will be ignored.
            isCancelAfterEnd() {
                return false;
            }
        };
    }, [value, refInput]);

    const onChange = React.useCallback((event) => {
        setValue(event.value);
    }, []);

    return (
        <DropDownList
            data={data}
            value={value}
            onChange={onChange}
            dataItemKey={keyField}
            textField={textField}
            ref={refInput}
            
        />
      );
});

export default ShiftEditor;
