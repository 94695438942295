import React from 'react';
import {
    Modal, Container, Row, Col, Button
 } from "reactstrap"; 
 import { useTable, useFlexLayout } from 'react-table';
import api from "../../../services/api";
import Loader from "../../loaders";
import Grid from "../../Grid";
import moment from "moment";
import qs from "qs";

export default function ConflictDialog(props) {
    const {
        data,
        rosterCandidate
    } = props;
    const [ loading, setLoading ] = React.useState(false);
    const [ conflicts, setConflicts ] = React.useState([]);

    React.useEffect(() => {
        setLoading(true);

        console.log('load', { data, rosterCandidate });
        const url = `/rostercandidate/conflict-schedule?candidateId=${data.candidateId}&startDate=${data.startDate}&endDate=${data.endDate}`;
        
        api.get(url)
        .then((response) => {
            // console.log(response.data);

            if (rosterCandidate && !response.data.filter(d => d.rosterCandidateId === rosterCandidate.rosterCandidateId).length)
                response.data.push({...rosterCandidate, startDate: data.startDate, endDate: data.endDate, status: {...rosterCandidate.statusNavigation}});
            
            setConflicts(response.data);
        }).catch((error) => {
            console.error("error: ", {error});
        }).finally(() => setLoading(false));

    }, []);

    const columns = React.useMemo(() => {
        return [
            {
                Header: "Project",
                id: "project",
                // accessor: (row) => row.project?.projectName,
                Cell: ( { row: { original } } ) => 
                {
                    return (
                        <div>
                            <span>
                            {
                                rosterCandidate && original.rosterCandidateId === rosterCandidate.rosterCandidateId ?
                                <strong>{original.project.projectName}</strong>
                                :
                                original.project.projectName
                            }
                            </span>
                        </div>
                    )
                },
                width: 300,
            },
            {
                Header: "Dispatch Unit",
                id: "dispatchUnit",
                // accessor: (row) => row.dispatchUnit?.dispatchUnitName,
                Cell: ( { row: { original } } ) => 
                {
                    return (
                        <div>
                            <span>
                            {
                                rosterCandidate && original.rosterCandidateId === rosterCandidate.rosterCandidateId ?
                                <strong>{original.dispatchUnit?.dispatchUnitName}</strong>
                                :
                                original.dispatchUnit?.dispatchUnitName
                            }
                            </span>
                        </div>
                    )
                },
                width: 250,
            },
            {
                Header: "Roster",
                id: "roster",
                // accessor: (row) => row.roster?.rosterName,
                Cell: ( { row: { original } } ) => 
                {
                    return (
                        <div>
                            <span>
                            {
                                rosterCandidate && original.rosterCandidateId === rosterCandidate.rosterCandidateId ?
                                <strong>{original.roster?.rosterName}</strong>
                                :
                                original.roster?.rosterName
                            }
                            </span>
                        </div>
                    )
                },
                width: 100,
            },
            {
                Header: "Status",
                id: "status",
                // accessor: (row) => row.status?.lookupValue,
                Cell: ( { row: { original } } ) => 
                {
                    return (
                        <div>
                            <span>
                            {
                                rosterCandidate && original.rosterCandidateId === rosterCandidate.rosterCandidateId ?
                                <strong>{original.status?.lookupValue}</strong>
                                :
                                original.status?.lookupValue
                            }
                            </span>
                        </div>
                    )
                },
                width: 150,
            },
            {
                Header: "Start",
                id: "start",
                // accessor: (row) => row.startDate ? moment(row.startDate).format("DD.MM.YYYY") : "N/A",
                Cell: ( { row: { original } } ) => 
                {
                    return (
                        <div>
                            <span>
                            {
                                rosterCandidate && original.rosterCandidateId === rosterCandidate.rosterCandidateId ?
                                <strong>{original.startDate ? moment(original.startDate).format("DD.MM.YYYY") : "N/A"}</strong>
                                :
                                (original.startDate ? moment(original.startDate).format("DD.MM.YYYY") : "N/A")
                            }
                            </span>
                        </div>
                    )
                },
                width: 100,
            },
            {
                Header: "End",
                id: "end",
                // accessor: (row) => row.endDate ? moment(row.endDate).format("DD.MM.YYYY") : "N/A",
                Cell: ( { row: { original } } ) => 
                {
                    return (
                        <div>
                            <span>
                            {
                                rosterCandidate && original.rosterCandidateId === rosterCandidate.rosterCandidateId ?
                                <strong>{original.endDate ? moment(original.endDate).format("DD.MM.YYYY") : "N/A"}</strong>
                                :
                                (original.endDate ? moment(original.endDate).format("DD.MM.YYYY") : "N/A")
                            }
                            </span>
                        </div>
                    )
                },
                width: 100,
            },
            {
                Header: "Action",
                id: 'button',
                Cell: ( { row: { original } } ) => 
                {
                    if (!original.rosterCandidateId || original.rosterCandidateId < 0)
                        return null;
                    
                    // console.log(original)
                    return (
                        <div>
                            <Button
                               id={`goto_${original.rosterCandidateId}`}
                               title="Go To"
                               className="btn-icon"
                               color="default"
                               size="sm"
                               type="button"
                               onClick={(event) => {
                                   window.open(`/roster?projectid=${original.project?.projectId}&dispatchunitid=${original.dispatchUnit?.dispatchUnitId}&rosterid=${original.roster?.rosterId}`, "_blank");
                                }}
                            >
                                <i className="fas fa-external-link-alt pt-1"></i>
                            </Button>
                        </div>
                    )
                },
                width: 50,
            }
        ]
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state
    } = useTable(
        {
            columns,
            data: conflicts,
        },
        useFlexLayout,
    );

    return (
        <Modal
            isOpen={true}
            className="modal-90w mx-4"
            modalClassName="db-example-modal-lg"
        >
            {loading && <Loader />}
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Roster Conflicts{` - ${conflicts[0]?.candidate?.firstName} ${conflicts[0]?.candidate?.surname}`}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.close}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                <Container fluid>
                    <Row>
                        <Col>
                            <Grid
                                height={'calc(100vh - 464px)'}
                                rows={rows}
                                tableProps={getTableProps()}
                                headerGroups={headerGroups}
                                tableBodyProps={getTableBodyProps()}
                                prepareRow={prepareRow}
                                onRowDoubleClick={(row) => {}}
                                needPaging={false}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>

        </Modal>
    );
}