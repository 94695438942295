import React from 'react';
import api from "../../../../services/api";
import {
    Input, Label, FormGroup, Button, Modal, Container, Row, Col
 } from "reactstrap"; 
import { DropdownBox } from "../../../dropdowns";
import { DatePicker } from "../../../date-pickers";
import Loader from "../../../loaders";
import { formatDate, formatDateZeroTime } from "../../../../utils";
import moment from "moment";
import { RadioGroup, CurrencyInput } from "../../../inputs";
import InputMask from "react-input-mask";
import { TrimObject } from '../../../../helpers/StringHelper';
//import CurrencyInput from "../"

export default function Editor(props) {

    const [ itemInEdit, setItemInEdit ] = React.useState(props.item);
    const [ loading, setLoading ] = React.useState(false);
    const [ trainingSubCategories, setTrainingSubCategories ] = React.useState([]);
    const [ trainingMasterOwners, setTrainingMasterOwners ] = React.useState([]);

    const handleTextChanged = ({ target: { name, value } }) => {
        setItemInEdit({ ...itemInEdit, [name]: value });
    }
    const handleMoneyChanged = ({ target: { name, value } }) => {

        // Validate name
        if (!name) {
            console.error('handleMoneyChanged', 'error', 'name not found');
            return;
        }

        var nameRaw = name.replaceAll('_Fmt', '');
        var nameStr = `${nameRaw}_Fmt`;
            
        try {
            if (value == null) {
                setItemInEdit({ ...itemInEdit, [nameRaw]: null, [nameStr]: null });
                return;
            }

            // Get clean float value
            var cleanValue = value.replaceAll(',', '');
            cleanValue = cleanValue.replaceAll('$', '');
            var floatValue = parseFloat(cleanValue);

            // Set Value
            setItemInEdit({ ...itemInEdit, [nameRaw]: Number.isNaN(floatValue) ? null : floatValue, [nameStr]: value});
        }
        catch (error)
        {
            console.error('handleMoneyChanged', 'error', error.message);
            setItemInEdit({ ...itemInEdit, [nameRaw]: null, [nameStr]: null });
        }
    }
    const handleDateChanged = (value, name) => {
        setItemInEdit({ 
            ...itemInEdit, 
            [name]: !value ? null : formatDateZeroTime(value) //moment(value).format("YYYY-MM-DDT00:00:00") 
        });
    }
    const handleSave = (item) => {
        setLoading(true);

        // Trim every string property
        item = TrimObject(item);
        setItemInEdit(item);

        // Save
        const data = JSON.stringify(item);
        const save = item.trainingMasterSkillId > 0 
            ? api.put(`trainingmaster/skill/${item.trainingMasterSkillId}`, data)
            : api.post(`trainingmaster/skill`, data)

        save
        .then((response) => {
            console.log("Training Master Skill Save: ", response);
            props.onSaved();
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }
    
    React.useEffect(() => {
        setLoading(true);
        api.get(`/lookup/trainingmasterowners`)
            .then((response) => {
                setTrainingMasterOwners(response.data);
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false))
        ;
    }, []);

    React.useEffect(() => { 
        setTrainingSubCategories([]);
        if (itemInEdit.trainingCategoryId) {
            api.get(`/lookup/trainingsubcategory/${itemInEdit.trainingCategoryId}`)
            .then(({data}) => {
                setTrainingSubCategories(data);
            })
            .catch((error) => console.error(error));
        } else {            
            setItemInEdit( { ...itemInEdit, trainingSubCategory: {lookupId: 0, lookupValue: ""}, trainingSubCategoryId: null } );
        }
    }, [itemInEdit.trainingCategoryId]);

    console.log("editor: ", props.lookups)
    const {
        trainingProviders,
        trainingCategories,
        deliveryMethods
    } = props.lookups
    
    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Training Details
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}>x</span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                <Container>
                    {(loading) && <Loader />}
                    <Row> 
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Skill name
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    name="skillName"
                                    id="skillName"
                                    type="text"
                                    required
                                    value={itemInEdit.skillName}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>  
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Skill Description
                                </Label>
                                <Input
                                    name="skillDescription"
                                    id="skillDescription"
                                    type="text"
                                    value={itemInEdit.skillDescription ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Owner
                                </Label>
                                <DropdownBox
                                    data={trainingMasterOwners}
                                    selectedItem={itemInEdit.trainingMasterOwner ?? {}}
                                    onChange={(item) => {
                                        setItemInEdit( { ...itemInEdit, trainingMasterOwner: item, trainingMasterOwnerId: item ? item.lookupId : null } );
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        {/* <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Category
                                </Label>
                                <DropdownBox
                                    data={trainingCategories}
                                    selectedItem={itemInEdit.trainingCategory || {}}
                                    onChange={(type) => {
                                        setItemInEdit( { ...itemInEdit, trainingCategory: type, trainingCategoryId: type ? type.lookupId : null } );
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Sub Category
                                </Label>
                                <DropdownBox 
                                    data={trainingSubCategories}
                                    selectedItem={itemInEdit.trainingSubCategory || {}}
                                    onChange={(type) => {
                                        setItemInEdit( { ...itemInEdit, trainingSubCategory: type, trainingSubCategoryId: type ? type.lookupId : null } );
                                    }}
                                />
                            </FormGroup>
                        </Col> */}
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Course Code
                                </Label>
                                <Input
                                    name="courseCode"
                                    id="courseCode"
                                    type="text"
                                    value={itemInEdit.courseCode}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Course Name
                                </Label>
                                <Input
                                    name="courseName"
                                    id="courseName"
                                    type="text"
                                    value={itemInEdit.courseName}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Default Expiry (years)
                                </Label>
                                <Input
                                    name="defaultExpiryYears"
                                    id="defaultExpiryYears"
                                    type="number"
                                    value={itemInEdit.defaultExpiryYears}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Internal Cost
                                </Label>
                                {/*
                                <InputMask
                                    name="internalCost"
                                    id="internalCost"
                                    mask={"#.##0,00"}
                                    value={(itemInEdit == null || itemInEdit.internalCost == null) ? "" : itemInEdit.internalCost}
                                    onChange={handleTextChanged}
                                    className="form-control w-100"
                                    alwaysShowMask={false}
                                    formatChars={{ '0': '[0-9X]' }}
                                    onFocus={(event) => event.currentTarget.select()}
                                    onChange={handleMoneyChanged}
                                    value={(itemInEdit == null || itemInEdit.internalCost == null) ? "" : ('internalCost_Fmt' in itemInEdit) ? itemInEdit.internalCost_Fmt : itemInEdit.internalCost}
                                />
                                */} 
                                <CurrencyInput
                                    name="internalCost"
                                    id="internalCost"
                                    autoComplete="off"
                                    placeholder="$0.00"
                                    type="text"
                                    className="form-control w-100"
                                    onChange={handleMoneyChanged}
                                    value={(itemInEdit == null || itemInEdit.internalCost == null) ? "" : ('internalCost_Fmt' in itemInEdit) ? `${itemInEdit.internalCost_Fmt}` : `${itemInEdit.internalCost}`}
                                    
                                />
                            </FormGroup>
                        </Col>
                        {/* <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Start Date
                                </Label>
                                <DatePicker
                                    name="startDate"
                                    id="startDate"
                                    type="text"
                                    value={formatDate(itemInEdit.startDate)}
                                    onChange={handleDateChanged}
                                    closeOnSelect                                
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    End Date
                                </Label>
                                <DatePicker
                                    name="endDate"
                                    id="endDate"
                                    type="text"
                                    value={formatDate(itemInEdit.endDate)}
                                    onChange={handleDateChanged}
                                    closeOnSelect                                
                                />
                            </FormGroup>
                        </Col> */}
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Training Provider
                                </Label>
                                <DropdownBox 
                                    data={trainingProviders}
                                    selectedItem={itemInEdit.defaultTrainingProvider}
                                    onChange={(type) => {
                                        setItemInEdit( { ...itemInEdit, defaultTrainingProvider: type, defaultTrainingProviderId: type ? type.lookupId : null } );
                                    }}
                                />
                            </FormGroup>
                        </Col>                            
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Delivery Method
                                </Label>
                                <DropdownBox
                                    data={deliveryMethods}
                                    selectedItem={itemInEdit.deliveryMethod || {}}
                                    onChange={(type) => {
                                        setItemInEdit( { ...itemInEdit, deliveryMethod: type, deliveryMethodId: type ? type.lookupId : null } );
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>Active</Label>
                                <RadioGroup 
                                    items={[
                                        {lookupId: true, lookupValue: "Yes"}, 
                                        {lookupId: false, lookupValue: "No"}
                                    ]} 
                                    name="active" 
                                    handleChange={({ target: { name, value } }) => setItemInEdit({ ...itemInEdit, [name]: value === "true" })} 
                                    selected={itemInEdit.active}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={props.onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave(itemInEdit)}
                    type="button"
                >
                    Save Changes
                </Button>
            </div>
        </Modal>
    )
}