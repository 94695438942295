import React from 'react';
import { useHistory } from "react-router-dom";
import {
    Container, Row, Col
} from "reactstrap";
import { requiresRoles } from "./Can";
import { useMsal } from "@azure/msal-react";
import { roles } from "../auth/roles";
import api from "../services/api";

import { ReactComponent as FormSvg } from "../assets/img/nucleo/form.svg";
import { ReactComponent as ProgressSvg } from "../assets/img/nucleo/time machine.svg";
import { ReactComponent as PendingSvg } from "../assets/img/nucleo/archive doc check.svg";
import { ReactComponent as ProjectSvg } from "../assets/img/nucleo/settings.svg";
import { ReactComponent as CogSvg } from "../assets/img/nucleo/settings-gear.svg";
import { ReactComponent as CandidatesSvg } from "../assets/img/nucleo/candidates.svg";
//import { ReactComponent as RosterSvg } from "../assets/img/nucleo/roster.svg";
import { ReactComponent as RosterSvg } from "../assets/img/nucleo/calendar-date-2.svg";
import { ReactComponent as ForecastSvg } from "../assets/img/nucleo/statistics.svg";
import { ReactComponent as CommunicationSvg } from "../assets/img/nucleo/mail.svg";
import { ReactComponent as EnviromentalismSvg } from "../assets/img/icons/svg/environmentalism.svg";
import { ReactComponent as TrainingSvg } from "../assets/img/icons/svg/training.svg";
import Loader from "./loaders";
import { CoinCard } from "./cards";
import { ForecastNotification } from "./alerts";
import { ViewAccess } from '../utils';

export function Home(props) {
    const [dropdownOpen, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [alert, setAlert] = React.useState([]);
    //const toggle = () => setOpen(!dropdownOpen);
    let history = useHistory();

    const [approvalViewsUrl, setApprovalViewsUrl] = React.useState('');

    const [viewAccess, setViewAccess] = React.useState(ViewAccess.FULLACCESS);
    const { instance } = useMsal();
    const user = instance.getActiveAccount();

    React.useEffect(() => {
        requiresRoles([roles.Spc], user, () => {
            setViewAccess(ViewAccess.READONLY);
        });

        api.get(`/config/approvalsurl`)
            .then(({ data }) => {
                setApprovalViewsUrl(data);
            }).catch((error) => {
                console.error("error: ", { error });
            });
    }, []);

    const rosteringChildrens = React.useMemo(() => {
        if (viewAccess == ViewAccess.FULLACCESS) {

            return [
                { title: "Create new Roster without Forecast", onClick: () => history.push("/roster-create-new-without-forecast") },
                { title: "Accommodations", onClick: () => history.push("/accommodation") },
                // { title: "Crews", onClick: () => history.push("/crew") },
                { title: "Manage Rosters", onClick: () => history.push("/roster") },
                { title: "All Roster Grid", onClick: () => history.push("/all-roster-grid") },
                { title: "Empty Slots", onClick: () => history.push("/rosteremptyslots") },
                { title: "Roster Accommodation Report", onClick: () => history.push("/rosteraccommodationreport") },
                //{ title: "Employee Location Report", onClick: () => window.open("/reports/rostercandidateallocation", "_blank") },
            ];
        } else {
            return null;

        }
    }, [viewAccess]);

    const forecastingChildrens = React.useMemo(() => {
        if (viewAccess == ViewAccess.FULLACCESS) {

            return [
                {
                    title: "Sync",
                    onClick: () => {
                        setLoading(true);
                        api.get(`forecast/sync`)
                            .then((jsonData) => {
                                console.log("SYNC", jsonData)
                                setLoading(false);
                                if (jsonData) {
                                    setAlert([<ForecastNotification key={0} onConfirm={() => setAlert([])} />]);
                                }
                            });
                    }
                },
            ];
        } else {
            return null;
        }
    }, [viewAccess]);

    return (
        <section style={{ minHeight: "100vh" }}>
            <Container fluid className='px-5'>
                <Row className='px-5'>
                    {requiresRoles([roles.Manager, roles.Spc], user,
                        <Col sm={12} md={6} lg={3}>
                            {(loading) && <Loader />}
                            {alert.length > 0 && alert}
                            <CoinCard
                                icon={<ForecastSvg />}
                                title="Forecasting"
                                bodyText={["The forecasting module is used to plot the resources required for all future work."]}
                                action={{
                                    title: "Start",
                                    onClick: () => { history.push('/forecasting') },
                                    children: forecastingChildrens
                                }}
                            />
                        </Col>
                    )}
                    {requiresRoles([roles.Manager, roles.Spc], user,
                        <Col sm={12} md={6} lg={3}>
                            <CoinCard
                                icon={<RosterSvg />}
                                title="Rostering"
                                bodyText={["The rostering module is used to create rosters, allocate resources, and manage ongoing engagement activities.",]}
                                action={{
                                    title: "Start",
                                    onClick: () => { history.push('/roster') },
                                    children: rosteringChildrens
                                }}
                            />
                        </Col>
                    )}
                    {requiresRoles([roles.Manager], user,
                        <Col sm={12} md={6} lg={3}>
                            <CoinCard
                                icon={<CommunicationSvg />}
                                title="Communication"
                                bodyText={["The communication module is used to manage communication with talents.",]}
                                action={{
                                    title: "View",
                                    onClick: () => { history.push('/communication') },
                                    // children: [
                                    //   { title: "Accommodations", onClick: () => history.push("/accommodation") },
                                    //   // { title: "Crews", onClick: () => history.push("/crew") },
                                    //   { title: "Manage Rosters", onClick: () => history.push("/roster") },
                                    //   { title: "Employee Location Report", onClick: () => window.open("/reports/rostercandidateallocation", "_blank") },
                                    // ]
                                }}
                            />
                        </Col>
                    )}
                    {requiresRoles([roles.SuperAdmin, roles.Candidate, roles.Recruiter, roles.Manager, roles.Admin], user,
                        <Col sm={12} md={6} lg={3}>
                            <CoinCard
                                icon={<ProjectSvg />}
                                title="Project Pre-Requisites"
                                bodyText={[
                                    "This section allows Contract Owners to complete all the labour request pre-requisites. This includes TNA submission, rate sheet submission and the T&C's",
                                ]}
                                action={{
                                    title: "Actions",
                                    onClick: () => { },
                                    children: [
                                        // { title: "Contract Terms", onClick: () => {history.push("/contractterm")} },
                                        // { title: "Project Contract Terms", onClick: () => {history.push('/projectterm') } },
                                        { title: "Project Contract Template", onClick: () => { history.push("/pct") } },
                                        { title: "Entity Contract Template", onClick: () => { history.push("/ect") } },
                                        { title: "Active TNA", onClick: () => { history.push("/activetna") } },
                                        { title: "Submit TNA", onClick: () => { history.push("/tna") } },
                                        { title: "Rate Sheet and T&C", onClick: () => { history.push('/rates') } },
                                        // { title: "Submit Supporting Files/T&C", onClick: () => {history.push('/terms')} },
                                    ]
                                }}
                            />
                        </Col>
                    )}
                    {requiresRoles([roles.Manager], user,
                        <Col sm={12} md={6} lg={3}>
                            <CoinCard
                                icon={<FormSvg />}
                                title="Labour Request Form"
                                bodyText={[
                                    "The LRF is the starting point of our recruitment process.",
                                    "Hiring Managers are required to provide complete information on every LRF raised.",
                                ]}
                                action={{
                                    title: "New LRF",
                                    onClick: () => { history.push("/lrf") },
                                    children: [
                                        { title: "View All", onClick: () => history.push("/lrflist") },
                                        { title: "Candidate Review Requests", onClick: () => history.push("/lrfreview") },
                                        { title: "Approval Request List", onClick: () => history.push("/lrfapproval") },
                                    ]
                                }}
                            />
                        </Col>
                    )}
                    {requiresRoles([roles.Manager], user,
                        <Col sm={12} md={6} lg={3}>
                            <CoinCard
                                icon={<ProgressSvg />}
                                title="Job Orders"
                                bodyText={["A central place to check job order fulfillment progress, including external applicants."]}
                                action={{
                                    title: "View",
                                    onClick: () => history.push("/joborder"),
                                    children: [
                                        { title: "Create new", onClick: () => history.push("/joborder/new") },
                                        { title: "Milestone Task List", onClick: () => history.push("/milestone") },
                                    ]
                                }}
                            />
                        </Col>
                    )}
                    {requiresRoles([roles.Manager], user,
                        <Col sm={12} md={6} lg={3}>
                            <CoinCard
                                icon={<PendingSvg />}
                                title="My Pending Approvals"
                                bodyText={["The approval module integrates with our Microsoft Flow automation system which allows approval of any digital request in the company."]}
                                action={{
                                    title: "Start",
                                    onClick: () => window.open(approvalViewsUrl, "_blank")
                                }}
                            />
                        </Col>
                    )}
                    {requiresRoles([roles.Manager], user,
                        <Col sm={12} md={6} lg={3}>
                            <CoinCard
                                icon={<CandidatesSvg />}
                                title="Talents"
                                bodyText={["A centralized module for any talent interaction."]}
                                action={{
                                    title: "Start",
                                    onClick: () => { history.push('/talents') },
                                    children: [
                                        { title: "Portal Users", onClick: () => history.push("/portalusers") },
                                        { title: "Merge Duplicate Data", onClick: () => history.push("/talents/merge/wizard") },
                                    ]
                                }}
                            />
                        </Col>
                    )}
                    <Col sm={12} md={6} lg={3}>
                        <CoinCard
                            icon={<TrainingSvg />}
                            title="Training"
                            bodyText={["Placeholder for HRIS' Training Integration Module."]}
                            action={{
                                title: "Coming Soon",
                                onClick: () => { }
                            }}
                        />
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                        <CoinCard
                            icon={<EnviromentalismSvg />}
                            title="HSE"
                            bodyText={["Health Safety Enviroment."]}
                            action={{
                                title: "Coming Soon",
                                onClick: () => { }
                            }}
                        />
                    </Col>


                    {/* {requiresRoles([roles.Admin], user ,
            <Col sm={12} md={6} lg={3}>
              <CoinCard 
                icon={<CogSvg />}
                title="Maintenance"
                bodyText={[
                  "Administrators will maintain this section. This includes",
                  "Training Master List and other lookup data not sourced from other system",
                ]}
                action={{
                  title: "Start",
                  onClick: () => { history.push('/maintenance') },
                }}
              />
            </Col>
            )} */}
                </Row>
            </Container>
        </section>
    );
}
