import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Container, Label, Modal, Row, Spinner } from "reactstrap";
import Loader from "../../../loaders";
import { AgGridReact } from "ag-grid-react";
import TalentEditor from '../../Candidates/Editor';
import api from "../../../../services/api";
import { DateValueFormatter, FiltersConverter, SortsConverter } from "../../../../helpers";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import SweetAlert from "react-bootstrap-sweetalert";
import { TooltipButton } from "../../../inputs";
import { Checkbox } from "@progress/kendo-react-inputs";
import moment from "moment";
import qs from "qs";

const ALTRAD_POOL = "Altrad Pool";
const PROJECT_POOL = "Project Pool";
const NONPROJECT_POOL = "Non-project Pool";
const pageSize = 100;

export default function TalentSearchDialog(props) {
    const {jobOrderId, onClose} = props;

    const [loading, setLoading] = useState(false);
    const [lookups, setLookups] = useState(null);
    const [loadingSlots, setLoadingSlots] = useState(false);
    const [slots, setSlots] = useState([]);
    const [jobOrderCandidateIds, setJobOrderCandidateIds] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [loadingTalents, setLoadingTalents] = useState(false);
    const [talentColumnDefs, setTalentColumnDefs] = useState([]);
    const [talentScheduleParameters, setTalentScheduleParameters] = useState(null);
    const [candidateInEdit, setCandidateInEdit] = useState(null);
    const [selectedPoolType, setSelectedPoolType] = useState(ALTRAD_POOL);
    const [positions, setPositions] = useState([]);
    const [positionFilter, setPositionFilter] = useState(null);
    const [selectedPositions, setSelectedPositions] = useState([]);
    const [checkExclusionList, setCheckExclusionList] = useState(false);
    const [checkInclusionList, setCheckInclusionList] = useState(false);
    const [checkScheduleConflict, setCheckScheduleConflict] = useState(true);
    const [talentAssignmentConfirmation, setTalentAssignmentConfirmation] = useState(null);
    const [ignoreMobe, setIgnoreMobe] = useState(false);
    const slotGridRef = useRef();
    const talentGridRef = useRef();
    const loadingOverlayComponent = useMemo(() => Loader, []);
    
    useEffect(() => {
        setLoading(true);

        const apiCalls = [
            api.get(`/lookup/skillposition`),
            api.get(`/lookup/candidatetype`),
            api.get(`/lookup/salutation`),
            api.get(`/lookup/preference`),
            api.get(`/lookup/industry`),
            api.get(`/lookup/gender`),
            api.get(`/lookup/maritalStatus`),
            api.get(`/lookup/candidateStatus`),
            api.get(`/lookup/candidateFlag`),
            api.get(`/lookup/residencyStatus`),
            api.get(`/lookup/visatype`),
            api.get(`/lookup/communicationtype`),
            api.get(`/lookup/country`),
            api.get(`/lookup/state`),
            api.get(`/lookup/relationshiptype`),
            api.get(`/lookup/candidateplatformorigin`),
            api.get(`/lookup/messagesmasters`),
            api.get(`/emailtemplate`), // TODO: returns paged result - move to lookup
            api.get(`/lookup/recruitmentpeople`),
            api.get(`/lookup/candidatediscipline`),
            api.get(`/lookup/company`),
            api.get(`/lookup/identifier`),
            api.get(`/lookup/candidateevent`),
            api.get(`/lookup/rosterflighttype`),
            api.get(`/lookup/flightstatus`),
            api.get(`/lookup/accommodationstatus`),
            api.get(`/lookup/RosterApprovalStatus`),
            api.get(`/lookup/WorkSequence`),
            api.get(`/lookup/agency`),
            api.get(`/contractingcompany/all`),
            api.get(`/lookup/candidatecommenttype`),
            api.get(`/lookup/candidateeventstatus`),
        ];

        Promise.all(apiCalls)
            .then((data) => {
                setLookups({
                    skillPositions: data[0].data,
                    candidateTypes: data[1].data,
                    salutations: data[2].data,
                    preferences: data[3].data,
                    industries: data[4].data,
                    genders: data[5].data,
                    maritalStatuses: data[6].data,
                    candidateStatuses: data[7].data,
                    candidateFlags: data[8].data,
                    residencyStatuses: data[9].data,
                    visaTypes: data[10].data,
                    communicationTypes: data[11].data,
                    countries: data[12].data,
                    states: data[13].data,
                    relationshipTypes: data[14].data,
                    candidatePlatformOrigin: data[15].data,
                    messagesMaster: data[16].data,
                    emailTemplate: data[17].data.data,
                    recruitmentPeople: data[18].data,
                    candidateDisciplines: data[19].data,
                    companies: data[20].data,
                    identifiers: data[21].data,
                    events: data[22].data,
                    rosterFlightTypes: data[23].data,
                    rosterFlightStatuses: data[24].data,
                    rosterAccommodationStatuses: data[25].data,
                    rosterApprovalStatus: data[26].data,
                    workSequence: data[27].data,
                    agencies: data[28].data,
                    contractingCompanies: data[29].data,
                    candidateCommentTypes: data[30].data,
                    eventStatus: data[31].data,
                });
            }).catch((error) => {
                console.error(error.response);
            }).finally(() => {
                setLoading(false);
            });
    }, []);

    const defaultColDef = useMemo(() => ({
        sortable: false,
        cellClass: 'roster-cell',
        autoHeight: false,
        resizable: true,
        filter: false,
        suppressPaste: true,
        suppressFillHandle: true,
        suppressMovable: true,
        suppressFillHandle: true,
    }), []);

    const slotColumnDefs = useMemo(() => [
        {
            field: "jobOrderCandidateId",
            headerName: "ID",
            headerCheckboxSelection: false,
            checkboxSelection: true,
            showDisabledCheckboxes: true,
            width: 80,
        },
        {
            headerName: "Roster Slot No.",
            field: "rosterCandidateNumber",
            cellRenderer: (params) => {
                const { data: rowData } = params;
                return rowData.rosterCandidate ? (rowData.rosterCandidate.relatedRosterCandidateId ? `${rowData.rosterCandidate.relatedRosterCandidateNumber}.${rowData.rosterCandidate.rosterCandidateNumber}` : `${rowData.rosterCandidate.rosterCandidateNumber}`) : null;
            },
            width: 100,
        },
        {
            headerName: "Employed Position",
            field: "position.positionName",
            flex: 1,
        },
        {
            headerName: "Talent Name",
            field: "candidateName",
            flex: 1,
        },
        {
            headerName: "Start Date",
            field: "startDate",
            filter: 'agDateColumnFilter',
            valueFormatter: DateValueFormatter,
            flex: 1,
        },
        {
            headerName: "End Date",
            field: "endDate",
            filter: 'agDateColumnFilter',
            valueFormatter: DateValueFormatter,
            flex: 1,
        },
    ], []);

    const loadSlotData = useCallback(() => {
        if (!jobOrderId)
        {
            setSlots([]);
            return;
        }

        setLoadingSlots(true);
        // console.log({dispatchUnitId, rosterCandidateIds, emptySlotGridRef, selectedPoolType, checkExclusionList, checkInclusionList, checkScheduleConflict, ignoreMobe});
        api.get(`/joborder/${jobOrderId}?showWithdrawn=false&showCancelled=false&onlyEmptySlots=true&showEmptyPositions=false`)
            .then((response) => {
                const {data: {jobOrderCandidates}} = response;
                setSlots([...jobOrderCandidates]);
                setJobOrderCandidateIds([...jobOrderCandidates.map(joc => joc.jobOrderCandidateId)]);
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoadingSlots(false))
        ;
    }, [jobOrderId]);

    const loadPositionData = useCallback(() => {
        api.get(`/hrispositionmaster/searchbyjoborder/${jobOrderId}?showWithdrawn=false&showCancelled=false&onlyEmptySlots=true${positionFilter ? `&filter=${positionFilter}` : ""}`)
            .then((response) => setPositions([...response.data]))
            .catch((error) => console.error({error}))
        ;
    }, [jobOrderId, positionFilter]);

    useEffect(() => {
        loadPositionData();
    }, [positionFilter]);

    const getSlotRowId = useMemo(() => (params) => params?.data?.jobOrderCandidateId, []);

    const assignTalent = useCallback((data) => {
        const newSlots = JSON.parse(JSON.stringify(slots));
        const updatedSlot = newSlots.filter(d => d.jobOrderCandidateId === selectedSlot?.jobOrderCandidateId)[0];

        if (!updatedSlot)
            return;

        const position = data.positions.filter(p => p.positionName === updatedSlot.position.positionName)[0];
        const defaultPosition = data.positions[0];
        updatedSlot.candidateId = data.candidateId;
        updatedSlot.candidateName = data.candidateName;
        updatedSlot.info = data.candidateId;
        updatedSlot.assign = true;
        updatedSlot.positionId = position?.positionId ?? defaultPosition?.positionId;
        updatedSlot.position = {positionId: position?.positionId ?? defaultPosition?.positionId, positionName: position?.positionName ?? defaultPosition?.positionName};

        if (updatedSlot.dates && data.dates)
        {
            const slotKeys = Object.keys(updatedSlot.dates);
            const scheduleKeys = Object.keys(data.dates);

            // console.log({slotKeys, scheduleKeys});
            let isOverlapped = false;

            for(let i = 0; i < scheduleKeys.length && !isOverlapped; i++)
                isOverlapped = slotKeys.includes(scheduleKeys[i]);

            updatedSlot.overlapStatus = isOverlapped;
        }

        newSlots.splice(newSlots.indexOf(updatedSlot), 1, {...updatedSlot});
        // console.log({data, updatedSlot, newSlots});
        setSlots([...newSlots]);
    }, [slots, selectedSlot]);

    const setSlotAssignmentColumn = useCallback((cellParams) => {
        const {data} = cellParams;

        return (
            <div className="d-flex flex-wrap justify-content-center align-items-center w-100 h-100">
                <TooltipButton
                    id={`assign_${data.candidateId}`}
                    title={`Assign ${data.candidateName}`}
                    disabled={!selectedSlot}
                    className="btn-icon ml-1 p-0"
                    color="default"
                    size="xss"
                    type="button"
                    onClick={() => {
                        const newSlots = JSON.parse(JSON.stringify(slots));
                        const updatedSlot = newSlots.filter(d => d.jobOrderCandidateId === selectedSlot?.jobOrderCandidateId)[0];

                        if (!updatedSlot)
                            return;

                        const position = data.positions.filter(p => p.positionName === updatedSlot.position?.positionName)[0];

                        if (!position)
                        {
                            setTalentAssignmentConfirmation({
                                message: `${data.candidateName} has never been assigned to position ${updatedSlot.position?.positionName}. Continue?`,
                                data
                            });
                            return;
                        }

                        assignTalent(data);
                    }}
                >
                    <i className="fas fa-xs fa-arrow-up" />
                </TooltipButton>
            </div>
        );
    }, [slots, selectedSlot, assignTalent]);

    const talentStaticColumns = useMemo(() => [
        {
            field: "assign",
            headerName: "Action",
            maxWidth: 80,
            editable: false,
            pinned: true,
            sortable: false,
            filter: false,
            cellRenderer: (cellParams) => setSlotAssignmentColumn(cellParams),
            suppressMenu: true,
        },
        {
            field: "no",
            headerName: "No.",
            maxWidth: 50,
            editable: false,
            pinned: true,
            sortable: false,
            filter: false,
            valueGetter: "node.rowIndex + 1",
            suppressMenu: true,
        },
        {
            field: "candidateName",
            headerName: "Employee Name",
            width: 150,
            editable: false,
            pinned: true,
            sortable: true,
            filter: "agTextColumnFilter",
            filterParams: {
                buttons: ['reset'],
                debounceMs: 200,
                excelMode: 'mac',
            },
            floatingFilter: true,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
        },
        {
            field: "info",
            headerName: "Info",
            width: 40,
            onCellClicked: (ev) => {
                if (ev.data.candidateId) {
                    setCandidateInEdit({ candidateId: ev.data.candidateId });
                }
            },
            valueFormatter: () => '',
            cellClass: (params) => `roster-cell ${params.value && 'cursor-pointer link fas fa-info-circle text-center'}`,
            sortable: true,
            filter: false,
            suppressMenu: true,
            pinned: true,
        },
        {
            field: "positionName",
            headerName: "Employed Position",
            width: 200,
            editable: false,
            sortable: true,
            pinned: true,
        },
        {
            field: "projectName",
            headerName: "Previous Project",
            width: 250,
            editable: false,
            sortable: true,
        },
        {
            field: "dispatchUnitName",
            headerName: "Previous Dispatch Unit",
            width: 250,
            editable: false,
            sortable: true,
        },
        {
            field: "totalProjectWorkingDays",
            headerName: "Total Working Days (Project)",
            maxWidth: 200,
            editable: false,
            sortable: true,
            filter: "agNumberColumnFilter",
        },
        {
            field: "score",
            headerName: "Score",
            width: 60,
            editable: false,
            sortable: true,
            filter: "agNumberColumnFilter",
        },
    ], [setSlotAssignmentColumn, selectedSlot]);

    useEffect(() => {
        if (!jobOrderId)
            return;
        
        loadSlotData();
    }, [jobOrderId]);

    const getTalentServerSideDatasource = useCallback(() => {
        return {
            getRows: (getRowParams) => {
                if (!selectedPositions.length)
                {
                    getRowParams.success({
                        rowData: [],
                        rowCount: 0,
                    });
                    return;
                }

                getRowParams.api.showLoadingOverlay();
                setLoadingTalents(true);
                const { request } = getRowParams;
                const { filterModel, sortModel } = request;
                const thisFilters = FiltersConverter(filterModel);
                const thisSorts = SortsConverter(sortModel);
                const selectedSorts = (thisSorts.length ? thisSorts : []);
                const queryString = qs.stringify({ filters: thisFilters, sortBy: selectedSorts }, { allowDots: true });
                // console.log({getRowParams, queryString});

                api.post(
                    `joborder/search/talents/${jobOrderId}?isAltradPool=${selectedPoolType === ALTRAD_POOL}&isProjectPool=${selectedPoolType === PROJECT_POOL}&checkExclusionList=${checkExclusionList}&checkInclusionList=${checkInclusionList}&checkScheduleConflict=${checkScheduleConflict}&ignoreMobe=${ignoreMobe}&skip=${request.startRow ?? 0}&take=${pageSize}${queryString ? `&${queryString}` : ""}`,
                    JSON.stringify({jobOrderCandidateIds, hrispositionMasterIds: selectedPositions.map(p => p.hrispositionMasterId)})
                )
                    .then((response) => {
                        try {
                            // console.log('response', {response});
                            const {data: {talentSchedules, startDate, endDate, total}} = response;
                            const successData = {
                                rowData: talentSchedules,
                                rowCount: total,
                            };
                            getRowParams.success(successData);
                            setTalentScheduleParameters({startDate, endDate});
                        }
                        catch (error) {
                            getRowParams.fail();
                            console.error('error on compile data to ag grid', {error});
                        }
                    })
                    .catch(error => {
                        getRowParams.fail();
                        console.error('error on request get for ag grid', {error});
                    })
                    .finally(() => {
                        getRowParams.api.hideOverlay();
                        setLoadingTalents(false);
                    })
                ;
            },
        };
    }, [jobOrderId, jobOrderCandidateIds, selectedPositions, selectedPoolType, checkExclusionList, checkInclusionList, checkScheduleConflict, ignoreMobe]);

    const onTalentGridReady = useCallback((params) => {
        params.api.setServerSideDatasource(getTalentServerSideDatasource());
    }, [getTalentServerSideDatasource]);

    useEffect(() => {
        if (!talentGridRef?.current?.api || !selectedPositions?.length)
            return;

        talentGridRef.current.api.setServerSideDatasource(getTalentServerSideDatasource());
    }, [selectedPositions]);

    const resizeTalentColumns = useCallback((params) => {
        if (!talentGridRef?.current?.columnApi)
            return;

        const allColumnIds = [];
        talentGridRef.current.columnApi.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        talentGridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }, [talentGridRef]);

    useEffect(() => {
        if (!talentScheduleParameters || !talentScheduleParameters.startDate || !talentScheduleParameters.endDate)
            return;

        const {startDate, endDate} = talentScheduleParameters;
        
        const talentScheduleDateColumns = [];
        const current = moment(startDate);
        const end = moment(endDate);

        while (current <= end) {
            talentScheduleDateColumns.push(moment(current));
            current.add(1, 'd');
        }

        const dateColDefs = talentScheduleDateColumns.map((m) => ({
            headerName: m.format('ddd'),
            marryChildren: true,
            children: [
                {
                    field: `dates.${m.format('YYYYMMDD')}`,
                    tooltipField: `dates.${m.format('YYYYMMDD')}`,
                    headerName: m.format('DD/MM/YYYY'),
                    autoHeight: false,
                    resizable: false,
                    width: 30,
                    editable: false,
                    headerClass: 'date-header',
                    suppressMovable: true,
                    lockPosition: 'right',
                    filter: false,
                    suppressMenu: true,
                    valueFormatter: () => {
                        return '';
                    },
                    valueSetter: (params) => {
                        params.data.name = params.newValue;
                        return true;
                    },
                    cellClass: (params) => {
                        return params.value && params.value.value ? `date-cell roster-icon ${params.value?.className} amount amount${params.value?.value.length}` : 'date-cell';
                    },
                }
            ]
        }));

        setTalentColumnDefs([
            ...talentStaticColumns,
            ...dateColDefs,
        ]);
    }, [talentScheduleParameters, selectedSlot]);

    const handleSave = useCallback(() => {
        if (!slots.filter(s => s.candidateId).length || !jobOrderId)
        {
            onClose();
            return;
        }

        setLoading(true);

        api.post(`joborder/talents/assign?jobOrderId=${jobOrderId}`, JSON.stringify(slots))
            .then(() => {
                setLoading(false);
                onClose();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
    }, [onClose, jobOrderId, slots]);

    return (
        <Modal
            isOpen={true}
            className="modal-fullscreen modal-fullscreen-xl"
        >
            {loading && <Loader />}
            <div
                className="modal-header"
            >
                <Container fluid className="p-0 m-0">
                    <Row className="p-0 m-0">
                        <Col lg={6} className="p-0 m-0 d-flex flex-column justify-content-start align-items-start">
                            <h5
                                className="modal-title"
                            >
                                Talents Search
                            </h5>
                        </Col>
                        <Col lg={6} className="p-0 m-0 d-flex flex-wrap justify-content-end align-items-center">
                            <button
                                aria-label="Close"
                                className="close"
                                onClick={onClose}
                                type="button"
                            >
                                <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                            </button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-body p-2"
                style={{maxHeight: "70vh", overflowY: "auto"}}
            >
                <Container fluid>
                    <Row>
                        <Col lg="12"><span className="font-weight-bold">Available Slots</span></Col>
                    </Row>
                    <div className="ag-theme-alpine w-100" style={{height: '20vh'}}>
                    {
                        loadingSlots ?
                        <div className="w-100 h-100 d-flex flex-wrap justify-content-center align-items-center">
                            <Spinner color="default" type="border" />
                        </div>
                        :
                        <AgGridReact
                            ref={slotGridRef}
                            rowData={slots}
                            columnDefs={slotColumnDefs}
                            defaultColDef={defaultColDef}
                            suppressColumnVirtualisation={false}
                            suppressRowVirtualisation={false}
                            rowBuffer={20}
                            debounceVerticalScrollbar={false}
                            allowContextMenuWithControlKey={true}
                            suppressContextMenu={true}
                            suppressClipboardPaste={true}
                            headerHeight={22}
                            rowHeight={22}
                            readOnlyEdit={true}
                            tooltipShowDelay={0}
                            tooltipHideDelay={20000}
                            suppressDragLeaveHidesColumns={true}
                            suppressColumnMoveAnimation={true}
                            multiSortKey='ctrl'
                            enableCellChangeFlash={false}
                            animateRows={true}
                            rowSelection={"single"}
                            getRowId={getSlotRowId}
                            onRowSelected={(e) => {
                                const {api} = e;

                                if (!api)
                                {
                                    setSelectedSlot(null);
                                    return;
                                }

                                const selectedData = api.getSelectedRows()[0];
                                setSelectedSlot(selectedData ? {...selectedData} : null);
                            }}
                        />
                    }
                    </div>
                </Container>
            </div>
            <div
                className="modal-body p-2"
                style={{maxHeight: "70vh", overflowY: "auto"}}
            >
                <Container fluid>
                    <Row>
                        <Col lg="12" className="mb-2">
                            <Label><span className="font-weight-bold">Talents</span></Label>
                            <MultiSelect
                                data={positions}
                                value={selectedPositions}
                                onChange={(e) => setSelectedPositions([...e.value])}
                                onFilterChange={(e) => setPositionFilter(e.filter.value)}
                                placeholder="Search..."
                                textField="hrispositionMasterName"
                                dataItemKey="hrispositionMasterId"
                                filterable={true}
                                disabled={loadingTalents}
                            />
                        </Col>
                        <Col lg="3" className="d-flex flex-wrap justify-content-center align-items-center">
                            <DropDownList
                                data={[ALTRAD_POOL, PROJECT_POOL, NONPROJECT_POOL]}
                                value={selectedPoolType}
                                onChange={(e) => setSelectedPoolType(e.target.value)}
                                style={{
                                    width: "100%",
                                }}
                                disabled={loadingTalents}
                            />
                        </Col>
                        <Col lg="2" className="d-flex flex-wrap justify-content-center align-items-center">
                            <Checkbox
                                label="No Schedule Conflict"
                                onChange={(e) => {
                                    setCheckScheduleConflict(e.value);

                                    if (!e.value && ignoreMobe)
                                        setIgnoreMobe(false);
                                }}
                                value={checkScheduleConflict}
                                disabled={loadingTalents}
                            />
                        </Col>
                        <Col lg="3" className="d-flex flex-wrap justify-content-center align-items-center">
                            <Checkbox
                                label="Ignore Mobe/Demobe Schedule"
                                onChange={(e) => setIgnoreMobe(e.value)}
                                value={ignoreMobe}
                                disabled={!checkScheduleConflict || loadingTalents}
                            />
                        </Col>
                    </Row>
                    <div className="ag-theme-alpine mt-2 w-100" style={{height: '30vh'}}>
                        <AgGridReact
                            ref={talentGridRef}
                            columnDefs={talentColumnDefs}
                            defaultColDef={defaultColDef}
                            suppressColumnVirtualisation={false}
                            suppressRowVirtualisation={false}
                            rowBuffer={20}
                            debounceVerticalScrollbar={false}
                            allowContextMenuWithControlKey={true}
                            onRowDataUpdated={(params) => resizeTalentColumns(params)}
                            pagination={true}
                            paginationPageSize={pageSize}
                            cacheBlockSize={pageSize}
                            rowModelType={'serverSide'}
                            serverSideInfiniteScroll={true}
                            onGridReady={onTalentGridReady}
                            suppressContextMenu={true}
                            suppressClipboardPaste={true}
                            headerHeight={22}
                            groupHeaderHeight={22}
                            rowHeight={22}
                            readOnlyEdit={true}
                            tooltipShowDelay={0}
                            tooltipHideDelay={20000}
                            suppressDragLeaveHidesColumns={true}
                            suppressColumnMoveAnimation={true}
                            multiSortKey='ctrl'
                            enableCellChangeFlash={false}
                            animateRows={true}
                            rowSelection={"single"}
                            loadingOverlayComponent={loadingOverlayComponent}
                            suppressRowClickSelection={true}
                        />
                    </div>
                </Container>
            </div>
            <div
                className="modal-footer d-flex flex-wrap"
            >
                <Button
                    color="secondary"
                    onClick={onClose}
                    type="button"
                    className="mt-2"
                >
                    Close
                </Button>
                <Button
                    color="default"
                    onClick={handleSave}
                    type="button"
                    className="mt-2"
                >
                    Save
                </Button>
            </div>
            {
                candidateInEdit &&
                <TalentEditor
                    item={candidateInEdit}
                    onClose={() => {
                        setCandidateInEdit(null);
                    }}
                    onSaved={() => {
                        setCandidateInEdit(null);
                    }}
                    lookups={{
                        ...lookups
                    }}
                />
            }
            {
                talentAssignmentConfirmation ?
                <SweetAlert
                    title="Assignment Confirmation"
                    warning
                    confirmBtnText="Yes"
                    onConfirm={() => {
                        setTalentAssignmentConfirmation(null);
                        assignTalent(talentAssignmentConfirmation.data);
                    }}
                    showCancel
                    onCancel={() => setTalentAssignmentConfirmation(null)}
                >
                {
                    talentAssignmentConfirmation.message
                }
                </SweetAlert>
                :
                null
            }
        </Modal>
    );
};