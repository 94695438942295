/* eslint-disable no-unused-vars */

import CopyToClipboard from "react-copy-to-clipboard";
import {
    Container, Row, Col, Button, Card, CardBody, CardTitle, UncontrolledTooltip, Progress, Label
} from "reactstrap";
import { TooltipButton } from '../../../../../inputs';
import React, { useEffect, useState, useCallback, useMemo, useRef, Component } from 'react';

const timeoutLength = 1500;
const defaultText = "Copy link to clipboard";
const copiedText = "Copied!"
const TooltipContent = ({ scheduleUpdate, index, copiedIndex }) => {
    const [text, setText] = React.useState(defaultText);

    React.useEffect(() => {
        if (copiedIndex === index) {
            setText(copiedText);
            scheduleUpdate();
            const timeoutId = setTimeout(() => {
                setText(defaultText);
                scheduleUpdate();
            }, timeoutLength);
            return () => clearTimeout(timeoutId);
        }
    }, [copiedIndex, index, scheduleUpdate]);

    return (
        <>{text}</>
    );
}


export default class ActionBtnCellRenderer extends Component {

    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }
    btnClickedHandler() {
        this.props.clicked(this.props.value);
    }
    render() {
        //console.log('BtnCellRenderer', 'this', this);
        var { props } = this;
        var { colDef, data } = props;
        var { additionalParams } = colDef;

        //console.log('additionalParams', additionalParams());
        const {
            copiedIndex,
            setCopiedIndex,
            itemInEdit,
            setItemInEdit,
            handleDelete,
            history,
            setShowJobOrderGrid,
        } = additionalParams();


        return (
            //<button onClick={this.btnClickedHandler}>Click Me!</button>
            <div key={data.jobOrderId} className="text-center">
                <UncontrolledTooltip delay={0} placement="top" target={`link-${data.jobOrderId}`}>
                    {({ scheduleUpdate }) => {
                        const index = data.jobOrderId;

                        return (
                            <TooltipContent {...{ scheduleUpdate, index, copiedIndex }} />
                        );
                    }
                    }
                </UncontrolledTooltip>
                <CopyToClipboard
                    text={`${window.location.protocol}//${window.location.host}/home?job=${data.identifier}`}
                    onCopy={(value) => {
                        setCopiedIndex(data.jobOrderId);
                    }}
                >
                    <Button
                        id={`link-${data.jobOrderId}`}
                        className="btn-icon"
                        color="default"
                        size="xss"
                        type="button"
                        onClick={() => { }}
                    >
                        <i className="fa fa-xs fa-clone pt-1"></i>
                    </Button>
                </CopyToClipboard>
                <TooltipButton
                    id={`edit_${data.jobOrderId}`}
                    title="Open"
                    className="btn-icon"
                    color="default"
                    size="xss"
                    type="button"
                    onClick={() => {
                        //setShowJobOrderGrid(false);
                        //setItemInEdit(data);
                        window.open(`/joborderdetail/${data.jobOrderId}`, "_blank");
                        //history.push(`/joborderdetail/${data.jobOrderId}`);
                    }}
                >
                    <i className="fa fa-xs fa-folder-open pt-1"></i>
                </TooltipButton>
                <TooltipButton
                    id={`edit_milestone_${data.jobOrderId}`}
                    title="Edit Milestone Template"
                    className="btn-icon"
                    color="default"
                    size="xss"
                    type="button"
                    onClick={() => {
                        //setShowJobOrderGrid(false);
                        //setItemInEdit(data);
                        window.open(`/jobordermilestone/${data.jobOrderId}`, "_blank");
                        //history.push(`/joborderdetail/${data.jobOrderId}`);
                    }}
                >
                    <i className="fas fa-xs fa-list pt-1"></i>
                </TooltipButton>
                <TooltipButton
                    id={`delete_${data.jobOrderId}`}
                    title="Delete"
                    className="btn-icon"
                    color="warning"
                    size="xss"
                    type="button"
                    onClick={() => handleDelete(data)}
                >
                    <i className="fa fa-xs fa-trash-alt pt-1"></i>
                </TooltipButton>
            </div>
        );


    }
}
