import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { ComboBox as ComboBox2 } from "../../../../dropdowns";
import { DatePicker as DatePicker2 } from "../../../../date-pickers";
import { IndeterminateCheckbox } from '../../../../react-table/controls';
import { formatDate } from "../../../../../utils";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
//import ContextMenuHelper from "../helpers/ContextMenuHelper";
import qs from "qs";
import debounce from 'lodash.debounce';
import useRoster from "../contexts/RosterContext";
import { initialState } from "../reducers/rosterReducer";
import moment from "moment";
import { filterBy } from "@progress/kendo-data-query";
import api from "../../../../../services/api";
import { ViewAccess } from '../../../../../utils'
import fileDownload from "js-file-download";
import { ErrorNotification } from '../../../../alerts';

export default (props) => {

    const {
        api: gridApi,
        columnApi,
        today,
        loadSchedule,
        autoSelectResources,
        setLoading,
        projectid,
        dispatchunitid,
        viewAccess,
        data
    } = props;

    const {
        setDispatchUnit,
        dispatchUnit,
        project,
        setProject,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        trimStartDate,
        setTrimStartDate,
        trimEndDate,
        setTrimEndDate,
        showArchivedDispatchUnits,
        setShowArchivedDispatchUnits,
        dataLoaded,
        setDispatchEditorOpen,
        setContingencyOpen,
        showAssignedTalents,
        setShowAssignedTalents
    } = useRoster();

    //const [ selectedProject, setSelectedProject ] = useState(null);
    const [projects, setProjects] = useState([]);
    const [projectsLoading, setProjectsLoading] = useState(false);

    //const [ dispatchUnit, setDispatchUnit ] = useState(null);
    const [dispatchUnits, setDispatchUnits] = useState([]);
    const [allDispatchUnits, setAllDispatchUnits] = useState([]);
    const [dispatchUnitsLoading, setDispatchUnitsLoading] = useState(false);

    // Candidate
    const [includeInactiveValue, setIncludeInactiveValue] = useState(false);

    // const [ showArchivedDispatchUnits, setShowArchivedDispatchUnits ] = useState(false);
    // const [ startDate, setStartDate ] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
    // const [ endDate, setEndDate ] = useState(new Date(today.getFullYear(), today.getMonth() + 6, 1));
    // const [ trimStartDate, setTrimStartDate ] = useState(true);
    // const [ trimEndDate, setTrimEndDate ] = useState(true);

    // Error message variables
    const [errorTitle, setErrorTitle] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);
    const [errorMessageOpening, setErrorMessageOpening] = React.useState(`Error occured when processing the request: `);
    const [errorTitleOnGenerate, setErrorTitleOnGenerate] = React.useState(`All Roster Grid Error`);

    useEffect(() => {
        if (projectid && dispatchunitid) {
            gridApi.showLoadingOverlay();
            const apiCalls = [
                api.get(`/project/${projectid}`),
                api.get(`/dispatchunit/id/${dispatchunitid}`)
            ]
            Promise.all(apiCalls)
                .then((response) => {
                    console.log('response', response);
                    const qsProject = { ...response[0].data };
                    const qsDispatch = {
                        locationName: response[1].data.location.lookupValue,
                        lookupId: response[1].data.dispatchUnitId,
                        lookupLabel: response[1].data.quoteNumber,
                        lookupValue: response[1].data.dispatchUnitName,
                    }
                    console.log('qs', { qsProject, qsDispatch });
                    localStorage.setItem(process.env.REACT_APP_HRIS_ALL_ROSTER_GRID_SETUP_STATE, JSON.stringify({
                        project: qsProject,
                        dispatchUnit: qsDispatch,
                        trimStartDate: initialState.trimStartDate,
                        trimEndDate: initialState.trimEndDate,
                        startDate: formatDate(initialState.startDate, 'YYYY-MM-DD'),
                        endDate: formatDate(initialState.endDate, 'YYYY-MM-DD'),
                        showArchivedDispatchUnits: initialState.showArchivedDispatchUnits,
                        showAssignedTalents: initialState.showAssignedTalents
                    }));
                    setProject(qsProject);
                    setDispatchUnit(qsDispatch);
                    setShowArchivedDispatchUnits(initialState.showArchivedDispatchUnits);
                    setStartDate(initialState.startDate);
                    setEndDate(initialState.endDate);
                    setTrimStartDate(initialState.trimStartDate);
                    setTrimEndDate(initialState.trimEndDate);
                    setShowAssignedTalents(initialState.showAssignedTalents);
                    loadSchedule({
                        projectId: qsProject.projectId,
                        dispatchUnitId: qsDispatch.lookupId,
                        startDate: initialState.trimStartDate ? null : formatDate(initialState.startDate, 'YYYY-MM-DD'),
                        endDate: initialState.trimEndDate ? null : formatDate(initialState.endDate, 'YYYY-MM-DD'),
                        showAssignedTalents: initialState.showAssignedTalents,
                    })
                })
                .catch((error) => {
                    console.error(error.response);
                });
        } else if (process.env.REACT_APP_HRIS_ALL_ROSTER_GRID_SETUP_STATE in localStorage) {
            const savedState = JSON.parse(localStorage.getItem(process.env.REACT_APP_HRIS_ALL_ROSTER_GRID_SETUP_STATE));
            console.log('savedState', savedState);
            setProject(savedState.project);
            setDispatchUnit(savedState.dispatchUnit);
            setShowArchivedDispatchUnits(savedState.showArchivedDispatchUnits);
            setStartDate(new Date(savedState.startDate));
            setEndDate(new Date(savedState.endDate));
            setTrimStartDate(savedState.trimStartDate);
            setTrimEndDate(savedState.trimEndDate);
            setShowAssignedTalents(savedState.showAssignedTalents);

            loadSchedule({
                projectId: savedState.project.projectId,
                dispatchUnitId: savedState.dispatchUnit?.lookupId,
                startDate: savedState.trimStartDate ? null : formatDate(savedState.startDate, 'YYYY-MM-DD'),
                endDate: savedState.trimEndDate ? null : formatDate(savedState.endDate, 'YYYY-MM-DD'),
                showAssignedTalents: savedState.showAssignedTalents,
            })
        }
    }, []);

    const requestProjectData = useCallback(
        debounce((filter) => {

            const queryString = qs.stringify({ filter }, { allowDots: true });

            api.get(`/project/search${queryString ? `?${queryString}` : ""}`)
                .then((response) => {
                    if (!response.data) {
                        setProjects([]);
                    }
                    else {
                        setProjects(response.data);
                    }

                })
                .catch((error) => {
                    console.log(error.response)
                })
                .finally(() => {
                    setProjectsLoading(false);
                });
        }, 300)
        , []);

    const onProjectFilterChange = useCallback((event) => {
        setProjectsLoading(true);
        requestProjectData(event.filter?.value);
        setProjects([]);
    }, []);

    const onProjectChange = useCallback((event) => {
        setProject(event.value);
    }, []);

    useEffect(() => {
        if (project) {
            setDispatchUnitsLoading(true);

            api.get(`/lookup/dispatchunit/${project?.projectId}/${showArchivedDispatchUnits}`)
                .then((response) => {
                    if (!response.data) {
                        setDispatchUnits([]);
                        setAllDispatchUnits([]);
                    }
                    else {
                        setDispatchUnits(response.data);
                        setAllDispatchUnits(response.data);
                    }

                })
                .catch((error) => console.log(error.response))
                .finally(() => {
                    setDispatchUnitsLoading(false);
                });
        }
        else {
            setDispatchUnits([]);
            setAllDispatchUnits([]);
        }

    }, [project, showArchivedDispatchUnits, setDispatchEditorOpen]);

    const onDispatchUnitChange = useCallback((event) => {
        setDispatchUnit(event.value);
    }, []);

    const handleFilterClick = useCallback(() => {
        localStorage.setItem(process.env.REACT_APP_HRIS_ALL_ROSTER_GRID_SETUP_STATE, JSON.stringify({
            project,
            dispatchUnit,
            trimStartDate,
            trimEndDate,
            startDate: formatDate(startDate, 'YYYY-MM-DD'),
            endDate: formatDate(endDate, 'YYYY-MM-DD'),
            showArchivedDispatchUnits,
            showAssignedTalents
        }));
        loadSchedule({
            projectId: project.projectId,
            dispatchUnitId: dispatchUnit?.lookupId,
            startDate: trimStartDate ? null : formatDate(startDate, 'YYYY-MM-DD'),
            endDate: trimEndDate ? null : formatDate(endDate, 'YYYY-MM-DD'),
            showAssignedTalents: showAssignedTalents
        })
    }, [project, dispatchUnit, trimStartDate, trimEndDate, startDate, endDate, showArchivedDispatchUnits, showAssignedTalents]);

    const onDispatchUnitFilterChange = useCallback((event) => {
        const copy = [...allDispatchUnits];
        const data = filterBy(copy, event.filter);
        setDispatchUnits(data);
    }, [allDispatchUnits]);

    // -----------------------------------------------------------------------


    const downloadExport = React.useCallback((url, filename) => {
        setLoading(true);
        api.get(url, { responseType: 'blob' })
            .then(blob => {
                // console.log(blob);
                //var filename = /(?<=attachment;.*filename=").*(?=";)/.exec(blob.headers["content-disposition"])[0];
                fileDownload(blob.data, filename);
            }).catch(error => {
                console.error(error);
                setErrorMessage(`${errorMessageOpening} ${error.message} `);
                //setLoading(false);
            }).finally(() => setLoading(false))
    }, []);

    const getProjectFilename = useCallback(() => {
        return project ? `-Project ${project.projectName.replace(/^(project)/ig, "")}` : ``;
    }, [project]);

    const getIncludeInactiveFilename = useCallback(() => {
        return includeInactiveValue == null ? `` : `-Include Inactive ${includeInactiveValue == true ? 'Yes' : 'No'}`;
    }, [includeInactiveValue]);

    const getShowAssignedTalentsFilename = useCallback(() => {
        return showAssignedTalents == null ? `` : `-Assigned Talents ${showAssignedTalents == true ? 'Yes' : 'No'}`;
    }, [showAssignedTalents]);

    const firstOrMulti = useCallback((list, keyName, prefix = '') => {

        //console.log('firstOrMulti', { list, keyName, prefix })
        //console.log('list', list);

        if (!list || list.length == 0) {
            return ``;
        }

        //console.log('continue');

        var prefixLower = prefix ? prefix.toLowerCase() : '';
        var unitName = list.find(w => w)[keyName];
        if (unitName && prefixLower) {
            var regEx = new RegExp(`/^(${prefixLower})/ig`);
            unitName = unitName.replace(regEx, '');
        }

        // Remove duplicate prefix
        //if (prefix && beginWith(unitName, prefix))


        if (list.length == 1) {
            return `-${prefix} ${unitName}`;
        }
        else {
            return `-${prefix} Multiple`;
        }
    }, []);

    useEffect(() => {
        console.log('dispatchUnit', dispatchUnit);
    }, [dispatchUnit]);


    useEffect(() => {
        console.log('dispatchunitid', dispatchunitid);
    }, [dispatchunitid]);

    // Function void to download the data
    const exportAction = useCallback(() => {
        try {
            //var d = selectedData;
            var reportFilename = "AllRosterExport.trdp";
            var reportName = "All Roster Export Grid View";
            //window.open(`/reportviewer/${d.reportName}?reportName=${d.reportFilename}&containerName=${d.reportName.replace(/\s/g, '')}`, "_blank");



            var defaultProjectList = !project ? [] : [project.projectId];

            const params = {
                reportName: reportFilename,
                start: trimStartDate ? null : moment(startDate).format('YYYY-MM-DD'),
                end: trimStartDate ? null : moment(endDate).format('YYYY-MM-DD'),
                projectId: !project ? '' : project.projectId,
                dispatchUnitId: !dispatchUnit ? '' : dispatchUnit.lookupId,
                locationId: '',
                dispatchUnit: !dispatchUnit ? '' : dispatchUnit.lookupId,
                location: '',
                positionIds: '',
                candidateIds: '',
                defaultProject: defaultProjectList,
                includeInactive: '',
                showArchived: showArchivedDispatchUnits ? true : false,
                showAssignedTalents: showAssignedTalents ? true : false,
            };
            console.log('params', params);

            let url = `/reports/download-from-report-centre?${qs.stringify(params)}`;
            let startdateFileName = moment(startDate).format('YYYYMMDD');
            let enddateFileName = moment(endDate).format('YYYYMMDD');
            let purename = reportFilename.replace('.trdp', '');



            let projectTitle = getProjectFilename();
            let duTitle = firstOrMulti((dispatchUnit && dispatchUnit.length) ? [dispatchUnit] : [], `lookupName`, `Dispatch Unit`);
            let locationTitle = firstOrMulti([], `locationName`, `Location`);
            let positionTitle = firstOrMulti([], `positionName`, `Position`);
            let includeInactiveTitle = getIncludeInactiveFilename();
            let includeShowAssignedTalents = getShowAssignedTalentsFilename()
            //let locationTitle = firstOrMulti(selectedLoc, `locationName`, `Location`);
            //let duTitle = selectedDu ? `-Dispatch Unit ${selectedDu.find(w => w).dispatchUnitName.replace(/^(dispatch unit)/ig, "")}` : ``;

            //let projectTitle = project ? `-Project ${project.projectName.replace(/^(project)/ig, "")}` : ``;
            //let projectTitle = project ? `-Project ${project.projectName.replace(/^(project)/ig, "")}` : ``;


            
            var endpointName = `download-all-roster-gridview`;
            var filename = `${endpointName}-${startdateFileName}-to-${enddateFileName}${projectTitle}${includeShowAssignedTalents}.xlsx`;
            
            // If end point overriden then get from end point name
            if (endpointName) {
                url = `/reports/${endpointName}?${qs.stringify(params)}`;
            }

            downloadExport(url, filename);
            //setStep(1);
        }
        catch (error) {
            console.error(error);
            setErrorMessage(`${errorMessageOpening}${error.message}`);
            setLoading(false);
        }
    }, [project, dispatchUnit, errorMessageOpening, errorTitleOnGenerate, startDate, endDate, includeInactiveValue, dispatchunitid, showAssignedTalents]);



    // -----------------------------------------------------------------------


    // Error message handling
    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            message: errorMessage,
            title: errorTitle,
            onConfirm: () => {
                setErrorTitle(null);
                setErrorMessage(null);
            }
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage, errorTitle]);


    return (
        <>
            {errorNotification.length > 0 && errorNotification}
            <Container fluid className='d-flex flex-column justify-content-between h-100'>
                <Row>
                    <Col xs={12} className="pt-3">
                        <h5>Roster Filter</h5>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <Label>
                                Project
                                <span className="text-danger"> *</span>
                            </Label>
                            <ComboBox
                                data={projects}
                                value={project}
                                onChange={onProjectChange}
                                dataItemKey="projectId"
                                textField="projectName"
                                filterable={true}
                                onFilterChange={onProjectFilterChange}
                                onFocus={onProjectFilterChange}
                                placeholder=""
                                loading={projectsLoading}
                                rounded="small"
                                size="small"
                                className='w-100'
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} className="d-none">
                        <FormGroup>
                            <div className="w-100 d-flex justify-content-between">
                                <Label>
                                    Dispatch Unit
                                    <span className="text-danger"> *</span>
                                </Label>
                                <IndeterminateCheckbox
                                    id="showArchived"
                                    type="checkbox"
                                    onChange={() => {
                                        setShowArchivedDispatchUnits(!showArchivedDispatchUnits);
                                    }}
                                    checked={showArchivedDispatchUnits}
                                    customTitle="Show Archived"
                                />
                            </div>
                            <ComboBox
                                data={dispatchUnits}
                                value={dispatchUnit}
                                onChange={onDispatchUnitChange}
                                dataItemKey="lookupId"
                                textField="lookupValue"
                                filterable={true}
                                placeholder=""
                                loading={dispatchUnitsLoading}
                                rounded="small"
                                size="small"
                                className='w-100'
                                onFilterChange={onDispatchUnitFilterChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup className={trimStartDate ? 'disabled' : ''}>
                            <div className="w-100 d-flex justify-content-between">
                                <Label>
                                    Start Date
                                    <span className="text-danger"> *</span>
                                </Label>
                                <span className="d-none">
                                    <IndeterminateCheckbox
                                        id="trimStartDate"
                                        type="checkbox"
                                        onChange={() => {
                                            setTrimStartDate(!trimStartDate);
                                        }}
                                        checked={trimStartDate}
                                        customTitle="Trim"
                                    />
                                </span>
                            </div>
                            <DatePicker
                                name="startDate"
                                id="startDate"
                                required
                                value={startDate}
                                onChange={({ value }) => {
                                    setStartDate(value);
                                }}
                                disabled={trimStartDate}
                                format={"dd/MM/yyyy"}
                                className='w-100'
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup className={trimEndDate ? 'disabled' : ''}>
                            <div className="w-100 d-flex justify-content-between d-none">
                                <Label>
                                    End Date
                                    <span className="text-danger"> *</span>
                                </Label>
                                <span className="d-none">
                                    <IndeterminateCheckbox
                                        id="trimEndDate"
                                        type="checkbox"
                                        onChange={() => {
                                            setTrimEndDate(!trimEndDate);
                                        }}
                                        checked={trimEndDate}
                                        customTitle="Trim"
                                    />
                                </span>
                            </div>
                            <DatePicker
                                name="endDate"
                                id="endDate"
                                required
                                value={endDate}
                                onChange={({ value }) => {
                                    setEndDate(value);
                                }}
                                disabled={trimEndDate}
                                format={"dd/MM/yyyy"}
                                className='w-100'
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup>
                            <div className="w-100 d-flex justify-content-between">
                                <IndeterminateCheckbox
                                    id="showAssignedTalentscb"
                                    type="checkbox"
                                    onChange={() => {
                                        setShowAssignedTalents(!showAssignedTalents);
                                    }}
                                    checked={showAssignedTalents}
                                    customTitle="Show Assigned Talents"
                                    labelClassName={"setup-panel-checkbox"}
                                />
                            </div>
                        </FormGroup>
                    </Col>
                    <Col>
                        <Button
                            color="secondary"
                            onClick={handleFilterClick}
                            type="button"
                            className="w-100"
                        >
                            Filter
                        </Button>
                    </Col>
                </Row>

                {/*{viewAccess == ViewAccess.FULLACCESS &&*/}
                <Row className="mb-3">
                    <Col xs={12} className="pt-3">
                        <h5>Actions</h5>
                    </Col>
                    <Col xs={12} className="">
                        <Button
                            color="secondary"
                            onClick={() => {
                                setLoading(true)

                                exportAction();
                            }}
                            type="button"
                            className="w-100 text-left"
                            disabled={!data}
                        >
                            Export
                        </Button>
                    </Col>

                </Row>
                {/*}*/}
            </Container>
        </>
    )
}