import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import api from "../../../../services/api";
import moment from "moment";
import TemplateDialog from "./TemplateDialog";
import { useBlockLayout, useFlexLayout, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import Grid from "../../../Grid";
import { RadioButton } from "@progress/kendo-react-inputs";
import { profileRows } from "./MergeHelper";

function ProfileComparisonDialog(props) {
    const {
        title,
        selectedCandidates,
        initialSelectedCandidateId,
        initialSelectedCandidateProfile,
        onClose,
        onBackClick,
        onNextClick,
    } = props;

    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState([]);
    const [ selectedCandidateId, setSelectedCandidateId ] = useState(initialSelectedCandidateId);
    const [ selectedCandidateAttribute, setSelectedCandidateAttribute ] = useState(initialSelectedCandidateProfile ?? profileRows.reduce((obj, item) => (obj[item.id] = null, obj), {}));
    const skipPageResetRef = useRef();

    const loadData = useCallback(() => {
        skipPageResetRef.current = true;

        if (!selectedCandidates?.length)
            return;

        setLoading(true);

        api.post(`candidatemerge/compare/profile`, JSON.stringify(selectedCandidates.map(c => c.candidateId)))
            .then((response) => {
                const {data: responseData} = response;
                const transposedData = [];

                profileRows.forEach(r => {
                    const row = {
                        title: r.title,
                        rowCustomId: r.id,
                        rowType: r.type,
                        additionalPropertyName: r.additionalPropertyName,
                        additionalPropertyType: r.additionalPropertyType,
                        rowDataColumnId: r.rowDataColumnId,
                    };

                    responseData.forEach(d => {
                        row[d.candidateId] = {};
                        row[d.candidateId].value = d[r.id];
                        row[d.candidateId].label = r.type === "date" ? (d[r.id] ? moment(d[r.id]).format("DD-MM-YYYY") : null) : d[r.id];

                        if (r.additionalPropertyName && r.additionalPropertyType)
                            row[d.candidateId][r.additionalPropertyName] = d[r.additionalPropertyName];

                        if (r.rowDataColumnId)
                            row[d.candidateId][row.rowDataColumnId] = d[row.rowDataColumnId];
                    });

                    transposedData.push(row);
                });
                // console.log({transposedData});
                setData([...transposedData]);
            })
            .catch((error) => console.log({error}))
            .finally(() => setLoading(false))
        ;
    }, [skipPageResetRef, api, selectedCandidates]);

    useEffect(() => loadData(), [selectedCandidates]);

    const defaultColumn = useMemo(() => ({
        disableFilters: true,
        disableSortBy: true,
    }), []);

    const columns = useMemo(() => {
        return [
            {
                Header: () => (
                    <span className="font-weight-normal font-size-small-custom text-transform-none"><i className="fas fa-info-circle mr-1" />Please select 1 talent data that you want to keep.</span>
                ),
                accessor: "title",
                sticky: "left",
                width: 100,
                maxWidth: 100,
            },
            ...selectedCandidates.map(d => ({
                Header: () => (
                    <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                        <RadioButton
                            name="selectCandidate"
                            onChange={({value}) => {
                                setSelectedCandidateId(value);
                                const newAttribute = profileRows.reduce((obj, item) => {
                                    const currentData = data.filter(dt => dt.rowCustomId === item.id)[0];

                                    if (!currentData)
                                        return obj;

                                    // console.log({obj, item, currentData});
                                    obj[item.id] = {
                                        candidateId: value,
                                        value: currentData[d.candidateId]?.value,
                                    };

                                    if (item.rowDataColumnId)
                                        obj[item.id][item.rowDataColumnId] = currentData[d.candidateId] ? currentData[d.candidateId][item.rowDataColumnId] : null;

                                    return obj;
                                }, {});
                                // console.log({newAttribute});
                                setSelectedCandidateAttribute(newAttribute);
                            }}
                            value={d.candidateId}
                            checked={selectedCandidateId === d.candidateId}
                        />
                        <span className="text-left ml-2">{d.candidateName} ({d.candidateId})</span>
                    </div>
                ),
                id: `${d.candidateId}`,
                Cell: ({ row: { original } }) => {
                    return (
                        <div className={`w-100 px-1 d-flex flex-row justify-content-start align-items-start`}>
                        {
                            original.rowType !== "title" &&
                            <RadioButton
                                name={`select${original.rowCustomId}`}
                                onChange={({value}) => {
                                    const dataToUpdate = {
                                        candidateId: value,
                                        value: original[value].value,
                                    }

                                    if (original.rowDataColumnId)
                                        dataToUpdate[original.rowDataColumnId] = original[value][original.rowDataColumnId];
                                    
                                    const current = {...selectedCandidateAttribute, [original.rowCustomId]: dataToUpdate};
                                    setSelectedCandidateAttribute(current);
                                }}
                                value={d.candidateId}
                                checked={
                                    original
                                    && selectedCandidateAttribute
                                    && (`${d.candidateId}` in original)
                                    && (original.rowCustomId in selectedCandidateAttribute)
                                    && (selectedCandidateAttribute[original.rowCustomId]?.candidateId === d.candidateId)
                                }
                                disabled={!selectedCandidateId}
                            />
                        }
                            <span className="text-left ml-2">
                            {
                                original.rowType !== "bool" ?
                                (original.rowType === "title" ? null : original[d.candidateId]?.label ?? "-")
                                :
                                original[d.candidateId]?.value ? "Yes" : (original[d.candidateId]?.value !== null ? "No" : "-")
                            }
                            </span>
                        {
                            original.additionalPropertyName && original.additionalPropertyType ?
                            <span className="text-left ml-2">
                            {
                                original.additionalPropertyType === "bool" ?
                                (
                                    original[d.candidateId][original.additionalPropertyName] ?
                                    <i className="fas fa-check-circle text-success" />
                                    :
                                    null
                                )
                                :
                                original[d.candidateId][original.additionalPropertyName]
                            }
                            </span>
                            :
                            null
                        }
                        </div>
                    );
                },
                width: 150,
                minWidth: 150,
            }))
        ]
    }, [selectedCandidates, selectedCandidateId, data, selectedCandidateAttribute]);

    // useEffect(() => console.log({selectedCandidateAttribute}), [selectedCandidateAttribute]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout,
        useBlockLayout,
        useSticky,
    );

    // useEffect(() => console.log({rows}), [rows]);

    const content = useMemo(() => {
        return (
            <>
                <Row className="m-0 p-0 w-100">
                    <Col lg={12} className="m-0 p-0 d-flex flex-wrap justify-content-start align-items-center">
                        <span className="font-size-small-custom">
                            <i className="fas fa-info-circle mr-1" />The other talent's profile including mobile number and address will be removed.<br/>
                            <i className="fas fa-info-circle mr-1" />This <i className="fas fa-check-circle text-success" /> icon indicates that the email and/or mobile phone number is used for talent authorization in the Talent Portal application.
                        </span>
                    </Col>
                </Row>
                <Row className="m-0 p-0 mt-3 w-100">
                    <Grid
                        height={'calc(100vh - 464px)'}
                        totalRecords={profileRows.length}
                        pageSize={profileRows.length}
                        rows={rows}
                        tableProps={getTableProps()}
                        headerGroups={headerGroups.map(h => {
                            const headerGroupProps = h.getHeaderGroupProps();
                            headerGroupProps.style = {...headerGroupProps.style, width: "100%"}

                            return {
                                ...h,
                                getHeaderGroupProps: () => headerGroupProps
                            };
                        })}
                        tableBodyProps={getTableBodyProps()}
                        prepareRow={(row) => {
                            prepareRow(row);
                            const rowProps = row.getRowProps();
                            const {original} = row;
                            row.getRowProps = () => {
                                return {
                                    ...rowProps,
                                    style: {
                                        ...rowProps.style,
                                        width: "100%",
                                    },
                                };
                            };

                            row.cells = row.cells.map(c => {
                                const columnId = parseInt(c.column.id);
                                const cellProps = c.getCellProps();
                                return {
                                    ...c,
                                    getCellProps: () => ({
                                        ...cellProps,
                                        style: {
                                            ...cellProps.style,
                                            backgroundColor: columnId === selectedCandidateId && (!original.rowType || original.rowType !== "title") ? "#c2deff" : c.column.id === "title" && (!original.rowType || original.rowType !== "title") ? "#fff" : original.rowType === "title" ? "#aaa" : "inherit",
                                            color: original.rowType === "title" ? "#fff" : "inherit",
                                        }
                                    })
                                };
                            });
                        }}
                        fullscreen={true}
                    />
                </Row>
            </>
        );
    }, [profileRows, rows, getTableProps, headerGroups, getTableBodyProps, prepareRow, selectedCandidateId]);

    const footer = useMemo(() => (
        <>
            <Button
                color="secondary"
                onClick={onClose}
                type="button"
            >
                Close
            </Button>
            <Button
                color="info"
                onClick={() => onBackClick()}
                type="button"
            >
                <i className="fas fa-chevron-left mr-2" />Back: Talent Duplication Search
            </Button>
            <Button
                color="info"
                onClick={() => onNextClick(selectedCandidateId, selectedCandidateAttribute)}
                type="button"
                disabled={!selectedCandidateId}
            >
                Next: Data Review - Dispatch History<i className="fas fa-chevron-right ml-2" />
            </Button>
        </>
    ), [onClose, onBackClick, onNextClick, selectedCandidateId, selectedCandidateAttribute]);

    return (
        <TemplateDialog
            title={title}
            loading={loading}
            content={content}
            footer={footer}
            onClose={onClose}
        />
    );
};

export default ProfileComparisonDialog;
