import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'reactstrap';
import { TooltipButton } from '../../inputs';
import Loader from '../../loaders';
import qs from "qs";
import api from "../../../services/api";
import moment from "moment";
import { useHistory, useParams } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

const NoRenderer = ({ document, fileName }) => {
    const fileText = fileName || document?.fileType || "";

    if (document?.fileType && document.fileType.includes("problem")) {
        return (<Row style={{ width: '100%' }}>
            <Col xs={12}>
                <div className="alert alert-danger">File not found.</div>
            </Col>
        </Row>);
    }

    if (fileText) {
        return (<Row style={{ width: '100%' }}>
            <Col xs={12}>
                <div className="alert alert-danger">No Renderer Error.</div>
            </Col>
        </Row>);
    }

    return (<Row style={{ width: '100%' }}>
        <Col xs={12}>
            <div className="alert alert-danger">File not found.</div>
        </Col>
    </Row>);
};

export default function ReviewRequestDetail() {
    const history = useHistory();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [itemInEdit, setItemInEdit] = useState(null);
    const [candidate, setCandidate] = useState(null);
    const [selectedCandidateDocumentId, setSelectedCandidateDocumentId] = useState(null);
    const dispatchGridRef = useRef();
    const jobOrderGridRef = useRef();

    const loadData = useCallback(() => {
        if (!id)
            return;

        setLoading(true);

        api.get(`/vacancy/review/request/detail/${id}`)
            .then(({data}) => {
                setItemInEdit(data);

                api.get(`/vacancy/candidate/summary/${id}/${data.candidateId}`)
                    .then(({data: candidateData}) => {
                        setCandidate(candidateData);
                    })
                    .catch((error) => {
                        console.log({error});
                        history.replace(`/lrfreview`);
                    })
                    .finally(() => {
                        setLoading(false);
                    })
                ;
            })
            .catch((error) => {
                console.log({error});
                setLoading(false);
            })
        ;
    }, [id, history]);

    useEffect(() => {
        if (!id)
            return;
        
        loadData();
    }, []);

    const onLrfNumberClicked = useCallback(() => {
        window.open(`/lrf/${itemInEdit?.recruitmentRequestId}`, "_blank");
    }, [itemInEdit]);

    const defaultColDef = useMemo(() => ({
        sortable: false,
        resizable: true,
        editable: false,
        filter: false,
        suppressMenu: true,
    }), []);

    const onDispatchGridReady = useCallback((params) => {
        const {api: gridApi} = params;

        gridApi.sizeColumnsToFit({
            defaultMinWidth: 100,
          });
    }, []);

    const dispatchColumnDefs = [
        {
            field: "positionName",
            headerName: "Position",
            valueFormatter: (params) => {
                const { data } = params;
                return data?.hrispositionMaster?.hrispositionMasterName ?? "N/A";
            },
        },
        {
            field: "projectName",
            headerName: "Project",
            valueFormatter: (params) => {
                const { data } = params;
                return data?.roster?.dispatchUnit.project.projectName ?? "N/A";
            },
        },
        {
            field: "dispatchUnitName",
            headerName: "Dispatch Unit",
            valueFormatter: (params) => {
                const { data } = params;
                return data?.roster?.dispatchUnit.dispatchUnitName ?? "N/A";
            },
        },
        {
            field: "startDate",
            headerName: "Start Date",
            valueFormatter: (params) => {
                const { data } = params;
                return data?.startDate ? moment(data.startDate).format("DD.MM.YYYY") : "N/A";
            },
        },
        {
            field: "endDate",
            headerName: "End Date",
            valueFormatter: (params) => {
                const { data } = params;
                return data?.endDate ? moment(data.endDate).format("DD.MM.YYYY") : "N/A";
            },
        },
    ];

    const onJobOrderGridReady = useCallback((params) => {
        const {api: gridApi} = params;

        gridApi.sizeColumnsToFit({
            defaultMinWidth: 150,
          });
    }, []);

    const jobOrderColumnDefs = [
        {
            field: "companyName",
            headerName: "Entity",
            valueFormatter: (params) => {
                const { data } = params;
                return data?.jobOrder?.desiredEntity ?? "N/A";
            },
        },
        {
            field: "projectName",
            headerName: "Last Primary Project",
            valueFormatter: (params) => {
                const { data } = params;
                return data?.jobOrder?.projectName ?? "N/A";
            },
        },
        {
            field: "positionName",
            headerName: "Position",
            valueFormatter: (params) => {
                const { data } = params;
                return data?.jobOrder?.positionName ?? "N/A";
            },
        },
        {
            field: "startDate",
            headerName: "Start Date",
            valueFormatter: (params) => {
                const { data } = params;
                return data?.startDate ? moment(data.startDate).format("DD.MM.YYYY") : "N/A";
            },
        },
        {
            field: "endDate",
            headerName: "End Date",
            valueFormatter: (params) => {
                const { data } = params;
                return data?.endDate ? moment(data.endDate).format("DD.MM.YYYY") : "N/A";
            },
        },
        {
            field: "isActive",
            headerName: "Active",
            valueFormatter: (params) => {
                const { data } = params;
                return data?.isActive ? "Yes" : "No";
            },
        },
    ];

    const documentPreview = useMemo(() => {
        if (!selectedCandidateDocumentId || !itemInEdit?.candidateId) {
            return [];
        }

        const uri = `api/candidate/documents-by-id/${selectedCandidateDocumentId}-${itemInEdit.candidateId}/`;
        return [ { uri } ];
    }, [selectedCandidateDocumentId, itemInEdit]);

    const downloadDocument = useCallback((id) => {
        setLoading(true);
        const url = `candidate/download-document/${id}`;
        api.get(url, { responseType: 'blob' })
            .then((response) => {
                const {data, headers} = response;
                const filename = headers["content-disposition"].split("; ")[1].split("=")[1].replaceAll('"', "");
                const fileURL = URL.createObjectURL(data);
                const anchorElement = document.createElement('a');
                anchorElement.style.display = 'none';
                anchorElement.href = fileURL;
                anchorElement.download = filename;
                anchorElement.click();
                URL.revokeObjectURL(url);
                setLoading(false);
            }).catch(error => {
                console.error(error);
                setLoading(false);
            })
        ;
    }, [candidate]);

    const setRequestApprovalStatus = useCallback((isApproved) => {
        setLoading(true);
        api.post(`vacancy/review/approval?id=${itemInEdit.jobOrderApplicationId}&isApproved=${isApproved}`)
            .then(() => {
                setLoading(false);
                history.replace(`/lrfreview`);
            })
            .catch((error) => {
                console.log({error});
                setLoading(false);
            })
        ;
    }, [itemInEdit, history]);

    return (
        <section className="main">
            {loading && <Loader />}
            <Container fluid>
                <Row>
                    <Col sm={12} className="border-bottom border-dark">
                        <span style={{fontSize: "1rem"}}><b>{itemInEdit?.recruitmentRequestNumber} - {itemInEdit?.candidateName} ({itemInEdit?.reviewRequestStatusName})</b></span>
                    </Col>
                </Row>
                <Row className="font-size-small-custom mt-2">
                    <Col sm={6}>
                        <Row>
                            <Col sm={4}>
                                LRF Number
                            </Col>
                            <Col sm={8}>
                                : <span className="cursor-pointer" onClick={() => onLrfNumberClicked()}>{itemInEdit?.recruitmentRequestNumber ?? "N/A"}<i className="fas fa-external-link-alt ml-1" /></span>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                Client
                            </Col>
                            <Col sm={8}>
                                : {itemInEdit?.clientName ?? "N/A"}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                Location
                            </Col>
                            <Col sm={8}>
                                : {itemInEdit?.locationName ?? "N/A"}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                Recruitment Type
                            </Col>
                            <Col sm={8}>
                                : {itemInEdit?.recruitmentTypeName ?? "N/A"}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                Urgent Request
                            </Col>
                            <Col sm={8}>
                                : {itemInEdit?.isUrgentRequest}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                Employed Position
                            </Col>
                            <Col sm={8}>
                                : {itemInEdit?.positionName ?? "N/A"}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                Employment Category
                            </Col>
                            <Col sm={8}>
                                : {itemInEdit?.employmentCategoryName ?? "N/A"}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={6}>
                        <Row>
                            <Col sm={4}>
                                Site / Project
                            </Col>
                            <Col sm={8}>
                                : {itemInEdit?.projectName ?? "N/A"}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                Onboarding Cost Code
                            </Col>
                            <Col sm={8}>
                                : {itemInEdit?.workorderNumber ?? "N/A"}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                Dispatch Unit
                            </Col>
                            <Col sm={8}>
                                : {itemInEdit?.dispatchUnitName ?? "N/A"}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                Roster Position
                            </Col>
                            <Col sm={8}>
                                : {itemInEdit?.hrispositionMasterName ?? "N/A"}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                Expected Start
                            </Col>
                            <Col sm={8}>
                                : {itemInEdit?.startDateExpected ? moment(itemInEdit.startDateExpected).format("DD-MM-YYYY") : "N/A"}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                Desired Entity
                            </Col>
                            <Col sm={8}>
                                : {itemInEdit?.companyName ?? "N/A"}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className="border-bottom border-dark mt-2">
                        <span style={{fontSize: "1rem"}}><b>Candidate Profile</b></span>
                    </Col>
                </Row>
                <Row className="font-size-small-custom my-2">
                    <Col sm={6}>
                        <Row>
                            <Col sm={4}>
                                Candidate Name
                            </Col>
                            <Col sm={8}>
                                : {itemInEdit?.candidateName ?? "N/A"}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                Gender
                            </Col>
                            <Col sm={8}>
                                : {itemInEdit?.gender ?? "N/A"}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                DOB
                            </Col>
                            <Col sm={8}>
                                : {itemInEdit?.doB ? moment(itemInEdit.doB).format("DD-MM-YYYY") : "N/A"}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                Performance Rating
                            </Col>
                            <Col sm={8}>
                                : {itemInEdit?.performanceRating ?? "N/A"}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={6}>
                        <Row>
                            <Col sm={4}>
                                Mobile Number
                            </Col>
                            <Col sm={8}>
                                : {itemInEdit?.mobilePhoneNumber ?? "N/A"}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                Email
                            </Col>
                            <Col sm={8}>
                                : {itemInEdit?.email ?? "N/A"}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                Address
                            </Col>
                            <Col sm={8}>
                                : {itemInEdit?.address ?? "N/A"}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                Residency Status
                            </Col>
                            <Col sm={8}>
                                : {itemInEdit?.residencyStatusName ?? "N/A"}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} className="bg-secondary mt-3">
                        <span><b>CV / Resume</b></span>
                    </Col>
                    <Col sm={12} className="mt-1 d-flex flex-wrap justify-content-center align-items-center">
                    {
                        candidate?.candidateDocuments.map((d, idx) => {
                            return (
                                <div key={`doc_${idx}`} className="w-100 mt-1">
                                    <span className="cursor-pointer border-bottom border-dark" onClick={() => setSelectedCandidateDocumentId(d.candidateDocumentId)}>{d.filename}</span><i className="fas fa-download ml-2 cursor-pointer" onClick={() => downloadDocument(d.candidateDocumentId)} />
                                </div>
                            );
                        })
                    }
                    </Col>
                    <Col sm={12} className="bg-secondary mt-3">
                        <span><b>Dispatch History</b></span>
                    </Col>
                    <Col sm={12} className="mt-2">
                        <div className="ag-theme-alpine ag-grid" style={{ width: '100%', height: '20vh' }}>
                            <AgGridReact
                                ref={dispatchGridRef}
                                columnDefs={dispatchColumnDefs}
                                defaultColDef={defaultColDef}
                                rowData={candidate?.rosterCandidates}
                                suppressColumnVirtualisation={false}
                                suppressRowVirtualisation={false}
                                rowBuffer={20}
                                debounceVerticalScrollbar={false}
                                allowContextMenuWithControlKey={true}
                                suppressContextMenu={true}
                                suppressClipboardPaste={true}
                                headerHeight={25}
                                groupHeaderHeight={22}
                                rowHeight={22}
                                readOnlyEdit={true}
                                enableRangeSelection={true}
                                tooltipShowDelay={0}
                                tooltipHideDelay={20000}
                                suppressDragLeaveHidesColumns={true}
                                suppressColumnMoveAnimation={true}
                                enableCellChangeFlash={true}
                                animateRows={true}
                                onGridReady={onDispatchGridReady}
                            />
                        </div>
                    </Col>
                    <Col sm={12} className="bg-secondary mt-3">
                        <span><b>Employment History</b></span>
                    </Col>
                    <Col sm={12} className="mt-2">
                        <div className="ag-theme-alpine ag-grid" style={{ width: '100%', height: '20vh' }}>
                            <AgGridReact
                                ref={jobOrderGridRef}
                                columnDefs={jobOrderColumnDefs}
                                defaultColDef={defaultColDef}
                                rowData={candidate?.jobOrderCandidates}
                                suppressColumnVirtualisation={false}
                                suppressRowVirtualisation={false}
                                rowBuffer={20}
                                debounceVerticalScrollbar={false}
                                allowContextMenuWithControlKey={true}
                                suppressContextMenu={true}
                                suppressClipboardPaste={true}
                                headerHeight={25}
                                groupHeaderHeight={22}
                                rowHeight={22}
                                readOnlyEdit={true}
                                enableRangeSelection={true}
                                tooltipShowDelay={0}
                                tooltipHideDelay={20000}
                                suppressDragLeaveHidesColumns={true}
                                suppressColumnMoveAnimation={true}
                                enableCellChangeFlash={true}
                                animateRows={true}
                                onGridReady={onJobOrderGridReady}
                            />
                        </div>
                    </Col>
                </Row>
            {
                itemInEdit?.reviewRequestStatusName === "Review Requested" &&
                <Row className="mb-2">
                    <Col sm={12} className="d-flex flex-wrap justify-content-center align-items-center">
                        <Button
                            color="default"
                            onClick={() => {
                                setRequestApprovalStatus(true);
                            }}
                            type="button"
                        >
                            Approve
                        </Button>
                        <Button
                            color="warning"
                            onClick={() => {
                                setRequestApprovalStatus(false);
                            }}
                            type="button"
                        >
                            Decline
                        </Button>
                    </Col>
                </Row>
            }
            </Container>
        {
            documentPreview.length ?
            <Modal
                isOpen={true}
                className="modal-lg"
                modalClassName="db-example-modal-lg"
            >
                <div
                    className="modal-header"
                >
                    <h5
                        className="modal-title"
                    >
                        CV Preview
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        onClick={() => setSelectedCandidateDocumentId(null)}
                        type="button"
                    >
                        <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                    </button>
                </div>
                <div
                    className="modal-body"
                >
                    <DocViewer
                        documents={documentPreview}
                        prefetchMethod="GET"
                        pluginRenderers={DocViewerRenderers}
                        config={{
                            header: { disableHeader: true },
                            noRenderer: {
                                overrideComponent: NoRenderer,
                            },
                        }}
                    />
                </div>
            </Modal>
            :
            null
            }
        </section>
    );
}
