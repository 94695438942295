import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import api from "../../../../services/api";
import TemplateDialog from "./TemplateDialog";
import { useBlockLayout, useFlexLayout, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import Grid from "../../../Grid";
import { Checkbox, RadioButton } from "@progress/kendo-react-inputs";

function InactiveDateDialog(props) {
    const {
        title,
        selectedCandidates,
        selectedCandidateId,
        initialSelectedDates,
        onClose,
        onBackClick,
        onNextClick,
        direction,
    } = props;

    const [ loading, setLoading ] = useState(false);
    const [ sourceData, setSourceData ] = useState([]);
    const [ data, setData ] = useState([]);
    const [ selectedDates, setSelectedDates ] = useState(initialSelectedDates ?? []);
    const skipPageResetRef = useRef();

    const loadData = useCallback(() => {
        skipPageResetRef.current = true;

        if (!selectedCandidates?.length)
            return;

        setLoading(true);

        api.post(`candidatemerge/compare/inactivedates`, JSON.stringify(selectedCandidates.map(c => c.candidateId)))
            .then((response) => {
                const {data: {data: responseData, maxRowCount}} = response;

                if (!responseData.length)
                {
                    setLoading(false);

                    if (direction === 1)
                        onNextClick([]);
                    else
                        onBackClick();

                    return;
                }

                const transposedData = [];

                for (let i = 0; i < maxRowCount; i++) {
                    const row = {};

                    responseData.forEach(d => {
                        if (responseData.filter(r => r.candidateId === d.candidateId).length - 1 < i)
                            return;

                        const currentData = responseData.filter(r => r.candidateId === d.candidateId)[i];
                        row[d.candidateId] = {
                            value: currentData.candidateInactiveDateId,
                            label: currentData.inactiveDateDescription,
                        };
                    });

                    if (!Object.keys(row).length)
                        continue;
                    
                    transposedData.push(row);
                }
                // console.log({transposedData});
                setData([...transposedData]);
                setSourceData([...transposedData]);
                setLoading(false);
            })
            .catch((error) => {
                console.log({error});
                setLoading(false);
            })
        ;
    }, [skipPageResetRef, api, selectedCandidateId, onNextClick, onBackClick, direction]);

    useEffect(() => loadData(), [selectedCandidates]);

    const defaultColumn = useMemo(() => ({
        disableFilters: true,
        disableSortBy: true,
    }), []);

    const columns = useMemo(() => {
        selectedCandidates.sort((a, b) => a.candidateId - selectedCandidateId !== 0 ? Math.abs(a.candidateId - b.candidateId) : -1);

        return [
            ...selectedCandidates.map(d => ({
                Header: () => {
                    const selectedCandidateDates = sourceData.filter(sd => sd[d.candidateId]).map(sd => sd[d.candidateId].value);
                    const selected = selectedCandidateDates.length && selectedCandidateDates.every(sd => selectedDates.includes(sd)) ? true : false;

                    return (
                        <div className="w-100 px-1 d-flex flex-column justify-content-start align-items-start">
                            <div className="w-100 d-flex flex-row justify-content-start align-items-start">
                            {
                                selectedCandidateId === d.candidateId &&
                                <RadioButton
                                    name="selectCandidate"
                                    value={d.candidateId}
                                    disabled={true}
                                    checked={d.candidateId === selectedCandidateId}
                                />
                            }
                                <span className={`text-left${selectedCandidateId === d.candidateId ? " ml-2" : ""}`}>{d.candidateName} ({d.candidateId})</span>
                            </div>
                            <div className="w-100 d-flex flex-row justify-content-start align-items-start mt-2">
                            {
                                selectedCandidateDates.length ?
                                <>
                                    <Checkbox
                                        onChange={(e) => {
                                            const {value} = e;

                                            if (!value)
                                            {
                                                setSelectedDates([...selectedDates.filter(sd => !selectedCandidateDates.includes(sd))]);
                                                return;
                                            }

                                            setSelectedDates([...selectedDates.filter(sd => !selectedCandidateDates.includes(sd)), ...selectedCandidateDates]);
                                        }}
                                        checked={selected}
                                    />
                                    <span className="text-left ml-2">Select All</span>
                                </>
                                :
                                null
                            }
                            </div>
                        </div>
                    );
                },
                accessor: `${d.candidateId}`,
                Cell: ({ row: { original } }) => {
                    return (
                        <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                        {
                            original[d.candidateId] ?
                            <>
                                <Checkbox
                                    onChange={(e) => {
                                        const {value} = e;

                                        if (!value)
                                        {
                                            setSelectedDates([...selectedDates.filter(sd => sd !== original[d.candidateId].value)]);
                                            return;
                                        }

                                        setSelectedDates([...selectedDates, original[d.candidateId].value]);
                                    }}
                                    checked={selectedDates.includes(original[d.candidateId].value)}
                                />
                                <span className="text-left ml-2">{original[d.candidateId].label}</span>
                            </>
                            :
                            <span className="text-left ml-2">-</span>
                        }
                        </div>
                    );
                },
                minWidth: 200,
                sticky: d.candidateId === selectedCandidateId ? "left" : null,
            }))
        ]
    }, [selectedCandidates, selectedCandidateId, sourceData, selectedDates]);

    // useEffect(() => console.log({selectedDates}), [selectedDates]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout,
        useBlockLayout,
        useSticky,
    );

    // useEffect(() => console.log({rows}), [rows]);

    const content = useMemo(() => {
        return (
            <>
                <Row className="m-0 p-0 w-100">
                    <Col lg={12} className="m-0 p-0 d-flex flex-wrap justify-content-start align-items-center">
                        <span className="font-size-small-custom">
                            <i className="fas fa-info-circle mr-1" />Please select the Inactive Dates to be merged.
                        </span>
                    </Col>
                </Row>
                <Row className="m-0 p-0 mt-3 w-100">
                    <Grid
                        height={'calc(100vh - 464px)'}
                        totalRecords={rows.length}
                        pageSize={rows.length}
                        rows={rows}
                        tableProps={getTableProps()}
                        headerGroups={headerGroups.map(h => {
                            const headerGroupProps = h.getHeaderGroupProps();
                            headerGroupProps.style = {...headerGroupProps.style, width: "100%"}

                            return {
                                ...h,
                                getHeaderGroupProps: () => headerGroupProps
                            };
                        })}
                        tableBodyProps={getTableBodyProps()}
                        prepareRow={(row) => {
                            prepareRow(row);
                            const rowProps = row.getRowProps();
                            row.getRowProps = () => {
                                return {
                                    ...rowProps,
                                    style: {
                                        ...rowProps.style,
                                        width: "100%",
                                    },
                                };
                            };

                            row.cells = row.cells.map(c => {
                                const columnId = parseInt(c.column.id);
                                const cellProps = c.getCellProps();
                                return {
                                    ...c,
                                    getCellProps: () => ({
                                        ...cellProps,
                                        style: {
                                            ...cellProps.style,
                                            backgroundColor: columnId === selectedCandidateId ? "#c2deff" : c.column.id === "title" ? "#fff" : "inherit",
                                        }
                                    })
                                };
                            });
                        }}
                        fullscreen={true}
                    />
                </Row>
            </>
        );
    }, [rows, getTableProps, headerGroups, getTableBodyProps, prepareRow, selectedCandidateId]);

    const footer = useMemo(() => (
        <>
            <Button
                color="secondary"
                onClick={onClose}
                type="button"
            >
                Close
            </Button>
            <Button
                color="info"
                onClick={() => onBackClick()}
                type="button"
            >
                <i className="fas fa-chevron-left mr-2" />Back: Data Review - Talent's Identifiers
            </Button>
            <Button
                color="info"
                onClick={() => onNextClick(selectedDates)}
                type="button"
            >
                Next: Data Review - Message Logs<i className="fas fa-chevron-right ml-2" />
            </Button>
        </>
    ), [onClose, onBackClick, onNextClick, selectedDates]);

    return (
        <TemplateDialog
            title={title}
            loading={loading}
            content={content}
            footer={footer}
            onClose={onClose}
        />
    );
};

export default InactiveDateDialog;
