import React from 'react';
import api from "../../../../services/api";
import qs from "qs";
import {
   Container, Row, Col, CardTitle
} from "reactstrap";
import TrainingEditor from "./Editor";
import SkillEditor from "./SkillEditor";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../../alerts";
import { DefaultColumnFilter } from "../../../react-table/filters";
import { useTable, useExpanded, useFilters, useSortBy, useFlexLayout, useAsyncDebounce } from 'react-table';
import Loader from "../../../loaders";
import moment from 'moment';
import { TooltipButton } from '../../../inputs';
import fileDownload from "js-file-download";
import Grid from "../../../Grid";

export default function List(props) {
    
    const [ data, setData ] = React.useState([]);
    const [ skillData, setSkillData ] = React.useState([]);

    const [ trainingProviders, setTrainingProviders ] = React.useState([]);
    const [ trainingCategories, setTrainingCategories ] = React.useState([]);
    const [ deliveryMethods, setDeliveryMethods ] = React.useState([]);
    const [ loading, setLoading ] = React.useState(false);
    const [ masterInEdit, setMasterInEdit ] = React.useState(null);
    const [ skillInEdit, setSkillInEdit ] = React.useState(null);

    const [ filters, setFilters ] = React.useState([]);
    const defaultSort = React.useMemo(() => [{ id: "skillName", desc: false }],
        []
    );    
    const [ sortBy, setSortBy ] = React.useState(defaultSort);
    const [ pageNumber, setPageNumber ] = React.useState(1);
    const [ pageSize, setPageSize ] = React.useState(10);
    const [ totalRecords, setTotalRecords ] = React.useState(0);
    const [ deleteAlert, setDeleteAlert ] = React.useState([]);

    const [ selected, setSelected ] = React.useState(null);

    const [ skillFilters, setSkillFilters ] = React.useState([]);
    const skillDefaultSort = React.useMemo(() => [{ id: "skillName", desc: false }],
        []
    );    
    const [ skillSortBy, setSkillSortBy ] = React.useState(defaultSort);
    const [ skillPageNumber, setSkillPageNumber ] = React.useState(1);
    const [ skillPageSize, setSkillPageSize ] = React.useState(10);
    const [ skillTotalRecords, setSkillTotalRecords ] = React.useState(0);

    const skipPageResetRef = React.useRef();
    const apiurl = "trainingmaster";

    const loadData = React.useCallback(() => {
        skipPageResetRef.current = true;
        const queryString = qs.stringify({filters, sortBy}, { allowDots: true });
        setMasterInEdit(null);
        setSkillInEdit(null);
        setData([]);
        setLoading(true);

        api.get(`/${apiurl}/${(pageNumber - 1) * pageSize}/${pageSize}${queryString ? `?${queryString}` : ""}`)
        .then(({data}) => {
            setData(data.data);
            setTotalRecords(data.total);
        }).catch((error) => {
            console.error("error: ", error);
        }).finally(() => setLoading(false));
    }, [filters, sortBy, pageNumber, pageSize]);

    // const loadSkillData = React.useCallback(() => {
    //     skipPageResetRef.current = true;
    //     const queryString = qs.stringify({filters, sortBy}, { allowDots: true });
    //     setMasterInEdit(null);
    //     setSkillInEdit(null);
    //     setData([]);
    //     setLoading(true);

    //     api.get(`/${apiurl}/skill/${(pageNumber - 1) * pageSize}/${pageSize}${queryString ? `?${queryString}` : ""}`)
    //     .then(({data}) => {
    //         setSkillData(data.data);
    //         setSkillTotalRecords(data.total);
    //     }).catch((error) => {
    //         console.error("error: ", error);
    //     }).finally(() => setLoading(false));
    // }, [filters, sortBy, pageNumber, pageSize]);
    
    const handleDelete = (item) => {
        const endpoint = item.trainingMasterSkillId > 0 ? `${apiurl}/skill/${item.trainingMasterSkillId}` :  `${apiurl}/${item.trainingMasterId}`;
        const success = () => {
            setDeleteAlert([<DeleteSuccessAlert onConfirm={() => setDeleteAlert([])} />]);
            if (data.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1);
            } else {
                loadData();
            }
        };
        const failure = () => setDeleteAlert([<DeleteFailedAlert onConfirm={() => setDeleteAlert([])} />]);
        const events = {
            onConfirm: () => api.delete(`/${endpoint}`).then(success).catch(failure),
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert {...events} />])
    }

    React.useEffect(() => {    
        skipPageResetRef.current = false;
        
        let apiCalls = [
            api.get("/lookup/trainingprovider"),
            api.get("/lookup/trainingcategory"),
            api.get("/lookup/deliverymethod"),
        ];

        Promise.all(apiCalls)
            .then((response) => {
                setTrainingProviders(response[0].data);
                setTrainingCategories(response[1].data);
                setDeliveryMethods(response[2].data);
            })
            .catch((error) => {
              console.error("error: ", error);
            })

    }, []);

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
                })
            },
        }),
        []
    )

    const columns = React.useMemo(() => {
        return [
            {
                id: 'expander',
                minWidth: 15,
                width: 15,
                maxWidth: 15,
                Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                  <span {...getToggleAllRowsExpandedProps()}>
                    {isAllRowsExpanded ? <i className="fas fa-minus mr-1"></i> : <i className="fas fa-plus mr-1"></i>}
                  </span>
                ),
                Cell: ({ row, ...rest }) =>
                {                       
                    return row.canExpand ? (
                        <span className="expander"
                            {...row.getToggleRowExpandedProps()}
                        >
                            {row.isExpanded ? <i className="fas fa-minus mr-1"></i> : <i className="fas fa-plus mr-1"></i>}
                        </span>
                    ) : row.depth > 0 ? <span className="expander pl-3"><i className={`fas fa-level-up-alt fa-rotate-90 mr-1`}></i></span> : null
                },
            },
            {
                Header: "Name",
                accessor: "skillName",
                Filter: DefaultColumnFilter,
                filter: "text",
                width: 250,
                disableSortBy: false,
            },
            // {
            //     Header: "Description",
            //     accessor: "skillDescription",
            //     Filter: DefaultColumnFilter,
            //     filter: "text",
            //     width: 160,
            //     disableSortBy: false,
            // },
            // {
            //     Header: "Category",
            //     accessor: "trainingCategory.lookupValue",
            //     Filter: DefaultColumnFilter,
            //     filter: "text",
            //     width: 160,
            //     disableSortBy: false,
            // },
            // {
            //     Header: "Sub Category",
            //     accessor: "trainingSubCategory.lookupValue",
            //     Filter: DefaultColumnFilter,
            //     filter: "text",
            //     width: 160,
            //     disableSortBy: false,
            // },
            {
                Header: "Active",
                accessor: (row) => {
                    return (row.active ?? true) ? "Yes" : "No"
                },
                Filter: DefaultColumnFilter,
                filter: "text",
                width: 60,
            },
            {
                Header: "Actions",
                id: 'button',
                Cell: ( { row: { original, depth, isGrouped } } ) =>
                    (
                        (original && !isGrouped) ? (
                        <div>
                             {depth === 0 && <TooltipButton
                                id={`add_${original.trainingMasterId}`}
                                title="Add Child"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={() => setSkillInEdit({ trainingMasterId: original.trainingMasterId, startDate: moment() })}
                            >
                                <i className="fas fa-plus pt-1"></i>
                            </TooltipButton>} 
                            <TooltipButton
                                id={`edit_${original.trainingMasterId}`}
                                title="Edit"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={() => depth === 0 ? setMasterInEdit({ ...original }) : setSkillInEdit({ ...original })}
                            >
                                <i className="fas fa-pencil-alt pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`delete_${original.trainingMasterId}`}
                                title="Delete"
                                className="btn-icon"
                                color="warning"
                                size="sm"
                                type="button"
                                onClick={() => handleDelete({ ...original })}
                            >
                                <i className="fas fa-trash-alt pt-1"></i>
                            </TooltipButton>
                        </div>
                        )
                        : (
                        <div></div>
                        )
                    ),
                width: 60,
            },
        ]
    }, []);

    const skillColumns = React.useMemo(() => {
        return [
            // {
            //     id: 'expander',
            //     minWidth: 15,
            //     width: 15,
            //     maxWidth: 15,
            //     Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
            //       <span {...getToggleAllRowsExpandedProps()}>
            //         {isAllRowsExpanded ? <i className="fas fa-minus mr-1"></i> : <i className="fas fa-plus mr-1"></i>}
            //       </span>
            //     ),
            //     Cell: ({ row, ...rest }) =>
            //     {                       
            //         return row.canExpand ? (
            //             <span className="expander"
            //                 {...row.getToggleRowExpandedProps()}
            //             >
            //                 {row.isExpanded ? <i className="fas fa-minus mr-1"></i> : <i className="fas fa-plus mr-1"></i>}
            //             </span>
            //         ) : row.depth > 0 ? <span className="expander pl-3"><i className={`fas fa-level-up-alt fa-rotate-90 mr-1`}></i></span> : null
            //     },
            // },
            {
                Header: "Name",
                accessor: "skillName",
                Filter: DefaultColumnFilter,
                filter: "text",
                width: 250,
                disableSortBy: false,
            },
            // {
            //     Header: "Description",
            //     accessor: "skillDescription",
            //     Filter: DefaultColumnFilter,
            //     filter: "text",
            //     width: 160,
            //     disableSortBy: false,
            // },
            // {
            //     Header: "Category",
            //     accessor: "trainingCategory.lookupValue",
            //     Filter: DefaultColumnFilter,
            //     filter: "text",
            //     width: 160,
            //     disableSortBy: false,
            // },
            // {
            //     Header: "Sub Category",
            //     accessor: "trainingSubCategory.lookupValue",
            //     Filter: DefaultColumnFilter,
            //     filter: "text",
            //     width: 160,
            //     disableSortBy: false,
            // },
            {
                Header: "Active",
                accessor: (row) => {
                    return (row.active ?? true) ? "Yes" : "No"
                },
                Filter: DefaultColumnFilter,
                filter: "text",
                width: 60,
            },
            {
                Header: "Actions",
                id: 'button',
                Cell: ( { row: { original, depth, isGrouped } } ) =>
                    (
                        (original && !isGrouped) ? (
                        <div>
                            {/* {depth === 0 && <TooltipButton
                                id={`add_${original.trainingMasterId}`}
                                title="Add Child"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={() => setSkillInEdit({ trainingMasterId: original.trainingMasterId, startDate: moment() })}
                            >
                                <i className="fas fa-plus pt-1"></i>
                            </TooltipButton>} */}
                            <TooltipButton
                                id={`edit_${original.trainingMasterId}`}
                                title="Edit"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={() => depth === 0 ? setMasterInEdit({ ...original }) : setSkillInEdit({ ...original })}
                            >
                                <i className="fas fa-pencil-alt pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`delete_${original.trainingMasterId}`}
                                title="Delete"
                                className="btn-icon"
                                color="warning"
                                size="sm"
                                type="button"
                                onClick={() => handleDelete({ ...original })}
                            >
                                <i className="fas fa-trash-alt pt-1"></i>
                            </TooltipButton>
                        </div>
                        )
                        : (
                        <div></div>
                        )
                    ),
                width: 60,
            },
        ]
    }, []);

    const defaultColumn = React.useMemo(
        () => ({
            width: 150,
            Filter: DefaultColumnFilter,
            filter: "text",
            disableSortBy: true,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: defaultSort,
                canSort: true,
            },
            filterTypes,
            defaultColumn,
            manualSortBy: true,
            manualFilters: true,
            getSubRows: row => row.skills,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
        useExpanded
    );
    const setFiltersDebounced = useAsyncDebounce(setFilters, 200);

    // React.useEffect(() => {
    //     loadData();
    // }, [pageNumber, pageSize, filters, sortBy]);
    
    // React.useEffect(() => {
    //     setFilters(state.filters);
    // }, [state.filters]);
    
    // React.useEffect(() => {
    //     setSortBy(state.sortBy);
    // }, [state.sortBy]);



    React.useEffect(() => {
        setFiltersDebounced(state.filters); 
    }, [state.filters]);

    React.useEffect(() => {
        setSortBy(state.sortBy); 
    }, [state.sortBy]);

    React.useEffect(() => {
        loadData()        
    }, [pageNumber, pageSize, filters, sortBy]);

    return (
        <>
            {deleteAlert.length > 0 && deleteAlert}
            {loading && <Loader />}
        
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <CardTitle>
                            <h4 className="text-center">Training Master List</h4>
                        </CardTitle>
                        <TooltipButton
                            id="addnew"
                            title="Add New"
                            className="btn-icon ml-2 mb-2"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => {  
                                setMasterInEdit({});
                            }}
                        >
                            <i className="fas fa-plus pt-1"></i>
                        </TooltipButton>
                        <TooltipButton
                            id="export"
                            title="Export to Excel"
                            className="btn-icon ml-2 mb-2"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => {
                                const queryString = qs.stringify({filters: [...filters]}, { allowDots: true });

                                setLoading(true);
                                let url = `${apiurl}/export${queryString ? `?${queryString}` : ""}`;
                                api.get(url, { responseType: 'blob' })
                                .then(blob => {                
                                    console.log("Download: ", blob);
                                    fileDownload(blob.data, "training.xlsx");
                                }).catch(error => {
                                    console.error(error)
                                }).finally(() => setLoading(false))

                            }}
                        >
                            <i className="fas fa-file-excel"></i> Export
                        </TooltipButton>
                        <Grid
                            height='calc(100vh - 460px)'
                            totalRecords={totalRecords}
                            pageSize={pageSize}
                            rows={rows}
                            tableProps={getTableProps()}
                            headerGroups={headerGroups}
                            tableBodyProps={getTableBodyProps()}
                            prepareRow={prepareRow}
                            onRowClick={(row) => setSelected(row.original)}
                            onPagerChangePage={(pager) => {
                                setPageNumber(pager.currentPage);
                            }}
                            onPagerChangePageSize={(size) => {
                                setPageSize(size);
                            }}
                            needPaging={true}
                        />

                        {
                            !!masterInEdit && 
                            <TrainingEditor 
                                item={masterInEdit} 
                                onClose={() => setMasterInEdit(null)} 
                                onSaved={() => loadData()}
                            />
                        }
                        {
                            !!skillInEdit && 
                            <SkillEditor 
                                item={skillInEdit} 
                                onClose={() => setSkillInEdit(null)} 
                                onSaved={() => loadData()}
                                lookups={{
                                    trainingProviders,
                                    trainingCategories,
                                    deliveryMethods
                                }}
                            />
                        }
                    </Col>
                    {/* <Col md={6}>

                    </Col> */}
                </Row>
            </Container>
        </>
    )

}