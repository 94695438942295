import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { TooltipButton } from '../../inputs';
import Loader from '../../loaders';
import qs from "qs";
import api from "../../../services/api";
import moment from "moment";
import { useHistory } from 'react-router-dom';
import { IndeterminateCheckbox } from '../../react-table/controls';
import CandidateDocumentViewer from './JobOrder/CandidateDocumentViewer';

const getReviewData = (showAll) => {
    return {
        getRows: (params) => {
            params.api.showLoadingOverlay();
            const query = {
                sortBy: params.request.sortModel.map(m => ({id: m.colId, desc: m.sort == 'desc'})),
                filters: Object.entries(params.request.filterModel).map(([id, value]) => ({id, value: value.filterType === "date" ? moment(value.dateFrom).format("YYYY-MM-DD") : value.filter})),
                showAll
            }
            const queryString = qs.stringify(query, { allowDots: true });
            
            api.get(`/vacancy/review/request/${params.request.startRow}/${params.request.endRow - params.request.startRow}?${queryString}`)
                .then((response) => {
                    params.success({
                        rowData: response.data.requests,
                        rowCount: response.data.total,
                    });
                })
                .catch((error) => {
                    console.log(error.response);
                    params.fail();
                }).finally(() => {
                    params.api.hideOverlay();
                })
            ;
        },
    };
};

export default function ReviewRequestList(props) {
    const history = useHistory();
    const gridRef = useRef();
    const [pageSize, setPageSize] = useState(50);
    const [agGridSort, setAgGridSort] = useState(['asc', 'desc']);
    const [showAll, setShowAll] = useState(false);
    const [ candidateIdForCvPreview, setCandidateIdForCvPreview ] = useState(null);

    const loadingOverlayComponent = useMemo(() => Loader, []);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        //filter: 'agSetColumnFilter',
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['reset'],
            debounceMs: 200,
            excelMode: 'mac',
        },
        floatingFilter: true,
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        suppressMenu: true,
    }), []);

    const columnDefs = [
        {
            field: "recruitmentRequestNumber",
            headerName: "LRF",
            width: 100,
            pinned: 'left',
        },
        {
            field: "jobOrderNumber",
            headerName: "Job Order ID",
            width: 100,
            pinned: 'left',
        },
        {
            field: "createdDate",
            headerName: "Created Date",
            valueFormatter: (params) => {
                var { value } = params;
                return value ? moment(value).format("DD.MM.YYYY") : "N/A";
            },
            width: 150,
            filter: 'agDateColumnFilter',
            sortable: false,
        },
        {
            field: "clientName",
            headerName: "Client",
        },
        {
            field: "projectName",
            headerName: "Site / Project",
        },
        {
            field: "locationName",
            headerName: "Location",
        },
        {
            field: "positionName",
            headerName: "Position",
        },
        {
            field: "candidateName",
            headerName: "Candidate Name",
        },
        {
            field: 'cv',
            headerName: "CV / Resume",
            minWidth: 120,
            cellRenderer: (props) => {
                const {data} = props;
                const isHavingCv = data.candidateDocumentIsHavingCv;

                return (
                    <div style={{ width: "100%" }} className={`d-flex flex-wrap justify-content-center align-items-center`}>
                    {
                        isHavingCv &&
                        <a className="text-info" href='javascript:;' onClick={() => { setCandidateIdForCvPreview(data.candidateId); }}><span className='fas fa-check-circle text-success'></span> View CV / Resume</a>
                    }
                    </div>
                )
            },
        },
        {
            field: "reviewRequestDate",
            headerName: "Review Request Date",
            valueFormatter: (params) => {
                var { value } = params;
                return value ? moment(value).format("DD.MM.YYYY") : "N/A";
            },
            filter: 'agDateColumnFilter',
            sortable: false,
        },
        {
            field: "reviewRequestStatusName",
            headerName: "Status",
        },
        {
            field: "button",
            headerName: "Action",
            pinned: 'right',
            filter: '',

            filterParams: {
                name: 'action'
            },
            sortable: false,
            floatingFilter: false,
            cellClass: "action-button-cell",
            cellRenderer: (props) => {
                const {data} = props;

                return (
                    <div key={`action_container_${data.jobOrderApplicationId}`} className="text-center">
                        <TooltipButton
                            id={`request_${data.jobOrderApplicationId}`}
                            title={data.reviewRequestStatusName !== "Review Requested" ? "Show Detail Request" : "Update Approval Status"}
                            className="btn-icon"
                            color="default"
                            size="xss"
                            type="button"
                            onClick={(e) => {
                                history.push(`/lrfreviewdetail/${data.jobOrderApplicationId}`);
                            }}
                        >
                            <i className={`fas fa-xs fa-${data.reviewRequestStatusName !== "Review Requested" ? "search" : "pencil-alt"}`}></i>
                        </TooltipButton>
                    </div>
                )
            },
        },
    ];

    const onGridReady = useCallback((params) => {
        const datasource = getReviewData(showAll);
        params.api.setServerSideDatasource(datasource);

        params.api.sizeColumnsToFit({
            defaultMinWidth: 100,
            columnLimits: [
                { key: 'clientName', minWidth: 300 },
                { key: 'reviewRequestStatusName', minWidth: 150 },
                { key: 'projectName', minWidth: 300 },
                { key: 'locationName', minWidth: 200 },
                { key: 'positionName', minWidth: 250 },
                { key: 'candidateName', minWidth: 300 },
                { key: 'reviewRequestDate', minWidth: 150 },
            ],
          });
    }, [showAll]);

    useEffect(() => {
        if (!gridRef?.current?.api)
            return;

        onGridReady(gridRef.current);
    }, [showAll]);

    return (
        <section className="main">
            <Container fluid>
                <Row>
                    <Col sm={12} className="d-flex flex-wrap justify-content-end align-items-center">
                        <IndeterminateCheckbox id="show-all" checked={showAll} onClick={(e) => setShowAll(e.target.checked)} customTitle="Show All" />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <div className="ag-theme-alpine ag-grid" style={{ width: '100%', height: 'calc(100vh - 200px)' }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                loadingOverlayComponent={loadingOverlayComponent}
                                suppressColumnVirtualisation={false}
                                suppressRowVirtualisation={false}
                                debounceVerticalScrollbar={false}
                                rowBuffer={20}
                                headerHeight={30}
                                groupHeaderHeight={30}
                                rowHeight={30}
                                floatingFiltersHeight={30}
                                suppressDragLeaveHidesColumns={true}
                                suppressColumnMoveAnimation={true}
                                animateRows={false}
                                pagination={true}
                                paginationPageSize={pageSize}
                                cacheBlockSize={pageSize}
                                rowModelType={'serverSide'}
                                serverSideInfiniteScroll={true}
                                onGridReady={onGridReady}
                                sortingOrder={agGridSort}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        {
            candidateIdForCvPreview &&
            <CandidateDocumentViewer
                onClose={() => setCandidateIdForCvPreview(null)}
                candidateId={candidateIdForCvPreview}
            />
        }
        </section>
    );
}
