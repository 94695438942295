import React from 'react';
import PropTypes from "prop-types";
import {
    Button, UncontrolledTooltip
 } from "reactstrap";

function TooltipButton(props) {
    const { title, id, delay, ...rest } = props;
    return <>
        <Button
            id={id}
            {...rest}
        >
            {props.children}
        </Button>
        <UncontrolledTooltip delay={delay ?? 0} placement="top" target={id}>
            {title}
        </UncontrolledTooltip>
    </>;
}
TooltipButton.propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
}

export default TooltipButton;