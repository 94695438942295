import React, { useState, useEffect } from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import { ListBox } from '@progress/kendo-react-listbox';
import { Input, Label, FormGroup, Button, Modal, Container, Row, Col } from "reactstrap";

import api from '../../../../services/api';
import Loader from "../../../loaders";
import { useMemo } from 'react';
import { HexToBytes, BytesToDataUri, Base64ToBytes } from '../../../../helpers/DataHelper';
import { GetExtension, ToFilenameString, ShortenWords } from '../../../../helpers/StringHelper';
import fileDownload from "js-file-download";

const NoRenderer = ({ document, fileName }) => {
    const fileText = fileName || document?.fileType || "";

    if (document?.fileType && document.fileType.includes("problem")) {
        return (<Row style={{ width: '100%' }}>
            <Col xs={12}>
                <div className="alert alert-danger">File not found.</div>
            </Col>
        </Row>);
    }

    if (fileText) {
        return (<Row style={{ width: '100%' }}>
            <Col xs={12}>
                <div className="alert alert-danger">No Renderer Error.</div>
            </Col>
        </Row>);
    }

    return (<Row style={{ width: '100%' }}>
        <Col xs={12}>
            <div className="alert alert-danger">File not found.</div>
        </Col>
    </Row>);
};


export default function CandidateDocumentViewer(props) {
    const {
        onClose,
        candidateId,
        title = "Document Viewer"
    } = props;

    const ListBoxItem = (props) => {
        let { dataItem, selected, onClick, ...others } = props;
        return (
            <li {...others} onClick={onClick}>
                {/*<span>{dataItem.filename}</span>*/}
                <Row style={{ width: "100%" }}>
                    <Col xs="10">{dataItem.filename}</Col>
                    <Col xs="2 text-right"><u className="text-info cursor-pointer" onClick={() => { downloadDocument(dataItem); }}><i class="fas fa-download"></i></u></Col>
                </Row>
            </li>
        );
    };
    const [documentData, setDocumentData] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [downloadingDocument, setDownloadingDocument] = useState(null);
    const [loading, setLoading] = useState(true);

    const dataUri = useMemo(() => {
        if (!selectedDocument?.fileType || !selectedDocument?.fileContent) {
            return null;
        }

        const byteArray = Base64ToBytes(selectedDocument.fileContent);
        const dataUri = BytesToDataUri(byteArray, selectedDocument.fileType);
        return dataUri;

    }, [selectedDocument]);

    const docs = useMemo(() => {
        if (!selectedDocument?.candidateDocumentId) {
            return [];
        }

        var uri1 = `api/candidate/documents-by-id/${selectedDocument.candidateDocumentId}-${selectedDocument.candidateId}/`;
        var docs = [
            { uri: uri1 }
        ];

        return docs;
    }, [selectedDocument]);


    // Update selected document when user clicks on a document in the list
    useEffect(() => {
        if (!documentData?.length || !selectedDocument)
            return;

        var newDocumentData = documentData.map((m) => ({ ...m, selected: false }));
        var selected = newDocumentData.find(x => x.candidateDocumentId == selectedDocument?.candidateDocumentId);
        selected.selected = true;
        setDocumentData(newDocumentData);
    }, [selectedDocument]);



    useEffect(() => {

        //console.log('downloadingDocument', downloadingDocument);

        if (!downloadingDocument) {
            return;
        }

        setLoading(true);
        var url = `candidate/documents-by-id/${downloadingDocument.candidateDocumentId}-${downloadingDocument.candidateId}/download/`;
        var ext = GetExtension(downloadingDocument.filename);
        var id = downloadingDocument.candidateDocumentId;
        var candidateFileName = ShortenWords(ToFilenameString(downloadingDocument.candidate.candidateName), 6);
        var filename = `${candidateFileName}-CV-${id}.${ext}`;


        api.get(url, { responseType: 'blob' })
            .then(blob => {
                // console.log(blob);
                //var filename = /(?<=attachment;.*filename=").*(?=";)/.exec(blob.headers["content-disposition"])[0];
                fileDownload(blob.data, filename);
            }).catch(error => {
                console.error(error);
                //setErrorMessage(`${errorMessageOpening} ${error.message} `);
                //setLoading(false);
            }).finally(() => setLoading(false))

        setDownloadingDocument(null);

    }, [downloadingDocument])

    useEffect(() => {
        // Fetch document data when component mounts
        fetchDocumentData(candidateId);
    }, [candidateId]);

    useEffect(() => {

        if (!documentData?.length) {
            return;
        }
        // Select the first document by default
        if (!selectedDocument) {
            const firstDocument = documentData[0];
            selectDocument(firstDocument);
        }
    }, [documentData])

    async function fetchDocumentData(id) {
        // This should be replaced with your actual API call to get the document data
        // Here's a placeholder example

        setLoading(true);
        api.get(`candidate/documents-by-candidate/${id}`)
            .then((response) => {

                // Add a selected property to each document
                var data = response.data.map(m => ({
                    ...m,
                    selected: false
                }));

                // Select the first document by default
                if (data?.length) {
                    data[0].selected = true;
                }

                // Set the document data
                setDocumentData(data);
                setLoading(false);
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));



    }

    function downloadDocument(doc) {
        setDownloadingDocument(doc);
    }

    function selectDocument(doc) {
        setSelectedDocument(doc);
    }

    async function fetchFileContent(doc) {
        setLoading(true);
        api.get(`candidate/documents-by-id/${doc.candidateDocumentId}/`)
            .then((response) => {
                var data = response.data;
                var fileContent = data.fileContent;

                // Create a copy of the doc object with the new fileContent
                const updatedDoc = { ...doc, fileContent };

                setSelectedDocument(updatedDoc);

                // Filter the existing object from documentData
                const updatedDocumentData = documentData.filter((item) => item.candidateDocumentId !== doc.candidateDocumentId);
                updatedDocumentData.push(updatedDoc);
                setDocumentData(updatedDocumentData); // you forgot to set the new data

                setLoading(false);
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }

    const MyHeader: IHeaderOverride = (state, previousDocument, nextDocument) => {
        return null;
    }

    return (
        <Modal isOpen={true} className={'modal-xl m-4'} modalClassName="modal-fullwidth">
            <div className="modal-header">
                <h5 className="modal-title">{title}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div className="modal-body">
                {(loading) && <Loader />}
                <Row>
                    <Col xs={12} sm={4}>
                        <ListBox
                            style={{
                                width: "100%",
                                minHeight: "100%",
                                maxHeight: 401,
                                overflowY: "scroll",
                                paddingBottom: "1rem",
                            }}
                            data={documentData}
                            textField="filename"
                            valueField='candidateDocumentId'
                            selectedField='selected'
                            onItemClick={(e) => {
                                console.log('onItemClick', 'e', e);
                                var { dataItem } = e;
                                selectDocument(dataItem);
                            }}
                            onDownload={(e) => {
                                var { dataItem } = e;
                                downloadDocument(dataItem);
                            }}
                            item={ListBoxItem}
                        />
                    </Col>
                    <Col xs={12} sm={8} style={{maxHeight:'calc(80vh)'} }>
                        {!!docs?.length && (
                            <DocViewer
                                documents={docs}
                                prefetchMethod="GET"
                                pluginRenderers={DocViewerRenderers}
                                config={{
                                    header: { disableHeader: true },
                                    noRenderer: {
                                        overrideComponent: NoRenderer,
                                    },
                                }}
                            />
                        )}
                    </Col>
                </Row>

            </div>
        </Modal>
    );
}
