import React, { useCallback, useEffect, useState } from 'react';
import {
    Label, FormGroup, Row, Col, Collapse, Input
} from "reactstrap";
import { ComboBox, DropdownBox } from "../../../dropdowns";
import { DatePicker } from "../../../date-pickers";
import { formatDate } from "../../../../utils";
import moment from "moment";
import { useTable, useFlexLayout, useFilters, useSortBy } from 'react-table';
import { DefaultColumnFilter, DateColumnFilter } from "../../../react-table/filters";
import api from "../../../../services/api";
import _ from "lodash";
import { TooltipButton } from '../../../inputs';
import Grid from "../../../Grid";
import { Mode } from '../../../../utils';
import Loader from '../../../loaders';

export default function SiteAccess(props) {
    // console.log('ProjectAttributes', props)
    const {
        itemInEdit,
        setItemInEdit
    } = props;

    const [drawerMode, setDrawerMode] = React.useState(Mode.NONE);
    const [siteAccess, setSiteAccess] = React.useState(null);
    const [newData, setNewData] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [lastProjectId, setLastProjectId] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [locationList, setLocationList] = useState([]);
    const [candidateId, setCandidateId] = useState(0);
    const [data, setData] = useState([]);
    const [deleteItem, setDeleteItem] = useState(null);
    const [ loading, setLoading ] = useState(false);

    const skipPageResetRef = React.useRef();

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 150,
            maxWidth: 200,
        }), []);

    useEffect(() => {
        // console.log("props", props);
        if (props.project) {
            console.log("props.project", props.project);
            setDrawerMode(Mode.ADD);
            setSelectedProject({
                lookupId: props.project.projectId,
                lookupValue: props.project.projectName,
            })
        }
    }, []);

    const refreshData = useCallback(() => {
        setData([]);

        if (!candidateId)
            return;
        
        setLoading(true);
        skipPageResetRef.current = true;
        api.get(`candidate/siteaccess/${candidateId}`)
            .then(response => {
                setData(response.data);
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
        ;
    }, [candidateId]);

    useEffect(() => {
        if (candidateId === (itemInEdit?.candidateId ?? 0))
            return;

        setCandidateId(itemInEdit?.candidateId ?? 0);
    }, [itemInEdit?.candidateId]);

    useEffect(() => {
        refreshData();
    }, [candidateId]);

    useEffect(() => {

        if (!itemInEdit || !itemInEdit.candidateId || !newData)
            return;

        skipPageResetRef.current = true;

        //setLoading(true);
        console.log("drawerMode", drawerMode);
        console.log('siteAccess', siteAccess)
        if (siteAccess && siteAccess.candidateSiteAccessId) {
            api.put(`CandidateSiteAccess/${siteAccess.candidateSiteAccessId}`, siteAccess)
                .then(response => {
                    //console.log("update data success", response);
                    refreshData();
                })
                .catch(error => console.log(error))
                .finally(() => { }//setLoading(false)
                );
        }
        else {
            api.post(`CandidateSiteAccess`, siteAccess)
                .then(response => {
                    //console.log("insert data success", response);
                    refreshData();
                })
                .catch(error => console.log(error))
                .finally(() => { }//setLoading(false)
                );
        }
        setNewData(null);
    }, [newData]);

    useEffect(() => {
        if (!deleteItem || !deleteItem.candidateSiteAccessId)
            return;

        api.delete(`CandidateSiteAccess/${deleteItem.candidateSiteAccessId}`)
            .then(response => {
                //console.log("delete data success", response);
                refreshData();
            })
            .catch(error => console.log(error))
            .finally(() => { }//setLoading(false)
            );

    }, [deleteItem]);

    useEffect(() => {
        if (!siteAccess)
            return;

        // console.log({siteAccess});
        if (!selectedProject)
            setSelectedProject(siteAccess?.project);
    }, [siteAccess]);

    useEffect(() => {

        // Prevent error and double trigger
        // console.log("lastProjectId", lastProjectId);
        // console.log("selectedProject", selectedProject);
        if (!selectedProject || !selectedProject.projectId ||
            lastProjectId == selectedProject.projectId) {
            return;
        }

        setLastProjectId(selectedProject.projectId);

        api.get(`/location/search`)
            .then((response) => {
                // console.log(response.data);
                setLocationList(response.data);
            })
            .catch((error) => {
                console.error(error);
            })

        siteAccess.projectId = selectedProject.projectId;
    }, [selectedProject]);

    useEffect(() => {
        if (drawerMode === Mode.NONE)
            setSiteAccess(null);
    }, [drawerMode]);

    const columns = React.useMemo(() => {
        return [
            {
                Header: "Project",
                id: "projectName",
                accessor: (row) => row.project ? row.project.projectName : "",
                Filter: DefaultColumnFilter,
                filter: "text",
            },
            {
                Header: "Location",
                id: "locationName",
                accessor: (row) => row.location ? row.location.locationName : "",
                Filter: DefaultColumnFilter,
                filter: "text",
            },
            {
                Header: "Start Date",
                id: "startDate",
                accessor: (row) => row.startDate ? moment(row.startDate.slice(0, 10)).format(`DD.MM.YYYY`) : null,
                Filter: (e) => DateColumnFilter({ ...e, dateFormat: "DD.MM.YYYY", dateMask: "99.99.9999" }),
                filter: "text",
                width: 100,
            },
            {
                Header: "End Date",
                id: "endDate",
                accessor: (row) => row.endDate ? moment(row.endDate.slice(0, 10)).format(`DD.MM.YYYY`) : null,
                Filter: (e) => DateColumnFilter({ ...e, dateFormat: "DD.MM.YYYY", dateMask: "99.99.9999" }),
                filter: "text",
                width: 100,
            },
            {
                Header: "Pass Name",
                id: "passName",
                accessor: (row) => row.passName,
                Filter: DefaultColumnFilter,
                filter: "text",
            },
            {
                Header: "Pass ID",
                id: "passID",
                accessor: (row) => row.passId,
                Filter: DefaultColumnFilter,
                filter: "text",
            },
            {
                id: 'buttons',
                Header: "Action",
                width: 180,
                canResize: false,
                Cell: ({ row: { original, isGrouped } }) => (
                    (original && !isGrouped) ?
                        (
                            <div className="td-action text-center">
                                <TooltipButton
                                    id={`edit_${original.candidateSiteAccessId}`}
                                    title="Edit"
                                    className="btn-icon"
                                    color="default"
                                    size="xsm"
                                    type="button"
                                    onClick={() => {
                                        // console.log({original});
                                        setSiteAccess({ ...original })
                                        setDrawerMode(Mode.EDIT);
                                    }}
                                >
                                    <i className="fas fa-pencil-alt pt-1"></i>
                                </TooltipButton>
                                <TooltipButton
                                    id={`delete_${original.candidateSiteAccessId}`}
                                    title="Delete"
                                    className="btn-icon"
                                    color="warning"
                                    size="xsm"
                                    type="button"
                                    onClick={() => {
                                        setDeleteItem({ ...original })
                                    }}
                                >
                                    <i className="fas fa-trash-alt pt-1"></i>
                                </TooltipButton>
                            </div>
                        ) :
                        (
                            <div className="td td-action"></div>
                        )
                )
            }
        ]
    }, [data]);

    const filterTypes = React.useMemo(() => ({
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
            })
        },
    }), [data]);

    const defaultSort = React.useMemo(() => [{ id: "projectName", desc: false }],
        []
    );
    const defaultFilter = React.useMemo(() => props.project ? [{ id: "projectName", value: props.project.projectName }] : [],
        []
    );
    // console.log('defaultFilter', defaultFilter)
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                "hiddenColumns": [],
                sortBy: defaultSort,
                canSort: true,
                filters: defaultFilter
            },
            defaultColumn,
            // manualSortBy: true,
            filterTypes,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        // useRowSelect,
        useFlexLayout,
        useFilters,
        useSortBy,
    );

    return (<>
        {loading && <Loader />}
        <Collapse
            isOpen={drawerMode !== Mode.NONE}
            style={{ zIndex: "9999", border: "0.0625rem solid #dee2e6" }}
            className="bg-white w-100 rounded p-3 mb-2"
        >
            <Row>
                <Col xs={12}>
                    <Row>
                        <Col xs={3}>
                            <FormGroup>
                                <Label>
                                    Project
                                </Label>
                                <ComboBox
                                    endpoint="/project/listall"
                                    isLookup={false}
                                    idField="projectId"
                                    valueField="projectName"
                                    selectedItem={selectedProject}
                                    onChange={(selectedItem) => {
                                        setSelectedProject(selectedItem);
                                        setSiteAccess({
                                            ...siteAccess,
                                            project: { ...selectedProject },
                                            projectId: selectedProject?.projectId,
                                            //location: selectedItem,
                                            //locationId: selectedItem?.locationId
                                        });
                                    }}
                                    minLength={0}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <Label>
                                    Location
                                </Label>
                                {/* <DropdownBox
                                    data={locationList}
                                    // disabled={!selectedProject}
                                    isLookup={false}
                                    idField="locationId"
                                    valueField="locationName"
                                    selectedItem={siteAccess?.location}
                                    onChange={(selectedItem) => setSiteAccess({
                                        ...siteAccess,
                                        //project: selectedProject,
                                        //projectId: selectedProject?.projectId,
                                        location: { ...selectedItem },
                                        locationId: selectedItem?.locationId
                                    })}
                                /> */}
                                <ComboBox
                                    endpoint="/location/search"
                                    isLookup={false}
                                    idField="locationId"
                                    valueField="locationName"
                                    selectedItem={siteAccess?.location}
                                    onChange={(selectedItem) => setSiteAccess({
                                        ...siteAccess,
                                        location: { ...selectedItem },
                                        locationId: selectedItem?.locationId
                                    })}
                                    minLength={0}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <Label>
                                    Start Date
                                </Label>
                                <DatePicker
                                    name="startDate"
                                    id="startDate"
                                    type="text"
                                    required
                                    value={formatDate(siteAccess?.startDate)}
                                    onChange={value => {
                                        setSiteAccess({
                                            ...siteAccess,
                                            startDate: !value ? null : moment(value).format("YYYY-MM-DDT00:00:00"),
                                        });
                                    }}
                                    closeOnSelect
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <Label>
                                    End Date
                                </Label>
                                <DatePicker
                                    name="endDate"
                                    id="endDate"
                                    type="text"
                                    required
                                    value={formatDate(siteAccess?.endDate)}
                                    onChange={value => {
                                        setSiteAccess({
                                            ...siteAccess,
                                            endDate: !value ? null : moment(value).format("YYYY-MM-DDT00:00:00"),
                                        });
                                    }}
                                    closeOnSelect
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={4}>
                            <FormGroup>
                                <Label>
                                    Pass Name
                                </Label>
                                <Input
                                    name="passName"
                                    id="passName"
                                    type="text"
                                    required
                                    value={siteAccess?.passName ?? ""}
                                    onChange={(e) => setSiteAccess({ ...siteAccess, passName: e.target.value })}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={4}>
                            <FormGroup>
                                <Label>
                                    Pass ID
                                </Label>
                                <Input
                                    name="passId"
                                    id="passId"
                                    type="text"
                                    required
                                    value={siteAccess?.passId ?? ""}
                                    onChange={(e) => setSiteAccess({ ...siteAccess, passId: e.target.value })}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={4}>
                            <FormGroup>
                                <Label>
                                    Notes
                                </Label>
                                <Input
                                    name="notes"
                                    id="notes"
                                    type="text"
                                    required
                                    value={siteAccess?.notes ?? ""}
                                    onChange={(e) => setSiteAccess({ ...siteAccess, notes: e.target.value })}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-end">
                    <TooltipButton
                        id="cancel"
                        title="Cancel"
                        className="btn-icon"
                        color="warning"
                        size="sm"
                        type="button"
                        onClick={() => {
                            setDrawerMode(Mode.NONE);
                        }}
                    >
                        Cancel
                    </TooltipButton>
                    <TooltipButton
                        id="add"
                        title="Add"
                        className="btn-icon ml-2"
                        color="default"
                        size="sm"
                        type="button"
                        onClick={() => {
                            var newDataRaw = Object.assign({}, siteAccess);
                            setNewData(newDataRaw);
                            if (drawerMode === Mode.ADD) {

                                setItemInEdit({
                                    ...itemInEdit,
                                    candidateSiteAccess: [
                                        ...data || [],
                                        {
                                            candidateId: itemInEdit?.candidateId,
                                            ...siteAccess,
                                            index: data ? data.length - 1 : 0
                                        }
                                    ]
                                });
                            }
                            else if (drawerMode === Mode.EDIT) {
                                const index = _.findIndex(data, o => {
                                    if (siteAccess.projectCandidateAttributeId)
                                        return o.projectCandidateAttributeId === siteAccess.projectCandidateAttributeId;

                                    return o.index === siteAccess.index;
                                });

                                const currAttributes = [...data]
                                currAttributes.splice(index, 1, {
                                    ...siteAccess
                                });
                                // console.log({index, siteAccess, currAttributes})
                                setItemInEdit({
                                    ...itemInEdit,
                                    candidateSiteAccess: currAttributes
                                });
                            }
                            setDrawerMode(Mode.NONE);
                        }}
                    >
                        {drawerMode === Mode.ADD ? "Add" : drawerMode === Mode.EDIT ? "Update" : ""}
                    </TooltipButton>
                </Col>
            </Row>
        </Collapse>
        <Collapse
            isOpen={drawerMode === Mode.NONE}
        >
            <TooltipButton
                id="addnew"
                title="Add New"
                className="btn-icon ml-3 mb-2"
                color="default"
                size="sm"
                type="button"
                onClick={() => {
                    setSiteAccess({ candidateId: itemInEdit?.candidateId });
                    setSelectedProject(null);
                    setDrawerMode(Mode.ADD);
                }}
            >
                <i className="fas fa-plus pt-1"></i>
            </TooltipButton>
        </Collapse>

        <Grid
            rows={rows}
            tableProps={getTableProps()}
            headerGroups={headerGroups}
            tableBodyProps={getTableBodyProps()}
            prepareRow={prepareRow}
            height={drawerMode !== Mode.NONE ? "300px" : "500px"}
        />
    </>)
}