import React, { useCallback, useEffect, useState } from 'react';
import api from "../../../../services/api";
import {
    Label, FormGroup, Button, Modal, Container, Row, Col, Input
 } from "reactstrap"; 
import { DropdownBox } from "../../../dropdowns";
import Loader from "../../../loaders";
import fileDownload from "js-file-download";
import { useDropzone } from 'react-dropzone';
import SweetAlert from "react-bootstrap-sweetalert";
import cn from "classnames";

// request a weekday along with a long date
var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };

export default function CandidateDocumentsEditor(props) {
    console.log('CandidateDocumentsEditor', props);

    const {
        project,
        locationId,
        positionId,
        rosterCandidateId,
        company,
        candidate,
        onClose
    } = props;

    const [ loading, setLoading ] = useState(false);
    const [ selectedTemplate, setSeletectedTemplate ] = useState({});
    const [ candidateDocuments, setCandidateDocuments ] = useState([]);
    const [ contractError, setContractError ] = useState(false);
    const [ generatedContractNames, setGeneratedContractNames ] = useState(null);
    const [ selectedCandidateDocument, setSelectedCandidateDocument ] = useState(null);

    const [ contractTemplates, setContractTemplates ] = useState([]);
    const [ itemInEdit, setItemInEdit ] = useState({});

    useEffect(() => {
        // if (!generateContract)
        //     return;

        const names = [];

        candidateDocuments.forEach(generatedContract => {
            const pdfName = generatedContract.pdfFilename?.split(".").slice(0, generatedContract.pdfFilename?.split(".").length - 1).join("");
            const docxName = generatedContract.docxFilename?.split(".").slice(0, generatedContract.pdfFilename?.split(".").length - 1).join("");
            names.push(pdfName ?? docxName);
        });

        setGeneratedContractNames(names);
    }, [candidateDocuments]);
    
    useEffect(() => {
        if (!project || !company)
            return;

        setLoading(true);
        
        let apiCalls = [
            api.get(`/lookup/contracttemplate?projectId=${project.projectId}${company ? `&companyId=${company.lookupId}` : ""}`),
            api.get(`/candidate/documents/${candidate.candidateId}`),
        ];

        Promise.all(apiCalls)
        .then((responses) => {
            console.log('data', responses);
            setContractTemplates(responses[0].data.map(m => ({ ...m, html: `<span class='ddl-wrap'><span class='ddl-header'>${m.lookupLabel}</span><span class='ddl-value'>${m.lookupValue}</span></span>` })));
            setCandidateDocuments(responses[1].data);
        })
        .catch((errors) => console.error("error: ", errors.response))
        .finally(() => setLoading(false));
    }, [project, company, candidate]);

    useEffect(() => {
        if (!selectedCandidateDocument)
            return;

        downloadAttachment();
    }, [selectedCandidateDocument]);

    const downloadAttachment = useCallback(() => {
        setLoading(true);
        const url = `candidate/document/download/${selectedCandidateDocument.candidateDocumentId}`;
        api.get(url, { responseType: 'blob' })
            .then(blob => {
                fileDownload(blob.data, selectedCandidateDocument.filename);
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
                setSelectedCandidateDocument(null);
            })
        ;
    }, [selectedCandidateDocument]);

    const {
        getRootProps, 
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone(
        {
            maxFiles: 1,
            multiple: false,
            accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            onDrop: ([file], fileRejections) => {
                console.log(file, fileRejections)
                setItemInEdit( { ...itemInEdit, file } );
            }
        });


    const files = React.useMemo(() => {
        let name, size, file;

        if (itemInEdit.file) {
            name = itemInEdit.file.name;
            size = itemInEdit.file.size;
            file = itemInEdit.file;
        } else if (itemInEdit.filename) {
            name = itemInEdit.filename;
            size = itemInEdit.fileSizeInBytes;
            file = itemInEdit;
        } else {
            return null;
        }
        return <aside className="dropzone-list">
            <a onClick={() => downloadAttachment(file)}>{name} - {size} bytes</a>
        </aside>
    }, [ itemInEdit.file ]);

    const dropzoneClasses = React.useMemo(() => cn({
        'dropzone': true,
        'mt-2': true,
        'active': isDragActive,
        'accept': isDragAccept,
        'reject': isDragReject
    }), [
        isDragActive,
        isDragAccept,
        isDragReject
    ]);

    const generateContract = React.useCallback(() => {
        console.log('params: ', { projectId: project.projectId, rosterCandidateId, locationId, positionId, companyId: company.lookupId, candidateId: candidate.candidateId, contractTemplateId: selectedTemplate.lookupId, contractTemplateType: selectedTemplate.lookupType });
        setLoading(true);
        api.post(`/candidate/document/generate`, { projectId: project.projectId, rosterCandidateId, locationId, positionId, companyId: company.lookupId, candidateId: candidate.candidateId, contractTemplateId: selectedTemplate.lookupId, contractTemplateType: selectedTemplate.lookupType })
            .then(response => {
                setCandidateDocuments([...candidateDocuments, ...response.data])
            }).catch(error => {
                var stringError = !error || !error.response ? "" : JSON.stringify(error.response);
                if (error.response.data?.message === "Contract Terms") {
                    setContractError({ message: `Project '${project.projectName}' (${error.response.data.locationName} - ${error.response.data.positionName}) missing Terms and Conditions.` });
                } else if (error.response.data?.message === "Rate") {
                    setContractError({ message: `Project '${project.projectName}' (${error.response.data.locationName} - ${error.response.data.positionName}) missing Rate.` });
                } else if (error.response.data === "Contract Template") {
                    setContractError({ message: `Project '${project.projectName}' missing Contract Template.` });
                } else if (error.response.data === "Project") {
                    setContractError({ message: `Cannot find project info for Job Order.` })
                }
                else if (stringError.toLowerCase().includes("error") && stringError.toLowerCase().includes("positionid")) {
                    setContractError({ message: `Employee you selected is missing an Employed Position.` })
                }

                console.error(error.response);
            }).finally(() => setLoading(false));
    }, [candidate, selectedTemplate, candidateDocuments, project, rosterCandidateId, locationId, positionId, company]);

    const manualUpload = React.useCallback(() => {
        setLoading(true);

        var formData = new FormData();

        formData.append("candidateId", candidate.candidateId);
        
        if (itemInEdit.file) {
            formData.append("file", itemInEdit.file);
        }

        api.post(`candidate/document/manual`, formData)
        .then(response => {
            setCandidateDocuments([...candidateDocuments, {...response.data}]);
        }).catch(error => {
            console.error(error.response);
        }).finally(() => setLoading(false))
    }, [candidate, itemInEdit]);

    return <>
        <SweetAlert
            customClass="contract-dialog"
            confirmBtnText="Done"
            title={`Contract - ${candidate.candidateName}`}
            onConfirm={onClose} 
        >
            {(loading) && <Loader />}
            <div className="dialog-wrapper">
                <div className="dialog-section">
                    <h5>Generate New</h5>
                    <div className="dialog-form">
                        <Row>
                            <Col xs={9}>
                                <FormGroup>
                                    <Label>
                                        Template
                                    </Label>
                                    <DropdownBox 
                                        data={contractTemplates}
                                        selectedItem={selectedTemplate}
                                        html={true}
                                        onChange={(item) => { 
                                            setSeletectedTemplate(item);
                                        }}
                                    />
                                </FormGroup>
                            </Col> 
                            <Col xs={3} className="d-flex align-items-end justify-content-start">
                                <Button className="mb-3 w-100" onClick={generateContract}>GO</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="dialog-section">
                    <h5>Manual Upload</h5>
                    <div className="dialog-form">
                        <Row>
                            <Col xs={9}>
                                <div {...getRootProps({className: dropzoneClasses})}>
                                    <input {...getInputProps()} />
                                    {files || <p>Drag 'n' drop a file here, or click to select a file</p>}
                                </div>
                            </Col>
                            <Col xs={3} className="d-flex align-items-end justify-content-start">
                                <Button className="mb-3 w-100" onClick={manualUpload}>UPLOAD</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="dialog-section">
                    <h5>Download Current Documents</h5>
                    <div className="dialog-form">
                        {
                            candidateDocuments?.length ?
                            <div style={{overflowY: "scroll", height: "40%"}}>
                            {candidateDocuments.map((cd, i) => {
                                return (
                                    <div key={`exist_${i}`} className="d-flex justify-content-start align-items-end">
                                        <button type="button" className="btn btn-xl btn-outline-secondary pr-3" onClick={() => setSelectedCandidateDocument({...cd})}>
                                            <span className="btn-inner--icon">
                                                <i className="fas fa-file-download"></i>
                                            </span>
                                        </button>
                                        <p>
                                            {cd.filename}<br/>
                                            {`Uploaded on ${new Intl.DateTimeFormat('en-AU', options).format(new Date(cd.modifiedDate))}`}
                                        </p>
                                    </div>
                                );
                            })}
                            </div>
                            : (<p>Cannot find any documents for this talent.</p>)
                        }
                    </div>
                </div>
            </div>
            {
            contractError &&
            <SweetAlert
                warning
                confirmBtnText="OK"
                confirmBtnBsStyle="danger"
                onConfirm={() => {
                    setContractError(false);
                }}
            >
                <span>{contractError.message}</span>
            </SweetAlert>
        }
        </SweetAlert>
    </>
}