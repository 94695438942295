/* eslint-disable no-unused-vars */
////import React, { useState } from 'react';
import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import { useParams } from "react-router-dom";
import api from "../../../../../services/api";
import qs from "qs";
import { SortsConverter, FiltersConverter } from "../../../../../helpers"
import { Container, Row, Col, Button, Label, FormGroup, Modal } from "reactstrap";
import { DropdownBox } from "../../../../dropdowns";
import moment from "moment";
import { ErrorNotification, ConfirmCancelAlert, ConfirmRollbackAlert, ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../../../alerts";
import { TooltipButton } from '../../../../inputs';
import fileDownload from "js-file-download";
import Loader from "../../../../loaders";
import ContractDialog from "../ContractDialog";
import ContractTemplate from "../ContractTemplate";
import DefinitivOnboardingEditor from '../DefinitivOnboardingEditor';
import SweetAlert from 'react-bootstrap-sweetalert';
import debounce from 'lodash.debounce';
import VacancyEditor from '../VacancyEditor';
import JobOrderApplicantList from '../JobOrderApplicantList';
import { DateComparator, DateValueFormatter, MakeWordsValueGetter, ImportDialogMessageCellRenderer } from '../../../../../helpers/AgGridHelper';
import DropdownButton from '../../../../dropdowns/dropdownbutton';
import { IndeterminateCheckbox } from '../../../../react-table/controls';
import EditJobOrderGridV2 from './EditJobOrderGridV2';

const timeoutLength = 1500;

export default function ModalContainer(props) {
    const {
        item,
        candidateIdForCvPreview,
        setCandidateIdForCvPreview,
        onClose,
        setRefreshHandle,
        onSaved,
        onViewCandidate,
        onViewSearchCandidate,
        onViewJobOrderCandidate,
        onViewCandidateEmail,
        onViewCandidateSMS,
        onViewCandidateEmails,
        onViewBulkCandidateSMS,
        onViewBulkMilestone,
        history,
    } = props;

    const itemInEditRef = useRef(item);
    const [open, setOpen] = React.useState(true);
    const [loading, setLoading] = useState(false);

    // BEGIN REF -----------------------------------------------------------------------------------------------------------------------------
    const loadingRef = useRef(loading);

    const openRef = useRef(open);
    useEffect(() => {
        openRef.current = open;
    }, [open]);

    useEffect(() => {
        loadingRef.current = loading;
    }, [loading]);
    // END REF -----------------------------------------------------------------------------------------------------------------------------


    return (
        <>

            {(loading) && <Loader />}
            <Modal
                isOpen={open}
                className="modal-xl m-4"
                modalClassName="modal-fullwidth"
                style={{ marginBottom: "200px", height: '100%' }}
            >
                <div
                    className="modal-header"
                >
                    <h5 className="modal-title">Job Order Editor -&nbsp;
                        {itemInEditRef?.current?.projectName} <i className="fas fa-angle-right ml-1 mr-1"></i>
                        {itemInEditRef?.current?.recruitLocation} <i className="fas fa-angle-right ml-1 mr-1"></i>
                        {itemInEditRef?.current?.hrispositionMasterName}
                    </h5>

                    {
                        item?.jobOrderId ?
                            <TooltipButton
                                id={`open-new-window`}
                                title="Open in New Window"
                                className="btn-icon ml-2"
                                color="default"
                                type="button"
                                onClick={() => {
                                    history.push(`/joborderdetail/${item.jobOrderId}`);
                                    props.onClose();
                                }}
                            >
                                <i className="fas fa-external-link-alt" />
                            </TooltipButton>
                            :
                            null
                    }
                    <button
                        aria-label="Close"
                        className="close"
                        onClick={onClose}
                        type="button"
                    >
                        <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                    </button>
                </div>
                <div className="modal-body" style={{ height: 'calc(125vh - 210px)' }}>
                    <EditJobOrderGridV2
                        item={item}
                        candidateIdForCvPreview={candidateIdForCvPreview}
                        setCandidateIdForCvPreview={setCandidateIdForCvPreview}
                        onClose={onClose}
                        setRefreshHandle={setRefreshHandle}
                        onSaved={onSaved}
                        onViewCandidate={onViewCandidate}
                        onViewSearchCandidate={onViewSearchCandidate}
                        onViewJobOrderCandidate={onViewJobOrderCandidate}
                        onViewCandidateEmail={onViewCandidateEmail}
                        onViewCandidateSMS={onViewCandidateSMS}
                        onViewCandidateEmails={onViewCandidateEmails}
                        onViewBulkCandidateSMS={onViewBulkCandidateSMS}
                        onViewBulkMilestone={onViewBulkMilestone}
                        itemInEditRef={itemInEditRef}
                        setOpen={setOpen}
                        openRef={openRef}
                        setLoading={setLoading}
                        loadingRef={loadingRef}
                        callFrom="modal"
                    />
                </div>
                <div className="modal-footer mt-3">
                    <Row>
                        <Col xs={12}>
                            <Button
                                color="secondary"
                                onClick={onClose}
                                type="button"
                            >
                                Close
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    )
}

