/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useTable, useRowSelect, useFlexLayout, useSortBy } from 'react-table';
import {
    Input, Label, FormGroup, Button, Modal, Container, Row, Col, Alert, Collapse, InputGroup
} from "reactstrap";
import { DatePicker } from "../../../components/date-pickers";
import { ComboBox, DropdownBox } from "../../dropdowns";
import { formatDate, formatDateZeroTime, Mode, PNM_StatusApproved, PNM_StatusPending, PNM_StatusRejected } from "../../../utils";
import Loader from "../../loaders";
import { labourRequestDetailValidation } from "./validation";
import fileDownload from "js-file-download";
import _ from "lodash";
import moment from "moment";
import { RadioGroup, CheckGroup, TooltipButton } from "../../inputs";
import api from "../../../services/api";
import { MissingJobDescriptionAlert } from "../../alerts";
import LabourRequestDetailPositionEditor from './LabourRequestDetailPositionEditor';
import Grid from '../../Grid';
import SearchRehire from './JobOrder/SearchRehire';
import { DefaultColumnFilter } from "../../react-table/filters";
import { roles } from '../../../auth/roles';
import { useMsal } from '@azure/msal-react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from 'ag-grid-react';
import ComboboxEditor from '../../ag-grid/ComboboxEditor';
import { moneyFormatter, percentFormatter } from '../../ag-grid/ValueFormatters';
import NumericEditor from '../../ag-grid/NumericEditor';
import { v4, validate as uuidValidate } from 'uuid';
import LabourRequestDetailEmployeeBenefitsModal from './LabourRequestDetailEmployeeBenefitsModal';

const DAYS_OF_WEEK = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 4,
    Thursday: 8,
    Friday: 16,
    Saturday: 32,
    Sunday: 64,
    Roster: 128,
    "Mon-Fri": 31
}

const getSelectedDays = (days) => {
    let selected = [];

    if ((days & DAYS_OF_WEEK.Monday) === DAYS_OF_WEEK.Monday) selected.push(DAYS_OF_WEEK.Monday);
    if ((days & DAYS_OF_WEEK.Tuesday) === DAYS_OF_WEEK.Tuesday) selected.push(DAYS_OF_WEEK.Tuesday);
    if ((days & DAYS_OF_WEEK.Wednesday) === DAYS_OF_WEEK.Wednesday) selected.push(DAYS_OF_WEEK.Wednesday);
    if ((days & DAYS_OF_WEEK.Thursday) === DAYS_OF_WEEK.Thursday) selected.push(DAYS_OF_WEEK.Thursday);
    if ((days & DAYS_OF_WEEK.Friday) === DAYS_OF_WEEK.Friday) selected.push(DAYS_OF_WEEK.Friday);
    if ((days & DAYS_OF_WEEK.Saturday) === DAYS_OF_WEEK.Saturday) selected.push(DAYS_OF_WEEK.Saturday);
    if ((days & DAYS_OF_WEEK.Sunday) === DAYS_OF_WEEK.Sunday) selected.push(DAYS_OF_WEEK.Sunday);

    return selected;
}

export default function LabourRequestDetailModal(props) {
    const {
        item,
        status,
        recruitmentType,
        isApproval,
        lookups,
    } = props;
    // console.log({status, recruitmentType});
    const [detail, setDetail] = React.useState(item.detail);
    const [loading, setLoading] = React.useState(false);
    const [validationAlert, setValidationAlert] = React.useState([]);
    const [alert, setAlert] = React.useState([]);
    const [showPositionEditor, setShowPositionEditor] = useState(false);
    const [request, setRequest] = useState({ ...item });
    const [firstLoad, setFirstLoad] = useState(true);
    const [chooseRehire, setChooseRehire] = useState(false);
    const [validateDownloadJobDesc, setValidateDownloadJobDesc] = useState(false);
    const [companyAllowanceTypes, setCompanyAllowanceTypes] = useState([]);
    const [showCurrentEmployeeBenefits, setShowCurrentEmployeeBenefits] = useState(false);
    const { instance } = useMsal();
    const user = instance.getActiveAccount();
    const isUserAdmin = user?.idTokenClaims.roles?.filter(r => r === roles.Admin).length > 0;
    const replacementEmployeeGridRef = useRef();
    const rehireListGridRef = useRef();

    const {
        employmentTypes,
        payProcessTypes,
        newOrReplacements,
        shifts,
        siteTravelTypes,
        pointOfHires,
        rosters,
        rosterSequences,
        flightBooked,
        accomodationTypes,
        mealsProvider,
        transferTypes,
        computerRequirements,
        phoneRequirements,
        employmentCategories,
        companies,
        itsystemAccesses
    } = lookups || {};

    // Rehire table
    const [dataRehire, setDataRehire] = useState(item.detail.requestDetailRehires ?? []);
    const loadingOverlayComponent = useMemo(() => Loader, []);

    const validate = React.useCallback(() => {
        let errors = [...validationAlert.filter(v => v.key !== "positionRates" && v.type === "required")];
        _.forEach(labourRequestDetailValidation, (value) => {
            _.forEach(value.tests, (test) => {
                const exists = _.some(errors, (item) => item.key === value.field && item.type === test.type);
                const result = test.validate(detail, item);
                if (result && !exists) {
                    errors.push({ key: value.field, type: test.type, message: test.message })
                } else if (!result && exists) {
                    _.remove(errors, (item) => item.key === value.field && item.type === test.type);
                }
            })
        });

        const warnings = [];

        if (detail.position && !detail?.positionHasRates)
        {
            warnings.push({
                key: "positionRates",
                type: "required",
                message: "Selected position does not have rates.",
            });
        }

        setValidationAlert([...errors, ...warnings]);
        if (errors.length > 0) {
            return false;
        }
        scrollToValidation();
        return true;
    }, [detail, item]);

    useEffect(() => {
        // console.log({user, detail});
        if (detail?.reportingToName)
            return;

        api.get(`/recruitmentrequest/searchusers?filter=${user?.username}`)
            .then((response) => setDetail({...detail, reportingToName: response?.data?.length ? response.data[0].email : null, reportingToApiResult: response?.data?.length ? response.data[0] : null}))
            .catch((error) => console.error({error}))
        ;
    }, []);

    // BEGIN AUTO SCROLL TO VALIDATION -----------------------------------------------------------------------------------------
    const scrollToValidation = useCallback(() => {
        if (validationAlert) {


            // Scroll to insert or update
            setTimeout(function () { //Start the timer
                var scrollObject = document.getElementById("lrfValidationDetailAlert");
                if (!scrollObject) return;
                scrollObject.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }.bind(this), 500);
        }
    }, [validationAlert]);
    React.useEffect(() => {
        scrollToValidation();
    }, [validationAlert]);
    // BEGIN AUTO SCROLL TO VALIDATION -----------------------------------------------------------------------------------------

    React.useEffect(() => {
        // console.log({detail});
        if (detail && validationAlert.length > 0) validate();
        // validate();
    }, [detail]);

    // Sync data to detail to enable save feature works
    React.useEffect(() => {
        var newRehire = [];
        var newDetail = {};
        if (dataRehire) {
            newRehire = [...dataRehire];
        }
        newDetail = { ...detail, requestDetailRehires: newRehire };
        setDetail(newDetail);

    }, [dataRehire]);

    const handleSave = () => {
        // console.log("handleSave: ", detail);

        if (!validate()) {
            return;
        }

        props.save({ ...detail }, item.mode);
    }


    const handleTextChanged = ({ target: { name, value } }) => {
        setDetail({ ...detail, [name]: value });
    }

    const handleLookupChange = ({ target: { name, value } }) => {
        // console.log("handleLookupChange: ", name, value);
        setDetail({ ...detail, [name]: value * 1 });
    }

    const handleDateChanged = (value, name) => {
        setDetail({
            ...detail,
            [name]: !value ? null : formatDateZeroTime(value) //moment(value).format("YYYY-MM-DDT00:00:00") 
        });
    }

    const handleDaysPerWeekChange = ({ target: { name, value, checked } }) => {
        const intVal = parseInt(value);
        const nextValue = (intVal === 31) ? (checked ? intVal : (detail.daysPerWeek > intVal ? detail.daysPerWeek - intVal : 0)) : (detail.daysPerWeek || 0) +
            (checked ?
                intVal
                :
                -intVal
            );
        // console.log("handleCheckboxChange: ", { name, value, checked, nextValue });

        setDetail({ ...detail, daysPerWeek: nextValue });
    }

    useEffect(() => {
        if (detail && detail.positionId && detail.employmentCategoryId && detail.startDateExpected && !detail.maxSalaryFrom && detail.employmentCategory.lookupValue === "White Collar") {
            setLoading(true);
            const start = moment(detail.startDateExpected).format("YYYY-MM-DD");
            const end = detail.finishDatePlanned ? moment(detail.finishDatePlanned).format("YYYY-MM-DD") : null;

            api.get(`rates/findrate/${detail.positionId}/${detail.employmentCategoryId}/${start}${end ? `/${end}` : ""}`)
                .then((response) => {
                    // console.log("Rates: ", response);
                    if (response.data && response.data.fulltimeRate) {
                        setDetail({ ...detail, maxSalaryFrom: response.data.fulltimeRate });
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [detail.positionId, detail.employmentCategoryId, detail.startDateExpected, detail.finishDatePlanned, detail.maxSalaryFrom]);

    useEffect(() => {
        setLoading(true);
        // console.log({detail});
        
        if (!detail.recruitmentCurrentEmployees?.length || !detail.recruitmentCurrentEmployees[0].companyId)
        {
            if (companyAllowanceTypes.length)
                setCompanyAllowanceTypes([]);

            setLoading(false);
            return;
        }

        api.get(`company/definitiv/allowancetypes?id=${detail.recruitmentCurrentEmployees[0].companyId}`)
            .then((response) => {
                setCompanyAllowanceTypes([...response.data]);
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [detail.recruitmentCurrentEmployees]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        filter: false,
        editable: false,
        suppressMenu: true,
    }), []);
    
    const replacementEmployeeColumnDefs = useMemo(() => [
        {
            field: "action",
            headerName: "Action",
            width: 80,
            maxWidth: 80,
            pinned: true,
            sortable: false,
            cellRenderer: (cellParams) => {
                const {data} = cellParams;

                return (
                    <div className="text-center">
                        <TooltipButton
                            title="Remove"
                            color="default"
                            onClick={() => {
                                const newDetail = JSON.parse(JSON.stringify(detail));

                                if (!uuidValidate(data.recruitmentCurrentBenefitId))
                                    newDetail.deletedRecruitmentCurrentBenefitIds.push(data.recruitmentCurrentBenefitId);

                                newDetail.recruitmentCurrentBenefits = newDetail.recruitmentCurrentBenefits.filter(rcb => rcb.recruitmentCurrentBenefitId !== data.recruitmentCurrentBenefitId);
                                // console.log({data, newDetail});
                                setDetail({...newDetail});
                            }}
                            id={`remove_benefit_${data.benefitTypeId}`}
                            size="xsm"
                            className="btn-icon"
                        >
                            <i className="fas fa-trash-alt pt-1" />
                        </TooltipButton>
                    </div>
                );
            },
            suppressMenu: true,
        },
        {
            field: "benefitTypeName",
            headerName: "Type",
            cellEditor: ComboboxEditor,    
            cellEditorParams: {
                initData: companyAllowanceTypes.filter(cat => !detail.recruitmentCurrentBenefits.filter(rcb => rcb.benefitTypeName === cat.allowanceTypeName).length),
                keyField: "benefitTypeId",
                textField: "benefitTypeName",
                dataKeyField: "allowanceTypeId",
                dataTextField: "allowanceTypeName",
                otherFields: [{field: "uom"}],
                className: "w-100",
            },
            editable: true,
        },
        {
            field: "uom",
            headerName: "UoM",
            width: 140,
            maxWidth: 140,
        },
        {
            field: "benefitValue",
            headerName: "Value",
            cellEditor: NumericEditor,
            editable: true,
            valueFormatter: (params) => {
                const {data: rowData} = params;

                if (rowData.uom === "Payment")
                    return moneyFormatter(params);

                if (rowData.uom === "Percent")
                    return percentFormatter(params);

                return params.value;
            },
            cellClass: "text-right",
            width: 160,
            maxWidth: 160,
        },
    ], [detail, companyAllowanceTypes]);

    const onReplacementEmployeeGridReady = useCallback((params) => {
        params.api.sizeColumnsToFit();
        window.addEventListener('resize', function () {
            setTimeout(function () {
                params.api.sizeColumnsToFit();
            });
        });
    }, [detail?.recruitmentCurrentBenefits]);

    const getReplacementEmployeeGridRowId = useMemo(() => (params) => params?.data?.recruitmentCurrentBenefitId, [detail?.recruitmentCurrentBenefits]);
    
    const onReplacementEmployeeGridCellEditRequest = useCallback((event) => {
        const {data: rowData, newValue, colDef} = event;
        // console.log({event, detail, valueType: typeof newValue});
        const newRowValue = typeof newValue === "string" || typeof newValue === "number" ? {...rowData, [colDef.field]: newValue} : {...rowData, ...newValue};
        const newBenefits = JSON.parse(JSON.stringify(detail.recruitmentCurrentBenefits));
        const selectedBenefit = newBenefits.filter(b => b.recruitmentCurrentBenefitId === newRowValue.recruitmentCurrentBenefitId)[0];
        const index = newBenefits.indexOf(selectedBenefit);
        newBenefits.splice(index, 1, newRowValue);
        setDetail({...detail, recruitmentCurrentBenefits: newBenefits});
    }, [detail]);
    
    const rehireColumnDefs = useMemo(() => [
        {
            field: 'requestDetailRehireId',
            headerName: "Request Detail Rehire Id",
            width: 50,
            maxWidth: 50,
            hide: true,
        },
        {
            field: 'requestDetailId',
            headerName: "Request Detail Id",
            width: 50,
            maxWidth: 50,
            hide: true,
        },
        {
            field: 'candidateId',
            headerName: "Candidate ID",
            width: 50,
            maxWidth: 50,
            hide: true,
        },
        {
            field: 'candidateName',
            headerName: "Employee Name",
        },
        {
            field: 'lastEmploymentDate',
            headerName: "Last Employment Date",
            width: 160,
            maxWidth: 160,
            valueFormatter: (params) => {
                const {data: rowData} = params;
                return rowData.lastEmploymentDate ? moment(rowData.lastEmploymentDate).format('dd, DD-MM-YYYY') : "N/A";
            },
            cellClass: "text-center",
        },
        {
            field: "action",
            headerName: "Action",
            width: 80,
            maxWidth: 80,
            pinned: true,
            sortable: false,
            cellRenderer: (cellParams) => {
                const {data: rowData} = cellParams;
                return (
                    <div className="text-center">
                        <TooltipButton
                            id={`delete_rehire_${rowData.candidateId}`}
                            title="Delete"
                            className="btn-icon"
                            color="default"
                            size="xsm"
                            type="button"
                            onClick={() => {

                                const newDataList = [...dataRehire];
                                const i = newDataList.findIndex((w) => w.candidateId == rowData.candidateId);
                                if (i >= 0) {
                                    //console.log('index found', 'i', i);
                                    newDataList.splice(i, 1);
                                }
                                setDataRehire(newDataList);

                            }}>
                            <i className="fas fa-trash-alt pt-1" />
                        </TooltipButton>
                    </div>
                );
            },
        },
    ], [detail, companyAllowanceTypes]);

    const onRehireGridReady = useCallback((params) => {
        params.api.sizeColumnsToFit();
        window.addEventListener('resize', function () {
            setTimeout(function () {
                params.api.sizeColumnsToFit();
            });
        });
    }, [dataRehire]);

    const downloadJobDescHandle = () => {
        // console.log('jobdescription', detail.positionId, detail.employmentCategoryId)

        // Validate job description
        if (!detail?.positionHasDescriptions) {
            setValidateDownloadJobDesc(true);
            return;
        }

        api.get(`jobdescription/bycategory/${detail.positionId}/${detail.employmentCategoryId}`, { responseType: 'blob' })
            .then((blob, oth) => {
                var filename = RegExp(/(?<=filename=)\*?['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?(?=;)/).exec(blob.headers["content-disposition"])[0];
                // console.log('filename', filename);
                filename = filename.replace(/^_+|_+$/g, '');
                // console.log('filename 2', filename);
                fileDownload(blob.data, filename);
            }).catch((error) => {
                const info = error?.response?.data;
                // console.log('info', info)

                if (info) {
                    info.text().then(text => {
                        // console.log('text', text)
                        const position = JSON.parse(text);
                        setAlert([<MissingJobDescriptionAlert
                            onConfirm={() => setAlert([])}
                            message={`Job Description's file is not uploaded for the selected Position: ${position.positionName}`}
                        />]);
                    })

                } else {
                    console.error(error);
                }
            }).finally(() => { });
    }

    const columnsSms = useMemo(() => {
        return [
            {
                id: 'requestDetailRehireId',
                Header: "Request Detail Rehire Id",
                accessor: (row) => row.requestDetailRehireId,
                width: 50,
                disableSortBy: false,
            },
            {
                id: 'requestDetailId',
                Header: "Request Detail Id",
                accessor: (row) => row.requestDetailId,
                width: 50,
                disableSortBy: false,
            },
            {
                id: 'candidateId',
                Header: "Candidate ID",
                accessor: (row) => row.candidateId,
                width: 50,
                disableSortBy: false,
            },
            {
                id: 'candidateName',
                Header: "Employee Name",
                Filter: DefaultColumnFilter,
                filter: "text",
                accessor: (row) => row.candidateName,
                width: 50,
                disableSortBy: false,
            },
            {
                id: 'lastEmploymentDate',
                Header: "Last Employment Date",
                Filter: DefaultColumnFilter,
                filter: "text",
                accessor: (row) => !row.lastEmploymentDate ? 'N/A' : moment(row.lastEmploymentDate, "YYYY-MM-DDT00:00:00").format('dd, DD-MM-YYYY'),
                width: 50,
                disableSortBy: false,
            },
            {
                id: "action",
                Header: "Action",
                //accessor: (row) => !row || !('message' in row) || !('message' in row.message) ? null : row.message.message,
                width: "15",
                Cell: ({ row }) => {
                    const { original } = row;
                    return (<TooltipButton
                        id={`deleteRehire_${original.candidateId}`}
                        title="Delete"
                        className="btn-icon"
                        color="default"
                        size="xsm"
                        type="button"
                        onClick={() => {

                            var newDataList = [...dataRehire];
                            var i = newDataList.findIndex((w) => w.candidateId == original.candidateId);
                            if (i >= 0) {
                                //console.log('index found', 'i', i);
                                newDataList.splice(i, 1);
                            }
                            setDataRehire(newDataList);

                        }}>
                        <i className="fas fa-trash-alt pt-1"></i>
                    </TooltipButton>);
                },
                disableSortBy: false,
            },
        ];
    }, [firstLoad, dataRehire, detail]);

    const {
        getTableProps: getTablePropsRehire,
        getTableBodyProps: getTableBodyPropsRehire,
        headerGroups: headerGroupsRehire,
        rows: rowsRehire,
        prepareRow: prepareRowRehire,
        state: stateRehire,
        selectedFlatRows: selectedFlatRowsRehire,
        toggleAllRowsSelected: toggleAllRowsSelectedRehire,
        flatRows: flatRowsRehire
    } = useTable(
        {
            columns: columnsSms,
            data: dataRehire,
            initialState: {
                // "selectedRowIds": {},
                "hiddenColumns": ['requestDetailRehireId', 'requestDetailId', 'candidateId']
            },
            // defaultColumn
        },
        useFlexLayout,
        useSortBy,
        useRowSelect,
    );

    return (
        <Modal
            isOpen={true}
            className="modal-xl"
            modalClassName="db-example-modal-xl"
        >
            {loading && <Loader />}
            {
                showPositionEditor &&
                <LabourRequestDetailPositionEditor
                    rosterId={request?.rosterId}
                    hrispositionMasterId={detail?.hrispositionMasterId}
                    request={request}
                    requestDetail={detail}
                    onClose={() => setShowPositionEditor(false)}
                    onSaved={(selectedRosterPositions) => {
                        const newPositions = [...selectedRosterPositions.filter(s => !s.requestDetailId || s.requestDetailId === detail?.requestDetailId).map(s => ({ ...s, requestDetailId: !s.requestDetailId ? detail?.requestDetailId : s.requestDetailId }))];
                        const newDetail = { ...detail, selectedRosterPositions: [...newPositions], quantityRequired: newPositions.length ?? 0 };
                        // console.log(newPositions, detail, request);
                        setRequest({
                            ...request, requestDetails: request.requestDetails.map(d => {
                                if (d.requestDetailId === detail.requestDetailId)
                                    return {
                                        ...newDetail
                                    };

                                return {
                                    ...d
                                };
                            })
                        })
                        setDetail({ ...newDetail });
                        setShowPositionEditor(false);
                    }}
                />
            }
            {
                chooseRehire &&
                <SearchRehire
                    dataExisting={dataRehire}
                    onClose={() => setChooseRehire(false)}
                    onSaved={(selectedCandidate) => {
                        if (!selectedCandidate)
                            return;

                        var selectedCopy = [...selectedCandidate];
                        var dataCopy = [...dataRehire];
                        var uniqueData = selectedCopy.filter(w => !dataRehire.some(w2 => w2.candidateId == w.candidateId));
                        var rehireList = uniqueData.map((v) => {

                            var latestEndDate = v.candidateAllocations.filter(w => w).sort((a, b) => (a.endDate > b.endDate) ? -1 : ((b.endDate > a.endDate) ? 1 : 0)).find(w => w);
                            var lastEmploymentDate = null;
                            if (latestEndDate)
                                lastEmploymentDate = latestEndDate.endDate;

                            return {
                                requestDetailRehireId: 0,
                                candidateId: v.candidateId,
                                candidateName: v.candidateName,
                                candidate: v,
                                lastEmploymentDate: lastEmploymentDate,
                                requestDetailId: detail?.requestDetailId,
                                createdDate: null,
                                createdBy: null,
                                modifiedDate: null,
                                modifiedBy: null
                            };
                        });

                        var newData = dataCopy.concat(rehireList);
                        setDataRehire(newData);
                    }}

                />
            }
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Detail
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.close}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body"
            >

                {alert.length > 0 && alert}
                <Collapse id="lrfValidationDetailAlert" isOpen={validationAlert && validationAlert.length > 0}>
                    <Alert color="warning" className="pa-0 d-flex justify-content-between" >
                        <span className="alert-text">
                            <ul className="mb-0">
                                {
                                    !!validationAlert && validationAlert.length > 0 && validationAlert.map((item, index) => <li key={index}>{item.message}</li>)
                                }
                            </ul>
                        </span>
                    </Alert>
                </Collapse>
                <Container fluid className='px-0'>
                    <Row>
                        <Col xs={4}>
                            <FormGroup>
                                <Label>
                                    Employment Category
                                    <span className="text-danger">*</span>
                                </Label>
                                {/* <RadioGroup
                                    items={employmentCategories}
                                    name="employmentCategoryId"
                                    handleChange={({ target: { value } }) => {
                                        const employmentCategoryId = value * 1
                                        const type = _.find(employmentCategories, (item) => item.lookupId === employmentCategoryId);
                                        setDetail({ ...detail, employmentCategory: type, employmentCategoryId });
                                    }}
                                    selected={detail.employmentCategoryId}
                                    colWidth={6}
                                /> */}
                                <Input
                                    value={detail?.employmentCategory?.lookupValue ?? ""}
                                    disabled={true}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={4}>
                            <FormGroup>
                                <Label>
                                    Employed Position (in rate sheet only)
                                    <span className="text-danger">*</span>
                                </Label>
                                <ComboBox
                                    endpoint="/position"
                                    //accessToken={token?.accessToken}
                                    selectedItem={detail.position}
                                    onChange={(item) => {
                                        setDetail({ ...detail, position: item ? { ...item, positionName: item.lookupValue } : null, positionId: item ? item.lookupId : null, positionHasRates: item?.positionHasRates ?? false });
                                    }}
                                    minLength={0}
                                    suffix={detail && detail.employmentCategoryId && detail.positionId ? [
                                        <TooltipButton
                                            color="primary"
                                            size="sm"
                                            id="downloadJobDesc"
                                            title={!detail.positionHasDescriptions ? `Job Description not set for the selected position: ${detail.position?.lookupValue}` : 'Download Job Description'}
                                            className={`${!detail.positionHasDescriptions ? 'disabled pe-none' : ''}`}
                                            onClick={() => downloadJobDescHandle()}
                                            //disabled={!detail.positionHasDescriptions}
                                        >
                                            <i className="fas fa-download"></i>
                                        </TooltipButton>
                                    ] : []}
                                    additionalParam={{
                                        checkRate: true,
                                        projectId: detail?.projectId ?? 0,
                                        locationId: detail?.locationId,
                                        categoryId: detail?.employmentCategoryId,
                                        startDate: detail?.startDateExpected ? moment(detail?.startDateExpected).format("YYYY-MM-DD") : null,
                                    }}
                                />

                            </FormGroup>
                        </Col>
                        {
                            request?.rosterId ?
                                <Col xs={4}>
                                    <FormGroup>
                                        <Label>
                                            Roster Position
                                            <span className="text-danger">*</span>
                                        </Label>
                                        {/* <ComboBox 
                                    endpoint="/position"
                                    //accessToken={token?.accessToken}
                                    selectedItem={detail.position}
                                    onChange={(item) => {
                                        setDetail( { ...detail, position: item, positionId: item ? item.lookupId : null } );
                                    }}
                                /> */}
                                        {/* <ComboBox 
                                    endpoint="/hrisposition/search"
                                    isLookup={false}
                                    selectedItem={detail.hrisposition}
                                    idField="hrispositionId"
                                    valueField="hrispositionName"
                                    onChange={(item) => {
                                        setDetail( { ...detail, hrisposition: item, hrispositionId: item ? item.hrispositionId : null, position: item?.position ?? null, positionId: item ? item?.position.lookupId : null } );
                                    }}
                                /> */}
                                        <ComboBox
                                            endpoint={item.rosterId ? `/hrispositionmaster/searchbyroster/${item.rosterId}` : "/hrispositionmaster/search-maintenance"}
                                            isLookup={false}
                                            selectedItem={detail.hrispositionMaster}
                                            idField="hrispositionMasterId"
                                            valueField="hrispositionMasterName"
                                            onChange={(item) => {
                                                setDetail({ ...detail, hrispositionMaster: item, hrispositionMasterId: item ? item.hrispositionMasterId : null });
                                                // api.get(`/hrisposition/bypositionmaster-single/${item?.hrispositionMasterId ?? 0}`)
                                                //     .then(({data}) => {
                                                //         // console.log({data});
                                                //         // console.log({ ...detail, hrispositionMaster: item, hrispositionMasterId: item ? item.hrispositionMasterId : null, hrisposition: data, hrispositionId: data ? data.hrispositionId : null, position: data?.position ?? null, positionId: data ? data?.positionId : null });
                                                //         setDetail( { ...detail, hrispositionMaster: item, hrispositionMasterId: item ? item.hrispositionMasterId : null, hrisposition: data, hrispositionId: data ? data.hrispositionId : null, position: data?.position ?? null, positionId: data ? data?.positionId : null } );
                                                //     })
                                                //     .catch((error) => console.log(error))
                                                // ;
                                            }}
                                            minLength={0}
                                        />
                                    </FormGroup>
                                </Col>
                                :
                                null
                        }
                        {
                            detail && detail.employmentCategoryId && detail.positionId &&
                            <>
                                {/* 
                                <Col xs={12} className='d-flex justify-content-end'>
                                    <Button
                                        color="primary"
                                        outline
                                        block
                                        size="sm"
                                        onClick={() => downloadJobDescHandle()}
                                        disabled={!detail.positionHasDescriptions}
                                    >
                                        <i className="fas fa-download mr-2"></i>Download Job Description 2
                                    </Button>
                                </Col>
                                */}
                            </>
                        }
                    </Row>
                {
                    request?.recruitmentCategory?.lookupValue === "Replacement" &&
                    <>
                        <Row className="mt-4 mb-3">
                            <Col xs={12} className="bg-secondary border-bottom">
                                <span className="h6">Replaced Employee Details</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <FormGroup>
                                    <Label>
                                        Company
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <ComboBox
                                        minLength={0}
                                        endpoint={`/company/search`}
                                        isLookup={false}
                                        idField="companyId"
                                        valueField="companyName"
                                        selectedItem={detail.recruitmentCurrentEmployees?.length ? detail.recruitmentCurrentEmployees[0].company : null}
                                        onChange={(item) => {
                                            const newDetail = JSON.parse(JSON.stringify(detail));

                                            if (!newDetail.recruitmentCurrentEmployees?.length)
                                                newDetail.recruitmentCurrentEmployees = [{
                                                    recruitmentCurrentEmployeeId: v4(),
                                                    requestDetailId: detail.requestDetailId,
                                                }];

                                            if (item?.companyId !== newDetail.recruitmentCurrentEmployees[0].companyId)
                                                newDetail.recruitmentCurrentBenefits = [];

                                            const oldCompanyId = newDetail.recruitmentCurrentEmployees[0].companyId;
                                            newDetail.recruitmentCurrentEmployees[0].company = item;
                                            newDetail.recruitmentCurrentEmployees[0].companyId = item?.companyId ?? null;

                                            if (item?.companyId !== oldCompanyId)
                                            {
                                                newDetail.recruitmentCurrentEmployees[0].employeePayrollId = null;
                                                newDetail.recruitmentCurrentEmployees[0].firstName = null;
                                                newDetail.recruitmentCurrentEmployees[0].surname = null;
                                                newDetail.recruitmentCurrentEmployees[0].employeeNumber = null;
                                                newDetail.recruitmentCurrentEmployees[0].employeeName = null;
                                            }

                                            setDetail({...newDetail});
                                        }}
                                        disabled={isApproval}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={4}>
                                <FormGroup>
                                    <Label>
                                        Employee
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <ComboBox
                                        endpoint={`/company/definitiv/employees`}
                                        selectedItem={detail.recruitmentCurrentEmployees?.length && detail.recruitmentCurrentEmployees[0].employeePayrollId ? detail.recruitmentCurrentEmployees[0] : null}
                                        isLookup={false}
                                        idField="employeeId"
                                        valueField="employeeName"
                                        onChange={(item) => {
                                            const newDetail = JSON.parse(JSON.stringify(detail));
                                            newDetail.recruitmentCurrentEmployees[0].employeePayrollId = item?.employeeId;
                                            newDetail.recruitmentCurrentEmployees[0].firstName = item?.firstName;
                                            newDetail.recruitmentCurrentEmployees[0].surname = item?.surname;
                                            newDetail.recruitmentCurrentEmployees[0].employeeNumber = item?.employeeNumber;
                                            newDetail.recruitmentCurrentEmployees[0].employeeName = item?.employeeName;
                                            setDetail({...newDetail});
                                        }}
                                        minLength={0}
                                        additionalParam={{
                                            id: detail.recruitmentCurrentEmployees?.length ? detail.recruitmentCurrentEmployees[0].companyId : 0,
                                        }}
                                        disabled={!detail.recruitmentCurrentEmployees?.length || isApproval}
                                        // suffix={isApproval ? [
                                        //     <TooltipButton
                                        //         color="primary"
                                        //         size="sm"
                                        //         id="downloadJobDesc"
                                        //         title="Show Replaced Employee's Benefits"
                                        //         className={`${(!detail?.recruitmentCurrentEmployees?.length || !detail.recruitmentCurrentEmployees[0].employeePayrollId) ? 'disabled pe-none' : ''}`}
                                        //         onClick={() => setShowCurrentEmployeeBenefits(true)}
                                        //         disabled={!detail?.recruitmentCurrentEmployees?.length || !detail.recruitmentCurrentEmployees[0].employeePayrollId}
                                        //     >
                                        //         <i className="fas fa-id-badge"></i>
                                        //     </TooltipButton>
                                        // ] : []}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <FormGroup>
                                {
                                    isApproval && request?.recruitmentCategory?.lookupValue === "Replacement" && request?.isLikeForLike === true ?
                                    <Button
                                        id="showEmployeeBenefits"
                                        color="default"
                                        size="sm"
                                        type="button"
                                        className={`btn-icon${(!detail?.recruitmentCurrentEmployees?.length || !detail.recruitmentCurrentEmployees[0].employeePayrollId) ? ' disabled pe-none' : ''}`}
                                        onClick={() => setShowCurrentEmployeeBenefits(true)}
                                        disabled={!detail?.recruitmentCurrentEmployees?.length || !detail.recruitmentCurrentEmployees[0].employeePayrollId}
                                    >
                                        <i className="fas fa-id-badge pt-1 mr-1" />Show Current Pay Profile
                                    </Button>
                                    :
                                    null
                                }
                                    <Button
                                        id="addnew"
                                        className="btn-icon"
                                        color="default"
                                        size="sm"
                                        type="button"
                                        onClick={() => {
                                            const newRecruitmentCurrentBenefits = detail?.recruitmentCurrentBenefits ?? [];
                                            newRecruitmentCurrentBenefits.push({
                                                recruitmentCurrentBenefitId: v4(),
                                                requestDetailId: detail?.requestDetailId,
                                                benefitTypeId: null,
                                                benefitTypeName: null,
                                                benefitValue: null,
                                            });
                                            setDetail({...detail, recruitmentCurrentBenefits: [...newRecruitmentCurrentBenefits]});
                                        }}
                                        disabled={!companyAllowanceTypes.length}
                                    >
                                        <i className="fas fa-plus pt-1 mr-1" />New Benefit
                                    </Button>
                                    <Container fluid className="m-0 p-0 mt-1 py-2">
                                        <div className="ag-theme-alpine ag-grid" style={{ width: '100%', height: '200px' }}>
                                            <AgGridReact
                                                ref={replacementEmployeeGridRef}
                                                columnDefs={replacementEmployeeColumnDefs}
                                                defaultColDef={defaultColDef}
                                                loadingOverlayComponent={loadingOverlayComponent}
                                                suppressColumnVirtualisation={false}
                                                suppressRowVirtualisation={false}
                                                debounceVerticalScrollbar={false}
                                                rowBuffer={20}
                                                headerHeight={30}
                                                groupHeaderHeight={30}
                                                rowHeight={35}
                                                floatingFiltersHeight={30}
                                                suppressDragLeaveHidesColumns={true}
                                                suppressColumnMoveAnimation={true}
                                                animateRows={false}
                                                pagination={false}
                                                rowClass={'ag-row-normal'}
                                                suppressContextMenu={true}
                                                rowData={detail?.recruitmentCurrentBenefits ?? []}
                                                onGridReady={onReplacementEmployeeGridReady}
                                                readOnlyEdit={true}
                                                onCellEditRequest={onReplacementEmployeeGridCellEditRequest}
                                                getRowId={getReplacementEmployeeGridRowId}
                                                stopEditingWhenCellsLoseFocus={true}
                                            />
                                        </div>
                                    </Container>
                                </FormGroup>
                            </Col>
                        </Row>
                    </>
                }
                    <Row className="mt-4 mb-3">
                        <Col xs={12} className="bg-secondary border-bottom">
                            <span className="h6">Employment Details</span>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs={2}>
                            <FormGroup>
                                <Label>
                                    Quantity Required
                                    <span className="text-danger">*</span>
                                </Label>
                            {
                                item?.rosterId ?
                                <FormGroup className="mb-0">
                                    <InputGroup>
                                        <Input
                                            name="quantityRequired"
                                            id="quantityRequired"
                                            type="number"
                                            required
                                            value={detail.quantityRequired ?? ""}
                                            onChange={handleTextChanged}
                                            disabled={true}
                                        />
                                        <div className="input-group-append">
                                            <TooltipButton
                                                id={`edit_position_qty`}
                                                title="Change Position Quantity"
                                                className="btn-icon"
                                                color="default"
                                                size="sm"
                                                type="button"
                                                onClick={() => {
                                                    setShowPositionEditor(true);
                                                }}
                                            >
                                                <i className="fas fa-pencil-alt"></i>
                                            </TooltipButton>
                                        </div>
                                    </InputGroup>
                                </FormGroup>
                                :
                                <Input
                                    name="quantityRequired"
                                    id="quantityRequired"
                                    type="number"
                                    required
                                    value={detail.quantityRequired ?? ""}
                                    onChange={handleTextChanged}
                                    min="1"
                                />
                            }
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup className="">
                                <Label>
                                    Payroll Default Cost Code
                                    {/* <span className="text-danger">*</span> */}
                                </Label>
                                {/* <ComboBox
                                    minLength={2}
                                    endpoint={`/workorder/${detail.projectId}`}
                                    //accessToken={token?.accessToken}
                                    selectedItem={detail.workorder}
                                    onChange={(item) => {
                                        setDetail({ ...detail, workorder: item, workorderId: item ? item.lookupId : null });
                                    }}
                                /> */}
                                <Input
                                    name="workorderNumber"
                                    id="workorderNumber"
                                    required
                                    value={detail.workorderNumber ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={2}>
                            <FormGroup>
                                <Label>
                                    Expected Start Date
                                    <span className="text-danger">*</span>
                                </Label>
                                <DatePicker
                                    name="startDateExpected"
                                    id="startDateExpected"
                                    type="text"
                                    required
                                    value={formatDate(detail.startDateExpected)}
                                    onChange={handleDateChanged}
                                    closeOnSelect
                                    isValidDate={(currentDate, selectedDate) => {
                                        // console.log('isValidDate', currentDate, selectedDate);
                                        const todayPlusTwo = moment().add(1, 'day');
                                        return currentDate.isSameOrAfter(todayPlusTwo);
                                        //return true;
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={2}>
                            <FormGroup>
                                <Label>
                                    Planned End Date
                                </Label>
                                <DatePicker
                                    name="finishDatePlanned"
                                    id="finishDatePlanned"
                                    type="text"
                                    required
                                    value={formatDate(detail.finishDatePlanned)}
                                    onChange={handleDateChanged}
                                    closeOnSelect
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <Label>
                                    Employment Type
                                    <span className="text-danger">*</span>
                                </Label>
                                {/* <RadioGroup
                                    items={employmentTypes}
                                    name="employmentTypeId"
                                    handleChange={({ target: { value } }) => {
                                        const employmentTypeId = value * 1
                                        const type = _.find(employmentTypes, (item) => item.lookupId === employmentTypeId);
                                        setDetail({ ...detail, employmentType: type, employmentTypeId });
                                    }}
                                    selected={detail.employmentTypeId}
                                    colWidth={2}
                                /> */}
                                <DropdownBox
                                    data={employmentTypes}
                                    isLookup={true}
                                    selectedItem={detail?.employmentType}
                                    onChange={(item) => {
                                        setDetail({ ...detail, employmentType: item, employmentTypeId: item?.lookupId ?? null });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={2}>
                            <FormGroup>
                                <Label>Point of Hire</Label>
                                {/* <RadioGroup 
                                    items={pointOfHires} 
                                    name="pointOfHireId" 
                                    handleChange={handleLookupChange} 
                                    selected={detail.pointOfHireId}
                                /> */}
                                <DropdownBox
                                    data={pointOfHires}
                                    isLookup={true}
                                    selectedItem={detail.pointOfHire}
                                    onChange={(item) => {
                                        setDetail({ ...detail, pointOfHire: item, pointOfHireId: item ? item.lookupId : null });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <Label>
                                    Desired Entity
                                    <span className="text-danger">*</span>
                                </Label>
                                <DropdownBox
                                    data={companies}
                                    isLookup={true}
                                    selectedItem={detail.company}
                                    onChange={(item) => {
                                        setDetail({ ...detail, company: item, companyId: item ? item.lookupId : null });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={4}>
                            <FormGroup>
                                <Label>
                                    Reporting To
                                    <span className="text-danger">*</span>
                                </Label>
                                {/* <Input
                                    name="reportingToName"
                                    id="reportingToName"
                                    type="text"
                                    required
                                    value={detail.reportingToName ?? ""}
                                    onChange={handleTextChanged}
                                /> */}
                                <ComboBox
                                    minLength={1}
                                    endpoint={`/recruitmentrequest/searchusers`}
                                    //accessToken={token?.accessToken}
                                    isLookup={false}
                                    idField="email"
                                    valueField="email"
                                    selectedItem={detail.reportingToApiResult}
                                    onChange={(item) => {
                                        // console.log(item);
                                        setDetail({ ...detail, reportingToName: item?.email ?? null, reportingToApiResult: item });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mt-3 mb-4">
                        <Col xs={12} className="bg-secondary border-bottom">
                            <span className="h6">Rehire List</span>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs={12}>
                            <FormGroup className='mb-0 pb-0'>
                                <Row>
                                    <Col sm={12} className="pt-0 pb-2">
                                        <Button
                                            id="addnew"
                                            className="btn-icon"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={() => { setChooseRehire(true); }}
                                        >
                                            <i className="fas fa-plus pt-1 mr-1" />New Rehire
                                        </Button>
                                    </Col>
                                </Row>
                                {/* <Grid
                                    height='auto'
                                    rows={rowsRehire}
                                    tableProps={getTablePropsRehire()}
                                    headerGroups={headerGroupsRehire}
                                    tableBodyProps={getTableBodyPropsRehire()}
                                    prepareRow={prepareRowRehire}
                                /> */}
                                <Container fluid className="m-0 p-0 mt-1 py-2">
                                    <div className="ag-theme-alpine ag-grid" style={{ width: '100%', height: '200px' }}>
                                        <AgGridReact
                                            ref={rehireListGridRef}
                                            columnDefs={rehireColumnDefs}
                                            defaultColDef={defaultColDef}
                                            loadingOverlayComponent={loadingOverlayComponent}
                                            suppressColumnVirtualisation={false}
                                            suppressRowVirtualisation={false}
                                            debounceVerticalScrollbar={false}
                                            rowBuffer={20}
                                            headerHeight={30}
                                            groupHeaderHeight={30}
                                            rowHeight={35}
                                            floatingFiltersHeight={30}
                                            suppressDragLeaveHidesColumns={true}
                                            suppressColumnMoveAnimation={true}
                                            animateRows={false}
                                            pagination={false}
                                            rowClass={'ag-row-normal'}
                                            suppressContextMenu={true}
                                            rowData={dataRehire ?? []}
                                            onGridReady={onRehireGridReady}
                                        />
                                    </div>
                                </Container>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mt-4 mb-3">
                        <Col xs={12} className="bg-secondary border-bottom">
                            <span className="h6">Scope of Work / Roster Details</span>
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-3">
                        <Col xs={2}>
                            <FormGroup>
                                <Label>
                                    Ordinary Hours / Week
                                </Label>
                                <Input
                                    name="hoursPerWeek"
                                    id="hoursPerWeek"
                                    type="number"
                                    required
                                    value={detail.hoursPerWeek ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={2}>
                            <FormGroup>
                                <Label>
                                    Hours per Day
                                </Label>
                                <Input
                                    name="hoursPerDay"
                                    id="hoursPerDay"
                                    type="number"
                                    required
                                    value={detail.hoursPerDay ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={2}>
                            <FormGroup>
                                <Label>Travel Type</Label>
                                {/* <RadioGroup
                                    items={siteTravelTypes}
                                    name="siteTravelTypeId"
                                    handleChange={handleLookupChange}
                                    selected={detail.siteTravelTypeId}
                                /> */}
                                <DropdownBox
                                    data={siteTravelTypes}
                                    idField="lookupId"
                                    valueField="lookupValue"
                                    selectedItem={detail?.siteTravelType}
                                    onChange={(type) => {
                                        setDetail({ ...detail, siteTravelType: type, siteTravelTypeId: type ? type.lookupId : null });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        {/* <Col xs={2}>
                            <FormGroup>
                                <Label>Roster</Label>
                                {/* <RadioGroup
                                    items={rosters}
                                    name="rosterId"
                                    handleChange={handleLookupChange}
                                    selected={detail.rosterId}
                                /> */}{ /*
                                <DropdownBox
                                    data={rosters}
                                    idField="lookupId"
                                    valueField="lookupValue"
                                    selectedItem={detail?.roster}
                                    onChange={(type) => {
                                        setDetail({ ...detail, roster: type, rosterId: type ? type.lookupId : null });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        */ }
                        <Col xs={2}>
                            <FormGroup>
                                <Label>Roster Sequence</Label>
                                <DropdownBox
                                    data={rosterSequences}
                                    idField="rosterSequenceId"
                                    valueField="description"
                                    selectedItem={detail?.rosterSequence}
                                    onChange={(type) => {
                                        setDetail({ ...detail, rosterSequence: type, rosterSequenceId: type ? type.rosterSequenceId : null });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label>
                                    Expected Start Time
                                </Label>
                                <Input
                                    name="expectedStartTime"
                                    id="expectedStartTime"
                                    type="time"
                                    value={detail.expectedStartTime ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label>
                                    Expected Finish Time
                                </Label>
                                <Input
                                    name="expectedFinishTime"
                                    id="expectedFinishTime"
                                    type="time"
                                    value={detail.expectedFinishTime ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                    {
                        detail?.employmentCategory && detail.employmentCategory.lookupValue === "White Collar" ?
                        <>
                            <Col xs={2}>
                                <FormGroup>
                                    <Label>
                                        Max Salary/Rate (From)
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        name="maxSalaryFrom"
                                        id="maxSalaryFrom"
                                        type="number"
                                        required
                                        value={detail.maxSalaryFrom ?? ""}
                                        onChange={handleTextChanged}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={2}>
                                <FormGroup>
                                    <Label>
                                        Max Salary/Rate (To)
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        name="maxSalaryTo"
                                        id="maxSalaryTo"
                                        type="number"
                                        required
                                        value={detail.maxSalaryTo ?? ""}
                                        onChange={handleTextChanged}
                                    />
                                </FormGroup>
                            </Col>
                        </>
                        :
                        null
                    }
                        {/* <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Flat or Penalties
                                    <span className="text-danger">*</span>
                                </Label>
                                <RadioGroup
                                    items={payProcessTypes}
                                    name="payProcessTypeId"
                                    colWidth={3}
                                    handleChange={handleLookupChange}
                                    selected={detail.payProcessTypeId}
                                />
                            </FormGroup>
                        </Col> */}
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Days per Week
                                </Label>
                                <CheckGroup
                                    flags
                                    items={DAYS_OF_WEEK}
                                    handleChange={handleDaysPerWeekChange}
                                    name="daysPerWeek"
                                    selected={detail.daysPerWeek}
                                    colWidth={0}
                                />
                                {/* <div>
                                    {Object.keys(DAYS_OF_WEEK).map((day, index) => {
                                        const value = DAYS_OF_WEEK[day];
                                        return (
                                            <div className="custom-control custom-checkbox custom-control-inline" key={index}>
                                                <input type="checkbox" className="custom-control-input" id={`daysOfWeek_${day}`} name="_daysPerWeek" value={value} 
                                                checked={(detail.daysPerWeek & DAYS_OF_WEEK[day]) === DAYS_OF_WEEK[day]}
                                                onChange={handleDaysPerWeekChange} />
                                                <label className="custom-control-label" htmlFor={`daysOfWeek_${day}`}>
                                                    {day}
                                                </label>
                                            </div>
                                        )
                                    })}
                                    
                                </div> */}
                            </FormGroup>
                        </Col>
                        <Col xs={4}>
                            <FormGroup>
                                <Label>
                                    Work Location
                                </Label>
                                <Input
                                    name="workLocation"
                                    id="workLocation"
                                    type="text"
                                    value={detail.workLocation ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={8}>
                            <FormGroup>
                                <Label>
                                    Scope of Work / Additional Project Information / Justification
                                </Label>
                                <Input
                                    name="scopeOfWork"
                                    id="scopeOfWork"
                                    type="textarea"
                                    value={detail.scopeOfWork ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        {
                            recruitmentType?.includes("SG&A") &&
                            <>
                                <Col xs={3}>
                                    <FormGroup>
                                        <Label>
                                            New or Replacement Position
                                        </Label>
                                        {/* <RadioGroup
                                        items={newOrReplacements}
                                        name="newOrReplacementId"
                                        colWidth={3}
                                        handleChange={({ target: { value } }) => {
                                            const newOrReplacementId = value * 1
                                            const type = _.find(newOrReplacements, (item) => item.lookupId === newOrReplacementId);
                                            setDetail({ ...detail, newOrReplacement: type, newOrReplacementId });
                                        }}
                                        selected={detail.newOrReplacementId}
                                    /> */}
                                        <DropdownBox
                                            data={newOrReplacements}
                                            idField="lookupId"
                                            valueField="lookupValue"
                                            selectedItem={detail.newOrReplacement}
                                            onChange={(type) => {
                                                setDetail({ ...detail, newOrReplacement: type, newOrReplacementId: type ? type.lookupId : null });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                {
                                    detail.newOrReplacement?.lookupValue === "Replacement" &&
                                    <Col xs={3}>
                                        <FormGroup>
                                            <Label>
                                                Replacement For
                                            </Label>
                                            <Input
                                                name="replacementForName"
                                                id="replacementForName"
                                                type="text"
                                                required
                                                value={detail.replacementForName ?? ""}
                                                onChange={handleTextChanged}
                                            />
                                        </FormGroup>
                                    </Col>
                                }
                            </>
                        }
                        {
                            (recruitmentType?.includes("SG&A") || recruitmentType?.includes("Cost of Sales")) &&
                            <Col xs={6}>
                                <FormGroup>
                                    <Label>
                                        Detailed Justification
                                    </Label>
                                    <Input
                                        name="detailedJustification"
                                        id="detailedJustification"
                                        type="textarea"
                                        required
                                        value={detail.detailedJustification ?? ""}
                                        onChange={handleTextChanged}
                                    />
                                </FormGroup>
                            </Col>
                        }
                    </Row>
                    {/* <Col xs={12}>
                            <FormGroup>
                                <Label>Shift</Label>
                                <RadioGroup 
                                    items={shifts} 
                                    name="shiftId" 
                                    handleChange={handleLookupChange} 
                                    selected={detail.shiftId}
                                />
                            </FormGroup>
                        </Col> */}
                    {/* <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Additional Project Information
                                </Label>
                                <Input
                                    name="additionalProjectInfo"
                                    id="additionalProjectInfo"
                                    type="textarea"
                                    value={detail.additionalProjectInfo ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col> */}
                    {/* <Col xs={12}>
                            <FormGroup>
                                <Label>Advertising to be approved by external Client</Label>
                                <RadioGroup 
                                    items={[
                                        {lookupId: true, lookupValue: "Yes"}, 
                                        {lookupId: false, lookupValue: "No"}
                                    ]} 
                                    name="advertisingApprovalByExternalClient" 
                                    handleChange={({ target: { name, value } }) => setDetail({ ...detail, [name]: value === "true" })} 
                                    selected={detail.advertisingApprovalByExternalClient}
                                />
                            </FormGroup>
                        </Col> */}
                    {/* <Col xs={12}>
                            <FormGroup>
                                <Label>Flights to be booked by</Label>
                                <RadioGroup 
                                    items={flightBooked} 
                                    name="flightBookedById" 
                                    handleChange={handleLookupChange} 
                                    selected={detail.flightBookedById}
                                    colWidth={3}
                                />
                            </FormGroup>
                        </Col> */}
                    {/* <Col xs={12}>
                            <FormGroup>
                                <Label>Accommodation</Label>
                                <RadioGroup
                                    items={accomodationTypes}
                                    name="accomodationTypeId"
                                    handleChange={handleLookupChange}
                                    selected={detail.accomodationTypeId}
                                    colWidth={3}
                                />
                            </FormGroup>
                        </Col> */}
                    {/* <Col xs={12}>
                            <FormGroup>
                                <Label>Meals</Label>
                                <RadioGroup 
                                    items={mealsProvider} 
                                    name="mealsProviderId" 
                                    handleChange={handleLookupChange} 
                                    selected={detail.mealsProviderId}
                                    colWidth={3}
                                />
                            </FormGroup>
                        </Col> */}
                    {/* <Col xs={12}>
                            <FormGroup>
                                <Label>Transfers</Label>
                                <RadioGroup 
                                    items={transferTypes} 
                                    name="transfersTypeId" 
                                    handleChange={handleLookupChange} 
                                    selected={detail.transfersTypeId}
                                    colWidth={4}
                                /> */}

                    {/* <CheckGroup 
                                    items={transferTypes}
                                    handleChange={({ target: { name, value } }) => {
                                        console.log("check group transfer types: ", name, value);
                                        setDetail({ ...detail, transfersTypeIds: [..._.uniq([ ...(detail.transfersTypeIds || []), value*1 ])] })
                                    }}
                                    name="transfersTypeIds"
                                    selected={detail.transfersTypeIds}
                                    colWidth={4}
                                /> */}
                    {/* </FormGroup>
                        </Col> */}
                    {/* <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Notes/Comments
                                </Label>
                                <Input
                                    name="mobilisationNotes"
                                    id="mobilisationNotes"
                                    type="textarea"
                                    value={detail.mobilisationNotes ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col> */}
                    <Row>
                        {
                            detail.employmentCategory && detail.employmentCategory.lookupValue !== "Blue Collar" &&
                            <>
                                <Col xs={2}>
                                    <FormGroup>
                                        <Label>Computer Requirement</Label>
                                        {/* <RadioGroup
                                        items={computerRequirements}
                                        name="computerRequirementId"
                                        handleChange={handleLookupChange}
                                        selected={detail.computerRequirementId}
                                    /> */}
                                        <DropdownBox
                                            data={computerRequirements}
                                            idField="lookupId"
                                            valueField="lookupValue"
                                            selectedItem={detail?.computerRequirement}
                                            onChange={(type) => {
                                                setDetail({ ...detail, computerRequirement: type, computerRequirementId: type ? type.lookupId : null });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={2}>
                                    <FormGroup>
                                        <Label>Phone Requirement</Label>
                                        {/* <RadioGroup
                                        items={phoneRequirements}
                                        name="phoneRequirementId"
                                        handleChange={handleLookupChange}
                                        selected={detail.phoneRequirementId}
                                    /> */}
                                        <DropdownBox
                                            data={phoneRequirements}
                                            idField="lookupId"
                                            valueField="lookupValue"
                                            selectedItem={detail?.phoneRequirement}
                                            onChange={(type) => {
                                                setDetail({ ...detail, phoneRequirement: type, phoneRequirementId: type ? type.lookupId : null });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </>
                        }
                        {
                            itsystemAccesses && itsystemAccesses.length &&
                            <Col xs={12}>
                                <FormGroup>
                                    <Label>System Access Required</Label>
                                    <Container fluid className="mt-1 py-2 border border-dark">
                                        {
                                            itsystemAccesses.map((d, idx) => {
                                                return (
                                                    <Row key={`itaccess_${idx}`} className="mb-1">
                                                        <Col md="1" className="p-0 m-0 d-flex flex-wrap justify-content-center align-items-center">
                                                            <Input
                                                                className="m-0 p-0"
                                                                type="checkbox"
                                                                name="itsystemAccessId"
                                                                id="itsystemAccessId"
                                                                onChange={({ target: { checked } }) => {
                                                                    // console.log(checked);

                                                                    const newAccesses = detail.requestDetailItsystemAccesses ?? [];

                                                                    if (checked) {
                                                                        newAccesses.push({
                                                                            requestDetailItsystemAccessId: 0,
                                                                            itsystemAccess: d,
                                                                            itsystemAccessId: d.itsystemAccessId
                                                                        });
                                                                    }

                                                                    if (!checked) {
                                                                        const removedItem = newAccesses.filter(r => r.itsystemAccessId === d.itsystemAccessId)[0];
                                                                        const removedIndex = newAccesses.indexOf(removedItem);
                                                                        newAccesses.splice(removedIndex, 1);
                                                                    }

                                                                    setDetail({
                                                                        ...detail,
                                                                        requestDetailItsystemAccesses: [...newAccesses]
                                                                    })
                                                                }}
                                                                checked={detail.requestDetailItsystemAccesses && detail.requestDetailItsystemAccesses.filter(r => r.itsystemAccessId === d.itsystemAccessId).length}
                                                            />
                                                        </Col>
                                                        <Col md="4" className="p-0 m-0 d-flex flex-wrap align-items-center">
                                                            <Label
                                                                className="align-middle p-0 m-0"
                                                            >
                                                                {
                                                                    d.systemName
                                                                }
                                                            </Label>
                                                        </Col>
                                                        <Col md="7" className="p-0 pr-2 m-0 d-flex flex-wrap align-items-center">
                                                            <Input
                                                                className="m-0 p-0"
                                                                type="text"
                                                                name="itsystemAccessNotes"
                                                                id="itsystemAccessNotes"
                                                                value={detail?.requestDetailItsystemAccesses?.filter(r => r.itsystemAccessId === d.itsystemAccessId)[0]?.notes ?? ""}
                                                                onChange={({ target: { value } }) => {
                                                                    // console.log(value);

                                                                    const newAccesses = detail.requestDetailItsystemAccesses ?? [];
                                                                    const removedItem = newAccesses.filter(r => r.itsystemAccessId === d.itsystemAccessId)[0];
                                                                    const removedIndex = newAccesses.indexOf(removedItem);
                                                                    removedItem.notes = value;
                                                                    newAccesses.splice(removedIndex, 1, removedItem);

                                                                    setDetail({
                                                                        ...detail,
                                                                        requestDetailItsystemAccesses: [...newAccesses]
                                                                    })
                                                                }}
                                                                disabled={!detail.requestDetailItsystemAccesses || !detail.requestDetailItsystemAccesses.filter(r => r.itsystemAccessId === d.itsystemAccessId).length}
                                                                autoComplete="off"
                                                            />
                                                        </Col>
                                                    </Row>
                                                );
                                            })
                                        }
                                    </Container>
                                </FormGroup>
                            </Col>
                        }
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={props.close}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={handleSave}
                    type="button"
                    disabled={(status && ((!isUserAdmin && status.lookupValue === PNM_StatusPending) || (!isApproval && status.lookupValue.toLowerCase().includes("pending") && status.lookupValue !== PNM_StatusPending && !request?.userIsHrDirector) || status.lookupValue === PNM_StatusApproved || status.lookupValue === PNM_StatusRejected || status.lookupValue === "Approved" || status.lookupValue === "In Progress" || status.lookupValue === "Void" || status.lookupValue === "Completed")) || (isApproval && status.lookupValue !== 'Pending Red Zone' && status.lookupValue !== "Pending Budget Sighting")}
                >
                    Save Changes
                </Button>
            </div>

        {
            validateDownloadJobDesc &&
            <SweetAlert
                danger
                confirmBtnText="OK"
                confirmBtnBsStyle="danger"
                title={<h4 className="mb-0">LRF</h4>}
                onConfirm={() => setValidateDownloadJobDesc(false)} // need to add loading indicator on confirm
                onCancel={() => setValidateDownloadJobDesc(false)}
                focusCancelBtn
                >
                    <p className="text-justify">
                        Job Description not set for the selected position: {detail.position?.lookupValue}.
                        Please click <u className="cursor-pointer" onClick={() => {
                            var empcat = !detail?.employmentCategoryId ? '' : detail.employmentCategoryId;
                            var filter = detail.position?.lookupValue;
                            window.open(`/maintenance/job descriptions/${Mode.ADD.toLowerCase()}?empcat=${empcat}&filter=${filter}`, '_blank');
                        }}>here</u> to setup new Job Description for {detail.position?.lookupValue}.
                    </p>
            </SweetAlert>
        }
        {
            showCurrentEmployeeBenefits &&
            <LabourRequestDetailEmployeeBenefitsModal
                requestDetailId={detail?.requestDetailId}
                onClose={() => setShowCurrentEmployeeBenefits(false)}
            />
        }
        </Modal>
    );
}