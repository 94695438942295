import React from 'react';
import { useTable, useFlexLayout, useRowSelect } from 'react-table';
import {
    Button
} from "reactstrap";
import { IndeterminateCheckbox } from "../../../react-table/controls";
import { TooltipButton } from '../../../inputs';

export const MoveDir = {
    UP: 2,
    DOWN: 1
}

export function PositionsTable({ data, onSelect }) {


    var makeString = (arr) => {
        if (arr.length === 1) return arr[0];
        const firsts = arr.slice(0, arr.length - 1);
        const last = arr[arr.length - 1];
        return firsts.join(', ') + ' and ' + last;
    }

    const columns = React.useMemo(() => [
        {
            Header: 'Position Title',
            accessor: 'lookupValue',

            Cell: ({ row }) => {
                const { original } = row;
                // console.log({row, original});

                const rehireNameList = !original.requestDetailRehires ? [] : original.requestDetailRehires.map((v) => v.candidateName);
                const nameWordsStr = !rehireNameList?.length ? `` : `(${makeString(rehireNameList)})`;
                const nameWords = () => {
                    return (<div className="col-sm-12">{!rehireNameList?.length ? `` : `(${makeString(rehireNameList)})`}</div>);
                };
                // console.log('rehireNameList', rehireNameList);
                // console.log('nameWords', nameWords);
                // console.log('row', row);

                return (
                    <div className="row">
                        <div className="col-sm-12" title={nameWordsStr}>{original.lookupValue}</div>
                        {nameWords()}
                    </div>
                );
            },
        },
    ], []);

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 150,
            maxWidth: 200,
        }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        toggleRowSelected,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFlexLayout,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    minWidth: 35,
                    width: 35,
                    maxWidth: 35,
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} id={`position-header`} />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} id={`position-${row.id}`} />
                        </div>
                    ),
                },
                ...columns,
            ]);
        });

    React.useEffect(() => {
        onSelect(selectedFlatRows.map(d => ({ ...d.original, selected: true })));
    }, [selectedFlatRows]);

    return (<>
        <label className="">Job Order Positions</label>
        <span className="text-danger">*</span>
        <div {...getTableProps()} className="table flex table-hover scrollable">
            <div className="thead">
                {headerGroups.map(headerGroup => {
                    return (<div {...headerGroup.getHeaderGroupProps()} className="tr">
                        {headerGroup.headers.map((column, index) => {
                            return (<div {...column.getHeaderProps()} className="th">
                                {column.render('Header')}
                            </div>);
                        })}
                        {/* <div className="th th-action-150 text-right">Actions</div> */}
                    </div>);
                }
                )}
            </div>
            <div {...getTableBodyProps()} className="tbody" style={{height: "35vh"}}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <div {...row.getRowProps()} className="tr">
                            {row.cells.map(cell => {

                                return <div {...cell.getCellProps({
                                    onClick: cell.column.id !== "selection" ? () => toggleRowSelected(row.id) : undefined
                                })}
                                    className={`td ${cell.column.id !== "selection" ? "cursor-pointer" : ""}`}
                                >
                                    {cell.render('Cell')}
                                </div>;
                            })}

                            {
                                // <div className="td td-action-150 text-right">
                                //     <Button
                                //         className="btn-icon"
                                //         color="default"
                                //         size="sm"
                                //         type="button"
                                //         onClick={() => {}}
                                //     >
                                //         <i className="fas fa-chevron-right pt-1"></i>
                                //     </Button>
                                // </div>
                            }
                        </div>
                    );
                })}
            </div>
        </div>

    </>);
}

export function MilestonesTable({ data, onSelect, onAdd, hideLabel, height = null }) {
    const columns = React.useMemo(() => [
        {
            Header: 'Milestone',
            accessor: 'lookupValue',
        },
    ], []);

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 150,
            maxWidth: 200,
        }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        toggleRowSelected,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFlexLayout,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    minWidth: 35,
                    width: 35,
                    maxWidth: 35,
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} id={`milestone-header`} />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} id={`milestone-${row.id}`} />
                        </div>
                    ),
                },
                ...columns,
            ]);
        });

    React.useEffect(() => {
        onSelect(selectedFlatRows.map(d => ({ ...d.original, selected: true })));
    }, [selectedFlatRows]);

    return (<>
        {!hideLabel && <label className="">Available Milestones</label>}
        <div {...getTableProps()} className="table flex table-hover scrollable">
            <div className="thead">
                {headerGroups.map(headerGroup => {
                    return (<div {...headerGroup.getHeaderGroupProps()} className="tr">
                        {headerGroup.headers.map((column, index) => {
                            return (<div {...column.getHeaderProps()} className="th">
                                {column.render('Header')}
                            </div>);
                        })}
                        <div className="th th-action text-right" style={{ width: "77px" }}></div>
                    </div>);
                }
                )}
            </div>
            <div {...getTableBodyProps()} className="tbody" style={{height: height ?? "50vh"}}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <div {...row.getRowProps()} className="tr">
                            {row.cells.map(cell => {
                                return <div {...cell.getCellProps({
                                    onClick: cell.column.id !== "selection" ? () => toggleRowSelected(row.id) : undefined
                                })}
                                    className={`td ${cell.column.id !== "selection" ? "cursor-pointer" : ""}`}
                                >
                                    {cell.render('Cell')}
                                </div>;
                            })}

                            {
                                <div className="td td-action text-right" style={{ width: "60px" }}>
                                    <TooltipButton
                                        id={`add_${i}`}
                                        title="Add"
                                        className="btn-icon"
                                        color="default"
                                        size="sm"
                                        type="button"
                                        outline
                                        onClick={() => {
                                            onAdd(row.original)
                                        }}
                                    >
                                        <i className="fas fa-chevron-right"></i>
                                    </TooltipButton>
                                </div>
                            }
                        </div>
                    );
                })}
            </div>
        </div>

    </>);
}

export function JobOrderMilestonesTable({ data, onSelect, onRemove, onMoveUp, onMoveDown, hideLabel, height = null }) {
    const columns = React.useMemo(() => [
        {
            Header: 'Milestone',
            accessor: 'lookupValue',
        },
    ], []);

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 150,
            maxWidth: 200,
        }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        toggleRowSelected,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFlexLayout,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    minWidth: 35,
                    width: 35,
                    maxWidth: 35,
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} id={`joborder-header`} />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} id={`joborder-${row.id}`} />
                        </div>
                    ),
                },
                ...columns,
            ]);
        });

    React.useEffect(() => {
        onSelect(selectedFlatRows.map(d => ({ ...d.original, selected: true })));
    }, [selectedFlatRows]);

    return (<>
        {!hideLabel && <><label className="">Job Order Milestones</label><span className="text-danger">*</span></>}
        <div {...getTableProps()} className="table flex table-hover scrollable">
            <div className="thead">
                {headerGroups.map(headerGroup => {
                    return (<div {...headerGroup.getHeaderGroupProps()} className="tr">

                        {headerGroup.headers.map((column, index) => {
                            return (<div {...column.getHeaderProps()} className="th">
                                {column.render('Header')}
                            </div>);
                        })}
                        <div className="th th-action-150 text-right"></div>
                    </div>);
                }
                )}
            </div>
            <div {...getTableBodyProps()} className="tbody" style={{height: height ?? "43vh"}}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <div {...row.getRowProps()} className={`tr${row.original.isUsed ? " bg-grey-light" : ""}`}>

                            {row.cells.map(cell => {
                                return <div {...cell.getCellProps({
                                    onClick: cell.column.id !== "selection" ? () => toggleRowSelected(row.id) : undefined
                                })}
                                    className={`td ${cell.column.id !== "selection" ? "cursor-pointer" : ""}`}
                                >
                                    {cell.render('Cell')}
                                </div>;
                            })}

                            {
                                <div className="td td-action-150 text-right">
                                    <TooltipButton
                                        id={`revove_${i}`}
                                        title="Remove"
                                        className="btn-icon"
                                        color="default"
                                        size="sm"
                                        type="button"
                                        outline
                                        disabled={row.original.isUsed}
                                        onClick={() => {
                                            onRemove(row.original)
                                        }}
                                    >
                                        <i className="fas fa-chevron-left"></i>
                                    </TooltipButton>
                                    <TooltipButton
                                        id={`up_${i}`}
                                        title="Move Up"
                                        className="btn-icon"
                                        color="default"
                                        size="sm"
                                        type="button"
                                        outline
                                        disabled={row.original.order === 1}
                                        onClick={() => {
                                            onMoveUp(row.original)
                                        }}
                                    >
                                        <i className="fas fa-chevron-up"></i>
                                    </TooltipButton>
                                    <TooltipButton
                                        id={`down_${i}`}
                                        title="Move Down"
                                        className="btn-icon"
                                        color="default"
                                        size="sm"
                                        type="button"
                                        outline
                                        disabled={row.original.order === rows.length}
                                        onClick={() => {
                                            onMoveDown(row.original)
                                        }}
                                    >
                                        <i className="fas fa-chevron-down"></i>
                                    </TooltipButton>
                                </div>
                            }
                        </div>
                    );
                })}
            </div>
        </div>

    </>);
}