import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import Loader from '../../../loaders';
import api from "../../../../services/api";
import { DatePicker } from '../../../date-pickers';
import { formatDate } from '../../../../utils';
import SweetAlert from 'react-bootstrap-sweetalert';
import { DropdownBox } from '../../../dropdowns';

export default function DefinitivOnboardingEditor(props) {
    const {
        selectedCandidates,
        onClose,
        onSaved,
        companies
    } = props;
    
    const [ loading, setLoading ] = React.useState(false);
    const [ definitivOnboardingCompany, setDefinitivOnboardingCompany ] = useState(null);
    const [ definitivOnboardingStartDate, setDefinitivOnboardingStartDate ] = useState(null);
    const [ validationItem, setValidationItem ] = useState(null);

    const handleSendToDefinitivOnboarding = useCallback(() => {
        if (!selectedCandidates?.length)
        {
            setValidationItem({
                title: "Empty Talent",
                message: "Please pick a talent."
            })
            return;
        }
        
        if (!definitivOnboardingCompany?.lookupId)
        {
            setValidationItem({
                title: "Empty Entity",
                message: "Please select the entity for the selected talents."
            })
            return;
        }
        
        if (!definitivOnboardingStartDate)
        {
            setValidationItem({
                title: "Empty Start Date",
                message: "Please select the start date for the selected talents."
            })
            return;
        }

        setLoading(true);

        const data = {
            candidateId: selectedCandidates.map(c => c.candidateId),
            designatedCompanyId: definitivOnboardingCompany.lookupId,
            hireDate: definitivOnboardingStartDate
        };

        api.post(`/joborder/definitivonboarding`, JSON.stringify(data))
            .then(() => {
                setLoading(false);
                onSaved();
            })
            .catch((error) => {
                console.log({error});
                setLoading(false);
                setValidationItem({
                    title: "System Error",
                    message: error.response.status >= 500 ? "Internal Error" : error.response.data
                })
            })
        ;
    }, [selectedCandidates, definitivOnboardingCompany, definitivOnboardingStartDate]);

    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
            {(loading) && <Loader />}
            {
                validationItem &&
                <SweetAlert
                    title={validationItem.title}
                    error
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    onConfirm={() => setValidationItem(null)}
                >
                {
                    validationItem.message
                }
                </SweetAlert>
            }
            <div
                className="modal-header"
            >
                <h5 className="modal-title">Definitiv Onboarding Details</h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={() => onClose()}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body" style={{height: 'calc(100vh - 500px)'}}
            >
                <Container fluid>
                    <Row>
                        <Col xs={12} className="pl-2">
                            <FormGroup>
                                <Label>
                                    Selected Candidates
                                </Label>
                                <Input
                                    type="textarea"
                                    value={selectedCandidates.map(c => c.candidateName).join(", ")}
                                    onChange={() => {}}
                                    disabled={true}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="pl-2">
                            <FormGroup>
                                <Label>
                                    Designated Entity
                                    <span className="text-danger">*</span>
                                </Label>
                                <DropdownBox
                                    data={companies}
                                    selectedItem={definitivOnboardingCompany}
                                    onChange={(company) => {
                                        setDefinitivOnboardingCompany(company);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6} className="pl-2">
                            <FormGroup>
                                <Label>
                                    Start Date
                                    <span className="text-danger">*</span>
                                </Label>
                                <DatePicker
                                    name="startDate"
                                    id="startDate"
                                    type="text"
                                    required
                                    value={formatDate(definitivOnboardingStartDate)}
                                    onChange={(value) => !value ? null : setDefinitivOnboardingStartDate(formatDate(value, "YYYY/MM/DD"))}
                                    closeOnSelect
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={() => onClose()}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSendToDefinitivOnboarding()}
                    type="button"
                >
                    Send
                </Button>
            </div>
        </Modal>
    );
};