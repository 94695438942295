/* eslint-disable default-case */
const today = new Date();

export const initialState = {
    dispatchUnit: null,
    startDate: new Date(today.getFullYear(), today.getMonth(), 1),
    endDate: new Date(today.getFullYear(), today.getMonth() + 6, 1),
    trimStartDate: true,
    trimEndDate: true,
    showArchivedDispatchUnits: false,
    dataLoaded: false,
    dispatchEditorOpen: false,
    notification: null,
    contingencyOpen: false,
};

export const actions = {
    SET_PROJECT: "SET_PROJECT",
    SET_DISPATCH: "SET_DISPATCH",
    SET_START: "SET_START",
    SET_TRIM_START: "SET_TRIM_START",
    SET_END: "SET_END",
    SET_TRIM_END: "SET_TRIM_END",
    SET_SHOW_ARCHIVED: "SET_SHOW_ARCHIVED",
    SET_DATA_LOADED: "SET_DATA_LOADED",
    SET_DATA_EXISTS: "SET_DATA_EXISTS",
    SET_DISPATCH_EDITOR_OPEN: "SET_DISPATCH_EDITOR_OPEN",
    SET_NOTIFICATION: "SET_NOTIFICATION",
    SET_CONTINGENCY_OPEN: "SET_CONTINGENCY_OPEN",
}

const rosterReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case actions.SET_PROJECT:
            return {
                ...state,
                project: {...payload.project},
            }
        case actions.SET_DISPATCH:
            return {
                ...state,
                dispatchUnit: {...payload.dispatchUnit},
            }
        case actions.SET_START:
            return {
                ...state,
                startDate: payload.startDate,
            }
        case actions.SET_TRIM_START:
            return {
                ...state,
                trimStartDate: payload.trimStartDate,
            }
        case actions.SET_END:
            return {
                ...state,
                endDate: payload.endDate,
            }
        case actions.SET_TRIM_END:
            return {
                ...state,
                trimEndDate: payload.trimEndDate,
            }
        case actions.SET_SHOW_ARCHIVED:
            return {
                ...state,
                showArchivedDispatchUnits: payload.showArchivedDispatchUnits,
            }
        case actions.SET_DATA_LOADED:
            return {
                ...state,
                dataLoaded: payload.dataLoaded,
            }
        case actions.SET_DATA_EXISTS:
            return {
                ...state,
                dataExists: payload.dataExists,
            }
        case actions.SET_DISPATCH_EDITOR_OPEN:
            return {
                ...state,
                dispatchEditorOpen: payload.dispatchEditorOpen,
            }
        case actions.SET_NOTIFICATION:
            return {
                ...state,
                notification: payload.notification,
            }
        case actions.SET_CONTINGENCY_OPEN:
            return {
                ...state,
                contingencyOpen: payload.contingencyOpen,
            }
    }
}

export default rosterReducer;