import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    Progress
} from "reactstrap";
import moment from "moment";
import { TooltipButton } from '../../../inputs';
import api from '../../../../services/api';
import { DefaultColumnFilter } from '../../../react-table/filters';
import { useFlexLayout, useTable } from 'react-table';
import Grid from '../../../Grid';
import Loader from '../../../loaders';
import fileDownload from 'js-file-download';
import JobOrderCandidateEditor from '../../LabourRequest/JobOrder/JobOrderCandidateEditor';

export default function Flights(props) {
    const {
        itemInEdit,
        lookups,
    } = props;
    const skipPageResetRef = useRef();

    const [data, setData] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [jobOrderCandidate, setJobOrderCandidate] = React.useState(null);
    const [candidateId, setCandidateId] = useState(0);

    const refreshData = useCallback(() => {
        setData([]);

        if (!itemInEdit || !itemInEdit.candidateId)
            return;

        setLoading(true);
        api.get(`candidate/flights/${itemInEdit?.candidateId}`)
            .then(response => {
                setData(response.data.flights);
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
            ;
    }, [itemInEdit]);

    React.useEffect(() => {
        if ((itemInEdit?.candidateId ?? 0) === candidateId)
            return;
        
        setCandidateId(itemInEdit?.candidateId ?? 0);
    }, [itemInEdit?.candidateId]);

    useEffect(() => {
        refreshData();
    }, [candidateId]);

    const downloadAttachment = useCallback((id, type) => {
        setLoading(true);
        let url = `generatedcontract/download/${id}/${type}`;
        api.get(url, { responseType: 'blob' })
            .then(blob => {
                var filename = /(?<=attachment;.*filename=").*(?=";)/.exec(blob.headers["content-disposition"])[0]
                fileDownload(blob.data, filename);
            }).catch(error => {
                console.error(error)
            }).finally(() => setLoading(false))
    }, []);

    const columns = useMemo(() => [
        {
            Header: "Dispatch",
            accessor: (row) => row.dispatchUnitName,
            filter: "text",
            width: 100,
            disableSortBy: false,
        },
        {
            Header: "Roster",
            accessor: (row) => row.rosterName,
            filter: "text",
            width: 100,
            disableSortBy: false,
        },
        {
            Header: "Flight Date",
            accessor: (row) => row.departureFlightDateTime ? moment(row.departureFlightDateTime.slice(0, 10)).format(`dddd, DD MMM YYYY`) : null,
            filter: "text",
            width: 100,
            disableSortBy: false,
        },
        {
            Header: "Time",
            accessor: (row) => row.departureFlightDateTime ? row.departureFlightDateTime.slice(11,16) : null,
            filter: "text",
            width: 100,
            disableSortBy: false,
        },
        {
            Header: "Flight Type",
            accessor: (row) => row.rosterFlightType,
            filter: "text",
            width: 100,
            disableSortBy: false,
        },
        {
            Header: "Status",
            accessor: (row) => row.status,
            filter: "text",
            width: 100,
            disableSortBy: false,
        },
        {
            Header: "From",
            accessor: (row) => `${row.departureAirport} (Terminal ${row.departureAirportTerminal})` ,
            filter: "text",
            width: 100,
            disableSortBy: false,
        },
        {
            Header: "To",
            accessor: (row) => `${row.arrivalAirport} (Terminal ${row.arrivalAirportTerminal})`,
            filter: "text",
            width: 100,
            disableSortBy: false,
        },
        {
            Header: "Flight Number",
            accessor: (row) => row.flightNumber,
            filter: "text",
            width: 100,
            disableSortBy: false,
        }
    ], [data]);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
        disableFilters: true,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout
    );

    return (
        <>
            {loading && <Loader />}
            <Grid
                rows={rows}
                tableProps={getTableProps()}
                headerGroups={headerGroups}
                tableBodyProps={getTableBodyProps()}
                prepareRow={prepareRow}
                height="auto"
                style="min-height:40vh"
            />
            {
                itemInEdit && jobOrderCandidate &&
                <JobOrderCandidateEditor
                    jobOrderCandidateId={jobOrderCandidate.jobOrderCandidateId}
                    itemIndex={jobOrderCandidate.index}
                    onClose={(item) => {
                        setJobOrderCandidate(null);
                    }}
                    onSaved={(item) => {
                        setJobOrderCandidate(null);
                    }}
                    lookups={{
                        ...lookups
                    }}
                />
            }
        </>
    );
}