import React, { useEffect, useState } from 'react';
import {
    Input, Label, FormGroup, Button, Modal, Container, Row, Col, InputGroup, InputGroupAddon, InputGroupText, Alert
 } from "reactstrap"; 
import { DatePicker } from "../../date-pickers";
import { ComboBox, DropdownBox } from "../../dropdowns";
import { formatDate, formatDateZeroTime } from "../../../utils";

import { RadioGroup, CheckGroup } from "../../inputs";
import _ from "lodash";
import { trainingNeedsAnalysisValidation } from "./validation";
import Loader from "../../loaders";
import api from "../../../services/api";


export default function MatrixEditor(props) {

    const multiple = !!props.item.tnaTrainingMasterIds && !!props.item.tnaPositionIds;

    const [ itemInEdit, setItemInEdit ] = React.useState(props.item);    
    const [ validationAlert, setValidationAlert ] = React.useState([]);
    const [ loading, setLoading ] = React.useState(false);
    const [ timeUnits, setTimeUnits ] = useState([]);

    useEffect(() => {
        setLoading(true);
        api.get(`lookup/TNAExpiryRequirmentUnit`)
            .then((response) => {
                // console.log(response);
                setTimeUnits(response.data);
            })
            .catch((error) => {
                console.error(error.response);
            })
            .finally(() => setLoading(false));
    }, []);

    const handleDateChanged = (value, name) => {
        setItemInEdit({ ...itemInEdit, 
            [name]: formatDateZeroTime(value) //moment(value).format("YYYY-MM-DDT00:00:00") 
        });
    }
    
    const handleNumberChanged = ({ target: { value, name, min, max } }) => {
        const percent = value * 1;
        setItemInEdit({ ...itemInEdit, [name]: percent >= max ? max : percent <= min ? "" : percent });
    }
    
    const handleTextChanged = ({ target: { name, value } }) => {
        setItemInEdit({ ...itemInEdit, [name]: value });
    }

    const validate = React.useCallback(() => {
        if (multiple) return true;

        const ignoreValidationFields = ["positionId", "trainingMasterId", "trainingGroupId"];

        let errors = [ ...validationAlert ];
        _.forEach(trainingNeedsAnalysisValidation.filter(o => !ignoreValidationFields.includes(o.field)), (value) => {
            _.forEach(value.tests, (test) => {
                const exists = _.some(errors, (item) => item.key === value.field && item.type === test.type);
                const result = test.validate(itemInEdit);
                if (result && !exists) {
                    errors.push( { key: value.field, type: test.type, message: test.message } )
                } else if (!result && exists) {
                    _.remove(errors, (item) =>  item.key === value.field && item.type === test.type);
                }
            })
        });
        setValidationAlert([ ...errors ]);
        if (errors.length > 0) {
            return false;
        }
        return true;
    }, [itemInEdit, validationAlert]);

    React.useEffect(() => {
        if (itemInEdit && validationAlert.length > 0) validate();
    }, [itemInEdit]);

    const handleSave = () => {
        if (!validate()) {
            return;
        }

        setLoading(true);

        const data = JSON.stringify(itemInEdit);
        const save = multiple 
        ? api.put(`trainingneedanalysis/tnamatrix`, data) 
        : itemInEdit.trainingNeedAnalysisId > 0 
            ? api.put(`trainingneedanalysis/${itemInEdit.trainingNeedAnalysisId}`, data)
            : api.post(`trainingneedanalysis`, data)

        save
        .then((response) => {
            setLoading(false);
            props.onSaved();
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
        });

    }

    const {
        trainingGroups,
        //trainingMaster,
        fundingSources,
        trainingProviders,
        trainingRequirements
    } = props.lookups;

    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Title
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}>x</span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                {validationAlert && validationAlert.length > 0 && <Alert color="warning" className="pa-0 d-flex justify-content-between">
                    <span className="alert-text">
                        <ul className="mb-0">
                            {
                                validationAlert.map((item, index) => <li key={index}>{item.message}</li>)
                            }
                        </ul>
                    </span>
                </Alert>}
                <Container>
                    {(loading) && <Loader />}
                    <Row>
                        <Col xs={12}>
                            <Row className="mb-3">
                                <Col xs={2}>
                                    <Label>Position:</Label>
                                </Col>
                                <Col xs={10}>
                                    <Label className="font-weight-bold">{'\u00A0'}{multiple ? "Multiple" : itemInEdit.position?.lookupValue}</Label>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row className="mb-3">
                                <Col xs={2}>
                                    <Label>Training:</Label>
                                </Col>
                                <Col xs={10}>
                                    <Label className="font-weight-bold">{'\u00A0'}{multiple ? "Multiple" : itemInEdit.trainingMaster?.lookupValue}</Label>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row className="mb-3">
                                <Col xs={2}>
                                    <Label>Training Group:</Label>
                                </Col>
                                <Col xs={10}>
                                    <Label className="font-weight-bold">{'\u00A0'}{multiple ? "Multiple" : itemInEdit.trainingGroup?.lookupValue}</Label>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Training Requirement
                                    <span className="text-danger">*</span>
                                </Label>
                                <RadioGroup
                                    items={trainingRequirements}
                                    name="trainingRequirementId"
                                    valueField="lookupLabel"
                                    handleChange={({ target: { value } }) => {
                                        const trainingRequirementId = value * 1
                                        const type = _.find(trainingRequirements, (item) => item.lookupId === trainingRequirementId);
                                        setItemInEdit( { ...itemInEdit, trainingRequirement: type, trainingRequirementId } );
                                    }}
                                    selected={itemInEdit.trainingRequirementId}
                                    colWidth={4}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Expiry Requirement Value
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input 
                                    name="expiryRequirementValue"
                                    id="expiryRequirementValue"
                                    type="number"
                                    value={itemInEdit.expiryRequirementValue || ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Expiry Requirement Time Unit
                                    <span className="text-danger">*</span>
                                </Label>
                                <DropdownBox 
                                    data={timeUnits}
                                    isLookup={true}
                                    selectedItem={itemInEdit.expiryRequirementUnit}
                                    onChange={(val) => {
                                        setItemInEdit({...itemInEdit, expiryRequirementUnit: val, expiryRequirementUnitId: val ? val.lookupId : null});
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Tag
                                </Label>
                                    <Input 
                                        name="tag"
                                        id="percetagntRequired"
                                        type="text"
                                        value={itemInEdit.tag || ""}
                                        onChange={handleTextChanged}
                                    ></Input>
                            </FormGroup>
                        </Col>
                        {/* <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Training Provider
                                </Label>
                                <RadioGroup
                                    items={trainingProviders}
                                    name="trainingProviderId"
                                    valueField="lookupValue"
                                    handleChange={({ target: { value } }) => {
                                        const trainingProviderId = value * 1
                                        const type = _.find(trainingProviders, (item) => item.lookupId === trainingProviderId);
                                        setItemInEdit( { ...itemInEdit, trainingProvider: type, trainingProviderId } );
                                    }}
                                    selected={itemInEdit.trainingProviderId}
                                    colWidth={3}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Funding Source
                                </Label>
                                <RadioGroup
                                    items={fundingSources}
                                    name="fundingSourceId"
                                    handleChange={({ target: { value } }) => {
                                        const fundingSourceId = value ? value * 1 : null;
                                        const type = _.find(fundingSources, (item) => item.lookupId === fundingSourceId);
                                        setItemInEdit( { ...itemInEdit, fundingSource: type, fundingSourceId } );
                                    }}
                                    selected={itemInEdit.fundingSourceId}
                                    colWidth={3}
                                    hasNA
                                />
                            </FormGroup>
                        </Col> */}
                        {/* <Col xs={12} md={6}>
                            <FormGroup>
                                <Label>
                                    Start Date
                                    <span className="text-danger">*</span>
                                </Label>
                                <DatePicker
                                    name="startDate"
                                    id="startDate"
                                    type="text"
                                    required
                                    value={formatDate(itemInEdit.startDate)}
                                    onChange={handleDateChanged}
                                    closeOnSelect                                
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label>
                                    End Date
                                </Label>
                                <DatePicker
                                    name="endDate"
                                    id="endDate"
                                    type="text"
                                    required
                                    value={formatDate(itemInEdit.endDate)}
                                    onChange={handleDateChanged}
                                    closeOnSelect                                
                                />
                            </FormGroup> 
                        </Col> */}
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Percentage
                                </Label>
                                <InputGroup className="mb-4">
                                    <Input 
                                        name="percentRequired"
                                        id="percentRequired"
                                        type="number"
                                        max={100}
                                        min={0}
                                        value={itemInEdit.percentRequired || ""}
                                        onChange={handleNumberChanged}
                                    ></Input>
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <i className="fas fa-percent"></i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    className="mr-auto"
                    color="default"
                    onClick={props.onClear}
                    type="button"
                >
                    Clear
                </Button>

                <Button
                    color="secondary"
                    onClick={props.onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={handleSave}
                    type="button"
                >
                    Save Changes
                </Button>
            </div>
        </Modal>
    )
}