import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import {
    Container, Row, Col, Card, CardBody, CardHeader, Label, FormGroup, Button, Input, Collapse
} from "reactstrap";
import { DropdownBox, ComboBox } from "../../dropdowns";
import { DatePicker } from "../../date-pickers";
import { formatDate } from "../../../utils";
import qs from "qs";
import api from "../../../services/api";
import Loader, { Save } from "../../loaders";
import Handsontable from "handsontable";
import moment from "moment";
import { HotTable, HotColumn, BaseEditorComponent } from "@handsontable/react";
import {
    alignHeaders
} from "./hooksCallbacks";
import _ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import 'handsontable/dist/handsontable.full.css';
import ErrorNotification from "../../alerts/ErrorNotification";

const ForecastColumnRenderer = (props) => {
    const { value, TD } = props;

    if (value && value.fte) {
        Handsontable.dom.addClass(
            TD,
            value.hasRoster ? 'data-cell has-roster' : 'data-cell'
        );
    }
    return (<>{value?.fte}</>);
};
Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

Date.prototype.getWeek = function () {
    const fiscalyear = (this.getMonth() + 1) <= 8 ? this.getFullYear() - 1 : this.getFullYear();
    const fyStart = new Date(fiscalyear, 8, 1);

    const diff = this - fyStart + 1;
    var dayOfYear = (diff / 86400000);
    return { week: Math.ceil(dayOfYear / 7), fy: fiscalyear };
};

function RequiredAlert(props) {
    const {
        onConfirm
    } = props;

    return (
        <SweetAlert
            title={<h4 className="mb-0">Department is required.</h4>}
            onConfirm={onConfirm}
            type="danger"
        >
            <div className="mb-2">
                Please select a department to continue.
            </div>
        </SweetAlert>
    );
}

function HasRosterAlert(props) {
    const {
        onConfirm
    } = props;

    return (
        <SweetAlert
            title="Selected rows contain rostered schedules."
            onConfirm={onConfirm}
            type="danger"
        >
            <div className="mb-2">
                Please select another schedules.
            </div>
        </SweetAlert>
    );
}

function Forcast(props) {
    const location = useLocation();
    const initFilters = location.state;

    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [output, setOutput] = React.useState([]);
    const hotTableComponent = useRef(null);
    const [data, setData] = React.useState(null);
    const [columns, setColumns] = React.useState(null);
    const [hotCols, setHotCols] = React.useState(null);
    const [hotInfo, setHotInfo] = React.useState(null);

    const date = new Date();

    const today = new Date();

    const [start, setStart] = React.useState(moment(!!initFilters?.start ? initFilters?.start : new Date(today.getFullYear(), today.getMonth(), 1)));
    const [end, setEnd] = React.useState(moment(!!initFilters?.end ? initFilters?.end : new Date(today.getFullYear(), today.getMonth() + 3, 0)));
    const [project, setProject] = React.useState(!!initFilters?.project ? initFilters?.project : {});
    const [department, setDepartment] = React.useState(!!initFilters?.department ? initFilters?.department : {});
    const [state, setState] = React.useState(!!initFilters?.state ? initFilters?.state : {});
    const [client, setClient] = React.useState(!!initFilters?.client ? initFilters?.client : {});
    const [scope, setScope] = React.useState(!!initFilters?.scope ? initFilters?.scope : {});
    const [manager, setManager] = React.useState(!!initFilters?.manager ? initFilters?.manager : {});
    const history = useHistory();
    const [lookups, setLookups] = React.useState({});
    const [clients, setClients] = React.useState([]);
    const [projects, setProjects] = React.useState([]);
    const [departments, setDepartments] = React.useState([]);
    const [states, setStates] = React.useState([]);
    const [scopes, setScopes] = React.useState([]);
    const [managers, setManagers] = React.useState([]);
    const [alert, setAlert] = React.useState([]);
    const [openedCollapse, setOpenedCollapse] = React.useState("collapse-1");
    const [create, setCreate] = React.useState({});
    const [update, setUpdate] = React.useState({});
    const [remove, setRemove] = React.useState({});
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);
    const [errorMessageOpening, setErrorMessageOpening] = React.useState(`Error occured when processing the request: `);


    // React.useEffect(() => {
    //     setLoading(true);
    //     api.get(`/forecast/clients`)
    //     .then((data) => {
    //         setClients(data.data)
    //         setLoading(false);
    //     })
    // }, [])
    React.useEffect(() => {
        setLoading(true);
        let apiCalls = [
            api.get(`/forecast/projects?client=${client?.lookupValue || ''}`),
            api.get(`/forecast/departments?client=${client?.lookupValue || ''}`),
            //api.get(`/forecast/states?client=${client?.lookupValue || ''}`),
            // api.get(`/forecast/scopes?client=${client?.lookupValue || ''}`),
            api.get(`/forecast/managers?client=${client?.lookupValue || ''}`),
        ];

        if (!client || !client.lookupValue) {
            apiCalls.push(api.get(`/forecast/clients`));
        }
        Promise.all(apiCalls).then((data) => {
            console.log('apiCalls', data)
            setProjects(data[0].data);
            setDepartments(data[1].data);
            //setStates(data[2].data);
            // setScopes(data[3].data);
            setManagers(data[2].data);

            if (!client || !client.lookupValue) {
                setClients(data[3].data);
            }

            if (initFilters) {
                loadData();
            } else {
                setLoading(false);
            }
        }).catch((err) => {
            setLoading(false);
            setErrorMessage(`${errorMessageOpening}${err.message}`)
        });
    }, [client])

    React.useEffect(() => {
        console.log("project", project);

        if (!project) {
            setStates([]);
            return;
        }
        let apiCalls = [
            api.get(`/forecast/states?client=${client?.lookupValue || ''}&siteProjectName=${project?.lookupValue || ''}&scopeRef=${scope?.lookupValue || ''}`),
            //api.get(`/forecast/scopes?client=${client?.lookupValue || ''}&siteProjectName=${project?.lookupValue}`),
        ];
        Promise.all(apiCalls).then((data) => {
            setStates(data[0].data);
            //setScopes(data[1].data);
        }).catch((err) => {
            setLoading(false);
            setErrorMessage(`${errorMessageOpening}${err.message}`)
        });
    }, [project, client])

    const loadData = () => {

        try {
            if (!department || !department.lookupValue || department.lookupValue == '') {
                setAlert([<RequiredAlert key={0} onConfirm={() => setAlert([])} />]);
                return;
            }
            setLoading(true);

            const queryString = qs.stringify({
                //fy: fy.lookupId,
                startDate: formatDate(start, 'YYYY-MM-DD'),
                endDate: formatDate(end, 'YYYY-MM-DD'),
                project: project?.lookupValue,
                department: department?.lookupValue,
                state: state?.lookupValue,
                client: client?.lookupValue,
                scope: scope?.lookupValue,
                manager: manager?.lookupValue,
            }, { allowDots: true });

            api.get(`forecast?${queryString}`)
                .then((jsonData) => {

                    try {
                        if (hotTableComponent.current) {
                            // fixes issue when changing date range previous col headers whould still be rendered and empty
                            hotTableComponent.current.columnSettings = [];
                        }

                        const dateColumns = new Array();
                        //const startDate = new Date(fy.lookupId, 8, 1);
                        //const stopDate = new Date(fy.lookupId + 1, 7, 31);
                        const startDate = start;
                        const stopDate = end;

                        let currentDate = moment(startDate);
                        //   

                        while (currentDate <= stopDate) {
                            dateColumns.push(moment(currentDate));
                            //currentDate = currentDate.addDays(1);
                            currentDate = currentDate.add(1, 'd');
                        }

                        let baseCols = [
                            //{ title: "Index", name: "index", width: 150 },
                            { title: "File Id", name: "manpowerForecastFileId", width: 30 },
                            { title: "Ref No", name: "refNo", width: 30 },
                            //{ title: "Country", name: "country", width: 150 },
                            //{ title: "Business Unit", name: "businessUnit", width: 150 },
                            { title: "Dept", name: "department", width: 65 },
                            { title: "Contract Owner", name: "contractOwner", width: 90 },
                            { title: "Client", name: "client", width: 100 },
                            { title: "Site Project", name: "siteProjectName", width: 130 },
                            { title: "State", name: "state", width: 40 },
                            { title: "Scope Ref", name: "scopeRef", width: 130 },
                            //{ title: "Pronto Job Number", name: "prontoJobNumber", width: 150 },
                            { title: "Position Title", name: "positionTitle", width: 140 },
                            //{ title: "Discipline Reporting Category - Awarded Work", name: "disciplineReportingCategory", width: 150 },
                            //{ title: "Discipline Role", name: "disciplineRole", width: 150 },
                            //{ title: "Grade Classification", name: "gradeClassification", width: 150 },
                            { title: "Crew", name: "crew", width: 40 },
                            { title: "Shift", name: "shift", width: 40 },
                            { title: "Std Hour", name: "stdShiftHours", width: 40 },
                            //{ title: "Roster Recruitment Status", name: "rosterRecruitmentStatus", width: 150 },
                        ]

                        let staticCols = [];
                        // const manualColumnMove = localStorage.getItem('forecast_manualColumnMove');
                        // if (manualColumnMove && manualColumnMove.length > 0) {
                        //     const columnOrder = JSON.parse(manualColumnMove);
                        //     staticCols = columnOrder.slice(0, baseCols.length).map((m) => ({...baseCols[m], index: m}))
                        //     console.log('manualColumnMove', {manualColumnMove, columnOrder: columnOrder.slice(0, baseCols.length), baseCols, staticCols});
                        // } 
                        // else {
                        staticCols = [...baseCols.map((m, i) => ({ ...m, index: i }))]
                        // }

                        const weekNumbers = dateColumns.map(m => m.toDate().getWeek());

                        const weekGroups = weekNumbers.reduce((p, c) => {
                            const key = `${c.week}-${c.fy}`;
                            if (!p.hasOwnProperty(key)) {
                                p[key] = { count: 0, fy: c.fy, week: c.week };
                            }
                            p[key].count++;
                            return p;
                        }, {});

                        const weeks = Object.keys(weekGroups).map(m => ({ label: `${weekGroups[m].week}`, colspan: weekGroups[m].count }));

                        const cols = [
                            [
                                { label: 'Reporting Week:', colspan: staticCols.length },
                                ...weeks
                            ],
                            [
                                ...staticCols.map(m => m.title),
                                ...dateColumns.map(m => m.format('DD/MM/YYYY'))
                            ]
                        ];

                        const hotCols = dateColumns.map(m => `${m.format('YYYYMMDD')}`);
                        const colWidths = [...staticCols.map(m => m.width), ...Array(dateColumns.length).fill(30)];

                        setHotInfo({
                            hotCols: hotCols,
                            columns: cols,
                            data: jsonData.data ?? [],
                            staticCols: staticCols,
                            colWidths: colWidths,
                            allCols: [
                                ...staticCols.map(m => <HotColumn key={m.name} data={m.name} readOnly={true} title={m.name} />),
                                ...hotCols.map(m => <HotColumn key={`${m}`} data={`dates.${m}`} className={'data-cell'} readOnly={true}>
                                    <ForecastColumnRenderer hot-renderer />
                                </HotColumn>
                                )
                            ]
                        });

                    }
                    catch (err) {
                        setLoading(false);
                        setErrorMessage(`${errorMessageOpening}${err.message}`);
                    }

                }).catch((err) => {
                    setLoading(false)
                    setErrorMessage(`${errorMessageOpening}${err.message}`);
                    console.log('error', err);
                }).finally(() => {
                    setLoading(false)
                });
        }
        catch (err) {
            setLoading(false);
            setErrorMessage(`${errorMessageOpening}${err.message}`);
        }
    };

    React.useEffect(() => {
        if (!document.getElementById('hot')) {
            return;
        }
        if (openedCollapse === '') {
            document.getElementById('hot').classList.remove('hot-expanded');
            document.getElementById('hot').classList.add('hot-collapsed');
        } else {
            document.getElementById('hot').classList.remove('hot-collapsed');
            document.getElementById('hot').classList.add('hot-expanded');
        }
        hotTableComponent.current.hotInstance.refreshDimensions();
    }, [openedCollapse]);

    const grid = useMemo(() => (
        <>
            {
                hotInfo &&
                <HotTable
                    ref={hotTableComponent}
                    id={'hot'}
                    data={hotInfo.data}
                    className={'hot-expanded'}
                    //stretchH={'all'}
                    autoColumnSize={false}
                    autoRowSize={false}
                    nestedHeaders={hotInfo.columns}
                    columnHeaderHeight={[20, 80]}
                    dropdownMenu={false}
                    contextMenu={true}
                    filters={false}
                    fillHandle={true}
                    rowHeaders={true}
                    columnSorting={false}
                    afterGetColHeader={alignHeaders}
                    //viewportRowRenderingOffset={150}
                    viewportColumnRenderingOffset={150}
                    modifyColWidth={(width, column) => {
                        if (column >= hotInfo.staticCols.length) {
                            return 24;
                        }
                        return hotInfo.colWidths[column];
                    }}
                    //wordWrap={false}
                    //colWidths={hotInfo.colWidths} // colWiths prop dosnt honor the width of the date cols if done this way
                    fixedColumnsLeft={hotInfo.staticCols.length}
                    manualRowMove={false}
                    manualColumnResize={true}
                    manualColumnFreeze={false}
                    manualColumnMove={false}
                    // persistentState={true}
                    // persistentStateLoad={(key, valuePlaceholder) => {
                    //     console.log('persistentStateLoad', { key, valuePlaceholder })
                    // }}
                    // persistentStateReset={(key) => {
                    //     console.log('persistentStateReset', { key })
                    // }}
                    // persistentStateSave={(key, value) => {
                    //     console.log('persistentStateSave', { key, value })
                    // }}
                    // beforeColumnResize={(newSize, column, isDoubleClick) => {
                    //     console.log('beforeColumnResize', { newSize, column, isDoubleClick })
                    // }}
                    // afterColumnResize={(newSize, column, isDoubleClick) => {
                    //     console.log('afterColumnResize', { newSize, column, isDoubleClick })
                    // }}
                    // beforeColumnMove={(movedColumns, finalIndex, dropIndex, movePossible) => {
                    //     console.log('beforeColumnMove', { movedColumns: [...movedColumns], finalIndex, dropIndex, movePossible })
                    // }}
                    // afterColumnMove={(movedColumns, finalIndex, dropIndex, movePossible, orderChanged) => {
                    //     console.log('afterColumnMove', { movedColumns: [...movedColumns], finalIndex, dropIndex, movePossible, orderChanged });
                    //     if (orderChanged) {
                    //         const original = [...hotInfo.staticCols];
                    //         const firstPart = original.slice(0, finalIndex);
                    //         const lastPart = original.slice(finalIndex);
                    //         let middlePart = [];
                    //         if (finalIndex < movedColumns[0]) 
                    //         {
                    //             middlePart = lastPart.splice(movedColumns[0] - finalIndex, movedColumns.length);
                    //         }
                    //         else
                    //         {
                    //             middlePart = firstPart.splice(movedColumns[0] - finalIndex, movedColumns.length);
                    //         }
                    //         const staticCols = [ ...firstPart, ...middlePart, ...lastPart ];
                    //         const manualColumnMove = staticCols.map(m => m.index);
                    //         localStorage.setItem('forecast_manualColumnMove', JSON.stringify(manualColumnMove));

                    //         console.log('columnMove', { firstPart, lastPart, original, middlePart, staticCols, manualColumnMove });
                    //         setHotInfo({
                    //             ...hotInfo, 
                    //             staticCols, 
                    //             columns: [ 
                    //                 [
                    //                     ...hotInfo.columns[0] 
                    //                 ], 
                    //                 [  
                    //                     ...staticCols.map(m => m.title),
                    //                     ...hotInfo.columns[1].slice(original.length) 
                    //                 ] 
                    //             ]});
                    //         return false;
                    //     }
                    // }}
                    renderAllRows={true}
                    selectionMode={'range'} // 'single', 'range' or 'multiple',
                    outsideClickDeselects={false}
                    hiddenColumns={{
                        columns: [0, 1]
                    }}
                    afterLoadData={(sourceData, initialLoad, source) => {
                        console.log('afterLoadData', { sourceData, initialLoad, source });
                    }}
                    licenseKey="5c26d-2087e-1df71-d6238-dfc22"
                    afterSelectionEnd={(row, prop, row2, prop2, preventScrolling, selectionLayerLevel) => {
                        console.log('afterSelectionEnd', { row, prop, row2, prop2, preventScrolling, selectionLayerLevel })
                    }}
                >
                    {hotInfo.allCols}
                    {/* {hotInfo.staticCols.map(m => <HotColumn key={m.name} data={m.name} readOnly={true} />)}
                {hotInfo.hotCols.map(m => <HotColumn key={`${m}`} data={`dates.${m}.fte`} className={'data-cell'} readOnly={true} />)} */}
                </HotTable>
            }
        </>
        //), [data, hotCols, hotTableComponent, columns]);
    ), [hotInfo, hotTableComponent]);

    //

    const handleSelected = useCallback(() => {
        const hotTable = hotTableComponent.current?.hotInstance;

        if (!hotTable)
            return;

        const selected = hotTable.getSelected();

        if (!selected)
            return;

        for (let i = selected[0][0]; i <= selected[0][2]; i++) {
            for (let j = selected[0][1]; j <= selected[0][3]; j++) {
                const className = hotTable.getCell(i, j).className;

                if (className.includes("has-roster")) {
                    setAlert([(
                        <HasRosterAlert
                            key={0}
                            onConfirm={() => setAlert([])}
                        />
                    )]);
                    return;
                }
            }
        }

        // Need data to figure out what days are being worked on roster screen (final wizard page)
        const selectedData = hotTable.getData(...selected[0]);

        const selectedCols = [];
        for (let i = selected[0][1]; i <= selected[0][3]; i++) {
            selectedCols.push(hotInfo.columns[1][i]);
        }

        const selectedRows = [];
        const selectedManpowerForecasts = [];
        for (let i = selected[0][0]; i <= selected[0][2]; i++) {
            selectedRows.push(hotTable.getDataAtRowProp(i, 'refNo'));
            const sourceData = hotTable.getSourceDataAtRow(i);

            if (sourceData) {
                for (let j = 0; j < selectedCols.length; j++) {
                    const date = moment(selectedCols[j], "DD/MM/YYYY").format("YYYY-MM-DD");

                    if (date)
                        selectedManpowerForecasts.push({
                            manpowerForecastFileId: sourceData.manpowerForecastFileId,
                            refNo: sourceData.refNo,
                            date
                        });
                }
            }
        }



        const selectedIndex = selected[0];
        const projects = [];
        const positions = [];
        const scope = [];

        for (let i = selectedIndex[0]; i <= selectedIndex[2]; i++) {
            const manpowerForecastFileId = hotTable.getDataAtRowProp(i, 'manpowerForecastFileId');
            const country = hotTable.getDataAtRowProp(i, 'country');
            const businessUnit = hotTable.getDataAtRowProp(i, 'businessUnit');
            const department = hotTable.getDataAtRowProp(i, 'department');
            const contractOwner = hotTable.getDataAtRowProp(i, 'contractOwner');
            const client = hotTable.getDataAtRowProp(i, 'client');
            const project = hotTable.getDataAtRowProp(i, 'siteProjectName');
            const state = hotTable.getDataAtRowProp(i, 'state');
            const scopeRef = hotTable.getDataAtRowProp(i, 'scopeRef');
            const positionTitle = hotTable.getDataAtRowProp(i, 'positionTitle');
            const disciplineReportingCategory = hotTable.getDataAtRowProp(i, 'disciplineReportingCategory');
            const disciplineRole = hotTable.getDataAtRowProp(i, 'disciplineRole');
            const gradeClassification = hotTable.getDataAtRowProp(i, 'gradeClassification');
            const crew = hotTable.getDataAtRowProp(i, 'crew');
            const shift = hotTable.getDataAtRowProp(i, 'shift');

            scope.push({ project, scopeRef });

            if (projects.filter(item => item.project === project).length === 0)
                projects.push({
                    manpowerForecastFileId,
                    country,
                    businessUnit,
                    department,
                    contractOwner,
                    client,
                    project,
                    state,
                    scopeRef,
                    positionTitle,
                    disciplineReportingCategory,
                    disciplineRole,
                    gradeClassification,
                    crew,
                    shift
                });

            //const position = hotTable.getDataAtRow(i)[9];
            const refNo = hotTable.getDataAtRowProp(i, 'refNo');
            const position = hotTable.getDataAtRowProp(i, 'positionTitle');

            let slot = 0;
            for (let j = selectedIndex[1]; j <= selectedIndex[3]; j++) {
                const cellData = hotTable.getDataAtCell(i, j);

                if (!cellData) {
                    continue;
                }

                slot = slot < cellData.fte ? cellData.fte : slot;
            }

            positions.push({
                project,
                position,
                slot,
                refNo
            });

            // if (positions.filter(item => item.project === project && item.position === position).length > 0)
            // {
            //     let slot = positions.filter(item => item.project === project && item.position === position)[0].slot ?? 0;
            //     positions.splice(positions.indexOf(positions.filter(item => item.project === project && item.position === position)[0]), 1);

            //     for(let j = selectedIndex[1]; j <= selectedIndex[3]; j++)
            //     {
            //         const cellData = parseInt(hotTable.getDataAtCell(i, j) ?? 0);
            //         slot = slot < cellData ? cellData : slot;
            //     }


            //     positions.push({
            //         project: project,
            //         position: position,
            //         slot: slot
            //     });

            //     continue;
            // }

            // if (positions.filter(item => item.project === project && item.position === position).length === 0)
            // {
            //     let slot = 0;

            //     for(let j = selectedIndex[1]; j <= selectedIndex[3]; j++)
            //     {
            //         const cellData = parseInt(hotTable.getDataAtCell(i, j) ?? 0);
            //         slot = slot < cellData ? cellData : slot;
            //     }


            //     positions.push({
            //         project: project,
            //         position: position,
            //         slot: slot
            //     });
            // }
        }

        const grouped = _.groupBy(scope, 'project');
        const projectScopes = Object.keys(grouped).map(m => ({ project: m, scopeRef: _.uniqBy(grouped[m], 'scopeRef').map(c => c.scopeRef).join(', ') }));
        for (let x = 0; x < projects.length; x++) {
            const p = projectScopes.find(f => f.project == projects[x].project);
            //console.log('p', {p, project: projects[x]})
            projects[x].scopeRef = p?.scopeRef;
        }
        //console.log('projectScopes', {grouped, projectScopes, projects})

        const dates = [];
        dates.push(hotInfo.columns[1][selectedIndex[1]]);
        dates.push(hotInfo.columns[1][selectedIndex[3]]);

        const forecastFilters = {
            start: start.toDate(),
            end: end.toDate(),
            client: client,
            department: department,
            manager: manager,
            project: project,
            scope: scope,
            state: state
        }
        console.log('forecastFilters', {projects, dates, positions, selectedData, selectedCols, selectedRows, forecastFilters, selectedManpowerForecasts})

        history.push({
            pathname: "/rosterwizard",
            state: {
                projects: projects,
                dates: dates,
                positions: positions,
                selectedData,
                selectedCols,
                selectedRows,
                forecastFilters,
                selectedManpowerForecasts
            }
        })
    }, [hotInfo]);
    //}, [columns]);

    useEffect(() => {
        setScope(null);
    }, [client]);

    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            onConfirm: () => setErrorMessage(null),
            message: errorMessage
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage]);

    return (
        <section className="main pb-0">
            {(loading) && <Loader />}
            {alert.length > 0 && alert}
            {errorNotification.length > 0 && errorNotification}
            <Container fluid>

                <Row>
                    <Col xs={12}>
                        <Card className="no-transition">
                            <CardHeader className='py-1'>
                                <h5 className="mb-0">
                                    <Button
                                        aria-expanded={openedCollapse === "collapse-1"}
                                        onClick={() =>
                                            setOpenedCollapse(openedCollapse === "collapse-1" ? "" : "collapse-1")
                                        }
                                        className="w-100 text-primary text-left no-shadow"
                                        color="link"
                                        type="button"
                                    >
                                        Forecast Filter
                                        <i className="ni ni-bold-down float-right pt-1"></i>
                                    </Button>
                                </h5>
                            </CardHeader>
                            <Collapse isOpen={openedCollapse === "collapse-1"}>
                                <CardBody className='pb-0'>
                                    <Container fluid>
                                        <Row>
                                            {/* <Col xs={3}>
                                                <FormGroup>
                                                    <Label>
                                                        Financial Year
                                                    </Label>
                                                    <DropdownBox 
                                                        data={financialYears}
                                                        selectedItem={fy}
                                                        onChange={(item) => {                                         
                                                            setFy(item);
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>  */}
                                            <Col xs={3}>
                                                <Row>
                                                    <Col xs={6}>
                                                        <FormGroup>
                                                            <Label>
                                                                Forecast Start Date
                                                            </Label>
                                                            <DatePicker
                                                                name="start"
                                                                id="start"
                                                                type="text"
                                                                required
                                                                value={formatDate(start)}
                                                                onChange={(value) => {
                                                                    console.log('setStart', value)
                                                                    setStart(value);
                                                                }}
                                                                closeOnSelect
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <FormGroup>
                                                            <Label>
                                                                Forecast End Date
                                                            </Label>
                                                            <DatePicker
                                                                name="end"
                                                                id="end"
                                                                type="text"
                                                                required
                                                                value={formatDate(end)}
                                                                onChange={(value) => {
                                                                    console.log('setEnd', value)
                                                                    setEnd(value);
                                                                }}
                                                                closeOnSelect
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={3}>
                                                <FormGroup>
                                                    <Label>
                                                        Client
                                                    </Label>
                                                    <DropdownBox
                                                        data={clients ?? []}
                                                        selectedItem={client}
                                                        valueField={'lookupValue'}
                                                        idField={'lookupValue'}
                                                        onChange={(item) => {
                                                            setClient(item);
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={3}>
                                                <FormGroup>
                                                    <Label>
                                                        Department
                                                    </Label>
                                                    {/* <Input
                                                        name="department"
                                                        id="department"
                                                        type="text"
                                                        value={department ?? ""}
                                                        onChange={({ target: { value } }) => setDepartment(value)}
                                                    /> */}
                                                    <DropdownBox
                                                        data={departments ?? []}
                                                        selectedItem={department}
                                                        valueField={'lookupValue'}
                                                        idField={'lookupValue'}
                                                        onChange={(item) => {
                                                            setDepartment(item);
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={3}>
                                                <FormGroup>
                                                    <Label>
                                                        Manager
                                                    </Label>
                                                    {/* <Input
                                                        name="manager"
                                                        id="manager"
                                                        type="text"
                                                        value={manager ?? ""}
                                                        onChange={({ target: { value } }) => setManager(value)}
                                                        autoComplete="off"
                                                    /> */}
                                                    <DropdownBox
                                                        data={managers ?? []}
                                                        selectedItem={manager}
                                                        valueField={'lookupValue'}
                                                        idField={'lookupValue'}
                                                        onChange={(item) => {
                                                            setManager(item);
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={3}>
                                                <FormGroup>
                                                    <Label>
                                                        Site/Project
                                                    </Label>
                                                    {/* <Input
                                                        name="project"
                                                        id="project"
                                                        type="text"
                                                        value={project ?? ""}
                                                        onChange={({ target: { value } }) => setProject(value)}
                                                    /> */}
                                                    <DropdownBox
                                                        data={projects ?? []}
                                                        selectedItem={project}
                                                        valueField={'lookupValue'}
                                                        idField={'lookupValue'}
                                                        onChange={(item) => {
                                                            setProject(item);
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={3}>
                                                <FormGroup>
                                                    <Label>
                                                        Scope Ref
                                                    </Label>
                                                    {/* <Input
                                                        name="discipline"
                                                        id="discipline"
                                                        type="text"
                                                        value={discipline ?? ""}
                                                        onChange={({ target: { value } }) => setDiscipline(value)}
                                                        autoComplete="off"
                                                    /> */}
                                                    {/* <DropdownBox 
                                                        data={scopes ?? []}
                                                        selectedItem={scope}
                                                        valueField={'lookupValue'}
                                                        idField={'lookupValue'}
                                                        onChange={(item) => {
                                                            setScope(item);
                                                        }}
                                                    /> */}
                                                    <ComboBox
                                                        minLength={0}
                                                        disabled={project == null || Object.keys(project).length == 0 ? true : false}
                                                        endpoint={`/forecast/scopes`}
                                                        placeholder={project == null || Object.keys(project).length == 0 ? '-- Please select Site/Project --' : false}
                                                        additionalParam={{
                                                            client: client?.lookupValue ?? "",
                                                            siteProjectName: project?.lookupValue ?? ""
                                                        }}
                                                        isLookup={false}
                                                        idField="lookupId"
                                                        valueField="lookupValue"
                                                        selectedItem={scope}
                                                        onChange={(item) => {
                                                            setScope(item);
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={3}>
                                                <FormGroup>
                                                    <Label>
                                                        State
                                                    </Label>
                                                    {/* <Input
                                                        name="state"
                                                        id="state"
                                                        type="text"
                                                        value={state ?? ""}
                                                        onChange={({ target: { value } }) => setState(value)}
                                                        autoComplete="off"
                                                    /> */}
                                                    <DropdownBox
                                                        disabled={project == null || Object.keys(project).length == 0 ? true : false}
                                                        data={states ?? []}
                                                        placeholder={project == null || Object.keys(project).length == 0 ? '-- Please select Site/Project --' : false}
                                                        selectedItem={state}
                                                        valueField={'lookupValue'}
                                                        idField={'lookupValue'}
                                                        onChange={(item) => {
                                                            setState(item);
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={3} className='d-flex justify-content-end align-items-center pt-2'>
                                                <Button
                                                    color="secondary"
                                                    onClick={loadData}
                                                    type="button"
                                                >
                                                    Search Forecasts
                                                </Button>
                                                <Button
                                                    color="secondary"
                                                    onClick={() => handleSelected()}
                                                    type="button"
                                                >
                                                    Create Roster
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </CardBody>
                            </Collapse>
                            <CardBody className='pt-1'>
                                <Container fluid>
                                    <Row>
                                        <Col xs={12}>
                                            {
                                                // data && data.length > 0 && columns && columns.length > 0 && hotCols && hotCols.length > 0 &&
                                                grid
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>
                        </Card>
                        {/* <div style='white-space: pre-wrap;'>
                            {output}
                        </div> */}

                    </Col>
                </Row>
            </Container>

        </section>);
}



export default Forcast;