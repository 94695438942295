import React from 'react';

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, id, customTitle, inputClassName = "", labelClassName = "", ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
        <div className="custom-control custom-checkbox">
            <input type="checkbox" className={`custom-control-input ${inputClassName}`} id={`indeterminate-checkbox-${id}`} ref={resolvedRef} {...rest} />
            <label className={`custom-control-label ${labelClassName}`} htmlFor={`indeterminate-checkbox-${id}`}>{customTitle ?? ""}</label>
        </div>
    )
})

export default IndeterminateCheckbox;