import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import qs from "qs";
import moment from "moment";
import debounce from 'lodash.debounce';
import {
    Notification,
    NotificationGroup,
  } from "@progress/kendo-react-notification";
import { Slide } from "@progress/kendo-react-animation";
import Loader from "../../loaders";
import useForecast, { ForecastProvider } from './contexts/ForecastContext';
import { SetupPanel, ConfigPanel, StatusPanel } from "./panels";
import RequiredAlert from "./alerts/RequiredAlert";
//import { formatDate } from "../../../utils";

import "../../../assets/css/ag-grid.css";
import "../../../assets/css/ag-theme-alpine.css";


//import api from "../../../services/api";
//import { ViewAccess } from '../../../utils'
import { formatDate, ViewAccess } from '../../../utils'
import { roles } from "../../../auth/roles";
import { useMsal } from "@azure/msal-react";
import { requiresRoles } from "../../Can";


Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

Date.prototype.getWeek = function () {
    const fiscalyear = (this.getMonth() + 1) <= 8 ? this.getFullYear() - 1 : this.getFullYear();
    const fyStart = new Date(fiscalyear, 8, 1);

    const diff = this - fyStart + 1;
    var dayOfYear = (diff / 86400000);
    return { week: Math.ceil(dayOfYear / 7), fy: fiscalyear, date: this };
};

//export default 
function Grid(props) {
    const history = useHistory();
    const [ loading, setLoading ] = React.useState(false);

    const gridRef = useRef();
    const [ rowData, setRowData ] = useState();
    const [ columnDefs, setColumnDefs ] = useState();
    const [alert, setAlert] = React.useState([]);


    // Access Right 
    const [viewAccess, setViewAccess] = React.useState(ViewAccess.FULLACCESS);
    const { instance } = useMsal();
    const user = instance.getActiveAccount();


    const {
        startDate,
        endDate,
        client,
        department,
        manager,
        project,
        scope,
        state,
        notification,
        setNotification,
    } = useForecast();

    const defaultColDef = useMemo(() => ({
        sortable: false,
        cellClass: 'roster-cell',
        autoHeight: false, 
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200
        },
        menuTabs: ['filterMenuTab'],
    }), []);

    const staticColumnDefs = useMemo(() => {
        return [
        {
            headerName: "",
            children: [
                { 
                    field: "refNo", 
                    headerName: "Ref No", 
                    width: 30, 
                    pinned: true, 
                    sort: "asc",
                    sortable: true,
                    filter: false,
                    suppressMenu: true,
                },
            ]
        },
        {
            headerName: "Reporting Week:",
            headerClass: "text-right pr-2",
            children: [
                { 
                    field: "department", 
                    headerName: "Dept", 
                    width: 65, 
                    pinned: true, 
                    sortable: true,
                },
                { 
                    field: "contractOwner", 
                    headerName: "Contract Owner", 
                    width: 90, 
                    pinned: true, 
                    sortable: true,
                },
                { 
                    field: "client", 
                    headerName: "Client", 
                    width: 100, 
                    pinned: true, 
                    sortable: true,
                },
                { 
                    field: "siteProjectName", 
                    headerName: "Site Project", 
                    width: 130, 
                    pinned: true, 
                    sortable: true,
                },
                { 
                    field: "state", 
                    headerName: "State", 
                    width: 40, 
                    pinned: true, 
                    sortable: true,
                },
                { 
                    field: "scopeRef", 
                    headerName: "Scope Ref", 
                    width: 130, 
                    pinned: true, 
                    sortable: true,
                },
                { 
                    field: "positionTitle", 
                    headerName: "Position Title", 
                    width: 140, 
                    pinned: true, 
                    sortable: true,
                },
                { 
                    field: "crew", 
                    headerName: "Crew", 
                    width: 40, 
                    pinned: true, 
                    sortable: true,
                },
                { 
                    field: "shift", 
                    headerName: "Shift", 
                    width: 40, 
                    pinned: true, 
                    sortable: true,
                },
                { 
                    field: "stdShiftHours", 
                    headerName: "Std Hour", 
                    width: 90, 
                    pinned: true, 
                    sortable: true,
                },
            ]
        },
    ]}, []);

    const loadData = useCallback((query) => {
        gridRef.current.api.clearRangeSelection();
        const queryString = qs.stringify(query, { allowDots: true });
        //console.log('useCallback', {queryString, query})

        if (!query.department) {
            setAlert([<RequiredAlert key={0} onConfirm={() => setAlert([])} />]);
            return;
        }

        setLoading(true);

        api.get(`/forecast?${queryString}`)
        .then((response) => {
            console.log('forecast ', response);

            const dateColumns = new Array();

            const startDate = moment(query.startDate);
            const stopDate = moment(query.endDate);

            let currentDate = moment(startDate);

            while (currentDate <= stopDate) {
                dateColumns.push(moment(currentDate));
                currentDate = currentDate.add(1, 'd');
            }

            const weekNumbers = dateColumns.map(m => m.toDate().getWeek());

            const weekGroups = weekNumbers.reduce((p, c) => {
                const key = `${c.week}-${c.fy}`;
                if (!p.hasOwnProperty(key)) {
                    p[key] = { fy: c.fy, week: c.week, dates: [] };
                }
                p[key].dates.push(moment(c.date));
                return p;
            }, {});
            
            const definition = [
                ...staticColumnDefs,
                ...Object.entries(weekGroups).map(([key, value]) => ({
                    headerName: value.week,
                    marryChildren: true,
                    children: value.dates.map(date => ({
                        field: `dates.${date.format('YYYYMMDD')}`, 
                        headerName: date.format('DD/MM/YYYY'), 
                        autoHeight: false, 
                        resizable: false,
                        width: 26, 
                        editable: false, 
                        headerClass: 'date-header',
                        suppressMovable: true,
                        lockPosition: 'right',
                        filter: false,
                        suppressMenu: true,
                        valueFormatter: (params) => {
                            return params.value?.fte ? params.value?.fte : '';
                        },
                        cellClass: (params) => {
                            let className = 'date-cell';
                            if (params.value?.fte) className += ' data-cell';
                            if (params.value?.hasRoster) className += ' has-roster';
                            return className;
                        },
                    }))
                }))
            ];

            setColumnDefs(definition);
            setRowData(response.data);

            //console.log('INFO', { weekGroups, weekNumbers, dateColumns, currentDate, startDate, stopDate, definition })

            gridRef.current.api.closeToolPanel();
        }).catch((err) => {
            //setErrorMessage(`${errorMessageOpening}${err.message}`);
        }).finally(() => {
            setLoading(false)
        });

    }, [staticColumnDefs]);

    const createRoster = useCallback((gridApi) => {
        const range = gridApi.getCellRanges()[0];
        const startRow = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
        const endRow = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);

        const selectedDates = range.columns.map(m => moment(m.colId.split('.')[1], 'YYYYMMDD'));
        const min = moment.min(selectedDates);
        const max = moment.max(selectedDates);
        const dates = [ min.format("DD/MM/YYYY"), max.format("DD/MM/YYYY") ];

        const selectedCols = selectedDates.map(m => m.format("DD/MM/YYYY"))

        const forecastFilters = {
            start: startDate, 
            end: endDate, 
            client: client ?? {}, 
            department: department ?? {}, 
            manager: manager ?? {}, 
            project: project ?? {}, 
            scope: scope ?? {}, 
            state: state ?? {}
        }

        const positions = [];
        const projects = [];
        const selectedData = [];
        const selectedRows = [];
        const selectedManpowerForecasts = [];
        const projectScopes = [];

        let index = 0;
        for (let i = startRow; i <= endRow; i++) {
            const { data } = gridApi.getDisplayedRowAtIndex(i);
            const slotValues = [];
            selectedData.push([]);
            selectedRows.push(data.refNo);
            projectScopes.push({ project: data.siteProjectName, scopeRef: data.scopeRef });

            range.columns.forEach(c => {
                const [ first, second ] = c.colId.split(".");

                const cellData = data[first][second];
                if (!cellData) {
                    return;
                }
                console.log('cellData', {first, second, cellData})

                // slotValues.push(data[first][second].fte);
                // selectedData[index].push(data[first][second]);
                slotValues.push(cellData.fte);
                selectedData[index].push(cellData);
                selectedManpowerForecasts.push({
                    manpowerForecastFileId: data.manpowerForecastFileId,
                    refNo: data.refNo,
                    date: moment(second, "YYYYMMDD").format("YYYY-MM-DD")
                });
            });

            console.log('data', {data, selectedData, selectedManpowerForecasts, slotValues})

            if (projects.filter(item => item.project === data.siteProjectName).length === 0)
            {
                projects.push({
                    manpowerForecastFileId: data.manpowerForecastFileId,
                    country: data.country,
                    businessUnit: data.businessUnit,
                    department: data.department,
                    contractOwner: data.contractOwner,
                    client: data.client,
                    project: data.siteProjectName,
                    state: data.state,
                    scopeRef: data.scopeRef,
                    positionTitle: data.positionTitle,
                    disciplineReportingCategory: data.disciplineReportingCategory,
                    disciplineRole: data.disciplineRole,
                    gradeClassification: data.gradeClassification,
                    crew: data.crew,
                    shift: data.shift
                });
            }

            positions.push({
                project: data.siteProjectName,
                position: data.positionTitle,
                slot: Math.max(...slotValues),
                refNo: data.refNo
            });

            index++;
        }
        forecastFilters.scope = projectScopes;

        console.log('Create Roster', {dates, forecastFilters, positions, projects, selectedCols, selectedData, selectedManpowerForecasts, selectedRows})
        history.push({
            pathname: "/rosterwizard",
            state: {
                projects: projects,
                dates: dates,
                positions: positions,
                selectedData,
                selectedCols,
                selectedRows,
                forecastFilters,
                selectedManpowerForecasts
            }
        })
    }, []);

    const sideBar = useMemo(() => ({
        toolPanels: [
            {
                id: 'setupPanel',
                labelDefault: 'Setup',
                labelKey: 'setupPanel',
                iconKey: 'fa-gear',
                toolPanel: SetupPanel,
                width: 280,
                toolPanelParams: {
                    setLoading,
                    loadData,
                    createRoster,
                    viewAccess,
                }
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: null,
                toolPanel: 'agFiltersToolPanel',
                width: 280,
            },
            {
                id: 'configPanel',
                labelDefault: 'Configuration',
                labelKey: 'configPanel',
                iconKey: 'fa-config',
                toolPanel: ConfigPanel,
                width: 280,
                toolPanelParams: {

                }
            },
        ],
        defaultToolPanel: 'setupPanel',
        position: "left"
    }), [loadData, viewAccess]);

    const statusBar = useMemo(() => ({
        statusPanels: [
            {
                statusPanel: StatusPanel,
                align: 'right',
            },
        ],
}), []);

    const getRowId = useMemo(() => (params) => params.data.refNo, []);

    const loadingOverlayComponent = useMemo(() => Loader, []);

    const getContextMenuItems = useCallback(({ api: gridApi }) => {

        return [
            {
                name: 'Create Roster',
                action: () => {
                    createRoster(gridApi);
                },
            },
        ]
    }, [ startDate, endDate, client, department, manager, project, scope, state ]);

    useEffect(() => {
        if (process.env.REACT_APP_HRIS_FORECAST_GRID_COLUMN_STATE in localStorage && !!gridRef.current.columnApi) {
            gridRef.current.columnApi.applyColumnState({
                state: JSON.parse(localStorage.getItem(process.env.REACT_APP_HRIS_FORECAST_GRID_COLUMN_STATE)),
                applyOrder: true,
                defaultState: {
                    aggFunc:null,
                    flex: null,
                    hide: false,
                    pinned: null,
                    pivot: false,
                    pivotIndex: null,
                    rowGroup: false,
                    rowGroupIndex: null,
                    sort: null,
                    sortIndex: null
                }
            });
        }
        //setDataLoaded(rowData && rowData.length > 0)
    }, [rowData]);

    useEffect(() => {
        if (notification) {
            window.setTimeout(() => {
                setNotification(null);
            }, 5000)
        }
    }, [notification]);

    useEffect(() => {
        requiresRoles([roles.Spc], user, () => {
            setViewAccess(ViewAccess.READONLY);
        });
    }, []);

    const onRangeSelectionChanged = React.useCallback(
        debounce((event) => {
            var cellRanges = event.api.getCellRanges();
            var elCounter = document.querySelector("#agg-info")
            elCounter.innerHTML = "";
            
            if (cellRanges && cellRanges.length > 0) {
                const firstCellRange = cellRanges[0];
                const column = firstCellRange.columns[0];

                const startRow = Math.min(firstCellRange.startRow.rowIndex, firstCellRange.endRow.rowIndex);
                const endRow = Math.max(firstCellRange.startRow.rowIndex, firstCellRange.endRow.rowIndex);

                const map = new Map();
                if (column.instanceId >= process.env.REACT_APP_FORECAST_DATE_COLUMN_START_INDEX) {

                    let result = "";
                    for (let i = startRow; i <= endRow; i++) {
                        const rowNode = event.api.getDisplayedRowAtIndex(i);
                        const key = `${rowNode.data.positionTitle} (${rowNode.data.refNo})`
                        const fteValues = [];
                        firstCellRange.columns.forEach(column => {
                            const cellValue = event.api.getValue(column, rowNode);
                            fteValues.push(cellValue?.fte ?? 0);
                        });
                        result += `${key}: ${Math.max(...fteValues)} | `
                    }

                    elCounter.innerHTML = result.slice(0, -3);
                } else {
                    for (let i = startRow; i <= endRow; i++) {
                        const rowNode = event.api.getDisplayedRowAtIndex(i);
                        const cellValue = event.api.getValue(column, rowNode) ?? "Empty";
                        map.set(cellValue, (map.get(cellValue) ?? 0) + 1)
                    }
                    let result = "";
                    map.forEach((value, key) => {
                        result += `${key}: ${value} | `
                    })
                    elCounter.innerHTML = result + `Total: ${endRow - startRow + 1}`;
                }
                
            }

            //console.log('elCounter.innerHTML', elCounter.innerHTML)
        }, 150)
    , []);

    return (<>
        {loading && <Loader />}
        {alert.length > 0 && alert}
        <section className="main forecast">
            <div className="ag-theme-alpine px-2" style={{width: '100%', height: 'calc(100vh - 130px)'}}>

                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    suppressColumnVirtualisation={false}
                    suppressRowVirtualisation={false}
                    rowBuffer={20}
                    debounceVerticalScrollbar={false}
                    allowContextMenuWithControlKey={true}
                    //getContextMenuItems={getContextMenuItems}
                    //suppressContextMenu={viewAccess == ViewAccess.READONLY ? }
                    getContextMenuItems={viewAccess == ViewAccess.FULLACCESS ? getContextMenuItems : null}
                    suppressContextMenu={viewAccess == ViewAccess.FULLACCESS ? false : true}
                    headerHeight={70}
                    groupHeaderHeight={22}
                    rowHeight={22}
                    onGridReady={( { api } ) => {
                        api.hideOverlay();
                    }}
                    readOnlyEdit={true}
                    getRowId={getRowId}
                    enableRangeSelection={true}
                    suppressMultiRangeSelection={true}
                    loadingOverlayComponent={loadingOverlayComponent}
                    sideBar={sideBar}
                    statusBar={statusBar}
                    tooltipShowDelay={0}
                    tooltipHideDelay={2000}
                    onRangeSelectionChanged={onRangeSelectionChanged}
                    suppressDragLeaveHidesColumns={true}
                    suppressColumnMoveAnimation={true}
                    multiSortKey='ctrl'
                    animateRows={false}
                    onAsyncTransactionsFlushed={(event) => {
                        console.log('onAsyncTransactionsFlushed', event)
                    }}
                    onRowDataUpdated={(event) => {
                        console.log('onRowDataUpdated', event)
                    }}
                />
            </div>
        </section>
        <NotificationGroup
            style={{
                right: 8,
                bottom: 0,
                alignItems: "flex-start",
                flexWrap: "wrap-reverse",
            }}
        >
            <Slide direction={notification ? "up" : "down"}>
            {
                notification &&
                <Notification
                    type={{
                        style: notification.type,
                        icon: true,
                    }}
                    closable={true}
                    onClose={() => setNotification(null)}
                    >
                    <span>{notification.message}</span>
                </Notification>
            }
            </Slide>
        </NotificationGroup>
    </>)
}

export default (props) => <ForecastProvider><Grid {...props} /></ForecastProvider>