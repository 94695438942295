const ProjectContractTermValidation = [
    {
        field: "contractTermId",
        tests: [
            {
                type: "required",
                message: "Contract Term Name is required.",
                validate: (obj) => {
                    return obj.contractTermId ? true : false
                },
            }
        ]
    },   
];
export {
    ProjectContractTermValidation
};