import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Container, Row, Col, Card, CardBody, CardTitle, FormGroup, Label, ButtonGroup
} from "reactstrap";
import api from '../../../services/api';
import { ComboBox } from "../../dropdowns";
import { TooltipButton } from '../../inputs';
import Loader from '../../loaders';
import RateGrid from './RateGrid';
import RateList from "./RateList";
import TcGrid from './TcGrid';
import TcList from "./TcList";

const viewModes = {
    rateList: "1",
    termList: "2"
}

export default function RateSheetTcView(props) {
    const {projectId} = useParams();
    const [selectedProject, setSelectedProject] = useState(null);
    const [viewMode, setViewMode] = useState(viewModes.rateList);
    const [topLeftBar, setTopLeftBar] = useState(<></>);
    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     console.log({ viewMode, resultRate: viewMode === viewModes.rateList, resultTerm: viewMode === viewModes.rateList });
    // }, [viewMode]);

    const loadProject = useCallback(() => {
        if (!projectId)
            return;

        setLoading(true);

        api.get(`project/${projectId}`)
            .then((response) => setSelectedProject(response.data))
            .catch((error) => console.log({error}))
            .finally(() => setLoading(false))
        ;
    }, [projectId]);

    useEffect(() => {
        if (!projectId)
            return;
        
        loadProject();
    }, [projectId]);

    return (
        <section className="main">
            {(loading) && <Loader />}
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    {/*<h4 className="text-center">Rate Sheets / Terms &amp; Conditions</h4>                                    */}
                                </CardTitle>
                                <Container fluid className="px-0">
                                    <Row noGutters>
                                        <Col sm={12} md={{ size: 4, offset: 4 }}>
                                            <FormGroup>
                                                <Label>
                                                    Project
                                                </Label>
                                                <ComboBox
                                                    endpoint="/project/search"
                                                    isLookup={false}
                                                    idField="projectId"
                                                    valueField="projectName"
                                                    selectedItem={selectedProject}
                                                    onChange={setSelectedProject}
                                                    minLength={0}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row noGutters>
                                        <Col sm={6}>
                                            {topLeftBar}
                                        </Col>
                                        <Col sm={6}>
                                            <ButtonGroup size="sm" style={{ float: "right" }}>
                                                <TooltipButton
                                                    disabled={!selectedProject}
                                                    id="rateview"
                                                    title="Rate View"
                                                    className="btn-icon mb-2"
                                                    name="viewMode"
                                                    active={viewMode === viewModes.rateList}
                                                    value={viewModes.rateList}
                                                    onClick={(e) => {
                                                        console.log(e.currentTarget.value);
                                                        setViewMode(e.currentTarget.value);
                                                    }}
                                                >
                                                    <i className="fas fa-th-list pt-1"></i> Rate View
                                                </TooltipButton>
                                                <TooltipButton
                                                    disabled={!selectedProject}
                                                    id="termview"
                                                    title="Term and Condition View"
                                                    className="btn-icon mb-2"
                                                    name="viewMode"
                                                    active={viewMode === viewModes.termList}
                                                    value={viewModes.termList}
                                                    onClick={(e) => {
                                                        console.log(e.currentTarget.value);
                                                        setViewMode(e.currentTarget.value);
                                                    }}
                                                >
                                                    <i className="fas fa-th-list pt-1"></i> Term and Condition View
                                                </TooltipButton>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                    <Row noGutters>
                                        {
                                            viewMode === viewModes.rateList &&
                                            <Col xs={12}>
                                                <RateGrid
                                                    selectedProject={selectedProject}
                                                    topLeftBar={topLeftBar}
                                                    setTopLeftBar={setTopLeftBar} />
                                                {/*<RateList selectedProject={selectedProject} />*/}
                                            </Col>
                                        }
                                        {
                                            viewMode === viewModes.termList &&
                                            <Col xs={12}>
                                                <TcGrid
                                                    selectedProject={selectedProject}
                                                    topLeftBar={topLeftBar}
                                                    setTopLeftBar={setTopLeftBar}
                                                />
                                                {/*<TcList selectedProject={selectedProject} />*/}
                                            </Col>
                                        }
                                    </Row>
                                </Container>


                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </section>
    )

}