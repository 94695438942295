import React from "react";
import Loader from "../../../loaders";
import PropTypes from "prop-types";

function TemplateDialog(props) {
    const {
        title,
        onClose,
        loading,
        content,
        footer,
    } = props;

    return (
        <>
            <div className="modal-header">
                <h5 className="modal-title">{title}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                {(loading) && <Loader />}
                {content}
            </div>
            <div
                className="modal-footer"
            >
                {footer}
            </div>
        </>
    )
};

TemplateDialog.propTypes = {
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    content: PropTypes.object.isRequired,
    footer: PropTypes.object.isRequired,
}

export default TemplateDialog;
