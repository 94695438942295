import React, { useEffect, useState } from 'react';
import { Container, Row, Col, FormGroup, Label } from 'reactstrap';

const legendStyle = {
    width: "60px"
};

export default (props) => { 
    
    const {
        api,
        columnApi,
    } = props;


    return (
        <Container fluid>
            <Row>
                <Col xs={12} className="pt-3">
                    <h5>Roster Legend</h5>
                </Col>

                <Col xs={1} className="">
                    <i className="fas fa-certificate font-1000 mt-1"></i>
                </Col>
                <Col xs={1} className="">
                    <span className="key-roster working mt-1"></span>
                </Col>
                <Col xs={9}>
                    <p className="text-muted mx-1 my-0 font-875">Working Day (W)</p>
                </Col>

                <Col xs={1} className="">
                    <i className="fas fa-moon font-1000 mt-1"></i>
                </Col>
                <Col xs={1} className="">
                    <span className="key-roster night mt-1"></span>
                </Col>
                <Col xs={9}>
                    <p className="text-muted mx-1 my-0 font-875">Working Night (N)</p>
                </Col>

                <Col xs={1} className="">
                    <i className="fas fa-play font-1000 mt-1"></i>
                </Col>
                <Col xs={1} className="">
                    <span className="key-roster start mt-1"></span>
                </Col>
                <Col xs={9}>
                    <p className="text-muted mx-1 my-0 font-875">Work Start (S)</p>
                </Col>

                <Col xs={1} className="">
                    <i className="fas fa-stop font-1000 mt-1"></i>
                </Col>
                <Col xs={1} className="">
                    <span className="key-roster end mt-1"></span>
                </Col>
                <Col xs={9}>
                    <p className="text-muted mx-1 my-0 font-875">Work End (E)</p>
                </Col>

                <Col xs={1} className="">
                    <i className="fas fa-car font-1000 mt-1"></i>
                </Col>
                <Col xs={1} className="">
                    <span className="key-roster drive-in mt-1"></span>
                </Col>
                <Col xs={9}>
                    <p className="text-muted mx-1 my-0 font-875">Drive-In (C)</p>
                </Col>

                <Col xs={1} className="">
                    <i className="fas fa-car-side font-1000 mt-1"></i>
                </Col>
                <Col xs={1} className="">
                    <span className="key-roster drive-out mt-1"></span>
                </Col>
                <Col xs={9}>
                    <p className="text-muted mx-1 my-0 font-875">Drive-Out (O)</p>
                </Col>

                <Col xs={1} className="">
                    <i className="fas fa-plane-arrival font-1000 mt-1"></i>
                </Col>
                <Col xs={1} className="">
                    
                </Col>
                <Col xs={9}>
                    <p className="text-muted mx-1 my-0 font-875">Mobilise (M)</p>
                </Col>

                <Col xs={1} className="">
                    <i className="fas fa-plane-departure font-1000 mt-1"></i>
                </Col>
                <Col xs={1} className="">
                    
                </Col>
                <Col xs={9}>
                    <p className="text-muted mx-1 my-0 font-875">Demobilise (D)</p>
                </Col>

                <Col xs={1} className="">
                    <i className="fas fa-graduation-cap font-1000 mt-1"></i>
                </Col>
                <Col xs={1} className="">
                    
                </Col>
                <Col xs={9}>
                    <p className="text-muted mx-1 my-0 font-875">Training (T)</p>
                </Col>

                <Col xs={1} className="">
                    <i className="fas fa-virus font-1000 mt-1"></i>
                </Col>
                <Col xs={1} className="">
                    
                </Col>
                <Col xs={9}>
                    <p className="text-muted mx-1 my-0 font-875">Sick Leave (U)</p>
                </Col>

                <Col xs={1} className="">
                    <span className="leave mx-1 font-1000"></span>
                </Col>
                <Col xs={1} className="">
                    
                </Col>
                <Col xs={9}>
                    <p className="text-muted mx-1 my-0 font-875">Leave (L)</p>
                </Col>

                <Col xs={1} className="">
                    <span className="randr mx-1 font-1000"></span>
                </Col>
                <Col xs={1} className="">
                    
                </Col>
                <Col xs={9}>
                    <p className="text-muted mx-1 my-0 font-875">R&R (R)</p>
                </Col>

                <Col xs={1} className="">
                    <span className="fatigue mx-1 font-1000"></span>
                </Col>
                <Col xs={1} className="">
                    
                </Col>
                <Col xs={9}>
                    <p className="text-muted mx-1 my-0 font-875">Fatigue Day (F)</p>
                </Col>

                <Col xs={1} className="">
                    <span className="lwop mx-1 font-1000"></span>
                </Col>
                <Col xs={1} className="">
                    
                </Col>
                <Col xs={9}>
                    <p className="text-muted mx-1 my-0 font-875">LWOP (P)</p>
                </Col>
            </Row>
        </Container>
    );
}