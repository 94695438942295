import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    Button, Modal, Row, Col, Container, FormGroup, Label
} from "reactstrap";
import { ComboBox, DropDownList } from "@progress/kendo-react-dropdowns";
import { AgGridReact } from 'ag-grid-react';
import SendEmailForContingency from "./SendEmailForContigency"
import SendSmsForContingency from "./SendSmsForContingency"
import api from "../../../../services/api";
import Loader from "../../../loaders";
import qs from "qs";
import debounce from 'lodash.debounce';
import { formatDate } from "../../../../utils";
import "../../../../assets/css/ag-grid.css";
import "../../../../assets/css/ag-theme-alpine.css";
import "./contingency.css";

const getContingencyDatasource = (dispatchUnit) => {
    return {
      getRows: (params) => {
        params.api.showLoadingOverlay();
        const query = {
            sortBy: params.request.sortModel.map(m => ({id: m.colId, desc: m.sort == 'desc'})),
            filters: Object.entries(params.request.filterModel).map(([id, value]) => ({id, value: value.filter}))
        }
        const queryString = qs.stringify(query, { allowDots: true });
        
        api.get(`/contingency/${dispatchUnit.lookupId}/${params.request.startRow}/${params.request.endRow - params.request.startRow}?${queryString}`)
        .then((response) => {
            
            params.success({
                rowData: response.data.candidates,
                rowCount: response.data.total,
            });
        })
        .catch((error) => {
            console.log(error.response);
            params.fail();
        }).finally(() => {
            params.api.hideOverlay();
        });

      },
    };
  };

export default function Contingency(props) {

    const {
        dispatchUnit,
        onClose,
        rosterGrid,
        node
    } = props;

    const gridRef = useRef();
    const [loading, setLoading] = React.useState(false);

    const [ selectedRow, setSelectedRow ] = React.useState(null);
    const [ selectedCandidate, setSelectedCandidate] = React.useState([]);

    const [onSendEmail, setOnSendEmail] = React.useState(false);
    const [onSendSms, setOnSendSms] = React.useState(false);
    const [lookups, setLookups] = React.useState([]);


    const [ candidate, setCandidate ] = useState(null);
    const [ candidates, setCandidates ] = useState([]);
    const [ candidatesLoading, setCandidatesLoading ] = useState(false);
    const [ slots, setSlots ] = useState([]);
    const [ selectedSlot, setSelectedSlot ] = useState(null);

    const columnDefs = useMemo(() => [
        { field: 'firstName', minWidth: 160, sort: 'asc', sortIndex: 2 },
        { field: 'surname', minWidth: 160, sort: 'asc', sortIndex: 1 },
        { field: 'position', minWidth: 160 },
        { field: 'entity', minWidth: 160 },
    ]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: false,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                debounceMs: 200,
            },
            suppressMenu: true,
            floatingFilter: true,
            
        };
    }, []);

    useEffect(() => {
        
        var array = [];
        rosterGrid.current.api.forEachNode(({data}) => {
            array.push({ rosterCandidateId: data.rosterCandidateId, value: `Slot: ${data.slot} | ${data.hrispositionMasterName ?? 'NA'} | ${data.candidateName ?? 'NA'}` });
        })

        if (typeof node == 'object')
        {
            setSelectedSlot(array.find(f => f.rosterCandidateId === node.data.rosterCandidateId));
        }
        
        setSlots(array);


        // Set lookups
        let apiCalls = [
            api.get(`/lookup/skillposition`),
            api.get(`/lookup/candidatetype`),
            api.get(`/lookup/salutation`),
            api.get(`/lookup/preference`),
            api.get(`/lookup/industry`),
            api.get(`/lookup/gender`),
            api.get(`/lookup/maritalStatus`),
            api.get(`/lookup/candidateStatus`),
            api.get(`/lookup/candidateFlag`),
            api.get(`/lookup/residencyStatus`),
            api.get(`/lookup/visatype`),
            api.get(`/lookup/communicationtype`),
            api.get(`/lookup/country`),
            api.get(`/lookup/state`),
            api.get(`/lookup/relationshiptype`),
            api.get(`/lookup/candidateplatformorigin`),
            api.get(`/lookup/recruitmentpeople`),
            api.get(`/lookup/messagesmasters`),
            api.get(`/emailtemplate`), // TODO: returns paged result - move to lookup
            api.get(`/lookup/candidatediscipline`),
            api.get(`/lookup/company`),
            api.get(`/lookup/identifier`),
            api.get(`/lookup/candidateevent`),
        ];

        Promise.all(apiCalls)
            .then((data) => {
                setLookups({
                    skillPositions: data[0].data,
                    candidateTypes: data[1].data,
                    salutations: data[2].data,
                    preferences: data[3].data,
                    industries: data[4].data,
                    genders: data[5].data,
                    maritalStatuses: data[6].data,
                    candidateStatuses: data[7].data,
                    candidateFlags: data[8].data,
                    residencyStatuses: data[9].data,
                    visaTypes: data[10].data,
                    communicationTypes: data[11].data,
                    countries: data[12].data,
                    states: data[13].data,
                    relationshipTypes: data[14].data,
                    candidatePlatformOrigin: data[15].data,
                    recruitmentPeople: data[16].data,
                    messagesMaster: data[17].data,
                    emailTemplate: data[18].data.data,
                    candidateDisciplines: data[19].data,
                    companies: data[20].data,
                    identifiers: data[21].data,
                    events: data[22].data,
                });
            }).finally(() => {
                setLoading(false);



            });


    }, [])

    const onGridReady = useCallback((params) => {
        var datasource = getContingencyDatasource(dispatchUnit);
        params.api.setServerSideDatasource(datasource);
    }, []);

    useEffect(() => {
        console.log('selectedCandidate', selectedCandidate);
    }, [selectedCandidate]);

    const onSelectionChanged = useCallback((params) => {
        const selectedRows = params.api.getSelectedRows();
        console.log('selectedRows', selectedRows)
        if (selectedRows.length > 1) {
            setSelectedRow(null);
        } else {
            setSelectedRow(selectedRows[0]);
        }

        if (selectedRows && selectedRows.length) {
            setSelectedCandidate(selectedRows.map(s => s.candidate));
        } else {
            setSelectedCandidate([]);
        }
      }, []);

    const getRowId = useMemo(() => (params) => params?.data?.rosterContingencyId, []);

    const loadingOverlayComponent = useMemo(() => Loader, []);

    const requestCandidateData = useCallback(
        debounce((filter) => {
            
            const queryString = qs.stringify({ filter }, { allowDots: true });

            api.get(`/contingency/candidates/${dispatchUnit.lookupId}${queryString ? `?${queryString}` : ""}`)
            .then((response) => {
                if (!response.data)
                {
                    setCandidates([]);
                } 
                else 
                {
                    setCandidates(response.data);
                }

            })
            .catch((error) => {
                console.log(error.response)
            })
            .finally(() => {
                setCandidatesLoading(false);
            });
        }, 300)
    , [dispatchUnit]);

    const onCandidateFilterChange = useCallback((event) => {
        setCandidatesLoading(true);
        requestCandidateData(event.filter?.value);
        setCandidates([]);
    }, []);

    const onCandidateChange = useCallback((event) => {
        
        setCandidate(event.value);
    }, []);

    const onAdd = useCallback((event) => {
        
        gridRef.current.api.showLoadingOverlay();
        api.post(`/contingency/candidate/${dispatchUnit.lookupId}/${candidate.candidateId}`)
            .then(({data}) => {
                gridRef.current.api.refreshServerSide();
                setCandidate(null);
            })
            .catch((error) => {
                console.log(error.response)
            })
            .finally(() => {
                gridRef.current.api.hideOverlay();
            });
    }, [dispatchUnit, candidate]);

    const insertToRoster = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();

        const queryString = qs.stringify({
            startDate: rosterGrid.current.props.context.trimStartDate ? undefined : formatDate(rosterGrid.current.props.context.startDate, 'YYYY-MM-DD'),
            endDate: rosterGrid.current.props.context.trimEndDate ? undefined : formatDate(rosterGrid.current.props.context.endDate, 'YYYY-MM-DD'),
        }, { allowDots: true });

        api.post(`/rostergrid/rostercandidate/${selectedSlot.rosterCandidateId}/candidate/${selectedRow.candidateId}?${queryString}`)
        .then((response) => {
            const tx = {
                 update: response.data,
            };

            rosterGrid.current.api.applyTransaction(tx);
        })
        .catch((error) => {
            // TODO: Display Error message in UI
            console.log(error.response)
        })
        .finally(() => {
            gridRef.current.api.hideOverlay();
            onClose();
        });

    }, [rosterGrid, selectedRow, selectedSlot]);

    const getContextMenuItems = useCallback((params) => {

        return [
            {
                name: 'remove',
                action: () => {
                    params.api.showLoadingOverlay();

                    const items = params.api.getSelectedRows().map(m => m.rosterContingencyId);
                    console.log('remove', {params, items})
                    if (items.length > 0) {
                        api.delete(`/contingency/candidate`, {
                            data: {
                                rosterContingencyId: items
                            }
                        })
                        .then(() => {
                            params.api.refreshServerSide();
                        })
                        .catch((error) => {
                            console.log(error.response)
                        })
                        .finally(() => {
                            params.api.hideOverlay();
                            setSelectedRow(null);
                        });
                    }
                    
                },
            },
        ]
    }
    , []);

    return (
        <Modal
            isOpen={true}
            className="modal-xxl"
            modalClassName="db-example-modal-xl"
        >
            {(loading) && <Loader />}

            <div className="modal-header">
                <Container fluid>
                    <Row>
                        <Col lg={9}>
                            <h5
                                className="modal-title"
                            >
                                Contingency
                            </h5>
                        </Col>
                        <Col lg={3}>
                            <button
                                aria-label="Close"
                                className="close"
                                onClick={onClose}
                                type="button"
                            >
                                <span aria-hidden={true}>x</span>
                            </button>
                        </Col>
                    </Row>
                    
                </Container>
            </div>
            <div
                className="modal-body"
            >
                <Container fluid className='px-0'>
                    <Row>
                        <Col lg={3}>
                            <FormGroup>
                                <Label>
                                    Candidate
                                </Label>
                                <ComboBox
                                    data={candidates}
                                    value={candidate}
                                    onChange={onCandidateChange}
                                    dataItemKey="candidateId"
                                    textField="candidateName"
                                    filterable={true}
                                    onFilterChange={onCandidateFilterChange}
                                    onFocus={onCandidateFilterChange}
                                    loading={candidatesLoading}
                                    rounded="small"
                                    size="small"
                                    className='w-100'
                                    placeholder='Search...'
                                />
                            </FormGroup>
                        </Col>

                        <Col>
                            <Button
                                color="secondary"
                                onClick={onAdd}
                                type="button"
                                disabled={!candidate}
                                style={{height:'36px', marginTop: '31px'}}
                            >
                                Add
                            </Button>
                            <Button
                                color="secondary"
                                onClick={() => setOnSendSms(true)}
                                type="button"
                                disabled={!selectedCandidate || !selectedCandidate.length}
                                style={{ height: '36px', marginTop: '31px' }}
                            >
                                Send SMS
                            </Button>
                            <Button
                                color="secondary"
                                onClick={() => setOnSendEmail(true)}
                                type="button"
                                disabled={!selectedCandidate || !selectedCandidate.length}
                                style={{ height: '36px', marginTop: '31px' }}
                            >
                                Send Email
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className="ag-theme-alpine px-0" style={{width: '100%', height: 'calc(100vh - 360px)'}}>
                                <AgGridReact
                                    ref={gridRef}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    rowModelType={'serverSide'}
                                    serverSideInfiniteScroll={true}
                                    pagination={true}
                                    paginationPageSize={50}
                                    cacheBlockSize={50}
                                    animateRows={true}
                                    onGridReady={onGridReady}
                                    headerHeight={22}
                                    rowHeight={22}
                                    rowSelection='multiple'
                                    onSelectionChanged={onSelectionChanged}
                                    getRowId={getRowId}
                                    loadingOverlayComponent={loadingOverlayComponent}
                                    getContextMenuItems={getContextMenuItems}
                                ></AgGridReact>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
            <div
                className="modal-footer w-100"
            >
                <Container fluid>
                    <Row className="w-100 m-0">
                        <Col lg="9" className="d-flex flex-wrap justify-content-start align-items-center m-0 p-0">

                            <DropDownList
                                className='mr-3'
                                style={{
                                width: "400px",
                                }}
                                data={slots}
                                textField="value"
                                dataItemKey="rosterCandidateId"
                                value={selectedSlot}
                                onChange={(event) => {
                                    
                                    setSelectedSlot(event.target.value)
                                }}
                            />
                            {selectedRow && `${selectedRow.firstName} ${selectedRow.surname}`}
                            <Button
                                className='ml-3'
                                color="info"
                                onClick={() => {
                                    insertToRoster()
                                }}
                                type="button"
                                disabled={!selectedRow || !selectedSlot}
                            >
                                Insert
                            </Button> 
                        </Col>
                        <Col lg="3" className="d-flex flex-wrap justify-content-end align-items-center m-0 p-0">

                            <Button
                                color="secondary"
                                onClick={onClose}
                                type="button"
                            >
                                Close
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            {
                selectedCandidate && onSendEmail &&
                <SendEmailForContingency
                    //candidateId={selectedCandidate}
                    items={selectedCandidate}
                    onClose={() => {
                        setOnSendEmail(false);
                        //setEmailCandidateId(null);
                        //setItemEmail([]);

                        //if (candidateCallback.current) {
                        //    candidateCallback.current(true);
                        //}
                    }}
                    onSaved={() => {
                        setOnSendEmail(false);
                        //setEmailCandidateId(null);

                        //if (candidateCallback.current) {
                        //    candidateCallback.current(true);
                        //}
                    }}
                    lookups={{
                        ...lookups
                    }}
                />
            }
            {
                selectedCandidate && onSendSms &&
                <SendSmsForContingency
                    //candidateId={selectedCandidate}
                    items={selectedCandidate}
                    onClose={() => {
                        setOnSendSms(false);
                        //setEmailCandidateId(null);
                        //setItemEmail([]);

                        //if (candidateCallback.current) {
                        //    candidateCallback.current(true);
                        //}
                    }}
                    onSaved={() => {
                        setOnSendSms(false);
                        //setEmailCandidateId(null);

                        //if (candidateCallback.current) {
                        //    candidateCallback.current(true);
                        //}
                    }}
                    lookups={{
                        ...lookups
                    }}
                />
            }
        </Modal>
    )
}