import React, { useEffect } from 'react';
import api from "../../../services/api";
import {
    Label, FormGroup, Button, Modal, Container, Row, Col, Alert, Input
 } from "reactstrap"; 
import Loader from "../../loaders";
import { DropdownBox } from "../../dropdowns";
import { ComboBox } from "../../dropdowns";
import { useDropzone } from 'react-dropzone';
import cn from "classnames";
import fileDownload from "js-file-download";
import _ from "lodash";

// const TemplateSources = [{key: 1, value: "Contract Template"}, {key: 2, value: "Document"}]
const TemplateSources = [{key: 1, value: "Contract Template"}]

export default function Editor(props) {
    const {
        item
    } = props;

    const [ loading, setLoading ] = React.useState(false);
    const [ selectedTemplateSource, setSelectedTemplateSource ] = React.useState((item && item.contractTemplateId) || !item.projectContractTemplateId ? TemplateSources[0] : null);
    const [ itemInEdit, setItemInEdit ] = React.useState(item ?? {});
    const [ validationAlert, setValidationAlert ] = React.useState([]);

    // useEffect(() => {
    //     console.log({item});
    // }, [item]);

    const apiurl = "projectcontracttemplate";
    const contracttemplateapiurl = "/contracttemplate/searchbytype/contracttemplatetype/project";

    React.useEffect(() => {
        setItemInEdit({
            ...itemInEdit,
            file: selectedTemplateSource?.key === 1 ? null : itemInEdit.file,
            contractTemplateId: selectedTemplateSource?.key === 2 ? null : itemInEdit.contractTemplateId,
        });
    }, [selectedTemplateSource]);

    const {
        getRootProps, 
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        maxFiles: 1,
        multiple: false,
        onDrop: ([file]) => {
            setItemInEdit( { ...itemInEdit, file } );
        }
    });

    const handleTextChanged = ({ target: { name, value } }) => {
        setItemInEdit({ ...itemInEdit, [name]: value });
    }

    const downloadAttachment = (fileInfo) => {
        if (fileInfo instanceof File) {
            fileDownload(fileInfo, fileInfo.name);
        } else {
            setLoading(true);
            let url = `${apiurl}/download/${fileInfo.projectContractTemplateId}`;
            api.get(url, { responseType: 'blob' })
            .then(blob => {
                fileDownload(blob.data, fileInfo.templateFilename);
            }).catch(error => {
                console.error(error)
            }).finally(() => setLoading(false))
        }
    }

    const validate = React.useCallback(() => {
        let errors = [ ...validationAlert ];
        const fileExists = _.some(errors, (item) => item.key === "file" && item.type === "required");

        const hasFile = selectedTemplateSource?.key === 1 || (!!itemInEdit.file || (!itemInEdit.file && !!itemInEdit.templateFilename));
        if (!hasFile && !fileExists)
        {
            errors.push( { key: "file", type: "required", message:  "A File is required" } )
        } else if (hasFile && fileExists)
        {
            _.remove(errors, (item) => item.key === "file" && item.type === "required");
        }

        setValidationAlert([ ...errors ]);
        if (errors.length > 0) {
            return false;
        }
        return true;
    }, [selectedTemplateSource, itemInEdit, validationAlert]);

    const files = React.useMemo(() => {
        let name, size, file;

        if (itemInEdit.file) {
            name = itemInEdit.file.name;
            size = itemInEdit.file.size;
            file = itemInEdit.file;
        } else if (itemInEdit.templateFilename) {
            name = itemInEdit.templateFilename;
            size = itemInEdit.templateLength;
            file = itemInEdit;
        } else {
            return null;
        }
        return <aside className="dropzone-list pt-3">
            <Label>Files</Label>
            <ul>
                <li key={name} className="pt-1">
                    <a onClick={() => downloadAttachment(file)}>{name} - {size} bytes</a>
                </li> 
            </ul>
        </aside>
    }, [ itemInEdit.file ]);

    const dropzoneClasses = React.useMemo(() => cn({
        'dropzone': true,
        'mt-2': true,
        'active': isDragActive,
        'accept': isDragAccept,
        'reject': isDragReject
    }), [
        isDragActive,
        isDragAccept,
        isDragReject
    ]);

    React.useEffect(() => {
        if (itemInEdit && validationAlert.length > 0) validate();
    }, [itemInEdit]);

    const handleSave = (item) => {
        if (!validate())
            return;

        setLoading(true);

        let url = `${process.env.REACT_APP_WEB_API_BASE_URI}/${apiurl}`;
        let method = "POST";

        if (!!item.projectContractTemplateId)
        {
            url = `${url}/${item.projectContractTemplateId}`;
            method = "PUT";
        }

        var formData = new FormData();

        formData.append("projectId", item.projectId ?? 0);
        formData.append("projectContractTemplateId", item.projectContractTemplateId ?? 0);
        formData.append("contractTemplateId", selectedTemplateSource?.key === 2 ? 0 : (item.contractTemplateId ?? 0));
        formData.append("templateName", item.templateName);

        if (selectedTemplateSource?.key === 2 && item.file) {
            formData.append("file", item.file);
        }

        api({ url, method, data: formData }).then((response) => {
            setLoading(false);
            props.onSaved();
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
        });
    }

    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    {props.title ?? ""}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times" /></span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                {validationAlert && validationAlert.length > 0 && <Alert color="warning" className="pa-0 d-flex justify-content-between">
                    <span className="alert-text">
                        <ul className="mb-0">
                            {
                                validationAlert.map((item, index) => <li key={index}>{item.message}</li>)
                            }
                        </ul>
                    </span>
                </Alert>}
                <Container>
                    {(loading) && <Loader />}
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Template Source
                                    <span className="text-danger">*</span>
                                </Label>
                                <DropdownBox
                                    data={TemplateSources}
                                    selectedItem={selectedTemplateSource}
                                    idField="key"
                                    valueField="value"
                                    onChange={(item) => setSelectedTemplateSource(item)}
                                />
                            </FormGroup>
                        </Col>
                        
                        {
                            (selectedTemplateSource?.key === 1) &&
                            <Col xs={12}>
                                <FormGroup>
                                    
                                    <Label>
                                        Template
                                    </Label>
                                    <ComboBox 
                                        minLength={0}
                                        endpoint={contracttemplateapiurl}
                                        isLookup={false}
                                        idField="lookupId"
                                        valueField="lookupValue"
                                        selectedItem={itemInEdit.contractTemplate ? {lookupId: itemInEdit.contractTemplate.contractTemplateId, lookupValue: itemInEdit.contractTemplate.templateName} : null}
                                        onChange={(item) => {
                                            // console.log('template', item)
                                            setItemInEdit( { 
                                                ...itemInEdit, 
                                                contractTemplate: item ? {contractTemplateId: item.lookupId, templateName: item.lookupValue} : null, 
                                                contractTemplateId: item ? item.lookupId : null,
                                                templateName: item ? item.lookupValue : null,
                                            } );
                                        }}
                                    />
                                </FormGroup>
                                
                            </Col>
                        }
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Name
                                </Label>
                                <Input
                                    name="templateName"
                                    id="templateName"
                                    type="text"
                                    required
                                    value={itemInEdit.templateName}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        {
                            (selectedTemplateSource?.key === 2) &&
                            <Col xs={12}>
                                <div {...getRootProps({className: dropzoneClasses})}>
                                    <input {...getInputProps()} />
                                    <p>Drag 'n' drop a file here, or click to select a file</p>
                                </div>
                                {files}
                            </Col>
                        }
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={() => {
                        props.onClose();
                    }}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave(itemInEdit)}
                    type="button"
                >
                    Save Changes
                </Button>
            </div>
        </Modal>
    )
}