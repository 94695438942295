import React, { useCallback, useEffect, useState } from 'react';
import api from "../../../../services/api";
import {
    Label, FormGroup, Button, Modal, Container, Row, Col
 } from "reactstrap"; 
import Loader from "../../../loaders";
import { ComboBox } from '../../../dropdowns';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import QuestionEditor from './QuestionEditor';
import { TrimObject } from '../../../../helpers/StringHelper';

export default function Editor(props) {
    const {item, onClose, onSaved} = props;
    const [ itemInEdit, setItemInEdit ] = useState(item);
    const [ loading, setLoading ] = useState(false);
    const [ questionNumbers, setQuestionNumbers ] = useState([]);
    const [ openQuestionEditor, setOpenQuestionEditor ] = useState(false);

    const loadData = useCallback(() => {
        setLoading(true);
        api.get(`/feedback/project/questions/${item.projectId ?? 0}`)
            .then(({data}) => {
                setItemInEdit({...data});
                const numbers = [];

                for (let i = 1; i <= data.questions.length; i++)
                    numbers.push({id: i, value: `${i}`});

                numbers.push({id: data.questions.length + 1, value: "-"});
                setQuestionNumbers(numbers);
            })
            .catch((error) => {
                console.error("error: ", error);
            })
            .finally(() => setLoading(false))
        ;
    }, [item]);

    useEffect(() => {
        loadData();
    }, [item]);

    const loadAdditionalQuestions = useCallback(() => {
        setLoading(true);
        api.get("/feedback/questions")
            .then(({data}) => {
                const newData = JSON.parse(JSON.stringify(data.filter(d => itemInEdit?.questions.length ? (!itemInEdit.questions.filter(q => q.feedbackQuestionId === d.feedbackQuestionId).length) : true)));
                setItemInEdit({
                    ...itemInEdit,
                    questions: [
                        ...(itemInEdit?.questions ?? []),
                        ...newData.map(d => ({
                            ...d,
                            projectFeedbackQuestionId: 0,
                            orderNumber: null,
                            projectId: null,
                            isHidden: true,
                            question: d.feedbackQuestion,
                        })),
                    ],
                });
                const numbers = [];

                for (let i = 1; i <= data.length; i++)
                    numbers.push({id: i, value: `${i}`});

                numbers.push({id: data.length + 1, value: "-"});
                setQuestionNumbers(numbers);
            })
            .catch((error) => {
                console.error("error: ", error);
            })
            .finally(() => setLoading(false))
        ;
    }, [itemInEdit]);

    const handleQuestionNumberChange = useCallback(({target: {value: {id}}}, feedbackQuestionId) => {
        const details = JSON.parse(JSON.stringify(itemInEdit.questions));
        const updatedRow = details.filter(d => d.feedbackQuestionId === feedbackQuestionId)[0];

        if (!updatedRow)
            return;

        const newRow = JSON.parse(JSON.stringify(updatedRow));
        const oldOrderNumber = newRow.orderNumber;

        if (id <= details.length)
        {
            const oldOrderNumberOwner = details.filter(d => d.orderNumber === id && d.feedbackQuestionId !== feedbackQuestionId)[0];

            if (oldOrderNumberOwner)
            {
                oldOrderNumberOwner.orderNumber = oldOrderNumber;

                if (!oldOrderNumber)
                    oldOrderNumberOwner.isHidden = true;
            }
        }

        newRow.orderNumber = id > details.length ? null : id;
        newRow.isHidden = id > details.length;
        details.splice(details.indexOf(updatedRow), 1, newRow);
        details.sort((a, b) => (a.orderNumber ?? details.length + 1) - (b.orderNumber ?? details.length + 1));

        details.filter(d => d.orderNumber && d.orderNumber <= details.length).forEach((d, idx) => {
            d.orderNumber = idx + 1;
        });

        // console.log({updatedRow, details, id});

        setItemInEdit({
            ...itemInEdit,
            questions: details,
        });
    }, [itemInEdit]);

    const handleSave = (item) => {
        setLoading(true);

        // Trim every string property
        item = TrimObject(item);
        setItemInEdit({ ...item });

        // Save
        item.questions.forEach(q => q.projectId = item.projectId);
        const data = JSON.stringify(item);
        const save = item.questions.filter(q => q.projectFeedbackQuestionId > 0).length > 0 
            ? api.put(`feedback/project/questions`, data)
            : api.post(`feedback/project/questions`, data)

        save
            .then(() => {
                setLoading(false);
                onSaved();
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }

    return (
        <>
            <Modal
                isOpen={true}
                className="modal-lg"
                modalClassName="db-example-modal-lg"
            >
                <div
                    className="modal-header"
                >
                    <h5
                        className="modal-title"
                    >
                        Project Feedback Questions{itemInEdit.projectName ? ` - ${itemInEdit.projectName}` : ""}
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        onClick={onClose}
                        type="button"
                    >
                        <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                    </button>
                </div>
                <div
                    className="modal-body"
                >
                    <Container>
                        {(loading) && <Loader />}
                        <Row>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label>
                                        Project
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <ComboBox
                                        minLength={0}
                                        endpoint="/project/search/false"
                                        isLookup={false}
                                        idField="projectId"
                                        valueField="projectName"
                                        selectedItem={itemInEdit.projectId ? {projectId: itemInEdit.projectId, projectName: itemInEdit.projectName} : null}
                                        onChange={(selected) => setItemInEdit({
                                            ...itemInEdit,
                                            projectId: selected?.projectId,
                                            projectName: selected?.projectName,
                                        })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="border-bottom mb-3">
                                <Label>
                                    Questions
                                </Label>
                            </Col>
                            <Col xs={12} style={{height: "40vh", maxheight: "40vh", overflowY: "auto"}}>
                            {
                                itemInEdit?.questions?.map((q, idx) => {
                                    const currentNumber = q.isHidden ? questionNumbers[questionNumbers.length - 1] : questionNumbers.filter(n => n.id === q.orderNumber)[0];

                                    return (
                                        <Row key={`q_${idx}`} className={idx > 0 ? "mt-2" : ""}>
                                            <Col xs={12} className="d-flex flex-wrap justify-content-start align-items-center">
                                                <DropDownList
                                                    data={questionNumbers}
                                                    textField="value"
                                                    dataItemKey="id"
                                                    value={currentNumber}
                                                    style={{width: "55px"}}
                                                    className="font-size-small-custom"
                                                    onChange={(e) => handleQuestionNumberChange(e, q.feedbackQuestionId)}
                                                />
                                                <p className="m-0 ml-2 font-size-small-custom text-break">{q.question}</p>
                                            </Col>
                                        </Row>
                                    );
                                })
                            }
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="modal-footer"
                >
                    <Button
                        color="secondary"
                        onClick={props.onClose}
                        type="button"
                    >
                        Close
                    </Button>
                    <Button
                        color="info"
                        onClick={() => setOpenQuestionEditor(true)}
                        type="button"
                    >
                        Add More Questions
                    </Button>
                    <Button
                        color="info"
                        onClick={() => handleSave(itemInEdit)}
                        type="button"
                        disabled={!itemInEdit?.projectId ? true : false}
                    >
                        Save Changes
                    </Button>
                </div>
            </Modal>
        {
            openQuestionEditor &&
            <QuestionEditor
                onSaved={() => {
                    setOpenQuestionEditor(false);
                    loadAdditionalQuestions();
                }}
                onClose={() => setOpenQuestionEditor(false)}
            />
        }
        </>
    )
}