/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useMemo, useCallback, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button, Col, Container, FormGroup, Input, Label, Modal, Row, Spinner } from 'reactstrap';
import { EditorState, ContentState, convertToRaw, SelectionState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { useTable, useFlexLayout, useFilters, useSortBy } from 'react-table';
import jsonFormData from "json-form-data";
import { DatePicker } from '../../../date-pickers';
import Loader from '../../../loaders';
import api from "../../../../services/api";
import fileDownload from "js-file-download";
import moment from 'moment';
import { FileDropzone, DropzoneList } from '../../../FileDropzone';
import { DefaultColumnFilter, DateColumnFilter } from '../../../react-table/filters';
import { TooltipButton } from '../../../inputs';
import Grid from '../../../Grid';
import { currencyFormatterAUD, formatDate, momentToDateOnly } from '../../../../utils';
import VacancyPreview from './VacancyPreview';
import { FormatFileSize } from '../../../../helpers/StringHelper'
import { ComboBox, DropdownBox } from "../../../dropdowns";



export default function EditDialog(props) {

    // Props
    const { onClose, onSave, jobOrderCandidate, refresh, jobOrder } = props;


    // Regular variables
    const [loading, setLoading] = useState(false);
    const [itemInEdit, setItemInEdit] = useState({
        ...jobOrderCandidate,
        startDate: !jobOrderCandidate.startDate ? null : momentToDateOnly(jobOrderCandidate.startDate),
        endDate: !jobOrderCandidate.endDate ? null : momentToDateOnly(jobOrderCandidate.endDate),
    });
    const [showRemovalConfirmation, setShowRemovalConfirmation] = useState(false);
    const [saved, setSaved] = useState(jobOrderCandidate?.startDate ? true : false);
    const [showExternalLink, setShowExternalLink] = useState(false);
    const [showVacancyPreview, setShowVacancyPreview] = useState(false);
    const [saveCount, setSaveCount] = useState(0);
    const [documentData, setDocumentData] = useState(null);
    const [documentList, setDocumentList] = useState([]);
    const [downloadingDocument, setDownloadingDocument] = useState(null);
    const [validationMessage, setValidationMessage] = useState(null);

    const [selectedEmail, setSelectedEmail] = React.useState(null);

    const skipPageResetRef = React.useRef();
    const [lookups, setLookups] = React.useState({});
    const [filters, setFilters] = React.useState([]);
    const [showPleaseSaveVacancyPreview, setShowPleaseSaveVacancyPreview] = useState(false);
    const [fileConfig, setFileConfig] = useState(null);

    // Editor variables
    const [firstLoadEditor, setFirstLoadEditor] = React.useState(true);
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setItemInEdit({ ...itemInEdit, vacancyDescription: draftToHtml(convertToRaw(editorState.getCurrentContent())) });
        //setSelectedEmail({ ...selectedEmail, lookupValue: draftToHtml(convertToRaw(editorState.getCurrentContent())) });
    }

    // Auto load vacancy description editor content
    useEffect(() => {
        if (!firstLoadEditor || itemInEdit?.vacancyDescription == null) {
            return;
        }

        // Use a regular expression to match and remove the font-size and font-family styles
        var newVacancyDescription = itemInEdit?.vacancyDescription;
        newVacancyDescription = newVacancyDescription.replace(/font-size: [^;]+;/g, '');
        newVacancyDescription = newVacancyDescription.replace(/font-family: [^;]+;/g, '');


        const contentBlock = htmlToDraft(newVacancyDescription);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const contentEditorState = EditorState.createWithContent(contentState);

            setEditorState(contentEditorState);
            setFirstLoadEditor(false);
        }
    }, [firstLoadEditor, jobOrderCandidate, itemInEdit])

    useEffect(() => {
        var newItemInEdit = {
            ...jobOrderCandidate,
            startDate: !jobOrderCandidate.startDate ? null : momentToDateOnly(jobOrderCandidate.startDate),
            endDate: !jobOrderCandidate.endDate ? null : momentToDateOnly(jobOrderCandidate.endDate),
        };

        setItemInEdit(newItemInEdit);
    }, [jobOrderCandidate]);

    // Refs
    const handleDateChanged = useCallback((name, value) => {
        // console.log({name, value});
        setItemInEdit({
            ...itemInEdit,
            [name]: value ? momentToDateOnly(value) : null,
            //[name]: value? value: null
        });
    }, [itemInEdit]);

    const lrfLabel = useMemo(() => {

        // Get the LRF and Job Order Id
        var lrfString = jobOrder.recruitmentRequestNumber ? jobOrder.recruitmentRequestNumber : '';
        var jobOrderIdString = jobOrder.jobOrderId ? jobOrder.jobOrderId : '';
        var newLrfOnBreadcrumb = lrfString ? `${lrfString} | ${jobOrderIdString}` : `${jobOrderIdString}`;

        return newLrfOnBreadcrumb;

    }, [jobOrder]);



    useEffect(() => {
        setLoading(true);
        const apiCalls = [
            api.get(`/lookup/fileconfig`),
        ];

        Promise.all(apiCalls)
            .then((responses) => {
                const fileconfig = { ...responses[0].data };
                setFileConfig(fileconfig);
            })
            .catch((error) => {
                console.log({ error });
            })
            .finally(() => setLoading(false))
            ;
    }, []);




    const handleSave = useCallback((close) => {
        if (!itemInEdit)
            return;

        setLoading(true);
        var postData = {...itemInEdit};
        var { jobOrderCandidateId } = itemInEdit;

        api.put(`/jobordercandidate/${jobOrderCandidateId}`, postData)
            .then(() => {
                setLoading(false);
                setSaveCount(saveCount + 1);
                setSaved(true);

                onSave(close);

            })
            .catch((error) => {
                console.log({ error });
                setLoading(false);
            })
            ;
    }, [itemInEdit, onSave, documentList]);


    const onSaveClick = useCallback((close = true) => {
        if (!itemInEdit.startDate) {
            setShowRemovalConfirmation(true);
            return;
        }

        handleSave(close);
    }, [itemInEdit, handleSave]);

    const onCloseClick = useCallback(() => {
        onClose();
    }, [onClose]);



    return (
        <Modal
            isOpen={true}
            className={'modal-xl'}
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Job Order Item
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onCloseClick}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                {/* <Container> */}
                {(loading) && <Loader />}
                <Row className={`h-100 job-order-header`}>
                    <Col lg={12} className="pl-2">
                        <div className="container-group pt-3 px-3 mb-3">
                            <Row>
                                <Col sm={4} className="mb-3">
                                    <div>ID</div>
                                    <div className="font-weight-bold">{itemInEdit.jobOrderCandidateId ?? 'N/A'}</div>
                                </Col>
                                <Col sm={4} className="mb-3">
                                    <div>LRF</div>
                                    <div className="font-weight-bold">{lrfLabel ?? 'N/A'}</div>
                                </Col>
                                <Col sm={4} className="mb-3">
                                    <div>Talent Name</div>
                                    <div className="font-weight-bold">{itemInEdit.candidateName ?? 'N/A'}</div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={4} xs={12}>
                        <FormGroup>
                            <Label>
                                Start Date
                                <span className="text-danger">*</span>
                            </Label>
                            <DatePicker
                                name="startDate"
                                id="startDate"
                                type="text"
                                required
                                value={itemInEdit?.startDate ? formatDate(itemInEdit.startDate) : null}
                                onChange={(value) => {
                                    handleDateChanged("startDate", value);
                                }}
                                closeOnSelect
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                        <FormGroup>
                            <Label>
                                End Date
                                {/*<span className="text-danger">*</span>*/}
                            </Label>
                            <DatePicker
                                name="endDate"
                                id="endDate"
                                type="text"
                                required
                                value={itemInEdit?.endDate ? formatDate(itemInEdit.endDate) : null}
                                onChange={(value) => {
                                    handleDateChanged("endDate", value);
                                }}
                                closeOnSelect
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4} xs={12}>
                        <FormGroup>
                            <Label>
                                Natural Position
                            </Label>
                            <ComboBox
                                minLength={0}
                                endpoint="/position/search"
                                isLookup={false}
                                idField="positionId"
                                valueField="positionName"
                                selectedItem={itemInEdit.position}
                                onChange={(selectedItem) => setItemInEdit({
                                    ...itemInEdit,
                                    position: selectedItem,
                                    positionId: selectedItem?.positionId
                                })}
                            />
                        </FormGroup>
                    </Col>


                </Row>
                {/* </Container> */}
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={onCloseClick}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => onSaveClick(false)}
                    type="button"
                    disabled={(!itemInEdit?.startDate)}
                >
                    Save
                </Button>
                <Button
                    color="info"
                    onClick={() => onSaveClick()}
                    type="button"
                    disabled={(!itemInEdit?.startDate)}
                >
                    Save & Close
                </Button>
            </div>
            {
                showRemovalConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Confirm"
                    confirmBtnBsStyle="danger"
                    title={<h4 className="mb-0">Vacancy Setting Clearance Confirmation</h4>}
                    onConfirm={() => handleSave()} // need to add loading indicator on confirm
                    onCancel={() => setShowRemovalConfirmation(false)}
                    focusCancelBtn
                >
                    <p>This will clear the vacancy setting. Talents will not be able to see this vacancy anymore.</p>
                    <p>Are you sure you want to continue?</p>
                </SweetAlert>
            }
            {
                showVacancyPreview &&
                <VacancyPreview
                    onClose={() => setShowVacancyPreview(false)}
                    itemInEdit={{ ...itemInEdit }}
                    jobOrder={{ ...jobOrderCandidate }}
                    documentList={{ ...documentList }}
                />
            }
            {
                showPleaseSaveVacancyPreview &&
                <SweetAlert
                    warning
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    title={<h4 className="mb-0">Vacancy Preview</h4>}
                    onConfirm={() => setShowPleaseSaveVacancyPreview(false)} // need to add loading indicator on confirm
                    onCancel={() => setShowPleaseSaveVacancyPreview(false)}
                    focusCancelBtn
                >
                    <p>Please click Save button before using the preview.</p>
                </SweetAlert>
            }
            {
                validationMessage &&
                <SweetAlert
                    warning
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    title={<h4 className="mb-0">Vacancy Preview</h4>}
                    onConfirm={() => setValidationMessage(null)} // need to add loading indicator on confirm
                    onCancel={() => setValidationMessage(null)}
                    focusCancelBtn
                >
                    <p>{validationMessage}</p>
                </SweetAlert>
            }
        </Modal>
    );
};

