import React, { useState } from 'react';
import { Button, Col, Container, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import { ComboBox } from '../../../dropdowns';
import PropTypes from "prop-types";

export default function JobOrderCancellationDialog(props) {
    const {jobOrderId, onClose, onSubmit} = props;
    const [ itemInEdit, setItemInEdit ] = useState({jobOrderId, cancelComment: null});

    return (
        <Modal
            isOpen={true}
        >
            <div className="modal-header">
                <h5 className="modal-title">Job Order Cancellation Dialog</h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div className="modal-body">
                <Container fluid className="px-0">
                    <Row>
                        <Col md={12} className="border-right border-black">
                            <FormGroup className="mb-3 pb-3 border-bottom-3">
                                <Label>
                                    Cancel Status
                                    <span className="text-danger">*</span>
                                </Label>
                                <ComboBox
                                    minLength={0}
                                    endpoint="/jobordercancelstatus"
                                    selectedItem={itemInEdit?.cancelledStatus}
                                    onChange={(item) => {
                                        setItemInEdit({
                                            ...itemInEdit,
                                            cancelledStatusId: item?.lookupId ?? null,
                                            cancelledStatus: item ? {...item} : null,
                                        });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12} className="border-right border-black">
                            <FormGroup className="mb-3 pb-3 border-bottom-3">
                                <Label>
                                    Comments
                                </Label>
                                <Input
                                    name="cancelComment"
                                    id="cancelComment"
                                    type="textarea"
                                    onChange={({ target: { value } }) => setItemInEdit({ ...itemInEdit, cancelComment: value })}
                                    value={itemInEdit?.cancelComment ?? ""}
                                    rows={3}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="default"
                    onClick={() => onSubmit(itemInEdit)}
                    type="button"
                    disabled={!itemInEdit?.cancelledStatus}
                >
                    Submit
                </Button>
            </div>
        </Modal>
    );
}

JobOrderCancellationDialog.propTypes = {
    jobOrderId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}