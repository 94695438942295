import { AgGridReact } from "ag-grid-react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Container, Input, Label, Modal, Row, Spinner } from "reactstrap";
import Loader from "../../../loaders";
import DateColumnTooltip from "../tooltips/DateColumnTooltip";
import api from "../../../../services/api";
import "../../../../assets/css/ag-grid.css";
import "../../../../assets/css/ag-theme-alpine.css";
import TalentEditor from '../../Candidates/Editor';
import moment from "moment";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { TooltipButton } from "../../../inputs";
import { Checkbox } from "@progress/kendo-react-inputs";
import SweetAlert from "react-bootstrap-sweetalert";

const ALTRAD_POOL = "Altrad Pool";
const PROJECT_POOL = "Project Pool";
const NONPROJECT_POOL = "Non-project Pool";

export default function CandidateSearchDialog(props) {
    const {
        dispatchUnitId,
        rosterCandidateIds,
        lookups,
        onClose,
        onSave,
    } = props;

    const [loading, setLoading] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [positions, setPositions] = useState([]);
    const [positionFilter, setPositionFilter] = useState(null);
    const [validPositions, setValidPositions] = useState([]);
    const [selectedPositions, setSelectedPositions] = useState([]);
    const [loadingEmptySlot, setLoadingEmptySlot] = useState(false);
    const [emptySlotParameters, setEmptySlotParameters] = useState(null);
    const [emptySlotRowData, setEmptySlotRowData] = useState([]);
    const [emptySlotColumnDefs, setEmptySlotColumnDefs] = useState([]);
    const [emptySlotDateColumnDefs, setEmptySlotDateColumnDefs] = useState([]);
    const emptySlotGridRef = useRef();
    const [candidateInEdit, setCandidateInEdit] = useState(null);
    const [loadingTalentSchedule, setLoadingTalentSchedule] = useState(false);
    const [selectedPoolType, setSelectedPoolType] = useState(ALTRAD_POOL);
    const [checkExclusionList, setCheckExclusionList] = useState(false);
    const [checkInclusionList, setCheckInclusionList] = useState(false);
    const [checkScheduleConflict, setCheckScheduleConflict] = useState(true);
    const [ignoreMobe, setIgnoreMobe] = useState(false);
    const [talentScheduleRowData, setTalentScheduleRowData] = useState([]);
    const [talentScheduleColumnDefs, setTalentScheduleColumnDefs] = useState([]);
    const [talentScheduleDateColumnDefs, setTalentScheduleDateColumnDefs] = useState([]);
    const [talentScheduleParameters, setTalentScheduleParameters] = useState(null);
    const [talentAssignmentConfirmation, setTalentAssignmentConfirmation] = useState(null);
    const [emptySlotAlert, setEmptySlotAlert] = useState(null);
    const talentScheduleGridRef = useRef();

    const loadingOverlayComponent = useMemo(() => Loader, []);

    const defaultColDef = useMemo(() => ({
        sortable: false,
        cellClass: 'roster-cell',
        tooltipComponent: DateColumnTooltip,
        autoHeight: false,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200
        },
        menuTabs: ['filterMenuTab'],
        suppressPaste: true,
        suppressFillHandle: true,
        suppressMovable: true,
        suppressFillHandle: true,
    }), [DateColumnTooltip]);

    const emptySlotStaticColumns = useMemo(() => [
        {
            headerName: "",
            children: [
                {
                    field: "select",
                    headerName: "",
                    maxWidth: 50,
                    editable: false,
                    pinned: true,
                    sortable: false,
                    filter: false,
                    cellRenderer: (cellParams) => {
                        const {data} = cellParams;
                        return (
                            <div className="d-flex flex-wrap justify-content-center align-items-center w-100 h-100">
                                <div className={`select-mark${data?.assign ? " selected" : ""}`} />
                            </div>
                        );
                    },
                    suppressMenu: true,
                },
                {
                    field: "action",
                    headerName: "Action",
                    maxWidth: 80,
                    editable: false,
                    pinned: true,
                    sortable: false,
                    filter: false,
                    cellRenderer: (cellParams) => {
                        const {data} = cellParams;

                        if (!data.candidateId)
                            return null;

                        return (
                            <div className="d-flex flex-wrap justify-content-center align-items-center w-100 h-100">
                                <TooltipButton
                                    id={`remove_${data.rosterCandidateId}`}
                                    title={`Remove ${data.candidateName}`}
                                    disabled={!data.candidateId || loadingTalentSchedule}
                                    className="btn-icon ml-1 p-0"
                                    color="warning"
                                    size="xss"
                                    type="button"
                                    onClick={() => {
                                        // console.log({data});
                                        // console.log({data, selectedSlot, emptySlotRowData});
                                        const newSlotData = JSON.parse(JSON.stringify(emptySlotRowData));
                                        const updatedSlot = newSlotData.filter(d => d.rosterCandidateId === data.rosterCandidateId)[0];

                                        if (!updatedSlot)
                                            return;

                                        updatedSlot.candidateId = null;
                                        updatedSlot.candidateName = null;
                                        updatedSlot.info = null;
                                        updatedSlot.assign = true;
                                        updatedSlot.positionId = null;
                                        updatedSlot.positionName = null;
                                        updatedSlot.overlapStatus = true;

                                        const slotKeys = Object.keys(updatedSlot.dates);
                                        const scheduleKeys = Object.keys(data.dates);

                                        // console.log({slotKeys, scheduleKeys});
                                        let isOverlapped = false;

                                        for(let i = 0; i < scheduleKeys.length && !isOverlapped; i++)
                                            isOverlapped = slotKeys.includes(scheduleKeys[i]);

                                        updatedSlot.overlapStatus = isOverlapped;

                                        newSlotData.splice(newSlotData.indexOf(updatedSlot), 1, {...updatedSlot});
                                        // console.log({newSlotData});
                                        setEmptySlotRowData([...newSlotData]);
                                    }}
                                >
                                    <i className="fas fa-xs fa-trash" />
                                </TooltipButton>
                            </div>
                        );
                    },
                    suppressMenu: true,
                },
                {
                    field: "slot",
                    headerName: "Slot",
                    width: 55,
                    pinned: true,
                    sort: "asc",
                    sortable: true,
                    filter: false,
                    suppressMenu: true,
                    editable: false,
                },
                {
                    field: "candidateName",
                    headerName: "Employee Name",
                    width: 150,
                    editable: false,
                    pinned: true,
                    sortable: true,
                },
                {
                    field: "info",
                    headerName: "Info",
                    width: 40,
                    onCellClicked: (ev) => {
                        if (ev.data.candidateId) {
                            //window.alert(`Open candidate profile dialog for candidate ${ev.data.candidateId}`);
                            setCandidateInEdit({ candidateId: ev.data.candidateId });
                        }
                    },
                    valueFormatter: () => '',
                    cellClass: (params) => `roster-cell ${params.value && 'cursor-pointer link fas fa-info-circle text-center'}`,
                    sortable: true,
                    filter: false,
                    suppressMenu: true,
                    pinned: true,
                },
                {
                    field: "hrispositionMasterName",
                    headerName: "Roster Position",
                    width: 200,
                    editable: false,
                    sortable: true,
                    pinned: true,
                },
                {
                    field: "positionName",
                    headerName: "Employed Position",
                    width: 200,
                    editable: false,
                    sortable: true,
                },
                {
                    field: "shiftName",
                    headerName: "Shift",
                    width: 60,
                    editable: false,
                    sortable: true,
                },
                {
                    field: "overlapStatus",
                    headerName: "Overlap Status",
                    width: 80,
                    editable: false,
                    sortable: false,
                    filter: false,
                    valueFormatter: () => '',
                    cellClass: (params) => {
                        if (params.data.candidateId)
                            return `roster-cell text-center fas ${!params.value ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'}`;
                        return 'roster-cell';
                    },
                    suppressMenu: true,
                },
            ]
        },
    ], [loadingTalentSchedule, emptySlotRowData]);

    const assignCandidate = useCallback((data) => {
        const newSlotData = JSON.parse(JSON.stringify(emptySlotRowData));
        const updatedSlot = newSlotData.filter(d => d.rosterCandidateId === selectedSlot?.rosterCandidateId)[0];

        if (!updatedSlot)
            return;

        const position = data.positions.filter(p => p.positionName === updatedSlot.hrispositionMasterName)[0];
        const defaultPosition = data.positions[0];
        updatedSlot.candidateId = data.candidateId;
        updatedSlot.candidateName = data.candidateName;
        updatedSlot.info = data.candidateId;
        updatedSlot.assign = true;
        updatedSlot.positionId = position?.positionId ?? defaultPosition?.positionId;
        updatedSlot.positionName = position?.positionName ?? defaultPosition?.positionName;

        const slotKeys = Object.keys(updatedSlot.dates);
        const scheduleKeys = Object.keys(data.dates);

        // console.log({slotKeys, scheduleKeys});
        let isOverlapped = false;

        for(let i = 0; i < scheduleKeys.length && !isOverlapped; i++)
            isOverlapped = slotKeys.includes(scheduleKeys[i]);

        updatedSlot.overlapStatus = isOverlapped;

        newSlotData.splice(newSlotData.indexOf(updatedSlot), 1, {...updatedSlot});
        // console.log({newSlotData});
        setEmptySlotRowData([...newSlotData]);

    }, [emptySlotRowData, selectedSlot]);

    const setScheduleAssignColumn = useCallback((cellParams) => {
        const {data} = cellParams;
        // console.log({cellParams, data});

        return (
            <div className="d-flex flex-wrap justify-content-center align-items-center w-100 h-100">
                <TooltipButton
                    id={`assign_${data.candidateId}`}
                    title={`Assign ${data.candidateName}`}
                    disabled={!selectedSlot}
                    className="btn-icon ml-1 p-0"
                    color="default"
                    size="xss"
                    type="button"
                    onClick={() => {
                        // console.log({data, selectedSlot, emptySlotRowData});
                        const newSlotData = JSON.parse(JSON.stringify(emptySlotRowData));
                        const updatedSlot = newSlotData.filter(d => d.rosterCandidateId === selectedSlot?.rosterCandidateId)[0];

                        if (!updatedSlot)
                            return;

                        const position = data.positions.filter(p => p.positionName === updatedSlot.hrispositionMasterName)[0];

                        if (!position)
                        {
                            setTalentAssignmentConfirmation({
                                message: `${data.candidateName} has never been assigned to position ${updatedSlot.hrispositionMasterName}. Continue?`,
                                data
                            });
                            return;
                        }

                        assignCandidate(data);
                    }}
                >
                    <i className="fas fa-xs fa-arrow-up" />
                </TooltipButton>
            </div>
        );
    }, [emptySlotRowData, selectedSlot, assignCandidate]);

    const talentScheduleStaticColumns = useMemo(() => [
        {
            headerName: "",
            children: [
                {
                    field: "assign",
                    headerName: "Action",
                    maxWidth: 80,
                    editable: false,
                    pinned: true,
                    sortable: false,
                    filter: false,
                    cellRenderer: (cellParams) => setScheduleAssignColumn(cellParams),
                    suppressMenu: true,
                },
                {
                    field: "no",
                    headerName: "No.",
                    maxWidth: 50,
                    editable: false,
                    pinned: true,
                    sortable: false,
                    filter: false,
                    valueGetter: "node.rowIndex + 1",
                    suppressMenu: true,
                },
                {
                    field: "candidateName",
                    headerName: "Employee Name",
                    width: 150,
                    editable: false,
                    pinned: true,
                    sortable: true,
                },
                {
                    field: "info",
                    headerName: "Info",
                    width: 40,
                    onCellClicked: (ev) => {
                        if (ev.data.candidateId) {
                            setCandidateInEdit({ candidateId: ev.data.candidateId });
                        }
                    },
                    valueFormatter: () => '',
                    cellClass: (params) => `roster-cell ${params.value && 'cursor-pointer link fas fa-info-circle text-center'}`,
                    sortable: true,
                    filter: false,
                    suppressMenu: true,
                    pinned: true,
                },
                {
                    field: "positionName",
                    headerName: "Employed Position",
                    width: 200,
                    editable: false,
                    sortable: true,
                    pinned: true,
                },
                {
                    field: "projectName",
                    headerName: "Previous Project",
                    width: 250,
                    editable: false,
                    sortable: true,
                },
                {
                    field: "dispatchUnitName",
                    headerName: "Previous Dispatch Unit",
                    width: 250,
                    editable: false,
                    sortable: true,
                },
                {
                    field: "totalProjectWorkingDays",
                    headerName: "Total Working Days (Project)",
                    maxWidth: 150,
                    editable: false,
                    sortable: true,
                    filter: "agNumberColumnFilter",
                },
                {
                    field: "exclusionList",
                    headerName: "Exclusion List",
                    width: 80,
                    editable: false,
                    sortable: false,
                    filter: false,
                    valueFormatter: () => '',
                    cellClass: (params) => {
                        if (params.data.candidateId)
                            return `roster-cell text-center fas ${params.value ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'}`;
                        return 'roster-cell';
                    },
                    suppressMenu: true,
                },
                {
                    field: "inclusionList",
                    headerName: "Inclusion List",
                    width: 80,
                    editable: false,
                    sortable: false,
                    filter: false,
                    valueFormatter: () => '',
                    cellClass: (params) => {
                        if (params.data.candidateId)
                            return `roster-cell text-center fas ${params.value ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'}`;
                        return 'roster-cell';
                    },
                    suppressMenu: true,
                },
                {
                    field: "score",
                    headerName: "Score",
                    width: 60,
                    editable: false,
                    sortable: true,
                    filter: "agNumberColumnFilter",
                },
                {
                    field: "performanceRating",
                    headerName: "Performance Rating",
                    width: 110,
                    editable: false,
                    sortable: true,
                    filter: "agNumberColumnFilter",
                },
            ]
        },
    ], [setScheduleAssignColumn]);

    const loadPositionData = useCallback(() => {
        api.get(`hrispositionmaster/search-maintenance${positionFilter ? `?filter=${positionFilter}` : ""}`)
            .then((response) => setPositions([...response.data]))
            .catch((error) => console.log({error}))
        ;
    }, [positionFilter]);

    useEffect(() => {
        loadPositionData();
    }, [positionFilter]);

    const loadData = useCallback(() => {
        setLoadingEmptySlot(true);
        // console.log({dispatchUnitId, rosterCandidateIds, emptySlotGridRef});

        api.post(`rostercandidatesearch/emptyslots/${dispatchUnitId}`, JSON.stringify(rosterCandidateIds ?? []))
            .then((response) => {
                const {data: {emptySlots, startDate, endDate, positionSearched}} = response;
                // console.log({emptySlots, startDate, endDate, positionSearched});
                setValidPositions([...positionSearched]);
                setSelectedPositions([...positionSearched]);
                setEmptySlotRowData([...emptySlots.map(t => ({...t, assign: false}))]);
                setEmptySlotParameters({startDate, endDate});
            })
            .catch((error) => {
                console.log({error});
                setEmptySlotAlert({
                    message: "Roster position and schedules should be filled.",
                })
            })
            .finally(() => setLoadingEmptySlot(false))
        ;
    }, [dispatchUnitId, rosterCandidateIds, emptySlotStaticColumns]);

    useEffect(() => {
        if (!dispatchUnitId)
            return;
        
        loadData();
    }, [dispatchUnitId, rosterCandidateIds]);

    useEffect(() => {
        
    }, [validPositions]);

    useEffect(() => {
        if (!emptySlotParameters)
            return;
        
        const {startDate, endDate} = emptySlotParameters;
        const emptySlotDateColumns = [];
        let current = moment(startDate);

        while (current <= moment(endDate)) {
            emptySlotDateColumns.push(moment(current));
            current.add(1, 'd');
        }

        const dateColDefs = emptySlotDateColumns.map((m) => ({
            headerName: m.format('ddd'),
            marryChildren: true,
            children: [
                {
                    field: `dates.${m.format('YYYYMMDD')}`,
                    tooltipField: `dates.${m.format('YYYYMMDD')}`,
                    headerName: m.format('DD/MM/YYYY'),
                    autoHeight: false,
                    resizable: false,
                    width: 30,
                    editable: false,
                    headerClass: 'date-header',
                    suppressMovable: true,
                    lockPosition: 'right',
                    filter: false,
                    suppressMenu: true,
                    valueFormatter: () => {
                        return '';
                    },
                    valueSetter: (params) => {
                        params.data.name = params.newValue;
                        return true;
                    },
                    cellClass: (params) => {
                        return params.value && params.value.value ? `date-cell roster-icon ${params.value?.className} amount amount${params.value?.value.length}` : 'date-cell';
                    },
                }
            ]
        }));

        setEmptySlotDateColumnDefs([...dateColDefs]);

        setEmptySlotColumnDefs([
            ...emptySlotStaticColumns,
            ...dateColDefs
        ]);
    }, [emptySlotParameters]);

    useEffect(() => {
        setEmptySlotColumnDefs([
            ...emptySlotStaticColumns,
            ...emptySlotDateColumnDefs
        ]);
    }, [loadingTalentSchedule]);

    const loadTalentData = useCallback(() => {
        if (!selectedPositions.length)
        {
            setTalentScheduleRowData([]);
            return;
        }

        setLoadingTalentSchedule(true);
        // console.log({dispatchUnitId, rosterCandidateIds, emptySlotGridRef, selectedPoolType, checkExclusionList, checkInclusionList, checkScheduleConflict, ignoreMobe});

        api.post(`rostercandidatesearch/talents/${dispatchUnitId}?isAltradPool=${selectedPoolType === ALTRAD_POOL}&isProjectPool=${selectedPoolType === PROJECT_POOL}&checkExclusionList=${checkExclusionList}&checkInclusionList=${checkInclusionList}&checkScheduleConflict=${checkScheduleConflict}&ignoreMobe=${ignoreMobe}`, JSON.stringify({rosterCandidateIds, hrispositionMasterIds: selectedPositions.map(p => p.hrispositionMasterId)}))
            .then((response) => {
                const {data: {talentSchedules, startDate, endDate}} = response;
                // console.log({talentSchedules, startDate, endDate});

                setTalentScheduleRowData([...talentSchedules]);
                setTalentScheduleParameters({startDate, endDate});
            })
            .catch((error) => console.log({error}))
            .finally(() => setLoadingTalentSchedule(false))
        ;
    }, [dispatchUnitId, rosterCandidateIds, emptySlotStaticColumns, selectedPositions, talentScheduleStaticColumns, selectedPoolType, checkExclusionList, checkInclusionList, checkScheduleConflict, ignoreMobe]);

    useEffect(() => {
        if (!dispatchUnitId)
            return;
        
        loadTalentData();
    }, [selectedPoolType, checkExclusionList, checkInclusionList, checkScheduleConflict, ignoreMobe, selectedPositions]);

    useEffect(() => {
        if (!talentScheduleParameters)
            return;

        const {startDate, endDate} = talentScheduleParameters;
        
        let current = moment(startDate);
        const talentScheduleDateColumns = [];
        current = moment(startDate);
        const end = moment(endDate);

        while (current <= end) {
            talentScheduleDateColumns.push(moment(current));
            current.add(1, 'd');
        }

        const dateColDefs = talentScheduleDateColumns.map((m) => ({
            headerName: m.format('ddd'),
            marryChildren: true,
            children: [
                {
                    field: `dates.${m.format('YYYYMMDD')}`,
                    tooltipField: `dates.${m.format('YYYYMMDD')}`,
                    headerName: m.format('DD/MM/YYYY'),
                    autoHeight: false,
                    resizable: false,
                    width: 30,
                    editable: false,
                    headerClass: 'date-header',
                    suppressMovable: true,
                    lockPosition: 'right',
                    filter: false,
                    suppressMenu: true,
                    valueFormatter: () => {
                        return '';
                    },
                    valueSetter: (params) => {
                        params.data.name = params.newValue;
                        return true;
                    },
                    cellClass: (params) => {
                        return params.value && params.value.value ? `date-cell roster-icon ${params.value?.className} amount amount${params.value?.value.length}` : 'date-cell';
                    },
                }
            ]
        }));

        setTalentScheduleDateColumnDefs([...dateColDefs]);

        setTalentScheduleColumnDefs([
            ...talentScheduleStaticColumns,
            ...dateColDefs,
        ]);
    }, [talentScheduleParameters]);

    useEffect(() => {
        if (!selectedSlot)
            return;

        setTalentScheduleColumnDefs([
            ...talentScheduleStaticColumns,
            ...talentScheduleDateColumnDefs,
        ]);
    }, [selectedSlot]);

    const getEmptySlotRowId = useMemo(() => (params) => params?.data?.rosterCandidateId, []);

    const getTalentScheduleRowId = useMemo(() => (params) => params?.data?.candidateId, []);

    const handleSave = useCallback(() => {
        if (!emptySlotRowData.filter(d => d.candidateId).length)
        {
            onSave();
            return;
        }

        setLoading(true);
        const dataToSend = [...emptySlotRowData.filter(d => d.candidateId).map(d => ({rosterCandidateId: d.rosterCandidateId, candidateId: d.candidateId, positionId: d.positionId}))];

        api.post(`rostercandidatesearch/submit`, JSON.stringify(dataToSend))
            .then(() => {
                setLoading(false);
                onSave();
            })
            .catch((error) => {
                console.log({error});
                setLoading(false);
            })
        ;
    }, [emptySlotRowData, onSave]);

    return (
        <Modal
            isOpen={true}
            className="modal-fullscreen modal-fullscreen-xl"
        >
            {loading && <Loader />}
            <div
                className="modal-header"
            >
                <Container fluid className="p-0 m-0">
                    <Row className="p-0 m-0">
                        <Col lg={6} className="p-0 m-0 d-flex flex-column justify-content-start align-items-start">
                            <h5
                                className="modal-title"
                            >
                                Talents Search
                            </h5>
                        {
                            emptySlotAlert ?
                            <span className="text-danger font-750">* {emptySlotAlert.message}</span>
                            :
                            null
                        }
                        </Col>
                        <Col lg={6} className="p-0 m-0 d-flex flex-wrap justify-content-end align-items-center">
                            <button
                                aria-label="Close"
                                className="close"
                                onClick={onClose}
                                type="button"
                            >
                                <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                            </button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-body p-2"
                style={{maxHeight: "70vh", overflowY: "auto"}}
            >
                <Container fluid>
                    <Row>
                        <Col lg="12"><span className="font-weight-bold">Available Slots</span></Col>
                    </Row>
                    <div className="ag-theme-alpine w-100" style={{height: '25vh'}}>
                    {
                        loadingEmptySlot ?
                        <div className="w-100 h-100 d-flex flex-wrap justify-content-center align-items-center">
                            <Spinner color="default" type="border" />
                        </div>
                        :
                        <AgGridReact
                            ref={emptySlotGridRef}
                            rowData={emptySlotRowData}
                            columnDefs={emptySlotColumnDefs}
                            defaultColDef={defaultColDef}
                            suppressColumnVirtualisation={false}
                            suppressRowVirtualisation={false}
                            rowBuffer={20}
                            debounceVerticalScrollbar={false}
                            allowContextMenuWithControlKey={true}
                            suppressContextMenu={true}
                            suppressClipboardPaste={true}
                            headerHeight={70}
                            groupHeaderHeight={22}
                            rowHeight={22}
                            readOnlyEdit={true}
                            getRowId={getEmptySlotRowId}
                            tooltipShowDelay={0}
                            tooltipHideDelay={20000}
                            suppressDragLeaveHidesColumns={true}
                            suppressColumnMoveAnimation={true}
                            multiSortKey='ctrl'
                            enableCellChangeFlash={false}
                            animateRows={true}
                            rowSelection={"single"}
                            onRowSelected={(e) => {
                                const {data, api, event} = e;

                                if (!event)
                                    return;

                                const displayRows = [];

                                for (let i = 0; i < api.getDisplayedRowCount(); i++)
                                {
                                    const rowNode = api.getDisplayedRowAtIndex(i);
                                    const currentData = { ...rowNode.data };

                                    if (currentData.rosterCandidateId !== data.rosterCandidateId && !currentData.assign)
                                        continue;

                                    displayRows.push({...currentData, assign: data.rosterCandidateId === currentData.rosterCandidateId});
                                }

                                const tx = {
                                    update: [...displayRows],
                                };
                    
                                api.applyTransactionAsync(tx, (result) => {
                                    api.refreshCells({force: true, rowNodes: result.update});
                                });
                                setSelectedSlot({...data});
                            }}
                        />
                    }
                    </div>
                    <Row className="mt-2">
                        <Col lg="12" className="mb-2">
                            <Label><span className="font-750">Target Roster Positions:</span></Label>
                            <MultiSelect
                                data={positions}
                                value={selectedPositions}
                                onChange={(e) => setSelectedPositions([...e.value])}
                                onFilterChange={(e) => setPositionFilter(e.filter.value)}
                                placeholder="Search..."
                                textField="hrispositionMasterName"
                                dataItemKey="hrispositionMasterId"
                                filterable={true}
                                disabled={loadingTalentSchedule}
                            />
                        </Col>
                        <Col lg="3" className="d-flex flex-wrap justify-content-center align-items-center">
                            <DropDownList
                                data={[ALTRAD_POOL, PROJECT_POOL, NONPROJECT_POOL]}
                                value={selectedPoolType}
                                onChange={(e) => setSelectedPoolType(e.target.value)}
                                style={{
                                    width: "100%",
                                }}
                                disabled={loadingTalentSchedule}
                            />
                        </Col>
                        <Col lg="2" className="d-flex flex-wrap justify-content-center align-items-center">
                            <Checkbox
                                label="Exclusion List"
                                onChange={(e) => setCheckExclusionList(e.value)}
                                value={checkExclusionList}
                                disabled={loadingTalentSchedule}
                            />
                        </Col>
                        <Col lg="2" className="d-flex flex-wrap justify-content-center align-items-center">
                            <Checkbox
                                label="Inclusion List"
                                onChange={(e) => setCheckInclusionList(e.value)}
                                value={checkInclusionList}
                                disabled={loadingTalentSchedule}
                            />
                        </Col>
                        <Col lg="2" className="d-flex flex-wrap justify-content-center align-items-center">
                            <Checkbox
                                label="No Schedule Conflict"
                                onChange={(e) => {
                                    setCheckScheduleConflict(e.value);

                                    if (!e.value && ignoreMobe)
                                        setIgnoreMobe(false);
                                }}
                                value={checkScheduleConflict}
                                disabled={loadingTalentSchedule}
                            />
                        </Col>
                        <Col lg="3" className="d-flex flex-wrap justify-content-center align-items-center">
                            <Checkbox
                                label="Ignore Mobe/Demobe Schedule"
                                onChange={(e) => setIgnoreMobe(e.value)}
                                value={ignoreMobe}
                                disabled={!checkScheduleConflict || loadingTalentSchedule}
                            />
                        </Col>
                    </Row>
                    <div className="ag-theme-alpine mt-2 w-100" style={{height: '25vh'}}>
                    {
                        loadingTalentSchedule ?
                        <div className="w-100 h-100 d-flex flex-wrap justify-content-center align-items-center">
                            <Spinner color="default" type="border" />
                        </div>
                        :
                        <AgGridReact
                            ref={talentScheduleGridRef}
                            rowData={talentScheduleRowData}
                            columnDefs={talentScheduleColumnDefs}
                            defaultColDef={defaultColDef}
                            suppressColumnVirtualisation={false}
                            suppressRowVirtualisation={false}
                            rowBuffer={20}
                            debounceVerticalScrollbar={false}
                            allowContextMenuWithControlKey={true}
                            suppressContextMenu={true}
                            suppressClipboardPaste={true}
                            suppressKeyboardEvent={true}
                            headerHeight={70}
                            groupHeaderHeight={22}
                            rowHeight={22}
                            readOnlyEdit={true}
                            getRowId={getTalentScheduleRowId}
                            enableRangeSelection={true}
                            loadingOverlayComponent={loadingOverlayComponent}
                            tooltipShowDelay={0}
                            tooltipHideDelay={20000}
                            suppressDragLeaveHidesColumns={true}
                            suppressColumnMoveAnimation={true}
                            multiSortKey='ctrl'
                            enableCellChangeFlash={true}
                            animateRows={true}
                            rowSelection={"single"}
                        />
                    }
                    </div>
                </Container>
            </div>
            <div
                className="modal-footer d-flex flex-wrap"
            >
                <Button
                    color="secondary"
                    onClick={onClose}
                    type="button"
                    className="mt-2"
                >
                    Close
                </Button>
                <Button
                    color="default"
                    onClick={handleSave}
                    type="button"
                    className="mt-2"
                    disabled={!emptySlotRowData.length}
                >
                    Save
                </Button>
            </div>

            {
                candidateInEdit &&
                <TalentEditor
                    item={candidateInEdit}
                    onClose={() => {
                        setCandidateInEdit(null);
                    }}
                    onSaved={() => {
                        setCandidateInEdit(null);
                    }}
                    lookups={{
                        ...lookups
                    }}
                />
            }

            {
                talentAssignmentConfirmation ?
                <SweetAlert
                    title="Assignment Confirmation"
                    warning
                    confirmBtnText="Yes"
                    onConfirm={() => {
                        setTalentAssignmentConfirmation(null);
                        assignCandidate(talentAssignmentConfirmation.data);
                    }}
                    showCancel
                    onCancel={() => setTalentAssignmentConfirmation(null)}
                >
                {
                    talentAssignmentConfirmation.message
                }
                </SweetAlert>
                :
                null
            }
        </Modal>
    );
};
