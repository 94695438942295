import React, { useEffect, useState } from 'react';
import api from "../../../services/api";
import { Input, Label, FormGroup, Button, Modal, Container, Row, Col } from "reactstrap";
import { DropdownBox } from "../../dropdowns";
import Loader from "../../loaders";
import { v4 } from "uuid";
import { DatePicker } from '../../date-pickers';
import { formatDate, formatDateZeroTime } from "../../../utils";

export default function EditorComment(props) {

    const {
        onCustomSave,
    } = props;

    const [itemInEdit, setItemInEdit] = React.useState([]);
    const [message, setMessage] = React.useState(String.Empty);
    const [messageDetails, setMessageDetails] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [failedMessages, setFailedMessages] = useState(null);

    React.useEffect(() => {
        // console.log('items: ', props.items);
        if (props.candidateId > 0) {
            setLoading(true);

            api.get(`candidate/${props.candidateId}`)
                .then((response) => {

                    const {
                        candidateNotes,
                        ...rest
                    } = response.data;

                    setItemInEdit([{
                        ...rest,
                        candidateNotes: candidateNotes && candidateNotes.length > 0
                            ? candidateNotes.map((item) => ({ ...item, uid: v4() }))
                            : []
                    }])

                })
                .catch((error) => console.error(error))
                .finally(() => setLoading(false));
        } else {
            setItemInEdit(props.items);
        }
    }, [props.candidateId]);

    const handleSave = (item) => {
        if (onCustomSave) {
            onCustomSave(message, setLoading);
            return;
        }

        setLoading(true);

        var messageLog = {
            refIds: item.map(m => m.candidateId),
            message: message,
            messageType: messageDetails?.commentType?.lookupValue,
            sentDate: messageDetails?.sentDate
        };

        const data = JSON.stringify(messageLog);
        // console.log(data);
        const submit = api.post(`addComment/new-message`, data);

        submit.then((response) => {
            if (response.data?.failed?.length > 0) {
                setFailedMessages(response.data.failed);
                setLoading(false);
                return;
            }
            props.onSaved();
        })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }
    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Comment Editor
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}>x</span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                <Container>
                    {(loading) && <Loader />}
                    <Row>
                        {
                            failedMessages?.length > 0 &&
                            <Col className="font-size-message-alert-custom text-danger" xs={12}>
                                <span>
                                    <i>
                                        Comment failed to add to:{" "}
                                        {
                                            failedMessages.map((item, idx) => {
                                                return `${item.candidateName}${idx < failedMessages.length - 1 ? ", " : "."}`;
                                            })
                                        }
                                    </i>
                                </span>
                            </Col>
                        }
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Selected Candidate(s)
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input placeholder={itemInEdit.map(m => m.firstName + ' ' + m.surname + ' (' + m.mobilePhoneNumber + ')')} readOnly />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <Row>
                                <Col>
                                <FormGroup>
                                    <Label>
                                        Date
                                    </Label>
                                    <DatePicker
                                        name="sendDate"
                                        id="sendDate"
                                        type="text"
                                        required
                                        value={formatDate(messageDetails?.sentDate)}
                                        onChange={(value) => !value ? null : setMessageDetails({ ...messageDetails, sentDate: formatDateZeroTime(value) })}
                                        closeOnSelect
                                    />
                                    </FormGroup>
                                </Col>
                                <Col>
                                <FormGroup>
                                    <Label>
                                        Comment Type
                                    </Label>
                                    <DropdownBox
                                        data={props.lookups.candidateCommentTypes}
                                        selectedItem={messageDetails?.commentType}
                                        onChange={(item) => {
                                            setMessageDetails({
                                                ...messageDetails,
                                                commentType: item,
                                                commentTypeId: item ? item.lookupId : null
                                            })
                                        }}
                                    />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Comment Text
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="textarea"
                                    value={message}
                                    onChange={({ target: { value } }) => {
                                        setMessage(value);
                                    }}
                                    rows={6}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={props.onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave(itemInEdit)}
                    type="button"
                >
                    <i className="fas fa-paper-plane mr-2"></i>Add Comment
                </Button>
            </div>
        </Modal>
        );
}