import React, { createContext, useReducer, useContext } from "react";
import forecastReducer, { initialState, actions } from "../reducers/forecastReducer";

const ForecastContext = createContext(initialState);

export const ForecastProvider = ({ children }) => {
    const [state, dispatch] = useReducer(forecastReducer, initialState);

    const setStartDate = (startDate) => {
        dispatch({
            type: actions.SET_START_DATE,
            payload: {
                startDate: startDate
            }
        });
    }

    const setEndDate = (endDate) => {
        dispatch({
            type: actions.SET_END_DATE,
            payload: {
                endDate: endDate
            }
        });
    }

    const setClient = (client) => {
        dispatch({
            type: actions.SET_CLIENT,
            payload: {
                client: client
            }
        });
    }

    const setDepartment = (department) => {
        dispatch({
            type: actions.SET_DEPARTMENT,
            payload: {
                department: department
            }
        });
    }

    const setManager = (manager) => {
        dispatch({
            type: actions.SET_MANAGER,
            payload: {
                manager: manager
            }
        });
    }

    const setProject = (project) => {
        dispatch({
            type: actions.SET_PROJECT,
            payload: {
                project: project
            }
        });
    }

    const setScope = (scope) => {
        dispatch({
            type: actions.SET_SCOPE,
            payload: {
                scope: scope
            }
        });
    }

    const setState = (state) => {
        dispatch({
            type: actions.SET_STATE,
            payload: {
                state: state
            }
        });
    }
    
    const setNotification = (notification) => {
        dispatch({
            type: actions.SET_NOTIFICATION,
            payload: {
                notification: notification
            }
        });
    }

    const value = {
        setStartDate,
        setEndDate,
        setClient,
        setDepartment,
        setManager,
        setProject,
        setScope,
        setState,
        setNotification,
        startDate: state.startDate,
        endDate: state.endDate,
        client: state.client,
        department: state.department,
        manager: state.manager,
        project: state.project,
        scope: state.scope,
        state: state.state,
        notification: state.notification,
    };
    return <ForecastContext.Provider value={value}>{children}</ForecastContext.Provider>;
}

const useForecast = () => {
    const context = useContext(ForecastContext);

    if (context === undefined) {
        throw new Error("useForecast must be used within ForecastProvider");
    }

    return context;
};

export default useForecast;