import React from 'react';
import {
    Label, FormGroup, Button, Modal, Container, UncontrolledTooltip
 } from "reactstrap"; 
import Loader from "../../../loaders";
import api from "../../../../services/api";
import CopyToClipboard  from "react-copy-to-clipboard";
const timeoutLength = 1500;
const defaultText = "Copy example to clipboard";
const copiedText = "Copied!"
const TooltipContent = ({ scheduleUpdate, index, copiedIndex }) => {
    const [text, setText] = React.useState(defaultText);

    React.useEffect(() => {
        if (copiedIndex === index) {
            setText(copiedText);
            scheduleUpdate();
            const timeoutId = setTimeout(() => {
                setText(defaultText);
                scheduleUpdate();
            }, timeoutLength);
            return () => clearTimeout(timeoutId);
        }
    }, [copiedIndex, index, scheduleUpdate]);

    return (
      <>{text}</>
    );
  }
export default function Editor(props) {
    const {
        projectId
    } = props;

    const [ loading, setLoading ] = React.useState(false);
    const [ templateModel, setTemplateModel ] = React.useState(null);
    const [ copiedIndex, setCopiedIndex ] = React.useState(null);

    React.useEffect(() => {
        if (copiedIndex !== null) {
            const timeoutId = setTimeout(() => {
                setCopiedIndex(null);
            }, timeoutLength);
          
            return () => clearTimeout(timeoutId);
        }
    }, [copiedIndex]);

    React.useEffect(() => {
        if (projectId)
        {
            setLoading(true);
            api.get(`/contracttemplate/project/model/${projectId}`)
                .then(({data}) => {
                    setTemplateModel(data);
                    setLoading(false);

                    if (!data.length)
                    {
                        setLoading(true);
                        api.get(`/contracttemplate/model`)
                        .then(({data}) => {
                            setTemplateModel(data);
                        }).catch((error) => {
                            console.error("error: ", error);
                        }).finally(() => setLoading(false));
                    }
                }).catch((error) => {
                    console.error("error: ", error);
                    setLoading(false);
                })
            ;
            return;
        }

        setLoading(true);
        api.get(`/contracttemplate/model`)
        .then(({data}) => {
            setTemplateModel(data);
        }).catch((error) => {
            console.error("error: ", error);
        }).finally(() => setLoading(false));
    }, [projectId])

    return (
        <Modal
            isOpen={props.open}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Template Help
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}>x</span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                <Container className="var-container">
                    {(loading) && <Loader />}
                    <h6 className="font-weight-bold">Element Names to be used in Contract Templates</h6>
                    {templateModel && templateModel.length > 0 && templateModel.map((item, index) => 
                    {
                        return (
                            <span key={index}>
                                <UncontrolledTooltip delay={0} placement="top" target={`link-${index}`}>
                                    {({ scheduleUpdate }) => (
                                        <TooltipContent {...{scheduleUpdate, index, copiedIndex}} />
                                    )}
                                </UncontrolledTooltip>
                                <CopyToClipboard
                                    text={item.example}
                                    onCopy={(value) => {
                                        setCopiedIndex(index);
                                    }}
                                >
                                    <p key={index} id={`link-${index}`}>{item.name}</p>
                                </CopyToClipboard>
                            </span>
                        );
                    })}
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={() => {
                        props.onClose();
                    }}
                    type="button"
                >
                    Close
                </Button>
            </div>
        </Modal>
    )
}