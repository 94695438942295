import React, { useCallback, useState } from 'react';
import api from "../../../../services/api";
import {
    Label, FormGroup, Button, Modal, Container, Row, Col, Input
 } from "reactstrap"; 
import Loader from "../../../loaders";
import { TrimObject } from '../../../../helpers/StringHelper';

export default function QuestionEditor(props) {
    const {onClose, onSaved} = props;
    const [ itemInEdit, setItemInEdit ] = useState({feedbackQuestion: null});
    const [ loading, setLoading ] = useState(false);

    const handleSave = useCallback(() => {
        setLoading(true);

        // Trim every string property
        var item = TrimObject(itemInEdit);
        setItemInEdit(item);

        // Save
        const data = JSON.stringify(item);
        api.post(`feedback/question`, data)
            .then(() => {
                setLoading(false);
                onSaved();
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, [itemInEdit, onSaved]);

    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Add New Question
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                <Container>
                    {(loading) && <Loader />}
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Question
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    name="feedbackQuestion"
                                    id="feedbackQuestion"
                                    type="textarea"
                                    onChange={({ target: { value } }) => setItemInEdit({feedbackQuestion: value})}
                                    value={itemInEdit.feedbackQuestion ?? ""}
                                    rows={3}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={props.onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave(itemInEdit)}
                    type="button"

                >
                    Save Changes
                </Button>
            </div>
        </Modal>
    )
}