import React, { useEffect, useState } from 'react';

export default (props) => {

    return (
        <div className='d-flex align-items-center h-100'>
            <p className='m-0'>
                <small id="agg-info"></small>
            </p>
        </div>
    );
};