import React from 'react';
import {
    Container, CardTitle, Row, Col, Button, Card, CardBody, Modal, FormGroup, Label, Input
 } from "reactstrap";
 import api from "../../../../services/api";
 import qs from "qs";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../../alerts";
import { TooltipButton } from '../../../inputs';
import { DefaultColumnFilter } from "../../../react-table/filters";
import { useTable, useExpanded, useFilters, useSortBy, useFlexLayout } from 'react-table';
import Loader from "../../../loaders";
import Grid from "../../../Grid";
import { TrimObject } from '../../../../helpers/StringHelper';

function TrainingCategory(props) {

    const [ categoryData, setCategoryData ] = React.useState([]);    
    const [ categoryLoading, setCategoryLoading ] = React.useState(false);
    const [ categoryInEdit, setCategoryInEdit ] = React.useState(null);
    const [ categoryPageNumber, setCategoryPageNumber ] = React.useState(1);
    const [ categoryPageSize, setCategoryPageSize ] = React.useState(10);
    const [ categoryTotalRecords, setCategoryTotalRecords ] = React.useState(0);
    const [ categoryFilters, setCategoryFilters ] = React.useState([]);
    const defaultCategorySort = React.useMemo(() => [{ id: "name", desc: false }],
        []
    );
    const [ categorySortBy, setCategorySortBy ] = React.useState(defaultCategorySort);

    const [ subCategoryData, setSubCategoryData ] = React.useState([]);    
    const [ subCategoryLoading, setSubCategoryLoading ] = React.useState(false);
    const [ subCategoryInEdit, setSubCategoryInEdit ] = React.useState(null);
    const [ subCategoryPageNumber, setSubCategoryPageNumber ] = React.useState(1);
    const [ subCategoryPageSize, setSubCategoryPageSize ] = React.useState(10);
    const [ subCategoryTotalRecords, setSubCategoryTotalRecords ] = React.useState(0);
    const [ subCategoryFilters, setSubCategoryFilters ] = React.useState([]);
    const defaultSubCategorySort = React.useMemo(() => [{ id: "name", desc: false }],
        []
    );
    const [ subCategorySortBy, setSubCategorySortBy ] = React.useState(defaultSubCategorySort);
    
    const [ deleteAlert, setDeleteAlert ] = React.useState([]);
    const [ selectedCategory, setSelectedCategory ] = React.useState(null);
    const [ loading, setLoading ] = React.useState(false);
    const skipPageResetCategoryRef = React.useRef();
    const skipPageResetSubCategoryRef = React.useRef();
    
    const loadCategoryData = React.useCallback(() => {
        skipPageResetCategoryRef.current = true;
        const queryString = qs.stringify({filters: categoryFilters, sortBy: categorySortBy}, { allowDots: true });
        setCategoryInEdit(null);
        setCategoryData([]);
        setCategoryLoading(true);

        api.get(`/trainingcategory/${(categoryPageNumber - 1) * categoryPageSize}/${categoryPageSize}${queryString ? `?${queryString}` : ""}`)
        .then(({data}) => {
            setCategoryData(data.data);
            setCategoryTotalRecords(data.total);
        }).catch((error) => {
            console.error("error: ", error);
        }).finally(() => {
            setCategoryLoading(false);
        }
        );
    }, [categoryFilters, categorySortBy, categoryPageNumber, categoryPageSize]);

    const loadSubCategoryData = React.useCallback(() => {
        skipPageResetSubCategoryRef.current = true;
        const queryString = qs.stringify({filters: subCategoryFilters, sortBy: subCategorySortBy}, { allowDots: true });
        setSubCategoryInEdit(null);
        setSubCategoryData([]);

        if(!selectedCategory)
            return;

        setSubCategoryLoading(true);
        api.get(`/trainingsubcategory/${selectedCategory.trainingCategoryId}/${(subCategoryPageNumber - 1) * subCategoryPageSize}/${subCategoryPageSize}${queryString ? `?${queryString}` : ""}`)
        .then(({data}) => {
            setSubCategoryData(data.data);
            setSubCategoryTotalRecords(data.total);
        }).catch((error) => {
            console.error("error: ", error);
        }).finally(() => {
            setSubCategoryLoading(false);
        });
    }, [subCategoryFilters, subCategorySortBy, subCategoryPageNumber, subCategoryPageSize, selectedCategory]);

    React.useEffect(() => {
        setLoading(categoryLoading || subCategoryLoading);
    }, [categoryLoading, subCategoryLoading]);

    const handleCategoryDelete = (item) => {
        const success = () => {
            setDeleteAlert([<DeleteSuccessAlert onConfirm={() => setDeleteAlert([])} />]);
            if (categoryData.length === 1 && categoryPageNumber > 1) {
                setCategoryPageNumber(categoryPageNumber - 1);
            } else {
                loadCategoryData();
            }
        };
        const failure = () => setDeleteAlert([<DeleteFailedAlert onConfirm={() => setDeleteAlert([])} />]);
        const events = {
            onConfirm: () => api.delete(`/trainingcategory/${categoryInEdit.trainingCategoryId}`).then(success).catch(failure),
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert {...events} />])
    }

    const handleSubCategoryDelete = (item) => {
        const success = () => {
            setDeleteAlert([<DeleteSuccessAlert onConfirm={() => setDeleteAlert([])} />]);
            if (subCategoryData.length === 1 && subCategoryPageNumber > 1) {
                setSubCategoryPageNumber(subCategoryPageNumber - 1);
            } else {
                loadSubCategoryData();
            }
        };
        const failure = () => setDeleteAlert([<DeleteFailedAlert onConfirm={() => setDeleteAlert([])} />]);
        const events = {
            onConfirm: () => api.delete(`/trainingsubcategory/${subCategoryInEdit.trainingSubCategoryId}`).then(success).catch(failure),
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert {...events} />])
    }

    const handleCategorySave = (item, setItem = null) => {
        setCategoryLoading(true);

        // Trim every string property
        item = TrimObject(item);
        if(setItem) setItem({ ...item });

        // Save the item
        const data = JSON.stringify(item);
        const save = categoryInEdit.trainingCategoryId > 0 
            ? api.put(`trainingcategory/${categoryInEdit.trainingCategoryId}`, data)
            : api.post("trainingcategory", data)

        save
        .then((response) => {
            loadCategoryData();
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            setCategoryLoading(false);
        });
    }

    const handleSubCategorySave = (item, setItem = null) => {
        setSubCategoryLoading(true)


        // Trim every string property
        item = TrimObject(item);
        if(setItem) setItem({ ...item });

        // Save the item
        const data = JSON.stringify(item);
        const save = subCategoryInEdit.trainingSubCategoryId > 0 
            ? api.put(`trainingsubcategory/${subCategoryInEdit.trainingSubCategoryId}`, data)
            : api.post("trainingsubcategory", data)

        save
        .then((response) => {
            loadSubCategoryData();
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            setSubCategoryLoading(false);
        });
    }

    const handleTextChanged = ({ target: { name, value } }) => {
        setCategoryInEdit({ ...categoryInEdit, [name]: value });
    }
    const handleSubTextChanged = ({ target: { name, value } }) => {
        setSubCategoryInEdit({ ...subCategoryInEdit, [name]: value });
    }

    React.useEffect(() => {
        loadCategoryData();
    }, [categoryPageNumber, categoryPageSize, categoryFilters, categorySortBy]);
    
    React.useEffect(() => {
        loadSubCategoryData();
    }, [subCategoryPageNumber, subCategoryPageSize, selectedCategory, subCategoryFilters, subCategorySortBy]);

    const filterCategoryTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
                })
            },
        }),
        []
    )

    const filterSubCategoryTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
                })
            },
        }),
        []
    )

    const columnsCategory = React.useMemo(() => {
        return [
            {
                Header: "Category Name",
                id: "name",
                accessor: "trainingCategoryName",
                Filter: DefaultColumnFilter,
                filter: "text",
                width: 300,
                disableSortBy: false,
            },
            {
                Header: "Actions",
                id: 'button',
                Cell: ( { row: { original } } ) =>
                    (
                        <div>
                            <TooltipButton
                                id={`edit_${original.trainingCategoryId}`}
                                title="Edit"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={() => setCategoryInEdit(original)}
                            >
                                <i className="fas fa-pencil-alt pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`delete_${original.trainingCategoryId}`}
                                title="Delete"
                                className="btn-icon"
                                color="warning"
                                size="sm"
                                type="button"
                                onClick={() => handleCategoryDelete(original)}
                            >
                                <i className="fas fa-trash-alt pt-1"></i>
                            </TooltipButton>
                        </div>
                    ),
                width: 140,
            },
        ]
    }, []);

    const columnsSubCategory = React.useMemo(() => {
        return [
            {
                Header: "Sub Category Name",
                id: "name",
                accessor: "trainingSubCategoryName",
                Filter: DefaultColumnFilter,
                filter: "text",
                width: 300,
                disableSortBy: false,
            },
            {
                Header: "Actions",
                id: 'button',
                Cell: ( { row: { original } } ) =>
                    (
                        <div>
                            <TooltipButton
                                id={`editsub_${original.trainingSubCategoryId}`}
                                title="Edit"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={() => setSubCategoryInEdit(original)}
                            >
                                <i className="fas fa-pencil-alt pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`deletesub_${original.trainingSubCategoryId}`}
                                title="Delete"
                                className="btn-icon"
                                color="warning"
                                size="sm"
                                type="button"
                                onClick={() => handleSubCategoryDelete(original)}
                            >
                                <i className="fas fa-trash-alt pt-1"></i>
                            </TooltipButton>
                        </div>
                    ),
                width: 140,
            },
        ]
    }, []);

    const defaultCategoryColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
    }), []);

    const defaultSubCategoryColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
    }), []);

    const tableCategory = useTable(
        {
            columns: columnsCategory,
            data: categoryData,
            initialState: {
                sortBy: defaultCategorySort,
                canSort: true,
            },
            manualSortBy: true,
            filterTypes: filterCategoryTypes,
            defaultColumn: defaultCategoryColumn,
            autoResetPage: !skipPageResetCategoryRef.current,
            autoResetExpanded: !skipPageResetCategoryRef.current,
            autoResetGroupBy: !skipPageResetCategoryRef.current,
            autoResetSelectedRows: !skipPageResetCategoryRef.current,
            autoResetSortBy: !skipPageResetCategoryRef.current,
            autoResetFilters: !skipPageResetCategoryRef.current,
            autoResetRowState: !skipPageResetCategoryRef.current,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
        useExpanded
    );
    
    React.useEffect(() => {
        setCategoryFilters(tableCategory.state.filters);
    }, [tableCategory.state.filters]);
    
    React.useEffect(() => {
        setCategorySortBy(tableCategory.state.sortBy);
    }, [tableCategory.state.sortBy]);

    const tableSubCategory = useTable(
        {
            columns: columnsSubCategory,
            data: subCategoryData,
            initialState: {
                sortBy: defaultSubCategorySort,
                canSort: true,
            },
            manualSortBy: true,
            filterTypes: filterSubCategoryTypes,
            defaultColumn: defaultSubCategoryColumn,
            autoResetPage: !skipPageResetSubCategoryRef.current,
            autoResetExpanded: !skipPageResetSubCategoryRef.current,
            autoResetGroupBy: !skipPageResetSubCategoryRef.current,
            autoResetSelectedRows: !skipPageResetSubCategoryRef.current,
            autoResetSortBy: !skipPageResetSubCategoryRef.current,
            autoResetFilters: !skipPageResetSubCategoryRef.current,
            autoResetRowState: !skipPageResetSubCategoryRef.current,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
        useExpanded
    );
    
    React.useEffect(() => {
        setSubCategoryFilters(tableSubCategory.state.filters);
    }, [tableSubCategory.state.filters]);
    
    React.useEffect(() => {
        setSubCategorySortBy(tableSubCategory.state.sortBy);
    }, [tableSubCategory.state.sortBy]);
    
    return (
        <>
            {loading && <Loader />}
            {deleteAlert.length > 0 && deleteAlert}
            <Container fluid style={{paddingLeft: 0, paddingRight: 0}}>
                <Row>
                    <Col xs={12}>
                        <Card className="no-transition" style={{boxShadow: "0 0 0 0"}}>
                            <CardBody>
                                <Row>
                                    <Col sm={12} md={6} lg={6}>
                                        <CardTitle>
                                            <h4 className="text-center">Training Category</h4>
                                        </CardTitle>
                                        <TooltipButton
                                            id="addnew"
                                            title="Add New"
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={() => {  
                                                setCategoryInEdit({});
                                            }}
                                        >
                                            <i className="fas fa-plus pt-1"></i>
                                        </TooltipButton>
                                        <Grid
                                            height='calc(100vh - 550px)'
                                            totalRecords={categoryTotalRecords}
                                            pageSize={categoryPageSize}
                                            rows={tableCategory.rows}
                                            tableProps={tableCategory.getTableProps()}
                                            headerGroups={tableCategory.headerGroups}
                                            tableBodyProps={tableCategory.getTableBodyProps()}
                                            prepareRow={tableCategory.prepareRow}
                                            onRowClick={(row) => setSelectedCategory(row.original)}
                                            onPagerChangePage={(pager) => {
                                                setCategoryPageNumber(pager.currentPage);
                                            }}
                                            onPagerChangePageSize={(size) => {
                                                setCategoryPageSize(size);
                                            }}
                                            needPaging={true}
                                        />
                                    </Col>
                                    <Col sm={12} md={6} lg={6}>
                                        <CardTitle>
                                            <h4 className="text-center">{`${selectedCategory ? (selectedCategory.trainingCategoryName + " - ") : ""}Sub Category`}</h4>
                                        </CardTitle>
                                        <TooltipButton
                                            id="addnewsub"
                                            title="Add New"
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            disabled={!selectedCategory}
                                            onClick={() => {  
                                                setSubCategoryInEdit({
                                                    trainingCategoryId: selectedCategory.trainingCategoryId
                                                });
                                            }}
                                        >
                                            <i className="fas fa-plus pt-1"></i>
                                        </TooltipButton>
                                        <Grid
                                            height='calc(100vh - 550px)'
                                            totalRecords={subCategoryTotalRecords}
                                            pageSize={subCategoryPageSize}
                                            rows={tableSubCategory.rows}
                                            tableProps={tableSubCategory.getTableProps()}
                                            headerGroups={tableSubCategory.headerGroups}
                                            tableBodyProps={tableSubCategory.getTableBodyProps()}
                                            prepareRow={tableSubCategory.prepareRow}
                                            onPagerChangePage={(pager) => {
                                                setSubCategoryPageNumber(pager.currentPage);
                                            }}
                                            onPagerChangePageSize={(size) => {
                                                setSubCategoryPageSize(size);
                                            }}
                                            needPaging={true}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {
            !!categoryInEdit && 
            <Modal
                isOpen={true}
                className="modal-lg"
                modalClassName="db-example-modal-lg"
            >
                <div
                    className="modal-header"
                >
                    <h5
                        className="modal-title"
                    >
                        Training Category
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        onClick={() => setCategoryInEdit(null)}
                        type="button"
                    >
                        <span aria-hidden={true}>x</span>
                    </button>
                </div>
                <div
                    className="modal-body"
                >
                    <Container>
                        <Row>
                            <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Name
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            name="trainingCategoryName"
                                            id="trainingCategoryName"
                                            type="text"
                                            required
                                            value={categoryInEdit.trainingCategoryName}
                                            onChange={handleTextChanged}
                                        />
                                    </FormGroup>
                            </Col>                
                        </Row>
                    </Container>
                </div>
                <div
                    className="modal-footer"
                >
                    <Button
                        color="secondary"
                        onClick={() => setCategoryInEdit(null)}
                        type="button"
                    >
                        Close
                    </Button>
                    <Button
                        color="info"
                        onClick={() => {
                            handleCategorySave(categoryInEdit, setCategoryInEdit);
                        }}
                        type="button"
                    >
                        Save Changes
                    </Button>
                </div>
            </Modal>
            }

            {
            !!subCategoryInEdit && 
            <Modal
                isOpen={true}
                className="modal-lg"
                modalClassName="db-example-modal-lg"
            >
                <div
                    className="modal-header"
                >
                    <h5
                        className="modal-title"
                    >
                        Training Sub Category
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        onClick={() => setSubCategoryInEdit(null)}
                        type="button"
                    >
                        <span aria-hidden={true}>x</span>
                    </button>
                </div>
                <div
                    className="modal-body"
                >
                    <Container>
                        <Row>
                            <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Name
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            name="trainingSubCategoryName"
                                            id="trainingSubCategoryName"
                                            type="text"
                                            required
                                            value={subCategoryInEdit.trainingSubCategoryName}
                                            onChange={handleSubTextChanged}
                                        />
                                    </FormGroup>
                            </Col>                
                        </Row>
                    </Container>
                </div>
                <div
                    className="modal-footer"
                >
                    <Button
                        color="secondary"
                        onClick={() => setSubCategoryInEdit(null)}
                        type="button"
                    >
                        Close
                    </Button>
                    <Button
                        color="info"
                        onClick={() => handleSubCategorySave(subCategoryInEdit, setSubCategoryInEdit)}
                        type="button"
                    >
                        Save Changes
                    </Button>
                </div>
            </Modal>
            }
        </>
    );
}

export default TrainingCategory;