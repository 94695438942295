/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    Button, Modal, Row, Col, Container, Spinner
} from "reactstrap";
import { ComboBox, DropDownList } from "@progress/kendo-react-dropdowns";
import { AgGridReact } from 'ag-grid-react';
import api from "../../../../services/api";
import Loader from "../../../loaders";
import qs from "qs";
import { formatDate } from "../../../../utils";
import "../../../../assets/css/ag-grid.css";
import "../../../../assets/css/ag-theme-alpine.css";
import PropTypes from "prop-types";
import SMSEditor from '../../Candidates/EditorSMS';
import EditorEmail from '../../Candidates/EditorEmail';
import TalentEditor from '../../Candidates/Editor';
import { TooltipButton } from '../../../inputs';
import CandidateDocumentViewer from './CandidateDocumentViewer';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';
import { roles } from '../../../../auth/roles';
import SweetAlert from 'react-bootstrap-sweetalert';
import DropdownButton from '../../../dropdowns/dropdownbutton';

const getApplicantData = (id) => {
    return {
        getRows: (params) => {
            params.api.showLoadingOverlay();
            const query = {
                sortBy: params.request.sortModel.map(m => ({id: m.colId, desc: m.sort == 'desc'})),
                filters: Object.entries(params.request.filterModel).map(([id, value]) => ({id, value: value.filter}))
            }
            const queryString = qs.stringify(query, { allowDots: true });
            
            api.get(`/vacancy/applicants/${id}/${params.request.startRow}/${params.request.endRow - params.request.startRow}?${queryString}`)
            .then((response) => {
                params.success({
                    rowData: response.data.applicants,
                    rowCount: response.data.total,
                });
            })
            .catch((error) => {
                console.log(error.response);
                params.fail();
            }).finally(() => {
                params.api.hideOverlay();
            });
        },
    };
};

export default function JobOrderApplicantList(props) {
    const {
        jobOrder,
        onClose,
        applicantGrid,
    } = props;

    const gridRef = useRef();
    const [ selectedRow, setSelectedRow ] = React.useState(null);
    const [ selectedApplicants, setSelectedApplicants] = React.useState([]);
    const [ slots, setSlots ] = useState([]);
    const [ selectedSlot, setSelectedSlot ] = useState(null);
    const [ emptySlots, setEmptySlots ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const [ loadingModal, setLoadingModal ] = useState(false);
    const [ showSmsEditor, setShowSmsEditor ] = useState(false);
    const [ showEmailEditor, setShowEmailEditor ] = useState(false);
    const [ lookups, setLookups ] = useState(null);
    const [ candidateInEdit, setCandidateInEdit ] = useState(null);
    const [ candidateIdForCvPreview, setCandidateIdForCvPreview ] = useState(null);
    const [ reloadData, setReloadData ] = useState(false);
    const { instance } = useMsal();
    const user = instance.getActiveAccount();
    const isManager = _.intersection([roles.Manager], user.idTokenClaims.roles).length > 0 && user.idTokenClaims.roles?.length === 1;
    const [ confirmationDialog, setConfirmationDialog ] = useState(null);

    const loadData = useCallback(() => {
        setLoading(true);
        setEmptySlots(jobOrder.jobOrderCandidates.filter(j => !j.candidateId).length);
        
        api.get(`/vacancy/vacantslots/${jobOrder.jobOrderId}`)
            .then((response) => {
                setSlots(response.data.map(d => (
                    {
                        ...d,
                        value: `ID: ${d.jobOrderCandidateId} - Roster Slot No: ${d.rosterCandidate ? (d.rosterCandidate.relatedRosterCandidateId ? `${d.rosterCandidate.relatedRosterCandidateNumber}.${d.rosterCandidate.rosterCandidateNumber}` : `${d.rosterCandidate.rosterCandidateNumber}`) : "-"}`}
                )));
            })
            .catch((error) => {
                console.log(error.response);
            })
            .finally(() => setLoading(false));
        ;
    }, [jobOrder]);

    useEffect(() => {
        if (!reloadData || !gridRef?.current?.api || !jobOrder?.jobOrderId)
            return;

        setReloadData(false);
        const datasource = getApplicantData(jobOrder.jobOrderId);
        gridRef.current.api.setServerSideDatasource(datasource);
    }, [reloadData]);

    const onGridReady = useCallback((params) => {
        const datasource = getApplicantData(jobOrder.jobOrderId);
        params.api.setServerSideDatasource(datasource);
    }, [jobOrder]);

    const sendReviewRequest = useCallback((jobOrderApplicationId) => {
        if (!jobOrder?.recruitmentPersonId)
            return;

        setLoadingModal(true);
        api.post(`vacancy/review/request?id=${jobOrderApplicationId}&recruiterId=${jobOrder?.recruitmentPersonId}`)
            .then(() => {
                setLoadingModal(false);
                onGridReady(gridRef?.current);
            })
            .catch((error) => {
                console.log({error});
                setLoadingModal(false);
            })
        ;
    }, [jobOrder, onGridReady]);

    const setAsDecline = useCallback((jobOrderApplicationId) => {

        var isApproved = false;
        var recruitmentPersonId = !jobOrder?.recruitmentPersonId ? "" : jobOrder?.recruitmentPersonId;


        setLoadingModal(true);
        api.post(`vacancy/review/approval?id=${jobOrderApplicationId}&isApproved=${isApproved}&recruiterId=${recruitmentPersonId}`)
            .then(() => {
                setLoadingModal(false);
                onGridReady(gridRef?.current);
            })
            .catch((error) => {
                console.log({ error });
                setLoadingModal(false);
            })
            ;

    }, [jobOrder, onGridReady, jobOrder]);

    const columnDefs = useMemo(() => {
        const result = [
            { field: 'firstName', minWidth: 160, sort: 'asc', sortIndex: 2 },
            { field: 'surname', minWidth: 160, sort: 'asc', sortIndex: 1 },
            { field: 'mobilePhoneNumber', minWidth: 160 },
            { field: 'reviewRequestStatusName', headerName: "Review Status", minWidth: 120 },
            {
                field: 'status',
                width: 80,
                cellClass: params => {
                    return params?.data?.jobOrderCandidateId ? "bg-success text-white" : "bg-white";
                },
            },
            { field: 'lastDispatchUnit', minWidth: 160 },
            {
                field: 'cv',
                headerName: "CV / Resume",
                minWidth: 120,
                cellRenderer: (props) => {
                    const {data} = props;
                    const isHavingCv = data.candidateDocumentIsHavingCv;
    
                    return (
                        <div style={{ width: "100%" }} className={`d-flex flex-wrap justify-content-center align-items-center`}>
                        {
                            isHavingCv &&
                            <a className="text-info" href='javascript:;' onClick={() => { setCandidateIdForCvPreview(data.candidateId); }}><span className='fas fa-check-circle text-success'></span> View CV / Resume</a>
                        }
                        </div>
                    )
                },
            },
            {
                field: 'info',
                filter: false,
                width: 40,
                pinned: 'right',
                onCellClicked: (ev) => {
                    if (!lookups)
                        return;
                    
                    setCandidateInEdit({ candidateId: ev.data.candidateId });
                },
                valueFormatter: () => '',
                cellClass: () => `roster-cell ${lookups ? "cursor-pointer " : ""}link fas fa-info-circle text-center align-middle`,
                sortable: false,
            },
            {
                field: "actions",
                pinned: 'right',
                width: 240,
                filter: '',
    
                filterParams: {
                    name: 'action'
                },
                sortable: false,
                floatingFilter: false,
                cellClass: "actions-button-cell ag-cell-vcenter",
                cellRenderer: (props) => {
                    const { data } = props;
    
                    var rejectCandidate = (jobOrderApplicationId) => {
                        setConfirmationDialog({
                            title: "Reject Candidate",
                            message: "Are you sure you want to reject this candidate?",
                            onConfirm: () => {
                                setAsDecline(jobOrderApplicationId);
                                setConfirmationDialog(null);
                            },
                            onCancel: () => {
                                setConfirmationDialog(null);
                            }
                        });
                    }
    
                    var children = [
                        {
                            title: "Send Request to Review",
                            icon: "fas fa-address-card",
                            visible: !!jobOrder?.recruitmentPersonId,
                            onClick: () => {
                                sendReviewRequest(data.jobOrderApplicationId);
                            }
                        },
                        {
                            title: "Reject Candidate",
                            icon: "fas fa-times",
                            visible: true,
                            onClick: () => {
                                //setAsDecline(data.jobOrderApplicationId);
                                rejectCandidate(data.jobOrderApplicationId);
                            }
                        },
                    ];
    
                    var mainButtonText = jobOrder?.recruitmentPersonId ? "Send Request to Review" : "Progress";
                    var mainButtonIcon = jobOrder?.recruitmentPersonId ? "fas fa-address-card" : "fas fa-times";
    
    
                    return (
    
    
                        <DropdownButton title={mainButtonText} icon={mainButtonIcon} children={children} size="xsm" buttonGroupClassName="btn-block w-100-custom"
                            onClick={() => {
                                if (!!jobOrder?.recruitmentPersonId) {
                                    sendReviewRequest(data.jobOrderApplicationId);
                                }
                                else
                                {
                                    rejectCandidate(data.jobOrderApplicationId);
                                }
                            }}
                        ></DropdownButton>
    
    
    
                    );
                },
            },
        ];
        
        if (!isManager)
            {
                const insertedIndex = result.indexOf(result.filter(c => c.field === "lastDispatchUnit")[0]);
                result.splice(insertedIndex, 0,
                {
                    field: 'info',
                    filter: false,
                    width: 40,
                    pinned: 'right',
                    onCellClicked: (ev) => {
                        if (!lookups)
                            return;
                        
                        setCandidateInEdit({ candidateId: ev.data.candidateId });
                    },
                    valueFormatter: () => '',
                    cellClass: () => `roster-cell ${lookups ? "cursor-pointer " : ""}link fas fa-info-circle text-center align-middle`,
                    sortable: false,
                    filter: false,
                });
            }
            
        return result;
    }, [lookups, jobOrder, sendReviewRequest]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: false,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                debounceMs: 200,
            },
            suppressMenu: true,
            floatingFilter: true,
            
        };
    }, []);

    useEffect(() => {
        const apiCalls = [
            api.get(`/lookup/skillposition`),
            api.get(`/lookup/candidatetype`),
            api.get(`/lookup/salutation`),
            api.get(`/lookup/preference`),
            api.get(`/lookup/industry`),
            api.get(`/lookup/gender`),
            api.get(`/lookup/maritalStatus`),
            api.get(`/lookup/candidateStatus`),
            api.get(`/lookup/candidateFlag`),
            api.get(`/lookup/residencyStatus`),
            api.get(`/lookup/visatype`),
            api.get(`/lookup/communicationtype`),
            api.get(`/lookup/country`),
            api.get(`/lookup/state`),
            api.get(`/lookup/relationshiptype`),
            api.get(`/lookup/candidateplatformorigin`),
            api.get(`/lookup/messagesmasters`),
            api.get(`/emailtemplate`), // TODO: returns paged result - move to lookup
            api.get(`/lookup/recruitmentpeople`),
            api.get(`/lookup/candidatediscipline`),
            api.get(`/lookup/company`),
            api.get(`/lookup/identifier`),
            api.get(`/lookup/candidateevent`),
            api.get(`/lookup/candidateeventstatus`),
            api.get(`/lookup/candidatecommenttype`),
            api.get(`/lookup/agency`)
        ];

        Promise.all(apiCalls)
            .then((data) => {
                setLookups({
                    skillPositions: data[0].data,
                    candidateTypes: data[1].data,
                    salutations: data[2].data,
                    preferences: data[3].data,
                    industries: data[4].data,
                    genders: data[5].data,
                    maritalStatuses: data[6].data,
                    candidateStatuses: data[7].data,
                    candidateFlags: data[8].data,
                    residencyStatuses: data[9].data,
                    visaTypes: data[10].data,
                    communicationTypes: data[11].data,
                    countries: data[12].data,
                    states: data[13].data,
                    relationshipTypes: data[14].data,
                    candidatePlatformOrigin: data[15].data,
                    messagesMaster: data[16].data,
                    emailTemplate: data[17].data.data,
                    recruitmentPeople: data[18].data,
                    candidateDisciplines: data[19].data,
                    companies: data[20].data,
                    identifiers: data[21].data,
                    events: data[22].data,
                    eventStatus: data[23].data,
                    candidateCommentTypes: data[24].data,
                    agencies: data[25].data
                });
            }).catch((error) => {
                console.error("error on getting options", error.message, error.response);
            })
        ;
    }, []);

    useEffect(() => {
        loadData();
    }, [jobOrder]);

    // useEffect(() => {
    //     console.log('selectedApplicants', selectedApplicants);
    // }, [selectedApplicants]);

    const onSelectionChanged = useCallback((params) => {
        const selectedRows = params.api.getSelectedRows();
        // console.log('selectedRows', selectedRows)
        if (selectedRows.length > 1) {
            setSelectedRow(null);
            setSelectedSlot(null);
        } else {
            setSelectedRow(selectedRows[0]);
        }

        if (selectedRows && selectedRows.length) {
            setSelectedApplicants([...selectedRows]);
        } else {
            setSelectedApplicants([]);
        }
      }, []);

    const getRowId = useMemo(() => (params) => params?.data?.jobOrderApplicationId, []);

    const loadingOverlayComponent = useMemo(() => Loader, []);

    const assignApplicants = useCallback(() => {
        if (!selectedApplicants.length)
            return;

        setLoading(true);
        gridRef.current.api.showLoadingOverlay();
        // console.log({selectedApplicants});

        api.post(`/vacancy/assign`, JSON.stringify(selectedApplicants.map(r => ({...r, jobOrderCandidateId: selectedApplicants.length > 1 ? null : selectedSlot?.jobOrderCandidateId}))))
            .then((response) => {
                gridRef.current.api.hideOverlay();
                setLoading(false);
                onClose();
            })
            .catch((error) => {
                // TODO: Display Error message in UI
                console.log(error.response);
                setLoading(false);
            })
        ;
    }, [applicantGrid, selectedApplicants, selectedSlot, gridRef, onClose]);

    return (
        <Modal
            isOpen={true}
            className="modal-xxl"
            modalClassName="db-example-modal-xl"
        >
        {
            showSmsEditor &&
            <SMSEditor
                items={[...selectedApplicants]}
                onClose={() => setShowSmsEditor(false)}
                onSaved={() => setShowSmsEditor(false)}
                lookups={lookups}
            />
        }
        {
            showEmailEditor &&
            <EditorEmail
                items={[...selectedApplicants]} 
                onClose={() => setShowEmailEditor(false)}
                onSaved={() => setShowEmailEditor(false)}
                lookups={lookups}
            />
        }
        {
            candidateInEdit ?
            <TalentEditor
                item={candidateInEdit}
                onClose={() => {
                    setCandidateInEdit(null);
                    setReloadData(true);
                }}
                onSaved={() => {
                    setCandidateInEdit(null);
                    setReloadData(true);
                }}
                lookups={{
                    ...lookups
                }}
            />
            :
            null
        }
        {
            loadingModal && <Loader />
        }
            <div className="modal-header">
                <Container fluid>
                    <Row>
                        <Col lg={9}>
                            <h5
                                className="modal-title"
                            >
                                Applicants -&nbsp;
                                {jobOrder?.projectName} <i className="fas fa-angle-right ml-1 mr-1"></i>
                                {jobOrder?.recruitLocation} <i className="fas fa-angle-right ml-1 mr-1"></i>
                                {jobOrder?.hrispositionMasterName}
                            </h5>
                        </Col>
                        <Col lg={3}>
                            <button
                                aria-label="Close"
                                className="close"
                                onClick={onClose}
                                type="button"
                            >
                                <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                            </button>
                        </Col>
                    </Row>
                    
                </Container>
            </div>
            <div
                className="modal-body"
            >
                <Container fluid className='px-0'>
                    <Row className="m-0 p-0 mb-2">
                        <Col lg={6} className="d-flex flex-wrap justify-content-start p-0">
                            <Button
                                color="default"
                                onClick={() => setShowSmsEditor(true)}
                                type="button"
                                size="sm"
                                disabled={!selectedApplicants?.length}
                            >
                                <i className="fas fa-paper-plane mr-2" />Send SMS
                            </Button>
                            <Button
                                color="default"
                                onClick={() => setShowEmailEditor(true)}
                                type="button"
                                size="sm"
                                disabled={!selectedApplicants || !selectedApplicants.length}
                            >
                                <i className="fas fa-envelope mr-2" />Send Email
                            </Button>
                        </Col>
                        <Col lg={6} className="d-flex flex-wrap justify-content-end">
                            <span>Empty Slots: <b>{emptySlots > 0 ? emptySlots : "-"}</b></span>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className="ag-theme-alpine px-0" style={{width: '100%', height: 'calc(100vh - 330px)'}}>
                                <AgGridReact
                                    ref={gridRef}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    rowModelType={'serverSide'}
                                    serverSideInfiniteScroll={true}
                                    pagination={true}
                                    paginationPageSize={50}
                                    cacheBlockSize={50}
                                    animateRows={true}
                                    onGridReady={onGridReady}
                                    headerHeight={22}
                                    rowHeight={28}
                                    rowSelection='multiple'
                                    onSelectionChanged={onSelectionChanged}
                                    getRowId={getRowId}
                                    loadingOverlayComponent={loadingOverlayComponent}
                                ></AgGridReact>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
            <div
                className="modal-footer w-100"
            >
                <Container fluid>
                    <Row className="w-100 m-0">
                        <Col lg="9" className="d-flex flex-wrap justify-content-start align-items-center m-0 p-0">
                            <DropDownList
                                className='mr-3'
                                style={{
                                width: "400px",
                                }}
                                data={slots}
                                textField="value"
                                dataItemKey="rosterCandidateId"
                                value={selectedSlot}
                                onChange={(event) => {
                                    setSelectedSlot(event.target.value)
                                }}
                            />
                            {selectedRow && selectedApplicants?.length === 1 ? `${selectedRow.firstName} ${selectedRow.surname}` : ""}
                            <Button
                                className='ml-3'
                                color="info"
                                onClick={() => {
                                    assignApplicants()
                                }}
                                type="button"
                                disabled={selectedApplicants?.length === 0 || loading || selectedApplicants.filter(a => a.jobOrderCandidateId).length > 0}
                            >
                                Shortlist{selectedApplicants?.length > 1 ? " (Multiple)" : ""}
                            {
                                loading && <Spinner size="sm" type="border" color="white" className="ml-2" />
                            }
                            </Button> 
                        </Col>
                        <Col lg="3" className="d-flex flex-wrap justify-content-end align-items-center m-0 p-0">

                            <Button
                                color="secondary"
                                onClick={onClose}
                                type="button"
                            >
                                Close
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            {
                candidateIdForCvPreview &&
                <CandidateDocumentViewer
                    onClose={() => setCandidateIdForCvPreview(null)}
                    candidateId={candidateIdForCvPreview}
                />
            }
            {
                confirmationDialog &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="danger"
                    cancelBtnText="No"
                    cancelBtnBsStyle="secondary"
                    title={<h4 className="mb-0">{confirmationDialog.title}</h4>}
                    onConfirm={confirmationDialog.onConfirm ? () => confirmationDialog.onConfirm() : () => setConfirmationDialog(null)} // need to add loading indicator on confirm
                    onCancel={confirmationDialog.onCancel ? () => confirmationDialog.onCancel() : () => setConfirmationDialog(null)}
                    focusCancelBtn
                >
                    <p>{confirmationDialog.message}</p>
                </SweetAlert>
            }
        </Modal>
    )
}

JobOrderApplicantList.propTypes = {
    jobOrder: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    applicantGrid: PropTypes.object.isRequired,
};
