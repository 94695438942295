import React, { useCallback, useEffect, useState } from 'react';
import api from "../../../services/api";
import {
    Input, Label, FormGroup, Button, Modal, Container, Row, Col
 } from "reactstrap"; 
import { DropdownBox, ComboBox } from "../../dropdowns";
import Loader from "../../loaders";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw, SelectionState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { v4 } from "uuid";
import { ListBox } from '@progress/kendo-react-listbox';
import moment from "moment";
import _ from 'lodash';
import { IndeterminateCheckbox } from '../../react-table/controls';

const ListBoxItem = (props) => {
    let { dataItem, selected, onClick, ...others } = props;
    return (
        <li {...others} onDoubleClick={onClick}>
            <span>{dataItem.name}: {dataItem.value}</span>
        </li>
    );
};

export default function EditorEmail(props) {
    const {
        sourcePage,
        refId,
        project,
        projectId,
    } = props;

    const [ itemInEdit, setItemInEdit ] = React.useState([]);
    const [ selectedEmail, setSelectedEmail ] = React.useState(null);
    const [ loading, setLoading ] = React.useState(false);
    const [ editorState, setEditorState ] = React.useState(EditorState.createEmpty());
    const [ failedMessages, setFailedMessages ] = useState(null);
    const [ templateFirstLoad, setTemplateFirstLoad ] = useState(false);
    const [ attributes, setAttributes ] = React.useState([]);
    const [ cursorPosition, setCursorPosition ] = React.useState(0);
    const [ refData, setRefData ] = useState(null);
    const [ needTalentResponse, setNeedTalentResponse ] = useState(false);
    const [ needYesNoResponse, setNeedYesNoResponse ] = useState(false);
    const [ needCommentResponse, setNeedCommentResponse ] = useState(null);
    const [ selectedEmailDetail, setSelectedEmailDetail ] = useState(null);
    const [ showTemplateOptions, setShowTemplateOptions ] = useState(false);
    
    useEffect(() => {
        setShowTemplateOptions(sourcePage);
    }, [sourcePage]);

    // console.log('EditorEmail');
    // console.log('props', props);

    React.useEffect(() => { 
        // console.log("email items: ", props.items);
        if (props.candidateId > 0) {
            setLoading(true);
            const apiCalls = [
                api.get(`candidate/${props.candidateId}`),
                api.get(`messagesmaster/messageattributes`),
                api.get(`projectAttribute/search`),
                api.get(`rosterAttribute/search`),
            ];

            Promise.all(apiCalls)
            .then((responses) => {
                const {
                    candidateNotes,
                    ...rest
                } = responses[0].data;

                setItemInEdit([{ 
                    ...rest, 
                    candidateNotes: candidateNotes && candidateNotes.length > 0 
                        ? candidateNotes.map((item) => ({ ...item, uid: v4() }))
                        : [] 
                }]);

                setAttributes([
                    ...responses[1].data.map(d => ({
                        ...d,
                        hidden: (!sourcePage && d.category !== "Candidate Message Attribute")
                    })),
                    ...responses[2].data.map(m => ({
                        name: `{${m.projectAttributeName}}`,
                        category: "Project Attribute",
                        hidden: !sourcePage
                    })),
                    ...responses[3].data.map(m => ({
                        name: `{${m.rosterAttributeName}}`,
                        category: "Roster Attribute",
                        hidden: !sourcePage
                    }))
                    ]);
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
            return;
        }
        
        if (sourcePage || props.items?.length) {
            setLoading(true);
            setItemInEdit(props.items.map(r => {
                if (r.candidate)
                    return {...r, ...r.candidate};
                
                return {...r};
            }));

            const apiCalls = [
                api.get(`messagesmaster/messageattributes`),
            ];

            if (refId) {
                //const refId = props.items.candidates[0].refId;
                apiCalls.push(api.get(`projectattribute/candidate/${projectId}/${refId}`));
                apiCalls.push(api.get(`rosterattribute/roster/${refId}`));

                if (sourcePage === "roster")
                    apiCalls.push(api.get(`rostercandidate/id/${refId}`));
                
                if (sourcePage === "jobOrder")
                    apiCalls.push(api.get(`jobordercandidate/${refId}`));
            }
            else
            {
                apiCalls.push(api.get(`projectAttribute/search`));
                apiCalls.push(api.get(`rosterAttribute/search`));
            }

            Promise.all(apiCalls)
            .then((responses) => {
                if (!refId)
                {
                    // console.log({responses});
                    setAttributes([
                        ...responses[0]?.data.map(d => ({
                            ...d,
                            hidden: (!sourcePage && d.category !== "Candidate Message Attribute")
                        })),
                        ...responses[1]?.data?.map(m => ({
                            name: `{${m.projectAttributeName}}`,
                            category: "Project Attribute",
                            hidden: !sourcePage
                        })),
                        ...responses[2]?.data.map(m => ({
                            name: `{${m.rosterAttributeName}}`,
                            category: "Roster Attribute",
                            hidden: !sourcePage
                        }))
                    ]);
                    return;
                }

                // console.log("roster candidate", responses[3].data);
                setRefData(responses[3].data);
                setAttributes([
                    ...responses[0].data.map(d => ({
                        ...d,
                        hidden: (!sourcePage && d.category !== "Candidate Message Attribute")
                    })),
                    ...responses[1].data.map(m => ({
                        name: `{${m.name}}`,
                        value: m.value,
                        category: "Project Attribute",
                        hidden: false
                    })),
                    ...responses[2].data.map(m => ({
                        name: `{${m.name}}`,
                        value: m.value,
                        category: "Roster Attribute",
                        hidden: false
                    }))
                ]);
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
        }
    }, [props.candidateId, props.items, sourcePage]);

    const messageValidation = useCallback((title, message) => {
        // console.log({message, needTalentResponse});
        if (!message || message.trim() === "")
        {
            setFailedMessages([{
                failCategory: "Empty Message",
            }]);
            return false;
        }

        if (title && (title.toLowerCase() === "availability" || title.toLowerCase() === "accommodation") && message.includes("{FlowUrls.TalentResponseUrl}"))
        {
            setFailedMessages([{
                failCategory: "Invalid Attribute Usage",
                message: "Attribute {FlowUrls.TalentResponseUrl} cannot be used if the message template is Availability or Accommodation."
            }]);
            return false;
        }

        if (message.includes("{FlowUrls.TalentResponseUrl}") && !needTalentResponse)
        {
            setFailedMessages([{
                failCategory: "URL Response",
            }]);
            return false;
        }

        if (message.includes("{FlowUrls.TalentResponseUrl}") && !needTalentResponse)
        {
            setFailedMessages([{
                failCategory: "URL Response",
            }]);
            return false;
        }
        
        if (!message.includes("{FlowUrls.TalentResponseUrl}") && needTalentResponse)
        {
            setFailedMessages([{
                failCategory: "Talent Response Parameter",
            }]);
            return false;
        }

        setFailedMessages(null);
        return true;
    }, [needTalentResponse]);

    useEffect(() => {
        const newMessageAttributes = [...attributes.map(d => ({...d, hidden: (selectedEmail?.lookupType?.toLowerCase() === "availability" || selectedEmail?.lookupType?.toLowerCase() === "accommodation") && (d.category === "Message Attribute" || d.category === "Candidate Message Attribute") && d.name === "{FlowUrls.TalentResponseUrl}"}))];
        setAttributes(newMessageAttributes);

        if (!selectedEmail?.lookupId)
        {
            if (selectedEmailDetail?.messageMasterId)
                setSelectedEmailDetail({...selectedEmail, includeUrl: false, showYesNo: false, showComment: false});
            return;
        }

        if (selectedEmail.lookupId === selectedEmailDetail?.messagesMasterId)
            return;

        setLoading(true);
        api.get(`messagesmaster/${selectedEmail.lookupId}`)
            .then((response) => {
                setSelectedEmailDetail(response.data);
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }, [selectedEmail?.lookupId]);

    useEffect(() => {
        // console.log('message1', {templateFirstLoad, itemInEdit, selectedEmail});
        // console.log('message1', {itemInEdit, selectedEmail, selectedEmailDetail});
        if (!itemInEdit || itemInEdit.length === 0 || !selectedEmail?.lookupValue)
            return;

        setNeedTalentResponse(selectedEmailDetail?.includeUrl ?? false);
        setNeedYesNoResponse(selectedEmailDetail?.showYesNo ?? false);
        setNeedCommentResponse(selectedEmailDetail?.showComment ?? false);

        let candidate = {};

        if (itemInEdit.length > 1)
        {
            const distinctDispatchUnit = [...new Set(itemInEdit.map(c => c.dispatchUnitId))].map(c => itemInEdit.filter(i => i.dispatchUnitId === c)[0]);
            const distinctRoster = [...new Set(itemInEdit.map(c => c.rosterId))].map(c => itemInEdit.filter(i => i.rosterId === c)[0].rosterName);
            const distinctLocation = [...new Set(itemInEdit.map(c => c.locationId))].map(c => itemInEdit.filter(i => i.locationId === c)[0].locationName);
            const distinctStartDate = [...new Set(itemInEdit.map(c => c.startDate))];
            const distinctEndDate = [...new Set(itemInEdit.map(c => c.endDate))];

            // console.log('message2', {itemInEdit, distinctDispatchUnit, distinctRoster, distinctLocation, distinctStartDate, distinctEndDate});

            candidate.projectName = project?.lookupValue ? project.lookupValue : project?.projectName;
            
            if (distinctDispatchUnit?.length === 1)
            {
                candidate.dispatchUnitName = distinctDispatchUnit[0].dispatchUnitName;
                candidate.quoteNumber = distinctDispatchUnit[0].quoteNumber;
                candidate.dispatchStartDate = distinctDispatchUnit[0].dispatchStartDate;
                candidate.dispatchEndDate = distinctDispatchUnit[0].dispatchEndDate;
            }
            
            if (distinctRoster?.length === 1)
                candidate.rosterName = distinctRoster[0];
            
            if (distinctLocation?.length === 1)
                candidate.locationName = distinctLocation[0];
        
            if (distinctStartDate?.length === 1)
                candidate.startDate = distinctStartDate[0];
            
            if (distinctEndDate?.length === 1)
                candidate.endDate = distinctEndDate[0];
        }
        else
        {
            if (refData)
            {
                if (sourcePage === "roster")
                {
                    candidate = {...refData.candidate};
                    candidate.projectName = project.lookupValue ? project.lookupValue : project.projectName;
                    candidate.dispatchUnitName = refData.roster.dispatchUnit.dispatchUnitName;
                    candidate.quoteNumber = refData.roster.dispatchUnit.quoteNumber;
                    candidate.rosterName = refData.roster.rosterName;
                    candidate.locationName = refData.roster.dispatchUnit.location.lookupValue;
                    candidate.dispatchStartDate = refData.roster.dispatchUnit.startDate;
                    candidate.dispatchEndDate = refData.roster.dispatchUnit.endDate;
                    candidate.startDate = refData.roster.dispatchUnit.startDate;
                    candidate.endDate = refData.roster.dispatchUnit.endDate;
                }

                if (sourcePage === "jobOrder")
                {
                    candidate = {...refData.candidate};
                    candidate.projectName = refData.projectName;
                }
            }
            else
                candidate = itemInEdit[0];
        }
        
        // console.log('message3', {candidate, attributes});
        
        let message = selectedEmail?.lookupValue;

        if (!message || message === "" || !candidate)
        {
            setSelectedEmail({...selectedEmail, lookupValue: message});
            return;
        }

        if (attributes && attributes.length > 0) {
            for (let i = 0; i < attributes.length; i++) {
                // console.log('attributes', {attributes, message});
                const attribute = attributes[i];

                if (attributes[i].category === "Message Attribute" || attributes[i].category === "Candidate Message Attribute")
                {
                    const valueSource = _.camelCase(attribute.valueSource);
                    // console.log("message attr", {attribute, valueSource, value: candidate[valueSource]});

                    if (attribute.replaceWhenSingle && itemInEdit.length === 1 && candidate[valueSource])
                    {
                        message = message.replace(new RegExp(attribute.name, 'g'), candidate[valueSource]);
                        continue;
                    }

                    if (candidate[valueSource])
                    {
                        // console.log("message attr 2", {message, attribute, name: attribute.name, valueSource, value: candidate[valueSource], result: message.replace(new RegExp(attribute.name, 'g'), candidate[valueSource])});
                        message = message.replace(new RegExp(attribute.name, 'g'), candidate[valueSource]);
                    }
                    
                    continue;
                }

                if (attribute.value)
                    message = message.replace(new RegExp(`${attribute.name}`, 'g'), attribute.value);
            }
        }
        
        // console.log('message5', {message});

        setSelectedEmail({...selectedEmail, lookupValue: message});
        // setTemplateFirstLoad(false);
    }, [itemInEdit, selectedEmail?.lookupValue, selectedEmailDetail, attributes]);

    useEffect(() => {
        if (!needTalentResponse)
        {
            setNeedYesNoResponse(false);
            setNeedCommentResponse(false);
            return;
        }

        setNeedYesNoResponse(true);
        setNeedCommentResponse(true);
    }, [needTalentResponse]);

    useEffect(() => {
        if (!needYesNoResponse && !needCommentResponse && needTalentResponse)
            setNeedTalentResponse(false);
    }, [needYesNoResponse, needCommentResponse]);

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setSelectedEmail({...selectedEmail, lookupValue: draftToHtml(convertToRaw(editorState.getCurrentContent()))});
    }

    const handleDragStart = (e) => {
        // console.log('handleDragStart', e)
        e.nativeEvent.dataTransfer.setData('text', e.dataItem.name);
        e.nativeEvent.effectAllowed = "copy";
    };

    const handleSave = useCallback((item) => {
        if (!messageValidation(selectedEmail?.lookupType, selectedEmail?.lookupValue))
            return;
        
        setLoading(true);

        // var emailLog = {
        //     candidateIds: item.map(m => m.candidateId),
        //     emailTemplateId: selectedEmail.lookupId,
        //     subject: selectedEmail.lookupType,
        //     message: selectedEmail.lookupValue,
        //     messageType: 'Notification'
        // };

        const emailLog = {
            messagesMasterId: selectedEmail?.lookupId,
            refIds: item.map(m => sourcePage === "roster" ? m.rosterCandidateId : (sourcePage === "jobOrder" ? m.jobOrderCandidateId : m.candidateId)),
            refType: sourcePage === "roster" ? "Roster" : (sourcePage === "jobOrder" ? "JobOrder" : "Candidate"),
            messagesMasterId: selectedEmail.lookupId,
            message: selectedEmail.lookupValue,
            messageType: 'Notification',
            showYesNo: needYesNoResponse,
            showComment: needCommentResponse,
            subject: selectedEmail?.lookupType
        };

        const data = JSON.stringify(emailLog);
        const submit = api.post(`sendEmail/new-message`, data);

        submit.then((response) => {
            if (response.data?.failed?.length > 0)
            {
                setFailedMessages(response.data.failed);
                setLoading(false);
                return;
            }
            setLoading(false);
            props.onSaved();
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
        });
    }, [selectedEmail, sourcePage, messageValidation, needYesNoResponse, needCommentResponse]);

    useEffect(() => {
        if (!selectedEmail) {
            setEditorState(EditorState.createEmpty());
            return;
        }
        
        const contentBlock = htmlToDraft(selectedEmail.lookupValue);

        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const contentEditorState = EditorState.createWithContent(contentState);
            
            let newBlockKey;
            const newContentState = contentEditorState.getCurrentContent();
            const blockMap = newContentState.getBlockMap();

            blockMap.forEach((contentBlock) => {
                newBlockKey = contentBlock.getKey();
            });

            // This sets a new SelectionState with the old selection's offsets
            // and the new editor state block keys
            const updateSelection = new SelectionState({
                anchorKey: newBlockKey,
                anchorOffset: editorState.getSelection().getAnchorOffset(),
                focusKey: newBlockKey,
                focusOffset: editorState.getSelection().getFocusOffset(),
                isBackward: false,
            });
            // And finally, we set the selection state to the new editor state so we can properly add links to it
            const newEditorStateWithSelection = EditorState.forceSelection(contentEditorState, contentEditorState.getSelection().merge(updateSelection));
            setEditorState(newEditorStateWithSelection);
        }
    }, [selectedEmail?.lookupValue]);

    return (
        <Modal
            isOpen={true}
            className={'modal-xl'}
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Email Editor
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}>x</span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                <Container>
                    {(loading) && <Loader />}
                    <Row>
                        {itemInEdit.some(s => !s.firstName) &&
                            <Col xs={12}>
                                <div className="alert alert-warning">You have selected item(s) with no candidate that might be ignored.</div>
                            </Col>
                        }
                        <Col xs={8}>
                            <Row>
                            {
                                failedMessages?.length > 0 &&
                                <Col className="font-size-message-alert-custom text-danger" xs={12}>
                                    <span>
                                    {
                                        failedMessages.map((item, idx) => {
                                            if (item.failCategory === "Empty Message")
                                                return (
                                                    <li key={`fail_${idx}`}>
                                                        <span>Message cannot be empty.</span>
                                                    </li>
                                                );

                                            if (item.failCategory === "Invalid Attribute Usage")
                                                return (
                                                    <li key={`fail_${idx}`}>
                                                        <span>{item.message}</span>
                                                    </li>
                                                );

                                            if (item.failCategory === "URL Response")
                                                return (
                                                    <li key={`fail_${idx}`}>
                                                        <span><b>Add URL to Response</b> must be checked.</span>
                                                    </li>
                                                );
                                            
                                            if (item.failCategory === "Talent Response Parameter")
                                                return (
                                                    <li key={`fail_${idx}`}>
                                                        <span><b>{"{FlowUrls.TalentResponseUrl}"}</b> must be provided.</span>
                                                    </li>
                                                );
                                            
                                            if (item.failCategory === "Message Unsent")
                                                return (
                                                    <li key={`fail_${idx}`}>
                                                        <span>
                                                        {
                                                            `Message is failed to send to ${item.candidateName}.`
                                                        }
                                                        </span>
                                                    </li>
                                                );
                                            
                                            if (item.failCategory === "Invalid Attribute")
                                                return (
                                                    <li key={`fail_${idx}`}>
                                                        <span>
                                                        {
                                                            item.failAttribute
                                                        }
                                                        </span>
                                                    </li>
                                                );

                                            return (
                                                <li key={`fail_${idx}`}>
                                                    <span>
                                                    {
                                                        `${item.candidateName}: missing ${item.failCategory} - ${item.failAttribute}.`
                                                    }
                                                    </span>
                                                </li>
                                            );
                                        })
                                    }
                                    </span>
                                </Col>
                            }
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Selected Candidate(s)
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Input placeholder={itemInEdit.filter(m => m.firstName).map(m => m.firstName + ' ' + m.surname + ' (' + m.email + ')')} readOnly />
                                    </FormGroup>
                                </Col>
                            {
                                showTemplateOptions &&
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Email Template
                                        </Label>
                                        <DropdownBox 
                                            data={props?.lookups?.messagesMaster?.filter(m => m.lookupType.toLowerCase() !== "availability" && m.lookupType.toLowerCase() !== "accommodation" && m.lookupType.toLowerCase() !== "das" && m.lookupType.toLowerCase() !== "estimate availability") ?? []}
                                            idField="lookupId"
                                            valueField="lookupType"
                                            selectedItem={selectedEmail}
                                            onChange={(item) => setSelectedEmail(item)}
                                        />
                                    </FormGroup>
                                </Col>
                            }
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Message Subject
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            value={selectedEmail?.lookupType ?? ""}
                                            onChange={({target:{ value }}) => { 
                                                setSelectedEmail({ ...selectedEmail, lookupType: value});
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Message Text
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Editor
                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={onEditorStateChange}
                                        />                                
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <Row>
                                        <Col xs={12} md={4} lg={4} className="w-100">
                                            <FormGroup>
                                                <IndeterminateCheckbox
                                                    id={`talentResponse`}
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                        setNeedTalentResponse(!needTalentResponse);
                                                    }}
                                                    checked={needTalentResponse}
                                                    customTitle="Add URL to Response"
                                                />
                                            </FormGroup>
                                        </Col>
                                    {
                                        needTalentResponse &&
                                        <>
                                            <Col xs={12} md={4} lg={4}>
                                                <FormGroup>
                                                    <IndeterminateCheckbox
                                                        id={`yesNoParam`}
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            setNeedYesNoResponse(!needYesNoResponse);
                                                        }}
                                                        checked={needYesNoResponse}
                                                        customTitle="Yes/No"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={4} lg={4}>
                                                <FormGroup>
                                                    <IndeterminateCheckbox
                                                        id={`commentParam`}
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            setNeedCommentResponse(!needCommentResponse);
                                                        }}
                                                        checked={needCommentResponse}
                                                        customTitle="Comment"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </>
                                    }
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={4}>
                            <label>Variables</label>
                            <ListBox
                                style={{
                                    width: "100%",
                                    height: "calc(100% - 29px)",
                                    maxHeight: 401,
                                    overflowY: "scroll",
                                    paddingBottom: "1rem",
                                }}
                                data={attributes?.filter(d => !d?.hidden)}
                                textField="name"
                                valueField='value'
                                onItemClick={(e) => {
                                    const value = selectedEmail?.lookupValue;
                                    const start = value?.substring(0, cursorPosition);
                                    const end = value?.substring(cursorPosition);
                                    // console.log('attribute click', {e, value, start, end, cursorPosition});
                                    setSelectedEmail({ ...selectedEmail, lookupValue: `${start ?? ""}${e.dataItem.name}${end ?? ""}` });
                                }}
                                item={ListBoxItem}
                                onDragStart={handleDragStart}
                            />
                        </Col>
                    </Row>
        
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={props.onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave(itemInEdit.filter(m => m.firstName).map(m => ({...m})))}
                    type="button"
                >
                    <i className="fas fa-paper-plane mr-2"></i>Send Email
                </Button>
            </div>
        </Modal>
    )
}