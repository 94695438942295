import React, { useCallback, useEffect, useState } from 'react';
import api from "../../../services/api";
import {
    Row, Col, Button, Modal, FormGroup, Label, Input
 } from "reactstrap"; 
import Loader from "../../loaders";
import { ErrorNotification } from '../../alerts';

function EditorPortalUser(props) {
    const {
        data,
        onClose,
        onSave
    } = props;

    const [loading, setLoading] = useState(false);
    const [itemInEdit, setItemInEdit] = useState({...data});
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorTitle, setErrorTitle] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);

    const handleTextChanged = useCallback(({ target: { name, value } }) => {
        setItemInEdit({ ...itemInEdit, [name]: value });
    }, [itemInEdit]);

    useEffect(() => {
        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        const events = {
            onConfirm: () => {
                setErrorMessage(null);
                setErrorTitle(null);
            },
            message: errorMessage,
            title: errorTitle
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage, errorTitle]);

    const handleSave = useCallback(() => {
        setLoading(true);
        api.put(`candidateportal/${itemInEdit.id}`, JSON.stringify(itemInEdit))
            .then(() => {
                setLoading(false);
                onSave();
            })
            .catch((error) => {
                console.log({error});
                setErrorTitle("User Update Failed");
                setErrorMessage(error.response.data);
                setLoading(false);
            })
        ;
    }, [itemInEdit]);

    return (
        <>
        {
            loading && <Loader />
        }
        {
            errorNotification.length > 0 && errorNotification
        }
            <Modal
                isOpen={true}
                className="modal-lg"
            >
                <div
                    className="modal-header my-0"
                >
                    <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="modal-title">
                            Update Portal User - {itemInEdit?.displayName ?? ""}
                        </h5>
                    </div>
                    <button
                        aria-label="Close"
                        className="close"
                        onClick={onClose}
                        type="button"
                    >
                        <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                    </button>
                </div>
                <div
                    className="modal-body"
                >
                    <Row>
                        <Col xs={4}>
                            <FormGroup>
                                <Label>
                                    Display Name
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    name="displayName"
                                    id="displayName"
                                    type="text"
                                    required
                                    value={itemInEdit.displayName || ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={4}>
                            <FormGroup>
                                <Label>
                                    Phone Number
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    type="phone"
                                    required
                                    value={itemInEdit.phoneNumber || ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={4}>
                            <FormGroup>
                                <Label>
                                    Email
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    name="email"
                                    id="email"
                                    type="email"
                                    required
                                    value={itemInEdit.email || ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <div
                    className="modal-footer flex-row"
                >
                    <Button
                        color="secondary"
                        onClick={() => {
                            onClose();
                        }}
                        type="button"
                    >
                        Close
                    </Button>
                    <Button
                        color="info"
                        onClick={() => {
                            handleSave();
                        }}
                        type="button"
                    >
                        Save
                    </Button>
                </div>
            </Modal>
        </>
    );
}

export default EditorPortalUser;
