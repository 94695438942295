import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { FiltersConverter, SortsConverter } from '../../../../helpers';
import { TooltipButton } from '../../../inputs';
import Loader from '../../../loaders';
import { IndeterminateCheckbox } from '../../../react-table/controls';
import PositionCellRenderer from '../LrGridV2/cellRenderer/positionCellRenderer';
import api from "../../../../services/api";
import qs from "qs";
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import fileDownload from 'js-file-download';
import { roles } from '../../../../auth/roles';

const pageSize = 50;

export default function LabourRequestApprovalList (props) {
    const [loading, setLoading] = useState(false);
    const [showActiveApproval, setShowActiveApproval] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const gridRef = useRef();
    const history = useHistory();
    const { instance } = useMsal();
    const user = instance.getActiveAccount();
    const isUserAdmin = user?.idTokenClaims.roles?.filter(r => r === roles.Admin).length > 0;
    // console.log({user});

    const loadingOverlayComponent = useMemo(() => Loader, []);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['reset'],
            debounceMs: 200,
            excelMode: 'mac',
        },
        floatingFilter: true,
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        suppressMenu: true,
    }), []);

    const columnDefs = useMemo(() => [
        {
            field: "action",
            headerName: "Action",
            maxWidth: 100,
            editable: false,
            pinned: true,
            sortable: false,
            filter: false,
            cellRenderer: (cellParams) => {
                const {data} = cellParams;

                return (
                    <div className="text-center">
                        <Button
                            color="default"
                            onClick={() => { history.push(`/lrfapprovaldetail/${data.recruitmentRequestId}?isapproval=true&approvalid=${data.recruitmentApprovalId}`) }}
                            id={`open_${data.recruitmentApprovalId}`}
                            size="xs"
                            style={{width: "100%"}}
                        >
                            <span className="font-size-xs-custom px-2">
                                <i className={`fas fa-${data.status === "Pending Approval" && (user?.username.toLowerCase() === data.approverEmail?.toLowerCase() || data.canApproveRedZone || isUserAdmin) ? "folder-open" : "search"} pt-1 mr-1`} />
                            {
                                data.status === "Pending Approval" && (user?.username.toLowerCase() === data.approverEmail?.toLowerCase() || data.canApproveRedZone || isUserAdmin) ? "Open" : "View"
                            }
                            </span>
                        </Button>
                    </div>
                );
            },
            suppressMenu: true,
        },
        {
            field: "isUrgent",
            headerName: "Urgent Request",
            width: 100,
            valueFormatter: (params) => {
                const { value } = params;
                return value ? "Y" : "N";
            },
        },
        {
            field: "recruitmentRequestNumber",
            headerName: "LRF Number",
        },
        {
            field: "requestDate",
            headerName: "Request Date",
            filter: 'agDateColumnFilter',
            valueFormatter: (params) => {
                const { value } = params;
                return moment(value).format('DD.MM.YYYY');
            },
        },
        {
            field: "client",
            headerName: "Client",
        },
        {
            field: "location",
            headerName: "Location",
        },
        {
            field: "project",
            headerName: "Project",
        },
        {
            field: "recruitmentType",
            headerName: "Type",
        },
        {
            field: "status",
            headerName: "Status",
        },
        {
            field: "actionedDate",
            headerName: "Action Date",
            valueFormatter: (params) => {
                const { value } = params;

                if (!value)
                    return null;

                return moment(value).format('DD.MM.YYYY HH:mm');
            },
        },
        {
            field: "approverName",
            headerName: "Approver Name",
        },
        {
            field: "requestor",
            headerName: "Requestor Name",
        },
        {
            field: "positionName",
            headerName: "Positions",
            cellRenderer: PositionCellRenderer,
            autoHeight: true,
            sortable: false,
        },
    ], [user, isUserAdmin]);

    const defaultSort = useMemo(() => [{ id: "lrfCreationDate", desc: true }, { id: "projectName", desc: false }],
        []
    );

    const getServerSideDatasource = () => {
        return {
            getRows: (params) => {
                const { request: { filterModel, sortModel, startRow } } = params;

                const currentFilters = FiltersConverter(filterModel);
                const currentSortBy = SortsConverter(sortModel);
                const selectedSorts = (currentSortBy.length ? currentSortBy : defaultSort);
                const queryString = qs.stringify({ filters: currentFilters, sortBy: selectedSorts, showAll, showActiveApproval }, { allowDots: true });

                api.get(`/recruitmentrequest/approval/list/${startRow ?? 0}/${pageSize}${queryString ? `?${queryString}` : ""}`)
                    .then((response) => {
                        const responseData = {
                            rowData: response.data.data,
                            rowCount: response.data.total,
                        };
                        
                        params.success(responseData);
                    })
                    .catch(error => {
                        params.fail();
                    });
            },
        };
    };
    
    const onGridReady = useCallback((params) => {
        const datasource = getServerSideDatasource();
        params.api.setServerSideDatasource(datasource);
    }, []);

    useEffect(() => {
        if (!gridRef?.current?.api)
            return;
        
        const datasource = getServerSideDatasource();
        gridRef.current.api.setServerSideDatasource(datasource);
    }, [showAll, showActiveApproval]);

    const handleExportToExcel = useCallback(() => {
        setLoading(true);
        const queryString = qs.stringify({ showAll, showActiveApproval }, { allowDots: true });
        const url = `recruitmentrequest/approval/export${queryString ? `?${queryString}` : ""}`;

        api.get(url, { responseType: 'blob' })
            .then(blob => {
                fileDownload(blob.data, "LRF Approval.xlsx");
            })
            .catch(error => {
                console.error({ error });
            })
            .finally(() => setLoading(false))
            ;
    }, [showAll, showActiveApproval]);

    return (
        <section className="main">
            {loading && <Loader />}
            <Container fluid className="mt-2">
                <Row>
                    <Col sm={6} className="d-flex flex-wrap justify-content-start align-items-center">
                        <TooltipButton
                            id="export-excel"
                            title="Export to Excel"
                            className="btn-icon ml-2 mb-2"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => handleExportToExcel()}
                        >
                            <i className="fas fa-file-excel pt-1 mr-2"></i>Export to Excel
                        </TooltipButton>
                    </Col>
                    <Col sm={6} className="d-flex flex-wrap justify-content-end align-items-center">
                        <IndeterminateCheckbox
                            id="showActiveApproval"
                            type="checkbox"
                            labelClassName="job-order-control mr-3"
                            onChange={() => {
                                setShowActiveApproval(!showActiveApproval);
                            }}
                            checked={showActiveApproval}
                            customTitle="Show All Active Approval"
                        />
                        <IndeterminateCheckbox
                            id="showAll"
                            type="checkbox"
                            labelClassName="job-order-control mr-3"
                            onChange={() => {
                                setShowAll(!showAll);
                            }}
                            checked={showAll}
                            customTitle="Show All Approval History"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <div className="ag-theme-alpine ag-grid" style={{ width: '100%', height: '77vh' }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                loadingOverlayComponent={loadingOverlayComponent}
                                suppressColumnVirtualisation={false}
                                suppressRowVirtualisation={false}
                                debounceVerticalScrollbar={false}
                                rowBuffer={20}
                                headerHeight={30}
                                groupHeaderHeight={30}
                                rowHeight={30}
                                floatingFiltersHeight={30}
                                // readOnlyEdit={true}
                                // onRowDataUpdated={() => resizeColumns()}
                                suppressDragLeaveHidesColumns={true}
                                suppressColumnMoveAnimation={true}
                                animateRows={false}
                                // onCellEditRequest={onCellEditRequest}
                                // excelStyles={excelStyles()}
                                pagination={true}
                                paginationPageSize={pageSize}
                                cacheBlockSize={pageSize}
                                rowModelType={'serverSide'}
                                serverSideInfiniteScroll={true}
                                onGridReady={onGridReady}
                                // sortingOrder={agGridSort}
                                rowClass={'ag-row-normal'}
                                // getRowClass={getRowClass}
                                suppressContextMenu={true}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
