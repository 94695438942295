import React, { useMemo, useCallback, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button, Col, Container, FormGroup, Input, Label, Modal, Row, Spinner } from 'reactstrap';
import { EditorState, ContentState, convertToRaw, SelectionState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { useTable, useFlexLayout, useFilters, useSortBy } from 'react-table';
import jsonFormData from "json-form-data";
import { DatePicker } from '../../../../date-pickers';
import Loader from '../../../../loaders';
import api from "../../../../../services/api";
import fileDownload from "js-file-download";
import moment from 'moment';
import { FileDropzone, DropzoneList } from '../../../../FileDropzone';
import { DefaultColumnFilter, DateColumnFilter } from '../../../../react-table/filters';
import { TooltipButton } from '../../../../inputs';
import Grid from '../../../../Grid';
import { currencyFormatterAUD, formatDate, momentToDateOnly } from '../../../../../utils';
//import VacancyPreview from './VacancyPreview';
import { FormatFileSize } from '../../../../../helpers/StringHelper'


function MilestoneEditor(props) {

    // Props
    const { onClose, onSave, origin, refresh, data } = props;


    // Regular variables
    const [loading, setLoading] = useState(false);
    const [itemInEdit, setItemInEdit] = useState({ ...origin });
    const [showRemovalConfirmation, setShowRemovalConfirmation] = useState(false);
    const [saved, setSaved] = useState(origin?.publishStartDate ? true : false);
    const [showExternalLink, setShowExternalLink] = useState(false);
    const [showVacancyPreview, setShowVacancyPreview] = useState(false);
    const [saveCount, setSaveCount] = useState(0);
    const [documentData, setDocumentData] = useState(null);
    const [documentList, setDocumentList] = useState([]);
    const [downloadingDocument, setDownloadingDocument] = useState(null);
    const [validationMessage, setValidationMessage] = useState(null);
    const [confirmation, setConfirmation] = useState(null);

    const [selectedEmail, setSelectedEmail] = React.useState(null);

    const skipPageResetRef = React.useRef();
    const [lookups, setLookups] = React.useState({});
    const [filters, setFilters] = React.useState([]);
    const [showPleaseSaveVacancyPreview, setShowPleaseSaveVacancyPreview] = useState(false);
    const [fileConfig, setFileConfig] = useState(null);

    // Editor variables
    const [firstLoadEditor, setFirstLoadEditor] = React.useState(true);
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setItemInEdit({ ...itemInEdit, vacancyDescription: draftToHtml(convertToRaw(editorState.getCurrentContent())) });
        //setSelectedEmail({ ...selectedEmail, lookupValue: draftToHtml(convertToRaw(editorState.getCurrentContent())) });
    }

    // Validate origin
    useEffect(() => {
        //if (origin == null) {
        //    setValidationMessage('Something went wrong. No item to edit.');
        //    return;
        //}
    }, [origin]);

    const taskList = useMemo(() => {
        var taskList = !data ? [] : data.filter(f => f.jobOrderCandidateId == itemInEdit?.jobOrderCandidateId);
        return taskList;
    }, [data, itemInEdit?.jobOrderCandidateId])


    // Auto load vacancy description editor content
    useEffect(() => {
        if (!firstLoadEditor || itemInEdit?.vacancyDescription == null) {
            return;
        }

        // Use a regular expression to match and remove the font-size and font-family styles
        var newVacancyDescription = itemInEdit?.vacancyDescription;
        newVacancyDescription = newVacancyDescription.replace(/font-size: [^;]+;/g, '');
        newVacancyDescription = newVacancyDescription.replace(/font-family: [^;]+;/g, '');


        const contentBlock = htmlToDraft(newVacancyDescription);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const contentEditorState = EditorState.createWithContent(contentState);

            setEditorState(contentEditorState);
            setFirstLoadEditor(false);
        }
    }, [firstLoadEditor, origin, itemInEdit])

    // Refs
    const handleDateChanged = useCallback((name, value) => {
        // console.log({name, value});
        setItemInEdit({
            ...itemInEdit,
            [name]: value ? momentToDateOnly(value) : null
        });
    }, [itemInEdit]);


    useEffect(() => {
        setLoading(true);
        const apiCalls = [
            api.get(`/lookup/fileconfig`),
        ];

        Promise.all(apiCalls)
            .then((responses) => {
                const fileconfig = { ...responses[0].data };
                setFileConfig(fileconfig);
            })
            .catch((error) => {
                console.log({ error });
            })
            .finally(() => setLoading(false))
            ;
    }, []);

    // Handle save
    const handleSave = useCallback((close, completed = false, assigntoroster = false) => {
        setLoading(true);

        try {

            // Initiate form data
            var item = {
                ...itemInEdit
            };
            console.log('handleSave', 'item', item);
            var form = jsonFormData(item, {
                initialFormData: new FormData(),
                showLeafArrayIndexes: true,
                includeNullValues: false,
                mapping: function (value) {
                    // if (typeof value === 'boolean') {
                    //     return +value ? '1': '0';
                    // }
                    return value;
                }
            });

            // Handle file upload
            const formData = new FormData();
            form.forEach((value, key, parent) => {

                if (value instanceof File || value instanceof Blob) {
                    formData.append(key.replace(/(\[)([a-zA-Z]+)(\])/, ".$2").replace(/\[\d+\]$/, ""), value, value.name)
                }
                else {
                    formData.append(key.replace(/(\[)([a-zA-Z]+)(\])/, ".$2"), value)
                }
            });

            let url = `${process.env.REACT_APP_WEB_API_BASE_URI}/jobordercandidatemilestone/setmilestone/${completed ? 1:0}/${assigntoroster ? 1:0}`;
            let method = "POST";

            const save = api({ url, method, data: formData })

            save
                .then((response) => {
                    setLoading(false);
                    setSaveCount(saveCount + 1);
                    setSaved(true);

                    if (close)
                        onSave();

                    //else
                    //    refresh();
                })
                .catch((error) => {
                    console.log({ error });
                    setLoading(false);
                });

        }
        catch (error) {
            setLoading(false);
            console.error(error, error.message);


            return;
        }

        //api.post(`/joborder/setvacancy`, JSON.stringify({ ...itemInEdit }))
        //    .then(() => {
        //        setLoading(false);
        //        setSaveCount(saveCount + 1);
        //        setSaved(true);

        //        if (close)
        //            onSave();
        //        //else
        //        //    refresh();
        //    })
        //    .catch((error) => {
        //        console.log({ error });
        //        setLoading(false);
        //    })
        //    ;
    }, [itemInEdit, onSave, documentList]);


    const handleOnConfirmed = useCallback(() => {
        if (!confirmation)
            return;

        //if (confirmation.type === "Set to Filled") {
        //    handleSetToFilled(() => setConfirmation(null));
        //    return;
        //}

        if (confirmation.type === "Talent Placement") {
            setConfirmation(null);
            handleSave(true, true, true);
            return;
        }
    }, [confirmation]);

    useEffect(() => {
        console.log('taskList', taskList);
    }, [taskList]);

    const onSaveClick = useCallback((close = true) => {
        if (!itemInEdit?.submitDate) {
            setValidationMessage("Please enter Submit Date");
            return;
        }
        //if (!itemInEdit?.note) {
        //    setValidationMessage("Please enter Milestone Note");
        //    return;
        //}

        var lastItem = !taskList?.length ? null : taskList[taskList.length - 1];
        console.log('lastItem', lastItem);

        if (itemInEdit.rosterId && lastItem.milestoneName == itemInEdit.milestoneName) {
            setConfirmation({
                type: "Talent Placement",
                title: "Talent Placement",
                message: `Do you want to automatically assign the talent to Dispatch ${itemInEdit.dispatchUnitName}?`
            });
            return;
        }

        handleSave(close);
    }, [itemInEdit, handleSave]);

    const onCloseClick = useCallback(() => {
        if (saveCount) {
            onSave();
            return;
        }
        onClose();
    }, [onClose, saveCount, onSave]);

    const onDeleteFile = useCallback((original) => {
        setLoading(true);
        const index = documentList.findIndex(val => {
            if (original.jobOrderVacancyAttachmentId)
                return val.jobOrderVacancyAttachmentId === original.jobOrderVacancyAttachmentId;

            return val.index === original.index;
        });
        //console.log("Delete: ", { documentData: original, itemInEdit, index, candidateDocuments: documentList });

        if (index < 0) {
            setLoading(false);
            return;
        }

        const documents = [...documentList];
        documents.splice(index, 1);
        setDocumentList([...documents]);
        setLoading(false);
    }, [documentList, setDocumentList, setLoading])


    return (
        <Modal
            isOpen={true}
            className={'modal-xl'}
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Milestone Editor
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onCloseClick}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                {/* <Container> */}
                {(loading) && <Loader />}
                <Row>
                    <Col lg={12} className="pl-2">
                        <div className="container-group pt-3 px-3 mb-3">
                            <Row>
                                <Col sm={3} className="mb-3">
                                    <div>Job Order Id</div>
                                    <div className="font-weight-bold">{itemInEdit?.jobOrderId ?? 'N/A'}</div>
                                </Col>
                                <Col sm={3} className="mb-3">
                                    <div>Created Date</div>
                                    <div className="font-weight-bold">{itemInEdit.createdDate ? moment(itemInEdit.createdDate).format("DD.MM.YYYY") : "N/A"}</div>
                                </Col>
                                <Col sm={3} className="mb-3">
                                    <div>Expected Start Date</div>
                                    <div className="font-weight-bold">{itemInEdit.expectedStartDate ? moment(itemInEdit.expectedStartDate).format("DD.MM.YYYY") : "N/A"}</div>
                                </Col>
                                <Col sm={3} className="mb-3">
                                    <div>LRF</div>
                                    <div className="font-weight-bold">{!itemInEdit?.lrfNumber ? <>N/A</> : <a className="text-info" href={`/lrf/${itemInEdit?.lrfId}`} target='_blank'>{itemInEdit?.lrfNumber}</a>}</div>
                                </Col>
                                <Col sm={3} className="mb-3">
                                    <div>Recruitment Person</div>
                                    <div className="font-weight-bold">{itemInEdit.recruitmentPerson ?? 'N/A'}</div>
                                </Col>
                                <Col sm={3} className="mb-3">
                                    <div>Task Name</div>
                                    <div className="font-weight-bold">{itemInEdit.milestoneName ?? 'N/A'}</div>
                                </Col>
                                <Col sm={3} className="mb-3">
                                    <div>Candidate Name</div>
                                    <div className="font-weight-bold">{!itemInEdit.firstName ? 'N/A' : `${itemInEdit.firstName} ${itemInEdit.surname}`}</div>
                                </Col>
                                <Col sm={3} className="mb-3">
                                    <div>Roster Id</div>
                                    <div className="font-weight-bold">{itemInEdit?.rosterId ?? 'N/A'}</div>
                                </Col>
                            </Row>
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col lg={3} xs={12}>
                        <FormGroup>
                            <Label>
                                Submit Date
                                <span className="text-danger">*</span>
                            </Label>
                            <DatePicker
                                name="submitDate"
                                id="submitDate"
                                type="text"
                                required
                                value={itemInEdit?.submitDate ? formatDate(itemInEdit.submitDate) : null}
                                onChange={(value) => {
                                    handleDateChanged("submitDate", value);
                                }}
                                closeOnSelect
                            />
                        </FormGroup>
                    </Col>

                    <Col xs={12}>
                        <FormGroup>
                            <Label>
                                Milestone Note
                            </Label>
                            <Input
                                type="textarea"
                                value={itemInEdit?.note ?? ""}
                                onChange={(e) => {
                                    //console.log('onChange', {e, currentTarget: e.currentTarget, nativeEvent: e.nativeEvent, target: e.target})
                                    setItemInEdit({ ...itemInEdit, note: e.target.value });
                                    //setCursorPosition(e.target.selectionStart);
                                }}
                                onClick={(e) => {
                                    //console.log('onClick', {e, currentTarget: e.currentTarget, nativeEvent: e.nativeEvent, target: e.target})
                                    //setCursorPosition(e.target.selectionStart)
                                }}
                                onKeyUp={(e) => {
                                    //console.log('onKeyDown', {e, currentTarget: e.currentTarget, nativeEvent: e.nativeEvent, target: e.target, code: e.nativeEvent.keyCode})
                                    //if (e.nativeEvent.keyCode >= 37 && e.nativeEvent.keyCode <= 40) {
                                    //    setCursorPosition(e.target.selectionStart)
                                    //}
                                }}
                                rows={10}
                            // disabled={!editorEnabled}
                            />
                        </FormGroup>
                    </Col>

                </Row>
                {/* </Container> */}
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={onCloseClick}
                    type="button"
                >
                    Close
                </Button>
                {/*<Button
                    color="info"
                    onClick={() => onSaveClick(false)}
                    type="button"
                    disabled={(!itemInEdit?.submitDate || !itemInEdit)}
                >
                    Save
                </Button>*/}
                <Button
                    color="info"
                    onClick={() => onSaveClick()}
                    type="button"
                //disabled={(!itemInEdit?.publishStartDate && itemInEdit?.publishEndDate) || (!itemInEdit?.publishStartDate && itemInEdit?.publishEndDate)}
                >
                    Save & Close
                </Button>
            </div>


            {
                showPleaseSaveVacancyPreview &&
                <SweetAlert
                    warning
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    title={<h4 className="mb-0">Vacancy Preview</h4>}
                    onConfirm={() => setShowPleaseSaveVacancyPreview(false)} // need to add loading indicator on confirm
                    onCancel={() => setShowPleaseSaveVacancyPreview(false)}
                    focusCancelBtn
                >
                    <p>Please click Save button before using the preview.</p>
                </SweetAlert>
            }
            {
                validationMessage &&
                <SweetAlert
                    warning
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    title={<h4 className="mb-0">Milestone Editor</h4>}
                    onConfirm={() => setValidationMessage(null)} // need to add loading indicator on confirm
                    onCancel={() => setValidationMessage(null)}
                    focusCancelBtn
                >
                    <p>{validationMessage}</p>
                </SweetAlert>
            }
            {
                confirmation &&
                    <SweetAlert
                        title={confirmation.title}
                        warning
                        showCancel
                        cancelBtnText="No"
                        confirmBtnText="Yes"
                        confirmBtnBsStyle="danger"
                        onConfirm={() => handleOnConfirmed()}
                        onCancel={() => {
                            if (confirmation.type === "Set to Filled") {
                                setConfirmation(null);
                                return;
                            }

                            if (confirmation.type === "Talent Placement") {
                                setLoading(true);
                                handleSave(true, true, false);
                                setConfirmation(null);
                                return;
                            }

                            setConfirmation(null);
                        }}
                    >
                        {confirmation.message}
                    </SweetAlert>
            }
        </Modal>
    );
};

export default MilestoneEditor;
