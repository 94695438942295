import React from 'react';
import api from "../../../services/api";
import ContractTermEditor from "./Editor";
import { DefaultColumnFilter } from "../../react-table/filters";
import { useTable, useFlexLayout } from 'react-table';
import Loader from "../../loaders";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import Grid from "../../Grid";

export default function Matrix(props) {
    const {
        addMode
    } = props;

    const [ loading, setLoading ] = React.useState(false);
    const [ itemInEdit, setItemInEdit ] = React.useState({});
    const [ matrixData, setMatrixData ] = React.useState([]);
    const [ matrixCols, setMatrixCols ] = React.useState([]);
    const [ selectedProject, setSelectedProject ] = React.useState(null);
    const history = useHistory();
    const apiurl = "contractterm";
    
    function getTextWidth(text, font) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
      
        context.font = font || getComputedStyle(document.body).font;
      
        return context.measureText(text).width;
    }
    
    const loadData = () => {
        setItemInEdit(null);
        setMatrixData([]);
        setMatrixCols([]);

        setLoading(true);
        api.get(`/${apiurl}/matrix`)
            .then(({data}) => {
                setMatrixData(data.rows);
                setMatrixCols(data.cols);
            })
            .catch((error) => {
                console.error("error: ", error);
            })
            .finally(() => setLoading(false));
    };

    React.useEffect(() => {
        if(!addMode)
            loadData();
    }, [addMode]);

    React.useEffect(() => {
         loadData();
    }, []);

    React.useEffect(() => {
        if(selectedProject)
            history.push("/projectterm", {project: selectedProject});
    }, [ selectedProject ]);

    const columns = React.useMemo(() => {
        return matrixCols.map((grp, grpIndex) => {
            return {
                Header: grp.name,
                width: grp.id === -1 || grp.id === 0 ? 200 : 100,
                id: grpIndex,
                Cell: ({row: {original}}) => {
                    let value = grp.id === -1 ? original.item1 : (grp.id === 0 ? original.item2 : "-");
                    const projects = original.item3.projects.filter(prj => prj?.projectId === grp.project?.projectId);
                    
                    if (projects.length > 0 && grp.id > 0)
                        value = "v";

                    return (
                        <div
                            className={`cursor-pointer ${grp.id > 0 ? "text-center" : ""}`}
                            onClick={() => grp.id <= 0 ? setItemInEdit(original.item3.term) : setSelectedProject(grp.project)}
                        >
                            {value}
                        </div>
                    );
                }
            }
        }
    )}, [ matrixCols ]);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    
    } = useTable(
        {
            columns,
            data: matrixData,
            defaultColumn
        },
        useFlexLayout
    );

    return (
        <>
            <Grid
                rows={rows}
                tableProps={getTableProps()}
                headerGroups={headerGroups}
                tableBodyProps={getTableBodyProps()}
                prepareRow={prepareRow}
                needHover={true}
            />
            {loading && <Loader />}
            {
                itemInEdit ?
                <ContractTermEditor
                    title="Contract Term"
                    item={itemInEdit}
                    onClose={() => setItemInEdit(null)}
                    onSaved={() => loadData()}
                />
                :
                null
            }
        </>
    )

}