import React from 'react';
import api from "../../../services/api";
import {
   Container, Row, Col, CardTitle, Card, CardBody, ButtonGroup
} from "reactstrap";
import { TooltipButton } from '../../inputs';
import List from "./List";
import Matrix from "./Matrix";
import ContractTermEditor from "./Editor";
import Import from "../../Import";

const ViewMode = ["list", "matrix"];

export default function View(props) {
    const {
        location
    } = props;

    const selectedViewMode = location?.state?.viewMode;

    const [ addMode, setAddMode ] = React.useState(false);
    const [ importMode, setImportMode ] = React.useState(false);
    const [ viewMode, setViewMode ] = React.useState(selectedViewMode ?? ViewMode[0]);

    React.useEffect(() => {
        setAddMode(importMode);
    }, [importMode]);

    return (
        <section className="main">
            <Container>
                <Row>
                    <Col>
                        <Card className="no-transition">
                            <CardBody>
                                <CardTitle>
                                    <h4 className="text-center">Contract Terms</h4>
                                </CardTitle>
                                <Col sm={12}>
                                    <TooltipButton                        
                                        id="addnew"
                                        title="Add New"
                                        className="btn-icon ml-2 mb-2"
                                        color="default"
                                        size="sm"
                                        type="button"
                                        onClick={() => setAddMode(true)}
                                    >
                                        <i className="fas fa-plus pt-1"></i>
                                    </TooltipButton>
                                    <TooltipButton                        
                                        id="import"
                                        title="Import"
                                        className="btn-icon ml-2 mb-2"
                                        color="default"
                                        size="sm"
                                        type="button"
                                        onClick={() => setImportMode(true)}
                                    >
                                        <i className="fas fa-file-upload pt-1"></i> Import Terms
                                    </TooltipButton>
                                    <ButtonGroup size="sm" style={{float:"right"}}>
                                        <TooltipButton
                                            id="listview"
                                            active={viewMode === ViewMode[0]}
                                            title="Tabular View"
                                            className="btn-icon mb-2"
                                            name="viewMode"
                                            value={ViewMode[0]}
                                            onClick={(e) => {
                                                setViewMode(ViewMode[0]);
                                            }}
                                        >
                                            <i className="fas fa-th-list pt-1"></i>
                                        </TooltipButton>
                                        <TooltipButton
                                            id="matrixview"
                                            active={viewMode === ViewMode[1]}
                                            title="Matrix View"
                                            className="btn-icon mb-2"
                                            name="viewMode"
                                            value={ViewMode[1]}
                                            onClick={(e) => {
                                                setViewMode(ViewMode[1]);
                                            }}
                                        >
                                            <i className="fas fa-table pt-1"></i>
                                        </TooltipButton>
                                    </ButtonGroup>
                                </Col>
                                {
                                    viewMode === ViewMode[0] &&
                                    <List
                                        addMode={addMode}
                                    />
                                }
                                {
                                    viewMode === ViewMode[1] &&
                                    <Matrix
                                        addMode={addMode}
                                    />
                                }
                                {
                                    addMode && !importMode ?
                                    <ContractTermEditor
                                        title="Add Contract Term"
                                        item={null} 
                                        onClose={() => setAddMode(false)}
                                        onSaved={() => setAddMode(false)}
                                    />
                                    :
                                    (
                                        importMode ?
                                        <Import
                                            title="Import Contract Terms"
                                            apiurl="contractterm"
                                            item={null} 
                                            onClose={() => setImportMode(false)}
                                            onSaved={() => setImportMode(false)}
                                        />
                                        : null
                                    )
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    )

}