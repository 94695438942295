import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Container, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import Loader from "../../../../../loaders";
import { DropdownBox } from "../../../../../dropdowns";
import api from "../../../../../../services/api";
import { AgGridReact } from "ag-grid-react";
import TalentEditor from '../../../../Candidates/Editor';
import moment from "moment";
import {
    ListView,
    ListViewHeader,
} from "@progress/kendo-react-listview";
import { IndeterminateCheckbox } from "../../../../../react-table/controls";

const PositionHeader = (props) => {
    const {checked, onChecked} = props;

    return (
        <ListViewHeader className="pl-2 pb-2 pt-2 d-flex flex-wrap justify-content-start align-items-start font-875">
            <IndeterminateCheckbox
                id="check_all"
                type="checkbox"
                onChange={() => {
                    onChecked(!checked);
                }}
                checked={checked}
                customTitle=""
            />
        </ListViewHeader>
    );
  };

const PositionRenderer = (props) => {
    const {dataItem, onChecked} = props;
    return (
        <Row className="k-listview-item p-0 m-0 p-2 border-bottom d-flex flex-wrap justify-content-start align-items-start font-875">
            <Col className="col-1 m-0 p-0">
                <IndeterminateCheckbox
                    id={`check_${dataItem?.hrispositionMasterName ?? 0}`}
                    type="checkbox"
                    onChange={() => {
                        onChecked(dataItem, !dataItem.selected);
                    }}
                    checked={dataItem.selected}
                    customTitle=""
                />
            </Col>
            <Col className="col-11 m-0 p-0">
            {
                dataItem?.hrispositionMasterName
            }
            </Col>
        </Row>
    );
};

export default function SearchCandidateDialog(props) {
    const {
        dispatchUnitId,
        onClose,
    } = props;

    const [loading, setLoading] = useState(false);
    const [lookups, setLookups] = useState(null);
    const [messageMasterList, setMessageMasterList] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [candidates, setCandidates] = useState([]);
    const [positions, setPositions] = useState([]);
    const [allPositionSelected, setAllPositionSelected] = useState(true);
    const [candidateInEdit, setCandidateInEdit] = useState(null);
    const [viewData, setViewData] = useState(null);
    const [selectedCandidates, setSelectedCandidates] = useState([]);
    const gridRef = useRef();
    const loadingOverlayComponent = useMemo(() => Loader, []);

    useEffect(() => {
        if (!dispatchUnitId)
            return;

        setLoading(true);

        const apiCalls = [
            api.get(`messagesmaster/list-for-user`),
            api.get(`candidate/roster/search/position?dispatchUnitId=${dispatchUnitId}`),
            api.get(`/lookup/skillposition`),
            api.get(`/lookup/candidatetype`),
            api.get(`/lookup/salutation`),
            api.get(`/lookup/preference`),
            api.get(`/lookup/industry`),
            api.get(`/lookup/gender`),
            api.get(`/lookup/maritalStatus`),
            api.get(`/lookup/candidateStatus`),
            api.get(`/lookup/candidateFlag`),
            api.get(`/lookup/residencyStatus`),
            api.get(`/lookup/visatype`),
            api.get(`/lookup/communicationtype`),
            api.get(`/lookup/country`),
            api.get(`/lookup/state`),
            api.get(`/lookup/relationshiptype`),
            api.get(`/lookup/candidateplatformorigin`),
            api.get(`/lookup/messagesmasters`),
            api.get(`/emailtemplate`),
            api.get(`/lookup/recruitmentpeople`),
            api.get(`/lookup/candidatediscipline`),
            api.get(`/lookup/company`),
            api.get(`/lookup/identifier`),
            api.get(`/lookup/candidateevent`),
            api.get(`/lookup/rosterflighttype`),
            api.get(`/lookup/flightstatus`),
            api.get(`/lookup/accommodationstatus`),
            api.get(`/lookup/RosterApprovalStatus`),
            api.get(`/lookup/WorkSequence`),
            api.get(`/lookup/agency`),
            api.get(`/contractingcompany/all`),
            api.get(`/lookup/candidatecommenttype`),
            api.get(`/lookup/candidateeventstatus`),
        ];

        Promise.all(apiCalls)
            .then((responses) => {
                const currentMessageMasterList = [...responses[0].data];
                setMessageMasterList(currentMessageMasterList);
                const {candidates: currentCandidates, startDate, endDate, dispatchUnit, positions: currentPositions} = responses[1].data;
                setCandidates([...currentCandidates.map(c => ({...c, visible: true}))]);
                setPositions([...currentPositions.map(p => ({...p, selected: true}))]);
                setViewData({
                    startDate,
                    endDate,
                    dispatchUnit,
                });
                setSelectedMessage(currentMessageMasterList.filter(m => m.lookupType && m.lookupType.toLowerCase() === "empty slot notification")[0] ?? null);

                setLookups({
                    skillPositions: responses[2].data,
                    candidateTypes: responses[3].data,
                    salutations: responses[4].data,
                    preferences: responses[5].data,
                    industries: responses[6].data,
                    genders: responses[7].data,
                    maritalStatuses: responses[8].data,
                    candidateStatuses: responses[9].data,
                    candidateFlags: responses[10].data,
                    residencyStatuses: responses[11].data,
                    visaTypes: responses[12].data,
                    communicationTypes: responses[13].data,
                    countries: responses[14].data,
                    states: responses[15].data,
                    relationshipTypes: responses[16].data,
                    candidatePlatformOrigin: responses[17].data,
                    messagesMaster: responses[18].data,
                    emailTemplate: responses[19].data.data,
                    recruitmentPeople: responses[20].data,
                    candidateDisciplines: responses[21].data,
                    companies: responses[22].data,
                    identifiers: responses[23].data,
                    events: responses[24].data,
                    rosterFlightTypes: responses[25].data,
                    rosterFlightStatuses: responses[26].data,
                    rosterAccommodationStatuses: responses[27].data,
                    rosterApprovalStatus: responses[28].data,
                    workSequence: responses[29].data,
                    agencies: responses[30].data,
                    contractingCompanies: responses[31].data,
                    candidateCommentTypes: responses[32].data,
                    eventStatus: responses[33].data,
                });
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [dispatchUnitId]);

    const defaultColDef = useMemo(() => ({
        sortable: false,
        cellClass: 'roster-cell',
        autoHeight: false,
        resizable: true,
        filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200
        },
        suppressPaste: true,
        suppressFillHandle: true,
        editable: false,
        suppressMenu: true,
    }), []);

    const columnDefs = useMemo(() => {
        return [
            {
                headerName: "",
                width: 30,
                filter: false,
                editable: false,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                showDisabledCheckboxes: true,
            },
            {
                headerName: "No.",
                width: 30,
                filter: false,
                editable: false,
                valueGetter: (args) => args.node.rowIndex + 1,
            },
            {
                field: "candidateName",
                headerName: "Employee Name",
                width: 150,
                sortable: true,
                filter: 'agTextColumnFilter',
            },
            {
                field: "info",
                headerName: "Info",
                width: 40,
                onCellClicked: (args) => {
                    setCandidateInEdit({ candidateId: args.data.candidateId });
                },
                valueFormatter: () => '',
                cellClass: () => "roster-cell cursor-pointer link fas fa-info-circle text-center",
                filter: false,
            },
            {
                field: "latestAllocationPosition",
                headerName: "Last Allocation Position",
                width: 150,
                sortable: true,
                filter: 'agTextColumnFilter',
            },
            {
                field: "latestAllocationStartDate",
                headerName: "Last Allocation Start Date",
                width: 150,
                sortable: true,
                filter: 'agTextColumnFilter',
                valueFormatter: (args) => {
                    return args.value && args.value !== "" ? moment(args.value).format("DD-MM-YYYY") : "";
                },
            },
            {
                field: "latestAllocationEndDate",
                headerName: "Last Allocation End Date",
                width: 150,
                sortable: true,
                filter: 'agTextColumnFilter',
                valueFormatter: (args) => {
                    return args.value && args.value !== "" ? moment(args.value).format("DD-MM-YYYY") : "";
                },
            },
            {
                field: "latestProject",
                headerName: "Last Roster Project",
                width: 150,
                sortable: true,
                filter: 'agTextColumnFilter',
            },
            {
                field: "latestDispatch",
                headerName: "Last Dispatch",
                width: 150,
                sortable: true,
                filter: 'agTextColumnFilter',
            },
            {
                field: "latestRosterPosition",
                headerName: "Last Roster Position",
                width: 150,
                sortable: true,
                filter: 'agTextColumnFilter',
            },
            {
                field: "latestRosterWorkingDate",
                headerName: "Last Roster Working Date",
                width: 150,
                sortable: true,
                filter: 'agTextColumnFilter',
                valueFormatter: (args) => {
                    return args.value && args.value !== "" ? moment(args.value).format("DD-MM-YYYY") : "";
                },
            },
            {
                field: "overlapStatus",
                headerName: "Overlap Status",
                width: 80,
                valueFormatter: () => '',
                sortable: true,
                filter: false,
                cellClass: (params) => {
                    return `roster-cell text-center fas ${params.value ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'}`;
                },
            },
        ]
    }, []);

    const onCandidateGridReady = useCallback(({api}) => {
        api.forEachNode((rowNode) => {
            if (selectedCandidates.filter(c => c.candidateId === rowNode.data.candidateId).length > 0)
                rowNode.setSelected(true);
        });

        api.hideOverlay();
    }, [selectedCandidates]);

    const onPositionSelectAll = useCallback((checked) => {
        if (!checked)
            setSelectedCandidates([]);

        setPositions([...positions.map(p => ({...p, selected: checked}))]);
        setCandidates([...candidates.map(c => ({...c, visible: checked}))]);
        setAllPositionSelected(checked);
    }, [candidates, selectedCandidates, positions]);

    const onPositionSelected = useCallback((data, checked) => {
        if (!checked)
        {
            const newSelectedCandidates = JSON.parse(JSON.stringify(selectedCandidates.filter(c => c.hrispositionMasterId !== data.hrispositionMasterId)));
            // console.log({newSelectedCandidates});
            setSelectedCandidates([...newSelectedCandidates]);
        }

        const newPositions = JSON.parse(JSON.stringify(positions));
        newPositions.filter(p => p.hrispositionMasterId === data.hrispositionMasterId).forEach(p => {p.selected = checked});
        const newCandidates = JSON.parse(JSON.stringify(candidates));
        newCandidates.filter(c => c.positions.filter(p => p.hrispositionMasterId === data.hrispositionMasterId).length > 0).forEach(c => c.visible = checked);
        
        // console.log({data, checked, newPositions, newCandidates});
        setPositions([...newPositions]);
        setCandidates([...newCandidates]);
        
        const selectedPositionCount = newPositions.filter(p => p.selected).length;
        setAllPositionSelected(selectedPositionCount === newPositions.length);
    }, [gridRef, candidates, selectedCandidates, positions]);

    const onSelectionChanged = useCallback(() => {
        const selectedRows = gridRef.current.api.getSelectedRows();

        if (!selectedRows?.length)
        {
            if (selectedCandidates?.length)
                setSelectedCandidates([]);

            return;
        }

        setSelectedCandidates([...selectedRows]);
    }, [gridRef, selectedCandidates]);

    const handleSendMessage = useCallback(() => {
        setLoading(true);

        api.post(`candidate/roster/emptyslot`, JSON.stringify({
            dispatchUnitId,
            messagesMasterId: selectedMessage?.lookupId,
            message: selectedMessage?.lookupValue,
            candidates: selectedCandidates,
        }))
            .then((response) => {
                setLoading(false);
                onClose();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [dispatchUnitId, selectedMessage, selectedCandidates, onClose]);

    return (
        <>
            <Modal
                isOpen={true}
                className="modal-fullscreen modal-fullscreen-xl"
            >
                <div
                    className="modal-header"
                >
                    <h5
                        className="modal-title"
                    >
                        Notify Available Talents{viewData ? ` - ${viewData.dispatchUnit.dispatchUnitName} (${viewData.startDate ? moment(viewData.startDate).format("DD-MM-YYYY") : "N/A"} to ${viewData.endDate ? moment(viewData.endDate).format("DD-MM-YYYY") : "N/A"})` : ""}
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        onClick={onClose}
                        type="button"
                    >
                        <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                    </button>
                </div>
                <div
                    className="modal-body"
                    style={{height: "70vh", maxHeight: "70vh", overflowY: "auto"}}
                >
                    <Container fluid className="m-0 p-0 p-2 h-100">
                        {loading && <Loader />}
                        <Row className="m-0 p-0 mt-2 h-100">
                            <Col xs={6} className="m-0 p-0 border-right">
                                <Row className="m-0 p-0">
                                    <Col xs={12}>
                                        <FormGroup>
                                            <Label>
                                                Message Template
                                                <span className="text-danger">*</span>
                                            </Label>
                                            <DropdownBox
                                                data={[...messageMasterList.filter(m => m.lookupType && m.lookupType.toLowerCase() === "empty slot notification")] ?? []}
                                                idField="lookupId"
                                                valueField="lookupType"
                                                selectedItem={selectedMessage}
                                                onChange={(item) => {
                                                    // console.log(item);
                                                    setSelectedMessage(item);
                                                    // setTemplateFirstLoad(true);
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12}>
                                        <FormGroup>
                                            <Label>
                                                Message Text
                                                <span className="text-danger">*</span>
                                            </Label>
                                            <Input
                                                type="textarea"
                                                value={selectedMessage?.lookupValue ?? ""}
                                                onChange={(e) => {
                                                    setSelectedMessage({ ...selectedMessage, lookupValue: e.target.value });
                                                }}
                                                rows={10}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12}>
                                        <FormGroup>
                                            <Label>
                                                Position List
                                            </Label>
                                            <ListView
                                                style={{ width: "100%", overflowY: "auto" }}
                                                data={positions}
                                                header={(args) => PositionHeader({...args, checked: allPositionSelected, onChecked: (checked) => onPositionSelectAll(checked)})}
                                                item={(args) => PositionRenderer({...args, onChecked: (data, checked) => onPositionSelected(data, checked)})}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={6} className="m-0 p-0">
                                <div className="ag-theme-alpine px-2" style={{ width: '100%', height: '63vh' }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        rowData={candidates.filter(c => c.visible)}
                                        columnDefs={columnDefs}
                                        defaultColDef={defaultColDef}
                                        suppressColumnVirtualisation={false}
                                        suppressRowVirtualisation={false}
                                        rowBuffer={20}
                                        debounceVerticalScrollbar={false}
                                        allowContextMenuWithControlKey={true}
                                        suppressClipboardPaste={true}
                                        headerHeight={25}
                                        groupHeaderHeight={22}
                                        rowHeight={22}
                                        onGridReady={onCandidateGridReady}
                                        readOnlyEdit={true}
                                        enableRangeSelection={true}
                                        loadingOverlayComponent={loadingOverlayComponent}
                                        tooltipShowDelay={0}
                                        tooltipHideDelay={20000}
                                        suppressDragLeaveHidesColumns={true}
                                        suppressColumnMoveAnimation={true}
                                        copyHeadersToClipboard={false}
                                        enableCellChangeFlash={true}
                                        animateRows={true}
                                        rowSelection="multiple"
                                        suppressRowClickSelection={true}
                                        onSelectionChanged={onSelectionChanged}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="modal-footer d-flex flex-wrap"
                >
                    <Button
                        color="secondary"
                        onClick={onClose}
                        type="button"
                        className="mt-2"
                    >
                        Close
                    </Button>
                    <Button
                        color="info"
                        onClick={() => handleSendMessage()}
                        type="button"
                        disabled={!selectedMessage?.lookupId || !selectedCandidates?.length}
                    >
                        Send Message
                    </Button>
                </div>
            </Modal>
        {
            candidateInEdit ?
            <TalentEditor
                item={candidateInEdit}
                onClose={() => {
                    setCandidateInEdit(null);
                }}
                onSaved={() => {
                    setCandidateInEdit(null);
                }}
                lookups={{
                    ...lookups
                }}
            />
            :
            null
        }
        </>
    );
};
