import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Button, Col, Row } from 'reactstrap';
import api from "../../../../services/api";
import Loader from "../../../loaders";
import qs from "qs";
import { Container } from 'reactstrap';
import Grid from '../../../Grid';
import { useTable, useRowSelect, useFlexLayout, useSortBy } from 'react-table';
import { IndeterminateCheckbox } from '../../../react-table/controls';
import moment from "moment";
import SweetAlert from 'react-bootstrap-sweetalert';
import EditorSMS from '../../Candidates/EditorSMS';
import { DropdownBox } from "../../../dropdowns";
import { Collapse } from "reactstrap"
import { ErrorNotification } from '../../../alerts';
import { TooltipButton } from "../../../inputs";
import _ from "lodash";
import { MakeWords } from '../../../../helpers/StringHelper';

export default function BulkDASConfirmation(props) {
    const {
        project,
        dispatchUnits,
        rosters,
        rosterCandidates,
        selected,
        onClose,
        onSaved,
        confirmationMessage,
        singleSelection,
    } = props;

    const invalidDateMsg = "One or more employees have invalid start/end date";
    const invalidNameMsg = "One or more employees have invalid name or mobile phone number";
    const invalidRosterPos = "Please enter Roster Position for slot no.";
    const errorMessageOpening = "Error occured when processing the request:";
    const errorTitleOnGenerate = "Bulk DAS Confirmation Error";

    const [loading, setLoading] = React.useState(false);
    const [title, setTitle] = useState(null);
    const [failedMessages, setFailedMessages] = useState(null);
    const [currentMessage, setCurrentMessage] = useState(confirmationMessage);
    const [dataIsEmpty, setDataIsEmpty] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [candidates, setCandidates] = useState([]);
    const [selectedCandidates2, setSelectedCandidates2] = useState([]);
    const [selectedSourceObject2, setSelectedSourceObject2] = useState([]);
    const [attributes, setAttributes] = React.useState([]);

    const [data, setData] = useState([]);
    const [selectedStartEndDateList, setSelectedStartEndDateList] = useState([]);
    const [step, setStep] = useState(1);
    const [initialSelectedRows, setInitialSelectedRows] = useState([]);
    const [validationMessage, setValidationMessage] = useState(null);
    const defaultSort = React.useMemo(() => [{ id: "slotNo", desc: false }],
        []
    );

    const [dataSms, setDataSms] = useState([]);
    const [showModalSms, setShowModalSms] = useState(false);

    // Error Notification Variables
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorTitle, setErrorTitle] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);

    const loadData = useCallback(() => {
        try {
            // console.log("{dispatchUnits, rosters, rosterCandidates}", { dispatchUnits, rosters, rosterCandidates });
            const refType = rosterCandidates && rosterCandidates.length > 0 ? "RC" : (rosters && rosters.length > 0 ? "R" : (dispatchUnits && dispatchUnits.length > 0 ? "D" : ""));

            // console.log('refType', refType);

            if (refType === "")
                return;

            const refIds = [];

            if (refType === "RC")
                rosterCandidates.forEach(rc => {
                    refIds.push(rc.rosterCandidateId);
                });

            if (refType === "R")
                rosters.forEach(rc => {
                    refIds.push(rc.rosterId);
                });

            if (refType === "D")
                dispatchUnits.forEach(rc => {
                    refIds.push(rc.dispatchUnitId);
                });

            if (refIds.length === 0)
                return;

            setLoading(true);

            const refIdStr = qs.stringify({ refIds }, { allowDots: true });

            const apiCalls = [
                api.get(`rostercandidateschedule/waiting-schedules-per-candidate?reftype=${refType}&${refIdStr}`)
            ];

            if (refType === "RC")
                apiCalls.push(api.get(`rostercandidate/candidates?${refIdStr}`));

            if (refType === "R")
                apiCalls.push(api.get(`roster/candidates?${refIdStr}`));

            if (refType === "D")
                apiCalls.push(api.get(`dispatchunit/candidates?${refIdStr}`));

            Promise.all(apiCalls)
                .then((response) => {
                    // console.log("response", response);
                    const newData = response[0].data && response[0].data !== "" ? [...response[0].data] : [];
                    setData(newData);
                    setDataIsEmpty(!response[0].data || response[0].data === "");
                    let currentTitle = " - Multiple";

                    // Set selected date by default
                    if (newData) {
                        const selectedList = [];
                        newData.forEach((oneRc, i, a) => {

                            // console.log("oneRc", oneRc);

                            if (oneRc.startEndDateList.length)
                                selectedList.push(oneRc.startEndDateList[0]);

                        });

                        // console.log("selectedList", selectedList);
                        setSelectedStartEndDateList(selectedList);
                    }

                    if (dispatchUnits && dispatchUnits.length) {
                        currentTitle = ` - ${dispatchUnits.length === 1 ? dispatchUnits[0].dispatchUnitName : "Multiple"}`;

                        if (rosters && rosters.length)
                            currentTitle = `${currentTitle} - ${rosters.length === 1 ? rosters[0].rosterName : "Multiple"}`;
                    }

                    setTitle(currentTitle);

                    if (rosterCandidates && rosterCandidates.length) {
                        let currentCandidateTitle = "";

                        rosterCandidates.forEach(x => {
                            currentCandidateTitle = `${currentCandidateTitle}${currentCandidateTitle !== "" ? "," : ""} ${x.candidateName} - ${x.positionName}`;
                        });
                    }

                    if (apiCalls.length > 1) {
                        const newCandidates = [];

                        response[1].data.filter(rc => rc.candidateId).forEach(rc => {
                            if (!newData.length)
                                return;

                            newData.filter(d => d.rosterCandidateId === rc.rosterCandidateId).forEach(d => {
                                newCandidates.push({
                                    ...rc.candidate,
                                    rosterCandidateId: rc.rosterCandidateId,
                                    rosterCandidateScheduleIds: d.rosterCandidateScheduleIds,
                                    currentProjectName: project.projectName,
                                    dispatchUnitId: rc.roster?.dispatchUnitId,
                                    dispatchUnitName: rc.roster?.dispatchUnit?.dispatchUnitName,
                                    rosterId: rc.rosterId,
                                    rosterName: rc.roster?.rosterName,
                                    locationId: rc.roster?.dispatchUnit?.locationId,
                                    locationName: rc.roster?.dispatchUnit?.location?.lookupValue,
                                    startDate: moment(d.startDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
                                    endDate: moment(d.endDate, "YYYY-MM-DD").format("DD-MM-YYYY")
                                })
                            });
                        });

                        setCandidates([...newCandidates]);
                    }

                    const apiCallsMessage = [
                        api.get(`messagesmaster/messageattributes`),
                    ];
                    apiCallsMessage.push(api.get(`projectAttribute/search`));
                    apiCallsMessage.push(api.get(`rosterAttribute/search`));

                    Promise.all(apiCallsMessage)
                        .then((responses) => {
                            // console.log('responses of set attributes', 'responses', responses);
                            setAttributes([...responses[0]?.data.map(d => ({ ...d, hidden: (d.name === "{RosterCandidateSchedule.StartDate}" || d.name === "{RosterCandidateSchedule.EndDate}") })), ...responses[1]?.data?.map(m => ({ name: `{${m.projectAttributeName}}`, category: "Project Attribute", hidden: false })), ...responses[2]?.data.map(m => ({ name: `{${m.rosterAttributeName}}`, category: "Roster Attribute", hidden: false }))]);
                        })
                        .catch((error) => {
                            setErrorMessage(`${errorMessageOpening}${error.response.data}`);
                            console.error(error);
                        })
                        .finally(() => {
                            setLoading(false);
                            setFirstLoad(false);
                        });
                })
                .catch((error) => {
                    if (error.response) {
                        setErrorMessage(`${errorMessageOpening}${error.response.data}`);
                    } else {
                        setErrorMessage(`${errorMessageOpening}An error occurred: ${error}`);
                    }
                    console.error(error);
                    setLoading(false);
                    setFirstLoad(false);
                });
        }
        catch (error) {
            setLoading(false);
            setErrorMessage(`${errorMessageOpening}${error.message}`);
        }

    }, [project, dispatchUnits, rosters, rosterCandidates, attributes]);

    const handleSend = useCallback(() => {
        try {
            setLoading(true);
            const sendData = [...dataSms];
            const apiSendSms = [];

            sendData.forEach((oneData) => {
                try {
                    const selectedSchedules = [...sendData.filter(w => w.rosterCandidateId == oneData.rosterCandidateId)];
                    const selectedDate = selectedStartEndDateList.find(w => w.rosterCandidateId == oneData.rosterCandidateId);
                    const refIds_ = selectedSchedules.map((w) => w.rosterCandidateId);
                    const body = {
                        refType: "RosterCandidate",
                        refIds: refIds_,
                        schedules: selectedSchedules,
                        message: encodeURI(oneData.message.message),
                        messagesMasterId: oneData.message?.lookupId,
                        startDate: selectedDate?.startDate,
                        endDate: selectedDate?.endDate,
                    };
                    apiSendSms.push(api.post(`rostercandidateaccommodation/das-confirm`, body));
                }
                catch (error) {
                    setLoading(false);
                    console.error(error);
                    setErrorMessage(`${errorMessageOpening}${error.message}`);
                }
            });

            Promise.all(apiSendSms)
                .then((responses) => {
                    const fails = responses.filter(r => r.data.failed.length).map(r => r.data.failed);

                    if (!fails.length) {
                        setLoading(false);
                        onSaved();
                        return;
                    }

                    const resultFailedMessages = [];

                    fails.forEach(fail => {
                        fail.forEach(item => resultFailedMessages.push(item));
                    });

                    setFailedMessages([...resultFailedMessages]);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setErrorMessage(`${errorMessageOpening}${error.message}`);
                    setLoading(false);
                });
        }
        catch (error) {
            setErrorMessage(`${errorMessageOpening}${error.message}`);
            console.log(error);
            setLoading(false);
        }
    }, [data, loading, dataSms, selectedStartEndDateList, errorMessageOpening, onSaved]);

    useEffect(() => {
        if (!project)
            return;

        if ((!dispatchUnits || dispatchUnits.length === 0) && (!rosters || rosters.length === 0) && (!rosterCandidates || rosterCandidates.length === 0))
            return;

        loadData();
    }, [project, dispatchUnits, rosters, rosterCandidates]);

    useEffect(() => {
        if (!data || !selectedStartEndDateList?.length)
            return;

        selectedStartEndDateList.forEach((date) => {
            const candidate = data.find(w => w && date && w.rosterCandidateId == date.rosterCandidateId);

            if (!candidate)
                return;

            candidate.rosterCandidateScheduleStartDate = date.startDateFormatted;
            candidate.rosterCandidateScheduleEndDate = date.endDateFormatted;
            candidate.startDate = date.startDate;
            candidate.endDate = date.endDate;
        });
    }, [selectedStartEndDateList]);

    const updateSelectedStartEndDate = (rosterCandidateId, item) => {
        const selectedList = [...selectedStartEndDateList];
        const i = selectedList.findIndex(w => w && w.rosterCandidateId == rosterCandidateId);

        if (i >= 0)
            selectedList.splice(i, 1);

        if (item)
            selectedList.push(item);

        setSelectedStartEndDateList(selectedList);
    }

    const columns = useMemo(() => {
        return [
            {
                id: 'slotNo',
                Header: "Slot",
                width: 50,
                disableSortBy: false,
                Cell: ({ row: { original } }) => {
                    const selectedStartEndDate = selectedStartEndDateList?.filter(w => w)?.find(w => w?.rosterCandidateId == original?.rosterCandidateId);
                    const hasWorkSchedule = original.startEndDateList?.filter(d => d.rosterCandidateId === selectedStartEndDate?.rosterCandidateId && d.startEndHasWorkSchedule).length > 0;
                    const slotNo = `${!hasWorkSchedule ? "*" : ""}${original.slotNo}`;

                    return (
                        <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                            <span className="text-left ml-2">{slotNo}</span>
                        </div>
                    );
                },
            },
            {
                id: 'rosterCandidateId',
                Header: "Roster Candidate ID",
                accessor: (row) => row.rosterCandidateId,
                disableSortBy: false,
            },
            {
                id: 'candidateName',
                Header: "Employee Name",
                accessor: (row) => !row || !row.candidate ? 'N/A' : row.candidate.candidateName,
                disableSortBy: false,
            },
            {
                id: 'mobilePhoneNumber',
                Header: "Mobile Number",
                accessor: (row) => !row || !row.candidate ? 'N/A' : row.candidate.mobilePhoneNumber,
                disableSortBy: false,
            },
            {
                id: 'startDate',
                Header: "Start Date",
                disableSortBy: false,
                accessor: (row) => {
                    // Return N/A if not available
                    if (!row.startEndDateList || !row.startEndDateList.some(w => w) || !selectedStartEndDateList)
                        return 'N/A';

                    // Return value if available
                    const selectedDate = selectedStartEndDateList.filter(w => w).find(w => w?.rosterCandidateId == row?.rosterCandidateId);
                    return !selectedDate ? 'N/A' : selectedDate.startDateFormatted;
                },
                Cell: ({ row }) => {
                    const { original } = row;

                    if (!original.startEndDateList || !original.startEndDateList.some(w => w) || !selectedStartEndDateList)
                        return (<>N/A</>);

                    return (
                        <div>
                            <DropdownBox
                                data={original.startEndDateList ?? []}
                                /*selectedItem={selectedStartEndDateList[original.rosterCandidateId]}*/
                                selectedItem={selectedStartEndDateList.filter(w => true).find(w => w?.rosterCandidateId == original?.rosterCandidateId)}
                                valueField={'startDateFormatted'}
                                idField={'startEndGroupId'}
                                onChange={(item) => {
                                    updateSelectedStartEndDate(original?.rosterCandidateId, item);
                                    // console.log('DropdownBox on change selectedStartEndDateList', 'item', item);
                                }}
                            />
                        </div>
                    );
                },
            },
            {
                id: 'endDate',
                Header: "End Date",
                accessor: (row) => {
                    if (!row.startEndDateList || !row.startEndDateList.some(w => w) || !selectedStartEndDateList)
                        return 'N/A';

                    const selectedDate = selectedStartEndDateList.filter(w => w).find(w => w?.rosterCandidateId == row?.rosterCandidateId);
                    return !selectedDate ? 'N/A' : selectedDate.endDateFormatted;
                },
                disableSortBy: false,
            },
            {
                id: 'workDays',
                Header: "Workdays",
                disableSortBy: false,
                Cell: ({ row: { original } }) => {
                    const selectedStartEndDate = selectedStartEndDateList?.filter(w => w)?.find(w => w?.rosterCandidateId == original?.rosterCandidateId);

                    const workDayText = (!original.startEndDateList || !original.startEndDateList.some(w => w) || !selectedStartEndDate) ? "N/A"
                        :
                        selectedStartEndDate.workDays;

                    const inactiveDateExists = original.startEndDateList.filter(d => d.rosterCandidateId === selectedStartEndDate?.rosterCandidateId && d.inactiveDateExists).length > 0;

                    return (
                        <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                            {
                                <span className={`text-left ml-2${workDayText !== "N/A" && inactiveDateExists ? " text-danger" : ""}`}>
                                    {
                                        workDayText
                                    }
                                    {
                                        workDayText !== "N/A" && inactiveDateExists ? "**" : ""
                                    }
                                </span>
                            }
                        </div>
                    );
                },
            },
            {
                id: 'status',
                Header: "Status",
                accessor: (row) => {
                    const selectedStartEndDate = selectedStartEndDateList?.filter(w => w)?.find(w => w?.rosterCandidateId == row?.rosterCandidateId);
                    // console.log({row, selectedStartEndDate});
                    return selectedStartEndDate?.dasStatus ?? "N/A";
                },
                disableSortBy: false,
            },
        ];
    }, [selectedStartEndDateList]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        flatRows,
        selectedFlatRows,
        toggleAllRowsSelected
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: defaultSort,
                // "selectedRowIds": {},
                "selectedRowIds": initialSelectedRows,
                "hiddenColumns": ['rosterCandidateId']
            },
            // defaultColumn
        },
        useFlexLayout,
        hooks => {
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    width: 30,
                    disableSortBy: true,
                    Header: ({ getToggleAllRowsSelectedProps }) => {
                        return (
                            <div>
                                {
                                    !singleSelection &&
                                    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} id="all" />
                                }
                            </div>
                        )
                    },
                    Cell: ({ row }) => {
                        const { original, values } = row;

                        if (firstLoad)
                            row.isSelected = (selected?.some(s => s.rosterCandidateId === original.rosterCandidateId && s.startEndGroupId === original.startEndGroupId) || row.isSelected);

                        let isValid = true;
                        const invalidMobe = !values.mobe || values.mobe == 'N/A';
                        const invalidDemobe = !values.demobe || values.demobe == 'N/A';
                        const invalidAccommodation = !values.accommodation || values.accommodation == 'N/A';

                        if (invalidMobe && invalidDemobe && invalidAccommodation)
                            isValid = false;

                        return (
                            <div>
                                {
                                    original != null &&
                                    <IndeterminateCheckbox
                                        {...row.getToggleRowSelectedProps()} id={`check_${original.rosterCandidateId}`}
                                        onChange={(e) => {
                                            const selected = row.isSelected;

                                            if (singleSelection)
                                                toggleAllRowsSelected(false);


                                            row.toggleRowSelected(!selected);
                                        }}
                                    />
                                }
                            </div>
                        )
                    },
                },
                ...columns,
            ])
        },
        useSortBy,
        useRowSelect,
    );

    useEffect(() => {
        if (!flatRows || flatRows.length == 0) {
            setValidationMessage(null);
            return;
        }

        console.log('flatRows', flatRows);

        const newErrorMessage = [];

        const invalidHrisPostitionList = flatRows.filter(s => !s.original.hrispositionMasterId);
        const invalidName = flatRows.some(s => !s.values.candidateName || s.values.candidateName == 'N/A');
        const invalidMobilePhoneNumber = flatRows.some(s => !s.values.mobilePhoneNumber || s.values.mobilePhoneNumber == 'N/A');
        if (invalidName || invalidMobilePhoneNumber)
            newErrorMessage.push(`${invalidNameMsg}`);

        const invalidStartDate = flatRows.some(s => !s.values.startDate || s.values.startDate == 'N/A');
        const invalidEndDate = flatRows.some(s => !s.values.endDate || s.values.endDate == 'N/A');
        if (invalidStartDate || invalidEndDate)
            newErrorMessage.push(`${invalidDateMsg}`);

        // Show invalid work day
        if (selectedStartEndDateList.filter(d => !d.startEndHasWorkSchedule).length)
            newErrorMessage.push("*The following start date / end date is not marked as work day.");


        // Show invalid Start / End date
        if (selectedStartEndDateList.filter(d => d.inactiveDateExists).length > 0)
            newErrorMessage.push("**The schedule is overlapping with inactive date(s).");

        // Show invalid HRIS roster position
        if (invalidHrisPostitionList.some(s => s)) {
            var slotNoList = invalidHrisPostitionList.map(m => m.original.slotNo);
            var slotNos = MakeWords(slotNoList);
            var invalidMsg = `${invalidRosterPos} ${slotNos}`;
            newErrorMessage.push(invalidMsg);
        }


        setValidationMessage(newErrorMessage.length > 0 ? newErrorMessage : null);
    }, [flatRows, selectedStartEndDateList]);

    const makeString = (arr) => {
        if (arr.length === 1) return arr[0];
        const firsts = arr.slice(0, arr.length - 1);
        const last = arr[arr.length - 1];
        return firsts.join(', ') + ' and ' + last;
    }

    const generateMessage = useCallback(() => {
        // console.log({selectedFlatRows});
        const invalidDateList = selectedFlatRows.filter(s => {
            const { values } = s;
            const invalidStartDate = !values.startDate || values.startDate == 'N/A';
            const invalidEndDate = !values.endDate || values.endDate == 'N/A';

            if (invalidStartDate || invalidEndDate)
                return true;

            return false;
        });

        const invalidNameOrPhoneList = selectedFlatRows.filter(s => {
            const { values } = s;
            const invalidCandidateName = !values.candidateName || values.candidateName == 'N/A';
            const invalidMobilePhoneNumber = !values.mobilePhoneNumber || values.mobilePhoneNumber == 'N/A';

            if (invalidCandidateName || invalidMobilePhoneNumber)
                return true;

            return false;
        });


        const invalidHrisPostitionList = selectedFlatRows.filter(s => !s.original.hrispositionMasterId);

        // Validate Name and Phone
        if (invalidNameOrPhoneList && invalidNameOrPhoneList.length > 0) {
            const slotNoList = invalidNameOrPhoneList.map((item) => item.original.slotNo);
            const slotNoWords = makeString(slotNoList);
            setErrorTitle(errorTitleOnGenerate);
            setErrorMessage(`${invalidNameMsg}. Please check slot number: ${slotNoWords}.`);
            return;
        }

        // Valdidate Date
        if (invalidDateList && invalidDateList.length > 0) {
            const slotNoList = invalidDateList.map((item) => item.original.slotNo);
            const slotNoWords = makeString(slotNoList);
            setErrorTitle(errorTitleOnGenerate);
            setErrorMessage(`${invalidDateMsg}. Please check slot number: ${slotNoWords}.`);
            return;
        }

        // Validate HRIS Position
        if (invalidHrisPostitionList && invalidHrisPostitionList.length > 0) {
            const slotNoList = invalidHrisPostitionList.map((item) => item.original.slotNo);
            const slotNoWords = makeString(slotNoList);
            setErrorTitle(errorTitleOnGenerate);
            setErrorMessage(`${invalidRosterPos} ${slotNoWords}.`);
            return;
        }

        const tempData = [...data];
        tempData.forEach((oneData) => {
            const oneDataDs = { ...oneData };
            const candidate = { ...oneData.candidate };

            if (!confirmationMessage) {
                tempData.message = null;
                return;
            }

            const messageObj = { ...confirmationMessage };
            let message = `${messageObj?.message}`;

            if (attributes && attributes.length > 0) {
                for (let i = 0; i < attributes.length; i++) {
                    const attribute = attributes[i];

                    if (attributes[i].category === "Message Attribute") {
                        const valueSource = _.camelCase(attribute.valueSource);

                        if (candidate[valueSource])
                            message = message.replace(new RegExp(attribute.name, 'g'), candidate[valueSource]);

                        if (oneDataDs[valueSource])
                            message = message.replace(new RegExp(attribute.name, 'g'), oneDataDs[valueSource]);

                        continue;
                    }

                    if ('value' in attribute && attribute.value)
                        message = message.replace(new RegExp(`${attribute.name}`, 'g'), attribute.value);
                }
            }

            messageObj.message = message;
            oneData.message = messageObj;

        });
        setData(tempData);

        const selectedRcIdList = selectedFlatRows.map((item) => { return item.values.rosterCandidateId; });
        const filteredData = tempData.filter((w) => selectedRcIdList.includes(w.rosterCandidateId));

        setDataSms(filteredData);
        setStep(2);
    }, [step, data, dataSms, selectedFlatRows]);

    const columnsSms = useMemo(() => {
        return [
            {
                id: 'slotNo',
                Header: "Slot",
                width: 25,
                disableSortBy: false,
                Cell: ({ row: { original } }) => {
                    const selectedStartEndDate = selectedStartEndDateList?.filter(w => w)?.find(w => w?.rosterCandidateId == original?.rosterCandidateId);
                    const hasWorkSchedule = original.startEndDateList?.filter(d => d.rosterCandidateId === selectedStartEndDate?.rosterCandidateId && d.startEndHasWorkSchedule).length > 0;
                    const slotNo = `${!hasWorkSchedule ? "*" : ""}${original.slotNo}`;

                    return (
                        <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                            <span className="text-left ml-2">{slotNo}</span>
                        </div>
                    );
                },
            },
            {
                Header: "Employee Name",
                accessor: (row) => !row || !row.candidate ? null : row.candidate.candidateName,
                width: 50,
                disableSortBy: false,
            },
            {
                id: 'mobilePhoneNumber',
                Header: "Mobile Number",
                accessor: (row) => !row || !row.candidate ? 'N/A' : row.candidate.mobilePhoneNumber,
                width: 50,
                disableSortBy: false,
            },
            {
                id: 'workDays',
                Header: "Workdays",
                disableSortBy: false,
                Cell: ({ row: { original } }) => {
                    const selectedStartEndDate = selectedStartEndDateList?.filter(w => w)?.find(w => w?.rosterCandidateId == original?.rosterCandidateId);

                    const workDayText = (!original.startEndDateList || !original.startEndDateList.some(w => w) || !selectedStartEndDate) ? "N/A"
                        :
                        selectedStartEndDate.workDays;

                    const inactiveDateExists = original.startEndDateList.filter(d => d.rosterCandidateId === selectedStartEndDate?.rosterCandidateId && d.inactiveDateExists).length > 0;

                    return (
                        <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                            {
                                <span className={`text-left ml-2${workDayText !== "N/A" && inactiveDateExists ? " text-danger" : ""}`}>
                                    {
                                        workDayText
                                    }
                                    {
                                        workDayText !== "N/A" && inactiveDateExists ? "**" : ""
                                    }
                                </span>
                            }
                        </div>
                    );
                },
                width: 30,
            },
            {
                id: 'status',
                Header: "Status",
                accessor: (row) => {
                    const selectedStartEndDate = selectedStartEndDateList?.filter(w => w)?.find(w => w?.rosterCandidateId == row?.rosterCandidateId);
                    // console.log({row, selectedStartEndDate});
                    return selectedStartEndDate?.dasStatus ?? "N/A";
                },
                disableSortBy: false,
                width: 35,
            },
            {
                Header: "Message",
                Cell: ({ row }) => {
                    const { original } = row;
                    return (<div>{(!original || !('message' in original) || !('message' in original.message) ? "" : original.message.message)}</div>);
                },
                disableSortBy: false,
            },
            {
                Header: "Action",
                width: "15",
                Cell: ({ row }) => {
                    const { original } = row;
                    return (<TooltipButton
                        id={`edit_${original?.candidate?.candidateId}`}
                        title="Edit"
                        className="btn-icon"
                        color="default"
                        size="xsm"
                        type="button"
                        onClick={() => {
                            const candidateList = [];
                            candidateList.push({ ...original.candidate });
                            setSelectedCandidates2(candidateList);
                            setSelectedSourceObject2({ ...original });

                            setShowModalSms(true);
                            setCurrentMessage(original.message);
                        }}>
                        <i className="fas fa-pencil-alt pt-1"></i>
                    </TooltipButton>);
                },
                disableSortBy: false,
                width: 25,
            },
        ];
    }, [firstLoad, selected, selectedStartEndDateList, step, data]);

    const {
        getTableProps: getTablePropsSms,
        getTableBodyProps: getTableBodyPropsSms,
        headerGroups: headerGroupsSms,
        rows: rowsSms,
        prepareRow: prepareRowSms,
    } = useTable(
        {
            columns: columnsSms,
            data: dataSms,
            initialState: {
                sortBy: defaultSort,
                // "selectedRowIds": {},
                "hiddenColumns": []
            },
            // defaultColumn
        },
        useFlexLayout,
        useSortBy,
        useRowSelect,
    );

    const onSaveEditSms = useCallback((message, callback, loadingCallback, sourceObject) => {
        if (!message || !message.message || message.message === "")
            return;

        if (loadingCallback)
            loadingCallback(true);
        else
            setLoading(true);

        const newData = [...dataSms];
        const editedData = newData.find(w => w.rosterCandidateId == sourceObject.rosterCandidateId);

        if (editedData)
            editedData.message.message = message.message;

        setDataSms(newData);
        callback();
        loadingCallback(false);
        setLoading(false);
    }, [selectedFlatRows]);

    useEffect(() => {
        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        const events = {
            onConfirm: () => {
                setErrorMessage(null);
                setErrorTitle(null);
            },
            message: errorMessage,
            title: errorTitle ?? errorTitleOnGenerate
        }
        setErrorNotification([<ErrorNotification {...events} />]);
    }, [errorMessage, errorTitle]);

    return (
        <>
            {(loading) && <Loader />}
            {errorNotification.length > 0 && errorNotification}
            <Collapse className="modal-bulk-send-choose-date" isOpen={step == 1}>
                <div className="modal-header my-0">
                    <Container fluid>
                        <Row>
                            <Col lg={9}>
                                <h5 className="modal-title">
                                    Bulk DAS Confirmation
                                    {title}
                                </h5>
                                <div><small>Roster Candidate Selection</small></div>
                            </Col>
                            <Col lg={3}>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    onClick={onClose}
                                    type="button"
                                >
                                    <span aria-hidden={true}>
                                        <i className="fas fa-times-circle text-black" />
                                    </span>
                                </button>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="modal-body">
                    <Collapse isOpen={!!validationMessage} className="mb-4">
                        <div className="alert alert-warning" role="alert">
                            {
                                validationMessage?.map((d, index) => (
                                    <>
                                        {d}
                                        {
                                            index < validationMessage.length - 1 ? <><br /></> : null
                                        }
                                    </>
                                ))
                            }
                        </div>
                    </Collapse>
                    <Grid
                        height='calc(100vh - 436px)'
                        rows={rows}
                        tableProps={getTableProps()}
                        headerGroups={headerGroups}
                        tableBodyProps={getTableBodyProps()}
                        prepareRow={prepareRow}
                        rowStyle={(row) => {
                            if (!selectedStartEndDateList?.length)
                                return "yellow";

                            return selectedStartEndDateList.filter(d => d.rosterCandidateId === row.original.rosterCandidateId && (!d.startEndHasWorkSchedule || d.inactiveDateExists)).length ? "yellow" : "";
                        }}
                    />
                </div>
                <div className="modal-footer">
                    <Button color="secondary" type="button"
                        onClick={() => {
                            loadData();
                            //setStep(2);
                        }}>
                        <span className="fa fa-refresh"></span>
                        Reset
                    </Button>
                    <Button color="secondary" type="button"
                        onClick={() => {
                            onClose();
                        }}>
                        Cancel
                    </Button>
                    <Button color="info" type="button" disabled={!selectedFlatRows || !selectedFlatRows.length}
                        onClick={() => {
                            generateMessage();
                            //setStep(2);
                        }}>
                        Continue
                    </Button>
                </div>

            </Collapse>

            <Collapse className="modal-bulk-send-choose-date" isOpen={step == 2}>
                <div className="modal-header my-0">
                    <Container fluid>
                        <Row>
                            <Col lg={9}>
                                <h5
                                    className="modal-title"
                                >
                                    Bulk DAS Confirmation
                                    {title}
                                </h5>
                                <div><small>Message Preparation</small></div>
                            </Col>
                            <Col lg={3}>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    onClick={onClose}
                                    type="button"
                                >
                                    <span aria-hidden={true}>
                                        <i className="fas fa-times-circle text-black" />
                                    </span>
                                </button>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="modal-body">
                    <Collapse isOpen={!!validationMessage} className="mb-4">
                        <div className="alert alert-warning" role="alert">
                            {
                                validationMessage?.map((d, index) => (
                                    <>
                                        {d}
                                        {
                                            index < validationMessage.length - 1 ? <><br /></> : null
                                        }
                                    </>
                                ))
                            }
                        </div>
                    </Collapse>
                    <Collapse isOpen={failedMessages?.length > 0} className="mb-2">
                        {
                            failedMessages?.length ?
                                <div className="p-2 font-size-message-alert-custom text-danger">
                                    <ul>
                                        {
                                            failedMessages.map((item, idx) => {
                                                if (item.failCategory === "Empty Message")
                                                    return (
                                                        <li key={`fail_${idx}`}>
                                                            <span>Message cannot be empty.</span>
                                                        </li>
                                                    );

                                                if (item.failCategory === "Invalid Attribute Usage")
                                                    return (
                                                        <li key={`fail_${idx}`}>
                                                            <span>{item.message}</span>
                                                        </li>
                                                    );

                                                if (item.failCategory === "URL Response")
                                                    return (
                                                        <li key={`fail_${idx}`}>
                                                            <span><b>Add URL to Response</b> must be checked.</span>
                                                        </li>
                                                    );

                                                if (item.failCategory === "Talent Response Parameter")
                                                    return (
                                                        <li key={`fail_${idx}`}>
                                                            <span><b>{"{FlowUrls.TalentResponseUrl}"}</b> must be provided.</span>
                                                        </li>
                                                    );

                                                if (item.failCategory === "Message Unsent")
                                                    return (
                                                        <li key={`fail_${idx}`}>
                                                            <span>
                                                                {
                                                                    `Message is failed to send to ${item.candidateName}.`
                                                                }
                                                            </span>
                                                        </li>
                                                    );

                                                if (item.failCategory === "Invalid Attribute")
                                                    return (
                                                        <li key={`fail_${idx}`}>
                                                            <span>
                                                                {
                                                                    item.failAttribute
                                                                }
                                                            </span>
                                                        </li>
                                                    );

                                                if (item.failCategory === "Index Error")
                                                    return (
                                                        <li key={`fail_${idx}`}>
                                                            <span>
                                                                {item.candidateName} ({item.mobileNumber}) SMS request is not able to be processed. Please contact your admin to check the log files.<br />
                                                                Original error message: "Index was outside the bounds of the array."<br />
                                                                Thank you.
                                                            </span>
                                                        </li>
                                                    );

                                                return (
                                                    <li key={`fail_${idx}`}>
                                                        <span>
                                                            {
                                                                `${item.candidateName}: missing ${item.failCategory} - ${item.failAttribute}.`
                                                            }
                                                        </span>
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                </div>
                                :
                                null
                        }
                    </Collapse>
                    <Grid
                        height='calc(100vh - 436px)'
                        rows={rowsSms}
                        tableProps={getTablePropsSms()}
                        headerGroups={headerGroupsSms}
                        tableBodyProps={getTableBodyPropsSms()}
                        prepareRow={prepareRowSms}
                        rowStyle={(row) => {
                            if (!selectedStartEndDateList?.length)
                                return "";

                            return selectedStartEndDateList.filter(d => d.rosterCandidateId === row.original.rosterCandidateId && (!d.startEndHasWorkSchedule || d.inactiveDateExists)).length ? "yellow" : "";
                        }}
                    />
                </div>
                <div className="modal-footer">
                    <Button
                        color="secondary"
                        onClick={() => {
                            setStep(1);
                        }}
                        type="button"
                    >
                        Back
                    </Button>
                    <Button
                        color="info"
                        onClick={() => {
                            handleSend();
                        }}
                        type="button"
                    >
                        Send Confirmation
                    </Button>
                </div>
            </Collapse>
            {
                showModalSms &&
                <EditorSMS
                    items={selectedCandidates2}
                    sourceObject={selectedSourceObject2}
                    onClose={() => {
                        setShowModalSms(false);
                        if (failedMessages?.length > 0)
                            setFailedMessages(null);
                        //onClose();
                    }}
                    onCustomSave={(message, loadingCallback, failedMessagesCallback, templateId, messageLog, sourceObject) =>
                        onSaveEditSms({ message }, () => {
                            setShowModalSms(false);
                            setLoading(false);
                        }, loadingCallback, sourceObject)}
                    lookups={{
                        ...props.lookups
                    }}
                    defaultMessage={currentMessage}
                    buttonSendMessage={false}
                />
            }
            {
                dataIsEmpty &&
                <SweetAlert
                    title="Error!"
                    error
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    onConfirm={() => onClose()}
                >
                    Data not found.
                </SweetAlert>
            }
        </>
    )
};