/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import {
    Modal, Container, Row, Col, Button, Label
 } from "reactstrap"; 
import api from "../../../../services/api";
import { useDropzone } from 'react-dropzone';
import cn from "classnames";
import Loader from "../../../loaders";
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community';
import readXlsxFile from 'read-excel-file';

// import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
// import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import _ from 'lodash';

import "../../../../assets/css/ag-grid.css";
import "../../../../assets/css/ag-theme-alpine.css";
import { TooltipButton } from '../../../inputs';
import moment from 'moment';
import MiniTable from './MiniTable';
import { DateTimeValueFormatter, DateValueFormatter } from '../../../../helpers/AgGridHelper';

const UPLOAD_MODE = "upload";
const VALIDATION_MODE = "validation";

export default function ImportAccommodationFlightDialog(props) {
    const [ loading, setLoading ] = useState(false);
    const [ importFiles, setImportFiles ] = useState([]);
    const [ alert, setAlert ] = useState('');
    const [ dataA, setDataA ] = useState([]);
    const [ dataF, setDataF ] = useState([]);
    const [ validationDataA, setValidationDataA ] = useState([]);
    const [ validationDataF, setValidationDataF ] = useState([]);
    const [ mode, setMode ] = useState(UPLOAD_MODE);
    const [ validationAlertF, setValidationAlertF ] = useState([]);
    const [ validationAlertA, setValidationAlertA ] = useState([]);
    const [ openValidationAlertF, setOpenValidationAlertF ] = useState(false);
    const [ openValidationAlertA, setOpenValidationAlertA ] = useState(false);
    const [ canSave, setCanSave ] = useState(false);
    const [ validatedA, setValidatedA ] = useState(false);
    const [ validatedF, setValidatedF ] = useState(false);
    const [ duplicateDataValidationAlert, setDuplicateDataValidationAlert ] = useState([]);


    // BEGIN FACTOR ---------------------------------------------------------------------------------------------------------------------------------

    const [loadingA, setLoadingA] = useState(false);
    const [loadingF, setLoadingF] = useState(false);

    useEffect(() => {
        if (loadingA == false && loadingF == false && loading == true) {
            setLoading(false);
        }
    }, [loadingA, loadingF]);


    // END FACTOR -----------------------------------------------------------------------------------------------------------------------------------

    // BEGIN LINKED VARIABLES ----------------------------------------------------------------------------------------------------------------------
    const dataARef = useRef(dataA);
    const dataFRef = useRef(dataF);

    const validatedARef = useRef(validatedA);
    const validatedFRef = useRef(validatedF);

    const validationAlertFRef = useRef(validationAlertF);
    const validationAlertARef = useRef(validationAlertA);

    useEffect(() => {
        dataARef.current = dataA;
    }, [dataA]);

    useEffect(() => {
        dataFRef.current = dataF;
    }, [dataF]);

    useEffect(() => {
            validatedARef.current = validatedA;
    }, [validatedA]);

    useEffect(() => {
        validatedFRef.current = validatedF;
    }, [validatedF]);


    useEffect(() => {
        validationAlertFRef.current = validationAlertF;
    }, [validationAlertF]);

    useEffect(() => {
        validationAlertARef.current = validationAlertA;
    }, [validationAlertA]);


    // END LINKED VARIABLES ------------------------------------------------------------------------------------------------------------------------


    const defaultColumnsA = [
        { name: "rowNo", title: "Row No" },
        //{ name: "rosterCandidateAccommodationId", title: "Roster Candidate Accommodation Id" },
        { name: "projectName", title: "Project Name" },
        { name: "dispatchUnitName", title: "Dispatch Unit Name" },
        { name: "slotNo", title: "Slot No" },
        { name: "candidateEmail", title: "Candidate Email" },
        { name: "startDate", title: "Start Date", filter: 'agDateColumnFilter', valueFormatter: DateValueFormatter, },
        { name: "endDate", title: "End Date", filter: 'agDateColumnFilter', valueFormatter: DateValueFormatter, },
        { name: "accommodationName", title: "Accommodation Name" },
        { name: "accommodationNote", title: "Accommodation Note" },
        { name: "status", title: "Status" },
    ];

    const defaultColumnsF = [
        { name: "rowNo", title: "Row No" },
        //{ name: "rosterCandidateScheduleId", title: "Roster Candidate Schedule Id" },
        { name: "projectName", title: "Project Name" },
        { name: "dispatchUnitName", title: "Dispatch Unit Name" },
        { name: "slotNo", title: "Slot No" },
        { name: "candidateEmail", title: "Candidate Email" },
        { name: "date", title: "Date", filter: 'agDateColumnFilter', valueFormatter: DateValueFormatter, },
        { name: "rosterScheduleTypeCode", title: "Roster Schedule Type Code" },
        { name: "flightFromAirportCode", title: "Flight From Airport Code" },
        { name: "departureTerminal", title: "Departure Terminal" },
        { name: "flightToAirportCode", title: "Flight To Airport Code" },
        { name: "arrivalTerminal", title: "Arrival Terminal" },
        { name: "flightDateTime", title: "Flight Date Time", filter: 'agDateColumnFilter', valueFormatter: DateTimeValueFormatter, },
        { name: "arrivalDateTime", title: "Arrival Date Time", filter: 'agDateColumnFilter', valueFormatter: DateTimeValueFormatter, },
        { name: "rosterScheduleTypeName", title: "Roster Schedule Type Name" },
        { name: "status", title: "Status" },
    ];

    const [columnDefsA, setColumnDefsA] = useState([...defaultColumnsA.map(c => ({
        ...c,
        field: c.name,
        headerName: c.title,
    }))]);
    const [columnDefsF, setColumnDefsF] = useState([...defaultColumnsF.map(c => ({
        ...c,
        field: c.name,
        headerName: c.title,
    }))]);
    const [ rowBuffer, setRowBuffer ] = useState(20);
    const gridRef = useRef();

    const onDrop = useCallback(acceptedFiles => setImportFiles([...acceptedFiles]), [importFiles]);

    /*
    const handleUpload = useCallback(() => {
        setLoading(true);

        const formData = new FormData();

        const file = importFiles[0];
        console.log('acceptedFiles', importFiles);
        formData.append('files', file, file.name);

        api({ url: `roster/import/${props.rosterId}`, method: 'POST', data: formData })
        .then(response => {
            console.log('roster import', response);
            setAlert('success');
        })
        .catch((error) =>{ 
            console.error(error);
            setAlert('error');
        })
        .finally(() => setLoading(false));

    }, [importFiles]);
    */

    const handleFileSelected = useCallback(() => {
        setLoading(true);
        setCanSave(true);
        const file = importFiles[0];

        readXlsxFile(file, { getSheets: true }).then((sheets) => {
            sheets.forEach((sheet) => {
                if (sheet.name === "Accommodations") {
                    
                    readXlsxFile(file, { sheet: sheet.name }).then((rows) => {
                        setLoadingA(true);
                        console.log(`Data from ${sheet.name}:`, rows);
                        // Process the data as required

                        if (rows?.length < 1 && rows[0]?.length < 4 && rows[0][0]?.toLowerCase() !== defaultColumnsA[0].title.toLowerCase()) {
                            setAlert({ type: "error", message: "Invalid data format." });
                            setImportFiles([]);
                            setDataA([]);
                            setValidationDataA([]);
                            setLoadingA(false);

                            return;
                        }


                        const columns = [...rows[0]];
                        const newRows = [...rows];
                        newRows.splice(0, 1);

                        const gridColumns = [];
                        const newData = [];
                        const newValidationData = [];

                        columns.forEach(c => {
                            const column = {
                                field: defaultColumnsA.filter(d => d.title === c)?.length ? defaultColumnsA.filter(d => d.title === c)[0].name : c.replace(/\s+/g, "").replace(/[^A-Za-z]+/g, ""),
                                headerName: c
                            };
                            gridColumns.push(column);
                        });


                        const validationColumns = [
                            "row no",
                            "project name",
                            "dispatch unit name",
                            "slot no",
                            "candidate email",
                            "start date",
                            "end date",
                            "accommodation name",
                            "accommodation note",
                            "status",
                        ];

                        newRows.forEach((r, r_idx) => {
                            const rowNo = r_idx + 1;
                            const row = { rowNo: rowNo };
                            const validationRow = {
                                rowNo: rowNo,
                                rosterAttributeNames: []
                            };
                            let rosterAttributeStart = false;

                            r.forEach((c, idx) => {
                                const columnName = defaultColumnsA.filter(d => d.title.toLowerCase() === columns[idx].toLowerCase())?.length ? defaultColumnsA.filter(d => d.title.toLowerCase() === columns[idx].toLowerCase())[0].name : columns[idx].replace(/\s+/g, "").replace(/[^A-Za-z]+/g, "");
                                // console.log(`row[${r_idx}].${columns[idx].replace(/\s+/g, "").replace(/[^A-Za-z]+/g, "")} = ${c}; real column name: ${columns[idx]}`);
                                row[columnName] = c;

                                if (validationColumns.includes(columns[idx].toLowerCase())) {
                                    // console.log(`validation row: ${columns[idx]}`);
                                    validationRow[columnName] = c;
                                }

                                if (rosterAttributeStart)
                                    validationRow.rosterAttributeNames.push({
                                        key: columns[idx],
                                        value: c
                                    });

                            });

                            // console.log(`row[${r_idx}] = `, {...row});
                            newData.push({ ...row });
                            newValidationData.push({ ...validationRow });
                        });

                        // console.log({gridColumns, newRows, newData, newValidationData});
                        setColumnDefsA([...gridColumns]);
                        setValidationDataA([...newValidationData]);
                        setDataA([...newData]);
                        setValidatedA(false);

                    })
                    .finally(() => setLoadingA(false));
                }
                else if (sheet.name === "Flights")
                {
                    setLoadingF(true);
                    //readXlsxFile(file, { sheet: sheet.name }).then((data) => {
                    //    console.log(`Data from ${sheet.name}:`, data);
                    //    // Process the data as required
                    //})
                    //.finally(() => setLoadingF(false));


                    readXlsxFile(file, { sheet: sheet.name }).then((rows) => {
                        setLoadingF(true);
                        console.log(`Data from ${sheet.name}:`, rows);
                        // Process the data as required

                        if (rows?.length < 1 && rows[0]?.length < 4 && rows[0][0]?.toLowerCase() !== defaultColumnsF[0].title.toLowerCase()) {
                            setAlert({ type: "error", message: "Invalid data format." });
                            setImportFiles([]);
                            setDataF([]);
                            setValidationDataF([]);
                            setLoadingF(false);

                            return;
                        }


                        const columns = [...rows[0]];
                        const newRows = [...rows];
                        //newRows.splice(0, 7);
                        newRows.splice(0, 1);

                        const gridColumns = [];
                        const newData = [];
                        const newValidationData = [];

                        columns.forEach(c => {
                            const column = {
                                field: defaultColumnsF.filter(d => d.title === c)?.length ? defaultColumnsF.filter(d => d.title === c)[0].name : c.replace(/\s+/g, "").replace(/[^A-Za-z]+/g, ""),
                                headerName: c
                            };
                            gridColumns.push(column);
                        });



                        const validationColumns = [
                            "row no",
                            "project name",
                            "dispatch unit name",
                            "slot no",
                            "candidate email",
                            "date",
                            "roster schedule type code",
                            "flight from airport code",
                            "departure terminal",
                            "flight to airport code",
                            "arrival terminal",
                            "flight date time",
                            "arrival date time",
                            "roster schedule type name",
                            "status"
                        ];


                        newRows.forEach((r, r_idx) => {
                            const rowNo = r_idx + 1;
                            const row = { rowNo: rowNo };
                            const validationRow = {
                                rowNo: rowNo,
                                rosterAttributeNames: []
                            };
                            let rosterAttributeStart = false;

                            r.forEach((c, idx) => {
                                const columnName = defaultColumnsF.filter(d => d.title.toLowerCase() === columns[idx].toLowerCase())?.length ? defaultColumnsF.filter(d => d.title.toLowerCase() === columns[idx].toLowerCase())[0].name : columns[idx].replace(/\s+/g, "").replace(/[^A-Za-z]+/g, "");
                                // console.log(`row[${r_idx}].${columns[idx].replace(/\s+/g, "").replace(/[^A-Za-z]+/g, "")} = ${c}; real column name: ${columns[idx]}`);
                                row[columnName] = c;

                                if (validationColumns.includes(columns[idx].toLowerCase())) {
                                    // console.log(`validation row: ${columns[idx]}`);
                                    validationRow[columnName] = c;
                                }

                                if (rosterAttributeStart)
                                    validationRow.rosterAttributeNames.push({
                                        key: columns[idx],
                                        value: c
                                    });

                            });

                            // console.log(`row[${r_idx}] = `, {...row});
                            newData.push({ ...row });
                            newValidationData.push({ ...validationRow });
                        });

                        // console.log({gridColumns, newRows, newData, newValidationData});
                        setColumnDefsF([...gridColumns]);
                        setValidationDataF([...newValidationData]);
                        setDataF([...newData]);
                        setValidatedF(false);

                    })
                    .finally(() => setLoadingF(false));
                }
            });
        });

    }, [importFiles]);

    useEffect(() => {
        console.log('dataF', dataF);
    }, [dataF]);

    useEffect(() => {
        console.log('dataA', dataF);
    }, [dataA]);

    useEffect(() => {
        if (!importFiles?.length) {
            setCanSave(false);
            return;
        }
            
        handleFileSelected();
    }, [importFiles]);

    //useEffect(() => {
    //    const rosterCandidateDates = [];
    //    const newValidationData = [];

    //    if (!dataA?.length)
    //    {
    //        setColumnDefsA([...defaultColumnsA.map(c => ({
    //            field: c.name,
    //            headerName: c.title,
    //        }))]);
    //        //setCanSave(false);
    //        setValidationAlertF([...newValidationData]);
    //        setDuplicateDataValidationAlert([...newValidationData]);
    //        return;
    //    }

    //    let newCanSave = true;
    //    let i = 0;

    //    while (i < dataA.length)
    //    {
    //        if (!rosterCandidateDates.filter(rc => rc.rosterCandidateId === dataA[i].rosterCandidateId)?.length)
    //        {
    //            rosterCandidateDates.push({
    //                rosterCandidateId: dataA[i].rosterCandidateId,
    //                startDate: dataA[i].workStart,
    //                endDate: dataA[i].workEnd,
    //            });
    //            i++;
    //            continue;
    //        }

    //        if (!rosterCandidateDates.filter(rc => rc.rosterCandidateId === dataA[i].rosterCandidateId && rc.startDate === dataA[i].workStart && rc.endDate === dataA[i].workEnd)?.length)
    //        {
    //            i++;
    //            continue;
    //        }

    //        if (newCanSave)
    //            newCanSave = false;
            
    //        if (!newValidationData.filter(d => d.id === dataA[i].rosterCandidateId)?.length)
    //            newValidationData.push({
    //                id: dataA[i].rosterCandidateId,
    //                messages: []
    //            });
            
    //        const validation = newValidationData.filter(d => d.id === dataA[i].rosterCandidateId)[0];

    //        if (!validation.messages?.length)
    //            validation.messages.push("Start Date/End Date for each row with the same ID should be different.");
            
    //        i++;
    //    }

    //    // console.log({newCanSave, rosterCandidateDates});

    //    setValidationAlertF([...newValidationData]);
    //    setDuplicateDataValidationAlert([...newValidationData]);
    //    setCanSave(newCanSave);
    //}, [dataA]);

    const {
        getRootProps, 
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ 
        onDrop,
        maxFiles: 1,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const dropzoneClasses = useMemo(() => cn({
        'dropzone': true,
        'mt-2': true,
        'active': isDragActive,
        'accept': isDragAccept,
        'reject': isDragReject
    }), [
        isDragActive,
        isDragAccept,
        isDragReject
    ]);

    const loadingOverlayComponent = useMemo(() => Loader, []);

    const defaultColDef = useMemo(() => ({
        sortable: false,
        resizable: true,
        //filter: true,
        editable: false,
        filter: 'agSetColumnFilter',
        filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200
        },
    }), []);

    const files = useMemo(() => 
        importFiles.map(
            file => (
                <li key={file.path}>
                    {file.path} - {file.size} bytes
                </li>
            )
        
    ), [importFiles]);


    const getRowId = useMemo(() => (params) => params.data.id, []);

    const onCellEditRequest = useCallback((event) => {
        console.log("on cell edit request: ", {event});
    }, []);

    const handleValidateData = useCallback(() => {
        if (!validationDataA?.length && !validationDataF?.length)
            return;

        setLoading(true);

        api.post(`/rostercandidateschedule/validateimportflight`, JSON.stringify(validationDataF))
            .then((response) => {
                // console.log({response});
                setLoadingF(true);

                if (mode !== VALIDATION_MODE)
                    setMode(VALIDATION_MODE);

                // console.log("check result: ", {check: response.data
                //     .filter(d => !d.messages?.length || !d.messages.filter(m => {
                //         console.log(m.toLowerCase().includes("start work date"), m.toLowerCase().includes("end work date"));
                //         return m.toLowerCase().includes("start work date") || m.toLowerCase().includes("end work date");
                //     }))?.length === 0}
                // );

                //const newValidationData = [...duplicateDataValidationAlert];
                var newValidationData = [];

                if (response.data?.length) {
                    // invalid data
                    newValidationData = [...response.data];
                }

                setValidationAlertF([...newValidationData]);
                setOpenValidationAlertF(true);
                setValidatedF(true);
                setLoadingF(false);
            })
            .catch((error) => {
                console.error({ error });
                setLoadingF(false);
            });

        api.post(`/rostercandidateschedule/validateimportaccommodation`, JSON.stringify(validationDataA))
            .then((response) => {
                // console.log({response});
                setLoadingA(true);

                if (mode !== VALIDATION_MODE)
                    setMode(VALIDATION_MODE);

                // console.log("check result: ", {check: response.data
                //     .filter(d => !d.messages?.length || !d.messages.filter(m => {
                //         console.log(m.toLowerCase().includes("start work date"), m.toLowerCase().includes("end work date"));
                //         return m.toLowerCase().includes("start work date") || m.toLowerCase().includes("end work date");
                //     }))?.length === 0}
                // );

                //const newValidationData = [...duplicateDataValidationAlert];
                var newValidationData = [];

                if (response.data?.length) {
                    // invalid data
                    newValidationData = [...response.data];
                }

                setValidationAlertA([...newValidationData]);
                setOpenValidationAlertA(true);
                setValidatedA(true);
                setLoadingA(false);
            })
            .catch((error) => {
                console.error({ error });
                setLoadingA(false);
            })
    }, [validationDataA, validationDataF, mode, duplicateDataValidationAlert]);

    const handleImport = useCallback(() => {
        setLoading(true);
        
        const rosterAttributes = [];
        
        columnDefsA.forEach(c => {
            if (defaultColumnsA.filter(d => d.name === c.field)?.length)
                return;

            rosterAttributes.push({fieldName: c.field, attributeName: c.headerName});
        });

        const postData = {
            fileName: importFiles[0].name,
            startDate: moment().format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            dispatchUnitId: props.dispatchUnitId,
            data: {
                accommodations: [...dataA],
                flights: [...dataF],
            }
        };

        console.log('props', props);
        console.log('postData', postData);
        //return;

        // console.log({rosterAttributes, postData});

        api.post(`rostercandidateschedule/importraw`, { ...postData })
            .then(response => {
                // console.log('roster import', response);
                setAlert({type: "success"});
            })
            .catch((error) =>{ 
                console.error({error});
                setAlert({type: "error", message: JSON.stringify(error.response?.data)});
            })
            .finally(() => setLoading(false));
    }, [dataA, dataF, columnDefsA, columnDefsF, importFiles, props]);

    return (
        <Modal
            isOpen={true}
            className="modal-xl"
            modalClassName="db-example-modal-xl"
        >
            {loading && <Loader />}
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Import Accommodation & Flights
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div {...getRootProps({className: dropzoneClasses})}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop a file here, or click to select a file</p>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <aside className="dropzone-list pt-3">
                                <Label>File</Label>
                                <ul>{files}</ul>
                            </aside>
                        </Col>
                    </Row>
                    <Row className="w-100 mx-0 mt-3">
                        <MiniTable
                            defaultColumns={defaultColumnsA}
                            //data={data}
                            dataRef={dataARef}
                            columnDefs={columnDefsA}
                            setData={setDataA}
                            defaultColDef={defaultColDef}
                            validationAlertRef={validationAlertARef}
                            setValidationAlert={setValidationAlertA}
                            validatedRef={validatedARef}
                            setValidated={setValidatedA}
                        ></MiniTable>
                        <MiniTable
                            defaultColumns={defaultColumnsF}
                            //data={data}
                            dataRef={dataFRef}
                            columnDefs={columnDefsF}
                            setData={setDataF}
                            defaultColDef={defaultColDef}
                            validationAlertRef={validationAlertFRef}
                            setValidationAlert={setValidationAlertF}
                            validatedRef={validatedFRef}
                            setValidated={setValidatedF}
                        ></MiniTable>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer d-flex flex-wrap justify-content-between align-items-center w-100"
            >
                <div className="d-flex flex-wrap justify-content-start align-items-center">
                    <Button
                        color="secondary"
                        onClick={props.onClose}
                        type="button"
                    >
                        Close
                    </Button>
                </div>
                <div className="d-flex flex-wrap justify-content-end align-items-center">
                <Button
                    color="default"
                    onClick={() => {
                        handleValidateData();
                    }}
                        type="button"
                        disabled={!importFiles?.length}
                    className="mr-2"
                >
                    <i className="fas fa-check-square mr-2" />Validate
                </Button>
                <Button
                    color="default"
                    onClick={() => {
                        handleImport();
                    }}
                    type="button"
                    disabled={!validatedA || !validatedF || !!validationAlertA?.length || !!validationAlertF?.length || !canSave}
                >
                    <i className="fas fa-paper-plane mr-2" />Upload to Roster
                </Button>
                </div>
            </div>
        {
            alert &&
            <SweetAlert
                title={alert.type.toLowerCase() === 'error' ? 'Error' : 'Success'}
                error={alert.type.toLowerCase() === 'error'}
                confirmBtnText="OK"
                confirmBtnBsStyle={alert.type.toLowerCase() === 'error' ? "danger" : "success"}
                onConfirm={() => {
                    if (alert.type.toLowerCase() === "error")
                    {
                        setAlert(null);
                        return;
                    }

                    props.onClose();
                }}
            >
            {
                alert.type.toLowerCase() == 'error' ? alert.message : 'File successfully uploaded'
            }
            </SweetAlert>
        }

        </Modal>
    )
}