import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import api from "../../../services/api";
import qs from "qs";
import {
    Container, Row, Col, Card, CardHeader, CardBody, Input, FormGroup, Label, Button, Collapse, Modal, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledPopover, PopoverBody
} from "reactstrap";
import { ComboBox, DropdownBox } from "../../dropdowns";
import { formatDate } from "../../../utils";
import Handsontable from "handsontable";
import { HotTable, HotColumn, BaseEditorComponent } from "@handsontable/react";
import debounce from 'lodash.debounce';
import Loader from "../../loaders";
import { useHistory, useLocation } from 'react-router-dom';
import { DatePicker } from "../../date-pickers";
import moment from "moment";
import CandidateEditor from "../Candidates/Editor";
import SweetAlert from "react-bootstrap-sweetalert";
import CandidateConfirmationEditor from "./Editors/CandidateConfirmationEditor";
import LogisticEditor from "./Editors/LogisticEditor";
import { TooltipButton } from '../../inputs';
import EditorSMS from '../Candidates/EditorSMS';
import ConflictDialog from './ConflictDialog';
import PositionStepUpEditor from './Editors/PositionStepUpEditor';
import CandidateDocumentsEditor from './Editors/CandidateDocumentsEditor';
import RosterAttributeEditor from './Editors/RosterAttributeEditor';
import ImportDialog from './ImportDialog'
import DispatchUnitEditor from './Editors/DispatchUnitEditor';
import { IndeterminateCheckbox } from '../../react-table/controls';
import { forEach } from 'lodash';
import DeleteSlotAlert from './Validations/DeleteSlotAlert';
import fileDownload from "js-file-download";
import { ErrorNotification } from "../../alerts"
import BulkAvailabilityConfirmation from './Editors/BulkAvailabilityConfirmation';
import { BulkConfirmationMode } from '../../../utils'

function InfoButton(props) {
    // console.log("info button", props.value, !props.value);
    if (!props.value)
        return null;

    return (
        <div className="cursor-pointer w-100 h-100 py-1 pr-1 m-0 d-flex flex-wrap justify-content-center">
            <i className="fas fa-info-circle"></i>
        </div>
    )
}
function TalentRenderer(props) {
    props.TD.className = 'talent-col unwraptext'
    props.TD.dataset.hasValue = !!props.value;
    return (
        <span>{props.value}</span>
    )
}
function CompanyRenderer(props) {
    //console.log('CompanyRenderer: ', props)
    if (props.value && props.value.lookupValue !== props.value.lookupLabel) {
        props.TD.className = 'text-danger';
    }
    return (
        <span>{props.value?.lookupValue}</span>
    )
}
function FatigueFlagRenderer(props) {
    if (!props.value) {
        return <></>
    }
    else if (props.value.toLowerCase().includes('breach of fatigue')) {
        props.TD.className = 'bg-danger text-white';
    }
    else if (props.value.toLowerCase().includes('no breach')) {
        props.TD.className = 'bg-green-light text-dark';
    }
    else if (props.value.toLowerCase().includes('review required')) {
        props.TD.className = 'bg-yellow text-dark';
    }
    return (
        <>{props.value}</>
    )
}

function OverlappedButton(props) {
    const {
        value
    } = props;

    if (!value)
        return null;

    return (
        <div className={`${value === "Y" ? "cursor-pointer " : ""}w-100 h-100 py-1 pr-1 m-0 d-flex flex-wrap justify-content-center`}>
            <i className={`fas ${value === "N" ? "fa-check-circle text-success" : "fa-times-circle text-danger"}`}></i>
        </div>
    )
}

const RendererComponent = (props) => {
    const [isFlightOpened, setIsFlightOpened] = useState(false);
    // the available renderer-related props are:
    // - `row` (row index)
    // - `col` (column index)
    // - `prop` (column property name)
    // - `TD` (the HTML cell element)
    // - `cellProperties` (the `cellProperties` object for the edited cell)
    const { value, TD, row, col, instance, prop } = props;
    // console.log({value, row, col, instance, props});
    if (!value || typeof (value) !== 'string' || !instance?.current?.hotInstance) return null;

    const hot = instance.current.hotInstance;
    const rowData = hot.getSourceDataAtRow(row);

    const colName = prop.split(".")[1];

    if (!colName)
        return null;

    if (!rowData.dates[colName])
        return null;

    let message = '';
    if (value) {
        if (value.includes("M")) {
            message += 'Mobilize\r\n';
        }
        if (value.includes("D")) {
            message += 'Demobilize\r\n';
        }
        if (value.includes("S")) {
            message += 'Work Start\r\n';
        }
        if (value.includes("E")) {
            message += 'Work End\r\n';
        }
        if (value.includes("W")) {
            message += 'Working Day\r\n';
        }
        if (value.includes("N")) {
            message += 'Working Night\r\n';
        }
        if (value.includes("L")) {
            message += 'Leave\r\n';
        }
        if (value.includes("R")) {
            message += 'R&R\r\n';
        }
        if (value.includes("P")) {
            message += 'LWOP\r\n';
        }
        if (value.includes("T")) {
            message += 'Training\r\n';
        }
        if (value.includes("F")) {
            message += 'Fatigue Day\r\n';
        }
        if (value.includes("U")) {
            message += 'Sick Leave\r\n';
        }
        if (value.includes("C")) {
            message += 'Drive-In\r\n';
        }
        if (value.includes("O")) {
            message += 'Drive-Out\r\n';
        }
    }
    TD.title = message;
    const selectedDate = rowData.dates[colName];
    TD.className = `data-value${selectedDate.flightConfirmed ? " text-red" : selectedDate.flights?.length > 0 ? " text-blue" : ""}`;

    if (selectedDate.flights && selectedDate.flights.length)
        return (
            <React.Fragment>
                <div
                    onMouseEnter={() => setIsFlightOpened(true)}
                    onMouseLeave={() => setIsFlightOpened(false)}
                >
                    <span className={`roster-icon ${selectedDate.value ? selectedDate.value.split('').join(' ') : ''}`} data-value={selectedDate.value} id={`sch-${selectedDate.rosterCandidateScheduleId}`}>
                        {'\u00A0'}
                    </span>
                    <UncontrolledPopover
                        isOpen={isFlightOpened}
                        toggle={() => setIsFlightOpened(!isFlightOpened)}
                        target={`sch-${selectedDate.rosterCandidateScheduleId}`}
                    >
                        <PopoverBody>
                            <Container fluid>
                                <Row>
                                    <Col lg={12}><span><b>Flights:</b></span></Col>
                                    {
                                        selectedDate.flights.map(((f, idx) => {
                                            return (
                                                <Col lg={12} key={`sch-${selectedDate.rosterCandidateScheduleId}-${idx}`}>
                                                    <span>{f.flightDateTime ? `${formatDate(f.flightDateTime, "DD-MM-YYYY HH:mm:ss")} - ` : ""}{`${f.flightFrom?.airportName ?? "-"} to ${f.flightTo?.airportName ?? "-"}`}</span>
                                                </Col>
                                            );
                                        }))
                                    }
                                </Row>
                            </Container>
                        </PopoverBody>
                    </UncontrolledPopover>
                </div>
            </React.Fragment>
        );

    return (
        <React.Fragment>
            <span className={`roster-icon ${selectedDate.value ? selectedDate.value.split('').join(' ') : ''}`} data-value={selectedDate.value}>
                {'\u00A0'}
            </span>
        </React.Fragment>
    );
}

function StatusRenderer(props) {
    if (!props.value)
        return null;

    if (props.value === "LRF Created" || props.value === "Job Order Created")
        return (
            <div className="cursor-pointer">
                <span><u><i className="fas fa-external-link-alt"></i> {props.value}</u></span>
            </div>
        )

    return (
        <>{props.value}</>
    )
}

const today = new Date();

export default function List(props) {
    const [loading, setLoading] = React.useState(false);
    const [lookups, setLookups] = useState({});
    const [selectedProject, setSelectedProject] = React.useState(null);
    //const [ selectedWorkorder, setSelectedWorkorder ] = React.useState(null);
    const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date(today.getFullYear(), today.getMonth() + 6, 1));
    const [dispatchUnit, setDispatchUnit] = useState(null);
    const [totalPosition, setTotalPosition] = useState(null);
    const [totalSlot, setTotalSlot] = useState(null);
    const [filterButtonPressed, setFilterButtonPressed] = useState(false);
    const [roster, setRoster] = useState(null);
    const [dispatchUnits, setDispatchUnits] = useState([]);
    const [rosters, setRosters] = useState([]);
    const [dispatchUnitData, setDispatchUnitData] = React.useState([]);
    const [selectedDispatchUnit, setSelectedDispatchUnit] = useState(null);
    const [selectedRoster, setSelectedRoster] = useState(null);
    const [tableColumns, setTableColumns] = useState([]);
    const [hotColumns, setHotColumns] = useState([]);
    const [editLogisticMode, setEditLogisticMode] = useState(false);
    const [importMode, setImportMode] = React.useState(false);
    const [showAllProjects, setShowAllProjects] = React.useState(false);
    const [deleteAlert, setDeleteAlert] = React.useState([]);
    const [openedCollapse, setOpenedCollapse] = React.useState("collapse-1");
    const [selectedCellGlobal, setSelectedCellGlobal] = useState([]);
    const [redirectLink, setRedirectLink] = useState(null);
    const [candidateInEdit, setCandidateInEdit] = useState(null);
    const [toggleLoadData, setToggleLoadData] = useState(false);
    const [logisticInEdit, setLogisticInEdit] = useState(null);
    const [confirmationMessage, setConfirmationMessage] = useState(null);
    const [confirmationMessageTemplateForAvailability, setConfirmationMessageTemplateForAvailability] = useState(null);
    const [confirmationMessageTemplateForLogistic, setConfirmationMessageTemplateForLogistic] = useState(null);
    const [showMessageConfirmation, setShowMessageConfirmation] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [candidateMessageList, setCandidateMessageList] = useState(null);
    const [failedMessages, setFailedMessages] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [checkConflict, setCheckConflict] = useState(null);
    const [firstColumn, setFirstColumn] = useState(0);
    const [validationAlert, setValidationAlert] = useState(null);
    const [confirmCancelAlert, setConfirmCancelAlert] = useState(null);
    const [disableActions, setDisableActions] = useState(false);
    const [refreshOverlapStatus, setRefreshOverlapStatus] = useState(false);
    const [quoteNumber, setQuoteNumber] = useState("");
    const [stepUpInEdit, setStepUpInEdit] = useState(null);
    const [candidateContractInEdit, setCandidateContractInEdit] = useState(null);
    const [rosterAttributeInEdit, setRosterAttributeInEdit] = useState(null);
    const [openRosterImport, setOpenRosterImport] = useState(false);
    const [dispatchUnitInEdit, setDispatchUnitInEdit] = useState(null);
    const [showArchivedDispatchUnits, setShowArchivedDispatchUnits] = useState(false);
    const [refreshSlotNumber, setRefreshSlotNumber] = useState(false);
    const [updatedSlotNo, setUpdatedSlotNo] = useState(null);
    const [trimStartDate, setTrimStartDate] = useState(true);
    const [trimEndDate, setTrimEndDate] = useState(true);
    const [reload, setReload] = useState(false);
    const [deletedData, setDeletedData] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [confirmCandidateChange, setConfirmCandidateChange] = useState(null);
    const [flights, setFlights] = useState([]);

    // Bulk Confirmation variables
    const [bulkAvailabilityConfirmationIsOpen, setBulkAvailabilityConfirmationIsOpen] = React.useState(false);
    const [bulkConfirmationMode, setBulkConfirmationMode] = React.useState(BulkConfirmationMode.AVAILABILITY);

    // Error message variables
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);
    const [errorMessageOpening, setErrorMessageOpening] = React.useState(`Error occured when processing the request: `);

    const [dropdownOpen, setOpen] = React.useState(false);
    const toggle = () => setOpen(!dropdownOpen);

    const hotTableComponent = useRef(null);
    const duapiurl = "dispatchunit";
    const rosterapiurl = "roster";
    const candidateapiurl = "rostercandidate";
    const location = useLocation();
    const history = useHistory();
    const talentColumnIndex = 3;
    const positionColumnIndex = 5;

    useEffect(() => {
        setLoading(true);
        const queryObj = qs.parse(location.search, { ignoreQueryPrefix: true });
        const projectId = queryObj.projectid;
        //const workorderId = qs.parse(location.search, { ignoreQueryPrefix: true }).workorderid;
        const dispatchUnitId = queryObj.dispatchunitid;
        const rosterId = queryObj.rosterid;
        // console.log('first load 0', { projectId, dispatchUnitId, rosterId })

        const apiCalls = [
            api.get(`/lookup/skillposition`),
            api.get(`/lookup/candidatetype`),
            api.get(`/lookup/salutation`),
            api.get(`/lookup/preference`),
            api.get(`/lookup/industry`),
            api.get(`/lookup/gender`),
            api.get(`/lookup/maritalStatus`),
            api.get(`/lookup/candidateStatus`),
            api.get(`/lookup/candidateFlag`),
            api.get(`/lookup/residencyStatus`),
            api.get(`/lookup/visatype`),
            api.get(`/lookup/communicationtype`),
            api.get(`/lookup/country`),
            api.get(`/lookup/state`),
            api.get(`/lookup/relationshiptype`),
            api.get(`/lookup/candidateplatformorigin`),
            api.get(`/lookup/messagesmasters`),
            api.get(`/emailtemplate`), // TODO: returns paged result - move to lookup
            api.get(`/lookup/recruitmentpeople`),
            api.get(`/lookup/candidatediscipline`),
            api.get(`/lookup/company`),
            api.get(`/lookup/identifier`),
            api.get(`/lookup/candidateevent`),
            api.get(`/lookup/rosterflighttype`),
            api.get(`/lookup/flightstatus`),
            api.get(`/lookup/accommodationstatus`),
            api.get(`/messagesmaster?${qs.stringify({ filters: [{ id: "title", value: "Availability" }] }, { allowDots: true })}`),
            api.get(`/messagesmaster?${qs.stringify({ filters: [{ id: "title", value: "Accommodation" }] }, { allowDots: true })}`),
            api.get(`/lookup/RosterApprovalStatus`),
        ];

        if (projectId) {
            apiCalls.push(api.get(`/project/${projectId}`));
        }
        // if (workorderId)
        // {
        //     apiCalls.push(api.get(`/workorder/${workorderId}`));
        // }
        if (dispatchUnitId) {
            apiCalls.push(api.get(`/dispatchunit/id/${dispatchUnitId}`));
        }
        if (rosterId) {
            apiCalls.push(api.get(`/roster/id/${rosterId}`));
        }

        if (!projectId || !dispatchUnitId)
            setFirstLoad(false);

        Promise.all(apiCalls)
            .then((data) => {
                setLookups({
                    skillPositions: data[0].data,
                    candidateTypes: data[1].data,
                    salutations: data[2].data,
                    preferences: data[3].data,
                    industries: data[4].data,
                    genders: data[5].data,
                    maritalStatuses: data[6].data,
                    candidateStatuses: data[7].data,
                    candidateFlags: data[8].data,
                    residencyStatuses: data[9].data,
                    visaTypes: data[10].data,
                    communicationTypes: data[11].data,
                    countries: data[12].data,
                    states: data[13].data,
                    relationshipTypes: data[14].data,
                    candidatePlatformOrigin: data[15].data,
                    messagesMaster: data[16].data,
                    emailTemplate: data[17].data.data,
                    recruitmentPeople: data[18].data,
                    candidateDisciplines: data[19].data,
                    companies: data[20].data,
                    identifiers: data[21].data,
                    events: data[22].data,
                    rosterFlightTypes: data[23].data,
                    rosterFlightStatuses: data[24].data,
                    rosterAccommodationStatuses: data[25].data,
                    rosterApprovalStatus: data[28].data,
                });

                setConfirmationMessageTemplateForAvailability(data[26].data.data[0]);
                setConfirmationMessageTemplateForLogistic(data[27].data.data[0]);
                setConfirmationMessage(data[26].data.data[0]);

                if (projectId && data[29]?.data) {
                    setSelectedProject(data[29].data);
                }
                else
                    setSelectedProject(null);

                // if (workorderId)
                // {
                //     setSelectedWorkorder(data[projectId ? 30 : 29d].data);
                // }

                if (dispatchUnitId && data[30]?.data) {
                    setDispatchUnit({
                        lookupId: data[30].data.dispatchUnitId,
                        lookupValue: data[30].data.dispatchUnitName,
                        lookupLabel: data[30].data.quoteNumber,
                        locationName: data[30].data.location?.lookupValue
                    });
                }
                else
                    setDispatchUnit(null);

                if (rosterId && data[31]?.data) {
                    setRoster({
                        lookupId: data[31].data.rosterId,
                        lookupValue: data[31].data.rosterName,
                        lookupLabel: data[31].data.rosterName
                    });
                }
                else
                    setRoster(null);
                
            }).catch((error) => {
                setLoading(false);
                console.error(error.response);
            });
    }, []);

    const refreshDispatchUnits = useCallback((callback) => {
        setDispatchUnits([]);
        if (selectedProject) {
            if (!firstLoad)
                setLoading(true);
            api.get(`/lookup/dispatchunit/${selectedProject.projectId}/${showArchivedDispatchUnits}`)
                .then(response => {
                    setDispatchUnits(response.data);

                    if (callback)
                        callback(response.data);
                }).catch((error) => {
                    console.error(error.response);
                })
                .finally(() => {
                    if (!firstLoad)
                        setLoading(false);
                });
        }
    }, [selectedProject, showArchivedDispatchUnits, firstLoad]);

    useEffect(() => {
        refreshDispatchUnits();
    }, [selectedProject, showArchivedDispatchUnits]);

    useEffect(() => {
        console.log('confirmationMessageTemplateForLogistic', confirmationMessageTemplateForLogistic);
    }, [confirmationMessageTemplateForLogistic]);

    useEffect(() => {
        console.log('confirmationMessageTemplateForAvailability', confirmationMessageTemplateForAvailability);
    }, [confirmationMessageTemplateForAvailability]);


    

    useEffect(() => {
        setRosters([]);
        if (dispatchUnit) {
            if (!firstLoad)
                setLoading(true);
            setQuoteNumber(dispatchUnit.lookupLabel);
            api.get(`/lookup/roster/${dispatchUnit.lookupId}`)
                .then(response => {
                    setRosters(response.data);
                }).catch((error) => {
                    console.error(error.response);
                })
                .finally(() => {
                    if (!firstLoad)
                        setLoading(false);
                });
        }
    }, [dispatchUnit]);

    useEffect(() => {
        // console.log('first load 1', { selectedProject, dispatchUnit, roster, firstLoad })
        if (!selectedProject && !dispatchUnit)
        {
            setLoading(false);
            return;
        }

        if (!firstLoad)
            return;

        loadDispacthUnitData(() => setFirstLoad(false));
    }, [selectedProject, dispatchUnit, firstLoad]);

    const loadDispacthUnitData = React.useCallback((callback) => {
        // console.log('loadDispacthUnitData', selectedProject, dispatchUnit);
        if (!selectedProject || !dispatchUnit) {
            setDispatchUnitData([]);
            return;
        }

        //if (!firstLoad)
        setLoading(true);

        setDispatchUnitData([]);
        setFilterButtonPressed(true);

        const query = {
            projectid: selectedProject.projectId,
            // startDate: formatDate(startDate, 'YYYY-MM-DD'),
            // endDate: formatDate(endDate, 'YYYY-MM-DD'),
            dispatchUnitId: dispatchUnit.lookupId,
            //rosterId: roster?.lookupId
        };

        if (!trimStartDate)
            query.startDate = formatDate(startDate, 'YYYY-MM-DD');

        if (!trimEndDate)
            query.endDate = formatDate(endDate, 'YYYY-MM-DD');

        const queryString = qs.stringify(query, { allowDots: true });

        const apiCalls = [
            api.get(`/${duapiurl}?${queryString}`),
            api.get(`/rostercandidateaccommodation/dispatchflights?dispatchUnitId=${dispatchUnit.lookupId}`)
        ];

        Promise.all(apiCalls)
            .then((responses) => {
                console.log(responses);

                const {
                    dispatchUnits,
                    filterStartDate,
                    filterEndDate
                } = responses[0].data;

                const flightData = responses[1].data;
                setFlights([...flightData]);

                const dateColumns = [];

                const start = moment(moment(filterStartDate, 'YYYY-MM-DD').format("DD/MM/YYYY"), "DD/MM/YYYY");
                const end = moment(moment(filterEndDate, 'YYYY-MM-DD').format("DD/MM/YYYY"), "DD/MM/YYYY");
                setStartDate(start);
                setEndDate(end);
                let current = moment(start);

                while (current <= end) {
                    dateColumns.push(moment(current));
                    current.add(1, 'd');
                }

                setHotColumns(dateColumns);
                setTableColumns([
                    "Dispatch Unit", // 0
                    "Roster", // 1
                    // "Status",            
                    // "Desired Entity",
                    "Slot", // 2
                    "Employee Name", // 3
                    "Info", // 4
                    "Roster Position", // 5
                    "Employed Position", // 6
                    "Candidate Type", // 7
                    "Shift", // 8
                    "Confirmation Status", // 9
                    "Entity", // 10
                    "Overlap Status", // 11
                    "Fatigue Flag", // 12
                    ...dateColumns.map((m) => m.format('DD/MM/YYYY'))
                ]);
                /*
                setTableColumns([
                    "Dispatch Unit", // 0
                    "Roster", // 1
                    // "Status",            
                    // "Desired Entity",
                    "Slot", // 2
                    "Employee Name", // 3
                    "Info", // 4
                    "Roster Position", // 5
                    "Employed Position", // 6
                    "Candidate Type", // 7
                    "Shift", // 8
                    "Status", // 9
                    "Availability", // 10
                    "Logistic", // 11
                    "Entity", //12
                    "Overlap Status", // 13
                    ...dateColumns.map((m) => m.format('DD/MM/YYYY'))
                ]);
                */

                const duData = dispatchUnits;

                if (!duData || !duData?.length || duData?.length === 0) {
                    return;
                }

                let rowIdx = 0;
                let firstIndex = 0;
                let firstDate = null;
                let rosterStatus = [];

                const tableData = [
                    ...duData.map((du, k) => {
                        let currentDuIdx = rowIdx;
                        rowIdx++;

                        return {
                            ...du,
                            index: { row: currentDuIdx, column: 0 },
                            __children: [
                                ...du.rosters?.map((r, h) => {
                                    let currentRosterIdx = rowIdx;
                                    rowIdx++;

                                    // console.log('roster: ', r)
                                    rosterStatus.push(r.status.lookupValue);
                                    let lastRelatedRosterCandidateId = 0;
                                    let currentRelatedIndex = 0;
                                    let parentIndex = -1;
                                    return {
                                        ...r,
                                        index: { row: currentRosterIdx, column: 1 },
                                        __children: r.rosterCandidates?.map((rc, i) => {
                                            const talentStartDate = moment(rc.startDate, "YYYY-MM-DD");
                                            const talentEndDate = moment(rc.endDate, "YYYY-MM-DD");

                                            const duplicateDates = rc.rosterCandidateSchedules?.map((x) => {
                                                return {
                                                    schDate: moment(x.date, "YYYY-MM-DD"),
                                                    rosterScheduleTypeId: x.rosterScheduleType.rosterScheduleTypeId,
                                                    rosterScheduleTypeCode: x.rosterScheduleType.rosterScheduleTypeCode,
                                                    shift: x.shift,
                                                }
                                            });
                                            const dates = dateColumns.map((d) => {
                                                const s = rc.rosterCandidateSchedules.find((x) => moment(x.date, "YYYY-MM-DD").format("YYYYMMDD") === d.format("YYYYMMDD"));

                                                if (s) {
                                                    return {
                                                        schDate: moment(s.date, "YYYY-MM-DD"),
                                                        rosterScheduleTypeId: s.rosterScheduleType.rosterScheduleTypeId,
                                                        rosterScheduleTypeCode: s.rosterScheduleType.rosterScheduleTypeCode,
                                                        shift: s.shift,
                                                        rosterCandidateScheduleId: rc.rosterCandidateSchedules.filter(x => moment(x.date, "YYYY-MM-DD").format("YYYYMMDD") === d.format("YYYYMMDD")).map(x => x.rosterCandidateScheduleId),
                                                    }
                                                } else {
                                                    return {
                                                        schDate: d,
                                                        rosterScheduleTypeId: null,
                                                        rosterScheduleTypeCode: '',
                                                        shift: null,
                                                        rosterCandidateScheduleId: null,
                                                    }
                                                }
                                            });

                                            let currentRCIdx = rowIdx;
                                            let currentTalentRowIdx = rowIdx;
                                            rowIdx++;
                                            // console.log("check related roster: ", {rc});

                                            if (rc.relatedRosterCandidateId) {
                                                currentRelatedIndex = rc.relatedRosterCandidateId === lastRelatedRosterCandidateId ? currentRelatedIndex + 1 : 0;
                                                lastRelatedRosterCandidateId = rc.relatedRosterCandidateId;
                                            }

                                            if (!rc.relatedRosterCandidateId)
                                                parentIndex++;

                                            return {
                                                ...rc,
                                                staticIsOverlapped: rc.isOverlapped !== null ? (rc.isOverlapped ? "Y" : "N") : null,
                                                isOverlapped: rc.isOverlapped !== null ? (rc.isOverlapped ? "Y" : "N") : null,
                                                talentIndex: { row: currentRCIdx, column: talentColumnIndex },
                                                positionIndex: { row: currentRCIdx, column: positionColumnIndex },
                                                slotNo: rc.relatedRosterCandidateId ? `${rc.relatedRosterCandidateNumber}.${rc.rosterCandidateNumber}` : `${rc.rosterCandidateNumber}`,
                                                // slotNo: rc.relatedRosterCandidateId ? `${parentIndex + 1}.${currentRelatedIndex + 1}` : `${parentIndex + 1}`,
                                                talent: rc.candidateId === null ? '' : `${rc.candidateName} - ${rc.candidateId}`,
                                                positionName: rc.hrispositionMaster ? `${rc.hrispositionMaster.hrispositionMasterName} - ${rc.hrispositionMaster.hrispositionMasterId}` : "",
                                                naturalPositionName: rc.position ? `${rc.position.positionName} - ${rc.position.positionId}` : "",
                                                info: rc.requestDetail?.jobOrderId ? "JobOrder" : (rc.requestDetailId ? "RecruitmentRequest" : "Info"),
                                                index: [k, h, rc.relatedRosterCandidateId ? `${parentIndex}.${currentRelatedIndex + 1}` : `${parentIndex}`],
                                                numIndex: [k, h, i],
                                                shiftLabel: rc.shift ? `${rc.shift?.lookupValue} - ${rc.shift?.lookupId}` : null,
                                                candidateType: rc.candidate?.candidateType,
                                                project: { ...selectedProject },
                                                dispatchUnit: {
                                                    dispatchUnitId: dispatchUnit.lookupId,
                                                    dispatchUnitName: dispatchUnit.lookupValue
                                                },
                                                roster: {
                                                    rosterId: r.rosterId,
                                                    rosterName: r.rosterName
                                                },
                                                //availabilityStatus: rc.availabilityStatus,
                                                //logisticStatus: rc.logisticStatus,
                                                dates: Object.assign(...dates.map((m, idx) => {
                                                    let value = "";
                                                    //const currentDate = moment(m.schDate, "YYYY-MM-DD");
                                                    const currentDate = m.schDate;
                                                    //console.log('date', {currentDate, idx, m})

                                                    const arr = duplicateDates?.filter(x => x.schDate.format('YYYYMMDD') === m.schDate.format('YYYYMMDD'));
                                                    arr.sort((a, b) => {
                                                        return a.rosterScheduleTypeId - b.rosterScheduleTypeId;
                                                    });
                                                    arr.forEach((x) => {
                                                        value = value + x.rosterScheduleTypeCode;
                                                    });
                                                    if (m.shift?.lookupValue === 'Night') {
                                                        value = value.replace('W', 'N');
                                                    }

                                                    const dateColumn = dateColumns.filter(x => x.format("YYYYMMDD") === currentDate.format("YYYYMMDD"))[0];
                                                    let currIdx = idx;

                                                    if (dateColumn) {
                                                        currIdx = dateColumns.indexOf(dateColumn);
                                                    }

                                                    if ((!firstDate || currentDate < firstDate) && value) {
                                                        firstIndex = idx;
                                                        firstDate = currentDate;
                                                    }

                                                    const currentFlights = flightData.filter(f => m.rosterCandidateScheduleId && m.rosterCandidateScheduleId.filter(x => x === f.rosterCandidateScheduleId).length > 0);
                                                    const currentConfirmedFlights = flightData.filter(f => m.rosterCandidateScheduleId && m.rosterCandidateScheduleId.filter(x => x === f.rosterCandidateScheduleId).length > 0 && f.flightStatus && (f.flightStatus.lookupValue === "Booked" || f.flightStatus.lookupValue === "Confirmed"));
                                                    const flightConfirmed = currentConfirmedFlights.length > 0;

                                                    m[currentDate.format("YYYYMMDD")] = {
                                                        value: value,
                                                        index: { row: currentTalentRowIdx, column: 10 + currIdx },
                                                        flightConfirmed,
                                                        flights: currentFlights,
                                                        rosterCandidateScheduleId: m.rosterCandidateScheduleId ? m.rosterCandidateScheduleId.filter(x => currentFlights.filter(f => f.rosterCandidateScheduleId === x).length > 0)[0] : 0
                                                    };

                                                    return m;
                                                }))

                                            }
                                        }
                                        )
                                    }
                                }
                                )
                            ]
                        }
                    }
                    )
                ];
                setFirstColumn(firstIndex < 12 ? 0 : firstIndex + 4);

                if (rosterStatus.some(s => s === "Cancelled")) {
                    setDisableActions(true);
                } else {
                    setDisableActions(false);
                }

                console.log("data: ", tableData, firstIndex, rosterStatus);

                setDispatchUnitData(tableData);
                setRefreshOverlapStatus(true);
                if (callback)
                    callback(tableData);
                else
                    setLoading(false);
            }).catch((error) => {
                setErrorMessage(`${errorMessageOpening}${error.message}`);
                console.error({ error });
                setLoading(false);
            });
    }, [selectedProject, startDate, endDate, dispatchUnit, roster, hotColumns, firstLoad, trimStartDate, trimEndDate]);



    const searchName = useCallback(
        debounce((query, process) => {
            const hotTable = hotTableComponent?.current?.hotInstance;

            setLoading(true);
            let queryString = null;

            const selected = hotTable.getSelected();
            const sourceData = hotTable.getSourceDataAtRow(selected[0][0]);
            // console.log('searchName', {sourceData, selected});

            if (query && query !== "" && sourceData?.startDate && sourceData?.endDate)
                queryString = qs.stringify({ filter: query, startDate: sourceData.startDate, endDate: sourceData.endDate }, { allowDots: true });
            else if (sourceData?.startDate && sourceData?.endDate)
                queryString = qs.stringify({ startDate: sourceData.startDate, endDate: sourceData.endDate }, { allowDots: true });
            // console.log('searchName', {sourceData, selected, queryString});

            if (!queryString || queryString === "") {
                setLoading(false);
                return;
            }

            api.get(`/candidate/searchname${queryString ? `?${queryString}` : ""}`)
                .then((response) => {
                    // console.log({response});
                    if (!response.data || response.data.length === 0) {
                        process([]);
                        return;
                    }

                    process(response.data.map(x => `${x.candidateName} - ${x.candidateId}`));
                })
                .catch((error) => console.log(error.response))
                .finally(() => setLoading(false));
        }, 300)
        , [hotTableComponent.current]);

    const searchPosition = useCallback(
        debounce((query, process) => {
            setLoading(true);
            let queryString = null;

            if (query && query !== "") {
                queryString = qs.stringify({ filter: query }, { allowDots: true })
            }

            api.get(`/hrispositionmaster/searchname${queryString ? `?${queryString}` : ""}`)
                .then((response) => {
                    if (!response.data || response.data.length === 0) {
                        process([]);
                        return;
                    }

                    const data = response.data.map(x => `${x.hrispositionMasterName} - ${x.hrispositionMasterId}`);
                    data.sort();

                    process(data);
                })
                .catch((error) => console.log(error.response))
                .finally(() => setLoading(false));
        }, 200)
        , []);

    const searchShift = useCallback(
        debounce((query, process) => {
            setLoading(true);
            let queryString = null;

            if (query && query !== "") {
                queryString = qs.stringify({ filter: query }, { allowDots: true })
            }

            api.get(`/lookup/shift${query && query !== "" ? `/$${query}` : ""}`)
                .then((response) => {
                    if (!response.data || response.data.length === 0) {
                        process([]);
                        return;
                    }

                    const data = response.data.map(x => `${x.lookupLabel} - ${x.lookupId}`);
                    data.sort();

                    process(data);
                })
                .catch((error) => console.log(error.response))
                .finally(() => setLoading(false));
        }, 200)
        , []);

    useEffect(() => {
        if (!selectedCellGlobal || !selectedCellGlobal.length)
            return;

        // console.log({selectedCellGlobal, logisticInEdit});

        setSelectedDispatchUnit(null);
        setSelectedRoster(null);
        setLogisticInEdit(null);
        const duList = [];
        const rosterList = [];
        const talentList = [];

        selectedCellGlobal.forEach(s => {
            const selected = {
                row: s[0],
                column: s[1],
                row2: s[2],
                column2: s[3]
            }

            let row = selected.row;

            while (row <= selected.row2) {
                let col = selected.column;
                while (col <= selected.column2) {
                    if (
                        dispatchUnitData?.filter(x => {
                            return x.index.row === row && x.index.column === col;
                        }).length > 0
                    ) {
                        const du = {
                            ...dispatchUnitData.filter(x => {
                                return x.index.row === row && x.index.column === col;
                            })[0]
                        };
                        //console.log('du', du);

                        if (duList.filter(x => x.dispatchUnitId === du.dispatchUnitId).length === 0)
                            duList.push(du);

                        col++;
                        continue;
                    }

                    if (
                        dispatchUnitData?.filter(d => {
                            return d.__children?.filter(x => x.index.row === row && x.index.column === col).length > 0;
                        }).length > 0
                    ) {
                        const du = {
                            ...dispatchUnitData.filter(d => {
                                return d.__children.filter(x => x.index.row === row && x.index.column === col).length > 0;
                            })[0]
                        };
                        //console.log('dispatchUnitData 1', dispatchUnitData);
                        //console.log('du 1', du);

                        const roster = {
                            ...du.__children.filter(x => x.index.row === row && x.index.column === col).map(x =>
                            ({
                                ...x,
                                dispatchUnitName: du.dispatchUnitName,
                                quoteNumber: du.quoteNumber,
                                locationName: du.location.lookupValue,
                                dispatchStartDate: du.startDate,
                                dispatchEndDate: du.endDate
                            }))[0]
                        };
                        roster.dispatchUnitId = du.dispatchUnitId;
                        roster.dispatchUnit = du;

                        if (duList.filter(x => x.dispatchUnitId === du.dispatchUnitId).length === 0)
                            duList.push(du);

                        if (rosterList.filter(x => x.rosterId === roster.rosterId).length === 0)
                            rosterList.push(roster);

                        col++;
                        continue;
                    }

                    if (
                        dispatchUnitData?.filter(d => {
                            return d.__children?.filter(r => {
                                return r.__children?.filter(x => x.talentIndex.row === row && x.talentIndex.column === col).length > 0;
                            }).length > 0;
                        }).length > 0
                    ) {
                        const du = {
                            ...dispatchUnitData?.filter(d => {
                                return d.__children?.filter(r => {
                                    return r.__children?.filter(x => x.talentIndex.row === row && x.talentIndex.column === col).length > 0;
                                }).length > 0;
                            })[0]
                        };

                        //console.log('dispatchUnitData 2', dispatchUnitData);
                        //console.log('du 2', du);

                        const roster = {
                            ...du.__children?.filter(r => {
                                return r.__children?.filter(x => x.talentIndex.row === row && x.talentIndex.column === col).length > 0;
                            }).map(x => {
                                // console.log('x', x);
                                return ({
                                    ...x,
                                    dispatchUnitName: du.dispatchUnitName,
                                    quoteNumber: du.quoteNumber,
                                    locationName: du.location.lookupValue,
                                    dispatchStartDate: du.startDate,
                                    dispatchEndDate: du.endDate
                                });
                            }

                            )[0]
                        };
                        roster.dispatchUnitId = du.dispatchUnitId;
                        roster.dispatchUnit = du;
                        const talent = {
                            ...roster.__children?.filter(x => x.talentIndex.row === row && x.talentIndex.column === col)
                                .map(x => ({
                                    ...x,
                                    rosterName: roster.rosterName,
                                    dispatchUnitId: roster.dispatchUnitId,
                                    dispatchUnitName: du.dispatchUnitName,
                                    quoteNumber: du.quoteNumber,
                                    locationName: du.location.lookupValue,
                                    dispatchStartDate: du.startDate,
                                    dispatchEndDate: du.endDate
                                }))[0]
                        };
                        talent.rosterId = roster.rosterId;
                        talent.roster = roster;

                        if (duList.filter(x => x.dispatchUnitId === du.dispatchUnitId).length === 0)
                            duList.push(du);

                        if (rosterList.filter(x => x.rosterId === roster.rosterId).length === 0)
                            rosterList.push(roster);

                        if (talentList.filter(x => x.rosterCandidateId === talent.rosterCandidateId).length === 0)
                            talentList.push(talent);

                        col++;
                        continue;
                    }

                    col++;
                }

                row++;
            }
        });

        // console.log("on selected cells: ", {duList, rosterList, talentList});

        if (duList.length > 0)
            setSelectedDispatchUnit(duList);

        if (rosterList.length > 0)
            setSelectedRoster(rosterList);

        if (talentList.length > 0)
            setLogisticInEdit(talentList);
    }, [selectedCellGlobal]);

    useEffect(() => {
        if (!dispatchUnitData?.length || !refreshOverlapStatus)
            return;

        setLoading(true);
        const apiCalls = [];

        dispatchUnitData.forEach(du => {
            du.__children.forEach(r => {
                r.__children.filter(rc => rc.candidateId).forEach(rc => {
                    if (!rc.dates)
                        return;

                    const dates = [];
                    Object.keys(rc.dates).forEach(d => {
                        if (rc.dates[d] && rc.dates[d].value !== "" && d !== "schDate" && d !== "rosterScheduleTypeCode" && d !== "rosterScheduleTypeId" && d !== "shift" && d !== "rosterCandidateScheduleId")
                            dates.push(d);
                    });

                    if (!dates.length)
                        return;

                    dates.sort();
                    const startDate = moment(dates[0], "YYYYMMDD").format("YYYY-MM-DD");
                    const endDate = moment(dates[dates.length - 1], "YYYYMMDD").format("YYYY-MM-DD");

                    apiCalls.push(api.get(`/rosterwizard/check-conflicts?candidateId=${rc.candidateId}&startDate=${startDate}&endDate=${endDate}&rowNumber=${rc.talentIndex.row}&rosterCandidateId=${rc.rosterCandidateId}`));
                })
            });
        });

        if (!apiCalls.length) {
            setLoading(false);
            setRefreshOverlapStatus(false);
            return;
        }

        const newData = JSON.parse(JSON.stringify(dispatchUnitData));

        Promise.all(apiCalls)
            .then((responses) => {
                responses.forEach(response => {
                    newData.forEach(du => {
                        du.__children.forEach(r => {
                            r.__children.filter(rc => rc.talentIndex.row === response.data?.rowNumber).forEach(rc => {
                                //console.log('rc', rc)
                                rc.isOverlapped = response.data.isOverlapped ? "Y" : "N";
                            });
                        });
                    });
                });
                //console.log('newData', newData)
                setDispatchUnitData(newData);
            })
            .catch((error) => console.log(error.response))
            .finally(() => setLoading(false))
            ;

        setRefreshOverlapStatus(false);
    }, [dispatchUnitData, refreshOverlapStatus]);

    useEffect(() => {
        // console.log("dispatch unit data changed: ", {logisticInEdit});

        if (
            !dispatchUnitData || dispatchUnitData.length === 0
            || (
                (!selectedDispatchUnit || selectedDispatchUnit.length === 0)
                && (!selectedRoster || selectedRoster.length === 0)
                && (!logisticInEdit || logisticInEdit.length === 0)
            )
        )
            return;

        if (selectedDispatchUnit?.length > 0) {
            const newDuList = dispatchUnitData
                .filter(d => selectedDispatchUnit.filter(du => du.dispatchUnitId === d.dispatchUnitId)?.length > 0)
                .map(d => ({ ...d }));
            setSelectedDispatchUnit(newDuList);
        }

        if (selectedRoster?.length > 0) {
            const newRosterList = [];
            dispatchUnitData
                .forEach(d => {
                    if (!d.__children)
                        return;

                    d.__children.forEach(r => {
                        if (selectedRoster.filter(x => x.rosterId === r.rosterId)?.length > 0)
                            newRosterList.push({
                                ...r,
                                dispatchUnitName: d.dispatchUnitName,
                                quoteNumber: d.quoteNumber,
                                locationName: d.location.lookupValue,
                                dispatchStartDate: d.startDate,
                                dispatchEndDate: d.endDate
                            });
                    });
                }
                );
            setSelectedRoster(newRosterList);
        }

        if (logisticInEdit?.length > 0) {
            const newLogisticInEdit = [];
            dispatchUnitData
                .forEach(d => {
                    // console.log("on du data changed - check du data: ", {d});
                    if (!d.__children)
                        return;

                    d.__children.forEach(r => {
                        // console.log("on du data changed - check roster data: ", {r});

                        if (!r.__children)
                            return;

                        r.__children.forEach(t => {
                            if (logisticInEdit.filter(x => x.rosterId === t.rosterId && x.slotNo === t.slotNo)?.length > 0)
                                newLogisticInEdit.push({
                                    ...t,
                                    rosterName: r.rosterName,
                                    dispatchUnitId: d.dispatchUnitId,
                                    dispatchUnitName: d.dispatchUnitName,
                                    rosterId: r.rosterId,
                                    quoteNumber: d.quoteNumber,
                                    locationName: d.location.lookupValue,
                                    dispatchStartDate: d.startDate,
                                    dispatchEndDate: d.endDate
                                });
                        });
                    });
                }
                );
            // console.log("on du data changed - logistic found: ", {newLogisticInEdit});
            setLogisticInEdit(newLogisticInEdit);
        }

    }, [dispatchUnitData]);

    // BEGIN COUNT TOTAL SLOT AND TOTAL POSITION --------------------------------------------------------------------------------
    const countTotalSlotAndPosition = useCallback(() => {

        try {
            // If data empty
            if (dispatchUnitData == null || dispatchUnitData.length == 0 || !filterButtonPressed) {
                setTotalSlot(null);
                setTotalPosition(null);
                return;
            }

            var totalSlotRaw = 0;
            var totalPositionRaw = 0;
            var hrisPositionMasterIdRawList = [];
            var tableData = dispatchUnitData.filter(() => true);




            // Check each item
            tableData.forEach(oneDispatchUnit => {

                oneDispatchUnit.__children.forEach(oneRoster => {

                    // Accumulate slot used
                    totalSlotRaw += oneRoster.__children.length;

                    oneRoster.__children.forEach(oneSlot => {

                        // Accumulate unique value for
                        // hris position master id
                        var posId = oneSlot.hrispositionMasterId;
                        if (hrisPositionMasterIdRawList.indexOf(posId) === -1) {
                            hrisPositionMasterIdRawList.push(posId);
                        }
                    });
                });
            });

            // Count Position 
            totalPositionRaw = hrisPositionMasterIdRawList.length;


            // Set total slot
            setTotalSlot(totalSlotRaw);
            setTotalPosition(totalPositionRaw);

        }
        catch (err) {
            console.log("Error Non countable total slot and total position", err);

            // Set total slot and position
            setTotalSlot(null);
            setTotalPosition(null);

        }
    }, [dispatchUnitData]);

    useEffect(() => {
        countTotalSlotAndPosition();
    }, [dispatchUnitData]);
    // END COUNT TOTAL SLOT AND TOTAL POSITION --------------------------------------------------------------------------------


    const generateCandidateMessageList = useCallback(() => {
        const newCandidateMessageList = { 
            candidates: [], 
            rosterId: selectedRoster && selectedRoster.length ? selectedRoster[0].rosterId : 0, 
            dispatchUnitId: selectedDispatchUnit && selectedDispatchUnit.length ? selectedDispatchUnit[0].dispatchUnitId : 0 
        };
        //rosterCandidateId
        // console.log('generateCandidateMessageList: ', { logisticInEdit, selectedRoster, selectedDispatchUnit, newCandidateMessageList })

        if (logisticInEdit && logisticInEdit.length > 0) {
            if (logisticInEdit.length === 1) {
                newCandidateMessageList.rosterCandidateId = logisticInEdit[0].rosterCandidateId
            }
            logisticInEdit.forEach(c => {
                // console.log(c);
                if (c.candidateId && c.candidateId > 0)
                    newCandidateMessageList.candidates.push({ ...c });
            });
            setCandidateMessageList(newCandidateMessageList);
            return;
        }

        if (selectedRoster && selectedRoster.length > 0) {
            selectedRoster.forEach(r => {
                r.__children.forEach(c => {
                    if (c.candidateId && c.candidateId > 0)
                        newCandidateMessageList.candidates.push({ ...r, ...c.candidate, rosterCandidateId: c.rosterCandidateId });
                })
            });
            setCandidateMessageList(newCandidateMessageList);
            return;
        }

        if (selectedDispatchUnit && selectedDispatchUnit.length > 0) {
            selectedDispatchUnit.forEach(d => {
                d.__children.forEach(r => {
                    r.__children.forEach(c => {
                        if (c.candidateId && c.candidateId > 0)
                            newCandidateMessageList.candidates.push({ ...d, ...r, ...c.candidate, rosterCandidateId: c.rosterCandidateId });
                    })
                })
            });
            setCandidateMessageList(newCandidateMessageList);
            return;
        }
    }, [selectedDispatchUnit, selectedRoster, logisticInEdit]);

    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            onConfirm: () => setErrorMessage(null),
            message: errorMessage
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage]);

    const hiddenAccommodationItem = useCallback(() => {
        return (
            (!selectedDispatchUnit || selectedDispatchUnit.length === 0)
            && (!selectedRoster || selectedRoster.length === 0)
            && (!logisticInEdit || logisticInEdit.length === 0)
        )
    }, [selectedDispatchUnit, selectedRoster, logisticInEdit]);

    const hiddenAvailabilityItem = useCallback(() => {
        const hot = hotTableComponent.current?.hotInstance;

        if (!hot || !selectedCellGlobal || !selectedCellGlobal.length)
            return true;

        let containRestrictedColumns = false;

        for (let index = 0; index < selectedCellGlobal.length && !containRestrictedColumns; index++) {
            const s = selectedCellGlobal[index];
            // console.log(s);

            if (
                s[1] < 0 || s[3] < 0
                || hot.colToProp(s[1]).toLowerCase() === "dispatchunitname" || hot.colToProp(s[3]).toLowerCase() === "dispatchunitname"
                || hot.colToProp(s[1]).toLowerCase() === "rostername" || hot.colToProp(s[3]).toLowerCase() === "rostername"
                || hot.colToProp(s[1]).toLowerCase() === "talent" || hot.colToProp(s[3]).toLowerCase() === "talent"
            )
                containRestrictedColumns = true;
        }

        return (
            ((!selectedDispatchUnit || selectedDispatchUnit.length === 0) && containRestrictedColumns)
            || (!selectedRoster || selectedRoster.length === 0 && containRestrictedColumns)
            || ((!logisticInEdit || logisticInEdit.length === 0 || logisticInEdit.filter(l => !l.candidateId).length > 0) && containRestrictedColumns)
        );
    }, [hotTableComponent?.current, selectedDispatchUnit, selectedRoster, logisticInEdit, selectedCellGlobal]);

    const hiddenMultipleTalentSpecificItem = useCallback(() => {
        const hot = hotTableComponent.current?.hotInstance;

        if (!hot || !selectedCellGlobal || !selectedCellGlobal.length)
            return true;

        const talentColumnIndex = hot.propToCol("talent");

        for (let index = 0; index < selectedCellGlobal.length; index++) {
            const s = selectedCellGlobal[index];
            const rowData = hot.getSourceDataAtRow(s[0]);

            if (s[0] !== s[2] || s[1] !== s[3] || s[1] !== talentColumnIndex || !rowData?.index || !rowData.index?.length || !rowData.talent || !rowData.talent === "")
                return true;
        }

        return false;
    }, [hotTableComponent?.current, selectedCellGlobal]);

    const hiddenTalentSpecificItem = useCallback(() => {
        const hot = hotTableComponent.current?.hotInstance;

        if (!hot || !selectedCellGlobal || !selectedCellGlobal.length || selectedCellGlobal.length > 1)
            return true;

        const talentColumnIndex = hot.propToCol("talent");
        const s = selectedCellGlobal[0];
        const rowData = hot.getSourceDataAtRow(s[0]);

        if (s[0] !== s[2] || s[1] !== s[3] || s[1] !== talentColumnIndex || !rowData?.index || !rowData.index?.length || !rowData.talent || !rowData.talent === "")
            return true;

        return false;
    }, [hotTableComponent?.current, selectedCellGlobal]);

    const hiddenItem = useCallback(() => {
        const hot = hotTableComponent.current?.hotInstance;
        // console.log({selectedCellGlobal});

        if (!hot || !selectedCellGlobal || !selectedCellGlobal.length)
            return true;

        let validationResult = true;
        const lastColumnIndex = hot.propToCol("isOverlapped");
        // console.log(lastColumnIndex);

        for (let index = 0; index < selectedCellGlobal.length && validationResult; index++) {
            const s = selectedCellGlobal[index];

            if (s[1] <= lastColumnIndex || s[3] <= lastColumnIndex) {
                validationResult = false;
                continue;
            }

            for (let i = s[0]; i <= s[2] && validationResult; i++) {
                const rowData = hot.getSourceDataAtRow(i);

                if (!rowData?.index || !rowData.index?.length) {
                    validationResult = false;
                    continue;
                }
            }
        }

        if (!validationResult)
            return true;

        return false;
    }, [hotTableComponent?.current, selectedCellGlobal]);

    const hiddenTalentItem = useCallback(() => {
        const hot = hotTableComponent.current?.hotInstance;

        if (!hot || !selectedCellGlobal || !selectedCellGlobal.length)
            return true;

        let validationResult = true;
        const firstColumnIndex = hot.propToCol("dispatchUnitName");
        const lastColumnIndex = hot.propToCol("rosterCandidateCompany");

        for (let index = 0; index < selectedCellGlobal.length && validationResult; index++) {
            const s = selectedCellGlobal[index];

            if (s[1] <= firstColumnIndex || s[3] <= firstColumnIndex || s[1] >= lastColumnIndex || s[3] >= lastColumnIndex) {
                validationResult = false;
                continue;
            }

            for (let i = s[0]; i <= s[2] && validationResult; i++) {
                const rowData = hot.getSourceDataAtRow(i);

                if (!rowData?.index || !rowData.index?.length) {
                    validationResult = false;
                    continue;
                }
            }
        }

        if (!validationResult)
            return true;

        return false;
    }, [hotTableComponent?.current, selectedCellGlobal]);

    const hiddenTalentRelatedRosterItem = useCallback(() => {
        const hot = hotTableComponent.current?.hotInstance;
        // console.log(selectedCellGlobal);

        if (!hot || !selectedCellGlobal || !selectedCellGlobal.length || selectedCellGlobal.length > 1)
            return true;

        const firstColumnIndex = hot.propToCol("dispatchUnitName");
        const lastColumnIndex = hot.propToCol("rosterCandidateCompany");
        const s = selectedCellGlobal[0];
        const rowData = hot.getSourceDataAtRow(s[0]);

        if (s[0] !== s[2] || s[1] <= firstColumnIndex || s[3] <= firstColumnIndex || s[1] >= lastColumnIndex || s[3] >= lastColumnIndex || !rowData?.index || !rowData.index?.length || rowData.relatedRosterCandidateId)
            return true;

        return false;
    }, [hotTableComponent?.current, selectedCellGlobal]);

    const hiddenSingleItem = useCallback(() => {
        const hot = hotTableComponent.current?.hotInstance;

        if (!hot || !selectedCellGlobal || !selectedCellGlobal.length || selectedCellGlobal.length > 1)
            return true;

        const firstColumnIndex = hot.propToCol("dispatchUnitName");
        const lastColumnIndex = hot.propToCol("rosterCandidateCompany");
        const s = selectedCellGlobal[0];
        const rowData = hot.getSourceDataAtRow(s[0]);

        if (s[0] !== s[2] || s[1] <= firstColumnIndex || s[3] <= firstColumnIndex || s[1] >= lastColumnIndex || s[3] >= lastColumnIndex || !rowData?.index || !rowData.index?.length)
            return true;

        return false;
    }, [hotTableComponent?.current, selectedCellGlobal]);

    const handleGenerateTalent = useCallback(() => {
        setLoading(true);

        saveProcess()
            .then(() => {
                loadDispacthUnitData((newDu) => {
                    const filters = [];
                    const exclude = [];
                    newDu.forEach(d => {
                        d.__children.forEach(r => {
                            r.__children.forEach(c => {
                                if (c.candidateId == null && c.requestDetailId == null) {
                                    // console.log({c});
                                    if (c.startDate && c.endDate && parseInt(moment(c.startDate, "YYYY-MM-DD").format("YYYY")) > 1900 && parseInt(moment(c.endDate, "YYYY-MM-DD").format("YYYY")) > 1900)
                                        filters.push({
                                            projectId: d.projectId,
                                            dispatchUnitId: d.dispatchUnitId,
                                            rosterId: r.rosterId,
                                            positionId: c.hrispositionMasterId,
                                            slotNo: `${c.slotNo}`,
                                            exclude: [],
                                            index: c.index,
                                            startDate: c.startDate,
                                            endDate: c.endDate
                                        });
                                }
                                else {
                                    if (c.candidateId != null)
                                        exclude.push(c.candidateId)
                                }
                            })
                        })
                    });
                    // console.log('filters', filters, exclude)
                    //const queryString = qs.stringify({filters: [...filters]}, { allowDots: true });

                    api.post(`/rosterwizard/roster-talents`, filters.map(m => ({ ...m, exclude }))).then((data) => {
                        // console.log('talents: ', data);
                        if (data.data && data.data.length) {
                            var newData = [...dispatchUnitData];
                            data.data.forEach((c, i) => {
                                const [i1, i2, i3] = c.index;
                                var item = newData[i1].__children[i2].__children[i3];
                                item.candidate = c.candidate;
                                item.talent = `${c.candidate.candidateName} - ${c.candidate.candidateId}`;
                                item.isOverlapped = "N";
                                item.rosterCandidateCompany = c.candidate.company;
                                item.candidateType = c.candidate.candidateType;
                            });

                            setDispatchUnitData(newData);

                        }
                    })
                        .catch((error) => console.log(error.response))
                        .finally(() => {
                            setLoading(false);
                        })
                });
            })
            .catch((error) => {
                console.log('error', error);
                setLoading(false);
            })
            // .finally(() => setLoading(false))
            ;

        //console.log('dispatchUnitData', dispatchUnitData);
        // const filters = [];
        // const exclude = [];
        // dispatchUnitData.forEach(d => {
        //     d.__children.forEach(r => {
        //         r.__children.forEach(c => {
        //             if (c.candidateId == null && c.requestDetailId == null) {
        //                 console.log({c});
        //                 if (c.startDate && c.endDate && parseInt(moment(c.startDate, "YYYY-MM-DD").format("YYYY")) > 1900 && parseInt(moment(c.endDate, "YYYY-MM-DD").format("YYYY")) > 1900)
        //                     filters.push({
        //                         projectId: d.projectId,
        //                         dispatchUnitId: d.dispatchUnitId,
        //                         rosterId: r.rosterId,
        //                         positionId: c.hrispositionMasterId,
        //                         slotNo: `${c.slotNo}`,
        //                         exclude: [],
        //                         index: c.index,
        //                         startDate: c.startDate,
        //                         endDate: c.endDate
        //                     });
        //             }
        //             else 
        //             {
        //                 if (c.candidateId != null)
        //                     exclude.push(c.candidateId)
        //             }
        //         })
        //     })
        // });
        // // console.log('filters', filters, exclude)
        // //const queryString = qs.stringify({filters: [...filters]}, { allowDots: true });

        // api.post(`/rosterwizard/roster-talents`, filters.map(m => ({...m, exclude}))).then((data) => {
        //     // console.log('talents: ', data);
        //     if (data.data && data.data.length)
        //     {
        //         var newData = [ ...dispatchUnitData ];
        //         data.data.forEach((c, i) => {
        //             const [ i1, i2, i3 ] = c.index;
        //             var item = newData[i1].__children[i2].__children[i3];
        //             item.candidate = c.candidate;
        //             item.talent = `${c.candidate.candidateName} - ${c.candidate.candidateId}`;
        //             item.isOverlapped = "N";
        //             item.rosterCandidateCompany = c.candidate.company;
        //             item.candidateType = c.candidate.candidateType;
        //         });

        //         setDispatchUnitData(newData);

        //     }
        // })
        // .catch((error) => console.log(error.response))
        // .finally(() => {
        //     setLoading(false);
        // })
    }, [dispatchUnitData]);

    const saveProcess = useCallback(() => {
        const hot = hotTableComponent.current?.hotInstance;

        if (!hot)
            return null;

        const data = [...dispatchUnitData.map((du) => {
            //console.log('du', du);
            return {
                dispatchUnitId: du.dispatchUnitId,
                rosters: du.__children.map((ro) => {
                    // console.log('ro', ro);
                    return {
                        rosterId: ro.rosterId,
                        statusId: ro.statusId,
                        candidates: ro.__children.map((ca) => {
                            // console.log('ca', ca);
                            // if (!ca.candidateId) {
                            //     const splittedStr = ca.talent.split(' - ');
                            //     ca.candidateId = parseInt(ca.talent.split(' - ')[splittedStr.length - 1]);
                            // }

                            const candidateId = ca.talent && ca.talent.trim() ? parseInt(ca.talent.split(' - ')[ca.talent.split(' - ').length - 1]) : null;
                            const hrispositionMasterId = ca.positionName && ca.positionName.trim() ? parseInt(ca.positionName.split(' - ')[ca.positionName.split(' - ').length - 1]) : null;
                            const positionId = ca.naturalPositionName && ca.naturalPositionName.trim() ? parseInt(ca.naturalPositionName.split(' - ')[ca.naturalPositionName.split(' - ').length - 1]) : null;
                            const shiftId = ca.shiftLabel && ca.shiftLabel.trim() ? parseInt(ca.shiftLabel.split(" - ")[ca.shiftLabel.split(" - ").length - 1]) ?? ca.shiftId : null;

                            // console.log("processed ca: ", {ca, candidateId, hrispositionMasterId, shiftId});
                            const statusId = ca.statusNavigation ? lookups.rosterApprovalStatus.filter(ra => ra.lookupValue.toLowerCase() === ca.statusNavigation.lookupValue.toLowerCase())[0].lookupId : null;

                            return {
                                candidateId: candidateId,
                                rosterCandidateId: ca.rosterCandidateId,
                                relatedRosterCandidateId: ca.relatedRosterCandidateId,
                                hrispositionMasterId: hrispositionMasterId,
                                positionId: positionId,
                                slotNo: ca.slotNo,
                                shiftId: shiftId,
                                companyId: ca.rosterCandidateCompany?.lookupId,
                                statusId,
                                refRosterCandidateId: ca.refRosterCandidateId ?? null,
                                dates: Object.entries(ca.dates)
                                    .filter(([key, value]) => {
                                        //console.log('filter',key)
                                        if (key === 'shift' || key === 'schDate' || key === 'rosterScheduleTypeId' || key === 'rosterScheduleTypeCode' || key === 'rosterCandidateScheduleId') {
                                            return false;
                                        }
                                        return true
                                    })
                                    .map(([key, value]) => {
                                        // console.log('map', {key, value})
                                        return {
                                            date: moment(key, 'YYYYMMDD').format('YYYY-MM-DD'),
                                            values: value?.value.split('')
                                        };
                                    }),
                            };
                        })
                    };
                })
            };
        })];

        console.log('Save:', { dispatchUnitData, sourceData: hot.getSourceData(), sourceDataArray: hot.getSourceDataArray(), data });

        return api.post(`/dispatchunit/roster`, data);
    }, [hotTableComponent, dispatchUnitData, loadDispacthUnitData]);

    const setCalendarCode = useCallback((key, selection, code) => {
        // console.log('setCalendarCode', { key, selection, code })
        const hot = hotTableComponent.current.hotInstance;
        hot.batch(() => {
            for (let r = selection[0].start.row; r <= selection[0].end.row; r++) {
                const rowData = hot.getSourceDataAtRow(r);
                if (!rowData?.index) {
                    continue;
                }
                for (let c = selection[0].start.col; c <= selection[0].end.col; c++) {
                    const cellData = hot.getDataAtCell(r, c);
                    let newData = '';
                    if (cellData && cellData.includes(code)) {
                        newData = cellData.replace(code, '');
                    }
                    else {
                        newData = cellData ? `${cellData}${code}` : code;
                    }
                    hot.setDataAtCell(r, c, newData.split('').sort().join(''), key)
                }
            }
        })
    }, [hotTableComponent.current]);

    useEffect(() => {
        if (redirectLink) {
            window.open(redirectLink, '_blank');
            setRedirectLink(null);
        }
    }, [redirectLink]);
    useEffect(() => {
        //console.log('dispatchUnitData', dispatchUnitData);
    }, [dispatchUnitData]);

    const grid = useMemo(() => {
        return (<>
            {
                dispatchUnitData && dispatchUnitData.length > 0 && tableColumns && tableColumns.length > 0 && hotColumns && hotColumns.length > 0 &&
                <HotTable
                    ref={hotTableComponent}
                    id={'hot'}
                    //comments={true}
                    className={'hot-roster'}
                    colHeaders={tableColumns}
                    data={dispatchUnitData}
                    rowHeaders={true}
                    nestedRows={true}
                    stretchH={'all'}
                    autoColumnSize={true}
                    autoRowSize={false}
                    filters={false}
                    height={`max(400px, calc(100vh - ${openedCollapse === 'collapse-1' ? '422px' : '220px'}))`}
                    licenseKey="5c26d-2087e-1df71-d6238-dfc22"
                    columnHeaderHeight={[80]}
                    outsideClickDeselects={false}
                    bindRowsWithHeaders={true}
                    manualRowMove={false}
                    manualColumnResize={true}
                    manualColumnFreeze={false}
                    manualColumnMove={false}
                    fixedColumnsLeft={talentColumnIndex + 1}
                    // selectionMode={'range'}
                    // cell={cellProcessing()}
                    contextMenu={
                        hiddenAccommodationItem() && hiddenTalentSpecificItem() && hiddenItem() && hiddenTalentItem() && hiddenAvailabilityItem() ?
                            false
                            :
                            {
                                items: {
                                    arrange_logistics: {
                                        name: 'Arrange Logistics',
                                        callback(key, selection, clickEvent) {
                                            if (!selectedDispatchUnit && !selectedRoster && !logisticInEdit)
                                                return;

                                            if (!isChanged) {
                                                setEditLogisticMode(true);
                                                return;
                                            }

                                            setLoading(true);

                                            saveProcess()
                                                .then((response) => {
                                                    loadDispacthUnitData(() => {
                                                        // console.log({selectedDispatchUnit, selectedRoster, logisticInEdit});
                                                        setIsChanged(false);
                                                        setEditLogisticMode(true);
                                                        setLoading(false);
                                                    });
                                                })
                                                .catch((error) => {
                                                    console.log('error', error);
                                                })
                                                .finally(() => setLoading(false));
                                        },
                                        hidden: hiddenAccommodationItem,
                                    },
                                    send_confirmation: {
                                        name: 'Send Availability Confirmation',
                                        callback(key, selection, clickEvent) {
                                            // console.log('send_confirmation 1', selection)
                                            setLoading(true);
                                            setBulkAvailabilityConfirmationIsOpen(false);
                                            generateCandidateMessageList();
                                            if (!isChanged) {
                                                // console.log('send_confirmation 2', confirmationMessageTemplate)
                                                setConfirmationMessage(confirmationMessageTemplateForAvailability);
                                                setShowMessageConfirmation(true);
                                                setLoading(false);
                                                return;
                                            }
                                            // console.log('send_confirmation 3', selection)

                                            saveProcess()
                                                .then((response) => {
                                                    // console.log('send_confirmation 4', response)
                                                    loadDispacthUnitData(() => {
                                                        setBulkAvailabilityConfirmationIsOpen(false);
                                                        // console.log('send_confirmation 5', confirmationMessageTemplate)
                                                        setConfirmationMessage(confirmationMessageTemplateForAvailability);
                                                        setShowMessageConfirmation(true);
                                                        setIsChanged(false);
                                                        setLoading(false);
                                                    });
                                                })
                                                .catch((error) => {
                                                    console.log('error', error);
                                                })
                                                .finally(() => setLoading(false));
                                        },
                                        hidden: hiddenAvailabilityItem,
                                    },
                                    send_message: {
                                        name: 'Send Message',
                                        callback(key, selection, clickEvent) {
                                            console.log('send_message', { key, selection, clickEvent })
                                            setLoading(true);
                                            generateCandidateMessageList();
                                            if (!isChanged) {
                                                setShowMessage(true);
                                                setLoading(false);
                                                return;
                                            }

                                            setLoading(true);

                                            saveProcess()
                                                .then((response) => {
                                                    loadDispacthUnitData(() => {
                                                        setShowMessage(true);
                                                        setIsChanged(false);
                                                        setLoading(false);
                                                    });
                                                })
                                                .catch((error) => {
                                                    console.log('error', error);
                                                })
                                                .finally(() => setLoading(false));
                                        },
                                        hidden: hiddenMultipleTalentSpecificItem,
                                    },
                                    project_attributes: {
                                        name: 'Project Specific Attributes',
                                        callback(key, selection, clickEvent) {
                                            const hotTable = this;
                                            const name = hotTable.getDataAtRowProp(selection[0].start.row, "talent");

                                            if (!name || name === "") {
                                                setValidationAlert({ title: "Unable to proceed.", type: "warning", message: "Talent must be provided in the selected schedule." });
                                                return;
                                            }

                                            const nameArr = name.split(" - ");
                                            const id = parseInt(nameArr[nameArr.length - 1]);
                                            setCandidateInEdit({ candidateId: id, project: selectedProject });
                                            console.log('project_attributes', { key, selection, clickEvent, hotTable })
                                        },
                                        hidden: hiddenTalentSpecificItem,
                                    },
                                    roster_attributes: {
                                        name: 'Roster Specific Attributes',
                                        callback(key, selection, clickEvent) {
                                            const hotTable = this;
                                            const name = hotTable.getDataAtRowProp(selection[0].start.row, "talent");

                                            // if (!name || name === "")
                                            // {
                                            //     setValidationAlert({title: "Unable to proceed.", type: "warning", message: "Talent must be provided in the selected schedule."});
                                            //     return;
                                            // }

                                            const data = hotTable.getSourceDataAtRow(selection[0].start.row);

                                            if (!data.rosterCandidateId) {
                                                setValidationAlert({ title: "Unable to proceed.", type: "warning", message: "Please save the data before setting the specific attributes." });
                                                return;
                                            }

                                            setRosterAttributeInEdit({
                                                rosterCandidateId: data.rosterCandidateId,
                                                project: selectedProject,
                                                candidate: data.candidate
                                            });
                                        },
                                        hidden: hiddenTalentSpecificItem,
                                    },
                                    sp0: '---------',
                                    bulk_availability_confirmation: {
                                        name: 'Bulk Availability Confirmation',
                                        callback(key, selection, clickEvent) {

                                            // console.log('send_confirmation 1', selection)
                                            setLoading(true);
                                            setBulkAvailabilityConfirmationIsOpen(true);
                                            setBulkConfirmationMode(BulkConfirmationMode.AVAILABILITY);
                                            if (!isChanged) {
                                                // console.log('send_confirmation 2', confirmationMessageTemplate)
                                                generateCandidateMessageList();
                                                setConfirmationMessage(confirmationMessageTemplateForAvailability);
                                                setShowMessageConfirmation(true);
                                                setLoading(false);
                                                return;
                                            }

                                            // console.log('send_confirmation 3', selection)

                                            saveProcess()
                                                .then((response) => {
                                                    // console.log('send_confirmation 4', response)
                                                    loadDispacthUnitData(() => {
                                                        //setBulkAvailabilityConfirmationIsOpen(true);
                                                        // console.log('send_confirmation 5', confirmationMessageTemplate)
                                                        generateCandidateMessageList();
                                                        setConfirmationMessage(confirmationMessageTemplateForAvailability);
                                                        setShowMessageConfirmation(true);
                                                        setIsChanged(false);
                                                        setLoading(false);
                                                    });
                                                })
                                                .catch((error) => {
                                                    console.log('error', error);
                                                })
                                                .finally(() => setLoading(false));
                                        },
                                        hidden: hiddenAccommodationItem,
                                    },
                                    bulk_logistic_confirmation: {
                                        name: 'Bulk Logistic Confirmation',
                                        callback(key, selection, clickEvent) {

                                            // console.log('send_confirmation 1', selection)
                                            setLoading(true);
                                            setBulkAvailabilityConfirmationIsOpen(true);
                                            setBulkConfirmationMode(BulkConfirmationMode.LOGISTIC);
                                            if (!isChanged) {
                                                // console.log('send_confirmation 2', confirmationMessageTemplate)
                                                generateCandidateMessageList();
                                                setConfirmationMessage(confirmationMessageTemplateForLogistic);
                                                setShowMessageConfirmation(true);
                                                setLoading(false);
                                                return;
                                            }

                                            // console.log('send_confirmation 3', selection)

                                            saveProcess()
                                                .then((response) => {
                                                    // console.log('send_confirmation 4', response)
                                                    loadDispacthUnitData(() => {
                                                        //setBulkAvailabilityConfirmationIsOpen(true);
                                                        // console.log('send_confirmation 5', confirmationMessageTemplate)
                                                        generateCandidateMessageList();
                                                        setConfirmationMessage(confirmationMessageTemplateForLogistic);
                                                        setShowMessageConfirmation(true);
                                                        setIsChanged(false);
                                                        setLoading(false);
                                                    });
                                                })
                                                .catch((error) => {
                                                    console.log('error', error);
                                                })
                                                .finally(() => setLoading(false));
                                        },
                                        hidden: hiddenAccommodationItem,
                                    },
                                    sp1: '---------',
                                    generate_contract: {
                                        name: 'Generate Contract',
                                        callback(key, selection, clickEvent) {
                                            const hot = hotTableComponent.current?.hotInstance;

                                            if (!hot)
                                                return;

                                            const rowData = hot.getSourceDataAtRow(selection[0].start.row);
                                            // console.log(rowData);
                                            const talent = rowData.talent;
                                            const company = rowData.rosterCandidateCompany;
                                            const position = rowData.naturalPositionName;
                                            const rosterCandidateId = rowData.rosterCandidateId;

                                            if (!talent || talent === "" || !company?.lookupId || !position || position === "")
                                                return;

                                            if (!rosterCandidateId) {
                                                setValidationAlert({ title: "Unable to proceed.", type: "warning", message: "Please save the data before setting the specific attributes." });
                                                return;
                                            }

                                            const splittedTalentStr = talent.split(" - ");
                                            const candidateId = parseInt(splittedTalentStr[splittedTalentStr.length - 1]);
                                            const candidateName = splittedTalentStr.slice(0, -1).join(" - ");

                                            const splittedPositionStr = position.split(" - ");
                                            const positionId = parseInt(splittedPositionStr[splittedPositionStr.length - 1]);

                                            setCandidateContractInEdit({
                                                project: selectedProject,
                                                locationId: rowData.locationId,
                                                positionId,
                                                rosterCandidateId,
                                                company,
                                                candidate: {
                                                    candidateId,
                                                    candidateName
                                                }
                                            });
                                        },
                                        hidden: () => {

                                            const hot = hotTableComponent.current?.hotInstance;

                                            if (!hot)
                                                return;

                                            const selectedRangeLast = hot.getSelectedRangeLast();
                                            if (!selectedRangeLast) {
                                                return;
                                            }

                                            const rowData = hot.getSourceDataAtRow(selectedRangeLast.to.row);
                                            const talent = rowData.talent;
                                            const company = rowData.rosterCandidateCompany;
                                            return hiddenTalentSpecificItem() || !talent || talent === "" || !company?.lookupId;
                                        },
                                    },
                                    step_up: {
                                        name: 'Step Up',
                                        callback(key, selection, clickEvent) {
                                            const hot = hotTableComponent.current?.hotInstance;

                                            if (!hot)
                                                return;

                                            const rowData = hot.getSourceDataAtRow(selection[0].start.row);
                                            // console.log(rowData);
                                            const talent = rowData.talent;
                                            const position = rowData.naturalPositionName;
                                            const company = rowData.rosterCandidateCompany;
                                            const startDate = Object.keys(rowData.dates).filter((val) => rowData.dates[val] && rowData.dates[val] !== "")[0];

                                            // console.log({talent, position, company, startDate});

                                            // if (!talent || talent === "" || !position || position === "" || !company || !startDate)
                                            //     return;
                                            if (!talent || talent === "" || !company || !startDate)
                                                return;

                                            const splittedTalentStr = talent.split(" - ");
                                            const candidateId = parseInt(splittedTalentStr[splittedTalentStr.length - 1]);
                                            const candidateName = splittedTalentStr.slice(0, -1).join(" - ");

                                            const naturalPosition = {
                                                positionId: 0,
                                                positionName: null
                                            };

                                            if (position && position !== "")
                                            {
                                                const splittedPositionStr = position.split(" - ");
                                                naturalPosition.positionId = parseInt(splittedPositionStr[splittedPositionStr.length - 1]);
                                                naturalPosition.positionName = splittedPositionStr.slice(0, -1).join(" - ");
                                            }

                                            setStepUpInEdit({
                                                rosterCandidateId: rowData.rosterCandidateId,
                                                candidate: {
                                                    candidateId,
                                                    candidateName
                                                },
                                                position: {...naturalPosition},
                                                company,
                                                startDate
                                            });
                                        },
                                        hidden: hiddenTalentSpecificItem,
                                    },
                                    sp2: '---------',
                                    confirm: {
                                        name: "Set Status to Confirmed",
                                        callback(key, selection, clickEvent) {
                                            const hot = hotTableComponent.current?.hotInstance;

                                            if (!hot)
                                                return;

                                            setLoading(true);
                                            const rowData = hot.getSourceDataAtRow(selection[0].start.row);
                                            const confirmedStatus = { ...lookups.rosterApprovalStatus.filter(r => r.lookupValue === "Confirmed")[0] };
                                            // console.log({rowData, confirmedStatus});
                                            const statusCol = hot.propToCol("statusNavigation.lookupValue");
                                            hot.setDataAtCell(selection[0].start.row, statusCol, confirmedStatus.lookupValue);

                                            saveProcess()
                                                .then(() => {
                                                    setLoading(false);
                                                    loadDispacthUnitData();
                                                })
                                                .catch((error) => {
                                                    console.log({ error });
                                                    setLoading(false);
                                                })
                                        },
                                        hidden: hiddenTalentSpecificItem
                                    },
                                    logistic_confirm: {
                                        name: "Set Status to Logistic Confirmed",
                                        callback(key, selection, clickEvent) {
                                            const hot = hotTableComponent.current?.hotInstance;

                                            if (!hot)
                                                return;

                                            setLoading(true);
                                            const rowData = hot.getSourceDataAtRow(selection[0].start.row);
                                            const confirmedStatus = { ...lookups.rosterApprovalStatus.filter(r => r.lookupValue === "Logistic Confirmed")[0] };
                                            // console.log({rowData, confirmedStatus});
                                            const statusCol = hot.propToCol("statusNavigation.lookupValue");
                                            hot.setDataAtCell(selection[0].start.row, statusCol, confirmedStatus.lookupValue);

                                            saveProcess()
                                                .then(() => {
                                                    setLoading(false);
                                                    loadDispacthUnitData();
                                                })
                                                .catch((error) => {
                                                    console.log({ error });
                                                    setLoading(false);
                                                })
                                        },
                                        hidden: hiddenTalentSpecificItem
                                    },
                                    sp3: '---------',
                                    generate_lrf: {
                                        name: 'Generate LRF',
                                        callback(key, selection, clickEvent) {
                                            const hot = hotTableComponent.current?.hotInstance;

                                            if (!hot || !selectedCellGlobal || !selectedCellGlobal.length)
                                                return true;

                                            setLoading(true);
                                            const slotNumbers = [];
                                            // console.log(selectedCellGlobal);

                                            for (let selectedIdx = 0; selectedIdx < selectedCellGlobal.length; selectedIdx++) {
                                                const selected = selectedCellGlobal[selectedIdx];
                                                // console.log(selected);

                                                for (let row = selected[0]; row <= selected[2]; row++) {
                                                    const rowData = hot.getSourceDataAtRow(row);
                                                    slotNumbers.push(rowData.slotNo);
                                                }
                                            }

                                            if (!slotNumbers.length) {
                                                setLoading(false);
                                                return;
                                            }

                                            saveProcess()
                                                .then(() => {
                                                    loadDispacthUnitData((data) => {
                                                        generateLrf(data, [...slotNumbers]);
                                                    });
                                                })
                                                .catch((error) => {
                                                    setLoading(false);
                                                    console.error(error);
                                                })
                                                ;
                                        },
                                        hidden: () => {
                                            const hot = hotTableComponent.current?.hotInstance;

                                            if (!hot || !selectedCellGlobal || !selectedCellGlobal.length)
                                                return true;

                                            for (let selectedIdx = 0; selectedIdx < selectedCellGlobal.length; selectedIdx++) {
                                                const selected = selectedCellGlobal[selectedIdx];

                                                if (selected[1] !== selected[3] || hot.colToProp(selected[1]).toLowerCase() !== "talent")
                                                    return true;

                                                for (let row = selected[0]; row <= selected[2]; row++) {
                                                    const rowData = hot.getSourceDataAtRow(row);

                                                    if (rowData.requestDetailId || rowData.talent !== "" || !rowData.positionName || rowData.positionName === "" || !rowData.naturalPositionName || rowData.naturalPositionName === "")
                                                        return true;

                                                    const propertyValues = Object.values(rowData.dates);
                                                    const filledDates = propertyValues.filter(v => v && v.value && (v.value.includes("M") || v.value.includes("S") || v.value.includes("E") || v.value.includes("D")));

                                                    if (!filledDates.length)
                                                        return true;

                                                    if (!filledDates.filter(v => v.value.includes("M") || v.value.includes("S")))
                                                        return true;

                                                    if (!filledDates.filter(v => v.value.includes("E") || v.value.includes("D")))
                                                        return true;
                                                }
                                            }

                                            return false;
                                        }
                                    },
                                    sp4: '---------',
                                    mobilise: {
                                        name: 'Mobilise',
                                        callback(key, selection, clickEvent) {
                                            setCalendarCode(key, selection, 'M')
                                        },
                                        hidden: hiddenItem
                                    },
                                    demobilise: {
                                        name: 'Demobilise',
                                        callback(key, selection, clickEvent) {
                                            setCalendarCode(key, selection, 'D')
                                        },
                                        hidden: hiddenItem
                                    },
                                    start: {
                                        name: 'Start',
                                        callback(key, selection, clickEvent) {
                                            setCalendarCode(key, selection, 'S')
                                        },
                                        hidden: hiddenItem
                                    },
                                    end: {
                                        name: 'End',
                                        callback(key, selection, clickEvent) {
                                            setCalendarCode(key, selection, 'E')
                                        },
                                        hidden: hiddenItem
                                    },
                                    working: {
                                        name: 'Working',
                                        callback(key, selection, clickEvent) {
                                            setCalendarCode(key, selection, 'W')
                                        },
                                        hidden: hiddenItem
                                    },
                                    training: {
                                        name: 'Training',
                                        callback(key, selection, clickEvent) {
                                            setCalendarCode(key, selection, 'T')
                                        },
                                        hidden: hiddenItem
                                    },
                                    leave: {
                                        name: 'Leave',
                                        callback(key, selection, clickEvent) {
                                            setCalendarCode(key, selection, 'L')
                                        },
                                        hidden: hiddenItem
                                    },
                                    randr: {
                                        name: 'R&R',
                                        callback(key, selection, clickEvent) {
                                            setCalendarCode(key, selection, 'R')
                                        },
                                        hidden: hiddenItem
                                    },
                                    sick_leave: {
                                        name: 'Sick Leave',
                                        callback(key, selection, clickEvent) {
                                            setCalendarCode(key, selection, 'U')
                                        },
                                        hidden: hiddenItem
                                    },
                                    fatigue: {
                                        name: 'Fatigue Day',
                                        callback(key, selection, clickEvent) {
                                            setCalendarCode(key, selection, 'F')
                                        },
                                        hidden: hiddenItem
                                    },
                                    lwop: {
                                        name: 'LWOP',
                                        callback(key, selection, clickEvent) {
                                            setCalendarCode(key, selection, 'P')
                                        },
                                        hidden: hiddenItem
                                    },
                                    drivein: {
                                        name: 'Drive-In',
                                        callback(key, selection, clickEvent) {
                                            setCalendarCode(key, selection, 'C')
                                        },
                                        hidden: hiddenItem
                                    },
                                    driveout: {
                                        name: 'Drive-Out',
                                        callback(key, selection, clickEvent) {
                                            setCalendarCode(key, selection, 'O')
                                        },
                                        hidden: hiddenItem
                                    },
                                    shift: {
                                        name: 'Switch shift',
                                        callback: (key, selection, clickEvent) => {
                                            // console.log('switch', { key, selection, clickEvent })
                                            const hot = hotTableComponent.current.hotInstance;
                                            hot.batch(() => {
                                                for (let r = selection[0].start.row; r <= selection[0].end.row; r++) {
                                                    const rowData = hot.getSourceDataAtRow(r);
                                                    // console.log('rowData', rowData)
                                                    if (!rowData?.index) {
                                                        continue;
                                                    }
                                                    for (let c = selection[0].start.col; c <= selection[0].end.col; c++) {

                                                        const cellData = hot.getDataAtCell(r, c);
                                                        // console.log('cellData', cellData)
                                                        let newData = '';
                                                        if (cellData && cellData.includes('N')) {
                                                            newData = cellData.replace('N', 'W');
                                                        }
                                                        else {
                                                            newData = cellData ? `${cellData.replace('W', '')}N` : 'N';
                                                        }
                                                        // console.log('newData', newData)
                                                        hot.setDataAtCell(r, c, newData.split('').sort().join(''), key)
                                                    }
                                                }
                                            })
                                        },
                                        hidden: hiddenItem
                                    },
                                    sp5: '---------',
                                    copy_slot: {
                                        name: 'Copy Slot',
                                        callback: (key, selection, clickEvent) => {
                                            const newData = [...dispatchUnitData];
                                            const hot = hotTableComponent.current.hotInstance;
                                            const [index1, index2, index3, index4] = hot.getSourceDataAtRow(selection[0].start.row).index;
                                            const parent = newData[index1].__children[index2];
                                            const originalSlot = parent.__children.filter(d => d.index[2] === index3)[0];
                                            const originalSlotIdx = parent.__children.indexOf(originalSlot);
                                            // const slot = parent.__children.filter(d => d.index[2] === index3)[0];
                                            const slot = { ...JSON.parse(JSON.stringify(originalSlot)) };
                                            const parentSlots = [...parent.__children].filter(d => !d.relatedRosterCandidateId);
                                            parentSlots.sort((a, b) => parseInt(b.slotNo) - parseInt(a.slotNo));
                                            // console.log({parent, parentSlots});
                                            const relatedLength = parent.__children.filter(d => d.relatedRosterCandidateId ? true : false).length;
                                            const slotIdx = parseInt(parentSlots[0].slotNo) + 1;
                                            const newItem = {
                                                ...slot,
                                                index: [index1, index2, `${parentSlots.length}`],
                                                numIndex: [index1, index2, parent.__children.length],
                                                candidateName: '',
                                                candidateId: null,
                                                candidate: null,
                                                talent: '',
                                                slotNo: `${slotIdx}`,
                                                rosterCandidateId: 0,
                                                statusNavigation: { ...lookups.rosterApprovalStatus.find(f => f.lookupValue == 'New') },
                                                talentIndex: { row: parentSlots.length + (index1 + 1) + (index2 + 1) + relatedLength, column: talentColumnIndex },
                                                positionIndex: { row: parentSlots.length + (index1 + 1) + (index2 + 1) + relatedLength, column: positionColumnIndex },
                                                requestDetailId: null,
                                                requestDetail: null,
                                                jobOrderCandidateId: null,
                                                jobOrderCandidate: null,
                                                info: "",
                                                rosterCandidateCompany: null,
                                                refRosterCandidateId: slot.refRosterCandidateId && slot.refRosterCandidateId > 0 ? slot.refRosterCandidateId : slot.rosterCandidateId,
                                            }
                                            parent.__children.splice(originalSlotIdx + 1, 0, newItem);
                                            setDispatchUnitData(newData);
                                            setRefreshSlotNumber(true);
                                            console.log('copy_slot callback', { newItem, newData, lookup: lookups.rosterApprovalStatus })
                                        },
                                        hidden: hiddenTalentRelatedRosterItem
                                    },
                                    add_slot: {
                                        name: 'Add Slot',
                                        callback: (key, selection, clickEvent) => {
                                            const newData = [...dispatchUnitData];
                                            const hot = hotTableComponent.current.hotInstance;
                                            const [index1, index2, index3, index4] = hot.getSourceDataAtRow(selection[0].start.row).index;
                                            const parent = newData[index1].__children[index2];
                                            const originalSlot = parent.__children.filter(d => d.index[2] === index3)[0];
                                            const originalSlotIdx = parent.__children.indexOf(originalSlot);
                                            //const slot = parent.__children[index3];
                                            const slot = { ...JSON.parse(JSON.stringify(originalSlot)) };
                                            const parentSlots = [...parent.__children].filter(d => !d.relatedRosterCandidateId);
                                            parentSlots.sort((a, b) => parseInt(b.slotNo) - parseInt(a.slotNo));
                                            // console.log({parent, parentSlots});
                                            const relatedLength = parent.__children.filter(d => d.relatedRosterCandidateId ? true : false).length;
                                            const slotIdx = parseInt(parentSlots[0].slotNo) + 1;
                                            Object.keys(slot.dates).forEach((val) => {
                                                // console.log('slot', val, slot.dates[val])
                                                if (slot.dates[val] && Object.hasOwn(slot.dates[val], 'value')) {
                                                    slot.dates[val].value = '';
                                                    slot.dates[val].flightConfirmed = false;
                                                    slot.dates[val].flights = [];
                                                    slot.dates[val].rosterCandidateScheduleId = 0;
                                                }
                                            });
                                            const newItem = {
                                                ...slot,
                                                index: [index1, index2, `${parentSlots.length}`],
                                                numIndex: [index1, index2, parent.__children.length],
                                                candidateName: '',
                                                candidateId: null,
                                                candidate: null,
                                                talent: '',
                                                slotNo: `${slotIdx}`,
                                                rosterCandidateId: 0,
                                                statusNavigation: { ...lookups.rosterApprovalStatus.filter(s => s.lookupValue === "New")[0] },
                                                rosterCandidateSchedules: [],
                                                talentIndex: { row: parentSlots.length + (index1 + 1) + (index2 + 1) + relatedLength, column: talentColumnIndex },
                                                positionIndex: { row: parentSlots.length + (index1 + 1) + (index2 + 1) + relatedLength, column: positionColumnIndex },
                                                requestDetailId: null,
                                                requestDetail: null,
                                                jobOrderCandidateId: null,
                                                jobOrderCandidate: null,
                                                info: "",
                                                rosterCandidateCompany: null,
                                                positionId: null,
                                                position: null,
                                                positionName: null,
                                                naturalPositionName: null,
                                                hrispositionId: null,
                                                hrisposition: null,
                                                hrispositionMasterId: null,
                                                hrispositionMaster: null,
                                                shiftId: null,
                                                shift: null,
                                                shiftLabel: null,
                                                targetPositionId: null,
                                                targetPosition: null,
                                                startDate: moment(startDate).format("YYYY-MM-DD"),
                                                endDate: moment(endDate).format("YYYY-MM-DD"),
                                                isOverlapped: null,
                                                staticIsOverlapped: null,
                                                refRosterCandidateId: 0,
                                            }
                                            parent.__children.splice(originalSlotIdx + 1, 0, newItem);
                                            setDispatchUnitData(newData);
                                            setRefreshSlotNumber(true);
                                            console.log('add_slot callback', { newItem, newData, lookup: lookups.rosterApprovalStatus })
                                        },
                                        hidden: hiddenTalentRelatedRosterItem
                                    },
                                    split_slot: {
                                        name: 'Split Slot',
                                        callback: (key, selection, clickEvent) => {
                                            const newData = [...dispatchUnitData];
                                            const hot = hotTableComponent.current.hotInstance;
                                            const [index1, index2, index3] = hot.getSourceDataAtRow(selection[0].start.row).index;
                                            const parent = newData[index1].__children[index2];
                                            const slot = parent.__children.filter(d => d.index[2] === index3)[0];

                                            if (!slot.rosterCandidateId) {
                                                setValidationAlert({
                                                    title: "Save Process Required",
                                                    type: "warning",
                                                    message: "Please save the new roster slot before splitting."
                                                });
                                                return;
                                            }

                                            const slotIdx = parent.__children.indexOf(slot);
                                            const relatedLength = parent.__children.filter(d => d.relatedRosterCandidateId === slot.rosterCandidateId).length;

                                            const newItem = {
                                                ...JSON.parse(JSON.stringify(slot)),
                                                index: [index1, index2, `${index3}.${relatedLength + 1}`],
                                                numIndex: [index1, index2, parent.__children.length],
                                                candidateName: '',
                                                candidateId: null,
                                                candidate: null,
                                                talent: '',
                                                slotNo: `${slot.slotNo}.${relatedLength + 1}`,
                                                rosterCandidateId: 0,
                                                relatedRosterCandidateId: slot.rosterCandidateId,
                                                statusNavigation: { ...lookups.rosterApprovalStatus.find(f => f.lookupValue == 'New') },
                                                talentIndex: { row: selection[0].start.row + relatedLength + 1, column: talentColumnIndex },
                                                positionIndex: { row: selection[0].start.row + relatedLength + 1, column: positionColumnIndex },
                                                requestDetailId: null,
                                                requestDetail: null,
                                                jobOrderCandidateId: null,
                                                jobOrderCandidate: null,
                                                info: "",
                                                rosterCandidateCompany: null,
                                                refRosterCandidateId: slot.refRosterCandidateId && slot.refRosterCandidateId > 0 ? slot.refRosterCandidateId : slot.rosterCandidateId,
                                            }

                                            Object.keys(slot.dates).forEach((val) => {
                                                // console.log('slot', val, slot.dates[val])
                                                if (newItem.dates[val] && Object.hasOwn(newItem.dates[val], 'value')) {
                                                    newItem.dates[val].value = '';
                                                }
                                            });

                                            parent.__children.splice(slotIdx + relatedLength + 1, 0, newItem);

                                            for (let rc = slotIdx + relatedLength + 2; rc < parent.__children.length; rc++) {
                                                parent.__children[rc].talentIndex.row++;
                                                parent.__children[rc].positionIndex.row++;
                                            }

                                            // console.log({newData, newItem, selectedRow: selection[0].start.row, sourceData: hot.getSourceDataAtRow(selection[0].start.row), selection, parent, slot, slotIdx, relatedLength});
                                            setDispatchUnitData(newData);
                                            setRefreshSlotNumber(true);
                                            console.log('split_slot callback', { newItem, newData, lookup: lookups.rosterApprovalStatus })
                                        },
                                        hidden: hiddenTalentRelatedRosterItem
                                    },
                                    remove_row: {
                                        name: 'Remove',
                                        callback(key, selection, clickEvent) {
                                            const hot = hotTableComponent.current.hotInstance;

                                            if (!hot)
                                                return;

                                            const rowData = hot.getSourceDataAtRow(selection[0].start.row);
                                            setDeletedData(rowData);
                                        },
                                        hidden: hiddenSingleItem
                                    },
                                    cancel_roster: {
                                        name: 'Cancel',
                                        callback(key, selection, clickEvent) {
                                            const hot = this;
                                            const id = hot.getDataAtRowProp(selection[0].start.row, 'rosterId')
                                            console.log('cancel_roster', { key, selection, clickEvent, hot, id })
                                            setConfirmCancelAlert(id);

                                        },
                                        hidden() {
                                            const selectedRangeLast = this.getSelectedRangeLast();
                                            // console.log('hiddenNonRoster', { selectedRangeLast, hot: this })

                                            if (!hiddenSingleItem())
                                                return true;

                                            if (!selectedRangeLast) {
                                                return true;
                                            }
                                            if (selectedRangeLast.to.col === this.propToCol("rosterName")) {
                                                return false
                                            }
                                            return true;
                                            // const data = this.getSourceDataAtRow(selectedRangeLast.to.row);

                                            // console.log('hiddenNonRoster', { data, selectedRangeLast, hot: this })
                                            // return !data.rosterId
                                        }
                                    },
                                }
                            }
                    }
                    /*
                    modifyColWidth={(width, column) => {


                        // > Overlap Status
                        if (column > 13) {
                            return 40;
                        }

                        // Employee Name
                        if (column === 3) {
                            return 300;
                        }

                        // Entity
                        if (column === 12) {
                            return 250;
                        }


                        // Status, Availability, Logistic
                        if (column === 9 || column === 10 || column === 11) {
                            return 200;
                        }
                        else {
                            return width + 15;
                        }
                    }}
                    */
                    modifyColWidth={(width, column) => {

                        if (column > 12) {
                            return 40;
                        }
                        else if (column === 3) {
                            return 300;
                        }
                        else if (column === 10) {
                            return 250;
                        }
                        if (column == 12) {
                            return width + 15 + 35;
                        }
                        else {
                            return width + 15;
                        }
                    }}
                    afterGetColHeader={(column, TH) => {
                        if (column < 0) {
                            return;
                        }
                        if (TH.textContent.includes("/")) {
                            Handsontable.dom.addClass(
                                TH,
                                'rotate-header'
                            );
                        }
                        if (TH.firstChild) {
                            Handsontable.dom.addClass(
                                TH.firstChild,
                                'htCenter'
                            );
                        }
                    }}
                    afterLoadData={(sourceData, initialLoad, source) => {

                        setTimeout(() => {
                            if (hotTableComponent.current) {
                                // hopefully temp solution, fixes issue with cell/header misalignment in some data scenario's
                                // test scenario used: 12 dates, 2 projects, 3 positions each
                                // Slots:
                                // proj 1: pos 1 x 3, pos 2 x 1, pos 3 x 14 (or greater)
                                // proj 2: pos 1 x 1, pos 2 x 2, pos 3 x 1
                                // 
                                //hotTableComponent.current.hotInstance.render(); 
                                if (initialLoad) {
                                    hotTableComponent.current.hotInstance.scrollViewportTo(0, firstColumn);
                                    //console.log('scrollViewportTo', firstColumn)
                                }
                            }
                        }, 100);
                        // console.log('afterLoadData', {sourceData, initialLoad, source, firstColumn})

                        // setToggleLoadData(!toggleLoadData);
                    }}
                    afterUpdateSettings={(newSettings) => {
                        // console.log('afterUpdateSettings', {newSettings})
                    }}
                    afterSelectionEnd={(row, column, row2, column2) => {
                        // console.log('afterSelectionEnd', {row, column, row2, column2})
                        const hotTable = hotTableComponent.current?.hotInstance;

                        if (!hotTable)
                            return;

                        const selected = hotTable.getSelected();
                        // if (column === column2 && row === row2)
                        // {
                        //     const prop = hotTable.colToProp(column);

                        //     if (prop === "info" || prop === "isOverlapped" || prop === "statusNavigation.lookupValue")
                        //         return;
                        // }

                        if (!selected.length)
                            return;

                        selected.forEach(s => {
                            if (s[0] > s[2]) {
                                const tmp = s[0];
                                s[0] = s[2];
                                s[2] = tmp;
                            }

                            if (s[1] > s[3]) {
                                const tmp = s[1];
                                s[1] = s[3];
                                s[3] = tmp;
                            }

                            // console.log(s);
                        });

                        setSelectedCellGlobal([...selected]);
                    }}
                    afterSetDataAtCell={(changes, source) => {
                        // console.log('afterSetDataAtCell', {changes, source});

                        if (source === "loadData" || changes.length === 0 || changes[0][1] === "isOverlapped")
                            return;

                        const hotTable = hotTableComponent.current?.hotInstance;

                        if (!hotTable)
                            return;

                        setIsChanged(true);

                        if (changes[0][1] === "talent") {
                            const rowData = hotTable.getSourceDataAtRow(changes[0][0]);
                            console.log({ rowData, changes });
                            setConfirmCandidateChange({
                                changes,
                                oldData: { ...rowData },
                                loadData: true,
                                confirmed: false,
                            });

                            return;
                        }

                        if (changes[0][1] === "positionName") {
                            const rowData = hotTable.getSourceDataAtRow(changes[0][0]);

                            if (rowData.talent && rowData.talent !== "")
                                return;

                            setLoading(true);
                            const value = changes[0][3];
                            const splittedValue = value.split(" - ");
                            const hrispositionMasterId = parseInt(splittedValue[splittedValue.length - 1]) ?? 0;
                            api.get(`hrispositionmaster/position?id=${hrispositionMasterId}`)
                                .then((response) => {
                                    setLoading(false);
                                    const naturalPositionCol = hotTable.propToCol("naturalPositionName");
                                    hotTable.setDataAtCell(changes[0][0], naturalPositionCol, `${response.data.positionName} - ${response.data.positionId}`);
                                })
                                .catch((error) => {
                                    console.log(error);
                                    setLoading(false);
                                })

                            return;
                        }
                    }}
                    beforeChange={(changes, source) => {
                        console.log('beforeChange', { changes, source });

                        // if (typeof changes[0][3] === 'object') {
                        //     return;
                        // }
                        const hotTable = hotTableComponent.current?.hotInstance;

                        if (!hotTable)
                            return;

                        setLoading(true);

                        for (let i = 0; i < changes.length; i++) {
                            //
                            // why do we need this??
                            //
                            const rowData = hotTable.getSourceDataAtRow(changes[i][0]);
                            // if (rowData?.candidateId === undefined && changes[i][1].includes("dates") && changes[i][3] !== changes[i][2] && changes[i][3] !== null && changes[i][3] !== "")
                            // {
                            //     changes[i][3] = "";
                            //     continue;
                            // }

                            // if (!changes[i][3])
                            //     changes[i][3] = "";

                            if (changes[i][3]) {
                                changes[i][3] = (changes[i][3] ? (typeof changes[i][3] === 'string' ? changes[i][3] : '') : '').toUpperCase()
                            } else {
                                changes[i][3] = ''
                            }

                            if (changes[i][1] === "slotNo") {
                                const value = changes[i][3];

                                if (!value || value === "") {
                                    setValidationAlert({
                                        type: "warning",
                                        title: "Invalid Slot Number",
                                        message: "Slot Number cannot be empty.",
                                    });
                                    changes[i][3] = rowData.slotNo;
                                    continue;
                                }

                                if (value.split(".").length > 2) {
                                    setValidationAlert({
                                        type: "warning",
                                        title: "Invalid Slot Number Format",
                                        message: "Correct format for splitted slot is [parent_slot].[slot_number].",
                                    });
                                    changes[i][3] = rowData.slotNo;
                                    continue;
                                }

                                if (!value.includes(".") && isNaN(value)) {
                                    setValidationAlert({
                                        type: "warning",
                                        title: "Invalid Slot Number",
                                        message: "Slot number must be a number.",
                                    });
                                    changes[i][3] = rowData.slotNo;
                                    continue;
                                }

                                if (value.split(".").length === 2) {
                                    const [slotNo, childNo] = value.split(".");

                                    if (!childNo || childNo === "" || isNaN(childNo)) {
                                        setValidationAlert({
                                            type: "warning",
                                            title: "Invalid Slot Number",
                                            message: "Child slot number must be a number.",
                                        });
                                        changes[i][3] = rowData.slotNo;
                                        continue;
                                    }

                                    if (dispatchUnitData.filter(dispatch => dispatch.__children.filter(roster => roster.__children.filter(rc => rc.relatedRosterCandidateId === rowData.rosterCandidateId).length).length).length) {
                                        setValidationAlert({
                                            type: "warning",
                                            title: "Orphan Slots",
                                            message: "Please remove any child slots from the slot before continue the process.",
                                        });
                                        changes[i][3] = rowData.slotNo;
                                        continue;
                                    }
                                }
                            }
                        }

                        setLoading(false);
                    }}
                    afterChange={(changes, source) => {
                        //console.log('afterChange', {changes, source});
                        const hotTable = hotTableComponent.current?.hotInstance;

                        if (!hotTable || source === "loadData")
                            return;

                        setLoading(true);

                        for (let i = 0; i < changes.length; i++) {
                            const rowData = hotTable.getSourceDataAtRow(changes[i][0]);

                            if (changes[i][1] === "slotNo") {
                                const value = changes[i][3];
                                let oldParentSlotNo = 0;
                                let newParentSlotNo = 0;

                                if (value.includes(".")) {
                                    const [parentSlotNo, slotNo] = value.split(".");

                                    dispatchUnitData.forEach(dispatch => {
                                        dispatch.__children.forEach(roster => {
                                            oldParentSlotNo = parseInt(roster.__children.filter(rc => rc.slotNo === parentSlotNo)[0].slotNo);
                                        });
                                    });

                                    newParentSlotNo = parseInt(value.split(".")[1]);
                                }

                                setUpdatedSlotNo({
                                    data: { ...rowData },
                                    oldIndex: rowData.numIndex[2],
                                    newValue: value,
                                    childToParent: rowData.relatedRosterCandidateId && !value.includes("."),
                                    parentToChild: !rowData.relatedRosterCandidateId && value.includes("."),
                                    changeParent: value.includes(".") && rowData.relatedRosterCandidateId ? true : false,
                                });
                            }
                        }
                        countTotalSlotAndPosition();

                        setLoading(false);
                    }}
                    afterOnCellMouseUp={(e, coord) => {
                        const { col, row } = coord;
                        const hotTable = hotTableComponent.current?.hotInstance;

                        if (!hotTable)
                            return;

                        if (col === hotTable.propToCol('info')) {
                            const name = hotTable.getDataAtRowProp(row, "talent");

                            if (!name || name === "")
                                return;

                            const nameArr = name.split(" - ");
                            const id = parseInt(nameArr[nameArr.length - 1]);
                            setCandidateInEdit({ candidateId: id });
                            return;
                        }

                        if (col === hotTable.propToCol('isOverlapped')) {
                            const sourceRow = hotTable.getSourceDataAtRow(row);
                            if (sourceRow.isOverlapped !== 'Y')
                                return;

                            const name = hotTable.getDataAtRowProp(row, "talent");

                            if (!name || name === "")
                                return;

                            const nameArr = name.split(" - ");
                            const id = parseInt(nameArr[nameArr.length - 1]);

                            const slotDates = Object.keys(sourceRow.dates)?.filter(d => {
                                // console.log({d, data: rowData.dates[d]});
                                return sourceRow.dates[d] && sourceRow.dates[d].value !== "" && d !== "schDate" && d !== "rosterScheduleTypeCode" && d !== "rosterScheduleTypeId" && d !== "shift" && d !== "rosterCandidateScheduleId";
                            });
                            slotDates.sort();
                            const startDate = moment(slotDates[0], "YYYYMMDD").format("YYYY-MM-DD");
                            const endDate = moment(slotDates[slotDates.length - 1], "YYYYMMDD").format("YYYY-MM-DD");

                            setCheckConflict({ rosterCandidateId: sourceRow.rosterCandidateId, rosterCandidate: sourceRow, candidateId: id, startDate, endDate });
                            return;
                        }

                        if (col === hotTable.propToCol('statusNavigation.lookupValue')) {
                            const rowValue = hotTable.getDataAtRowProp(row, "info");
                            const rowData = hotTable.getSourceDataAtRow(row, "info");

                            if (rowValue === "JobOrder") {
                                setRedirectLink(`/joborder/${rowData.requestDetail.recruitmentRequestId}`);
                            }

                            if (rowValue === "RecruitmentRequest") {
                                setRedirectLink(`/lrf/${rowData.requestDetail.recruitmentRequestId}`);
                            }
                            return;
                        }
                    }}
                >
                    <HotColumn data={'dispatchUnitName'} readOnly={true} />
                    <HotColumn data={'rosterName'} readOnly={true} />
                    {/* <HotColumn data={'status.lookupValue'} readOnly={true} />
                <HotColumn data={'rosterCompany.lookupValue'} readOnly={true} /> */}
                    {/* <HotColumn data={'slotNo'} readOnly={true} /> */}
                    <HotColumn data={'slotNo'} />
                    <HotColumn
                        data={'talent'}
                        type="dropdown"
                        strict={true}
                        source={(query, process) => {
                            // console.log({query});
                            searchName(query, process);
                        }}
                        className='talent-col'
                        wordWrap={false}
                    >
                        <TalentRenderer hot-renderer />
                    </HotColumn>
                    {/* <HotColumn
                    data="talent"
                    // type="dropdown"
                    // strict={true}
                    // source={(query, process) => {
                    //     searchName(query, process);
                    // }}
                    // editor={SelectEditor}
                    // selectOptions={['option1', 'option2', 'option3']}
                >
                    <GridDropdownComponent
                        hotTable={hotTableComponent.current?.hotInstance}
                        objectField="candidate"
                        idField="candidateId"
                        valueField="candidateName"
                        endpoint="/candidate/searchname"
                        setSelectedItem={setSelectedTalent}
                        isLookup={false}
                        hot-renderer
                    />
                </HotColumn> */}
                    <HotColumn data={'info'} readOnly={true} className='cursor-pointer info-col'>
                        <InfoButton hot-renderer />
                    </HotColumn>
                    <HotColumn
                        data={'positionName'}
                        type="dropdown"
                        strict={true}
                        source={(query, process) => {
                            searchPosition(query, process);
                        }}
                    />
                    <HotColumn data={'naturalPositionName'} readOnly={true} />
                    <HotColumn data={'candidateType'} readOnly={true} />
                    <HotColumn
                        data={'shiftLabel'}
                        type="dropdown"
                        strict={true}
                        source={(query, process) => {
                            searchShift(query, process);
                        }}
                    />
                    <HotColumn data={'statusNavigation.lookupValue'} readOnly={true}>
                        <StatusRenderer hot-renderer />
                    </HotColumn>
                    {/*
                    <HotColumn data={'availabilityStatus?.lookupValue'} readOnly={true}>
                        <StatusRenderer hot-renderer />
                    </HotColumn>
                    <HotColumn data={'logisticStatus?.lookupValue'} readOnly={true}>
                        <StatusRenderer hot-renderer />
                    </HotColumn>
                    */}
                    <HotColumn data={'rosterCandidateCompany'} readOnly={true}>
                        <CompanyRenderer hot-renderer />
                    </HotColumn>
                    <HotColumn data={'isOverlapped'} readOnly={true}>
                        <OverlappedButton hot-renderer />
                    </HotColumn>
                    <HotColumn data={'fatigueStatus'} readOnly={true}>
                        <FatigueFlagRenderer hot-renderer />
                    </HotColumn>
                    {hotColumns.map(m => {
                        return (<HotColumn
                            readOnly={false}
                            className='data-value'
                            key={`${m.format('YYYYMMDD')}`}
                            data={`dates.${m.format('YYYYMMDD')}.value`}
                        >
                            <RendererComponent hot-renderer instance={hotTableComponent} />
                        </HotColumn>)

                    })}
                </HotTable>
            }
        </>);
    }, [selectedProject, dispatchUnitData, tableColumns, selectedDispatchUnit, selectedRoster, logisticInEdit, selectedCellGlobal, confirmationMessageTemplateForAvailability,
        hotTableComponent.current, isChanged, saveProcess, loadDispacthUnitData, generateCandidateMessageList, firstColumn, hiddenAccommodationItem,
        hiddenItem, hiddenTalentItem, hiddenTalentSpecificItem, openedCollapse]);

    const sortSlot = (duData) => {
        let childIndexReducer = 0;

        duData.forEach(dispatch => {
            let rosterIndex = 0;
            dispatch.__children.forEach(roster => {
                roster.__children.forEach((rc, idx) => {
                    if (rc.relatedRosterCandidateId) {
                        const parentSlot = roster.__children.filter(r => r.rosterCandidateId === rc.relatedRosterCandidateId)[0];
                        const childIndexes = roster.__children.filter(r => r.relatedRosterCandidateId === rc.relatedRosterCandidateId).map(r => roster.__children.indexOf(r));
                        const slotNo = childIndexes.indexOf(childIndexes.filter(c => c === roster.__children.indexOf(rc))[0]) + 1;
                        rc.slotNo = `${parentSlot.slotNo}.${slotNo}`;
                        childIndexReducer++;
                        return;
                    }

                    rc.slotNo = `${idx + 1 - childIndexReducer}`;
                    rc.numIndex = [0, rosterIndex, idx];
                });
                rosterIndex++;
            });
        });

        return duData;
    };

    useEffect(() => {
        if (!refreshSlotNumber)
            return;

        setLoading(true)
        const newData = [...dispatchUnitData];
        setDispatchUnitData([...sortSlot(newData)]);
        setRefreshSlotNumber(false);
        setLoading(false);
        setSelectedCellGlobal([...selectedCellGlobal]);
    }, [refreshSlotNumber]);

    useEffect(() => {
        if (!updatedSlotNo)
            return;

        const {
            data,
            oldIndex,
            newValue,
            childToParent,
            parentToChild,
            changeParent,
        } = updatedSlotNo;

        setLoading(true);
        console.log("dispatchUnitData: ", { dispatchUnitData, data, oldIndex, newValue, childToParent, parentToChild, changeParent });
        const newData = [...dispatchUnitData];
        let rowIdx = 0;

        newData.forEach(dispatch => {
            // console.log("dispatchItemData: ", dispatch);
            rowIdx++;
            dispatch.__children.forEach((roster, rosterIdx) => {
                rowIdx++;

                if (roster.rosterId !== data.rosterId) {
                    roster.__children.forEach((rc) => {
                        rc.talentIndex = { ...rc.talentIndex, row: rowIdx };
                        rowIdx++;
                    });
                    return;
                }

                // console.log("rosterData: ", roster);
                const rosterCandidates = roster.__children;
                let replacedData = null;
                let replacedDataIndex = -1;
                const removedData = rosterCandidates[oldIndex];
                removedData.slotNo = newValue;

                if (data.relatedRosterCandidateId) {
                    if (!childToParent && !changeParent) {
                        const [parentSlotNo, slotNo] = newValue.split(".");
                        const slotCount = rosterCandidates.filter(rc => rc.relatedRosterCandidateId === data.relatedRosterCandidateId).length;

                        rosterCandidates.filter(rc => rc.slotNo === `${parentSlotNo}.${slotNo}`).forEach(rc => {
                            if (rosterCandidates.indexOf(rc) !== oldIndex) {
                                replacedDataIndex = rosterCandidates.indexOf(rc);
                                replacedData = rc;
                            }
                        });

                        if (replacedDataIndex === -1) {
                            const parentIndex = rosterCandidates.indexOf(rosterCandidates.filter(rc => rc.slotNo === `${parentSlotNo}`)[0]);
                            replacedDataIndex = parseInt(slotNo) > parseInt(slotCount) ? parentIndex + slotCount : (parseInt(slotNo) <= 0 ? parentIndex + 1 : oldIndex);
                            replacedData = rosterCandidates[oldIndex];
                        }
                    }

                    if (childToParent) {
                        const totalSlotCount = rosterCandidates.length;
                        const slotCount = rosterCandidates.filter(rc => !rc.relatedRosterCandidateId).length;

                        rosterCandidates.filter(rc => rc.slotNo === newValue).forEach(rc => {
                            // console.log(rc, oldIndex, rosterCandidates.indexOf(rc) !== oldIndex);
                            const currIdx = rosterCandidates.indexOf(rc);
                            if (currIdx !== oldIndex) {
                                replacedDataIndex = currIdx - (oldIndex < currIdx ? 1 : 0);
                                replacedData = rc;
                            }
                        });

                        if (replacedDataIndex === -1) {
                            // console.log("in", parseInt(newValue), parseInt(slotCount));
                            replacedDataIndex = parseInt(newValue) > parseInt(slotCount) ? totalSlotCount - 1 : (parseInt(newValue) <= 0 ? 0 : oldIndex);
                            replacedData = rosterCandidates[oldIndex];
                        }

                        removedData.relatedRosterCandidateId = null;
                        removedData.relatedRosterCandidate = null;
                    }

                    if (changeParent) {
                        const [parentSlotNo, slotNo] = newValue.split(".");
                        const slotCount = rosterCandidates.filter(rc => rc.slotNo.includes(".") && rc.slotNo.split(".")[0] === parentSlotNo && rc.slotNo).length;

                        rosterCandidates.filter(rc => rc.slotNo === `${parentSlotNo}.${slotNo}`).forEach(rc => {
                            const currIdx = rosterCandidates.indexOf(rc);
                            if (currIdx !== oldIndex) {
                                replacedDataIndex = currIdx - (oldIndex < currIdx ? 1 : 0);
                                replacedData = rc;
                            }
                        });

                        if (replacedDataIndex === -1) {
                            const parentIndex = rosterCandidates.indexOf(rosterCandidates.filter(rc => rc.slotNo === `${parentSlotNo}`)[0]);
                            // console.log({slotNo, slotCount});
                            replacedDataIndex = parseInt(slotNo) >= parseInt(slotCount) ? parentIndex + slotCount - (oldIndex < parentIndex ? 1 : 0) : (parseInt(slotNo) <= 0 ? parentIndex + 1 : oldIndex);
                            replacedData = rosterCandidates[oldIndex];
                        }

                        const parent = rosterCandidates.filter(rc => rc.slotNo === parentSlotNo)[0];
                        removedData.relatedRosterCandidateId = parent.rosterCandidateId;
                        removedData.relatedRosterCandidate = JSON.parse(JSON.stringify(parent));
                    }
                }

                if (!data.relatedRosterCandidateId) {
                    if (!parentToChild) {
                        const totalSlotCount = rosterCandidates.length;
                        const slotCount = rosterCandidates.filter(rc => !rc.relatedRosterCandidateId).length;

                        rosterCandidates.filter(rc => rc.slotNo === newValue).forEach(rc => {
                            // console.log(rc, oldIndex, rosterCandidates.indexOf(rc) !== oldIndex);
                            if (rosterCandidates.indexOf(rc) !== oldIndex) {
                                // console.log(rosterCandidates.indexOf(rc));
                                replacedDataIndex = rosterCandidates.indexOf(rc);
                                replacedData = rc;
                            }
                        });

                        if (replacedDataIndex === -1) {
                            // console.log("in", parseInt(newValue), parseInt(slotCount));
                            replacedDataIndex = parseInt(newValue) > parseInt(slotCount) ? totalSlotCount - 1 : (parseInt(newValue) <= 0 ? 0 : oldIndex);
                            replacedData = rosterCandidates[oldIndex];
                        }
                    }

                    if (parentToChild) {
                        const [parentSlotNo, slotNo] = newValue.split(".");
                        const slotCount = rosterCandidates.filter(rc => rc.slotNo.includes(".") && rc.slotNo.split(".")[0] === parentSlotNo && rc.slotNo).length;

                        rosterCandidates.filter(rc => rc.slotNo === `${parentSlotNo}.${slotNo}`).forEach(rc => {
                            const currIdx = rosterCandidates.indexOf(rc);
                            if (currIdx !== oldIndex) {
                                replacedDataIndex = currIdx - (oldIndex < currIdx ? 1 : 0);
                                replacedData = rc;
                            }
                        });

                        if (replacedDataIndex === -1) {
                            const parentIndex = rosterCandidates.indexOf(rosterCandidates.filter(rc => rc.slotNo === `${parentSlotNo}`)[0]);
                            // console.log({slotNo, slotCount});
                            replacedDataIndex = parseInt(slotNo) >= parseInt(slotCount) ? parentIndex + slotCount - (oldIndex < parentIndex ? 1 : 0) : (parseInt(slotNo) <= 0 ? parentIndex + 1 : oldIndex);
                            replacedData = rosterCandidates[oldIndex];
                        }

                        const parent = rosterCandidates.filter(rc => rc.slotNo === parentSlotNo)[0];
                        removedData.relatedRosterCandidateId = parent.rosterCandidateId;
                        removedData.relatedRosterCandidate = JSON.parse(JSON.stringify(parent));
                    }
                }

                if (oldIndex === replacedDataIndex) {
                    roster.__children.forEach((rc) => {
                        rc.talentIndex = { ...rc.talentIndex, row: rowIdx };
                        rowIdx++;
                    });
                    return;
                }

                replacedDataIndex = replacedDataIndex + (replacedDataIndex <= oldIndex ? 0 : rosterCandidates.filter(rc => rc.relatedRosterCandidateId === replacedData?.rosterCandidateId).length);
                rosterCandidates.splice(oldIndex, 1);
                rosterCandidates.splice(replacedDataIndex, 0, removedData);

                if (!replacedData.relatedRosterCandidateId) {
                    rosterCandidates.filter(rc => rc.relatedRosterCandidateId === removedData.rosterCandidateId).forEach((rc, idx) => {
                        // console.log(rosterCandidates.indexOf(rc), replacedDataIndex + idx);
                        rosterCandidates.splice(rosterCandidates.indexOf(rc), 1);
                        rosterCandidates.splice(oldIndex <= replacedDataIndex ? replacedDataIndex : replacedDataIndex + idx + 1, 0, rc);
                    });
                }

                rosterCandidates.forEach((rc, rcIdx) => {
                    rc.numIndex = [0, rosterIdx, rcIdx];
                    rc.index = [0, rosterIdx, rc.slotNo];
                    rc.talentIndex = { ...rc.talentIndex, row: rowIdx };
                    rowIdx++;
                });

                // console.log({removedData, replacedData, oldIndex, replacedDataIndex, rosterCandidates});
            });
        });

        // console.log("newData: ", newData);
        setDispatchUnitData([...newData]);
        setUpdatedSlotNo(null);
        setLoading(false);
        setRefreshSlotNumber(true);
    }, [updatedSlotNo]);

    useEffect(() => {
        if (!confirmCandidateChange)
            return;

        const { oldData, changes } = confirmCandidateChange;

        // console.log("old data: ", oldData);

        if (!oldData.slotNo) {
            setConfirmCandidateChange(null);
            return;
        }

        const newDispatchUnitData = [...dispatchUnitData];

        if (!confirmCandidateChange.confirmed) {
            if (confirmCandidateChange.revert) {
                setLoading(true);
                newDispatchUnitData.forEach(du => {
                    du.__children.forEach(r => {
                        const rosterCandidateData = r.__children.filter(rc => rc.slotNo === oldData.slotNo)[0];
                        rosterCandidateData.candidateId = oldData.candidateId;
                        rosterCandidateData.candidate = oldData.candidate;
                        rosterCandidateData.talent = oldData.talent;
                        rosterCandidateData.isOverlapped = oldData.isOverlapped;
                        rosterCandidateData.rosterCandidateCompany = oldData.rosterCandidateCompany;
                        rosterCandidateData.naturalPositionName = oldData.targetPosition 
                            ? `${oldData.targetPosition?.positionName} - ${oldData.targetPosition?.positionId}` 
                            : `${oldData.position?.positionName} - ${oldData.position?.positionId}` 
                            ;
                        
                        rosterCandidateData.candidateType = oldData.candidateType;
                        rosterCandidateData.statusNavigation = oldData.statusNavigation;
                        rosterCandidateData.status = oldData.status;
                        rosterCandidateData.info = oldData.info;
                    });
                });

                setDispatchUnitData([...newDispatchUnitData]);
                setConfirmCandidateChange(null);
                setLoading(false);
                return;
            }

            if (!confirmCandidateChange.loadData)
                return;

            setLoading(true);

            if (!oldData.candidateId) {
                setConfirmCandidateChange({ ...confirmCandidateChange, confirmed: true, loadData: false });
                return;
            }


            api.get(`communication/candidatelog?rostercandidateid=${oldData.rosterCandidateId}&candidateid=${oldData.candidateId}`)
                .then(({ data }) => {

                    if (!data || !data.length) {
                        setConfirmCandidateChange({ ...confirmCandidateChange, confirmed: true, loadData: false });
                        return;
                    }

                    setConfirmCandidateChange({ ...confirmCandidateChange, loadData: false, messageLogs: [...data] });
                })
                .catch((error) => console.log({ error }))
                .finally(() => setLoading(false))
                ;
            return;
        }

        setLoading(true);
        // console.log("changes: ", changes);
        const removedStatus = { ...lookups.rosterApprovalStatus.filter(a => a.lookupValue === "Candidate Removed")[0] };

        if (!changes[0][3] || changes[0][3] === "") {
            newDispatchUnitData.forEach(du => {
                du.__children.forEach(r => {
                    const rosterCandidateData = r.__children.filter(rc => rc.slotNo === oldData.slotNo)[0];
                    rosterCandidateData.candidateId = null;
                    rosterCandidateData.candidate = null;
                    rosterCandidateData.talent = null;
                    rosterCandidateData.isOverlapped = null;
                    rosterCandidateData.rosterCandidateCompany = null;
                    rosterCandidateData.naturalPositionName = 
                        oldData.targetPosition 
                        ? `${oldData.targetPosition?.positionName} - ${oldData.targetPosition?.positionId}`
                        : `${oldData.position?.positionName} - ${oldData.position?.positionId}`
                        ;
                    rosterCandidateData.candidateType = null;
                    rosterCandidateData.info = null;

                    if (!oldData.requestDetailId) {
                        rosterCandidateData.status = removedStatus.lookupId;
                        rosterCandidateData.statusNavigation = removedStatus;
                    }
                });
            });

            setDispatchUnitData([...newDispatchUnitData]);
            setLoading(false);
            setConfirmCandidateChange(null);
            return;
        }

        const hotTable = hotTableComponent.current?.hotInstance;

        if (!hotTable) {
            setLoading(false);
            setConfirmCandidateChange(null);
            return;
        }

        const newStatus = { ...lookups.rosterApprovalStatus.filter(a => a.lookupValue === "New")[0] };
        const rowNum = hotTable.countRows();
        const apiCalls = [];
        const slotDates = Object.keys(oldData.dates)?.filter(d => {
            // console.log({d, data: oldData.dates[d]});
            return oldData.dates[d] && oldData.dates[d].value !== "" && d !== "schDate" && d !== "rosterScheduleTypeCode" && d !== "rosterScheduleTypeId" && d !== "shift" && d !== "rosterCandidateScheduleId";
        });
        slotDates.sort();
        const startDate = slotDates[0] && slotDates[0] !== "" ? moment(slotDates[0], "YYYYMMDD").format("YYYY-MM-DD") : null;
        const endDate = slotDates[slotDates.length - 1] && slotDates[slotDates.length - 1] !== "" ? moment(slotDates[slotDates.length - 1], "YYYYMMDD").format("YYYY-MM-DD") : null;
        const sourceData = [];

        for (let i = 0; i < rowNum; i++) {
            const value = (changes[0][0] === i) ? changes[0][3] : null;
            const talentValues = hotTable.getDataAtRowProp(i, "talent");
            const currRowData = hotTable.getSourceDataAtRow(i);

            if (talentValues && talentValues !== null)
                sourceData.push({
                    source: currRowData,
                    rowIdx: i
                });

            if (value && value !== "") {
                // console.log({startDate, endDate});
                const splittedValue = value.split(" - ");
                const candidateId = parseInt(splittedValue[splittedValue.length - 1]) ?? 0;
                newDispatchUnitData.forEach(du => {
                    du.__children.forEach(r => {
                        r.__children.filter(rc => rc.slotNo === oldData.slotNo).forEach(rc => {
                            // console.log('editedData', {rc, r});
                            rc.candidateId = candidateId;
                            rc.candidate = {
                                candidateName: splittedValue.filter((v, idx) => idx < splittedValue.length - 1).join(" - "),
                                candidateId: candidateId,
                            };
                            rc.talent = value;
                            rc.statusNavigation = newStatus;
                            rc.status = newStatus.lookupId;
                            rc.info = rc.requestDetail?.jobOrderId ? "JobOrder" : (rc.requestDetailId ? "RecruitmentRequest" : "Info");
                        });
                    });
                });
                apiCalls.push(api.get(`rosterwizard/check-conflicts?candidateid=${candidateId}&candidatename=${value}&rownumber=${i}${startDate ? `&startdate=${startDate}` : ""}${endDate ? `&enddate=${endDate}` : ""}&rostercandidateid=${currRowData.rosterCandidateId}`));
            }
        }

        // console.log({apiCalls});

        if (!apiCalls || apiCalls.length === 0) {
            setDispatchUnitData(newDispatchUnitData);
            setLoading(false);
            setConfirmCandidateChange(null);
            return;
        }

        // console.log({apiCalls});

        Promise.all(apiCalls)
            .then((responses) => {
                // console.log('responses', { responses, changes });
                if (!responses || responses.length === 0)
                    return;

                const candidateResponse = responses[0];
                const duplicateTalent = sourceData?.filter(y => y.source?.candidateId === candidateResponse.data.candidateId);

                newDispatchUnitData.forEach(du => {
                    du.__children.forEach(r => {
                        r.__children.filter(rc => rc.slotNo === oldData.slotNo).forEach(rc => {
                            // console.log('editedData', {rc, r});
                            rc.isOverlapped = duplicateTalent.length > 0 ? "Y" : "N";
                            rc.rosterCandidateCompany = { ...candidateResponse.data.company };
                            rc.naturalPositionName = candidateResponse.data.naturalPosition ? `${candidateResponse.data.naturalPosition?.lookupValue} - ${candidateResponse.data.naturalPosition?.lookupId}` : rc.naturalPositionName;
                            rc.candidateType = candidateResponse.data.candidateType;
                        });
                    });
                });

                setDispatchUnitData(newDispatchUnitData);
            })
            .catch((errors) => {
                console.error({ errors });
            })
            .finally(() => {
                setConfirmCandidateChange(null);
                setLoading(false);
            });
    }, [confirmCandidateChange]);

    const handleSave = useCallback(() => {
        setLoading(true);

        const process = saveProcess();

        if (!process) {
            setLoading(false);
            return;
        }

        process
            .then((response) => {
                //console.log('response', response);
                loadDispacthUnitData(() => {
                    setIsChanged(false);
                    setLoading(false);
                });
            })
            .catch((error) => {

                console.log('error', error, { ...error });

                // if (error.response.status === 400 && error.response.data) 
                // {
                //     setValidationAlert({ message: error.response.data });
                // }

                setValidationAlert({ message: "An Error Occured, please contact Admin." });
                setLoading(false)
            })
        //.finally(() => setLoading(false)); // leave the setLoading(false) to loadDispacthUnitData
    }, [hotTableComponent, dispatchUnitData, loadDispacthUnitData, saveProcess]);

    const generateLrf = useCallback((data, selectedSlots) => {
        setLoading(true);

        // console.log(selectedRosterCandidateIds);

        if (data.length === 0) {
            setLoading(false);
            return;
        }

        const emptySlots = [];
        // console.log({selectedSlots});

        data.forEach(du => {
            du.__children.forEach(r => {
                r.__children.filter(rc => (!rc.candidate || !rc.candidate.candidateId) && !rc.requestDetailId && (!selectedSlots || selectedSlots.filter(s => s === rc.slotNo).length > 0)).forEach(rc => {
                    // console.log({rc});
                    const dates = [];
                    Object.keys(rc.dates).forEach(d => {
                        if (!rc.dates[d] || !rc.dates[d].value || (!rc.dates[d].value.includes("M") && !rc.dates[d].value.includes("S") && !rc.dates[d].value.includes("E") && !rc.dates[d].value.includes("D")))
                            return;

                        dates.push(d);
                    })

                    if (!dates.length)
                        return;

                    dates.sort();

                    emptySlots.push({
                        projectId: du.projectId,
                        rosterId: r.rosterId,
                        //workorderId: du.workorderId,
                        startDate: moment(dates[0], "YYYYMMDD").format("YYYY-MM-DD"),
                        endDate: moment(dates[dates.length - 1], "YYYYMMDD").format("YYYY-MM-DD"),
                        hrispositionId: rc.hrispositionId,
                        positionId: rc.positionId,
                        hrispositionMasterId: rc.hrispositionMasterId,
                        locationId: du.locationId,
                        companyId: r.rosterCompany?.lookupId
                    });
                })
            })
        })

        // console.log('emptySlots', emptySlots, dispatchUnitData)

        if (emptySlots.length === 0) {
            setValidationAlert({ message: "No data to be generated." });
            setLoading(false);
            return;
        }

        api.post("/roster/lrf", emptySlots)
            .then((response) => {
                // console.log(response);
                //history.push(`/lrf/${response.data}`);
                window.open(`/lrf/${response.data}`, '_blank');
                loadDispacthUnitData();
            })
            .catch((error) => {
                setValidationAlert({ message: error.response.status === 501 ? error.response.data : "System error." });
            })
            .finally(() => setLoading(false));
    }, [selectedProject, startDate, endDate, dispatchUnit, roster, hotColumns, firstLoad]);

    const handleGenerateLrf = useCallback(() => {
        setLoading(true);

        saveProcess()
            .then((response) => {
                // console.log('response', response);
                setIsChanged(false);
                setLoading(true);

                loadDispacthUnitData((data) => {
                    generateLrf(data);
                });
            })
            .catch((error) => {
                setLoading(false);
                console.log('error', error);
            });
    }, [dispatchUnitData]);


    const openRosterExport = useCallback(() => {
        const params = {
            start: moment(startDate).format('YYYY-MM-DD'),
            end: moment(endDate).format('YYYY-MM-DD'),
            projectId: selectedProject?.projectId,
            dispatchUnitId: dispatchUnit?.lookupId
        }
        //console.log('openRosterExport', { startDate, endDate, selectedProject, dispatchUnit }, qs.stringify(params));

        //window.open(`/reports/rosterexport?${qs.stringify(params)}`, "_blank")
        //window.open(`/api/reports/download-roster?${qs.stringify(params)}`, "_blank");

        // let url = `/reports/download-roster?${qs.stringify(params)}`;
        const url = `/reports/download-roster-sheet?${qs.stringify(params)}`;
        let startdateFileName = moment(startDate).format('YYYYMMDD');
        let enddateFileName = moment(endDate).format('YYYYMMDD');

        let filename = `roster-${selectedProject?.projectName}-${dispatchUnit?.lookupValue}-${startdateFileName}-to-${enddateFileName}.xlsx`;
        downloadExport(url, filename);
    }, [startDate, endDate, selectedProject, dispatchUnit]);

    const openRosterSiteExport = useCallback(() => {
        const params = {
            start: moment(startDate).format('YYYY-MM-DD'),
            end: moment(endDate).format('YYYY-MM-DD'),
            projectId: selectedProject?.projectId,
            dispatchUnitId: dispatchUnit?.lookupId
        }
        //console.log('openRosterExport', { startDate, endDate, selectedProject, dispatchUnit }, qs.stringify(params));

        //window.open(`/api/reports/download-roster-site?${qs.stringify(params)}`, "_blank");
        //window.open(`/reports/rostersiteexport?${qs.stringify(params)}`, "_blank")

        let url = `/reports/download-roster-site?${qs.stringify(params)}`;
        let startdateFileName = moment(startDate).format('YYYYMMDD');
        let enddateFileName = moment(endDate).format('YYYYMMDD');

        let filename = `roster-site-${selectedProject?.projectName}-${dispatchUnit?.lookupValue}-${startdateFileName}-to-${enddateFileName}.xlsx`;
        downloadExport(url, filename);
    }, [startDate, endDate, selectedProject, dispatchUnit]);

    const downloadExport = React.useCallback((url, filename) => {
        setLoading(true);
        api.get(url, { responseType: 'blob' })
            .then(blob => {
                // console.log(blob);
                //var filename = /(?<=attachment;.*filename=").*(?=";)/.exec(blob.headers["content-disposition"])[0];
                fileDownload(blob.data, filename);
            }).catch(error => {
                console.error({error});
                setErrorMessage(`${errorMessageOpening}${error.message}`);
                //setLoading(false);
            }).finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        if (reload) {
            loadDispacthUnitData();
            setReload(false);
        }
    }, [reload]);

    useEffect(() => {
        if (!dispatchUnit)
            return;

        refreshDispatchUnits((list) => {
            if (list.filter(l => l.lookupId === dispatchUnit.lookupId).length)
                return;

            setDispatchUnit(null);

            if (dispatchUnitData?.length)
                setReload(true);
        });
    }, [showArchivedDispatchUnits]);

    useEffect(() => {
        if (!confirmDelete)
            return;

        handleSave();
    }, [confirmDelete]);

    return (
        <section className="main">
            {(loading) && <Loader />}
            {alert.length > 0 && alert}
            {errorNotification.length > 0 && errorNotification}
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card className="no-transition">
                            <CardHeader className='py-1'>
                                <h5 className="mb-0">
                                    <Button
                                        aria-expanded={openedCollapse === "collapse-1"}
                                        onClick={() =>
                                            setOpenedCollapse(openedCollapse === "collapse-1" ? "" : "collapse-1")
                                        }
                                        className="w-100 text-primary text-left no-shadow"
                                        color="link"
                                        type="button"
                                    >
                                        Roster Filter
                                        <i className="ni ni-bold-down float-right pt-1"></i>
                                    </Button>
                                </h5>
                            </CardHeader>
                            <Collapse isOpen={openedCollapse === "collapse-1"} className="mb-2">
                                <CardBody className='pb-1'>
                                    <Container fluid>
                                        <Row>

                                            <Col md={12} lg={3}>
                                                <FormGroup>
                                                    <Label>
                                                        Project
                                                        <span className="text-danger">*</span>
                                                    </Label>
                                                    <ComboBox
                                                        endpoint="/project/search"
                                                        isLookup={false}
                                                        idField="projectId"
                                                        valueField="projectName"
                                                        selectedItem={selectedProject}
                                                        onChange={setSelectedProject}
                                                        minLength={0}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            {/* <Col md={12} lg={2}>
                                                <FormGroup>
                                                    <Label>
                                                        Job
                                                        <span className="text-danger">*</span>
                                                    </Label>
                                                    <ComboBox 
                                                        disabled={!selectedProject}
                                                        endpoint={`/workorder/${selectedProject?.projectId}`}
                                                        minLength={2}
                                                        selectedItem={selectedWorkorder}
                                                        onChange={setSelectedWorkorder}
                                                    />
                                                </FormGroup>
                                            </Col> */}
                                            <Col md={12} lg={3}>
                                                <FormGroup>
                                                    <div className="w-100 d-flex justify-content-between">
                                                        <Label>
                                                            Dispatch Unit
                                                            <span className="text-danger">*</span>
                                                        </Label>
                                                        <IndeterminateCheckbox
                                                            id="showArchived"
                                                            type="checkbox"
                                                            onChange={() => {
                                                                setShowArchivedDispatchUnits(!showArchivedDispatchUnits);
                                                            }}
                                                            checked={showArchivedDispatchUnits}
                                                            customTitle="Show Archived"
                                                        />
                                                    </div>
                                                    {/* <DropdownBox 
                                                        data={dispatchUnits}
                                                        disabled={!selectedProject}
                                                        isLookup={true}
                                                        selectedItem={dispatchUnit}
                                                        onChange={setDispatchUnit}
                                                    /> */}
                                                    <ComboBox
                                                        endpoint={`/lookup/dispatchunit/${selectedProject?.projectId}/${showArchivedDispatchUnits}`}
                                                        isLookup={false}
                                                        idField="lookupId"
                                                        valueField="lookupValue"
                                                        selectedItem={dispatchUnit}
                                                        onChange={setDispatchUnit}
                                                        minLength={0}
                                                        disabled={!selectedProject}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            {/* <Col md={12} lg={2}>
                                                <FormGroup>
                                                    <Label>
                                                        Roster
                                                    </Label>
                                                    <DropdownBox 
                                                        data={rosters}
                                                        disabled={!dispatchUnit}
                                                        isLookup={true}
                                                        selectedItem={roster}
                                                        onChange={setRoster}
                                                    />
                                                </FormGroup>
                                            </Col> */}

                                            <Col md={12} lg={3}>
                                                <FormGroup>
                                                    <div className="w-100 d-flex justify-content-between">
                                                        <Label>
                                                            Start Date
                                                            <span className="text-danger">*</span>
                                                        </Label>
                                                        <IndeterminateCheckbox
                                                            id="trimStartDate"
                                                            type="checkbox"
                                                            onChange={() => {
                                                                setTrimStartDate(!trimStartDate);
                                                            }}
                                                            checked={trimStartDate}
                                                            customTitle="Trim"
                                                        />
                                                    </div>
                                                    <DatePicker
                                                        name="startDate"
                                                        id="startDate"
                                                        type="text"
                                                        required
                                                        value={formatDate(startDate)}
                                                        onChange={(value) => {
                                                            setStartDate(value);
                                                        }}
                                                        closeOnSelect
                                                        disabled={trimStartDate}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12} lg={3}>
                                                <FormGroup>
                                                    <div className="w-100 d-flex justify-content-between">
                                                        <Label>
                                                            End Date
                                                            <span className="text-danger">*</span>
                                                        </Label>
                                                        <IndeterminateCheckbox
                                                            id="trimEndDate"
                                                            type="checkbox"
                                                            onChange={() => {
                                                                setTrimEndDate(!trimEndDate);
                                                            }}
                                                            checked={trimEndDate}
                                                            customTitle="Trim"
                                                        />
                                                    </div>
                                                    <DatePicker
                                                        name="endDate"
                                                        id="endDate"
                                                        type="text"
                                                        required
                                                        value={formatDate(endDate)}
                                                        onChange={(value) => {
                                                            setEndDate(value);
                                                        }}
                                                        closeOnSelect
                                                        disabled={trimEndDate}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col xs={8}>
                                                <TooltipButton
                                                    id="generatetalent"
                                                    title="Auto-Select Resources"
                                                    className="btn-icon roster-button"
                                                    color="default"
                                                    type="button"
                                                    disabled={!dispatchUnitData || dispatchUnitData.length === 0 || disableActions}
                                                    onClick={() => handleGenerateTalent()}
                                                >
                                                    <i className="fas fa-download pt-1 mr-2"></i> Auto-Select Resources
                                                </TooltipButton>
                                                <ButtonGroup className="mr-2 roster-button">
                                                    <Button className="roster-button" color="primary" disabled={(!!!selectedProject || !dispatchUnitData || dispatchUnitData.length === 0)} onClick={openRosterExport}>
                                                        Export
                                                    </Button>
                                                    <ButtonDropdown className="roster-button ml-0" isOpen={dropdownOpen} toggle={toggle} direction="up" disabled={(!!!selectedProject || !dispatchUnitData || dispatchUnitData.length === 0)}>
                                                        <DropdownToggle caret color="primary" className={`${(!dispatchUnitData || dispatchUnitData.length === 0 || disableActions) ? "disabled" : ""}`} />
                                                        <DropdownMenu>
                                                            <DropdownItem key={0} onClick={openRosterExport}>Roster Schedule</DropdownItem>
                                                            <DropdownItem key={1} onClick={openRosterSiteExport}>Site Export</DropdownItem>
                                                            <DropdownItem key={2} onClick={() => setOpenRosterImport(dispatchUnitData && dispatchUnitData.length > 0)}>Import Roster Update</DropdownItem>
                                                        </DropdownMenu>
                                                    </ButtonDropdown>
                                                </ButtonGroup>
                                                <TooltipButton
                                                    id="editDispatchUnit"
                                                    title="Edit Dispatch"
                                                    className="btn-icon roster-button"
                                                    color="default"
                                                    type="button"
                                                    disabled={!dispatchUnitData || dispatchUnitData.length === 0 || disableActions}
                                                    onClick={() => setDispatchUnitInEdit(dispatchUnit.lookupId)}
                                                >
                                                    <i className="fas fa-cog mr-2 pt-1"></i> Edit Dispatch
                                                </TooltipButton>
                                                <TooltipButton
                                                    id="tnaDiscrepancyReport"
                                                    title="TNA Discrepancy Report"
                                                    className="btn-icon roster-button"
                                                    color="default"
                                                    type="button"
                                                    disabled={!dispatchUnitData || dispatchUnitData.length === 0 || disableActions}
                                                    onClick={() => {
                                                        const candidateIds = [];
                                                        // console.log({logisticInEdit, selectedRoster, selectedDispatchUnit});

                                                        if (logisticInEdit?.length > 0) {
                                                            logisticInEdit.forEach(d => {
                                                                if (!d.candidateId)
                                                                    return;

                                                                candidateIds.push(d.candidateId);
                                                            })
                                                        }

                                                        if (!logisticInEdit?.length && selectedRoster?.length > 0) {
                                                            selectedRoster.forEach(d => {
                                                                if (!d.__children?.length)
                                                                    return;

                                                                d.__children.forEach(c => {
                                                                    if (!c.candidateId || candidateIds.filter(ci => ci === c.candidateId).length)
                                                                        return;

                                                                    candidateIds.push(c.candidateId);
                                                                });
                                                            })
                                                        }

                                                        if (!logisticInEdit?.length && !selectedRoster?.length && selectedDispatchUnit?.length > 0) {
                                                            selectedDispatchUnit.forEach(d => {
                                                                if (!d.__children?.length)
                                                                    return;

                                                                d.__children.forEach(r => {
                                                                    if (!r.__children?.length)
                                                                        return;

                                                                    r.__children.forEach(c => {
                                                                        if (!c.candidateId || candidateIds.filter(ci => ci === c.candidateId).length)
                                                                            return;

                                                                        candidateIds.push(c.candidateId);
                                                                    });
                                                                });
                                                            })
                                                        }
                                                        
                                                        window.open(`/tnadiscrepancy-roster?dispatchUnitId=${dispatchUnit.lookupId}${roster?.lookupId ? `&rosterId=${roster.lookupId}` : ""}${candidateIds.length > 0 ? `&candidateIds=${encodeURI(candidateIds.join("-"))}` : ""}`, '_blank');
                                                    }}
                                                >
                                                    <i className="fas fa-external-link-alt mr-2" /> TNA Discrepancy Report
                                                </TooltipButton>
                                                <TooltipButton
                                                    id="communications"
                                                    title="Open Communication Page"
                                                    className="btn-icon roster-button"
                                                    color="default"
                                                    type="button"
                                                    disabled={!dispatchUnitData || dispatchUnitData.length === 0 || disableActions}
                                                    onClick={() => window.open(`communication?dispatchunit=${encodeURIComponent(dispatchUnit.lookupValue)}`, '_blank')}
                                                >
                                                    <i className="fas fa-envelope-open mr-2 pt-1"></i> Communications
                                                </TooltipButton>
                                            </Col>
                                            <Col xs={4} className="d-flex flex-wrap justify-content-end">
                                                <Button
                                                    className="roster-button"
                                                    color="secondary"
                                                    onClick={() => loadDispacthUnitData()}
                                                    type="button"
                                                >
                                                    Filter
                                                </Button>
                                                <Button
                                                    className="roster-button"
                                                    color="secondary"
                                                    onClick={() => handleSave()}
                                                    type="button"
                                                    disabled={disableActions}
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    className="roster-button"
                                                    color="secondary"
                                                    onClick={() => handleGenerateLrf()}
                                                    type="button"
                                                    disabled={disableActions}
                                                >
                                                    Generate LRF
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={3} sm={6}>
                                                <Row className="ml-0 mr-0">
                                                    <Col xs={6} className="mr-0 p-0">
                                                        <div className='d-flex align-items-start w-100'>
                                                            <div className='d-flex justify-content-start align-items-start roster-legend-group-icon'>
                                                                <i className="fas fa-certificate mx-1"></i>
                                                                <span className='key-roster working mx-1'></span>
                                                            </div>
                                                            <small className="text-muted mx-1">
                                                                Working Day (W)
                                                            </small>
                                                        </div>
                                                    </Col>
                                                    <Col xs={6} className="mr-0 p-0">
                                                        <div className='d-flex align-items-start w-100'>
                                                            <div className='d-flex justify-content-start align-items-start roster-legend-group-icon'>
                                                                <i className="fas fa-moon mx-1"></i>
                                                                <span className='key-roster night mx-1'></span>
                                                            </div>
                                                            <small className='text-muted mx-1'>
                                                                Working Night (N)
                                                            </small>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={3} sm={6}>
                                                <Row className="ml-0 mr-0">
                                                    <Col xs={6} className="mr-0 p-0">
                                                        <div className='d-flex align-items-start w-100'>
                                                            <div className='d-flex justify-content-start align-items-start roster-legend-group-icon'>
                                                                <i className="fas fa-play mx-1"></i>
                                                                <span className='key-roster start mx-1'></span>
                                                            </div>
                                                            <small className='text-muted mx-1'>
                                                                Work Start (S)
                                                            </small>
                                                        </div>
                                                    </Col>
                                                    <Col xs={6} className="mr-0 p-0">
                                                        <div className='d-flex align-items-start w-100'>
                                                            <div className='d-flex justify-content-start align-items-start roster-legend-group-icon'>
                                                                <i className="fas fa-stop mx-1"></i>
                                                                <span className='key-roster end mx-1'></span>
                                                            </div>
                                                            <small className='text-muted mx-1'>
                                                                Work End (E)
                                                            </small>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={3} sm={6}>
                                                <Row className="ml-0 mr-0">
                                                    <Col xs={6} className="mr-0 p-0">
                                                        <div className='d-flex align-items-start w-100'>
                                                            <div className='d-flex justify-content-start align-items-start roster-legend-group-icon'>
                                                                <i className="fas fa-car mx-1"></i>
                                                                <span className='key-roster drive-in mx-1'></span>
                                                            </div>
                                                            <small className='text-muted mx-1'>
                                                                Drive-In (C)
                                                            </small>
                                                        </div>
                                                    </Col>
                                                    <Col xs={6} className="mr-0 p-0">
                                                        <div className='d-flex align-items-start w-100'>
                                                            <div className='d-flex justify-content-start align-items-start roster-legend-group-icon'>
                                                                <i className="fas fa-car-side mx-1"></i>
                                                                <span className='key-roster drive-out mx-1'></span>
                                                            </div>
                                                            <small className='text-muted mx-1'>
                                                                Drive-Out (O)
                                                            </small>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={3} sm={6}>
                                                <Row className="ml-0 mr-0">
                                                    <Col xs={6} className="mr-0 p-0">
                                                        <div className='d-flex align-items-start w-100'>
                                                            <div className='d-flex justify-content-start align-items-start roster-legend-group-icon'>
                                                                <i className="fas fa-plane-arrival mx-1"></i>
                                                            </div>
                                                            <small className='text-muted mx-1'>
                                                                Mobilise (M)
                                                            </small>
                                                        </div>
                                                    </Col>
                                                    <Col xs={6} className="mr-0 p-0">
                                                        <div className='d-flex align-items-start w-100'>
                                                            <div className='d-flex justify-content-start align-items-start roster-legend-group-icon'>
                                                                <i className="fas fa-plane-departure mx-1"></i>
                                                            </div>
                                                            <small className='text-muted mx-1'>
                                                                Demobilise (D)
                                                            </small>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={3} sm={6}>
                                                <Row className="ml-0 mr-0">
                                                    <Col xs={6} className="mr-0 p-0">
                                                        <div className='d-flex align-items-start w-100'>
                                                            <div className='d-flex justify-content-start align-items-start roster-legend-group-icon'>
                                                                <i className="fas fa-graduation-cap mx-1"></i>
                                                            </div>
                                                            <small className='text-muted mx-1'>
                                                                Training (T)
                                                            </small>
                                                        </div>
                                                    </Col>
                                                    <Col xs={6} className="mr-0 p-0">
                                                        <div className='d-flex align-items-start w-100'>
                                                            <div className='d-flex justify-content-start align-items-start roster-legend-group-icon'>
                                                                <i className="fas fa-virus mx-1"></i>
                                                            </div>
                                                            <small className='text-muted mx-1'>
                                                                Sick Leave (U)
                                                            </small>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={3} sm={6}>
                                                <Row className="ml-0 mr-0">
                                                    <Col xs={6} className="mr-0 p-0">
                                                        <div className='d-flex align-items-start w-100'>
                                                            <div className='d-flex justify-content-start align-items-start roster-legend-group-icon'>
                                                                <span className="leave mx-1"></span>
                                                            </div>
                                                            <small className='text-muted mx-1'>
                                                                Leave (L)
                                                            </small>
                                                        </div>
                                                    </Col>
                                                    <Col xs={6} className="mr-0 p-0">
                                                        <div className='d-flex align-items-start w-100'>
                                                            <div className='d-flex justify-content-start align-items-start roster-legend-group-icon'>
                                                                <span className="randr mx-1"></span>
                                                            </div>
                                                            <small className='text-muted mx-1'>
                                                                R&R (R)
                                                            </small>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={3} sm={6}>
                                                <Row className="ml-0 mr-0">
                                                    <Col xs={6} className="mr-0 p-0">
                                                        <div className='d-flex align-items-start w-100'>
                                                            <div className='d-flex justify-content-start align-items-start roster-legend-group-icon'>
                                                                <span className="fatigue mx-1"></span>
                                                            </div>
                                                            <small className='text-muted mx-1'>
                                                                Fatigue Day (F)
                                                            </small>
                                                        </div>
                                                    </Col>
                                                    <Col xs={6} className="mr-0 p-0">
                                                        <div className='d-flex align-items-start w-100'>
                                                            <div className='d-flex justify-content-start align-items-start roster-legend-group-icon'>
                                                                <span className="lwop mx-1"></span>
                                                            </div>
                                                            <small className='text-muted mx-1'>
                                                                LWOP (P)
                                                            </small>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className="roster-header pt-1 pb-1 mt-1 mb-1" hidden={!(quoteNumber || dispatchUnit || totalPosition || totalSlot)}>
                                            <Col className='d-flex'>
                                                {
                                                    quoteNumber && <Label className='mb-0 text-muted mr-4'><b>Quote Number: {quoteNumber}</b></Label>
                                                }
                                                {
                                                    dispatchUnit && <Label className='mb-0 text-muted mr-4'><b>Location: {dispatchUnit.locationName}</b></Label>
                                                }
                                                {
                                                    totalPosition && <Label className='mb-0 text-muted mr-4'><b>Total Position: {totalPosition}</b></Label>
                                                }
                                                {
                                                    totalSlot && <Label className='mb-0 text-muted'><b>Total Slot: {totalSlot}</b></Label>
                                                }
                                            </Col>
                                        </Row>
                                    </Container>
                                </CardBody>
                            </Collapse>
                            <CardBody className='pt-1'>
                                <Container fluid>
                                    <Row>
                                        <Col xs={12}>
                                            {
                                                grid
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {
                candidateInEdit &&
                <CandidateEditor
                    item={candidateInEdit}
                    onClose={() => {
                        setCandidateInEdit(null);
                    }}
                    onSaved={() => {
                        setCandidateInEdit(null);
                    }}
                    lookups={{
                        ...lookups
                    }}

                />
            }
            {
                editLogisticMode &&
                <LogisticEditor
                    project={selectedProject}
                    dispatchUnits={selectedDispatchUnit}
                    rosters={selectedRoster}
                    startDate={startDate}
                    endDate={endDate}
                    rosterCandidates={logisticInEdit}
                    rosterFlightTypes={lookups.rosterFlightTypes}
                    rosterFlightStatuses={lookups.rosterFlightStatuses}
                    rosterAccommodationStatuses={lookups.rosterAccommodationStatuses}
                    onClose={() => {
                        setEditLogisticMode(false);
                        loadDispacthUnitData();
                    }}
                    onSaved={() => {
                        setEditLogisticMode(false);
                        loadDispacthUnitData();
                    }}
                    lookups={lookups}
                />
            }
            {
                <Modal
                    isOpen={(bulkAvailabilityConfirmationIsOpen && confirmationMessage && showMessageConfirmation)}
                    className="modal-xxl"
                    modalClassName="db-example-modal-xl"
                >
                    <BulkAvailabilityConfirmation
                        project={selectedProject}
                        dispatchUnits={selectedDispatchUnit}
                        dispatchUnitData={dispatchUnitData}
                        rosters={selectedRoster}
                        rosterCandidates={logisticInEdit}
                        confirmationMessage={confirmationMessage}
                        onClose={() => setShowMessageConfirmation(false)}
                        onSaved={() => {
                            setShowMessageConfirmation(false);
                            loadDispacthUnitData();
                        }}
                        lookups={lookups}
                        bulkConfirmationMode={bulkConfirmationMode}
                    />
                </Modal>
            }
            {
                <Modal
                    isOpen={(!bulkAvailabilityConfirmationIsOpen && confirmationMessage && showMessageConfirmation)}
                    className="modal-xl"
                    modalClassName="db-example-modal-xl"
                >
                    <CandidateConfirmationEditor
                        project={selectedProject}
                        dispatchUnits={selectedDispatchUnit}
                        rosters={selectedRoster}
                        rosterCandidates={logisticInEdit}
                        confirmationMessage={confirmationMessage}
                        onClose={() => setShowMessageConfirmation(false)}
                        onSaved={() => {
                            setShowMessageConfirmation(false);
                            loadDispacthUnitData();
                        }}
                        lookups={lookups}
                    />
                </Modal>
            }
            {
                showMessage && candidateMessageList && candidateMessageList.candidates && candidateMessageList.candidates.length > 0 &&
                <EditorSMS
                    items={candidateMessageList.candidates}
                    refId={candidateMessageList.rosterCandidateId}
                    project={selectedProject}
                    projectId={selectedProject.projectId}
                    onClose={() => {
                        setShowMessage(false);
                    }}
                    onSaved={() => {
                        setShowMessage(false);
                    }}
                    lookups={lookups}
                    sourcePage="roster"
                />
            }
            {
                checkConflict?.rosterCandidateId && checkConflict?.candidateId &&
                <ConflictDialog data={checkConflict} rosterCandidate={checkConflict.rosterCandidate} close={() => {
                    setCheckConflict(null);
                }} />
            }
            {
                validationAlert &&
                <SweetAlert
                    title={validationAlert.title ?? "Error!"}
                    error={validationAlert.type === "error" || true}
                    warning={validationAlert.type === "warning"}
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    onConfirm={() => setValidationAlert(null)}
                >
                    {
                        validationAlert.message
                    }
                </SweetAlert>
            }
            {
                confirmCancelAlert &&
                <SweetAlert
                    title="Cancel Roster"
                    warning
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    onConfirm={() => {
                        setConfirmCancelAlert(null);
                        setLoading(true);
                        api.post(`roster/cancel-roster/${confirmCancelAlert}`)
                            .then((response) => {
                                loadDispacthUnitData();
                            })
                            .catch((error) => console.log(error.response))
                            .finally(() => setLoading(false));
                    }}
                    showCancel
                    cancelBtnText="Cancel"
                    cancelBtnBsStyle="primary"
                    onCancel={() => {
                        setConfirmCancelAlert(null);
                    }}
                >
                    <span>This will release all assigned candidates and disable actions for this roster, are you sure?</span>
                </SweetAlert>
            }
            {
                stepUpInEdit &&
                <PositionStepUpEditor
                    rosterCandidateId={stepUpInEdit.rosterCandidateId}
                    candidate={stepUpInEdit.candidate}
                    position={stepUpInEdit.position}
                    company={stepUpInEdit.company}
                    startDate={stepUpInEdit.startDate}
                    onClose={() => setStepUpInEdit(null)}
                    onSave={() => {
                        setStepUpInEdit(null);
                        setLoading(true);
                        saveProcess()
                            .then(() => {
                                loadDispacthUnitData();
                            })
                            .catch((error) => {
                                setLoading(false);
                                console.error(error);
                            })
                            ;

                    }}
                />
            }
            {
                candidateContractInEdit &&
                <CandidateDocumentsEditor
                    project={candidateContractInEdit.project}
                    company={candidateContractInEdit.company}
                    candidate={candidateContractInEdit.candidate}
                    locationId={candidateContractInEdit.locationId}
                    positionId={candidateContractInEdit.positionId}
                    rosterCandidateId={candidateContractInEdit.rosterCandidateId}
                    onClose={() => {
                        setCandidateContractInEdit(null);
                        loadDispacthUnitData();
                    }}
                />
            }
            {
                rosterAttributeInEdit &&
                <RosterAttributeEditor
                    rosterCandidateId={rosterAttributeInEdit.rosterCandidateId}
                    project={rosterAttributeInEdit.project}
                    candidate={rosterAttributeInEdit.candidate}
                    onClose={() => {
                        setRosterAttributeInEdit(null);
                        loadDispacthUnitData();
                    }}
                />
            }
            {
                openRosterImport && dispatchUnitData && dispatchUnitData.length > 0 &&
                <ImportDialog
                    onClose={() => {
                        setOpenRosterImport(false);
                        loadDispacthUnitData();
                    }}
                    rosterId={dispatchUnitData[0].rosters[0].rosterId}
                />
            }
            {
                dispatchUnitInEdit &&
                <DispatchUnitEditor
                    dispatchUnitId={dispatchUnitInEdit}
                    onClose={() => setDispatchUnitInEdit(null)}
                    onSaved={(data) => {
                        setDispatchUnitInEdit(null);
                        refreshDispatchUnits((list) => {
                            if (!list.filter(l => l.lookupId === data.dispatchUnitId).length) {
                                setDispatchUnit(null);
                                setReload(true);
                                return;
                            }

                            setDispatchUnit({
                                ...list.filter(l => l.lookupId === data.dispatchUnitId)[0]
                            });
                            setReload(true);
                        });
                    }}
                    onDeleted={() => {
                        setDispatchUnitInEdit(null);
                        refreshDispatchUnits();
                        setDispatchUnit(null);
                        loadDispacthUnitData();
                    }}
                />
            }
            {
                deletedData &&
                <DeleteSlotAlert
                    dispatchUnitData={dispatchUnitData}
                    rosterCandidate={deletedData}
                    setDispatchUnitData={setDispatchUnitData}
                    onSort={sortSlot}
                    onClose={() => setDeletedData(null)}
                    onDelete={(data) => {
                        setDispatchUnitData(data);
                        setDeletedData(null);
                        setConfirmDelete(true);
                    }}
                />
            }
            {
                confirmCandidateChange && !confirmCandidateChange.confirmed && confirmCandidateChange.messageLogs && !confirmCandidateChange.revert ?
                    <SweetAlert
                        title="Change Candidate Confirmation"
                        warning
                        showCancel
                        cancelBtnText="No"
                        confirmBtnText="Yes"
                        confirmBtnBsStyle="danger"
                        onConfirm={() => setConfirmCandidateChange({ ...confirmCandidateChange, confirmed: true })}
                        onCancel={() => setConfirmCandidateChange({ ...confirmCandidateChange, confirmed: false, revert: true })}
                        customClass="modal-xl-mandatory"
                    >
                        <Container fluid>
                            <Row>
                                <Col lg="12" className="my-3">
                                    <h5 className="text-justify">
                                        Confirmation Message is detected for this Candidate. Are you sure you want to remove the candidate assignment?
                                    </h5>
                                </Col>
                                <Col lg="12">
                                    <h6 className="text-justify">
                                        Message Logs:
                                    </h6>
                                </Col>
                            </Row>
                            <Row style={{ height: 400, overflowY: "scroll" }}>
                                {
                                    confirmCandidateChange.messageLogs.map((m, idx) => {
                                        return (
                                            <Col lg="12" key={`candidate_change_confirm_${idx}`}>
                                                <p className="text-left">
                                                    <span style={{ fontWeight: "bold" }}>{moment(m.sentDate).format("DD-MM-YYYY HH:mm:ss")} - </span>{m.messageText}
                                                </p>
                                            </Col>
                                        );
                                    })
                                }
                            </Row>
                        </Container>
                    </SweetAlert>
                    :
                    null
            }
        </section>
    );
}