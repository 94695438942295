import React from 'react';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { formatDate } from '../../../utils';
import moment from "moment";
import { DatePicker } from "@progress/kendo-react-dateinputs";

function DateColumnFilter({
    column: {
        filterValue,
        preFilteredRows,
        setFilter
    },
    dateFormat,
    dateMask,
    className = ''
}) {
    const defaultFormat = "DD/MM/YYYY";
    const defaultMask = "99/99/9999";
    return (
        <InputGroup>
            <DatePicker
                format="dd/MM/yyyy"
                weekNumber={true}
                onChange={({value}) => {
                    if (!value)
                    {
                        setFilter(undefined);
                        return;
                    }

                    const formattedDate = moment(value).format(dateFormat ?? defaultFormat);
                    setFilter(formattedDate);
                }}
                size="sm"
                className="k-input-sm"
            />
        </InputGroup>
    )
}

export default DateColumnFilter;