import moment from 'moment';
import React, { useEffect, useState } from 'react';

export default (props) => {
    const {node, column, node: {data}} = props;

    return (
        <div key={`validation-status-${node.rowIndex}-${column.instanceId}`}
            className="border border-info px-3 py-1 d-flex flex-wrap justify-content-center"
            style={{ backgroundColor: 'white', width: '200px' }}
        >
            <div className="w-100">
            {
                data.validationResult?.warningItems.map((w, idx) => {
                    return (
                        <div className={`w-100 d-flex flex-wrap justify-content-center align-items-start${idx + 1 === data.validationResult?.warningItems.length ? "" : " border-bottom"}`}>
                            <div className="text-center" style={{width: "10%",}}>
                                <i className={`fas ${w.isError ? "fa-times-circle text-danger" : "fa-exclamation-circle text-warning"}`} />
                            </div>
                            <div style={{width: "90%"}}>
                                <span className="text-wrap">
                                {
                                    w.name
                                }
                                </span>
                            </div>
                        </div>
                    );
                })
            }
            </div>
        </div>
    );
};
