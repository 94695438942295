import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Input, Row } from "reactstrap";
import { TooltipButton } from "../../../inputs";
import Loader from "../../../loaders";
import api from "../../../../services/api";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import TemplateDialog from "./TemplateDialog";
import { Checkbox } from "@progress/kendo-react-inputs";

function SearchDialog(props) {
    const {
        title,
        initialFilter,
        initialCandidates,
        initialFilteredCandidates,
        initialSelectedCandidates,
        onClose,
        onNextClick
    } = props;

    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState([...initialCandidates, ...initialFilteredCandidates.filter(d => initialCandidates.filter(c => c.candidateId === d.candidateId).length === 0)]);
    const [ candidateName, setCandidateName ] = useState(initialFilter);
    const [ selectedData, setSelectedData ] = useState([...initialCandidates, ...initialSelectedCandidates.filter(d => initialCandidates.filter(c => c.candidateId === d.candidateId).length === 0)]);
    const gridRef = useRef();

    const loadingOverlayComponent = useMemo(() => Loader, []);

    const loadData = useCallback(() => {
        if (!candidateName || candidateName === "")
            return;

        setLoading(true);

        api.get(`candidate/search?filter=${candidateName}`)
            .then((response) => {
                setData([...initialCandidates, ...response.data.filter(d => initialCandidates.filter(c => c.candidateId === d.candidateId).length === 0)]);
            })
            .catch((error) => console.log({error}))
            .finally(() => setLoading(false))
        ;
    }, [api, candidateName, initialCandidates]);

    // useEffect(() => console.log({data}), [data]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        filter: false,
        editable: false,
        suppressMenu: true,
    }), []);

    const columnDefs = useMemo(() => {
        return [
            {
                field: "select",
                headerName: "",
                pinned: true,
                sortable: false,
                cellRenderer: (cellParams) => {
                    const {data: cellData} = cellParams;
                    return (
                        <div className="d-flex flex-wrap justify-content-center align-items-center w-100 h-100">
                            <Checkbox
                                onChange={(e) => {
                                    if (!e.value)
                                    {
                                        setSelectedData([...selectedData.filter(d => d.candidateId !== cellData.candidateId)]);
                                        return;
                                    }
                                    
                                    setSelectedData([...selectedData, {candidateId: cellData.candidateId, candidateName: cellData.candidateName}]);
                                }}
                                checked={selectedData.filter(d => d.candidateId === cellData.candidateId).length > 0}
                                disabled={initialCandidates.filter(c => c.candidateId === cellData.candidateId).length > 0}
                            />
                        </div>
                    );
                },
                suppressMenu: true,
            },
            {
                field: "candidateId",
                headerName: "SID",
                pinned: true,
            },
            {
                field: "employeeNumber",
                headerName: "Employee Number",
                pinned: true,
            },
            {
                field: "firstName",
                headerName: "First Name",
            },
            {
                field: "middleName",
                headerName: "Middle Name",
            },
            {
                field: "surname",
                headerName: "Surname",
            },
            {
                field: "mobilePhoneNumber",
                headerName: "Mobile",
            },
            {
                field: "email",
                headerName: "Email",
            },
            {
                field: "naturalPosition.positionName",
                headerName: "Position",
            },
            {
                field: "createdDate",
                headerName: "Created Date",
                cellRenderer: (cellParams) => {
                    const {data: cellData} = cellParams;
                    return (
                        <div className="d-flex flex-wrap justify-content-center align-items-center w-100 h-100">
                        {
                            moment(cellData.createdDate).format("DD-MM-YYYY HH:mm:ss")
                        }
                        </div>
                    );
                },
            },
            {
                field: "createdBy",
                headerName: "Created By",
            },
        ];
    }, [selectedData, initialCandidates]);

    const onGridReady = useCallback((params) => {
        const {api: gridApi} = params;

        gridApi.sizeColumnsToFit({
            defaultMinWidth: 120,
            columnLimits: [
                { key: 'select', maxWidth: 50 },
                { key: 'firstName', minWidth: 150 },
                { key: 'middleName', minWidth: 150 },
                { key: 'surname', minWidth: 150 },
                { key: 'createdDate', minWidth: 150 },
                { key: 'createdBy', minWidth: 150 },
            ],
        })
    }, []);

    const content = useMemo(() => (
        <>
            <Row className="m-0 p-0">
                <Col lg={12} className="m-0 p-0 d-flex flex-wrap justify-content-start align-items-center">
                    <span className="font-size-small-custom">
                        <i className="fas fa-info-circle mr-1" />Please enter first name and surname of the talent and then click the search button.<br/>
                        <i className="fas fa-info-circle mr-1" />You can select the talent data that you want to merge using the checkbox.
                    </span>
                </Col>
            </Row>
            <Row className="m-0 p-0 mt-3">
                <Col lg={12} className="m-0 p-0 d-flex flex-wrap justify-content-start align-items-center">
                    Search Talent
                    <Input
                        name="candidateName"
                        id="candidateName"
                        type="text"
                        value={candidateName}
                        onChange={(e) => setCandidateName(e.target.value ?? "")}
                        className="ml-2 w-25"
                        autoComplete="off"
                    />
                    <TooltipButton
                        id="search-talent"
                        title="Search"
                        className="btn-icon ml-2"
                        color="default"
                        size="sm"
                        type="button"
                        onClick={() => loadData()}
                        disabled={!candidateName || candidateName === ""}
                    >
                        <i className="fas fa-search"></i>
                    </TooltipButton>
                </Col>
            </Row>
            <Row className="m-0 p-0 mt-2">
                <div className="ag-theme-alpine ag-grid clickable-row" style={{ width: '100%', height: '60vh' }}>
                    <AgGridReact
                        ref={gridRef}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowData={data}
                        loadingOverlayComponent={loadingOverlayComponent}
                        suppressColumnVirtualisation={false}
                        suppressRowVirtualisation={false}
                        rowBuffer={20}
                        debounceVerticalScrollbar={false}
                        allowContextMenuWithControlKey={true}
                        suppressContextMenu={true}
                        suppressClipboardPaste={true}
                        headerHeight={25}
                        groupHeaderHeight={22}
                        rowHeight={22}
                        readOnlyEdit={true}
                        enableRangeSelection={true}
                        tooltipShowDelay={0}
                        tooltipHideDelay={20000}
                        suppressDragLeaveHidesColumns={true}
                        suppressColumnMoveAnimation={true}
                        multiSortKey='ctrl'
                        enableCellChangeFlash={true}
                        animateRows={true}
                        onGridReady={onGridReady}
                    />
                </div>
            </Row>
        </>
    ), [candidateName, loadData, gridRef, columnDefs, defaultColDef, data, onGridReady]);

    const footer = useMemo(() => (
        <>
            <Button
                color="secondary"
                onClick={onClose}
                type="button"
            >
                Close
            </Button>
            <Button
                color="info"
                onClick={() => onNextClick(candidateName, data, selectedData)}
                type="button"
                disabled={selectedData?.length < 2}
            >
                Next: Data Compare - Talent's Profile<i className="fas fa-chevron-right ml-2" />
            </Button>
        </>
    ), [onClose, onNextClick, candidateName, data, selectedData]);

    return (
        <TemplateDialog
            title={title}
            loading={loading}
            content={content}
            footer={footer}
            onClose={onClose}
        />
    );
};

export default SearchDialog;
