/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { AgGridReact } from "ag-grid-react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Container, Input, Label, Modal, Row, Spinner, FormGroup } from "reactstrap";
import Loader from "../../../loaders";
import DateColumnTooltip from "../tooltips/DateColumnTooltip";
import api from "../../../../services/api";
import "../../../../assets/css/ag-grid.css";
import "../../../../assets/css/ag-theme-alpine.css";
import TalentEditor from '../../Candidates/Editor';
import moment from "moment";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { TooltipButton } from "../../../inputs";
import { Checkbox } from "@progress/kendo-react-inputs";
import SweetAlert from "react-bootstrap-sweetalert";
import ErrorNotification from "../../../alerts/ErrorNotification";

import { Dropdown, DropdownBox } from "../../../dropdowns";
import { IsNullOrWhiteSpace } from "../../../../helpers/StringHelper";

const ALTRAD_POOL = "Altrad Pool";
const PROJECT_POOL = "Project Pool";
const NONPROJECT_POOL = "Non-project Pool";

export default function PrepopulateWorkSequence(props) {
    const {
        dates,
        projectId,
        dispatchUnitId,
        lookups,
        onClose,
        onSave,
        loadSchedule,
        setShowTalentsSearch
    } = props;

    const [loading, setLoading] = useState(false);
    const [rosterSequences, setRosterSequences] = useState([]);
    const [modalSize, setModalSize] = useState("");
    //const [selectedRosterSequence, setSelectedRosterSequence] = useState(null);
    const [itemInEdit, setItemInEdit] = useState({
        rosterSequenceId: null,
        rosterSequence: null,
        swings: "1",
        workSequence: null
    });

    const [emptySlotAlert, setEmptySlotAlert] = useState(null);
    const talentScheduleGridRef = useRef();
    //const rsddIsOpenRef = useRef(false); 

    // Error message variables
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorTitle, setErrorTitle] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);
    const [errorMessageOpening, setErrorMessageOpening] = React.useState(`Error occured when processing the request: `);
    const [errorTitleOnGenerate, setErrorTitleOnGenerate] = React.useState(`Pre-populate Work Sequence Error`);


    const loadData = useCallback(() => {
        //setLoading(true);
        // console.log({dispatchUnitId, rosterCandidateIds, emptySlotGridRef});

        api.get(`rostersequence/get-all`)
            .then((response) => {
                //const { data: { emptySlots, startDate, endDate, positionSearched } } = response;
                // console.log({emptySlots, startDate, endDate, positionSearched});
                const { data } = response;
                console.log('data', data);
                console.log('response', response);

                setRosterSequences(data);


            })
            .catch((error) => {
                console.log({ error });
                setErrorMessage(`${error.response?.data ?? "System Error!"}`);
                setErrorTitle("Pre-populate Work Sequence Error");
            })
            .finally(() => {
                setLoading(false);
            });
        ;
    }, []);

    useEffect(() => {
        loadData();
    }, []);

    //useEffect(() => {
    //    console.log('rsddIsOpenRef', rsddIsOpenRef);
    //    if (rsddIsOpenRef.current) {
    //        setModalSize("modal-enlarge-dropdown");
    //    } else {
    //        setModalSize("");
    //    }
    //}, [rsddIsOpenRef, rsddIsOpenRef.current])

    const handleSave = useCallback(() => {
        //if (!emptySlotRowData.filter(d => d.candidateId).length)
        //{
        //    onSave();
        //    return;
        //}

        if (validateSwings() == false) {
            return;
        }

        setLoading(true);

        // Swing 
        var swingsInt = parseInt(itemInEdit.swings, 10);

        // Repeat the sequence and then remove the last colon
        var newWorkSequence = (itemInEdit.workSequence + ":").repeat(swingsInt).slice(0, -1);


        //const dataToSend = [...emptySlotRowData.filter(d => d.candidateId).map(d => ({rosterCandidateId: d.rosterCandidateId, candidateId: d.candidateId, positionId: d.positionId}))];
        const dataToSend = {
            workSequence: newWorkSequence,
            workSequencePeriod: "daily",
            //swings: swingsInt,
            dates
        };


        api.post(`/rostergrid/worksequence/populate`, dataToSend)
            .then((response) => {
                const { data: responseData } = response;
                // const tx = {
                //     update: responseData.rosterCandidates
                // };
                // _gridApi.applyTransactionAsync(tx, (result) => {
                //     _gridApi.clearRangeSelection();
                //     _gridApi.refreshCells({force: true, rowNodes: result.update});
                //     _setDisableTrimStartDateDefault(false);
                //     _setDisableTrimEndDateDefault(false);
                //     _setStartDate()
                //     _gridApi.hideOverlay();
                // });
                loadSchedule({
                    projectId: projectId,
                    dispatchUnitId: dispatchUnitId,
                    startDate: responseData.startDate,
                    endDate: responseData.endDate,
                });
                onSave();
            })
            .catch((error) => {
                // TODO: Display Error message in UI
                console.log('error log');
                console.log({ error });
                setErrorMessage(`${error.response?.data ?? "System Error!"}`);
                setErrorTitle(errorTitleOnGenerate);
            })
            .finally(() => {
                setLoading(false);
            })
            ;
    }, [onSave, projectId, dispatchUnitId, itemInEdit, dates]);

    useEffect(() => {
        console.log('dates', dates);
    }, [dates]);

    const validateSwings = () => {

        // Find the roster sequence
        //const value = itemInEdit?.swings;
        //const isEmpty = IsNullOrWhiteSpace(value);
        //const splitVal = value == null ? null : value.split(':');
        //const daysOn = isEmpty ? null : splitVal[0] ? splitVal[0].trim() : null;
        //const daysOff = isEmpty ? null : splitVal[1] ? splitVal[1].trim() : null;

        //if (daysOn == null || daysOff == null) { // If either daysOn or daysOff is null
        //    setErrorMessage("Invalid swings format, please enter using 0:0 format");
        //    setErrorTitle(errorTitleOnGenerate);
        //    return false;
        //}

        // Validate Roster Sequence is selected
        if (!itemInEdit.rosterSequence) {
            setErrorMessage("Please select a roster sequence");
            setErrorTitle(errorTitleOnGenerate);
            return false;
        }


        // Validate swings is number and greater than 0
        var swingsInt = parseInt(itemInEdit.swings, 10);

        if (swingsInt < 1) {
            setErrorMessage("Invalid swings format, please enter swings as number greater than 0");
            setErrorTitle(errorTitleOnGenerate);
            return false;
        }

        return true;

    }

    // BEGIN ERROR MESSAGE HANDLER -----------------------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            onConfirm: () => {
                setErrorMessage(null);
                setErrorTitle(null);
            },
            message: errorMessage,
            title: errorTitle ?? errorTitleOnGenerate
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage, errorTitle]);
    // END ERROR MESSAGE HANDLER -------------------------------------------------------------------------------------------------------------------------------------------

    return (
        <Modal
            isOpen={true}
            className={`modal-lg modal-with-dropdown`}
            modalClassName="db-example-modal-lg"
        >
            {loading && <Loader />}
            {errorNotification.length > 0 && errorNotification}
            <div
                className="modal-header"
            >
                <Container fluid className="p-0 m-0">
                    <Row className="p-0 m-0">
                        <Col lg={6} className="p-0 m-0 d-flex flex-column justify-content-start align-items-start">
                            <h5
                                className="modal-title"
                            >
                                Pre-populate Work Sequence
                            </h5>
                        </Col>
                        <Col lg={6} className="p-0 m-0 d-flex flex-wrap justify-content-end align-items-center">
                            <button
                                aria-label="Close"
                                className="close"
                                onClick={onClose}
                                type="button"
                            >
                                <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                            </button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className={`modal-body p-2 modal-body-animate ${modalSize}`}
                style={{ maxHeight: "70vh", overflowY: "auto" }}
            >
                <Container fluid>
                    <Row>
                        <Col sm={12}>

                            <FormGroup>
                                <Row>
                                    <Col sm={4}>
                                        <Label>
                                            Roster Sequence
                                            <span className="text-danger">*</span>
                                        </Label>

                                    </Col>
                                    <Col sm={8} className="roster-sequence-select">
                                        <DropdownBox
                                            data={rosterSequences}
                                            selectedItem={itemInEdit.rosterSequence}
                                            onChange={(item) => {
                                                setItemInEdit({
                                                    ...itemInEdit,
                                                    rosterSequence: item,
                                                    rosterSequenceId: !item ? null : item.rosterSequenceId,
                                                    workSequence: !item ? null : item.daysOn + ":" + item.daysOff
                                                });
                                            }}
                                            idField="rosterSequenceId"
                                            valueField="label"
                                            //isOpenRef={rsddIsOpenRef}
                                            onDropdownPress={(isOpen) => {
                                                if (isOpen) {
                                                    setModalSize("modal-enlarge-dropdown");
                                                } else {
                                                    setModalSize("");
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>

                            <FormGroup>
                                <Row>
                                    <Col sm={4}>
                                        <Label>
                                            Swings
                                            <span className="text-danger">*</span>
                                        </Label>
                                    </Col>
                                    <Col sm={8}>
                                        <Input
                                            name="swings"
                                            id="swings"
                                            type="number"
                                            min="1"
                                            required
                                            value={!itemInEdit ? "" : itemInEdit.swings}
                                            onChange={({ target: { name, value } }) => {

                                                // Find the roster sequence
                                                //const isEmpty = IsNullOrWhiteSpace(value);
                                                //const splitVal = value == null ? "" : value.split(':');
                                                //const daysOn = isEmpty ? null : splitVal[0] ? splitVal[0].trim() : "";
                                                //const daysOff = isEmpty ? null : splitVal[1] ? splitVal[1].trim() : "";
                                                //const rosterSequence = rosterSequences.find(rs => rs.daysOn == daysOn && rs.daysOff == daysOff);


                                                // Update the item in edit
                                                setItemInEdit({
                                                    ...itemInEdit,
                                                    swings: value,
                                                    //rosterSequence: rosterSequence,
                                                    //rosterSequenceId: !rosterSequence ? null : rosterSequence.rosterSequenceId,
                                                });



                                            }}
                                            readOnly={false}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer d-flex flex-wrap"
            >
                <Button
                    color="secondary"
                    onClick={onClose}
                    type="button"
                    className="mt-2"
                >
                    Close
                </Button>
                <Button
                    color="default"
                    onClick={handleSave}
                    type="button"
                    className="mt-2"
                    disabled={false}
                >
                    Save
                </Button>
            </div>

        </Modal>
    );
};
