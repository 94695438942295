import React from 'react';
import _ from "lodash";

function SelectColumnFilter({ 
    column: { 
        filterValue, 
        setFilter, 
        preFilteredRows, 
        id,
    },
}) {

    const options = React.useMemo(() => {   
        const options = _.uniqBy(preFilteredRows, (row) => row.values[id]);
        return options ? options.map(option => option.values[id]) : []
    }, [id, preFilteredRows]);

    return (
        <select
            className="select-filter form-control"
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>{option}</option>
            ))}
        </select>
    )
}

export default SelectColumnFilter;