/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import api from "../../../../services/api";
import { Label, FormGroup, Button, Container, Row, Col, Alert } from "reactstrap";
import Loader from "../../../loaders";
import { MilestonesTable, JobOrderMilestonesTable, MoveDir } from './Tables';
import _ from "lodash";
import { TooltipButton } from '../../../inputs';
import { useParams } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';

function JobOrderMilestoneEditor(props) {
    const params = useParams();
    const { jobOrderId } = params;

    const [loading, setLoading] = useState(false);
    const [jobOrder, setJobOrder] = useState(null);
    const [milestones, setMilestones] = useState([]);
    const [selectedMilestones, setSelectedMilestones] = useState([]);
    const [staticJobOrderMilestones, setStaticJobOrderMilestones] = useState([]);
    const [jobOrderMilestones, setJobOrderMilestones] = useState([]);
    const [validationAlert, setValidationAlert] = useState([]);
    const [showMilestoneRemovalWarning, setShowMilestoneRemovalWarning] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        setLoading(true);

        const apiCalls = [
            api.get(`joborder/milestone/${jobOrderId}`),
            api.get("/lookup/jobordermilestonemaster"),
        ];

        Promise.all(apiCalls)
            .then((responses) => {
                // console.log({responses});
                const {data: currentJobOrder} = responses[0];
                setJobOrder({
                    jobOrderId: currentJobOrder.jobOrderId,
                    recruitmentRequestNumber: currentJobOrder.recruitmentRequestNumber,
                    recruitmentCoordinator: currentJobOrder.recruitmentCoordinator,
                    recruitmentPerson: currentJobOrder.recruitmentPerson,
                });

                const currentJobOrderMilestones = responses[0].data?.jobOrderMilestones ? responses[0].data?.jobOrderMilestones.map(d => ({
                    jobOrderId: d.jobOrderId,
                    lookupId: d.jobOrderMilestoneMasterId,
                    jobOrderMilestoneId: d.jobOrderMilestoneId,
                    lookupValue: d.milestoneName,
                    order: d.orderingIndex,
                    isUsed: d.isUsed,
                })) : [];
                setJobOrderMilestones(currentJobOrderMilestones);
                setStaticJobOrderMilestones(currentJobOrderMilestones);

                setMilestones(responses[1].data);
            })
            .catch((error) => console.error("error: ", error))
            .finally(() => setLoading(false));
    }, [jobOrderId]);

    // useEffect(() => {
    //     console.log({jobOrderMilestones});
    // }, [jobOrderMilestones]);

    const handleAddSelected = useCallback(() => {
        const length = jobOrderMilestones.length;
        setJobOrderMilestones(_.concat(jobOrderMilestones, selectedMilestones.map((o, i) => ({ ...o, order: (length + i + 1) }))));

        const selectedMilestoneIds = selectedMilestones.map(o => o.lookupId);
        const updatedMilestones = _.values(_.chain(milestones).keyBy("lookupId").omit(selectedMilestoneIds).value());

        setMilestones(_.orderBy(updatedMilestones, o => o.lookupValue));
        setSelectedMilestones([]);
    }, [jobOrderMilestones, selectedMilestones, milestones]);

    const handleAdd = useCallback((item) => {
        const length = jobOrderMilestones.length;
        setJobOrderMilestones([...jobOrderMilestones, { ...item, order: length + 1 }]);
        setMilestones(milestones.filter(f => f.lookupId !== item.lookupId));
        setSelectedMilestones([]);
    }, [jobOrderMilestones, milestones]);

    const handleRemoveSelected = useCallback(() => {
        const selectedMilestoneIds = selectedMilestones.map(o => o.lookupId);
        const updatedJobOrderMilestones = _.values(_.chain(jobOrderMilestones).keyBy("lookupId").omit(selectedMilestoneIds).value());
        setJobOrderMilestones(_.orderBy(updatedJobOrderMilestones, o => o.order).map((o, i) => ({ ...o, order: i + 1 })));

        setMilestones(_.orderBy(_.concat(milestones, selectedMilestones), o => o.lookupValue));
        setSelectedMilestones([]);
    }, [selectedMilestones, jobOrderMilestones, milestones]);

    const handleRemove = useCallback((item) => {
        setMilestones(_.orderBy([...milestones, { ...item }], o => o.lookupValue));
        setJobOrderMilestones(jobOrderMilestones.filter(f => f.lookupId !== item.lookupId).map((o, i) => ({ ...o, order: i + 1 })));
        setSelectedMilestones([]);
    }, [milestones, jobOrderMilestones]);

    const splitJoms = useCallback((item, dir) => {
        const upperList = _.slice(jobOrderMilestones, 0, item.order - dir);
        const lowerList = _.slice(jobOrderMilestones, item.order - dir);
        return {
            upperList,
            lowerList,
            swapItems: lowerList.splice(0, 2)
        }
    }, [jobOrderMilestones]);

    const handleMoveUp = useCallback((item) => {
        const { upperList, lowerList, swapItems } = splitJoms(item, MoveDir.UP);

        const downItem = swapItems[0];
        const upItem = swapItems[1];

        setJobOrderMilestones([...upperList, { ...upItem, order: upItem.order - 1 }, { ...downItem, order: downItem.order + 1 }, ...lowerList]);
    }, [jobOrderMilestones]);

    const handleMoveDown = useCallback((item) => {
        const { upperList, lowerList, swapItems } = splitJoms(item, MoveDir.DOWN);

        const upItem = swapItems[0];
        const downItem = swapItems[1];

        setJobOrderMilestones([...upperList, { ...downItem, order: downItem.order - 1 }, { ...upItem, order: upItem.order + 1 }, ...lowerList]);
    }, [jobOrderMilestones]);

    const validate = useCallback(() => {
        const currentValidationResult = [];

        if (!jobOrder?.jobOrderId)
            currentValidationResult.push({
                message: "Invalid job order.",
            });

        if (!jobOrderMilestones.length)
            currentValidationResult.push({
                message: "There should be at least one milestone selected.",
            });

        setValidationAlert([...currentValidationResult]);
        return currentValidationResult.length === 0;
    }, [jobOrderMilestones]);

    const handleSave = useCallback(() => {
        setLoading(true);
        setErrorMessage(null);

        if (!validate())
        {
            setLoading(false);
            return;
        }

        const details = jobOrderMilestones.map(m => ({
            jobOrderMilestoneId: m.jobOrderMilestoneId ?? 0,
            jobOrderId: jobOrderId,
            jobOrderMilestoneMasterId: m.lookupId,
            orderingIndex: m.order,
        }));

        // console.log({details, jobOrderMilestones});

        api.post(`joborder/milestone/update/${jobOrder.jobOrderId}`, JSON.stringify(details))
            .then(() => {
                setLoading(false);
                window.location.replace(`joborder`);
            })
            .catch((error) => {
                console.error({error});
                setErrorMessage(error.response.data);
                setLoading(false);
            })
        ;
    }, [jobOrderId, jobOrder, jobOrderMilestones, validate]);

    const checkRemovedUsedMilestones = useCallback(() => {
        setShowMilestoneRemovalWarning(false);
        const removedMilestones = [];

        staticJobOrderMilestones.forEach(m => {
            const currentMilestones = jobOrderMilestones.filter(jom => jom.jobOrderMilestoneMasterId === m.jobOrderMilestoneMasterId);

            if (!currentMilestones.length)
                removedMilestones.push({...m});
        });
        
        setShowMilestoneRemovalWarning(removedMilestones.length > 0);

        if (!removedMilestones.length)
            handleSave();
    }, [staticJobOrderMilestones, jobOrderMilestones, handleSave]);

    return (
        <section className="main">
            {loading && <Loader />}
        {
            showMilestoneRemovalWarning ?
            <SweetAlert
                title="Milestone Removal"
                warning={true}
                showCancel
                confirmBtnText="Yes"
                confirmBtnBsStyle="danger"
                cancelBtnText="Cancel"
                onConfirm={() => handleSave()}
                onCancel={() => setShowMilestoneRemovalWarning(false)}
            >
                Used milestone removal detected. The milestone progress will also be removed. This action can't be undone. Continue?
            </SweetAlert>
            :
            null
        }
        {
            (validationAlert && validationAlert.length > 0) &&
            <Alert color="warning" className="pa-0 d-flex justify-content-between">
                <span className="alert-text">
                    <ul className="mb-0">
                    {
                        validationAlert.map((item, index) => <li key={index}>{item.message}</li>)
                    }
                    </ul>
                </span>
            </Alert>
        }
        {
            errorMessage ?
            <Alert color="warning" className="pa-0 d-flex justify-content-between">
                <span className="alert-text">
                    <ul className="mb-0">
                    {
                        errorMessage
                    }
                    </ul>
                </span>
            </Alert>
            :
            null
        }
            <Container fluid className="d-flex flex-wrap justify-content-between align-items-center pb-4">
                <span><h5><strong>{jobOrder?.jobOrderId ? `Job Order ${jobOrder.jobOrderId}` : ""}</strong></h5></span>
                <Button
                    color="info"
                    onClick={() => checkRemovedUsedMilestones()}
                    type="button"
                    disabled={!jobOrderMilestones?.length}
                >
                    Save
                </Button>
            </Container>
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Row>
                            <Col xs={3}>
                                <Row className="m-0 p-0">
                                    <Col xs={12} className="m-0 p-0">
                                        <FormGroup className="w-100">
                                            <Label>
                                                Recruitment Request:<br />
                                                <b>{jobOrder?.recruitmentRequestNumber ?? "N/A"}</b>
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={3} className="m-0 p-0">
                                <Row className="m-0 p-0">
                                    <Col xs={12} className="m-0 p-0">
                                        <FormGroup className="w-100">
                                            <Label>
                                                Recruitment Coordinator:<br />
                                                <b>{jobOrder?.recruitmentCoordinator?.lookupValue ?? "N/A"}</b>
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={3} className="m-0 p-0">
                                <Row className="m-0 p-0">
                                    <Col xs={12} className="m-0 p-0">
                                        <FormGroup className="w-100">
                                            <Label>
                                                Recruitment Person:<br />
                                                <b>{jobOrder?.recruitmentPerson?.lookupValue ?? "N/A"}</b>
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <MilestonesTable
                                    data={milestones}
                                    onSelect={setSelectedMilestones}
                                    onAdd={handleAdd}
                                    height="60vh"
                                />
                            </Col>
                            <Col xs={6}>
                                <Row>
                                    <Col xs={1} className="pt-5 px-0">
                                        <div style={{ marginLeft: "-3px" }}>
                                            <TooltipButton
                                                id="addselected"
                                                title="Add Selected"
                                                className="btn-icon mt-5 mb-3 py-2 px-3"
                                                color="default"
                                                type="button"
                                                outline
                                                onClick={handleAddSelected}
                                            >
                                                <i className="fas fa-chevron-right"></i>
                                            </TooltipButton>
                                        </div>
                                        <div style={{ marginLeft: "-3px" }}>
                                            <TooltipButton
                                                id="removeselected"
                                                title="Remove Selected"
                                                className="btn-icon py-2 px-3"
                                                color="default"
                                                type="button"
                                                outline
                                                onClick={handleRemoveSelected}
                                            >
                                                <i className="fas fa-chevron-left"></i>
                                            </TooltipButton>
                                        </div>
                                    </Col>
                                    <Col xs={11}>
                                        <JobOrderMilestonesTable
                                            data={jobOrderMilestones}
                                            onSelect={setSelectedMilestones}
                                            onRemove={handleRemove}
                                            onMoveUp={handleMoveUp}
                                            onMoveDown={handleMoveDown}
                                            height="60vh"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default JobOrderMilestoneEditor;
