import React from 'react';
import {
    Label, FormGroup, Button, Modal, Container, Row, Col, Alert, Input
 } from "reactstrap";
// import { DropdownBox } from "../../../dropdowns";
import _ from "lodash";
import Loader from "../../../loaders";
import api from "../../../../services/api";
import { useDropzone } from 'react-dropzone';
import cn from "classnames";
import fileDownload from "js-file-download";
import { RadioGroup } from "../../../inputs";
import { TrimObject } from '../../../../helpers/StringHelper';

export default function Editor(props) {

    const [ itemInEdit, setItemInEdit ] = React.useState(props.item);
    const [ loading, setLoading ] = React.useState(false);
    const [ validationAlert, setValidationAlert ] = React.useState([]);
    // const [ lookups, setLookups ] = React.useState({templateTypes:[]});

    // React.useEffect(() => {
    //     setLoading(true);
    //     const apiCalls = [api.get('lookup/contracttemplatetype')];
    //     Promise.all(apiCalls)
    //     .then((response) => {
    //         setLookups({
    //             templateTypes: [...response[0].data]
    //         })
    //     })
    //     .catch(error => console.error(error))
    //     .finally(() => setLoading(false));

    // }, []);

    const validate = React.useCallback(() => {
        let errors = [ ...validationAlert ];

        const fileExists = _.some(errors, (item) => item.key === "file" && item.type === "required");

        const hasFile = !!itemInEdit.file || (!itemInEdit.file && !!itemInEdit.templateFilename);
        if (!hasFile && !fileExists)
        {
            errors.push( { key: "file", type: "required", message:  "A File is required" } )
        } else if (hasFile && fileExists)
        {
            _.remove(errors, (item) => item.key === "file" && item.type === "required");
        }

        setValidationAlert([ ...errors ]);
        if (errors.length > 0) {
            return false;
        }
        return true;
    }, [itemInEdit, validationAlert]);

    const downloadAttachment = (fileInfo) => {

        if (fileInfo instanceof File) {
            fileDownload(fileInfo, fileInfo.name);
        } else {
            setLoading(true);
            let url = `contracttemplate/download/${fileInfo.contractTemplateId}`;
            api.get(url, { responseType: 'blob' })
            .then(blob => {
                fileDownload(blob.data, fileInfo.templateFilename);
            }).catch(error => {
                console.error(error)
            }).finally(() => setLoading(false))
        }
    }

    const {
        getRootProps, 
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        maxFiles: 1,
        accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        multiple: false,
        onDrop: ([file]) => {
            console.log(file)
            setItemInEdit( { ...itemInEdit, file } );
        }
    });

    const files = React.useMemo(() => {
        let name, size, file;

        if (itemInEdit.file) {
            name = itemInEdit.file.name;
            size = itemInEdit.file.size;
            file = itemInEdit.file;
        } else if (itemInEdit.templateFilename) {
            name = itemInEdit.templateFilename;
            size = itemInEdit.templateLength;
            file = itemInEdit;
        } else {
            return null;
        }
        return <aside className="dropzone-list pt-3">
            <Label>Files</Label>
            <ul>
                <li key={name} className="pt-1">
                    <a onClick={() => downloadAttachment(file)}>{name} - {size} bytes</a>
                </li> 
            </ul>
        </aside>
    }, [ itemInEdit.file ]);

    const dropzoneClasses = React.useMemo(() => cn({
        'dropzone': true,
        'mt-2': true,
        'active': isDragActive,
        'accept': isDragAccept,
        'reject': isDragReject
    }), [
        isDragActive,
        isDragAccept,
        isDragReject
    ]);

    const handleTextChanged = ({ target: { name, value } }) => {
        setItemInEdit({ ...itemInEdit, [name]: value });
    }

    React.useEffect(() => {
        if (itemInEdit && validationAlert.length > 0) validate();
    }, [itemInEdit]);

    const handleSave = (item) => {
        item = TrimObject(item);

        if (!validate()) {
            return;
        }
        setLoading(true);

        let url = `${process.env.REACT_APP_WEB_API_BASE_URI}/contracttemplate`;
        let method = "POST";

        if (!!item.contractTemplateId)
        {
            url = `${url}/${item.contractTemplateId}`;
            method = "PUT";
        }

        var formData = new FormData();

        formData.append("contractTemplateId", item.contractTemplateId ?? 0);
        formData.append("templateName", item.templateName || "");
        formData.append("hidden", !!item.hidden);

        if (item.file) {
            formData.append("file", item.file);
        }

        api({ url, method, data: formData }).then((response) => {
            console.log("upload: ", response);

            props.onSaved();
        })
        .catch((error) => {
            console.error(error.response);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    {itemInEdit?.contractTemplateId ? "Edit" : "New"} Template
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}>x</span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                {validationAlert && validationAlert.length > 0 && <Alert color="warning" className="pa-0 d-flex justify-content-between">
                    <span className="alert-text">
                        <ul className="mb-0">
                            {
                                validationAlert.map((item, index) => <li key={index}>{item.message}</li>)
                            }
                        </ul>
                    </span>
                </Alert>}
                <Container>
                    {(loading) && <Loader />}
                    <Row> 
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Name
                                </Label>
                                <Input
                                    name="templateName"
                                    id="templateName"
                                    type="text"
                                    required
                                    value={itemInEdit.templateName}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>
                        {/* <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Template Type
                                    <span className="text-danger">*</span>
                                </Label>
                                <DropdownBox
                                    data={lookups.templateTypes}
                                    selectedItem={itemInEdit.templateType}
                                    onChange={(item) => setItemInEdit( { ...itemInEdit, templateType: item, templateTypeId: item ? item.lookupId : null } )}
                                />
                            </FormGroup>
                        </Col> */}
                        <Col xs={12}>
                            <FormGroup>
                                <Label>Active</Label>
                                <RadioGroup 
                                    items={[
                                        {lookupId: 1, lookupValue: "Yes"}, 
                                        {lookupId: 2, lookupValue: "No"}
                                    ]} 
                                    name="hidden" 
                                    handleChange={({ target: { name, value } }) => {
                                        const lookupId = parseInt(value);
                                        setItemInEdit({ ...itemInEdit, [name]: lookupId === 2 });
                                    }}
                                    selected={itemInEdit.hidden ? 2 : 1}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <div {...getRootProps({className: dropzoneClasses})}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop a file here, or click to select a file</p>
                            </div>
                            {files}
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={() => {
                        props.onClose();
                    }}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave(itemInEdit)}
                    type="button"
                >
                    Save Changes
                </Button>
            </div>
        </Modal>
    )
}