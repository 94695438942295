/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useState } from 'react';
import {
    Container, Row, Col, Button, CardTitle, Modal, FormGroup, Label, Input, Alert
 } from "reactstrap";
 import api from "../../../../services/api";
 import qs from "qs";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../../alerts";
import { ComboBox, DropdownBox } from "../../../dropdowns";
import { TooltipButton } from '../../../inputs';
import Loader from "../../../loaders";
import { DefaultColumnFilter } from "../../../react-table/filters";
import { useTable, useExpanded, useFilters, useSortBy, useFlexLayout, useAsyncDebounce } from 'react-table';
import Grid from "../../../Grid";
import {
    ListBox
} from "@progress/kendo-react-listbox";
import { IndeterminateCheckbox } from '../../../react-table/controls';
import { useMsal } from '@azure/msal-react';
import _ from 'lodash';
import { roles as existingRoles } from '../../../../auth/roles';
import { TrimObject } from '../../../../helpers/StringHelper';
import SweetAlert from 'react-bootstrap-sweetalert';

const ListBoxItem = (props) => {
    const { dataItem, selected, onClick, ...others } = props;
    return (
        <li {...others} onDoubleClick={onClick}>
            <span>{dataItem.name}</span>
        </li>
    );
};

function MessagesMaster(props) {

    const [ messagesMasterData, setMessagesMasterData ] = React.useState([]); 
    const [ messagesTypes, setMessagesTypes ] = React.useState([]);      
    const [ messagesMasterLoading, setMessagesMasterLoading ] = React.useState(false);
    const [ messagesMasterInEdit, setMessagesMasterInEdit ] = React.useState(null);
    const [ messagesMasterInEditBefore, setMessagesMasterInEditBefore ] = React.useState(null);
    const [ pageNumber, setPageNumber ] = React.useState(1);
    const [ pageSize, setPageSize ] = React.useState(10);
    const [ totalRecords, setTotalRecords ] = React.useState(0);
    const [ filters, setFilters ] = React.useState([]);
    const [ invalidMessages, setInvalidMessages ] = useState(null);
    const [ needTalentResponse, setNeedTalentResponse ] = useState(false);
    const [ needYesNoResponse, setNeedYesNoResponse ] = useState(false);
    const [ needCommentResponse, setNeedCommentResponse ] = useState(null);
    const [ validation, setValidation ]= useState(null);

    const defaultSort = React.useMemo(() => [{ id: "project", desc: false }],
        []
    );
    
    const [ sortBy, setSortBy ] = React.useState(defaultSort);
    
    const [ deleteAlert, setDeleteAlert ] = React.useState([]);

    const [ emailInvalid, setEmailInvalid ] = React.useState(false);
    const skipPageResetRef = React.useRef();
    const [ attributes, setAttributes ] = React.useState([]);
    const [ cursorPosition, setCursorPosition ] = React.useState(0);
    
    const { instance } = useMsal();
    const { idTokenClaims: { roles } } = instance.getActiveAccount();

    const [ isSuperAdmin, setIsSuperAdmin ] = useState(_.some(roles, o => existingRoles.SuperAdmin === o));

    useEffect(() => {
        setIsSuperAdmin(_.some(roles, o => existingRoles.SuperAdmin === o));
    }, [roles]);

    const loadData = React.useCallback(() => {
        skipPageResetRef.current = true;
        const queryString = qs.stringify({filters, sortBy}, { allowDots: true });
        setMessagesMasterInEdit(null);
        setMessagesMasterInEditBefore(null);

        setMessagesMasterLoading(true);
        api.get(`/messagesmaster/${(pageNumber - 1) * pageSize}/${pageSize}${queryString ? `?${queryString}` : ""}`)
        .then(({data}) => {
            setMessagesMasterData(data.data);
            setTotalRecords(data.total);
        }).catch((error) => {
            setMessagesMasterData([]);
            setTotalRecords(0);
            console.error("error: ", error);
        }).finally(() => setMessagesMasterLoading(false));
    }, [filters, sortBy, pageNumber, pageSize]);

    const messageValidation = useCallback((title, message, item, handleSaveCallback) => {
        if (!title || title.trim() === "")
        {
            setInvalidMessages([{
                failCategory: "Empty Title",
            }]);
            return false;
        }
        
        if (!message || message.trim() === "")
        {
            setInvalidMessages([{
                failCategory: "Empty Message",
            }]);
            return false;
        }

        if ((title.toLowerCase() === "availability" || title.toLowerCase() === "accommodation") && message.includes("{FlowUrls.TalentResponseUrl}"))
        {
            setInvalidMessages([{
                failCategory: "Invalid Attribute Usage",
                message: "Attribute {FlowUrls.TalentResponseUrl} cannot be used if the message title is Availability or Accommodation."
            }]);
            return false;
        }

        if (message.includes("{FlowUrls.TalentResponseUrl}") && !needTalentResponse)
        {
            setInvalidMessages([{
                failCategory: "URL Response",
            }]);
            return false;
        }
        
        if (!message.includes("{FlowUrls.TalentResponseUrl}") && needTalentResponse)
        {
            setInvalidMessages([{
                failCategory: "Talent Response Parameter",
            }]);
            return false;
        }


        // Get title if changed
        var isValidated = null;
        var titleBefore = messagesMasterInEditBefore?.title == null ? '' : messagesMasterInEditBefore.title.trim();
        var titleAfter = title == null ? '' : title.trim();

        var titleLowerBefore = titleBefore.toLowerCase();
        var titleLowerAfter = titleAfter.toLowerCase();


        // BEGIN - If the title is changed, check if it is being used by a milestone ------------------------------------------------------------------------
        if (titleBefore != '' && titleLowerBefore != titleLowerAfter) {


            const queryStringBefore = qs.stringify({ name: titleBefore }, { allowDots: true });
            //const queryStringAfter = qs.stringify({ name: titleAfter }, { allowDots: true });
            const apiCalls = [
                api.get(`/messagesmaster/check-for-milestone?${queryStringBefore}`),
                //api.get(`/messagesmaster/check-for-milestone?${queryStringAfter}`),
            ];


            setMessagesMasterLoading(true);

            Promise.all(apiCalls)
                .then((responses) => {
                    setMessagesMasterLoading(false);
                    var foundMilestonesBefore = responses[0].data;
                    //var foundMilestonesAfter = responses[1].data;


                    // If the title is being used by a milestone, show error
                    if (foundMilestonesBefore.length > 0 && foundMilestonesBefore[0].requireSms == true) {
                        isValidated = false;
                        setValidation({
                            title: `Messages Master`,
                            message: `This message is being used by Job Order Milestone Master. To continue, please open Maintenance -> Job Order Milestone Master -> ${titleBefore} -> Uncheck Require SMS -> Save.`
                        });
                        return;
                    }

                    isValidated = true;
                    if (!!handleSaveCallback) {
                        handleSaveCallback(item, isValidated);
                    }

                })
                .catch((error) => {
                    setMessagesMasterLoading(false);

                    setValidation({
                        title: `Messages Master`,
                        message: `Unexpected error.`
                    });

                    console.error(error);
                });
            return;
        }
        // END - If the title is changed, check if it is being used by a milestone ------------------------------------------------------------------------

        // If the title is not being used by a milestone, proceed with the save
        isValidated = true;
        setInvalidMessages(null);
        if (!!handleSaveCallback) {
            handleSaveCallback(item, isValidated);
        }
        return isValidated;
    }, [needTalentResponse, messagesMasterInEditBefore]);

    const handleDelete = (item) => {
        const success = () => {
            setDeleteAlert([<DeleteSuccessAlert onConfirm={() => setDeleteAlert([])} />]);
            if (messagesMasterData.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1);
            } else {
                loadData();
            }
        };
        const failure = () => setDeleteAlert([<DeleteFailedAlert onConfirm={() => setDeleteAlert([])} />]);
        const events = {
            onConfirm: () => api.delete(`/messagesmaster/${item.messagesMasterId}`).then(success).catch(failure),
            onCancel: () => setDeleteAlert([])
        }


        const queryString = qs.stringify({ name: item.title }, { allowDots: true });
        const apiCalls = [
            api.get(`/messagesmaster/check-for-milestone?${queryString}`),

        ];

        setMessagesMasterLoading(true);

        Promise.all(apiCalls)
            .then((responses) => {
                setMessagesMasterLoading(false);
                var foundMilestones = responses[0].data;

                // If the title is being used by a milestone, show error
                if (foundMilestones.length > 0 && foundMilestones[0].requireSms == true) {
                    setValidation({
                        title: `Messages Master`,
                        message: `This message is being used by Job Order Milestone Master. To continue, please open Maintenance -> Job Order Milestone Master -> ${item?.title} -> Uncheck Require SMS -> Save.`
                    });
                    return;
                }

                // If the title is not being used by a milestone, show delete confirmation
                setDeleteAlert([<ConfirmDeleteAlert {...events} />])
            })
            .catch((error) => {
                setMessagesMasterLoading(false);
                console.error(error);
            });


    }

    const handleSave = useCallback((item, isValidated = null) => {

        // Trim every string property
        item = TrimObject(item);
        setMessagesMasterInEdit(item);


        // Validate
        if (isValidated == null) {
            messageValidation(item.title, item.message, item, handleSave);
            return;
        }
        if (isValidated == false) {
            return;
        }

        //if (!messageValidation(item.title, item.message, item, handleSave))
        //    return;

        setMessagesMasterLoading(true);
        var validator = require("email-validator");
        if(validator.validate(item.emailAddress) || (item.emailAddress == null || item.emailAddress == "")){
            setMessagesMasterInEdit(item)

            const data = JSON.stringify(item);
            const save = item.messagesMasterId > 0 
                ? api.put(`messagesmaster/${item.messagesMasterId}`, data)
                : api.post("messagesmaster", data)

            save
            .then((response) => {
                loadData();
            })
            .catch((error) => {
                setMessagesMasterLoading(false);
                console.error(error);
            })
            .finally(() => {
                setEmailInvalid(false);
            });
            
        }
        if(!validator.validate(item.emailAddress)){
            setEmailInvalid(true);
            setMessagesMasterLoading(false);
        }
    }, [messageValidation]);

    const handleTextChanged = ({ target: { name, value, selectionStart } }) => {
        setMessagesMasterInEdit({ ...messagesMasterInEdit, [name]: value });
        setCursorPosition(selectionStart)
    }

    useEffect(() => {
        if (!messagesMasterInEdit?.title)
        {
            if (attributes?.length)
                setAttributes([...attributes.map(d => ({...d, hidden: false}))]);
            
            return;
        }
        
        const newMessageAttributes = [...attributes.map(d => ({...d, hidden: (messagesMasterInEdit.title.toLowerCase() === "availability" || messagesMasterInEdit.title.toLowerCase() === "accommodation") && d.category === "Message Attribute" && d.name === "{FlowUrls.TalentResponseUrl}"}))];
        setAttributes(newMessageAttributes);
    }, [messagesMasterInEdit?.title]);

    const handleDragStart = (e) => {
        console.log('handleDragStart', e)
        e.nativeEvent.dataTransfer.setData('text', e.dataItem.name);
        e.nativeEvent.effectAllowed = "copy";
    };

    React.useEffect(() => {
        const apiCalls = [
            api.get(`messagesmaster/messageattributes`),
            api.get(`projectAttribute/search`),
            api.get(`rosterAttribute/search`),
        ];

        Promise.all(apiCalls)
        .then((responses) => {
            setAttributes([ ...responses[0].data.map(d => ({...d, hidden: false})), ...responses[1].data.map(m => ({ name: `{${m.projectAttributeName}}`, category: "Project Attribute", hidden: false })), ...responses[2].data.map(m => ({ name: `{${m.rosterAttributeName}}`, category: "Roster Attribute", hidden: false })) ]);
        })
        .catch((error) => console.error(error));

        api.get(`/lookup/messagestype`)
        .then(({data}) => {
            setMessagesTypes(data);
        })
        .catch((error) => console.error(error));
    }, []);

    React.useEffect(() => {
        loadData();
    }, [pageNumber, pageSize, filters, sortBy]);

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
                })
            },
        }),
        []
    )

    const columns = React.useMemo(() => {
        const result = [
            {
                Header: "Title",
                id: "title",
                accessor: (row) => row.title,
                filter: "text",
                width: 100,
                disableSortBy: false,
            },
            {
                Header: "Message",
                id: "message",
                accessor: (row) => row.message,
                filter: "text",
                width: 200,
                disableSortBy: false,
            },
            {
                Header: "Actions",
                id: 'button',
                Cell: ( { row: { original } } ) =>
                    (
                        <div>
                            <TooltipButton
                                id={`edit_${original.messagesMasterId}`}
                                title="Edit"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={() => {
                                    setMessagesMasterInEdit(original);
                                    setMessagesMasterInEditBefore({ ...original });
                                }}
                            >
                                <i className="fas fa-pencil-alt pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`delete_${original.messagesMasterId}`}
                                title="Delete"
                                className="btn-icon"
                                color="warning"
                                size="sm"
                                type="button"
                                onClick={() => handleDelete(original)}
                            >
                                <i className="fas fa-trash-alt pt-1"></i>
                            </TooltipButton>
                        </div>
                    ),
                width: 70,
            },
        ];

        if (isSuperAdmin)
            result.splice(2, 0, {
                Header: "Hidden",
                id: "hidden",
                accessor: (row) => row.isHidden ? "Yes" : "No",
                filter: "text",
                width: 50,
                disableSortBy: false,
            });

        return result;
    }, [isSuperAdmin]);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
    } = useTable(
        {
            columns,
            data: messagesMasterData,
            initialState: {
                sortBy: defaultSort,
                canSort: true,
            },
            filterTypes,
            defaultColumn,
            manualSortBy: true,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
        useExpanded
    );
    
    React.useEffect(() => {
        setFilters(state.filters);
    }, [state.filters]);
    
    React.useEffect(() => {
        setSortBy(state.sortBy);
    }, [state.sortBy]);

    useEffect(() => {
        if (!needTalentResponse)
        {
            setNeedYesNoResponse(false);
            setNeedCommentResponse(false);
            
            if (messagesMasterInEdit)
                setMessagesMasterInEdit({
                    ...messagesMasterInEdit,
                    includeUrl: needTalentResponse,
                    showYesNo: false,
                    showComment: false
                });
    
            return;
        }

        setNeedYesNoResponse(true);
        setNeedCommentResponse(true);

        if (messagesMasterInEdit)
            setMessagesMasterInEdit({
                ...messagesMasterInEdit,
                includeUrl: needTalentResponse,
                showYesNo: true,
                showComment: true
            });
    }, [needTalentResponse]);

    useEffect(() => {
        if (!needYesNoResponse && !needCommentResponse && needTalentResponse)
        {
            setNeedTalentResponse(false);

            if (messagesMasterInEdit)
                setMessagesMasterInEdit({
                    ...messagesMasterInEdit,
                    includeUrl: false,
                    showYesNo: needYesNoResponse,
                    showComment: needCommentResponse
                });
            
            return;
        }
        
        if (messagesMasterInEdit)
            setMessagesMasterInEdit({
                ...messagesMasterInEdit,
                includeUrl: needTalentResponse,
                showYesNo: needYesNoResponse,
                showComment: needCommentResponse
            });

    }, [needYesNoResponse, needCommentResponse]);
    
    return (
        <>
            {messagesMasterLoading && <Loader />}
            {deleteAlert.length > 0 && deleteAlert}
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <CardTitle>
                            <h4 className="text-center">Messages Master</h4>
                        </CardTitle>
                        <TooltipButton                        
                            id="addnew"
                            title="Add New"
                            className="btn-icon ml-2 mb-2"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => {  
                                setMessagesMasterInEdit({});
                                setMessagesMasterInEditBefore(null);
                            }}
                        >
                            <i className="fas fa-plus pt-1"></i>
                        </TooltipButton>
                        <Grid
                            height='calc(100vh - 460px)'
                            totalRecords={totalRecords}
                            pageSize={pageSize}
                            rows={rows}
                            tableProps={getTableProps()}
                            headerGroups={headerGroups}
                            tableBodyProps={getTableBodyProps()}
                            prepareRow={prepareRow}
                            onPagerChangePage={(pager) => {
                                setPageNumber(pager.currentPage);
                            }}
                            onPagerChangePageSize={(size) => {
                                setPageSize(size);
                            }}
                            needPaging={true}
                        />
                    </Col>
                </Row>
            </Container>
            {
            !!messagesMasterInEdit && 
            <Modal
                isOpen={true}
                className="modal-xl"
                modalClassName="db-example-modal-lg"
            >
                <div
                    className="modal-header"
                >
                    <h5
                        className="modal-title"
                    >
                        Messages Master Editor
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        onClick={() => {
                            setMessagesMasterInEdit(null);
                            setMessagesMasterInEditBefore(null);
                        }}
                        type="button"
                    >
                        <span aria-hidden={true}>x</span>
                    </button>
                </div>
                <div
                    className="modal-body"
                >
                    <Container>
                        <Row>
                            <Col xs={8}>
                                <Row>
                                {
                                    invalidMessages?.length > 0 &&
                                    <Col className="font-size-message-alert-custom text-danger" xs={12}>
                                        <ul>
                                        {
                                            invalidMessages.map((item, idx) => {
                                                if (item.failCategory === "Empty Title")
                                                    return (
                                                        <li key={`fail_${idx}`}>
                                                            <span>Message title cannot be empty.</span>
                                                        </li>
                                                    );

                                                if (item.failCategory === "Empty Message")
                                                    return (
                                                        <li key={`fail_${idx}`}>
                                                            <span>Message cannot be empty.</span>
                                                        </li>
                                                    );

                                                if (item.failCategory === "Invalid Attribute Usage")
                                                    return (
                                                        <li key={`fail_${idx}`}>
                                                            <span>{item.message}</span>
                                                        </li>
                                                    );

                                                if (item.failCategory === "URL Response")
                                                    return (
                                                        <li key={`fail_${idx}`}>
                                                            <span><b>Add URL to Response</b> must be checked.</span>
                                                        </li>
                                                    );
                                                
                                                if (item.failCategory === "Talent Response Parameter")
                                                    return (
                                                        <li key={`fail_${idx}`}>
                                                            <span><b>{"{FlowUrls.TalentResponseUrl}"}</b> must be provided.</span>
                                                        </li>
                                                    );
                                            })
                                        }
                                        </ul>
                                    </Col>
                                }
                                    <Col xs={12}>
                                        <FormGroup>
                                            <Label>
                                                Title
                                            </Label>
                                            <Input
                                                name="title"
                                                id="title"
                                                type="text"
                                                value={messagesMasterInEdit.title}
                                                onChange={handleTextChanged}
                                            />
                                        </FormGroup>
                                    </Col> 
                                    <Col xs={12}>
                                        <FormGroup>
                                            <Label>
                                                Message
                                            </Label>
                                            <Input
                                                name="message"
                                                id="message"
                                                type="textarea"
                                                rows={20}
                                                value={messagesMasterInEdit.message}
                                                onChange={handleTextChanged}
                                                onClick={(e) => {
                                                    //console.log('onClick', {e, currentTarget: e.currentTarget, nativeEvent: e.nativeEvent, target: e.target})
                                                    setCursorPosition(e.target.selectionStart)
                                                }}
                                                onKeyUp={(e) => {
                                                    //console.log('onKeyDown', {e, currentTarget: e.currentTarget, nativeEvent: e.nativeEvent, target: e.target, code: e.nativeEvent.keyCode})
                                                    if(e.nativeEvent.keyCode >= 37 || e.nativeEvent.keyCode <= 40) {
                                                        setCursorPosition(e.target.selectionStart)
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12}>
                                        <Row>
                                            <Col xs={12} md={4} lg={4} className="w-100">
                                                <FormGroup>
                                                    <IndeterminateCheckbox
                                                        id={`talentResponse`}
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            setNeedTalentResponse(!needTalentResponse);
                                                        }}
                                                        checked={needTalentResponse}
                                                        customTitle="Add URL to Response"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        {
                                            needTalentResponse &&
                                            <>
                                                <Col xs={12} md={4} lg={4}>
                                                    <FormGroup>
                                                        <IndeterminateCheckbox
                                                            id={`yesNoParam`}
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                setNeedYesNoResponse(!needYesNoResponse);
                                                            }}
                                                            checked={needYesNoResponse}
                                                            customTitle="Yes/No"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} md={4} lg={4}>
                                                    <FormGroup>
                                                        <IndeterminateCheckbox
                                                            id={`commentParam`}
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                setNeedCommentResponse(!needCommentResponse);
                                                            }}
                                                            checked={needCommentResponse}
                                                            customTitle="Comment"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </>
                                        }
                                        </Row>
                                    </Col>
                                {
                                    isSuperAdmin ?
                                    <Col lg={12}>
                                        <IndeterminateCheckbox
                                            id="isHidden"
                                            type="checkbox"
                                            onChange={() => {
                                                setMessagesMasterInEdit({ ...messagesMasterInEdit, isHidden: !messagesMasterInEdit.isHidden });
                                            }}
                                            checked={messagesMasterInEdit?.isHidden ?? false}
                                            customTitle="Hidden"
                                        />
                                    </Col>
                                    :
                                    null
                                }
                                </Row>
                            </Col>
                            <Col xs={4}>
                                <label>Variables</label>
                                <ListBox
                                    style={{
                                        width: "100%",
                                        height: "calc(100% - 29px)",
                                        maxHeight: 535,
                                        overflowY: "scroll",
                                        paddingBottom: "1rem",
                                    }}
                                    data={attributes.filter(a => !a.hidden)}
                                    textField="name"
                                    valueField='value'
                                    onItemClick={(e) => {
                                        const value = messagesMasterInEdit.message;
                                        const start = value?.substring(0, cursorPosition);
                                        const end = value?.substring(cursorPosition);
                                        // console.log('attribute click', {e, value, start, end, cursorPosition});
                                        setMessagesMasterInEdit({ ...messagesMasterInEdit, message: `${start ?? ""}${e.dataItem.name}${end ?? ""}` });
                                    }}
                                    item={ListBoxItem}
                                    onDragStart={handleDragStart}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="modal-footer"
                >
                    <Button
                        color="secondary"
                        onClick={() => { 
                            setMessagesMasterInEdit(null);
                            setEmailInvalid(false);
                        }}
                        type="button"
                    >
                        Close
                    </Button>
                    <Button
                        color="info"
                        onClick={() => handleSave(messagesMasterInEdit)}
                        type="button"
                    >
                        Save Changes
                    </Button>
                </div>
            </Modal>
            }
            {
                validation ?
                    <SweetAlert
                        title={validation.title}
                        error
                        confirmBtnText="OK"
                        confirmBtnBsStyle="danger"
                        onConfirm={() => setValidation(null)}
                    >
                        {validation.message}
                    </SweetAlert>
                    :
                    null
            }
        </>
    );
}

export default MessagesMaster;