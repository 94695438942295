import React, { useState } from 'react';
import {
    Button, Modal, Row, Col, FormGroup, Label, Input, Container
} from "reactstrap";
import api from "../../../../services/api";
import { Mode } from '../../../../utils';

export default function AddSlotDialog(props) {
    const {
        data,
        onClose,
        gridApi
    } = props;

    const {mode, action} = data;
    
    const [slotCount, setSlotCount] = useState(null);

    return (
        <Modal
            isOpen={true}
        >
            <div
                className="modal-header"
            >
                <Container fluid>
                    <Row>
                        <Col lg={9}>
                            <h5
                                className="modal-title"
                            >
                                {mode === Mode.ADD ? "Add" : "Copy"} N Slots
                            </h5>
                        </Col>
                        <Col lg={3}>
                            <button
                                aria-label="Close"
                                className="close"
                                onClick={onClose}
                                type="button"
                            >
                                <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                            </button>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <h6
                                className="modal-title"
                            >
                                
                            </h6>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-body"
            >
                <FormGroup>
                    <Label>
                        Slot Count
                        <span className="text-danger">*</span>
                    </Label>
                    <Input
                        type="number"
                        placeholder="Input the desired slot count"
                        name="slotCount"
                        onChange={(e) => setSlotCount(e.target.value)}
                    />
                </FormGroup>
            </div>
                <div
                    className="modal-footer"
                >
                    <div className="w-100 h-100 d-flex flex-wrap justify-content-end align-items-center">
                        <Button
                            color="default"
                            onClick={() => {
                                onClose();
                                gridApi.showLoadingOverlay();
                                action(gridApi, slotCount);
                            }}
                            type="button"
                            disabled={!gridApi || !slotCount || parseInt(slotCount) <= 0}
                        >
                            Submit
                        </Button>
                        <Button
                            color="secondary"
                            onClick={onClose}
                            type="button"
                        >
                            Close
                        </Button>
                    </div>
                </div>
        </Modal>
    )
}