import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import {
    Container, Row, Col, Card, CardBody, CardTitle, Button, Input, ButtonGroup
} from "reactstrap";
import { ComboBox, DropdownBox } from "../../dropdowns";
import Loader from "../../loaders";
import api from "../../../services/api";
import 'handsontable/dist/handsontable.full.css';
import qs from "qs";
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import moment from 'moment'
import { ErrorNotification } from '../../alerts'

function Wizard(props) {
    const location = useLocation();
    // console.log(location);
    const {
        projects,
        positions,
        dates,
        projectDataState,
        positionDataState,
        selectedData,
        selectedCols,
        selectedRows,
        forecastFilters,
        selectedManpowerForecasts,
    } = location.state;

    const [ loading, setLoading ] = React.useState(false);
    const [ projectData, setProjectData ] = React.useState(projectDataState);
    const [ selectedProject, setSelectedProject ] = React.useState(projectData?.length > 0 ? projectData[0].forecastProject : null);
    const [ positionData, setPositionData ] = React.useState(positionDataState);
    const [ selectedPositionData, setSelectedPositionData ] = useState(null);
    const [ showValidationAlert, setShowValidationAlert ] = useState(false);
    const [ validationMessage, setValidationMessage ] = useState(false);
    const [ warningMessage, setWarningMessage ] = useState(false);
    const [ entities, setEntities ] = React.useState([]);
    const [ tmpDispatchUnitName, setTmpDispatchUnitName ] = useState(null);

    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);
    const [errorMessageOpening, setErrorMessageOpening] = React.useState(`Error occured when processing the request: `);

    const history = useHistory();

    const loadData = useCallback(() => {
        if (projectDataState && positionDataState)
            return;

        setLoading(true);
        // console.log('projects', projects)
        let projCalls = projects.map(item => {
            const queryString = qs.stringify({ project: item.project, param: {...item}}, {allowDots: true});
            //console.log(queryString);
            return api.get(`forecast/check/project?${queryString}`);
        })
        Promise.all(projCalls).then((data) => {
            // console.log('data', data);
            setProjectData(data.map(item => ({
                param: item.data.param,
                forecastProject: decodeURIComponent(item.data.project),
                project: item.data.selected,
                dispatchUnitName: null,
                scopeRef: item.data.param.scopeRef,
                location: null,
                startDate: dates[0],
                endDate: dates[1],
            })));
            setSelectedProject(decodeURIComponent(data[0].data.project));
            if (loading)
                setLoading(true);
        })
        .catch((error) => {
            setErrorMessage(`${errorMessageOpening}${error.message}`);
            console.error("error on promises", error.message);
            setLoading(false);
        })
        .finally(() => {

        });

        // setProjectData(projects.map(item => {
        //     return {
        //         forecastProject: item,
        //         project: null,
        //         dispatchUnit: null,
        //         location: null,
        //         startDate: dates[0],
        //         endDate: dates[1],
        //     }
        // }));

        //console.log('positions', positions)

        if (!positionData || positionData.length === 0)
        {
            if (positions && positions.length > 0) {
                let posCalls = positions.map(item => {
                    // console.log('pos', {
                    //     item,
                    //     epos: encodeURIComponent(item.position),
                    //     eproj: encodeURIComponent(item.project)
                    // })
                    const queryString = qs.stringify({  position: item.position, project: item.project, slot: item.slot, refNo: item.refNo }, { allowDots: true })
                    

                    //return api.get(`forecast/check/position/${encodeURIComponent(item.position)}/${encodeURIComponent(item.project)}/${item.slot}/${item.refNo}`)
                    return api.get(`forecast/check/position?${queryString}`)
                })

                Promise.all(posCalls).then((data) => {
                    
                    //console.log('position 1', data)
                    setPositionData(data.map(item => ({
                        project: decodeURIComponent(item.data.project),
                        forecastPosition: decodeURIComponent(item.data.position),
                        hrispositionMaster: item.data.selected,
                        slot: item.data.slot,
                        refNo: item.data.refNo,
                    })))

                    if (loading)
                        setLoading(true);
                })
                .catch((error) => {
                    setErrorMessage(`${errorMessageOpening}${error.message}`);
                    console.error("error on promises", error.message);
                    setLoading(false);
                })
                .finally(() => {
                })
                
                return;
            } else {
                setPositionData(null);
                setLoading(false);
            }
        }
    }, [projectDataState, positionDataState, positionData, positions]);

    useEffect(() => loadData(), []);
    
    useEffect(() => {
        if (!selectedProject)
        {
            setSelectedPositionData(null);
            return;
        }
        // console.log('positionData', positionData)

        setLoading(true);
        setSelectedPositionData(
            positionData && positionData.filter(item => item.project === selectedProject).length > 0 ?
            positionData.filter(item => item.project === selectedProject)
            :
            null
        );
        setLoading(false);
    }, [selectedProject, positionData]);

    const handleProjectRowChanged = useCallback((item, name, value) => {
        console.log(value);
        const dataStr = JSON.stringify(projectData);
        const finalData = JSON.parse(dataStr);

        const rowData = finalData.filter(x => x.forecastProject === item.forecastProject)[0];
        const arrIdx = finalData.indexOf(rowData);
        finalData.splice(finalData.indexOf(rowData), 1);
        finalData.splice(arrIdx, 0, {
            ...rowData,
            [name]: value
        });
        setProjectData(finalData);
    }, [projectData]);

    const checkDispatchUnit = useMemo(
        () => _.debounce((du, project) => {
            if (project && du) {
                
                const queryString = qs.stringify({  project, du }, { allowDots: true })
                api.get(`rosterwizard/check/dispatchunit?${queryString}`)
                .then((response) => {
                    if (!!response.data) 
                    {
                        setWarningMessage({title: 'Dispatch Unit', msg: `${response.data.dispatchUnitName} already exists. Continuing will merge this roster into Dispatch Unit '${response.data.dispatchUnitName}'`});
                    }
                })
            }
        }, 600)
    , [])

    useEffect(() => {
        setLoading(true);
        api.get(`/lookup/company`)
        .then(({data}) => {
            
            setLoading(false);
            setEntities(data);
        })
        .catch((error) => {
            setErrorMessage(`${errorMessageOpening}${error.message}`);
            console.error("error on promises", error.message);
            setLoading(false);
        });
    }, []);

    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            onConfirm: () => setErrorMessage(null),
            message: errorMessage
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage]);


    const projectGrid = useMemo(() => {
        return (
            <>
                <Row className="border-bottom font-weight-bold font-size-small-custom mb-2">
                    <Col xs={1}>
                        <span>Forecast Site / Project</span>
                    </Col>
                    <Col xs={2}>
                        <span>Definitiv Target Project</span>
                    </Col>
                    <Col xs={2}>
                        <span>Dispatch Unit</span>
                    </Col>

                    <Col xs={2}>
                        <span>Definitiv Location</span>
                    </Col>
                    <Col xs={2}>
                        <span>Desired Entity</span>
                    </Col>
                    <Col xs={3}>
                        <Row>
                            <Col xs={2}>
                                <span>Scope Ref.</span>
                            </Col>
                            <Col xs={2}>
                                <span>Shift</span>
                            </Col>
                            <Col xs={2}>
                                <span>State</span>
                            </Col>
                            <Col xs={2}>
                                <span>Crew</span>
                            </Col>
                            <Col xs={2}>
                                <span>Roster Start Date</span>
                            </Col>
                            <Col xs={2}>
                                <span>Roster End Date</span>
                            </Col>
                        </Row>
                    </Col> 
                    
                </Row>
            {
                projectData && projectData.length > 0 &&
                projectData.map((item, index) => {
                    return (
                        <Row
                            className={`cursor-pointer py-2 font-size-small-custom${selectedProject === item.forecastProject ? " bg-secondary" : ""}`}
                            key={index}
                            onClick={() => setSelectedProject(item.forecastProject)}
                        >
                            <Col xs={1} className="d-flex flex-wrap align-items-center">
                                <span>{item.forecastProject}</span>
                            </Col>
                            <Col xs={2}>
                                <ComboBox 
                                    endpoint="/project/search"
                                    isLookup={false}
                                    idField="projectId"
                                    valueField="projectName"
                                    selectedItem={item.project}
                                    minLength={0}
                                    onChange={(project) => {
                                        handleProjectRowChanged(item, "project", project);
                                        checkDispatchUnit(item.dispatchUnitName, project?.projectId);
                                    }}
                                />
                            </Col>
                            <Col xs={2}>
                                <ComboBox
                                    minLength={0}
                                    endpoint={`/dispatchunit/search?projectid=${item.project?.projectId ?? 0}`}
                                    isLookup={false}
                                    idField="dispatchUnitId"
                                    valueField="dispatchUnitName"
                                    selectedItem={item.project}
                                    disabled={!item.project}
                                    onChange={(du) => {
                                        // console.log("onchange: ", {du, tmpDispatchUnitName});
                                        handleProjectRowChanged(item, "dispatchUnitName", du?.dispatchUnitName ?? tmpDispatchUnitName);
                                    }}
                                    onInputValueChange={(value) => {
                                        if (!value)
                                            return;

                                        // console.log(value);
                                        setTmpDispatchUnitName(value);
                                        checkDispatchUnit(value, item.project?.projectId);
                                    }}
                                />
                                {/* <Input
                                    name={`dispatchUnitName${index}`}
                                    id={`dispatchUnitName${index}`}
                                    type="text"
                                    required
                                    value={item.dispatchUnitName ?? ""}
                                    onChange={(e) => {
                                        const du = e.target.value;
                                        handleProjectRowChanged(item, "dispatchUnitName", du);
                                        checkDispatchUnit(du, item.project?.projectId);
                                    }}
                                    autoComplete="off"
                                /> */}
                            </Col>
                            <Col xs={2}>
                                <ComboBox 
                                    minLength={0}
                                    endpoint="/location"
                                    selectedItem={item.location}
                                    onChange={(location) => {
                                        handleProjectRowChanged(item, "location", location);
                                    }}
                                />
                            </Col>
                            <Col xs={2}>
                                <DropdownBox 
                                    data={entities}
                                    selectedItem={item.entity}
                                    onChange={(entity) => {                                        
                                        handleProjectRowChanged(item, "entity", entity);                                        
                                    }}
                                />
                            </Col>
                            <Col xs={3}>
                                <Row>
                                    <Col xs={2} className="d-flex flex-wrap align-items-center">
                                        <span style={{fontSize: "0.75rem"}}>{item.param?.scopeRef}</span>
                                    </Col>
                                    <Col xs={2} className="d-flex flex-wrap align-items-center">
                                        <span style={{fontSize: "0.75rem"}}>{item.param?.shift}</span>
                                    </Col>
                                    <Col xs={2} className="d-flex flex-wrap align-items-center">
                                        <span style={{fontSize: "0.75rem"}}>{item.param?.state}</span>
                                    </Col>
                                    <Col xs={2} className="d-flex flex-wrap align-items-center">
                                        <span style={{fontSize: "0.75rem"}}>{item.param?.crew}</span>
                                    </Col>
                                    <Col xs={2} className="d-flex flex-wrap align-items-center">
                                        <span style={{fontSize: "0.75rem"}}>{moment(item.startDate, 'DD/MM/YYYY').format('DD.MM.YY')}</span>
                                    </Col>
                                    <Col xs={2} className="d-flex flex-wrap align-items-center">
                                        <span style={{fontSize: "0.75rem"}}>{moment(item.endDate, 'DD/MM/YYYY').format('DD.MM.YY')}</span>
                                    </Col>
                                </Row>
                            </Col>
                            
                        </Row>
                    );
                })
            }
            </>
        );
    }, [projectData, selectedProject, entities, tmpDispatchUnitName, checkDispatchUnit, handleProjectRowChanged]);

    const handlePositionRowChanged = useCallback((item, name, value) => {
        const dataStr = JSON.stringify(positionData);
        const finalData = JSON.parse(dataStr);

        //const rowData = finalData.filter(x => x.forecastPosition === item.forecastPosition && x.project === item.project && x.refNo === item.refNo)[0];
        const rowData = finalData.filter(x => x.forecastPosition === item.forecastPosition && x.project === item.project);
        for (let i = 0; i < rowData.length; i++) {
            const arrIdx = finalData.indexOf(rowData[i]);
            finalData.splice(arrIdx, 1);
            finalData.splice(arrIdx, 0, {
                ...rowData[i],
                [name]: value,
            });
        }
        // const arrIdx = finalData.indexOf(rowData);
        // finalData.splice(arrIdx, 1);
        // finalData.splice(arrIdx, 0, {
        //     ...rowData,
        //     [name]: value,
        // });
        //console.log('finalData', {finalData, rowData, item})
        setPositionData(finalData);
    }, [positionData]);

    const positionGrid = useMemo(() => {
        const groupedData = _.groupBy(selectedPositionData, 'forecastPosition');

        // console.log('groupBy', {selectedPositionData, groupedData})
        return (
            selectedPositionData && selectedPositionData.length > 0 &&
            <>
                <Row className="border-bottom font-weight-bold font-size-small-custom mb-2">
                    {/* <Col xs={2}>
                        <span>Manpower Forecast Ref No</span>
                    </Col> */}
                    <Col xs={5}>
                        <span>Forecast Position Title</span>
                    </Col>
                    <Col xs={5}>
                        <span>Roster Position Title</span>
                    </Col>
                    <Col xs={2}>
                        <span>Number of Roster Slots</span>
                    </Col>
                </Row>
                {
                    Object.keys(groupedData).map((item, index) => {
                        const items = groupedData[item];
                        const slots = _.sumBy(items, 'slot');
                        const first = items[0]
                        console.log('item', { item, items, slots });
                        return (
                            <Row className="font-size-small-custom py-1" key={index}>
                                <Col xs={5} className="d-flex flex-wrap align-items-center">
                                    <span>{item}</span>
                                </Col>
                                <Col xs={5}>
                                    <ComboBox 
                                        endpoint="/hrispositionmaster/search-by-string"
                                        isLookup={false}
                                        idField="hrispositionMasterId"
                                        valueField="hrispositionMasterName"
                                        selectedItem={first?.hrispositionMaster}
                                        minLength={0}
                                        onChange={(position) => {
                                            handlePositionRowChanged(first, "hrispositionMaster", position);
                                        }}
                                    />
                                </Col>
                                <Col xs={2} className="d-flex flex-wrap align-items-center">
                                    <span>{slots}</span>
                                </Col>
                            </Row>
                        );
                    })
                }
            {/* {
                selectedPositionData.map((item, index) => {
                    return (
                        <Row className="font-size-small-custom py-1" key={index}>
                            <Col xs={2} className="d-flex flex-wrap align-items-center">
                                <span>{item.refNo}</span>
                            </Col>
                            <Col xs={4} className="d-flex flex-wrap align-items-center">
                                <span>{item.forecastPosition}</span>
                            </Col>
                            <Col xs={4}>
                                <ComboBox 
                                    endpoint="/position/search"
                                    isLookup={false}
                                    idField="positionId"
                                    valueField="positionName"
                                    selectedItem={item.position}
                                    onChange={(position) => {
                                        handlePositionRowChanged(item, "position", position);
                                    }}
                                />
                            </Col>
                            <Col xs={2} className="d-flex flex-wrap align-items-center">
                                <span>{item.slot}</span>
                            </Col>
                        </Row>
                    );
                })
            } */}
            </>
        );
    }, [positionData, selectedPositionData]);

    const handleNextPressed = useCallback(() => {
        if (projectData.filter(item =>
                item.project === null
                || item.dispatchUnitName === null || item.dispatchUnitName === ""
                || item.location === null
                //|| item.workorder === null
            ).length > 0
        )
        {
            const invalidProject = projectData.filter(item =>
                    item.project === null
                    || item.dispatchUnitName === null || item.dispatchUnitName === ""
                    || item.location === null
                    //|| item.workorder === null
                )[0];
            setValidationMessage(`Definitiv Target Project, Dispatch Unit, and Definitiv Location for Forecast Site / Project ${invalidProject.forecastProject} cannot be empty.`);
            setShowValidationAlert(true);
            return;
        }
        
        // console.log({positionData});
        
        if (positionData.filter(item =>
                item.hrispositionMaster === null
                || item.slot === null || item.slot === 0
            ).length > 0
        )
        {
            const invalidPosition = positionData.filter(item =>
                    item.hrispositionMaster === null
                    || item.slot === null || item.slot === 0
                )[0];
            setValidationMessage(`Roster Position Title and Number of Roster Slots for Forecast Position Title ${invalidPosition.forecastPosition} in Forecast Site / Project ${invalidPosition.project} cannot be empty.`);
            setShowValidationAlert(true);
            return;
        }
        
        history.push({
            pathname: "/rosterwizardfinal",
            state: {
                projects: projects,
                positions: positions,
                dates: dates,
                projectData: projectData,
                positionData: positionData,
                selectedData,
                selectedCols,
                selectedRows,
                forecastFilters,
                selectedManpowerForecasts
            }
        })
    }, [projects, positions, dates, projectData, positionData]);

    return (
        <section className="main">
            {(loading) && <Loader />}
            {errorNotification.length > 0 && errorNotification}
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card className="no-transition" style={{height: 'calc(100vh - 178px)'}}>
                            <CardBody style={{overflowX: 'scroll'}}>
                                <CardTitle>
                                    <h4 className="text-center">Roster Wizard - Create Roster Template Mapping</h4>
                                </CardTitle>
                                <Container fluid>
                                    <Row className="mb-3">
                                        <Col xs={12} className="d-flex flex-wrap justify-content-end">
                                            <ButtonGroup>
                                                <Button
                                                    color="secondary"
                                                    onClick={() => history.push({ 
                                                        pathname: "/forecasting",
                                                        state: forecastFilters
                                                    })}
                                                >
                                                    Back
                                                </Button>
                                                <Button
                                                    color="secondary"
                                                    onClick={() => {
                                                        handleNextPressed();
                                                    }}
                                                    disabled={!projectData || projectData.length == 0}
                                                >
                                                    Next
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                {
                                    projectGrid
                                }
                                    <Row className="mb-5"><Col xs={12}></Col></Row>
                                {
                                    positionGrid
                                }
                                </Container>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
            {
                showValidationAlert &&
                <SweetAlert
                    title="Invalid Data"
                    warning
                    onConfirm={() => setShowValidationAlert(false)}
                >
                {
                    validationMessage
                }
                </SweetAlert>
            }
             {
                warningMessage &&
                <SweetAlert
                    title={warningMessage.title}
                    warning
                    onConfirm={() => setWarningMessage(false)}
                >
                {
                    warningMessage.msg
                }
                </SweetAlert>
            }
            </Container>
        </section>
    );
}

export default Wizard;