import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { ComboBox as ComboBox2 } from "../../../dropdowns";
import { DatePicker as DatePicker2 } from "../../../date-pickers";
import { IndeterminateCheckbox } from '../../../react-table/controls';
import { formatDate } from "../../../../utils";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import ContextMenuHelper from "../helpers/ContextMenuHelper";
import qs from "qs";
import debounce from 'lodash.debounce';
import useRoster from "../contexts/RosterContext";
import { initialState } from "../reducers/rosterReducer";
import moment from "moment";
import { filterBy } from "@progress/kendo-data-query";
import api from "../../../../services/api";
import { ViewAccess } from '../../../../utils'
import SweetAlert from 'react-bootstrap-sweetalert';
import { ScheduleTypeCodes } from '../helpers/enums';

export default (props) => { 
    
    const {
        api: gridApi,
        columnApi,
        today,
        loadSchedule,
        autoSelectResources,
        setLoading,
        projectid,
        dispatchunitid,
        viewAccess,
        startDateDefault,
        endDateDefault,
        disableTrimStartDateDefault,
        disableTrimEndDateDefault,
        setShowTalentsSearch,
        setShowSpvRatingRequest,
        setShowSpvFeedbackReport,
    } = props;

    const { 
        setDispatchUnit, 
        dispatchUnit,
        project,
        setProject,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        trimStartDate,
        setTrimStartDate,
        trimEndDate,
        setTrimEndDate,
        showArchivedDispatchUnits,
        setShowArchivedDispatchUnits,
        dataLoaded,
        dataExists,
        setDispatchEditorOpen,
        setContingencyOpen,
    } = useRoster();


    //const [ selectedProject, setSelectedProject ] = useState(null);
    const [ projects, setProjects ] = useState([]);
    const [ projectsLoading, setProjectsLoading ] = useState(false);

    //const [ dispatchUnit, setDispatchUnit ] = useState(null);
    const [ dispatchUnits, setDispatchUnits ] = useState([]);
    const [ allDispatchUnits, setAllDispatchUnits ] = useState([]);
    const [ dispatchUnitsLoading, setDispatchUnitsLoading ] = useState(false);
    const [ validationAlert, setValidationAlert ] = useState(null);

    // const [ showArchivedDispatchUnits, setShowArchivedDispatchUnits ] = useState(false);
    // const [ startDate, setStartDate ] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
    // const [ endDate, setEndDate ] = useState(new Date(today.getFullYear(), today.getMonth() + 6, 1));
    // const [ trimStartDate, setTrimStartDate ] = useState(true);
    // const [ trimEndDate, setTrimEndDate ] = useState(true);

    useEffect(() => {
        // console.log('startDateDefault', startDateDefault);
        // console.log('endDateDefault', endDateDefault);

        if (projectid && dispatchunitid) {
            gridApi.showLoadingOverlay();
            const apiCalls = [
                api.get(`/project/${projectid}`),
                api.get(`/dispatchunit/id/${dispatchunitid}`)
            ]
            Promise.all(apiCalls)
            .then((response) => {
                // console.log('response', response);
                const qsProject = { ...response[0].data };
                const qsDispatch = {  
                    locationName: response[1].data.location.lookupValue,
                    lookupId: response[1].data.dispatchUnitId,
                    lookupLabel: response[1].data.quoteNumber,
                    lookupValue: response[1].data.dispatchUnitName,
                }

                // Date states
                var startDateState = startDateDefault ? startDateDefault : initialState.startDate;
                var endDateState = endDateDefault ? endDateDefault : initialState.endDate;
                var trimStartDateState = disableTrimStartDateDefault !== null ? false : initialState.trimStartDate;
                var trimEndDateState = disableTrimEndDateDefault !== null ? false : initialState.trimEndDate;

                // Load schedule var parameters
                var startDateStateLoad = trimStartDateState ? null : formatDate(startDateState, 'YYYY-MM-DD');
                var endDateStateLoad = trimEndDateState ? null : formatDate(endDateState, 'YYYY-MM-DD');
                //var startDateStateLoad = trimStartDateState ? true : false;
                //var endDateStateLoad = trimEndDateState ? true : false;

                // console.log('startDateState', startDateState);
                // console.log('endDateState', endDateState);
                // console.log('trimStartDateState', trimStartDateState);
                // console.log('trimEndDateState', trimEndDateState);

                // console.log('startDateStateLoad', startDateStateLoad);
                // console.log('endDateStateLoad', endDateStateLoad);


                //console.log('initialState.startDate', initialState.startDate);
                //console.log('initialState.endDate', initialState.endDate);

                // console.log('qs', {qsProject, qsDispatch});
                localStorage.setItem(process.env.REACT_APP_HRIS_ROSTER_GRID_SETUP_STATE, JSON.stringify({
                    project: qsProject,
                    dispatchUnit: qsDispatch,
                    trimStartDate: trimStartDateState,
                    trimEndDate: trimEndDateState,
                    startDate: formatDate(startDateState, 'YYYY-MM-DD'),
                    endDate: formatDate(endDateState, 'YYYY-MM-DD'),
                    showArchivedDispatchUnits: initialState.showArchivedDispatchUnits
                }));
                setProject(qsProject);            
                setDispatchUnit(qsDispatch);
                setShowArchivedDispatchUnits(initialState.showArchivedDispatchUnits);

                setStartDate(startDateState);
                setEndDate(endDateState);
                setTrimStartDate(trimStartDateState);
                setTrimEndDate(trimEndDateState);

                // console.log('loadSchedule', 'case 1');
                loadSchedule({ 
                    projectId: qsProject.projectId,
                    dispatchUnitId: qsDispatch.lookupId,
                    startDate: startDateStateLoad,
                    endDate: endDateStateLoad
                })
            })
            .catch((error) => {
                console.error(error.response);
            });
        } else if (process.env.REACT_APP_HRIS_ROSTER_GRID_SETUP_STATE in localStorage) {
            const savedState = JSON.parse(localStorage.getItem(process.env.REACT_APP_HRIS_ROSTER_GRID_SETUP_STATE));
            // console.log('savedState', savedState);


            // Date states
            var startDateState = startDateDefault ? startDateDefault : new Date(savedState.startDate);
            var endDateState = endDateDefault ? endDateDefault : new Date(savedState.endDate);
            var trimStartDateState = savedState.trimStartDate;
            var trimEndDateState = savedState.trimEndDate;

            // Load schedule var parameters
            var startDateStateLoad = trimStartDateState ? null : formatDate(startDateState, 'YYYY-MM-DD');
            var endDateStateLoad = trimEndDateState ? null : formatDate(endDateState, 'YYYY-MM-DD');

            // console.log('startDateState', startDateState);
            // console.log('endDateState', endDateState);
            // console.log('trimStartDateState', trimStartDateState);
            // console.log('trimEndDateState', trimEndDateState);

            // console.log('startDateStateLoad', startDateStateLoad);
            // console.log('endDateStateLoad', endDateStateLoad);

            setProject(savedState.project);            
            setDispatchUnit(savedState.dispatchUnit);
            setShowArchivedDispatchUnits(savedState.showArchivedDispatchUnits);

            setStartDate(startDateState);
            setEndDate(endDateState);
            setTrimStartDate(trimStartDateState);
            setTrimEndDate(trimEndDateState);

            // console.log('loadSchedule', 'case 2');
            loadSchedule({ 
                projectId: savedState.project.projectId,
                dispatchUnitId: savedState.dispatchUnit.lookupId,
                startDate: startDateStateLoad,
                endDate: endDateStateLoad
            })
        }
    }, []);

    const requestProjectData = useCallback(
        debounce((filter) => {
            
            const queryString = qs.stringify({ filter }, { allowDots: true });

            api.get(`/project/search${queryString ? `?${queryString}` : ""}`)
            .then((response) => {
                if (!response.data)
                {
                    setProjects([]);
                } 
                else 
                {
                    setProjects(response.data);
                }

            })
            .catch((error) => {
                console.log(error.response)
            })
            .finally(() => {
                setProjectsLoading(false);
            });
        }, 300)
    , []);

    const onProjectFilterChange = useCallback((event) => {
        setProjectsLoading(true);
        requestProjectData(event.filter?.value);
        setProjects([]);
    }, []);

    const onProjectChange = useCallback((event) => {
        setProject(event.value);
    }, []);

    useEffect(() => {
        if (project) {
            setDispatchUnitsLoading(true);

            api.get(`/lookup/dispatchunit/${project?.projectId}/${showArchivedDispatchUnits}`)
                .then((response) => {
                    if (!response.data)
                    {
                        setDispatchUnits([]);
                        setAllDispatchUnits([]);
                    } 
                    else 
                    {
                        setDispatchUnits(response.data);
                        setAllDispatchUnits(response.data);
                    }
    
                })
                .catch((error) => console.log(error.response))
                .finally(() => {
                    setDispatchUnitsLoading(false);
                });
        }
        else {
            setDispatchUnits([]);
            setAllDispatchUnits([]);
        }
        
    }, [project, showArchivedDispatchUnits, setDispatchEditorOpen]);

    const onDispatchUnitChange = useCallback((event) => {
        setDispatchUnit(event.value);
    }, []);
    
    const handleFilterClick = useCallback(() => {
        localStorage.setItem(process.env.REACT_APP_HRIS_ROSTER_GRID_SETUP_STATE, JSON.stringify({
            project,
            dispatchUnit,
            trimStartDate,
            trimEndDate,
            startDate: formatDate(startDate, 'YYYY-MM-DD'),
            endDate: formatDate(endDate, 'YYYY-MM-DD'),
            showArchivedDispatchUnits
        }));
        loadSchedule({ 
            projectId: project.projectId,
            dispatchUnitId: dispatchUnit.lookupId,
            startDate: trimStartDate ? null: formatDate(startDate, 'YYYY-MM-DD'),
            endDate: trimEndDate ? null : formatDate(endDate, 'YYYY-MM-DD')
        })
    }, [project, dispatchUnit, trimStartDate, trimEndDate, startDate, endDate, showArchivedDispatchUnits]);

    const onDispatchUnitFilterChange = useCallback((event) => {
        const copy = [...allDispatchUnits];
        const data = filterBy(copy, event.filter);
        setDispatchUnits(data);
    }, [allDispatchUnits]);



    return (
        <Container fluid className='d-flex flex-column justify-content-between h-100'>
            <Row>
                <Col xs={12} className="pt-3">
                    <h5>Roster Filter</h5>
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <Label>
                            Project
                            <span className="text-danger"> *</span>
                        </Label>
                        <ComboBox
                            data={projects}
                            value={project}
                            onChange={onProjectChange}
                            dataItemKey="projectId"
                            textField="projectName"
                            filterable={true}
                            onFilterChange={onProjectFilterChange}
                            onFocus={onProjectFilterChange}
                            placeholder=""
                            loading={projectsLoading}
                            rounded="small"
                            size="small"
                            className='w-100'
                        />
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <div className="w-100 d-flex justify-content-between">
                            <Label>
                                Dispatch Unit
                                <span className="text-danger"> *</span>
                            </Label>
                            <IndeterminateCheckbox
                                id="showArchived"
                                type="checkbox"
                                onChange={() => {
                                    setShowArchivedDispatchUnits(!showArchivedDispatchUnits);
                                }}
                                checked={showArchivedDispatchUnits}
                                customTitle="Show Archived"
                            />
                        </div>
                        <ComboBox
                            data={dispatchUnits}
                            value={dispatchUnit}
                            onChange={onDispatchUnitChange}
                            dataItemKey="lookupId"
                            textField="lookupValue"
                            filterable={true}
                            placeholder=""
                            loading={dispatchUnitsLoading}
                            rounded="small"
                            size="small"
                            className='w-100'
                            onFilterChange={onDispatchUnitFilterChange}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup className={trimStartDate ? 'disabled' : ''}>
                        <div className="w-100 d-flex justify-content-between">
                            <Label>
                                Start Date
                                <span className="text-danger"> *</span>
                            </Label>
                            <IndeterminateCheckbox
                                id="trimStartDate"
                                type="checkbox"
                                onChange={() => {
                                    setTrimStartDate(!trimStartDate);
                                }}
                                disabled={disableTrimStartDateDefault}
                                checked={trimStartDate}
                                customTitle="Trim"
                            />
                        </div>
                        <DatePicker
                            name="startDate"
                            id="startDate"
                            required
                            value={startDate}
                            onChange={({value}) => {
                                setStartDate(value);
                            }}
                            disabled={trimStartDate}
                            format={"dd/MM/yyyy"}
                            className='w-100'
                        />
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup className={trimEndDate ? 'disabled' : ''}>
                        <div className="w-100 d-flex justify-content-between">
                            <Label>
                                End Date
                                <span className="text-danger"> *</span>
                            </Label>
                            <IndeterminateCheckbox
                                id="trimEndDate"
                                type="checkbox"
                                onChange={() => {
                                    setTrimEndDate(!trimEndDate);
                                }}
                                disabled={disableTrimEndDateDefault}
                                checked={trimEndDate}
                                customTitle="Trim"
                            />
                        </div>
                        <DatePicker
                            name="endDate"
                            id="endDate"
                            required
                            value={endDate}
                            onChange={({value}) => {
                                setEndDate(value);
                            }}
                            disabled={trimEndDate}
                            format={"dd/MM/yyyy"}
                            className='w-100'
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <Button
                        color="secondary"
                        onClick={handleFilterClick}
                        type="button"
                        className="w-100"
                    >
                        Filter
                    </Button>
                </Col>
            </Row>
        {
            viewAccess == ViewAccess.FULLACCESS &&
            <Row className="mb-3 pb-3">
                <Col xs={12} className="pt-3">
                    <h5>Actions</h5>
                </Col>
                <Col xs={12} className="">
                    <Button
                        color="secondary btn-grid-panel"
                        onClick={() => {
                            setLoading(true)
                            api.get(`/rostergrid/statuses/${dispatchUnit?.lookupId}`)
                                .then((response) => {
                                    // console.log('response', response)
                                    if (response.data && response.data.length > 0) {
                                        const update = [];
                                        response.data.forEach(status => {
                                            const { data } = gridApi.getRowNode(status.rosterCandidateId);
                                            // if (status.availabilityStatusId !== data.availabilityStatusId)
                                            // {
                                            update.push({ ...data, ...status });
                                            // }
                                        });
                                        // console.log('update', update)
                                        gridApi.applyTransaction({ update });
                                    }

                                })
                                .catch((error) => console.log(error.response))
                                .finally(() => {
                                    setLoading(false);
                                });
                        }}
                        type="button"
                        className="w-100 text-left"
                        disabled={!dataLoaded || !dataExists}
                    >
                        Check Statuses
                    </Button>
                </Col>
                <Col xs={12}>
                    <Button
                        color="secondary btn-grid-panel"
                        onClick={() => {
                            setLoading(true)
                            // const candidateId = [];
                            // gridApi.forEachNode(rowNode => {
                            //     if (rowNode.data.candidateId) {
                            //         candidateId.push(rowNode.data.candidateId)
                            //     }
                            // });
                            // const payload = {
                            //     startDate,
                            //     endDate,
                            //     candidateId
                            // };
                            api.get(`/rostergrid/fatigue/${dispatchUnit?.lookupId}`)
                                .then((response) => {
                                    // console.log('response', response)
                                    if (response.data && response.data.length > 0) {
                                        const update = [];

                                        response.data.forEach((item) => {
                                            const rowNode = gridApi.getRowNode(item.rosterCandidateId);
                                            if (rowNode) {
                                                update.push({ ...rowNode.data, fatigueFlag: item.fatigueFlag, fatigueDetails: item.fatigueDetails });
                                            }
                                        })

                                    // gridApi.forEachNode((rowNode, index) => {
                                    //     const data = response.data.find(f => f.candidateId === rowNode.data.candidateId);
                                    //     if (!data) {
                                    //         return;
                                    //     } 
                                    //     update.push({ ...rowNode.data, fatigueStatus: data.fatigueStatus });
                                    // })
                                    // console.log('update', update)
                                    gridApi.applyTransaction({update});
                                }
                
                            })
                            .catch((error) => console.log(error.response))
                            .finally(() => {
                                setLoading(false);
                            });
                        }}
                        type="button"
                        className="w-100 text-left"
                        disabled={!dataLoaded || !dataExists}
                    >
                        Check Fatigue
                    </Button>
                </Col>
                <Col xs={12}>
                    <Button
                        color="secondary btn-grid-panel"
                        onClick={() => {
                            autoSelectResources(gridApi);
                        }}
                        type="button"
                        className="w-100 text-left"
                        disabled={!dataLoaded || !dataExists}
                    >
                        Auto-Select Resources
                    </Button>
                </Col>
                <Col xs={12}>
                    <Button
                        color="secondary btn-grid-panel"
                        onClick={() => {
                            setContingencyOpen(true)
                        }}
                        type="button"
                        className="w-100 text-left"
                        disabled={!dataLoaded || !dataExists}
                    >
                        Contingency 
                    </Button>
                </Col>
                <Col xs={12}>
                    <Button
                        color="secondary btn-grid-panel"
                        onClick={() => {
                            setDispatchEditorOpen(dispatchUnit.lookupId)
                        }}
                        type="button"
                        className="w-100 text-left"
                        disabled={!dataLoaded}
                    >
                        Edit Dispatch
                    </Button>
                </Col>
                <Col xs={12}>
                    <Button
                        color="secondary btn-grid-panel"
                        onClick={() => {
                            let queryString = null;
                            const ranges = gridApi.getCellRanges();
                            if (ranges && ranges.length) {
                                //console.log('ranges', ranges)
                                const filteredRanges = ranges.filter(m => m.columns.some(s => s.colId === "candidateName"));
                                if (filteredRanges && filteredRanges.length) {
                                    const candidateIds = ContextMenuHelper
                                        .getdRowIndexes(filteredRanges)
                                        .map(i => gridApi.getDisplayedRowAtIndex(i).data.candidateId)
                                        .filter(f => f)
                                        .join("-");
                                    queryString = candidateIds;
                                    //console.log('queryString', { queryString, ranges, filteredRanges, candidateIds })
                                }
                            }
                            window.open(`/tnadiscrepancy-roster?dispatchUnitId=${dispatchUnit.lookupId}${queryString ? `&candidateIds=${encodeURI(queryString)}` : ""}`, '_blank');
                        }}
                        type="button"
                        className="w-100 text-left"
                        disabled={!dataLoaded || !dataExists}
                    >
                        TNA Discrepancy Report
                    </Button>
                </Col>
                <Col xs={12}>
                    <Button
                        color="secondary btn-grid-panel"
                        onClick={() => {
                             console.log("Generate LRF");
                            setLoading(true);
                            
                            const ids = [];
                            const slots = [];
                            const gridModel = gridApi.getModel();

                            console.log('gridModel', gridModel);
                            gridModel.forEachNode((rowNode, index) => {
                                console.log('rowNode', rowNode);
                                console.log('index', index);

                                if (!rowNode.data.candidateId && !rowNode.data.recruitmentRequestId) {
                                    const dateKeys = Object.keys(rowNode.data.dates);

                                    const dateLimits = {};
                        
                                    dateKeys.forEach(dt => {
                                        if (rowNode.data.dates[dt].value.includes(ScheduleTypeCodes.Start) && (!dateLimits.startDateKey || dateLimits.startDateKey > dt))
                                            dateLimits.startDateKey = dt;
                        
                                        if (rowNode.data.dates[dt].value.includes(ScheduleTypeCodes.End) && (!dateLimits.endDateKey || dateLimits.endDateKey < dt))
                                            dateLimits.endDateKey = dt;
                                    });

                                    slots.push({
                                        projectId: rowNode.data.projectId,
                                        rosterId: rowNode.data.rosterId,
                                        positionId: rowNode.data.positionId,
                                        hrispositionMasterId: rowNode.data.hrispositionMasterId,
                                        hrispositionId: rowNode.data.hrispositionId,
                                        locationId: rowNode.data.locationId,
                                        companyId: rowNode.data.rosterCandidateCompanyId,
                                        slot: rowNode.data.slot,
                                        startDate: dateKeys.length ? moment(dateKeys[0], "YYYYMMDD").format("YYYY-MM-DD") : null,
                                        endDate: dateKeys.length ? moment(dateKeys[dateKeys.length - 1], "YYYYMMDD").format("YYYY-MM-DD") : null,
                                        startDateKey: dateLimits.startDateKey ?? null,
                                        endDateKey: dateLimits.endDateKey ?? null,
                                        dates: rowNode.data.dates,
                                    });
                                    ids.push(rowNode.data.rosterCandidateId);
                                }
                            });

                            console.log('slots', slots);
                            if (!slots?.length)
                            {
                                setLoading(false);
                                return;
                            }

                            if (slots.filter(s => !s.startDate || !s.endDate || !s.dates[s.startDateKey]?.value.includes(ScheduleTypeCodes.Start) || !s.dates[s.endDateKey]?.value.includes(ScheduleTypeCodes.End)).length > 0)
                            {
                                const slotNo = slots.filter(s => !s.startDate || !s.endDate || !s.dates[s.startDateKey]?.value.includes(ScheduleTypeCodes.Start) || !s.dates[s.endDateKey]?.value.includes(ScheduleTypeCodes.End))[0].slot;
                                setLoading(false);
                                setValidationAlert({
                                    title: "Generate LRF Error",
                                    message: `Slot No. ${slotNo} has no schedule or Start / End Date to generate LRF. Please fix before generating the LRF.`
                                });
                                return;
                            }

                            // console.log({slots, ids});

                            // if (slots.filter())

                            api.post("/rostergrid/lrf", {
                                rosterCandidateIds: ids,
                                items: slots,
                                dispatchUnitId: dispatchUnit.lookupId,
                                startDate: trimStartDate ? null : formatDate(startDate, 'YYYY-MM-DD'),
                                endDate: trimEndDate ? null : formatDate(endDate, 'YYYY-MM-DD'),
                            })
                                .then((response) => {
                                    console.log({response});
                                    response.data.recruitmentRequestIds.forEach(d => {
                                        console.log({d});
                                        window.open(`/lrf/${d}`, '_blank');
                                    });

                                    const tx = {
                                        update: response.data.rosterCandidates,
                                    };

                                    gridApi.applyTransactionAsync(tx, (result) => {
                                        gridApi.refreshCells({force: true, rowNodes: result.update});
                                    });
                                })
                                .catch((error) => {
                                    console.error('error.response', error.response)
                                    //setValidationAlert({ message: error.response.status === 501 ? error.response.data : "System error." });
                                    setValidationAlert({
                                        title: "Generate LRF Error",
                                        message: error?.response?.status === 501 ? error?.response?.data?.error ?? "Unexpected error." : "System error."
                                    });
                                })
                                .finally(() => setLoading(false))
                            ;
                        }}
                        type="button"
                        className="w-100 text-left"
                        disabled={!dataLoaded || !dataExists}
                    >
                        Generate LRF
                    </Button>
                </Col>
                <Col xs={12}>
                    <Button
                        color="secondary btn-grid-panel"
                        onClick={() => {
                            window.open(`communication?dispatchunit=${encodeURIComponent(dispatchUnit.lookupValue)}`, '_blank');
                        }}
                        type="button"
                        className="w-100 text-left"
                        disabled={!dataLoaded || !dataExists}
                    >
                        Communications
                    </Button>
                </Col>
                <Col xs={12}>
                    <Button
                        color="secondary btn-grid-panel"
                        onClick={() => setShowTalentsSearch({rosterCandidateIds: []})}
                        type="button"
                        className="w-100 text-left"
                        disabled={!dataLoaded || !dataExists}
                    >
                        Talents Search
                    </Button>
                </Col>
                <Col xs={12}>
                    <Button
                        color="secondary btn-grid-panel"
                        onClick={() => setShowSpvRatingRequest(true)}
                        type="button"
                        className="w-100 text-left"
                        disabled={!dataLoaded || !dataExists}s
                    >
                        Supervisor Rating Request
                    </Button>
                </Col>
                <Col xs={12}>
                    <Button
                        color="secondary btn-grid-panel"
                        onClick={() => setShowSpvFeedbackReport(true)}
                        type="button"
                        className="w-100 text-left"
                        disabled={!dataLoaded || !dataExists}
                    >
                        Supervisor Feedback Report
                    </Button>
                </Col>
                <Col xs={12}>
                    <Button
                        color="secondary btn-grid-panel"
                        onClick={() => setShowSpvFeedbackReport(true)}
                        type="button"
                        className="w-100 text-left"
                        disabled={!dataLoaded}
                    >
                        Talent Pool Notification
                    </Button>
                </Col>
            </Row>
        }
        {
            validationAlert ?
            <SweetAlert
                title={validationAlert.title}
                warning={true}
                confirmBtnText="OK"
                confirmBtnBsStyle="danger"
                onConfirm={() => setValidationAlert(null)}
            >
            {
                validationAlert.message
            }
            </SweetAlert>
            :
            null
        }
        </Container>
    )
}