import React, { useEffect, useState } from 'react';
import {
    Label, FormGroup, Row, Col, Collapse, InputGroup, Input
 } from "reactstrap"; 
import { DropdownBox } from "../../../dropdowns";
import { useTable, useFlexLayout } from 'react-table';
import api from "../../../../services/api";
import { useDropzone } from 'react-dropzone';
import cn from "classnames";
import fileDownload from "js-file-download";
import _ from "lodash";
import { TooltipButton } from '../../../inputs';
import TextareaAutosize from "react-autosize-textarea";
import Grid from "../../../Grid";
import { Mode } from '../../../../utils';
import { DatePicker } from '../../../date-pickers';
import { formatDate, formatDateZeroTime } from "../../../../utils";

export default function Events(props) {
    const {
        itemInEdit, 
        setItemInEdit
    } = props;

    const [ drawerMode, setDrawerMode ] = React.useState(Mode.NONE);
    const [ candidateEvent, setCandidateEvent ] = React.useState(null);
    const [ acceptedAttachments, setAcceptedAttachments ] = React.useState([]);
    const [candidateId, setCandidateId] = useState(0);
    const [ data, setData ] = useState([]);

    useEffect(() => {
        if ((itemInEdit?.candidateId ?? 0) === candidateId)
            return;
        
        setCandidateId(itemInEdit?.candidateId ?? 0);
    }, [itemInEdit?.candidateId]);

    useEffect(() => {
        if (!candidateId)
            return;

        setData(itemInEdit?.candidateEvents ?? []);
    }, [candidateId]);
    
    const skipPageResetRef = React.useRef();
    const statusFinished = 'Finished';

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 150, 
            maxWidth: 200,
    }), [])

    const columns = React.useMemo(() => {
        return [
            {
                Header: "Event",
                accessor: "event.lookupValue",
            },
            {
                Header: "Note",
                accessor: "note",
            },
            {
                Header: "File",
                accessor: "candidateEventId",
                Cell: (p) => {
                    // console.log("File Cell: ", p)
                    return <span className="cursor-pointer" onClick={() => downloadAttachment(p.row.original.file || p.row.original)}>{p.row.original.file ? p.row.original.file.name : p.row.original.filename}</span>
                }
            },
            {
                id: 'buttons',
                Header: "Action",
                width: 180,
                canResize: false,
                Cell: ({row:{original, isGrouped}}) => (
                    (original && !isGrouped) ?
                    (
                        <div className="td-action text-right">   
                            <TooltipButton
                                id={`edit_${original.candidateEventId}`}
                                title="Edit"
                                className="btn-icon"
                                color="default"
                                size="xsm"
                                type="button"
                                onClick={() => {
                                    setCandidateEvent({...original})
                                    setDrawerMode(Mode.EDIT);
                                }}
                            >
                                <i className="fas fa-pencil-alt pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`delete_${original.candidateEventId}`}
                                title="Delete"
                                className="btn-icon"
                                color="warning"
                                size="xsm"
                                type="button"
                                onClick={() => {
                                    console.log("Delete: ", { event: original, itemInEdit });
                                    const index = data.findIndex(val => {
                                        if (original.candidateEventId)
                                            return val.candidateEventId === original.candidateEventId;

                                        return val.index === original.index;
                                    });
                                    console.log("Deleted index: ", { index });
                                    const events = [...data];
                                    events.splice(index, 1);
                                    setData([...events]);
                                    setItemInEdit({ ...itemInEdit, candidateEvents: events });
                                }}
                            >
                                <i className="fas fa-trash-alt pt-1"></i>
                            </TooltipButton>
                        </div>
                    ) : (
                        <div className="td td-action"></div>
                    )
                )
            },
        ]
    }, [data]);

    const downloadAttachment = (fileInfo) => {

        if (fileInfo instanceof File) {
            fileDownload(fileInfo, fileInfo.name);
        } else {
            let url = `candidate/event/download/${fileInfo.candidateEventId}`;
            api.get(url, { responseType: 'blob' })
            .then(blob => {                
                fileDownload(blob.data, fileInfo.filename);
            }).catch(error => {
                console.error(error)
            })
        }
    }

    const {
        getRootProps, 
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ 
        onDrop: ([file]) => {
            console.log(file)
            setCandidateEvent({
                ...candidateEvent,
                file
            });
        }
     });

    const files = React.useMemo(() => {
        let name, size, file;

        if (candidateEvent?.file) {
            name = candidateEvent.file.name;
            size = candidateEvent.file.size;
            file = candidateEvent.file;
        } else if (candidateEvent?.filename) {
            name = candidateEvent.filename;
            size = candidateEvent.fileSizeInBytes;
            file = candidateEvent;
        } else {
            return null;
        }
        return <aside className="dropzone-list pt-3">
            <Label>Files</Label>
            <ul>
                <li key={name} className="pt-1">
                    <a onClick={() => downloadAttachment(file)}>{name} - {size} bytes</a>
                </li> 
            </ul>
        </aside>
    }, [ candidateEvent?.file ]);

    const dropzoneClasses = React.useMemo(() => cn({
        'dropzone': true,
        'mt-2': true,
        'm-3': true,
        'active': isDragActive,
        'accept': isDragAccept,
        'reject': isDragReject
    }), [
        isDragActive,
        isDragAccept,
        isDragReject
    ])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data: itemInEdit?.candidateEvents || [],
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout
    )

    return (
    <>
        <Collapse 
            isOpen={drawerMode !== Mode.NONE}
            style={{ zIndex: "9999", border: "0.0625rem solid #dee2e6" }}
            className="bg-white w-100 rounded p-3 mb-2"
        >
            <Row>
                <Col xs={6}>
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>
                                                Activity Date 
                                        </Label>
                                        <DatePicker
                                                name="candidateEventDateTime"
                                                id="candidateEventDateTime"
                                                type="text"
                                                required
                                                value={formatDate(candidateEvent?.candidateEventDateTime)}
                                                onChange={(value) => !value? null : setCandidateEvent({ ...candidateEvent, candidateEventDateTime: formatDateZeroTime(value) })}
                                                closeOnSelect
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>
                                            Activity Code
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <DropdownBox
                                            data={props.lookups.events}
                                            selectedItem={candidateEvent?.event}
                                            onChange={(item) => {
                                                console.log("on component change: ", item);
                                                setCandidateEvent({
                                                    ...candidateEvent,
                                                    event: item,
                                                    eventId: item ? item.lookupId : null
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Description
                                </Label>
                                <Input
                                    name="candidateEventDescription"
                                    id="candidateEventDescription"
                                    type="textarea"
                                    onChange={({ target: { value } }) => setCandidateEvent({ ...candidateEvent, candidateEventDescription: value })}
                                    value={candidateEvent?.candidateEventDescription ?? ""}
                                    tag={TextareaAutosize}
                                    rows={3}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Details
                                </Label>
                                <Input
                                    name="note"
                                    id="note"
                                    type="textarea"
                                    onChange={({ target: { value } }) => setCandidateEvent({...candidateEvent, note: value})}
                                    value={candidateEvent?.note ?? ""}
                                    tag={TextareaAutosize}
                                    rows={3}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col xs={6}>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Status
                                </Label>
                                <DropdownBox
                                    data={props.lookups.eventStatus}
                                    selectedItem={candidateEvent?.candidateEventStatus}
                                    onChange={(item) => {
                                        setCandidateEvent({
                                            ...candidateEvent,
                                            candidateEventStatus: item,
                                            candidateEventStatusId: item ? item.lookupId : null,
                                            completionComment: item?.lookupValue != statusFinished ? null : candidateEvent.completionComment,
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        {candidateEvent?.candidateEventStatus?.lookupValue === statusFinished &&
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Completion Comment
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    name="completionComment"
                                    id="completionComment"
                                    type="textarea"
                                    onChange={({ target: { value } }) => setCandidateEvent({ ...candidateEvent, completionComment: value })}
                                    value={candidateEvent?.completionComment ?? ""}
                                    tag={TextareaAutosize}
                                />
                            </FormGroup>
                        </Col>
                        }
                        <Col xs={12}>
                          <FormGroup>
                            <Label>
                                Attachments:
                            </Label>
                            <div {...getRootProps({className: dropzoneClasses})}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop some files here, or click to select files</p>
                            </div>
                            {files}
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
                <Row>
                <Col className="d-flex justify-content-end">
                    <TooltipButton
                        id="cancel"
                        title="Cancel"
                        className="btn-icon"
                        color="warning"
                        size="sm"
                        type="button"
                        onClick={ () => {                        
                            setDrawerMode(Mode.NONE);
                        }}                    
                    >
                        Cancel
                    </TooltipButton>
                    <TooltipButton
                        id="add"
                        title="Add"
                        className="btn-icon ml-2"
                        color="default"
                        size="sm"
                        type="button"
                        // disabled={!candidateEvent || (!candidateEvent.filename && !candidateEvent.file) || !candidateEvent.eventId}
                        disabled={!candidateEvent || !candidateEvent.eventId}
                        onClick={ () => { 
                            if (drawerMode === Mode.ADD) {
                                const newData = {
                                    ...candidateEvent,
                                    index: data?.length ? data.length - 1 : 0,
                                    files: [...acceptedAttachments]
                                }
                                setData([...data, {...newData}]);
                                setItemInEdit( { 
                                    ...itemInEdit,
                                    candidateEvents: [
                                        ...data || [],
                                        {...newData}
                                    ]
                                } );
                            } 
                            else if (drawerMode === Mode.EDIT)
                            {
                                const index = _.findIndex(data, o => {
                                    if (candidateEvent.candidateEventId)
                                        return o.candidateEventId === candidateEvent.candidateEventId;

                                    return o.index === candidateEvent.index;
                                });

                                const events = [...data]
                                events.splice(index, 1, { 
                                    ...candidateEvent,
                                    files: [...acceptedAttachments]
                                } );
                                console.log({index, candidateEvent, events})
                                setData([...events]);
                                setItemInEdit( { 
                                    ...itemInEdit, 
                                    candidateEvents: events 
                                } );
                            }
                            setDrawerMode(Mode.NONE);
                        }}
                    >
                        {drawerMode === Mode.ADD ? "Add" : drawerMode === Mode.EDIT ? "Update" : ""}
                    </TooltipButton>
                </Col>
            </Row>
        </Collapse>
        <Collapse
            isOpen={drawerMode === Mode.NONE}
        >
            <TooltipButton
                id="addnew"
                title="Add New"
                className="btn-icon ml-3 mb-2"
                color="default"
                size="sm"
                type="button"
                onClick={ () => {
                    setCandidateEvent({ active: true })
                    setDrawerMode(Mode.ADD);
                }}
            >
                <i className="fas fa-plus pt-1"></i>
            </TooltipButton>
        </Collapse>
        
        <Grid
            rows={rows}
            tableProps={getTableProps()}
            headerGroups={headerGroups}
            tableBodyProps={getTableBodyProps()}
            prepareRow={prepareRow}
        />
    </>)
}