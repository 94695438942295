import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal, Row } from "reactstrap";
import Loader from "../../../../loaders";
import api from "../../../../../services/api";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";

const ratingSlots = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const initialColumnDefs = [
    {
        field: "candidateName",
        headerName: "Employee Name",
        suppressMenu: true,
        pinned: true,
        cellRenderer: (cellParams) => {
            const {data} = cellParams;
            const rating = Math.ceil(data.averageRatings);

            return (
                <div className="d-flex flex-wrap justify-content-center align-items-center w-100-custom h-100 m-0 p-0">
                    <div className="d-flex flex-wrap justify-content-start align-items-start w-100-custom">
                        <span>{data.candidateName}</span>
                    </div>
                    <div className="d-flex flex-wrap justify-content-center align-items-start w-100-custom">
                        <span>
                        {
                            ratingSlots.map(r => {
                                return (
                                    <i className={`fa${r <= rating ? "s text-yellow" : "r"} fa-star`} />
                                );
                            })
                        }
                        </span>
                    </div>
                </div>
            );
        },
    },
];

function SupervisorFeedbackDialog(props) {
    const {
        dispatchUnitId,
        onClose,
    } = props;

    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState([]);
    const [ columnDefs, setColumnDefs ] = useState([]);
    const gridRef = useRef();

    const loadingOverlayComponent = useMemo(() => Loader, []);

    const defaultColDef = useMemo(() => ({
        sortable: false,
        resizable: true,
        filter: false,
        editable: false,
        suppressMenu: true,
        autoHeight: true,
    }), []);

    const loadData = useCallback(() => {
        setLoading(true);

        api.get(`supervisorrating/feedback/${dispatchUnitId}`)
            .then((response) => {
                const {supervisors, employees, feedbacks} = response.data;
                setColumnDefs([
                    ...initialColumnDefs,
                    ...supervisors.map(s => {
                        const currentFeedback = feedbacks.filter(f => f.supervisorId === s.candidateId)[0];

                        return {
                            field: `spv_${s.candidateId}`,
                            headerName: `${s.candidateName}${currentFeedback ? ` (${moment(currentFeedback.modifiedDate).format("DD-MM-YYYY")})` : ""}`,
                            suppressMenu: true,
                            cellRenderer: (cellParams) => {
                                const {data} = cellParams;
                                const rating = Math.ceil(data[`spv_${s.candidateId}`].rating);

                                return (
                                    <div className="d-flex flex-wrap justify-content-center align-items-center w-100-custom h-100 m-0 p-0">
                                        <div className="d-flex flex-wrap justify-content-center align-items-center w-100-custom">
                                            <span>
                                            {
                                                ratingSlots.map(r => {
                                                    return (
                                                        <i className={`fa${r <= rating ? "s text-yellow" : "r"} fa-star`} />
                                                    );
                                                })
                                            }
                                            </span>
                                        </div>
                                        <div className="d-flex flex-wrap justify-content-start align-items-start w-100-custom">
                                            <span>{data[`spv_${s.candidateId}`].comments}</span>
                                        </div>
                                    </div>
                                );
                            },
                        };
                    })
                ]);

                const newData = [];

                employees.forEach(e => {
                    const employeeFeedbacks = feedbacks.filter(f => f.candidateId === e.candidateId);
                    const averageRatings = employeeFeedbacks.length ? Math.ceil(employeeFeedbacks.map(f => f.overallRatingValue ?? -1).reduce((a, b) => a + b, 0) / employeeFeedbacks.length) : -1;

                    const currentData = {
                        candidateName: e.candidateName,
                        averageRatings
                    };

                    supervisors.forEach(s => {
                        const currentFeedback = feedbacks.filter(f => f.candidateId === e.candidateId && f.supervisorId === s.candidateId)[0];
                        currentData[`spv_${s.candidateId}`] = {
                            rating: currentFeedback?.overallRatingValue ?? -1,
                            comments: currentFeedback?.feedbackReason ?? null,
                        };
                    });

                    newData.push(currentData);
                });

                setData(newData);
            })
            .catch((error) => console.log({error}))
            .finally(() => setLoading(false))
        ;
    }, [api, dispatchUnitId]);

    // useEffect(() => console.log({data}), [data]);

    useEffect(() => {
        loadData();
    }, [dispatchUnitId]);

    const onGridReady = useCallback((params) => {
        // console.log({params});
        const {api: gridApi} = params;

        gridApi.sizeColumnsToFit({
            defaultMinWidth: 150,
            columnLimits: [
                { key: 'candidateName', minWidth: 200 },
            ],
          });
    }, [columnDefs]);

    return (
        <>
        {
            loading && <Loader />   
        }
            <Modal
                isOpen={true}
                className="modal-xxl"
                modalClassName="db-example-modal-xl"
            >
                <div className="modal-header">
                    <h5 className="modal-title">Performance Rating and Feedback</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        onClick={onClose}
                        type="button"
                    >
                        <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                    </button>
                </div>
                <div
                    className="modal-body"
                >
                    <Row className="m-0 p-0">
                        <div className="ag-theme-alpine ag-grid clickable-row" style={{ width: '100%', height: '60vh' }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                rowData={data}
                                loadingOverlayComponent={loadingOverlayComponent}
                                suppressColumnVirtualisation={false}
                                suppressRowVirtualisation={false}
                                rowBuffer={20}
                                debounceVerticalScrollbar={false}
                                allowContextMenuWithControlKey={true}
                                suppressContextMenu={true}
                                suppressClipboardPaste={true}
                                headerHeight={25}
                                groupHeaderHeight={22}
                                rowHeight={22}
                                readOnlyEdit={true}
                                enableRangeSelection={true}
                                tooltipShowDelay={0}
                                tooltipHideDelay={20000}
                                suppressDragLeaveHidesColumns={true}
                                suppressColumnMoveAnimation={true}
                                enableCellChangeFlash={true}
                                animateRows={true}
                                onGridReady={onGridReady}
                            />
                        </div>
                    </Row>
                </div>
                <div
                    className="modal-footer"
                >
                    <Button
                        color="secondary"
                        onClick={onClose}
                        type="button"
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default SupervisorFeedbackDialog;
