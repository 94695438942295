import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";

export default function ParagraphNotification(props) {

    const {
        onConfirm,
        message,
        title = "Message Text"
    } = props;

    return (
        <SweetAlert
            title={<h4 className="mb-0 text-center">{title ? title : "Message Text"}</h4>}
            onConfirm={onConfirm}
            type="info"
        >
            <div className="mb-2 text-justify">
                {message}
            </div>
        </SweetAlert>
    );
}
