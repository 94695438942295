import React, { useEffect } from 'react';

export default (props) => {
    const {node, column, node: {data}} = props;

    return (
        <div key={`${node.rowIndex}-${column.instanceId}`}
            className="border border-info px-3 py-1 d-flex flex-wrap justify-content-center"
            style={{ backgroundColor: 'white', minWidth: '150px' }}
        >
            <div className="w-100">
                Cancel Reason:<br/><b>{data?.cancelledStatus?.lookupValue}</b><br/><br/>
                Comments:<br/><b>{data?.cancelComment ?? "N/A"}</b>
            </div>
        </div>
    );
};
