import React from "react";

// reactstrap compnents
import { Button, Container, Row, Col } from "reactstrap";

// Core Components

function FooterMain() {
  return (
    <>
      <footer className="footer footer-simple bg-gradient-default footer-height p-0 m-0">
        <Container className="w-100 h-100 p-0 m-0">
          <Row className="w-100 h-100 p-0 m-0">
            <Col md="12" className="m-0 p-0 h-100 w-100">
              <div className="copyright m-0 p-0 h-100 w-100">
                <a
                  className="h-100 w-100"
                  href="/"
                  onClick={(e) => e.preventDefault()}
                >
                  {`© ALTRAD ${new Date().getFullYear()}`}
                </a>
              </div>
            </Col>
            {/* <Col md="6">
              <div className="column">
                <ul className="inline-menu">
                  <li>
                    <NavLink href="#" onClick={(e) => e.preventDefault()}>
                      Blog
                    </NavLink>
                  </li>
                  <li>
                    <NavLink href="#" onClick={(e) => e.preventDefault()}>
                      Presentation
                    </NavLink>
                  </li>
                  <li>
                    <NavLink href="#" onClick={(e) => e.preventDefault()}>
                      Discover
                    </NavLink>
                  </li>
                  <li>
                    <NavLink href="#" onClick={(e) => e.preventDefault()}>
                      Contact Us
                    </NavLink>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="3" className="text-right">
              <ul className="social-buttons">
                <li>
                  <Button
                    className="btn-icon mr-2"
                    color="twitter"
                    href="https://twitter.com/"
                    size="sm"
                    target="_blank"
                  >
                    <i className="fab fa-twitter"></i>
                  </Button>{" "}
                </li>
                <li>
                  <Button
                    className="btn-icon mr-2"
                    color="facebook"
                    href="https://www.facebook.com/"
                    size="sm"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-square"></i>
                  </Button>
                </li>
                <li>
                  <Button
                    className="btn-icon"
                    color="instagram"
                    href="https://www.instagram.com/"
                    size="sm"
                    target="_blank"
                  >
                    <i className="fab fa-instagram"></i>
                  </Button>
                </li>
              </ul>
            </Col> */}
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterMain;
