import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";


export default function DeleteSuccessAlert(props) {
    const {
        onConfirm
    } = props;

    return (
        <SweetAlert success title="Deleted!" onConfirm={onConfirm}>
            The record has been deleted.
        </SweetAlert>
    );
}
