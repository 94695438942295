import React, { useEffect, useState } from 'react';
import qs from "qs";
import { DefaultColumnFilter } from "../../../react-table/filters";
import { useTable, useFilters, useSortBy, useFlexLayout, useAsyncDebounce } from 'react-table';
import api from "../../../../services/api";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../../alerts";
import { TooltipButton } from '../../../inputs';
import Loader from "../../../loaders";
import JobDescriptionEditor from "./Editor";
import fileDownload from "js-file-download";
import {
   Container, Row, Col, CardTitle
} from "reactstrap";
import Grid from "../../../Grid";
import { Mode } from '../../../../utils';
import { useHistory } from 'react-router-dom';
import { ShortenTextColumn } from '../../../../helpers';


function JobDescription(props) {
    const history = useHistory();
    const [ data, setData ] = React.useState([]);
    const [ loading, setLoading ] = React.useState(false);
    const [ itemInEdit, setItemInEdit ] = React.useState(null);
    const [ filters, setFilters ] = React.useState([]);

    const defaultSort = React.useMemo(() => [{ id: "position", desc: false }],
        []
    );
    
    const [ sortBy, setSortBy ] = React.useState(defaultSort);

    const [ pageNumber, setPageNumber ] = React.useState(1);
    const [ pageSize, setPageSize ] = React.useState(10);
    const [ totalRecords, setTotalRecords ] = React.useState(0);
    const [ deleteAlert, setDeleteAlert ] = React.useState([]);
    const [ defaultPositionFilter, setDefaultPositionFilter ] = useState(null);
    const [ defaultEmploymentCategory, setDefaultEmploymentCategory] = useState(null);
    const { location, mode, id } = props;

    useEffect(() => {
        if (!location?.search || !location.search.includes("filter"))
        {
            setDefaultPositionFilter(null);
            return;
        }
        
        const params = new URLSearchParams(location.search);
        const filter = params.get('filter');
        const employmentCategory = params.get('empcat');
        setDefaultPositionFilter(filter);
        setDefaultEmploymentCategory(employmentCategory);
    }, [location]);

    const skipPageResetRef = React.useRef();

    const loadData = React.useCallback(() => {
        skipPageResetRef.current = true
        const queryString = qs.stringify({filters, sortBy}, { allowDots: true });
        setData([]);
        setLoading(true);
        api.get(`/jobdescription/${(pageNumber - 1) * pageSize}/${pageSize}${queryString ? `?${queryString}` : ""}`)
            .then(({data}) => {
                setData(data.data);
                setTotalRecords(data.total);
            })
            .catch((error) => {
                console.error("error: ", error);
            })
            .finally(() => {
                if (mode !== Mode.EDIT.toLowerCase() || !id)
                    setLoading(false);
            })
        ;

        if (mode === Mode.ADD.toLowerCase())
        {
            setItemInEdit({});
            return;
        }
        
        if (mode !== Mode.EDIT.toLowerCase() || !id)
            return;

        api.get(`/jobdescription/${id}`)
            .then(({data}) => {
                if (!data)
                    return;

                setItemInEdit({...data});
            })
            .catch((error) => {
                console.error({error});
            })
            .finally(() => setLoading(false))
        ;
    }, [filters, sortBy, pageNumber, pageSize, mode, id]);

    const downloadAttachment = (fileInfo) => {
        setLoading(true);
        let url = `jobdescription/download/${fileInfo.jobDescriptionId}`;
        api.get(url, { responseType: 'blob' })
        .then(blob => {
            fileDownload(blob.data, fileInfo.filename);
        }).catch(error => {
            console.error(error)
        }).finally(() => setLoading(false));
    }

    const handleDelete = (item) => {
        const endpoint = `jobdescription/${item.jobDescriptionId}`;
        const success = () => {
            setDeleteAlert([<DeleteSuccessAlert onConfirm={() => setDeleteAlert([])} />]);
            if (data.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1);
            } else {
                loadData();
            }
        };
        const failure = () => setDeleteAlert([<DeleteFailedAlert onConfirm={() => setDeleteAlert([])} />]);
        const events = {
            onConfirm: () => api.delete(`/${endpoint}`).then(success).catch(failure),
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert {...events} />])
    }

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
                })
            },
        }),
        []
    );
    
    const columns = React.useMemo(() => {
        return [
            {
                Header: "Employment Category",
                id: "employmentCategory",
                accessor: "employmentCategory.lookupValue",
                Filter: DefaultColumnFilter,
                filter: "text",
                width: 200, 
                disableSortBy: false,
            },
            {
                Header: "Position",
                id: "position",
                accessor: "position.positionName",
                Filter: DefaultColumnFilter,
                filter: "text",
                width: 200, 
                disableSortBy: false,
            },
            {
                Header: "Description",
                id: "description",
                accessor: "description",
                Filter: DefaultColumnFilter,
                filter: "text",
                width: 200,
                disableSortBy: false,
                Cell: ({ row: { original } }) => {
                    var text = ShortenTextColumn(original.description);
                    if (!text?.length) return (<>N/A</>);
                    return (<>{text}</>);
                },
            },
            {
                id: 'button',
                Cell: ({ row: { original } }) => (<>
                    <TooltipButton
                        id={`view_${original.jobDescriptionId}`}
                        title="Download"
                        className="btn-icon"
                        color="default"
                        size="sm"
                        type="button"
                        onClick={() => {downloadAttachment({ ...original })}}
                    >
                        <i className="fas fa-file-download pt-1"></i>
                    </TooltipButton>
                    <TooltipButton
                        id={`edit_${original.jobDescriptionId}`}
                        title="Edit"
                        className="btn-icon"
                        color="default"
                        size="sm"
                        type="button"
                        onClick={() => setItemInEdit({ ...original })}
                    >
                        <i className="fas fa-pencil-alt pt-1"></i>
                    </TooltipButton>
                    <TooltipButton
                        id={`delete_${original.jobDescriptionId}`}
                        title="Delete"
                        className="btn-icon"
                        color="warning"
                        size="sm"
                        type="button"
                        onClick={() => handleDelete({ ...original })}
                    >
                        <i className="fas fa-trash-alt pt-1"></i>
                    </TooltipButton>
                </>),
                //minWidth: 0,
                width: 200, 
                //maxWidth: 100,
            }
        ]
    }, []);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: defaultSort,
                canSort: true,
            },
            filterTypes,
            defaultColumn,
            manualSortBy: true,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        useFlexLayout,
        useFilters,
        useSortBy
    );

    React.useEffect(() => {
        loadData();
    }, [pageNumber, pageSize, filters, sortBy]);
    
    React.useEffect(() => {
        setFilters(state.filters);
    }, [state.filters]);
    
    React.useEffect(() => {
        setSortBy(state.sortBy);
    }, [state.sortBy]);

    return (
        <>
            {deleteAlert.length > 0 && deleteAlert}
            {loading && <Loader />}
            <Container fluid>
                <Row>
                    <Col>
                        <CardTitle>
                            <h4 className="text-center">Job Descriptions</h4>
                        </CardTitle>
                        <TooltipButton
                            id="addnew"
                            title="Add New"
                            className="btn-icon ml-2 mb-2"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => {  
                                setItemInEdit({});
                            }}
                        >
                            <i className="fas fa-plus pt-1"></i>
                        </TooltipButton>
                        <Grid
                            height='calc(100vh - 460px)'
                            totalRecords={totalRecords}
                            pageSize={pageSize}
                            rows={rows}
                            tableProps={getTableProps()}
                            headerGroups={headerGroups}
                            tableBodyProps={getTableBodyProps()}
                            prepareRow={prepareRow}
                            onPagerChangePage={(pager) => {
                                setPageNumber(pager.currentPage);
                            }}
                            onPagerChangePageSize={(size) => {
                                setPageSize(size);
                            }}
                            needPaging={true}
                        />

                        {itemInEdit && <JobDescriptionEditor 
                            item={itemInEdit}
                            onSaved={() => {
                                setItemInEdit(null);
                                loadData(filters);
                            }}
                            onClose={() => {
                                setItemInEdit(null);

                                if (mode)
                                    history.replace("/maintenance/job descriptions");
                            }}
                            defaultPositionFilter={defaultPositionFilter}
                            defaultEmploymentCategory={defaultEmploymentCategory}
                        />}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default JobDescription;