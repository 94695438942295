import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";

export default function ForecastNotification(props) {

    const {
        onConfirm,
        fields
    } = props;

    return (
        <SweetAlert
            title={<h4 className="mb-0">Syncronization already in progress</h4>}
            onConfirm={onConfirm}
            type="danger"
        >
            <div className="mb-2">
                A syncronization is already in progress, please try again later.
            </div>
        </SweetAlert>
    );
}
