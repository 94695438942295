import React, { useCallback, useState } from 'react';
import {
    Label, Col, Container, Row, Button
 } from "reactstrap";
 import PropTypes from "prop-types";
 import { useDropzone } from 'react-dropzone';
 import cn from "classnames";

 export const FileDropzone = (props) => {
    const {
        files,
        onDrop,
        maxFiles,
        disabled,
    } = props;

    const {
        getRootProps, 
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        maxFiles: maxFiles ?? 1,
        multiple: false,
        onDrop: (param) => {
            onDrop(param);
        }
    });

    const dropzoneClasses = React.useMemo(() => cn({
        'dropzone': true,
        'mt-2': true,
        'active': isDragActive,
        'accept': isDragAccept,
        'reject': isDragReject,
        'cursor-pointer': true,
    }), [
        isDragActive,
        isDragAccept,
        isDragReject
    ]);

    // console.log({files});

    return (
        <Col xs={12} className="m-0 p-0">
        {
            !disabled ?
            <div {...getRootProps({className: dropzoneClasses})}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop a file here, or click to select a file.</p>
            </div>
            :
            (!files ? (<span>N/A</span>) : null)
        }
            {files}
        </Col>
    );
};

FileDropzone.propTypes = {
    files: PropTypes.object,
    onDrop: PropTypes.func.isRequired,
    maxFiles: PropTypes.number
}

FileDropzone.defaultProps = {
    maxFiles: 1
}

export const DropzoneList = (props) => {
    const {
        name,
        size,
        download,
        itemInEdit,
    } = props;

    return (
        <aside className="dropzone-list pt-3">
            <ul>
                <li key={name} className="pt-1">
                    <a onClick={() => download(itemInEdit)}>{name} - {size} bytes</a>
                </li> 
            </ul>
        </aside>
    );
}

DropzoneList.propTypes = {
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    download: PropTypes.func.isRequired,
    itemInEdit: PropTypes.object,
}

const maxFileSize = 512000;

export const PhotoDropzone = (props) => {
    const {
        photo,
        setBinaryStr,
        accept,
        onError,
        maxFileCount,
    } = props;

    const [ isFileSizeLarger, setIsFileSizeLarger ] = useState(false);

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();
      
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => onError();
            reader.onload = () => {
                const binaryStr = reader.result;
                setBinaryStr(file.name, binaryStr);
            };
            reader.readAsDataURL(file);
        })
    }, [setBinaryStr, onError]);

    const onDropRejected = useCallback((files) => {
        setIsFileSizeLarger(files.length > 0 && files[0].file.size > maxFileSize);
    }, []);

    const {getRootProps, getInputProps, isDragActive, isDragReject} = useDropzone({maxFiles: maxFileCount, maxSize: maxFileSize, accept: accept, onDrop, onDropRejected});
    
    return (
        <Container className="w-100 h-100 d-flex flex-wrap justify-content-center align-items-center">
            <Row className="w-100 h-80">
                <Col
                    {...getRootProps()}
                    className="w-100 h-100 p-0 m-0 d-flex flex-wrap align-items-center justify-content-center"
                    style={{cursor: "pointer", backgroundColor: photo ? "transparent" : "#e9ecef"}}
                >
                {
                    photo ?
                    <img alt={photo.fileName} src={`data:${photo.fileType};base64,${photo.fileContent}`} className="img-fluid" /> :
                    null
                }
                    <input {...getInputProps()} className="w-100 h-100" />
                    {
                        !isFileSizeLarger && isDragActive && !isDragReject && !photo ?
                        <i className="fas fa-user" style={{fontSize: 42}}></i> :
                        (
                            !isFileSizeLarger && !isDragActive && !photo ?
                            <i className="fas fa-user" style={{fontSize: "6rem"}}></i>
                            :
                            null
                        )
                    }
                    {
                        !isFileSizeLarger && isDragReject ?
                        <span className="w-100 text-center"><i>Invalid file type.</i></span>
                        :
                        null
                    }
                    {
                        isFileSizeLarger ?
                        <span className="w-100 text-center"><i>File size is too large.</i></span>
                        :
                        null
                    }
                </Col>
            </Row>
        {
            photo &&
            <Row className="w-100 d-flex flex-wrap justify-content-center align-items-center">
                <Col className="w-100 d-flex flex-wrap justify-content-center align-items-center">
                    <Button size="sm" color="default" onClick={() => setBinaryStr()}>
                        Remove Photo
                    </Button>
                </Col>
            </Row>
        }
            <Row className="w-100 d-flex flex-wrap justify-content-center align-items-center">
                <Col className="w-100 d-flex flex-wrap justify-content-center align-items-center">
                    <span style={{fontSize: "0.8rem"}}>Max file size: {maxFileSize / 1024} KB</span>
                </Col>
            </Row>
        </Container>
    );
};

PhotoDropzone.propTypes = {
    photo: PropTypes.object,
    setBinaryStr: PropTypes.func,
    accept: PropTypes.string,
    onError: PropTypes.func,
    maxFileCount: PropTypes.number,
}

export const MultipleDropzoneList = (props) => {
    const {
        idField,
        nameField,
        sizeField,
        download,
        remove,
        data,
        disabled,
    } = props;

    return (
        <aside className="dropzone-list pt-3 w-100">
            <ul className="container">
            {
                data?.map(d => {
                    return (
                        <li key={d[idField]} className="pt-1 w-100 row border-bottom pb-2">
                            <div className="col-sm-9">
                                <a onClick={() => download(d)}>{d.file ? d.file.name : d[nameField]} - {d.file ? d.file.size : d[sizeField]} bytes</a>
                            </div>
                        {
                            !disabled &&
                            <div className="col-sm-3">
                                <Button
                                    type="button"
                                    onClick={() => {
                                    if(remove)
                                        remove(d);
                                    }}
                                    size="sm"
                                    color="danger"
                                >
                                    <i className="fas fa-times-circle text-white" /> Remove
                                </Button>
                            </div>
                        }
                        </li>
                    );
                })
            }
            </ul>
        </aside>
    );
}

MultipleDropzoneList.propTypes = {
    idField: PropTypes.string.isRequired,
    nameField: PropTypes.string.isRequired,
    sizeField: PropTypes.string.isRequired,
    download: PropTypes.func.isRequired,
    remove: PropTypes.func,
    data: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
}

MultipleDropzoneList.defaultProps = {
    disabled: false,
}