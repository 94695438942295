import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { useHistory } from "react-router";
import Loader from "../../../../loaders";
import { ErrorNotification } from "../../../../alerts";
import EmployeeSelectionDialog from "./EmployeeSelectionDialog";
import MessageDialog from "./MessageDialog";
import api from "../../../../../services/api";

function SpvRatingDialog(props) {
    const {
        dispatchUnitId,
        onClose
    } = props;

    const [ loading, setLoading ] = useState(false);
    const [ pageId, setPageId ] = useState(0);
    const [ selectedSupervisors, setSelectedSupervisors ] = useState([]);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ errorNotification, setErrorNotification ] = useState([]);
    const history = useHistory();

    useEffect(() => {
        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        const events = {
            onConfirm: () => setErrorMessage(null),
            message: errorMessage
        }
        setErrorNotification([<ErrorNotification {...events} />]);
    }, [errorMessage]);

    const handleSendMessage = useCallback((messagesMasterId, messageTemplate) => {
        setLoading(true);
        
        const dataToSend = {
            dispatchUnitId,
            messagesMasterId,
            messageTemplate,
            rosterSupervisorProfiles: [...selectedSupervisors],
        };

        api.post("supervisorrating", JSON.stringify(dataToSend))
            .then((response) => {
                console.log({response});
                setLoading(false);
                onClose();
            })
            .catch((error) => {
                console.log({error});
                setLoading(false);
            })
        ;
    }, [dispatchUnitId, selectedSupervisors, onClose]);

    // useEffect(() => console.log({selectedSupervisors}), [selectedSupervisors]);

    return (
        <>
        {
            loading && <Loader />   
        }
            <Modal
                isOpen={true}
                className="modal-xxl"
                modalClassName="db-example-modal-xl"
            >
            {
                pageId === 0 &&
                <EmployeeSelectionDialog
                    title="Send Feedback Request"
                    dispatchUnitId={dispatchUnitId}
                    initialSelectedSupervisors={selectedSupervisors}
                    onClose={onClose}
                    onNextClick={(supervisors) => {
                        setSelectedSupervisors(supervisors);
                        setPageId(1);
                    }}
                />
            }
            {
                pageId === 1 &&
                <MessageDialog
                    title="Message Confirmation"
                    dispatchUnitId={dispatchUnitId}
                    selectedSupervisors={selectedSupervisors}
                    onClose={onClose}
                    onBackClick={() => {
                        setPageId(0);
                    }}
                    onNextClick={(messagesMasterId, message) => {
                        console.log({messagesMasterId, message});
                        handleSendMessage(messagesMasterId, message);
                    }}
                />
            }
            </Modal>
        {
            errorNotification.length > 0 && errorNotification
        }
        </>
    );
};

export default SpvRatingDialog;
