import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'reactstrap';
import api from "../../../../services/api";
import Loader from '../../../loaders';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';

export default function CommunicationDialog(props) {
    const {
        onClose,
        data,
    } = props;

    const [ loading, setLoading ] = useState(false);
    const [ messages, setMessages ] = useState([]);

    const defaultColDef = useMemo(() => ({
        resizable: true,
        editable: false,
        suppressMenu: true,
    }), []);

    const columnDefs = useMemo(() => {
        return [
            {
                headerName: "Sent By",
                field: "createdBy",
                width: 90,
            },
            {
                headerName: "Sent Date",
                field: "sentDate",
                width: 60,
                cellRenderer: (props) => {
                    const {data: {sentDate, relatedMessageLogId}} = props;
                    const value = sentDate ? moment(sentDate).format("DD-MM-YYYY HH:mm:ss") : "";
    
                    return (
                        <div>
                            {relatedMessageLogId ? <i className="fas fa-long-arrow-alt-right text-default mr-2" /> : null}
                            {value}
                        </div>
                    );
                },
            },
            {
                headerName: "Employee Name",
                field: "candidateName",
                width: 60,
            },
            {
                headerName: "Start Date",
                field: "startDate",
                valueFormatter: (params) => {
                    const { value } = params;
                    return value ? moment(value).format("DD-MM-YYYY") : "";
                },
                width: 60,
            },
            {
                headerName: "End Date",
                field: "endDate",
                valueFormatter: (params) => {
                    const { value } = params;
                    return value ? moment(value).format("DD-MM-YYYY") : "";
                },
                width: 60,
            },
            {
                headerName: "Type",
                field: "messageType",
                width: 60,
            },
            {
                headerName: "Detail",
                field: "message",
                wrapText: true,
                autoHeight: true,
                cellStyle: {lineHeight: "1rem"},
                cellClass: ["py-2"],
            },
        ]
    }, []);

    const loadData = useCallback(() => {
        setLoading(true);

        api.get(`communication/rostercandidate/${data?.rosterCandidateId}`)
            .then((response) => {
                const result = [];

                if (response.data.length)
                    response.data.forEach(d => {
                        result.push({...d, relatedMessageLogId : null});

                        if (d.relatedMessageLogs.length)
                            d.relatedMessageLogs.forEach((dr) => {
                                result.push({...dr});
                            });
                    });

                setMessages(result);
            })
            .catch((error) => console.log({error}))
            .finally(() => setLoading(false))
        ;
    }, [data]);

    useEffect(() => {
        loadData();
    }, [data]);

    const resizeColumns = useCallback((event) => {
        if (data?.length === 0)
            return;

        event.api.sizeColumnsToFit();

        event.api.sizeColumnsToFit();
        window.addEventListener('resize', function () {
            setTimeout(function () {
                event.api.sizeColumnsToFit();
            });
        });
    }, [data]);

    const getRowClass = (params) => {
        // console.log({params});
        if (params.data.relatedMessageLogId)
            return null;

        return "bg-blue-light";
    };

    return (
        <Modal
            isOpen={true}
            className="modal-fullscreen modal-fullscreen-xl"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Message Log - Slot {data?.slot}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body p-2"
                style={{maxHeight: "70vh", overflowY: "auto"}}
            >
                <Container fluid>
                    {loading && <Loader />}
                    <Row className="m-0">
                        <Col xs={12}>
                            <span style={{fontSize: "0.8rem"}}>Legend:<br style={{lineHeight: "1rem"}} /><i className="fas fa-long-arrow-alt-right text-default mr-2" />reply from the talent</span>
                        </Col>
                    </Row>
                    <Row className="m-0 mt-2">
                    {
                        messages?.length > 0 ?
                        <Col xs={12}>
                            <div className="ag-theme-alpine ag-grid clickable-row" style={{ width: '100%', height: '61vh' }}>
                                <AgGridReact
                                    rowData={messages}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    suppressColumnVirtualisation={false}
                                    suppressRowVirtualisation={false}
                                    debounceVerticalScrollbar={false}
                                    rowBuffer={20}
                                    headerHeight={30}
                                    groupHeaderHeight={30}
                                    rowHeight={30}
                                    floatingFiltersHeight={30}
                                    suppressDragLeaveHidesColumns={true}
                                    suppressColumnMoveAnimation={true}
                                    animateRows={false}
                                    onRowDataUpdated={(e) => resizeColumns(e)}
                                    suppressContextMenu={true}
                                    suppressRowClickSelection={true}
                                    getRowClass={getRowClass}
                                    suppressRowHoverHighlight={true}
                                />
                            </div>
                        </Col>
                        :
                        null
                    }
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer d-flex flex-wrap"
            >
                <Button
                    color="secondary"
                    onClick={onClose}
                    type="button"
                    className="mt-2"
                >
                    Close
                </Button>
            </div>
        </Modal>
    );
}