import moment from 'moment';
import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button, Col, Container, Modal, Row } from 'reactstrap';
import api from "../../../../services/api";
import Loader from '../../../loaders';

export default function DeleteSlotDialog(props) {
    const {
        rosterCandidates,
        onClose,
        onDelete
    } = props;

    const [ data, setData ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ flights, setFlights ] = useState([]);

    const loadData = useCallback(() => {
        const rosterCandidateIds = rosterCandidates?.map(rc => rc.rosterCandidateId);

        if (!rosterCandidateIds.length)
        {
            setData([]);
            return;
        }

        setLoading(true);

        api.post(`rostercandidate/id-bulk`, JSON.stringify(rosterCandidateIds))
            .then((response) => {
                const { data } = response;

                const newData = data.map(d => {
                    const oldRow = rosterCandidates.filter(rc => rc.rosterCandidateId === d.rosterCandidateId)[0];
                    return {...d, slotNo: oldRow.slot};
                });

                setData(newData);
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [rosterCandidates]);

    useEffect(() => {
        loadData();
    }, [rosterCandidates]);

    const handleRemoval = useCallback(() => {
        onDelete(rosterCandidates);
    }, [rosterCandidates]);

    useEffect(() => {
        if (!data?.length)
        {
            setFlights([]);
            return;
        }

        const newFlights = [];

        data.forEach(rc => {
            const currentFlights = [];
            rc.rosterCandidateSchedules.forEach(rcs => {
                if (!rcs.flights.length)
                    return;
                
                rcs.flights.forEach(f => currentFlights.push({...f}));
            })

            if (!currentFlights.length)
                return;

            newFlights.push({rosterCandidateId: rc.rosterCandidateId, flights: currentFlights});
        });

        setFlights([...newFlights]);
    }, [data]);

    if (!data.length)
        return (<>
        {
            loading && <Loader />
        }
        </>);

    if (
        !(flights.length)
        && !(data.filter(rc => rc.rosterCandidateAccommodations.length > 0).length)
        && !(data.filter(rc => rc.requestDetail !== null).length)
        && !(data.filter(rc => rc.jobOrderCandidate !== null).length)
        && !(data.filter(rc => rc.relatedJobOrderCandidate !== null).length)
        && !(data.filter(rc => rc.inverseRelatedRosterCandidate?.length > 0).length)
    )
        return (
            <>
            {
                loading && <Loader />
            }
                <SweetAlert
                    title="Roster Candidate Removal Confirmation"
                    warning
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="danger"
                    onConfirm={() => handleRemoval()}
                    showCancel={true}
                    cancelBtnText="Cancel"
                    onCancel={() => onClose()}
                >
                    Are you sure you want to delete slot {data?.slotNo}?
                </SweetAlert>
            </>
        );

    return (
        <>
        {
            loading && <Loader />
        }
            <Modal
                isOpen={true}
                className="modal-lg"
                modalClassName="db-example-modal-xl"
            >
                <div
                    className="modal-header"
                >
                    <h5
                        className="modal-title"
                    >
                        Roster Candidate Removal Confirmation
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        onClick={onClose}
                        type="button"
                    >
                        <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                    </button>
                </div>
                <div
                    className="modal-body"
                >
                    <Row className="mb-3">
                        <Col lg="12">
                            <h6
                                className="modal-title"
                            >
                                The following dependencies will be deleted as well. Are you sure?
                            </h6>
                        </Col>
                    </Row>
                    <Container className="p-0 m-0" style={{overflowX: "hidden", overflowY: "scroll", maxHeight: "50vh"}}>
                    {
                        data.map(d => (
                            <>
                                <Row className="mb-3">
                                    <Col lg="12">
                                        <span><strong>Slot {d.slotNo} - {d.candidateName ?? "No Talent Selected"}</strong></span>
                                    </Col>
                                </Row>
                                <Row className="mb-3 pl-3">
                                    <Col lg="12">
                                        <span><strong>Flights</strong></span>
                                    </Col>
                                {
                                    flights.filter(f => f.rosterCandidateId === d.rosterCandidateId).length ?
                                    flights.filter(f => f.rosterCandidateId === d.rosterCandidateId).map((rc, rc_idx) => {
                                        return rc.flights.map((f, f_idx) => {
                                            return (
                                                <Col lg="12" key={`flight_${rc_idx}_${f_idx}`}>
                                                    <span>{f.flightNumber} - {moment(f.flightDateTime, "YYYY-MM-DDTHH:mm:ss").format("DD-MM-YYYY HH:mm A")} {f.flightFrom && f.flightTo ? ` - ${f.flightFrom?.airportName} to ${f.flightTo?.airportName}` : ''}</span>
                                                </Col>
                                            );
                                        })
                                    })
                                    :
                                    <Col lg="12">-</Col>
                                }
                                </Row>
                                <Row className="mb-3 pl-3">
                                    <Col lg="12">
                                        <span><strong>Accommodations</strong></span>
                                    </Col>
                                {
                                    d.rosterCandidateAccommodations?.length ? 
                                    d.rosterCandidateAccommodations.map((rc, idx) => {
                                        return (
                                            <Col lg="12" key={`accmdn_${idx}`}>
                                                <span>{rc.accommodation?.accommodationName} - {rc.startDate ? moment(rc.startDate, "YYYY-MM-DD").format("DD-MM-YYYY") : null} to {rc.endDate ? moment(rc.endDate, "YYYY-MM-DD").format("DD-MM-YYYY") : null}</span>
                                            </Col>
                                        );
                                    })
                                    :
                                    <Col lg="12">-</Col>
                                }
                                </Row>
                                <Row className="mb-3 pl-3">
                                    <Col lg="12">
                                        <span><strong>Recruitment Request</strong></span>
                                    </Col>
                                    <Col lg="12">
                                    {
                                        d.requestDetail ?
                                        `${d.requestDetail.recruitmentRequest.recruitmentRequestNumber} - ${d.requestDetail.position?.lookupValue} - Status: ${d.requestDetail.recruitmentRequest.status?.lookupValue}`
                                        :
                                        "-"
                                    }
                                    </Col>
                                </Row>
                                <Row className="mb-3 pl-3">
                                    <Col lg="12">
                                        <span><strong>Job Order</strong></span>
                                    </Col>
                                    <Col lg="12">
                                    {
                                        d.jobOrderCandidate ?
                                        `${d.jobOrderCandidate.jobOrder.recruitmentRequestNumber} - ${d.jobOrderCandidate.latestJobOrderMilestone?.milestoneName ?? "N/A"} - ${d.jobOrderCandidate.latestJobOrderMilestoneDate ? moment(d.jobOrderCandidate.latestJobOrderMilestoneDate, "YYYY-MM-DD").format("DD-MM-YYYY") : "N/A"}`
                                        :
                                        d.relatedJobOrderCandidate ?
                                        `${d.relatedJobOrderCandidate.jobOrder.recruitmentRequestNumber} - ${d.relatedJobOrderCandidate.latestJobOrderMilestone?.milestoneName ?? "N/A"} - ${d.relatedJobOrderCandidate.latestJobOrderMilestoneDate ? moment(d.relatedJobOrderCandidate.latestJobOrderMilestoneDate, "YYYY-MM-DD").format("DD-MM-YYYY") : "N/A"}`
                                        :
                                        "-"
                                    }
                                    </Col>
                                </Row>
                                <Row className="mb-3 pl-3">
                                    <Col lg="12">
                                        <span><strong>Split Slots</strong></span>
                                    </Col>
                                {
                                    d.inverseRelatedRosterCandidate?.length ? 
                                    d.inverseRelatedRosterCandidate.map((rc, idx) => {
                                        return (
                                            <Col lg="12" key={`accmdn_${idx}`}>
                                                <span>{`${rc.slotNo} - ${rc.position?.positionName}`}</span>
                                            </Col>
                                        );
                                    })
                                    :
                                    <Col lg="12">-</Col>
                                }
                                </Row>
                            </>
                        ))
                    }
                    </Container>
                </div>
                <div
                    className="modal-footer"
                >
                    <Button
                        color="secondary"
                        onClick={onClose}
                        type="button"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="info"
                        onClick={() => handleRemoval()}
                        type="button"
                    >
                        Remove
                    </Button>
                </div>
            </Modal>
        </>
    );
};
