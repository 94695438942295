import React, { createContext, useReducer, useContext } from "react";
import reportReducer, { initialState, actions } from "../reducers/reportReducer";

export const ReportContext = createContext(initialState);

export const ReportProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reportReducer, initialState);

    //console.log('ReportProvider', {reportReducer});
    //console.log('ReportProvider', {initialState});
    //console.log('ReportProvider', {state});
    //console.log('ReportProvider', {dispatch});
    //console.log('ReportProvider', {children});
    //console.log('ReportProvider', {actions});

    const setProject = (project) => {
        dispatch({
            type: actions.SET_PROJECT,
            payload: {
                project: project
            }
        });
    }

    const setDispatchUnit = (dispatchUnit) => {
        dispatch({
            type: actions.SET_DISPATCH,
            payload: {
                dispatchUnit: dispatchUnit
            }
        });
    }

    const setStartDate = (startDate) => {
        dispatch({
            type: actions.SET_START,
            payload: {
                startDate: startDate
            }
        });
    }

    const setEndDate = (endDate) => {
        dispatch({
            type: actions.SET_END,
            payload: {
                endDate: endDate
            }
        });
    }

    const setTrimStartDate = (trimStartDate) => {
        dispatch({
            type: actions.SET_TRIM_START,
            payload: {
                trimStartDate: trimStartDate
            }
        });
    }

    const setTrimEndDate = (trimEndDate) => {
        dispatch({
            type: actions.SET_TRIM_END,
            payload: {
                trimEndDate: trimEndDate
            }
        });
    }

    const setShowArchivedDispatchUnits = (showArchivedDispatchUnits) => {
        dispatch({
            type: actions.SET_SHOW_ARCHIVED,
            payload: {
                showArchivedDispatchUnits: showArchivedDispatchUnits
            }
        });
    }

    const setDataLoaded = (dataLoaded) => {
        dispatch({
            type: actions.SET_DATA_LOADED,
            payload: {
                dataLoaded: dataLoaded
            }
        });
    }

    const value = {
        setProject,
        setDispatchUnit,
        setStartDate,
        setEndDate,
        setTrimStartDate,
        setTrimEndDate,
        setShowArchivedDispatchUnits,
        setDataLoaded,
        project: state.project,
        dispatchUnit: state.dispatchUnit,
        startDate: state.startDate,
        endDate: state.endDate,
        trimStartDate: state.trimStartDate,
        trimEndDate: state.trimEndDate,
        showArchivedDispatchUnits: state.showArchivedDispatchUnits,
        dataLoaded: state.dataLoaded,
    };
    return <ReportContext.Provider value={value}>{children}</ReportContext.Provider>;
}

const useReport = () => {
    const context = useContext(ReportContext);
  
    if (context === undefined) {
      throw new Error("useRoster must be used within RosterProvider");
    }
  
    return context;
  };
  
  export default useReport;