import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Loader from '../../../loaders';
import api from "../../../../services/api";
import { Button, Col, Container, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import { useFlexLayout, useRowSelect, useTable } from 'react-table';
import { IndeterminateCheckbox } from '../../../react-table/controls';
import { TooltipButton } from '../../../inputs';

function HrispositionListComponent(props) {
    const { id, title, data, onSelect, onMove, hideLabel, columnHeader, listPosition } = props;

    const columns = useMemo(() => [
        {
            Header: columnHeader,
            accessor: row => `${row.lookupValue}`,
        },
    ], []);

    const defaultColumn = useMemo(
        () => ({
            minWidth: 30,
            width: 150,
            maxWidth: 200,
        }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // selectedFlatRows,
        toggleRowSelected,
    } = useTable (
        {
            columns,
            data,
            defaultColumn,
        },
        useFlexLayout,
        useRowSelect,
        // hooks => {
        //     hooks.visibleColumns.push(columns => [
        //         {
        //             id: 'selection',
        //             minWidth: 35,
        //             width: 35,
        //             maxWidth: 35,
        //             Header: ({ getToggleAllRowsSelectedProps }) => (
        //                 <div>
        //                     <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} id={`milestone-header-${id}`} />
        //                 </div>
        //             ),
        //             Cell: ({ row }) => (
        //                 <div>
        //                     <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} id={`milestone-${id}-${row.id}`} />
        //                 </div>
        //             ),
        //         },
        //         ...columns,
        //     ]);
        // }
    );

    // useEffect(() => {
    //     onSelect(selectedFlatRows.map(d => ({ ...d.original, selected: true })));
    // }, [selectedFlatRows]);

    return (
        <>
            {!hideLabel && <label className="">{title}</label>}
            <div {...getTableProps()} className="table flex table-hover scrollable">
                <div className="thead">
                    {headerGroups.map(headerGroup => {
                        return (<div {...headerGroup.getHeaderGroupProps()} className="tr">
                            {headerGroup.headers.map((column, index) => {
                                return (<div {...column.getHeaderProps()} className="th">
                                    {column.render('Header')}
                                </div>);
                            })}
                            <div className="th th-action text-right" style={{width: "77px"}}></div>
                        </div>);
                    }
                    )}
                </div>
                <div {...getTableBodyProps()} className="tbody">
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <div {...row.getRowProps()} className="tr">
                            {
                                listPosition === "right" &&
                                <div className="td td-action text-left" style={{width: "60px"}}>
                                    <TooltipButton
                                        id={`move_${i}`}
                                        title="Move"
                                        className="btn-icon"
                                        color="default"
                                        size="sm"
                                        type="button"
                                        outline
                                        onClick={() => {
                                            onMove(row.original)
                                        }}
                                    >
                                        <i className="fas fa-chevron-left"></i>
                                    </TooltipButton>
                                </div>
                            }
                            {
                                row.cells.map(cell => {
                                    return <div {...cell.getCellProps({
                                        onClick: cell.column.id !== "selection" ? () => toggleRowSelected(row.id) : undefined
                                    })}
                                        className={`td ${cell.column.id !== "selection" ? "cursor-pointer" : ""}`}
                                    >
                                        {cell.render('Cell')}
                                    </div>;
                                })
                            }
                            {
                                listPosition === "left" &&
                                <div className="td td-action text-right" style={{width: "60px"}}>
                                    <TooltipButton
                                        id={`move_${i}`}
                                        title="Move"
                                        className="btn-icon"
                                        color="default"
                                        size="sm"
                                        type="button"
                                        outline
                                        onClick={() => {
                                            onMove(row.original)
                                        }}
                                    >
                                        <i className="fas fa-chevron-right"></i>
                                    </TooltipButton>
                                </div>
                            }
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default function HrispositionList(props) {
    const {
        hrispositionMasterId,
        setSelectedHrispositionMasterId
    } = props;

    const [ loading, setLoading ] = useState(false);
    const [ hrispositionMaster, setHrispositionMaster ] = useState(null);
    const [ selectedPositions, setSelectedPositions ] = useState([]);
    const [ positionName, setPositionName ] = useState(null);
    const [ positions, setPositions ] = useState([]);

    const loadData = useCallback(() => {
        setHrispositionMaster(null);
        setSelectedPositions([]);

        if (!hrispositionMasterId)
            return;

        setLoading(true);

        const apiCalls = [
            api.get(`/hrispositionmaster/${hrispositionMasterId}`),
            api.get(`/hrispositionmaster/search-hrispositions?id=${hrispositionMasterId}`)
        ]

        Promise.all(apiCalls)
            .then((responses) => {
                // console.log(responses);
                setHrispositionMaster(responses[0]?.data);
                setSelectedPositions(responses[1]?.data.map(p => ({...p, positionId: p.lookupId})) ?? []);
            })
            .catch(error => console.error(error))
            .finally(() => setLoading(false))
        ;
    }, [hrispositionMasterId]);

    useEffect(() => {
        loadData();
    }, [hrispositionMasterId]);

    const loadHrispositionData = useCallback(() => {
        setPositions([]);

        if (!positionName || positionName?.trim() === "")
            return;
        
        setLoading(true);
        api.get(`/lookup/position?filter=${positionName}`)
            .then((response) => {
                const loadedPositions = response.data?.filter(p => selectedPositions.filter(sp => sp.positionId === p.lookupId).length === 0).map(p => ({ ...p, positionId: p.lookupId }));
                console.log(loadedPositions);
                setPositions(loadedPositions);
            })
            .catch(error => console.error(error))
            .finally(() => setLoading(false))
        ;
    }, [selectedPositions, positionName]);

    useEffect(() => {
        loadHrispositionData();
    }, [positionName]);

    const handleSave = useCallback(() => {
        if (!selectedPositions)
            return;
        
        setLoading(true);
        console.log(selectedPositions);
        api.post(`/hrispositionmaster/hrispositions/${hrispositionMasterId}`, selectedPositions.map(sp => sp.positionId))
            .then(() => {
                setLoading(false);
                setSelectedHrispositionMasterId(null);
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [hrispositionMasterId, selectedPositions]);

    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
        {
            loading && <Loader />
        }
            <div className="modal-header">
                <h5 className="modal-title">
                    Manage HRIS Position Master - {hrispositionMaster?.hrispositionMasterName}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={() => setSelectedHrispositionMasterId(null)}
                    type="button"
                >
                    <span aria-hidden={true}>x</span>
                </button>
            </div>
            <div className="modal-body">
                <Container fluid>
                    <Row>
                        <Col lg={6}>
                            <Row>
                                <Col lg={12}>
                                    <FormGroup>
                                        <Label>
                                            Available Employed Position Name
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            value={positionName ?? ""}
                                            onChange={(e) => setPositionName(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <HrispositionListComponent
                                id="available-positions"
                                title="Available Employed Positions"
                                hideLabel={true}
                                data={positions}
                                onSelect={() => {}}
                                onMove={(data) => {
                                    const currentPositions = [...positions];
                                    const removedPosition = currentPositions.filter(sp => sp.positionId === data.positionId)[0];
                                    currentPositions.splice(currentPositions.indexOf(removedPosition), 1);
                                    setPositions(currentPositions);
                                    const currentSelectedPositions = [...selectedPositions];
                                    currentSelectedPositions.push(data);
                                    setSelectedPositions(currentSelectedPositions);
                                }}
                                columnHeader="Available Employed Positions"
                                listPosition="left"
                            />
                        </Col>
                        <Col lg={6}>
                            <HrispositionListComponent
                                id="selected-positions"
                                title="Selected Employed Positions"
                                hideLabel={true}
                                data={selectedPositions}
                                onSelect={() => {}}
                                onMove={(data) => {
                                    const currentSelectedPositions = [...selectedPositions];
                                    const removedPosition = currentSelectedPositions.filter(sp => sp.positionId === data.positionId)[0];
                                    currentSelectedPositions.splice(currentSelectedPositions.indexOf(removedPosition), 1);
                                    setSelectedPositions(currentSelectedPositions);
                                    // console.log({positionName, positions, data, added: data?.positionName?.toLowerCase().includes(positionName?.toLowerCase())});

                                    if (!positionName || positionName.trim() === "" || !data?.positionName?.toLowerCase().includes(positionName?.toLowerCase()))
                                        return;

                                    const currentPositions = [...positions];
                                    currentPositions.push(data);
                                    setPositions(currentPositions);
                                }}
                                columnHeader="Selected Employed Positions"
                                listPosition="right"
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="modal-footer">
                <Button
                    color="secondary"
                    onClick={() => setSelectedHrispositionMasterId(null)}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave()}
                    type="button"
                >
                    Save Changes
                </Button>
            </div>
        </Modal>
    );
};