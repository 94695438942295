import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import SweetAlert from "react-bootstrap-sweetalert";

export default function RequiredAlert(props) {
    const {
        onConfirm
    } = props;

    return (
        <SweetAlert
            title={<h4 className="mb-0">Department is required.</h4>}
            onConfirm={onConfirm}
            type="danger"
        >
            <div className="mb-2">
                Please select a department to continue.
            </div>
        </SweetAlert>
    );
}