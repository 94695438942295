import React, { Component } from 'react';
import qs from 'query-string';

export default class RosterTNAExport extends Component {
    componentDidMount() {
        const params = qs.parse(this.props.location.search);
        window.jQuery('#reportViewer1')
            .telerik_ReportViewer({
                serviceUrl: 'api/reports/',
                reportSource: {
                    report: './TNAExport.trdp',
                    parameters: {
                        "projectId": params.projectId
                    }
                },
                scale: 1.0,
                viewMode: 'INTERACTIVE',
                printMode: 'SPECIFIC',
            });
    }

    render() {
        return <div id="reportViewer1"></div>
    }
}