export const profileRows = [
    {
        id: "profile",
        title: "Profile",
        type: "title",
    },
    {
        id: "firstName",
        title: "First Name",
    },
    {
        id: "middleName",
        title: "Middle Name",
    },
    {
        id: "surname",
        title: "Surname",
    },
    {
        id: "preferredName",
        title: "Preferred Name",
    },
    {
        id: "salutation",
        title: "Salutation",
        rowDataColumnId: "salutationId",
    },
    {
        id: "maritalStatus",
        title: "Marital Status",
        rowDataColumnId: "maritalStatusId",
    },
    {
        id: "universalStudentId",
        title: "Universal Student ID",
    },
    {
        id: "gender",
        title: "Gender",
        rowDataColumnId: "genderId",
    },
    {
        id: "doB",
        title: "DoB (day-month-year)",
        type: "date",
    },
    {
        id: "naturalPosition",
        title: "Natural Position",
        rowDataColumnId: "naturalPositionId",
    },
    {
        id: "atsi",
        title: "ATSI",
        type: "bool",
    },
    {
        id: "disability",
        title: "Disability",
        type: "bool",
    },
    {
        id: "cald",
        title: "CALD",
        type: "bool",
    },
    {
        id: "contact",
        title: "Contact",
        type: "title",
    },
    {
        id: "mobilePhoneNumber",
        title: "Mobile Number",
        additionalPropertyName: "phoneNumberUsedAsUsername",
        additionalPropertyType: "bool",
    },
    {
        id: "email",
        title: "Email",
        additionalPropertyName: "emailUsedAsUsername",
        additionalPropertyType: "bool",
    },
    {
        id: "receivingCommunication",
        title: "Receive Communication",
        type: "bool",
    },
    {
        id: "receivingEmail",
        title: "Receive Email",
        type: "bool",
    },
    {
        id: "receivingPhoneCall",
        title: "Receive Phone Call",
        type: "bool",
    },
    {
        id: "receivingSms",
        title: "Receive SMS",
        type: "bool",
    },
    {
        id: "preferredCommunication",
        title: "Preferred Communication Type",
        rowDataColumnId: "preferredCommunicationId",
    },
    {
        id: "residency",
        title: "Residency",
        type: "title",
    },
    {
        id: "residencyStatus",
        title: "Residency Status",
        rowDataColumnId: "residencyStatusId",
    },
    {
        id: "visaNumber",
        title: "Visa Number",
    },
    {
        id: "visaExpiryDate",
        title: "Visa Expiry",
        type: "date",
    },
    {
        id: "nationality",
        title: "Nationality",
    },
    {
        id: "preference",
        title: "Preference",
        type: "title",
    },
    {
        id: "performanceRating",
        title: "Performance Rating",
    },
    {
        id: "alternativeLocation",
        title: "Alternative Location",
        rowDataColumnId: "alternativeLocationId",
    },
    {
        id: "candidateType",
        title: "Candidate Type",
        rowDataColumnId: "candidateTypeId",
    },
    {
        id: "candidateStatus",
        title: "Candidate Status",
        rowDataColumnId: "candidateStatusId",
    },
    {
        id: "agency",
        title: "Agency",
        rowDataColumnId: "agencyId",
    },
    {
        id: "defaultProject",
        title: "Default Project",
        rowDataColumnId: "defaultProjectId",
    },
    {
        id: "adsReference",
        title: "External Reference No",
    },
    {
        id: "mainAddress",
        title: "Main Address",
        type: "title",
    },
    {
        id: "mainAddressLine1",
        title: "Main Address Line 1",
    },
    {
        id: "mainAddressLine2",
        title: "Main Address Line 2",
    },
    {
        id: "mainAddressCountry",
        title: "Country",
        rowDataColumnId: "mainAddressCountryId",
    },
    {
        id: "mainAddressState",
        title: "State",
        rowDataColumnId: "mainAddressStateId",
    },
    {
        id: "mainAddressCity",
        title: "City",
    },
    {
        id: "mainAddressPostcode",
        title: "Postcode",
    },
    {
        id: "mailingAddressSameAsMain",
        title: "Mailing Address Same as Main",
    },
    {
        id: "mailingAddress",
        title: "Mailing Address",
        type: "title",
    },
    {
        id: "mailingAddressLine1",
        title: "Mailing Address Line 1",
    },
    {
        id: "mailingAddressLine2",
        title: "Mailing Address Line 2",
    },
    {
        id: "mailingAddressCountry",
        title: "Country",
        rowDataColumnId: "mailingAddressCountryId",
    },
    {
        id: "mailingAddressState",
        title: "State",
        rowDataColumnId: "mailingAddressStateId",
    },
    {
        id: "mailingAddressCity",
        title: "City",
    },
    {
        id: "mailingAddressPostcode",
        title: "Postcode",
    },
    {
        id: "emergencyContact",
        title: "Emergency Contact",
        type: "title",
    },
    {
        id: "emergencyContactName",
        title: "Emergency Contact Name",
    },
    {
        id: "emergencyContactPhone",
        title: "Emergency Contact Phone",
    },
    {
        id: "relationshipToCandidate",
        title: "Relationship",
        rowDataColumnId: "relationshipToCandidateId",
    },
];