import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";
import _ from 'lodash';
import Checkbox from './Checkbox';

function CheckGroup(props) {

    const {
        items,
        name,
        idField,
        valueField,
        handleChange,
        colWidth,
        inline,
        selected = [],
        flags,
        disabled,
        className,
    } = props;

    const wrapperClasses = classNames(
        { 'custom-control-inline': inline },
        { [`col-${colWidth}`]: inline && !!colWidth },
        'custom-control', 
        'custom-checkbox',
        { 'mr-0': inline && !!colWidth }
    )

    const predicate = (value, field) => _.isArray(selected) 
        ? _.every(selected, _.isNumber)
            ? (item) => item === value
            : (item) => item[field] === value
        : null;

    return (
        <div>
            {[...flags ? Object.keys(items) : items].map((lookupItem, index) => {

                const text = flags ? lookupItem : lookupItem[valueField];
                const value = flags ? items[lookupItem] : lookupItem[idField];
                const checked = flags ? (selected & items[lookupItem]) === items[lookupItem] : _.some(selected, predicate(lookupItem[idField], idField));
                const inputProps = {
                    wrapperClasses,
                    index,
                    text,
                    id: `${name}_${text}`,
                    name,
                    value,
                    checked,
                    onChange: !disabled ? handleChange : undefined,
                    disabled,
                    className,
                }
                return (<Checkbox {...inputProps} key={index} />)
            })}
        </div>
    )
}

CheckGroup.propTypes = {
    items: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
    idField: PropTypes.string,
    valueField: PropTypes.string,
    handleChange: PropTypes.func,
    colWidth: PropTypes.number,
    inline: PropTypes.bool,
    selected: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    flags: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
}

CheckGroup.defaultProps = {
    idField: "lookupId",
    valueField: "lookupValue",
    colWidth: 2,
    inline: true,
    flags: false,
    disabled: false,
    className: "",
}

export default CheckGroup;