import React from 'react';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

function DefaultColumnFilter({ 
    column: { 
        filterValue, 
        preFilteredRows, 
        setFilter 
    }, 
}) {
  
    return (
        <InputGroup>
            <input 
                className="input-filter form-control"
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                }}
                placeholder={`Search`}
                style={{height: 38}}
             />
            {filterValue && <InputGroupAddon addonType="append" onClick={() => setFilter(undefined)}>
                <InputGroupText>
                    <i className="fas fa-times "></i>
                </InputGroupText>
            </InputGroupAddon>}
        </InputGroup>
        // <input
        //     className="input-filter"
        //     value={filterValue || ''}
        //     onChange={e => {
        //         setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        //     }}
        //     placeholder={`Search`}
        // />
    )
}

export default DefaultColumnFilter;