import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Input, Row } from "reactstrap";
import { TooltipButton } from "../../../../inputs";
import Loader from "../../../../loaders";
import api from "../../../../../services/api";
import TemplateDialog from "./TemplateDialog";
import qs from "qs";

const messageMasterTitle = "Supervisor Feedback";

function MessageDialog(props) {
    const {
        title,
        dispatchUnitId,
        selectedSupervisors,
        onClose,
        onBackClick,
        onNextClick,
    } = props;

    const [ loading, setLoading ] = useState(false);
    const [ messagesMasterId, setMessagesMasterId ] = useState(null);
    const [ messageTemplate, setMessageTemplate ] = useState(null);

    const loadData = useCallback(() => {
        setLoading(true);

        api.get(`/messagesmaster?${qs.stringify({ filters: [{ id: "title", value: messageMasterTitle }] }, { allowDots: true })}`)
            .then((response) => {
                // console.log({response});
                setMessagesMasterId(response.data.data[0]?.messagesMasterId ?? null);
                setMessageTemplate(response.data.data[0]?.message ?? null);
            })
            .catch((error) => console.log({error}))
            .finally(() => setLoading(false))
        ;
    }, [api, dispatchUnitId]);

    // useEffect(() => console.log({data}), [data]);

    useEffect(() => {
        loadData();
    }, [dispatchUnitId]);

    const content = useMemo(() => (
        <>
            <Row className="m-0 p-0 mt-2">
                <span className="font-size-small-custom">
                    <b>
                    {
                        selectedSupervisors.map(s => `${s.candidateName} (${s.mobileNumber})`).join(", ")
                    }
                    </b>
                </span>
            </Row>
            <Row className="m-0 p-0 mt-2">
                <span className="font-size-small-custom">Message Master Title: <b>{messageMasterTitle}</b></span>
            </Row>
            <Row className="m-0 p-0 mt-2">
                <Input
                    type="textarea"
                    value={messageTemplate ?? ""}
                    onChange={(e) => {
                        setMessageTemplate(e.target.value);
                    }}
                    rows={10}
                />
            </Row>
        </>
    ), [selectedSupervisors, messageTemplate]);

    const footer = useMemo(() => (
        <>
            <Button
                color="secondary"
                onClick={onClose}
                type="button"
            >
                Close
            </Button>
            <Button
                color="secondary"
                onClick={onBackClick}
                type="button"
            >
                <i className="fas fa-chevron-left mr-2" />Back: Supervisor Details
            </Button>
            <Button
                color="info"
                onClick={() => onNextClick(messagesMasterId, messageTemplate)}
                type="button"
                disabled={messageTemplate === null}
            >
                Finish and Send SMS
            </Button>
        </>
    ), [onClose, onBackClick, onNextClick, messagesMasterId, messageTemplate]);

    return (
        <TemplateDialog
            title={title}
            loading={loading}
            content={content}
            footer={footer}
            onClose={onClose}
        />
    );
};

export default MessageDialog;
