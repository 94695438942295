const AccommodationValidation = [
    {
        field: "accommodationName",
        tests: [
            {
                type: "required",
                message: "Name is required.",
                validate: (obj) => obj.accommodationName ? true : false,
            }
        ]
    },
    {
        field: "accommodationAddress",
        tests: [
            {
                type: "required",
                message: "Address is required.",
                validate: (obj) => obj.accommodationAddress ? true : false,
            }
        ]
    },
];
export {
    AccommodationValidation
};