/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import api from "../../../../services/api";
import {
    Label, FormGroup, Button, Modal, Container, Row, Col, Alert
} from "reactstrap";
import { ComboBox, DropdownBox } from "../../../dropdowns";
import Loader from "../../../loaders";
import { PositionsTable, MilestonesTable, JobOrderMilestonesTable, MoveDir } from './Tables';
import _ from "lodash";
import { jobOrderValidation } from "./validation";
import { TooltipButton } from '../../../inputs';
import qs from "qs";
import { useHistory } from "react-router-dom";
import { DefaultColumnFilter } from "../../../react-table/filters";
import { useTable, useFilters, useSortBy, useFlexLayout } from 'react-table';
import Grid from "../../../Grid";
import moment from 'moment';
import LRFDetailList from './LRFDetailList';

function CreateJobOrder(props) {

    const [itemInEdit, setItemInEdit] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [recruitmentRequests, setRecruitmentRequests] = React.useState([]);
    const [requestDetails, setRequestDetails] = React.useState([]);
    const [staticMilestones, setStaticMilestones] = useState([]);
    const [milestones, setMilestones] = React.useState([]);
    const [selectedMilestones, setSelectedMilestones] = React.useState([]);
    const [jobOrderMilestones, setJobOrderMilestones] = React.useState([]);
    const [selectedRequestDetails, setSelectedRequestDetails] = React.useState([]);
    const [selectedRecruitmentRequestDetailed, setSelectedRecruitmentRequestDetailed] = React.useState(null);
    const [recruitmentPeopleEndpoint, setRecruitmentPeopleEndpoint] = React.useState("/recruitmentperson/all");
    const [milestoneTemplates, setMilestoneTemplates] = React.useState([]);
    const [selectedTemplate, setSelectedTemplate] = React.useState({});
    const [validationAlert, setValidationAlert] = React.useState([]);
    const [milestoneTemplateAlert, setMilestoneTemplateAlert] = React.useState([]);
    const [recruitmentPeople, setRecruitmentPeople] = React.useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [generateMilestoneDetails, setGenerateMilestoneDetails] = useState(false);
    const [recruitmentRequestShowId, setRecruitmentRequestShowId] = useState(null);

    const [lrfSearch, setLrfSrearch] = React.useState(false);
    const [selectedLrf, setSelectedLrf] = React.useState(null);
    const [data, setData] = React.useState([]);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [filters, setFilters] = React.useState([]);
    const history = useHistory();

    const defaultSort = React.useMemo(() => [{ id: "department", desc: false }],
        []
    );

    const [sortBy, setSortBy] = React.useState(defaultSort);

    // const getUsers = async (filter) => {

    //     if (!msalInstance.getAccount) {
    //         msalInstance.getAccount = () => msalInstance.getActiveAccount()
    //     }

    //     const options = new MSALAuthenticationProviderOptions(["user.read"]);
    //     const authProvider = new ImplicitMSALAuthenticationProvider(msalInstance, options);

    //     const graph = Client.initWithMiddleware({
    //         authProvider
    //     });

    //     const user = await graph
    //     .api('/me')
    //     .select(["displayName", "userPrincipalName", "department", "mail"])
    //     .get();

    //     console.log("ME: ", user);
    // }

    React.useEffect(() => {
        setLoading(true);

        let apiCalls = [
            api.get("/lookup/rrncf"),
            api.get("/lookup/jobordermilestonemaster"),
            api.get("/lookup/milestonetemplate"),
            api.get(`/lookup/recruitmentpeople`),
        ];

        Promise.all(apiCalls)
            .then((response) => {
                setRecruitmentRequests(response[0].data);
                setStaticMilestones(response[1].data);
                setMilestones(response[1].data);
                setMilestoneTemplates(response[2].data);
                setRecruitmentPeople(response[3].data);
            })
            .catch((error) => console.error("error: ", error))
            .finally(() => setLoading(false));

    }, []);

    useEffect(() => {
        if (!selectedRecruitmentRequestDetailed || selectedRecruitmentRequestDetailed.isConfidential != true) {
            setRecruitmentPeopleEndpoint("/recruitmentperson/all");
            setItemInEdit({ ...itemInEdit, recruitmentPerson: null, recruitmentPersonId: null });
        }
        else {
            setRecruitmentPeopleEndpoint("/recruitmentperson/all-cf");
            setItemInEdit({ ...itemInEdit, recruitmentPerson: null, recruitmentPersonId: null });
        }
    }, [selectedRecruitmentRequestDetailed]);

    React.useEffect(() => {
        if (!itemInEdit?.recruitmentRequestId) {
            setSelectedProject(null);
            setSelectedRecruitmentRequestDetailed(null);
            return;
        }

        setLoading(true);
        const apiCalls = [
            api.get(`/lookup/requestdetail/${itemInEdit.recruitmentRequestId}`),
            api.get(`/recruitmentrequest/project/${itemInEdit.recruitmentRequestId}`),
            api.get(`/recruitmentrequest/${itemInEdit.recruitmentRequestId}`),
        ];

        Promise.all(apiCalls)
            .then((responses) => {
                setLoading(false);
                setRequestDetails(responses[0].data);
                setSelectedProject(responses[1].data);
                setSelectedRecruitmentRequestDetailed(responses[2].data);
            })
            .catch((error) => {
                console.error("error: ", error);
                setLoading(false);
            })
            ;
    }, [itemInEdit?.recruitmentRequestId]);

    useEffect(() => {
        if (!selectedProject?.milestoneTemplate) {
            setSelectedTemplate({});
            setJobOrderMilestones([]);
            setMilestones([...staticMilestones]);
            return;
        }

        setSelectedTemplate({ ...selectedProject.milestoneTemplate });
        setGenerateMilestoneDetails(true);
    }, [selectedProject]);

    const handleAddTemplate = React.useCallback(() => {
        setMilestoneTemplateAlert([]);

        if (!selectedTemplate || !selectedTemplate.lookupId) {
            setMilestoneTemplateAlert([{ key: "milestoneTemplate", type: "required", message: "Milestone Template has not been selected." }]);
            return;
        }

        if (selectedTemplate && selectedTemplate.lookupId > 0) {
            setLoading(true)
            const apiCalls = [
                api.get(`/milestonetemplate/${selectedTemplate.lookupId}`),
            ];

            Promise.all(apiCalls)
                .then((response) => {
                    const { milestoneTemplateDetails } = response[0].data;

                    if (!milestoneTemplateDetails.length) {
                        setMilestoneTemplateAlert([{ key: "milestoneTemplate", type: "empty", message: `Template ${selectedTemplate.lookupValue} does not have any milestones.` }]);
                        return;
                    }

                    const selectedMilestoneIds = milestoneTemplateDetails.map(o => o.lookupId);
                    const updatedMilestones = _.values(_.chain(milestones).keyBy("lookupId").omit(selectedMilestoneIds).value());

                    setMilestones(_.orderBy(updatedMilestones, o => o.lookupValue));
                    setJobOrderMilestones(_.orderBy(milestoneTemplateDetails, o => o.order));
                })
                .catch((error) => console.error("error: ", error))
                .finally(() => setLoading(false));
        }
    }, [selectedTemplate, milestones]);

    useEffect(() => {
        if (!generateMilestoneDetails)
            return;

        setGenerateMilestoneDetails(false);
        handleAddTemplate();
    }, [generateMilestoneDetails]);

    useEffect(() => {
        if ((selectedTemplate || milestones?.length) && milestoneTemplateAlert?.length)
            setMilestoneTemplateAlert([]);
    }, [selectedTemplate, milestones]);

    const validate = React.useCallback(() => {
        console.log("validating...");
        const jobOrder = {
            ...itemInEdit,
            positions: [...selectedRequestDetails],
            milestones: [...jobOrderMilestones]
        }
        let errors = [...validationAlert];
        _.forEach(jobOrderValidation, (value) => {
            _.forEach(value.tests, (test) => {
                const exists = _.some(errors, (item) => item.key === value.field && item.type === test.type);
                const result = test.validate(jobOrder);
                if (result && !exists) {
                    errors.push({ key: value.field, type: test.type, message: test.message })
                } else if (!result && exists) {
                    _.remove(errors, (item) => item.key === value.field && item.type === test.type);
                }
            })
        });
        setValidationAlert([...errors]);
        if (errors.length > 0) {
            return false;
        }
        return true;
    }, [itemInEdit, selectedRequestDetails, jobOrderMilestones]);

    React.useEffect(() => {
        // console.log("check valid");
        if (validationAlert.length > 0) validate();
    }, [itemInEdit, selectedRequestDetails, jobOrderMilestones]);

    const handleOpenLrfSearch = () => {
        setLrfSrearch(true)
    }

    const handleAddSelected = () => {
        const length = jobOrderMilestones.length;
        setJobOrderMilestones(_.concat(jobOrderMilestones, selectedMilestones.map((o, i) => ({ ...o, order: (length + i + 1) }))));

        const selectedMilestoneIds = selectedMilestones.map(o => o.lookupId);
        const updatedMilestones = _.values(_.chain(milestones).keyBy("lookupId").omit(selectedMilestoneIds).value());

        setMilestones(_.orderBy(updatedMilestones, o => o.lookupValue));
        setSelectedMilestones([]);
    }

    const handleAdd = (item) => {
        const length = jobOrderMilestones.length;
        setJobOrderMilestones([...jobOrderMilestones, { ...item, order: length + 1 }]);
        setMilestones(milestones.filter(f => f.lookupId !== item.lookupId));
        setSelectedMilestones([]);
    }

    const handleRemoveSelected = () => {
        const selectedMilestoneIds = selectedMilestones.map(o => o.lookupId);
        const updatedJobOrderMilestones = _.values(_.chain(jobOrderMilestones).keyBy("lookupId").omit(selectedMilestoneIds).value());
        setJobOrderMilestones(_.orderBy(updatedJobOrderMilestones, o => o.order).map((o, i) => ({ ...o, order: i + 1 })));

        setMilestones(_.orderBy(_.concat(milestones, selectedMilestones), o => o.lookupValue));
        setSelectedMilestones([]);
    }

    const handleRemove = (item) => {
        setMilestones(_.orderBy([...milestones, { ...item }], o => o.lookupValue));
        setJobOrderMilestones(jobOrderMilestones.filter(f => f.lookupId !== item.lookupId).map((o, i) => ({ ...o, order: i + 1 })));
        setSelectedMilestones([]);
    }

    const splitJoms = (item, dir) => {
        const upperList = _.slice(jobOrderMilestones, 0, item.order - dir);
        const lowerList = _.slice(jobOrderMilestones, item.order - dir);
        return {
            upperList,
            lowerList,
            swapItems: lowerList.splice(0, 2)
        }
    }

    const handleMoveUp = (item) => {
        const { upperList, lowerList, swapItems } = splitJoms(item, MoveDir.UP);

        const downItem = swapItems[0];
        const upItem = swapItems[1];

        setJobOrderMilestones([...upperList, { ...upItem, order: upItem.order - 1 }, { ...downItem, order: downItem.order + 1 }, ...lowerList]);
    }

    const handleMoveDown = (item) => {
        const { upperList, lowerList, swapItems } = splitJoms(item, MoveDir.DOWN);

        const upItem = swapItems[0];
        const downItem = swapItems[1];

        console.log({
            upperList, lowerList, swapItems, upItem, downItem
        })

        setJobOrderMilestones([...upperList, { ...downItem, order: downItem.order - 1 }, { ...upItem, order: upItem.order + 1 }, ...lowerList]);
    }

    const handleCreate = () => {

        setLoading(true);

        if (!validate()) {
            setLoading(false);
            return;
        }

        const payload = {
            requestDetailIds: selectedRequestDetails.map(o => o.lookupId),
            recruitmentPersonId: itemInEdit.recruitmentPersonId,
            recruitmentCoordinator: { ...itemInEdit.recruitmentCoordinator },
            recruitmentCoordinatorId: itemInEdit.recruitmentCoordinatorId,
            jobOrderMilestones: jobOrderMilestones.map(o => ({
                jobOrderMilestoneMasterId: o.lookupId,
                orderingIndex: o.order
            }))
        };
        //console.log('payload', payload);
        //setLoading(false);
        //return;

        api.post('/joborder/create', JSON.stringify(payload))
            .then((response) => {
                setLoading(false);
                props.onSaved();
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }

    //LRF
    const skipPageResetRef = React.useRef();

    const loadData = React.useCallback(() => {
        const queryString = qs.stringify({ filters, sortBy }, { allowDots: true });
        setLoading(true);
        setData([]);

        skipPageResetRef.current = true;

        api.get(`/recruitmentrequest/approved/${(pageNumber - 1) * pageSize}/${pageSize}${queryString ? `?${queryString}` : ""}`)
            .then(({ data }) => {
                setData(data.data);
                setTotalRecords(data.total);
            }).catch((error) => {
                console.error("error: ", { error });
            }).finally(() => setLoading(false));
    }, [filters, sortBy, pageNumber, pageSize]);

    React.useEffect(() => loadData(), [pageNumber, pageSize, filters, sortBy]);

    const filterTypes = React.useMemo(() => ({
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
            })
        },
    }), []);

    const columns = React.useMemo(() => {
        return [
            {
                Header: "Action",
                id: 'button',
                Cell: ({ row: { original } }) => {
                    return (
                        <div>
                            <TooltipButton
                                id={`edit_${original.recruitmentRequestId}`}
                                title="Edit"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={(event) => {
                                    // console.log('select 1', original)
                                    setItemInEdit({
                                        ...itemInEdit,
                                        recruitmentRequest: {
                                            lookupId: original.recruitmentRequestId,
                                            lookupValue: `${original.recruitmentRequestNumber} - ${original.project ? original.project.lookupValue : ''} - ${original.location ? original.location.lookupValue : ''}`
                                        },
                                        recruitmentRequestId: original.recruitmentRequestId
                                    });
                                    setLrfSrearch(false)
                                }}
                            >
                                <i className="fas fa-check pt-1"></i>
                            </TooltipButton>
                        </div>
                    )
                },
                width: 81,
            },
            {
                Header: "LRF Number",
                id: "recruitmentRequestNumber",
                accessor: "recruitmentRequestNumber",
                width: 100,
                disableSortBy: false,
            },
            {
                Header: "Created Date",
                id: "createdDate",
                accessor: (row) => moment(row.createdDate).format('DD.MM.YYYY'),
                width: 90,
                disableSortBy: false,
            },
            {
                Header: "Department",
                id: "department",
                accessor: (row) => row.department?.lookupValue,
                width: 150,
                disableSortBy: false,
            },
            {
                Header: "Location",
                id: "location",
                accessor: (row) => row.location?.lookupValue,
                width: 100,
                disableSortBy: false,
            },
            {
                Header: "Project",
                id: "project",
                accessor: (row) => row.project?.lookupValue,
                width: 100,
                disableSortBy: false,
            },
            {
                Header: "Dispatch Unit",
                id: "dispatchUnit",
                accessor: (row) => row.roster?.dispatchUnit.dispatchUnitName,
                width: 100,
                disableSortBy: false,
            },
            {
                Header: "Roster",
                id: "roster",
                accessor: (row) => row.roster?.rosterName,
                width: 100,
                disableSortBy: false,
            },
            {
                Header: "Type",
                id: "type",
                accessor: (row) => row.recruitmentType?.lookupValue,
                width: 100,
                disableSortBy: false,
            },
            {
                Header: "Status",
                id: "status",
                accessor: (row) => row.status?.lookupValue,
                width: 100,
                disableSortBy: false,
            },
            {
                Header: "Approver Name",
                id: "approverName",
                accessor: "finalApprovalBy",
                width: 120,
                disableSortBy: false,
            },
            {
                Header: "Created By",
                id: "createdBy",
                accessor: "createdBy",
                width: 120,
                disableSortBy: false,
            },
            {
                Header: "Positions",
                id: "positions",
                accessor: (row) => {
                    // console.log('psition', row);
                    return row.details?.map(m => (m.joExists ? <><a href={`/joborder/${m.requestDetailId}/joborder`} target='_blank'>{m.hrispositionMaster?.hrispositionMasterName} ({m.amount})</a><br /></> : <><span>{m.hrispositionMaster?.hrispositionMasterName} ({m.amount})</span><br /></>))
                },
                width: 300,
                disableSortBy: true,
            }
        ]
    }, []);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: defaultSort,
                canSort: true,
            },
            filterTypes,
            manualSortBy: true,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout,
        useFilters,
        useSortBy
    );

    React.useEffect(() => {
        setFilters(state.filters);
    }, [state.filters]);

    React.useEffect(() => {
        setSortBy(state.sortBy);
    }, [state.sortBy]);

    return (
        <Modal
            isOpen={true}
            fullscreen="true"
            className="modal-fullscreen modal-fullscreen-xl"
        // modalClassName="db-example-modal-xl"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Job Order Editor
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body pb-1"
            >
                {loading && <Loader />}
                <Container fluid>
                    {((validationAlert && validationAlert.length > 0) || (milestoneTemplateAlert && milestoneTemplateAlert.length > 0)) && <Alert color="warning" className="pa-0 d-flex justify-content-between">
                        <span className="alert-text">
                            <ul className="mb-0">
                                {
                                    validationAlert.map((item, index) => <li key={index}>{item.message}</li>)
                                }
                                {
                                    milestoneTemplateAlert.map((item, index) => <li key={index}>{item.message}</li>)
                                }
                            </ul>
                        </span>
                    </Alert>}
                    <Row>
                        <Col xs={4}>
                            <Row className="m-0 p-0">
                                <Col xs={12} className="m-0 p-0">
                                    <FormGroup className="w-100">
                                        <Label>
                                            Recruitment Request
                                            <span className="text-danger">*</span>
                                            <span
                                                className={`ml-2${itemInEdit?.recruitmentRequestId ? " cursor-pointer" : " text-muted"}`}
                                                onClick={() => {
                                                    if (!itemInEdit?.recruitmentRequestId)
                                                        return;

                                                    setRecruitmentRequestShowId(itemInEdit.recruitmentRequestId);
                                                }}
                                            >
                                                <b><i className="fas fa-info-circle"></i></b>
                                            </span>
                                        </Label>
                                        <Row>
                                            <Col xs={10} className="m-0">
                                                <ComboBox
                                                    minLength={0}
                                                    endpoint="/recruitmentrequest"
                                                    selectedItem={itemInEdit?.recruitmentRequest}
                                                    onChange={(item) => {
                                                        setItemInEdit({ ...itemInEdit, recruitmentRequest: item, recruitmentRequestId: item ? item.lookupId : null });
                                                    }}
                                                />
                                                {/* <DropdownBox 
                                                    data={recruitmentRequests}
                                                    selectedItem={itemInEdit.recruitmentRequest}
                                                    onChange={(item) => {
                                                        // console.log('select 2', item)
                                                        setItemInEdit( { ...itemInEdit, recruitmentRequest: item, recruitmentRequestId: item ? item.lookupId : null } );
                                                    }}
                                                /> */}
                                            </Col>
                                            <Col xs={2} className="m-0 p-0">
                                                <TooltipButton
                                                    id="searchlrf"
                                                    title="Open LRF Search"
                                                    className="btn-icon"
                                                    color="default"
                                                    size="sm"
                                                    type="button"
                                                    onClick={handleOpenLrfSearch}
                                                >
                                                    <i className="fas fa-search pt-1"></i>
                                                </TooltipButton>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4} className="border-right">
                            {
                                selectedRecruitmentRequestDetailed &&
                                <>
                                    <Row className="m-0 p-0">
                                        <Col xs={12} className="m-0 p-0">
                                            <FormGroup className="w-100">
                                                <Label>
                                                    Recruitment Coordinator
                                                </Label>
                                                <ComboBox
                                                    className="mr-2"
                                                    endpoint="/lookup/users"
                                                    isLookup={false}
                                                    minLength={1}
                                                    idField="email"
                                                    valueField="nameAndEmail"
                                                    selectedItem={itemInEdit?.recruitmentCoordinator}
                                                    onChange={(selectedItem) => {
                                                        if (!selectedItem) {
                                                            setItemInEdit({ ...itemInEdit, recruitmentCoordinator: null, recruitmentCoordinatorId: null });
                                                            return;
                                                        }

                                                        setItemInEdit({
                                                            ...itemInEdit,
                                                            recruitmentCoordinator: selectedItem,
                                                            recruitmentCoordinatorId: selectedItem?.userProfileId ? selectedItem?.userProfileId : null
                                                        });

                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="m-0 p-0">
                                        <Col xs={12} className="m-0 p-0">
                                            <FormGroup className="w-100">
                                                <Label>
                                                    Recruitment Person
                                                </Label>
                                                <ComboBox
                                                    minLength={0}
                                                    endpoint={recruitmentPeopleEndpoint}
                                                    selectedItem={itemInEdit?.recruitmentPerson}
                                                    isLookup={false}
                                                    idField="recruitmentPersonId"
                                                    valueField="nameAndEmail"
                                                    onChange={(item) => {
                                                        setItemInEdit({
                                                            ...itemInEdit,
                                                            recruitmentPersonId: item ? item.recruitmentPersonId : null,
                                                            recruitmentPerson: !item ? null : {
                                                                ...item,
                                                                lookupId: item.recruitmentPersonId,
                                                                lookupValue: item.nameAndEmail
                                                            }
                                                        });
                                                    }}
                                                />
                                                {/* 
                                                <DropdownBox 
                                                data={recruitmentPeople}
                                                selectedItem={itemInEdit.recruitmentPerson}
                                                onChange={(item) => {
                                                    setItemInEdit( { ...itemInEdit, recruitmentPerson: item, recruitmentPersonId: item ? item.lookupId : null } );
                                                }}
                                                /> 
                                            */}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </>
                            }
                            <Row className="m-0 p-0">
                                <Col xs={12} className="m-0 p-0">
                                    <PositionsTable
                                        data={requestDetails}
                                        onSelect={setSelectedRequestDetails}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={4}>
                            <MilestonesTable
                                data={milestones}
                                onSelect={setSelectedMilestones}
                                onAdd={handleAdd}
                            />
                        </Col>
                        <Col xs={4}>
                            <Row>
                                <Col xs={1} className="px-0">
                                </Col>
                                <Col xs={11}>
                                    <FormGroup className="w-100">
                                        <Label>
                                            Add Milestones from Template:
                                        </Label>
                                        <Row>
                                            <Col xs={11} className="m-0">
                                                <DropdownBox
                                                    data={milestoneTemplates}
                                                    selectedItem={selectedTemplate}
                                                    onChange={setSelectedTemplate}
                                                />
                                            </Col>
                                            <Col xs={1} className="m-0 p-0">
                                                <TooltipButton
                                                    id="addmilestones"
                                                    title="Add Milestones from Template"
                                                    className="btn-icon"
                                                    color="default"
                                                    size="sm"
                                                    type="button"
                                                    onClick={handleAddTemplate}
                                                >
                                                    <i className="fas fa-plus pt-1"></i>
                                                </TooltipButton>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={1} className="pt-5 px-0">
                                    <div style={{ marginLeft: "-3px" }}>
                                        <TooltipButton
                                            id="addselected"
                                            title="Add Selected"
                                            className="btn-icon mt-5 mb-3 py-2 px-3"
                                            color="default"
                                            type="button"
                                            outline
                                            onClick={handleAddSelected}
                                        >
                                            <i className="fas fa-chevron-right"></i>
                                        </TooltipButton>
                                    </div>
                                    <div style={{ marginLeft: "-3px" }}>
                                        <TooltipButton
                                            id="removeselected"
                                            title="Remove Selected"
                                            className="btn-icon py-2 px-3"
                                            color="default"
                                            type="button"
                                            outline
                                            onClick={handleRemoveSelected}
                                        >
                                            <i className="fas fa-chevron-left"></i>
                                        </TooltipButton>
                                    </div>
                                </Col>
                                <Col xs={11}>
                                    <JobOrderMilestonesTable
                                        data={jobOrderMilestones}
                                        onSelect={setSelectedMilestones}
                                        onRemove={handleRemove}
                                        onMoveUp={handleMoveUp}
                                        onMoveDown={handleMoveDown}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={props.onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={handleCreate}
                    type="button"
                >
                    Create
                </Button>
            </div>

        {
            lrfSearch && <Modal
                isOpen={true}
                className="modal-xl"
                modalClassName="db-example-modal-xl"
            >
                <div
                    className="modal-header"
                >
                    <h5
                        className="modal-title"
                    >
                        Search LRF
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        onClick={() => setLrfSrearch(false)}
                        type="button"
                    >
                        <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                    </button>
                </div>
                <div
                    className="modal-body pb-1"
                >
                    <Grid
                        height={'700px'}
                        totalRecords={totalRecords}
                        pageSize={pageSize}
                        rows={rows}
                        tableProps={getTableProps()}
                        headerGroups={headerGroups}
                        tableBodyProps={getTableBodyProps()}
                        prepareRow={prepareRow}
                        needPaging={false}

                    />
                </div>
            </Modal>
        }

        {
            recruitmentRequestShowId ?
            <LRFDetailList
                onClose={() => setRecruitmentRequestShowId(null)}
                recruitmentRequestId={recruitmentRequestShowId}
                isModalView={true}
            />
            :
            null
        }
        </Modal>
    )
}

export default CreateJobOrder;