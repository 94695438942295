import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { Label, FormGroup, Row, Col, Collapse } from "reactstrap";
import { ComboBox, DropdownBox } from "../../../dropdowns";
import { DatePicker } from "../../../date-pickers";
import { formatDate } from "../../../../utils";
import moment from "moment";
import { useTable, useFlexLayout, useFilters, useSortBy } from 'react-table';
import { DateColumnFilter, DefaultColumnFilter } from "../../../react-table/filters";
import api from "../../../../services/api";
import { useDropzone } from 'react-dropzone';
import cn from "classnames";
import fileDownload from "js-file-download";
import _ from "lodash";
import { TooltipButton } from '../../../inputs';
import Grid from "../../../Grid";
import { Mode } from '../../../../utils';
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from '../../../loaders';
import qs from "qs";

export default function Skills(props) {
    const {isPage} = props;

    const [acheivedDateError, setAcheivedDateError] = useState(false);
    const [drawerMode, setDrawerMode] = useState(Mode.NONE);
    const [downloadDrawerMode, setDownloadDrawerMode] = useState(Mode.NONE);
    const [noDownloadDrawerMode, setNoDownloadDrawerMode] = useState(Mode.NONE);
    const [candidateSkill, setCandidateSkill] = useState(null);
    const [acceptedAttachments, setAcceptedAttachments] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [trainingMaster, setTrainingMaster] = useState(null);
    const [trainingMasterSkills, setTrainingMasterSkills] = useState([]);
    const [selectedSkillToDownload, setSelectedSkillToDownload] = useState(null);
    const [downloadCandidate, setDownloadCandidate] = useState(null);
    const [urlCandidateDocuments, setUrlCandidateDocuments] = useState(false);
    const [candidateId, setCandidateId] = useState(0);
    const [validationMessage, setValidationMessage] = useState(null);
    const [invalidTrainingMaster, setInvalidTrainingMaster] = useState(null);
    const [invalidSkillName, setInvalidSkillName] = useState(null);
    const [filters, setFilters] = useState([]);
    const [data, setData] = useState([]);
    const skipPageResetRef = useRef();

    const {
        itemInEdit,
        setItemInEdit
    } = props;

    const defaultColumn = useMemo(
        () => ({
            minWidth: 30,
            width: 150,
            maxWidth: 200,
            disableSortBy: true,
        }), []);

    useEffect(() => {
        skipPageResetRef.current = true;
    }, []);

    const refreshData = useCallback(() => {
        if (!candidateId)
            return;

        // console.log({pageNumber, pageSize, candidateId});

        setLoading(true);

        const queryString = qs.stringify({ filters }, { allowDots: true });
        api.get(`candidate/${candidateId}/skills/${(pageNumber - 1)*pageSize}/${pageSize}${queryString ? `?${queryString}` : ""}`)
            .then((response) => {
                const {data: responseData} = response;
                // console.log({responseData});
                // const data = [...response.data];
                setTotalRecords(responseData.total);
                setData([...responseData.data]);
                // setItemInEdit({
                //     ...itemInEdit,
                //     candidateSkills: [...responseData.data]
                // });
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [candidateId, pageNumber, pageSize, filters]);

    useEffect(() => {
        if ((itemInEdit?.candidateId ?? 0) === candidateId)
            return;
        
        setCandidateId(itemInEdit?.candidateId ?? 0);
    }, [itemInEdit?.candidateId]);

    useEffect(() => {
        if (!candidateId)
            return;

        refreshData();
    }, [candidateId, pageNumber, pageSize, filters]);

    useEffect(() => {
        if (!downloadCandidate)
            return;

        downloadAttachmentByCandidateId();
    }, [downloadCandidate]);

    const downloadAttachmentByCandidateId = useCallback(() => {
        setLoading(true);

        if (downloadCandidate == null) {
            SweetAlert("Error", "Candidate not found", "error");
            return;
        }

        if (downloadCandidate.candidateId == null) {
            SweetAlert("Error", "Candidate id not found", "error");
            return;
        }

        const url = `candidate/skill-attachments/${downloadCandidate.candidateSkillId}`;
        // console.log("downloadCandidate:");
        // console.log(downloadCandidate);
        api.get(url, { responseType: 'json' })
            .then(json => {
                // console.log('json:');
                // console.log(json);
                setUrlCandidateDocuments(json.data);
            })
            .catch(error => {
                console.error(error);

                //SweetAlert("Error", "Failed to download document list", "error");
                return;
            })
            .finally(() => {
                setLoading(false);

            })
            ;

    }, [downloadCandidate]);

    const callbackTriggerCandidateIdDownload = useCallback(() => {

        // console.log("urlCandidateDocuments:");
        // console.log(urlCandidateDocuments);

        if (urlCandidateDocuments == null) {
            return;
        }

        setLoading(true);

        urlCandidateDocuments.map((oneDocument, i) => {

            const url = `candidate/skill/download/${oneDocument.candidateSkillAttachmentId}`;
            api.get(url, { responseType: 'blob' })
                .then(blob => {
                    // Async file download
                    setTimeout(function () { //Start the timer
                        fileDownload(blob.data, oneDocument.filename);
                    }.bind(this), 250);
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        })

    }, [urlCandidateDocuments]);

    useEffect(() => {
        if (!urlCandidateDocuments)
            return;

        callbackTriggerCandidateIdDownload();
    }, [urlCandidateDocuments]);

    useEffect(() => {
        if (!validationMessage)
            return;

        setTimeout(function () {
            var scrollObject = document.getElementById("validationMessage");
            scrollObject.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }.bind(this), 500);
    }, [validationMessage]);

    useEffect(() => {
        if (downloadDrawerMode == Mode.NONE)
            return;

        // Scroll to confirmation
        setTimeout(function () {
            var scrollObject = document.getElementById("downloadConfirm");
            scrollObject.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }.bind(this), 500);
    }, [downloadDrawerMode]);

    useEffect(() => {

        if (noDownloadDrawerMode == Mode.NONE)
            return;

        // Scroll to confirmation
        setTimeout(function () { //Start the timer
            var scrollObject = document.getElementById("noDownloadConfirm");
            scrollObject.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }.bind(this), 500);


    }, [noDownloadDrawerMode]);

    useEffect(() => {
        if (!candidateSkill?.trainingMasterId || trainingMaster)
            return;

        api.get(`/lookup/trainingmaster?trainingmasterid=${candidateSkill.trainingMasterId}`)
            .then((response) => {
                setTrainingMaster(response.data[0]);
            })
            .catch((error) => {
                console.error({error});
            })
    }, [candidateSkill]);

    useEffect(() => {
        if (!trainingMaster)
            return;

        if (!candidateSkill || candidateSkill.trainingMasterId !== trainingMaster.lookupId)
            setCandidateSkill({
                ...candidateSkill,
                trainingMasterSkill: null,
                trainingMasterSkillId: null,
                trainingMasterId: trainingMaster.lookupId,
                trainingMasterName: trainingMaster.lookupValue
            })

        api.get(`/lookup/trainingmasterskill?trainingmasterid=${trainingMaster.lookupId}`)
            .then((response) => {
                setTrainingMasterSkills(response.data);
            })
            .catch((error) => {
                console.error({error});
            })
    }, [trainingMaster]);

    useEffect(() => {
        if (drawerMode === Mode.NONE) {
            setTrainingMaster(null);
            return;
        }
        // Scroll to insert or update
        setTimeout(function () { //Start the timer
            var scrollObject = document.getElementById("skillInsertUpdateContainer");
            scrollObject.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }.bind(this), 500);
    }, [drawerMode]);

    const downloadMypassAttachment = useCallback((attainment) => {
        setLoading(true);

        api.post(`mypass/download/document/attainment`, JSON.stringify({personnelId: attainment.personnelId, personnelAttainmentId: attainment.personnelAttainmentId}))
            .then((response) => {
                console.log({response});
                const {data: mypassDocData} = response;

                if (!mypassDocData.length)
                    return;

                mypassDocData.forEach(d => {
                    if (!d.presignedUrl)
                        return;

                    window.open(d.presignedUrl, "_blank");
                });
            })
            .catch(error => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, []);
    
    const columns = useMemo(() => {
        return [
            {
                Header: "Type",
                id: "certificateType",
                accessor: (row) => row.certificateType,
                Filter: DefaultColumnFilter,
                filter: "text",
            },
            {
                Header: "Code",
                id: "certificateCode",
                accessor: (row) => row.certificateCode,
                Filter: DefaultColumnFilter,
                filter: "text",
            },
            {
                Header: "Name",
                id: "certificateName",
                accessor: (row) => row.certificateName,
                Filter: DefaultColumnFilter,
                filter: "text",
            },
            {
                Header: "Reference",
                id: "reference",
                accessor: (row) => row.reference,
                Filter: DefaultColumnFilter,
                filter: "text",
            },
            {
                Header: "Date Attained <=",
                id: "dateAttained",
                accessor: (row) => row.dateAttained ? moment(row.dateAttained).format("DD.MM.YYYY") : "N/A",
                Filter: (e) => DateColumnFilter({...e, dateFormat: "YYYY-MM-DD"}),
                filter: "text",
            },
            {
                Header: "Expiry Period >=",
                id: "expiryDate",
                accessor: (row) => row.expiryDate ? moment(row.expiryDate).format("DD.MM.YYYY") : "N/A",
                Filter: (e) => DateColumnFilter({...e, dateFormat: "YYYY-MM-DD"}),
                filter: "text",
            },
            {
                Header: "Action",
                id: 'actions',
                width: 180,
                canResize: false,
                disableFilters: true,
                Cell: ({ row: { original } }) => {
                    return (
                        <div className="td-action text-center">
                            <TooltipButton
                                id={`download_${original.personnelAttainmentId}`}
                                title="Download Attachment"
                                className="btn-icon"
                                color="default"
                                size="xsm"
                                type="button"
                                onClick={() => downloadMypassAttachment(original)}
                            >
                                <i className="fas fa-download pt-1"></i>
                            </TooltipButton>
                        </div>
                    );
                }
            }
        ]
    }, [downloadMypassAttachment]);

    const filterTypes = useMemo(
        () => ({
            text: (rows) => {
                return rows.filter(() => true)
            },
        }),
        []
    );

    const downloadAttachment = (fileInfo) => {

        if (fileInfo instanceof File) {
            fileDownload(fileInfo, fileInfo.name);
        } else {
            const url = `candidate/skill/download/${fileInfo.candidateSkillAttachmentId}`;
            api.get(url, { responseType: 'blob' })
                .then(blob => {
                    fileDownload(blob.data, fileInfo.filename);
                }).catch(error => {
                    console.error(error)
                })
        }
    }

    const onDrop = useCallback(acceptedFiles => setAcceptedAttachments([...acceptedAttachments].concat([...acceptedFiles])), [acceptedAttachments]);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ onDrop });

    const files = useMemo(() =>
        (candidateSkill?.candidateSkillAttachments || [])
            .map(
                attachment => (
                    <li key={attachment.filename}>
                        <a onClick={() => downloadAttachment(attachment)}>{attachment.filename} - {attachment.fileSizeInBytes} bytes</a>

                        <span className="float-right"
                            onClick={(event) => {
                                event.stopPropagation();
                                const index = candidateSkill.candidateSkillAttachments.findIndex(val => val.candidateSkillAttachmentId === attachment.candidateSkillAttachmentId);
                                const attachments = [...candidateSkill.candidateSkillAttachments]
                                attachments.splice(index, 1);
                                // console.log(attachment, candidateSkill.candidateSkillAttachments, index, attachments);
                                setCandidateSkill({ ...candidateSkill, candidateSkillAttachments: attachments });
                            }}
                        >
                            &#10005;
                        </span>
                    </li>
                )
            )
            .concat(acceptedAttachments.map(
                file => (
                    <li key={file.path}>
                        <a onClick={() => downloadAttachment(file)}>{file.path} - {file.size} bytes (new)</a>

                        <span className="float-right"
                            onClick={(event) => {
                                event.stopPropagation();
                                const index = acceptedAttachments.findIndex(val => (val.name === file.name && val.lastModified === file.lastModified && val.size === file.size));
                                const attachments = [...acceptedAttachments]
                                attachments.splice(index, 1);
                                // console.log(file, acceptedAttachments);
                                setAcceptedAttachments(attachments);
                            }}
                        >
                            &#10005;
                        </span>
                    </li>
                )
            )
            ), [candidateSkill?.candidateSkillAttachments, acceptedAttachments]);

    const dropzoneClasses = useMemo(() => cn({
        'dropzone': true,
        'mt-2': true,
        'm-3': true,
        'active': isDragActive,
        'accept': isDragAccept,
        'reject': isDragReject
    }), [
        isDragActive,
        isDragAccept,
        isDragReject
    ]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
    } = useTable(
        {
            columns,
            data,
            filterTypes,
            manualSortBy: true,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout,
        useFilters
    );

    useEffect(() => {
        setFilters(state.filters);
    }, [state.filters]);

    return (
        <>
            {loading && <Loader />}
            <Collapse
                id="validationMessage"
                isOpen={validationMessage}
                style={{ zIndex: "9999", border: "0.0625rem solid #dee2e6" }}
                className="bg-white w-100 rounded p-3 mb-2"
            >
                <Row>
                    <Col xs={12}>
                        {validationMessage}
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-end">
                        <TooltipButton
                            id="okValidate"
                            title="Ok"
                            className="btn-icon ml-2"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => {
                                setValidationMessage(null);
                            }}
                        >
                            OK
                        </TooltipButton>
                    </Col>
                </Row>
            </Collapse>
            <Collapse
                id="downloadConfirm"
                isOpen={downloadDrawerMode != Mode.NONE && selectedSkillToDownload}
                style={{ zIndex: "9999", border: "0.0625rem solid #dee2e6" }}
                className="bg-white w-100 rounded p-3 mb-2"
            >
                <Row>
                    <Col xs={12}>
                        You may download multiple files. Please confirm yes if you want to.
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-end">
                        <TooltipButton
                            id="okDownload"
                            title="Ok"
                            className="btn-icon ml-2"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => {
                                setDownloadDrawerMode(Mode.NONE);
                                setNoDownloadDrawerMode(Mode.NONE);
                                const download = JSON.parse(JSON.stringify(selectedSkillToDownload));
                                setDownloadCandidate({...download});
                                setSelectedSkillToDownload(null);
                            }}
                        >
                            OK
                        </TooltipButton>
                    </Col>
                </Row>
            </Collapse>
            <Collapse
                id="noDownloadConfirm"
                isOpen={noDownloadDrawerMode != Mode.NONE}
                style={{ zIndex: "9999", border: "0.0625rem solid #dee2e6" }}
                className="bg-white w-100 rounded p-3 mb-2"
            >
                <Row>
                    <Col xs={12}>
                        There is no data to download here.
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-end">
                        <TooltipButton
                            id="okNoDownload"
                            title="Ok"
                            className="btn-icon ml-2"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => {

                                setDownloadDrawerMode(Mode.NONE);
                                setNoDownloadDrawerMode(Mode.NONE);
                            }}
                        >
                            OK
                        </TooltipButton>
                    </Col>
                </Row>
            </Collapse>
            <Collapse
                id="skillInsertUpdateContainer"
                isOpen={drawerMode !== Mode.NONE}
                style={{ zIndex: "9999", border: "0.0625rem solid #dee2e6" }}
                className="bg-white w-100 rounded p-3 mb-2"
            >
                <Row>
                    <Col xs={12}>
                        <Row>
                            <Col xs={3}>
                                <FormGroup>
                                    <Label>
                                        Training Master
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <ComboBox
                                        endpoint="/trainingmaster"
                                        selectedItem={trainingMaster}
                                        onChange={(selectedItem) => setTrainingMaster(selectedItem)}
                                        minLength={0}
                                        required={true}
                                    />
                                    <Collapse id="invalidTrainingMaster" isOpen={(invalidTrainingMaster == null ? false : true)}><small className="text-danger">{invalidTrainingMaster}</small></Collapse>
                                </FormGroup>
                            </Col>
                            <Col xs={3}>
                                <FormGroup>
                                    <Label>
                                        Skill Name
                                        <span className="text-danger">*</span>
                                    </Label>
                                    {/* <ComboBox 
                                        endpoint={`/trainingmasterskill?trainingMasterId=${trainingMaster ? trainingMaster.lookupId : 0}`}
                                        selectedItem={candidateSkill?.trainingMasterSkill}
                                        onChange={(selectedItem) => setCandidateSkill({ 
                                            ...candidateSkill, 
                                            trainingMasterSkill: selectedItem, 
                                            trainingMasterSkillId: selectedItem?.lookupId 
                                        })}
                                    /> */}
                                    <DropdownBox
                                        data={trainingMasterSkills}
                                        disabled={!trainingMaster}
                                        isLookup={true}
                                        selectedItem={candidateSkill?.trainingMasterSkill}
                                        required={true}
                                        minLength={0}
                                        onChange={(selectedItem) => setCandidateSkill({
                                            ...candidateSkill,
                                            trainingMasterSkill: selectedItem,
                                            trainingMasterSkillId: selectedItem?.lookupId
                                        })}
                                    />
                                    <Collapse id="invalidSkillName" isOpen={(invalidSkillName == null ? false : true)}><small className="text-danger">{invalidSkillName}</small></Collapse>
                                </FormGroup>
                            </Col>
                            <Col xs={2}>
                                <FormGroup>
                                    <Label>
                                        Achieved Date
                                    </Label>
                                    <DatePicker
                                        name="achievedDate"
                                        id="achievedDate"
                                        type="text"
                                        required
                                        value={!candidateSkill?.achievedDate ? "" : formatDate(candidateSkill.achievedDate)}
                                        onChange={value => {
                                            setAcheivedDateError(value > moment());
                                            setCandidateSkill({
                                                ...candidateSkill,
                                                achievedDate: !value ? null : moment(value).format("YYYY-MM-DDT00:00:00")
                                            });
                                        }}
                                        closeOnSelect
                                    />
                                    {acheivedDateError && <small className="text-danger">Achieved date cannot be greater than todays date.</small>}
                                </FormGroup>
                            </Col>
                            <Col xs={2}>
                                <FormGroup>
                                    <Label>
                                        Expiry Date
                                    </Label>
                                    <DatePicker
                                        name="expiryDate"
                                        id="expiryDate"
                                        type="text"
                                        required
                                        value={!candidateSkill?.expiryDate ? "" : formatDate(candidateSkill.expiryDate)}
                                        onChange={value => {
                                            setCandidateSkill({
                                                ...candidateSkill,
                                                expiryDate: !value ? null : moment(value).format("YYYY-MM-DDT00:00:00")
                                            });
                                        }}
                                        closeOnSelect
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={2}>
                                <FormGroup className="">
                                    <Label for="verified">
                                        Active
                                    </Label>
                                    <label className="custom-toggle custom-toggle-primary d-block">
                                        <input type="checkbox"
                                            name="active"
                                            id="active"
                                            checked={!!candidateSkill?.active}
                                            onChange={({ target: { checked } }) => {
                                                setCandidateSkill({ ...candidateSkill, active: checked })
                                            }}
                                        />
                                        <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                                    </label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12}>
                        <Row>
                            <Col xs={6}>
                                <div {...getRootProps({ className: dropzoneClasses })}>
                                    <input {...getInputProps()} />
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <aside className="dropzone-list pt-3">
                                    <Label>Files</Label>
                                    <ul>{files}</ul>
                                </aside>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-end">
                        <TooltipButton
                            id="cancel"
                            title="Cancel"
                            className="btn-icon"
                            color="warning"
                            size="sm"
                            type="button"
                            onClick={() => {
                                setDrawerMode(Mode.NONE);
                            }}
                        >
                            Cancel
                        </TooltipButton>
                        <TooltipButton
                            id="add"
                            title="Add"
                            className="btn-icon ml-2"
                            color="default"
                            size="sm"
                            type="button"
                            disabled={acheivedDateError}
                            onClick={() => {

                                // Remove validation message if no validation detected
                                setValidationMessage(null);
                                setInvalidTrainingMaster(null);
                                setInvalidSkillName(null);

                                // Validate training master
                                if (!trainingMaster) {
                                    setInvalidTrainingMaster("This field can't be empty!");
                                    // console.log("invalidTrainingMaster", invalidTrainingMaster);

                                    var isOpen = invalidTrainingMaster == null ? false : true;
                                    // console.log("isOpen", isOpen);
                                    return;
                                }
                                // Validate input skill name
                                if (!candidateSkill?.trainingMasterSkill) {
                                    setInvalidSkillName("This field can't be empty!");
                                    // console.log("invalidSkillName", invalidSkillName);

                                    var isOpen = invalidSkillName == null ? false : true;
                                    // console.log("isOpen", isOpen);
                                    return;
                                }

                                // If add mode
                                if (drawerMode === Mode.ADD) {
                                    const newData = {
                                        ...candidateSkill,
                                        index: data?.length ? data.length - 1 : 0,
                                        files: [...acceptedAttachments]
                                    }
                                    setData([...data, {...newData}])
                                    setItemInEdit({
                                        ...itemInEdit,
                                        candidateSkills: [
                                            ...data || [],
                                            {...newData}
                                        ]
                                    });
                                }

                                // If edit mode
                                else if (drawerMode === Mode.EDIT) {
                                    const index = _.findIndex(data, o => {
                                        if (candidateSkill.candidateSkillId)
                                            return o.candidateSkillId === candidateSkill.candidateSkillId;

                                        return o.index === candidateSkill.index;
                                    });

                                    const skills = [...data]
                                    skills.splice(index, 1, {
                                        ...candidateSkill,
                                        files: [...acceptedAttachments]
                                    });
                                    // console.log({ index, candidateSkill, skills });
                                    setData([...skills]);
                                    setItemInEdit({
                                        ...itemInEdit,
                                        candidateSkills: skills
                                    });
                                }
                                
                                setDrawerMode(Mode.NONE);
                            }}
                        >
                            {drawerMode === Mode.ADD ? "Add" : drawerMode === Mode.EDIT ? "Update" : ""}
                        </TooltipButton>
                    </Col>
                </Row>
            </Collapse>
            {/* <Collapse
                isOpen={drawerMode === Mode.NONE}
            >
                <TooltipButton
                    id="addnew"
                    title="Add New"
                    className="btn-icon ml-3 mb-2"
                    color="default"
                    size="sm"
                    type="button"
                    onClick={() => {
                        setCandidateSkill({
                            achievedDate: null,
                            expiryDate: null,
                            active: true
                        });
                        setDrawerMode(Mode.ADD);
                    }}
                >
                    <i className="fas fa-plus pt-1"></i>
                </TooltipButton>
            </Collapse> */}
            <Grid
                totalRecords={totalRecords}
                pageSize={pageSize}
                rows={rows}
                tableProps={getTableProps()}
                headerGroups={headerGroups}
                tableBodyProps={getTableBodyProps()}
                prepareRow={prepareRow}
                onPagerChangePage={(pager) => {
                    setPageNumber(pager.currentPage);
                }}
                onPagerChangePageSize={(size) => {
                    // console.log({size});
                    setPageSize(size);
                }}
                needPaging={true}
                height={isPage ? '44vh' : '37vh'}
            />
        </>
    )
}