/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import {
    Label, Button, Modal, Container, Row, Col
} from "reactstrap";
import _ from "lodash";
import moment from "moment";
import api from "../../../../services/api";
import { AgGridReact } from 'ag-grid-react';
import { formatDate } from '../../../../utils';
import Loader from '../../../loaders';
import { moneyFormatter, percentFormatter } from '../../../ag-grid/ValueFormatters';
import { CheckGroup } from '../../../inputs';

const DAYS_OF_WEEK = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 4,
    Thursday: 8,
    Friday: 16,
    Saturday: 32,
    Sunday: 64,
    Roster: 128,
}

export default function LRFDetailView(props) {
    const {
        item,
        onClose,
    } = props;
    
    const dataRehire = item.detail?.requestDetailRehires ?? [];
    const [detail, setDetail] = useState(item.detail);
    const [loading, setLoading] = useState(false);
    const [companyAllowanceTypes, setCompanyAllowanceTypes] = useState([]);
    const replacementEmployeeGridRef = useRef();
    const rehireListGridRef = useRef();

    const loadingOverlayComponent = useMemo(() => Loader, []);

    useEffect(() => {
        if (detail && detail?.positionId && detail?.employmentCategoryId && detail?.startDateExpected && !detail?.maxSalaryFrom && detail?.employmentCategory.lookupValue === "White Collar") {
            setLoading(true);
            const start = moment(detail.startDateExpected).format("YYYY-MM-DD");
            const end = detail.finishDatePlanned ? moment(detail.finishDatePlanned).format("YYYY-MM-DD") : null;

            api.get(`rates/findrate/${detail.positionId}/${detail.employmentCategoryId}/${start}${end ? `/${end}` : ""}`)
                .then((response) => {
                    // console.log("Rates: ", response);
                    if (response.data && response.data.fulltimeRate) {
                        setDetail({ ...detail, maxSalaryFrom: response.data.fulltimeRate });
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [detail, detail?.positionId, detail?.employmentCategoryId, detail?.startDateExpected, detail?.finishDatePlanned, detail?.maxSalaryFrom]);

    useEffect(() => {
        setLoading(true);
        // console.log({detail});
        
        if (!detail.recruitmentCurrentEmployees?.length || !detail.recruitmentCurrentEmployees[0].companyId)
        {
            if (companyAllowanceTypes.length)
                setCompanyAllowanceTypes([]);

            setLoading(false);
            return;
        }

        api.get(`company/definitiv/allowancetypes?id=${detail.recruitmentCurrentEmployees[0].companyId}`)
            .then((response) => {
                setCompanyAllowanceTypes([...response.data]);
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [detail.recruitmentCurrentEmployees]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        filter: false,
        editable: false,
        suppressMenu: true,
    }), []);
    
    const replacementEmployeeColumnDefs = useMemo(() => [
        {
            field: "benefitTypeName",
            headerName: "Type",
        },
        {
            field: "uom",
            headerName: "UoM",
            width: 140,
            maxWidth: 140,
        },
        {
            field: "benefitValue",
            headerName: "Value",
            valueFormatter: (params) => {
                const {data: rowData} = params;

                if (rowData.uom === "Payment")
                    return moneyFormatter(params);

                if (rowData.uom === "Percent")
                    return percentFormatter(params);

                return params.value;
            },
            cellClass: "text-right",
            width: 160,
            maxWidth: 160,
        },
    ], [detail, companyAllowanceTypes]);

    const onReplacementEmployeeGridReady = useCallback((params) => {
        params.api.sizeColumnsToFit();
        window.addEventListener('resize', function () {
            setTimeout(function () {
                params.api.sizeColumnsToFit();
            });
        });
    }, [detail?.recruitmentCurrentBenefits]);

    const getReplacementEmployeeGridRowId = useMemo(() => (params) => params?.data?.recruitmentCurrentBenefitId, [detail?.recruitmentCurrentBenefits]);
    
    const onReplacementEmployeeGridCellEditRequest = useCallback((event) => {
        const {data: rowData, newValue, colDef} = event;
        // console.log({event, detail, valueType: typeof newValue});
        const newRowValue = typeof newValue === "string" || typeof newValue === "number" ? {...rowData, [colDef.field]: newValue} : {...rowData, ...newValue};
        const newBenefits = JSON.parse(JSON.stringify(detail.recruitmentCurrentBenefits));
        const selectedBenefit = newBenefits.filter(b => b.recruitmentCurrentBenefitId === newRowValue.recruitmentCurrentBenefitId)[0];
        const index = newBenefits.indexOf(selectedBenefit);
        newBenefits.splice(index, 1, newRowValue);
        setDetail({...detail, recruitmentCurrentBenefits: newBenefits});
    }, [detail]);
    
    const rehireColumnDefs = useMemo(() => [
        {
            field: 'requestDetailRehireId',
            headerName: "Request Detail Rehire Id",
            width: 50,
            maxWidth: 50,
            hide: true,
        },
        {
            field: 'requestDetailId',
            headerName: "Request Detail Id",
            width: 50,
            maxWidth: 50,
            hide: true,
        },
        {
            field: 'candidateId',
            headerName: "Candidate ID",
            width: 50,
            maxWidth: 50,
            hide: true,
        },
        {
            field: 'candidateName',
            headerName: "Employee Name",
        },
        {
            field: 'lastEmploymentDate',
            headerName: "Last Employment Date",
            width: 160,
            maxWidth: 160,
            valueFormatter: (params) => {
                const {data: rowData} = params;
                return rowData.lastEmploymentDate ? moment(rowData.lastEmploymentDate).format('dd, DD-MM-YYYY') : "N/A";
            },
            cellClass: "text-center",
        },
    ], [detail, companyAllowanceTypes]);

    const onRehireGridReady = useCallback((params) => {
        params.api.sizeColumnsToFit();
        window.addEventListener('resize', function () {
            setTimeout(function () {
                params.api.sizeColumnsToFit();
            });
        });
    }, [dataRehire]);

    return (
        <Modal
            isOpen={true}
            className="modal-xl"
            modalClassName="db-example-modal-xl"
        >
            {loading && <Loader />}
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    {item?.recruitmentRequestNumber} Detail{detail?.position ? ` - ${detail.position.lookupValue}` : ""}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body"
                style={{height: "75vh", maxHeight: "75vh", overflowY: "auto", }}
            >
                <Container fluid className='px-0'>
                    <Row>
                        <Col xs={4}>
                            <Label className="font-size-small-custom-forced mb-0">
                                Employment Category
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Label className="font-size-small-custom-forced mb-0">
                                : <b>{detail?.employmentCategory?.lookupValue ?? "N/A"}</b>
                            </Label>
                        </Col>
                        <Col xs={4}>
                            <Label className="font-size-small-custom-forced mb-0">
                                Employed Position (in rate sheet only)
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Label className="font-size-small-custom-forced mb-0">
                                : <b>{detail?.position?.lookupValue ?? "N/A"}</b>
                            </Label>
                        </Col>
                    {
                        item?.rosterId ?
                        <>
                            <Col xs={4}>
                                <Label className="font-size-small-custom-forced mb-0">
                                    Roster Position
                                </Label>
                            </Col>
                            <Col xs={8}>
                                <Label className="font-size-small-custom-forced mb-0">
                                    : <b>{detail?.hrispositionMaster?.hrispositionMasterName ?? "N/A"}</b>
                                </Label>
                            </Col>
                        </>
                        :
                        null
                    }
                    </Row>
                {
                    item?.recruitmentCategory?.lookupValue === "Replacement" &&
                    <>
                        <Row className="mt-4 mb-3">
                            <Col xs={12} className="bg-secondary border-bottom">
                                <span className="h6">Replaced Employee Details</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <Label className="font-size-small-custom-forced mb-0">
                                    Company
                                </Label>
                            </Col>
                            <Col xs={8}>
                                <Label className="font-size-small-custom-forced mb-0">
                                    : <b>{detail.recruitmentCurrentEmployees?.length ? detail.recruitmentCurrentEmployees[0].company.companyName : "N/A"}</b>
                                </Label>
                            </Col>
                            <Col xs={4}>
                                <Label className="font-size-small-custom-forced mb-0">
                                    Employee
                                </Label>
                            </Col>
                            <Col xs={8}>
                                <Label className="font-size-small-custom-forced mb-0">
                                    : <b>{detail.recruitmentCurrentEmployees?.length ? detail.recruitmentCurrentEmployees[0].employeeName : "N/A"}</b>
                                </Label>
                            </Col>
                            <Col xs={12}>
                                <div className="ag-theme-alpine ag-grid" style={{ width: '100%', height: '200px' }}>
                                    <AgGridReact
                                        ref={replacementEmployeeGridRef}
                                        columnDefs={replacementEmployeeColumnDefs}
                                        defaultColDef={defaultColDef}
                                        loadingOverlayComponent={loadingOverlayComponent}
                                        suppressColumnVirtualisation={false}
                                        suppressRowVirtualisation={false}
                                        debounceVerticalScrollbar={false}
                                        rowBuffer={20}
                                        headerHeight={30}
                                        groupHeaderHeight={30}
                                        rowHeight={35}
                                        floatingFiltersHeight={30}
                                        suppressDragLeaveHidesColumns={true}
                                        suppressColumnMoveAnimation={true}
                                        animateRows={false}
                                        pagination={false}
                                        rowClass={'ag-row-normal'}
                                        suppressContextMenu={true}
                                        rowData={detail?.recruitmentCurrentBenefits ?? []}
                                        onGridReady={onReplacementEmployeeGridReady}
                                        readOnlyEdit={true}
                                        onCellEditRequest={onReplacementEmployeeGridCellEditRequest}
                                        getRowId={getReplacementEmployeeGridRowId}
                                        stopEditingWhenCellsLoseFocus={true}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </>
                }
                    <Row className="mt-4 mb-3">
                        <Col xs={12} className="bg-secondary border-bottom">
                            <span className="h6">Employment Details</span>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs={4}>
                            <Label className="font-size-small-custom-forced mb-0">
                                Quantity Required
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Label className="font-size-small-custom-forced mb-0">
                                : <b>{detail?.quantityRequired ?? "N/A"}</b>
                            </Label>
                        </Col>
                        <Col xs={4}>
                            <Label className="font-size-small-custom-forced mb-0">
                                Payroll Default Cost Code
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Label className="font-size-small-custom-forced mb-0">
                                : <b>{detail?.workorderNumber ?? "N/A"}</b>
                            </Label>
                        </Col>
                        <Col xs={4}>
                            <Label className="font-size-small-custom-forced mb-0">
                                Expected Start Date
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Label className="font-size-small-custom-forced mb-0">
                                : <b>{detail?.startDateExpected ? formatDate(detail.startDateExpected) : "N/A"}</b>
                            </Label>
                        </Col>
                        <Col xs={4}>
                            <Label className="font-size-small-custom-forced mb-0">
                                Planned End Date
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Label className="font-size-small-custom-forced mb-0">
                                : <b>{detail?.finishDatePlanned ? formatDate(detail.finishDatePlanned) : "N/A"}</b>
                            </Label>
                        </Col>
                        <Col xs={4}>
                            <Label className="font-size-small-custom-forced mb-0">
                                Employment Type
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Label className="font-size-small-custom-forced mb-0">
                                : <b>{detail?.employmentType?.lookupValue ?? "N/A"}</b>
                            </Label>
                        </Col>
                        <Col xs={4}>
                            <Label className="font-size-small-custom-forced mb-0">
                                Point of Hire
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Label className="font-size-small-custom-forced mb-0">
                                : <b>{detail?.pointOfHire?.lookupValue ?? "N/A"}</b>
                            </Label>
                        </Col>
                        <Col xs={4}>
                            <Label className="font-size-small-custom-forced mb-0">
                                Desired Entity
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Label className="font-size-small-custom-forced mb-0">
                                : <b>{detail?.company?.lookupValue ?? "N/A"}</b>
                            </Label>
                        </Col>
                        <Col xs={4}>
                            <Label className="font-size-small-custom-forced mb-0">
                                Reporting To
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Label className="font-size-small-custom-forced mb-0">
                                : <b>{detail?.reportingToApiResult?.email ?? "N/A"}</b>
                            </Label>
                        </Col>
                    </Row>
                {
                    dataRehire?.length ?
                    <>
                        <Row className="mt-3 mb-4">
                            <Col xs={12} className="bg-secondary border-bottom">
                                <span className="h6">Rehire List</span>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col xs={12}>
                                <div className="ag-theme-alpine ag-grid" style={{ width: '100%', height: '200px' }}>
                                    <AgGridReact
                                        ref={rehireListGridRef}
                                        columnDefs={rehireColumnDefs}
                                        defaultColDef={defaultColDef}
                                        loadingOverlayComponent={loadingOverlayComponent}
                                        suppressColumnVirtualisation={false}
                                        suppressRowVirtualisation={false}
                                        debounceVerticalScrollbar={false}
                                        rowBuffer={20}
                                        headerHeight={30}
                                        groupHeaderHeight={30}
                                        rowHeight={35}
                                        floatingFiltersHeight={30}
                                        suppressDragLeaveHidesColumns={true}
                                        suppressColumnMoveAnimation={true}
                                        animateRows={false}
                                        pagination={false}
                                        rowClass={'ag-row-normal'}
                                        suppressContextMenu={true}
                                        rowData={dataRehire}
                                        onGridReady={onRehireGridReady}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </>
                    :
                    null
                }
                    <Row className="mt-4 mb-3">
                        <Col xs={12} className="bg-secondary border-bottom">
                            <span className="h6">Scope of Work / Roster Details</span>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs={4}>
                            <Label className="font-size-small-custom-forced mb-0">
                                Ordinary Hours / Week
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Label className="font-size-small-custom-forced mb-0">
                                : <b>{detail?.hoursPerWeek ?? "N/A"}</b>
                            </Label>
                        </Col>
                        <Col xs={4}>
                            <Label className="font-size-small-custom-forced mb-0">
                                Hours per Day
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Label className="font-size-small-custom-forced mb-0">
                                : <b>{detail?.hoursPerDay ?? "N/A"}</b>
                            </Label>
                        </Col>
                        <Col xs={4}>
                            <Label className="font-size-small-custom-forced mb-0">
                                Travel Type
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Label className="font-size-small-custom-forced mb-0">
                                : <b>{detail?.siteTravelType?.lookupValue ?? "N/A"}</b>
                            </Label>
                        </Col>
                        <Col xs={4}>
                            <Label className="font-size-small-custom-forced mb-0">
                                Roster Sequence
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Label className="font-size-small-custom-forced mb-0">
                                : <b>{detail?.rosterSequence?.description ?? "N/A"}</b>
                            </Label>
                        </Col>
                        <Col md={4}>
                            <Label className="font-size-small-custom-forced mb-0">
                                Expected Start Time
                            </Label>
                        </Col>
                        <Col md={8}>
                            <Label className="font-size-small-custom-forced mb-0">
                                : <b>{detail?.expectedStartTime ?? "N/A"}</b>
                            </Label>
                        </Col>
                        <Col md={4}>
                            <Label className="font-size-small-custom-forced mb-0">
                                Expected Finish Time
                            </Label>
                        </Col>
                        <Col md={8}>
                            <Label className="font-size-small-custom-forced mb-0">
                                : <b>{detail?.expectedFinishTime ?? "N/A"}</b>
                            </Label>
                        </Col>
                    {
                        detail?.employmentCategory && detail.employmentCategory.lookupValue === "White Collar" ?
                        <>
                            <Col xs={4}>
                                <Label className="font-size-small-custom-forced mb-0">
                                    Max Salary/Rate (From)
                                </Label>
                            </Col>
                            <Col xs={8}>
                                <Label className="font-size-small-custom-forced mb-0">
                                    : <b>{detail?.maxSalaryFrom ? `$ ${detail?.maxSalaryFrom}` : "N/A"}</b>
                                </Label>
                            </Col>
                            <Col xs={4}>
                                <Label className="font-size-small-custom-forced mb-0">
                                    Max Salary/Rate (To)
                                </Label>
                            </Col>
                            <Col xs={8}>
                                <Label className="font-size-small-custom-forced mb-0">
                                    : <b>{detail?.maxSalaryTo ? `$ ${detail?.maxSalaryTo}` : "N/A"}</b>
                                </Label>
                            </Col>
                        </>
                        :
                        null
                    }
                        <Col xs={4}>
                            <Label className="font-size-small-custom-forced mb-0">
                                Days per Week
                            </Label>
                        </Col>
                        <Col xs={8} className="d-flex flex-row justify-content-start align-items-start">
                            <div>
                                <Label className="font-size-small-custom-forced mb-0">
                                    :&nbsp;
                                </Label>
                            </div>
                            <CheckGroup
                                flags
                                items={DAYS_OF_WEEK}
                                name="daysPerWeek"
                                selected={detail.daysPerWeek}
                                colWidth={0}
                                disabled={true}
                                className="font-size-small-custom-forced"
                            />
                        </Col>
                        <Col xs={4}>
                            <Label className="font-size-small-custom-forced mb-0">
                                Work Location
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Label className="font-size-small-custom-forced mb-0">
                                : <b>{detail?.workLocation ?? "N/A"}</b>
                            </Label>
                        </Col>
                        <Col xs={4}>
                            <Label className="font-size-small-custom-forced mb-0">
                                Scope of Work/Additional Project Information/Justification
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Label className="font-size-small-custom-forced mb-0">
                                : <b>{detail?.scopeOfWork ?? "N/A"}</b>
                            </Label>
                        </Col>
                    {
                        item?.recruitmentType?.lookupLabel.includes("SG&A") &&
                        <>
                            <Col xs={4}>
                                <Label className="font-size-small-custom-forced mb-0">
                                    New or Replacement Position
                                </Label>
                            </Col>
                            <Col xs={8}>
                                <Label className="font-size-small-custom-forced mb-0">
                                    : <b>{detail?.newOrReplacement?.lookupValue ?? "N/A"}</b>
                                </Label>
                            </Col>
                        {
                            detail.newOrReplacement?.lookupValue === "Replacement" &&
                            <>
                                <Col xs={4}>
                                    <Label className="font-size-small-custom-forced mb-0">
                                        Replacement For
                                    </Label>
                                </Col>
                                <Col xs={8}>
                                    <Label className="font-size-small-custom-forced mb-0">
                                        : <b>{detail?.replacementForName ?? "N/A"}</b>
                                    </Label>
                                </Col>
                            </>
                        }
                        </>
                    }
                    {
                        (item?.recruitmentType?.lookupLabel.includes("SG&A") || item?.recruitmentType?.lookupLabel.includes("Cost of Sales")) &&
                        <>
                            <Col xs={4}>
                                <Label className="font-size-small-custom-forced mb-0">
                                    Detailed Justification
                                </Label>
                            </Col>
                            <Col xs={8}>
                                <Label className="font-size-small-custom-forced mb-0">
                                    : <b>{detail?.detailedJustification ?? "N/A"}</b>
                                </Label>
                            </Col>
                        </>
                    }
                    {
                        detail.employmentCategory && detail.employmentCategory.lookupValue !== "Blue Collar" &&
                        <>
                            <Col xs={4}>
                                <Label className="font-size-small-custom-forced mb-0">
                                    Computer Requirement
                                </Label>
                            </Col>
                            <Col xs={8}>
                                <Label className="font-size-small-custom-forced mb-0">
                                    : <b>{detail?.computerRequirement?.lookupValue ?? "N/A"}</b>
                                </Label>
                            </Col>
                            <Col xs={4}>
                                <Label className="font-size-small-custom-forced mb-0">
                                    Phone Requirement
                                </Label>
                            </Col>
                            <Col xs={8}>
                                <Label className="font-size-small-custom-forced mb-0">
                                    : <b>{detail?.phoneRequirement?.lookupValue ?? "N/A"}</b>
                                </Label>
                            </Col>
                        </>
                    }
                        <Col xs={4}>
                            <Label className="font-size-small-custom-forced mb-0">
                                System Access Required
                            </Label>
                        </Col>
                        <Col xs={8}>
                            <Label className="font-size-small-custom-forced mb-0">
                                : <b>{detail?.requestDetailItsystemAccesses?.length ? detail?.requestDetailItsystemAccesses.map(d => d.systemName).join(", ") : "N/A"}</b>
                            </Label>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={onClose}
                    type="button"
                >
                    Close
                </Button>
            </div>
        </Modal>
    );
}