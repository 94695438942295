import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import Loader from "../../../../loaders";
import api from "../../../../../services/api";
import { AgGridReact } from "ag-grid-react";
import TemplateDialog from "./TemplateDialog";
import { Checkbox } from "@progress/kendo-react-inputs";

function EmployeeSelectionDialog(props) {
    const {
        title,
        dispatchUnitId,
        initialSelectedSupervisors,
        onClose,
        onNextClick
    } = props;

    const [ loading, setLoading ] = useState(false);
    const [ spvData, setSpvData ] = useState([]);
    const [ employeeData, setEmployeeData ] = useState([]);
    const [ selectedSupervisors, setSelectedSupervisors ] = useState(initialSelectedSupervisors);
    const gridRef = useRef();

    const loadingOverlayComponent = useMemo(() => Loader, []);
    const employeeLoadingOverlayComponent = useMemo(() => Loader, []);

    const loadData = useCallback(() => {
        setLoading(true);

        api.get(`supervisorrating/${dispatchUnitId}`)
            .then((response) => {
                // console.log({response});
                setSpvData([...response.data.supervisors]);
                setEmployeeData([...response.data.employees]);
            })
            .catch((error) => console.log({error}))
            .finally(() => setLoading(false))
        ;
    }, [api, dispatchUnitId]);

    // useEffect(() => console.log({data}), [data]);

    useEffect(() => {
        loadData();
    }, [dispatchUnitId]);

    const defaultColDef = useMemo(() => ({
        sortable: false,
        resizable: true,
        filter: false,
        editable: false,
        suppressMenu: true,
    }), []);

    const spvColumnDefs = useMemo(() => {
        return [
            {
                field: "select",
                headerName: "",
                headerComponent: () => {
                    return (
                        // <div className="d-flex flex-wrap justify-content-center align-items-center w-100-custom h-100 m-0 p-0">
                        <div className="ag-cell-label-container">
                            <div className="ag-header-cell-label">
                                <Checkbox
                                    onChange={(e) => {
                                        if (!e.value)
                                        {
                                            setSelectedSupervisors([]);
                                            return;
                                        }
                                        
                                        setSelectedSupervisors([...spvData]);
                                    }}
                                    checked={selectedSupervisors.length > 0 && selectedSupervisors.length === spvData.length}
                                />
                            </div>
                        </div>
                    );
                },
                pinned: true,
                filter: false,
                cellRenderer: (cellParams) => {
                    const {data} = cellParams;
                    return (
                        <div className="d-flex flex-wrap justify-content-center align-items-center w-100-custom h-100 m-0 p-0">
                            <Checkbox
                                onChange={(e) => {
                                    // console.log({e, data});

                                    if (!e.value)
                                    {
                                        setSelectedSupervisors([
                                            ...selectedSupervisors.filter(s => s.candidateId !== data.candidateId),
                                        ]);
                                        return;
                                    }
                                    
                                    setSelectedSupervisors([
                                        ...selectedSupervisors,
                                        {...data},
                                    ]);
                                }}
                                checked={selectedSupervisors.filter(c => c.candidateId === data.candidateId).length > 0}
                            />
                        </div>
                    );
                },
                suppressMenu: true,
            },
            {
                field: "slotNumber",
                headerName: "Slot",
                filter: false,
                suppressMenu: true,
                pinned: true,
            },
            {
                field: "candidateName",
                headerName: "Supervisor Name",
                suppressMenu: true,
                pinned: true,
            },
            {
                field: "rosterPositionName",
                headerName: "Roster Position",
                suppressMenu: true,
            },
            {
                field: "shiftName",
                headerName: "Shift",
                suppressMenu: true,
            },
            {
                field: "mobileNumber",
                headerName: "Phone Number",
                suppressMenu: true,
            },
        ];
    }, [spvData, selectedSupervisors]);

    const employeeColumnDefs = useMemo(() => {
        return [
            {
                field: "slotNumber",
                headerName: "Slot",
                width: 55,
                filter: false,
                suppressMenu: true,
                pinned: true,
            },
            {
                field: "candidateName",
                headerName: "Employee Name",
                width: 150,
                suppressMenu: true,
                pinned: true,
            },
            {
                field: "rosterPositionName",
                headerName: "Roster Position",
                width: 150,
                suppressMenu: true,
            },
            {
                field: "shiftName",
                headerName: "Shift",
                width: 150,
                suppressMenu: true,
            },
            {
                field: "entityName",
                headerName: "Entity",
                width: 150,
                suppressMenu: true,
            },
            {
                field: "candidateTypeName",
                headerName: "Candidate Type",
                width: 150,
                suppressMenu: true,
            },
        ];
    }, []);

    const onGridReady = useCallback((params) => {
        // console.log({params});
        const {api: gridApi} = params;

        gridApi.sizeColumnsToFit({
            defaultMinWidth: 150,
            columnLimits: [
                { key: 'candidateName', minWidth: 200 },
                { key: 'slotNumber', maxWidth: 55 },
                { key: 'select', maxWidth: 50 }
            ],
          });
    }, []);

    const content = useMemo(() => (
        <>
            <Row className="m-0 p-0">
                <Col lg={12} className="m-0 p-0 d-flex flex-wrap justify-content-start align-items-center">
                    <span className="font-size-small-custom">
                        <i className="fas fa-info-circle mr-1" />Please select the supervisors from the roster list for the feedback request.<br/>
                    </span>
                </Col>
            </Row>
            <Row className="m-0 p-0 mt-2">
                <div className="ag-theme-alpine ag-grid clickable-row" style={{ width: '100%', height: '30vh' }}>
                    <AgGridReact
                        ref={gridRef}
                        columnDefs={spvColumnDefs}
                        defaultColDef={defaultColDef}
                        rowData={spvData}
                        loadingOverlayComponent={loadingOverlayComponent}
                        suppressColumnVirtualisation={false}
                        suppressRowVirtualisation={false}
                        rowBuffer={20}
                        debounceVerticalScrollbar={false}
                        allowContextMenuWithControlKey={true}
                        suppressContextMenu={true}
                        suppressClipboardPaste={true}
                        headerHeight={25}
                        groupHeaderHeight={22}
                        rowHeight={22}
                        readOnlyEdit={true}
                        enableRangeSelection={true}
                        tooltipShowDelay={0}
                        tooltipHideDelay={20000}
                        suppressDragLeaveHidesColumns={true}
                        suppressColumnMoveAnimation={true}
                        enableCellChangeFlash={true}
                        animateRows={true}
                        onGridReady={onGridReady}
                    />
                </div>
            </Row>
            <Row className="m-0 p-0 mt-2">
                <div className="ag-theme-alpine ag-grid clickable-row" style={{ width: '100%', height: '30vh' }}>
                    <AgGridReact
                        ref={gridRef}
                        columnDefs={employeeColumnDefs}
                        defaultColDef={defaultColDef}
                        rowData={employeeData}
                        loadingOverlayComponent={employeeLoadingOverlayComponent}
                        suppressColumnVirtualisation={false}
                        suppressRowVirtualisation={false}
                        rowBuffer={20}
                        debounceVerticalScrollbar={false}
                        allowContextMenuWithControlKey={true}
                        suppressContextMenu={true}
                        suppressClipboardPaste={true}
                        headerHeight={25}
                        groupHeaderHeight={22}
                        rowHeight={22}
                        readOnlyEdit={true}
                        enableRangeSelection={false}
                        tooltipShowDelay={0}
                        tooltipHideDelay={20000}
                        suppressDragLeaveHidesColumns={true}
                        suppressColumnMoveAnimation={true}
                        multiSortKey='ctrl'
                        enableCellChangeFlash={true}
                        animateRows={true}
                        onGridReady={onGridReady}
                    />
                </div>
            </Row>
        </>
    ), [loadData, gridRef, spvColumnDefs, defaultColDef, spvData, onGridReady, employeeColumnDefs, employeeData, selectedSupervisors]);

    const footer = useMemo(() => (
        <>
            <Button
                color="secondary"
                onClick={onClose}
                type="button"
            >
                Close
            </Button>
            <Button
                color="info"
                onClick={() => onNextClick(selectedSupervisors)}
                type="button"
                disabled={selectedSupervisors.length < 1}
            >
                Next: SMS Confirmation<i className="fas fa-chevron-right ml-2" />
            </Button>
        </>
    ), [onClose, onNextClick, selectedSupervisors]);

    return (
        <TemplateDialog
            title={title}
            loading={loading}
            content={content}
            footer={footer}
            onClose={onClose}
        />
    );
};

export default EmployeeSelectionDialog;
