import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Loader from '../../../loaders';
import api from "../../../../services/api";
import { Button, Col, Container, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import { useFlexLayout, useRowSelect, useTable } from 'react-table';
import { IndeterminateCheckbox } from '../../../react-table/controls';
import { TooltipButton } from '../../../inputs';

function ProjectListComponent(props) {
    const { id, title, data, onSelect, onMove, hideLabel, columnHeader, listPosition } = props;

    const columns = useMemo(() => [
        {
            Header: columnHeader,
            accessor: 'lookupValue',
        },
    ], []);

    const defaultColumn = useMemo(
        () => ({
            minWidth: 30,
            width: 150,
            maxWidth: 200,
        }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // selectedFlatRows,
        toggleRowSelected,
    } = useTable (
        {
            columns,
            data,
            defaultColumn,
        },
        useFlexLayout,
        useRowSelect,
        // hooks => {
        //     hooks.visibleColumns.push(columns => [
        //         {
        //             id: 'selection',
        //             minWidth: 35,
        //             width: 35,
        //             maxWidth: 35,
        //             Header: ({ getToggleAllRowsSelectedProps }) => (
        //                 <div>
        //                     <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} id={`milestone-header-${id}`} />
        //                 </div>
        //             ),
        //             Cell: ({ row }) => (
        //                 <div>
        //                     <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} id={`milestone-${id}-${row.id}`} />
        //                 </div>
        //             ),
        //         },
        //         ...columns,
        //     ]);
        // }
    );

    // useEffect(() => {
    //     onSelect(selectedFlatRows.map(d => ({ ...d.original, selected: true })));
    // }, [selectedFlatRows]);

    return (
        <>
            {!hideLabel && <label className="">{title}</label>}
            <div {...getTableProps()} className="table flex table-hover scrollable">
                <div className="thead">
                    {headerGroups.map(headerGroup => {
                        return (<div {...headerGroup.getHeaderGroupProps()} className="tr">
                            {headerGroup.headers.map((column, index) => {
                                return (<div {...column.getHeaderProps()} className="th">
                                    {column.render('Header')}
                                </div>);
                            })}
                            <div className="th th-action text-right" style={{width: "77px"}}></div>
                        </div>);
                    }
                    )}
                </div>
                <div {...getTableBodyProps()} className="tbody">
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <div {...row.getRowProps()} className="tr">
                            {
                                listPosition === "right" &&
                                <div className="td td-action text-left" style={{width: "60px"}}>
                                    <TooltipButton
                                        id={`move_${i}`}
                                        title="Move"
                                        className="btn-icon"
                                        color="default"
                                        size="sm"
                                        type="button"
                                        outline
                                        onClick={() => {
                                            onMove(row.original)
                                        }}
                                    >
                                        <i className="fas fa-chevron-left"></i>
                                    </TooltipButton>
                                </div>
                            }
                            {
                                row.cells.map(cell => {
                                    return <div {...cell.getCellProps({
                                        onClick: cell.column.id !== "selection" ? () => toggleRowSelected(row.id) : undefined
                                    })}
                                        className={`td ${cell.column.id !== "selection" ? "cursor-pointer" : ""}`}
                                    >
                                        {cell.render('Cell')}
                                    </div>;
                                })
                            }
                            {
                                listPosition === "left" &&
                                <div className="td td-action text-right" style={{width: "60px"}}>
                                    <TooltipButton
                                        id={`move_${i}`}
                                        title="Move"
                                        className="btn-icon"
                                        color="default"
                                        size="sm"
                                        type="button"
                                        outline
                                        onClick={() => {
                                            onMove(row.original)
                                        }}
                                    >
                                        <i className="fas fa-chevron-right"></i>
                                    </TooltipButton>
                                </div>
                            }
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default function ProjectList(props) {
    const {
        rosterAttributeId,
        setSelectedRosterAttributeId
    } = props;

    const [ loading, setLoading ] = useState(false);
    const [ rosterAttribute, setRosterAttribute ] = useState(null);
    const [ selectedProjects, setSelectedProjects ] = useState([]);
    const [ projectName, setProjectName ] = useState(null);
    const [ projects, setProjects ] = useState([]);

    const loadData = useCallback(() => {
        setRosterAttribute(null);
        setSelectedProjects([]);

        if (!rosterAttributeId)
            return;

        setLoading(true);

        const apiCalls = [
            api.get(`/rosterattribute/${rosterAttributeId}`),
            api.get(`/rosterattribute/search-projects?id=${rosterAttributeId}`)    
        ]

        Promise.all(apiCalls)
            .then((responses) => {
                console.log(responses);
                setRosterAttribute(responses[0]?.data);
                setSelectedProjects(responses[1]?.data ?? []);
            })
            .catch(error => console.error(error))
            .finally(() => setLoading(false))
        ;
    }, [rosterAttributeId]);

    useEffect(() => {
        loadData();
    }, [rosterAttributeId]);

    const loadProjectData = useCallback(() => {
        setProjects([]);

        if (!projectName || projectName?.trim() === "")
            return;
        
        setLoading(true);
        api.get(`/lookup/project?filter=${projectName}`)
            .then((response) => {
                const loadedProjects = response.data?.filter(p => selectedProjects.filter(sp => sp.lookupId === p.lookupId).length === 0);
                // console.log(loadedProjects);
                setProjects(loadedProjects);
            })
            .catch(error => console.error(error))
            .finally(() => setLoading(false))
        ;
    }, [selectedProjects, projectName]);

    useEffect(() => {
        loadProjectData();
    }, [projectName]);

    const handleSave = useCallback(() => {
        if (!selectedProjects)
            return;
        
        setLoading(true);

        api.post(`/rosterattribute/projects/${rosterAttributeId}`, selectedProjects.map(sp => sp.lookupId))
            .then(() => {
                setLoading(false);
                setSelectedRosterAttributeId(null);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            })
        ;
    }, [rosterAttributeId, selectedProjects]);

    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
        {
            loading && <Loader />
        }
            <div className="modal-header">
                <h5 className="modal-title">
                    Manage Roster Attribute's Project - {rosterAttribute?.rosterAttributeName}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={() => setSelectedRosterAttributeId(null)}
                    type="button"
                >
                    <span aria-hidden={true}>x</span>
                </button>
            </div>
            <div className="modal-body">
                <Container fluid>
                    <Row>
                        <Col lg={6}>
                            <Row>
                                <Col lg={12}>
                                    <FormGroup>
                                        <Label>
                                            Available Project Name
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            value={projectName ?? ""}
                                            onChange={(e) => setProjectName(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <ProjectListComponent
                                id="available-projects"
                                title="Available Projects"
                                hideLabel={true}
                                data={projects}
                                onSelect={() => {}}
                                onMove={(data) => {
                                    const currentProjects = [...projects];
                                    const removedProject = currentProjects.filter(sp => sp.lookupId === data.lookupId)[0];
                                    currentProjects.splice(currentProjects.indexOf(removedProject), 1);
                                    setProjects(currentProjects);
                                    const currentSelectedProjects = [...selectedProjects];
                                    currentSelectedProjects.push(data);
                                    setSelectedProjects(currentSelectedProjects);
                                }}
                                columnHeader="Available Projects"
                                listPosition="left"
                            />
                        </Col>
                        <Col lg={6}>
                            <ProjectListComponent
                                id="selected-projects"
                                title="Selected Projects"
                                hideLabel={true}
                                data={selectedProjects}
                                onSelect={() => {}}
                                onMove={(data) => {
                                    const currentSelectedProjects = [...selectedProjects];
                                    const removedProject = currentSelectedProjects.filter(sp => sp.lookupId === data.lookupId)[0];
                                    currentSelectedProjects.splice(currentSelectedProjects.indexOf(removedProject), 1);
                                    setSelectedProjects(currentSelectedProjects);
                                    // console.log({projectName, projects, data, added: data?.lookupValue?.toLowerCase().includes(projectName?.toLowerCase())});

                                    if (!projectName || projectName.trim() === "" || !data?.lookupValue?.toLowerCase().includes(projectName?.toLowerCase()))
                                        return;

                                    const currentProjects = [...projects];
                                    currentProjects.push(data);
                                    setProjects(currentProjects);
                                }}
                                columnHeader="Selected Projects"
                                listPosition="right"
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="modal-footer">
                <Button
                    color="secondary"
                    onClick={() => setSelectedRosterAttributeId(null)}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave()}
                    type="button"
                >
                    Save Changes
                </Button>
            </div>
        </Modal>
    );
};