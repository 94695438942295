/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import api from "../../../../services/api";
import {
    Button, Modal, Container, Row, Col, Input, FormGroup
} from "reactstrap";
import { DropdownBox } from "../../../dropdowns";
import { DatePicker } from "../../../date-pickers";
import Loader from "../../../loaders";
import { formatDate, formatDateZeroTime } from "../../../../utils";
import _ from 'lodash';
import { useEffect } from 'react';
import { MakeWords } from '../../../../helpers/StringHelper';
import { useMemo, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import qs from "qs";

function BulkMilestoneEditor(props) {
    const {
        candidates,
        jobOrderCandidates = [],
        setPdrsRequest
    } = props;

    const [bulkMilestone, setBulkMilestone] = React.useState({ jobOrderCandidateIds: candidates.filter(f => !!f.candidateId), jobOrderMilestoneId: null, milestoneName: "" });
    const [loading, setLoading] = React.useState(false);
    const [milestones, setMilestones] = React.useState([]);
    const [ignoredRosterSlotNo, setIgnoredRosterSlotNo] = React.useState([]);
    //const [applicantPackSentMessage, setApplicantPackSentMessage] = useState(null);
    const [validation, setValidation] = useState(null);
    const ignoredRosterSlotNoStr = useMemo(() => {
        if (!ignoredRosterSlotNo?.length) return "";
        return MakeWords(ignoredRosterSlotNo);
    }, [ignoredRosterSlotNo]);

    useEffect(() => {
        if (!jobOrderCandidates?.length) {
            setIgnoredRosterSlotNo([]);
            return;
        }

        var jocNoCandidate = jobOrderCandidates.filter(f => !f.candidateId);
        var ignoredRosterSlotNo = jocNoCandidate.map(m => m.rosterCandidate ? (m.rosterCandidate.relatedRosterCandidateId ? `${m.rosterCandidate.relatedRosterCandidateNumber}.${m.rosterCandidate.rosterCandidateNumber}` : `${m.rosterCandidate.rosterCandidateNumber}`) : null);
        setIgnoredRosterSlotNo(ignoredRosterSlotNo);
        setBulkMilestone({ ...bulkMilestone, jobOrderCandidateIds: jobOrderCandidates.filter(f => f.candidateId).map(m => m.jobOrderCandidateId) });
    }, [candidates, jobOrderCandidates]);



    React.useEffect(() => {
        if (props.jobOrderId > 0) {
            setLoading(true);

            let apiCalls = [
                api.get(`jobordercandidate/milestones/${props.jobOrderId}`), // 0
                //api.get(`/messagesmaster?${qs.stringify({ filters: [{ id: "title", value: "Applicant Pack Sent" }] }, { allowDots: true })}`), // 1
            ];

            Promise.all(apiCalls)
                .then((response) => {
                    setMilestones(response[0].data);
                    //setApplicantPackSentMessage(response[1].data.data[0]);
                })
                .catch((error) => console.error(error))
                .finally(() => setLoading(false));
        }
    }, []);

    const handleSelectedMilestoneChanged = useCallback((item) => {
        setBulkMilestone({
            ...bulkMilestone,
            jobOrderMilestoneId: item ? item.jobOrderMilestoneId : null,
            milestoneName: item ? item.milestoneName : "",
            orderingIndex: item ? item.orderingIndex : null,
            requireSms: item ? item.requireSms : null
        });
    }, [bulkMilestone]);

    const handleTextChanged = useCallback(({ target: { name, value } }) => {
        setBulkMilestone({ ...bulkMilestone, [name]: value });
    }, [bulkMilestone]);

    const handleDateChanged = useCallback((value, name) => {
        setBulkMilestone({
            ...bulkMilestone,
            [name]: !value ? null : formatDateZeroTime(value)
        });
    }, [bulkMilestone]);

    const handleSave = useCallback((completed = false) => {
        setLoading(true);
        api.post(
            `jobordercandidate/bulkmilestone?completed=${completed}`,
            JSON.stringify(bulkMilestone)
        )
            .then((response) => {
                setLoading(false);
                props.onSaved();
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            })
            ;
    }, [bulkMilestone]);

    const checkMilestone = useCallback((isAlreadySentMessage = false) => {

        // BEGIN VALIDATION ------------------------------------------------------------------------------------------------------------------------
        if (!bulkMilestone?.milestoneName) {
            setValidation({
                title: `Bulk Milestone Editor`,
                message: "Please select the Milestone Progress Update from the list."
            });
            return;
        }


        if (!bulkMilestone?.milestoneDate) {
            setValidation({
                title: `Bulk Milestone Editor`,
                message: "Please enter the Milestone Date."
            });
            return;
        }

        if (!milestones.length || !bulkMilestone?.jobOrderMilestoneId)
            return;

        // END VALIDATION ------------------------------------------------------------------------------------------------------------------------


        const latestMilestoneId = milestones[milestones.length - 1].jobOrderMilestoneId;


        //if (!isAlreadySentMessage && currentCandidateMilestone?.milestoneName && currentCandidateMilestone?.milestoneName.trim().toLowerCase() == 'applicant pack sent') {
        //if (!isAlreadySentMessage && bulkMilestone?.milestoneName && bulkMilestone?.milestoneName.trim().toLowerCase() == 'applicant paperwork received') {

        console.log('checkMilestone');
        console.log('isAlreadySentMessage', isAlreadySentMessage);
        console.log('bulkMilestone', bulkMilestone);
        if (!isAlreadySentMessage && bulkMilestone?.requireSms == true) {

            var name = bulkMilestone?.milestoneName == null ? '' : bulkMilestone?.milestoneName.trim();
            var applicantPackSentMessages = [];
            //const queryString = qs.stringify({ name: name }, { allowDots: true });
            const queryString = qs.stringify({ filters: [{ id: "title", value: name }] }, { allowDots: true });
            let apiCalls = [
                //api.get(`/messagesmaster/check-for-milestone?${queryString}`),
                api.get(`/messagesmaster?${queryString}`), // 1
            ];

            setLoading(true);
            Promise.all(apiCalls)
                .then((response) => {
                    setLoading(false);
                    //console.log('applicantPackSentMessages', applicantPackSentMessages);
                    //return;
                    applicantPackSentMessages = response[0].data.data;

                    // If message master template not found
                    if (applicantPackSentMessages.length == 0) {
                        setValidation({
                            title: `Bulk Milestone Editor`,
                            message: `Milestone ${name} requires a message master template, which is not found. To continue, please open Maintenance -> Messages Master -> Add -> ${name} -> Save.`
                        });
                        return;
                    }



                    // If message master template found
                    var applicantPackSentMessage = applicantPackSentMessages[0];
                    //console.log('checkMilestone', checkMilestone);
                    //console.log('bulkMilestone', bulkMilestone);
                    //console.log('candidates', candidates);
                    //console.log('jobOrderCandidates', jobOrderCandidates);


                    var newPdrsRequest = {
                        milestoneName: name,
                        jobOrderCandidateId: null,
                        jobOrderId: null,
                        projectId: null,
                        candidateId: null,
                        itemSMS: [...jobOrderCandidates],
                        defaultMessage: applicantPackSentMessage,
                        onSaved: (isSuccess = true) => {
                            checkMilestone(isSuccess);
                        }
                    };

                    console.log('newPdrsRequest', newPdrsRequest);
                    //console.log('itemInEdit', itemInEdit);

                    ////setIsAlreadySentMessage(true);
                    setPdrsRequest(newPdrsRequest);




                })
                .catch((error) => {
                    setLoading(false);
                    setValidation({
                        title: `Bulk Milestone Editor`,
                        message: `Unexpected error.`
                    });
                    console.error(error);
                });
                //.finally(() => setLoading(false));


            return;

        }


        if (latestMilestoneId !== bulkMilestone.jobOrderMilestoneId) {
            handleSave();
            return;
        }

        handleSave(true);
    }, [bulkMilestone, milestones, jobOrderCandidates]);

    return <Modal
        isOpen={true}
        className="modal-lg"
        modalClassName="db-example-modal-lg"
    >
        <div className="modal-header">
            <h5 className="modal-title">Bulk Milestone Update</h5>
            <button
                aria-label="Close"
                className="close"
                onClick={props.onClose}
                type="button"
            >
                <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
            </button>
        </div>
        <div className="modal-body">
            <Container fluid className="px-0">
                {(loading) && <Loader />}
                {!!ignoredRosterSlotNo?.length &&
                    <Row>
                        <Col xs={12}>
                            <div className="alert alert-danger">Roster Slot with no associated candidate will be ignored. Please check Roster Slot No. {ignoredRosterSlotNoStr}</div>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col xs={12} sm={6} className="pr-1">
                        <FormGroup>
                            <DropdownBox
                                data={milestones}
                                idField="jobOrderMilestoneId"
                                valueField="milestoneName"
                                selectedItem={bulkMilestone}
                                onChange={(item) => {
                                    handleSelectedMilestoneChanged(item);
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} className="pl-2">
                        <FormGroup>
                            <DatePicker
                                name="milestoneDate"
                                id="milestoneDate"
                                type="text"
                                required
                                value={formatDate(bulkMilestone == null ? "" : bulkMilestone.milestoneDate)}
                                onChange={handleDateChanged}
                                closeOnSelect
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={12}>
                        <FormGroup>
                            <Input
                                name="milestoneNote"
                                id="milestoneNote"
                                type="textarea"
                                placeholder="Milestone Note"
                                rows={8}
                                value={bulkMilestone == null ? "" : bulkMilestone.milestoneNote}
                                onChange={handleTextChanged}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button
                                color="primary"
                                onClick={() => {
                                    checkMilestone();
                                }}
                                type="button">
                                Submit Progress <i className="fas fa-caret-right ml-2"></i>
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
            </Container>
        </div>
        <div
            className="modal-footer"
        >
            <Button
                color="secondary"
                onClick={() => {
                    props.onClose()
                }}
                type="button"
            >
                Close
            </Button>
        </div>

        {
            validation ?
                <SweetAlert
                    title={validation.title}
                    error
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    onConfirm={() => setValidation(null)}
                >
                    {validation.message}
                </SweetAlert>
                :
                null
        }
    </Modal>
}

export default BulkMilestoneEditor;
