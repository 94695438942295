import React, { useCallback, useState } from 'react';
import { useTable, useRowSelect, useFlexLayout, useSortBy } from 'react-table';
import api from "../../../../services/api";
import {
    Label, FormGroup, Button, Modal, Container, Row, Col, Input
 } from "reactstrap"; 
import { DropdownBox } from "../../../dropdowns";
import Loader from "../../../loaders";
import moment from "moment";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert, ConfirmMissingMilestonesAlert } from "../../../alerts";
import { TooltipButton } from '../../../inputs';
import fileDownload from "js-file-download";
import ContractDialog from "./ContractDialog";
import ContractTemplate from "./ContractTemplate";
import Grid from "../../../Grid";
import qs from "qs";
import SweetAlert from 'react-bootstrap-sweetalert';
import { DatePicker } from '../../../date-pickers';
import { formatDate, formatDateZeroTime } from '../../../../utils';
import DefinitivOnboardingEditor from './DefinitivOnboardingEditor';
import VacancyEditor from './VacancyEditor';

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, id, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef
  
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])
  
      return (
        <>
            <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" ref={resolvedRef} id={`candidate_${id}`} {...rest} />
                <label className="custom-control-label" htmlFor={`candidate_${id}`}></label>
            </div>            
        </>
      )
    }
);

function EditJobOrder(props) {
    const [ itemInEdit, setItemInEdit ] = React.useState({jobOrderCandidates:[]});
    const [ data, setData ] = React.useState([]);
    const [ loading, setLoading ] = React.useState(false);
    const [ newAssignment, setNewAssignment ] = React.useState(null);
    const [ open, setOpen ] = React.useState(true);
    const [ deleteAlert, setDeleteAlert ] = React.useState([]);   
    const [ missingMilestoneAlert, setMissingMilestoneAlert ] = React.useState([]);   
    const [ recruitmentPeople, setRecruitmentPeople ] = React.useState([]);   
    const [ contractDialogOpen, setContractDialogOpen ] = React.useState(null);
    const [ contractTemplateOpen, setContractTemplateOpen ] = React.useState(null);
    const [ validationItem, setValidationItem ] = useState(null);
    const [ definitivOnboardingCandidates, setDefinitivOnboardingCandidates ] = useState([]);
    const [ definitivOnboardingCompany, setDefinitivOnboardingCompany ] = useState(null);
    const [ definitivOnboardingStartDate, setDefinitivOnboardingStartDate ] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [ openVacancySetting, setOpenVacancySetting ] = useState(false);
    //const [lrf, setLrf] = useState({});

    const loadData = () => {
        setLoading(true);
        
        const apiCalls = [
            api.get(`/joborder/${props.item.jobOrderId}`), // 0
            api.get(`/lookup/recruitmentpeople`), // 1
            api.get(`/lookup/company`), // 2
            //api.get(`/recruitmentrequest/${props.item.jobOrderId}`), // 3
        ];

        Promise.all(apiCalls)
        .then((response) => {
            const jobOrder = response[0].data;
            //var lrf_ = response[3].data;
            setItemInEdit(jobOrder);
            setData(jobOrder.jobOrderCandidates);
            setRecruitmentPeople(response[1].data);
            setCompanies(response[2].data);
            //setLrf(lrf_);
        })
        .catch((error) => console.error("error: ", error))
        .finally(() => setLoading(false));
    }

    React.useEffect(() => { 
        loadData();
    }, [newAssignment, props.item]);

    const cancelJobOrder = React.useCallback(() => {
        let url = `joborder/cancel/${props.item.jobOrderId}`;
        api.post(url)
        .then(response => {
            // console.log(response);
            
            props.onClose();
        }).catch(error => {
            console.error(error)
        }).finally(() => setLoading(false))
    }, [props.item.jobOrderId]);

    const downloadAttachment = React.useCallback((id, type) => {
        setLoading(true);
        let url = `generatedcontract/download/${id}/${type}`;
        api.get(url, { responseType: 'blob' })
        .then(blob => {
            // console.log(blob);
            var filename = /(?<=attachment;.*filename=").*(?=";)/.exec(blob.headers["content-disposition"])[0]
            fileDownload(blob.data, filename);
        }).catch(error => {
            console.error(error)
        }).finally(() => setLoading(false))
    }, [])

    const handleCandidateDelete = (index, item) => {
        const success = () => {
            setDeleteAlert([<DeleteSuccessAlert onConfirm={() => setDeleteAlert([])} />]);
            
            setNewAssignment(item);
            setLoading(false);
        };
        const failure = () => {
            setDeleteAlert([<DeleteFailedAlert onConfirm={() => setDeleteAlert([])} />]);
            setLoading(false);
        };
        const events = {
            onConfirm: () => {
                setDeleteAlert([]);
                setLoading(true);
                // api.delete(`/joborder/${item.jobOrderId}`).then(success).catch(failure)
                var updatedItem = { 
                    ...item, 
                    candidateName: null, 
                    candidateId: null, 
                    latestJobOrderMilestoneId: null,
                    latestJobOrderMilestoneDate: null,
                    jobOrderCandidateMilestones: item.jobOrderCandidateMilestones?.map(o => ({...o, milestoneDate: null}))
                };

                api.put(`/jobordercandidate/${updatedItem.jobOrderCandidateId}`, JSON.stringify(updatedItem))
                .then(success)
                .catch(failure);
            },
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert {...events} />])
    }

    const viewSearchCandidate = (jobOrderCandidateId, index) => {
        setOpen(false);
        setNewAssignment(null);
        props.onViewSearchCandidate(jobOrderCandidateId, index, setOpen, setNewAssignment);
    }

    const viewJobOrderCandidate = (jobOrderCandidateId, index) => {
        // setOpen(false);
        setNewAssignment(null);
        props.onViewJobOrderCandidate(jobOrderCandidateId, index, setOpen, setNewAssignment);
    }

    const viewCandidate = (id) => {
        //setLoading(true);
        // console.log("CandidateId: ", id);
        setOpen(false);
        props.onViewCandidate(id, setOpen)
    }

    const viewCandidateEmail = (id) => {
        //setLoading(true);
        setOpen(false)
        props.onViewCandidateEmail(id, setOpen)
    }

    const viewCandidateEmails = (items) => {
        //setLoading(true);
        setOpen(false)
        props.onViewCandidateEmails(items, setOpen)
    }

    const viewCandidateSMS = (id, jobOrderCandidateId) => {
        //setLoading(true);
        setOpen(false)
        props.onViewCandidateSMS(id, jobOrderCandidateId, setOpen)
    }

    const viewBulkCandidateSMS = (items) => {
        //setLoading(true);
        setOpen(false)
        props.onViewBulkCandidateSMS(items, setOpen)
    }

    const viewBulkMilestone = (items) => {
        //setLoading(true);
        setOpen(false)
        props.onViewBulkMilestone(items, (isOpen) => {
            setOpen(isOpen); 
            loadData();
        })
    }

    const assignToRoster = (item) => {
        setLoading(true);

        api.put(`/jobordercandidate/assigntoroster?id=${item.jobOrderCandidateId}&candidateid=${item.candidateId}`)
            .then(() => item.candidateAssigned = true)
            .catch((error) => {
                setValidationItem({
                    type: "error",
                    title: "Assignment Failed",
                    jobOrderCandidateId: item.jobOrderCandidateId,
                    message: error.response.data,
                });
            })
            .finally(() => setLoading(false))
        ;
    }

    const withdraw = (item) => {
        // console.log(item);
        setValidationItem({
            type: "warning",
            title: "Withdrawal Confirmation",
            jobOrderCandidateId: item.jobOrderCandidateId,
            message: `Are you sure you want to withdraw ${item.candidateName}?`,
            urlParameter: "withdraw"
        });
    }

    const unsuccessful = (item) => {
        setValidationItem({
            type: "warning",
            title: "Unsuccessful Confirmation",
            jobOrderCandidateId: item.jobOrderCandidateId,
            message: `Are you sure you want to cancel ${item.candidateName}?`,
            urlParameter: "unsuccessful"
        });
    }

    const hold = (item) => {
        setValidationItem({
            type: "warning",
            title: "On Hold Confirmation",
            jobOrderCandidateId: item.jobOrderCandidateId,
            message: `Are you sure you want to put ${item.candidateName} on hold?`,
            urlParameter: "hold"
        });
    }

    const handleCancellation = useCallback(() => {
        const currentValidationItem = {...validationItem};
        setValidationItem(null);
        setLoading(true);

        api.put(`/jobordercandidate/${validationItem.urlParameter}?id=${validationItem.jobOrderCandidateId}`)
            .then(() => loadData())
            .catch((error) => {
                console.error(error);
                setValidationItem({
                    type: "error",
                    title: "System Error",
                    jobOrderCandidateId: currentValidationItem.jobOrderCandidateId,
                    message: "An Error Occured, please contact Admin.",
                });
                setLoading(false);
            })
            .finally(() => {
                setValidationItem(null);
            })
        ;
    }, [validationItem]);

    const handleSendToDefinitivOnboarding = useCallback(() => {
        if (!definitivOnboardingCandidates?.length || !definitivOnboardingCompany?.lookupId || !definitivOnboardingStartDate)
            return;

        setLoading(true);

        const data = {
            candidateIds: definitivOnboardingCandidates.map(c => c.candidateId),
            designatedCompanyId: definitivOnboardingCompany.lookupId,
            hireDate: definitivOnboardingStartDate
        };

        api.post(`/joborder/definitivonboarding`, JSON.stringify(data))
            .then(() => loadData())
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
            .finally(() => {
                setValidationItem(null);
            })
        ;
    }, [definitivOnboardingCandidates, definitivOnboardingCompany, definitivOnboardingStartDate]);
    
    const columns = React.useMemo(() => {
        return [
            {
                Header: "Id",
                width: 100,
                accessor: "jobOrderCandidateId",
            },
            {
                Header: "Roster Slot No.",
                accessor: (row) => row.rosterCandidate ? (row.rosterCandidate.relatedRosterCandidateId ? `${row.rosterCandidate.relatedRosterCandidateNumber}.${row.rosterCandidate.rosterCandidateNumber}` : `${row.rosterCandidate.rosterCandidateNumber}`) : null,
            },
            {
                Header: "Employed Position",
                accessor: (row) => row.position ? row.position.positionName : null,
            },
            {
                Header: "Talent Name",
                accessor: "candidateName",
            },
            {
                Header: "Mobile",
                accessor: "candidate.mobilePhoneNumber",
            },
            {
                Header: "Email",
                accessor: "candidate.email",
            },
            {
                Header: "Start Date",
                accessor: (row) => row.startDate ? moment(row.startDate).format("DD-MM-YYYY") : null,
            },
            {
                Header: "End Date",
                accessor: (row) => row.endDate ? moment(row.endDate).format("DD-MM-YYYY") : null,
            },
            {
                Header: "Latest Milestone Date",
                width: 180,
                canResize: false,
                // canResize: false,
                Cell: ({row:{original}}) => (
                    original.latestJobOrderMilestoneDate != null ? moment(original.latestJobOrderMilestoneDate).format("DD.MM.YYYY") : ""
                )
            },
            {
                Header: "Latest Milestone",
                Cell: ({row:{original}}) => (
                    original.candidate != null ? original.latestJobOrderMilestone != null ? original.latestJobOrderMilestone.milestoneName : "New" : ""
                )
            },
            {
                Header: "Status",
                Cell: ({row:{original}}) => {
                    const withdrawn = original.status?.lookupValue === "Withdrawn";
                    const unsuitable = original.status?.lookupValue === "Candidate Unsuitable";
                    // console.log({original, withdrawn});

                    return (
                        <div className={withdrawn ? "red text-white" : unsuitable ? "maroon text-white" : "text-dark"}>
                            {original.status?.lookupValue}
                        </div>
                    )
                }
            },
            {
                Header: "Point of Hire",
                accessor: "pointOfHire",
            },
            {
                id: 'buttons',
                Header: "Action",
                width: 210,
                canResize: false,
                Cell: ({row:{original, index}}) => (
                    original.candidate != null && original.status?.lookupValue !== "Withdrawn" && original.status?.lookupValue !== "Candidate Unsuitable" ?
                    <>
                        <div className={`d-flex flex-wrap justify-content-${itemInEdit?.isCancelled ? "start" : "center"} align-items-center w-100`}>
                            <TooltipButton
                                id={`milestones_${index}`}
                                title="Milestone Progress"
                                className="btn-icon mb-2"
                                color="default"
                                size="xsm"
                                type="button"
                                onClick={() => viewJobOrderCandidate(original.jobOrderCandidateId, index)}
                            >
                            <i className="fas fa-folder-open"></i>
                            </TooltipButton>
                        {
                            !itemInEdit?.isCancelled &&
                            <>
                                <TooltipButton
                                    id={`contract_${index}`}
                                    title="Contract Options"
                                    className="btn-icon mb-2"
                                    color="default"
                                    size="xsm"
                                    type="button"
                                    onClick={() => {
                                        setContractDialogOpen({...original, projectName: props.item.projectName, projectId: props.item.projectId})
                                    }}
                                >
                                    <i className="fas fa-file-signature"></i>
                                </TooltipButton>
                                <TooltipButton
                                    id={`contract_docx_${index}`}
                                    title="Download Contract (Word)"
                                    className="btn-icon mb-2"
                                    color="default"
                                    size="xsm"
                                    type="button"
                                    disabled={!original.generatedContractId}
                                    onClick={() => {
                                        downloadAttachment(original.generatedContractId, "docx")
                                    }}
                                >
                                    <i className="fas fa-file-word"></i>
                                </TooltipButton>
                                <TooltipButton
                                    id={`contract_pdf_${index}`}
                                    title="Download Contract (PDF)"
                                    className="btn-icon mb-2"
                                    color="default"
                                    size="xsm"
                                    type="button"
                                    disabled={!original.generatedContractId}
                                    onClick={() => {
                                        downloadAttachment(original.generatedContractId, "pdf")                            
                                    }}
                                >
                                    <i className="fas fa-file-pdf"></i>
                                </TooltipButton>
                                <TooltipButton
                                    id={`sendsms_${index}`}
                                    title="Send SMS"
                                    className="btn-icon mb-2"
                                    color="default"
                                    size="xsm"
                                    type="button"
                                    onClick={() => viewCandidateSMS(original.candidateId, original.jobOrderCandidateId)}
                                >
                                    <i className="fas fa-comment"></i>
                                </TooltipButton>
                                <TooltipButton
                                    id={`sendemail_${index}`}
                                    title="Send Email"
                                    className="btn-icon mb-2"
                                    color="default"
                                    size="xsm"
                                    type="button"
                                    onClick={() => viewCandidateEmail(original.candidateId)}
                                >
                                    <i className="fas fa-envelope-open"></i>
                                </TooltipButton>
                            </>
                        }
                        </div>
                    {
                        !itemInEdit?.isCancelled &&
                        <div className="d-flex flex-wrap justify-content-center align-items-center w-100">
                            <TooltipButton 
                                id={`candidate_${index}`}
                                title= "View Talent"
                                className="btn-icon mb-2"
                                color="default"
                                size="xsm"
                                type="button"
                                onClick={()=> {
                                    viewCandidate(original.candidateId)
                                }}
                            >
                                <i className="fas fa-cog"></i>
                            </TooltipButton>
                            <TooltipButton 
                                id={`roster_${index}`}
                                title= "Assign to Roster"
                                className="btn-icon mb-2"
                                color="default"
                                size="xsm"
                                type="button"
                                disabled={!original.candidateId || !original.rosterCandidate || original.candidateAssigned}
                                onClick={()=> {
                                    assignToRoster(original)
                                }}
                            >
                                <i className="fas fa-calendar-alt"></i>
                            </TooltipButton>
                            <TooltipButton 
                                id={`stop_${index}`}
                                title= "Withdraw"
                                className="btn-icon mb-2"
                                color="default"
                                size="xsm"
                                type="button"
                                disabled={original.candidateAssigned}
                                onClick={()=> {
                                    withdraw(original)
                                }}
                            >
                                <i className="fas fa-ban"></i>
                            </TooltipButton>
                            <TooltipButton 
                                id={`unsuccessful_${index}`}
                                title= "Unsuccessful"
                                className="btn-icon mb-2"
                                color="default"
                                size="xsm"
                                type="button"
                                disabled={original.candidateAssigned}
                                onClick={()=> {
                                    unsuccessful(original)
                                }}
                            >
                                <i className="fas fa-eraser"></i>
                            </TooltipButton>
                            <TooltipButton 
                                id={`hold_${index}`}
                                title= "On Hold"
                                className="btn-icon mb-2"
                                color="default"
                                size="xsm"
                                type="button"
                                disabled={original.candidateAssigned}
                                onClick={()=> {
                                    hold(original)
                                }}
                            >
                                <i className="fas fa-hand-paper"></i>
                            </TooltipButton>
                            <TooltipButton 
                                id={`delete_${index}`}
                                title="Clear Talent"
                                className="btn-icon mb-2"
                                color="warning"
                                size="xsm"
                                type="button"
                                onClick={
                                    ()=> handleCandidateDelete(index, original)
                                }
                            >
                                <i className="fas fa-trash-alt"></i>
                            </TooltipButton>
                        </div>
                    }
                    </>
                    :
                    original.status?.lookupValue !== "Withdrawn" && original.status?.lookupValue !== "Candidate Unsuitable" && !itemInEdit?.isCancelled ?
                    <>
                        <Button
                            className="btn-block"
                            size="xsm"
                            type="button"
                            onClick={() => viewSearchCandidate(original.jobOrderCandidateId, index)}
                        >
                            <i className="fas fa-search mr-3"></i>Search Talent
                        </Button>
                    </>
                    :
                    null
                )
            }
        ]
    }, [itemInEdit]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,     
        selectedFlatRows,  
    } = useTable(
        {
            columns,
            data,
            initialState: {
                // "selectedRowIds": {},
                "hiddenColumns": []
            },
            // defaultColumn
        },
        useFlexLayout,
        hooks => {
          hooks.visibleColumns.push(columns => [
            // Let's make a column for selection
            {
              id: 'selection',
              width: 30,
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} id="all" />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div>
                    {row.original.candidate != null &&
                      <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} id={row.original.candidateId} />
                    }
                </div>
              ),
            },
            ...columns,
          ])
        },
        useRowSelect,
    )

    const updateStatus = (callback = null) => {
        return api.put(`/joborder/complete/${itemInEdit.jobOrderId}/${itemInEdit.completionDate === null}`)
        .then((response) => {
            setItemInEdit({...itemInEdit, completionDate: response.data === "" ? null : response.data})
        })
        .catch((error) => console.error(error))
        .finally(() => {
            setLoading(false);
            if (callback) {
                callback();
            }
        });
    };

    return (
        <>
            {deleteAlert.length > 0 && deleteAlert}
            {missingMilestoneAlert.length > 0 && missingMilestoneAlert}
            {!!contractDialogOpen && 
                <ContractDialog 
                    jobOrderCandidate={contractDialogOpen}
                    onClose={() => {
                        setContractDialogOpen(null);
                        loadData();
                    }}
                />
            }
            {!!contractTemplateOpen && 
                <ContractTemplate 
                    jobOrderCandidates={contractTemplateOpen}
                    onClose={() => {
                        setContractTemplateOpen(null);
                        loadData();
                    }}
                />
            }
            {
                validationItem &&
                <SweetAlert
                    title={validationItem.title}
                    error={validationItem.type === "error"}
                    warning={validationItem.type === "warning"}
                    confirmBtnText={validationItem.type === "warning" ? "Yes" : "OK"}
                    confirmBtnBsStyle="danger"
                    cancelBtnText={validationItem.type === "warning" ? "Cancel" : null}
                    onConfirm={() => handleCancellation()}
                    onCancel={validationItem.type === "warning" ? () => setValidationItem(null) : null}
                    showCancel={validationItem.type === "warning"}
                >
                {
                    validationItem.message
                }
                </SweetAlert>
            }
            <Modal
                isOpen={open}
                className="modal-xl m-4"
                modalClassName="modal-fullwidth"
                style={{marginBottom:"200px", height: '100%'}}
            >
                <div
                    className="modal-header"
                >
                    <h5 className="modal-title">Job Order Editor -&nbsp;              
                    {itemInEdit?.projectName} <i className="fas fa-angle-right ml-1 mr-1"></i>
                    {itemInEdit?.recruitLocation} <i className="fas fa-angle-right ml-1 mr-1"></i>
                    {itemInEdit?.hrispositionMasterName}
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        onClick={props.onClose}
                        type="button"
                    >
                        <span aria-hidden={true}><i className="fas fa-times-circle"/></span>
                    </button>
                </div>
                <div
                    className="modal-body" style={{height: 'calc(100vh - 210px)'}}
                >
                    <Container fluid>
                        {(loading) && <Loader />}
                    {
                        itemInEdit ?
                        <Row className="mb-2">
                            <Col lg={12} className="pl-2">
                                <div className="bg-info w-100 py-2 px-3 rounded">
                                    <span className="text-white" style={{fontSize: "0.85rem"}}>
                                        Dispatch Unit: {itemInEdit.dispatchUnitName ?? 'N/A'};
                                        Expected Start Date: {itemInEdit.startDateExpected ? moment(itemInEdit.startDateExpected).format("DD.MM.YYYY") : "N/A"};
                                        Planned End Date: {itemInEdit.finishDatePlanned ? moment(itemInEdit.finishDatePlanned).format("DD.MM.YYYY") : "N/A"};
                                        Desired Entity: {itemInEdit.desiredEntity ?? 'N/A'};
                                        Reporting To: {itemInEdit.reportingToName ?? 'N/A'};
                                        Work Location: {itemInEdit.workLocation ?? 'N/A'};
                                        Expected Start Time: {itemInEdit.expectedStartTime ? moment(itemInEdit.expectedStartTime, 'HH:mm:ss').format("HH:mm") : "N/A"};
                                        Expected Finish Time: {itemInEdit.expectedFinishTime ? moment(itemInEdit.expectedFinishTime, 'HH:mm:ss').format("HH:mm") : "N/A"};
                                        Scope of Work: {itemInEdit.scopeOfWork ?? 'N/A'};
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        :
                        null
                    }
                        <Row>
                            <Col xs={4} className="pl-2">
                                <FormGroup>
                                    <Label>
                                        Recruitment Person
                                    </Label>
                                    <DropdownBox 
                                        data={recruitmentPeople}
                                        selectedItem={itemInEdit.recruitmentPerson}
                                        onChange={(item) => {
                                            setLoading(true);
                                            setItemInEdit( { ...itemInEdit, recruitmentPerson: item, recruitmentPersonId: item ? item.lookupId : null } );
                                            api.put(`/joborder/person/${props.item.jobOrderId}`, JSON.stringify({
                                                jobOrderId: props.item.jobOrderId,
                                                recruitmentPersonId: item ? item.lookupId : null
                                            }))
                                            .then((response) => { })
                                            .catch((error) => console.error("error: ", error))
                                            .finally(() => setLoading(false));
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        {
                            itemInEdit?.publishStartDate !== null &&
                            <Col xs={8} className="d-flex flex-wrap align-items-center justify-content-start">
                                <div className="h-50 px-3 bg-warning d-flex flex-wrap align-items-center rounded">
                                    <Label className="m-0">
                                        <span className="text-white"><i className="fas fa-info-circle mr-2" /><i>Vacancies are open from {moment(itemInEdit.publishStartDate).format("DD MMMM YYYY")} to {moment(itemInEdit.publishEndDate).format("DD MMMM YYYY")}</i></span>
                                    </Label>
                                </div>
                            </Col>
                        }
                        </Row>
                        <Row>
                        {
                            !itemInEdit?.isCancelled &&
                            <>
                                <Button
                                    className="btn-icon ml-2 mb-3"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    disabled={!selectedFlatRows.length}
                                    onClick={() => {
                                        const ids = selectedFlatRows.map(d => d.original.jobOrderCandidateId);
                                        viewBulkMilestone(ids)
                                    }}
                                >
                                    <i className="fas fa-folder-open"></i> Milestone Progress
                                </Button>
                                <Button
                                    className="btn-icon ml-2 mb-3"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    disabled={!selectedFlatRows.length}
                                    onClick={() => viewBulkCandidateSMS(selectedFlatRows.map(d => ({...d.original.candidate, jobOrderCandidateId: d.original.jobOrderCandidateId})))}
                                    // onClick={() => { 
                                    //     setItemSMS(selectedFlatRows.map(d => d.original))
                                    // }}
                                >
                                    <i className="fas fa-comment mr-1"></i> Send SMS
                                </Button>
                                <Button
                                    className="btn-icon ml-2 mb-3"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    disabled={!selectedFlatRows.length}
                                    onClick={() => 
                                        viewCandidateEmails(selectedFlatRows.map(d => d.original.candidate))
                                    }
                                >
                                    <i className="fas fa-envelope-open"></i> Send Email
                                </Button>
                                {/* <Button
                                    className="btn-icon ml-2 mb-3"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        if (itemInEdit.completionDate === null) {
                                            setLoading(true);
                                            api.get(`/joborder/status/${itemInEdit.jobOrderId}`)
                                            .then(({ status, data }) => {
                                                console.log({status,data});
                                                if (status === 204) {
                                                    updateStatus();
                                                    return;
                                                }

                                                setMissingMilestoneAlert([
                                                    <ConfirmMissingMilestonesAlert 
                                                        data={data}
                                                        onConfirm={() => {
                                                            updateStatus(() => setMissingMilestoneAlert([]));                                                
                                                        }}
                                                        onCancel={() => {
                                                            setMissingMilestoneAlert([])
                                                        }}
                                                    />
                                                ])

                                            })
                                            .catch((error) => console.error(error))
                                            .finally(() => setLoading(false));
                                        } else {
                                            updateStatus();
                                        }
                                    }}
                                >
                                    <i className={`fas fa-${itemInEdit.completionDate === null ? "check" : "times"}`}></i> {itemInEdit.completionDate === null ? "Mark Complete" : "Mark Incomplete"}
                                </Button> */}
                                <Button
                                    className="btn-icon ml-2 mb-3"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    disabled={!selectedFlatRows.length}
                                    onClick={() => {
                                        setContractTemplateOpen([...selectedFlatRows.map(d => d.original.jobOrderCandidateId)])
                                    }}
                                >
                                    <i className="fas fa-file-signature mr-1"></i> Generate Contracts
                                </Button>
                                <Button
                                    className="btn-icon ml-2 mb-3"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    disabled={!selectedFlatRows.length}
                                    onClick={() => {
                                        setDefinitivOnboardingCandidates([...selectedFlatRows.filter(c => c.original.candidateId && c.original.status.lookupValue !== "Withdrawn" && c.original.status.lookupValue !== "Candidate Unsuitable").map(c => c.original)]);
                                    }}
                                >
                                    <i className="fas fa-upload"></i> Send to Definitiv Onboarding
                                </Button>
                                <Button
                                    className="btn-icon ml-2 mb-3"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        const candidateIds = [];

                                        if (selectedFlatRows?.length > 0)
                                        {
                                            selectedFlatRows.forEach(d => {
                                                if (!d.original.candidateId)
                                                    return;
                                                
                                                candidateIds.push(d.original.candidateId);
                                            })
                                        }

                                        window.open(`/tnadiscrepancy-joborder/${props.item.jobOrderId}${candidateIds.length > 0 ? `?candidateIds=${candidateIds.join("-")}` : ""}`, '_blank');
                                    }}
                                >
                                    <i className="fas fa-external-link-alt"></i> TNA Discrepancy Report
                                </Button>
                                <Button
                                    className="btn-icon ml-2 mb-3"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        setOpenVacancySetting(true);
                                    }}
                                    disabled={data?.filter(d => !d.candidateId).length === 0}
                                >
                                    <i className="fas fa-door-open mr-1"></i> Vacancies Setting
                                </Button>
                                <Button
                                    className="btn-icon ml-2 mb-3"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        cancelJobOrder();
                                    }}
                                >
                                    <i className="fas fa-ban mr-1"></i> Cancel
                                </Button>
                            </>
                        }
                        </Row>
                        <Row>
                            <Container fluid>
                                <Grid
                                    height='calc(100vh - 500px)'
                                    rows={rows}
                                    tableProps={getTableProps()}
                                    headerGroups={headerGroups}
                                    tableBodyProps={getTableBodyProps()}
                                    prepareRow={prepareRow}
                                />
                            </Container>
                            
                            {/* <Col xs={12}>
                                <div {...getTableProps()} className="table flex table-hover scrollable header-center">
                                    <div className="thead">
                                        {headerGroups.map(headerGroup => (
                                            <div {...headerGroup.getHeaderGroupProps()} className="tr">
                                            {headerGroup.headers.map(column => (
                                                <div {...column.getHeaderProps()} className="th">{column.render('Header')}</div>
                                            ))}
                                            </div>
                                        ))}
                                    </div>
                                        <div {...getTableBodyProps()} className="tbody">
                                            {rows.map((row, i) => {
                                                prepareRow(row);
                                                
                                                return (
                                                <div {...row.getRowProps()} className={`tr ${state.selectedRowIds[row.id] ? "selected" : ""}`}
                                                >
                                                    {row.cells.map(cell => {
                                                        return <div {...cell.getCellProps()} className="td">{cell.render('Cell')}</div>
                                                    })}
                                                </div>
                                                )
                                            })}
                                        </div>

                                </div>
                            </Col> */}
                        </Row>
                        {/* <Table className="scrollable">
                            <thead>
                                <tr>
                                    <th className="">Candidate</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Latest Milestone</th>
                                    <th>Date</th>
                                    <th className="th-action-250 text-right">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {itemInEdit.jobOrderCandidates && itemInEdit.jobOrderCandidates.length > 0 && itemInEdit.jobOrderCandidates.map(renderTableRow)}
                            </tbody>
                            </Table> */}
                            
                    </Container>
                </div>
                <div
                    className="modal-footer"
                >
                    <Button
                        color="secondary"
                        onClick={props.onClose}
                        type="button"
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        {
            definitivOnboardingCandidates?.length &&
            <DefinitivOnboardingEditor
                selectedCandidates={[...definitivOnboardingCandidates]}
                onClose={() => setDefinitivOnboardingCandidates([])}
                onSaved={() => {
                    setDefinitivOnboardingCandidates();
                    loadData();
                }}
                companies={[...companies]}
            />
        }
        {
            openVacancySetting &&
            <VacancyEditor
                onClose={() => setOpenVacancySetting(false)}
                onSave={() => {
                    setOpenVacancySetting(false);
                    loadData();
                }}
                jobOrder={itemInEdit}
            />
        }
        </>
    )
}

export default EditJobOrder;