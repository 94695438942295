import React from 'react';
import api from "../../../../services/api";
import qs from "qs";
import {
   Container, Row, Col, CardTitle
} from "reactstrap";
import LookupEditor from "./Editor";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../../alerts";
import { TooltipButton } from '../../../inputs';
import { DefaultColumnFilter } from "../../../react-table/filters";
import { useTable, useExpanded, useFilters, useSortBy, useFlexLayout } from 'react-table';
import Loader from "../../../loaders";
import Grid from "../../../Grid";

export default function List(props) {

    const [ data, setData ] = React.useState([]);
    const [ loading, setLoading ] = React.useState(false);
    const [ itemInEdit, setItemInEdit ] = React.useState(null);
    const [ filters, setFilters ] = React.useState([]);

    const defaultSort = React.useMemo(() => [{ id: "value", desc: false }],
        []
    );
    
    const [ sortBy, setSortBy ] = React.useState(defaultSort);

    const [ pageNumber, setPageNumber ] = React.useState(1);
    const [ pageSize, setPageSize ] = React.useState(10);
    const [ totalRecords, setTotalRecords ] = React.useState(0);
    const [ deleteAlert, setDeleteAlert ] = React.useState([]);
    const [ columnsToShow, setColumnsToShow ] = React.useState([]);
    const skipPageResetRef = React.useRef();
    const apiurl = "lookup";
    
    const loadData = React.useCallback(() => {
        const queryString = qs.stringify({filters, sortBy}, { allowDots: true });
        skipPageResetRef.current = true;
        setItemInEdit(null);
        setData([]);

        setLoading(true);
        api.get(`/${apiurl}/${props.selectedType.lookupType}/${(pageNumber - 1) * pageSize}/${pageSize}${queryString ? `?${queryString}` : ""}`)
        .then(({data}) => {
            setData(data.data);
            setTotalRecords(data.total);
        }).catch((error) => {
            console.error("error: ", error);
        }).finally(() => setLoading(false));
    }, [filters, sortBy, pageNumber, pageSize, props.selectedType.lookupType]);
    
    const handleDelete = (item) => {
        const success = () => {
            setLoading(false);
            setDeleteAlert([<DeleteSuccessAlert onConfirm={() => setDeleteAlert([])} />]);
            if (data.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1);
            } else {
                loadData();
            }
        };
        const failure = () => {
            setLoading(false);
            setDeleteAlert([<DeleteFailedAlert onConfirm={() => setDeleteAlert([])} />]);
        };
        const events = {
            onConfirm: () => {
                setLoading(true);
                api.delete(`/${apiurl}/${item.lookupId}`).then(success).catch(failure);
            },
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert {...events} />])
    }

    React.useEffect(() => {        
         loadData();
    }, [props.selectedType, pageNumber, pageSize, filters, sortBy]);

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
                })
            },
        }),
        []
    )

    const columns = React.useEffect(() => {
        const cols = [
            {
                Header: `${props.selectedType.name} Name`,
                id: "value",
                accessor: (row) => row.lookupValue,
                filter: "text",
                width: 300,
                disableSortBy: false,
            },
            {
                Header: `${props.selectedType.name} Description`,
                id: "label",
                accessor: (row) => row.lookupLabel,
                filter: "text",
                width: 300,
                disableSortBy: false,
            },
            {
                Header: "Enabled",
                accessor: (row) => row.hidden ? "No" : "Yes",
                filter: "text",
                width: 150,
                disableFilters: true,
            },
            {
                Header: "Actions",
                id: 'button',
                Cell: ( { row: { original } } ) =>
                    (
                        <div>
                            <TooltipButton
                                id={`edit_${original.lookupId}`}
                                title="Edit"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={() => setItemInEdit(original)}
                            >
                                <i className="fas fa-pencil-alt pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`delete_${original.lookupId}`}
                                title="Delete"
                                className="btn-icon"
                                color="warning"
                                size="sm"
                                type="button"
                                onClick={() => handleDelete(original)}
                            >
                                <i className="fas fa-trash-alt pt-1"></i>
                            </TooltipButton>
                        </div>
                    ),
                width: 140,
            },
        ];
        setColumnsToShow(cols);
    }, [props.selectedType]);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,       
    } = useTable(
        {
            columns: columnsToShow,
            data,
            initialState: {
                sortBy: defaultSort,
                canSort: true,
            },
            filterTypes,
            defaultColumn,
            manualSortBy: true,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
        useExpanded
    );
    
    React.useEffect(() => {
        setFilters(state.filters);
    }, [state.filters]);
    
    React.useEffect(() => {
        setSortBy(state.sortBy);
    }, [state.sortBy]);

    return (
        <>
            {loading && <Loader />}
            {deleteAlert.length > 0 && deleteAlert}
            <Container fluid>
                <Row>
                    <Col>
                        <CardTitle>
                            <h4 className="text-center">{props.selectedType.name}</h4>
                        </CardTitle>
                        <TooltipButton                        
                                    id="addnew"
                                    title="Add New"
                            className="btn-icon ml-2 mb-2"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => {  
                                setItemInEdit({lookupType: props.selectedType.lookupType, hidden: false});
                            }}
                        >
                            <i className="fas fa-plus pt-1"></i>
                        </TooltipButton>
                        <Grid
                        height='calc(100vh - 460px)'
                            totalRecords={totalRecords}
                            pageSize={pageSize}
                            rows={rows}
                            tableProps={getTableProps()}
                            headerGroups={headerGroups}
                            tableBodyProps={getTableBodyProps()}
                            prepareRow={prepareRow}
                            onPagerChangePage={(pager) => {
                                setPageNumber(pager.currentPage);
                            }}
                            onPagerChangePageSize={(size) => {
                                setPageSize(size);
                            }}
                            needPaging={true}
                        />
                        {
                            !!itemInEdit && 
                            <LookupEditor
                                title={props.selectedType.name}
                                item={itemInEdit} 
                                onClose={() => setItemInEdit(null)} 
                                onSaved={() => loadData()}
                            />
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )

}