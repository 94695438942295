import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from "reactstrap";

const Unauthorized = () => {
  return (
        <div className="wrapper">
            <div className="page-header error-page">
                <Container>
                    <Row>
                    <Col className="text-center" md="12">
                        <h1 className="title">403</h1>
                        <p className="lead">Unauthorized</p>
                        <h4 className="description text-primary">
                            You don't have sufficient permissions to view this page.
                        </h4>
                        <p><Link to='/'>Back to Home</Link></p>
                    </Col>
                    </Row>
                </Container>
            </div>
        </div>

  )
}

export default Unauthorized;