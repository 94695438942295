import React, { useMemo, useCallback, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button, Col, Container, FormGroup, Input, Label, Modal, Row, Spinner } from 'reactstrap';
import { EditorState, ContentState, convertToRaw, SelectionState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { useTable, useFlexLayout, useFilters, useSortBy } from 'react-table';
import jsonFormData from "json-form-data";
import { DatePicker } from '../../../date-pickers';
import Loader from '../../../loaders';
import api from "../../../../services/api";
import fileDownload from "js-file-download";
import moment from 'moment';
import { FileDropzone, DropzoneList } from '../../../FileDropzone';
import { DefaultColumnFilter, DateColumnFilter } from '../../../react-table/filters';
import { TooltipButton } from '../../../inputs';
import Grid from '../../../Grid';
import { currencyFormatterAUD, formatDate, momentToDateOnly } from '../../../../utils';
import { RemoveScriptTags, FormatFileSize } from '../../../../helpers/StringHelper'

export default function VacancyPreview(props) {

    // Props
    const { onClose, jobOrder, refresh, itemInEdit } = props;


    // Regular variables
    const [loading, setLoading] = useState(false);

    const [summary, setSummary] = useState(null);
    const [downloadingDocument, setDownloadingDocument] = useState(null);
    const [showApplied, setShowApplied] = useState(false);

    //const { id } = qs.parse(location.search, { ignoreQueryPrefix: true });

    const id = useMemo(() => {
        if (!jobOrder) {
            return null;
        }
        console.log('id', jobOrder.identifier);
        return jobOrder.identifier;
    }, [jobOrder]);

    const loadData = useCallback(() => {
        setLoading(true);

        api.get(`joborder/vacancy-preview/${id}`)
            .then((response) => {
                // console.log({response});
                setSummary(response.data ? {
                    ...response.data,
                    startDate: response.data.startDate ? moment(response.data.startDate).format("DD-MM-YYYY") : null,
                    endDate: response.data.endDate ? moment(response.data.endDate).format("DD-MM-YYYY") : null,
                    publishStartDate: response.data.publishStartDate ? moment(response.data.publishStartDate).format("DD-MM-YYYY") : null,
                    publishEndDate: response.data.publishEndDate ? moment(response.data.publishEndDate).format("DD-MM-YYYY") : null,
                } : null);
            })
            .catch((error) => console.log({ error }))
            .finally(() => setLoading(false))
            ;
    }, [id]);

    const vacancyDescription = useMemo(() => {
        var newVacDesc = summary?.vacancyDescription ?? "N/A";
        if (newVacDesc == 'N/A') return newVacDesc;
        newVacDesc = RemoveScriptTags(newVacDesc);
        return newVacDesc;
    }, [summary?.vacancyDescription]);


    useEffect(() => {
        if (!downloadingDocument) {
            return;
        }

        setLoading({ type: true });
        var url = `joborder/vacancy-attachment-by-id/${downloadingDocument.jobOrderVacancyAttachmentId}-${downloadingDocument.jobOrderId}/download/`;
        var filename = `${downloadingDocument.filename}`;


        api.get(url, { responseType: 'blob' })
            .then(blob => {
                // console.log(blob);
                //var filename = /(?<=attachment;.*filename=").*(?=";)/.exec(blob.headers["content-disposition"])[0];
                fileDownload(blob.data, filename);
            }).catch(error => {
                console.error(error);
                //setErrorMessage(`${errorMessageOpening} ${error.message} `);
                //setLoading(false);
            }).finally(() => setLoading({ type: false }))

        setDownloadingDocument(null);

    }, [downloadingDocument]);

    const vacancyName = useMemo(() => {
        return summary?.jobOrderNavigation.position.lookupValue ?? "";
    }, [summary]);




    useEffect(() => {
        if (!id)
            return;

        loadData();
    }, [id]);




    return (
        <Modal
            isOpen={true}
            className={'modal-xl'}
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Vacancies Preview
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body"
            >

                {(loading) && <Loader />}

                <Row className="w-100 p-0 m-0 mb-4">
                    <Col lg="12" className="d-flex flex-wrap justify-content-center align-items-center">
                        <h5 className="h-100 m-0 d-flex flex-wrap align-items-center justify-content-center">
                            <b className="text-uppercase">{vacancyName}</b>
                        </h5>
                    </Col>
                </Row>
                <Row className="w-100 m-0 p-0 py-4 d-flex-flex-wrap-justify-content-center">
                    <Col lg={12} md={12}>
                        <Row className="w-100 m-0 mt-2">
                            <Col lg={6} md={12} className="d-flex flex-wrap justify-content-start align-items-start">
                                <Col lg={4} className="m-0 p-0">
                                    <span className="font-sm-custom">
                                        Project
                                    </span>
                                </Col>
                                <Col lg={8} className="m-0 p-0 pl-2">
                                    <span className="font-sm-custom">
                                        <b className="text-dark">{summary?.jobOrderNavigation.recruitmentRequest.project.lookupValue ?? "-"}</b>
                                    </span>
                                </Col>
                            </Col>
                            <Col lg={6} md={12} className="d-flex flex-wrap justify-content-start align-items-center">
                                <Col lg={4} className="m-0 p-0">
                                    <span className="font-sm-custom">
                                        Location
                                    </span>
                                </Col>
                                <Col lg={8} className="m-0 p-0 pl-2">
                                    <span className="font-sm-custom"><b className="text-dark">{summary?.jobOrderNavigation.recruitmentRequest.location.lookupValue ?? "-"}</b></span>
                                </Col>
                            </Col>
                        </Row>
                        <Row className="w-100 m-0">
                            <Col lg={6} md={12} className="d-flex flex-wrap justify-content-start align-items-start">
                                <Col lg={4} className="m-0 p-0">
                                    <span className="font-sm-custom">
                                        Scope of Work
                                    </span>
                                </Col>
                                <Col lg={8} className="m-0 p-0 pl-2">
                                    <span className="font-sm-custom"><b className="text-dark">{summary?.jobOrderNavigation.scopeOfWork ?? "-"}</b></span>
                                </Col>
                            </Col>
                            <Col lg={6} md={0}>
                            </Col>
                        </Row>
                        <Row className="w-100 m-0">
                            <Col lg={6} md={12} className="d-flex flex-wrap justify-content-start align-items-start">
                                <Col lg={4} className="m-0 p-0">
                                    <span className="font-sm-custom">
                                        Expected Start
                                    </span>
                                </Col>
                                <Col lg={8} className="m-0 p-0 pl-2">
                                    <span className="font-sm-custom"><b className="text-dark">{summary?.startDate ?? "-"}</b></span>
                                </Col>
                            </Col>
                            <Col lg={6} md={12} className="d-flex flex-wrap justify-content-start align-items-start">
                                <Col lg={4} className="m-0 p-0">
                                    <span className="font-sm-custom">
                                        Expected End
                                    </span>
                                </Col>
                                <Col lg={8} className="m-0 p-0 pl-2">
                                    <span className="font-sm-custom"><b className="text-dark">{summary?.endDate ?? "-"}</b></span>
                                </Col>
                            </Col>
                        </Row>
                        <Row className="w-100 m-0 mt-3">
                            <Col lg={6} md={12} className="d-flex flex-wrap justify-content-start align-items-start">
                                <Col lg={4} className="m-0 p-0">
                                    <span className="font-sm-custom">
                                        Rates
                                    </span>
                                </Col>
                                <Col lg={8} className="m-0 p-0 pl-2">
                                    <span className="font-sm-custom"><b className="text-dark">{currencyFormatterAUD(summary?.fulltimeRate) ?? "-"}</b></span>
                                </Col>
                            </Col>
                            <Col lg={6} md={12} className="d-flex flex-wrap justify-content-start align-items-start">
                            </Col>
                        </Row>
                        <Row className="w-100 m-0">
                            <Col lg={6} md={12} className="d-flex flex-wrap justify-content-start align-items-start">
                                <Col lg={4} className="m-0 p-0">
                                    <span className="font-sm-custom">
                                        Vacancy End
                                    </span>
                                </Col>
                                <Col lg={8} className="m-0 p-0 pl-2">
                                    <span className="font-sm-custom"><b className="text-dark">{summary?.publishEndDate ?? "-"}</b></span>
                                </Col>
                            </Col>
                            <Col lg={6} md={12} className="d-flex flex-wrap justify-content-start align-items-start">
                            </Col>
                        </Row>
                        {!!vacancyDescription &&
                            <Row className="w-100 m-0 mt-3">
                                <Col lg={12} className="d-flex flex-wrap justify-content-start align-items-start">
                                    {/* <Col lg={12} className="m-0 p-0">
                                    <span className="font-sm-custom">
                                        Vacancy Description
                                    </span>
                                </Col> */}
                                    <Col lg={12} className="m-0 p-0">
                                        <span className="font-sm-custom"><span className="text-dark" dangerouslySetInnerHTML={{ __html: vacancyDescription }}></span></span>
                                    </Col>
                                </Col>
                            </Row>
                        }
                        <Row className="w-100 m-0">
                            <Col lg={12} md={12} className="d-flex flex-wrap justify-content-start align-items-start">
                                <Col lg={2} md={12} className="m-0 p-0">
                                    <span className="font-sm-custom">
                                        Attachment(s)
                                    </span>
                                </Col>
                                <Col lg={10} md={12} className="m-0 p-0 pl-2">
                                    {!!summary?.jobOrderVacancyAttachments?.length &&
                                        <ul className="pl-3">
                                            {summary.jobOrderVacancyAttachments.map((attachment, index) => {
                                                return (<li id={`attachment-${index}`}><span className="font-sm-custom"><a className="text-info" href="javascript:;" onClick={() => { setDownloadingDocument(attachment); }}>{attachment?.filename ?? "-"}</a> ({FormatFileSize(attachment.fileSizeInBytes)})</span></li>);
                                            })}
                                        </ul>}
                                </Col>
                            </Col>
                        </Row>
                        <Row className="w-100 m-0 mt-3">
                            <Col lg={12} className="d-flex flex-wrap justify-content-start align-items-start">
                                <Col lg={12} className="m-0 p-0">
                                    <span className="font-sm-custom">
                                        Recruiter
                                        <b className="ml-3 text-dark">{summary?.recruitmentPerson?.displayName ?? "-"}</b>
                                        <i className="fas fa-phone-alt ml-3 mr-1" />
                                        <b className="text-dark">{summary?.phoneNumber ?? "-"}</b>
                                        <i className="fas fa-envelope ml-3 mr-1" />
                                        <b className="text-dark">{summary?.recruitmentPerson?.email ?? "-"}</b>
                                    </span>
                                </Col>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* <Row className="w-100 p-0 m-0 mt-4">
                    <Col lg="12" className="d-flex flex-wrap justify-content-center align-items-center">
                        <Button
                            className="mr-2 w-25"
                            color="danger"
                            onClick={() => setShowApplied(true)}
                        >
                            Apply
                        </Button>
                    </Col>
                </Row> */}
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={onClose}
                    type="button"
                >
                    Close
                </Button>
            </div>
            {
                showApplied &&
                <SweetAlert
                    info
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    title={<h4 className="mb-0">Vacancy Preview</h4>}
                    onConfirm={() => setShowApplied()} // need to add loading indicator on confirm
                    onCancel={() => setShowApplied(false)}
                    focusCancelBtn
                >
                    <p>This button will functioning as Apply button.</p>
                </SweetAlert>
            }
        </Modal>
    );
};
