import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";

export default function DeleteFailedAlert(props) {
    const {
        onConfirm,
        message
    } = props;

    return (
        <SweetAlert error title="Error!" onConfirm={onConfirm}>
        {
            message ?
            <span>{message}</span>
            :
            <span>An error occurred deleting the record.</span>
        }
        </SweetAlert>
    );
}
