/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useMemo, useRef, Component } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import { useParams, useHistory } from "react-router-dom";
import api from "../../../../../services/api";
import qs from "qs";
import { SortsConverter, FiltersConverter } from "../../../../../helpers"
import {
    Container, Row, Col, Card, CardBody,
} from "reactstrap";

import CreateJobOrder from "../CreateJobOrder";
import CandidateEditor from "../../../Candidates/Editor";
import CandidateEditorEmail from "../../../Candidates/EditorEmail";
import CandidateEditorSMS from "../../../Candidates/EditorSMS";
import CandidateSearch from "../CandidateSearch";
import JobOrderCandidateEditor from "../JobOrderCandidateEditor";
import EditJobOrder from "../EditJobOrder";
//import EditJobOrderGridV2 from "../EditJobOrderV2/EditJobOrderGridV2";
import EditJobOrderGridV2 from "../EditJobOrderV2/ModalContainer";
import EditJobOrderGridV2PageAlt from "../EditJobOrderV2/PageAlt";
import moment from "moment";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../../../alerts";
import { TooltipButton } from '../../../../inputs';
import BulkMilestoneEditor from "../BulkMilestoneEditor";
import fileDownload from "js-file-download";
import Loader from "../../../../loaders";
import { IndeterminateCheckbox } from '../../../../react-table/controls';
import { ConfigPanel, SetupPanel } from './panels';
import ActionBtnCellRenderer from './cellRenderer/actionBtnCellRenderer';
import CompletionPercentCellRenderer from './cellRenderer/completionPercentCellRenderer';
import LrfCellRenderer from './cellRenderer/lrfCellRenderer';
import CandidateDocumentViewer from '../CandidateDocumentViewer';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useAppContext } from '../../../../../contexts/AppProvider';
import useJobOrder from './contexts/JobOrderContext';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Slide } from '@progress/kendo-react-animation';
import StatusTooltip from './tooltips/StatusTooltip';

const timeoutLength = 1500;

export default function JobOrderGrid(props) {
    const {
        notification,
        setNotification,
    } = useJobOrder();

    // console.log({notification, setNotification});

    //const { token } = useMsal();
    const history = useHistory();
    const [data, setData] = React.useState([]);

    const params = useParams();
    const { fpJobOrderId } = params;
    const [copiedIndex, setCopiedIndex] = React.useState(null);
    React.useEffect(() => {
        if (copiedIndex !== null) {
            const timeoutId = setTimeout(() => {
                setCopiedIndex(null);
            }, timeoutLength);

            return () => clearTimeout(timeoutId);
        }
    }, [copiedIndex]);

    // useEffect(() => {
    //     console.log('fpJobOrderId', fpJobOrderId);
    // }, [fpJobOrderId])

    // useEffect(() => {
    //     console.log('params', params);
    // }, [params]);


    // const [ jobOrder, setjobOrder ] = React.useState([]);
    // const [ recruitmentRequests, setRecruitmentRequests ] = React.useState([]);
    // const [ jobOrderMilestoneMaster, setJobOrderMilestoneMaster ] = React.useState([]);
    // const [ candidates, setCandidates ] = React.useState([]);
    const [candidateId, setCandidateId] = React.useState(null);
    const candidateCallback = React.useRef(null);
    const assingmentCallback = React.useRef(null);

    const [loading, setLoading] = React.useState(false);
    const [itemInEdit, setItemInEdit] = React.useState(null);
    const [createNew, setCreateNew] = React.useState(false);
    const [canAutoOpen, setCanAutoOpen] = React.useState(true);
    const [bulkMilestone, setBulkMilestone] = React.useState([]);
    const [bulkMilestoneJoc, setBulkMilestoneJoc] = React.useState([]);
    const [showCancelledJobOrder, setShowCancelledJobOrder] = useState(false);
    const [showCompletedJobOrder, setShowCompletedJobOrder] = useState(false);
    const [showActiveJobOrder, setShowActiveJobOrder] = useState(false);
    const [showJobOrderGrid, setShowJobOrderGrid] = useState(true);
    const [showFpJobEditOrder, setShowFpEditJobOrder] = useState(false);

    const [validationMessage, setValidationMessage] = React.useState(null);
    const [validationOkHandle, setValidationOkHandle] = React.useState(null);


    const [pdrsCancelDialog, setPdrsCancelDialog] = React.useState(false);


    //const [editJobOrderRefreshHandle, setEditJobOrderRefreshHandle] = React.useState(null);
    const editJobOrderRefreshHandle = React.useRef(null);
    const [candidateIdForCvPreview, setCandidateIdForCvPreview] = React.useState(null);

    const getCustomFilter = useCallback(() => { return { showCancelledJobOrder, showCompletedJobOrder } }, [showCancelledJobOrder, showCompletedJobOrder]);

    var customFilter = {
        showCancelledJobOrder: useMemo(() => { return showCancelledJobOrder; }, [showCancelledJobOrder]),
        showCompletedJobOrder: useMemo(() => { return showCompletedJobOrder; }, [showCompletedJobOrder]),
    };

    const [emailCandidateId, setEmailCandidateId] = React.useState(null);
    const [smsCandidateId, setSMSCandidateId] = React.useState(null);
    const [itemSMS, setItemSMS] = React.useState([]);

    // Personal Details Request Message
    const [pdrsRequest, setPdrsRequest] = React.useState(null);

    const [itemEmail, setItemEmail] = React.useState([]);
    const [jobOrderCandidate, setJobOrderCandidate] = React.useState(null);
    const [searchCandidate, setSearchCandidate] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(50);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [deleteAlert, setDeleteAlert] = React.useState([]);
    const [lookups, setLookups] = React.useState({});
    const [filters, setFilters] = React.useState([]);

    const {
        breadcrumbs, setBreadcrumbs, breadcrumbsRef,
        breadcrumbItems, setBreadcrumbItems, breadcrumbItemsRef,
        breadcrumbItemOverrides, setBreadcrumbItemOverrides, breadcrumbItemOverridesRef,
    } = useAppContext();

    const defaultSort = React.useMemo(() => [{ id: "lrfCreationDate", desc: true }, { id: "projectName", desc: false }],
        []
    );

    const [sortBy, setSortBy] = React.useState(defaultSort);
    const skipPageResetRef = React.useRef();
    const apiurl = "joborder";


    // BEGIN AG GRID VARS -------------------------------------------------------------------------------------------------------------------------

    const [versionCounter, setVersionCounter] = useState(0);
    const gridRef = useRef();
    const [gridOptions, setGridOptions] = useState(null);
    //const [serverSideInitialRowCount, setServerSideInitialRowCount] = useState(0);

    // END AG GRID VARS ---------------------------------------------------------------------------------------------------------------------------

    // BEGIN AG GRID FUNCTIONS -------------------------------------------------------------------------------------------------------------------------

    const resizeColumns = useCallback(() => {
        if (!gridRef?.current?.columnApi || data?.length === 0)
            return;

        const allColumnIds = [];
        gridRef.current.columnApi.getColumns().forEach((column) => {
            // console.log(column, column.getId());
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }, [data, gridRef]);

    useEffect(() => {
        //console.log('refreshCache', 'showCompletedJobOrder', showCompletedJobOrder, 'showCancelledJobOrder', showCancelledJobOrder);
        //console.log('gridRef', gridRef);
        //console.log('gridOptions', gridOptions);

        // Refresh datasource
        if (gridRef && gridRef.current && gridRef.current.api) {
            var datasource = getServerSideDatasource();
            gridRef.current.api.setServerSideDatasource(datasource);
        }

        // Refresh data
        refreshCache([]);

    }, [showActiveJobOrder, showCompletedJobOrder, showCancelledJobOrder, gridRef, params.action, params.requestId]);

    const defaultColumns = [
        {
            name: "recruitmentRequestNumber",
            title: "LRF",
            cellRenderer: LrfCellRenderer,
        },
        {
            name: "projectName",
            title: "Project Name",
            sort: 'asc'
        },
        {
            name: "positionName",
            title: "Employed Position"
        },
        {
            name: "requestDetailQuantity",
            title: "Quantity Required"
        },
        {
            name: "startDateExpected",
            title: "Expected Start",
            field: 'date',
            filter: 'agDateColumnFilter',
            valueFormatter: (params) => {
                var { value } = params;
                return value ? moment(value).format("DD.MM.YYYY") : "N/A";
            },
        },
        {
            name: "finishDatePlanned",
            title: "Expected End",
            field: 'date',
            filter: 'agDateColumnFilter',
            valueFormatter: (params) => {
                var { value } = params;
                return value ? moment(value).format("DD.MM.YYYY") : "N/A";
            },
        },
        {
            name: "recruitLocation",
            title: "Job Location"
        },
        {
            name: "contractOwner",
            title: "Contract Owner"
        },
        {
            name: "lrfCreationDate",
            title: "LRF Creation Date",
            field: 'date',
            filter: 'agDateColumnFilter',
            valueFormatter: (params) => {
                var { value } = params;
                return value ? moment(value).format("DD.MM.YYYY") : "N/A";
            },
        },
        {
            name: "jobOrderCreationDate",
            title: "Job Order Creation Date",
            field: 'date',
            filter: 'agDateColumnFilter',
            valueFormatter: (params) => {
                var { value } = params;
                return value ? moment(value).format("DD.MM.YYYY") : "N/A";
            },
        },
        {
            name: "recruitmentPerson.lookupValue",
            title: "Recruitment Person"
        },
        {
            name: "hrispositionMasterName",
            title: "Position Name",
            filterParams: {
                name: 'acts'
            },
        },
        {
            name: "recruitmentCoordinator.displayName",
            title: "Recruitment Coordinator"
        },
        {
            name: "dispatchUnitName",
            title: "Dispatch Name",

        },
        {
            name: "jobOrderId",
            title: "ID",
            width: 75,
        },
        {
            name: "completionPercent",
            title: "Completion Percent",
            floatingFilter: false,
            sortable: false,
            cellClass: "completion-percent-cell",
            pinned: 'right',
            cellRenderer: CompletionPercentCellRenderer,
            tooltipComponent: (props) => props.node.data.cancelledStatus ? StatusTooltip(props) : (<></>),
            tooltipField: `completionPercent`,
        },
        {
            name: "button",
            title: "Action",
            pinned: 'right',
            filter: '',

            filterParams: {
                name: 'action'
            },
            sortable: false,
            floatingFilter: false,
            cellClass: "action-button-cell",
            cellRenderer: ActionBtnCellRenderer,
            //valueFormatter: (value) => {
            //    console.log('value', value);
            //    return <>Abc</>
            //},
            cellRendererParams: {
                clicked: function (field) {
                    alert(`${field} was clicked`);
                }
            },
            additionalParams: () => {

                var props = {
                    copiedIndex: copiedIndex,
                    setCopiedIndex: setCopiedIndex,

                    itemInEdit: itemInEdit,
                    setItemInEdit: setItemInEdit,
                    setShowJobOrderGrid: setShowJobOrderGrid,
                    handleDelete: handleDelete,
                    history: history,
                };

                return props;
            },
        },
    ];

    const [agGridSort, setAgGridSort] = useState(['asc', 'desc']);

    const [columnDefs, setColumnDefs] = useState(defaultColumns.map((c) => {
        const obj = {
            ...c,
            field: c.name,
            headerName: c.title,
        };

        if ('name' in obj) delete obj.name;
        if ('title' in obj) delete obj.title;
        return obj;
    }));

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        //filter: true,
        editable: false,
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['reset'],
            debounceMs: 200,
            excelMode: 'mac',
        },
        floatingFilter: true,
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        suppressMenu: true,
    }), []);

    const loadingOverlayComponent = useMemo(() => Loader, []);

    const onCellEditRequest = useCallback((event) => {
        // console.log("on cell edit request: ", { event });
    }, []);

    const sideBar = useMemo(() => ({
        toolPanels: [
            // {
            //     id: 'setupPanel',
            //     labelDefault: 'Setup',
            //     labelKey: 'setupPanel',
            //     iconKey: 'fa-gear',
            //     toolPanel: SetupPanel,
            //     width: 280,
            //     toolPanelParams: () => {
            //         return {
            //             showCompletedJobOrder: showCompletedJobOrder,
            //             setShowCompletedJobOrder: setShowCompletedJobOrder,
            //             showCancelledJobOrder: showCancelledJobOrder,
            //             setShowCancelledJobOrder: setShowCancelledJobOrder,
            //             setCreateNew: setCreateNew,
            //             setLoading: setLoading,
            //             fileDownload: fileDownload
            //         };
            //     }
            // },
            // {
            //     id: 'filters',
            //     labelDefault: 'Filters',
            //     labelKey: 'filters',
            //     iconKey: null,
            //     toolPanel: 'agFiltersToolPanel',
            //     width: 280,
            // },
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressPivots: true,
                    suppressPivotMode: true,
                    suppressValues: true
                },
            },
            {
                id: "configPanel",
                labelDefault: "Configuration",
                labelKey: "configPanel",
                iconKey: 'fa-config',
                toolPanel: ConfigPanel,
                width: 280,
            }
        ],
        //defaultToolPanel: 'columns',
        position: "left"
    }), [showActiveJobOrder, showCompletedJobOrder, showCancelledJobOrder, fileDownload]);

    const refreshCache = useCallback((route) => {
        //console.log('gridRef', gridRef);
        if (!gridRef || !gridRef.current || !gridRef.current.api)
            return;

        setVersionCounter(versionCounter + 1);
        gridRef.current.api.purgeServerSideCache();

    }, [versionCounter, params, showActiveJobOrder, showCancelledJobOrder, showCompletedJobOrder]);

    const onGridReady = useCallback((params) => {

        var datasource = getServerSideDatasource();
        params.api.setServerSideDatasource(datasource);
    }, [showActiveJobOrder, showCancelledJobOrder, showCompletedJobOrder]);

    const getServerSideDatasource = useCallback(() => {

        return {

            getRows: (params2) => {
                var { request } = params2;
                var { filterModel, sortModel } = request;

                var thisFilters = FiltersConverter(filterModel);
                var thisSorts = SortsConverter(sortModel);

                var selectedSorts = (thisSorts.length ? thisSorts : defaultSort);
                setFilters(thisFilters);
                setSortBy(selectedSorts);

                const queryString = qs.stringify({
                    filters: thisFilters,
                    sortBy: selectedSorts,

                    showActiveJobOrder: showActiveJobOrder,
                    showCancelledJobOrder: showCancelledJobOrder,
                    showCompletedJobOrder: showCompletedJobOrder,
                }, {
                    allowDots: true
                });
                const url = `/${apiurl}/${params2.request.startRow ?? 0}/${pageSize}${params.requestId ? ("/" + params.requestId + "/" + params.action) : ""}${queryString ? `?${queryString}` : ""}`;
                
                api.get(url)
                    .then((response) => {
                        // console.log('response', response);
                        var successData = {
                            rowData: response.data.data,
                            //rowCount: 100,
                            rowCount: response.data.total,
                        };
                        //setServerSideInitialRowCount(response.data.total);

                        //console.log('success data', successData);
                        params2.success(successData);
                    })
                    .catch(error => {
                        console.error('error on getServerSideDatasource.getRows', error);
                        params2.fail();
                    });
            },
        };
    }, [params, showActiveJobOrder, showCancelledJobOrder, showCompletedJobOrder]);

    const updateSingleRow = useCallback((jobOrderId) => {
        var gridApi = gridRef.current?.api;

        if (!gridApi)
            return;

        gridApi.forEachNode(rowNode => {

            if (jobOrderId == rowNode.data.jobOrderId) {
                //console.log('rowNode', rowNode);

                const url = `/${apiurl}/${jobOrderId}`;
                setLoading(true);
                api.get(url)
                    .then(({ data }) => {
                        //console.log('data to set', 'data', data);
                        rowNode.setData(data)
                    }).catch((error) => {
                        console.error("error: ", { error });
                    }).finally(() => setLoading(false));
            }
        });
    }, [gridRef]);

    // END AG GRID FUNCTIONS ---------------------------------------------------------------------------------------------------------------------------

    const handleExport = () => {
        setLoading(true);

        const queryString = qs.stringify({
            filters: filters,
            sortBy: sortBy,

            showActiveJobOrder: showActiveJobOrder,
            showCancelledJobOrder: showCancelledJobOrder,
            showCompletedJobOrder: showCompletedJobOrder,
        }, {
            allowDots: true
        });
        const exportUrl = `joborder/export`;
        const url = `/${exportUrl}/${params.requestId ? ("/" + params.requestId + "/" + params.action) : ""}${queryString ? `?${queryString}` : ""}`;

        api.get(url, { responseType: 'blob' })
            .then(blob => {
                // console.log("Download: ", blob);
                fileDownload(blob.data, "joborder.xlsx");
            }).catch(error => {
                console.error(error)
            }).finally(() => setLoading(false))

    };

    const handleExportCandidate = () => {
        setLoading(true);

        const queryString = qs.stringify({
            filters: filters,
            sortBy: sortBy,

            showActiveJobOrder: showActiveJobOrder,
            showCancelledJobOrder: showCancelledJobOrder,
            showCompletedJobOrder: showCompletedJobOrder,
        }, {
            allowDots: true
        });
        let exportUrl = `joborder/exportcandidate`;
        const url = `/${exportUrl}/${params.requestId ? ("/" + params.requestId + "/" + params.action) : ""}${queryString ? `?${queryString}` : ""}`;

        api.get(url, { responseType: 'blob' })
            .then(blob => {
                // console.log("Download: ", blob);
                fileDownload(blob.data, "jobordercandidate.xlsx");
            }).catch(error => {
                console.error(error)
            }).finally(() => setLoading(false))

    };

    React.useEffect(() => {
        if (params.action === "new")
            setCreateNew(true);
    }, [params.action])

    const loadData = React.useCallback((params2 = null) => {
        if (params2 && 'api' in params2) {
            var { api: api2 } = params2;
            api2.hideOverlay();
        }

        const queryString = qs.stringify({ filters, sortBy, showActiveJobOrder, showCancelledJobOrder, showCompletedJobOrder }, { allowDots: true });
        skipPageResetRef.current = true;
        setItemInEdit(null);
        setCreateNew(false);
        setData([]);

        const url = `/${apiurl}/${(pageNumber - 1) * pageSize}/${pageSize}${params.requestId ? ("/" + params.requestId + "/" + params.action) : ""}${queryString ? `?${queryString}` : ""}`;
        setLoading(true);
        api.get(url)
            .then(({ data }) => {
                setData(data.data);
                setTotalRecords(data.total);
            }).catch((error) => {
                setItemInEdit(null);
                setData([]);
                console.error("error: ", { error });
            }).finally(() => setLoading(false));
    }, [filters, sortBy, pageNumber, pageSize, params, showActiveJobOrder, showCancelledJobOrder, showCompletedJobOrder]);

    const handleDelete = (item) => {
        const success = () => {
            setLoading(false);
            setDeleteAlert([<DeleteSuccessAlert key={0} onConfirm={() => setDeleteAlert([])} />]);
            if (data.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1);
            } else {
                refreshCache();
            }
        };
        const failure = () => {
            setLoading(false);
            setDeleteAlert([<DeleteFailedAlert key={0} onConfirm={() => setDeleteAlert([])} />]);
        };
        const events = {
            onConfirm: () => {
                setLoading(true);
                api.delete(`/${apiurl}/${item.jobOrderId}`).then(success).catch(failure);
            },
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert key={0} {...events} />])
    }

    React.useEffect(() => {
        // console.log("data", data);
        if (canAutoOpen && data && data.length === 1 && params.requestId && params.action && params.action === "joborder") {
            setItemInEdit(data[0]);
        }
    }, [canAutoOpen, data, params]);

    // BEGIN EditJobOrderGridV2 Functions ---------------------------------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (fpJobOrderId) {
            setShowJobOrderGrid(false);
            setShowFpEditJobOrder(true);
            loadFpData(fpJobOrderId);
        }


    }, [fpJobOrderId]);

    const loadFpData = useCallback((id) => {
        setLoading(true);

        const url = `/${apiurl}/${id}`;
        api.get(url)
            .then(({ data }) => {
                setLoading(false);
                //console.log('data to set', 'data', data);
                setItemInEdit(data);
            }).catch((error) => {
                setLoading(false);
                console.error("error: ", { error });
            }).finally(() => { });

    }, []);

    const editJobOrderGridV2OnClose = (callFrom) => {
        //refreshCache();
        updateSingleRow(itemInEdit.jobOrderId);
        setItemInEdit(null);
        setCanAutoOpen(false);
        if (callFrom == "page") {
            setShowJobOrderGrid(true);
        }
    }

    const setRefreshHandle = useCallback((callback) => {
        //setEditJobOrderRefreshHandle(callback);
        editJobOrderRefreshHandle.current = callback;
    }, [editJobOrderRefreshHandle]);

    const onSavedEditJobOrderGrid = () => {
        //refreshCache();
        updateSingleRow(itemInEdit.jobOrderId);

    }
    const onViewCandidate = (id, callback) => {

        candidateCallback.current = callback;

        setLoading(true);
        let apiCalls = [
            api.get(`/lookup/skillposition`),
            api.get(`/lookup/candidatetype`),
            api.get(`/lookup/salutation`),
            api.get(`/lookup/preference`),
            api.get(`/lookup/industry`),
            api.get(`/lookup/gender`),
            api.get(`/lookup/maritalStatus`),
            api.get(`/lookup/candidateStatus`),
            api.get(`/lookup/candidateFlag`),
            api.get(`/lookup/residencyStatus`),
            api.get(`/lookup/visatype`),
            api.get(`/lookup/communicationtype`),
            api.get(`/lookup/country`),
            api.get(`/lookup/state`),
            api.get(`/lookup/relationshiptype`),
            api.get(`/lookup/candidateplatformorigin`),
            api.get(`/lookup/recruitmentpeople`),
            api.get(`/lookup/messagesmasters`),
            api.get(`/emailtemplate`), // TODO: returns paged result - move to lookup
            api.get(`/lookup/candidatediscipline`),
            api.get(`/lookup/company`),
            api.get(`/lookup/identifier`),
            api.get(`/lookup/candidateevent`),
            api.get(`/lookup/agency`),
            api.get(`/contractingcompany/all`),
        ];

        Promise.all(apiCalls)
            .then((data) => {
                setLookups({
                    skillPositions: data[0].data,
                    candidateTypes: data[1].data,
                    salutations: data[2].data,
                    preferences: data[3].data,
                    industries: data[4].data,
                    genders: data[5].data,
                    maritalStatuses: data[6].data,
                    candidateStatuses: data[7].data,
                    candidateFlags: data[8].data,
                    residencyStatuses: data[9].data,
                    visaTypes: data[10].data,
                    communicationTypes: data[11].data,
                    countries: data[12].data,
                    states: data[13].data,
                    relationshipTypes: data[14].data,
                    candidatePlatformOrigin: data[15].data,
                    recruitmentPeople: data[16].data,
                    messagesMaster: data[17].data,
                    emailTemplate: data[18].data.data,
                    candidateDisciplines: data[19].data,
                    companies: data[20].data,
                    identifiers: data[21].data,
                    events: data[22].data,
                    agencies: data[23].data,
                    contractingCompanies: data[24].data,
                });
            }).finally(() => {
                setLoading(false);
                callback(false);

                if (id != null)
                    setCandidateId(id);

            });
    }
    const onViewSearchCandidate = (jobOrderCandidateId, index, callback, callbackNewAssignment) => {

        candidateCallback.current = callback;
        assingmentCallback.current = callbackNewAssignment;

        setSearchCandidate({ jobOrderCandidateId, index });
    }

    const onViewJobOrderCandidate = (jobOrderCandidateId, index, callback, callbackNewAssignment) => {

        candidateCallback.current = callback;
        assingmentCallback.current = callbackNewAssignment;

        setJobOrderCandidate({ jobOrderCandidateId, index });
    }

    const onViewCandidateEmail = (id, callback) => {

        candidateCallback.current = callback;

        setLoading(true);
        let apiCalls = [
            api.get(`/lookup/messagesmasters`),
        ];

        Promise.all(apiCalls)
            .then((data) => {
                setLookups({
                    ...lookups,
                    messagesMaster: data[0].data,
                });
            }).finally(() => {
                setLoading(false);
                callback(false);
                setEmailCandidateId(id);
            });
    }

    const onViewCandidateSMS = (id, jobOrderCandidateId, callback) => {

        candidateCallback.current = callback;

        setLoading(true);
        let apiCalls = [
            api.get(`/lookup/messagesmasters`),
        ];

        Promise.all(apiCalls)
            .then((data) => {
                setLookups({
                    ...lookups,
                    messagesMaster: data[0].data,
                });
            }).finally(() => {
                setLoading(false);
                callback(false);

                setSMSCandidateId({ candidateId: id, jobOrderCandidateId });
            });
    }

    const onViewCandidateEmails = (items, callback) => {
        candidateCallback.current = callback;

        setLoading(true);
        let apiCalls = [
            api.get(`/lookup/messagesmasters`),
        ];

        Promise.all(apiCalls)
            .then((data) => {
                setLookups({
                    ...lookups,
                    messagesMaster: data[0].data,
                });
            }).finally(() => {
                setLoading(false);
                callback(false);

                setItemEmail(items);
                setEmailCandidateId(0);
            });
    }

    const onViewBulkCandidateSMS = (items, callback) => {


        candidateCallback.current = callback;

        setLoading(true);
        let apiCalls = [
            api.get(`/lookup/messagesmasters`),
        ];

        Promise.all(apiCalls)
            .then((data) => {
                setLookups({
                    ...lookups,
                    messagesMaster: data[0].data,
                });
            }).finally(() => {
                setLoading(false);
                callback(false);

                setItemSMS(items);
                setSMSCandidateId(null);
            });
    }

    const onViewBulkMilestone = (items, joc, callback) => {
        candidateCallback.current = callback;
        setBulkMilestone(items);
        setBulkMilestoneJoc(joc);
        //callback(false);
    }

    // END EditJobOrderGridV2 Functions -----------------------------------------------------------------------------------------------------------------------------

    // BEGIN BREADCRUMBS -----------------------------------------------------------------------------------------------------------------------------

    useEffect(() => {
        // Validate state
        if (!breadcrumbs || breadcrumbs?.length < 3 || breadcrumbs[1].toLowerCase() != "joborderdetail" ||
            !breadcrumbItems || breadcrumbItems?.length < 3 ||
            !itemInEdit) {
            if (breadcrumbItemOverrides?.length)
                setBreadcrumbItemOverrides([]);
            return;
        }

        var jobOrderId = parseInt(breadcrumbs[2]);
        var isCorrectAsId = jobOrderId == breadcrumbs[2];

        if (!isCorrectAsId) {
            if (breadcrumbItemOverrides?.length)
                setBreadcrumbItemOverrides([]);
            return;
        }

        // Get the LRF and Job Order Id
        var lrfString = itemInEdit.recruitmentRequestNumber ? itemInEdit.recruitmentRequestNumber : '';
        var jobOrderIdString = itemInEdit.jobOrderId ? itemInEdit.jobOrderId : '';
        var newLrfOnBreadcrumb = lrfString ? `${lrfString} | ${jobOrderIdString}` : `${jobOrderIdString}`;


        // Override the breadcrumb item
        var existingIndex = breadcrumbItemOverrides.findIndex(f => f.index == 2);
        if (existingIndex) {
            breadcrumbItemOverrides.splice(existingIndex, 1);
        }
        breadcrumbItemOverrides.push({
            index: 2,
            breadcrumbItem: newLrfOnBreadcrumb
        });
        setBreadcrumbItemOverrides([...breadcrumbItemOverrides]);

    }, [breadcrumbs, itemInEdit]);

    // END BREADCRUMBS -----------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        // console.log({
        //     localStorage,
        //     exists: process.env.REACT_APP_HRIS_JOBORDER_GRID_COLUMN_STATE in localStorage,
        //     columnApi: gridRef.current.columnApi,
        // });

        if (process.env.REACT_APP_HRIS_JOBORDER_GRID_COLUMN_STATE in localStorage && gridRef.current.columnApi) {
            const items = JSON.parse(localStorage.getItem(process.env.REACT_APP_HRIS_JOBORDER_GRID_COLUMN_STATE));

            // console.log({items});

            gridRef.current.columnApi.applyColumnState({
                state: items,
                applyOrder: true,
                defaultState: {
                    aggFunc: null,
                    flex: null,
                    hide: false,
                    pinned: null,
                    pivot: false,
                    pivotIndex: null,
                    rowGroup: false,
                    rowGroupIndex: null,
                    sort: null,
                    sortIndex: null
                }
            });
        }
    }, [gridRef?.current?.columnApi]);

    return (
        <section className="main">
            {loading && <Loader />}
            {deleteAlert.length > 0 && deleteAlert}
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        {!!showJobOrderGrid &&
                            <Card className="no-transition">
                                <CardBody>
                                    {/*
                                <CardTitle>
                                    <h4 className="text-center">Job Orders {params.requestId && `LRF #${params.requestId}`}</h4>
                                </CardTitle>
                                */}

                                    <Row>
                                        <Col sm={6}>
                                            <TooltipButton
                                                id="addnew"
                                                title="Add New"
                                                className="btn-icon ml-2 mb-2"
                                                color="default"
                                                size="sm"
                                                type="button"
                                                onClick={() => {
                                                    //setItemInEdit({});
                                                    setCreateNew(true);
                                                }}
                                            >
                                                <i className="fas fa-plus pt-1"></i>
                                            </TooltipButton>
                                            <TooltipButton
                                                id="export"
                                                title="Export to Excel"
                                                className="btn-icon ml-2 mb-2"
                                                color="default"
                                                size="sm"
                                                type="button"
                                                onClick={() => handleExport()}
                                            >
                                                <i className="fas fa-file-excel"></i> Export
                                            </TooltipButton>
                                            <TooltipButton
                                                id="exportcandidate"
                                                title="Export Candidate to Excel"
                                                className="btn-icon ml-2 mb-2"
                                                color="default"
                                                size="sm"
                                                type="button"
                                                onClick={() => handleExportCandidate()}
                                            >
                                                <i className="fas fa-file-excel"></i> Export Candidate
                                            </TooltipButton>

                                        </Col>
                                        <Col sm={6} className="text-xs-left text-sm-right d-flex flex-wrap justify-content-end align-items-center">
                                            <IndeterminateCheckbox
                                                id="showActiveJobOrder"
                                                type="checkbox"
                                                labelClassName="job-order-control mr-3"
                                                onChange={() => {
                                                    setShowActiveJobOrder(!showActiveJobOrder);
                                                }}
                                                checked={showActiveJobOrder}
                                                customTitle="Show All Active Job Order"
                                            />
                                            <IndeterminateCheckbox
                                                id="showCompletedJobOrderCheckBox"
                                                type="checkbox"
                                                labelClassName="job-order-control mr-3"
                                                onChange={() => {
                                                    setShowCompletedJobOrder(!showCompletedJobOrder);
                                                }}
                                                checked={showCompletedJobOrder}
                                                customTitle="Show Completed Job Orders"
                                            />
                                            <IndeterminateCheckbox
                                                id="showCancelledJobOrderCheckBox"
                                                type="checkbox"
                                                labelClassName="job-order-control"
                                                onChange={() => {
                                                    setShowCancelledJobOrder(!showCancelledJobOrder);
                                                }}
                                                checked={showCancelledJobOrder}
                                                customTitle="Show Cancelled Job Orders"
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={12}>
                                            <div className="ag-theme-alpine ag-grid" style={{ width: '100%', height: 'calc(100vh - 200px)' }}>
                                                <AgGridReact
                                                    ref={gridRef}
                                                    gridOptions={gridOptions}
                                                    //domLayout={'autoHeight'}
                                                    columnDefs={columnDefs}
                                                    defaultColDef={defaultColDef}
                                                    loadingOverlayComponent={loadingOverlayComponent}
                                                    sideBar={sideBar}
                                                    suppressColumnVirtualisation={false}
                                                    suppressRowVirtualisation={false}
                                                    debounceVerticalScrollbar={false}
                                                    rowBuffer={20}
                                                    headerHeight={30}
                                                    groupHeaderHeight={30}
                                                    rowHeight={30}
                                                    floatingFiltersHeight={30}
                                                    // readOnlyEdit={true}
                                                    onRowDataUpdated={() => resizeColumns()}
                                                    suppressDragLeaveHidesColumns={true}
                                                    suppressColumnMoveAnimation={true}
                                                    animateRows={false}
                                                    onCellEditRequest={onCellEditRequest}
                                                    excelStyles={excelStyles()}
                                                    pagination={true}
                                                    paginationPageSize={pageSize}
                                                    cacheBlockSize={pageSize}
                                                    rowModelType={'serverSide'}
                                                    serverSideInfiniteScroll={true}
                                                    onGridReady={onGridReady}
                                                    sortingOrder={agGridSort}
                                                    tooltipShowDelay={0}
                                                    tooltipHideDelay={20000}
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        }

                        {
                            !!showFpJobEditOrder &&
                            <EditJobOrderGridV2PageAlt
                                item={itemInEdit}
                                candidateIdForCvPreview={candidateIdForCvPreview}
                                setCandidateIdForCvPreview={setCandidateIdForCvPreview}
                                onClose={() => editJobOrderGridV2OnClose('page')}
                                setRefreshHandle={(callback) => setRefreshHandle(callback)}
                                onSaved={() => onSavedEditJobOrderGrid()}
                                onViewCandidate={(id, callback) => onViewCandidate(id, callback)}
                                onViewSearchCandidate={(jobOrderCandidateId, index, callback, callbackNewAssignment) => onViewSearchCandidate(jobOrderCandidateId, index, callback, callbackNewAssignment)}
                                onViewJobOrderCandidate={(jobOrderCandidateId, index, callback, callbackNewAssignment) => onViewJobOrderCandidate(jobOrderCandidateId, index, callback, callbackNewAssignment)}
                                onViewCandidateEmail={(id, callback) => onViewCandidateEmail(id, callback)}
                                onViewCandidateSMS={(id, jobOrderCandidateId, callback) => onViewCandidateSMS(id, jobOrderCandidateId, callback)}
                                onViewCandidateEmails={(items, callback) => onViewCandidateEmails(items, callback)}
                                onViewBulkCandidateSMS={(items, callback) => onViewBulkCandidateSMS(items, callback)}
                                onViewBulkMilestone={(items, joc, callback) => onViewBulkMilestone(items, joc, callback)}
                                setPdrsRequest={setPdrsRequest}
                                history={history}
                            />
                            // <JobOrderCreatorEditor 
                            //     item={itemInEdit} 
                            //     onClose={() => setItemInEdit(null)} 
                            //     onSaved={() => refreshCache()}
                            //     lookups={{
                            //         // jobOrder, 
                            //         //recruitmentRequests, 
                            //         jobOrderMilestoneMaster, candidates
                            //     }}
                            // />
                        }
                    </Col>
                </Row>
            </Container>
            {
                itemInEdit && candidateId &&
                <CandidateEditor
                    item={{ candidateId }}
                    onClose={() => {
                        setCandidateId(null);

                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }

                    }}
                    onSaved={(candidateItem) => {
                        setCandidateId(null);

                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }
                    }}
                    lookups={{
                        ...lookups
                    }}
                />
            }
            {
                itemInEdit && searchCandidate &&
                <CandidateSearch
                    jobOrderCandidate={searchCandidate}
                    jobOrderCandidateId={searchCandidate.jobOrderCandidateId}
                    jobOrderCandidateIndex={searchCandidate.index}
                    onClose={() => {
                        setSearchCandidate(null);

                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }
                        if (assingmentCallback.current) {
                            assingmentCallback.current(null);
                        }
                    }}
                    onSaved={(item, index) => {

                        setSearchCandidate(null);

                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }

                        if (assingmentCallback.current) {
                            assingmentCallback.current({ item, index });
                        }


                    }}
                    lookups={{
                        ...lookups
                    }}
                />
            }
            {
                itemInEdit && (emailCandidateId > 0 || itemEmail.length > 0) &&
                <CandidateEditorEmail
                    candidateId={emailCandidateId}
                    items={itemEmail}
                    onClose={() => {
                        setEmailCandidateId(null);
                        setItemEmail([]);

                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }
                    }}
                    onSaved={() => {
                        setEmailCandidateId(null);
                        setItemEmail([]);

                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }
                    }}
                    lookups={{
                        ...lookups
                    }}
                />
            }
            {
                itemInEdit && ((smsCandidateId && smsCandidateId.candidateId > 0) || itemSMS.length > 0) &&
                <CandidateEditorSMS
                    candidateId={smsCandidateId?.candidateId}
                    refId={smsCandidateId?.jobOrderCandidateId}
                    items={itemSMS}
                    onClose={() => {
                        setSMSCandidateId(null);
                        setItemSMS([]);

                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }
                    }}
                    onSaved={() => {
                        setSMSCandidateId(null);
                        setItemSMS([]);

                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }
                    }}
                    lookups={{
                        ...lookups
                    }}
                    sourcePage="jobOrder"
                    defaultShowProjectAttribute={false}
                />
            }
            {
                pdrsRequest && pdrsRequest?.itemSMS &&
                <CandidateEditorSMS
                    //candidateId={pdrsRequest?.candidateId}
                    refId={pdrsRequest?.jobOrderCandidateId}
                    projectId={pdrsRequest?.projectId}
                    items={pdrsRequest?.itemSMS}
                    onClose={() => {
                        setPdrsCancelDialog({ ...pdrsRequest });
                        //var lastPdrsRequest = { ...pdrsRequest };
                        //setPdrsRequest(null);
                        //setSMSCandidateId(null);
                        //setItemSMS([]);
                    }}
                    onSaved={() => {
                        var lastPdrsRequest = { ...pdrsRequest };
                        setPdrsRequest(null);

                        if (lastPdrsRequest.onSaved) {
                            lastPdrsRequest.onSaved(true);
                        }
                    }}
                    lookups={{
                        ...lookups
                    }}
                    sourcePage="jobOrder"
                    defaultShowProjectAttribute={false}
                    defaultMessage={pdrsRequest?.defaultMessage}
                    showMessageTemplate={false}
                    showTalentReponseCheckbox={false}
                />
            }
            {
                itemInEdit && jobOrderCandidate &&
                <JobOrderCandidateEditor
                    jobOrder={itemInEdit}
                    jobOrderCandidateId={jobOrderCandidate.jobOrderCandidateId}
                    itemIndex={jobOrderCandidate.index}
                    onClose={(item) => {
                        setJobOrderCandidate(null);


                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }

                        if (assingmentCallback.current) {
                            assingmentCallback.current(item);
                        }
                    }}
                    onSaved={(item) => {
                        setJobOrderCandidate(null);

                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }
                    }}
                    lookups={{
                        ...lookups
                    }}
                    setPdrsRequest={setPdrsRequest}
                />
            }
            {
                itemInEdit && bulkMilestone?.length > 0 &&
                <BulkMilestoneEditor
                    candidates={bulkMilestone}
                    jobOrderCandidates={bulkMilestoneJoc}
                    jobOrderId={itemInEdit.jobOrderId}
                    onClose={() => {
                        setBulkMilestone([]);
                        setBulkMilestoneJoc([]);
                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }
                        //console.log('BulkMilestoneEditor', 'onSaved', 'editJobOrderRefreshHandle', editJobOrderRefreshHandle?.current);
                        if (!!editJobOrderRefreshHandle?.current) editJobOrderRefreshHandle.current();
                    }}
                    onSaved={() => {
                        setBulkMilestone([]);
                        setBulkMilestoneJoc([]);
                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }
                        //console.log('BulkMilestoneEditor', 'onSaved', 'editJobOrderRefreshHandle', editJobOrderRefreshHandle?.current);
                        if (!!editJobOrderRefreshHandle?.current) editJobOrderRefreshHandle.current();
                    }}
                    setPdrsRequest={setPdrsRequest}
                />
            }
            {
                showJobOrderGrid && itemInEdit &&
                <EditJobOrderGridV2
                    item={itemInEdit}
                    candidateIdForCvPreview={candidateIdForCvPreview}
                    setCandidateIdForCvPreview={setCandidateIdForCvPreview}
                    onClose={() => editJobOrderGridV2OnClose('modal')}
                    setRefreshHandle={(callback) => setRefreshHandle(callback)}
                    onSaved={() => onSavedEditJobOrderGrid()}
                    onViewCandidate={(id, callback) => onViewCandidate(id, callback)}
                    onViewSearchCandidate={(jobOrderCandidateId, index, callback, callbackNewAssignment) => onViewSearchCandidate(jobOrderCandidateId, index, callback, callbackNewAssignment)}
                    onViewJobOrderCandidate={(jobOrderCandidateId, index, callback, callbackNewAssignment) => onViewJobOrderCandidate(jobOrderCandidateId, index, callback, callbackNewAssignment)}
                    onViewCandidateEmail={(id, callback) => onViewCandidateEmail(id, callback)}
                    onViewCandidateSMS={(id, jobOrderCandidateId, callback) => onViewCandidateSMS(id, jobOrderCandidateId, callback)}
                    onViewCandidateEmails={(items, callback) => onViewCandidateEmails(items, callback)}
                    onViewBulkCandidateSMS={(items, callback) => onViewBulkCandidateSMS(items, callback)}
                    onViewBulkMilestone={(items, joc, callback) => onViewBulkMilestone(items, joc, callback)}
                    history={history}
                />
                // <JobOrderCreatorEditor 
                //     item={itemInEdit} 
                //     onClose={() => setItemInEdit(null)} 
                //     onSaved={() => refreshCache()}
                //     lookups={{
                //         // jobOrder, 
                //         //recruitmentRequests, 
                //         jobOrderMilestoneMaster, candidates
                //     }}
                // />
            }
            {
                createNew &&
                <CreateJobOrder
                    onClose={() => {
                        setCreateNew(false);
                        history.push(`/${apiurl}${params.requestId ? ("/" + params.requestId) : ""}`);
                    }}
                    onSaved={() => {
                        setCreateNew(false);
                        refreshCache();
                        // history.push(`/${apiurl}${params.requestId ? ("/" + params.requestId) : ""}`);
                    }}
                />
            }
            {
                candidateIdForCvPreview &&
                <CandidateDocumentViewer
                    onClose={() => setCandidateIdForCvPreview(null)}
                    candidateId={candidateIdForCvPreview}
                />
            }
            {
                validationMessage &&
                <SweetAlert
                    danger
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    title={<h4 className="mb-0">Job Order</h4>}
                    onConfirm={validationOkHandle ? () => validationOkHandle() : () => setValidationMessage(null)} // need to add loading indicator on confirm
                    onCancel={validationOkHandle ? () => validationOkHandle() : () => setValidationMessage(null)}
                    focusCancelBtn
                >
                    <p>{validationMessage}</p>
                </SweetAlert>
            }
            {
                pdrsCancelDialog &&
                <SweetAlert
                    warning
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="danger"
                    cancelBtnText="No"
                    cancelBtnBsStyle="secondary"
                    title={<h4 className="mb-0">SMS Editor</h4>}
                    onConfirm={() => {
                        var lastPdrsRequest = { ...pdrsCancelDialog };
                        setPdrsCancelDialog(null);
                        setPdrsRequest(null);

                        if (lastPdrsRequest.onCLose) {
                            lastPdrsRequest.onCLose(true);
                        }
                    }} // need to add loading indicator on confirm
                    onCancel={() => {
                        setPdrsCancelDialog(null);

                    }}
                    showCancel={true}
                    focusCancelBtn
                >
                        <p>This Milestone - {pdrsCancelDialog.milestoneName} requires you to send message(s). Are you sure to cancel? </p>
                </SweetAlert>
            }

            <NotificationGroup
                style={{
                    right: 8,
                    bottom: 0,
                    alignItems: "flex-start",
                    flexWrap: "wrap-reverse",
                }}
            >
                <Slide direction={notification ? "up" : "down"}>
                    {
                        notification &&
                        <Notification
                            type={{
                                style: notification.type,
                                icon: true,
                            }}
                            closable={true}
                            onClose={() => setNotification(null)}
                        >
                            <span>{notification.message}</span>
                        </Notification>
                    }
                </Slide>
            </NotificationGroup>
        </section>
    )

}


export const excelStyles = () => {
    const headerDefaultStyle = {
        alignment: {
            vertical: 'Center',
            horizontal: "Center",
        },
        interior: {
            color: '#f8f8f8',
            pattern: 'Solid',
            patternColor: undefined,
        },
        borders: {
            borderTop: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderRight: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderBottom: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderLeft: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
        },
        font: {
            fontName: 'Roboto',
            color: '#181d1f',
            bold: true,
            size: 12,
        },
    }

    return [
        {
            id: 'header',
            ...headerDefaultStyle
        },
        {
            id: 'date-header',
            ...headerDefaultStyle,
            alignment: {
                vertical: 'Center',
                horizontal: "Center",
                rotate: 90,
            },
        },
        {
            id: 'cell',
            alignment: {
                vertical: 'Top',
                wrapText: true,
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid',
                patternColor: undefined,
            },
            borders: {
                borderTop: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderRight: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderBottom: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderLeft: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
            },
            font: {
                fontName: 'Roboto',
                color: '#181d1f',
                weight: 700,
                size: 12,
            },
        },
    ];
};


