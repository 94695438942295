import React from 'react';
import api from "../../../../services/api";
import {
    Input, Label, FormGroup, Button, Modal, Container, Row, Col
 } from "reactstrap"; 
import Loader from "../../../loaders";
import { RadioGroup } from "../../../inputs";
import { TrimObject } from '../../../../helpers/StringHelper';

export default function Editor(props) {

    const [ itemInEdit, setItemInEdit ] = React.useState(props.item);
    const [ loading, setLoading ] = React.useState(false);

    const handleTextChanged = ({ target: { name, value } }) => {
        setItemInEdit({ ...itemInEdit, [name]: value });
    }

    const handleSave = (item) => {
        setLoading(true);

        // Trim every string property
        item = TrimObject(item);
        setItemInEdit(item);

        // Save
        const data = JSON.stringify(item);
        const save = item.trainingMasterId > 0 
            ? api.put(`trainingmaster/${item.trainingMasterId}`, data)
            : api.post(`trainingmaster`, data)

        save
        .then((response) => {
            console.log("Training Master Save: ", response);
            props.onSaved();
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }
   
    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Training Master
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}>x</span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                <Container>
                    {(loading) && <Loader />}
                    <Row>                        
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Group Title
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    name="skillName"
                                    id="skillName"
                                    type="text"
                                    required
                                    value={itemInEdit.skillName}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>
                        </Col>  
                        <Col xs={12}>
                            <FormGroup>
                                <Label>
                                    Group Description
                                </Label>
                                <Input
                                    name="skillDescription"
                                    id="skillDescription"
                                    type="text"
                                    value={itemInEdit.skillDescription ?? ""}
                                    onChange={handleTextChanged}
                                />
                            </FormGroup>                                
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>Active</Label>
                                <RadioGroup 
                                    items={[
                                        {lookupId: true, lookupValue: "Yes"}, 
                                        {lookupId: false, lookupValue: "No"}
                                    ]} 
                                    name="active" 
                                    handleChange={({ target: { name, value } }) => setItemInEdit({ ...itemInEdit, [name]: value === "true" })} 
                                    selected={itemInEdit.active}
                                />
                            </FormGroup>
                        </Col>
                        {/* <Col md={6} xs={12}>
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Skill name
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            name="skillName"
                                            id="skillName"
                                            type="text"
                                            required
                                            value={itemInEdit.skillName}
                                            onChange={handleTextChanged}
                                        />
                                    </FormGroup>
                                </Col>  
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Skill Description
                                        </Label>
                                        <Input
                                            name="skillDescription"
                                            id="skillDescription"
                                            type="text"
                                            value={itemInEdit.skillDescription ?? ""}
                                            onChange={handleTextChanged}
                                        />
                                    </FormGroup>
                                </Col>                                
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Category
                                        </Label>
                                        <DropdownBox 
                                            data={trainingCategories}
                                            selectedItem={itemInEdit.trainingCategory || {}}
                                            onChange={(type) => {
                                                setItemInEdit( { ...itemInEdit, trainingCategory: type, trainingCategoryId: type ? type.lookupId : null } );
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Sub Category
                                        </Label>
                                        <DropdownBox 
                                            data={trainingSubCategories}
                                            selectedItem={itemInEdit.trainingSubCategory || {}}
                                            onChange={(type) => {
                                                setItemInEdit( { ...itemInEdit, trainingSubCategory: type, trainingSubCategoryId: type ? type.lookupId : null } );
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Course Code
                                        </Label>
                                        <Input
                                            name="courseCode"
                                            id="courseCode"
                                            type="text"
                                            value={itemInEdit.courseCode}
                                            onChange={handleTextChanged}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Course Name
                                        </Label>
                                        <Input
                                            name="courseName"
                                            id="courseName"
                                            type="text"
                                            value={itemInEdit.courseName}
                                            onChange={handleTextChanged}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Default Expiry (years)
                                        </Label>
                                        <Input
                                            name="defaultExpiryYears"
                                            id="defaultExpiryYears"
                                            type="number"
                                            value={itemInEdit.defaultExpiryYears}
                                            onChange={handleTextChanged}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label>
                                            Start Date
                                        </Label>
                                        <DatePicker
                                            name="startDate"
                                            id="startDate"
                                            type="text"
                                            value={formatDate(itemInEdit.startDate)}
                                            onChange={handleDateChanged}
                                            closeOnSelect                                
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label>
                                            End Date
                                        </Label>
                                        <DatePicker
                                            name="endDate"
                                            id="endDate"
                                            type="text"
                                            value={formatDate(itemInEdit.endDate)}
                                            onChange={handleDateChanged}
                                            closeOnSelect                                
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Training Provider
                                        </Label>
                                        <DropdownBox 
                                            data={trainingProviders}
                                            selectedItem={itemInEdit.defaultTrainingProvider}
                                            onChange={(type) => {
                                                setItemInEdit( { ...itemInEdit, defaultTrainingProvider: type, defaultTrainingProviderId: type ? type.lookupId : null } );
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col> */}
                    </Row>
                    
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={props.onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave(itemInEdit)}
                    type="button"
                >
                    Save Changes
                </Button>
            </div>
        </Modal>
    )
}