/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFlexLayout, useRowSelect, useSortBy, useTable } from 'react-table';
import { Button, Col, Container, Modal, Row, Collapse } from 'reactstrap';
import api from "../../../../services/api";
import PropTypes from "prop-types";
import Loader from '../../../loaders';
import Grid from '../../../Grid';
import { IndeterminateCheckbox } from '../../../react-table/controls';
import qs from "qs";
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import { ErrorNotification } from '../../../alerts';

function ManualConfirmationLogisticSelectionView(props) {
    const {
        status,
        onClose,
        onSave,
        data
    } = props;


    // Regular Variables
    const gridRef = useRef();
    const [loading, setLoading] = useState(false);
    const [logistics, setLogistics] = useState([]);
    const [selectedAccommodations, setSelectedAccommodations] = useState([]);

    const defaultColDef = useMemo(() => ({
        resizable: true,
        editable: false,
        suppressMenu: true,
    }), []);

    const columnDefs = useMemo(() => {
        return [
            {
                headerName: "Start Date",
                field: "startDate",
                valueFormatter: (params) => {
                    const { value } = params;
                    return value ? moment(value).format("DD-MM-YYYY") : "";
                },
                // checkboxSelection: true,
                // showDisabledCheckboxes: true,
            },
            {
                headerName: "End Date",
                field: "endDate",
                valueFormatter: (params) => {
                    const { value } = params;
                    return value ? moment(value).format("DD-MM-YYYY") : "";
                },
            },
            {
                headerName: "Type",
                field: "displayType",
            },
            {
                headerName: "Detail",
                field: "detail",
                width: 650,
            },
        ]
    }, []);

    const loadData = useCallback(() => {
        setLoading(true);
        const dataToSend = data?.map((d) => ({
            rosterCandidateId: d.rosterCandidateId,
            candidateName: d.candidate.candidateName,
            startDate: d.startDate,
            endDate: d.endDate,
        }))

        api.post("rostercandidateaccommodation/confirmation/details", JSON.stringify(dataToSend))
            .then((response) => {
                // console.log({response});
                setLogistics([...response.data.map(d => {
                    const data = {
                        rosterCandidateId: d.rosterCandidateId,
                        candidateName: d.candidateName,
                        details: [],
                    };

                    d.flights.forEach(f => {

                        var fromAirportName = f.flightFrom?.airportName ?? "N/A";
                        var toAirportName = f.flightTo?.airportName ?? "N/A";

                        data.details.push({
                            rosterCandidateId: d.rosterCandidateId,
                            id: f.flightId,
                            logisticType: "Flight",
                            displayType: f.rosterFlightType?.lookupValue,
                            startDate: f.confirmationStartDate,
                            endDate: f.confirmationEndDate,
                            detail: `${moment(f.flightDateTime).format("DD-MM-YYYY HH:mm")} - ${moment(f.arrivalTime).format("HH:mm")} | ${fromAirportName} to ${toAirportName}`
                        });
                    });

                    d.accommodations.forEach(f => {
                        data.details.push({
                            rosterCandidateId: d.rosterCandidateId,
                            id: f.rosterCandidateAccommodationId,
                            logisticType: "Accommodation",
                            displayType: "Accommodation",
                            startDate: f.confirmationStartDate,
                            endDate: f.confirmationEndDate,
                            detail: `${f.startDate ? moment(f.startDate).format("DD-MM-YYYY") : ""} - ${f.endDate ? moment(f.endDate).format("DD-MM-YYYY") : ""} | ${f.accommodation?.accommodationName}`
                        });
                    });

                    data.details.sort((a, b) => (a.startDate < b.startDate) ? 1 : ((b.startDate < a.startDate) ? -1 : 0));
                    // console.log({data});
                    return data;
                })]);
            })
            .catch((error) => console.log({ error }))
            .finally(() => setLoading(false))
            ;
    }, [data, logistics, selectedAccommodations]);

    useEffect(() => {
        loadData();
    }, [data]);

    // useEffect(() => console.log({logistics}), [logistics]);

    const resizeColumns = useCallback((event) => {
        if (data?.length === 0)
            return;

        const allColumnIds = [];
        event.columnApi.getColumns().filter(c => c.getId() !== "detail").forEach((column) => {
            allColumnIds.push(column.getId());
        });
        event.columnApi.autoSizeColumns(allColumnIds, false);
    }, [data]);

    const handleSave = useCallback(() => {
        setLoading(true);
        const dataToSend = [];

        logistics.forEach((r) => {
            const dates = [];

            (new Set(r.details.map(d => ({ startDate: d.startDate, endDate: d.endDate })))).forEach(d => {
                if (!dates.filter(dt => dt.startDate === d.startDate && dt.endDate === d.endDate).length)
                    dates.push({ ...d });
            });

            // console.log({dates, details: r.details});

            dates.forEach(d => {
                dataToSend.push({
                    rosterCandidateId: r.rosterCandidateId,
                    startDate: d.startDate,
                    endDate: d.endDate,
                    flightIds: r.details.filter(rd => rd.rosterCandidateId === r.rosterCandidateId && rd.startDate === d.startDate && rd.endDate === d.endDate && rd.logisticType === "Flight").map(rd => rd.id),
                    rosterCandidateAccommodationIds: r.details.filter(rd => rd.rosterCandidateId === r.rosterCandidateId && rd.startDate === d.startDate && rd.endDate === d.endDate && rd.logisticType === "Accommodation").map(rd => rd.id),
                });
            });
        });
        // console.log({dataToSend});

        api.post(`rostergrid/logisticstatus/${status}`, JSON.stringify(dataToSend))
            .then((response) => {
                setLoading(false);
                onSave();
            })
            .catch((error) => {
                console.log({ error });
                setLoading(false);
            })
            ;
    }, [status, onSave, data, selectedAccommodations, logistics]);

    return (
        <Modal
            isOpen={true}
            className="modal-xl"
            modalClassName="db-example-modal-xl"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Detail Logistic Confirmation
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body"
                style={{ maxHeight: "70vh", overflowY: "auto" }}
            >
                <Container>
                    {loading && <Loader />}
                    {
                        logistics?.filter(l => l?.details?.length).map((l, idx) => {
                            return (
                                <Row key={`logistic-detail-${idx}`} className={idx > 0 ? "mt-3" : ""}>
                                    <Col xs={12}>
                                        <span><b>{l.candidateName}</b></span>
                                    </Col>
                                    {
                                        l.details?.length > 0 ?
                                            <Col xs={12}>
                                                <div className="ag-theme-alpine ag-grid clickable-row" style={{ width: '100%', height: '22vh' }}>
                                                    <AgGridReact
                                                        rowData={l.details}
                                                        columnDefs={columnDefs}
                                                        defaultColDef={defaultColDef}
                                                        suppressColumnVirtualisation={false}
                                                        suppressRowVirtualisation={false}
                                                        debounceVerticalScrollbar={false}
                                                        rowBuffer={20}
                                                        headerHeight={30}
                                                        groupHeaderHeight={30}
                                                        rowHeight={30}
                                                        floatingFiltersHeight={30}
                                                        suppressDragLeaveHidesColumns={true}
                                                        suppressColumnMoveAnimation={true}
                                                        animateRows={false}
                                                        onRowDataUpdated={(e) => resizeColumns(e)}
                                                        suppressContextMenu={true}
                                                        rowSelection={'multiple'}
                                                    />
                                                </div>
                                            </Col>
                                            :
                                            null
                                    }
                                </Row>
                            );
                        })
                    }
                </Container>
            </div>
            <div
                className="modal-footer d-flex flex-wrap"
            >
                <Button
                    color="secondary"
                    onClick={onClose}
                    type="button"
                    className="mt-2"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => {
                        handleSave();
                    }}
                    type="button"
                    className="mt-2"
                >
                    Send
                </Button>
            </div>
        </Modal>
    );
}

export default function ManualConfirmationEditor(props) {
    const {
        rosterCandidateList,
        confirmationType,
        status,
        onClose,
        onSave,
        customAlert = null,
        customValidation = null,
    } = props;

    // Validation & Text Variables
    const [validationMessage, setValidationMessage] = useState([]);
    const invalidRosterPos = "Please enter Roster Position for slot no.";
    const errorTitleOnGenerate = useMemo(() => { return `Manual ${confirmationType} Confirmation Error`; }, [confirmationType])

    // Error Notification Variables
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorTitle, setErrorTitle] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);

    // Regular Variables
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [openLogisticSelectionView, setOpenLogisticSelectionView] = useState(false);
    const defaultSort = useMemo(() => [{ id: "index", desc: false }],
        []
    );

    const isOpen = useMemo(() => {
        //var statusLower = !status ? null : status.trim().toLowerCase();
        //return statusLower == "clear" ? false : true;
        return true;
    }, [status])

    // Load schedules
    const loadSchedules = useCallback(() => {
        setLoading(true);
        const refIds = rosterCandidateList.map(rc => rc.rosterCandidateId);
        const queryString = qs.stringify({ refIds }, { allowDots: true });

        api.get(`rostercandidateschedule/waiting-schedules?refType=RC&${queryString}`)
            .then((response) => {
                // console.log({response});
                setLoading(false);
                setData(response.data.map((d, idx) => ({ ...d, index: idx + 1 })));
            })
            .catch((error) => {
                setLoading(false);
                console.log({ error });
            })
            .finally(() => { })
            ;
    }, [rosterCandidateList]);

    useEffect(() => {
        if (!rosterCandidateList.length)
            return;

        loadSchedules();
    }, [rosterCandidateList]);

    const columns = useMemo(() => {
        return [
            {
                id: "index",
                Header: "No.",
                accessor: (row) => row.index,
                disableSortBy: false,
                width: "50",
            },
            {
                Header: "Talent Name",
                accessor: "candidate.candidateName",
                disableSortBy: false,
            },
            {
                id: 'startDate',
                Header: "Start Date",
                accessor: (row) => !row.nullableStartDate ? 'N/A' : moment(row.startDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
                disableSortBy: false,
                width: "100",
            },
            {
                id: 'endDate',
                Header: "End Date",
                accessor: (row) => !row.nullableEndDate ? 'N/A' : moment(row.endDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
                disableSortBy: false,
                width: "100",
            },
            {
                id: 'workDays',
                Header: "Work Days",
                accessor: "workDays",
                disableSortBy: false,
                width: "60",
            },
            {
                id: 'status',
                Header: "Last Status",
                accessor: (row) => {
                    const status = (confirmationType === "Availability" ? row.availabilityStatus : confirmationType === "Logistic" ? row.logisticStatus : confirmationType === "DAS" ? row.dasStatus : confirmationType === "Estimate Availability" ? row.estimateAvailabilityStatus : null);
                    // console.log({status});
                    return status === "Rejected" || status === "Estimate Rejected" ? "Declined" : status === "Estimate Confirmed" ? "Confirmed" : status;
                },
                disableSortBy: false,
                width: "120",
            },
        ]
    }, [confirmationType]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        flatRows,
        state,
        selectedFlatRows,
        toggleAllRowsSelected
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: defaultSort,
            },
            // defaultColumn
        },
        useFlexLayout,
        hooks => {
            hooks.visibleColumns.push(cols => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    width: 20,
                    disableSortBy: true,
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => {
                        return (
                            <div>
                                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} id="all" />
                            </div>
                        )
                    },
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => {
                        const { original } = row;

                        return (
                            <div>
                                {
                                    row.original != null &&
                                    <IndeterminateCheckbox
                                        {...row.getToggleRowSelectedProps()} id={row.original.startEndGroupId}
                                        onChange={(e) => {
                                            const selected = row.isSelected;
                                            row.toggleRowSelected(!selected);
                                        }}
                                    />
                                }
                            </div>
                        )
                    },
                },
                ...cols,
            ])
        },
        useSortBy,
        useRowSelect,
    );

    // Custom Alert Handler
    useEffect(() => {
        if (!customAlert)
            return;

        var props = {
            flatRows,
            selectedFlatRows,
            setValidationMessage,
            validationMessage,
            invalidRosterPos
        };

        customAlert(props);


    }, [flatRows]);


    // BEGIN HANDLE CLEAR DAS --------------------------------------------------------------------------------------------------
    /*
    useEffect(() => {
        var statusLower = !status ? null : status.trim().toLowerCase();
        if (statusLower != "clear" || !data?.length) {
            return;
        }


        setLoading(true);
        api.post(`rostergrid/${confirmationType === "Availability" ? "availability" : confirmationType === "Logistic" ? "logistic" : confirmationType === "DAS" ? "das" : confirmationType === "Estimate Availability" ? "estimateavailability" : ""}status/${status}`, JSON.stringify(data))
            .then((response) => {
                setLoading(false);
                onSave();
            })
            .catch((error) => {
                console.log({ error });
                setLoading(false);
            })
            ;

    }, [status, data]);
    */
    // END HANDLE CLEAR DAS --------------------------------------------------------------------------------------------------

    const handleSave = useCallback(() => {
        if (!confirmationType || confirmationType === "")
            return;

        setLoading(true);
        const confirmationData = selectedFlatRows.map((r) => r.original);
        // console.log({selectedFlatRows, confirmationData});

        if (customValidation) {
            var props = {
                selectedFlatRows, flatRows, errorMessage, setErrorMessage, errorTitle, setErrorTitle, invalidRosterPos
            };
            var ret = customValidation(props);
            if (ret) { return; }
        }

        setLoading(true);
        api.post(`rostergrid/${confirmationType === "Availability" ? "availability" : confirmationType === "Logistic" ? "logistic" : confirmationType === "DAS" ? "das" : confirmationType === "Estimate Availability" ? "estimateavailability" : ""}status/${status}`, JSON.stringify(confirmationData))
            .then((response) => {
                setLoading(false);
                onSave();
            })
            .catch((error) => {
                console.log({ error });
                setLoading(false);
            })
            ;
    }, [selectedFlatRows, confirmationType, status, onSave]);

    // BEGIN ERROR MESSAGE HANDLER --------------------------------------------------------------------------------
    useEffect(() => {
        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        const events = {
            onConfirm: () => {
                setErrorMessage(null);
                setErrorTitle(null);
            },
            message: errorMessage,
            title: errorTitle ?? errorTitleOnGenerate
        }
        setErrorNotification([<ErrorNotification {...events} />]);
    }, [errorMessage, errorTitle]);
    // END ERROR MESSAGE HANDLER ----------------------------------------------------------------------------------

    return (
        <>
            {loading && <Loader />}
            <Modal
                isOpen={isOpen}
                className="modal-xl"
                modalClassName="db-example-modal-xl"
            >
                <div
                    className="modal-header"
                >
                    <h5
                        className="modal-title"
                    >
                        Manual {confirmationType} Confirmation - Set to {status}
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        onClick={props.onClose}
                        type="button"
                    >
                        <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                    </button>
                </div>
                <div
                    className="modal-body"
                >
                    {errorNotification.length > 0 && errorNotification}
                    <Row>
                        <Col xs={12}>
                            <Collapse isOpen={!!validationMessage?.length} className="mb-4">
                                <div className="alert alert-warning" role="alert">
                                    {
                                        validationMessage?.map((d, index) => (
                                            <>
                                                {d}
                                                {
                                                    index < validationMessage.length - 1 ? <><br /></> : null
                                                }
                                            </>
                                        ))
                                    }
                                </div>
                            </Collapse>
                            <Grid
                                height={'calc(100vh - 464px)'}
                                totalRecords={data?.length ?? 0}
                                pageSize={data?.length ?? 0}
                                rows={rows}
                                tableProps={getTableProps()}
                                headerGroups={headerGroups}
                                tableBodyProps={getTableBodyProps()}
                                prepareRow={prepareRow}
                                needPaging={false}
                            />
                        </Col>
                    </Row>
                </div>
                <div
                    className="modal-footer d-flex flex-wrap"
                >
                    <Button
                        color="secondary"
                        onClick={onClose}
                        type="button"
                        className="mt-2"
                    >
                        Close
                    </Button>
                    <Button
                        color="info"
                        onClick={() => {
                            if (confirmationType === "Logistic") {
                                setOpenLogisticSelectionView(true);
                                return;
                            }

                            handleSave();
                        }}
                        type="button"
                        disabled={!selectedFlatRows.length}
                        className="mt-2"
                    >
                        Set Confirmation
                    </Button>
                </div>
                {
                    openLogisticSelectionView && selectedFlatRows?.length > 0 &&
                    <ManualConfirmationLogisticSelectionView
                        onClose={() => setOpenLogisticSelectionView(false)}
                        onSave={() => {
                            setOpenLogisticSelectionView(false);
                            onSave();
                        }}
                        data={selectedFlatRows.map((r => r.original))}
                        status={status}
                    />
                }
            </Modal>
        </>
    )
};

ManualConfirmationEditor.propTypes = {
    rosterCandidateList: PropTypes.array.isRequired,
    confirmationType: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
}
