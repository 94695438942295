import React from 'react';
import { useTable, useGroupBy, useExpanded, useFilters, useSortBy, useFlexLayout } from 'react-table';
import _ from "lodash";
import { DefaultColumnFilter, SelectColumnFilter, NumberRangeColumnFilter } from "../../react-table/filters";
import Grid from "../../Grid";


function useControlledState(state, { instance }) {
    return React.useMemo(() => {
        if (state.groupBy.length) {
            return {
                ...state,
                hiddenColumns: [...state.hiddenColumns, ...state.groupBy].filter((col, index, all) => all.indexOf(col) === index),
            }
        }
        return state;
    }, [state])
}

function List(props) {
    const skipPageResetRef = React.useRef();
    const {
        data,
        handleFilterChange
    } = props;

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
                })
            },
        }),
        []
    )

    const columns = React.useMemo(() => {
        return [
            {
                Header: "Position",
                id: "position",
                accessor: "hrispositionMasterName",
                Filter: SelectColumnFilter,
                filter: "includes",
            },
            {
                Header: "Group",
                id: "group",
                accessor: "trainingGroup.lookupValue",
                Filter: SelectColumnFilter,
                filter: "includes",
            },
            {
                Header: "Training",
                id: "training",
                accessor: "trainingMaster.lookupValue",
                Filter: SelectColumnFilter,
                filter: "includes",
            },
            {
                Header: "Tag",
                id: "tag",
                accessor: "tag",
                Filter: DefaultColumnFilter,
                filter: "text",
            },
            // {
            //     Header: "Provider",
            //     accessor: "trainingProvider.lookupValue",
            //     Filter: SelectColumnFilter,
            //     filter: "includes",
            // },
            // {
            //     Header: "Funding Source",
            //     accessor: "fundingSource.lookupValue",
            //     Filter: SelectColumnFilter,
            //     filter: "includes",
            // },
            {
                Header: "Requirement",
                id: "requirement",
                accessor: "trainingRequirement.lookupValue",
                Filter: SelectColumnFilter,
                filter: "includes",
            },
            {
                Header: "Percentage",
                accessor: (row) => {
                    return row.percentRequired;
                },
                Cell: (cell) => {
                    return cell.value && `${cell.value}%`
                },
                Filter: NumberRangeColumnFilter,
                filter: (rows, [ column ], [ min, max ]) => {
                    if (!min && !max) {
                        return rows;
                    }
                    return _.filter(rows, (row) => (!min || row.values[column] >= min) && (!max || row.values[column] <= max));
                },
            },
            // {
            //     Header: "Start Date",
            //     accessor: (row) => row.startDate ? `${moment(row.startDate).format("DD/MM/YYYY")}` : "", //"startDate",
            //     Filter: DefaultColumnFilter,
            //     filter: 'text',
            // },
            // {
            //     Header: "End Date",
            //     accessor: (row) => row.endDate ? `${moment(row.endDate).format("DD/MM/YYYY")}` : "", //"endDate",
            //     Filter: DefaultColumnFilter,
            //     filter: 'text',
            // },
        ]
    }, []);

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 150, 
            maxWidth: 200,
            Filter: DefaultColumnFilter,
            filter: "text",
            disableSortBy: true,
    }), []);


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state
    } = useTable(
        {
            columns,
            data,
            filterTypes,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout,
        useFilters,
        useGroupBy,
        useSortBy,
        useExpanded,
        (hooks) => {
            hooks.useControlledState.push(useControlledState);
            hooks.visibleColumns.push((columns, { instance }) => {
                if (!instance.state.groupBy.length) {
                    return columns
                }
        
                return [
                    {
                        id: 'expander', // Make sure it has an ID
                        // Build our expander column
                        Header: ({ allColumns, state: { groupBy } }) => {
                        return groupBy.map(columnId => {
                            const column = allColumns.find(d => d.id === columnId)
            
                            return (
                            <span {...column.getHeaderProps()}>
                                {column.canGroupBy ? (
                                // If the column can be grouped, let's add a toggle
                                <span {...column.getGroupByToggleProps()}>
                                    {column.isGrouped ? <i className="fas fa-minus mr-1"></i> : <i className="fas fa-plus mr-1"></i>}
                                </span>
                                ) : null}
                                {column.render('Header')}{' '}
                            </span>
                            )
                        })
                        },
                        Cell: ({ row }) => {
                        if (row.canExpand) {
                            const groupedCell = row.allCells.find(d => d.isGrouped)
            
                            return (
                            <span
                                {...row.getToggleRowExpandedProps({
                                style: {
                                    // We can even use the row.depth property
                                    // and paddingLeft to indicate the depth
                                    // of the row
                                    paddingLeft: `${row.depth * 2}rem`,
                                },
                                })}
                            >
                                {row.isExpanded ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-right"></i>} {groupedCell.render('Cell')}{' '}
                                ({row.subRows.length})
                            </span>
                            )
                        }
            
                        return null
                        },
                    },
                    ...columns,
                ]
              })
        }
    )

    
    React.useEffect(() => {
        handleFilterChange([...state.filters]);
    }, [state.filters, handleFilterChange]);

    return (      
        <Grid
            rows={rows}
            tableProps={getTableProps()}
            headerGroups={headerGroups}
            tableBodyProps={getTableBodyProps()}
            prepareRow={prepareRow}
            height='calc(100vh - 436px)'
        />
    )

}

export default List;