import React, { useCallback } from 'react';
import api from "../../../services/api";
import {
    Button, Modal
 } from "reactstrap";
 import { Skills } from './Tabs';
 import { formatDateZeroTime } from "../../../utils";
import Loader from '../../loaders';
import SweetAlert from 'react-bootstrap-sweetalert';
import jsonFormData from "json-form-data";

export default function EditorEmail(props) {
    const [ itemInEdit, setItemInEdit ] = React.useState({});
    const [ loading, setLoading ] = React.useState(false);
    const [ validationAlert, setValidationAlert ] = React.useState(null);

    const handleSave = useCallback(() => {
        if (!itemInEdit.candidateSkills || itemInEdit.candidateSkills.length === 0)
        {
            setValidationAlert({
                message: "Candidate skills cannot be empty."
            });
            return;
        }

        setLoading(true);

        const data = {
            candidateIds: props.items,
            candidateSkills: itemInEdit.candidateSkills
        }

        console.log(data);

        var form = jsonFormData(data, {
            initialFormData: new FormData(),
            showLeafArrayIndexes: true,
            includeNullValues: false,
            mapping: function(value) {
                // if (typeof value === 'boolean') {
                //     return +value ? '1': '0';
                // }
                return value;
            }
        });
        
        const formData = new FormData();

        form.forEach((value, key, parent) => {

            if (value instanceof File || value instanceof Blob) {
                formData.append(key.replace(/(\[)([a-zA-Z]+)(\])/, ".$2").replace(/\[\d+\]$/, ""), value, value.name)
            }
            else 
            {
                formData.append(key.replace(/(\[)([a-zA-Z]+)(\])/, ".$2"), value)
            }
        })

        api({ url: `candidate/bulkskills`, method: "POST", data: formData })
            .then(() => {
                setLoading(false);
                props.onSaved();
            })
            .catch((error) => {
                console.error(error.response);
                setLoading(false);
            });
    }, [itemInEdit]);

    const handleTextChanged = useCallback(({ target: { name, value } }) => {
        setItemInEdit({ ...itemInEdit, [name]: value });
    }, [itemInEdit]);
    
    const handleDateChanged = useCallback((value, name) => {
        setItemInEdit({ 
            ...itemInEdit, 
            [name]: !value ? null : formatDateZeroTime(value) //moment(value).format("YYYY-MM-DDT00:00:00") 
        });
    }, [itemInEdit]);

    const editorProps = {
        itemInEdit, 
        setItemInEdit,
        handleTextChanged,
        handleDateChanged,
        lookups: props.lookups,
    };

    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
            {(loading) && <Loader />}
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Skill Editor
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}>x</span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                <Skills {...editorProps} />
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={props.onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave()}
                    type="button"
                >
                    Add Skills
                </Button>
            </div>
            {
                validationAlert &&
                <SweetAlert
                    title="Error!"
                    error
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    onConfirm={() => setValidationAlert(null)}
                >
                {
                    validationAlert.message
                }
                </SweetAlert>
            }
        </Modal>
    )
}