import React, { useEffect, useState } from "react";
import { Row, Col, Input } from 'reactstrap';
import { DropdownBox } from "../../../../dropdowns";
import { TooltipButton } from "../../../../inputs";

export const PhoneNumberRenderer = (props) => {
    const {
        dataItem,
        phoneTypes,
        onDataChanged,
        onSendSms,
    } = props;

    const [ itemInEdit, setItemInEdit ] = useState({...dataItem});
    const [ updateMainData, setUpdateMainData ] = useState(false);

    useEffect(() => {
        setItemInEdit({...dataItem});
    }, [dataItem]);

    useEffect(() => {
        if (!updateMainData)
            return;

        setUpdateMainData(false);
        onDataChanged({...itemInEdit});
    }, [updateMainData]);

    return (
        <Row className="w-100 my-2 mx-0">
            <Col lg={4} className="d-flex flex-wrap justify-content-center align-items-center">
                <DropdownBox
                    data={phoneTypes}
                    className="font-size-xs-custom"
                    onChange={(e) => {
                        setItemInEdit({...itemInEdit, phoneType: e, phoneTypeId: e.lookupId});
                        setUpdateMainData(true);
                    }}
                    selectedItem={itemInEdit?.phoneType ?? null}
                />
            </Col>
            <Col lg={5} className="d-flex flex-wrap justify-content-center align-items-center">
                <Input
                    name={`phoneNumber-${itemInEdit?.uid}`}
                    id={`phoneNumber-${itemInEdit?.uid}`}
                    type="text"
                    required
                    value={itemInEdit?.phoneNumber ?? ""}
                    onChange={(e) => {
                        e.preventDefault();
                        setItemInEdit({...itemInEdit, phoneNumber: e.target.value});
                        setUpdateMainData(true);
                    }}
                    className="font-size-xs-custom"
                />
            </Col>
            <Col lg={3} className="d-flex flex-wrap justify-content-center align-items-center">
                <TooltipButton
                    disabled={!itemInEdit?.phoneNumber || itemInEdit.phoneNumber === ""}
                    onClick={() => onSendSms(itemInEdit)}
                    id={`send-sms-${itemInEdit?.uid}`}
                    title="Send SMS"
                    className="btn-icon"
                    color="default"
                    size="xss"
                >
                    <i className="fas fa-paper-plane font-size-xs-custom" />
                </TooltipButton>
                <TooltipButton
                    id={`remove-phone-${itemInEdit?.uid}`}
                    title="Remove"
                    className="btn-icon"
                    color="warning"
                    size="xss"
                    onClick={() => onDataChanged(itemInEdit, true)}
                >
                    <i className="fas fa-trash-alt font-size-xs-custom" />
                </TooltipButton>
            </Col>
        </Row>
    );
};
