import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'reactstrap';
import api from "../../../../services/api";
import Loader from '../../../loaders';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';

const formatDate = "MMM DD, YYYY";

export default function AuditDialog(props) {
    const {
        onClose,
        data,
    } = props;

    const [ loading, setLoading ] = useState(false);
    const [ auditLogs, setAuditLogs ] = useState([]);
    const [ dates, setDates ] = useState([]);

    const loadData = useCallback(() => {
        setLoading(true);

        api.get(`rosteraudit/rostercandidate/${data?.rosterCandidateId}`)
            .then((response) => {
                const {data} = response;
                const newDates = [...new Set(data.map(d => moment(d.entryDate).format(formatDate)))];
                console.log({data, newDates});
                setAuditLogs(data);
                setDates(newDates);
            })
            .catch((error) => console.log({error}))
            .finally(() => setLoading(false))
        ;
    }, [data]);

    useEffect(() => {
        loadData();
    }, [data]);

    return (
        <Modal
            isOpen={true}
            className="modal-fullscreen modal-fullscreen-xl"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Audit Log - Slot {data?.slot}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body p-2"
                style={{maxHeight: "70vh", overflowY: "auto"}}
            >
                <Container fluid>
                    {loading && <Loader />}
                    {/* <Row className="m-0">
                        <Col xs={12}>
                            <span style={{fontSize: "0.8rem"}}>Legend:<br style={{lineHeight: "1rem"}} /><i className="fas fa-long-arrow-alt-right text-default mr-2" />reply from the talent</span>
                        </Col>
                    </Row> */}
                    <Row className="m-0 mt-2">
                    {
                        dates?.map((date, idx) => {
                            const auditsOnDate = auditLogs.filter(a => moment(a.entryDate).format(formatDate) === date);

                            return (
                                <Col xs="12" key={`audit-${idx}`} className="px-2 my-1">
                                    <Row className="m-0 p-0 bg-blue-light rounded-top">
                                        <Col xs="12">
                                            <span className="font-size-message-alert-custom"><b>{date}</b></span>
                                        </Col>
                                    </Row>
                                    <Row className="m-0 p-0 my-1">
                                        {
                                            auditsOnDate?.map((a, d_idx) => {
                                                // console.log('auditsOnDate');
                                                // console.log('a', a);
                                                return (
                                                    <Fragment key={`audit-d-${d_idx}`}>
                                                        <Col xs="12" className={`font-size-message-alert-custom text-break d-flex flex-wrap justify-content-start align-items-center${d_idx === 0 ? "" : " mt-1"}`}>
                                                            <div className="bg-grey-light d-flex flex-wrap justify-content-center align-items-center p-2 mr-1" style={{ height: 25, lineHeight: 0}}>{moment(a.entryDate).format("HH:mm")}</div>
                                                            <div className={`rounded-circle bg-${a.messageType && a.message ? (a.isMessageSent ? "yellow" : "default text-white") : "green-light"} d-flex flex-wrap justify-content-center align-items-center ml-2`} style={{width: 25, height: 25}}><i className={`fas fa-${a.messageType ? "envelope" : "edit"}`} /></div>
                                                            <span className="ml-2"><bold>{a.createdBy}</bold>: {a.changes}</span>
                                                        </Col>
                                                    {
                                                        a.messageType && a.message ?
                                                        <Col xs="12" className="text-break d-flex flex-wrap justify-content-start align-items-center mt-1 p-2 border">
                                                            <blockquote class="blockquote m-0">
                                                                <p class="font-size-xs-custom mb-0">{a.message}</p>
                                                            </blockquote>
                                                        </Col>
                                                        :
                                                        null
                                                    }
                                                    </Fragment>
                                                );
                                            })
                                        }
                                    </Row>
                                </Col>
                            )
                        })
                    }
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer d-flex flex-wrap"
            >
                <Button
                    color="secondary"
                    onClick={onClose}
                    type="button"
                    className="mt-2"
                >
                    Close
                </Button>
            </div>
        </Modal>
    );
}