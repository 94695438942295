import React from 'react';
import {
    Input, Label, FormGroup, Row, Col
 } from "reactstrap"; 
import { DatePicker } from "../../../date-pickers";
import { formatDate } from "../../../../utils";

export default function Interview(props) {

    const {
        itemInEdit, 
        setItemInEdit,
        handleTextChanged,
        handleDateChanged,
    } = props;
    
    return (
        <Row>
            <Col lg={6} xs={12}>
                <FormGroup>
                    <Label>
                        Interview Date
                    </Label>
                    <DatePicker
                        name="interviewDate"
                        id="interviewDate"
                        type="text"
                        required
                        value={formatDate(itemInEdit.interviewDate)}
                        onChange={handleDateChanged}
                        closeOnSelect                                
                    />
                </FormGroup>
            </Col> 
            <Col xs={12}>
                <FormGroup>
                    <Label>
                        Interview Notes
                    </Label>
                    <Input
                        name="interviewNotes"
                        id="interviewNotes"
                        type="textarea"
                        rows={6}
                        value={itemInEdit.interviewNotes ?? ""}
                        onChange={handleTextChanged}
                    />
                </FormGroup>
            </Col>
            <Col lg={6} xs={12}>
                <FormGroup className="">
                    <Label for="verified">
                        Verified
                    </Label>
                    <label className="custom-toggle custom-toggle-primary d-block">
                        <input type="checkbox" 
                            name="verified"
                            id="verified"
                            checked={itemInEdit.verified} 
                            onChange={({ target: { checked } }) => {
                                setItemInEdit({ ...itemInEdit, verified: checked })
                            }} 
                        />
                        <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Yes"></span>
                    </label>
                </FormGroup>
            </Col>
            <Col lg={6} xs={12}>
                <FormGroup>
                    <Label>
                        Verified By
                    </Label>
                    <Input
                        name="verifiedBy"
                        id="verifiedBy"
                        type="text"                        
                        value={itemInEdit.verifiedBy ?? ""}
                        onChange={handleTextChanged}
                    />
                </FormGroup>
            </Col>
        </Row>
    )
}