import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";

export default function ConfirmRollbackAlert(props) {

    const {
        onConfirm,
        onCancel
    } = props;

    return (
        <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, rollback it!"
            confirmBtnBsStyle="danger"
            title={<span className="mb-0 h4">Are you sure?</span>}
            onConfirm={onConfirm} // need to add loading indicator on confirm
            onCancel={onCancel}
            focusCancelBtn
        >
        This procedure will rollback status of this record.
        </SweetAlert>
    );
}



