import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

//import { MsalProvider } from "./auth/msalContext";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import { msalConfig } from "./auth/authConfig";
import { LicenseManager } from 'ag-grid-enterprise';

import "./assets/css/nucleo-svg.css";
import "./assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-design-system.scss?v1.0.0";

LicenseManager.setLicenseKey(process.env.REACT_APP_AGGRID_LICENSE)

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback(async (event) => {
    //console.log(event.eventType, event);
    switch (event.eventType) {
        case EventType.ACQUIRE_TOKEN_FAILURE:
            break;    
        case EventType.ACQUIRE_TOKEN_NETWORK_START:
            break;    
        case EventType.ACQUIRE_TOKEN_START:
            break;    
        case EventType.ACQUIRE_TOKEN_SUCCESS:
            // console.log("accessToken: ", event)
            // sessionStorage.setItem("ALTRAD.HRIS.accessToken", JSON.stringify({
            //     accessToken: event.payload.accessToken,
            //     expiresOn: event.payload.expiresOn,
            // }))
            break;    
        case EventType.HANDLE_REDIRECT_END:
            break;    
        case EventType.HANDLE_REDIRECT_START:
            break;    
        case EventType.LOGIN_FAILURE:
            break;    
        case EventType.LOGIN_START:
            break;    
        case EventType.LOGIN_SUCCESS:
            msalInstance.setActiveAccount(event.payload.account);

            // const accessToken = await msalInstance.acquireTokenSilent(apiRequest).then(response => {
            //     if (response.accessToken) {
            //         return response.accessToken;
            //     }
            //     return null;
            // });
            
            // console.log("accessToken: ", accessToken)
            break;  
        case EventType.LOGOUT_FAILURE:
            break;  
        case EventType.LOGOUT_START:
            break;  
        case EventType.LOGOUT_SUCCESS:
            break;  
        case EventType.SSO_SILENT_FAILURE:
            break;  
        case EventType.SSO_SILENT_START:
            break;  
        case EventType.SSO_SILENT_SUCCESS:
            break;
        default:
            break;
    }

});

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
//   <MsalProvider
//     config={msalConfig}
//     scopes={loginRequest}
//   >
    <MsalProvider instance={msalInstance}>
        <BrowserRouter basename={baseUrl}>
        <App />
        {/* <App /> */}
        </BrowserRouter>
    </MsalProvider>
  , 
  rootElement
);

//registerServiceWorker();
unregister();


