import React from 'react';
import {
    Card, CardHeader, CardBody, CardFooter,
    ListGroup, ListGroupItem, Button, ButtonGroup, DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown
} from "reactstrap";
import PropTypes from "prop-types";
import { isArray } from 'lodash';

function DropdownButton(props) {

    const [dropdownOpen, setOpen] = React.useState(false);
    const toggle = () => setOpen(!dropdownOpen);



    const {
        icon,
        icon2,
        title,
        title2,
        onClick,
        onClick2,
        children,
        size = 'xss',
        color = 'default',
        dataList = [],
        dataItem = null,
        buttonGroupClassName = ""
    } = props;

    return (
        <>
            { /*
            <ButtonGroup>
                <Button className="mb-3" color="primary" onClick={onClick ? onClick : () => { }}>
                    {icon && <li className={icon}></li>}
                    {title}
                </Button>
                <ButtonDropdown className="mb-3" isOpen={dropdownOpen} toggle={toggle} direction="down" >
                    <DropdownToggle caret color="primary" />
                    <DropdownMenu>
                        {
                            children.filter(f => f.visible).map(({ title, onClick, icon, disabled }, index) => {
                                var isDisabled = typeof disabled == 'undefined' ? false : disabled;
                                var onClick_ = onClick && !isDisabled ? onClick : () => { };
                                return (
                                    <DropdownItem key={index} disabled={isDisabled} onClick={onClick_}>
                                        {icon && <li className={icon}></li>}
                                        {title}
                                    </DropdownItem>);
                            })
                        }
                    </DropdownMenu>
                </ButtonDropdown>
            </ButtonGroup>
            */}

            <ButtonGroup className={`dropdown-button-group ${buttonGroupClassName}`}>
                <Button
                    color={color}
                    onClick={(event) => onClick(event, dataItem)}
                    size={size}
                >
                    <span className="font-size-small-custom">
                        <i className={`${icon} mr-2 ml-2`} />
                        <span className="mr-2">{title}</span>
                    </span>
                </Button>
                {
                    (title2 || icon2) &&
                    <div color={color} className={`btn-group btn-group${size}`}>
                        <Button
                            color={color}
                            onClick={(event) => onClick2(event, dataItem)}
                            size={size}
                        >
                            <span className="font-size-small-custom">
                                <i className={`${icon2} ml-2 mr-2`} />
                                {title2 && <>title2</>}
                            </span>
                        </Button>
                    </div>
                }
                <ButtonDropdown
                    isOpen={dropdownOpen}
                    toggle={toggle}
                    color={color}
                    size={size}
                >
                    <DropdownToggle caret color={color} />
                    <DropdownMenu className="p-0 grid-dropdown-menu">
                        {
                            children.filter(f => f.visible).map(({ title, onClick, icon, disabled }, index) => {
                                var isDisabled = typeof disabled == 'undefined' ? false : disabled;
                                var onClick_ = onClick && !isDisabled ? onClick : (event) => { };
                                return (
                                    <DropdownItem
                                        onClick={onClick_}
                                        className="p-0 px-3 m-0"
                                        disabled={isDisabled}
                                    >
                                        <span className="font-size-small-custom">
                                            <i className={`${icon} mr-2 w-10px`} /> {title}
                                        </span>
                                    </DropdownItem>);
                            })
                        }
                    </DropdownMenu>
                </ButtonDropdown>
            </ButtonGroup>

        </>
    )
}

//DropdownButton.PropTypes = {
//    icon: PropTypes.string,
//    title: PropTypes.string,
//    onClick: PropTypes.func,
//    children: PropTypes.arrayOf(PropTypes.shape({
//        title: PropTypes.string,
//        onClick: PropTypes.func,
//        icon: PropTypes.string,
//    }))
//}



export default DropdownButton;