////import React, { useState } from 'react';
import React, { useEffect, useState, useCallback, useMemo, useRef, Component } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import { useParams, useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import api from "../../../services/api";
import qs from "qs";
import { useLocation } from 'react-router-dom';
import { SortsConverter, FiltersConverter } from "../../../helpers"
import {
    Container, Row, Col, Button, Card, CardBody, CardTitle, UncontrolledTooltip, Progress, Label
} from "reactstrap";

//import { DefaultColumnFilter } from "../../../react-table/filters";
import { useTable, useFilters, useSortBy, useFlexLayout, useBlockLayout } from 'react-table';

import moment from "moment";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../alerts";
import CopyToClipboard from "react-copy-to-clipboard";
import { TooltipButton } from '../../inputs';

import fileDownload from "js-file-download";
import Loader from "../../loaders";
//import DateFilter from "../../../date-filters"
//import { DatePicker } from '../../../date-pickers';
import { DefaultColumnFilter, DateColumnFilter } from "../../react-table/filters";
import { formatDate, formatDateTime, BulkConfirmationMode, ViewAccess } from '../../../utils';
import { IndeterminateCheckbox } from '../../react-table/controls';
import { useSticky } from 'react-table-sticky';
//import { SetupPanel } from './panels'
import RateImportDialog from './RateImportDialog';

import { ParagraphNotification } from '../../alerts';
import { DateComparator, DateValueFormatter, CurrencyValueFormatter, MakeWordsValueGetter, ImportDialogMessageCellRenderer } from '../../../helpers/AgGridHelper';


import EditorEmail from '../Candidates/EditorEmail';
import SMSEditor from '../Candidates/EditorSMS';
import ConfirmBigDownloadAlert from "../../alerts/ConfirmBigDownloadAlert"
import { Mode } from "../../../utils";
import TcEditor from "./TcEditor";
import TcImportDialog from './TcImportDialog';


const timeoutLength = 1500;


export default function TcGrid(props) {
    //const { token } = useMsal();
    const history = useHistory();
    const [data, setData] = React.useState([]);
    const [attrCols, setAttrCols] = React.useState([]);
    const [drawerMode, setDrawerMode] = React.useState(Mode.NONE);

    const params = useParams();
    const [copiedIndex, setCopiedIndex] = React.useState(null);

    const {
        selectedProject,
        topLeftBar,
        setTopLeftBar
    } = props;



    React.useEffect(() => {
        if (copiedIndex !== null) {
            const timeoutId = setTimeout(() => {
                setCopiedIndex(null);
            }, timeoutLength);

            return () => clearTimeout(timeoutId);
        }
    }, [copiedIndex]);




    const [loading, setLoading] = React.useState(false);
    const [itemInEdit, setItemInEdit] = React.useState(null);
    const [createNew, setCreateNew] = React.useState(false);
    const [canAutoOpen, setCanAutoOpen] = React.useState(true);


    const [downloadAlert, setDownloadAlert] = useState([]);



    const [pageNumber, setPageNumber] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(50);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [deleteAlert, setDeleteAlert] = React.useState([]);
    const [lookups, setLookups] = React.useState({});
    const [filters, setFilters] = React.useState([]);

    const defaultSort = React.useMemo(() => [
        { id: "orderIndex", desc: false },
        { id: "rosterCandidateNumber", desc: false },
        { id: "projectName", desc: false },

    ], []);

    const [sortBy, setSortBy] = React.useState(defaultSort);
    const skipPageResetRef = React.useRef();
    const apiurl = "termandcondition";


    const location = useLocation();
    const {
        projectid,
        dispatchunitid,
        rosterid,
    } = qs.parse(location.search, { ignoreQueryPrefix: true });


    // BEGIN PARAGRAPH NOTIFICATION VARIABLES
    const [pnMessage, setPnMessage] = React.useState(null);
    const [pnTitle, setPnTitle] = React.useState(null);
    const [paragraphNotification, setParagraphNotification] = React.useState([]);
    const [pnMessageOpening, setPnMessageOpening] = React.useState(``);
    const [pnTitleOnGenerate, setPnTitleOnGenerate] = React.useState(``);
    // END PARAGRAPH NOTIFICATION VARIABLES


    // Access Right 
    const [viewAccess, setViewAccess] = React.useState(ViewAccess.FULLACCESS);
    const { instance } = useMsal();
    const user = instance.getActiveAccount();
    const isEditable = useMemo(() => { return viewAccess == ViewAccess.READONLY ? false : true }, [viewAccess]);
    const isReadonly = useMemo(() => { return viewAccess == ViewAccess.READONLY ? true : false }, [viewAccess]);
    const editableCellClass = useMemo(() => { return viewAccess == ViewAccess.READONLY ? `roster-cell` : `editable-cell roster-cell` }, [viewAccess]);


    const [importDialogIsOpen, setImportDialogIsOpen] = useState(false);


    // BEGIN AG GRID VARS -------------------------------------------------------------------------------------------------------------------------

    const [versionCounter, setVersionCounter] = useState(0);
    const gridRef = useRef();
    const [gridOptions, setGridOptions] = useState(null);
    //const [serverSideInitialRowCount, setServerSideInitialRowCount] = useState(0);

    // END AG GRID VARS ---------------------------------------------------------------------------------------------------------------------------

    // BEGIN AG GRID FUNCTIONS -------------------------------------------------------------------------------------------------------------------------

    const handleActionTaken = useCallback((original) => {
        // console.log(original);
        setLoading(true);
        const newAction = !original.actionTaken;

        api.put(`/${apiurl}/actiontaken/${original.messageLogId}?action=${newAction}`)
            .then((response) => {
                const responseData = response.data;
                const newData = [...data];
                const checkedRow = newData.filter(d => d.messageLogId === original.messageLogId)[0];
                const checkedIdx = newData.indexOf(checkedRow);
                const newRow = { ...checkedRow };
                newRow.actionTaken = newAction;
                newRow.actionTakenDate = responseData.actionTakenDate;
                newRow.modifiedBy = responseData.modifiedBy;
                newData.splice(checkedIdx, 1, newRow);
                // console.log({original, newRow, newData, data, responseData});
                setData(newData);
                updateSingleRow(original.messageLogId);
            })
            .catch((error) => {
                console.error("error: ", { error });
            })
            .finally(() => setLoading(false));
    }, [data]);

    const resizeColumns = useCallback(() => {
        if (!gridRef?.current?.columnApi || data?.length === 0)
            return;

        const allColumnIds = [];
        gridRef.current.columnApi.getColumns().forEach((column) => {
            // console.log(column, column.getId());
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }, [data, gridRef]);

    /*
    useEffect(() => {

        // Refresh datasource
        if (gridRef && gridRef.current && gridRef.current.api) {
            var datasource = getServerSideDatasource();
            gridRef.current.api.setServerSideDatasource(datasource);
        }

        // Refresh data
        refreshCache([]);

    }, [gridRef, params.action, params.requestId]);
    */

    const defaultColumns1 = [
        // Single Group Column (Custom)
        //{
        //    // group column name
        //    headerName: 'Category',
        //    // use the group cell render provided by the grid
        //    cellRenderer: 'agGroupCellRenderer',
        //    // informs the grid to display row groups under this column
        //    showRowGroup: "employmentCategory.lookupValue",
        //    //name: "employmentCategory.lookupValue",
        //    //rowGroup: true,
        //    flex: 2,
        //},
        {
            title: "Category",
            name: "employmentCategory.lookupValue",
            rowGroup: true,
            hide: true,
            flex: 2,
        },
        {
            title: "Component",
            name: "component.lookupValue",
            flex: 2,
        },
        {
            title: "Value",
            name: "componentValue",
            flex: 2,
        },
        {
            title: "Start Date",
            name: "startDate",
            field: 'date',
            filter: 'agDateColumnFilter',
            valueFormatter: DateValueFormatter,
            filterParams: {
                comparator: DateComparator
            },
            flex: 1,
        },
        {
            title: "End Date",
            name: "endDate",
            field: 'date',
            filter: 'agDateColumnFilter',
            valueFormatter: DateValueFormatter,
            filterParams: {
                comparator: DateComparator
            },
            flex: 1,
        },
        {
            title: "Location",
            name: "location.locationName",
            flex: 2,
        },
        {
            title: "Position",
            name: "position.positionName",
            flex: 2,
        },

    ];

    const defaultColumns2 = [

        {
            name: "button",
            title: "Action",
            pinned: 'right',
            filter: '',
            //width: 85,
            width: 130,
            filterParams: {
                name: 'action'
            },

            sortable: false,
            floatingFilter: false,
            cellClass: "action-button-cell",
            cellRenderer: (params) => {
                var { data } = params;
                var index = 0;

                if (!data) return null;

                return (
                    <div key={data.termAndConditionId} style={{ width: "100%" }} className={`d-flex flex-wrap justify-content-center align-items-center`}>
                        <TooltipButton
                            id={`edit_${data.termAndConditionId}`}
                            title="Edit"
                            className="btn-icon mb-2"
                            color="default"
                            size="xsm"
                            type="button"
                            onClick={(event) => {
                                setItemInEdit(data);
                                setDrawerMode(Mode.EDIT);
                            }}
                        >
                            <i className="fas fa-pencil-alt pt-1"></i>
                        </TooltipButton>
                        <TooltipButton
                            id={`delete_${data.termAndConditionId}`}
                            title="Delete"
                            className="btn-icon mb-2"
                            color="warning"
                            size="xsm"
                            type="button"
                            onClick={(event) => handleDelete(data)}
                        >
                            <i className="fas fa-trash-alt pt-1"></i>
                        </TooltipButton>
                    </div>
                )
            }
        },
    ];


    const autoGroupColumnDef = {
        filterValueGetter: params => params.data.employmentCategory?.lookupValue,
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['reset'],
            debounceMs: 200,
            excelMode: 'mac',
        },
        floatingFilter: true,
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        suppressMenu: true,
    };

    const [agGridSort, setAgGridSort] = useState(['asc', 'desc']);

    const columnDefs = useMemo(() => {
        var defaultCols1 = defaultColumns1.map((c) => {
            var obj = {
                ...c,
                field: c.name,
                headerName: c.title,
            };

            if ('name' in obj) delete obj.name;
            if ('title' in obj) delete obj.title;

            return obj;
        });
        var defaultCols2 = defaultColumns2.map((c) => {
            var obj = {
                ...c,
                field: c.name,
                headerName: c.title,
            };

            if ('name' in obj) delete obj.name;
            if ('title' in obj) delete obj.title;

            return obj;
        });

        var attrNo = 0;

        // If there is custom columns
        if (attrCols && attrCols.length) {
            var attrHeader = attrCols.map(s => {
                attrNo++;
                var { key, name, orderIndex } = s;
                return {
                    field: key,
                    headerName: name,
                    //headerName: `${attrNo}. ${name}`,
                };
            });

            return defaultCols1.concat(attrHeader, defaultCols2);
        }

        // If there is no custom columns
        else {

            return defaultCols1.concat(defaultCols2);
        }
    }, [attrCols]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        filter: true,
        editable: false,
        //filter: 'agSetColumnFilter',
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['reset'],
            debounceMs: 200,
            excelMode: 'mac',
        },
        floatingFilter: true,
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        suppressMenu: true,
    }), []);

    const loadingOverlayComponent = useMemo(() => Loader, []);

    const onCellEditRequest = useCallback((event) => {
        console.log("on cell edit request: ", { event });
    }, []);

    const autoSelectResources = useCallback((gridApi) => {
    });

    const sideBar = useMemo(() => ({
        toolPanels: [
            /*
            {
                id: 'setupPanel',
                labelDefault: 'Setup',
                labelKey: 'setupPanel',
                iconKey: 'fa-gear',
                toolPanel: SetupPanel,
                width: 280,
                toolPanelParams: {
                    today: new Date(),
                    loadSchedule,
                    autoSelectResources,
                    setLoading,
                    projectid,
                    dispatchunitid,
                    viewAccess,
                    data,
                },
            },
            */
        ],
        defaultToolPanel: 'setupPanel',
        position: "left"
    }), [fileDownload]);

    const refreshCache = useCallback((route) => {
        //console.log('gridRef', gridRef);
        if (!gridRef || !gridRef.current || !gridRef.current.api)
            return;

        setVersionCounter(versionCounter + 1);
        gridRef.current.api.purgeServerSideCache();

    }, [versionCounter, params]);



    const updateSingleRow = useCallback((id) => {
        var gridApi = gridRef.current.api;
        gridApi.forEachNode(rowNode => {

            if (id == rowNode.data.messageLogId) {
                //console.log('rowNode', rowNode);

                const url = `/${apiurl}/${id}`;
                setLoading(true);
                api.get(url)
                    .then(({ data }) => {
                        //console.log('data to set', 'data', data);
                        rowNode.setData(data)
                    }).catch((error) => {
                        console.error("error: ", { error });
                    }).finally(() => setLoading(false));
            }
        });
    }, [gridRef]);


    // END AG GRID FUNCTIONS ---------------------------------------------------------------------------------------------------------------------------


    React.useEffect(() => {
        if (params.action === "new")
            setCreateNew(true);
    }, [params.action])

    const loadData = React.useCallback((paramObject = null) => {

        /*
        if (params2 && 'api' in params2) {
            var { api: api2 } = params2;
            api2.hideOverlay();
        }
        */

        //console.log('params2', params2);

        /*
        var paramObject = {
            projectId: 493,
            start: '2022-10-01',
            end: '2022-12-31'
        };
        */

        const queryString = qs.stringify(paramObject, { allowDots: false });
        skipPageResetRef.current = true;
        setItemInEdit(null);
        setCreateNew(false);
        setData([]);

        setDrawerMode(Mode.NONE);

        if (!selectedProject) {
            return
        }

        const url = `/${apiurl}/${selectedProject.projectId}?${queryString}`;
        setLoading(true);
        api.get(url)
            .then(({ data }) => {

                // Set data
                var newData = [...data];
                setData(newData);
                setTotalRecords(newData.length);

            }).catch((error) => {
                setItemInEdit(null);
                setData([]);
                setAttrCols([]);
                console.error("error: ", { error });
            }).finally(() => setLoading(false));
    }, [filters, sortBy, pageNumber, pageSize, params, selectedProject]);

    const handleDelete = React.useCallback((item) => {
        const success = (response) => {
            if (!response.status === 200) {
                throw new Error("Delete failed.");
            }
            setDeleteAlert([<DeleteSuccessAlert onConfirm={() => setDeleteAlert([])} />]);
            loadData();
        };
        const failure = () => setDeleteAlert([<DeleteFailedAlert onConfirm={() => setDeleteAlert([])} />]);
        const events = {
            onConfirm: () => api.delete(`/${apiurl}/${item.rateId}`).then(success).catch(failure),
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert {...events} />])
    }, [loadData]);

    React.useEffect(() => {
        console.log("data", data);
        if (canAutoOpen && data && data.length === 1 && params.requestId && params.action && params.action === "joborder") {
            setItemInEdit(data[0]);
        }
    }, [canAutoOpen, data, params]);


    // BEGIN PARAGRAPH NOTIFICATION HANDLER --------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {

        if (!pnMessage) {
            setParagraphNotification([]);
            return;
        }

        var events = {
            onConfirm: () => {
                setPnMessage(null);
                setPnTitle(null);
            },
            message: pnMessage,
            title: pnTitle ?? pnTitleOnGenerate
        }
        setParagraphNotification([<ParagraphNotification {...events} />]);

    }, [pnMessage, pnTitle]);
    // END PARAGRAPH NOTIFICATION HANDLER -----------------------------------------------------------------------------------------------------------------------


    // On load
    useEffect(() => {

        // Set top left bar
        setTopLeftBar(topLeftBarForThis);

        // Lookup api
        const apiCalls = [api.get('lookup/component'), api.get('lookup/employmentcategory')];
        Promise.all(apiCalls)
            .then((response) => {
                setLookups({
                    components: [...response[0].data],
                    employmentcategories: [...response[1].data]
                })
            })
            .catch(error => console.error(error))
            //.finally(() => setLoading(false));
            ;
    }, []);
    useEffect(() => {
        loadData();
        setTopLeftBar(topLeftBarForThis);
    }, [selectedProject])

    var topLeftBarForThis = <>
        <TooltipButton
            id="addnew"
            title="Add New"
            className="btn-icon mr-2 mb-2"
            color="default"
            size="sm"
            type="button"
            disabled={!selectedProject}
            onClick={() => {
                setItemInEdit({
                    termAndConditionId: 0,
                    projectId: selectedProject.projectId,
                    startDate: new Date()
                });
                setDrawerMode(Mode.ADD);
            }}
        >
            <i className="fas fa-plus pt-1"></i>
        </TooltipButton>
        <TooltipButton
            id="export"
            title="Export to Excel"
            className="btn-icon mr-2 mb-2"
            color="default"
            size="sm"
            type="button"
            disabled={!selectedProject}
            onClick={() => {
                setLoading(true);

                let url = `${apiurl}/export/${selectedProject.projectId}`;
                api.get(url, { responseType: 'blob' })
                    .then(blob => {
                        console.log("Download: ", blob);
                        fileDownload(blob.data, `Term and Condition of ${selectedProject.projectName}.xlsx`);
                    }).catch(error => {
                        console.error(error)
                    }).finally(() => setLoading(false))
            }}
        >
            <i className="fas fa-file-excel"></i> Export
        </TooltipButton>
        <TooltipButton
            id="import"
            title="Import"
            className="btn-icon mr-2 mb-2"
            color="default"
            size="sm"
            type="button"
            disabled={!selectedProject}
            onClick={() => {
                //setLoading(true);
                setImportDialogIsOpen(true);

            }}
        >
            <i className="fas fa-file-import"></i> Import
        </TooltipButton>
        <h5 className="text-left ml-3 d-inline">Terms &amp; Conditions</h5>
    </>;

    return (
        <>
            {loading && <Loader />}
            {deleteAlert.length > 0 && deleteAlert}
            {downloadAlert.length > 0 && downloadAlert}
            {paragraphNotification.length > 0 && paragraphNotification}
            <Container fluid className="px-0">

                <Row noGutters>
                    <Col xs={12}>
                    </Col>
                    <Col sm={12}>
                        <div className="ag-theme-alpine ag-grid ag-ignore-disabled-input" style={{ width: '100%', height: 'calc(100vh - 200px)' }}>
                            <AgGridReact
                                ref={gridRef}
                                gridOptions={gridOptions}
                                //domLayout={'autoHeight'}
                                columnDefs={columnDefs}
                                rowData={data}
                                defaultColDef={defaultColDef}
                                loadingOverlayComponent={loadingOverlayComponent}
                                //sideBar={sideBar}
                                suppressColumnVirtualisation={false}
                                suppressRowVirtualisation={false}
                                debounceVerticalScrollbar={false}
                                rowBuffer={20}
                                headerHeight={30}
                                groupHeaderHeight={30}
                                rowHeight={30}
                                floatingFiltersHeight={30}
                                // readOnlyEdit={true}
                                // onRowDataUpdated={() => resizeColumns()}
                                suppressDragLeaveHidesColumns={true}
                                suppressColumnMoveAnimation={true}
                                animateRows={false}
                                onCellEditRequest={onCellEditRequest}
                                excelStyles={excelStyles()}
                                pagination={true}
                                paginationPageSize={pageSize}
                                cacheBlockSize={pageSize}
                                //rowModelType={'serverSide'}
                                //serverSideInfiniteScroll={true}
                                //onGridReady={onGridReady}
                                sortingOrder={agGridSort}
                                groupDisplayType={'multipleColumns'}
                                autoGroupColumnDef={autoGroupColumnDef}
                            />
                        </div>
                    </Col>
                </Row >


            </Container >

            {
                !!itemInEdit &&
                <TcEditor
                    item={itemInEdit}
                    components={[...lookups.components]}
                    employmentcategories={[...lookups.employmentcategories]}
                    onClose={() => {
                        setItemInEdit(null);
                        setDrawerMode(Mode.NONE);
                    }}
                    onSaved={() => {
                        loadData();
                        setDrawerMode(Mode.NONE);
                    }}
                    drawerMode={drawerMode}
                    setDrawerMode={setDrawerMode}
                />
            }
            {
                importDialogIsOpen && !!selectedProject &&
                <TcImportDialog
                    projectId={selectedProject.projectId}
                    onClose={() => {
                        setImportDialogIsOpen(false);
                        setLoading(false);
                    }}
                    loadData={loadData}
                />
            }
        </>
    )

}


export const excelStyles = () => {
    const headerDefaultStyle = {
        alignment: {
            vertical: 'Center',
            horizontal: "Center",
        },
        interior: {
            color: '#f8f8f8',
            pattern: 'Solid',
            patternColor: undefined,
        },
        borders: {
            borderTop: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderRight: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderBottom: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderLeft: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
        },
        font: {
            fontName: 'Roboto',
            color: '#181d1f',
            bold: true,
            size: 12,
        },
    }

    return [
        {
            id: 'header',
            ...headerDefaultStyle
        },
        {
            id: 'date-header',
            ...headerDefaultStyle,
            alignment: {
                vertical: 'Center',
                horizontal: "Center",
                rotate: 90,
            },
        },
        {
            id: 'cell',
            alignment: {
                vertical: 'Top',
                wrapText: true,
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid',
                patternColor: undefined,
            },
            borders: {
                borderTop: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderRight: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderBottom: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderLeft: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
            },
            font: {
                fontName: 'Roboto',
                color: '#181d1f',
                weight: 700,
                size: 12,
            },
        },
    ];
};


