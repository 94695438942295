import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import _ from "lodash";

const ProtectedRoute = ({ component: Component, allowedRoles, ...rest }) => {
    
    const { instance, accounts, inProgress } = useMsal();

    const { idTokenClaims: { roles } } = instance.getActiveAccount();

    const allowed = _.some(roles, o => allowedRoles && allowedRoles.includes(o));

    return (
        <Route {...rest} render={
            props => {
                if (allowed) {
                    return <Component {...rest} {...props} />
                } else {
                    return <Redirect to={
                        {
                            pathname: '/unauthorized',
                            state: {
                                from: props.location
                            }
                        }
                    } />
                }                
            }
        } />
    )
}

export default ProtectedRoute;