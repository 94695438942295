const TabList = [
    {
        title: "Profile",
        tabIndex: 0,
    },
    {
        title: "Qualifications / Skills",
        tabIndex: 4,
    },
    {
        title: "Recruitment",
        tabIndex: 5,
    },
    {
        title: "Documents",
        tabIndex: 8,
    },
    {
        title: "Comment and Notification History",
        tabIndex: 9,
    },
    {
        title: "Project Specific Attribute",
        tabIndex: 10,
    },
    {
        title: "Dispatch History",
        tabIndex: 17,
    },
    {
        title: "Allocation History",
        tabIndex: 11,
    },
    {
        title: "Flights",
        tabIndex: 16,
    },
    {
        title: "Identifiers",
        tabIndex: 12,
    },
    {
        title: "Activities",
        tabIndex: 13,
    },
    {
        title: "Inactive Dates",
        tabIndex: 14,
    },
    {
        title: "Positions",
        tabIndex: 15,
    },
    {
        title: "Site Access",
        tabIndex: 18,
    },
    {
        title: "Training History",
        tabIndex: 19,
    },
];

export default TabList;
