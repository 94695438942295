import React from 'react';
import moment from "moment";
import { useTable, useFlexLayout } from 'react-table';
import _ from "lodash";
import Loader from "../../loaders";
import { UncontrolledTooltip } from "reactstrap";

function Matrix(props) {

    const {
        selectedProject,
        cols,
        data,
        setItemInEdit,
        setPositionInEdit
    } = props;
    
    function getTextWidth(text, font) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
      
        context.font = font || getComputedStyle(document.body).font;
      
        return context.measureText(text).width;
    }

    const columns = React.useMemo(() => {
        return cols.filter(f => f.name === "Position" || f.columns.length > 0).map((grp, grpIndex) => {
            return {
                Header: grp.name,
                accessor: !grp.columns ? grp.path || grp.name : undefined,
                width: grpIndex === 0 ? 300 : undefined,
                id: grp.id,
                columns: 
                    grp.columns && grp.columns.length > 0 ? 
                    grp.columns.map((col) => ({
                        Header: col.name,
                        accessor: `${col.path}.values.trainingRequirementName`,
                        align: "center",
                        id:  col.tnaTrainingMasterId,
                    })) : grpIndex > 0 ? [{Header: `Placeholder_${grpIndex}`, placeholder: true}] : null
            }
        }
    )}, [ cols ]);

    const columnSize = React.useMemo(() => {
        let longestTextLength = 0, longestText;
        const check = (col) => {
            if (col.name.length > longestTextLength) {
                longestTextLength = col.name.length;
                longestText = col.name;
            }
            if (col.columns && col.columns.length > 0) {
                _.forEach(col.columns, check)
            }            
        }
        _.forEach(cols, check);

        const height = Math.ceil(getTextWidth(longestText));
        
        return {
            rowHeight: height + 20,
            bottomPosition: height / 2,   
            leftPosition: 32
        };
    }, [cols])

    const defaultColumn = React.useMemo(() => ({
        // When using the useFlexLayout:
        minWidth: 30, // minWidth is only used as a limit for resizing
        width: 100, // width is used for both the flex-basis and flex-grow
        maxWidth: 200, // maxWidth is only used as a limit for resizing
        // Block layout:
        //width: 60
    }), [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    
    } = useTable(
        {
            columns,
            data,
            defaultColumn
        },
        useFlexLayout
    );

    const innerHeaderStyle = (column) => ({
        transform: "rotate(-90deg)",
        width: "0px",
        marginBottom: "-3px",
        display: column.placeholder ? "none" : "flex"
    })
    
    return (<div style={{overflowX: "scroll"}}>
        <div {...getTableProps()} className="table bordered">
            <div className="thead">
                {headerGroups.map((headerGroup, headerGroupIndex) => {

                    let {style, ...headerGroupProps} = headerGroup.getHeaderGroupProps();
                    if (headerGroupIndex === 1) {
                        style = {
                            ...style,
                            height: `${columnSize.rowHeight}px`
                        }
                    }

                    return (<div {...headerGroupProps} style={style} className="tr">
                        {headerGroup.headers.map((column, index) => {

                            let {style, ...headerProps} = column.getHeaderProps();

                            style = headerGroupIndex === 1 ? {
                                ...style,
                                display: "flex",
                                alignItems: index === 0 ? "flex-end" : "flex-end",
                                justifyContent: index === 0 ? "flex-start" : "center"
                            } : {
                                ...style,
                                overflow: "hidden",
                                display: "flex",
                                justifyContent: "center"
                            }
                            console.log("HEADER: ", { headerGroup, headerGroupIndex, column, index, data, rows })
                            if (typeof column.Header == "string") {
                                headerProps.title = column.Header
                            }
                            return (index > 0 && ((headerGroupIndex === 0 && column.columns && !column.columns.some(o => o.placeholder)) || (headerGroupIndex > 0 && !column.placeholder))) 
                            ? (<div {...headerProps} style={style} className="th td-hover cursor-pointer" id={`header_${headerGroupIndex}_${index}`} onClick={() => {
                                
                                

                                let headerKeys = []
                                if (headerGroupIndex === 0) 
                                {
                                    headerKeys = column.columns.map(o => ({header: o.Header, id: o.id}))
                                } 
                                else 
                                {
                                    headerKeys = [{header: column.Header, id: column.id}];
                                }

                                const items = _.flatten(data.map(o => headerKeys.map(k => o[k.header]))).filter(f => f !== null);

                                setItemInEdit({ 
                                    tnaTrainingMasterIds: _.uniq(items.map(o => o.tnaTrainingMasterId)), 
                                    tnaPositionIds: _.uniq(items.map(o => o.tnaPositionId)),
                                    projectId: selectedProject.projectId
                                });

                                //console.log("HEADER: ", { items, headerKeys })
                            }}>
                                <div style={headerGroupIndex === 1 && index > 0 ? innerHeaderStyle(column) : {}}>
                                    {column.render('Header')}
                                </div>
                                <UncontrolledTooltip target={`header_${headerGroupIndex}_${index}`}>
                                    {column.Header}
                                </UncontrolledTooltip>
                            </div>) 
                            : (<div {...headerProps} style={style} className="th">
                                <div style={headerGroupIndex === 1 && index > 0 ? innerHeaderStyle(column) : {}}>
                                    {column.render('Header')}
                                </div>
                            </div>)
                         })}
                    </div>)
                }
                )}
            </div>
            <div {...getTableBodyProps()} className="tbody">
                {rows.map((row, i) => {
                prepareRow(row);
                    //console.log("ROW: ", row)
                return (
                    <div {...row.getRowProps({id: `test-${i}`})} className="tr">
                        {row.cells.map((cell, index) => {

                            let {style, ...cellProps} = cell.getCellProps();

                            //console.log("CELL: ", {cell, row, index})
                            return index > 0 && !cell.column.placeholder
                            ? (
                            <div {...cellProps} 
                                style={{...style, display: "flex", justifyContent: "center"}} 
                                className="td td-hover cursor-pointer" 
                                onClick={(e) => {

                                    const values = cell.row.original[cell.column.Header]?.values;

                                    //console.log("EDIT: ", {cell, row, index, e, values, selectedProject})

                                    if (values) {
                                        setItemInEdit({
                                            ...values,
                                            position: {
                                                lookupValue: row.original.Position.title
                                            },
                                            trainingGroup: {
                                                lookupValue: cell.column.parent.Header
                                            },
                                            trainingMaster: {
                                                lookupValue: cell.column.Header
                                            },
                                            trainingRequirement: values.trainingRequirementId ? 
                                            _.find(props.lookups.trainingRequirements, o => o.lookupId === values.trainingRequirementId) 
                                            : null,
                                            trainingProvider: values.trainingProviderId ? 
                                            _.find(props.lookups.trainingProviders, o => o.lookupId === values.trainingProviderId) 
                                            : null,
                                            fundingSource: values.fundingSourceId ? 
                                            _.find(props.lookups.fundingSources, o => o.lookupId === values.fundingSourceId) 
                                            : null,
                                        });
                                    } else {

                                        setItemInEdit({
                                            projectId: selectedProject.projectId,
                                            trainingNeedAnalysisId: 0,
                                            tnaTrainingMasterId: cell.column.id,
                                            tnaPositionId: row.original.Position.tnaPositionId,
                                            fundingSourceId: null,
                                            trainingProviderId: null,
                                            trainingRequirementId: null,
                                            percentRequired: null,
                                            startDate: moment(),
                                            endDate: null,
                                            position: {
                                                lookupValue: row.original.Position.title
                                            },
                                            trainingGroup: {
                                                lookupValue: cell.column.parent.Header
                                            },
                                            trainingMaster: {
                                                lookupValue: cell.column.Header
                                            },
                                        })
                                    }
                                
                                }
                            }>
                                {cell.render('Cell')}
                            </div>
                            ) : !cell.column.placeholder ? (
                            <div {...cellProps} 
                                style={style} 
                                className="th td-hover cursor-pointer"
                                onClick={(e) => {
                                    //console.log("POSITION: ", { cell, row, index, cols, columns, data, rows });

                                    const item = {
                                        position: {
                                            positionId: row.original.Position.id,
                                            positionName: row.original.Position.title,
                                            tnaPositionId: row.original.Position.tnaPositionId
                                        },
                                        groups: _.keyBy(cols.filter(f => f.name !== "Position").map(o => {

                                            return {
                                                trainingGroupName: o.name,
                                                trainingGroupId: o.id,
                                                skills: o.columns.map(c => {
                                                    const value = row.original[c.name];
                                                    if (value) {
                                                        const { values, title, ...rest } = value;
                                                        return { trainingTitle: title, ...values, ...rest }
                                                    }
                                                    return undefined;
                                                }).filter(f => f !== undefined)
                                            }
                                        }), o => o.trainingGroupId)
                                    }
                                    
                                    //console.log("ITEM: ", item);
                                    setPositionInEdit(item);
                                }}
                            >
                                {cell.render('Cell')}
                            </div>
                            ) : (<div {...cellProps} 
                                style={style} 
                                className="td"
                            >
                                {cell.render('Cell')}
                            </div>)
                        })}
                    </div>
                )
                })}
            </div>
        </div>
        
    </div>)
}

export default Matrix;