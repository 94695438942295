import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, FormGroup, Label, Button } from 'reactstrap';
//import { ComboBox as ComboBox2 } from "../../../dropdowns";
//import { DatePicker as DatePicker2 } from "../../../date-pickers";
import { IndeterminateCheckbox } from '../../../../react-table/controls';
//import { formatDate } from "../../../../../utils";
//import { ComboBox } from "@progress/kendo-react-dropdowns";
//import { DatePicker } from "@progress/kendo-react-dateinputs";
//import ContextMenuHelper from "../helpers/ContextMenuHelper";
//import qs from "qs";
//import debounce from 'lodash.debounce';
//import useRoster from "../contexts/RosterContext";
//import { initialState } from "../reducers/rosterReducer";
//import moment from "moment";
//import { filterBy } from "@progress/kendo-data-query";
import api from "../../../../../services/api";

export default function SetupPanel (props) {

    const {
        showCompletedJobOrder,
        setShowCompletedJobOrder,
        showCancelledJobOrder,
        setShowCancelledJobOrder,
        setCreateNew,
        setLoading,
        fileDownload
    } = props;

    //const {
    //    api: gridApi,
    //    columnApi,
    //    today,
    //    loadSchedule,
    //    autoSelectResources,
    //    setLoading,
    //    projectid,
    //    dispatchunitid,
    //} = props;

    //const { 
    //    setDispatchUnit, 
    //    dispatchUnit,
    //    project,
    //    setProject,
    //    startDate,
    //    setStartDate,
    //    endDate,
    //    setEndDate,
    //    trimStartDate,
    //    setTrimStartDate,
    //    trimEndDate,
    //    setTrimEndDate,
    //    showArchivedDispatchUnits,
    //    setShowArchivedDispatchUnits,
    //    dataLoaded,
    //    setDispatchEditorOpen,
    //} = useRoster();



    const handleFilterClick = useCallback(() => {
        //localStorage.setItem(process.env.REACT_APP_HRIS_ROSTER_GRID_SETUP_STATE, JSON.stringify({
        //    project,
        //    dispatchUnit,
        //    trimStartDate,
        //    trimEndDate,
        //    startDate: formatDate(startDate, 'YYYY-MM-DD'),
        //    endDate: formatDate(endDate, 'YYYY-MM-DD'),
        //    showArchivedDispatchUnits
        //}));

    }, []);

    console.log('props', props);

    return (
        <Container fluid className='d-flex flex-column justify-content-between h-100'>
            <Row>
                <Col xs={12} className="pt-3">
                    <h5>Job Order Filter</h5>
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <IndeterminateCheckbox
                            id="showCompletedJobOrderCheckBox"
                            type="checkbox"
                            labelClassName="job-order-control mr-3"
                            onChange={() => {
                                setShowCompletedJobOrder(!showCompletedJobOrder);
                            }}
                            checked={showCompletedJobOrder}
                            customTitle="Show Completed Job Orders"
                        />
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <IndeterminateCheckbox
                            id="showCancelledJobOrderCheckBox"
                            type="checkbox"
                            labelClassName="job-order-control"
                            onChange={() => {
                                setShowCancelledJobOrder(!showCancelledJobOrder);
                            }}
                            checked={showCancelledJobOrder}
                            customTitle="Show Cancelled Job Orders"
                        />
                    </FormGroup>
                </Col>
                {/*
                <Col>
                    <Button
                        color="secondary"
                        onClick={handleFilterClick}
                        type="button"
                        className="w-100"
                    >
                        Filter
                    </Button>
                </Col>
                */}
            </Row>
            <Row className="mb-3">
                <Col xs={12} className="pt-3">
                    <h5>Actions</h5>
                </Col>
                <Col xs={12} className="">
                    <Button
                        color="secondary"
                        type="button"
                        className="w-100 text-left"
                        onClick={() => {
                            //setItemInEdit({});
                            setCreateNew(true);
                        }}
                    >
                        <i className="fas fa-plus pt-1"></i> Add New Data
                    </Button>
                </Col>
                <Col xs={12} className="pt-3">

                    <Button
                        color="secondary"
                        type="button"
                        className="w-100 text-left"
                        onClick={() => {
                            setLoading(true);

                            let url = `joborder/export`;
                            api.get(url, { responseType: 'blob' })
                                .then(blob => {
                                    console.log("Download: ", blob);
                                    fileDownload(blob.data, "joborder.xlsx");
                                }).catch(error => {
                                    console.error(error)
                                }).finally(() => setLoading(false))

                        }}
                    >
                        <i className="fas fa-file-excel"></i> Export
                    </Button>
                </Col>
                <Col xs={12} className="pt-3">
                    <Button
                        color="secondary"
                        type="button"
                        className="w-100 text-left"
                        onClick={() => {
                            setLoading(true);

                            let url = `joborder/exportcandidate`;
                            api.get(url, { responseType: 'blob' })
                                .then(blob => {
                                    console.log("Download: ", blob);
                                    fileDownload(blob.data, "jobordercandidate.xlsx");
                                }).catch(error => {
                                    console.error(error)
                                }).finally(() => setLoading(false))

                        }}
                    >
                        <i className="fas fa-file-excel"></i> Export Candidate
                    </Button>
                </Col>

            </Row>
        </Container>
    )
}