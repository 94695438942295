import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import api from "../../../../services/api";
import { useFlexLayout, useRowSelect, useTable } from "react-table";
import Loader from "../../../loaders";
import { TooltipButton } from "../../../inputs";
import { IndeterminateCheckbox } from "../../../react-table/controls";

function ApprovalRoleComponent(props) {
    const { id, title, data, onSelect, onMove, hideLabel, columnHeader, listPosition, defaultColumns, disableCheckFunction } = props;

    const columns = useMemo(() => defaultColumns ?? [
        {
            Header: columnHeader,
            accessor: 'lookupValue',
        },
    ], [data]);

    const defaultColumn = useMemo(
        () => ({
            minWidth: 30,
            width: 150,
            maxWidth: 200,
        }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // selectedFlatRows,
        toggleRowSelected,
    } = useTable (
        {
            columns,
            data,
            defaultColumn,
        },
        useFlexLayout,
        useRowSelect,
        // hooks => {
        //     hooks.visibleColumns.push(columns => [
        //         {
        //             id: 'selection',
        //             minWidth: 35,
        //             width: 35,
        //             maxWidth: 35,
        //             Header: ({ getToggleAllRowsSelectedProps }) => (
        //                 <div>
        //                     <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} id={`milestone-header-${id}`} />
        //                 </div>
        //             ),
        //             Cell: ({ row }) => (
        //                 <div>
        //                     <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} id={`milestone-${id}-${row.id}`} />
        //                 </div>
        //             ),
        //         },
        //         ...columns,
        //     ]);
        // }
    );

    // useEffect(() => {
    //     onSelect(selectedFlatRows.map(d => ({ ...d.original, selected: true })));
    // }, [selectedFlatRows]);

    return (
        <>
            {!hideLabel && <label className="mb-1"><b>{title}</b></label>}
            <div {...getTableProps()} className="table flex table-hover scrollable">
                <div className="thead">
                    {headerGroups.map(headerGroup => {
                        return (<div {...headerGroup.getHeaderGroupProps()} className="tr">
                            {
                                listPosition === "right" &&
                                <div className="th" style={{width: "60px"}} />
                            }
                            {headerGroup.headers.map((column, index) => {
                                return (<div {...column.getHeaderProps()} className="th">
                                    {column.render('Header')}
                                </div>);
                            })}
                            {
                                listPosition === "left" &&
                                <div className="th th-action text-right" style={{width: "60px"}} />
                            }
                        </div>);
                    }
                    )}
                </div>
                <div {...getTableBodyProps()} className="tbody">
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <div {...row.getRowProps()} className="tr">
                            {
                                listPosition === "right" &&
                                <div className="td td-action text-left" style={{width: "60px"}}>
                                    <TooltipButton
                                        id={`move_${i}`}
                                        title="Move"
                                        className="btn-icon"
                                        color="default"
                                        size="sm"
                                        type="button"
                                        outline
                                        onClick={() => {
                                            onMove(row.original)
                                        }}
                                        disabled={disableCheckFunction ? disableCheckFunction(row.original) : false}
                                    >
                                        <i className="fas fa-chevron-left"></i>
                                    </TooltipButton>
                                </div>
                            }
                            {
                                row.cells.map(cell => {
                                    return <div {...cell.getCellProps({
                                        onClick: cell.column.id !== "selection" ? () => toggleRowSelected(row.id) : undefined
                                    })}
                                        className={`td ${cell.column.id !== "selection" ? "cursor-pointer" : ""}`}
                                    >
                                        {cell.render('Cell')}
                                    </div>;
                                })
                            }
                            {
                                listPosition === "left" &&
                                <div className="td td-action text-right" style={{width: "60px"}}>
                                    <TooltipButton
                                        id={`move_${i}`}
                                        title="Move"
                                        className="btn-icon"
                                        color="default"
                                        size="sm"
                                        type="button"
                                        outline
                                        onClick={() => {
                                            onMove(row.original)
                                        }}
                                        disabled={disableCheckFunction ? disableCheckFunction(row.original) : false}
                                    >
                                        <i className="fas fa-chevron-right"></i>
                                    </TooltipButton>
                                </div>
                            }
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default function Editor(props) {
    const {data, onClose, onSave} = props;
    const {recruitmentTypeId} = data;
    const [ loading, setLoading ] = useState(false);
    const [ itemInEdit, setItemInEdit ] = useState({...data});
    const [ validationMessageObj, setValidationMessageObj ] = useState(null);
    const [ roles, setRoles ] = useState([]);
    const [ oldSelectedRoles, setOldSelectedRoles ] = useState([]);
    const [ selectedRoles, setSelectedRoles ] = useState([]);

    const loadData = useCallback(() => {
        if (!recruitmentTypeId)
            return;

        setLoading(true);

        const apiCalls = [
            api.get(`recruitmentapproval/flow/${recruitmentTypeId}`),
            api.get(`approvalrole/searchall`),
        ];

        Promise.all(apiCalls)
            .then(responses => {
                const responseSelectedRoles = ([...responses[0].data]).sort((a, b) => a.orderId - b.orderId);
                setSelectedRoles(responseSelectedRoles);
                setOldSelectedRoles(responseSelectedRoles);
                const selectedRoleIds = responseSelectedRoles.map(r => r.approvalRoleId);
                const responseRoles = [...responses[1].data].filter(r => !selectedRoleIds.filter(i => i === r.approvalRoleId).length > 0);
                setRoles(responseRoles);
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [recruitmentTypeId]);

    useEffect(() => {
        if (!recruitmentTypeId)
            return;

        loadData();
    }, [recruitmentTypeId]);

    const validate = useCallback(() => {
        if (!itemInEdit || !itemInEdit?.recruitmentTypeId)
        {
            setValidationMessageObj([{
                failCategory: "Empty Title",
            }]);
            return false;
        }

        setValidationMessageObj(null);
        return true;
    }, [itemInEdit]);

    const handleSave = useCallback(() => {
        if (!validate(itemInEdit))
            return;

        setLoading(true);
        // console.log({itemInEdit, selectedRoles});
        const recruitmentApprovalFlows = selectedRoles.map(r => ({
                recruitmentApprovalFlowId: r.recruitmentApprovalFlowId ?? 0,
                recruitmentTypeId: itemInEdit.recruitmentTypeId,
                approvalRoleId: r.approvalRoleId,
                orderId: r.orderId,
                isFinal: r.isFinal,
            })
        );
        const dataToSend = JSON.stringify(recruitmentApprovalFlows);
        const save = api.post(`/recruitmentapproval/flow/${itemInEdit.recruitmentTypeId}`, dataToSend);

        save
            .then(() => {
                setLoading(false);
                onSave();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            });
    }, [validate, itemInEdit, selectedRoles]);

    return (
        <Modal
            isOpen={true}
            className="modal-xl"
            modalClassName="db-example-modal-lg"
        >
            {loading && <Loader />}
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    LRF Approval Setting
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={() => onClose()}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                <Container>
                    <Row>
                    <   Col lg={4}>

                            <ApprovalRoleComponent
                                id="available-roles"
                                title="Available Roles"
                                data={roles}
                                onSelect={() => {}}
                                onMove={(data) => {
                                    const currentRoles = [...roles];
                                    const removedRole = currentRoles.filter(r => r.approvalRoleId === data.approvalRoleId)[0];
                                    currentRoles.splice(currentRoles.indexOf(removedRole), 1);
                                    setRoles(currentRoles);
                                    const currentSelectedRoles = [...selectedRoles];
                                    const currentRole = oldSelectedRoles.filter(r => r.approvalRoleId === data.approvalRoleId)[0] ?? {recruitmentTypeId, approvalRoleId: data.approvalRoleId, approvalRole: data};
                                    currentRole.orderId = currentSelectedRoles.length + 1;
                                    currentSelectedRoles.push(currentRole);
                                    currentSelectedRoles.sort((a, b) => a.orderId - b.orderId);
                                    setSelectedRoles(currentSelectedRoles);
                                }}
                                listPosition="left"
                                defaultColumns={[
                                    {
                                        Header: "Role Name",
                                        accessor: 'approvalRoleName',
                                    },
                                ]}
                            />
                        </Col>
                        <Col lg={8}>
                            <ApprovalRoleComponent
                                id="selected-roles"
                                title="Selected Roles"
                                data={selectedRoles}
                                onSelect={() => {}}
                                onMove={(data) => {
                                    const currentSelectedRoles = [...selectedRoles];
                                    const removedRole = currentSelectedRoles.filter(sp => sp.approvalRoleId === data.approvalRoleId)[0];
                                    currentSelectedRoles.splice(currentSelectedRoles.indexOf(removedRole), 1);

                                    if (currentSelectedRoles.length)
                                        for (let i = 0; i < currentSelectedRoles.length; i++)
                                            currentSelectedRoles[i].orderId = i + 1;

                                    setSelectedRoles(currentSelectedRoles);
                                    const currentRoles = [...roles];
                                    currentRoles.push(data.approvalRole);
                                    setRoles(currentRoles);
                                }}
                                disableCheckFunction={(row) => {
                                    return row.isUsed ?? false;
                                }}
                                listPosition="right"
                                defaultColumns={[
                                    {
                                        Header: "Role Name",
                                        accessor: 'approvalRole.approvalRoleName',
                                        width: 200,
                                    },
                                    {
                                        Header: "Order No.",
                                        accessor: 'orderId',
                                        width: 60,
                                    },
                                    {
                                        Header: "Is Used",
                                        id: 'isUsed',
                                        Cell: (args) => {
                                            const {row} = args;

                                            return (
                                                <div>
                                                    <span>{row.original.isUsed ? "Y" : "N"}</span>
                                                </div>
                                            );
                                        },
                                        width: 60,
                                    },
                                    {
                                        Header: "Is Final",
                                        id: "isFinal",
                                        Cell: (args) => {
                                            const {row} = args;

                                            return (
                                                <div>
                                                    <IndeterminateCheckbox
                                                        {...row.getToggleRowSelectedProps()}
                                                        checked={row.original.isFinal}
                                                        id={`selected_approval_role_${row.original.approvalRoleId}`}
                                                        onClick={(clickArgs) => {
                                                            const currentSelectedRoles = JSON.parse(JSON.stringify(selectedRoles));
                                                            const currentSelectedRole = currentSelectedRoles.filter(r => r.approvalRoleId === row.original.approvalRoleId)[0];

                                                            if (!currentSelectedRole)
                                                                return;

                                                            currentSelectedRole.isFinal = !currentSelectedRole.isFinal;
                                                            setSelectedRoles([...currentSelectedRoles]);
                                                        }}
                                                    />
                                                </div>
                                            );
                                        },
                                        width: 60,
                                    },
                                    {
                                        Header: "Action",
                                        id: "action",
                                        Cell: (args) => {
                                            const {row} = args;
                                            
                                            return (
                                                <div>
                                                {
                                                    row.original.orderId > 1 &&
                                                    <TooltipButton
                                                        id={`move_up_${row.original.approvalRoleId}`}
                                                        title="Move Up"
                                                        className="btn-icon"
                                                        size="sm"
                                                        color="default"
                                                        type="button"
                                                        outline
                                                        onClick={() => {
                                                            const currentSelectedRoles = JSON.parse(JSON.stringify(selectedRoles));
                                                            const currentSelectedRole = currentSelectedRoles.filter(r => r.approvalRoleId === row.original.approvalRoleId)[0];

                                                            if (!currentSelectedRole)
                                                                return;

                                                            const prevRole = currentSelectedRoles.filter(r => r.orderId === currentSelectedRole.orderId - 1)[0];

                                                            if (!prevRole)
                                                                return;

                                                            currentSelectedRole.orderId--;
                                                            prevRole.orderId++;
                                                            currentSelectedRoles.sort((a, b) => a.orderId - b.orderId);
                                                            setSelectedRoles([...currentSelectedRoles]);
                                                        }}
                                                    >
                                                    <i className="fas fa-chevron-up"></i>
                                                    </TooltipButton>
                                                }
                                                {
                                                    row.original.orderId < selectedRoles.length &&
                                                    <TooltipButton
                                                        id={`move_down_${row.original.approvalRoleId}`}
                                                        title="Move Down"
                                                        className="btn-icon"
                                                        size="sm"
                                                        color="default"
                                                        type="button"
                                                        outline
                                                        onClick={() => {
                                                            const currentSelectedRoles = JSON.parse(JSON.stringify(selectedRoles));
                                                            const currentSelectedRole = currentSelectedRoles.filter(r => r.approvalRoleId === row.original.approvalRoleId)[0];

                                                            if (!currentSelectedRole)
                                                                return;

                                                            const nextRole = currentSelectedRoles.filter(r => r.orderId === currentSelectedRole.orderId + 1)[0];

                                                            if (!nextRole)
                                                                return;

                                                            currentSelectedRole.orderId++;
                                                            nextRole.orderId--;
                                                            currentSelectedRoles.sort((a, b) => a.orderId - b.orderId);
                                                            setSelectedRoles([...currentSelectedRoles]);
                                                        }}
                                                    >
                                                        <i className="fas fa-chevron-down"></i>
                                                    </TooltipButton>
                                                }
                                                </div>
                                            );
                                        },
                                        width: 100,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={() => onClose()}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave()}
                    type="button"
                >
                    Save Changes
                </Button>
            </div>
        </Modal>
    )
};
