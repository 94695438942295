/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import api from "../../services/api";
import qs from "qs";
import {
    Button, CardTitle, Input, Label, FormGroup, Modal, Container, Row, Col, Alert
} from "reactstrap";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../alerts";
import PropTypes from "prop-types";
import Loader from "../loaders";
import { DefaultColumnFilter } from "../react-table/filters";
import { useTable, useExpanded, useFilters, useSortBy, useFlexLayout } from 'react-table';
import Grid from "../Grid";
import { ComboBox, DropdownBox } from '../dropdowns';
import { SketchPicker } from 'react-color';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from "moment";

// RTF Dependencies
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw, SelectionState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { v4 } from "uuid";
import { filter } from 'lodash';
import { HtmlToText, ShortenTextColumn } from '../../helpers/index';
import he from 'he';
import { TrimObject } from '../../helpers/StringHelper';

function CrudList(props) {
    const {
        endpoint,
        endpointAdditionalParameters,
        primaryKey,
        columns,
        title,
        defaultItem,
        readOnly,
        customCommandColumns,
        dropdownDataEndpoints,
        searchEndpoint,
        customValidation,
        customSort,
        colEditorFill = 'Y',
        colEditorEmpty = 'N',
        syncEnabled = false,
        syncEndpoint = "",
    } = props;

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [itemInEdit, setItemInEdit] = React.useState(null);
    const [filters, setFilters] = React.useState([]);
    const [showColorPicker, setShowColorPicker] = React.useState(false);
    const [dropdownData, setDropdownData] = useState(null);
    const [canSave, setCanSave] = useState({ value: false, message: null, data: null, needConfirmation: false, validationCallback: null });

    // Sync constants
    const [syncAlertMessage, setAlertSyncMessage] = useState(null);
    const [syncAlertStatus, setAlertSyncStatus] = useState("");
    const [syncIcon, setSyncIcon] = useState("fa-sync-alt");
    const [syncLastStatus, setSyncLastStatus] = useState("N/A");
    const [syncInlineText, setSyncInlineText] = useState("");
    const [syncInterval, setSyncInterval] = useState(1000);
    const [syncIsBusy, setSyncIsBusy] = useState(false);



    const defaultSort = React.useMemo(() => {

        if (customSort?.length) {
            return customSort;
        }

        if (columns?.length) {
            return [{ id: columns[0].accessor, desc: false }];
        }

        return [];
    }, [customSort, columns]);

    const [sortBy, setSortBy] = React.useState(defaultSort);

    const [pageNumber, setPageNumber] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(100);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [deleteAlert, setDeleteAlert] = React.useState([]);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [refreshEditor, setRefreshEditor] = React.useState(false);

    const [emailInvalid, setEmailInvalid] = React.useState(false);
    const skipPageResetRef = React.useRef();

    // RTF constants
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());

    const loadData = React.useCallback(() => {
        skipPageResetRef.current = true;
        const queryString = qs.stringify({ filters, sortBy }, { allowDots: true });

        if (errorMessage)
            setErrorMessage(null);

        setItemInEdit(null);
        //setData([]);

        setLoading(true);
        api.get(`/${searchEndpoint ?? endpoint}/${(pageNumber - 1) * pageSize}/${pageSize}${endpointAdditionalParameters ? `${endpointAdditionalParameters}` : ""}${queryString ? `?${queryString}` : ""}`)
            .then(({ data }) => {
                setData(data.data);
                setTotalRecords(data.total);
            }).catch((error) => {
                setData([]);
                setTotalRecords(0);
                console.error("error: ", error);
            }).finally(() => setLoading(false));
    }, [filters, sortBy, pageNumber, pageSize, errorMessage]);

    // BEGIN SYNC SECTION ------------------------------------------------------------------------------------------------------------

    const syncData = React.useCallback(() => {
        //setLoading(true);
        //syncCheckStatus();
        //setSyncInterval(500);
        if (syncLastStatus == "InProgress" || syncLastStatus == "Queued") {
            return;

        }
        setSyncLastStatus("Queued");
        setSyncInlineText(`Sync is on Progress. Start time: ${moment().format("DD/MM/YYYY HH:mm")}.`);
        var body = {
            waitForFinish: true
        };
        api.post(syncEndpoint, body)
            .then(({ data }) => {
                syncCheckStatus(true);
                setAlertSyncStatus(data.status);
                setAlertSyncMessage(data.message);
                loadData();
            }).catch((error) => {
                syncCheckStatus();
                console.error("error: ", error);
                if (error?.response?.data) {
                    setAlertSyncStatus(error.response.data.status);
                    setAlertSyncMessage(error.response.data.message);
                }
            }).finally(() => setLoading(false));
    }, [syncEndpoint, syncLastStatus]);


    useEffect(() => {
        if (syncEnabled && syncEndpoint) {
            syncCheckStatus(true);
        }
    }, []);

    useEffect(() => {

        if (syncLastStatus == "InProgress" || syncLastStatus == "Queued") {
            if (syncIcon != "fa-sync-alt") {
                setSyncIcon("fa-sync-alt");
                setSyncIsBusy(true);
            }
        }
        else {
            if (syncIcon != "fa-download") {
                setSyncIcon("fa-download");
                setSyncIsBusy(false);
            }
        }
    }, [syncLastStatus, syncIcon]);

    // When someone sync the data, check the status every 1 sec
    const syncCheckStatus = (repeatCall = false) => {
        api.get(syncEndpoint)
            .then(({ data }) => {
                var lastRun = !data ? "N/A" : moment(data.lastRun).format("DD/MM/YYYY HH:mm");
                var lastStatus = !data ? "N/A" : data.packageStatus;
                var msg = `Last Refresh: ${lastRun} - ${lastStatus}.`;

                // If sync is on progress, check again after 1 sec
                if (lastStatus == "InProgress" || lastStatus == "Queued") {
                    msg = `Sync is on Progress. Start time: ${lastRun}.`;
                }
                // If sync is succeeded or crashed, don't repeat call
                else {
                    repeatCall = false;
                }
                setSyncInlineText(msg);
                setSyncLastStatus(lastStatus);

                if (repeatCall) {
                    setTimeout(() => syncCheckStatus(repeatCall), syncInterval);
                }

            }).catch((error) => {
                console.error("error: ", error);
                setSyncInlineText("Error on checking sync status.");

            });
    }

    // END SYNC SECTION ------------------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (!dropdownDataEndpoints?.length)
            return;

        setLoading(true);
        dropdownDataEndpoints.sort(d => d.id);
        const endpoints = dropdownDataEndpoints.map(d => d.endpoint);

        Promise.all(endpoints)
            .then((responses) => {
                // console.log(responses);
                const data = {};
                responses.forEach((r, idx) => {
                    data[idx + 1] = r.data;
                });

                // console.log(data);
                setDropdownData(data);
            })
            .catch((error) => console.error(error.response))
            .finally(() => setLoading(false))
            ;
    }, [dropdownDataEndpoints]);

    const colEditorContent = (content) => {
        if (content && colEditorFill != null) {
            return colEditorFill;
        }

        if (!content && colEditorEmpty != null) {
            return colEditorEmpty;
        }
        if (content) {
            return ShortenTextColumn(content);
            //return content.replace(/<[^>]+>/g, '');
        }

        return content;
    }

    const handleDelete = (item) => {
        const success = () => {
            setDeleteAlert([<DeleteSuccessAlert onConfirm={() => setDeleteAlert([])} />]);
            if (data.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1);
            } else {
                loadData();
            }
        };
        const failure = () => setDeleteAlert([<DeleteFailedAlert onConfirm={() => setDeleteAlert([])} />]);
        const events = {
            onConfirm: () => api.delete(`/${endpoint}/${item[primaryKey]}`).then(success).catch(failure),
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert {...events} />])
    }

    const handleTextChanged = ({ target: { name, value } }) => {
        //const handleTextChanged = (target) => {
        //console.log('handleTextChanged', { target });
        //const { name, value } = target;
        setItemInEdit({ ...itemInEdit, [name]: value });
    }

    // BEGIN EDITOR HANDLER ---------------------------------------------------------------------------------------------------------------------------
    const onEditorStateChange = (editorState, name) => {
        //console.log('onEditorStateChange', { editorState});
        setEditorState(editorState);
        var value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        //var text = value == null ? '' : value.replace(/<[^>]*>/g, '').trim();
        var text = value == null ? '' : HtmlToText(value).trim();
        var store = text == '' ? null : value;
        setItemInEdit({ ...itemInEdit, [name]: store });
    }

    // Create editor state from html content
    const createEditorStateFromHtml = (html) => {
        const contentBlockArray = htmlToDraft(html ? html : "");
        if (html && contentBlockArray) {
            const contentState = ContentState.createFromBlockArray(contentBlockArray);
            return EditorState.createWithContent(contentState);
        }
        return EditorState.createEmpty();
    };

    // Get editor accessor from columns
    const editorAccessor = useMemo(() => {
        if (!columns?.length)
            return null;

        var editorAccessor = columns.find(c => c.inputType === 'editor')?.accessor;
        return editorAccessor;

    }, [columns]);

    // Set editor state from html content
    useEffect(() => {

        // If refresh editor or editor accessor is null, return
        if (!refreshEditor || !editorAccessor) {
            return;
        }

        // If no data, Set empty data to editor
        if (!itemInEdit) {
            setRefreshEditor(false);
            setEditorState(EditorState.createEmpty());
            return;
        }


        // If there is new data, Set new data to editor
        setRefreshEditor(false);
        const newEditorState = createEditorStateFromHtml(itemInEdit[editorAccessor]);
        setEditorState(newEditorState);

    }, [itemInEdit, refreshEditor, editorAccessor]);
    // END EDITOR HANDLER ---------------------------------------------------------------------------------------------------------------------------

    const handleDropdownChanged = (id, valueId, name, value) => {
        setItemInEdit({ ...itemInEdit, [id]: valueId, [name]: value });
    }

    useEffect(() => {
        if (!itemInEdit && showColorPicker != false)
            setShowColorPicker(false);

        //if (itemInEdit )
    }, [itemInEdit]);

    const saveData = (item) => {
        setLoading(true)
        let emailFields = [];
        for (const [key] of Object.entries(item)) {
            if (key === "emailTemplateId") { // HARD CODED emailtemplateID out of the maintenance master validation
                continue;
            }
            if (key.includes("email") || key.includes("Email")) {
                emailFields.push(key)
            }
        }
        var validator = require("email-validator");
        var allEmailValid = true;
        for (let i = 0; i < emailFields.length; i++) {
            // console.log("item email: ", item[emailFields[i]]);
            if (item[emailFields[i]] === "" || item[emailFields[i]] === null) {
                continue
            }
            if (!validator.validate(item[emailFields[i]])) {
                setEmailInvalid(true);
                setLoading(false);
                allEmailValid = false;
                break
            }
        }
        if (allEmailValid) {
            const data = JSON.stringify(item);
            const save = item[primaryKey] > 0
                ? api.put(`${endpoint}/${item[primaryKey]}`, data)
                : api.post(endpoint, data)

            save
                .then((response) => {
                    loadData();
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setLoading(false);
                    setEmailInvalid(false);
                });
        }
    }

    const handleSave = useCallback((item) => {
        item = TrimObject(item);
        setItemInEdit(item);

        if (customValidation) {
            // console.log({ item });
            customValidation(item, setLoading, (value, message, needConfirmation = false, validationCallback = null) => setCanSave({ value, message, data: item, needConfirmation, validationCallback }));
            return;
        }

        setCanSave({ value: true, message: null, data: item, needConfirmation: false });
    }, [customValidation]);

    useEffect(() => {
        if (!canSave.value) {
            setErrorMessage(canSave.message);
            return;
        }

        if (!canSave.needConfirmation && canSave.validationCallback) {
            canSave.validationCallback();
            return;
        }

        saveData(canSave.data);
    }, [canSave]);

    React.useEffect(() => {
        loadData();
    }, [pageNumber, pageSize, filters, sortBy]);

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue).replace(/[/-]/g, "")
                            .toLowerCase()
                            .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const columnsProcessed = React.useMemo(() => {
        const columnsMemo = [];

        columns.map((col, i) => {

            var nullValue = 'nullValue' in col || col.nullValue ? col.nullValue : 'N/A';

            if (col.inputType === "colorpicker") {
                columnsMemo.push(
                    {
                        Header: col.title,
                        width: col.width ?? 50,
                        disableSortBy: true,
                        Cell: ({ row: { original } }) =>
                        (
                            <div className="w-100 h-100" style={original[col.accessor] ? { backgroundColor: original[col.accessor], color: original[col.accessor] } : {}}>{original[col.accessor] ? "-" : null}</div>
                        ),
                        disableFilters: col.disableFilters !== undefined ? col.disableFilters : false,
                    }
                );
                return;
            }

            columnsMemo.push(
                {
                    Header: col.title,
                    accessor: (row) => {
                        // console.log({row, accessor: col.accessor, value: col.accessorEntity ? row[col.accessorEntity][col.accessor] : row[col.accessor]});
                        //return col.accessorEntity ? `${row[col.accessorEntity] ? row[col.accessorEntity][col.accessor] : ""}` : (col.inputType === "checkbox" || col.inputType === "editor" ? (colEditorContent(row[col.accessor])) : row[col.accessor] == null ? nullValue : `${row[col.accessor]}`);

                        if (col.accessorEntity) {
                            return row[col.accessorEntity] ? `${row[col.accessorEntity][col.accessor]}` : "";
                        } else if (col.inputType === "checkbox") {
                            return row[col.accessor] ? "Y" : "N";
                        } else if (col.inputType === "editor") {
                            return colEditorContent(row[col.accessor]);
                        } else {
                            return row[col.accessor] == null ? nullValue : `${row[col.accessor]}`;
                        }

                    },
                    filter: "text",
                    id: col.accessorEntity ? `${col.accessorEntity}.${col.accessor}` : col.accessor,
                    width: col.width ?? 200,
                    disableSortBy: col.disableSortBy !== undefined ? col.disableSortBy : false,
                    disableFilters: col.disableFilters !== undefined ? col.disableFilters : false,
                }
            );
        });

        if (!readOnly || customCommandColumns?.length) {
            columnsMemo.push(
                {
                    Header: "Actions",
                    id: 'button',
                    Cell: ({ row: { original } }) => {
                        if (readOnly)
                            return (
                                <div>
                                    {
                                        customCommandColumns.map(cc => cc(original, totalRecords, loadData, setLoading))
                                    }
                                </div>
                            );

                        return (
                            <div>
                                <Button
                                    className="btn-icon"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        setItemInEdit(original);
                                        setRefreshEditor(true);
                                    }}
                                >
                                    <i className="fas fa-pencil-alt pt-1"></i>
                                </Button>
                                <Button
                                    className="btn-icon"
                                    color="warning"
                                    size="sm"
                                    type="button"
                                    onClick={() => handleDelete(original)}
                                >
                                    <i className="fas fa-trash-alt pt-1"></i>
                                </Button>
                                {
                                    customCommandColumns?.map(cc => cc(original, totalRecords, loadData, setLoading))
                                }
                            </div>
                        );
                    },
                    width: 140,
                }
            );
        }

        return columnsMemo;
    }, [totalRecords, loadData]);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
    } = useTable(
        {
            columns: columnsProcessed,
            data,
            initialState: {
                sortBy: defaultSort,
                canSort: true,
            },
            filterTypes,
            defaultColumn,
            manualSortBy: true,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
        useExpanded
    );

    React.useEffect(() => {
        setFilters(state.filters);
    }, [state.filters]);

    React.useEffect(() => {
        setSortBy(state.sortBy);
    }, [state.sortBy]);

    return (
        <>
            {loading && <Loader />}
            {deleteAlert.length > 0 && deleteAlert}
            {
                errorMessage &&
                <SweetAlert
                    title={canSave?.needConfirmation ? "Confirmation" : "Error!"}
                    error={!canSave?.needConfirmation}
                    warning={canSave?.needConfirmation}
                    confirmBtnText={canSave?.needConfirmation ? "Yes" : "OK"}
                    confirmBtnBsStyle="danger"
                    onConfirm={() => {
                        if (canSave?.validationCallback) {
                            if (canSave?.needConfirmation)
                                return canSave?.validationCallback({
                                    selectedOption: true,
                                    callback: () => loadData()
                                });

                            setErrorMessage(null);
                        }
                        else
                            setErrorMessage(null);
                    }}
                    showCancel={canSave?.needConfirmation}
                    cancelBtnText={canSave?.needConfirmation ? "No" : null}
                    onCancel={() => {
                        if (canSave?.validationCallback)
                            return canSave?.validationCallback({
                                selectedOption: false,
                                callback: () => loadData()
                            });
                    }}
                >
                    {
                        errorMessage
                    }
                </SweetAlert>
            }
            {
                syncAlertMessage && syncAlertStatus == "Succeeded" &&
                <SweetAlert
                    title={`${title} Sync ${syncAlertStatus}`}
                    success
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    onConfirm={() => {
                        setAlertSyncStatus("");
                        setAlertSyncMessage(null);
                    }}
                >
                    {
                        syncAlertMessage
                    }
                </SweetAlert>
            }
            {
                syncAlertMessage && (syncAlertStatus == "Queued" || syncAlertStatus == "InProgress" || syncAlertStatus == "Crash" || syncAlertStatus.toLowerCase().includes("error")) &&
                <SweetAlert
                    title={`${title} Sync ${syncAlertStatus}`}
                    danger
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    onConfirm={() => {
                        setAlertSyncStatus("");
                        setAlertSyncMessage(null);
                    }}
                >
                    {
                        syncAlertMessage
                    }
                </SweetAlert>
            }
            <Container fluid>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <CardTitle>
                                    <h4 className="text-center">{title}</h4>
                                </CardTitle>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {!readOnly && <Button
                                    className="btn-icon ml-2 mb-2"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        setItemInEdit(defaultItem);
                                    }}
                                >
                                    <i className="fas fa-plus pt-1"></i>
                                </Button>}
                                {!!syncEnabled &&
                                    <>
                                        <Button
                                            className="btn-icon mr-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={() => syncData()}
                                        >
                                            <i className={`fas ${syncIcon} pt-1`}></i>
                                        </Button>

                                        <div className="d-inline">{syncInlineText}</div>
                                    </>
                                }
                            </Col>
                        </Row>
                        <Grid
                            height={'calc(100vh - 460px)'}
                            totalRecords={totalRecords}
                            pageSize={pageSize}
                            rows={rows}
                            tableProps={getTableProps()}
                            headerGroups={headerGroups}
                            tableBodyProps={getTableBodyProps()}
                            prepareRow={prepareRow}
                            onPagerChangePage={(pager) => {
                                setPageNumber(pager.currentPage);
                            }}
                            onPagerChangePageSize={(size) => {
                                setPageSize(size);
                            }}
                            needPaging={true}
                        />
                        {
                            !!itemInEdit &&
                            <Modal
                                isOpen={true}
                                className="modal-lg"
                                modalClassName="db-example-modal-lg"
                            >
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                        {title}
                                    </h5>
                                    <button
                                        aria-label="Close"
                                        className="close"
                                        onClick={() => setItemInEdit(null)}
                                        type="button"
                                    >
                                        <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <Container>
                                        {(loading) && <Loader />}
                                        <Row>
                                            {
                                                columns.map((col, idx) => {

                                                    let inputJsx = null;

                                                    if (col.isDropdown) {
                                                        inputJsx = (<DropdownBox
                                                            data={dropdownData[col.endpointId]}
                                                            selectedItem={itemInEdit[col.accessorEntity]}
                                                            isLookup={col.isLookup ?? false}
                                                            idField={col.isLookup ? "lookupId" : col.accessorId}
                                                            valueField={col.isLookup ? "lookupValue" : col.accessorValue}
                                                            onChange={(selected) => {
                                                                const accessorIdOnParent = col.accessorIdOnParent ? col.accessorIdOnParent : col.accessorId;
                                                                const accessorId = col.isLookup ? "lookupId" : col.accessorId;
                                                                handleDropdownChanged(accessorIdOnParent, selected ? selected[accessorId] : null, col.accessorEntity, selected);
                                                            }}
                                                            disabled={col.readonly ?? false}
                                                        />);

                                                    }
                                                    else if (col.isComboBox) {
                                                        inputJsx = (<ComboBox
                                                            endpoint={`${col.endpoint}`}
                                                            isLookup={col.isLookup ?? false}
                                                            idField={col.isLookup ? "lookupId" : col.accessorId}
                                                            valueField={col.isLookup ? "lookupValue" : col.accessorValue}
                                                            minLength={col.minLength ?? 1}
                                                            selectedItem={itemInEdit[col.accessorEntity]}
                                                            onChange={(selected) => {
                                                                const accessorIdOnParent = col.accessorIdOnParent ? col.accessorIdOnParent : col.accessorId;
                                                                const accessorId = col.isLookup ? "lookupId" : col.accessorId;
                                                                handleDropdownChanged(accessorIdOnParent, selected ? selected[accessorId] : null, col.accessorEntity, selected);
                                                            }}
                                                            disabled={col.readonly ?? false}
                                                        />);
                                                    }
                                                    else if (col.inputType === "textarea") {
                                                        inputJsx = (<Input
                                                            name={col.accessor}
                                                            id={col.accessor}
                                                            type={col.inputType}
                                                            rows={col.rows ?? 3}
                                                            required
                                                            value={itemInEdit[col.accessor] ?? ""}
                                                            onChange={handleTextChanged}
                                                            readOnly={col.readonly ?? false}
                                                        />);

                                                    } else if (col.inputType === "checkbox") {
                                                        inputJsx = (<Input
                                                            name={col.accessor}
                                                            id={col.accessor}
                                                            type={col.inputType}
                                                            required
                                                            checked={itemInEdit[col.accessor] === col.checkedValue ?? false}
                                                            onChange={() => {
                                                                // console.log(itemInEdit[col.accessor] === col.checkedValue ? col.uncheckedValue : col.checkedValue);
                                                                setItemInEdit({ ...itemInEdit, [col.accessor]: itemInEdit[col.accessor] === col.checkedValue ? col.uncheckedValue : col.checkedValue });
                                                            }}
                                                            readOnly={col.readonly ?? false}
                                                        />);
                                                    } else if (col.inputType === "editor") {
                                                        inputJsx = (<Editor
                                                            name={col.accessor}
                                                            id={col.accessor}
                                                            editorState={editorState}
                                                            toolbarClassName="toolbarClassName"
                                                            wrapperClassName="wrapperClassName"
                                                            editorClassName="editorClassName"
                                                            toolbar={{
                                                                options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'remove', 'history'], // Removed 'fontSize' and 'fontFamily'
                                                            }}
                                                            onEditorStateChange={(editorState) => {
                                                                onEditorStateChange(editorState, col.accessor);
                                                            }}
                                                        />);
                                                    } else if (col.inputType === "colorpicker") {
                                                        inputJsx = (<Row>
                                                            <Col xs={3} className="mr-0 my-0 pr-0 py-0">
                                                                <Button
                                                                    name={col.accessor}
                                                                    id={col.accessor}
                                                                    className="form-control rounded-left"
                                                                    size="xsm"
                                                                    type="button"
                                                                    style={itemInEdit[col.accessor] ? { backgroundColor: itemInEdit[col.accessor] } : {}}
                                                                    onClick={() => setShowColorPicker(!showColorPicker)}
                                                                />
                                                            </Col>
                                                            <Col xs={1} className="m-0 p-0">
                                                                <Button
                                                                    name={`clear_${col.accessor}`}
                                                                    id={`clear_${col.accessor}`}
                                                                    className="btn-icon rounded-right border-dark"
                                                                    color="white"
                                                                    size="xsm"
                                                                    type="button"
                                                                    onClick={() => setItemInEdit({
                                                                        ...itemInEdit,
                                                                        [col.accessor]: null
                                                                    })}
                                                                >
                                                                    <i className="fas fa-times-circle" />
                                                                </Button>
                                                            </Col>
                                                            <Col xs={8}>
                                                            </Col>
                                                            {
                                                                showColorPicker &&
                                                                <SketchPicker
                                                                    color={itemInEdit[col.accessor] ?? { hex: null }}
                                                                    onChangeComplete={(color) => {
                                                                        if (!color || !color?.hex)
                                                                            return;

                                                                        setItemInEdit({
                                                                            ...itemInEdit,
                                                                            [col.accessor]: color?.hex
                                                                        });
                                                                    }}
                                                                    className="mt-2"
                                                                    disableAlpha={true}
                                                                />
                                                            }
                                                        </Row>);
                                                    }
                                                    else {
                                                        inputJsx = (<Input
                                                            name={col.accessor}
                                                            id={col.accessor}
                                                            type={col.inputType ?? "text"}
                                                            required
                                                            value={itemInEdit[col.accessor] ?? ""}
                                                            onChange={handleTextChanged}
                                                            readOnly={col.readonly ?? false}
                                                            min={col.min ?? null}
                                                        />);
                                                    }
                                                    // console.log({col, data: dropdownData[col.endpointId]});
                                                    return (
                                                        <Col xs={12} key={idx}>
                                                            <FormGroup>
                                                                <Label className={col.inputType === "checkbox" ? "mr-4" : ""}>
                                                                    {col.title}
                                                                    {(!('isRequired' in col) || col.isRequired) && <span className="text-danger">*</span>}
                                                                </Label>
                                                                <>{inputJsx}</>
                                                            </FormGroup>
                                                        </Col>
                                                    );
                                                })
                                            }
                                            {/* <Col xs={12}>
                                                <FormGroup>
                                                    <Label>
                                                        Name
                                                        <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input
                                                        name="rateComponentName"
                                                        id="rateComponentName"
                                                        type="text"
                                                        required
                                                        value={itemInEdit.rateComponentName}
                                                        onChange={handleTextChanged}
                                                    />
                                                </FormGroup>
                                            </Col>  
                                            <Col xs={12}>
                                                <FormGroup>
                                                    <Label>
                                                        Description
                                                    </Label>
                                                    <Input
                                                        name="rateComponentDescription"
                                                        id="rateComponentDescription"
                                                        type="textarea"
                                                        value={itemInEdit.rateComponentDescription ?? ""}
                                                        onChange={handleTextChanged}
                                                    />
                                                </FormGroup>
                                            </Col> */}
                                        </Row>
                                        {emailInvalid &&
                                            <Alert
                                                color="warning" className="mb-0 d-flex mx-auto justify-content-between"
                                            >
                                                <ul className="mb-0 px-0 mx-auto">
                                                    Please check email(s) have been entered correctly
                                                </ul>
                                            </Alert>
                                        }
                                    </Container>
                                </div>
                                <div className="modal-footer">
                                    <Button
                                        color="secondary"
                                        onClick={() => {
                                            setItemInEdit(null);
                                            setEmailInvalid(false);
                                        }}
                                        type="button"
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        color="info"
                                        onClick={() => handleSave(itemInEdit)}
                                        type="button"
                                    >
                                        Save Changes
                                    </Button>
                                </div>
                            </Modal>
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )

}

CrudList.propTypes = {
    endpoint: PropTypes.string.isRequired,
    primaryKey: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
        accessor: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
    })).isRequired,
    title: PropTypes.string.isRequired,
    defaultItem: PropTypes.object,
    readOnly: PropTypes.bool,
    customCommandColumns: PropTypes.arrayOf(PropTypes.func),
    dropdownDataEndpoints: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        endpoint: PropTypes.object
    })),
    searchEndpoint: PropTypes.string,
    customValidation: PropTypes.func
}

CrudList.defaultProps = {
    defaultItem: {},
    readOnly: false
}

export default CrudList;