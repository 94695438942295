import React, { Component } from 'react';
import qs from 'query-string';

export default class GenericReport extends Component {
    componentDidMount() {
        const params = qs.parse(this.props.location.search);
        window.jQuery(`#${params.containerName}`)
            .telerik_ReportViewer({
                serviceUrl: 'api/reports/',
                reportSource: {
                    report: `./${params?.reportName}`
                },
                scale: 1.0,
                viewMode: 'INTERACTIVE',
                printMode: 'SPECIFIC',
            });
    }

    render() {
        const params = qs.parse(this.props.location.search);
        return <div id={params.containerName} className="reportViewer"></div>
    }
}
