import React from 'react';
import api from "../../../services/api";
import qs from "qs";
import { DefaultColumnFilter } from "../../react-table/filters";
import { useTable, useFlexLayout } from 'react-table';
import Loader from "../../loaders";
import _, { filter } from "lodash";
// import { useHistory } from "react-router-dom";
import Grid from "../../Grid";
import {
   Button, Row, Col, Collapse, Container, FormGroup, Label, Input
} from "reactstrap";
import MatrixCellDetailView from './MatrixCellDetailView';
import { DatePicker } from "../../date-pickers";
import { formatDate, rgbToHex } from "../../../utils";

const conflictedClass = "matrix-conflicted";

export default function Matrix(props) {
    const {
        project,
        showAllProjects,
        showFilters,
        reportMode,
        selectedCandidate,
        crewId,
        onClick,
    } = props;

    const [ loading, setLoading ] = React.useState(false);
    // const [ itemInEdit, setItemInEdit ] = React.useState({});
    const [ matrixData, setMatrixData ] = React.useState([]);
    const [ matrixCols, setMatrixCols ] = React.useState([]);
    const [ pageNum, setPageNum ] = React.useState(1);
    const [ pageSize, setPageSize ] = React.useState(10);
    const [ datePage, setDatePage ] = React.useState(0);
    const [ isMostLeft, setIsMostLeft ] = React.useState(false);
    const [ isMostRight, setIsMostRight ] = React.useState(false);
    const [ filterOpen, setFilterOpen ] = React.useState(false);
    const [ employeeCode, setEmployeeCode ] = React.useState("");
    const [ employeeName, setEmployeeName ] = React.useState("");
    const [ startDate, setStartDate ] = React.useState("");
    const [ endDate, setEndDate ] = React.useState("");
    const [ mobDate, setMobDate ] = React.useState("");
    const [ demobDate, setDemobDate ] = React.useState("");
    const [ filters, setFilters ] = React.useState([]);
    const [ selectedItem, setSelectedItem ] = React.useState(null);
    const dateFormat = "DD-MM-YYYY";
    const apiurl = "rostercandidate";
    
    function getTextWidth(text, font) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
      
        context.font = font || getComputedStyle(document.body).font;
      
        return context.measureText(text).width;
    }
    
    const loadData = React.useCallback(() => {
        if (!project)
            return;
        // setItemInEdit(null);
        setMatrixData([]);
        setMatrixCols([]);

        setLoading(true);
        const queryString = qs.stringify({crewId: crewId, filters: filters}, { allowDots: true });
        api.get(`/${apiurl}/schedules/${project.projectId}/${(pageNum - 1) * pageSize}/${pageSize}/${datePage}${queryString ? `?${queryString}` : ""}`)
            .then(({data}) => {
                if (!data || data.length === 0 || !data.rows || data.rows.length === 0 || !data.cols || data.cols.length === 0) {
                    setMatrixData([{
                        title: "No data found."
                    }]);
                    setMatrixCols(data.cols);
                    setIsMostLeft(true);
                    setIsMostRight(true);
                    setLoading(false);
                    return;
                }

                setMatrixData(data.rows);
                setMatrixCols(data.cols);
                setIsMostLeft(data.isMostLeft);
                setIsMostRight(data.isMostRight);
                setLoading(false);
            })
            .catch((error) => {
                console.error("error: ", error.response);
                setLoading(false);
            });
    }, [project, pageNum, pageSize, datePage, selectedCandidate, filters]);
    
    const loadAllData = React.useCallback(() => {
        setMatrixData([]);
        setMatrixCols([]);

        setLoading(true);
        const queryString = qs.stringify({crewId: crewId, filters: filters}, { allowDots: true });
        api.get(`/${apiurl}/allschedules/${(pageNum - 1) * pageSize}/${pageSize}/${datePage}${queryString ? `?${queryString}` : ""}`)
            .then(({data}) => {
                if (!data || data.length === 0 || !data.rows || data.rows.length === 0 || !data.cols || data.cols.length === 0) {
                    setMatrixData([{
                        title: "No data found."
                    }]);
                    setMatrixCols(data.cols);
                    setIsMostLeft(true);
                    setIsMostRight(true);
                    setLoading(false);
                    return;
                }

                setMatrixData(data.rows);
                setMatrixCols(data.cols);
                setIsMostLeft(data.isMostLeft);
                setIsMostRight(data.isMostRight);
                setLoading(false);
            })
            .catch((error) => {
                console.error("error: ", error.response);
                setLoading(false);
            });
    }, [pageNum, pageSize, datePage, crewId, selectedCandidate, filters]);

    const composeFilters = (items, newId, newVal) => {
        if (!newVal || newVal.length === 0)
            return items.filter(x => x.id !== newId);

        const filterStr = JSON.stringify(items);
        const finalFilters = JSON.parse(filterStr);

        if (finalFilters.filter(x => x.id === newId).length === 0)
        {
            finalFilters.push({id: newId, value: newVal});
            return finalFilters;
        }

        const objIdx = finalFilters.findIndex((item) => item.id === newId);
        finalFilters[objIdx].value = newVal;
        return finalFilters;
    };

    React.useEffect(() => {
        setFilters(composeFilters(filters, "code", employeeCode));
    }, [employeeCode]);

    React.useEffect(() => {
        setFilters(composeFilters(filters, "name", employeeName));
    }, [employeeName]);

    React.useEffect(() => {
        setFilters(composeFilters(filters, "startDate", startDate));
    }, [startDate]);

    React.useEffect(() => {
        setFilters(composeFilters(filters, "endDate", endDate));
    }, [endDate]);

    React.useEffect(() => {
        setFilters(composeFilters(filters, "mobDate", mobDate));
    }, [mobDate]);

    React.useEffect(() => {
        setFilters(composeFilters(filters, "demobDate", demobDate));
    }, [demobDate]);

    React.useEffect(() => {
        if(selectedCandidate)
            return;

        if(project && !showAllProjects)
        {
            loadData();
            return;
        }

        loadAllData();
    }, [project, pageNum, pageSize, datePage, showAllProjects, crewId, selectedCandidate]);

    const columns = React.useMemo(() => {
        if (!matrixCols || matrixCols.length === 0)
            return [{
                Header: "",
                id: "title",
                accessor: "title",
                width: 400
            }];

        return matrixCols.map((grp, grpIndex) => {
            return {
                Header: grp.id < -1 ?
                    grp.name : (
                        grp.id > -2 && grp.id <= 0 ?
                        (
                            grp.id === -1 ?
                            (
                                !isMostLeft ?
                                <div className="text-center align-middle">
                                    <Button
                                        className="btn-icon"
                                        size="sm"
                                        color="default"
                                        onClick={() => setDatePage(datePage - 1)}
                                    >
                                        <i className="fas fa-chevron-left"></i>
                                    </Button>
                                </div>
                                :
                                grp.name
                            )
                            :
                            (
                                !isMostRight ?
                                <div className="text-center align-middle">
                                    <Button
                                        className="btn-icon"
                                        size="sm"
                                        color="default"
                                        onClick={() => setDatePage(datePage + 1)}
                                    >
                                        <i className="fas fa-chevron-right"></i>
                                    </Button>
                                </div>
                                :
                                grp.name
                            )
                        )
                        :
                        <div className="text-center align-middle">
                            {grp.name}
                        </div>
                    ),
                width: grp.id < -1 ? 200 : (grp.id <= 0 ? 50 : 100),
                id: grpIndex,
                Cell: ({row: {original}}) => {
                    const id = `cell_item_${original.item4}`;
                    const element = document.getElementById(id);

                    if (original.item3.isConflicted && element && !element.parentElement.parentElement.classList.contains(conflictedClass))
                        element.parentElement.parentElement.classList.add(conflictedClass);

                    if (grp.id === -3)
                        return (
                            <div
                                className="w-100 h-100 m-0 p-0 align-middle"
                                id={id}
                            >
                                <span>
                                    <small className="w-100 h-100 m-0 p-0 align-middle">
                                        {original.item1}
                                    </small>
                                </span>
                                {original.item3.isConflicted ? <span className="ml-2 text-danger"><i className="fas fa-exclamation"></i></span> : null}
                            </div>
                        );

                    if (grp.id === -2)
                        return (
                            <div
                                className="w-100 h-100 m-0 p-0 align-middle"
                            >
                                <span>
                                    <small className="w-100 h-100 m-0 p-0 align-middle">
                                        {original.item2}
                                    </small>
                                </span>
                                {original.item3.isConflicted ? <span className="ml-2 text-danger"><i className="fas fa-exclamation"></i></span> : null}
                            </div>
                        );
                    
                    if (original.item3.dates.filter(x => x.date === grp.name).length === 0)
                        return (
                            <div
                                className="w-100 m-0 p-0"
                            >
                            </div>
                        );
                    
                    return original.item3.dates.filter(x => x.date === grp.name).map(item => {
                        const style = grp.id > 0 ? {backgroundColor: rgbToHex(item.colorR, item.colorG, item.colorB)} : {};

                        return (
                            <div
                                key={item.projectName + item.dispatchUnitName + item.rosterName + "-" + item.date}
                                className="w-100 my-1 p-0 cursor-pointer"
                                onClick={onClick ? () => onClick(item) : undefined}
                                id={`cell_inner_${item.rosterCandidateId}_${grp.name}`}
                                onMouseOver={() => {
                                    setSelectedItem(item);
                                }}
                                onMouseLeave={() => {
                                    setSelectedItem(null);
                                }}
                            >
                                <div
                                    className={`w-100 h-100 ${item.isConflicted || (item.colorR + item.colorG + item.colorB) / 3 <= 210 ? "text-white" : "text-dark"} align-middle px-1 py-1`}
                                    style={style}
                                >
                                    <small className="w-100 h-100">
                                        {
                                            selectedItem?.rosterCandidateId === item.rosterCandidateId && selectedItem?.date === grp.name ?
                                            <>
                                                {selectedItem.projectName}
                                                <br/>
                                                {selectedItem.dispatchUnitName}
                                                <br/>
                                                {selectedItem.rosterName}
                                            </>
                                            :
                                            item.rosterName
                                        }
                                    </small>
                                </div>
                            </div>
                        )
                    });
                }
            }
        }
    )}, [ matrixCols, matrixData, isMostLeft, isMostRight, selectedItem ]);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    
    } = useTable(
        {
            columns,
            data: matrixData,
            defaultColumn,
        },
        useFlexLayout
    );

    const filterSection = (
        <Col xs={12} className="px-3 mb-2">
            <Collapse
                isOpen={filterOpen}
                className="border py-3"
            >
                <Container>
                    <Row>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Employee Code
                                </Label>
                                <Input
                                    name="employeeCode"
                                    id="employeeCode"
                                    type="text"
                                    required
                                    value={employeeCode}
                                    onChange={(e) => setEmployeeCode(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Employee Name
                                </Label>
                                <Input
                                    name="employeeName"
                                    id="employeeName"
                                    type="text"
                                    required
                                    value={employeeName}
                                    onChange={(e) => setEmployeeName(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Start Date
                                </Label>
                                <DatePicker
                                    name="startDate"
                                    id="startDate"
                                    type="text"
                                    required
                                    value={!startDate || startDate.length === 0 ? "" : formatDate(startDate, dateFormat)}
                                    onChange={(value) => setStartDate(formatDate(value, dateFormat))}
                                    closeOnSelect
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    End Date
                                </Label>
                                <DatePicker
                                    name="endDate"
                                    id="endDate"
                                    type="text"
                                    required
                                    value={!endDate || endDate.length === 0 ? "" : formatDate(endDate, dateFormat)}
                                    onChange={(value) => setEndDate(formatDate(value, dateFormat))}
                                    closeOnSelect
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Mob Date
                                </Label>
                                <DatePicker
                                    name="mobDate"
                                    id="mobDate"
                                    type="text"
                                    required
                                    value={!mobDate || mobDate.length === 0 ? "" : formatDate(mobDate, dateFormat)}
                                    onChange={(value) => setMobDate(formatDate(value, dateFormat))}
                                    closeOnSelect
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>
                                    Demob Date
                                </Label>
                                <DatePicker
                                    name="demobDate"
                                    id="demobDate"
                                    type="text"
                                    required
                                    value={!demobDate || demobDate.length === 0 ? "" : formatDate(demobDate, dateFormat)}
                                    onChange={(value) => setDemobDate(formatDate(value, dateFormat))}
                                    closeOnSelect
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Button
                                size="sm"
                                color="default"
                                className="w-100"
                                onClick={() => {
                                    if(project && !showAllProjects)
                                    {
                                        loadData();
                                        return;
                                    }

                                    loadAllData();
                                }}
                            >
                                <i className="fas fa-search"></i>
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Collapse>
        </Col>
    );

    return (
        <Row>
        {
            loading && <Loader />
        }
        {
            showFilters ?
            <Col xs={12} className="ml-2 mb-2">
            {
                reportMode ?
                <>
                    <Button
                        onClick={() => {}}
                        size="sm"
                        color="default"
                    >
                        <i className="far fa-file-pdf"></i>
                    </Button>
                    <Button
                        onClick={() => {}}
                        size="sm"
                        color="default"
                    >
                        <i className="far fa-file-excel"></i>
                    </Button>
                </>
                :
                null
            }
                <Button
                    onClick={() => setFilterOpen(!filterOpen)}
                    size="sm"
                    color="default"
                >
                    {filterOpen ? "Hide Filters" : "Show Filters"}
                </Button>
                <Button
                    onClick={() => {
                        setEmployeeCode("");
                        setEmployeeName("");
                        setStartDate("");
                        setEndDate("");
                        setMobDate("");
                        setDemobDate("");
                    }}
                    size="sm"
                    color="default"
                >
                    Clear Filters
                </Button>
            </Col>
            :
            null
        }
        {
            showFilters ?
            filterSection
            :
            null
        }
            <Col xs={12} className="ml-2 mb-2 d-flex">
                <div style={{width: 100, height: "100%", border: '0.05rem solid rgba(0, 0, 0, 0.3)'}} className={`${conflictedClass} mr-1`} />
                <span style={{fontSize: "0.75rem"}}> : Conflicted Schedule</span>
            </Col>
            <Col xs={12}>
                <Grid
                    totalRecords={rows.length}
                    rows={rows}
                    tableProps={getTableProps()}
                    headerGroups={headerGroups}
                    tableBodyProps={getTableBodyProps()}
                    prepareRow={prepareRow}
                    needHover={true}
                    onPagerChangePage={(pager) => setPageNum(pager.currentPage)}
                    onPagerChangePageSize={(size) => setPageSize(size)}
                    needPaging={true}
                />
            </Col>
        </Row>
    )

}