import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";

export default function ErrorNotification(props) {

    const {
        onConfirm,
        message,
        errorDetails = null,
        title = "Error",
        messageClassSubfix = "",
        messageOpening = "",
    } = props;

    return (
        <SweetAlert
            title={<h4 className="mb-0 text-center">{title ? title : "Error"}</h4>}
            onConfirm={onConfirm}
            type="danger"
        >
            <div className={`row mb-2 ${messageClassSubfix}`}>
                {messageOpening && <div className="col-12 error-message-opening">{messageOpening}</div>}
                <div className="col-12 error-message">{message}</div>
                {errorDetails && <div className={"col-12 mt-4 text-left error-details"}>{errorDetails}</div>}
            </div>
        </SweetAlert>
    );
}

export function ErrorResponseValidation(error) {

    // Init vars
    const { response } = error;
    var errorMessageResult = '';
    console.log('response', response);

    // If validation message array existing
    if (typeof (response) === 'object' && response?.data?.errors) {
        var errorList = Object.values(response.data.errors);
        errorMessageResult = errorList.join('; ');

    }
    // If no validation message
    else if (typeof (response) === 'object' && response?.data) {
        errorMessageResult = response.data;
    }
    // If no validation message
    else if (typeof (response) === 'object') {
        errorMessageResult = error.message;
    }
    // If custom validation messaage
    else {
        errorMessageResult = error.response;
    }

    return errorMessageResult;
}
