/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useParams, useHistory } from "react-router-dom";
import api from "../../../../services/api";
import qs from "qs";
import { SortsConverter, FiltersConverter } from "../../../../helpers"
import {
    Container, Row, Col, Button, Card, CardBody, Label, Collapse, FormGroup, Input
} from "reactstrap";
import { ComboBox, DropdownBox } from '../../../dropdowns';
import { DatePicker } from '../../../date-pickers';
import debounce from 'lodash.debounce';
import EditorSMS from "../EditorSMS";
import EditorEmail from '../EditorEmail';
import EditorSkill from '../EditorSkill';
import EditorComment from '../EditorComment';
import CandidateEditor from "../../Candidates/Editor";
import moment from "moment";
import { TooltipButton } from '../../../inputs';
import fileDownload from "js-file-download";
import Loader from "../../../loaders";
import { formatDate, Mode } from '../../../../utils';
import { ErrorNotification } from '../../../alerts';
import DataMergeDialog from '../DataMerge';
import Multiselect from 'multiselect-react-dropdown';
import _ from 'lodash';

const timeoutLength = 1500;
const errorMessageOpening = `Error occured when processing the request: `;
const errorTitleOnGenerate = `Talent Grid`;
const canAutoOpen = true;
const pageSize = 100;

export default function CandidateGrid(props) {
    const history = useHistory();
    const [data, setData] = React.useState([]);
    const [requestResultData, setRequestResultData] = useState([]);

    const params = useParams();
    const [copiedIndex, setCopiedIndex] = React.useState(null);
    React.useEffect(() => {
        if (copiedIndex !== null) {
            const timeoutId = setTimeout(() => {
                setCopiedIndex(null);
            }, timeoutLength);

            return () => clearTimeout(timeoutId);
        }
    }, [copiedIndex]);

    const [loading, setLoading] = React.useState(false);
    const [itemInEdit, setItemInEdit] = React.useState(null);
    const [lookups, setLookups] = React.useState({});
    const [filters, setFilters] = React.useState([]);

    const defaultSort = React.useMemo(() => [{ id: "lrfCreationDate", desc: true }, { id: "projectName", desc: false }],
        []
    );

    const [editMode, setEditMode] = useState(Mode.NONE);
    const [itemSMS, setItemSMS] = React.useState([]);
    const [itemEmail, setItemEmail] = React.useState([]);
    const [itemComment, setItemComment] = React.useState([]);
    const [showSkillEditor, setShowSkillEditor] = React.useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [advancedFilters, setAdvancedFilters] = useState([]);
    const advancedFiltersRef = useRef(advancedFilters);
    const [sortBy, setSortBy] = React.useState(defaultSort);
    const [showMergeDialog, setShowMergeDialog] = useState(false);
    const skipPageResetRef = React.useRef();
    const apiurl = "candidate";
    const { id } = useParams();

    // Error message variables
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorTitle, setErrorTitle] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);
    const [isSelectAll, setIsSelectAll] = React.useState(false);
    const isSelectAllRef = useRef(isSelectAll);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [selectedCandidates, setSelectedCandidates] = useState([]);
    const [versionCounter, setVersionCounter] = useState(0);
    const [checkRemovedRows, setCheckRemovedRows] = useState(false);
    const agGridSort = ['asc', 'desc'];
    const gridRef = useRef();
    const selectedCandidatesRef = useRef();

    useEffect(() => {
        isSelectAllRef.current = isSelectAll;
    }, [isSelectAll]);

    const getIsSelectAll = useCallback(() => {
        return isSelectAll;
    }, [isSelectAll]);

    // useEffect(() => console.log({selectedCandidates}), [selectedCandidates]);

    const resizeColumns = useCallback(() => {
        if (!gridRef?.current?.columnApi || data?.length === 0)
            return;

        const allColumnIds = [];
        gridRef.current.columnApi.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }, [data, gridRef]);


    const selectAll = useCallback(() => {
        var statusTo = isSelectAll ? false : true;
        gridRef.current.api.forEachNode(function (node) {
            //console.log('node', node);
            node.setSelected(statusTo);
        });
    }, [gridRef, isSelectAll]);

    const onRowClicked = useCallback((event) => {
        const { data: selectedData } = event;
        setItemInEdit(selectedData);
        setEditMode(Mode.EDIT);
    }, []);

    const checkForSelectAll = useCallback(() => {
        var no = 0;
        var selectedNodes = gridRef.current.api.getSelectedNodes();
        var isSelectAll = selectedNodes.length == data.length;
        setIsSelectAll(isSelectAll);
        return isSelectAll;
    }, [gridRef, data]);

    const onSelectionChanged = useCallback(debounce((event) => {
        const selectedNodes = event.api.getSelectedNodes();
        setIsSelectAll(selectedNodes.length === data.length ? true : selectedNodes.length > 0 ? null : false);
        setSelectedRows(_.uniq(selectedNodes?.filter(n => n.rowIndex !== null) ?? []));
        // setSelectedCandidates(selectedNodes.map(x => ({ ...x.data })));
        selectedCandidatesRef.current = _.uniq(selectedNodes?.filter(n => n.rowIndex !== null)?.map(x => ({ ...x.data })) ?? [], "candidateId");
        // console.log("on selection changed", {event, selectedCandidates: selectedCandidatesRef?.current, selectedNodes});
        const removedSelectedCandidates = selectedCandidates.filter(c => !selectedCandidatesRef.current.some(r => r?.candidateId === c.candidateId)) ?? [];
        // console.log({selectedCandidates, removedSelectedCandidates});

        if (removedSelectedCandidates.length && !requestResultData?.some(r => removedSelectedCandidates.some(c => c?.candidateId === r.candidateId)))
        {
            // console.log("selection changed refresh data: ", {event, selectedCandidates: selectedCandidatesRef?.current, removedSelectedCandidates});
            const datasource = getServerSideDatasource();
            gridRef.current.api.setServerSideDatasource(datasource);
        }

        setSelectedCandidates([...selectedCandidatesRef.current]);
    }, 200), [gridRef, data, requestResultData, selectedCandidatesRef, selectedCandidates, resizeColumns]);

    const [additionalParamsForCheckboxHeader, setAdditionalParamsForCheckboxHeader] = useState({
        isSelectAll,
        setIsSelectAll,
        selectAll,
        getIsSelectAll
    });

    useEffect(() => {
        setAdditionalParamsForCheckboxHeader({ ...additionalParamsForCheckboxHeader, isSelectAll: isSelectAll });
    }, [isSelectAll, setIsSelectAll, selectAll, getIsSelectAll]);

    const columnDefs = useMemo(() => ([
        {
            field: "surname",
            headerName: "Surname",

            headerCheckboxSelection: true,
            checkboxSelection: true,
            showDisabledCheckboxes: true,

        },
        {
            field: "firstName",
            headerName: "First Name"
        },
        {
            field: "employeeNumber",
            headerName: "Employee Number"
        },
        {
            field: "mobilePhoneNumber",
            headerName: "Mobile",
            valueFormatter: (params) => {
                var { value } = params;

                const match = value?.match(/^([\dX]{4})([\dX]{3})([\dX]{3})$/)
                if (match) {
                    return `${match[1]} ${match[2]} ${match[3]}`;
                } else {
                    return value;
                }
            },
        },
        {
            field: "email",
            headerName: "Email"
        },
        {
            field: "position.positionName",
            headerName: "Position"
        },
        {
            field: "adsReference",
            headerName: "Advertising Reference"
        },
        {
            field: "candidateStatus.lookupValue",
            headerName: "Candidate Status",
        },
        {
            field: "jobOrderStatus",
            headerName: "Job Order Status",
        },
        {
            field: "candidateAddress.country.lookupValue",
            headerName: "Country",
        },
        {
            field: "candidateAddress.state.lookupValue",
            headerName: "State",
        },
        {
            field: "candidateAddress.city",
            headerName: "City",
        },
        {
            field: "candidateAddress.postcode",
            headerName: "Postcode",
        },
        {
            field: "nationality",
            headerName: "Nationality",
        },
        {
            field: "agency.lookupValue",
            headerName: "Agency",
        },
        {
            field: "contractingCompany.contractingCompanyName",
            headerName: "Contracting Company",
        },
        {
            field: "residencyStatus.lookupValue",
            headerName: "Residency Status",
        },
        {
            field: "candidateType",
            headerName: "Candidate Type",
        },
    ]), [data]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        //filter: true,
        editable: false,
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['reset'],
            debounceMs: 200,
            excelMode: 'mac',
        },
        floatingFilter: true,
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        suppressMenu: true,
    }), []);

    const loadingOverlayComponent = useMemo(() => Loader, []);

    const refreshCache = useCallback((route) => {
        // console.log('gridRef', gridRef);
        // console.log("refresh cache", {selectedCandidates: selectedCandidatesRef?.current});
        if (!gridRef || !gridRef.current || !gridRef.current.api)
            return;

        // Refresh Source
        const datasource = getServerSideDatasource();
        gridRef.current.api.setServerSideDatasource(datasource);

        // Refresh Data
        setVersionCounter(versionCounter + 1);
        gridRef.current.api.purgeServerSideCache();

    }, [versionCounter, advancedFilters, selectedCandidatesRef?.current]);

    const onGridReady = useCallback((params) => {
        // console.log("grid ready", {selectedCandidates: selectedCandidatesRef?.current});
        const datasource = getServerSideDatasource();
        params.api.setServerSideDatasource(datasource);
    }, [advancedFilters, selectedCandidatesRef?.current]);

    useEffect(() => {
        advancedFiltersRef.current = advancedFilters;
    }, [advancedFilters]);

    const getRows = useCallback((getRowParams) => {
        // console.log("get rows", {selectedCandidates: selectedCandidatesRef?.current});
        const { request } = getRowParams;
        const { filterModel, sortModel } = request;

        const thisFilters = FiltersConverter(filterModel);
        const thisSorts = SortsConverter(sortModel);
        const selectedSorts = (thisSorts.length ? thisSorts : defaultSort);

        setFilters(thisFilters);
        setSortBy(selectedSorts);

        const queryString = qs.stringify({ filters: thisFilters, sortBy: selectedSorts, advancedFilters: advancedFiltersRef.current }, { allowDots: true });
        const url = `/${apiurl}/${getRowParams.request.startRow ?? 0}/${pageSize}${params.requestId ? ("/" + params.requestId + "/" + params.action) : ""}${queryString ? `?${queryString}` : ""}`;

        //api.get(`/path/to/endpoint/${params.request.startRow}/${params.request.endRow}`)
        api.get(url)
            .then((response) => {
                try {
                    //console.log('response', response);
                    const requestResult = response.data.data ?? [];
                    const newSelectedCandidates = JSON.parse(JSON.stringify(selectedCandidatesRef?.current ?? []));
                    const filteredSelectedCandidates = newSelectedCandidates.filter(c => !response.data.data?.some(r => r.candidateId === c.candidateId));
                    const successData = {
                        rowData: [...filteredSelectedCandidates, ...response.data.data],
                        //rowCount: 100,
                        rowCount: response.data.total + (filteredSelectedCandidates?.length ?? 0),
                    };
                    setData(successData.rowData);
                    setRequestResultData(requestResult);
                    //setServerSideInitialRowCount(response.data.total);

                    // console.log('success data', {successData, successDataContainsFilteredLength: successData.rowData.filter(d => newSelectedCandidates.some(c => c.candidateId === d.candidateId))?.length, newSelectedCandidates, filteredSelectedCandidates});
                    getRowParams.success(successData);
                    const gridApi = getRowParams?.api;

                    if (!gridApi)
                        return;

                    gridApi.forEachNode((node) => {
                        // if (newSelectedCandidates.some(c => c.candidateId === node?.data?.candidateId))
                        //     console.log("check node selected: ", {node, newSelectedCandidates});

                        // if (!node?.data)
                        //     console.log("invalid node: ", {node});

                        if (node?.data)
                            node.setSelected(newSelectedCandidates.some(f => f.candidateId === node.data.candidateId));
                    });

                    resizeColumns();
                }
                catch (error) {
                    setData([]);
                    console.error('error on compile data to ag grid', {error});
                    setErrorMessage(`${errorMessageOpening}${error.message}`);
                    setErrorTitle(errorTitleOnGenerate);
                }
            })
            .catch(error => {
                getRowParams.fail();
                console.error('error on request get for ag grid', {error});
                setErrorMessage(`${errorMessageOpening}${error.message}`);
                setErrorTitle(errorTitleOnGenerate);
            });
    }, [gridRef, pageSize, advancedFiltersRef, selectedCandidatesRef?.current, resizeColumns]);

    const getServerSideDatasource = () => {
        return {
            getRows,
        };
    };

    const updateSingleRow = useCallback((candidateId) => {
        const gridApi = gridRef.current.api;
        gridApi.forEachNode(rowNode => {
            if (candidateId == rowNode.data.candidateId) {
                //console.log('rowNode', rowNode);
                const url = `/${apiurl}/ag-grid/${candidateId}`;
                setLoading(true);
                api.get(url)
                    .then((result) => {
                        const newObject = result.data;
                        //console.log('data to set', 'data', data);
                        rowNode.setData(newObject);
                        if (data) {
                            const delIndex = data.indexOf(w => w.candidateId == candidateId);
                            if (delIndex != -1) data.splice(delIndex, 1);
                            data.push(newObject);
                        }
                    })
                    .catch((error) => {
                        console.error("error on updateSingleRow", error.message, error.response);
                        setErrorMessage(`${errorMessageOpening}${error.message}`);
                        setErrorTitle(errorTitleOnGenerate);
                    })
                    .finally(() => setLoading(false))
                ;
            }
        });
    }, [gridRef]);

    // END AG GRID FUNCTIONS ---------------------------------------------------------------------------------------------------------------------------

    const loadData = useCallback(() => {
        skipPageResetRef.current = true;
        setItemInEdit(null);
        setEditMode(Mode.NONE);
        setData([]);
        refreshCache();
    }, []);

    React.useEffect(() => {
        skipPageResetRef.current = false;

        setLoading(true);
        let apiCalls = [
            api.get(`/lookup/skillposition`),
            api.get(`/lookup/candidatetype`),
            api.get(`/lookup/salutation`),
            api.get(`/lookup/preference`),
            api.get(`/lookup/industry`),
            api.get(`/lookup/gender`),
            api.get(`/lookup/maritalStatus`),
            api.get(`/lookup/candidateStatus`),
            api.get(`/lookup/candidateFlag`),
            api.get(`/lookup/residencyStatus`),
            api.get(`/lookup/visatype`),
            api.get(`/lookup/communicationtype`),
            api.get(`/lookup/country`),
            api.get(`/lookup/state`),
            api.get(`/lookup/relationshiptype`),
            api.get(`/lookup/candidateplatformorigin`),
            api.get(`/lookup/messagesmasters`),
            api.get(`/emailtemplate`), // TODO: returns paged result - move to lookup
            api.get(`/lookup/recruitmentpeople`),
            api.get(`/lookup/candidatediscipline`),
            api.get(`/lookup/company`),
            api.get(`/lookup/identifier`),
            api.get(`/lookup/candidateevent`),
            api.get(`/lookup/candidateeventstatus`),
            api.get(`/lookup/candidatecommenttype`),
            api.get(`/lookup/agency`),
            api.get(`/lookup/phonetype`),
            api.get(`/contractingcompany/all`),
        ];

        Promise.all(apiCalls)
            .then((data) => {
                setLookups({
                    skillPositions: data[0].data,
                    candidateTypes: data[1].data,
                    salutations: data[2].data,
                    preferences: data[3].data,
                    industries: data[4].data,
                    genders: data[5].data,
                    maritalStatuses: data[6].data,
                    candidateStatuses: data[7].data,
                    candidateFlags: data[8].data,
                    residencyStatuses: data[9].data,
                    visaTypes: data[10].data,
                    communicationTypes: data[11].data,
                    countries: data[12].data,
                    states: data[13].data,
                    relationshipTypes: data[14].data,
                    candidatePlatformOrigin: data[15].data,
                    messagesMaster: data[16].data,
                    emailTemplate: data[17].data.data,
                    recruitmentPeople: data[18].data,
                    candidateDisciplines: data[19].data,
                    companies: data[20].data,
                    identifiers: data[21].data,
                    events: data[22].data,
                    eventStatus: data[23].data,
                    candidateCommentTypes: data[24].data,
                    agencies: data[25].data,
                    phoneTypes: data[26].data,
                    contractingCompanies: data[27].data,
                });
                if (id) {
                    setItemInEdit({ candidateId: id });
                }
            }).catch((error) => {
                console.error("error on getting options", error.message, error.response);
                setErrorMessage(`${errorMessageOpening}${error.message}`);
                setErrorTitle(errorTitleOnGenerate);
            }).finally(() => setLoading(false));

    }, []);

    React.useEffect(() => {
        if (canAutoOpen && data && data.length === 1 && params.requestId && params.action && params.action === "joborder") {
            setItemInEdit(data[0]);
        }
    }, [canAutoOpen, data, params]);

    const handleSetAdvancedFilters = useCallback((name, data, idField = 'lookupId', valueField = 'lookupValue') => {
        // console.log("currentFilter: ", { advancedFilters, data });
        const newFilters = [...advancedFilters];
        const index = newFilters.indexOf(newFilters.filter(f => f.id === name)[0]);
        // console.log("currentIndex: ", { index });

        if (data && index > -1)
            newFilters.splice(index, 1, { id: name, value: data[valueField] ?? data, lookupId: data[idField] });

        if (!data && index > -1)
            newFilters.splice(index, 1);

        if (data && index === -1)
            //newFilters.push({ id: name, value: data.lookupValue ?? data, lookupId: data.lookupId });
            newFilters.push({ id: name, value: data[valueField] ?? data, lookupId: data[idField] });

        // console.log({ newFilters });
        setAdvancedFilters([...newFilters]);
    }, [advancedFilters]);

    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            onConfirm: () => {
                setErrorMessage(null);
                setErrorTitle(null);
            },
            message: errorMessage,
            title: errorTitle ?? errorTitleOnGenerate
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage, errorTitle]);

    const mergeCandidates = React.useCallback(() => {
        setShowMergeDialog(true);
    }, [selectedCandidatesRef?.current, history, selectedRows]);

    useEffect(() => {
        if (!checkRemovedRows || !gridRef?.current?.api)
            return;

        setCheckRemovedRows(false);
        gridRef.current.api.getSelectedNodes().forEach((node) => {
            node.setSelected(selectedCandidates.filter(c => c.candidateId === node.data.candidateId).length > 0);
        });
    }, [selectedCandidates, checkRemovedRows]);

    return (
        <section className="main">
            {loading && <Loader />}
            {errorNotification.length > 0 && errorNotification}
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card className="no-transition">
                            <CardBody>
                                {/*
                                <CardTitle>
                                    <h4 className="text-center">Job Orders {params.requestId && `LRF #${params.requestId}`}</h4>
                                </CardTitle>
                                */}

                                <Row>
                                    <Col lg={12}>
                                        <TooltipButton
                                            id="addnew"
                                            title="Add New"
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={() => {
                                                setItemInEdit({
                                                    candidateId: 0,
                                                    receivingCommunication: false,
                                                    receivingEmail: false,
                                                    receivingSms: false,
                                                    mailingAddressSameAsMain: true
                                                });
                                                setEditMode(Mode.ADD);
                                            }}
                                        >
                                            <i className="fas fa-plus pt-1"></i>
                                        </TooltipButton>
                                        <TooltipButton
                                            id="selectAll"
                                            title={!isSelectAll ? 'Select All' : 'Unselect All'}
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={() => selectAll()}
                                        >
                                            <i className={`fas ${isSelectAll == null ? 'fa-minus-square' : !isSelectAll ? 'fa-square' : 'fa-check-square'}`}></i> {!isSelectAll ? 'Select All' : 'Unselect All'}
                                        </TooltipButton>
                                        <TooltipButton
                                            id="sendsms"
                                            title="Send Bulk SMS"
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={() => {
                                                setItemSMS(selectedRows.map(d => d.data));
                                            }}
                                        >
                                            <i className="fas fa-comment mr-1"></i> Send SMS
                                        </TooltipButton>
                                        <TooltipButton
                                            id="sendemail"
                                            title="Send Bulk Email"
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={() => {
                                                setItemEmail(selectedRows.map(d => d.data));
                                            }}
                                        >
                                            <i className="fas fa-envelope-open"></i> Send Email
                                        </TooltipButton>
                                        <TooltipButton
                                            id="addcomment"
                                            title="Add Bulk Comment"
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={() => {
                                                setItemComment(selectedRows.map(d => d.data));
                                            }}
                                        >
                                            <i className="fas fa-comment mr-1"></i> Add Comment
                                        </TooltipButton>
                                        <TooltipButton
                                            id="export"
                                            title="Export to Excel"
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={() => {

                                                // console.log("Filters: ", filters);
                                                const queryString = qs.stringify({ filters: [...filters] }, { allowDots: true });

                                                setLoading(true);
                                                let url = `candidate/export${queryString ? `?${queryString}` : ""}`;
                                                api.get(url, { responseType: 'blob' })
                                                    .then(blob => {
                                                        // console.log("Download: ", blob);
                                                        fileDownload(blob.data, "candidates.xlsx");
                                                    }).catch(error => {
                                                        console.error(error)
                                                    }).finally(() => setLoading(false))

                                            }}
                                        >
                                            <i className="fas fa-file-excel"></i> Export
                                        </TooltipButton>
                                        <TooltipButton
                                            id="merge"
                                            title="Merge Candidates"
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            onClick={mergeCandidates}
                                        >
                                            <i className="fas fa-object-group"></i> Merge
                                        </TooltipButton>
                                        <TooltipButton
                                            id="skill"
                                            title="Bulk Add Skills"
                                            className="btn-icon ml-2 mb-2"
                                            color="default"
                                            size="sm"
                                            type="button"
                                            disabled={!selectedCandidates || !selectedCandidates.length}
                                            onClick={() => setShowSkillEditor(true)}
                                        >
                                            <i className="fas fa-object-group"></i> Bulk Add Skills
                                        </TooltipButton>
                                    </Col>
                                </Row>
                                <Row className="my-2">
                                    <Col lg={12}>
                                        <Button
                                            aria-expanded={openFilter}
                                            onClick={() =>
                                                setOpenFilter(!openFilter)
                                            }
                                            className="w-100 text-primary text-left no-shadow"
                                            color="link"
                                            type="button"
                                        >
                                            Advanced Filters
                                            <i className="ni ni-bold-down float-right pt-1"></i>
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <Collapse isOpen={openFilter} className="mb-2">
                                            <Row>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Country
                                                        </Label>
                                                        <DropdownBox
                                                            data={lookups?.countries ?? []}
                                                            isLookup={true}
                                                            selectedItem={advancedFilters?.filter(f => f.id === "Country").map(f => ({ lookupId: f.lookupId, lookupValue: f.value }))[0]}
                                                            onChange={(item) => handleSetAdvancedFilters("Country", item)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            State
                                                        </Label>
                                                        <DropdownBox
                                                            data={lookups?.states ?? []}
                                                            isLookup={true}
                                                            selectedItem={advancedFilters?.filter(f => f.id === "State").map(f => ({ lookupId: f.lookupId, lookupValue: f.value }))[0]}
                                                            onChange={(item) => handleSetAdvancedFilters("State", item)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            City
                                                        </Label>
                                                        <Input
                                                            value={advancedFilters.filter(a => a.id === "City")[0]?.value ?? ""}
                                                            onChange={(e) => handleSetAdvancedFilters("City", e.target.value)}
                                                            autoComplete="off"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Postcode
                                                        </Label>
                                                        <Input
                                                            value={advancedFilters.filter(a => a.id === "Postcode")[0]?.value ?? ""}
                                                            onChange={(e) => handleSetAdvancedFilters("Postcode", e.target.value)}
                                                            autoComplete="off"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Residency Status
                                                        </Label>
                                                        <DropdownBox
                                                            data={lookups?.residencyStatuses ?? []}
                                                            isLookup={true}
                                                            selectedItem={advancedFilters?.filter(f => f.id === "ResidencyStatus").map(f => ({ lookupId: f.lookupId, lookupValue: f.value }))[0]}
                                                            onChange={(item) => handleSetAdvancedFilters("ResidencyStatus", item)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Nationality
                                                        </Label>
                                                        <Input
                                                            value={advancedFilters.filter(a => a.id === "Nationality")[0]?.value ?? ""}
                                                            onChange={(e) => handleSetAdvancedFilters("Nationality", e.target.value)}
                                                            autoComplete="off"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Skill
                                                        </Label>
                                                        <ComboBox
                                                            endpoint={`/trainingmasterskill`}
                                                            selectedItem={advancedFilters?.filter(f => f.id === "Skill").map(f => ({ lookupId: f.lookupId, lookupValue: f.value }))[0]}
                                                            onChange={(item) => handleSetAdvancedFilters("Skill", item)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Min. Expiry Date
                                                        </Label>
                                                        <DatePicker
                                                            name="ExpiryDate"
                                                            id="ExpiryDate"
                                                            type="text"
                                                            value={formatDate(advancedFilters.filter(a => a.id === "ExpiryDate")[0]?.value ?? "")}
                                                            onChange={value => handleSetAdvancedFilters("ExpiryDate", !value ? null : moment(value).format("YYYY-MM-DDT00:00:00"))}
                                                            closeOnSelect
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Project
                                                        </Label>
                                                        <ComboBox
                                                            endpoint={`/project`}
                                                            selectedItem={advancedFilters?.filter(f => f.id === "Project").map(f => ({ lookupId: f.lookupId, lookupValue: f.value }))[0]}
                                                            onChange={(item) => handleSetAdvancedFilters("Project", item)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Location
                                                        </Label>
                                                        <ComboBox
                                                            endpoint={`/location`}
                                                            selectedItem={advancedFilters?.filter(f => f.id === "Location").map(f => ({ lookupId: f.lookupId, lookupValue: f.value }))[0]}
                                                            onChange={(item) => handleSetAdvancedFilters("Location", item)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Available Date Range
                                                        </Label>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <DatePicker
                                                                    name="AvailableDateRangeStart"
                                                                    id="AvailableDateRangeStart"
                                                                    type="text"
                                                                    value={formatDate(advancedFilters.filter(a => a.id === "AvailableDateRangeStart")[0]?.value ?? "")}
                                                                    onChange={value => handleSetAdvancedFilters("AvailableDateRangeStart", !value ? null : moment(value).format("YYYY-MM-DDT00:00:00"))}
                                                                    closeOnSelect
                                                                />
                                                            </Col>
                                                            <Col lg={6}>
                                                                <DatePicker
                                                                    name="AvailableDateRangeEnd"
                                                                    id="AvailableDateRangeEnd"
                                                                    type="text"
                                                                    value={formatDate(advancedFilters.filter(a => a.id === "AvailableDateRangeEnd")[0]?.value ?? "")}
                                                                    onChange={value => handleSetAdvancedFilters("AvailableDateRangeEnd", !value ? null : moment(value).format("YYYY-MM-DDT00:00:00"))}
                                                                    closeOnSelect
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Has Selected Document Types
                                                        </Label>
                                                        <DropdownBox
                                                            data={[{ lookupId: 1, lookupValue: "Yes" }, { lookupId: 2, lookupValue: "No" }]}
                                                            isLookup={true}
                                                            selectedItem={advancedFilters?.filter(f => f.id === "HasSelectedDocTypes").map(f => ({ lookupId: f.lookupId, lookupValue: f.value }))[0]}
                                                            onChange={(item) => handleSetAdvancedFilters("HasSelectedDocTypes", item)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className="mt-n3">
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Candidate Status
                                                        </Label>
                                                        <DropdownBox
                                                            data={lookups?.candidateStatuses ?? []}
                                                            isLookup={true}
                                                            selectedItem={advancedFilters?.filter(f => f.id === "CandidateStatuses").map(f => ({ lookupId: f.lookupId, lookupValue: f.value }))[0]}
                                                            onChange={(item) => handleSetAdvancedFilters("CandidateStatuses", item)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Agency
                                                        </Label>
                                                        <DropdownBox
                                                            data={lookups?.agencies ?? []}
                                                            isLookup={true}
                                                            selectedItem={advancedFilters?.filter(f => f.id === "Agency").map(f => ({ lookupId: f.lookupId, lookupValue: f.value }))[0]}
                                                            onChange={(item) => handleSetAdvancedFilters("Agency", item)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Contracting Company
                                                        </Label>
                                                        <DropdownBox
                                                            data={lookups?.contractingCompanies ?? []}
                                                            isLookup={true}
                                                            idField="contractingCompanyId"
                                                            valueField="contractingCompanyName"
                                                            //selectedItem={advancedFilters?.filter(f => f.id === "ContractingCompany").map(f => ({ lookupId: f.contractingCompanyId, lookupValue: f.value.contractingCompanyName }))[0]}
                                                            /*selectedItem={() => {
                                                                console.log('selectedItem', 'ContractingCompany');
                                                                console.log('advancedFilters', advancedFilters);
                                                                var selId = advancedFilters?.find(f => f.id === "ContractingCompany")?.lookupId;

                                                                console.log('selId', selId);
                                                                console.log('lookups?.contractingCompanies', lookups?.contractingCompanies);
                                                                if (!selId || !lookups?.contractingCompanies?.length) return null;
                                                                var selItem = lookups.contractingCompanies.find(f => f.contractingCompanyId === selId);
                                                                console.log('selItem', selItem);
                                                                return selItem;
                                                            }}
                                                            */
                                                            selectedItem={advancedFilters?.filter(f => f.id === "ContractingCompany").map(f => ({ contractingCompanyId: f.lookupId, contractingCompanyName: f.value }))[0]}
                                                            onChange={(item) => handleSetAdvancedFilters("ContractingCompany", item, 'contractingCompanyId', 'contractingCompanyName')}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={2}>
                                                    <FormGroup>
                                                        <Label>
                                                            Candidate Type
                                                        </Label>
                                                        <DropdownBox
                                                            data={lookups?.candidateTypes ?? []}
                                                            isLookup={true}
                                                            idField="lookupId"
                                                            valueField="lookupValue"
                                                            //selectedItem={advancedFilters?.filter(f => f.id === "ContractingCompany").map(f => ({ lookupId: f.contractingCompanyId, lookupValue: f.value.contractingCompanyName }))[0]}
                                                            /*selectedItem={() => {
                                                                console.log('selectedItem', 'ContractingCompany');
                                                                console.log('advancedFilters', advancedFilters);
                                                                var selId = advancedFilters?.find(f => f.id === "ContractingCompany")?.lookupId;

                                                                console.log('selId', selId);
                                                                console.log('lookups?.contractingCompanies', lookups?.contractingCompanies);
                                                                if (!selId || !lookups?.contractingCompanies?.length) return null;
                                                                var selItem = lookups.contractingCompanies.find(f => f.contractingCompanyId === selId);
                                                                console.log('selItem', selItem);
                                                                return selItem;
                                                            }}
                                                            */
                                                            selectedItem={advancedFilters?.filter(f => f.id === "CandidateType").map(f => ({ lookupId: f.lookupId, lookupValue: f.value }))[0]}
                                                            onChange={(item) => handleSetAdvancedFilters("CandidateType", item, 'lookupId', 'lookupValue')}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row className="mb-4">
                                                <Col lg={12} className="d-flex flex-wrap align-items-center justify-content-end">
                                                    <Button
                                                        color="secondary"
                                                        onClick={() => loadData()}
                                                        type="button"
                                                    >
                                                        Filter
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Collapse>
                                    </Col>
                                </Row>
                                {!!selectedCandidates?.length &&
                                    <Row>
                                        <Col lg={12}>
                                            <FormGroup>
                                                <Multiselect
                                                    className="selected-items"
                                                    options={selectedCandidates}
                                                    displayValue="candidateName"
                                                    placeholder=" "
                                                    selectedValues={selectedCandidates}
                                                    onRemove={(value) => {
                                                        selectedCandidatesRef.current = value;
                                                        setSelectedCandidates([...value]);
                                                        setCheckRemovedRows(true);
                                                    }}
                                                    showCheckbox={true}
                                                    showArrow={false}
                                                    closeOnSelect={true}
                                                    hidePlaceholder={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                }

                                <Row>
                                    <Col sm={12}>
                                        <div className="ag-theme-alpine ag-grid clickable-row" style={{ width: '100%', height: 'calc(100vh - 200px)' }}>
                                            <AgGridReact
                                                ref={gridRef}
                                                columnDefs={columnDefs}
                                                defaultColDef={defaultColDef}
                                                loadingOverlayComponent={loadingOverlayComponent}
                                                suppressColumnVirtualisation={false}
                                                suppressRowVirtualisation={false}
                                                debounceVerticalScrollbar={false}
                                                rowBuffer={20}
                                                headerHeight={30}
                                                groupHeaderHeight={30}
                                                rowHeight={30}
                                                floatingFiltersHeight={30}
                                                onRowDataUpdated={() => resizeColumns()}
                                                suppressDragLeaveHidesColumns={true}
                                                suppressColumnMoveAnimation={true}
                                                animateRows={false}
                                                excelStyles={excelStyles()}
                                                pagination={true}
                                                paginationPageSize={pageSize}
                                                cacheBlockSize={pageSize}
                                                rowModelType={'serverSide'}
                                                serverSideInfiniteScroll={true}
                                                onGridReady={onGridReady}
                                                sortingOrder={agGridSort}
                                                rowSelection={'multiple'}
                                                suppressRowClickSelection={true}
                                                onSelectionChanged={onSelectionChanged}
                                                onRowClicked={onRowClicked}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {
                !!itemInEdit &&
                <CandidateEditor
                    item={itemInEdit}
                    onClose={() => {
                        if (editMode == Mode.EDIT) {
                            updateSingleRow(itemInEdit.candidateId);
                            setItemInEdit(null);
                            setEditMode(Mode.NONE);
                        } else {
                            loadData(filters, sortBy);
                        }
                    }}
                    onSaved={() => {
                        if (editMode == Mode.EDIT) {
                            updateSingleRow(itemInEdit.candidateId);
                            setItemInEdit(null);
                            setEditMode(Mode.NONE);
                        } else {
                            loadData(filters, sortBy);
                        }
                    }}
                    lookups={{
                        ...lookups
                    }}
                />
            }
            {
                !!itemSMS && itemSMS.length > 0 &&
                <EditorSMS
                    items={itemSMS}
                    onClose={() => setItemSMS([])}
                    onSaved={() => {
                        setItemSMS([]);
                        loadData(filters, sortBy);
                    }}
                    lookups={{
                        ...lookups
                    }}
                />
            }
            {
                !!itemEmail && itemEmail.length > 0 &&
                <EditorEmail
                    items={itemEmail}
                    onClose={() => setItemEmail([])}
                    onSaved={() => {
                        setItemEmail([]);
                        loadData(filters, sortBy);
                    }}
                    lookups={{
                        ...lookups
                    }}
                />
            }
            {
                !!itemComment && itemComment.length > 0 &&
                <EditorComment
                    items={itemComment}
                    onClose={() => setItemComment([])}
                    onSaved={() => loadData(filters, sortBy)}
                    lookups={{
                        ...lookups
                    }}
                />
            }
            {
                showSkillEditor && selectedCandidates && selectedCandidates?.length > 0 ?
                <EditorSkill
                    items={selectedCandidates}
                    onClose={() => setShowSkillEditor(false)}
                    onSaved={() => loadData(filters, sortBy)}
                    lookups={{
                        ...lookups
                    }}
                />
                :
                null
            }
            {
                showMergeDialog &&
                <DataMergeDialog
                    fromGrid={true}
                    initialCandidates={selectedRows.map(r => r.data)}
                    gridOnClose={() => {
                        setShowMergeDialog(false);
                        loadData();
                    }}
                />
            }
        </section >
    )

}


export const excelStyles = () => {
    const headerDefaultStyle = {
        alignment: {
            vertical: 'Center',
            horizontal: "Center",
        },
        interior: {
            color: '#f8f8f8',
            pattern: 'Solid',
            patternColor: undefined,
        },
        borders: {
            borderTop: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderRight: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderBottom: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderLeft: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
        },
        font: {
            fontName: 'Roboto',
            color: '#181d1f',
            bold: true,
            size: 12,
        },
    }

    return [
        {
            id: 'header',
            ...headerDefaultStyle
        },
        {
            id: 'date-header',
            ...headerDefaultStyle,
            alignment: {
                vertical: 'Center',
                horizontal: "Center",
                rotate: 90,
            },
        },
        {
            id: 'cell',
            alignment: {
                vertical: 'Top',
                wrapText: true,
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid',
                patternColor: undefined,
            },
            borders: {
                borderTop: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderRight: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderBottom: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderLeft: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
            },
            font: {
                fontName: 'Roboto',
                color: '#181d1f',
                weight: 700,
                size: 12,
            },
        },
    ];
};
