import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { useTable, useFlexLayout, useFilters, useSortBy } from 'react-table';
import Loader from '../../loaders';
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Input,
    Row
} from "reactstrap";
import qs from "qs";
import api from "../../../services/api";
import Grid from "../../Grid";
import { IndeterminateCheckbox } from '../../react-table/controls';
import { DefaultColumnFilter, DateColumnFilter } from "../../react-table/filters";
import { TooltipButton } from '../../inputs';
import moment from "moment";
import { formatDate, formatDateTime } from '../../../utils';
import EditorEmail from '../Candidates/EditorEmail';
import SMSEditor from '../Candidates/EditorSMS';
import fileDownload from "js-file-download";
import ConfirmBigDownloadAlert from "../../alerts/ConfirmBigDownloadAlert"
import { useLocation } from 'react-router-dom';

export default function List(props) {
    const location = useLocation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(100);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [filters, setFilters] = React.useState([]);
    const [smsEditorData, setSmsEditorData] = useState(null);
    const [emailEditorData, setEmailEditorData] = useState(null);
    const [lookups, setLookups] = useState({});
    const [downloadAlert, setDownloadAlert] = useState([]);
    const queryObj = qs.parse(location.search, { ignoreQueryPrefix: true });
    const [firstLoad, setFirstLoad] = useState(true);
    const dispatchUnitName = queryObj?.dispatchunit ?? "";

    const defaultSort = React.useMemo(() => [{ id: "createdDate", desc: true }],
        []
    );

    const [sortBy, setSortBy] = React.useState(defaultSort);
    const skipPageResetRef = React.useRef();

    const loadData = React.useCallback(() => {
        if (firstLoad && dispatchUnitName !== "")
            filters.push({id: "dispatchUnitName", value: dispatchUnitName});
        
        // console.log({filters, sortBy});

        const queryString = qs.stringify({ filters, sortBy }, { allowDots: true });
        setData([]);
        skipPageResetRef.current = true;
        setLoading(true);

        const apiCalls = [
            api.get(`/lookup/messagesmasters`),
            api.get(`/communication/${(pageNumber - 1) * pageSize}/${pageSize}${queryString ? `?${queryString}` : ""}`),
        ];

        Promise.all(apiCalls)
            .then((responses) => {
                //console.log(responses[1].data.data);
                setLookups({
                    messagesMaster: responses[0].data
                });
                setData(responses[1].data.data);
                setTotalRecords(responses[1].data.total);
            })
            .catch((error) => {
                console.error("error: ", {error});
            })
            .finally(() => {
                setFirstLoad(false);
                setLoading(false);
            });
    }, [filters, sortBy, pageNumber, pageSize, firstLoad, dispatchUnitName]);

    useEffect(() => {
        loadData();
    }, [filters, sortBy, pageNumber, pageSize]);

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue).replace(/[/-]/g, "")
                            .toLowerCase()
                            .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            width: 190,
            disableSortBy: true
        }), []);

    const handleActionTaken = useCallback((original) => {
        // console.log(original);
        setLoading(true);
        const newAction = !original.actionTaken;

        api.put(`/communication/actiontaken/${original.messageLogId}?action=${newAction}`)
            .then((response) => {
                const responseData = response.data;
                const newData = [...data];
                const checkedRow = newData.filter(d => d.messageLogId === original.messageLogId)[0];
                const checkedIdx = newData.indexOf(checkedRow);
                const newRow = { ...checkedRow };
                newRow.actionTaken = newAction;
                newRow.actionTakenDate = responseData.actionTakenDate;
                newRow.modifiedBy = responseData.modifiedBy;
                newData.splice(checkedIdx, 1, newRow);
                // console.log({original, newRow, newData, data, responseData});
                setData(newData);
            })
            .catch((error) => {
                console.error("error: ", { error });
            })
            .finally(() => setLoading(false));
    }, [data]);

    const columns = React.useMemo(() => {
        return [
            {
                Header: "Date / Time",
                id: "createdDate",
                accessor: (row) => moment(new Date(`${moment(formatDateTime(row.createdDate)).format("M/D/YYYY HH:mm:ss")}`)).format("DD-MM-YYYY HH:mm:ss"),
                Filter: (e) => DateColumnFilter({ ...e, dateFormat: "DD-MM-YYYY", dateMask: "99-99-9999" }),
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "From",
                accessor: "createdBy",
                id: "createdBy",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "To",
                accessor: (row) => row.emailAddress ?? row.mobilePhone,
                id: "destination",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "Talent Name",
                accessor: (row) => `${row.candidate.firstName} ${row.candidate.surname}`,
                id: "candidateName",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "Project Name",
                accessor: (row) => row.rosterCandidate?.roster?.dispatchUnit?.project?.projectName,
                id: "projectName",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "Dispatch Name",
                accessor: (row) => row.rosterCandidate?.roster?.dispatchUnit?.dispatchUnitName,
                id: "dispatchUnitName",
                Filter: DefaultColumnFilter,
                filter: "text",
                Cell: ({ row: { original } }) => {
                    return (
                        <div className="w-100 p-0 m-0 d-flex flex-wrap justify-content-center align-items-start">
                        {
                            original.rosterCandidate ?
                            <a className="w-100" href={`/roster?projectid=${original.rosterCandidate.roster.dispatchUnit.projectId}&dispatchunitid=${original.rosterCandidate.roster.dispatchUnitId}`} target="_blank">
                                <span className="text-blue">
                                    <u>{original.rosterCandidate?.roster?.dispatchUnit?.dispatchUnitName}</u>
                                </span>
                            </a>
                            :
                            null
                        }
                        </div>
                    );
                },
            },
            {
                Header: "Location",
                accessor: (row) => row.rosterCandidate?.roster?.dispatchUnit?.location?.lookupValue,
                id: "locationName",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "Roster Position",
                accessor: (row) => row.rosterCandidate?.hrispositionMaster?.hrispositionMasterName,
                id: "hrispositionMasterName",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "Message Type",
                accessor: (row) => row.notificationType,
                id: "notificationType",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "Message Template Title",
                accessor: (row) => row.messageType?.lookupValue === "Notification" ? row.messagesMaster?.title : "Confirmation",
                id: "messagesMasterTitle",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "Response",
                accessor: "response",
                id: "response",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: true,
                disableFilters: true,
                width: 100
            },
            {
                Header: "Attachment from Email",
                id: "anyAttachment",
                Filter: DefaultColumnFilter,
                filter: "text",
                Cell: ({ row: { original } }) => {
                    return (
                        <div className="w-100 h-100 p-0 m-0 d-flex flex-wrap justify-content-center align-items-center">
                            <span>{original.anyAttachment ? "Y" : "N"}</span>
                        </div>
                    );
                },
            },
            {
                Header: "Action Taken",
                id: "action",
                disableSortBy: true,
                disableFilters: true,
                width: 130,
                Cell: ({ row: { original, index } }) => {
                    return (
                        <div className="w-100 p-0 m-0 d-flex flex-wrap justify-content-center align-items-center">
                            <IndeterminateCheckbox
                                id={`actiontaken-${index}`}
                                type="checkbox"
                                onChange={() => handleActionTaken(original)}
                                checked={original.actionTaken ?? false}
                            />
                        </div>
                    );
                }
            },
            {
                Header: "Action Date",
                accessor: (row) => row.actionTakenDate ? moment(formatDateTime(row.actionTakenDate)).format("DD-MM-YYYY HH:mm:ss") : null,
                id: "actionTakenDate",
                Filter: (e) => DateColumnFilter({ ...e, dateFormat: "DD-MM-YYYY", dateMask: "99-99-9999" }),
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "User",
                accessor: (row) => row.actionTaken !== null ? row.modifiedBy : null,
                id: "modifiedBy",
                Filter: DefaultColumnFilter,
                filter: "text",
                disableSortBy: false,
            },
            {
                Header: "Follow Up Action",
                id: "followUpAction",
                disableSortBy: true,
                disableFilters: true,
                width: 130,
                Cell: ({ row: { original, index } }) => {
                    return (
                        <div className="w-100 h-100 p-0 m-0 d-flex flex-wrap justify-content-center align-items-center">
                            <TooltipButton
                                id={`sendsms_${index}`}
                                title="Send SMS"
                                className="btn-icon mb-2"
                                color="default"
                                size="xsm"
                                type="button"
                                onClick={() => setSmsEditorData(original)}
                            >
                                <i className="fas fa-comment"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`sendemail_${index}`}
                                title="Send Email"
                                className="btn-icon mb-2"
                                color="default"
                                size="xsm"
                                type="button"
                                onClick={() => setEmailEditorData(original)}
                            >
                                <i className="fas fa-envelope-open"></i>
                            </TooltipButton>
                        </div>
                    );
                }
            },
        ]
    }, [handleActionTaken, dispatchUnitName]);

    const defaultFilter = useMemo(() => dispatchUnitName !== "" ? [{ id: "dispatchUnitName", value: dispatchUnitName }] : [], []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state
    } = useTable(
        {
            columns,
            data: data,
            initialState: {
                "hiddenColumns": [],
                sortBy: defaultSort,
                canSort: true,
                filters: defaultFilter
            },
            filterTypes,
            manualSortBy: true,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout,
        useFilters,
        useSortBy
    )

    React.useEffect(() => {
        if (firstLoad)
            return;

        const newFilters = [...state?.filters];
        const dateFilters = newFilters?.filter(f => f.id === "createdDate");

        if (dateFilters && dateFilters.length) {
            const itemIdx = newFilters.indexOf(dateFilters[0]);
            const dateFilter = { ...dateFilters[0] };
            // console.log({dateFilters, dateFilter});
            dateFilter.value = moment(formatDateTime(dateFilter.value)).format("YYYY-MM-DD");

            if (dateFilter.value.toLowerCase() === "invalid date")
                return;

            newFilters.splice(itemIdx, 1, dateFilter);
            // console.log({dateFilter, filters: newFilters});
        }

        // console.log({newFilters});
        setFilters(newFilters);
    }, [state.filters]);

    React.useEffect(() => {
        if (firstLoad)
            return;
        
        setSortBy(state.sortBy);
    }, [state.sortBy]);

    return (
        <section className="main">
            {loading && <Loader />}
            {downloadAlert.length > 0 && downloadAlert}
            <Container fluid>
                <Row>
                    <Col>
                        <Card className="no-transition">
                            <CardBody>
                                <Row>
                                    <Col lg={12}>
                                        <CardTitle>
                                            <h4 className="text-center">Communication History</h4>
                                        </CardTitle>
                                        <Row>
                                            <Col sm={12}>
                                                <TooltipButton
                                                    id="export"
                                                    title="Export to Excel"
                                                    className="btn-icon ml-2 mb-2"
                                                    color="default"
                                                    size="sm"
                                                    type="button"
                                                    disabled={(data == null || data.length == 0)}
                                                    onClick={() => {

                                                        // Function void to download the data
                                                        var exportAction = () => {
                                                            setLoading(true);
                                                            setDownloadAlert([]);
                                                            const queryString = qs.stringify({ filters, sortBy }, { allowDots: true });
                                                            var url = `Communication/export?${queryString}`;
                                                            api.get(url, { responseType: 'blob' })
                                                                .then(blob => {
                                                                    var today = new Date();
                                                                    var dd = String(today.getDate()).padStart(2, '0');
                                                                    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                                                                    var yyyy = today.getFullYear();

                                                                    // console.log("Download: ", blob);
                                                                    var fileNameDownload = `Communications_${yyyy}${mm}${dd}.xlsx`;
                                                                    fileDownload(blob.data, fileNameDownload);
                                                                }).catch(error => {
                                                                    console.error(error)
                                                                }).finally(() => setLoading(false));
                                                        }

                                                        // If big data download,
                                                        // Confirm the user first
                                                        if (totalRecords != null && totalRecords > 1000) {

                                                            var events = {
                                                                onConfirm: () => exportAction(),
                                                                onCancel: () => setDownloadAlert([])
                                                            }
                                                            setDownloadAlert([<ConfirmBigDownloadAlert {...events} />]);
                                                        }
                                                        // If small data download,
                                                        // Just directly download the data
                                                        else {
                                                            exportAction();
                                                        }

                                                    }}
                                                >
                                                    <i className="fas fa-file-excel"></i> Export
                                                </TooltipButton>


                                            </Col>
                                        </Row>
                                        <Grid
                                            height={'calc(100vh - 500px)'}
                                            totalRecords={totalRecords}
                                            pageSize={pageSize}
                                            rows={rows}
                                            tableProps={getTableProps()}
                                            headerGroups={headerGroups}
                                            tableBodyProps={getTableBodyProps()}
                                            prepareRow={prepareRow}
                                            onPagerChangePage={(pager) => {
                                                setPageNumber(pager.currentPage);
                                            }}
                                            onPagerChangePageSize={(size) => {
                                                setPageSize(size);
                                            }}
                                            needPaging={true}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {
                smsEditorData &&
                <SMSEditor
                    candidateId={smsEditorData.rosterCandidateId || smsEditorData.jobOrderCandidateId ? null : smsEditorData.candidateId}
                    refId={smsEditorData.rosterCandidateId ?? smsEditorData.jobOrderCandidateId}
                    projectId={smsEditorData.rosterCandidateId ? smsEditorData.rosterCandidate.roster.dispatchUnit.projectId : smsEditorData.jobOrderCandidateId ? smsEditorData.jobOrderCandidate.jobOrder.projectId : null}
                    project={smsEditorData.rosterCandidateId ? { projectId: smsEditorData.rosterCandidate.roster.dispatchUnit.projectId, projectName: smsEditorData.rosterCandidate.roster.dispatchUnit.project.projectName } : smsEditorData.jobOrderCandidateId ? { projectId: smsEditorData.jobOrderCandidate.jobOrder.projectId, projectName: smsEditorData.jobOrderCandidate.jobOrder.projectName } : null}
                    items={[{ ...smsEditorData }]}
                    onClose={() => {
                        setSmsEditorData(null);
                        loadData();
                    }}
                    onSaved={() => {
                        setSmsEditorData(null);
                        loadData();
                    }}
                    lookups={{
                        ...lookups
                    }}
                    sourcePage={smsEditorData.rosterCandidateId ? "roster" : smsEditorData.jobOrderCandidateId ? "jobOrder" : null}
                />
            }
            {
                emailEditorData &&
                <EditorEmail
                    candidateId={emailEditorData.rosterCandidateId || emailEditorData.jobOrderCandidateId ? null : emailEditorData.candidateId}
                    items={[{ ...emailEditorData }]}
                    refId={emailEditorData.rosterCandidateId ?? emailEditorData.jobOrderCandidateId}
                    projectId={emailEditorData.rosterCandidateId ? emailEditorData.rosterCandidate.roster.dispatchUnit.projectId : emailEditorData.jobOrderCandidateId ? emailEditorData.jobOrderCandidate.jobOrder.projectId : null}
                    project={emailEditorData.rosterCandidateId ? { projectId: emailEditorData.rosterCandidate.roster.dispatchUnit.projectId, projectName: emailEditorData.rosterCandidate.roster.dispatchUnit.project.projectName } : emailEditorData.jobOrderCandidateId ? { projectId: emailEditorData.jobOrderCandidate.jobOrder.projectId, projectName: emailEditorData.jobOrderCandidate.jobOrder.projectName } : null}
                    onClose={() => {
                        setEmailEditorData(null);
                        loadData();
                    }}
                    onSaved={() => {
                        setEmailEditorData(null);
                        loadData();
                    }}
                    lookups={{
                        ...lookups
                    }}
                    sourcePage={emailEditorData.rosterCandidateId ? "roster" : emailEditorData.jobOrderCandidateId ? "jobOrder" : null}
                />
            }
        </section >
    );
}