import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import {
   Container, Row, Col, Table, Button, Card, CardBody, CardTitle
} from "reactstrap";
import { DefaultColumnFilter } from "../../react-table/filters";
import { useTable, useFilters, useSortBy, useFlexLayout } from 'react-table';
import api from "../../../services/api";
import qs from "qs";
import Loader from "../../loaders";
import { TooltipButton } from '../../inputs';
import Grid from "../../Grid";
import moment from 'moment';
// import { 
//     ConfirmDeleteAlert, DeleteSuccessAlert, DeleteFailedAlert 
// } from "../../alerts";
import { useMsal } from "@azure/msal-react";
import { roles as constRoles } from "../../../auth/roles";
import _ from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';
import fileDownload from 'js-file-download';
import ComboboxColumnFilter from '../../react-table/filters/ComboboxColumnFilter';

function LabourRequestList (props) {
    const { instance } = useMsal();
    const [ data, setData ] = React.useState([]);
    // const [ alert, setAlert ] = React.useState([]);
    const [ pageNumber, setPageNumber ] = React.useState(1);
    const [ pageSize, setPageSize ] = React.useState(10);
    const [ totalRecords, setTotalRecords ] = React.useState(0);
    const [ filters, setFilters ] = React.useState([]);

    const defaultSort = React.useMemo(() => [{ id: "department", desc: false }],
        []
    );
    
    const [ sortBy, setSortBy ] = React.useState(defaultSort);

    const [ loading, setLoading ] = React.useState(false);
    const [ voidAuthorized, setVoidAuthorized ] = useState(false);
    const [ voidConfirmation, setVoidConfirmation ] = useState(null);
    const [ filterStatus, setFilterStatus ] = useState(null);
    const history = useHistory();
    const skipPageResetRef = React.useRef();

    useEffect(() => {
        const { idTokenClaims: { roles } } = instance.getActiveAccount();
        setVoidAuthorized(_.some(roles, o => o === constRoles.Admin));
    }, [instance]);

    const loadData = React.useCallback(() => {
        const queryString = qs.stringify({filters, sortBy}, { allowDots: true });
        setLoading(true);
        setData([]);

        skipPageResetRef.current = true;
        
        api.get(`/recruitmentrequest/${(pageNumber - 1) * pageSize}/${pageSize}${queryString ? `?${queryString}` : ""}`)
        .then(({data}) => {
            setData(data.data);
            setTotalRecords(data.total);
        }).catch((error) => {
            console.error("error: ", {error});
        }).finally(() => setLoading(false));
    }, [filters, sortBy, pageNumber, pageSize]);
    
    React.useEffect(() => loadData(), [pageNumber, pageSize, filters, sortBy]);

    const filterTypes = React.useMemo(() => ({
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
            const rowValue = row.values[id]
            return rowValue !== undefined
                ? String(rowValue).replace(/[/-]/g, "")
                    .toLowerCase()
                    .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                : true
            })
        },
    }), []);

    const columns = React.useMemo(() => {
        return [
            {
                Header: "Urgent Request",
                id: "isUrgent",
                accessor: (row) => row.isUrgent ? "Y" : "N",
                width: 130,
                disableSortBy: false,
            },      
            {
                Header: "Action",
                id: 'button',
                Cell: ({ row: { original } }) => {
                    return (
                        <div>
                            <TooltipButton
                                id={`edit_${original.recruitmentRequestId}`}
                                title="Edit"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={(event) => { history.push(`/lrf/${original.recruitmentRequestId}`) }}
                            >
                                <i className="fas fa-pencil-alt pt-1"></i>
                            </TooltipButton>
                            <TooltipButton
                                id={`joborders_${original.recruitmentRequestId}`}
                                title="View Job Orders"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={(event) => { history.push(`/joborder/${original.recruitmentRequestId}/request`) }}
                            >
                                <i className="fas fa-link pt-1"></i>
                            </TooltipButton>
                        {
                            voidAuthorized && original.status?.lookupValue === "Approved" &&
                            <TooltipButton
                                id={`void_${original.recruitmentRequestId}`}
                                title="Void"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={() => getLRFRelatedData(original)}
                            >
                                <i className="far fa-trash-alt pt-1"></i>
                            </TooltipButton>
                        }
                        </div>
                    )
                },
                width: 150,
            },
            {
                Header: "LRF Number",
                id: "recruitmentRequestNumber",
                accessor: "recruitmentRequestNumber",
                width: 100,
                disableSortBy: false,
            },            
            {
                Header: "Created Date",
                id: "createdDate",
                accessor: (row) => moment(row.createdDate).format('DD.MM.YYYY'),
                width: 100,
                disableSortBy: false,
            },
            {
                Header: "Department",
                id: "department",
                accessor: (row) => row.department?.lookupValue,
                width: 120,
                disableSortBy: false,
            },
            {
                Header: "Location",
                id: "location",
                accessor: (row) => row.location?.lookupValue,
                width: 100,
                disableSortBy: false,
            },
            {
                Header: "Project",
                id: "project",
                accessor: (row) => row.project?.lookupValue,
                width: 100,
                disableSortBy: false,
            },
            {
                Header: "Dispatch Unit",
                id: "dispatchUnit",
                accessor: (row) => row.roster?.dispatchUnit.dispatchUnitName,
                width: 100,
                disableSortBy: false,
            },
            {
                Header: "Roster",
                id: "roster",
                accessor: (row) => row.roster?.rosterName,
                width: 100,
                disableSortBy: false,
            },
            {
                Header: "Type",
                id: "type",
                accessor: (row) => row.recruitmentType?.lookupValue,
                width: 100,
                disableSortBy: false,
            },
            {
                Header: "Status",
                id: "status",
                accessor: (row) => row.status?.lookupValue,
                Filter: (e) => ComboboxColumnFilter({ ...e, isLookup: true, endpoint: "/ApprovalStatus", idField: "lookupId", valueField: "lookupValue", defaultValue: filterStatus }),
                width: 150,
                disableSortBy: false,
            },
            {
                Header: "Approver Name",
                id: "approverName",
                accessor: "approverName",
                width: 120,
                disableSortBy: false,
            },
            {
                Header: "Created By",
                id: "createdBy",
                accessor: "createdBy",
                width: 120,
                disableSortBy: false,
            },
            {
                Header: "Positions",
                id: "positions",
                accessor: (row) => row.details?.map(m => (m.joExists ? <><a href={`/joborder/${m.requestDetailId}/joborder`} target='_blank'>{m.hrispositionMaster} ({m.amount})</a><br/></> : <><span>{m.hrispositionMaster} ({m.amount})</span><br/></>)),
                width: 300,
                disableSortBy: true,
            }
        ]
    }, [voidAuthorized, filterStatus]);

    // const handleDelete = (id) => {

    //     setAlert([
    //         <ConfirmDeleteAlert 
    //             onCancel={() => setAlert([])}
    //             deleteFile={() => {
    //                 apiDelete(`/recruitmentrequest/${id}`, token)
    //                 .then(() => {
    //                     setAlert([
    //                         <DeleteSuccessAlert hideAlert={() => setAlert([])} />
    //                     ])
    //                 })
    //                 .catch(() => {
    //                     setAlert([
    //                         <DeleteFailedAlert hideAlert={() => setAlert([])} />
    //                     ])
    //                 });
    //             }}
    //         />
    //     ])
    // }

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: defaultSort,
                canSort: true,
            },
            filterTypes,
            manualSortBy: true,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout,
        useFilters,
        useSortBy
    );
    
    React.useEffect(() => {
        setFilters(state.filters);
    }, [state.filters]);
    
    React.useEffect(() => {
        setSortBy(state.sortBy);
    }, [state.sortBy]);

    const getLRFRelatedData = useCallback((data) => {
        setLoading(true);
        setVoidConfirmation(null);

        const apiCalls = [
            api.get(`/joborder/lrfvoidcheck?recruitmentrequestid=${data.recruitmentRequestId}`),
            api.get(`/rostercandidate/lrfvoidcheck?recruitmentrequestid=${data.recruitmentRequestId}`),
        ];

        Promise.all(apiCalls)
            .then(responses => {
                setVoidConfirmation({
                    lrfId: data.recruitmentRequestId,
                    lrfNo: data.recruitmentRequestNumber,
                    rosters: [...responses[1].data],
                    jobOrders: [...responses[0].data]
                });
            })
            .catch((error) => console.log({error}))
            .finally(() => setLoading(false))
        ;
    }, []);
    
    const handleVoidRequest = useCallback(() => {
        if (!voidConfirmation)
            return;
        
        setLoading(true);
        const confirmObject = {...voidConfirmation};
        setVoidConfirmation(null);

        api.put(`/recruitmentrequest/void/${confirmObject.lrfId}`)
            .then(() => {
                setVoidConfirmation(null);
                loadData();
            })
            .catch((error) => console.log({error}))
            .finally(() => setLoading(false))
        ;
    }, [voidConfirmation]);

    const handleExportToExcel = useCallback(() => {
        setLoading(true);
        const url = `recruitmentrequest/export`;

        api.get(url, { responseType: 'blob' })
            .then(blob => {
                fileDownload(blob.data, "LRF.xlsx");
            })
            .catch(error => {
                console.error({error});
            })
            .finally(() => setLoading(false))
        ;
    }, [data]);

    return (
        <section className="main">
            {loading && <Loader />}
            {/* {!!alert && alert.length > 0 && alert} */}
            <Container fluid>
                <Row>
                    <Col>
                    <Card className="no-transition">
                        <CardBody>
                            <CardTitle>
                                <h4 className="text-center">Labour Request Forms</h4>
                            </CardTitle>
                                <TooltipButton
                                    id="addnew"
                                    title="Add New"
                                    className="btn-icon ml-2 mb-2"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    onClick={() => { history.push("/lrf") }}
                                >
                                    <i className="fas fa-plus pt-1"></i>
                                </TooltipButton>
                                <TooltipButton
                                    id="export-excel"
                                    title="Export to Excel"
                                    className="btn-icon ml-2 mb-2"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    onClick={() => handleExportToExcel()}
                                >
                                    <i className="fas fa-file-excel pt-1"></i>
                                </TooltipButton>
                                <Grid
                                    height={'calc(100vh - 464px)'}
                                    totalRecords={totalRecords}
                                    pageSize={pageSize}
                                    rows={rows}
                                    tableProps={getTableProps()}
                                    headerGroups={headerGroups}
                                    tableBodyProps={getTableBodyProps()}
                                    prepareRow={prepareRow}
                                    onRowDoubleClick={(row) => history.push(`/tna/${row.original.projectId}`)}
                                    onPagerChangePage={(pager) => {
                                        setPageNumber(pager.currentPage);
                                    }}
                                    onPagerChangePageSize={(size) => {
                                        setPageSize(size);
                                    }}
                                    needPaging={true}
                                    rowStyle={(row) => {
                                        return row.original.status?.lookupValue == "Approved" ? "green" : row.original.status?.lookupValue == "Draft" ? "yellow" : ""
                                    }}
                                />
                        </CardBody>
                    </Card>                        
                    </Col>
                </Row>
            </Container>
        {
            voidConfirmation ?
            (
                
                <SweetAlert
                    title={`${voidConfirmation.lrfNo} - Void Confirmation`}
                    warning
                    showCancel
                    cancelBtnText="No"
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="danger"
                    onConfirm={() => handleVoidRequest()}
                    onCancel={() => setVoidConfirmation(null)}
                    customClass={voidConfirmation.rosters?.length || voidConfirmation.jobOrders?.length ? "modal-xl-mandatory" : null}
                >
                {
                    voidConfirmation.rosters?.length || voidConfirmation.jobOrders?.length ?
                    <Container fluid>
                        <Row>
                            <Col lg="12" className="my-3">
                                <h5 className="text-justify">
                                    Roster/Job Order is detected for this Recruitment Request. Are you sure you want to void this request?
                                </h5>
                            </Col>
                        </Row>
                        <Row style={{maxHeight: 400, overflowY: "scroll"}}>
                        {
                            voidConfirmation.rosters?.length > 0 ?
                            <>
                                <Col lg="12">
                                    <h5 className="text-justify">
                                        <u>Rosters:</u>
                                    </h5>
                                </Col>
                                <Col lg="12" className="mb-2">
                                {
                                    voidConfirmation.rosters.map((m, idx) => {
                                        return (
                                            <p className="text-left m-0 p-0" key={`candidate_change_confirm_${idx}`}>
                                                <span style={{fontWeight: "bold"}}>{m.dispatchUnitName}<i className="fas fa-chevron-right mx-2" /></span>{m.rosterName}<i className="fas fa-chevron-right mx-2" />{`Slot No. ${m.slotNo}`}<i className="fas fa-chevron-right mx-2" />{`Roster Position: ${m.hrispositionMasterName}`}<i className="fas fa-chevron-right mx-2" />{`Employed Position: ${m.positionName}`}<i className="fas fa-chevron-right mx-2" />{m.startDate ? moment(m.startDate).format("DD-MM-YYYY") : ""} to {m.endDate ? moment(m.endDate).format("DD-MM-YYYY") : ""}<i className="fas fa-chevron-right mx-2" />{`Status: ${m.status}`}
                                            </p>
                                        );
                                    })
                                }
                                </Col>
                            </>
                            :
                            null
                        }
                        {
                            voidConfirmation.jobOrders?.length > 0 ?
                            <>
                                <Col lg="12">
                                    <h5 className="text-justify">
                                        <u>Job Orders:</u>
                                    </h5>
                                </Col>
                                <Col lg="12" className="mb-2">
                                {
                                    voidConfirmation.jobOrders.map((m, idx) => {
                                        return (
                                            <p className="text-left m-0 p-0" key={`candidate_change_confirm_${idx}`}>
                                                <span style={{fontWeight: "bold"}}>{m.recruiterName}<i className="fas fa-chevron-right mx-2" /></span>{`Roster Position: ${m.hrispositionMasterName}`}<i className="fas fa-chevron-right mx-2" />{`Employed Position: ${m.positionName}`}<i className="fas fa-chevron-right mx-2" />Talent Name: {m.candidateName ? m.candidateName : "-"}<i className="fas fa-chevron-right mx-2" />Latest Milestone: {m.latestMilestoneName ? m.latestMilestoneName : "-"}{m.latestMilestoneDate ? ` - ${moment(m.latestMilestoneDate).format("DD-MM-YYYY")}` : ""}<i className="fas fa-chevron-right mx-2" />Status: {m.status ? m.status : "-"}
                                            </p>
                                        );
                                    })
                                }
                                </Col>
                            </>
                            :
                            null
                        }
                        </Row>
                    </Container>
                    :
                    <h5 className="text-justify">
                        Are you sure you want to void this Recruitment Request?
                    </h5>
                }
                </SweetAlert>
            )
            :
            null
        }
        </section>
    );
}

export default LabourRequestList;
