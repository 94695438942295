import React, { useCallback, useEffect, useMemo, useState } from 'react';
import api from "../../../../services/api";
import {
    Button, Modal, Row, Col, Container, Alert
} from "reactstrap"; 
import Loader from "../../../loaders";
import { useFlexLayout, useRowSelect, useSortBy, useTable } from 'react-table';
import { IndeterminateCheckbox } from '../../../react-table/controls';
import Grid from '../../../Grid';
import moment from "moment";

export default function PositionStepUpEditor(props) {
    const {
        rosterCandidateId,
        candidate,
        position,
        company,
        startDate,
        onClose,
        onSave
    } = props;

    const [ loading, setLoading ] = React.useState(false);
    const [ data, setData ] = useState([]);
    const [ rosterCandidate, setRosterCandidate ] = useState(null);
    const [ initialSelectedRows, setInitialSelectedRows ] = useState([]);

    const loadData = React.useCallback(() => {
        setLoading(true);

        const apiCalls = [
            api.get(`/candidate/positions/${candidate?.candidateId}`),
            api.get(`/rosterCandidate/id/${rosterCandidateId}`)
        ];

        Promise.all(apiCalls)
            .then((responses) => {
                setRosterCandidate(responses[1].data);
                setData(responses[0].data);
            })
            .catch((error) => console.log(error.response))
            .finally(() => {
                setLoading(false);
            });
    }, [candidate, rosterCandidateId]);

    useEffect(() => {
        if (!candidate)
            return;

        loadData();
    }, [candidate]);

    useEffect(() => {
        // console.log({data, position, rosterCandidate, startDate});

        if (!data.length)
            return;
        
        const arraySelected = [];
        data.forEach((item, index) => {
            if (!(
                    (!position || !position.positionId || position.positionId === 0 || item.positionId === position.positionId)
                    //&& rosterCandidate.companyId === item.companyId
                    && company.lookupId === item.companyId
                    && item.startDate
                    && moment(startDate, "YYYY-MM-DD") >= moment(item.startDate, "YYYY-MM-DD")
                    && (!item.endDate || moment(startDate, "YYYY-MM-DD") <= moment(item.endDate, "YYYY-MM-DD"))
                ))
                return;
            
            const existing = arraySelected.filter(d => d.positionId === item.positionId && d.companyId === item.companyId);

            if (!existing.length)
            {
                arraySelected.push({id: index, ...item});
                return;
            }

            const existingItem = existing[0];
            const itemIndex = arraySelected.indexOf(existingItem);
            const newItem = {...item, id: index};

            if (moment(item.startDate, "YYYY-MM-DD") > moment(existingItem.startDate, "YYYY-MM-DD") && (!item.endDate || !existingItem.endDate || moment(item.endDate, "YYYY-MM-DD") > moment(existing.endDate, "YYYY-MM-DD")))
                arraySelected.splice(itemIndex, 1, newItem);
        });

        const selected = {};

        arraySelected.forEach((item) => {
            selected[`${item.id}`] = true;
        })

        // console.log(selected);

        setInitialSelectedRows(selected);
    }, [data, position, rosterCandidate, startDate]);

    // useEffect(() => {
    //     console.log({initialSelectedRows});
    // }, [initialSelectedRows]);

    const columns = React.useMemo(() => {
        return [
            {
                id: 'selection',
                width: 30,
                disableSortBy: true,
                Header: () => {
                    return (
                        <div>
                        </div>
                    )
                },
                Cell: ({row}) => {
                    const {original} = row;
                    const currentDate = moment();
                    
                    return (
                        <div>
                        {
                            original != null &&
                            (!original.startDate || moment(original.startDate, "YYYY-MM-DDTHH:mm:ss") <= currentDate) &&
                            (!original.endDate || moment(original.endDate, "YYYY-MM-DDTHH:mm:ss") >= currentDate) &&
                            <IndeterminateCheckbox
                                {...row.getToggleRowSelectedProps()}
                                id={row.id}
                                onChange={(e) => {
                                    // console.log({row, selected: row.isSelected});
                                    const selected = row.isSelected;
                                    toggleAllRowsSelected(false);
                                    row.toggleRowSelected(!selected);
                                }}
                            />
                        }
                        </div>
                    )
                },
            },
            {
                Header: "Position Name",
                accessor: "position.positionName",
                disableSortBy: false,
            },
            {
                Header: "Company Name",
                accessor: "company.companyName",
                disableSortBy: false,
            },
            {
                Header: "Is Primary",
                accessor: (row) => row.isPrimary ? "Y" : "N",
                disableSortBy: false,
            },
            {
                Header: "Start Date",
                accessor: (row) => row.startDate ? moment(row.startDate, "YYYY-MM-DD").format("DD-MM-YYYY") : null,
                disableSortBy: false,
            },
            {
                Header: "End Date",
                accessor: (row) => row.endDate ? moment(row.endDate, "YYYY-MM-DD").format("DD-MM-YYYY") : null,
                disableSortBy: false,
            },
        ]
    }, [data, initialSelectedRows]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,     
        selectedFlatRows,
        toggleAllRowsSelected
    } = useTable(
        {
            columns,
            data,
            initialState: {
                selectedRowIds: initialSelectedRows
            },
        },
        useFlexLayout,
        // hooks => {
        //     hooks.visibleColumns.push(columns => [
                
        //         ...columns,
        //     ])
        // },
        useSortBy,
        useRowSelect,
    )

    const handleSave = useCallback(() => {
        if (!selectedFlatRows?.length || !rosterCandidateId)
            return;
        
        setLoading(true);
        // console.log({selectedFlatRows, rosterCandidateId});
        const selectedData = selectedFlatRows[0].original;
        // console.log({selectedData});
        api.put(`/rostercandidate/positioncandidate/${rosterCandidateId}/${selectedData.positionId}/${selectedData.companyId}/${candidate.candidateId}`)
            .then((response) => {
                onSave(response.data, props.rowNode);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error.response);
            })
    }, [rosterCandidateId, selectedFlatRows]);

    return (
        <Modal
            isOpen={true}
            className="modal-xl"
            modalClassName="db-example-modal-xl"
        >
        {
            (loading) && <Loader />
        }
            <div
                className="modal-header"
            >
                <Container fluid>
                    <Row>
                        <Col lg={9}>
                            <h5
                                className="modal-title"
                            >
                                Talent Position - {candidate?.candidateName}
                            </h5>
                        </Col>
                        <Col lg={3}>
                            <button
                                aria-label="Close"
                                className="close"
                                onClick={onClose}
                                type="button"
                            >
                                <span aria-hidden={true}><i className="fas fa-times" /></span>
                            </button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-body"
            >
                <Row>
                    <Col lg={12}>
                    <Grid
                        height='calc(100vh - 436px)'
                        rows={rows}
                        tableProps={getTableProps()}
                        headerGroups={headerGroups}
                        tableBodyProps={getTableBodyProps()}
                        prepareRow={prepareRow}
                    />
                    </Col>
                </Row>
            </div>
            <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={() => handleSave()}
                    type="button"
                >
                    Save Changes
                </Button>
            </div>
        </Modal>
    );
}
