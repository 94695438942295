/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col, Button, Input } from 'reactstrap';
import useRoster from "../contexts/RosterContext";
import moment from "moment";
import fileDownload from "js-file-download";
import qs from "qs";
import api from "../../../../services/api";
import { formatDate, ViewAccess } from '../../../../utils'
import ExportRosterScheduleDialog from '../dialogs/ExportRosterScheduleDialog';
import ExportAccommodationFlightDialog from '../dialogs/ExportAccommodationFlightDialog';
import { ShortenWords, ToFilenameString } from '../../../../helpers/StringHelper';

export default (props) => {

    const {
        api: gridApi,
        columnApi,
        setLoading,
        setOpenRosterImport,
        setOpenAccommodationFlightImport,
        setOpenRosterSheetImport,
        viewAccess,
        exportDataCallback,
    } = props;

    const {
        setDispatchUnit,
        dispatchUnit,
        project,
        setProject,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        trimStartDate,
        setTrimStartDate,
        trimEndDate,
        setTrimEndDate,
        showArchivedDispatchUnits,
        setShowArchivedDispatchUnits,
        dataLoaded,
        dataExists,
    } = useRoster();

    //const [rosterExportDialogOpen, setRosterExportDialogOpen] = useState(false);

    
    // BEGIN rosterExport ------------------------------------------------------------------------------
    const [rosterExportIsOpen, setRosterExportIsOpen] = useState(false);
    const [rosterExport, setRosterExport] = useState(null);
    const rosterExportRef = React.useRef(rosterExport);
    useEffect(() => {
        rosterExportRef.current = rosterExport;
    }, [rosterExport]);
    // END rosterExport --------------------------------------------------------------------------------

    // BEGIN accommodationFlightExport ------------------------------------------------------------------
    const [accommodationFlightIsOpen, setAccommodationFlightIsOpen] = useState(false);
    const [accommodationFlightExport, setAccommodationFlightExport] = useState(null);
    const accommodationFlightExportRef = React.useRef(accommodationFlightExport);
    useEffect(() => {
        accommodationFlightExportRef.current = accommodationFlightExport;
    }, [accommodationFlightExport]);
    // END accommodationFlightExport --------------------------------------------------------------------

    const resetRosterExportValues = useCallback(() => {
        setRosterExport({
            startDate: startDate,
            endDate: endDate
        });
        //setRosterExportIsOpen(false);
    }, [startDate, endDate]);

    useEffect(() => {
        resetRosterExportValues();
    }, [startDate, endDate]);



    //const [inputStartDate, setInputStartDate] = useState(null);
    //const [inputEndDate, setInputEndDate] = useState(null);

    //const inputStartDateRef = React.useRef(inputStartDate);
    //const inputEndDateRef = React.useRef(inputEndDate);

    //useEffect(() => {
    //    inputStartDateRef.current = inputStartDate;
    //}, [inputStartDate]);

    //useEffect(() => {
    //    inputEndDateRef.current = inputEndDate;
    //}, [inputEndDate]);



    //useEffect(() => {
    //    setInputStartDate(startDate);
    //}, [startDate]);
    //useEffect(() => {
    //    setInputEndDate(endDate);
    //}, [endDate])

    const openRosterExport = useCallback(() => {
        const params = {
            start: moment(rosterExport.startDate).format('YYYY-MM-DD'),
            end: moment(rosterExport.endDate).format('YYYY-MM-DD'),
            projectId: project?.projectId,
            dispatchUnitId: dispatchUnit?.lookupId
        }

        // const url = `/reports/download-roster?${qs.stringify(params)}`;
        const url = `/reports/download-roster-sheet?${qs.stringify(params)}`;
        let startdateFileName = moment(rosterExport.startDate).format('YYYYMMDD');
        let enddateFileName = moment(rosterExport.endDate).format('YYYYMMDD');
        let projectName = ShortenWords(ToFilenameString(project?.projectName), 6);
        let dispatchUnitName = ShortenWords(ToFilenameString(dispatchUnit?.lookupValue), 10);

        let filename = `roster-${projectName}-${dispatchUnitName}-${startdateFileName}-to-${enddateFileName}.xlsx`;
        downloadExport(url, filename);
        //}, [startDate, endDate, project, dispatchUnit]);
    }, [rosterExport, project, dispatchUnit]);

    const openAccommodationFlightsExport = useCallback(() => {
        const params = {
            //start: moment(rosterExport.startDate).format('YYYY-MM-DD'),
            //end: moment(rosterExport.endDate).format('YYYY-MM-DD'),
            projectIds: [project?.projectId],
            dispatchUnitIds: [dispatchUnit?.lookupId]
        }

        // const url = `/reports/download-roster?${qs.stringify(params)}`;
        const url = `/reports/download-accommodation-flight?${qs.stringify(params)}`;
        let updateTimeFileName = moment().format('YYYY.MM.DD.HH.mm.ss');


        let projectName = ShortenWords(ToFilenameString(project?.projectName), 6);
        let dispatchUnitName = ShortenWords(ToFilenameString(dispatchUnit?.lookupValue), 10);
        let filename = `accommodation-and-flights-${projectName}-${dispatchUnitName}-per-${updateTimeFileName}.xlsx`;
        downloadExport(url, filename);
        //}, [startDate, endDate, project, dispatchUnit]);
    }, [accommodationFlightExport, project, dispatchUnit]);

    const openRosterSiteExport = useCallback(() => {
        const params = {
            start: moment(startDate).format('YYYY-MM-DD'),
            end: moment(endDate).format('YYYY-MM-DD'),
            projectId: project?.projectId,
            dispatchUnitId: dispatchUnit?.lookupId
        }

        let url = `/reports/download-roster-site?${qs.stringify(params)}`;
        let startdateFileName = moment(startDate).format('YYYYMMDD');
        let enddateFileName = moment(endDate).format('YYYYMMDD');

        let filename = `roster-site-${project?.projectName}-${dispatchUnit?.lookupValue}-${startdateFileName}-to-${enddateFileName}.xlsx`;
        downloadExport(url, filename);
    }, [startDate, endDate, project, dispatchUnit]);

    const downloadExport = React.useCallback((url, filename) => {
        setLoading(true);
        api.get(url, { responseType: 'blob' })
            .then(blob => {
                fileDownload(blob.data, filename);
                setRosterExportIsOpen(false);
                resetRosterExportValues();
            }).catch(error => {
                console.error({ error });
                //setErrorMessage(`${errorMessageOpening}${error.message}`);
            }).finally(() => setLoading(false))
    }, []);

    const generateRosterSheetAction = useCallback(() => {
        setLoading(true);

        const params = {
            fileName: `${dispatchUnit.lookupValue} - ${moment(new Date()).format("DD-MM-YYYY HH:mm:ss")}`,
            sheetName: "data",
            processCellCallback: (param) => {

                // If date column, return the date value
                if (param.column.colId.includes("dates") && param.value)
                {
                    return param.column.colId.includes("dates") && param.value ? param.value.value : param.value;
                }

                if (param.column.colId.includes("candidateName") && param.value)
                { 
                    return param.value.trim();
                }

                return param.value;

            },
        };

        gridApi.exportDataAsExcel(params);
        setLoading(false);
        // exportDataCallback();
    }, [gridApi, dispatchUnit]);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col xs={12} className="pt-3">
                        <h5>Import / Export Options</h5>
                    </Col>
                    <Col xs={12} className="pt-3">
                        <Button
                            color="secondary btn-grid-panel"
                            onClick={() => {
                                //setRosterExport({
                                //    startDate: startDate,
                                //    endDate: endDate
                                //});
                                setRosterExportIsOpen(true);
                                //openRosterExport();
                            }}
                            type="button"
                            className="w-100 text-left"
                            disabled={!dataLoaded}
                        >
                            Export Roster Schedule
                        </Button>
                    </Col>
                    {/* <Col xs={12} >
                    <Button
                        color="secondary"
                        onClick={openRosterSiteExport}
                        type="button"
                        className="w-100"
                        disabled={!dataLoaded}
                    >
                        Site Export
                    </Button>
                </Col> */}
                    {viewAccess == ViewAccess.FULLACCESS &&
                        <Col xs={12}>
                            <Button
                                color="secondary btn-grid-panel"
                                onClick={() => {
                                    setOpenRosterImport(true);
                                }}
                                type="button"
                                className="w-100 text-left"
                                disabled={!dataLoaded}
                            >
                                Import Roster Update
                            </Button>
                        </Col>
                    }

                    <Col xs={12}>
                        <Button
                            color="secondary btn-grid-panel"
                            onClick={generateRosterSheetAction}
                            type="button"
                            className="w-100 text-left"
                            disabled={!dataLoaded}
                        >
                            Roster Sheet Generator
                        </Button>
                    </Col>
                {
                    viewAccess === ViewAccess.FULLACCESS &&
                    <Col xs={12}>
                        <Button
                            color="secondary btn-grid-panel"
                            onClick={() => {
                                setOpenRosterSheetImport(true);
                            }}
                            type="button"
                            className="w-100 text-left"
                            disabled={!dataLoaded}
                        >
                            Import Roster Sheet
                        </Button>
                    </Col>
                }
                    <Col xs={12} className="pt-3">
                        <h5>Accommodation & Flights</h5>
                    </Col>
                    {viewAccess == ViewAccess.FULLACCESS &&
                        <Col xs={12}>
                            <Button
                                color="secondary btn-grid-panel"
                                onClick={() => {
                                    setOpenAccommodationFlightImport(true);
                                }}
                                type="button"
                                className="w-100 text-left"
                                disabled={!dataLoaded}
                            >
                                Import Accommodation & Flights Update
                            </Button>
                        </Col>
                    }
                    <Col xs={12} className="pt-3">
                        <Button
                            color="secondary btn-grid-panel"
                            onClick={() => {
                                //setRosterExport({
                                //    startDate: startDate,
                                //    endDate: endDate
                                //});
                                setAccommodationFlightIsOpen(true);
                                //openRosterExport();
                            }}
                            type="button"
                            className="w-100 text-left"
                            disabled={!dataLoaded}
                        >
                            Export Accommodation & Flights
                        </Button>
                    </Col>
                </Row>
            </Container>
        {
            rosterExportIsOpen &&
            <ExportRosterScheduleDialog
                itemInEditRef={rosterExportRef}
                setItemInEdit={setRosterExport}


                onClose={() => {
                    setRosterExportIsOpen(false);
                    resetRosterExportValues();
                }}
                action={() => {
                    openRosterExport();
                }}

            />
        }
        {
            accommodationFlightIsOpen &&
            <ExportAccommodationFlightDialog
                itemInEditRef={accommodationFlightExportRef}
                setItemInEdit={setAccommodationFlightExport}


                onClose={() => {
                    setAccommodationFlightIsOpen(false);
                    //resetRosterExportValues();
                }}
                action={() => {
                    openAccommodationFlightsExport();
                }}

            />
        }
        </>
    )
}