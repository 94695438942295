import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Container } from 'reactstrap';
import PropTypes from "prop-types";

const NavbarPage = (props) => {
    const {
        name,
        tabs,
        onTabClick,
    } = props;
    
    const tab_id_template = `${name}-tab-`;
    const [ activeTab, setActiveTab ] = useState(0);
    const [counter, setCounter] = useState(100);
    const intervalRef = useRef(null);

    const getElementByTabIndex = useCallback((index) => {
        const tab = tabs[index];
        return document.getElementById(`${tab_id_template}${tab.tabIndex}`);
    }, []);

    const scrollNavigation = useCallback((left = true) => {
        const length = 50;
        document.getElementById("navigation-container").scrollLeft += (left ? (-length) : length);
    }, [getElementByTabIndex]);
  
    const startCounter = useCallback((left = true) => {
        if (intervalRef.current) return;
        intervalRef.current = setInterval(() => {
            scrollNavigation(left);
        }, 50);
    }, [scrollNavigation]);
  
    const stopCounter = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };
  
    useEffect(() => {
        return () => stopCounter();
    }, []);

    return (
        <Container
            fluid
            className="d-flex flex-row justify-content-between align-items-center pt-3 border-bottom border-light"
        >
            <button
                className="btn-tab-nav-carousel"
                onMouseDown={() => startCounter()}
                onMouseUp={() => stopCounter()}
                onMouseLeave={() => stopCounter()}
            >
                <i className="fas fa-chevron-left" />
            </button>
            <div
                className="d-flex flex-row align-items-center tab-nav-carousel-container"
                style={{overflowX: "hidden", width: "95%"}}
                id="navigation-container"
            >
            {
                tabs.map((t) => (
                    <Button
                        id={`${tab_id_template}${t.tabIndex}`}
                        key={`${tab_id_template}${t.tabIndex}`}
                        className="text-nowrap py-2 m-0 btn-nav-tab"
                        color="default"
                        active={activeTab === t.tabIndex}
                        onClick={(e) => {
                            e.preventDefault();
                            setActiveTab(t.tabIndex);
                            onTabClick(t);
                        }}
                    >
                        {t.title}
                    </Button>
                ))
            }
            </div>
            <button
                className="btn-tab-nav-carousel"
                onMouseDown={() => startCounter(false)}
                onMouseUp={() => stopCounter()}
                onMouseLeave={() => stopCounter()}
            >
                <i className="fas fa-chevron-right" />
            </button>
        </Container>
    );
};

NavbarPage.propTypes = {
    name: PropTypes.string.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        tabIndex: PropTypes.number.isRequired
    })).isRequired,
    onTabClick: PropTypes.func.isRequired,
};

export default NavbarPage;