import React from 'react';
import PropTypes from "prop-types";
import {
    Input, Label, Button
 } from "reactstrap";

export default function EditSeqNo(props) {
    const {
        id,
        seqNo,
        status,
        onEdit,
        onSave,
        onClose,
    } = props;

    const [ newSeqNo, setNewSeqNo ] = React.useState(0);

    if(!status.isSelected)
        return (
            <div id={`show_div_${id}`} className="d-flex flex-row justify-content-center align-items-center w-100">
                <Input
                    id={`show_${id}`}
                    name="seqNo_show"
                    type="text"
                    value={seqNo}
                    className="mr-2"
                    disabled={true}
                />
                <Button
                    id={`edit_${id}`}
                    title="Edit"
                    className="btn-icon"
                    color="default"
                    size="sm"
                    type="button"
                    onClick={() => onEdit()}
                >
                    <i className="fas fa-pencil-alt pt-1"></i>
                </Button>
            </div>
        );
    
    return (
        <div id={`edit_div_${id}`} className="d-flex flex-row justify-content-center align-items-center w-100">
            <Input
                id={`edit_${id}`}
                name="seqNo"
                type="number"
                required
                value={newSeqNo ? newSeqNo : seqNo}
                onChange={({ target: { value } }) => setNewSeqNo(parseInt(value))}
                className="mr-2"
            />
            <Button
                id={`save_${id}`}
                title="Save"
                className="btn-icon"
                color="default"
                size="sm"
                type="button"
                onClick={() => {
                    onSave(id, newSeqNo);
                }}
            >
                <i className="fas fa-save pt-1"></i>
            </Button>
            <Button
                id={`close_${id}`}
                title="Cancel"
                className="btn-icon"
                color="default"
                size="sm"
                type="button"
                onClick={() => onClose()}
            >
                <i className="fas fa-times-circle pt-1"></i>
            </Button>
        </div>
    );
};

EditSeqNo.propTypes = {
    id: PropTypes.number.isRequired,
    seqNo: PropTypes.number.isRequired,
    status: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
}