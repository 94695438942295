import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import api from "../../../../services/api";
import TemplateDialog from "./TemplateDialog";
import { useBlockLayout, useFlexLayout, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import Grid from "../../../Grid";
import { RadioButton } from "@progress/kendo-react-inputs";

const templateRows = [
    {
        id: "recruitmentRequestNumber",
        title: "LRF Number",
    },
    {
        id: "recruiterName",
        title: "Recruiter",
    },
    {
        id: "projectName",
        title: "Project",
    },
    {
        id: "positionName",
        title: "Position",
    },
    {
        id: "date",
        title: "Start/End Date",
        isEndRow: true,
    },
];

function JobOrderAssignmentDialog(props) {
    const {
        title,
        selectedCandidates,
        selectedCandidateId,
        onClose,
        onBackClick,
        onNextClick,
        direction,
    } = props;

    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState([]);
    const skipPageResetRef = useRef();

    const loadData = useCallback(() => {
        skipPageResetRef.current = true;

        if (!selectedCandidates?.length)
            return;

        setLoading(true);

        api.post(`candidatemerge/compare/joborder/assignments`, JSON.stringify(selectedCandidates.map(c => c.candidateId)))
            .then((response) => {
                const {data: {data: responseData, maxRowCount}} = response;

                if (!responseData.length)
                {
                    setLoading(false);

                    if (direction === 1)
                        onNextClick();
                    else
                        onBackClick();

                    return;
                }

                const transposedData = [];

                for (let i = 0; i < maxRowCount; i++) {
                    templateRows.forEach(r => {
                        const row = {
                            title: r.title,
                            isEndRow: r.isEndRow,
                        };

                        responseData.forEach(d => {
                            if (responseData.filter(r => r.candidateId === d.candidateId).length - 1 < i)
                                return;
                            
                            const currentResponseData = responseData.filter(r => r.candidateId === d.candidateId)[i];
                            row[d.candidateId] = currentResponseData[r.id] ?? "N/A";
                        });

                        transposedData.push(row);
                    });
                }
                // console.log({transposedData});
                setData([...transposedData]);
                setLoading(false);
            })
            .catch((error) => {
                console.log({error});
                setLoading(false);
            })
        ;
    }, [skipPageResetRef, api, selectedCandidateId, onNextClick, onBackClick, direction]);

    useEffect(() => loadData(), [selectedCandidates]);

    const defaultColumn = useMemo(() => ({
        disableFilters: true,
        disableSortBy: true,
    }), []);

    const columns = useMemo(() => {
        selectedCandidates.sort((a, b) => a.candidateId - selectedCandidateId !== 0 ? Math.abs(a.candidateId - b.candidateId) : -1);

        return [
            {
                Header: "",
                accessor: "title",
                sticky: "left",
                width: 100,
                maxWidth: 100,
            },
            ...selectedCandidates.map(d => ({
                Header: () => (
                    <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                    {
                        selectedCandidateId === d.candidateId &&
                        <RadioButton
                            name="selectCandidate"
                            value={d.candidateId}
                            disabled={true}
                            checked={d.candidateId === selectedCandidateId}
                        />
                    }
                        <span className={`text-left${selectedCandidateId === d.candidateId ? " ml-2" : ""}`}>{d.candidateName} ({d.candidateId})</span>
                    </div>
                ),
                accessor: `${d.candidateId}`,
                Cell: ({ row: { original } }) => {
                    if (!original[d.candidateId])
                        return (
                            <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                                <span className="text-left">-</span>
                            </div>
                        );

                    return (
                        <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                            <span className="text-left text-break">{original[d.candidateId] ?? "N/A"}</span>
                        </div>
                    );
                },
                minWidth: 200,
                sticky: d.candidateId === selectedCandidateId ? "left" : null,
            }))
        ]
    }, [selectedCandidates, selectedCandidateId]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout,
        useBlockLayout,
        useSticky,
    );

    // useEffect(() => console.log({rows}), [rows]);

    const content = useMemo(() => {
        return (
            <>
                <Row className="m-0 p-0 w-100">
                    <Col lg={12} className="m-0 p-0 d-flex flex-wrap justify-content-start align-items-center">
                        <span className="font-size-small-custom">
                            <i className="fas fa-info-circle mr-1" />Data will be merged into the selected talent.<br />
                            <i className="fas fa-info-circle mr-1" />The list below is only a sample data (maximum of 10 data shown).
                        </span>
                    </Col>
                </Row>
                <Row className="m-0 p-0 mt-3 w-100">
                    <Grid
                        height={'calc(100vh - 464px)'}
                        totalRecords={rows.length}
                        pageSize={rows.length}
                        rows={rows}
                        tableProps={getTableProps()}
                        headerGroups={headerGroups.map(h => {
                            const headerGroupProps = h.getHeaderGroupProps();
                            headerGroupProps.style = {...headerGroupProps.style, width: "100%"}

                            return {
                                ...h,
                                getHeaderGroupProps: () => headerGroupProps
                            };
                        })}
                        tableBodyProps={getTableBodyProps()}
                        prepareRow={(row) => {
                            prepareRow(row);
                            const rowProps = row.getRowProps();
                            row.getRowProps = () => {
                                return {
                                    ...rowProps,
                                    style: {
                                        ...rowProps.style,
                                        width: "100%",
                                    },
                                };
                            };

                            row.cells = row.cells.map(c => {
                                const columnId = parseInt(c.column.id);
                                const cellProps = c.getCellProps();
                                return {
                                    ...c,
                                    getCellProps: () => ({
                                        ...cellProps,
                                        style: {
                                            ...cellProps.style,
                                            backgroundColor: columnId === selectedCandidateId ? "#c2deff" : c.column.id === "title" ? "#fff" : "inherit",
                                            borderBottomWidth: row.original.isEndRow ? "0.15rem" : "inherit",
                                            borderBottomColor: row.original.isEndRow ? "#bbb" : "inherit",
                                            borderBottomStyle: row.original.isEndRow ? "solid" : "inherit",
                                        }
                                    })
                                };
                            });
                        }}
                        fullscreen={true}
                    />
                </Row>
            </>
        );
    }, [rows, getTableProps, headerGroups, getTableBodyProps, prepareRow, selectedCandidateId]);

    const footer = useMemo(() => (
        <>
            <Button
                color="secondary"
                onClick={onClose}
                type="button"
            >
                Close
            </Button>
            <Button
                color="info"
                onClick={() => onBackClick()}
                type="button"
            >
                <i className="fas fa-chevron-left mr-2" />Back: Data Review - Job Order Applications
            </Button>
            <Button
                color="info"
                onClick={() => onNextClick(selectedCandidates, selectedCandidateId)}
                type="button"
            >
                Next: Data Compare - Documents<i className="fas fa-chevron-right ml-2" />
            </Button>
        </>
    ), [onClose, onBackClick, onNextClick, selectedCandidates, selectedCandidateId]);

    return (
        <TemplateDialog
            title={title}
            loading={loading}
            content={content}
            footer={footer}
            onClose={onClose}
        />
    );
};

export default JobOrderAssignmentDialog;
