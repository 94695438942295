import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import Loader from '../../../loaders';
import api from "../../../../services/api";
import { DatePicker } from '../../../date-pickers';
import { formatDate, formatDateZeroTime } from '../../../../utils';
import SweetAlert from 'react-bootstrap-sweetalert';
import { IndeterminateCheckbox } from '../../../react-table/controls';
import { useMsal } from '@azure/msal-react';
import { roles as existingRoles } from '../../../../auth/roles';
import _ from 'lodash';
import moment from 'moment';

export default function DispatchUnitEditor(props) {
    const {
        dispatchUnitId,
        onClose,
        onSaved,
        onDeleted
    } = props;

    const [ itemInEdit, setItemInEdit ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ validationAlert, setValidationAlert ] = useState(null);
    const [ confirmationAlert, setConfirmationAlert ] = useState(null);
    const [ relatedModules, setRelatedModules ] = useState(null);
    
    const { instance } = useMsal();
    const { idTokenClaims: { roles } } = instance.getActiveAccount();

    const [ isSuperAdmin, setIsSuperAdmin ] = useState(_.some(roles, o => existingRoles.SuperAdmin === o));

    useEffect(() => {
        setIsSuperAdmin(_.some(roles, o => existingRoles.SuperAdmin === o));
    }, [roles]);

    const loadData = useCallback(() => {
        setLoading(true);

        api.get(`dispatchunit/id/${dispatchUnitId}`)
            .then((response) => {
                console.log(response);
                setItemInEdit(response.data);
            })
            .catch((error) => console.log({error}))
            .finally(() => setLoading(false))
        ;
    }, [dispatchUnitId]);

    useEffect(() => {
        if (!dispatchUnitId)
            return;

        loadData();
    }, [dispatchUnitId]);

    const handleSave = useCallback(() => {
        if (!itemInEdit || !itemInEdit.dispatchUnitId)
        {
            setValidationAlert({
                message: "Data not found."
            });
            return;
        }

        if (!itemInEdit.dispatchUnitName)
        {
            setValidationAlert({
                message: "Dispatch Name cannot be empty."
            });
            return;
        }
        
        if (!itemInEdit.quoteNumber)
        {
            setValidationAlert({
                message: "Quote Number cannot be empty."
            });
            return;
        }
        
        if (!itemInEdit.startDate)
        {
            setValidationAlert({
                message: "Start Date cannot be empty."
            });
            return;
        }
        
        if (!itemInEdit.endDate)
        {
            setValidationAlert({
                message: "End Date cannot be empty."
            });
            return;
        }

        setLoading(true);

        api.put(`dispatchunit/${itemInEdit.dispatchUnitId}`, JSON.stringify(itemInEdit))
            .then((response) => {
                setLoading(false);
                const { data } = response;

                if (data.errorType)
                {
                    setValidationAlert({
                        message: data.message
                    });
                    return;
                }

                onSaved(data);
            })
            .catch((error) => {
                setLoading(false);
                console.log({error});
            })
    }, [itemInEdit]);

    const handleConfirmRemoval = useCallback(() => {
        setLoading(true);

        const apiCalls = [
            api.get(`recruitmentrequest/dispatchunit/${itemInEdit.dispatchUnitId}`),
            api.get(`communication/dispatchlog/${itemInEdit.dispatchUnitId}`),
            api.get(`rostercandidateaccommodation/dispatchaccommodations?dispatchunitid=${itemInEdit.dispatchUnitId}`),
            api.get(`rostercandidateaccommodation/dispatchflights?dispatchunitid=${itemInEdit.dispatchUnitId}`)
        ];

        Promise.all(apiCalls)
            .then((responses) => {
                const lrfs = responses[0].data;
                const messageLogs = responses[1].data;
                const accommodations = responses[2].data;
                const flights = responses[3].data;
                console.log({
                    lrfs,
                    messageLogs,
                    accommodations,
                    flights
                });

                setRelatedModules({
                    lrfs,
                    messageLogs,
                    accommodations,
                    flights
                });
                setConfirmationAlert({
                    type: "Removal"
                });
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [itemInEdit]);

    const handleRemove = useCallback(() => {
        setLoading(true);

        api.delete(`dispatchunit/${itemInEdit.dispatchUnitId}`)
            .then(() => {
                setRelatedModules(null);
                setConfirmationAlert(null);
                setLoading(false);

                if (onDeleted)
                    onDeleted();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [itemInEdit, onDeleted]);

    const removalConfirmation = useMemo(() => {
        return (
            <SweetAlert
                title="Dispatch Unit Removal Confirmation"
                warning
                showCancel
                cancelBtnText="Cancel"
                confirmBtnText="Yes"
                confirmBtnBsStyle="default"
                onConfirm={() => handleRemove()}
                onCancel={() => {
                    setRelatedModules(null);
                    setConfirmationAlert(null);
                }}
                customClass="dispatch-confirm-dialog"
                closeOnClickOutside={false}
            >
                <h5>Are you sure you want to remove Dispatch {itemInEdit?.dispatchUnitName}?</h5>
                <div className="w-100 px-2" style={{maxHeight: "35vh", overflowY: "scroll"}}>
                {
                    relatedModules?.lrfs?.length > 0 ?
                    <>
                        <div className="w-100 d-flex flex-wrap justify-content-start align-items-center mb-2">
                            <span className="font-weight-bold text-left" style={{fontSize: "1rem"}}>Recruitment Requests:</span>
                        </div>
                    {
                        relatedModules?.lrfs?.map((r, idx) => {
                            return (
                                <div className="w-100 d-flex flex-wrap justify-content-start align-items-center" key={`lrf_${idx}`}>
                                <span className="text-left" style={{fontSize: "1rem"}}>
                                    {r.recruitmentRequestNumber}<i className="fas fa-chevron-right mx-2" style={{fontSize: "0.85rem"}} />Position: {r.positionName}<i className="fas fa-minus mx-2" style={{fontSize: "0.85rem"}} />Qty: {r.quantityRequired}<i className="fas fa-minus mx-2" style={{fontSize: "0.85rem"}} />Talent: {r.candidateName ?? "-"}
                                </span>
                                </div>
                            );
                        })
                    }
                    </>
                    :
                    null
                }
                {
                    relatedModules?.messageLogs?.length > 0 ?
                    <>
                        <div className="w-100 d-flex flex-wrap justify-content-start align-items-center mt-3 mb-2">
                            <span className="font-weight-bold text-left" style={{fontSize: "1rem"}}>Message Logs:</span>
                        </div>
                    {
                        relatedModules?.messageLogs?.map((r, idx) => {
                            return (
                                <div className="w-100 d-flex flex-wrap justify-content-start align-items-center" key={`msg_log_${idx}`}>
                                <span className="text-left" style={{fontSize: "1rem"}}>
                                    {r.messageType}<i className="fas fa-chevron-right mx-2" style={{fontSize: "0.85rem"}} />Talent: {r.talentName}<i className="fas fa-minus mx-2" style={{fontSize: "0.85rem"}} />Sent Date: {moment(r.sentDate).format("DD-MM-YYYY HH:mm:ss")}<i className="fas fa-minus mx-2" style={{fontSize: "0.85rem"}} />PIC: {r.actionUser ?? "-"}
                                </span>
                                </div>
                            );
                        })
                    }
                    </>
                    :
                    null
                }
                {
                    relatedModules?.accommodations?.length > 0 ?
                    <>
                        <div className="w-100 d-flex flex-wrap justify-content-start align-items-center mt-3 mb-2">
                            <span className="font-weight-bold text-left" style={{fontSize: "1rem"}}>Accommodations:</span>
                        </div>
                    {
                        relatedModules?.accommodations?.map((r, idx) => {
                            return (
                                <div className="w-100 d-flex flex-wrap justify-content-start align-items-center" key={`accmdn_${idx}`}>
                                <span className="text-left" style={{fontSize: "1rem"}}>
                                    {r.accommodation?.accommodationName}<i className="fas fa-chevron-right mx-2" style={{fontSize: "0.85rem"}} />Slot No: {`${r.rosterCandidate?.relatedRosterCandidateNumber ? `${r.rosterCandidate?.relatedRosterCandidateNumber}.` : ""}${r.rosterCandidate?.rosterCandidateNumber}`}<i className="fas fa-minus mx-2" style={{fontSize: "0.85rem"}} />Start/End Date: {`${r.startDate ? moment(r.startDate).format("DD-MM-YYYY") : "-"} - ${r.endDate ? moment(r.endDate).format("DD-MM-YYYY") : "-"}`}<i className="fas fa-minus mx-2" style={{fontSize: "0.85rem"}} />Talent: {r.rosterCandidate?.candidate?.candidateName ?? "-"}<i className="fas fa-minus mx-2" style={{fontSize: "0.85rem"}} />Status: {r.accommodationStatus?.lookupValue}
                                </span>
                                </div>
                            );
                        })
                    }
                    </>
                    :
                    null
                }
                {
                    relatedModules?.flights?.length > 0 ?
                    <>
                        <div className="w-100 d-flex flex-wrap justify-content-start align-items-center mt-3 mb-2">
                            <span className="font-weight-bold text-left" style={{fontSize: "1rem"}}>Flights:</span>
                        </div>
                    {
                        relatedModules?.flights?.map((r, idx) => {
                            return (
                                <div className="w-100 d-flex flex-wrap justify-content-start align-items-center" key={`accmdn_${idx}`}>
                                <span className="text-left" style={{fontSize: "1rem"}}>
                                    Slot No: {`${r.rosterCandidateSchedule?.rosterCandidate?.relatedRosterCandidateNumber ? `${r.rosterCandidateSchedule?.rosterCandidate?.relatedRosterCandidateNumber}.` : ""}${r.rosterCandidateSchedule?.rosterCandidate?.rosterCandidateNumber}`}<i className="fas fa-minus mx-2" style={{fontSize: "0.85rem"}} />Departure Date: {`${r.flightDateTime ? moment(r.flightDateTime).format("DD-MM-YYYY HH:mm") : "-"}`}<i className="fas fa-minus mx-2" style={{fontSize: "0.85rem"}} />Talent: {r.rosterCandidateSchedule?.rosterCandidate?.candidate?.candidateName ?? "-"}<i className="fas fa-minus mx-2" style={{fontSize: "0.85rem"}} />Status: {r.flightStatus?.lookupValue}
                                </span>
                                </div>
                            );
                        })
                    }
                    </>
                    :
                    null
                }
                </div>
            </SweetAlert>
        );
    }, [itemInEdit, relatedModules, handleRemove]);

    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
            centered
        >
        {
            (loading) && <Loader />
        }
        {
            validationAlert &&
            <SweetAlert
                title="Error"
                error
                confirmBtnText="OK"
                confirmBtnBsStyle="danger"
                onConfirm={() => setValidationAlert(null)}
            >
                {validationAlert.message}
            </SweetAlert>
        }
        {
            confirmationAlert?.type === "Removal" && relatedModules ?
            removalConfirmation
            :
            null
        }
            <div
                className="modal-header"
            >
                <h5 className="modal-title">Dispatch Editor - {itemInEdit?.dispatchUnitName}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle"/></span>
                </button>
            </div>
            <div className="modal-body">
                <Container fluid>
                    <Row>
                        <Col
                            sm={12} md={6}
                        >
                            <FormGroup>
                                <Label for="dispatchUnitName">
                                    Dispatch Name
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    name="dispatchUnitName"
                                    id="dispatchUnitName"
                                    value={itemInEdit?.dispatchUnitName ?? ""}
                                    onChange={(e) => setItemInEdit({ ...itemInEdit, dispatchUnitName: e.target.value })}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                            sm={12} md={6}
                        >
                            <FormGroup>
                                <Label for="locationName">
                                    Location
                                </Label>
                                <Input
                                    type="text"
                                    name="locationName"
                                    id="locationName"
                                    disabled={true}
                                    placeholder={itemInEdit?.location?.lookupValue ?? ""}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                            sm={12} md={6}
                        >
                            <FormGroup>
                                <Label for="quoteNumber">
                                    Quote Number
                                    <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    name="quoteNumber"
                                    id="quoteNumber"
                                    value={itemInEdit?.quoteNumber ?? ""}
                                    onChange={(e) => setItemInEdit({ ...itemInEdit, quoteNumber: e.target.value })}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                            sm={12} md={6}
                        >
                            <FormGroup>
                                <Label for="projectName">
                                    Project
                                </Label>
                                <Input
                                    type="text"
                                    name="projectName"
                                    id="projectName"
                                    disabled={true}
                                    placeholder={itemInEdit?.project?.projectName ?? ""}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                            sm={12} md={6}
                        >
                            <FormGroup>
                                <Label for="startDate">
                                    Start Date
                                    <span className="text-danger">*</span>
                                </Label>
                                <DatePicker
                                    name="startDate"
                                    id="startDate"
                                    type="text"
                                    required
                                    value={formatDate(itemInEdit?.startDate)}
                                    onChange={(value) => !value ? null : setItemInEdit({ ...itemInEdit, startDate: formatDate(value, "DD-MM-YYYY") })}
                                    closeOnSelect
                                />
                            </FormGroup>
                        </Col>
                        <Col
                            sm={12} md={6}
                        >
                            <FormGroup>
                                <Label for="endDate">
                                    End Date
                                    <span className="text-danger">*</span>
                                </Label>
                                <DatePicker
                                    name="endDate"
                                    id="endDate"
                                    type="text"
                                    required
                                    value={formatDate(itemInEdit?.endDate)}
                                    onChange={(value) => !value ? null : setItemInEdit({ ...itemInEdit, endDate: formatDate(value, "DD-MM-YYYY") })}
                                    closeOnSelect
                                />
                            </FormGroup>
                        </Col>
                        <Col
                            sm={12} md={6}
                        >
                            <FormGroup>
                                <IndeterminateCheckbox
                                    id="isArchived"
                                    type="checkbox"
                                    onChange={() => {
                                        setItemInEdit({...itemInEdit, isArchived: !itemInEdit.isArchived});
                                    }}
                                    checked={itemInEdit?.isArchived ?? false}
                                    customTitle="Archived"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer w-100"
            >
                <Container fluid>
                    <Row className="w-100 m-0">
                        <Col lg="6" className="d-flex flex-wrap justify-content-start align-items-center m-0 p-0">
                        {
                            isSuperAdmin ?
                            <Button
                                color="default"
                                onClick={handleConfirmRemoval}
                                type="button"
                            >
                                Remove Dispatch Unit
                            </Button>
                            :
                            null
                        }
                        </Col>
                        <Col lg="6" className="d-flex flex-wrap justify-content-end align-items-center m-0 p-0">
                            <Button
                                color="secondary"
                                onClick={onClose}
                                type="button"
                            >
                                Close
                            </Button>
                            <Button
                                color="info"
                                onClick={handleSave}
                                type="button"
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Modal>
    );
}