import React, { useCallback, useEffect } from 'react';
import {
    Label, FormGroup, Button, Modal, Container, Row, Col, Alert
 } from "reactstrap"; 
import _, { forEach } from "lodash";
import Loader from "../../loaders";
import api from "../../../services/api";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { debounce } from "lodash";
import '@progress/kendo-theme-bootstrap/dist/all.css';
import Multiselect from 'multiselect-react-dropdown';
import { ErrorNotification, DuplicateNotification } from '../../alerts';
export default function Setup(props) {

    const [ tnaSetup, setTnaSetup ] = React.useState({ ...props.item });
    const [ loading, setLoading ] = React.useState(false);
    const [ loadingPositions, setLoadingPositions ] = React.useState(false);
    const [ loadingTraining, setLoadingTraining ] = React.useState(false);
    const [ positions, setPositions ] = React.useState([]);
    const [ training, setTraining ] = React.useState([]);
    const [ lastSelectedId, setLastSelectedId] = React.useState([]);
    // const [ validationAlert, setValidationAlert ] = React.useState([]);

    // Error message variables
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [errorTitle, setErrorTitle] = React.useState(null);
    const [errorNotification, setErrorNotification] = React.useState([]);
    const [errorMessageOpening, setErrorMessageOpening] = React.useState(`Error occured when processing the request: `);
    const [errorTitleOnGenerate, setErrorTitleOnGenerate] = React.useState(`Contract Template File Error`);

    // Duplicate notification
    const [duplicateNotification, setDuplicateNotification] = React.useState([]);

    useEffect(() => {
        //getTraining("", 1);
        getPositions("");
    }, []);

    useEffect(() => {
        console.log('tnaSetup.groups', tnaSetup.groups);
    }, [tnaSetup.groups]);

    useEffect(() => {
        console.log('lastSelectedId', lastSelectedId);
    }, [lastSelectedId]);

    const validateSave = () => {
        try {
            // Validate data
            if (!tnaSetup) throw 'tnaSetup not found';

            if (!tnaSetup.groups) throw 'Groups not found';

            //var groupList = Object.values(tnaSetup.groups);
            //groupList.filter((w,i) => w.skills.filter())


            // Flatten object with simple detail
            const existingItems = _.flatten(Object.entries(tnaSetup.groups).map(([k, v]) => [...v.skills.map(o => {
                return {
                    trainingMasterId: o.trainingMasterId,
                    trainingMasterSkillId: o.trainingMasterSkillId
                }
            })]));

            // Flatten object with complete detail
            const existingItemsCompleteDetail = _.flatten(Object.entries(tnaSetup.groups).map(([k, v]) => [...v.skills.map(o => {
                return {
                    trainingMasterId: o.trainingMasterId,
                    trainingMasterSkillId: o.trainingMasterSkillId,
                    trainingTitle: o.trainingTitle,
                    trainingGroupName: v.trainingGroupName,
                    trainingGroupId: v.trainingGroupId
                }
            })]));

            // Sorting item to filter duplicate faster
            existingItems.sort((a, b) => {
                if (a.trainingMasterId < b.trainingMasterId) {
                    return -1;
                }
                if (a.trainingMasterId > b.trainingMasterId) {
                    return 1;
                }
                if (a.trainingMasterSkillId < b.trainingMasterSkillId) {
                    return -1;
                }
                if (a.trainingMasterSkillId > b.trainingMasterSkillId) {
                    return 1;
                }
                return 0;
            });

            // Checking value
            //console.log('existingItems', existingItems);

            // Check for duplicates
            var groupedSkill = []
            groupedSkill = existingItems.reduce((accumulatedValue, currentValue) => {

                // Copy new duplicate value
                var newVal = { ...currentValue };

                // Add count if any
                if ('count' in newVal) {
                    newVal.count++;
                }
                else {
                    newVal.count = 1;
                }

                // Find existing duplicate
                var existingSkill = accumulatedValue.find(w =>
                    w.trainingMasterId == currentValue.trainingMasterId &&
                    w.trainingMasterSkillId == currentValue.trainingMasterSkillId);

                if (existingSkill) {
                    existingSkill.count++;
                }
                else {
                    accumulatedValue.push(newVal);
                }

                return accumulatedValue;

            }, []);

            // Filter duplicate data 
            // where count > 1
            var duplicateSkill = [];
            duplicateSkill = groupedSkill.filter(w => w.count > 1);
            if (duplicateSkill && duplicateSkill.length > 0) {

                // Remove loading screen
                setLoading(false);

                // Foreach duplicate item
                //var duplicateToShow = [];
                //for (var i = 0; i < duplicateSkill.length; i++) {

                //    // Get detailed item, where is duplicate, and where to duplicate
                //    var duplicateItem = duplicateSkill[i];
                //    var duplicateLocations = existingItemsCompleteDetail.filter(w =>
                //        w.trainingMasterId == duplicateItem.trainingMasterId &&
                //        w.trainingMasterSkillId == duplicateItem.trainingMasterSkillId);

                //    // If data after recheck is not duplicate then skip it
                //    if (duplicateLocations.length < 2) {
                //        continue;
                //    }

                //    // Accumulate data to show it after this
                //    var oneDuplicateToShow = {
                //        id: i,
                //        title: duplicateLocations[0].trainingTitle,
                //        firstDuplicate: duplicateLocations[0].trainingGroupName,
                //        secondDuplicate: duplicateLocations[1].trainingGroupName
                //    }

                //    duplicateToShow.push(oneDuplicateToShow);
                //}

                // Foreach duplicate item
                var duplicateToShow2 = [];
                for (var i = 0; i < duplicateSkill.length; i++) {

                    // Get detailed item, where is duplicate, and where to duplicate
                    var duplicateItem = duplicateSkill[i];
                    var duplicateLocations = existingItemsCompleteDetail.filter(w =>
                        w.trainingMasterId == duplicateItem.trainingMasterId &&
                        w.trainingMasterSkillId == duplicateItem.trainingMasterSkillId);

                    // If data after recheck is not duplicate then skip it
                    if (duplicateLocations.length < 2) {
                        continue;
                    }

                    var duplicateSourceList = duplicateLocations.map((item) => { return item.trainingGroupName });

                    // Accumulate data to show it after this
                    var oneDuplicateToShow = {
                        id: i,
                        duplicateItem: duplicateLocations[0].trainingTitle,
                        duplicateSourceList: duplicateSourceList,
                    }

                    duplicateToShow2.push(oneDuplicateToShow);
                }

                // Prepare vars and show error message
                /*var events = {
                    onConfirm: () => setDuplicateNotification([]),
                    showList: true,
                    data: duplicateToShow,
                    column1duplicateDataTitle: `Skill Name${duplicateToShow.length > 1 ? 's' : ''}`
                }*/
                var events = {
                    onConfirm: () => setDuplicateNotification([]),
                    showList2: true,
                    data2: duplicateToShow2,
                    desc: `The following skill is assigned multiple times. Please review and remove the duplication.`,
                }
                setDuplicateNotification([<DuplicateNotification {...events} />]);


                // Return false if duplcate found
                return false;
            }

            // If duplicate not found then return true
            return true;
        }
        catch (error) {
            setLoading(false);
            setErrorMessage(`${errorMessageOpening}${error.message}`);
            return false;
        }
    }
    const handleSave = () => {
        try {
            setLoading(true);

            // console.log({tnaSetup, groups: _.values(tnaSetup.groups)})

            // Do validation
            var isCorrect = validateSave();
            if (!isCorrect) {
                setLoading(false);
                return;
            }

            // Prepare json data to send
            const jsonData = JSON.stringify({
                positions: tnaSetup.positions,
                groups: _.values(tnaSetup.groups)
            })

            // Send data
            api.put(`/trainingneedanalysis/setup/${props.projectId}`, jsonData)
                .then((response) => {
                    setLoading(false);
                    props.onSaved();
                })
                .catch((error) => {
                    setLoading(false)
                    console.error(error);
                });
        }
        catch (error) {
            setLoading(false);
            setErrorMessage(`${errorMessageOpening}${error.message}`);
        }
    }

    const getPositions = React.useCallback(debounce(filter => {
        // if (!filter || filter.length === 0) return;

        setLoadingPositions(true);
        api.get(`/trainingneedanalysis/hrisposition?filter=${filter}`)
        .then(({data}) => {
            // setPositions([...data.map(d => ({...d, combinedName: `${d.hrispositionName} (${d.position.lookupValue})`}))]);
            setPositions(data);
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => setLoadingPositions(false));
    }, 200), []);

    const onPositionsFilterChange = (event) => {
        const filter  = event.filter.value;
        getPositions(filter);
    }

    const onPositionsFilterChangeMultiselect = (value) => {
        getPositions(value);
    }

    const onPositionsChange = (event) => {        
        setTnaSetup({ ...tnaSetup, positions: event.target.value });
    }

    const onPositionsChangeMultiselect = useCallback((values) => {       
        console.log(values);
        setTnaSetup({ ...tnaSetup, positions: values });
    }, [tnaSetup]);

    const getTraining = React.useCallback(debounce((filter, key) => {
        // if (!filter || filter.length < 3) return;

        const existingItems = _.flatten(Object.entries(tnaSetup.groups).map(([k, v]) => [...v.skills.map(o => {
            return {
                trainingMasterId: o.trainingMasterId,
                trainingMasterSkillId: o.trainingMasterSkillId
            }
        })]));

        setLoadingTraining(key);
        api.get(`/trainingneedanalysis/training?filter=${filter}`)
        .then(({data}) => {
            // console.log(existingItems);
            // console.log(data, data.filter(o => {
            //     console.log(o);
            //     return !existingItems.some(p => p.trainingMasterId === o.trainingMasterId && p.trainingMasterSkillId == o.trainingMasterSkillId);
            // }));
            setTraining(data.filter(o => !existingItems.some(p => p.trainingMasterId === o.trainingMasterId && p.trainingMasterSkillId == o.trainingMasterSkillId)));
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => setLoadingTraining(false));
    }, 200), [tnaSetup.groups]);

    useEffect(() => {
        if (!training || training.length == 0)
            getTraining('',1);

    }, [training]);


    const onTrainingFilterChange = (event) => {
        const filter  = event.filter.value;
        getTraining(filter, 1);
    }

    const onTrainingFilterChangeMultiselect = (value) => {
        //console.log('onTrainingFilterChangeMultiselect', onTrainingFilterChangeMultiselect);
        getTraining(value, 1);
    }

    const onTrainingRemove = (value, id) => {

        //console.log('remove', 'value', value);
        tnaSetup.groups[id].skills = value;
    }

    const onTrainingChange = (event, key) => {

        const groups = {
            ...tnaSetup.groups,
            [key]: { ...tnaSetup.groups[key], skills: event.target.value }
        }
        setTnaSetup({ ...tnaSetup, groups: groups })
    }

    const onTrainingChangeMultiselect = useCallback((selected, key) => {
        const groups = {
            ...tnaSetup.groups,
            [key]: { ...tnaSetup.groups[key], skills: selected }
        }
        setTnaSetup({ ...tnaSetup, groups: groups });
    }, [tnaSetup]);

    ////$('.multiselect-search')
    //$(".multiselect-search").blur(function () {
    //    console.log('.multiselect-search', 'onblur2');
    //    setTraining([]);
    //    //setTimeout(function () {
    //    //    element.setState({
    //    //        inputValue: ""
    //    //    });
    //    //}, 1);
    //});


    useEffect(() => {

        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        var events = {
            onConfirm: () => {
                setErrorMessage(null);
                setErrorTitle(null);
            },
            message: errorMessage,
            title: errorTitle
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage, errorTitle]);


    return <Modal
        isOpen={true}
        fullscreen={'true'}
        className="modal-fullscreen"
        modalClassName=""
    >
        <div
            className="modal-header"
        >
            <h5
                className="modal-title"
            >
                Title
            </h5>
            <button
                aria-label="Close"
                className="close"
                onClick={props.onClose}
                type="button"
            >
                <span aria-hidden={true}>x</span>
            </button>
        </div>
        <div
            className="modal-body"
        >
            {/* {validationAlert && validationAlert.length > 0 && <Alert color="warning" className="pa-0 d-flex justify-content-between">
                    <span className="alert-text">
                        <ul className="mb-0">
                            {
                                validationAlert.map((item, index) => <li key={index}>{item.message}</li>)
                            }
                        </ul>
                    </span>
                </Alert>} */}
            <Container fluid>
                {(loading) && <Loader />}
                {errorNotification.length > 0 && errorNotification}
                {duplicateNotification.length > 0 && duplicateNotification}
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <Label>
                                Positions
                            </Label>
                            {/* <MultiSelect
                                data={positions}
                                loading={loadingPositions}
                                textField="positionName"
                                dataItemKey="positionId"
                                placeholder="Type to search"
                                value={tnaSetup.positions}
                                filterable={true}
                                onFilterChange={onPositionsFilterChange}
                                onChange={onPositionsChange}
                                style={{ width: '100%' }}
                                onClose={() => setPositions([])}
                            /> */}
                            <Multiselect
                                options={positions}
                                loading={loadingPositions}
                                displayValue="hrispositionMasterName"
                                placeholder="Type to search"
                                selectedValues={tnaSetup.positions}
                                onSearch={onPositionsFilterChangeMultiselect}
                                onSelect={(selected) => {
                                    onPositionsChangeMultiselect(selected);
                                }}
                                showCheckbox={true}
                                showArrow={true}
                                closeOnSelect={true}
                            />
                        </FormGroup>
                    </Col>
                    {
                        Object.entries(tnaSetup.groups).map(([id, group]) => {

                            return <Col xs={12} key={id}>
                                <FormGroup>
                                    <Label>
                                        {group.trainingGroupName}
                                    </Label>
                                    {/* <MultiSelect
                                        data={training}
                                        loading={loadingTraining === id}
                                        textField="trainingTitle"
                                        dataItemKey="trainingMasterSkillId"
                                        placeholder="Type to search"
                                        value={tnaSetup.groups[id].skills}
                                        //value={tnaSetup.allTraining}
                                        //tags={tnaSetup.groups[id].skills ? tnaSetup.groups[id].skills.map(o => ({ text: o.trainingTitle, data: [{ ...o }] })) : []}
                                        filterable={true}
                                        onFilterChange={onTrainingFilterChange}
                                        onChange={(event) => onTrainingChange(event, id)}
                                        style={{ width: '100%' }}
                                        onClose={() => setTraining([])}
                                    /> */}
                                    {/* options={
                                        () => {
                                            console.log('training', training);
                                            return training;
                                        }
                                    }
                                    selectedValues={() => {
                                        console.log('id', id, 'tnaSetup.groups[id].skills', tnaSetup.groups[id].skills);
                                        return tnaSetup.groups[id].skills;
                                    }}*/}
                                    <Multiselect
                                        className="multiselect-search-group"
                                        id={`multiselect_groupid_${id}`}
                                        options={training}
                                        loading={loadingTraining === id}
                                        displayValue="trainingTitle"
                                        placeholder="Type to search"
                                        selectedValues={tnaSetup.groups[id].skills}
                                        onSearch={onTrainingFilterChangeMultiselect}
                                        onRemove={(value) => {
                                            onTrainingRemove(value, id);
                                        }}
                                        onSelect={(selected) => {
                                            onTrainingChangeMultiselect(selected, id);
                                        }}
                                        /*onCloseOptionList={() => {
                                            console.log('onCloseOptionList');
                                        }}
                                        onBlur={() => {
                                            console.log('onBlur');
                                            console.log('id', id);
                                            if (lastSelectedId != id) {
                                                setLastSelectedId(id);
                                                //setTraining([]);
                                            }
                                        }}
                                        onFocus={() => {
                                            console.log('onFocus');
                                            console.log('id', id);
                                            if (lastSelectedId != id) {
                                                setLastSelectedId(id);
                                                setTraining([]);
                                            }
                                        }}*/                                        showCheckbox={true}
                                        showArrow={true}
                                        groupBy="trainingMasterName"
                                        closeOnSelect={true}
                                        /*props={{

                                            onblur: () => {
                                                console.log('onblur3');
                                                //console.log('a', a);
                                                //console.log('b', b);
                                            },
                                            onchange: () => {
                                                console.log('test');
                                            }
                                        }}*/
                                    />
                                </FormGroup>
                            </Col>
                        })
                    }
                </Row>
            </Container>
        </div>
        <div
                className="modal-footer"
            >
                <Button
                    color="secondary"
                    onClick={props.onClose}
                    type="button"
                >
                    Close
                </Button>
                <Button
                    color="info"
                    onClick={handleSave}
                    type="button"
                >
                    Save Changes
                </Button>
            </div>
            <script>
            </script>
    </Modal>
}
/*
 
 document.getElementsByClassName('multiSelectContainer').forEach((item)=>{
     item.getElementsByClassName('searchBox').forEach((x)=>{
       console.log('x',x);
    });
 });

*/