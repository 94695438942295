import React from 'react';
import {
    Row, Col
 } from "reactstrap";
 import Pager from "./Pager";
import PropTypes from "prop-types";

function Grid(props)
{
    const {
        totalRecords,
        pageSize,
        rows,
        tableProps,
        headerGroups,
        tableBodyProps,
        prepareRow,
        onRowClick,
        onRowDoubleClick,
        onCellClick,
        onPagerChangePage,
        onPagerChangePageSize,
        needPaging,
        needHover,
        height = '200px',
        rowStyle,
        maxHeight = null,
        fullscreen = false,
    } = props;

    return (
        <>
            <Row className={fullscreen ? "w-100" : ""} style={{overflowX: "scroll", marginLeft: "0", marginRight: "0"}}>
                <Col style={{paddingLeft: "0", paddingRight: "0"}}>
                    <div {...tableProps} className="table table-hover sticky">
                        <div className="thead header">
                            {headerGroups.map((headerGroup, hg_idx) => (
                                <div {...headerGroup.getHeaderGroupProps()} className="tr" key={`header_group_${hg_idx}`}>
                                {headerGroup.headers.map((column, h_idx) => {
                                    // console.log(column);
                                    return (
                                        <div {...column.getHeaderProps()} className="th" key={`header_${h_idx}`}>
                                            {column.canGroupBy ? (
                                                <span {...column.getGroupByToggleProps()}>
                                                    {column.isGrouped ? <i className="fas fa-minus mr-1"></i> : <i className="fas fa-plus mr-1"></i>}
                                                </span>
                                            ) : null}

                                            <span {...(column.getSortByToggleProps ? column.getSortByToggleProps() : {})}>
                                                {column.render('Header')}
                                            </span>

                                            <span>
                                            {
                                                column.isSorted
                                                    ? column.isSortedDesc
                                                    ? <i className="fas fa-long-arrow-alt-up ml-1"></i>
                                                    : <i className="fas fa-long-arrow-alt-down ml-1"></i>
                                                    : ''
                                            }
                                            </span>

                                            <div className="mt-1">
                                                {column.canFilter ? column.render('Filter') : null}
                                            </div>

                                        </div>
                                    )
                                })}
                                </div>
                            ))}
                        </div>
                        <div {...tableBodyProps} style={{ height: height, maxHeight: maxHeight ? maxHeight : 'initial' }} className="body">
                            {rows.map((row, i) => {
                                prepareRow(row);
                                return (
                                <div {...row.getRowProps()}
                                    key={`row_${i}`}
                                    className={`tr${onRowClick || onCellClick ? " cursor-pointer" : ""} ${!!rowStyle && rowStyle(row)}`}
                                    onDoubleClick={() => onRowDoubleClick ? onRowDoubleClick(row) : null}
                                    onClick={() => onRowClick ? onRowClick(row) : null}
                                >
                                    {row.cells.map((cell, index) => {
                                        return <div 
                                            key={`cell-${i}-${index}`}
                                            {...cell.getCellProps()}
                                            className={`td text-wrap ${needHover ? "td-hover" : ""}`}
                                            onClick={onCellClick ? () => onCellClick(cell) : null}
                                        >
                                            {cell.render('Cell')}
                                        </div>
                                    })}
                                </div>
                                )
                            })}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>&nbsp;</Row>
            {
                needPaging &&
                <Pager 
                    totalItems={totalRecords ?? 0}
                    rowInPage={rows.length ?? 0}
                    pageSize={pageSize} 
                    onChangePage={onPagerChangePage}
                    onChangePageSize={onPagerChangePageSize}
                />
            }
        </>
    );
}

Grid.propTypes = {
    totalRecords: PropTypes.number,
    pageSize: PropTypes.number,
    rows: PropTypes.array.isRequired,
    tableProps: PropTypes.object.isRequired,
    headerGroups: PropTypes.array.isRequired,
    tableBodyProps: PropTypes.object.isRequired,
    prepareRow: PropTypes.func.isRequired,
    onRowClick: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
    onPagerChangePage: PropTypes.func,
    onPagerChangePageSize: PropTypes.func,
    needPaging: PropTypes.bool,
    needHover: PropTypes.bool,
}

Grid.defaultProps = {
    needPaging: false,
    needHover: false,
}

export default Grid;