import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import api from "../../../../services/api";
import TemplateDialog from "./TemplateDialog";
import { useBlockLayout, useFlexLayout, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import Grid from "../../../Grid";
import { Checkbox, RadioButton } from "@progress/kendo-react-inputs";
import moment from "moment";

function SpecificAttributeDialog(props) {
    const {
        title,
        selectedCandidates,
        selectedCandidateId,
        initialSelectedAttributes,
        onClose,
        onBackClick,
        onNextClick,
        direction,
    } = props;

    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState([]);
    const [ defaultAttributes, setDefaultAttributes ] = useState({});
    const [ selectedAttributes, setSelectedAttributes ] = useState(initialSelectedAttributes);
    const skipPageResetRef = useRef();

    // useEffect(() => console.log({initialSelectedAttributes}), [initialSelectedAttributes]);

    const loadData = useCallback(() => {
        skipPageResetRef.current = true;

        if (!selectedCandidates?.length)
            return;

        setLoading(true);

        api.post(`candidatemerge/compare/attributes`, JSON.stringify(selectedCandidates.map(c => c.candidateId)))
            .then((response) => {
                const {data: {data: responseData, attributes}} = response;

                if (!responseData.length)
                {
                    setLoading(false);

                    if (direction === 1)
                        onNextClick([]);
                    else
                        onBackClick();

                    return;
                }

                const transposedData = [];

                attributes.forEach(r => {
                    const row = {
                        rowCustomId: r.projectAttributeId,
                        title: r.projectAttributeName,
                        rowType: r.projectAttributeType.lookupValue,
                    };

                    responseData.filter(d => d.attributeId === row.rowCustomId).forEach(d => {
                        row[d.candidateId] = {
                            attributeId: d.candidateAttributeId,
                            label: row.rowType === "date" ? (d.value ? moment(d.value).format("DD-MM-YYYY") : null) : d.value,
                        }
                    });

                    transposedData.push(row);
                });
                // console.log({transposedData, currentAttributes});
                setData([...transposedData]);
                const currentAttributes = [];

                responseData.filter(d => d.candidateId === selectedCandidateId).forEach(d => {
                    currentAttributes.push(d.candidateAttributeId);
                });

                if (!initialSelectedAttributes)
                    setSelectedAttributes(currentAttributes);
                
                setDefaultAttributes(currentAttributes);
                setLoading(false);
            })
            .catch((error) => {
                console.log({error});
                setLoading(false);
            })
        ;
    }, [skipPageResetRef, api, selectedCandidateId, initialSelectedAttributes, onNextClick, onBackClick, direction]);

    useEffect(() => loadData(), [selectedCandidates]);

    const defaultColumn = useMemo(() => ({
        disableFilters: true,
        disableSortBy: true,
    }), []);

    const columns = useMemo(() => {
        selectedCandidates.sort((a, b) => a.candidateId - selectedCandidateId !== 0 ? Math.abs(a.candidateId - b.candidateId) : -1);

        return [
            {
                Header: () => (
                    <Button
                        size="sm"
                        color="primary"
                        onClick={() => setSelectedAttributes({...defaultAttributes})}
                    >
                        <i className="fas fa-sync mr-2" />Reset
                    </Button>
                ),
                accessor: "title",
                sticky: "left",
                width: 100,
                maxWidth: 100,
            },
            ...selectedCandidates.map(d => ({
                Header: () => (
                    <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                    {
                        selectedCandidateId === d.candidateId &&
                        <RadioButton
                            name="selectCandidate"
                            value={d.candidateId}
                            disabled={true}
                            checked={d.candidateId === selectedCandidateId}
                        />
                    }
                        <span className={`text-left${selectedCandidateId === d.candidateId ? " ml-2" : ""}`}>{d.candidateName} ({d.candidateId})</span>
                    </div>
                ),
                accessor: `${d.candidateId}`,
                Cell: ({ row: { original } }) => {
                    // console.log({
                    //     selectedCandidateId: selectedAttributes[original.rowCustomId],
                    //     currentCandidateId: d.candidateId,
                    //     checked: selectedAttributes[original.rowCustomId] === d.candidateId,
                    // });
                    return (
                        <div className="w-100 px-1 d-flex flex-row justify-content-start align-items-start">
                        {
                            original[d.candidateId] ?
                            <>
                                <RadioButton
                                    name={`${original.rowCustomId}`}
                                    value={original[d.candidateId].attributeId}
                                    onChange={(e) => {
                                        // console.log({e});
                                        setSelectedAttributes([...selectedAttributes, e.value]);
                                    }}
                                    checked={selectedAttributes.filter(c => c === original[d.candidateId].attributeId).length > 0}
                                />
                                <span className="text-left ml-2">{original[d.candidateId].label ?? "-"}</span>
                            </>
                            :
                            <span className="text-left">-</span>
                        }
                        </div>
                    );
                },
                minWidth: 200,
                sticky: d.candidateId === selectedCandidateId ? "left" : null,
            }))
        ]
    }, [selectedCandidates, selectedCandidateId, defaultAttributes, selectedAttributes]);

    // useEffect(() => {
    //     console.log({selectedAttributes});
    // }, [selectedAttributes]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout,
        useBlockLayout,
        useSticky,
    );

    // useEffect(() => console.log({rows}), [rows]);

    const content = useMemo(() => {
        return (
            <>
                <Row className="m-0 p-0 w-100">
                    <Col lg={12} className="m-0 p-0 d-flex flex-wrap justify-content-start align-items-center">
                        <span className="font-size-small-custom">
                            <i className="fas fa-info-circle mr-1" />Please select the Identifier to be merged.
                        </span>
                    </Col>
                </Row>
                <Row className="m-0 p-0 mt-3 w-100">
                    <Grid
                        height={'calc(100vh - 464px)'}
                        totalRecords={rows.length}
                        pageSize={rows.length}
                        rows={rows}
                        tableProps={getTableProps()}
                        headerGroups={headerGroups.map(h => {
                            const headerGroupProps = h.getHeaderGroupProps();
                            headerGroupProps.style = {...headerGroupProps.style, width: "100%"}

                            return {
                                ...h,
                                getHeaderGroupProps: () => headerGroupProps
                            };
                        })}
                        tableBodyProps={getTableBodyProps()}
                        prepareRow={(row) => {
                            prepareRow(row);
                            const rowProps = row.getRowProps();
                            row.getRowProps = () => {
                                return {
                                    ...rowProps,
                                    style: {
                                        ...rowProps.style,
                                        width: "100%",
                                    },
                                };
                            };

                            row.cells = row.cells.map(c => {
                                const columnId = parseInt(c.column.id);
                                const cellProps = c.getCellProps();
                                return {
                                    ...c,
                                    getCellProps: () => ({
                                        ...cellProps,
                                        style: {
                                            ...cellProps.style,
                                            backgroundColor: columnId === selectedCandidateId ? "#c2deff" : c.column.id === "title" ? "#fff" : "inherit",
                                        }
                                    })
                                };
                            });
                        }}
                        fullscreen={true}
                    />
                </Row>
            </>
        );
    }, [rows, getTableProps, headerGroups, getTableBodyProps, prepareRow, selectedCandidateId]);

    const footer = useMemo(() => (
        <>
            <Button
                color="secondary"
                onClick={onClose}
                type="button"
            >
                Close
            </Button>
            <Button
                color="info"
                onClick={() => onBackClick()}
                type="button"
            >
                <i className="fas fa-chevron-left mr-2" />Back: Data Review - Dispatch History
            </Button>
            <Button
                color="info"
                onClick={() => onNextClick(selectedAttributes)}
                type="button"
            >
                Next: Data Review - Inactive Dates<i className="fas fa-chevron-right ml-2" />
            </Button>
        </>
    ), [onClose, onBackClick, onNextClick, selectedAttributes]);

    return (
        <TemplateDialog
            title={title}
            loading={loading}
            content={content}
            footer={footer}
            onClose={onClose}
        />
    );
};

export default SpecificAttributeDialog;
