import React from 'react';
import api from "../services/api";
import {
    Label, Button, Modal, Container, Row, Col, Alert
 } from "reactstrap"; 
import Loader from "./loaders";
import { useDropzone } from 'react-dropzone';
import cn from "classnames";

export default function Import(props) {
    const {
        apiurl,
        needValidation,
    } = props;

    const [ itemInEdit, setItemInEdit ] = React.useState({file: null});
    const [ loading, setLoading ] = React.useState(false);
    const [ validationAlert, setValidationAlert ] = React.useState([]);
    const [ saveValidationPassed, setSaveValidationPassed ] = React.useState(needValidation ? false : true);

    const {
        getRootProps, 
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        maxFiles: 1,
        multiple: false,
        onDrop: ([file]) => {
            setItemInEdit( { ...itemInEdit, file } );
        }
    });

    const validate = React.useCallback((itemToCheck) => {
        const errors = [];

        if (!itemToCheck || !itemToCheck.file)
        {
            errors.push({ key: "file", type: "required", message:  "A file is required." });
            setValidationAlert(errors);
            return false;
        }
        
        return true;
    }, [validationAlert]);

    const files = React.useMemo(() => {
        let name, size, file;

        if (itemInEdit?.file) {
            name = itemInEdit.file.name;
            size = itemInEdit.file.size;
            file = itemInEdit.file;
        } else {
            return null;
        }
        return <aside className="dropzone-list pt-3">
            <Label>Files</Label>
            <ul>
                <li key={name} className="pt-1">
                    <Label>{name} - {size} bytes</Label>
                </li> 
            </ul>
        </aside>
    }, [itemInEdit.file]);

    React.useEffect(() => {
        setSaveValidationPassed(needValidation ? false : true);
    }, [needValidation, itemInEdit.file]);

    const dropzoneClasses = React.useMemo(() => cn({
        'dropzone': true,
        'mt-2': true,
        'active': isDragActive,
        'accept': isDragAccept,
        'reject': isDragReject
    }), [
        isDragActive,
        isDragAccept,
        isDragReject
    ]);

    const handleValidation = (item) => {
        if (!validate(item))
            return;

        setLoading(true);
        const url = `${apiurl}/validateimport`;
        const method = "POST";
        const formData = new FormData();
        
        if(item.file)
            formData.append("file", item.file);

        api({ url, method, data: formData })
        .then((response) => {
            setLoading(false);
            const result = response.data.result;
            setSaveValidationPassed(result);

            if(!result)
            {
                const errors = [];
                errors.push({key: response.data.key, type: response.data.errorType, message: response.data.message});
                setValidationAlert(errors);
                return;
            }

            setValidationAlert([]);
        })
        .catch((error) => {
            setLoading(false);
            console.error(error.response);
            const errors = [];
            errors.push({key: "system", type: "system", message: "System error."});
            setValidationAlert(errors);
        });
    }

    const handleSave = (item) => {
        if (!validate(item))
            return;

        setLoading(true);
        const url = `${apiurl}/import`;
        const method = "POST";
        const formData = new FormData();
        
        if(item.file)
            formData.append("file", item.file);

        api({ url, method, data: formData })
        .then((response) => {
            setLoading(false);
            props.onSaved();
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
        });
    }

    return (
        <Modal
            isOpen={true}
            className="modal-lg"
            modalClassName="db-example-modal-lg"
        >
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    {props.title ?? "Title"}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}>x</span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                {
                    validationAlert && validationAlert.length > 0 &&
                    <Alert color="warning" className="pa-0 d-flex justify-content-between">
                        <span className="alert-text">
                            <ul className="mb-0">
                                {
                                    validationAlert.map((item, index) => <li key={index}>{item.message}</li>)
                                }
                            </ul>
                        </span>
                    </Alert>
                }
                <Container>
                    {(loading) && <Loader />}
                    <Row>
                        <Col xs={12}>
                            <div {...getRootProps({className: dropzoneClasses})}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop a file here, or click to select a file. Allowed: Excel or XML (.xlsx or .xml)</p>
                            </div>
                            {files}
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer d-flex flex-wrap"
            >
                <Button
                    color="secondary"
                    onClick={props.onClose}
                    type="button"
                    className="mt-2"
                >
                    Close
                </Button>
                {
                    needValidation ?
                    <Button
                        color="warning"
                        onClick={() => handleValidation(itemInEdit)}
                        type="button"
                        className="mt-2"
                    >
                        Validate
                    </Button>
                    :
                    null
                }
                <Button
                    color="info"
                    onClick={() => handleSave(itemInEdit)}
                    type="button"
                    disabled={!saveValidationPassed}
                    className="mt-2"
                >
                    Save Changes
                </Button>
            </div>
        </Modal>
    )
}