import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import {
    Modal, Container, Row, Col, Button, Label
} from "reactstrap";
import api from "../../../services/api";
import { useDropzone } from 'react-dropzone';
import cn from "classnames";
import Loader from "../../loaders";
import SweetAlert from "react-bootstrap-sweetalert";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community';
import readXlsxFile from 'read-excel-file';
import { MakeWords } from '../../../helpers/StringHelper';
import { DateValueFormatter, MakeWordsValueGetter, ImportDialogMessageCellRenderer } from '../../../helpers/AgGridHelper';

// import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
// import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import _ from 'lodash';

import "../../../assets/css/ag-grid.css";
import "../../../assets/css/ag-theme-alpine.css";
import { TooltipButton } from '../../inputs';
import moment from 'moment';

const UPLOAD_MODE = "upload";
const VALIDATION_MODE = "validation";

export default function TcImportDialog(props) {
    const [loading, setLoading] = useState(false);
    const [importFiles, setImportFiles] = useState([]);
    const [alert, setAlert] = useState('');
    const [data, setData] = useState([]);
    const [validationData, setValidationData] = useState([]);
    const [mode, setMode] = useState(UPLOAD_MODE);
    const [validationAlert, setValidationAlert] = useState([]);
    const [openValidationAlert, setOpenValidationAlert] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [duplicateDataValidationAlert, setDuplicateDataValidationAlert] = useState([]);
    const apiurl = 'termandcondition';

    const defaultColumns = [
        {
            name: "id",
            title: "ID"
        },
        {
            name: "project",
            title: "Project"
        },
        {
            name: "employmentCategory",
            title: "Employment Category"
        },
        {
            name: "component",
            title: "Component"
        },
        {
            name: "value",
            title: "Value"
        },
        {
            name: "startDate",
            title: "Start Date"
        },
        {
            name: "endDate",
            title: "End Date"
        },
        {
            name: "location",
            title: "Location"
        },
        {
            name: "position",
            title: "Position"
        },
    ];

    const [columnDefs, setColumnDefs] = useState([...defaultColumns.map(c => ({
        field: c.name,
        headerName: c.title,
    }))]);
    const [rowBuffer, setRowBuffer] = useState(20);
    const gridRef = useRef();

    const onDrop = useCallback(acceptedFiles => setImportFiles([...acceptedFiles]), [importFiles]);

    const handleUpload = useCallback(() => {
        setLoading(true);

        const formData = new FormData();

        const file = importFiles[0];
        console.log('acceptedFiles', importFiles);
        formData.append('files', file, file.name);

        api({ url: `${apiurl}/import/${props.projectId}`, method: 'POST', data: formData })
            .then(response => {
                console.log('roster import', response);
                setAlert('success');
            })
            .catch((error) => {
                console.error(error);
                setAlert('error');
            })
            .finally(() => setLoading(false));

    }, [importFiles]);

    useEffect(() => {
        if (!data || data.length == 0) {
            setIsValid(true);
            return;
        }
        var isError = data.some((v, i, a) => v.status == false);
        setIsValid(isError == false);
    }, [data]);

    const handleFileSelected = useCallback(() => {
        setLoading(true);
        const file = importFiles[0];

        console.log('readXlsxFile');
        readXlsxFile(file)
            .then((rows) => {
                console.log({ rows });

                if (rows.length == 0 || rows[0].length < 9 || rows[0][0]?.toLowerCase() !== defaultColumns[0].title.toLowerCase()) {
                    setAlert({ type: "error", message: "Invalid data format." });
                    setImportFiles([]);
                    setData([]);
                    setValidationData([]);

                    return;
                }

                const columns = [...rows[0]];
                const newRows = [...rows];
                newRows.splice(0, 1);

                var gridColumns = [];
                const newData = [];
                const newValidationData = [];

                console.log('rows', rows);
                console.log('columns', columns);

                columns.forEach(c => {
                    const fieldName = defaultColumns.filter(d => d.title === c)?.length ? defaultColumns.filter(d => d.title === c)[0].name : c.replace(/\s+/g, "").replace(/[^A-Za-z]+/g, "");


                    var columnStaticState = {
                        field: `before.${fieldName}`,
                        headerName: `${c}`,
                    };
                    var columnBefore = {
                        field: `before.${fieldName}`,
                        headerName: `${c} - Before`,
                    };
                    var columnAfter = {
                        field: `after.${fieldName}`,
                        headerName: `${c} - After`,
                    };

                    // If column is date
                    // Then format it
                    if (fieldName && fieldName.toLowerCase().includes("date")) {
                        var addonForDate = {
                            filter: 'agDateColumnFilter',
                            valueFormatter: DateValueFormatter
                        };

                        columnBefore = { ...columnBefore, ...addonForDate };
                        columnAfter = { ...columnAfter, ...addonForDate };
                    }

                    // If column is ID
                    // Then dont use before after
                    else if (fieldName && fieldName.toLowerCase() == "id") {
                        gridColumns.push(columnStaticState);
                        return;
                    }


                    gridColumns.push(columnBefore);
                    gridColumns.push(columnAfter);
                });

                var columnSubfix = [
                    {
                        field: 'status',
                        headerName: `Validation Status`,
                        editable: false,
                        cellClass: "px-0 py-0 text-center",
                        cellRenderer: ImportDialogMessageCellRenderer,
                    },
                    {
                        field: 'messages',
                        headerName: `Validation Message`,
                        editable: false,
                        valueGetter: MakeWordsValueGetter,
                    }
                ];
                gridColumns = gridColumns.concat(columnSubfix);

                const validationColumns = [
                    "id",
                    "project",
                    "employment category",
                    "component",
                    "value",
                    "start date",
                    "end date",
                    "location",
                    "position",
                ];

                newRows.forEach((r, r_idx) => {
                    const row = {
                        before: {},
                        after: {},
                    };
                    const validationRow = {};
                    let rosterAttributeStart = false;

                    validationRow['rowId'] = r_idx;
                    validationRow['rowNo'] = r_idx + 1;
                    row['after']['rowId'] = r_idx;
                    row['after']['rowNo'] = r_idx + 1;

                    r.forEach((c, idx) => {
                        const columnName = defaultColumns.filter(d => d.title.toLowerCase() === columns[idx].toLowerCase())?.length ? defaultColumns.filter(d => d.title.toLowerCase() === columns[idx].toLowerCase())[0].name : columns[idx].replace(/\s+/g, "").replace(/[^A-Za-z]+/g, "");

                        row['after'][columnName] = c;

                        if (validationColumns.includes(columns[idx].toLowerCase())) {
                            validationRow[columnName] = c;
                        }


                    });

                    newData.push({ ...row });
                    newValidationData.push({ ...validationRow });
                });

                //setColumnDefs([...gridColumns]);
                //setData([...newData]);


                api.post(`/${apiurl}/validateimport`, JSON.stringify(newValidationData))
                    .then((response) => {
                        var { data: serverValidation } = response;

                        if (mode !== VALIDATION_MODE)
                            setMode(VALIDATION_MODE);

                        if (serverValidation?.length)
                            serverValidation.forEach(d => {
                                var selectedRow = newData.find(w => w.after && w.after.rowId == d.rowId);

                                if (selectedRow) {

                                    selectedRow['messages'] = d.messages;
                                    selectedRow['status'] = d.status;
                                    selectedRow['before'] = { ...d.data.before };
                                    selectedRow['after'] = { ...d.data.after };



                                }
                            });

                        //console.log('newData', newData);
                        //console.log('serverValidation', serverValidation);
                        //console.log('gridColumns', gridColumns);


                        setColumnDefs([...gridColumns]);
                        setValidationData([...newValidationData]);
                        setData([...newData]);
                        setValidated(false);
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.error({ error });
                        setLoading(false);
                    })

            })
            //.finally(() => setLoading(false))
            ;
    }, [importFiles]);

    useEffect(() => {
        if (!importFiles?.length)
            return;

        handleFileSelected();
    }, [importFiles]);

    useEffect(() => {
        const rosterCandidateDates = [];
        const newValidationData = [];

        if (!data?.length) {
            setColumnDefs([...defaultColumns.map(c => ({
                field: c.name,
                headerName: c.title,
            }))]);
            setCanSave(false);
            setValidationAlert([...newValidationData]);
            setDuplicateDataValidationAlert([...newValidationData]);
            return;
        }

        let newCanSave = true;
        let i = 0;

        while (i < data.length) {
            if (!rosterCandidateDates.filter(rc => rc.rosterCandidateId === data[i].rosterCandidateId)?.length) {
                rosterCandidateDates.push({
                    rosterCandidateId: data[i].rosterCandidateId,
                    startDate: data[i].workStart,
                    endDate: data[i].workEnd,
                });
                i++;
                continue;
            }

            if (!rosterCandidateDates.filter(rc => rc.rosterCandidateId === data[i].rosterCandidateId && rc.startDate === data[i].workStart && rc.endDate === data[i].workEnd)?.length) {
                i++;
                continue;
            }

            if (newCanSave)
                newCanSave = false;

            if (!newValidationData.filter(d => d.id === data[i].rosterCandidateId)?.length)
                newValidationData.push({
                    id: data[i].rosterCandidateId,
                    messages: []
                });

            const validation = newValidationData.filter(d => d.id === data[i].rosterCandidateId)[0];

            if (!validation.messages?.length)
                validation.messages.push("Start Date/End Date for each row with the same ID should be different.");

            i++;
        }

        // console.log({newCanSave, rosterCandidateDates});

        setValidationAlert([...newValidationData]);
        setDuplicateDataValidationAlert([...newValidationData]);
        setCanSave(newCanSave);
    }, [data]);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const dropzoneClasses = useMemo(() => cn({
        'dropzone': true,
        'mt-2': true,
        'active': isDragActive,
        'accept': isDragAccept,
        'reject': isDragReject
    }), [
        isDragActive,
        isDragAccept,
        isDragReject
    ]);

    const loadingOverlayComponent = useMemo(() => Loader, []);

    const defaultColDef = useMemo(() => ({
        sortable: false,
        resizable: true,
        filter: true,
        editable: false,
        filter: 'agSetColumnFilter',
        filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200
        },
    }), []);

    const files = useMemo(() =>
        importFiles.map(
            file => (
                <li key={file.path}>
                    {file.path} - {file.size} bytes
                </li>
            )

        ), [importFiles]);

    const resizeColumns = useCallback(() => {
        if (!gridRef?.current?.columnApi || data?.length === 0)
            return;

        const allColumnIds = [];
        gridRef.current.columnApi.getColumns().forEach((column) => {
            // console.log(column, column.getId());
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }, [data, gridRef]);

    const getRowId = useMemo(() => (params) => params.data.id, []);

    const onCellEditRequest = useCallback((event) => {
        console.log("on cell edit request: ", { event });
    }, []);

    const handleValidateData = useCallback(() => {
        if (!validationData?.length)
            return;

        setLoading(true);

        api.post(`/${apiurl}/validateimport`, JSON.stringify(validationData))
            .then((response) => {
                // console.log({response});

                if (mode !== VALIDATION_MODE)
                    setMode(VALIDATION_MODE);

                // console.log("check result: ", {check: response.data
                //     .filter(d => !d.messages?.length || !d.messages.filter(m => {
                //         console.log(m.toLowerCase().includes("start work date"), m.toLowerCase().includes("end work date"));
                //         return m.toLowerCase().includes("start work date") || m.toLowerCase().includes("end work date");
                //     }))?.length === 0}
                // );

                const newValidationData = [...duplicateDataValidationAlert];

                if (response.data?.length)
                    response.data.forEach(d => {
                        if (!newValidationData.filter(v => v.id === d.id)?.length) {
                            newValidationData.push({ ...d });
                            return;
                        }

                        const validation = newValidationData.filter(v => v.id === d.id)[0];
                        validation.messages = [...validation.messages, d.messages];
                    });

                setValidationAlert([...newValidationData]);
                setOpenValidationAlert(true);
                setValidated(true);
                setLoading(false);
            })
            .catch((error) => {
                console.error({ error });
                setLoading(false);
            })
    }, [validationData, mode, duplicateDataValidationAlert]);

    const handleImport = useCallback(() => {
        setLoading(true);

        const rosterAttributes = [];

        columnDefs.forEach(c => {
            if (defaultColumns.filter(d => d.name === c.field)?.length)
                return;

        });

        const postData = data.map((v, i, a) => {
            return { ...v.after };
        });

        //data.forEach(d => {
        //    var newRow = { ...d.after };
        //    postData.push(newRow);
        //});

        // console.log({rosterAttributes, postData});

        api.post(`${apiurl}/importraw`, postData)
            .then(response => {
                // console.log('roster import', response);
                setAlert({ type: "success" });
            })
            .catch((error) => {
                console.error({ error });
                setAlert({ type: "error", message: JSON.stringify(error.response?.data) });
            })
            .finally(() => setLoading(false));
    }, [data, columnDefs, importFiles]);

    return (
        <Modal
            isOpen={true}
            className="modal-xl"
            modalClassName="db-example-modal-xl"
        >
            {loading && <Loader />}
            <div
                className="modal-header"
            >
                <h5
                    className="modal-title"
                >
                    Import Terms & Conditions
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    onClick={props.onClose}
                    type="button"
                >
                    <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                </button>
            </div>
            <div
                className="modal-body"
            >
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div {...getRootProps({ className: dropzoneClasses })}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop a file here, or click to select a file</p>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <aside className="dropzone-list pt-3">
                                <Label>File</Label>
                                <ul>{files}</ul>
                            </aside>
                        </Col>
                    </Row>
                    <Row className="w-100 mx-0 mt-3">

                        {
                            data?.length > 0 && !isValid &&
                            <Col xs={12} className="d-flex flex-wrap justify-content-end align-items-center px-0 mb-2">
                                <span className="text-warning font-italic">Please check the invalid data below.</span>
                            </Col>
                        }
                        {
                            data?.length > 0 && isValid &&
                            <Col xs={12} className="d-flex flex-wrap justify-content-end align-items-center px-0 mb-2">
                                <span className="text-success font-italic">Your data are ok, click Upload to continue.</span>
                            </Col>
                        }
                        <Col xs={12} className="d-flex flex-wrap justify-content-center align-items-center px-0">
                            <div className="ag-theme-alpine" style={{ width: '100%', height: data.length ? (mode === UPLOAD_MODE ? "44vh" : "40vh") : 75 }}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={data}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    loadingOverlayComponent={loadingOverlayComponent}
                                    suppressColumnVirtualisation={false}
                                    suppressRowVirtualisation={false}
                                    debounceVerticalScrollbar={false}
                                    rowBuffer={20}
                                    headerHeight={40}
                                    groupHeaderHeight={22}
                                    rowHeight={22}
                                    // readOnlyEdit={true}
                                    onRowDataUpdated={() => resizeColumns()}
                                    suppressDragLeaveHidesColumns={true}
                                    suppressColumnMoveAnimation={true}
                                    animateRows={false}
                                    onCellEditRequest={onCellEditRequest}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div
                className="modal-footer d-flex flex-wrap justify-content-between align-items-center w-100"
            >
                <div className="d-flex flex-wrap justify-content-start align-items-center">
                    <Button
                        color="secondary"
                        onClick={props.onClose}
                        type="button"
                    >
                        Close
                    </Button>
                </div>
                <div className="d-flex flex-wrap justify-content-end align-items-center">
                    <Button
                        color="default"
                        onClick={() => {
                            handleImport();
                        }}
                        type="button"
                        disabled={!data || data.length == 0 || isValid == false}
                    >
                        <i className="fas fa-paper-plane mr-2" />Upload
                    </Button>
                </div>
            </div>
            {
                alert &&
                <SweetAlert
                    title={alert.type.toLowerCase() === 'error' ? 'Error' : 'Success'}
                    error={alert.type.toLowerCase() === 'error'}
                    confirmBtnText="OK"
                    confirmBtnBsStyle={alert.type.toLowerCase() === 'error' ? "danger" : "success"}
                    onConfirm={() => {
                        if (alert.type.toLowerCase() === "error") {
                            setAlert(null);
                            return;
                        }

                        props.loadData();
                        props.onClose();
                    }}
                >
                    {
                        alert.type.toLowerCase() == 'error' ? alert.message : 'File successfully uploaded'
                    }
                </SweetAlert>
            }

        </Modal>
    )
}