import React, { useEffect } from 'react';
import api from "../../../services/api";
import qs from "qs";
import {
    Container, Row, Col, Card, CardBody, CardTitle, FormGroup, Label, Collapse
} from "reactstrap";
import { ComboBox, DropdownBox } from "../../dropdowns";
import ProjectContractTemplateEditor from "./Editor";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../alerts";
import { TooltipButton } from '../../inputs';
import { DefaultColumnFilter } from "../../react-table/filters";
import { useTable, useExpanded, useFilters, useSortBy, useFlexLayout } from 'react-table';
import Loader from "../../loaders";
import Grid from "../../Grid";
import TemplateHelp from "../Maintenance/ContractTemplate/Help";
import { useParams } from 'react-router-dom';
import fileDownload from "js-file-download";

export default function List(props) {
    const { entityId = 0 } = useParams();
    const [selectedEntity, setSelectedEntity] = React.useState(null);
    const [entities, setEntities] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [itemInEdit, setItemInEdit] = React.useState(null);
    const [filters, setFilters] = React.useState([]);

    const defaultSort = React.useMemo(() => [{ id: "templateName", desc: false }],
        []
    );

    const [sortBy, setSortBy] = React.useState(defaultSort);

    const [pageNumber, setPageNumber] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [deleteAlert, setDeleteAlert] = React.useState([]);
    const skipPageResetRef = React.useRef();
    const [templateModalOpen, setTemplateModalOpen] = React.useState(false);

    const apiurl = "entitycontracttemplate";

    const loadData = React.useCallback(() => {
        if (!selectedEntity)
            return;


        const queryString = qs.stringify({ filters, sortBy }, { allowDots: true });
        setItemInEdit(null);
        setData([]);
        skipPageResetRef.current = true;

        setLoading(true);
        api.get(`/${apiurl}/${selectedEntity.lookupId}/${(pageNumber - 1) * pageSize}/${pageSize}${queryString ? `?${queryString}` : ""}`)
            .then(({ data }) => {
                setData(data.data ?? []);
                setTotalRecords(data.total);
            }).catch((error) => {

            }).finally(() => setLoading(false));
    }, [filters, sortBy, pageNumber, pageSize, selectedEntity]);

    const handleDelete = (item) => {
        const success = () => {
            setLoading(false);
            setDeleteAlert([<DeleteSuccessAlert onConfirm={() => setDeleteAlert([])} />]);
            if (data.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1);
            } else {
                loadData();
            }
        };
        const failure = () => {
            setLoading(false);
            setDeleteAlert([<DeleteFailedAlert onConfirm={() => setDeleteAlert([])} />]);
        };
        const events = {
            onConfirm: () => {
                setLoading(true);
                api.delete(`/${apiurl}/${item.entityContractTemplateId}`).then(success).catch(failure);
            },
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert {...events} />])
    }

    React.useEffect(() => {
        if (selectedEntity)
            loadData();
    }, [selectedEntity, pageNumber, pageSize, filters, sortBy]);

    useEffect(() => {
        setLoading(true);
        api.get(`/lookup/company`)
            .then(({ data }) => {

                setLoading(false);
                setEntities(data);
            }).catch((error) => {
                setLoading(false);

            });
    }, []);

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue).replace(/[/-]/g, "")
                            .toLowerCase()
                            .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const columns = React.useMemo(() => [
        {
            Header: "Contract Template",
            id: "templateName",
            accessor: (row) => row.templateName,
            filter: "text",
            width: 300,
            disableSortBy: false,
        },
        {
            Header: "File Name",
            id: "fileName",
            accessor: (row) => row.downloadFilename,
            filter: "text",
            width: 300,
            disableSortBy: false,
        },
        {
            Header: "Template Source",
            id: "templateSource",
            accessor: (row) => row.templateSource,
            filter: "text",
            width: 300,
            disableSortBy: false,
        },
        {
            Header: "Project Name",
            id: "projectName",
            accessor: (row) => !row.projectContractTemplate || !row.projectContractTemplate.project ? null : row.projectContractTemplate.project.projectName,
            filter: "text",
            width: 300,
            disableSortBy: false,
        },
        {
            Header: "Download",
            id: "downloadUrl",
            Cell: ({ row: { original } }) =>
            (
                <>
                    <Collapse isOpen={original.hasDownloadAttachment} className="">
                        <div style={{ textAlign: 'center' }}>
                            <TooltipButton
                                id={`download_${original.entityContractTemplateId}`}
                                title="Download"
                                className="btn-icon"
                                color="default"
                                size="sm"
                                type="button"
                                onClick={() => {
                                    const url = `entitycontracttemplate/download-2/${original.entityContractTemplateId}`;
                                    api.get(url, { responseType: 'blob' })
                                        .then(blob => {
                                            const url = `entitycontracttemplate/download-filename/${original.entityContractTemplateId}`;
                                            api.get(url, { responseType: 'text' })
                                                .then(text => {
                                                    //console.log("filename", text);
                                                    fileDownload(blob.data, text.data);
                                                })
                                                .catch(error => {
                                                    console.error(error);

                                                    //SweetAlert("Error", "Failed to download document list", "error");
                                                    return;
                                                })
                                                .finally(() => {
                                                    //setLoading(false);
                                                })
                                                ;
                                        })
                                        .catch(error => {
                                            console.error(error);

                                            //SweetAlert("Error", "Failed to download document list", "error");
                                            return;
                                        })
                                        .finally(() => {
                                            //setLoading(false);

                                        })
                                        ;
                                }}
                            >
                                <i className="fas fa-download pt-1"></i>
                            </TooltipButton>
                        </div>
                    </Collapse>

                    <Collapse isOpen={!original.hasDownloadAttachment} className="">
                        <div style={{ textAlign: 'center' }}>-</div>
                    </Collapse>
                </>
            ),
            filter: "text",
            width: 100,
            disableFilters: true,
            disableSortBy: false,
        },
        {
            Header: "Actions",
            id: 'button',
            Cell: ({ row: { original } }) =>
            (
                <div>
                    <TooltipButton
                        id={`edit_${original.entityContractTemplateId}`}
                        title="Edit"
                        className="btn-icon"
                        color="default"
                        size="sm"
                        type="button"
                        onClick={() => setItemInEdit(original)}
                    >
                        <i className="fas fa-pencil-alt pt-1"></i>
                    </TooltipButton>
                    <TooltipButton
                        id={`delete_${original.entityContractTemplateId}`}
                        title="Delete"
                        className="btn-icon"
                        color="warning"
                        size="sm"
                        type="button"
                        onClick={() => handleDelete(original)}
                    >
                        <i className="fas fa-trash-alt pt-1"></i>
                    </TooltipButton>
                </div>
            ),
            width: 140,
        }]
        , [selectedEntity]);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: defaultSort,
                canSort: true,
            },
            filterTypes,
            defaultColumn,
            manualSortBy: true,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
        useExpanded
    );

    React.useEffect(() => {
        setFilters(state.filters);
    }, [state.filters]);

    React.useEffect(() => {
        setSortBy(state.sortBy);
    }, [state.sortBy]);

    return (
        <section className="main">
            {loading && <Loader />}
            {deleteAlert.length > 0 && deleteAlert}
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card className="no-transition">
                            <CardBody>
                                <CardTitle>
                                    <h4 className="text-center">Entity Contract Templates</h4>
                                </CardTitle>
                                <Container fluid className="px-0">
                                    <Row noGutters>
                                        <Col sm={12} md={{ size: 4, offset: 4 }}>
                                            <FormGroup>
                                                <Label>
                                                    Entity
                                                </Label>
                                                {/* <ComboBox 
                                                    endpoint="/company"
                                                    selectedItem={selectedEntity}
                                                    onChange={setSelectedEntity}
                                                /> */}
                                                <DropdownBox
                                                    data={entities}
                                                    selectedItem={selectedEntity || {}}
                                                    onChange={(item) => {
                                                        setSelectedEntity(item)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12}>
                                            {
                                                // (totalRecords === 0) &&
                                                <TooltipButton
                                                    id="addnew"
                                                    title="Add New"
                                                    className="btn-icon ml-2 mb-2"
                                                    color="default"
                                                    size="sm"
                                                    type="button"
                                                    disabled={!selectedEntity}
                                                    onClick={() => {
                                                        setItemInEdit({
                                                            entityId: selectedEntity.lookupId
                                                        });
                                                    }}
                                                >
                                                    <i className="fas fa-plus pt-1"></i>
                                                </TooltipButton>
                                            }
                                            <TooltipButton
                                                id="templatehelp"
                                                title="Template Help"
                                                className="btn-icon ml-2 mb-2"
                                                color="default"
                                                size="sm"
                                                type="button"
                                                disabled={!selectedEntity}
                                                onClick={() => {
                                                    setTemplateModalOpen(true);
                                                }}
                                            >
                                                <i className="fas fa-question pt-1"></i>
                                            </TooltipButton>
                                        </Col>
                                        <Col xs={12}>
                                            <Grid
                                                height='calc(100vh - 552px)'
                                                totalRecords={totalRecords}
                                                pageSize={pageSize}
                                                rows={rows}
                                                tableProps={getTableProps()}
                                                headerGroups={headerGroups}
                                                tableBodyProps={getTableBodyProps()}
                                                prepareRow={prepareRow}
                                                onPagerChangePage={(pager) => {
                                                    setPageNumber(pager.currentPage);
                                                }}
                                                onPagerChangePageSize={(size) => {
                                                    setPageSize(size);
                                                }}
                                                needPaging={true}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                                {
                                    !!itemInEdit &&
                                    <ProjectContractTemplateEditor
                                        title={`${selectedEntity.lookupValue} Contract Template`}
                                        item={itemInEdit}
                                        onClose={() => setItemInEdit(null)}
                                        onSaved={() => loadData()}
                                    />
                                }
                                {
                                    !!selectedEntity &&
                                    <TemplateHelp
                                        open={templateModalOpen}
                                        onClose={() => setTemplateModalOpen(false)}
                                        projectId={selectedEntity.projectId}
                                    />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    )

}