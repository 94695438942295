import moment from 'moment';
import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button, Col, Container, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import api from "../../../../services/api";
import Loader from '../../../loaders';
import { DatePicker } from "../../../date-pickers";

export default function CandidateTrainingDialog(props) {
    const {
        candidateId,
        startDate,
        endDate,
        onClose,
        onSaved,
        onDelete
    } = props;

    const [ itemInEdit, setItemInEdit ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ validationAlert, setValidationAlert ] = useState(null);

    const loadData = useCallback(() => {
        console.log("load talent training data:", {candidateId, startDate, endDate});

        if (!candidateId)
        {
            setItemInEdit(null);
            return;
        }

        if (!startDate || !endDate)
        {
            setItemInEdit({
                candidateId
            });
            return;
        }

        setLoading(true);

        api.get(`candidatetraining/bydate?candidateid=${candidateId}&startdate=${startDate}&endDate=${endDate}`)
            .then((response) => {
                const { data } = response;
                // console.log({data});
                setItemInEdit(data);
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [candidateId, startDate, endDate]);

    useEffect(() => {
        loadData();
    }, [candidateId, startDate, endDate]);

    const handleSave = useCallback(() => {
        setLoading(true);
        onSaved({
            data: itemInEdit,
            successCallback: () => {
                setLoading(false);
            },
            errorCallback: (message) => {
                setValidationAlert({
                    title: "Save Process Failed",
                    message
                });
                setLoading(false);
            }
        });
    }, [itemInEdit]);

    const handleCheck = useCallback(() => {
        if (!itemInEdit?.startDate || !itemInEdit?.endDate || !itemInEdit?.trainingDetails)
            return;

        setLoading(true);

        api.get(`candidatetraining/check?candidateid=${candidateId}&startdate=${itemInEdit.startDate}&endDate=${itemInEdit.endDate}&trainingId=${itemInEdit.candidateTrainingId ?? 0}`)
            .then((response) => {
                if (!response.data)
                {
                    setLoading(false);
                    handleSave();
                    return;
                }

                const {data} = response;

                setLoading(false);
                setValidationAlert({
                    title: "Training Replacement Confirmation",
                    message: `Do you want to replace Training ${data.trainingDetails ?? "[N/A]"} (${moment(data.startDate).format("DD-MM-YYYY")} to ${moment(data.endDate).format("DD-MM-YYYY")}) with this new training?`,
                    confirmedAction: () => handleSave()
                });
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
    }, [itemInEdit, handleSave]);

    const handleRemove = useCallback(() => {
        setLoading(true);
        onDelete({
            data: itemInEdit,
            successCallback: () => {
                setLoading(false);
            },
            errorCallback: (message) => {
                setValidationAlert({
                    title: "Delete Process Failed",
                    message
                });
                setLoading(false);
            }
        });
    }, [itemInEdit]);

    const handleCheckRemove = useCallback(() => {
        if (!itemInEdit?.candidateTrainingId)
            return;

        setValidationAlert({
            title: "Training Removal Confirmation",
            message: `Are you sure you want to remove this training data?`,
            confirmedAction: () => handleRemove()
        });
    }, [itemInEdit, handleSave]);
    
    const handleTextChanged = useCallback(({ target: { name, value } }) => {
        setItemInEdit({ ...itemInEdit, [name]: value });
    }, [itemInEdit]);

    const handleDateChanged = useCallback((value, name) => {
        // console.log({value, formattedValue: value.format("YYYY-MM-DD")});
        setItemInEdit({
            ...itemInEdit,
            [name]: !value ? null : value.format("YYYY-MM-DD")
        });
    }, [itemInEdit]);

    return (
        <>
        {
            loading && <Loader />
        }
            <Modal
                isOpen={true}
                className="modal-lg"
                modalClassName="db-example-modal-xl"
            >
                <div
                    className="modal-header"
                >
                    <h5
                        className="modal-title"
                    >
                        Talent Training - {itemInEdit?.candidate?.candidateName} {!itemInEdit?.candidateTrainingId ? "(New)" : "(Edit)"}
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        onClick={onClose}
                        type="button"
                    >
                        <span aria-hidden={true}><i className="fas fa-times-circle" /></span>
                    </button>
                </div>
                <div
                    className="modal-body"
                >
                    <Container fluid>
                        <Row>
                            <Col md={12} lg={6}>
                                <FormGroup>
                                    <Label>
                                        Start Date
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <DatePicker 
                                        name="startDate"
                                        id="startDate"
                                        type="text"
                                        required
                                        value={itemInEdit?.startDate ? moment(itemInEdit.startDate, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY") : null}
                                        onChange={handleDateChanged}
                                        closeOnSelect
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12} lg={6}>
                                <FormGroup>
                                    <Label>
                                        End Date
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <DatePicker 
                                        name="endDate"
                                        id="endDate"
                                        type="text"
                                        required
                                        value={itemInEdit?.endDate ? moment(itemInEdit.endDate, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY") : null}
                                        onChange={handleDateChanged}
                                        closeOnSelect
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={12}>
                                <FormGroup>
                                    <Label>
                                        Training Details
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        name="trainingDetails"
                                        id="trainingDetails"
                                        value={itemInEdit?.trainingDetails ?? ""}
                                        onChange={handleTextChanged}
                                        type="textarea"
                                        rows={6}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="modal-footer"
                >
                    <div className="w-100 h-100 d-flex flex-wrap justify-content-center align-items-center">
                        <div className="w-50 d-flex flex-wrap justify-content-start align-items-center">
                            <Button
                                color="default"
                                onClick={handleCheckRemove}
                                type="button"
                                disabled={(itemInEdit?.candidateTrainingId ? false : true) ?? true}
                            >
                                Delete
                            </Button>
                        </div>
                        <div className="w-50 d-flex flex-wrap justify-content-end align-items-center">
                            <Button
                                color="secondary"
                                onClick={onClose}
                                type="button"
                            >
                                Cancel
                            </Button>
                            <Button
                                color="info"
                                onClick={() => handleCheck()}
                                type="button"
                                disabled={!itemInEdit || !itemInEdit.startDate || !itemInEdit.endDate || !itemInEdit.trainingDetails}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        {
            validationAlert &&
            <SweetAlert
                title={validationAlert.title}
                warning
                confirmBtnText="Yes"
                confirmBtnBsStyle="danger"
                onConfirm={() => {
                    setValidationAlert(null);
                    validationAlert.confirmedAction();
                }}
                showCancel={true}
                cancelBtnText="Cancel"
                onCancel={() => setValidationAlert(null)}
            >
            {
                validationAlert.message
            }
            </SweetAlert>
        }
        </>
    );
};
