////import React, { useState } from 'react';
import React, { useEffect, useState, useCallback, useMemo, useRef, Component } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import { useParams, useHistory } from "react-router-dom";
import api from "../../../../../services/api";
import qs from "qs";
import { SortsConverter, FiltersConverter } from "../../../../../helpers"
import {
    Container, Row, Col, Button, Card, CardBody, CardTitle, UncontrolledTooltip, Progress, Label, FormGroup
} from "reactstrap";

//import { DefaultColumnFilter } from "../../../react-table/filters";
import { useTable, useFilters, useSortBy, useFlexLayout, useBlockLayout } from 'react-table';
import CreateJobOrder from "../CreateJobOrder";
import CandidateEditor from "../../../Candidates/Editor";
import CandidateEditorEmail from "../../../Candidates/EditorEmail";
import CandidateEditorSMS from "../../../Candidates/EditorSMS";
//import SearchCandidates from "./SearchCandidates";
import CandidateSearch from "../CandidateSearch";
import JobOrderCandidateEditor from "../JobOrderCandidateEditor";
import EditJobOrder from "../EditJobOrder";
import EditJobOrderGridV2 from "../EditJobOrderV2/EditJobOrderGridV2";
import moment from "moment";
import { DropdownBox } from '../../../../dropdowns';
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../../../alerts";
import CopyToClipboard from "react-copy-to-clipboard";
import { TooltipButton } from '../../../../inputs';
import BulkMilestoneEditor from "../BulkMilestoneEditor";
import fileDownload from "js-file-download";
import Loader from "../../../../loaders";
import Grid from "../../../../Grid";
//import DateFilter from "../../../date-filters"
//import { DatePicker } from '../../../date-pickers';
import { DefaultColumnFilter, DateColumnFilter } from "../../../../react-table/filters";
import { formatDate, formatDateTime } from '../../../../../utils';
import { IndeterminateCheckbox } from '../../../../react-table/controls';
import { useSticky } from 'react-table-sticky';
import CandidateDocumentViewer from '../CandidateDocumentViewer';
import MilestoneEditor from './MilestoneEditor';
import { SmartGroup } from '../../../../../helpers/AgGridHelper'
import Trial from './Trial';
import { debounce } from "lodash";

const timeoutLength = 1500;

export default function MilestoneGrid(props) {
    //const { token } = useMsal();
    const history = useHistory();
    const [data, setData] = React.useState([]);

    const params = useParams();
    const [copiedIndex, setCopiedIndex] = React.useState(null);
    React.useEffect(() => {
        if (copiedIndex !== null) {
            const timeoutId = setTimeout(() => {
                setCopiedIndex(null);
            }, timeoutLength);

            return () => clearTimeout(timeoutId);
        }
    }, [copiedIndex]);

    // const [ jobOrder, setjobOrder ] = React.useState([]);
    // const [ recruitmentRequests, setRecruitmentRequests ] = React.useState([]);
    // const [ jobOrderMilestoneMaster, setJobOrderMilestoneMaster ] = React.useState([]);
    // const [ candidates, setCandidates ] = React.useState([]);
    const [candidateId, setCandidateId] = React.useState(null);
    const candidateCallback = React.useRef(null);
    const assingmentCallback = React.useRef(null);

    const [loading, setLoading] = React.useState(false);
    //const [itemInEdit, setItemInEdit] = React.useState(null);
    const [openMilestoneEditor, setOpenMilestoneEditor] = React.useState(false);
    const [taskList, setTaskList] = React.useState([]);
    const [createNew, setCreateNew] = React.useState(false);
    const [canAutoOpen, setCanAutoOpen] = React.useState(true);
    const [bulkMilestone, setBulkMilestone] = React.useState([]);
    const [showCancelledJobOrder, setShowCancelledJobOrder] = useState(false);
    const [showCompletedJobOrder, setShowCompletedJobOrder] = useState(false);

    const [candidateIdForCvPreview, setCandidateIdForCvPreview] = React.useState(null);

    const getCustomFilter = useCallback(() => { return { showCancelledJobOrder, showCompletedJobOrder } }, [showCancelledJobOrder, showCompletedJobOrder]);

    var customFilter = {
        showCancelledJobOrder: useMemo(() => { return showCancelledJobOrder; }, [showCancelledJobOrder]),
        showCompletedJobOrder: useMemo(() => { return showCompletedJobOrder; }, [showCompletedJobOrder]),
    };

    const [emailCandidateId, setEmailCandidateId] = React.useState(null);
    const [smsCandidateId, setSMSCandidateId] = React.useState(null);
    const [itemSMS, setItemSMS] = React.useState([]);
    const [itemEmail, setItemEmail] = React.useState([]);
    const [jobOrderCandidate, setJobOrderCandidate] = React.useState(null);
    const [itemInEdit, setItemInEdit] = React.useState(null);

    const [searchCandidate, setSearchCandidate] = React.useState(null);

    const [projects, setProjects] = React.useState([]);
    const [selectedProject, setSelectedProject] = React.useState(null);
    const [locations, setLocations] = React.useState([]);
    const [selectedLocation, setSelectedLocation] = React.useState(null);

    const [pageNumber, setPageNumber] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(5);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [deleteAlert, setDeleteAlert] = React.useState([]);
    const [lookups, setLookups] = React.useState({});
    const [filters, setFilters] = React.useState([]);

    const defaultSort = React.useMemo(() => [{ id: "lrfCreationDate", desc: true }, { id: "projectName", desc: false }],
        []
    );

    const [sortBy, setSortBy] = React.useState(defaultSort);
    const skipPageResetRef = React.useRef();
    const apiurl = "jobordercandidatemilestone";

    // BEGIN AG GRID VARS -------------------------------------------------------------------------------------------------------------------------

    const [versionCounter, setVersionCounter] = useState(0);
    const gridRef = useRef();
    const [gridOptions, setGridOptions] = useState(null);
    //const [serverSideInitialRowCount, setServerSideInitialRowCount] = useState(0);

    // END AG GRID VARS ---------------------------------------------------------------------------------------------------------------------------

    // BEGIN AG GRID FUNCTIONS -------------------------------------------------------------------------------------------------------------------------

    const resizeColumns = useCallback(() => {
        if (!gridRef?.current?.columnApi || data?.length === 0)
            return;

        //const allColumnIds = [];
        //gridRef.current.columnApi.getColumns().forEach((column) => {
        //    // console.log(column, column.getId());
        //    allColumnIds.push(column.getId());
        //});
        //gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
        autoSizeAll(true);
    }, [data, gridRef]);

    const autoSizeAll = useCallback((skipHeader = true) => {
        const allColumnIds = [];
        gridRef.current.columnApi.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }, [gridRef]);


    //useEffect(() => {
    //    resizeColumns();
    //}, [data]);

    useEffect(() => {
        //console.log('refreshCache', 'showCompletedJobOrder', showCompletedJobOrder, 'showCancelledJobOrder', showCancelledJobOrder);
        //console.log('gridRef', gridRef);
        //console.log('gridOptions', gridOptions);

        // Refresh datasource
        if (gridRef && gridRef.current && gridRef.current.api) {
            var datasource = getServerSideDatasource();
            gridRef.current.api.setServerSideDatasource(datasource);
        }



        // Refresh data
        refreshCache([]);

    }, [showCompletedJobOrder, showCancelledJobOrder, gridRef, params.action, params.requestId]);



    const defaultColumns = [
        {
            name: "jobOrderId",
            title: "Job Order Id",
            //cellRenderer: SmartGroup(['jobOrderId']),
            rowGroup: true,
            hide: true,
            width: 60,
            suppressSizeToFit: true 
        },
        {
            name: "createdDate",
            title: "Created Date",
            field: 'date',
            filter: 'agDateColumnFilter',
            rowGroup: true,
            hide: true,
            width: 60,
            //flex: 1,
            //cellRenderer: SmartGroup(['jobOrderId','createdDate'], 'valueFormatter', (params) => {
            //    var { value } = params;
            //    return value ? moment(value).format("DD.MM.YYYY") : "N/A";
            //}),
            cellRenderer: (params) => {
                var { value } = params;
                return value ? moment(value).format("DD.MM.YYYY") : "N/A";
            }
        },
        {
            name: "expectedStartDate",
            title: "Expected Start Date",
            field: 'date',
            filter: 'agDateColumnFilter',
            rowGroup: true,
            hide: true,
            width: 60,
            //flex: 1,
            //cellRenderer: SmartGroup(['jobOrderId', 'createdDate', 'expectedStartDate'], 'valueFormatter', (params) => {
            //    var { value } = params;
            //    return value ? moment(value).format("DD.MM.YYYY") : "N/A";
            //}),
            cellRenderer: (params) => {
                var { value } = params;
                return value ? moment(value).format("DD.MM.YYYY") : "N/A";
            }
        },
        {
            name: "lrfNumber",
            title: "LRF",
            rowGroup: true,
            hide: true,
            //flex: 1.5,
            //cellRenderer: SmartGroup(['jobOrderId', 'createdDate', 'expectedStartDate', 'lrfNumber'], 'cellRenderer', (params) => {
            //    var { data } = params;
            //    if (!data?.lrfNumber) return "";
            //    return (<a className="text-info" href={`/lrf/${data?.lrfId}`} target='_blank'>{data?.lrfNumber}</a>);
            //}),
            //cellRenderer: (params) => {
            //    var { data } = params;
            //    return (<a className="text-info" href={`/lrf/${data?.lrfId}`} target='_blank'>{data?.lrfNumber}</a>);
            //},
            //valueGetter: (params) => {
            //    var { data } = params;
            //    return data?.lrfNumber;
            //},

        },
        {
            name: "recruitmentPerson",
            title: "Recruitment Person",
            rowGroup: true,
            hide: true,
            //flex: 2,
            //cellRenderer: SmartGroup(['jobOrderId', 'createdDate', 'expectedStartDate', 'lrfNumber', 'recruitmentPerson'])
        },
        {
            name: "firstName",
            title: "Candidate Name",
            //flex: 2,

            rowGroup: true,
            hide: true,
            //cellRenderer: SmartGroup(['jobOrderId', 'createdDate', 'expectedStartDate', 'lrfNumber', 'recruitmentPerson', 'firstName', 'surname'], 'cellRenderer', (params) => {
            //    var { data } = params;

            //    if (!data)
            //        return "";
            //    var { firstName, surname } = data;
            //    if (!firstName)
            //        return "";

            //    return `${firstName} ${surname}`;
            //}),
            valueGetter: (params) => {
                var { data } = params;

                // Auto return if no data, as error prevention
                if (!data) return "";

                var { firstName, surname } = data;
                if (!firstName)
                    return "";

                return `${firstName} ${surname}`;
            },

        },
        {
            name: "milestoneName",
            title: "Task Name",
            //aggFunc: 'first',
            sortable: false,
            //flex: 3,
            suppressSizeToFit: true,
        },
        {
            name: "submittedBy",
            title: "Actionee",
            //aggFunc: 'first',
            sortable: false,
            //flex: 3,
            suppressSizeToFit: true,
        },
        {
            name: "submitDate",
            title: "Action",
            field: 'date',
            //aggFunc: 'first',
            filter: 'agDateColumnFilter',
            sortable: false,
            suppressSizeToFit: true,
            //flex: 2,
            valueFormatter: (params) => {
                var { value } = params;
                return value ? moment(value).format("DD.MM.YYYY") : "N/A";
            },
            cellClass: "action-button-cell action-button-cell-wide",
            cellRenderer: useCallback((params) => {
                var { data} = params;

                // Auto return if no data, as error prevention
                if (!data) return "";


                var index = 0;
                if (data.submitDate) {
                    var value = data.submitDate;
                    return <div style={{ width: "100%" }} className="d-flex flex-wrap justify-content-center align-items-center">{value ? moment(value).format("DD.MM.YYYY") : "N/A"}</div>;
                }

                return (<>
                    <div style={{ width: "100%" }} className="d-flex flex-wrap justify-content-center align-items-center">
                        <TooltipButton
                            id={`candidate_${data.rowNo}`}
                            title="Submit Progress"
                            className="btn-icon ml-2 mb-2 pl-2 pr-2"
                            color="default"
                            size="xs"
                            type="button"
                            onClick={() => {
                                setItemInEdit({ ...data });
                                setOpenMilestoneEditor(true);
                            }}
                        >
                            <i className="fas fa-check mr-1"></i> Submit Progress
                        </TooltipButton>
                    </div>

                </>);
            },[data]),
        },
        {
            name: "note",
            title: "Note",
            sortable: false,
            minWidth: 240,
            //aggFunc: 'first',
            //flex: 3,
            suppressSizeToFit: true,
        },
    ];

    const [agGridSort, setAgGridSort] = useState(['asc', 'desc']);

    const [columnDefs, setColumnDefs] = useState(defaultColumns.map((c) => {
        //var obj = {
        //    ...obj,
        //    field: c.name,
        //    headerName: c.title,
        //    editable: false,
        //    filter: !('filter' in c) ? 'agTextColumnFilter' : c.filter,
        //    floatingFilter: !('floatingFilter' in c) ? true : c.floatingFilter,
        //};
        var obj = {
            ...c,
            field: c.name,
            headerName: c.title,
            //editable: false,
            //filter: !('filter' in c) ? 'agTextColumnFilter' : c.filter,
            //floatingFilter: !('floatingFilter' in c) ? true : c.floatingFilter,
            //floatingFilterComponentParams: {
            //    suppressFilterButton: true,
            //},
        };

        if ('name' in obj) delete obj.name;
        if ('title' in obj) delete obj.title;

        //if ('pinned' in c) obj = { ...obj, pinned: c.pinned };
        //if ('valueFormatter' in c) obj = { ...obj, valueFormatter: c.valueFormatter };
        //if ('cellRenderer' in c) obj = { ...obj, cellRenderer: c.cellRenderer };
        //if ('cellRendererParams' in c) obj = { ...obj, cellRendererParams: c.cellRendererParams };
        //if ('additionalParams' in c) obj = { ...obj, additionalParams: c.additionalParams };
        //if ('cellClass' in c) obj = { ...obj, cellClass: c.cellClass };
        
        return obj;
    }));

    const defaultColDef = useMemo(() => {
        return {
            sortable: false,
            resizable: true,
            filter: true,
            editable: false,
            //filter: 'agSetColumnFilter',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset'],
                debounceMs: 200,
                excelMode: 'mac',
            },
            floatingFilter: true,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            suppressMenu: true,
    
    
            //enableRowGroup: true,
            //enablePivot: true,
            //enableValue: true,
        };
    }, []);

    const loadingOverlayComponent = useMemo(() => Loader, []);

    const onCellEditRequest = useCallback((event) => {
        console.log("on cell edit request: ", { event });
    }, []);


    const refreshCache = useCallback((route) => {
        //console.log('gridRef', gridRef);
        if (!gridRef || !gridRef.current || !gridRef.current.api)
            return;

        setVersionCounter(versionCounter + 1);
        gridRef.current.api.purgeServerSideCache();

    }, [versionCounter, params, showCancelledJobOrder, showCompletedJobOrder]);

    // const onGridReady = useCallback((params) => {

    //     var datasource = getServerSideDatasource();
    //     params.api.setServerSideDatasource(datasource);
    // }, [showCancelledJobOrder, showCompletedJobOrder]);

    const getServerSideDatasource = useCallback(() => {

        return {

            getRows: (params2) => {


                //console.log('getRows');
                //console.log('params2', params2);
                //console.log('showCancelledJobOrder', showCancelledJobOrder);
                //console.log('showCompletedJobOrder', showCompletedJobOrder);

                var { request } = params2;
                var { filterModel, sortModel } = request;

                var thisFilters = FiltersConverter(filterModel);
                var thisSorts = SortsConverter(sortModel);
                var selectedSorts = (thisSorts.length ? thisSorts : defaultSort);

                const queryString = qs.stringify({
                    filters: thisFilters,
                    sortBy: selectedSorts,

                    showCancelledJobOrder: showCancelledJobOrder,
                    showCompletedJobOrder: showCompletedJobOrder,
                }, {
                    allowDots: true
                });
                const url = `/${apiurl}/${params2.request.startRow ?? 0}/${pageSize}${params.requestId ? ("/" + params.requestId + "/" + params.action) : ""}${queryString ? `?${queryString}` : ""}`;

                //console.log('queryString', queryString);
                //console.log('url', url);


                //api.get(`/path/to/endpoint/${params.request.startRow}/${params.request.endRow}`)
                api.get(url)
                    .then((response) => {
                        console.log('response', response);
                        var successData = {
                            rowData: response.data.data,
                            //rowCount: 100,
                            rowCount: response.data.total,
                        };
                        //setServerSideInitialRowCount(response.data.total);

                        //console.log('success data', successData);
                        params2.success(successData);
                    })
                    .catch(error => {
                        console.error('error on getServerSideDatasource.getRows', error);
                        params2.fail();
                    });
            },
        };
    }, [params, showCancelledJobOrder, showCompletedJobOrder]);


    const updateSingleRow = useCallback((jobOrderId) => {
        var gridApi = gridRef.current.api;
        gridApi.forEachNode(rowNode => {

            if (jobOrderId == rowNode.data.jobOrderId) {
                //console.log('rowNode', rowNode);

                const url = `/${apiurl}/${jobOrderId}`;
                setLoading(true);
                api.get(url)
                    .then(({ data }) => {
                        //console.log('data to set', 'data', data);
                        rowNode.setData(data)
                    }).catch((error) => {
                        console.error("error: ", { error });
                    }).finally(() => setLoading(false));
            }
        });
    }, [gridRef]);

    //React.useEffect(() => {
    //    console.log('gridOptions', gridOptions);
    //}, [gridOptions]);

    //React.useEffect(() => {
    //    console.log('gridRef', gridRef);
    //}, [gridRef]);

    const autoGroupColumnDef = useMemo(() => {
        return {
            minWidth: 140,
            // suppressSizeToFit: true,
            maxWidth: 280,
            cellRendererParams: {
                suppressCount: true,
            },
            filterValueGetter: (params) => {
                if (params.node) {
                    var colGettingGrouped = params.colDef.showRowGroup + '';
                    return params.api.getValue(colGettingGrouped, params.node);
                }
            },
        };
    }, []);


    // END AG GRID FUNCTIONS ---------------------------------------------------------------------------------------------------------------------------


    useEffect(() => {
        console.log('data', data);
    }, [data])

    React.useEffect(() => {
        if (params.action === "new")
            setCreateNew(true);
    }, [params.action])

    // HIGHLY IMPORTANT TO USE DEBOUNCE HERE
    const loadData = useCallback(
        debounce((paramObject = null) => {

            /*
            if (params2 && 'api' in params2) {
                var { api: api2 } = params2;
                api2.hideOverlay();
            }
            */

            //console.log('params2', params2);

            /*
            var paramObject = {
                projectId: 493,
                start: '2022-10-01',
                end: '2022-12-31'
            };
            */

            //const queryString = qs.stringify(paramObject, { allowDots: false });
            const queryString = '';
            skipPageResetRef.current = true;
            setItemInEdit(null);
            setCreateNew(false);
            setData([]);

            var projectId = selectedProject ? selectedProject.projectId : 0;
            var locationId = selectedLocation ? selectedLocation.locationId : 0;

            //if (!selectedProject || !selectedLocation) return;



            const url = `/${apiurl}/${projectId}/${locationId}/0/0${params.requestId ? ("/" + params.requestId + "/" + params.action) : ""}${queryString ? `?${queryString}` : ""}`;
            setLoading(true);
            api.get(url)
                .then(({ data }) => {

                    // Set data
                    var newData = [...data];
                    setData(newData);
                    setTotalRecords(newData.length);

                }).catch((error) => {
                    setItemInEdit(null);
                    setData([]);
                    setTotalRecords(0);
                    console.error("error: ", { error });
                }).finally(() => setLoading(false));
        }, 200),
        [filters, sortBy, pageNumber, pageSize, params, selectedProject, selectedLocation]
    );

    useEffect(() => {
        loadData();
        //}, [filters, sortBy, pageNumber, pageSize, params]);
    }, [selectedProject, selectedLocation]);

    useEffect(() => {

        const url = `/${apiurl}/projects`;
        setLoading(true);
        api.get(url)
            .then(({ data }) => {

                // Set data
                var newData = [...data];
                setProjects(newData);

            }).catch((error) => {
                setProjects([]);
                console.error("error: ", { error });
            }).finally(() => setLoading(false));
    }, []);

    useEffect(() => {

        if (!selectedProject) {
            setLocations([]);
            setSelectedLocation(null);
            return;
        };
        const url = `/${apiurl}/locations/${selectedProject.projectId}`;
        setLoading(true);
        api.get(url)
            .then(({ data }) => {

                // Set data
                var newData = [...data];
                setLocations(newData);
                setSelectedLocation(null);

            }).catch((error) => {
                setLocations([]);
                console.error("error: ", { error });
            }).finally(() => setLoading(false));



    }, [selectedProject]);

    const handleDelete = (item) => {
        const success = () => {
            setLoading(false);
            setDeleteAlert([<DeleteSuccessAlert key={0} onConfirm={() => setDeleteAlert([])} />]);
            if (data.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1);
            } else {
                refreshCache();
            }
        };
        const failure = () => {
            setLoading(false);
            setDeleteAlert([<DeleteFailedAlert key={0} onConfirm={() => setDeleteAlert([])} />]);
        };
        const events = {
            onConfirm: () => {
                setLoading(true);
                api.delete(`/${apiurl}/${item.jobOrderId}`).then(success).catch(failure);
            },
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert key={0} {...events} />])
    }

    React.useEffect(() => {
        console.log("data", data);
        if (canAutoOpen && data && data.length === 1 && params.requestId && params.action && params.action === "joborder") {
            setItemInEdit(data[0]);
        }
    }, [canAutoOpen, data, params]);


    return (
        <section className="main">
            {loading && <Loader />}
            {deleteAlert.length > 0 && deleteAlert}
            <Container fluid>
                <Row>
                    <Col xs={12}>

                        {/*<Card className="no-transition">*/}
                            <CardBody>


                                <Row>

                                    <Col sm={12} className="form-inline mb-3">
                                        <FormGroup>
                                            <Label className="mr-3">
                                                Project
                                            </Label>
                                            <DropdownBox
                                                data={projects}
                                                selectedItem={selectedProject}
                                                onChange={(item) => {
                                                    setSelectedProject(item);
                                                }}
                                                idField="projectId"
                                                valueField="projectName"
                                                className="mr-3"
                                            />
                                        </FormGroup>
                                        <FormGroup className="ml-3">
                                            <Label className="mr-3">
                                                Location
                                            </Label>
                                            <DropdownBox
                                                data={locations}
                                                selectedItem={selectedLocation}
                                                onChange={(item) => {
                                                    setSelectedLocation(item);
                                                }}
                                                idField="locationId"
                                                valueField="locationName"
                                                className="mr-3"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12}>
                                        <div className="ag-theme-alpine ag-grid" style={{ width: '100%', height: 'calc(100vh - 200px)' }}>
                                            <AgGridReact
                                                ref={gridRef}
                                                gridOptions={gridOptions}
                                                //domLayout={'autoHeight'}
                                                columnDefs={columnDefs}
                                                defaultColDef={defaultColDef}
                                                loadingOverlayComponent={loadingOverlayComponent}
                                                rowData={data}
                                                //sideBar={sideBar}
                                                suppressColumnVirtualisation={true}
                                                suppressRowVirtualisation={true}
                                                debounceVerticalScrollbar={true}
                                                rowBuffer={20}
                                                headerHeight={30}
                                                groupHeaderHeight={30}
                                                rowHeight={30}
                                                floatingFiltersHeight={30}
                                                // readOnlyEdit={true}
                                                onRowDataUpdated={() => resizeColumns()}
                                                suppressDragLeaveHidesColumns={true}
                                                suppressColumnMoveAnimation={true}
                                                animateRows={false}
                                                onCellEditRequest={onCellEditRequest}
                                                excelStyles={excelStyles()}
                                                // pagination={true}
                                                // paginationPageSize={pageSize}
                                                // cacheBlockSize={pageSize}
                                                //rowModelType={'serverSide'}
                                                // onGridReady={onGridReady}
                                                //serverSideInfiniteScroll={true}
                                                sortingOrder={agGridSort}
                                                onFirstDataRendered={(e) => { e.api.sizeColumnsToFit(); } }
                                                //onModelUpdated={autoSizeAll}

                                                //pivotMode={true}
                                                //showOpenedGroup={true}
                                                groupDefaultExpanded={5}
                                                groupHideOpenParents={true}
                                                //groupDisplayType={'multipleColumns'}
                                                //sideBar={'columns'}
                                                autoGroupColumnDef={autoGroupColumnDef}

                                            />
                                        </div>
                                    </Col>
                                </Row>

                            </CardBody>
                        {/*</Card>*/}

                    </Col>

                    {/* 
                    <Col xs={12}>
                        <Card className="no-transition">
                            <CardBody>
                                <Row>
                                    <Col xs={12}>
                                        <Trial />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    */}
                </Row>
            </Container>
            {
                itemInEdit && candidateId &&
                <CandidateEditor
                    item={{ candidateId }}
                    onClose={() => {
                        setCandidateId(null);

                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }

                    }}
                    onSaved={(candidateItem) => {
                        setCandidateId(null);

                        if (candidateCallback.current) {
                            candidateCallback.current(true);
                        }
                    }}
                    lookups={{
                        ...lookups
                    }}
                />
            }
            {
                (openMilestoneEditor && itemInEdit) &&
                <MilestoneEditor
                    onClose={() => {
                        setOpenMilestoneEditor(false);
                        setItemInEdit(null);
                    }}
                    onSave={() => {
                        setOpenMilestoneEditor(false);
                        setItemInEdit(null);
                        loadData();
                    }}
                    data={data}
                    refresh={() => loadData()}
                    origin={itemInEdit}
                />
            }
        </section>
    )

}


export const excelStyles = () => {
    const headerDefaultStyle = {
        alignment: {
            vertical: 'Center',
            horizontal: "Center",
        },
        interior: {
            color: '#f8f8f8',
            pattern: 'Solid',
            patternColor: undefined,
        },
        borders: {
            borderTop: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderRight: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderBottom: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
            borderLeft: {
                color: '#babfc7',
                lineStyle: 'Continuous',
                weight: 1,
            },
        },
        font: {
            fontName: 'Roboto',
            color: '#181d1f',
            bold: true,
            size: 12,
        },
    }

    return [
        {
            id: 'header',
            ...headerDefaultStyle
        },
        {
            id: 'date-header',
            ...headerDefaultStyle,
            alignment: {
                vertical: 'Center',
                horizontal: "Center",
                rotate: 90,
            },
        },
        {
            id: 'cell',
            alignment: {
                vertical: 'Top',
                wrapText: true,
            },
            interior: {
                color: '#ffffff',
                pattern: 'Solid',
                patternColor: undefined,
            },
            borders: {
                borderTop: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderRight: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderBottom: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
                borderLeft: {
                    color: '#babfc7',
                    lineStyle: 'Continuous',
                    weight: 1,
                },
            },
            font: {
                fontName: 'Roboto',
                color: '#181d1f',
                weight: 700,
                size: 12,
            },
        },
    ];
};


