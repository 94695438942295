import React from 'react';
import {
    Spinner
} from "reactstrap";

export default function Loader({ message, size = 3.2, absolute = false }) {
    return (
        <div style={{
            position: absolute ? 'absolute' : 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            backgroundColor: 'rgba(0,0,0,0.15)',
            zIndex: '999999'
        }}>
            
            <div 
                className="uil-reload-css mr-1" 
                style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <Spinner color="default" type="border"  style={{ width: `${size}rem`, height: `${size}rem`, borderWidth: `${size / 10}rem` }} />
                
            </div>
           {/*  {message && 
                <div
                    style={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, calc(-50% + 40px))'
                    }}
                >                
                    <p>{message}</p>
                </div>    
            } */}
        </div>
    )
}
export function Save({ message, size = 1, absolute = true }) {
    return (
        <div style={{
            position: absolute ? 'absolute' : 'fixed',
            top: '0',
            left: '33px',
            right: '0',
            bottom: '0',
            zIndex: '99999'
        }}>
            
            <div 
                className="uil-reload-css mr-1" 
                style={{

                }}
            >
                <Spinner color="default" type="grow"  style={{ width: `${size}rem`, height: `${size}rem`, borderWidth: `${size / 10}rem` }} />
                
            </div>
           {/*  {message && 
                <div
                    style={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, calc(-50% + 40px))'
                    }}
                >                
                    <p>{message}</p>
                </div>    
            } */}
        </div>
    )
}