/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */

import moment from "moment";

export function DateStringToUtcDate(dateString, format = 'YYYYMMDD') {
    if (dateString == null || dateString == undefined) {
        return null;
    }
    return moment.utc(dateString, format).toDate();

}

export function StripName(rawString) {
    if (rawString == undefined) {
        return rawString;
    }
    return rawString.replace(/[^a-zA-Z0-9]/g, '');

}

export function ToLower(str) {
    return str.toLowerCase();
}

export function RemoveAllSymbol(str) {
    return str.replace(/[^a-zA-Z0-9\s]/g, "");
}

export function ReplaceSpaceToStrip(str) {
    return str.replace(/\s/g, "-");
}

export function TrimObject(itemInEdit) {
    var newItemInEdit = { ...itemInEdit };
    Object.keys(newItemInEdit).forEach(key => {
        if (typeof newItemInEdit[key] === 'string' && newItemInEdit[key] != null) {
            newItemInEdit[key] = newItemInEdit[key].trim();
        }
    });
    return newItemInEdit;
    //setItemInEdit(newItemInEdit);
}

export function ToFilenameString(str) {
    if (str == null || str == undefined) return str;
    let result = ToLower(str);
    result = RemoveAllSymbol(result);
    result = ReplaceSpaceToStrip(result);
    return result;
}

export function RemoveScriptTags(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const scripts = doc.getElementsByTagName('script');

    // Menghapus elemen <script>
    while (scripts[0]) {
        scripts[0].parentNode.removeChild(scripts[0]);
    }

    // Mengubah DOM kembali menjadi string HTML
    const div = document.createElement('div');
    div.appendChild(doc.documentElement.cloneNode(true));
    return div.innerHTML;
}


export function GetExtension(filename) {
    return filename.split('.').pop();
}

export function ShortenWords(str, maxLength, separator = '-') {
    if (str == null || str == undefined) return str;
    return str.split(separator).map(word => word.substring(0, maxLength)).join(separator);
}

export function AcceptableExportWords(str, maxLength = 8, separator = '-') {
    if (str == null || str == undefined) return str;
    var newStr = ShortenWords(ToFilenameString(str), maxLength, separator);
    return newStr;
}

export function StringToBoolean(rawString) {
    return rawString == undefined || rawString == null ? null : rawString == true || rawString == "true" || rawString == "1" ? true : false;
}

export function MakeWords(arr) {
    if (!arr || arr.length == 0) {
        return "";
    }
    else if (arr.length === 1) {
        return arr[0];
    }
    const firsts = arr.slice(0, arr.length - 1);
    const last = arr[arr.length - 1];
    return firsts.join(', ') + ' and ' + last;
}

export function HtmlToText(htmlString) {
    if (!htmlString?.length) {
        return "";
    }
    else if (window?.navigator?.userAgent && window.navigator.userAgent.indexOf("MSIE ") > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        // This is Internet Explorer
        var div = document.createElement('div');
        div.innerHTML = htmlString;
        return div.textContent || div.innerText || "";
    } else {
        // This is a modern browser
        var parser = new DOMParser();
        var dom = parser.parseFromString(htmlString, 'text/html');
        return dom.body.textContent || "";
    }
}
export function ShortenTextColumn(htmlString, maxLength = 40) {
    var text = HtmlToText(htmlString);
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    } else {
        return text;
    }
}

export function FormatFileSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (!bytes) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
}

export function IsNullOrWhiteSpace(str) {
    var isString = typeof str === 'string' || str instanceof String;
    //console.log('IsNullOrWhiteSpace');
    //console.log('str', str);
    //console.log('isString', isString);
    //if (!isString) return false;
    if(str != null && !isString) return false;
    return str === null || str.match(/^ *$/) !== null;
}

export function IsInteger(value) {
    if (value === null || value === undefined) {
        return false;
    }
    const num = Number(value);
    return Number.isInteger(num);
}
