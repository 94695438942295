/* eslint-disable eqeqeq */
import React from 'react';
import {
    Input, Button, Row, Col, Media, Card, CardHeader, CardBody, Collapse
} from "reactstrap"; 
import PropTypes from "prop-types";
import moment from "moment";
import Loader from "../../../loaders";
import api from "../../../../services/api";
import EditorSMS from "../EditorSMS";
import EditorEmail from '../EditorEmail';
import EditorComment from '../EditorComment';
import { TooltipButton } from '../../../inputs';

export default function Requests(props) {

    const {
        itemInEdit: { candidateId },
    } = props;
    const [loading, setLoading] = React.useState(false);
    const [messageLogs, setMessageLogs] = React.useState([]);
    const [itemSMS, setItemSMS] = React.useState([]);
    const [itemEmail, setItemEmail] = React.useState([]);
    const [itemComment, setItemComment] = React.useState([]);

    const loadMessageLogs = React.useCallback(() => {
        setItemSMS([]);
        setItemEmail([]);
        setItemComment([]);

        setLoading(true);

        api.get(`candidate/messagelogs/${candidateId}`)
            .then((response) => {
                setMessageLogs(response.data);
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }, [candidateId]);

    React.useEffect(() => {
        if (candidateId) {
            loadMessageLogs();
        }
    }, [candidateId]);

    const renderHistoryList = (item, index) => {
        var dispatchUnitName = () => {
            var duName = item?.rosterCandidate?.roster?.dispatchUnit?.dispatchUnitName;
            if (duName) {
                duName = duName.replace(/^(dispatch unit)/ig, "");
                return `Dispatch Unit ${duName}`;
            }
            return null;
        }

        var sentToStatement = () => {
            if (item.notificationType == "Comment") {
                return "";
            }
            else if (item.notificationType === 'Email') {
                return ' Sent to ' + item.emailAddress;
            }
            else if (item.notificationType === 'SMS') {
                return ' Sent to ' + item.mobilePhone;
            } else {
                return ': ' + item.messageType?.lookupValue;
            }
        };

        return (
                
            <li key={index} className="list-group-item">
                <Row>
                    <Col xs={6}>
                        {item.sentDate && moment(item.sentDate).format("DD/MM/YYYY HH:mm:ss")}
                        <i className="ml-1 mr-1"></i> {item.notificationType} 
                        {sentToStatement()} <i className="ml-1 mr-1"></i> {dispatchUnitName()}
                    </Col>
                    <Col xs={6} className="text-right">
                        {item.createdBy}
                    </Col>
                </Row>
                <br />
                <blockquote className="blockquote">
                    {
                        item.messageText ? <p className="mb-0">{item.messageText}</p>
                            : "<Empty>"
                    }
                </blockquote>
                    </li>
        );
    }


    return (
        <Row className="candidate-requests-container">
            <Col xs={12}>
                {loading && <Loader />}
                {!!itemSMS && itemSMS.length > 0 &&
                    <EditorSMS
                        items={itemSMS}
                        onClose={() => setItemSMS([])}
                        onSaved={() => loadMessageLogs()}
                        lookups={{
                            ...props.lookups
                        }}
                    />
                }
                {
                    !!itemEmail && itemEmail.length > 0 &&
                    <EditorEmail
                        items={itemEmail}
                        onClose={() => setItemEmail([])}
                        onSaved={() => loadMessageLogs()}
                        lookups={{
                            ...props.lookups
                        }}
                    />
                }
                {
                    !!itemComment && itemComment.length > 0 &&
                    <EditorComment
                        items={itemComment}
                        onClose={() => setItemComment([])}
                        onSaved={() => loadMessageLogs()}
                        lookups={{
                            ...props.lookups
                        }}
                    />
                }
                <Collapse
                    isOpen={true}
                >
                    <TooltipButton
                        id="sendsms"
                        title="Send Bulk SMS"
                        className="btn-icon ml-2 mb-2"
                        color="default"
                        size="sm"
                        type="button"
                        onClick={() => {
                            setItemSMS([props.itemInEdit])
                        }}
                    >
                        <i className="fas fa-comment mr-1"></i> Send SMS
                    </TooltipButton>
                    <TooltipButton
                        id="sendemail"
                        title="Send Bulk Email"
                        className="btn-icon ml-2 mb-2"
                        color="default"
                        size="sm"
                        type="button"
                        onClick={() => {
                            setItemEmail([props.itemInEdit])
                        }}
                    >
                        <i className="fas fa-envelope-open"></i> Send Email
                    </TooltipButton>
                    <TooltipButton
                        id="addcomment"
                        title="Add Bulk Comment"
                        className="btn-icon ml-2 mb-2"
                        color="default"
                        size="sm"
                        type="button"
                        onClick={() => {
                            setItemComment([props.itemInEdit])
                        }}
                    >
                        <i className="fas fa-comment mr-1"></i> Add Comment
                    </TooltipButton>
                </Collapse>
            <Col xs={12}>
                <ul className="list-group list-group-flush">
                    {messageLogs?.map(renderHistoryList)}
                </ul>
                </Col>
            </Col>
        </Row>
    )

}