const today = new Date();

export const initialState = {
    dispatchUnit: null,
    startDate: new Date(today.getFullYear(), today.getMonth(), 1),
    endDate: new Date(today.getFullYear(), today.getMonth() + 6, 1),
    trimStartDate: true,
    trimEndDate: true,
    showArchivedDispatchUnits: false,
    dataLoaded: false,
};

export const actions = {
    SET_PROJECT: "SET_PROJECT",
    SET_DISPATCH: "SET_DISPATCH",
    SET_START: "SET_START",
    SET_TRIM_START: "SET_TRIM_START",
    SET_END: "SET_END",
    SET_TRIM_END: "SET_TRIM_END",
    SET_SHOW_ARCHIVED: "SET_SHOW_ARCHIVED",
    SET_DATA_LOADED: "SET_DATA_LOADED",
}

const reportReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case actions.SET_PROJECT:
            return {
                ...state,
                project: {...payload.project},
            }
        case actions.SET_DISPATCH:
            return {
                ...state,
                dispatchUnit: {...payload.dispatchUnit},
            }
        case actions.SET_START:
            return {
                ...state,
                startDate: payload.startDate,
            }
        case actions.SET_TRIM_START:
            return {
                ...state,
                trimStartDate: payload.trimStartDate,
            }
        case actions.SET_END:
            return {
                ...state,
                endDate: payload.endDate,
            }
        case actions.SET_TRIM_END:
            return {
                ...state,
                trimEndDate: payload.trimEndDate,
            }
        case actions.SET_SHOW_ARCHIVED:
            return {
                ...state,
                showArchivedDispatchUnits: payload.showArchivedDispatchUnits,
            }
        case actions.SET_DATA_LOADED:
            return {
                ...state,
                dataLoaded: payload.dataLoaded,
            }
    }
}

export default reportReducer;
