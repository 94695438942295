import React from 'react';
import api from "../../../services/api";
import {
   Container, Row, Col, Card, CardBody, CardTitle
} from "reactstrap";
import { useParams } from "react-router-dom";

function CandidateDocuments(props) {


    const {id} = useParams();

    React.useEffect(() => { 
        if (id > 0) {
            api.get(`candidate/${id}/docsurl`)
            .then((response) => {
                console.log(response.data);
                // props.history.push(response.data);
                window.open(response.data, "_self");
            })
            .catch((error) => console.error(error))
        } 
    }, [id]);

 
    return (
        <section className="main">
            <Container fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <h4 className="text-center">Candidate's Documents</h4>
                                    <h6 className="text-center">Please wait. The page is redirecting you to the SharePoint Folder of the candidate</h6>
                                </CardTitle>                        
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default CandidateDocuments;