import React, { useCallback, useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import useRoster from "../contexts/RosterContext";

export default (props) => { 
    
    const {
        api: gridApi,
        columnApi,
    } = props;

    const { 
        setNotification,
    } = useRoster();

    const handleSaveColumnState = useCallback(() => {
        const savedState = columnApi.getColumnState().slice(0, process.env.REACT_APP_ROSTER_DATE_COLUMN_START_INDEX);
        localStorage.setItem(process.env.REACT_APP_HRIS_ROSTER_GRID_COLUMN_STATE, JSON.stringify(savedState));
        setNotification({
            type: "success",
            message: "Column state has been saved."
        })
    }, []);

    const handleResetColumnState = useCallback(() => {
        localStorage.removeItem(process.env.REACT_APP_HRIS_ROSTER_GRID_COLUMN_STATE);
        columnApi.resetColumnState();
        setNotification({
            type: "success",
            message: "Column state has been reset."
        })
    }, []);

    return (
        <Container fluid>
            <Row>
                <Col xs={12} className="pt-3">
                    <h5>Grid Configuration</h5>
                </Col>
                <Col xs={12} className="pt-3">
                    <Button
                        color="secondary btn-grid-panel"
                        onClick={handleSaveColumnState}
                        type="button"
                        className="w-100 text-left"
                    >
                        Save Column State
                    </Button>
                </Col>
                <Col xs={12}>
                    <Button
                        color="secondary btn-grid-panel"
                        onClick={handleResetColumnState}
                        type="button"
                        className="w-100 text-left"
                    >
                        Reset Column State
                    </Button>
                </Col>
            </Row>
        </Container>
    )
}