import React, { Component } from 'react';
import moment from 'moment';

export default class RosterCandidateAllocation extends Component {
  componentDidMount() {
      window.jQuery('#reportViewer1')
          .telerik_ReportViewer({
              serviceUrl: 'api/reports/',
              reportSource: {
                  report: './RosterCandidateAllocation.trdp',
                  parameters: {
                    "start": moment().format('YYYY-MM-DD'),
                    "end": moment().format('YYYY-MM-DD'),
                }
              },
              scale: 1.0,
              viewMode: 'INTERACTIVE',
              printMode: 'SPECIFIC',
          });
  }

  render() {
      return <div id="reportViewer1"></div>
  }
}