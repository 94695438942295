import React from 'react';
import Matrix from '../Matrix';
import {
   Container, Row, Col, Card, CardBody, CardTitle, FormGroup, Label, ButtonGroup, Button
} from "reactstrap";

export default function CandidateLocationReport(props) {
    return (
        <section className="main">
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card className="no-transition">
                            <CardBody>
                                <CardTitle>
                                    <h4 className="text-center">Employee Location Report</h4>                                    
                                </CardTitle>
                                <Container fluid className="px-0">
                                    <Row noGutters>
                                        <Col xs={12}>
                                            <Matrix
                                                project={null}
                                                showAllProjects={true}
                                                selectedCandidate={null}
                                                showFilters={true}
                                                reportMode={true}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}