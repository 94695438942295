import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { useHistory } from "react-router";
import SearchDialog from "./SearchDialog";
import ProfileComparisonDialog from "./ProfileComparisonDialog";
import DispatchHistoryDialog from "./DispatchHistoryDialog";
import SpecificAttributeDialog from "./SpecificAttributeDialog";
import InactiveDateDialog from "./InactiveDateDialog";
import MessageLogDialog from "./MessageLogDialog";
import JobOrderApplicationDialog from "./JobOrderApplicationDialog";
import JobOrderAssignmentDialog from "./JobOrderAssignmentDialog";
import DocumentDialog from "./DocumentDialog";
import TrainingDialog from "./TrainingDialog";
import ClientInclusionDialog from "./ClientInclusionDialog";
import ClientExclusionDialog from "./ClientExclusionDialog";
import MergeSummaryDialog from "./MergeSummaryDialog";
import api from "../../../../services/api";
import { profileRows } from "./MergeHelper";
import Loader from "../../../loaders";
import SweetAlert from "react-bootstrap-sweetalert";
import { ErrorNotification } from "../../../alerts";

function DataMergeDialog(props) {
    const {
        fromGrid,
        initialCandidates,
        gridOnClose,
    } = props;

    const [ loading, setLoading ] = useState(false);
    const [ pageId, setPageId ] = useState(0);
    const [ filter, setFilter ] = useState("");
    const [ filteredCandidates, setFilteredCandidates ] = useState([]);
    const [ selectedCandidates, setSelectedCandidates ] = useState([]);
    const [ selectedCandidateId, setSelectedCandidateId ] = useState(null);
    const [ selectedCandidateProfile, setSelectedCandidateProfile ] = useState(null);
    const [ selectedCandidateAttribute, setSelectedCandidateAttribute ] = useState([]);
    const [ selectedInactiveDates, setSelectedInactiveDates ] = useState([]);
    const [ selectedDocuments, setSelectedDocuments ] = useState([]);
    const [ selectedClientInclusions, setSelectedClientInclusions ] = useState([]);
    const [ selectedClientExclusions, setSelectedClientExclusions ] = useState([]);
    const [ showMergeConfirmation, setShowMergeConfirmation ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ errorNotification, setErrorNotification ] = useState([]);
    const [ showMergeSuccessInformation, setShowMergeSuccessInformation ] = useState(false);
    const [ direction, setDirection ] = useState(1);
    const history = useHistory();

    const onClose = useCallback(() => {
        if (!fromGrid)
        {
            history.push(`/talents`);
            return;
        }

        if (gridOnClose)
            gridOnClose();
    }, [history, fromGrid, gridOnClose]);

    const onMergeConfirmed = useCallback(() => {
        setLoading(true);
        const keys = Object.keys(selectedCandidateProfile);

        const dataToSend = {
            profile: {
                ...keys.reduce((obj, item) => {
                    // console.log({obj, item});
                    const templateRow = profileRows.filter(r => r.id === item)[0];

                    if (templateRow?.type === "title")
                        return obj;

                    obj[item] = selectedCandidateProfile[item].value;

                    if (templateRow.rowDataColumnId)
                        obj[templateRow.rowDataColumnId] = selectedCandidateProfile[item][templateRow.rowDataColumnId];
                    
                    return obj;
                }, {}),
                candidateId: selectedCandidateId,
            },
            mergedCandidateIds: selectedCandidates.filter(c => c.candidateId !== selectedCandidateId).map(c => c.candidateId),
            projectCandidateAttributeIds: [...selectedCandidateAttribute],
            documentIds: [...selectedDocuments],
            inactiveDateIds: [...selectedInactiveDates],
            clientInclusionClientIds: [...selectedClientInclusions],
            clientExclusionClientIds: [...selectedClientExclusions],
        };
        // console.log({selectedCandidates, selectedCandidateId, selectedCandidateProfile, selectedCandidateAttribute, selectedInactiveDates, selectedDocuments, selectedClientInclusions, selectedClientExclusions, dataToSend});
        api.post("candidatemerge/merge", JSON.stringify(dataToSend))
            .then(() => {
                setLoading(false);
                setShowMergeSuccessInformation(true);
            })
            .catch((error) => {
                console.log({error});
                setErrorMessage(error.response.data ?? "System Error.");
                setLoading(false);
            })
        ;
    }, [history, selectedCandidates, selectedCandidateId, selectedCandidateProfile, selectedCandidateAttribute, selectedInactiveDates, selectedDocuments, selectedClientInclusions, selectedClientExclusions]);

    useEffect(() => {
        if (!errorMessage) {
            setErrorNotification([]);
            return;
        }

        const events = {
            onConfirm: () => setErrorMessage(null),
            message: errorMessage
        }
        setErrorNotification([<ErrorNotification {...events} />]);

    }, [errorMessage]);

    return (
        <>
        {
            loading && <Loader />   
        }
            <Modal
                isOpen={true}
                className="modal-xxl"
                modalClassName="db-example-modal-xl"
            >
            {
                pageId === 0 ?
                <SearchDialog
                    title="Talents Data Merge"
                    initialFilter={filter}
                    initialCandidates={initialCandidates ?? []}
                    initialFilteredCandidates={filteredCandidates}
                    initialSelectedCandidates={selectedCandidates}
                    onNextClick={(filter, filtered, selected) => {
                        setFilter(filter);
                        setFilteredCandidates([...filtered]);
                        setSelectedCandidates([...selected]);
                        setPageId(pageId + 1);
                    }}
                    onClose={onClose}
                />
                :
                null
            }
            {
                pageId === 1 ?
                <ProfileComparisonDialog
                    title="Talents Profile"
                    selectedCandidates={selectedCandidates}
                    initialSelectedCandidateId={selectedCandidateId}
                    initialSelectedCandidateProfile={selectedCandidateProfile}
                    onClose={onClose}
                    onBackClick={() => {
                        setSelectedCandidateId(null);
                        setSelectedCandidateProfile(null);
                        setSelectedCandidateAttribute([]);
                        setSelectedInactiveDates([]);
                        setSelectedDocuments([]);
                        setSelectedClientInclusions([]);
                        setSelectedClientExclusions([]);
                        setPageId(pageId - 1);
                    }}
                    onNextClick={(candidateId, candidateProfile) => {
                        setSelectedCandidateId(candidateId);
                        setSelectedCandidateProfile(candidateProfile);
                        setPageId(pageId + 1);
                    }}
                />
                :
                null
            }
            {
                pageId === 2 ?
                <DispatchHistoryDialog
                    title="Dispatch History"
                    selectedCandidates={selectedCandidates}
                    selectedCandidateId={selectedCandidateId}
                    onClose={onClose}
                    onBackClick={() => {
                        setDirection(0);
                        setPageId(pageId - 1);
                    }}
                    onNextClick={() => {
                        setDirection(1);
                        setPageId(pageId + 1);
                    }}
                    direction={direction}
                />
                :
                null
            }
            {
                pageId === 3 ?
                <SpecificAttributeDialog
                    title="Talent's Identifier"
                    selectedCandidates={selectedCandidates}
                    selectedCandidateId={selectedCandidateId}
                    initialSelectedAttributes={selectedCandidateAttribute}
                    onClose={onClose}
                    onBackClick={() => {
                        setDirection(0);
                        setPageId(pageId - 1);
                    }}
                    onNextClick={(selectedAttributes) => {
                        setSelectedCandidateAttribute(selectedAttributes);
                        setDirection(1);
                        setPageId(pageId + 1);
                    }}
                    direction={direction}
                />
                :
                null
            }
            {
                pageId === 4 ?
                <InactiveDateDialog
                    title="Inactive Dates"
                    selectedCandidates={selectedCandidates}
                    selectedCandidateId={selectedCandidateId}
                    initialSelectedDates={selectedInactiveDates}
                    onClose={onClose}
                    onBackClick={() => {
                        setDirection(0);
                        setPageId(pageId - 1);
                    }}
                    onNextClick={(selectedDates) => {
                        setSelectedInactiveDates(selectedDates);
                        setDirection(1);
                        setPageId(pageId + 1);
                    }}
                    direction={direction}
                />
                :
                null
            }
            {
                pageId === 5 ?
                <MessageLogDialog
                    title="Message Logs"
                    selectedCandidates={selectedCandidates}
                    selectedCandidateId={selectedCandidateId}
                    onClose={onClose}
                    onBackClick={() => {
                        setDirection(0);
                        setPageId(pageId - 1);
                    }}
                    onNextClick={() => {
                        setDirection(1);
                        setPageId(pageId + 1);
                    }}
                    direction={direction}
                />
                :
                null
            }
            {
                pageId === 6 ?
                <JobOrderApplicationDialog
                    title="Job Order Applications"
                    selectedCandidates={selectedCandidates}
                    selectedCandidateId={selectedCandidateId}
                    onClose={onClose}
                    onBackClick={() => {
                        setDirection(0);
                        setPageId(pageId - 1);
                    }}
                    onNextClick={() => {
                        setDirection(1);
                        setPageId(pageId + 1);
                    }}
                    direction={direction}
                />
                :
                null
            }
            {
                pageId === 7 ?
                <JobOrderAssignmentDialog
                    title="Job Order Assignments"
                    selectedCandidates={selectedCandidates}
                    selectedCandidateId={selectedCandidateId}
                    onClose={onClose}
                    onBackClick={() => {
                        setDirection(0);
                        setPageId(pageId - 1);
                    }}
                    onNextClick={() => {
                        setDirection(1);
                        setPageId(pageId + 1);
                    }}
                    direction={direction}
                />
                :
                null
            }
            {
                pageId === 8 ?
                <DocumentDialog
                    title="Documents"
                    selectedCandidates={selectedCandidates}
                    selectedCandidateId={selectedCandidateId}
                    initialSelectedDocuments={selectedDocuments}
                    onClose={onClose}
                    onBackClick={() => {
                        setDirection(0);
                        setPageId(pageId - 1);
                    }}
                    onNextClick={(documents) => {
                        setSelectedDocuments(documents);
                        setDirection(1);
                        setPageId(pageId + 1);
                    }}
                    direction={direction}
                />
                :
                null
            }
            {
                pageId === 9 ?
                <TrainingDialog
                    title="Trainings"
                    selectedCandidates={selectedCandidates}
                    selectedCandidateId={selectedCandidateId}
                    onClose={onClose}
                    onBackClick={() => {
                        setDirection(0);
                        setPageId(pageId - 1);
                    }}
                    onNextClick={() => {
                        setDirection(1);
                        setPageId(pageId + 1);
                    }}
                    direction={direction}
                />
                :
                null
            }
            {
                pageId === 10 ?
                <ClientInclusionDialog
                    title="Client Inclusion"
                    selectedCandidates={selectedCandidates}
                    selectedCandidateId={selectedCandidateId}
                    initialSelectedClients={selectedClientInclusions}
                    onClose={onClose}
                    onBackClick={() => {
                        setDirection(0);
                        setPageId(pageId - 1);
                    }}
                    onNextClick={(clients) => {
                        setSelectedClientInclusions(clients);
                        setDirection(1);
                        setPageId(pageId + 1);
                    }}
                    direction={direction}
                />
                :
                null
            }
            {
                pageId === 11 ?
                <ClientExclusionDialog
                    title="Client Exclusion"
                    selectedCandidates={selectedCandidates}
                    selectedCandidateId={selectedCandidateId}
                    initialSelectedClients={selectedClientExclusions}
                    onClose={onClose}
                    onBackClick={() => {
                        setDirection(0);
                        setPageId(pageId - 1);
                    }}
                    onNextClick={(clients) => {
                        setSelectedClientExclusions(clients);
                        setDirection(1);
                        setPageId(pageId + 1);
                    }}
                    direction={direction}
                />
                :
                null
            }
            {
                pageId === 12 ?
                <MergeSummaryDialog
                    title="Summary"
                    selectedCandidates={selectedCandidates}
                    selectedCandidateId={selectedCandidateId}
                    selectedCandidateProfile={selectedCandidateProfile}
                    selectedCandidateAttribute={selectedCandidateAttribute}
                    selectedInactiveDates={selectedInactiveDates}
                    selectedDocuments={selectedDocuments}
                    selectedClientInclusions={selectedClientInclusions}
                    selectedClientExclusions={selectedClientExclusions}
                    onClose={onClose}
                    onBackClick={() => {
                        setDirection(0);
                        setPageId(pageId - 1);
                    }}
                    onNextClick={() => {
                        setShowMergeConfirmation(true);
                    }}
                />
                :
                null
            }
            </Modal>
        {
            showMergeConfirmation &&
            <SweetAlert
                type="warning"
                title="Talent Merge Confirmation"
                showCancel={true}
                confirmBtnText="OK"
                confirmBtnBsStyle="danger"
                onConfirm={() => {
                    onMergeConfirmed();
                }}
                onCancel={() => setShowMergeConfirmation(false)}
            >
                This action can't be reversed. Please do not refresh the page until the process is done. Any interuption may lead to data corruption.
            </SweetAlert>
        }
        {
            errorNotification.length > 0 && errorNotification
        }
        {
            showMergeSuccessInformation &&
            <SweetAlert
                type="info"
                title="Talent Merge Information"
                confirmBtnText="OK"
                confirmBtnBsStyle="success"
                onConfirm={() => {
                    setShowMergeSuccessInformation(false);
                    setShowMergeConfirmation(false);
                    
                    if (!fromGrid)
                    {
                        history.push(`/talents`);
                        return;
                    }
            
                    if (gridOnClose)
                        gridOnClose();
                }}
            >
                Data successfully merged.
            </SweetAlert>
        }
        </>
    );
};

export default DataMergeDialog;
