
import CopyToClipboard from "react-copy-to-clipboard";
import {
    Container, Row, Col, Button, Card, CardBody, CardTitle, UncontrolledTooltip, Progress, Label
} from "reactstrap";
import { TooltipButton } from '../../../../inputs';
import React, { useEffect, useState, useCallback, useMemo, useRef, Component } from 'react';

const timeoutLength = 1500;
const defaultText = "Copy link to clipboard";
const copiedText = "Copied!"
const TooltipContent = ({ scheduleUpdate, index, copiedIndex }) => {
    const [text, setText] = React.useState(defaultText);

    React.useEffect(() => {
        if (copiedIndex === index) {
            setText(copiedText);
            scheduleUpdate();
            const timeoutId = setTimeout(() => {
                setText(defaultText);
                scheduleUpdate();
            }, timeoutLength);
            return () => clearTimeout(timeoutId);
        }
    }, [copiedIndex, index, scheduleUpdate]);

    return (
        <>{text}</>
    );
}


export default class ActionBtnCellRenderer extends Component {

    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }
    btnClickedHandler() {
        this.props.clicked(this.props.value);
    }
    render() {
        //console.log('BtnCellRenderer', 'this', this);
        var { props } = this;
        var { colDef, data } = props;
        var { additionalParams } = colDef;
        //console.log('additionalParams', additionalParams());
        const {
            copiedIndex,
            setCopiedIndex,
            itemInEdit,
            setItemInEdit,
            handleDelete,
            setSmsEditorData,
            setEmailEditorData
        } = additionalParams();


        return (
            //<button onClick={this.btnClickedHandler}>Click Me!</button>
            <div key={`action_${data.messageLogId}`} className="text-center">

                <TooltipButton
                    id={`sendsms_${data.messageLogId}`}
                    title="Send SMS"
                    className="btn-icon"
                    color="default"
                    size="xss"
                    type="button"
                    onClick={() => setSmsEditorData(data)}
                >
                    <i className="fas fa-comment"></i>
                </TooltipButton>
                <TooltipButton
                    id={`sendemail_${data.messageLogId}`}
                    title="Send Email"
                    className="btn-icon"
                    color="default"
                    size="xss"
                    type="button"
                    onClick={() => setEmailEditorData(data)}
                >
                    <i className="fas fa-envelope-open"></i>
                </TooltipButton>
            </div>
        );


    }
}
