import React, { useMemo, useCallback, useEffect, useState } from 'react';
import {
    Label, FormGroup, Row, Col, Collapse, Container
 } from "reactstrap";
import Loader from '../../../loaders';
import { useTable, useFlexLayout, useFilters, useSortBy } from 'react-table';
import _ from "lodash";
import Grid from '../../../Grid';
import { DefaultColumnFilter, DateColumnFilter } from '../../../react-table/filters';
import { TooltipButton } from '../../../inputs';
import { Mode } from '../../../../utils';
import api from "../../../../services/api";
import fileDownload from "js-file-download";
import { FileDropzone, DropzoneList } from '../../../FileDropzone';
import { ComboBox, DropdownBox } from "../../../dropdowns";
import { DatePicker } from "../../../date-pickers";
import { formatDate } from "../../../../utils";
import moment from 'moment';
import react from '@handsontable/react';
import { FormatFileSize } from '../../../../helpers';
import SweetAlert from 'react-bootstrap-sweetalert';

export default function Documents(props) {
    const {
        itemInEdit,
        setItemInEdit,
    } = props;
    
    const [ loading, setLoading ] = React.useState(false);
    const [ drawerMode, setDrawerMode ] = React.useState(Mode.NONE);
    const [documentData, setDocumentData] = useState(null);
    const [documentTypeList, setDocumentTypeList] = useState([]);
    const skipPageResetRef = React.useRef();
    const [lookups, setLookups] = React.useState({});
    const [filters, setFilters] = React.useState([]);
    const [candidateId, setCandidateId] = useState(0);
    const [data, setData] = useState([]);
    const [validationMessage, setValidationMessage] = useState(null);
    const [fileConfig, setFileConfig] = useState(null);

    const refreshData = useCallback(() => {
        setLoading(true);
        api.get(`candidate/${candidateId}/documents`)
            .then((response) => {
                // console.log({response, candidateId});
                setData([...response.data]);
                setItemInEdit({
                    ...itemInEdit,
                    candidateDocuments: [...response.data],
                    shouldRefreshDocuments: false,
                });
            })
            .catch((error) => console.log(error.response))
            .finally(() => setLoading(false))
        ;
    }, [candidateId, itemInEdit]);


    const selected = useMemo(() => {

        if (documentData?.documentType)
            return documentData?.documentType;

        var newSelected = documentTypeList.find(f => f.lookupValue.toLowerCase().includes('cv') || f.lookupValue.toLowerCase().includes('resume'));

        setDocumentData({
            ...newSelected,
            documentType: newSelected,
            documentTypeId: newSelected ? newSelected.lookupId : null
        });
        return newSelected;

    }, [documentTypeList, documentData?.documentType]);


    React.useEffect(() => {
        if ((itemInEdit?.candidateId ?? 0) === candidateId && !itemInEdit.shouldRefreshDocuments)
            return;
        
        setCandidateId(itemInEdit?.candidateId ?? 0);
    }, [itemInEdit]);

    useEffect(() => {
        if (!candidateId || !itemInEdit?.shouldRefreshDocuments)
            return;

        refreshData();
    }, [candidateId, itemInEdit?.shouldRefreshDocuments]);

    const defaultSort = useMemo(() => [{ id: "filename", desc: false }],
        []
    );

    const [sortBy, setSortBy] = useState(defaultSort);

    useEffect(() => {
        skipPageResetRef.current = true;


        setLoading(true);
        const apiCalls = [
            api.get(`lookup/documentType?filter=`),
            api.get(`lookup/fileconfig`),
        ];

        Promise.all(apiCalls)
            .then((responses) => {
                const documentTypeList = [...responses[0].data];
                const fileconfig = { ...responses[1].data };
                setFileConfig(fileconfig);
                setDocumentTypeList(documentTypeList);
            })
            .catch((error) => {
                console.log({ error });
            })
            .finally(() => setLoading(false))
            ;

    }, []);
    
    const filterTypes = React.useMemo(() => ({
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
            })
        },

    }), []);
    const columns = React.useMemo(() => [
        {
            Header: "File Name",
            id: "filename",
            accessor: "filename",
            Filter: DefaultColumnFilter,
            filter: "text",
            width: 100,
            disableSortBy: false,
            disableFilters: false,
        },
        {
            Header: "Document Type",
            id: "documentType",
            accessor: (row) => row.documentType?.lookupValue,
            Filter: DefaultColumnFilter,
            filter: "text",
            width: 100,
            disableSortBy: false,
            disableFilters: false,
        },
        {
            Header: "Expiration Date",
            id: "expirationDate",
            accessor: (row) => row.expirationDate ? moment(row.expirationDate.slice(0, 10)).format("YYYY.MM.DD") : null,
            Filter: (e) => DateColumnFilter({ ...e, dateFormat: "YYYY.MM.DD", dateMask: "99.99.9999" }),
            Cell: ({ row }) => row.values.expirationDate ? moment(row.values.expirationDate.slice(0, 10)).format("DD.MM.YYYY") : null,
            filter: "text",
            width: 100,
            disableSortBy: false,
            disableFilters: false,
        },
        {
            Header: "Uploaded By",
            id: "modifiedBy",
            accessor: (row) => row.modifiedBy,
            Filter: DefaultColumnFilter,
            filter: "text",
            width: 70,
            disableSortBy: false,
            disableFilters: false,
        },
        {
            Header: "Upload Date",
            id: "modifiedDate",
            accessor: (row) => row.modifiedDate ? moment(row.modifiedDate.slice(0, 10)).format("YYYY.MM.DD") : null,
            Filter: (e) => DateColumnFilter({ ...e, dateFormat: "YYYY.MM.DD", dateMask: "99.99.9999" }),
            Cell: ({ row }) => row.values.modifiedDate ? moment(row.values.modifiedDate.slice(0, 10)).format("DD.MM.YYYY") : null,
            filter: "text",
            width: 50,
            disableSortBy: false,
            disableFilters: false,
        },
        {
            id: 'buttons',
            Header: "Action",
            width: 180,
            canResize: false,
            disableSortBy: true,
            disableFilters: true,
            Cell: ({row:{original, isGrouped}}) => (
                (original && !isGrouped) ?
                (
                    <div className="td-action text-center">
                        <TooltipButton
                            id={`edit_${original.candidateDocumentId}`}
                            title="Edit"
                            className="btn-icon"
                            color="default"
                            size="xsm"
                            type="button"
                            onClick={() => {
                                setDocumentData({...original});
                                setDrawerMode(Mode.EDIT);
                            }}
                        >
                            <i className="fas fa-pencil-alt pt-1"></i>
                        </TooltipButton>
                        <TooltipButton
                            id={`show_${original.candidateDocumentId}`}
                            title="Show"
                            className="btn-icon"
                            color="default"
                            size="xsm"
                            type="button"
                            onClick={() => {
                                if (original.fileType)
                                {
                                    setLoading(true);
                                    const url = `candidate/download-document/${original.candidateDocumentId}`;
                                    api.get(url, { responseType: 'blob' })
                                    // api.get(url)
                                        .then((response) => {
                                            const {data, headers} = response;
                                            const filename = headers["content-disposition"].split("; ")[1].split("=")[1].replaceAll('"', "");
                                            const fileURL = URL.createObjectURL(data);
                                            const anchorElement = document.createElement('a');
                                            anchorElement.style.display = 'none';
                                            anchorElement.href = fileURL;
                                            anchorElement.download = filename;
                                            anchorElement.click();
                                            URL.revokeObjectURL(url);
                                            setLoading(false);
                                        }).catch(error => {
                                            console.error(error);
                                            setLoading(false);
                                        })
                                    ;
                                }
                                
                                if (original.file && original.file instanceof File)
                                {
                                    const blobObj = original.file;
                                    const url = URL.createObjectURL(blobObj);
                                    window.open(url, '_blank');
                                }
                            }}
                        >
                            <i className="fas fa-search pt-1"></i>
                        </TooltipButton>
                        <TooltipButton
                            id={`delete_${original.candidateDocumentId}`}
                            title="Delete"
                            className="btn-icon"
                            color="warning"
                            size="xsm"
                            type="button"
                            onClick={() => {
                                setLoading(true);
                                const index = data.findIndex(val => {
                                    if (original.candidateDocumentId)
                                        return val.candidateDocumentId === original.candidateDocumentId;

                                    return val.index === original.index;
                                });
                                console.log("Delete: ", { documentData: original, itemInEdit, index, candidateDocuments: data });

                                if (index < 0)
                                {
                                    setLoading(false);
                                    return;
                                }

                                const documents = [...data];
                                documents.splice(index, 1);
                                setData([...documents]);
                                setLoading(false);
                            }}
                        >
                            <i className="fas fa-trash-alt pt-1"></i>
                        </TooltipButton>
                    </div>
                ) :
                (
                    <div className="td td-action"></div>
                )
            )
        },
    ]
    , [data]);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
        disableFilters: true,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
    } = useTable(
        {
            columns,
            data: data || [],
            initialState: {
                sortBy: defaultSort,
                canSort: true,
            },
            filterTypes,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
            defaultColumn,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
    );

    React.useEffect(() => {
        setFilters(state.filters);
    }, [state.filters]);

    React.useEffect(() => {
        setSortBy(state.sortBy);
    }, [state.sortBy]);

    const downloadAttachment = (fileInfo) => {
        if (fileInfo instanceof File) {
            fileDownload(fileInfo, fileInfo.name);
        } else {
            setLoading(true);
            let url = `candidate/download-document/${fileInfo.candidateDocumentId}`;
            api.get(url, { responseType: 'blob' })
            .then(blob => {
                fileDownload(blob.data, fileInfo.filename);
            }).catch(error => {
                console.error(error);
            }).finally(() => setLoading(false))
        }
    }




    const files = React.useMemo(() => {
        if (documentData?.file)
            return <DropzoneList
                name={documentData.file.name}
                size={documentData.file.size}
                download={downloadAttachment}
                itemInEdit={documentData.file}
            />;
        
        if (documentData?.filename)
        {
            return <DropzoneList
                name={documentData.filename}
                size={documentData.fileSizeInKbytes}
                download={downloadAttachment}
                itemInEdit={documentData}
            />;
        }
        
        return null;
    }, [documentData]);

    useEffect(() => {
        setItemInEdit({...itemInEdit, candidateDocuments: [...data]})
    }, [data]);


    return (
        <Row>
            <Col xs={12}>
                {loading && <Loader />}
                <Collapse
                    isOpen={drawerMode !== Mode.NONE}
                    style={{ zIndex: "9999", border: "0.0625rem solid #dee2e6" }}
                    className="bg-white w-100 rounded p-3 mb-2"
                >
                    <Container fluid>
                        <Collapse
                            isOpen={drawerMode !== Mode.NONE && (!documentData?.documentTypeId || (!documentData?.filename && !documentData?.file))}
                            style={{ zIndex: "10000" }}
                            className="w-100 mb-2"
                        >
                            <div className="alert alert-warning" role="alert">
                                <span>Please select a document type and attach a document.</span>
                            </div>
                        </Collapse>

                        <Collapse
                            isOpen={!!fileConfig?.maxFileSizeByte}
                            style={{ zIndex: "10000" }}
                            className="w-100 mb-2"
                        >
                                <div className="alert alert-warning">
                                    Max file size is {FormatFileSize(fileConfig?.maxFileSizeByte)}
                                </div>
                        </Collapse>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        Document Type
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <DropdownBox
                                        data={documentTypeList}
                                        selectedItem={selected}
                                        onChange={(data) => setDocumentData({
                                            ...documentData,
                                            documentTypeId: data.lookupId,
                                            documentType: data
                                        })}
                                        minLength={0}
                                        sortByName={true}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label>
                                        Expiration Date
                                    </Label>
                                    <DatePicker
                                        name="expirationDate"
                                        id="expirationDate"
                                        type="text"
                                        required
                                        value={formatDate(documentData?.expirationDate)}
                                        onChange={(value) => {
                                            setDocumentData({
                                                ...documentData,
                                                expirationDate: !value ? null : moment(value).format("YYYY-MM-DDT00:00:00"),
                                            });
                                        }}
                                        closeOnSelect
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label>
                                        File
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <FileDropzone
                                        files={files}
                                        onDrop={([file]) => {
                                            // Validate file size
                                            if (fileConfig?.maxFileSizeByte && file.size > fileConfig.maxFileSizeByte) {
                                                setValidationMessage(`File size exceeded. Please upload a file with a maximum size of ${FormatFileSize(fileConfig.maxFileSizeByte)}.`);
                                                return;
                                            }


                                            setDocumentData( { ...documentData, filename: file.name, file } );
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="d-flex justify-content-end">
                                <TooltipButton
                                    id="cancel"
                                    title="Cancel"
                                    className="btn-icon"
                                    color="warning"
                                    size="sm"
                                    type="button"
                                    onClick={ () => {                        
                                        setDrawerMode(Mode.NONE);
                                    }}                    
                                >
                                    Cancel
                                </TooltipButton>
                                <TooltipButton
                                    id="add"
                                    title={drawerMode === Mode.ADD ? "Add" : drawerMode === Mode.EDIT ? "Update" : ""}
                                    className="btn-icon ml-2"
                                    color="default"
                                    size="sm"
                                    type="button"
                                    disabled={!documentData?.documentTypeId || (!documentData?.filename && !documentData?.file)}
                                    onClick={ () => {
                                        if (!documentData?.documentTypeId || (!documentData?.filename && !documentData?.file))
                                            return;

                                        setLoading(true);
                                        if (drawerMode === Mode.ADD) {
                                            const documents = [...data, {...documentData, index: data?.length ? data?.length - 1 : 0}];
                                            console.log({documents});
                                            setData([...documents]);
                                            setItemInEdit( { 
                                                ...itemInEdit,
                                                candidateDocuments: [...documents],
                                            } );
                                        } 
                                        else if (drawerMode === Mode.EDIT)
                                        {
                                            const index = _.findIndex(data, o => {
                                                if (documentData.candidateDocumentId)
                                                    return o.candidateDocumentId === documentData.candidateDocumentId;
            
                                                return o.index === documentData.index;
                                            });

                                            const documents = [...data];
                                            
                                            documents.splice(index, 1, { 
                                                ...documentData
                                            } );

                                            setData([...documents]);
                                            setItemInEdit( { 
                                                ...itemInEdit,
                                                candidateDocuments: [...documents],
                                            } );
                                        }
                                        setDocumentData(null);
                                        setDrawerMode(Mode.NONE);
                                        setLoading(false);
                                    }}
                                >
                                    {drawerMode === Mode.ADD ? "Add" : drawerMode === Mode.EDIT ? "Update" : ""}
                                </TooltipButton>
                            </Col>
                        </Row>
                    </Container>
                </Collapse>
                <Collapse
                    isOpen={drawerMode === Mode.NONE}
                >
                    <TooltipButton
                        id="addnew"
                        title="Add New"
                        className="btn-icon ml-3 mb-2"
                        color="default"
                        size="sm"
                        type="button"
                        onClick={ () => {
                            setDocumentData({ candidateId: itemInEdit?.candidateId });
                            setDrawerMode(Mode.ADD);
                        }}
                    >
                        <i className="fas fa-plus pt-1"></i>
                    </TooltipButton>
                </Collapse>
                <Grid
                    rows={rows}
                    tableProps={getTableProps()}
                    headerGroups={headerGroups}
                    tableBodyProps={getTableBodyProps()}
                    prepareRow={prepareRow}
                    height='33vh'
                />
            </Col>
            {
                validationMessage &&
                <SweetAlert
                    warning
                    confirmBtnText="OK"
                    confirmBtnBsStyle="danger"
                    title={<h4 className="mb-0">Candidate Documents</h4>}
                    onConfirm={() => setValidationMessage(null)} // need to add loading indicator on confirm
                    onCancel={() => setValidationMessage(null)}
                    focusCancelBtn
                >
                    <p>{validationMessage}</p>
                </SweetAlert>
            }
        </Row>
    )
}