import React, { useCallback, useEffect, useState } from 'react';
import {
    Container, Row, Col, Button, CardTitle, Modal, FormGroup, Label, Input, Alert
} from "reactstrap";
import api from "../../../../services/api";
import qs from "qs";
import { ConfirmDeleteAlert, DeleteFailedAlert, DeleteSuccessAlert } from "../../../alerts";
import { ComboBox, DropdownBox } from "../../../dropdowns";
import { TooltipButton } from '../../../inputs';
import Loader from "../../../loaders";
import { DefaultColumnFilter } from "../../../react-table/filters";
import { useTable, useExpanded, useFilters, useSortBy, useFlexLayout, useAsyncDebounce } from 'react-table';
import Grid from "../../../Grid";
import SweetAlert from 'react-bootstrap-sweetalert';
import { TrimObject } from '../../../../helpers/StringHelper';

function ApprovalMaster(props) {

    const [ approvalMasterData, setApprovalMasterData ] = React.useState([]); 
    const [ approvalTypes, setApprovalTypes ] = React.useState([]);      
    const [ approvalMasterLoading, setApprovalMasterLoading ] = React.useState(false);
    const [ approvalMasterInEdit, setApprovalMasterInEdit ] = React.useState(null);
    const [ pageNumber, setPageNumber ] = React.useState(1);
    const [ pageSize, setPageSize ] = React.useState(10);
    const [ totalRecords, setTotalRecords ] = React.useState(0);
    const [ filters, setFilters ] = React.useState([]);
    const [ isApprovalMasterUsed, setIsApprovalMasterUsed ] = useState(false);

    const defaultSort = React.useMemo(() => [
        { id: "project", desc: false },
        { id: "location", desc: false }
    ]);

    const [sortBy, setSortBy] = React.useState(defaultSort);

    const [deleteAlert, setDeleteAlert] = React.useState([]);

    const [validationMessages, setValidationMessages] = React.useState([]);
    const skipPageResetRef = React.useRef();

    const loadData = React.useCallback(() => {
        skipPageResetRef.current = true;
        const queryString = qs.stringify({ filters, sortBy }, { allowDots: true });
        setApprovalMasterInEdit(null);
        setApprovalMasterData([]);

        setApprovalMasterLoading(true);
        api.get(`/approvalmaster/${(pageNumber - 1) * pageSize}/${pageSize}${queryString ? `?${queryString}` : ""}`)
            .then(({ data }) => {
                setApprovalMasterData(data.data);
                setTotalRecords(data.total);
            }).catch((error) => {
                console.error("error: ", error);
            }).finally(() => setApprovalMasterLoading(false));
    }, [filters, sortBy, pageNumber, pageSize]);

    const handleDelete = (item) => {
        const success = () => {
            setDeleteAlert([<DeleteSuccessAlert onConfirm={() => setDeleteAlert([])} />]);
            if (approvalMasterData.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1);
            } else {
                loadData();
            }
        };
        const failure = () => setDeleteAlert([<DeleteFailedAlert onConfirm={() => setDeleteAlert([])} />]);
        const events = {
            onConfirm: () => api.delete(`/approvalmaster/${item.approvalMasterId}`).then(success).catch(failure),
            onCancel: () => setDeleteAlert([])
        }
        setDeleteAlert([<ConfirmDeleteAlert {...events} />])
    }

    const handleSave = useCallback((item, updateApproval = true) => {


        // Trim every string property
        item = TrimObject(item);
        setApprovalMasterInEdit(item);

        // Save
        const data = JSON.stringify(item);
        const save = item.approvalMasterId > 0 
            ? api.put(`approvalmaster/${item.approvalMasterId}?updateApproval=${updateApproval}`, data)
            : api.post("approvalmaster", data)

        save
            .then((response) => {
                setIsApprovalMasterUsed(false);
                loadData();
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setApprovalMasterLoading(false);
            });
    }, []);

    const handleTextChanged = ({ target: { name, value } }) => {
        setApprovalMasterInEdit({ ...approvalMasterInEdit, [name]: value });
    }

    React.useEffect(() => {

        api.get(`/lookup/approvaltype`)
            .then(({ data }) => {
                setApprovalTypes(data);
            })
            .catch((error) => console.error(error));
    }, []);

    React.useEffect(() => {
        loadData();
    }, [pageNumber, pageSize, filters, sortBy]);

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue).replace(/[/-]/g, "")
                            .toLowerCase()
                            .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const columns = React.useMemo(() => {
        return [
            {
                Header: "Project",
                id: "project",
                accessor: (row) => row.project?.lookupValue,
                filter: "text",
                width: 200,
                disableSortBy: false,
            },
            {
                Header: "Approval Type",
                id: "type",
                accessor: (row) => row.approvalType?.lookupValue,
                filter: "text",
                width: 200,
                disableSortBy: false,
            },
            {
                Header: "Location",
                id: "location",
                accessor: (row) => row.location?.lookupValue,
                filter: "text",
                width: 200,
                disableSortBy: false,
            },
            {
                Header: "Approval Email",
                id: "email",
                accessor: (row) => row.emailAddress,
                filter: "text",
                width: 200,
                disableSortBy: false,
            },
            {
                Header: "Actions",
                id: 'button',
                Cell: ({ row: { original } }) =>
                (
                    <div>
                        <TooltipButton
                            id={`edit_${original.approvalMasterId}`}
                            title="Edit"
                            className="btn-icon"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => setApprovalMasterInEdit(original)}
                        >
                            <i className="fas fa-pencil-alt pt-1"></i>
                        </TooltipButton>
                        <TooltipButton
                            id={`delete_${original.approvalMasterId}`}
                            title="Delete"
                            className="btn-icon"
                            color="warning"
                            size="sm"
                            type="button"
                            onClick={() => handleDelete(original)}
                        >
                            <i className="fas fa-trash-alt pt-1"></i>
                        </TooltipButton>
                    </div>
                ),
                width: 140,
            },
        ]
    }, []);

    const defaultColumn = React.useMemo(() => ({
        width: 150,
        Filter: DefaultColumnFilter,
        filter: "text",
        disableSortBy: true,
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
    } = useTable(
        {
            columns,
            data: approvalMasterData,
            initialState: {
                sortBy: defaultSort,
                canSort: true,
            },
            filterTypes,
            defaultColumn,
            manualSortBy: true,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
        useExpanded
    );

    React.useEffect(() => {
        setFilters(state.filters);
    }, [state.filters]);

    React.useEffect(() => {
        setSortBy(state.sortBy);
    }, [state.sortBy]);

    const checkActiveApproval = useCallback((callback) => {
        const validator = require("email-validator");

        const newValidationMessages = [];

        if (!approvalMasterInEdit?.approvalType) {
            newValidationMessages.push("Please check Approval Types has been entered correctly.");
        }

        if (!approvalMasterInEdit?.emailAddress || !validator.validate(approvalMasterInEdit?.emailAddress)) {
            newValidationMessages.push("Please check email has been entered correctly.");
        }
        
        setValidationMessages(newValidationMessages);

        if (newValidationMessages.length)
            return;

        setApprovalMasterLoading(true);

        api.get(`approvalmaster/lrf/usage?id=${approvalMasterInEdit?.approvalMasterId ?? 0}${approvalMasterInEdit?.projectId ? `&projectId=${approvalMasterInEdit.projectId}` : ""}${approvalMasterInEdit?.locationId ? `&locationId=${approvalMasterInEdit.locationId}` : ""}&email=${approvalMasterInEdit.emailAddress}`)
            .then((response) => {
                console.log({response});
                
                if (!response.data)
                {
                    callback();
                    return;
                }

                setIsApprovalMasterUsed(true);
                setApprovalMasterLoading(false);
            })
            .catch((error) => {
                console.log({error});
                setApprovalMasterLoading(false);
            })
        ;
    }, [approvalMasterInEdit?.approvalMasterId, approvalMasterInEdit?.approvalType, approvalMasterInEdit?.projectId, approvalMasterInEdit?.locationId, approvalMasterInEdit?.emailAddress]);
    
    return (
        <>
            {approvalMasterLoading && <Loader />}
            {deleteAlert.length > 0 && deleteAlert}
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <CardTitle>
                            <h4 className="text-center">Approval Master</h4>
                        </CardTitle>
                        <TooltipButton
                            id="addnew"
                            title="Add New"
                            className="btn-icon ml-2 mb-2"
                            color="default"
                            size="sm"
                            type="button"
                            onClick={() => {
                                setApprovalMasterInEdit({});
                            }}
                        >
                            <i className="fas fa-plus pt-1"></i>
                        </TooltipButton>
                        <Grid
                            height='calc(100vh - 460px)'
                            totalRecords={totalRecords}
                            pageSize={pageSize}
                            rows={rows}
                            tableProps={getTableProps()}
                            headerGroups={headerGroups}
                            tableBodyProps={getTableBodyProps()}
                            prepareRow={prepareRow}
                            onPagerChangePage={(pager) => {
                                setPageNumber(pager.currentPage);
                            }}
                            onPagerChangePageSize={(size) => {
                                setPageSize(size);
                            }}
                            needPaging={true}
                        />
                    </Col>
                </Row>
            </Container>
            {
                !!approvalMasterInEdit &&
                <Modal
                    isOpen={true}
                    className="modal-lg"
                    modalClassName="db-example-modal-lg"
                >
                    <div
                        className="modal-header"
                    >
                        <h5
                            className="modal-title"
                        >
                            Approval Master Editor
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            onClick={() => setApprovalMasterInEdit(null)}
                            type="button"
                        >
                            <span aria-hidden={true}>x</span>
                        </button>
                    </div>
                    <div
                        className="modal-body"
                    >
                        <Container>
                        {
                            validationMessages.length > 0 &&
                            <Alert color="warning" className="mb-0 d-flex mx-auto justify-content-between mt-3">
                                <ul className="mb-0">
                                {
                                    validationMessages.map((m, idx) => 
                                        <li key={`app_am_${idx}`}>{m}</li>
                                    )
                                }
                                </ul>
                            </Alert>
                        }
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Project
                                        </Label>
                                        <ComboBox
                                            minLength={0}
                                            endpoint="/project"
                                            selectedItem={approvalMasterInEdit.project}
                                            onChange={(item) => {
                                                setApprovalMasterInEdit({ ...approvalMasterInEdit, project: item, projectId: item ? item.lookupId : null });
                                            }}
                                        >
                                        </ComboBox>
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Approval Type
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <DropdownBox
                                            data={approvalTypes}
                                            //endpoint="/approvaltype"
                                            selectedItem={approvalMasterInEdit.approvalType}
                                            onChange={(item) => {
                                                setApprovalMasterInEdit({ ...approvalMasterInEdit, approvalType: item, approvalTypeId: item ? item.lookupId : null });
                                            }}
                                        >
                                        </DropdownBox>
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Location
                                        </Label>
                                        <ComboBox
                                            minLength={0}
                                            endpoint="/location"
                                            selectedItem={approvalMasterInEdit.location}
                                            onChange={(item) => {
                                                setApprovalMasterInEdit({ ...approvalMasterInEdit, location: item, locationId: item ? item.lookupId : null });
                                            }}
                                        >
                                        </ComboBox>
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label>
                                            Approver Email Address
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            name="emailAddress"
                                            id="emailAddress"
                                            type="text"
                                            value={approvalMasterInEdit.emailAddress}
                                            onChange={handleTextChanged}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div
                        className="modal-footer"
                    >
                        <Button
                            color="secondary"
                            onClick={() => {
                                setApprovalMasterInEdit(null);
                                setValidationMessages([]);
                            }}
                            type="button"
                        >
                            Close
                        </Button>
                        <Button
                            color="info"
                            onClick={() => checkActiveApproval(() => handleSave(approvalMasterInEdit, false))}
                            type="button"
                        >
                            Save Changes
                        </Button>
                    </div>
                {
                    isApprovalMasterUsed &&
                    <SweetAlert
                        title="Approval Update Confirmation"
                        confirmBtnText="Yes"
                        confirmBtnBsStyle="default"
                        onConfirm={() => {
                            handleSave(approvalMasterInEdit);
                        }}
                        showCancel
                        onCancel={() => {
                            handleSave(approvalMasterInEdit, false);
                        }}
                        cancelBtnText="No"
                        cancelBtnBsStyle="warning"
                    >
                        Do you want to update all active request to the new approver?
                    </SweetAlert>
                }
                </Modal>
            }
        </>
    );
}

export default ApprovalMaster;