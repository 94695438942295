
import CopyToClipboard from "react-copy-to-clipboard";
import {
    Container, Row, Col, Button, Card, CardBody, CardTitle, UncontrolledTooltip, Progress, Label
} from "reactstrap";
import { TooltipButton } from '../../../../../inputs';
import React, { useEffect, useState, useCallback, useMemo, useRef, Component } from 'react';

export default class LrfCellRenderer extends Component {

    constructor(props) {
        super(props);
        //this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    render() {

        var { props } = this;
        var { colDef, data } = props;

        return (<a className="text-info" href={`/lrf/${data.recruitmentRequestId}`} target='_blank'>{data.recruitmentRequestNumber}</a>);


    }
}