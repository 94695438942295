import React, { useEffect, useRef, useState, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import api from "../../services/api";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import qs from "qs";
import debounce from 'lodash.debounce';

const ComboboxEditor = forwardRef((props, ref) => {
    const { charPress, keyField, textField, dataKeyField, dataTextField, otherFields, endpoint, initData, className } = props;
    const minLength = props?.minLength ?? 0;
    const ovalue = {
        [keyField]: props.data[keyField],
        [textField]: props.data[textField]
    };
    const [value, setValue] = useState(ovalue);
    const [filter, setFilter] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [opened, setOpened] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const refInput = useRef(null);

    useEffect(() => {
        refInput.current.focus();

        if ((!charPress || charPress === ""))
            return;

        setFilter(charPress);
        setValue({[keyField]: 0, [textField]: charPress});
    }, []);

    useEffect(() => {
        if (disabled)
            return;

        refInput.current.focus();
    }, [disabled]);

    useImperativeHandle(ref, () => {
        return {
            // the final value to send to the grid, on completion of editing
            getValue() {
                return value;
            }, 
            // Gets called once before editing starts, to give editor a chance to
            // cancel the editing before it even starts.
            isCancelBeforeStart() {
                return false;
            }, 
            // Gets called once when editing is finished (eg if Enter is pressed).
            // If you return true, then the result of the edit will be ignored.
            isCancelAfterEnd() {
                // console.log('isCancelAfterEnd', {value,ovalue})
                return (!value ? null : value[keyField]) === ovalue[keyField];
            }
        };
    });

    const dataItemMapper = useCallback((item) => {
        const result = {
            [keyField]: item[dataKeyField ?? keyField],
            [textField]: item[dataTextField ?? textField],
        };

        if (otherFields?.length)
            otherFields.forEach(f => {
                result[f.field] = item[f.dataField ?? f.field];
            });

        return result;
    }, [keyField, textField, dataKeyField, dataTextField, otherFields]);

    const requestData = useCallback(
        debounce((filter) => {
            if (!filter)
                filter = "";

            if (filter.length < minLength) {
                setLoading(false);
                return;
            }

            if (initData?.length > 0)
            {
                const filteredInitData = initData.filter(d => d[dataTextField].toLowerCase().includes(filter.toLowerCase()));
                setData([...filteredInitData.map(d => dataItemMapper(d))]);
                setLoading(false);
                return;
            }

            const queryString = qs.stringify({ filter, startDate: props.data.startDate, endDate: props.data.endDate }, { allowDots: true });

            if (!queryString) {
                setLoading(false);
                return;
            }

            api.get(`${endpoint}${queryString ? `?${queryString}` : ""}`)
            .then((response) => {
                setData(!response.data?.length ? [] : response.data.map(d => dataItemMapper(d)));
                setLoading(false);

                if (!opened)
                    setOpened(true);
            })
            .catch((error) => {
                console.error(error.response);
                setLoading(false);
            })
            ;
        }, 300)
    , [opened, initData, dataTextField, dataItemMapper, minLength]);

    useEffect(() => {
        setLoading(true);
        setData([]);
        requestData(filter);
    }, [filter]);

    const onFilterChange = useCallback((event) => {
        setFilter(event.filter.value);
    }, []);

    const onChange = useCallback((event) => {
        setValue(event.value);
    }, [opened, textField]);

    const comboboxProps = useMemo(() => {
        const result = {
            data,
            value,
            onChange,
            keyField,
            textField,
            filterable: true,
            onFilterChange,
            ref: refInput,
            rounded: "null",
            size: "small",
            loading,
            opened,
            onOpen: () => setOpened(true),
            onClose: () => setOpened(false),
            className: className ?? "",
        }

        return result;
    }, [data, value, onChange, keyField, textField, onFilterChange, refInput, loading, opened, disabled, className]);

    return (
        <ComboBox {...comboboxProps}/>
    );
});

export default ComboboxEditor;
