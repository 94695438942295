const tcValidation = [
    {
        field: "projectId",
        tests: [
            {
                type: "required",
                message: "Project is required.",
                validate: (obj) => !obj.projectId,
            }
        ]
    },
    {
        field: "employmentCategoryId",
        tests: [
            {
                type: "required",
                message: "Employment Category is required.",
                validate: (obj) => {
                    return !obj.employmentCategoryId
                },
            }
        ]
    },
    {
        field: "componentId",
        tests: [
            {
                type: "required",
                message: "Component is required.",
                validate: (obj) => !obj.componentId,
            }
        ]
    },
    {
        field: "startDate",
        tests: [
            {
                type: "required",
                message: "Start Date is required.",
                validate: (obj) => !obj.startDate,
            }
        ]
    }, 
    {
        field: "componentValue",
        tests: [
            {
                type: "required",
                message: "Value is required.",
                validate: (obj) => !obj.componentValue,
            }
        ]
    },   
];
export {
    tcValidation
};